import Address from 'data/Address';
import VendorInterface from 'data/VendorInterface';

export default interface Company {
  id: number;
  companyId: string;
  status: string;
  country: string;
  creditType: string;
  parentCompanyId: string;
  address: number;
  companyName: string;
  displayName: string;
  DUNS: string;
  phone: number;
  seller: number;
  taxId: string;
  contact: number;
  contactType: string;
  customerSince: string;
  customerRole: string;
  ignored: number;
  processed: number;
  companyAddress: string;
  countryCode: string;
  addresses: Address[];
  vendorInterfaces: VendorInterface[];
  source: string;
  companyHistoryNameCount: number;
  companyCountry?: {
    countryName: string,
    countryIso: string
  }
  countryName?: string,
  countryIso?: string,
  domain?: string,
};

export interface CompaniesList {
  page: number;
  totalPages: number;
  size: number;
  totalItems: number;
  companies: Company[];
};

export type CompanyHierarchy = {
  id: string;
  name: string;
  parentCompanyId: string;
  children: Omit<CompanyHierarchy, 'children'>[];
};

export const empty: Omit<Company, 'id'> = {
  companyId: '',
  status: '',
  country: '',
  creditType: '',
  parentCompanyId: '',
  address: 0,
  companyName: '',
  displayName: '',
  DUNS: '',
  phone: 0,
  seller: 0,
  taxId: '',
  contact: 0,
  contactType: '',
  customerSince: '',
  customerRole: '',
  ignored: 0,
  processed: 0,
  companyAddress: '',
  countryCode: '',
  addresses: [],
  vendorInterfaces: [],
  source: '',
  companyHistoryNameCount: 0
};
