import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { CreditInfo } from 'data/VendorCompany';
import Button from 'components/UI/Button';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Divider from 'components/UI/Divider';
import { Space } from 'styles/themeSpaces';
import { Main, Text, Border, Background } from 'styles/themeColors';
import CurrencyInput from 'components/UI/CurrencyInput';
import { currencyFormatter } from 'utils/formatters';
import { isDateOlderThan, numberOfDaysPassed } from 'utils/date';

const TextSecondary = styled.span`
  color: ${Text.secondary} !important;
  margin-top: ${Space.spBasePlus};
`;

const ConnectionStatus = styled.div<any>`
  background-color: ${({ $isConnected, creditStatusBgColor }) => $isConnected && creditStatusBgColor ? creditStatusBgColor : Main.error};
  border-radius: 22px;
  color: ${Text.whitePrimary} !important;
  padding: ${Space.spHalf} ${Space.spBase};
  margin-left: ${Space.spBase};
`;

interface Props {
  className?: string;
  isConnected: boolean;
  lastUpdated: string;
  creditInfo: CreditInfo;
  onClick: (value: string) => void;
};

const CreditCheckCard = ({
  className,
  isConnected,
  lastUpdated,
  creditInfo,
  onClick
}: Props) => {
  const [value, setValue] = useState(creditInfo.totalCreditLimit.toFixed(2));
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    setIsInvalid(creditInfo.totalCreditLimit <= 0);
    setValue(creditInfo.totalCreditLimit.toFixed(2));
  }, [creditInfo.totalCreditLimit]);

  const creditStatus = isConnected
    ? creditInfo.isReadOnly
      ? lastUpdated && isDateOlderThan(lastUpdated, 1)
        ? `Read from you source system ${numberOfDaysPassed(lastUpdated)} day ago`
        : 'Reading from your source system'
      : 'Tracked by OFMP'
    : 'Company UID required';

  const creditStatusBgColor = creditInfo.isReadOnly
    ? lastUpdated && isDateOlderThan(lastUpdated, 5)
      ? Main.error
      : lastUpdated && isDateOlderThan(lastUpdated, 1)
        ? Main.warning
        : Main.success
    : Main.success;

  const onInputChange = (value?: string) => {
    const val = value ? value : '0';

    setIsInvalid(+val <= 0);
    setValue(val);
  };

  const onCancel = () => {
    const val = creditInfo.totalCreditLimit ?  creditInfo.totalCreditLimit.toFixed(2) : '0'
    setIsInvalid(+val <= 0);
    setValue(val);
  };

  const onApplyCreditLimit = () => {
    if (value) {
      onClick(value);
    }
  };

  const input = (
    <CurrencyInput
      required
      selectAllOnFirstClick
      id='credit-card-limit-input-id'
      prefix='$'
      textAlign='right'
      labelName='Credit limit'
      isInvalid={isInvalid}
      labelPosition='left'
      placeholder=''
      decimalsLimit={2}
      value={value}
      onValueChange={onInputChange}
      errorMessage='Non-zero value required'
    />
  );

  const creditButtons = (
    <FlexboxContainer justifyContent="flex-end">
      <Button onClick={onCancel} isGhost color={Main.accent} margin={`0 ${Space.spHalf} 0 0`}>Cancel</Button>
      <Button onClick={onApplyCreditLimit}>Apply new credit limit</Button>
    </FlexboxContainer>
  );

  return (
    <FlexboxContainer
      className={`border ${className}`}
      width="512px"
      flexDirection="column"
      padding={Space.sp2x}
      borderColor={`${Border.light} !important`}
      bgColor={Background.light}
    >
      <FlexboxContainer justifyContent="flex-start" alignItems="center">
        <h5>Credit check</h5>
        <ConnectionStatus className="base-strong" $isConnected={isConnected} creditStatusBgColor={creditStatusBgColor}>{creditStatus}</ConnectionStatus>
      </FlexboxContainer>
      <TextSecondary className="dense-italic">
        Credit check will be performed on each Purchase Order placement
      </TextSecondary>
      <FlexboxContainer
        className="border"
        flexDirection="column"
        bgColor={Background.shaded}
        borderColor={`${Border.light} !important`}
        margin={`${Space.spBasePlus} 0`}
        padding={`${Space.sp2x} ${Space.spBase} ${Space.sp2x} ${Space.sp2x}`}
      >
        <FlexboxContainer justifyContent="space-between" alignItems="center" padding={`0 0 ${Space.sp2x}`}>
          {isConnected
            ? creditInfo.isReadOnly
              ? (
                <>
                  <span className="base">Credit limit</span>
                  <span className="base">{currencyFormatter.format(creditInfo.totalCreditLimit)}</span>
                </>
              )
              : input
            : (
              <>
                <span className="base">Credit limit</span>
                <span className="base">n/a</span>
              </>
            )}
        </FlexboxContainer>
        {isConnected && !creditInfo.isReadOnly ? null : <Divider bgColor={Border.main} />}
        <FlexboxContainer justifyContent="space-between" padding={`${Space.sp2x} 0`}>
          <span className="base">Utillization</span>
          <span className="base">{isConnected ? `${currencyFormatter.format(creditInfo.utilization)}` : 'n/a'}</span>
        </FlexboxContainer>
        <FlexboxContainer justifyContent="space-between" padding={`${Space.sp2x} 0 0`}>
          <span className="base">Available</span>
          <span className="base">{isConnected ? `${currencyFormatter.format(creditInfo.availableCreditLimit)}` : 'n/a'}</span>
        </FlexboxContainer>
      </FlexboxContainer>
      {isConnected && parseInt(value) > 0 && parseInt(value) !== creditInfo.totalCreditLimit ? creditButtons : null}
    </FlexboxContainer>
  );
};

CreditCheckCard.defaultProps = {
  className: ''
};

export default CreditCheckCard;
