import React from 'react';
import RadioButton from './RadioButton';

interface Props {
  className?: string;
  options: Object;
  selectedOption: string;
  isDisabled?: boolean;
  color?: string;
  setSelectedOption: (value: string) => void;
}

const RadioList = ({
  className,
  options,
  selectedOption,
  isDisabled = false,
  color,
  setSelectedOption,
}: Props) => {
  return (
    <>
      {Object.values(options).map((option, index) => (
        <RadioButton
          key={index.toString()}
          id={index.toString()}
          name={Object.keys(options)[index]}
          label={option}
          value={Object.keys(options)[index]}
          checked={selectedOption === Object.keys(options)[index]}
          onChangeValue={setSelectedOption}
          disabled={isDisabled}
          color={color}
        />
      ))}
    </>
  );
};

RadioList.defaultProps = {
  className: '',
};

export default RadioList;
