let stashes: Record<string, any> = {};

export function clearStashes(): void {
  stashes = {};
}

export function getStash<T>(key: string): T | undefined {
  return stashes[key];
}

export function setStash<T>(key: string, value: T): void {
  stashes[key] = value;
}

export function unsetStash(key: string): void {
  delete stashes[key];
}
