import React from 'react';
import { useAsync } from 'react-async';
import styled from 'styled-components';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { Color } from 'lib/styles';
import { getVideoThumbnail } from 'lib/brightcove';

const Container = styled.div`
  border: 1px solid ${Color.gray};
  height: 3.5rem;
  width: 5rem;
`;

const ThumbnailImage = styled.img<any>`
  object-fit: cover;
  height: ${({ productRedesignFlag }) => (productRedesignFlag ? '0' : '100%')};
  width: ${({ productRedesignFlag }) => (productRedesignFlag ? '0' : ' 100%')};
`;

interface Props {
  className?: string;
  policyKey: string;
  videoId: string;
  productRedesignFlag?: boolean;
}

export default function VideoThumbnail({
  className,
  policyKey,
  videoId,
  productRedesignFlag=false
}: Props) {
  const videoThumbnailReq = useAsync({
    promiseFn: getVideoThumbnail,
    policyKey,
    videoId
  });

  if (videoThumbnailReq.isLoading) {
    return <LoadingIndicator size={18} />;
  }

  return (
    <Container className={className}>
      <ThumbnailImage src={videoThumbnailReq.data} productRedesignFlag />
    </Container>
  );
}

VideoThumbnail.defaultProps = {};
