import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import Icon from 'view/Icon';
import Divider from 'components/UI/Divider';
import Pill from 'components/UI/Pill';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Color } from 'lib/styles';
import { useAccordion } from 'lib/useAccordion';
import { Background, Main } from 'styles/themeColors';

const Header = styled.div`
  display: flex;
`;

const AccordionTitle = styled.button`
  background-color: ${Background.white};
  color: ${Color.black};
  cursor: pointer;
  padding: 30px;
  width: 100%;
  transition: 0.4s;
  border-radius: 4px;
  font-size: 20px;
  font-weight: bolder;
`;

const AccordionWrapper = styled.div<any>`
  width: ${({ containerWidth }) => containerWidth};
`;

const SubLinks = styled.div`
  margin-left: 4px;
  border-left: 4px solid ${Main.accent};
  font-size: 20px;
`;

SubLinks.displayName = 'SubLinks'

const SubLinksContainer = styled.div<any>`
  height: ${(p) => p.height};
  overflow: hidden;
`;

const RightAlignedItemsContainer = styled.span<any>`
  width: 100px;
  display: flex;
  justify-content: space-between;
`;

interface Props {
  children: React.ReactNode;
  childrenLength?: string;
  className?: string;
  title: string;
  withPill?: boolean;
  pillTheme?: string;
  width?: string;
}

const Accordion = ({
  title,
  withPill,
  pillTheme,
  children,
  childrenLength,
  width
}: Props) => {
  const { height, toggleAccordion, ref } = useAccordion();

  let pill = withPill
    ? <Pill theme={pillTheme} size='s'>{childrenLength}</Pill>
    : null;

  return (
    <AccordionWrapper containerWidth={width ? width : '100%'}>
      <Header>
        <AccordionTitle onClick={toggleAccordion}>
          <FlexboxContainer justifyContent="space-between">
            {title}
            <RightAlignedItemsContainer>
              {pill}
              <Icon name="chevron-down" />
            </RightAlignedItemsContainer>
          </FlexboxContainer>
        </AccordionTitle>
      </Header>
      <SubLinksContainer>
        <animated.div style={{ height }}>
          <SubLinks ref={ref}>{children}</SubLinks>
        </animated.div>
      </SubLinksContainer>
      <Divider bgColor={Color.lightGray} />
    </AccordionWrapper>
  );
};

Accordion.defaultProps = {
  withPill: false,
  pillTheme: 'accent'
};

export default Accordion;
