import FlexboxContainer from 'components/UI/FlexboxContainer';
import React, { useState } from 'react';
import * as User from 'data/User';
import styled from 'styled-components';
import { Main, Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { useAuth } from 'lib/Auth';
import ConsignmentHistoryChange from 'data/ConsignmentHistoryChange';
import Pill from 'components/UI/Pill';
import Divider from 'components/UI/Divider';
import { SHIPPING } from 'constants/deliveryModes';
import { formatDateTime, getLocalTimezone } from 'utils/formatters';
import { getMappedDisplayState, getPillTheme } from 'utils/consignment';
import FeatureFlag from 'data/FeatureFlag';
import Button from 'components/UI/Button';

const Container = styled(FlexboxContainer)`
       flex-direction: row;
`;

// FlexboxContainer customization for each row
const Row = styled(FlexboxContainer)`
    flex-direction: row;
    padding: 4px 0;
`;

const Column = styled(FlexboxContainer)`
  margin-bottom: 0.8rem;
  flex-direction: column;
    `;

const Wrapper = styled.div`
   padding: 0 16px 16px 16px;
    `;

const Label = styled.span`
    min-width: 200px;
    font-size: ${Space.sp2x};
    line-height: 20px;
    color: ${Text.primary};
`;

const Value = styled.span`
    min-width: 200px;
    font-size: ${Space.sp2x};
    line-height: 20px;
    color: ${Text.primary};
`;


const PreviousValue = styled.span`
    text-decoration: line-through;
    color: ${Text.secondary}; // Lighter color for previous value
    font-size: ${Space.sp2x};

`;

const CurrentValue = styled.span`
    font-weight: bold;
    color: ${Text.primary}; // Normal color for current value
    font-size: ${Space.sp2x};

`;

const PrintButton = styled(Button)`
    margin-top: 16px;
`;


interface OrderHistoryItemProps {
    isVendor: boolean;
    item: ConsignmentHistoryChange;
    featureFlags: FeatureFlag
}

const OrderHistoryItem: React.FC<OrderHistoryItemProps> = ({ isVendor, item, featureFlags }) => {

    const {
        toConsignmentStatus: consignmentState,
        dowJonesStatus,
        createdOn,
        message,
        deliveryTypes,
        currentCarrierName,
        currentDeliveryDate,
        currentTracking,
        currentRentEndDate,
        currentRentStartDate,
        previousCarrierName,
        previousDeliveryDate,
        previousRentEndDate,
        previousRentStartDate,
        previousTracking,
    } = item || {};

    const { displayState } = getMappedDisplayState(consignmentState, isVendor, dowJonesStatus, featureFlags);

    const pillTheme = getPillTheme(consignmentState, dowJonesStatus, isVendor);

    const pill = <Pill margin="0 0 0 0" theme={pillTheme} bgColor={pillTheme} size="xsPlus" padding="4px">{displayState}</Pill>;

    const renderDateValue = (previous: any, current: any, formatter = (val: any, _: any) => val) => {
        return previous && previous !== current ? (
            <>
                {previous && <PreviousValue>{formatter(previous, true)} {getLocalTimezone()}</PreviousValue>}
                <br />
                {current && <CurrentValue>{formatter(current, true)} {getLocalTimezone()}</CurrentValue>}
            </>
        ) : (
            current && <Value>{formatter(current, true)} {getLocalTimezone()}</Value>
        );
    };


    const renderValue = (previous: any, current: any) => {
        return previous && previous !== current ? (
            <>
                {previous && <PreviousValue>{previous}</PreviousValue>}
                <br />
                {current && <CurrentValue>{current}</CurrentValue>}
            </>
        ) : (
            current && <Value>{current}</Value>
        );
    };


    return <>
        <Container>
            <Row>
                <Label>Status:</Label>
                <Value>{pill}</Value>
            </Row>
        </Container>

        <Container>
            <Row gap='14rem'>
                <Row>
                    <Label>Change date:</Label>
                    <Value>{formatDateTime(createdOn, true)} {getLocalTimezone()}</Value>
                </Row>
                {deliveryTypes === SHIPPING &&
                    <Row>
                        <Label>Carrier:</Label>
                        <Value>
                            {renderValue(previousCarrierName, currentCarrierName)}
                        </Value>
                    </Row>
                }
            </Row>
        </Container>

        <Container>
            <Row gap='14rem'>
                <Row>
                    <Label>{deliveryTypes === SHIPPING ? 'Tracking number:' : 'Reference number:'} </Label>
                    <Value>
                        {renderValue(previousTracking, currentTracking)}
                    </Value>
                </Row>
                <Row>
                    <Label>{deliveryTypes === SHIPPING ? 'Est. delivery:' : 'Est. pickup:'}</Label>
                    <Value>
                        {renderDateValue(previousDeliveryDate, currentDeliveryDate, formatDateTime)}
                    </Value>
                </Row>

            </Row>
        </Container>

        {currentRentStartDate && currentRentEndDate &&
            <Container>
                <Row gap='14rem'>
                    <Row>
                        <Label>Rent start date:</Label>
                        <Value>
                            {renderDateValue(previousRentStartDate, currentRentStartDate, formatDateTime)}
                        </Value>
                    </Row>
                    <Row>
                        <Label>Rent end date:</Label>
                        <Value>
                            {renderDateValue(previousRentEndDate, currentRentEndDate, formatDateTime)}
                        </Value>
                    </Row>
                </Row>
            </Container>
        }

        <Container>
            <Column>
                <Row>
                    <Label>Reason/Comments:</Label>
                    <Value>{message}</Value>
                </Row>
            </Column>
        </Container>
    </>
}

const UnderlinedButton = styled.button`
  background: none;
  border: none;
  color: ${Main.accent};
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: none;
  }
`;

interface OrderHistoryProps {
    consignmentHistoryChanges: ConsignmentHistoryChange[];
    consignmentState: string;
    featureFlags: FeatureFlag
}

const OrderHistory: React.FC<OrderHistoryProps> = (props) => {

    const { user } = useAuth();
    const isVendor = User.isVendor(user!);
    let { consignmentHistoryChanges, consignmentState, featureFlags } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    let mostRecentHistoryItem: ConsignmentHistoryChange | undefined;
    if (consignmentHistoryChanges[0]?.toConsignmentStatus === consignmentState) {
        // Remove the first element if it is the same as the current status
        consignmentHistoryChanges = consignmentHistoryChanges.filter((_, index) => index !== 0);

        if (consignmentHistoryChanges.length) mostRecentHistoryItem = consignmentHistoryChanges[0];
    } else {
        mostRecentHistoryItem = consignmentHistoryChanges[0];
    }

    if (!consignmentHistoryChanges.length) return null;


    return (
        <Wrapper>

            {!isExpanded && mostRecentHistoryItem && (
                <>
                    {/** Order progress section in collapsed state shows the most recent previous status of the order  */}
                    <OrderHistoryItem item={mostRecentHistoryItem!} isVendor={isVendor} key={mostRecentHistoryItem?.id} featureFlags={featureFlags} />
                    {consignmentHistoryChanges.length > 1 && <UnderlinedButton onClick={toggleIsExpanded}>Expand</UnderlinedButton>}
                </>
            )}

            {isExpanded && consignmentHistoryChanges.map((item, index) => (
                <React.Fragment key={item.id}>
                    <OrderHistoryItem item={item} isVendor={isVendor} featureFlags={featureFlags} />
                    {index !== consignmentHistoryChanges.length - 1 && (
                        <Divider height="1px" bgColor={'#C1C9D1'} marginY='10px' noMarginX />
                    )}
                    {consignmentHistoryChanges.length > 1 && index === consignmentHistoryChanges.length - 1 && (
                        <UnderlinedButton onClick={toggleIsExpanded}>Collapse</UnderlinedButton>
                    )}
                </React.Fragment>
            ))}

            <div id='order-progress-printable-content' style={{display:'none'}}>
                    {consignmentHistoryChanges.map((item, index) => (
                        <React.Fragment key={index}>
                            <OrderHistoryItem item={item} isVendor={isVendor} featureFlags={featureFlags} />
                            {index !== consignmentHistoryChanges.length - 1 && (
                                <Divider height="1px" bgColor={'#C1C9D1'} marginY='10px' noMarginX />
                            )}
                        </React.Fragment>
                    ))}
            </div>
        </Wrapper>
    );
};

export default React.memo(OrderHistory);
