import React, { useEffect, useRef, forwardRef } from 'react';
import MaterialTable, { Column, Query } from 'material-table';
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import Pagination from '../../UI/Pagination';
import { defaultPaginationSelectOptions } from '../../../constants/paginationOptions';
import { useAuth } from '../../../lib/Auth';
import { ProductListFilter } from './ProductListingFilters';
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined';
import { getAllProducts } from '../../../requests/product';
import ProductListingActions from './ProductListingActions';
import styled from 'styled-components';
import { navigate } from 'hookrouter';
import { PRODUCT_STATUS } from 'constants/product';

const CustomPaginationComponent = (props: any) => {
  const {
    page,
    rowsPerPage,
    count,
    onChangePage,
    onChangeRowsPerPage,
  } = props;
 
  const totalPages = Math.ceil(count / rowsPerPage);

  return (
    <td style={{ display: 'block' }}>
       <Pagination
        itemsPerPage={rowsPerPage}
        currentPage={page + 1}
        totalPages={totalPages > 0 ? totalPages : 1}
        shouldRedirect={false}
        selectorOptions={defaultPaginationSelectOptions}
        setItemsPerPage={(newRowsPerPage: number) => {
          onChangeRowsPerPage({ target: { value: newRowsPerPage } }); // Update rows per page
        }}
        setCurrentPage={(newPage: number) => {
          onChangePage(newPage);
        }}
        baseRoute="products"
      />
    </td>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#F7991C',
    },
    secondary: {
      main: '#F7991C',
    },
  },
});

const StyledButton = styled.button<any>`
  background: none;
  border: none;
  color: #FC780B;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  margin: 0 5px;
`;


const StyledTable = styled.div`
  span {
    &:focus {
      color: black !important;
    }
  }
`;


interface Props {
  selectedVendorId?: number;
  filters: ProductListFilter;
  searchTerm: string;
  productColumns: Column<any>[];
  setShowDeleteModal: Function;
  setShowUpdateModal: Function;
  setShowPublishModal: Function;
  setSelectedRows: Function;
  showDeleteModal: boolean;
  showPublishModal: boolean;
  showUpdateModal: 'none' | 'update' | 'revise';
  selectedRows: any[];
  reload: boolean;
  setReload: Function;
  setLoading: Function;
}
interface TableRef {
  onQueryChange: () => void;
}

const ProductListingTable = ({
  selectedVendorId, filters, searchTerm, productColumns, setShowDeleteModal, setShowUpdateModal, showDeleteModal,
  showUpdateModal, setSelectedRows, selectedRows, showPublishModal, setShowPublishModal, reload, setReload, setLoading
}: Props) => {

  const tableRef = React.useRef<TableRef>(null);
  const { token } = useAuth();
  const [columns, setColumns] = React.useState(productColumns);
  const [selectedProduct, setSelectedProduct] = React.useState<any>();
  const [tablesData, setTablesData] = React.useState<any>({
    data: [],
    page: 1,
  });
  const [conditions, setConditions] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);

  // Ref to track if component is mounted
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    const actionColumn = {
      render: (rowData: any) => {
        return <div>
          <StyledButton onClick={() => { navigate(`/products/product-details/${rowData.id}`) }}>{rowData.status === PRODUCT_STATUS.DRAFT ? 'Edit' : 'View'}</StyledButton>
          {rowData.status === PRODUCT_STATUS.PUBLISHED && <StyledButton onClick={() => { setSelectedProduct(rowData.id); setShowUpdateModal('update') }}>Update</StyledButton>}
          {rowData.status === PRODUCT_STATUS.READY && <StyledButton onClick={() => { setSelectedProduct(rowData.id); setShowUpdateModal('revise') }}>Revise</StyledButton>}
        </div>
      }
    };

    const imageColumn = {
      render: (rowData: any) => {
        return (
          <>
            {rowData.imagePath !== null && <img src={process.env.REACT_APP_MEDIA_URL! + rowData.imagePath} alt={rowData.name} style={{ width: '30px', height: '30px', objectFit: 'contain', margin: '0px 0px -5px 0px' }} />}
          </>
        );
      }
    }

    const updatedColumns = columns.map((col) => {
      if (col.field === 'actions') {
        return { ...col, ...actionColumn };
      }

      if (col.field === 'imagePath') {
        const image = productColumns.find(x => x.title === 'Image');
        return { ...col, ...imageColumn, hidden: image?.hidden };
      }

      const column = productColumns.find((column) => column.field === col.field);
      if (column) {
        return {
          ...col,
          hidden: column.hidden,
        };
      } else {
        return col;
      }
    });
    setColumns([...updatedColumns]);
  }, [productColumns]);

  React.useEffect(() => {
    setTablesData((prevData: any) => ({
      ...prevData,
      page: 0,
    }));

    if (tableRef.current) {
      if (typeof tableRef.current.onQueryChange === 'function') {
        tableRef.current.onQueryChange();
      } else {
        console.error("onQueryChange method does not exist on tableRef.current");
      }
    }
  }, [filters, selectedVendorId, searchTerm, reload])

  const handleQuery = (query: Query<any>): string => {
    setTablesData((prevData: any) => ({
      ...prevData,
      page: query.page,
    }));

    let params = `page=${tablesData.page + 1}&pageSize=${query.pageSize}`;

    if (filters) {
      if (filters.publishStatus.id) {
        params += `&status=${filters.publishStatus.value.toLowerCase()}`;
      }
      if (filters.brand && filters.brand.length > 0) {
        params += `&brandIds=${filters.brand.map((brand) => brand.id).join(',')}`;
      }
      if (filters.vendor.id) {
        params += `&vendorId=${filters.vendor.id}`;
      }
      if (filters.country.id) {
        params += `&countryIso=${filters.country.id}`;
      }
    }

    if (selectedVendorId) {
      params += `&vendorId=${selectedVendorId}`;
    }

    if (searchTerm && searchTerm.trim() !== '') {
      params += `&search=${searchTerm}`;
    }

    if (query.orderBy) {
      params += `&sortField=${String(query.orderBy.field)}&sortOrder=${query.orderDirection}`;
    }

    const updatedColumns = columns.map((column) => {
      if (query.orderBy && column.field === query.orderBy.field) {
        return {
          ...column,
          defaultSort: query.orderDirection,
        };
      } else {
        return {
          ...column,
          defaultSort: undefined
        };
      }
    });
    setColumns(updatedColumns);

    return params;
  }

  const handleDeletion = (perform: boolean) => {
    if (perform) {
      setConditions('');
      setSelectedRows([]);
      if (tableRef.current) tableRef.current.onQueryChange();
    }
    setShowDeleteModal(false);
  }

  const handleProductUpdate = () => {
    setShowUpdateModal('none');
    setSelectedProduct(undefined);
  }

  const handleRowsPerPageChange = (newPageSize: number) => {
    const totalPages = Math.ceil(tablesData.totalCount / newPageSize);
    const lastPage = Math.min(tablesData.page, totalPages - 1);
  
    setRowsPerPage(newPageSize);
    setTablesData((prevData: any) => ({
      ...prevData,
      page: lastPage,
    }));
  
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange();
    }
  };

  const handlePageChange = (newPage: number) => {
    const totalPages = Math.ceil(tablesData.totalCount / rowsPerPage);
    const lastPage = Math.max(totalPages - 1, 0);
    const validPage = Math.min(newPage - 1, lastPage);
  
    setPage(validPage);
    setTablesData((prevData: any) => ({
      ...prevData,
      page: validPage,
    }));
  
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange();
    }
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <StyledTable>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            icons={
              {
                SortArrow: forwardRef((props, ref) => <KeyboardArrowDownOutlined {...props} ref={ref} />),
              }
            }
            data={(query) =>
              new Promise(async (resolve, reject) => {
                query.pageSize = rowsPerPage;
                query.page = page;

                const myQuery = handleQuery(query);

                if (conditions !== myQuery || reload) {
                  setConditions(myQuery);
                  try {
                    setLoading(true);
                    const result = await getAllProducts(myQuery, token);
                    if (isMounted.current) {
                      const tableData = {
                        data: result.data,
                        page: result.meta.currentPage - 1,
                        totalCount: result.meta.totalRecords,
                      };
                      setTablesData(tableData);
                      setReload(false);
                      resolve(tableData);
                    }
                  } catch (error) {
                    if (isMounted.current) {
                      console.error('API error:', error);
                      reject(error);
                    }
                  } finally {
                    setLoading(false);
                  }
                } else {
                  resolve(tablesData);
                }
              })
            }
            options={{
              sorting: true,
              draggable: false,
              showTitle: false,
              search: false,
              showTextRowsSelected: false,
              selection: true,
              selectionProps: {
                style: {
                  padding: '0px 14px'
                }
              },
              toolbar: false,
              pageSize: rowsPerPage,
              tableLayout: 'fixed',
              maxBodyHeight: 'calc(100vh - 200px)',
              emptyRowsWhenPaging: false,
              headerStyle: {
                color: '#FFF',
                fontSize: '16px',
                height: 50,
                padding: '0px 5px'
              },
              rowStyle: {
                fontSize: '14px',
                wordWrap: 'break-word',
              }
            }}
            components={{
              Pagination: (props) => {
                return <CustomPaginationComponent {...props} 
                  onChangeRowsPerPage={({ target }: any) =>
                    handleRowsPerPageChange(parseInt(target.value, 10))
                  }
                  onChangePage={(newPage: any) =>
                    handlePageChange(newPage)
                  }
                />;
              },
            }}
            onSelectionChange={(rows) => {
              setSelectedRows(rows);
            }}
          />
        </StyledTable>
      </MuiThemeProvider>

      <ProductListingActions
        showDeleteModal={showDeleteModal}
        selectedRows={selectedRows}
        onDelete={handleDeletion}
        showUpdateModal={showUpdateModal}
        onUpdate={handleProductUpdate}
        productId={selectedProduct}
        showPublishModal={showPublishModal}
        setShowPublishModal={setShowPublishModal}
        setSelectedRows={setSelectedRows}
        setReload={setReload}
      />
    </div>
  );
};

export default ProductListingTable;
