import { usePath } from "hookrouter";
import { useEffect } from "react";

let customPath : CustomPathInterface = { previousPath: '', currentPath: ''};

interface CustomPathInterface {
    previousPath: string;
    currentPath: string;
}

const usePathState = () : CustomPathInterface => {
    const path = usePath();

    useEffect(() => {
        customPath = { previousPath: customPath.currentPath, currentPath: path };
    }, [path]);

    return customPath;
};

export default usePathState;