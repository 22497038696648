export const COMPANY_TABS = [
  {
    value: 'General',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Orders',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Documents',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Pricebooks',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Sales Order',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  //   {
  //     value: 'Members',
  //     badge: {
  //       content: '',
  //       color: '',
  //       backgroundColor: ''
  //     }
  //   },
  //   {
  //     value: 'Agreements',
  //     badge: {
  //       content: '',
  //       color: '',
  //       backgroundColor: ''
  //     }
  //   },
  //   {
  //     value: 'Addresses',
  //     badge: {
  //       content: '',
  //       color: '',
  //       backgroundColor: ''
  //     }
  //   }
];

export const CUSTOMER_TABS = [
  {
    value: 'Info & Companies',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Compliance check',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  }
];

export const INVITE_TABS = [
  {
    value: 'Vendor',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Admin',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  }
];

export const POWER_BI_TABS = [
  {
    value: 'Order information',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Vendor overview',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  }
];

export const VENDOR_TABS = [
  {
    value: 'About Vendor',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Automation Settings',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Settings',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  }
];

export const VENDOR_SETTING_TABS = [
  {
    value: 'About Vendor',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Settings',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  }
];

export const BRAND_TABS = [
  {
    value: 'About Brand',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  },
  {
    value: 'Automation Setting',
    badge: {
      content: '',
      color: '',
      backgroundColor: ''
    }
  }
];
