import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Input from 'components/UI/Input';
import Modal from 'components/UI/Modal';
import ConfirmModal from 'components/UI/Modal';
import { renderToast } from 'components/UI/ToastNotification';
import { Size, Percent } from 'lib/styles';
import { fieldName } from 'utils/variant';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Space } from 'styles/themeSpaces';

const Content = styled.div`
  text-align: center;
`;

const Editor = styled.form`
  width: ${Percent.full};
  padding: 0 ${Size.medium} ${Size.medium};
`;

const Row = styled.div`
  align-items: center;
  display: flex;
  width: ${Percent.full};
  padding: ${Size.small} 0;
`;

const Column = styled.div`
  flex: 0 0 50%;
  padding: 0 ${Size.small};

  &:first-child() {
    padding-left: 0;
  }

  &:last-child() {
    padding-right: 0;
  }
`;

const Heading = styled.h2`
  flex: 0 0 50%;
  font-size: 18px;
  margin: 0;
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

const Original = styled.div``;

const UpdatedInput = styled(Input)``;

function buildState(attributes) {
  return attributes.map(attribute => ({
    original: attribute,
    new: attribute
  }));
};

export default function AttributeValueEditor({
  attributes: originalAttributes,
  attributeKey,
  selectedVendor,
  onClose,
  onSave,
  foundProductAttributeName,
  showModal
}) {
  const [attributes, setAttributes] = useState(buildState(originalAttributes));
  const [showSubmitModal, setShowSubmitModal] = useState(false);

  const changedAttributes = attributes.filter(
    attribute => attribute.original !== attribute.new
  );

  function onSubmit() {
    if (changedAttributes.length > 0 && selectedVendor) {
      onSave(changedAttributes);
    } else {
      renderToast(toast.TYPE.INFO, 'Please select the correct vendor for this product before you try to update the values.');
      onClose();
    }

    setShowSubmitModal(false);
  }

  const confirmSubmitModal = (
    <ConfirmModal
      showModal={showSubmitModal}
      onClose={() => setShowSubmitModal(false)}
      onClick={onSubmit}
      title="Update attribute value"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          {`This will update ${changedAttributes.length} attribute value. Would you like to continue?`}
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )

  return (
    <Modal
      showModal={showModal}
      showCloseIcon
      width={'700px'}
      onClose={onClose}
      onClick={() => setShowSubmitModal(true)}
      primaryButtonText='Save'
      showGhostButton={false}
      title={`Editing ${fieldName(foundProductAttributeName)} attribute`}
    >
      {confirmSubmitModal}
      <Content>
        <Editor onSubmit={onSubmit}>
          <Row>
            <Heading>Original value</Heading>
            <Heading>Updated value</Heading>
          </Row>
          {attributes.map((attribute, i) => {
            function updateAttribute(val) {
              const newAttributes = [...attributes];
              newAttributes[i] = { ...newAttributes[i], new: val };
              setAttributes(newAttributes);
            }

            return (
              <Row key={i}>
                <Column>
                  <Original>{attribute.original}</Original>
                </Column>
                <Column>
                  <UpdatedInput
                    id='update-attribute-id'
                    placeholder='Insert new value'
                    labelName="Update attribute"
                    labelHidden={true}
                    onInputChange={updateAttribute}
                    value={attribute.new}
                  />
                </Column>
              </Row>
            );
          })}
        </Editor>
      </Content>
    </Modal>
  );
};

AttributeValueEditor.propTypes = {
  attributes: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]).isRequired,
  attributeKey: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};
