import React, { useState } from 'react';
import styled from 'styled-components';
import Product, * as Prod from 'data/Product';
import Video from 'data/Video';
import PDPPreviewDetails from 'common/PDPPreviewDetails';
import PDPPreviewImages from 'common/PDPPreviewImages';
import PDPPreviewModal from 'common/PDPPreviewModal';
import PDPPreviewSelectors from 'common/PDPPreviewSelectors';
import VideoPlayModal from 'view/VideoPlayModal';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { Size, Percent } from 'lib/styles';
import { Main } from 'styles/themeColors';

const Breakpoints = {
  large: '1024px',
  small: '641px'
};

const Container = styled.div`
  font-size: 14px;
  margin: 0 auto;
  width: ${Percent.full};
  padding: 20px;
`;

const Breadcrumbs = styled.nav`
  margin-bottom: ${Size.medium};
`;

const Breadcrumb = styled.span<any>`
  color: ${({ active }) => (active ? Main.accent : 'inherit')};
  display: inline-block;

  &:not(:first-child)::before {
    content: '/';
    display: inline-block;
    padding: 0 0.5em;
  }
`;

const MainContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 100%;
  width: ${Percent.full};

  @media (min-width: ${Breakpoints.small}) {
    flex-basis: 50%;
    width: ${Percent.half};
  }

  @media (min-width: ${Breakpoints.large}) {
    flex-basis: calc((2 / 3) * 100%);
    width: calc((2 / 3) * 100%);
  }
`;

const PDPPreviewImagesContainer = styled.div`
  flex: 1 1 100%;
  width: ${Percent.full};

  @media (min-width: ${Breakpoints.small}) {
    flex-basis: 50%;
    width: ${Percent.half};
  }

  @media (min-width: ${Breakpoints.large}) {
    flex-basis: calc((1 / 3) * 100%);
    width: calc((1 / 3) * 100%);
  }
`;

const Column = styled.div`
  flex: 1 1 100%;
  margin-top: ${Size.medium};
  padding: 0 ${Size.medium};
  width: ${Percent.full};

  &:last-child {
    padding-right: 0;
  }

  @media (min-width: ${Breakpoints.small}) {
    &:first-child {
      margin-top: 0;
    }
  }

  @media (min-width: ${Breakpoints.large}) {
    flex-basis: calc((1 / 3) * 100%);
    margin-top: 0;
    width: calc((1 / 3) * 100%);
  }
`;

interface Props {
  loading: boolean;
  product: Product;
};

export default function PDPPreview({ loading, product }: Props) {
  const [selectedAttributes, setSelectedAttributes] = useState<Record<string, Prod.SelectedValue | null>>({});
  const [modalVideo, setModalVideo] = useState<Video | null>(null);

  if (loading) return <LoadingIndicator />;

  const {
    name,
    category,
    productImages,
    contextualImages,
    superCategory
  } = product;

  const matchingVariants = Prod.getVariantsWithAttributes(
    product,
    selectedAttributes
  );

  function updateSelect(event: any) {

    const index = event.target.selectedIndex;
    const optionElement = event.target.childNodes[index]
    const optionId: string = optionElement.getAttribute('id');
    const mappedAttributes = product.attributes.filter(el => el.isSelectable).map(attribute => ({ id: attribute.id, name: attribute.name }));

    const attrsBeforeIncludingSelected = {
      ...mappedAttributes
        .slice(
          0,
          mappedAttributes.map(el => el.id).indexOf(
            parseInt((event.target as HTMLInputElement).id)
          )
        )
        .reduce((obj: Record<string, Prod.SelectedValue | null>, attr) => {
          obj[attr.name] =
            selectedAttributes[attr.name] === undefined
              ? null
              : selectedAttributes[attr.name];
          return obj;
        }, {}),
      [(event.target as HTMLSelectElement)
        .name]: { id: parseInt(optionId), name: (event.target as HTMLInputElement).value }
    };

    const attrsAfterSelected = mappedAttributes
      .slice(
        mappedAttributes.map(el => el.id).indexOf(
          parseInt((event.target as HTMLInputElement).id)
        ) + 1
      )
      .reduce(
        (obj: Record<string, Prod.SelectedValue | null>, attr) => {
          const option = getOptions(attr.name, attrsBeforeIncludingSelected)[0];
          obj[attr.name] = option === undefined ? null : option;
          return obj;
        },
        {} as Record<string, Prod.SelectedValue>
      );

    setSelectedAttributes({
      ...attrsBeforeIncludingSelected,
      ...attrsAfterSelected
    });
  }

  function getOptions(
    selectableAttributeKey: string,
    currentSelectedAttributes: any
  ) {
    return Prod.getAttributeOptions(
      product,
      selectableAttributeKey,
      currentSelectedAttributes,
      product.attributes.filter(el => el.isSelectable)
    );
  }

  const price = Prod.getFormattedPrice(product, matchingVariants);
  const sku =
    matchingVariants.length === 1 ? matchingVariants[0].sku : product.code;

  const classificationAttributes =
    matchingVariants.length === 1
      ? Prod.getClassifications(product, matchingVariants[0])
      : {};

  const productImageUrls = productImages.map(el => el.path);
  const contextualImageUrls = contextualImages.map(el => el.path);

  let modalVideoEl = null;
  if (modalVideo) {
    modalVideoEl = (
      <VideoPlayModal
        showModal={modalVideo}
        name={modalVideo.name}
        onClose={() => setModalVideo(null)}
        videoId={modalVideo.videoId}
      />
    );
  }
  const logo = product.brand && product.brand.media && product.brand.media.length > 0 ? process.env.REACT_APP_MEDIA_URL + product.brand.media[0].path : undefined;

  return (
    <>
      {modalVideoEl}
      <PDPPreviewModal>
        <Container>
          <Breadcrumbs>
            <Breadcrumb>Home</Breadcrumb>
            <Breadcrumb>{superCategory ? superCategory.name : null}</Breadcrumb>
            <Breadcrumb>{category ? category.name : null}</Breadcrumb>
            <Breadcrumb active>{name}</Breadcrumb>
          </Breadcrumbs>
          <MainContent>
            <PDPPreviewImagesContainer>
              <PDPPreviewImages
                images={[...productImageUrls, ...contextualImageUrls]}
                onVideoClick={video => setModalVideo(video)}
                videos={product.videos}
              />
            </PDPPreviewImagesContainer>
            <ContentContainer>
              <Column>
                <PDPPreviewDetails
                  documents={product.documents}
                  logo={logo}
                  name={name}
                  price={price}
                  description={product.description || ''}
                  productDetails={{ sku, ...classificationAttributes }}
                />
              </Column>
              <Column>
                <PDPPreviewSelectors
                  deliveryLeadDays={product.deliveryLeadDays}
                  getOptions={getOptions}
                  onSelectChange={updateSelect}
                  attributes={product.attributes.filter(el => el.isSelectable)}
                  shipping={product.shipping}
                  selectedAttributes={selectedAttributes}
                  showQuantityAndCartButton={matchingVariants.length === 1}
                />
              </Column>
            </ContentContainer>
          </MainContent>
        </Container>
      </PDPPreviewModal>
    </>
  );
};
