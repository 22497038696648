export default interface WarehouseAddFormValidator {
    street: boolean;
    city: boolean;
    state: boolean;
    zip: boolean;
    nickname: boolean;
    name: boolean;
    warehouseId: boolean;
    sapPlantId?:boolean;
    salesOrg?:boolean;
    subSegment?:boolean;
    project?:boolean;
}

export const empty: WarehouseAddFormValidator = {
    street: false,
    city: false,
    state: false,
    zip: false,
    nickname: false,
    name: false,
    warehouseId: false,
    sapPlantId: false,
    salesOrg: false,
    subSegment: false,
    project: false
}