import styled from 'styled-components';

import { Card } from 'OnboardingComponents/Elements';
import { latoFont } from 'OnboardingComponents/Elements/Typography';

export const LeftNav = styled(Card)`
  margin: 0;
  padding-right: 0;
  background-color: ${({ theme }) => theme.greyThree};
  flex: 0 0 220px;
`;

export const NavList = styled.ul`
  list-style: none;
  display: block;
  padding: 0;
  margin-top: 1.714rem;
  color: black;

  & & {
    margin-left: 0.571rem;
  }
`;

export const NavItem = styled.li`
  display: list-item;
  color: black;
  padding: 0;
  margin-bottom: 1.714rem;
  margin-left: 0;
`;

export const StandardLink = styled.a`
  &&& {
    ${latoFont}
    color: ${({ theme }) => theme.textBlack};
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.blue};
    }
  }
`;

export const NavLink = styled(StandardLink)<{ active: boolean }>`
  &&& {
    font-weight: 700;
    color: ${({ active, theme }) => (active ? theme.blue : theme.textBlack) };
  }
`;
