import React, { CSSProperties, MouseEvent, MutableRefObject } from 'react';
import styled, { css } from 'styled-components';
import MaterialTable, { MTableToolbar, MTableBodyRow, Components } from 'material-table';
import { Paper } from '@material-ui/core';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import SearchBar from 'components/UI/Search';
import Switch from 'components/UI/Switch';
import Select, { Option } from 'components/UI/Select';
import Pagination from 'components/UI/Pagination';
import MatTableIcons from 'components/MaterialTable/MatTableIcons';
import MatTableBody from 'components/MaterialTable/MatTableBody';
import MatTableColumnsButton from 'components/MaterialTable/MatTableColumnsButton';
import { FontSize } from 'styles/themeSizes';
import { Text, Background, Border } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Height } from 'styles/themeHeights';
import ActionMenu, { ActionMenuItem } from '../UI/actionMenu';
import { emptyDataSourceExtraMessage, emptyDataSourceMainMessage } from '../../constants/errorMessages';

const TableWrapper = styled.div<any>`
  max-width: 100%;
  width: ${({ wrapperWidth }) => wrapperWidth};

  @media (min-width: 1400px) {
    width: 100%;
  }

  ${({ disableBorderCollapse }) => disableBorderCollapse && css`
    table {
      border-collapse: separate !important;
    }
 `}

  ${({ customHeaderBorders }) => customHeaderBorders && css`
    table thead tr th {
      border-right: none !important;
      padding: 4px 2px 4px 16px !important;
    }
  
    table thead tr{
      th:last-child{
        padding: 4px 0px 4px 16px !important;
        :after{
          display: none !important;
        }
      } 
    }
  
    table thead tr th:after {
      position: absolute;
      content: "";
      top: 3px;
      height: 24px;
      border: 1px solid #C1C9D1;
    }
  
    table thead tr th span{
      width: 100%
    }
 `}

  table tbody tr td {
    vertical-align: top !important;
    cursor: pointer;
    word-wrap: break-word;
    font-size: ${FontSize.medium};
    line-height: 20px;
    color: ${Text.primary};
    font-family: 'Lato', sans-serif !important;
  }

  table thead tr th span[tabindex='-1'] {
    visibility: hidden !important;
  }

  th {
    &:first-child {
      ${({ detailPanel }) => detailPanel && css`
        border-right: none !important;
      `}

      ${({ borderRadius }) => borderRadius && css`
        border-radius: ${BorderRadius.m} 0 0 0;
      `}
    }

    &:last-child {
      border-right: none !important;

      ${({ borderRadius }) => borderRadius && css`
        border-radius: 0 ${BorderRadius.m} 0 0;
      `}
    }
  }
`;

// Hide the arrow for orders with only 1 consignment
const StyledMTableBodyRow = styled(MTableBodyRow) <any>`
  ${({ $hideArrow }) => $hideArrow && css`
    div button.MuiButtonBase-root.MuiIconButton-root {
      visibility: hidden !important;
    }
  `}
  ${({ $highlightedRow }) => $highlightedRow && css`
    td {
      border-top: 1px solid ${Border.brand};
      border-bottom: none;

      &:first-child {
        border-left: 1px solid ${Border.brand};
      }

      &:last-child {
        border-right: 1px solid ${Border.brand};
      }
    }
  `}
`;

const headerStyle: CSSProperties = {
  padding: `${Space.spHalf} ${Space.spBase} ${Space.spHalf} ${Space.sp2x}`,
  textAlign: 'left',
  backgroundColor: Background.shaded,
  color: Text.primary,
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: FontSize.medium,
  lineHeight: '20px',
  borderRight: `1px solid ${Border.main}`,
  minHeight: `${Height.l}`
};

const rowStyle = (data: any, index: number) => {
  const hasMoreThanOneConsignments = data?.consignments?.length > 1;
  const showDetailPanel = data?.tableData?.showDetailPanel;
  const highlightedRow = hasMoreThanOneConsignments && showDetailPanel;

  const style = {
    padding: `${Space.sp2x} ${Space.spBase} ${Space.sp2x} ${Space.sp2x}`,
    borderBottom: `1px solid ${highlightedRow ? Border.brand : Border.light}`,
    borderTop: `1px solid ${highlightedRow ? Border.brand : Border.light}`,
    borderRight: highlightedRow && `1px solid ${Border.brand}`,
    borderLeft: highlightedRow && `1px solid ${Border.brand}`,
    fontSize: FontSize.medium,
    lineHeight: '20px',
    backgroundColor: highlightedRow ? Background.lightBrand : (index % 2 ? Background.light : Background.white),
    verticalAlign: 'top'
  };

  return style;
};

interface SwitcherProps {
  label: string;
  checked: boolean;
  onChange: () => void;
};

interface SelectProps {
  label: string;
  value: string;
  width?: string;
  options: Option[],
  onChange: (value: string) => void;
};

interface SearchProps {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
};

interface PaginationProps {
  itemsPerPage?: number;
  setItemsPerPage: Function;
  selectorOptions?: string[];
  currentPage: number;
  setCurrentPage: Function;
  totalPages: number;
  baseRoute: string;
  shouldRedirect?: boolean;
};

interface Props {
  columns: any[];
  data: any[];
  detailPanel?: any[];
  initialColumns?: any[];
  emptyDataSourceMessage?: string;
  emptyDataSourceHeader?: string;
  addRemoveColumns?: string;
  title?: string;
  wrapperWidth?: string;
  search?: boolean;
  paging?: boolean;
  sorting?: boolean;
  toolbar?: boolean;
  isLoading?: boolean;
  columnsButton?: boolean;
  draggable?: boolean;
  thirdSortClick?: boolean;
  overrideToolbar?: boolean;
  switchers?: SwitcherProps[];
  selectors?: SelectProps[];
  customSearch?: SearchProps;
  customPagination?: PaginationProps;
  tableRef?: MutableRefObject<any>;
  headerStyle?: CSSProperties;
  rowStyle?: CSSProperties;
  onRowClick?: (event?: MouseEvent<Element>, rowData?: any) => void;
  onOrderChange?: (orderedColumnId: number, orderDirection: string) => void;
  onColumnDragged?: (sourceIndex: number, destinationIndex: number) => void;
  onChangeColumnHidden?: (column: any, hidden: boolean) => void;
  onUpdateColumns?: (updatedColumns: any[]) => void;
  onToggleDetailPanel?: (path: number[], render: Function) => void;
  borders?: boolean;
  customHeaderBorders?: boolean;
  actionsColumnIndex?: number;
  actionMenu?: ActionMenuItem[];
  hideActionsTitle?: boolean;
  disableBorderCollapse?: boolean;
}

const MatTable = ({
  columns,
  data,
  detailPanel,
  emptyDataSourceMessage,
  emptyDataSourceHeader,
  addRemoveColumns,
  title,
  wrapperWidth,
  search,
  paging,
  sorting,
  toolbar,
  switchers,
  selectors,
  customSearch,
  customPagination,
  isLoading,
  columnsButton,
  draggable,
  headerStyle,
  rowStyle,
  tableRef,
  onRowClick,
  onOrderChange,
  onColumnDragged,
  onChangeColumnHidden,
  overrideToolbar,
  onUpdateColumns,
  onToggleDetailPanel,
  initialColumns,
  thirdSortClick,
  borders,
  customHeaderBorders,
  actionsColumnIndex,
  actionMenu,
  disableBorderCollapse,
}: Props) => {
    const options = {
    search,
    paging,
    sorting,
    showTitle: !!title,
    toolbar,
    headerStyle,
    rowStyle,
    columnsButton,
    draggable,
    thirdSortClick,
    detailPanelType: 'single' as 'single',
    ...(actionsColumnIndex !== undefined && { actionsColumnIndex }),
  };

  const localization = {
    body: {
      emptyDataSourceMessage
    },
    toolbar: {
      addRemoveColumns
    }
  };

  // Override the onToggleDetailPanel event, which is not exposed by material-table
  if (tableRef && tableRef.current && onToggleDetailPanel) {
    tableRef.current.onToggleDetailPanel = (path: number[], render: Function) => {
      // Original onToggleDetailPanel function
      tableRef.current.dataManager.changeDetailPanelVisibility(path, render);
      tableRef.current.setState(tableRef.current.dataManager.getRenderState());

      // Extended functionality
      onToggleDetailPanel(path, render);
    };
  };

  const searchBar = customSearch
    ? (
      <SearchBar
        placeholder={customSearch.placeholder}
        searchValue={customSearch.value}
        onSearch={customSearch.onChange}
        containerWidth="320px"
      />
    )
    : null;

  const switchComponent = switchers
    ? switchers.map((switcher, index) => (
      <Switch
        id={`custom-switch-${index}`}
        key={index}
        label={switcher.label}
        checked={switcher.checked}
        onChange={switcher.onChange}
      />
    ))
    : null;

  const selectComponent = selectors
    ? selectors.map((selector, index) => (
      <Select
        key={index}
        options={selector.options}
        value={selector.value}
        onChange={selector.onChange}
        width={selector.width || '100%'}
        containerWidth={'auto'}
      />
    ))
    : null;

  const tableColumnsButton = initialColumns && onUpdateColumns
    ? (
      <MatTableColumnsButton
        columns={columns}
        onUpdateColumns={onUpdateColumns}
        initialColumns={initialColumns}
      />
    )
    : null;

  const customToolbar = (
    <FlexboxContainer
      justifyContent={customSearch ? 'space-between' : 'flex-end'}
      padding={Space.spBase}
      alignItems="center"
      bgColor={Background.shaded}
      borderRadius={`${BorderRadius.m} ${BorderRadius.m} 0 0`}
    >
      {searchBar}
      <FlexboxContainer justifyContent="flex-end" alignItems="center">
        {switchComponent}
        {tableColumnsButton}
        {selectComponent}
      </FlexboxContainer>
    </FlexboxContainer>
  );

  const components: Components = {
    OverlayLoading: () => <LoadingIndicator />,
    Body: (props: any) => {
      const newProps = {
        ...props,
        emptyDataSourceHeader
      };
      return <MatTableBody {...newProps} />;
    },
    Container: (props: any) => <Paper {...props} elevation={0} />,
    Toolbar: (props: any) => overrideToolbar ? customToolbar : <MTableToolbar {...props} />,
    Row: (props: any) => (
      <StyledMTableBodyRow
        {...props}
        $hideArrow={props.data.consignments ? props.data.consignments.length === 1 : false}
        $highlightedRow={props?.data?.tableData?.showDetailPanel && props?.data?.consignments?.length > 1}
      />
    ),
    ...(actionMenu && {Action: ({data}: any) => <ActionMenu {...{actionMenu, data}}/>})
  };

  return (
    <TableWrapper
      borderRadius={!toolbar && !customToolbar}
      wrapperWidth={wrapperWidth}
      detailPanel={detailPanel}
      customHeaderBorders={customHeaderBorders}
      disableBorderCollapse={disableBorderCollapse}
    >
      <MaterialTable
        style={borders ? {border: "2px solid #E1E6EB"} : {}}
        columns={columns}
        data={data}
        {...(actionMenu && {actions: [{} as any]})}
        detailPanel={detailPanel}
        title={title}
        icons={MatTableIcons}
        isLoading={isLoading}
        options={options}
        localization={localization}
        components={components}
        tableRef={tableRef}
        onRowClick={onRowClick}
        onOrderChange={onOrderChange}
        onColumnDragged={onColumnDragged}
        onChangeColumnHidden={onChangeColumnHidden}
      />
      {customPagination ? <Pagination {...customPagination} /> : null}
    </TableWrapper>
  )
};

MatTable.defaultProps = {
  search: false,
  paging: false,
  sorting: true,
  toolbar: false,
  isLoading: false,
  columnsButton: false,
  draggable: false,
  thirdSortClick: false,
  overrideToolbar: false,
  emptyDataSourceMessage: emptyDataSourceExtraMessage,
  emptyDataSourceHeader: emptyDataSourceMainMessage,
  addRemoveColumns: 'VIEW COLUMNS',
  wrapperWidth: '100%',
  headerStyle,
  rowStyle,
  disableBorderCollapse: false
};

export default React.memo(MatTable);
