import Product, * as Prod from 'data/Product';

export interface ProductValidationTier {
  priceStart: number;
  recommendations: ProductValidationTierRequirements;
  requirements: ProductValidationTierRequirements;
}

export interface ProductValidationBoolean {
  value: boolean;
}

export interface ProductValidationMinMax {
  min: number;
  max?: number;
}

export interface ProductValidationTierRequirements {
  description: ProductValidationMinMax;
  documents: ProductValidationMinMax;
  productImages: ProductValidationMinMax;
  contextualImages: ProductValidationMinMax;
  name: ProductValidationMinMax;
  videos: ProductValidationMinMax;
  brand: ProductValidationMinMax;
  sku: ProductValidationMinMax;
  category: ProductValidationMinMax;
  superCategory: ProductValidationMinMax;
}

export function getProductValidationTier(
  product: Product
): ProductValidationTier {
  const maxPrice = Prod.getMaxPrice(product) || 0;
  const sortedTiers = productValidationTiers.sort(
    (a, b) => b.priceStart - a.priceStart
  );
  return (
    sortedTiers.find(tier => maxPrice >= tier.priceStart) ||
    sortedTiers[sortedTiers.length - 1]
  );
}

const productValidationTiers: ProductValidationTier[] = [
  {
    priceStart: 1000,
    recommendations: {
      description: { min: 100 },
      documents: { min: 2 },
      productImages: { min: 5 },
      contextualImages: { min: 0 },
      name: { min: 1, max: 5 },
      videos: { min: 1 },
      brand: { min: 1 },
      sku: { min: 1 },
      category: { min: 1 },
      superCategory: { min: 1 },
    },
    requirements: {
      description: { min: 10 },
      documents: { min: 0 },
      productImages: { min: 1 },
      contextualImages: { min: 0 },
      name: { min: 1 },
      videos: { min: 0 },
      brand: { min: 1 },
      sku: { min: 1 },
      category: { min: 1 },
      superCategory: { min: 1 },
    }
  },
  {
    priceStart: 151,
    recommendations: {
      description: { min: 60 },
      documents: { min: 2 },
      productImages: { min: 3 },
      contextualImages: { min: 0 },
      name: { min: 1, max: 5 },
      videos: { min: 1 },
      brand: { min: 1 },
      sku: { min: 1 },
      category: { min: 1 },
      superCategory: { min: 1 },
    },
    requirements: {
      description: { min: 1 },
      documents: { min: 0 },
      productImages: { min: 1 },
      contextualImages: { min: 0 },
      name: { min: 1 },
      videos: { min: 0 },
      brand: { min: 1 },
      sku: { min: 1 },
      category: { min: 1 },
      superCategory: { min: 1 },
    }
  },
  {
    priceStart: 0,
    recommendations: {
      description: { min: 30 },
      documents: { min: 1 },
      productImages: { min: 2 },
      contextualImages: { min: 0 },
      name: { min: 1, max: 5 },
      videos: { min: 0 },
      brand: { min: 1 },
      sku: { min: 1 },
      category: { min: 1 },
      superCategory: { min: 1 },
    },
    requirements: {
      description: { min: 1 },
      documents: { min: 0 },
      productImages: { min: 1 },
      contextualImages: { min: 0 },
      name: { min: 1 },
      videos: { min: 0 },
      brand: { min: 1 },
      sku: { min: 1 },
      category: { min: 1 },
      superCategory: { min: 1 },
    }
  }
];

export default productValidationTiers;
