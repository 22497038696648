import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Checkbox from "components/UI/Checkbox";
import Input from 'components/UI/Input';
import EditorSection from 'view/EditorSection';
import { Main } from 'styles/themeColors';
import 'react-toastify/dist/ReactToastify.css';
import Vendor from 'data/Vendor';
import Brand from 'data/Brand';
import { Color } from 'lib/styles';
import FeatureFlag from 'data/FeatureFlag';
import { useAuth } from 'lib/Auth';
import { USER_ROLE } from '../../constants/warehouseDeliveryOptions';

interface Props {
  brand: Brand;
  selectedVendor: Vendor;
  setBrand: (brand: Brand) => void;
  featureFlags: FeatureFlag
}

const CheckboxContainer = styled.div`
    margin-top: 20px;
`;

const Description = styled.p`
    margin: 0;
`;
const Heading = styled.h6`
  color: ${Color.black};
  font-weight: normal;
  margin-top:20px;
`;


const AutomationSettingTab: React.FC<Props> = ({ brand, setBrand, selectedVendor, featureFlags }) => {
  const { user } = useAuth();

  // validate user on the basis of role
  const hasValidUserRole = (user?.role === USER_ROLE.SuperAdmin || user?.role === USER_ROLE.VendorAdmin || user?.role === USER_ROLE.Admin);

  const onChangeMaterialApi = (isMaterialApiEnabled: boolean) => {

    if (!isMaterialApiEnabled) {
      setBrand({ ...brand, authenticationUrl: '', baseUrl: '', consumerKey: '', consumerSecret: '', isStockApiEnabled: false, isMaterialApiEnabled })
    }
    else {
      setBrand({ ...brand, isMaterialApiEnabled });
    }

  }
  const onChangeStockApi = (isStockApiEnabled: boolean) => {

    setBrand({ ...brand, isStockApiEnabled });

  }

  const onChangeSalesOrderApi = (isSalesOrderApiEnabled: boolean) => {
    setBrand({ ...brand, isSalesOrderApiEnabled });
  }

  return (
    <FlexboxContainerComponent flexDirection="column">
      {selectedVendor?.isMaterialApiEnabled && <div>
      <CheckboxContainer>
        <Checkbox
          id="material-api-check"
          name="isMaterialApiEnabled"
          // disabled={disabled}
          label="Material API"
          appearance="none"
          checkboxSize="s"
          checked={brand.isMaterialApiEnabled}
          onChange={onChangeMaterialApi}
          theme={'brandLight'}
          bgColor={Main.brandLight}
        />
        <Description>
          This API helps you stay organized by tracking all the products used by your company via unique identifier, SKU number. However, if you prefer, you can still revert to the manual process by deselecting the same checkbox.
        </Description>
      </CheckboxContainer>
      { (featureFlags?.stocks && featureFlags.stocks.enabled && selectedVendor.isStockApiEnabled) ? 
      <CheckboxContainer>
        <Checkbox
          id="stock-api-check"
          name="isStockApiEnabled"
          disabled={!brand.isMaterialApiEnabled}
          label="Stock API"
          appearance="none"
          checkboxSize="s"
          checked={brand.isStockApiEnabled}
          onChange={onChangeStockApi}
          theme={'brandLight'}
          bgColor={Main.brandLight}
        />
        <Description>
          This API helps you automate your inventory management. However, if you prefer, you can still revert to the manual process by deselecting the same checkbox.        </Description>
      </CheckboxContainer> : null }

      {!selectedVendor?.isSlbVendor && (
        <><Heading>Authentication Setting</Heading>     
        <EditorSection noBorder={true}>
          <EditorSection countType="item" noBorder={true}>
            <FlexboxContainerComponent justifyContent="space-between">
              <Input
                id='client-id'
                labelName="Client id"
                placeholder="123456"
                disabled={!brand.isMaterialApiEnabled}
                displayAsterisk={true}
                width="40%"
                value={brand.consumerKey}
                onInputChange={(consumerKey) => setBrand({ ...brand, consumerKey })}
              />
              <div style={{ width: '30px' }} />
              <Input
                id='client-secret'
                labelName="Client Secret"
                placeholder="fgi1@ys9"
                disabled={!brand.isMaterialApiEnabled}
                displayAsterisk={true}
                width="40%"
                value={brand.consumerSecret}
                onInputChange={(consumerSecret) => setBrand({ ...brand, consumerSecret })}
              />
              <div style={{ width: '30px' }} />
              <Input
                id='authentication-url'
                labelName="Authentication URL"
                placeholder="https://"
                disabled={!brand.isMaterialApiEnabled}
                displayAsterisk={true}
                width="40%"
                value={brand.authenticationUrl}
                onInputChange={(authenticationUrl) => setBrand({ ...brand, authenticationUrl })}
              />
            </FlexboxContainerComponent>
          </EditorSection>

          <EditorSection countType="item" noBorder={true}>
            <FlexboxContainerComponent justifyContent="space-between">
              <Input
                id='base-url'
                labelName="Base URL"
                placeholder="https://"
                disabled={!brand.isMaterialApiEnabled}
                displayAsterisk={true}
                width="32%"
                value={brand.baseUrl}
                onInputChange={(baseUrl) => setBrand({ ...brand, baseUrl })}
              />
            </FlexboxContainerComponent>
          </EditorSection>
        </EditorSection></>
      )}
      </div>}

      {hasValidUserRole && featureFlags?.salesOrder && featureFlags?.salesOrder.enabled && selectedVendor.isSalesApiEnabled && <div style={{marginBottom: '20px'}}>
        <Checkbox
          id="saleorder-api-check"
          name="isSalesApiEnabled"
          // disabled={disabled}
          label="Sales Order API"
          appearance="none"
          checkboxSize="s"
          checked={brand.isSalesOrderApiEnabled}
          onChange={onChangeSalesOrderApi}
          theme={'brandLight'}
          bgColor={Main.brandLight}
        />
        <Description>
          This API helps you automate sales order creation. However, if you prefer, you can still revert to the manual process by deselecting the same checkbox.
        </Description>
      </div>}
    </FlexboxContainerComponent>
  );
};

export default AutomationSettingTab;
