const htmlInnerContentSelectReg = /<[^>]*>/g;

export const doesHtmlHaveContent = (html: string): boolean => !!html.replace(htmlInnerContentSelectReg, '').length;

type SeparatorProps = {
  char: string,
  oneWhiteSpace?: boolean,
  oneSpaceBeforeChar?: boolean,
}

export const createCharacterSeparator = (props: SeparatorProps): (str: string) => string => {
  const {
    char,
    oneWhiteSpace,
    oneSpaceBeforeChar
  } = props
  const pattern = new RegExp(`(?<=\\S)${char}(?=\\S)`, 'g');
  const oneSpaceReplaceValue = oneSpaceBeforeChar ? ` ${char}` : `${char} `;
  const replaceValue = oneWhiteSpace ? oneSpaceReplaceValue :` ${char} `
  return (str: string): string => str.replace(pattern, replaceValue);
}