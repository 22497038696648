import React from 'react';
import styled, { css } from 'styled-components';
import { DateRange } from '@material-ui/icons';
import { Color, BorderRadius } from 'lib/styles';
import { Background, Border} from 'styles/themeColors';

const DatePickerContainer = styled.div<any>`
  display: flex;
  align-items: center;
  border: 1px solid ${Border.main};
  border-radius: ${BorderRadius.medium};
  background-color: ${Background.white};
  padding: 0 10px 0 0;

  ${({ disabled }) => disabled && css`
    background-color: ${Color.lightGray};
  `}

  ${({ error }) => error && css`
    border-color: ${Color.error};
  `}
`;

const DatePicker = styled.input<any>`
  font-size: 1em;
  padding: 0.5rem;
  width: 100%;
  height: 34px;
  border: none;
  background-color: ${Background.white};
  border-radius: ${BorderRadius.medium};

  &:focus {
    border: none;
  }

  ${({ disabled }) => disabled && css`
    background-color: ${Color.lightGray};
  `}
`;

interface Props {
  value?: any;
  onClick?: any;
  onChange?: any;
  disabled?: any;
  showIcon?: boolean;
  error?: any;
  placeholderText?: string;
};

const DatePickerInput = ({
  value,
  onClick,
  onChange,
  disabled,
  showIcon = true,
  error = false,
  placeholderText = 'Select date'
}: Props) => {

  return (
    <DatePickerContainer onClick={onClick} disabled={disabled} error={error}>
      <DatePicker
        type="text"
        value={value}
        onChange={onChange}
        readOnly={!onChange}
        disabled={disabled}
        placeholder={placeholderText}
      />
      { showIcon && <DateRange style={{ color: '#212529' }} />}
    </DatePickerContainer>
  );
};

export default DatePickerInput;
