import React from 'react';
import styled, { css } from 'styled-components';
import selectdropdownIcon from 'images/selectdropdown-icon.svg';
import { ProductAttribute, SelectedValue } from 'data/Product';
import { Color, FontSize, Percent } from 'lib/styles';
import { Background, Main } from 'styles/themeColors';

const AttributeSet = styled.div`
  margin: 0 0 20px;
`;

const AttributeLabel = styled.label`
  font-size: ${FontSize.smaller};
  margin-bottom: 5px;
  display: block;
`;

const BaseAttributeInputStyles = css`
  font-size: 15px;
  appearance: none;
  padding: 10px 14px;
  width: ${Percent.full};
  background-color: ${Background.white};
  position: relative;
  border-radius: 0;
  border: 1px solid ${Color.darkerGray};

  &[disabled] {
    opacity: 0.5;
    background-color: ${Color.whiteSmoke};
  }
`;

const AttributeInput = styled.input`
  ${BaseAttributeInputStyles}
`;

const AttributeSelect = styled.select`
  ${BaseAttributeInputStyles}
  background-image: url(${selectdropdownIcon});
  background-repeat: no-repeat;
  background-position: right 15px center;
  padding-right: 38px;
`;

const AddToCartBtn = styled.button`
  padding: 10px;
  margin: 0 0 10px;
  background: ${Main.success};
  color: ${Background.white};
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 1.375;
  width: ${Percent.full};
  border: none;
  cursor: pointer;
`;

const ShippingInfo = styled.div`
  font-size: ${FontSize.smaller};
`;

function QuantityAndCartButton({
  deliveryLeadDays,
  shipping
}: Pick<Props, 'deliveryLeadDays' | 'shipping'>) {
  function getLeadDaysMessage() {
    if (!deliveryLeadDays) {
      return null;
    }

    const pickupShipText = shipping ? 'to ship' : 'for pickup';

    if (deliveryLeadDays >= 30) {
      return `If not in stock, usually ready ${pickupShipText} in ${Math.ceil(
        deliveryLeadDays / 30
      )} month(s) or less.`;
    } else if (deliveryLeadDays >= 7) {
      return `If not in stock, usually ready ${pickupShipText} in ${Math.ceil(
        deliveryLeadDays / 7
      )} week(s) or less.`;
    }

    return `If not in stock, usually ready ${pickupShipText} in ${deliveryLeadDays} day(s).`;
  }

  let deliveryMessage = null;

  if (shipping) {
    deliveryMessage = (
      <>
        <div>Delivery in Lower 48 adds 4 to 8 days.</div>
        <br />
        <div>Price includes shipping.</div>
      </>
    );
  }

  return (
    <>
      <AttributeSet>
        <AttributeLabel htmlFor="quantity">Quantity</AttributeLabel>
        <AttributeInput
          type="number"
          id="quantity"
          name="quantity"
          defaultValue="1"
          min="1"
          autoComplete="off"
        />
      </AttributeSet>
      <AddToCartBtn>Add to cart</AddToCartBtn>
      <ShippingInfo>
        <div>{getLeadDaysMessage()}</div>
        {deliveryMessage}
      </ShippingInfo>
    </>
  );
};

interface Props {
  deliveryLeadDays: number | null;
  getOptions: (a: string, b: Record<string, any>) => SelectedValue[];
  onSelectChange: (event: React.SyntheticEvent<HTMLSelectElement>) => void;
  attributes: ProductAttribute[];
  shipping: boolean;
  selectedAttributes: Record<string, SelectedValue | null>;
  showQuantityAndCartButton: boolean;
};

const PDPPreviewSelectors = ({
  deliveryLeadDays,
  getOptions,
  onSelectChange,
  attributes,
  shipping,
  selectedAttributes,
  showQuantityAndCartButton
}: Props) => {
  function renderOptions(selectableAttribute: any) {
    return getOptions(selectableAttribute.name, selectedAttributes).map(
      (option, i) => (
        <option key={option.id} id={option.id + ""} value={option.name}>
          {option.name}
        </option>
      )

    );
  }

  let selectableAttributeInputs = null;
  if (attributes.length > 0) {
    selectableAttributeInputs = attributes.map(
      (selectableAttribute, i) => {
        const options = renderOptions(selectableAttribute);
        const isDisabled =
          options.length <= 1 ||
          (selectedAttributes[selectableAttribute.name] === undefined && i > 0);

        if (options.length === 0) {
          return null;
        }
        const selectedValue: SelectedValue | null =
          selectedAttributes[selectableAttribute.name] === null ||
            selectedAttributes[selectableAttribute.name] === undefined
            ? null
            : selectedAttributes[selectableAttribute.name];

        return (
          <AttributeSet key={i}>
            <AttributeLabel htmlFor={selectableAttribute.code}>
              {selectableAttribute.name}
            </AttributeLabel>
            <AttributeSelect
              id={selectableAttribute.id + ""}
              name={selectableAttribute.name}
              onChange={onSelectChange}
              value={selectedValue !== null ? selectedValue.name : ''}
              disabled={isDisabled}
            >
              <option value="" disabled>
                Select {selectableAttribute.name}
              </option>
              {options}
            </AttributeSelect>
          </AttributeSet>
        );
      }
    );
  }

  return (
    <>
      {selectableAttributeInputs}
      {showQuantityAndCartButton ? (
        <QuantityAndCartButton
          deliveryLeadDays={deliveryLeadDays}
          shipping={shipping}
        />
      ) : null}
    </>
  );
};

export default PDPPreviewSelectors;
