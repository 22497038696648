import { USER_CONFIG_TYPES } from 'constants/userConfiguration';
import Product from 'data/Product';
import UserConfiguration from 'data/UserConfiguration';
import {post, get, del} from 'lib/API';

export const getAllProducts = async (
  queryParams: string,
  token: any
) => {
  return await get(`v2/products?${queryParams}`, token);
}

export function deleteDraftProducts(
  productIds: number[],
  token: string
): Promise<any> {
  return del(`v2/products?productIds=${productIds.join(',')}`, token);
}

export const uploadProductForUser = async (
  [fileContent, fileName, productId, uploadDate]: any[],
  { token }: any
): Promise<any> => {
  return await post(`v2/products/variants/validate`, token, {
    fileContent,
    fileName,
    productId,
    uploadDate,
  });
};

export const getProductById = async (
    [productId]: any[],
    { token }: any
  ) => {
    return await get(`v2/products/variants/validate/${productId}`, token);
  };

  export const loadProductDetails = async (
    [productId]: any[],
    { token }: any
  ): Promise<Product>  => {
    const routePath = 'v2/products/details';
    return get(`${routePath}/${productId}`, token);
  };

  export const getTopProductBrands = async (
    [vendorId, limit]: any[],
    { token }: any
  ) => {
    let routePath = 'v2/products/top-brands?';
    if(vendorId) routePath = routePath.concat(`vendor=${vendorId}&`);
    if(limit) routePath = routePath.concat(`limit=${limit}`);
    return get(routePath, token);
  };
  
  export const getVariantsByProductId = async (
    [productId, paginationObj,]: any[],
    { token }: any
  ): Promise<Product>  => {
    const {currentPage,itemsPerPage , sortByHeader , skuForSearch, priceType}  = paginationObj
    const routePath = 'v2/variants';

    let sortingParams = '';
    if(sortByHeader?.header){
      sortingParams = `&sortField=${sortByHeader.header}&sortOrder=${sortByHeader.direction}`
      
      // if sorting is on productVariantPrices or attributeValues
      if(sortByHeader?.sortField && sortByHeader.sortField.includes('productVariantPrices') || sortByHeader.sortField.includes('attributeValues')){
        const [sortField, sortId] = sortByHeader.sortField.split('-');
        sortingParams = `&sortField=${sortField}&sortOrder=${sortByHeader.direction}&sortId=${sortId}`
      }
    }
    
   const skuSearch = skuForSearch ? `&sku=${skuForSearch}` : ''
   const priceTypeSearch = priceType ? `&priceType=${priceType}` : ''
   const pageSize = itemsPerPage === 'All' ? -1 : itemsPerPage
    return get(`${routePath}/${productId}?page=${currentPage}&pageSize=${pageSize}${sortingParams}${skuSearch}${priceTypeSearch}`, token);
  };

export const getColumnSettings = async (
  [data]: any[],
  { token }: any
) : Promise<UserConfiguration> => {
  const routePath = `v2/userConfiguration?type=${USER_CONFIG_TYPES.VARIANT}`;
  return get(routePath, token);
}

export const updateColumnSettings = async (
  [content]: any[],
  { token }: any
 ) : Promise<UserConfiguration> => {
  const routePath = `v2/userConfiguration?type=${USER_CONFIG_TYPES.VARIANT}`;
  return post(routePath, token, content);
}

