export enum Height {
  xxs = '8px',
  xxsPlus = '12px',
  xs = '16px',
  xsMedium = '18px',
  xsPlus = '20px',
  s = '24px',
  sPlus = '28px',
  mMinus = '32px',
  m = '36px',
  l = '40px',
  lPlus = '44px',
  xl = '48px',
  xlPlus = '52px',
  xxl = '56px',
  xxxl = '64px'
};
