import React from 'react';
import styled, { css } from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Height } from 'styles/themeHeights';
import { Border, Main, Background, Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { BorderRadius } from 'styles/themeBorderRadius';
import { LabelPosition } from 'types/position';
import { MainColors } from 'types/enumTypes';
import CheckIcon from 'images/check.svg';
import BlackCheckIcon from 'images/black-check.svg'

const Input = styled.input<any>`
  appearance: ${({ appearance }: Props) => appearance };
  border: 2px solid ${({ $error }) => $error ? Main.error : Border.main} !important;
  border-color: ${({ theme }: Props) => Main[theme]} !important;
  box-sizing: border-box;
  border-radius: ${BorderRadius.s};
  cursor: pointer;
  height: ${({ $size }) => $size};
  width: ${({ $size }) => $size};
  margin-top: ${({ $marginTop }) => $marginTop};
  background-color: ${Background.white};

  &:checked {
    background-color: ${({ $bgColor }) => $bgColor ? $bgColor : Main.accent};
    background-image: ${({ $checkIcon }) => $checkIcon ? `url(${BlackCheckIcon})` : `url(${CheckIcon})`}; 
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  &:disabled {
    background-color: ${Main.disabled};
  }
`;

const Label = styled.label<any>`
  color: ${({ $error }) => $error ? Main.error : Text.primary} !important;

  ${({ labelFontWeight }) => labelFontWeight && css`
    font-weight: ${labelFontWeight};
  `}
`;

const LabelDisabled = styled.label<any>`
  color: ${Text.disabled} !important;

  ${({ labelFontWeight }) => labelFontWeight && css`
    font-weight: ${labelFontWeight};
  `}
`;

interface Props {
  id: string;
  className?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  labelPosition?: LabelPosition;
  labelFontWeight?: string;
  appearance?: string;
  onChange: (checked: boolean) => void;
  checkboxSize?: 'xs' | 's' | 'm' | 'l';
  checkboxPosition?: 'top' | 'center' | 'bottom';
  errorOccurred?: boolean;
  theme: MainColors;
  bgColor?: string;
};

const Checkbox = ({
  id,
  className,
  name,
  value,
  checked,
  disabled,
  label,
  labelPosition,
  labelFontWeight,
  appearance,
  onChange,
  checkboxSize,
  checkboxPosition,
  errorOccurred,
  theme,
  bgColor,
}: Props) => {
  const checkSize = (checkboxSize === 'l')
    ? Height.l
    : (checkboxSize === 'm')
      ? Height.m
      :(checkboxSize === 's')
      ? Height.s
      : Height.xs;
  
  const checkPosition = (checkboxPosition === 'top')
    ? '-2rem'
    : (checkboxPosition ==='bottom')
      ? '2rem'
      : 'none';

  const labelStyle = labelPosition === 'left'
    ? { marginRight: `${Space.spHalfPlus}` }
    : { marginLeft: `${Space.spHalfPlus}` };


  const labelElement = disabled ? <LabelDisabled labelFontWeight={labelFontWeight} $error={errorOccurred} htmlFor={id} style={labelStyle} className="base">{label}</LabelDisabled> : <Label labelFontWeight={labelFontWeight} $error={errorOccurred} htmlFor={id} style={labelStyle} className="base">{label}</Label>;

  return (
    <FlexboxContainer
      alignItems="center"
      padding={label ? `${Space.spHalfPlus} ${Space.sp2x} ${Space.spHalfPlus} 0` : '0'}
      displayFlex="inline-flex"
      className='warehouse-select-box'
    >
      {label && labelPosition === 'left' ? labelElement : null}
      <Input
        $error={errorOccurred}
        $size={checkSize}
        $marginTop={checkPosition}
        id={id}
        className={className}
        name={name}
        value={value}
        checked={checked}
        $bgColor={bgColor}
        $checkIcon = {disabled}
        disabled={disabled}
        theme={theme}
        appearance={appearance}
        onChange={(e: any) => onChange(e.target.checked)}
        type="checkbox"
      />
      {label && labelPosition === 'right' ? labelElement : null}
    </FlexboxContainer>
  );
};

Checkbox.defaultProps = {
  className: '',
  checked: false,
  disabled: false,
  labelPosition: 'right',
  theme: 'accent'
};

export default Checkbox;
