import React from 'react';
import styled from 'styled-components';
import { Space } from 'styles/themeSpaces';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Text } from 'styles/themeColors';
import OrderConsignment from 'components/Orders/OrderConsignment'
import Consignment from 'data/Consignment';
import { currencySign } from 'utils/formatters';

interface Props {
  consignments: Consignment[];
  orderTotal: number;
  totalSurcharges: number;
  currencyIso: string;
};

const Heading = styled.h3`
  font-size: ${Space.sp3x};
  margin-top: 46px;
`;

const Header = styled.label<any>`
  text-align: ${({ $textAlign }) => $textAlign ? $textAlign : 'center'};
  width: ${({ $width }) => $width};
  border-bottom: 1px solid ${Text.primary};
  color: ${Text.primary};
`;

const Span = styled.span<any>`
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-style: ${({ $fontStyle }) => $fontStyle};
  color: ${({ $color }) => $color};
`;

const OrderConsignmentList = ({ orderTotal, totalSurcharges, consignments, currencyIso }: Props) => {
  console.log(currencyIso)
  return (
    <>
      <Heading>Split order details</Heading>
      <FlexboxContainer width='100%' flexDirection='column'>
        <FlexboxContainer width='100%' justifyContent='space-between' padding='15px'>
          <Header className='base' $width='50%' $textAlign='left'>Products</Header>
          <Header className='base' $width='15%'>Units</Header>
          <Header className='base' $width='15%'>Price/Unit</Header>
          <Header className='base' $width='15%'>Subtotal</Header>
        </FlexboxContainer>
        {
          consignments.map((consignment, key) => (
            <OrderConsignment key={key} consignment={consignment} currencyIso={currencyIso} />
          ))
        }
      </FlexboxContainer>
      {
        totalSurcharges
          ? (
            <FlexboxContainer width='100%' justifyContent='space-between' padding='20px 20px 0'>
              <Span $fontStyle='italic'>Includes surcharges</Span>
              <Span $fontStyle='italic'>+{currencySign(currencyIso,totalSurcharges)}</Span>
            </FlexboxContainer>
          )
          : null
      }
      <FlexboxContainer width='100%' justifyContent='space-between' padding='10px 20px 20px' margin='0 0 90px 0'>
        <Span $fontWeight='bold'>Split order total</Span>
        <Span $fontWeight='bold'>{currencySign(currencyIso,orderTotal)}</Span>
      </FlexboxContainer>
    </>
  );
};

export default OrderConsignmentList;
