

export default interface Campaign {
    id?: number;
    title: string;
    countryId: number| null;
    campaignTypeId: number | null;
    startDate?: string | null;
    endDate?: string | null;
    launchPerson?: string;
    campaignLaunchCompanyName: string;
    budget?: number;
    actualSpend?: number;
    eventId?: string;
    vendors?: number[];
  }

export const empty: Campaign =
{
  title: '',
  eventId: '',
  vendors: [],
  countryId: null,
  campaignTypeId: null,
  startDate: null,
  endDate: null,
  budget: undefined,
  actualSpend: undefined,
  campaignLaunchCompanyName: '',
  launchPerson: ''
}

export interface CampaignStats {
  activeCampaigns: number;
  campaignsBudget: number;
  launchCompanies: number;
  totalCampaigns: number;
}

