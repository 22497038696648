import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'hookrouter';
import { toast } from 'react-toastify';
import Keyword from 'data/Keyword';
import Brand, * as Br from 'data/Brand';
import Vendor from 'data/Vendor';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import ConfirmModal from 'components/UI/Modal';
import Page from 'components/UI/Page';
import { renderToast } from 'components/UI/ToastNotification';
import { Color } from 'lib/styles';
import { BorderRadius } from "styles/themeBorderRadius";
import { getStash } from 'lib/stash';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import {
    createBrand,
    loadBrand,
    saveBrand
} from 'requests/brand';
import { loadKeywords } from 'requests/keyword';
import { Space } from 'styles/themeSpaces';
import { Main, Background } from 'styles/themeColors';
import Tabs from 'components/UI/Tabs';
import AutomationSettingTab from './AutomationSettingTab';
import { BRAND_TABS } from 'constants/tabs';
import BrandConfigurationTab from './BrandConfigurationTab';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { mapBrandVendorToBrand } from 'utils/brand';
import FeatureFlag from 'data/FeatureFlag';
import { loadVendor } from 'requests/vendor';

const CancelButton = styled(Button)`
  margin-right: 5px;
  color: ${Color.black} !important;
  background-color: ${Background.white};
  border: 1px solid ${Color.veryLightGray} !important;
`;

const SaveButton = styled(Button)`
  padding: 10px;
  border-radius: ${BorderRadius.m};
  background-color: ${Main.brandDark};
  border-color: ${Main.disabled} !important;
`;


const Span = styled.span`
  margin: ${Space.spBase} 0;
`;


export interface Props {
    brandId?: string;
    selectedVendor: Vendor;
    vendorsList?: Vendor[];
    featureFlags: FeatureFlag;
    updateVendor: (name: string) => void;
};

const BrandDetails = ({ brandId, selectedVendor, vendorsList, updateVendor, featureFlags }: Props) => {

    const { token } = useAuth();
    const [keywordsList, setKeywordsList] = useState<Keyword[]>([]);
    const [brand, setBrand] = useState<Brand>({ id: 0, ...Br.empty });
    const [keywordsTags, setKeywordsTags] = useState<string[]>([]);
    const [showBrandCancelModal, setShowBrandCancelModal] = useState(false);
    const [brandTabs, setBrandTabs] = useState(BRAND_TABS);
    const [vendor, setVendor] = useState<Vendor>({ ...selectedVendor });

    const vendorReq = useAPI({ deferFn: loadVendor });

    // condition updated for vendor admin when there is no vendor list
    // const updatedSelectedVendor = vendorsList? vendorsList.find((vendor) => vendor.id === selectedVendor.id): selectedVendor;
    // const brandTabs = updatedSelectedVendor && updatedSelectedVendor?.isMaterialApiEnabled ? BRAND_TABS : [BRAND_TABS[0]];

    useEffect(() => {
        if (selectedVendor) {
            vendorReq.run(selectedVendor?.id);
        }
    }, [selectedVendor]);

    useEffect(() => {
        if (vendorReq.data) {
            setVendor(vendorReq.data);
            setBrandTabs((vendorReq.data.isMaterialApiEnabled || (featureFlags?.salesOrder && featureFlags?.salesOrder.enabled && vendorReq.data.isSalesApiEnabled)) ? BRAND_TABS : [BRAND_TABS[0]]);
            updateVendor(vendorReq.data.name);
        }
    }, [vendorReq.data]);
    
    const brandReq = useAPI<Brand>({
        deferFn: loadBrand,
        onResolve: (result) => {
            const brandVendor = mapBrandVendorToBrand(result);

            setBrand({...result, ...brandVendor});
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    const keywordsReq = useAPI({
        promiseFn: loadKeywords,
        onResolve: (result) => {
            setKeywordsList(result);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    const updateBrandReq = useAPI({
        deferFn: saveBrand,
        onResolve: () => {
            renderToast(toast.TYPE.SUCCESS, 'Brand updated.');
            navigate(previousLibraryPath);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        },
    });

    const createReq = useAPI({
        deferFn: createBrand,
        onResolve: () => {
            renderToast(toast.TYPE.SUCCESS, 'Brand created.');
            navigate(previousLibraryPath);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        },
    });

    
    const disabled = !!(
        !brand.name ||
        (!vendor?.isSlbVendor && brand?.isMaterialApiEnabled) && !brand.authenticationUrl ||
        (!vendor?.isSlbVendor && brand?.isMaterialApiEnabled) && !brand.consumerSecret ||
        (!vendor?.isSlbVendor && brand?.isMaterialApiEnabled) && !brand.consumerKey ||
        (!vendor?.isSlbVendor && brand?.isMaterialApiEnabled) && !brand.baseUrl ||
        updateBrandReq.isLoading || 
        createReq.isLoading
    );

    useEffect(() => {
        if (brandId) {
            brandReq.run(brandId);
        }
    }, []);

    useEffect(() => {
        if (selectedVendor) {
            setBrand({ ...brand, vendor: selectedVendor });
        }
    }, [selectedVendor]);

    useEffect(() => {
        if (brand) {
            setKeywordsTags(brand.keywords);
        }
    }, [brand && brand.keywords]);

    const saveClick = () => {
        if (!selectedVendor) {
            renderToast(toast.TYPE.INFO, 'Please select a vendor before you save your brand.');
            return;
        }

        if (brand.id === 0) {
            createReq.run(brand, selectedVendor.id);
        } else {
            updateBrandReq.run(brand, selectedVendor.id);
        }
    };

    const previousLibraryPath: string = getStash('brands') || '/brands';

    const onConfirmCancelBrandModalClick = () => {
        setShowBrandCancelModal(false);
        navigate('/brands');
    };

    const confirmBrandCancelModal = (
        <ConfirmModal
            showModal={showBrandCancelModal}
            onClose={() => setShowBrandCancelModal(false)}
            onClick={onConfirmCancelBrandModalClick}
            title="Are you sure you want to cancel?"
            primaryButtonText="Confirm cancel"
            ghostButtonText="Back"
        >
            <FlexboxContainer flexDirection="column">
                <Span>
                    Unsaved progress will be lost
                </Span>
            </FlexboxContainer>
        </ConfirmModal>
    )




    return (

        brandReq.isLoading || keywordsReq.isLoading
            ? <LoadingIndicator />
            :
            <Page bgColor={Background.white} width="85%">
                <Tabs theme='brandDark' margin='8px 24px 8px 0' height='auto' borderRadius='20px' tabPanelPadding='0 !important' tabs={brandTabs} activeTab={0}>
                    <BrandConfigurationTab brand={brand} selectedVendor={vendor} keywordsList={keywordsList} updateVendor={updateVendor} setBrand={setBrand} />
                    <AutomationSettingTab brand={brand} selectedVendor={vendor} featureFlags={featureFlags} setBrand={setBrand} />
                </Tabs>

                <FlexboxContainer width="100%" border="none">
                    <div style={{ border: 'none', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                        <CancelButton key="cancel" onClick={() => setShowBrandCancelModal(true)}>
                            Cancel
                        </CancelButton>
                        {confirmBrandCancelModal}
                        <SaveButton key="save" onClick={saveClick} disabled={disabled}>
                            Save
                        </SaveButton>
                    </div>
                </FlexboxContainer>
            </Page>
    );
};

export default BrandDetails;
