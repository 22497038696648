const API_URL = process.env.REACT_APP_API_URL;

export default {
  orders: {
    method: 'get',
    url: `${API_URL}/orders`,
    useQueryString: false,
    rootKey: 'orders',
  }
};
