// Component to add/edit campaign in a modal
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useAPI } from 'lib/API';
import { createCampaign, getCampaign, updateCampaign } from 'requests/campaigns';
import Modal from '../UI/Modal';
import { renderToast } from '../UI/ToastNotification';
import Campaign, { empty } from 'data/Campaign';
import { mapDataToCampaign } from 'utils/campaign';
import CampaignForm from './CampaignForm';
import Vendor from 'data/Vendor';
import { useCampaignTypes } from 'hooks/useCampaignTypes';
import { useLaunchCompanies } from 'hooks/useLaunchCompanies';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Icon from 'view/Icon';
import { STATUS, TEXT_LABELS } from 'constants/campaign';
import { useCampaignsEventIds } from 'hooks/useCampaignEventIds';

export interface UpsertCampaignModalProps {
  onClose: () => void;
  showModal: boolean;
  campaignId?: number;
  selectedVendorId?: number;
  vendorsList: Vendor[];
  handleModalOpen: () => void;
  handleRefresh: () => void;
  showCloseModal?: boolean;
  setShowModal?: (showModal: boolean) => void;
  onUpsertModalClose?: () => void;
}

const DownloadTemplate = styled.div`
  display: flex;
  height: 36px;
  background-color: transparent !important;
  color: #666c73 !important;
  border-color: #666c73 !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;

const ButtonIcon = styled(Icon).attrs({ size: 16 })`
  color: #666c73;
`;

const UpsertCampaignModal = ({ showModal, onClose, campaignId, selectedVendorId, vendorsList, handleModalOpen, handleRefresh, showCloseModal,onUpsertModalClose }: UpsertCampaignModalProps) => {

  const { campaignTypes, campaignTypeReq } = useCampaignTypes();
  const { launchCompanies, launchCompaniesReq } = useLaunchCompanies();
  const { eventIds = [], eventIdsReq } = useCampaignsEventIds();
  const [campaign, setCampaign] = useState<Campaign[]>([{
    ...empty
  }]);

  const campaignReq = useAPI({
    deferFn: getCampaign,
    onResolve: (result) => {
      if (result.data) {
        const initialCampaign = mapDataToCampaign(result.data);
        setCampaign([initialCampaign]);
      }

    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    if (selectedVendorId) {

      const initialCampaign = {
        ...empty,
        vendors: [selectedVendorId]
      };
      setCampaign([initialCampaign]);
    }
  }, [selectedVendorId]);

  useEffect(() => {
    if (campaignId) {
      campaignReq.run(campaignId);
    }

    return () => {
      campaignReq.cancel();
    }
  }, [campaignId]);

  const createReq = useAPI({
    deferFn: createCampaign,
    onResolve: (response) => {
      let status = response?.status;

      let type = toast.TYPE.SUCCESS;
      let message = 'Campaign[s] created.';
      if(status === STATUS.FAILED){
        type = toast.TYPE.ERROR;
        message = 'Campaign[s] creation failed. There were duplications in Event id.';
      }else if(status === STATUS.PARTIAL){
        type = toast.TYPE.WARNING;
        message = 'Campaign[s] created partially. There were duplications in Event id.';
      }
      renderToast(type, message);
      onUpsertModalClose?.();
      setCampaign([empty]);
      handleRefresh();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const updateReq = useAPI({
    deferFn: updateCampaign,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Campaign updated.');
      onUpsertModalClose?.();
      setCampaign([empty]);
      handleRefresh();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const onSave = (data: { campaigns: Campaign[] }) => {

    // If campaignId is present, then it is an update request
    if(campaignId){
      const payload = JSON.parse(JSON.stringify(data?.campaigns[0]));
      updateReq.run(payload);

    }

    // If campaignId is not present, then it is a create request
    if (!campaignId) {
      createReq.run(data);
    }
  }


  const isLoading = createReq.isLoading || updateReq.isLoading || campaignReq.isLoading || campaignTypeReq.isLoading || launchCompaniesReq.isLoading || eventIdsReq.isLoading;

  return (
    <Modal
      showModal={showModal}
      onClose={onClose}
      title=''
      width='720px'
      left='30vw'
      top="2vh"
      showButtons={false}
      visibility={!showCloseModal ? 'visible' : 'hidden'} // Hide form modal if confirm close modal is opened
    >
      <FlexboxContainerComponent flexDirection='row' justifyContent='space-between' alignItems='center'>
        <h4>{campaignId ? TEXT_LABELS.EDIT_EVENT : TEXT_LABELS.ADD_NEW_EVENTS}</h4>
        {!isLoading && !campaignId && <DownloadTemplate onClick={handleModalOpen} >
          <FlexboxContainerComponent>
            <ButtonIcon name="FileDownloadIcon" />
            <FlexboxContainerComponent>
              {TEXT_LABELS.IMPORT_XLSX}
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
        </DownloadTemplate>
        }
      </FlexboxContainerComponent>
      {isLoading ? <LoadingIndicator /> :
        <CampaignForm
          selectedVendorId={selectedVendorId}
          vendorsList={vendorsList}
          onSave={onSave}
          onClose={onClose}
          campaignTypes={campaignTypes}
          launchCompanies={launchCompanies}
          campaign={campaign?.[0] || empty}
          eventIds={eventIds}
          editMode={!!campaignId}
        />
      }


    </Modal>
  );
}

export default React.memo(UpsertCampaignModal);

