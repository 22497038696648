import React, { useState, useEffect } from 'react';
import { navigate } from 'hookrouter';
import styled from 'styled-components';
import * as User from 'data/User';
import VendorModal from 'admin/VendorModal';
import Button from 'components/UI/Button';
import Card from 'view/Card';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Slab from 'view/Slab';
import PageHeading from 'components/UI/PageHeading';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { Color, Size } from 'lib/styles';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { getVendorsWithDetails } from 'requests/vendor';
import { Background, Main } from 'styles/themeColors';

const Actions = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const EmptyLogo = styled.div`
  background-color: ${Color.darkGray};
  height: 64px;
  margin-right: ${Size.medium};
  padding: ${Size.tiny};
  width: 64px;
`;

const Header = styled.header`
  border-bottom: 1px solid ${Color.darkGray};
  display: flex;
  line-height: 1;
  margin: 0px auto;
  width: 100%;
  padding: ${Size.medium} 0px;
`;

const CreateButton = styled(Button)`
  display: inline-flex;
`;

const LinkSlab = styled(Slab)`
  margin: 0px ${Size.medium};
`;

const List = styled.ul`
  margin: 0px auto;
  width: 100%;
  padding: 0px;
`;

const NameSlab = styled(Slab)`
  flex-grow: 1;
`;

const Page = styled.div`
  margin: ${Size.large} 0px;
  width: 100%;
  padding: 0 30px;
`;

const VendorImage = styled.img`
  background-color: ${Background.white};
  height: 64px;
  margin-right: ${Size.medium};
  object-fit: contain;
  object-position: 50%;
  padding: ${Size.tiny};
  width: 64px;
`;

export default function VendorsList() {
  const [showVendorModal, setShowVendorModal] = useState(false);
  const [selectedVendorId, setSelectedVendorId] = useState<number | null>(null);
  const [shouldReload, setShouldReload] = useState(false);
  const { user, token } = useAuth();

  const vendorsReq = useAPI({ deferFn: getVendorsWithDetails });
  const isVendor = User.isVendor(user!);

  useEffect(() => {
    if (!isVendor) {
      vendorsReq.run([token]);
    }
  }, []);

  if (shouldReload) {
    vendorsReq.reload();
    setShouldReload(false);
  }

  const onEditVendorClick = (vendorId: number) => {
    navigate('/vendors/update/' + vendorId);
  }

  const onCloseVendorClick = () => {
    setShowVendorModal(false);
    setSelectedVendorId(null);
  }

  let vendorsList = null;
  let vendorModal = null;

  if (vendorsReq.isLoading) {
    vendorsList = <LoadingIndicator />;
  } else if (vendorsReq.data) {
    const vendorItems = vendorsReq.data.map(vendor => {
      const vendorLogo = vendor.media && vendor.media.length > 0
        ? <VendorImage alt={`${vendor.name} logo`} src={process.env.REACT_APP_MEDIA_URL + vendor.media[0].path} />
        : <EmptyLogo />;

      return (
        <Card key={vendor.id}>
          {vendorLogo}
          <NameSlab label="">{vendor.name}</NameSlab>
          <LinkSlab
            label="Draft"
            onClick={() => navigate('/products')}
          >
            {vendor.draftCount}
          </LinkSlab>
          <LinkSlab
            label="Ready to publish"
            onClick={() => navigate('/products/ready')}
          >
            {vendor.readyCount}
          </LinkSlab>
          <LinkSlab
            label="Published"
            onClick={() => navigate('/products/published')}
          >
            {vendor.publishedCount}
          </LinkSlab>
          <FlexboxContainer justifyContent="flex-end">
            <Button
              isGhost
              color={Main.accent}
              onClick={() => onEditVendorClick(vendor.id)}
            >
              Manage
            </Button>
          </FlexboxContainer>
        </Card>
      );
    });

    vendorsList = <List>{vendorItems}</List>;

    if (showVendorModal) {
      vendorModal = (
        <VendorModal
          showModal={showVendorModal}
          onClose={() => onCloseVendorClick()}
          vendors={vendorsReq.data}
          selectedVendorId={selectedVendorId}
          setShouldReload={setShouldReload}
        />
      );
    }
  }

  return (
    <Page>
      {vendorModal}
      <Header>
        <PageHeading>Vendors</PageHeading>
        <Actions>
          <CreateButton onClick={() => navigate('/vendors/create')}>Create Vendor</CreateButton>
        </Actions>
      </Header>
      {vendorsList}
    </Page>
  );
}
