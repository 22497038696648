import { Main } from 'styles/themeColors';
import Order from 'data/Order';
import { navigate } from 'hookrouter';
import { DOW_JONES_REVIEWED_BLOCKED, DOW_JONES_BLOCKED } from 'constants/dowJonesStatuses';
import { SHIPPING } from 'constants/deliveryModes';
import {
  CHECKING_CREDIT,
  ORDER_SENT_TO_OEM,
  READY,
  CANCELING,
  CANCELED,
  ON_HOLD,
  PROCESSING,
  MANUFACTURING,
  PACKING,
  SHIPPED_READY_FOR_PICKUP,
  READY_TO_SHIP,
  READY_FOR_PICKUP,
  DELIVERED_PICKED_UP,
  DELAYED,
  SHIPPED,
  PICKED_UP
} from 'constants/consignmentStates';

interface DowJonesBannerContent {
  theme: string;
  header: string;
  labels: string;
  button?: { value: string, onClick: () => void };
};

export const retrieveOrderDowJonesBannerContent = (order: Order): DowJonesBannerContent => {
  const theme = (order.dowJones?.status === DOW_JONES_REVIEWED_BLOCKED)
    ? Main.error
    : Main.warning;

  const header = (order.dowJones?.status === DOW_JONES_REVIEWED_BLOCKED)
    ? 'This customer has failed OFMP compliance check'
    : 'The customer has failed the Dow Jones compliance check and requires a review';

  const labels = (order.dowJones?.status === DOW_JONES_REVIEWED_BLOCKED)
    ? 'Order is canceled'
    : 'Till then this order will stay on hold';

  const button = (order.dowJones?.status === DOW_JONES_REVIEWED_BLOCKED)
    ? undefined
    : { value: 'Review customer\'s compliance status', onClick: () => navigate(`/customers/customer-details/${order.customer.id}`, false, { activeTab: 1 }) };

  return { theme, header, labels, button };
};

export const retrieveOrderComponentTitle = (order: Order): string => {
  const initial = 'Split order';
  const status = order.consignments.find(cons => cons)?.state;

  if (!status || !(order.consignments.every(consignment => consignment.state === status)))
    return initial;

  const deliveryTypes = order.consignments.map(consignment => consignment.deliveryType);

  switch (status) {
    case CHECKING_CREDIT: return 'Checking Credit';
    case READY:
    case ORDER_SENT_TO_OEM: return order.dowJones?.status === DOW_JONES_BLOCKED ? 'Compliance Fail' : 'New';
    case CANCELING:
    case CANCELED: return 'Cancelled';
    case ON_HOLD: return 'On Hold';
    case PROCESSING: return 'Processing';
    case MANUFACTURING: return 'Manufacturing';
    case PACKING: return 'Preparing';
    case DELAYED: return 'Delayed';
    case SHIPPED_READY_FOR_PICKUP:
      return (deliveryTypes.length > 1)
        ? 'Shipped / Ready for Pickup'
        : deliveryTypes[0] === SHIPPING
          ? 'Ready to ship'
          : 'Ready for Pickup';
    case READY_TO_SHIP: return 'Ready to ship';
    case READY_FOR_PICKUP: return 'Ready for Pickup';
    case DELIVERED_PICKED_UP:
      return (deliveryTypes.length > 1)
        ? 'Shipped / Ready for Pickup'
        : deliveryTypes[0] === SHIPPING
          ? 'Shipped'
          : 'Picked up';
    case SHIPPED: return 'Shipped';
    case PICKED_UP: return 'Picked up';

    default: return initial;
  }
};
