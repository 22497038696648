import { AsyncOptions, AsyncState, useAsync } from 'react-async';
import { useAuth } from 'lib/Auth';
import * as SessionStorage from 'lib/SessionStorage';
import { clearStashes } from 'lib/stash';
import { navigate } from 'hookrouter';

const API_URL = process.env.REACT_APP_API_URL;

export const formatUrl = (path: string, params: Record<string, any> = {}): string => {
  const paramsString = Object.entries(params)
    .filter(([, value]) => value !== undefined)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join('&');

  if (paramsString.length > 0) {
    return `${path}?${paramsString}`;
  }

  return path;
};

export const send = async (request: Request): Promise<any> => {
  const response = await window.fetch(request);
  const data = await response.json();

  if (response.status === 401) {
    clearStashes();

    SessionStorage.clear('auth');

    navigate('/');
  }

  if (!response.ok) {
    throw data;
  };

  return data;
};

export const sendReturnBlob = async (request: Request): Promise<any> => {
  const response = await window.fetch(request);
  const data = await response.blob();

  if (response.status === 401) {
    clearStashes();

    SessionStorage.clear('auth');

    navigate('/');
  }

  if (!response.ok) {
    throw data;
  };

  return data;
};

export const del = async (path: string, token?: string): Promise<any> => {
  const headers: Record<string, string> = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const request = new Request(`${API_URL}/${path}`, {
    headers,
    method: 'DELETE'
  });

  return send(request);
};

export const get = async (
  path: string,
  token?: string,
  params: Record<string, any> = {}
): Promise<any> => {
  const headers: Record<string, string> = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const request = new Request(formatUrl(`${API_URL}/${path}`, params), {
    headers,
    method: 'GET'
  });

  return send(request);
};

export const getBlob = async (
  path: string,
  token?: string,
  params: Record<string, any> = {}
): Promise<any> => {
  const headers: Record<string, string> = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const request = new Request(formatUrl(`${API_URL}/${path}`, params), {
    headers,
    method: 'GET'
  });

  return sendReturnBlob(request);
};

export const post = async (
  path: string,
  token?: string,
  bodyData: Record<string, any> = {}
): Promise<any> => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const request = new Request(`${API_URL}/${path}`, {
    body: JSON.stringify(bodyData),
    headers,
    method: 'POST'
  });

  return send(request);
};

export const postFile = async (
  path: string,
  media: File,
  token?: string
): Promise<any> => {
  const headers: Record<string, string> = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const body = new FormData();
  body.append('media', media);

  const request = new Request(`${API_URL}/${path}`, {
    body,
    headers,
    method: 'POST'
  });

  return send(request);
};

export const postFormData = async (
  path: string,
  bodyObject: any,
  token?: string
): Promise<any> => {
  const headers: Record<string, string> = {};

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const body = new FormData();

  Object.keys(bodyObject).forEach((key: string) => {
    if (key === 'fileCategories') {
      body.append(key, JSON.stringify(bodyObject[key]));
    } else if (key === 'imageFile') {
      bodyObject[key].forEach((test: any) => {
        body.append(key, test);
      })
    } else {
      body.append(key, bodyObject[key]);
    }
  });

  const request = new Request(`${API_URL}/${path}`, {
    body,
    headers,
    method: 'POST'
  });

  return send(request);
};

export const put = async (
  path: string,
  token?: string,
  data?: any
): Promise<any> => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const request = new Request(`${API_URL}/${path}`, {
    body: JSON.stringify(data),
    headers,
    method: 'PUT'
  });

  return send(request);
};

export const useAPI = <T>(options: AsyncOptions<T>): AsyncState<T> => {
  const { token, user } = useAuth();

  return useAsync({ ...options, token, user });
};
