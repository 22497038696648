import React from 'react';
import Requirement from 'view/Requirement';
import styled from 'styled-components';
import { validate } from 'lib/validation';

const Container = styled.div``;

type PasswordError = 'Length' | 'Lowercase' | 'Uppercase' | 'Nonalpha';

export function validatePassword(password: string): [boolean, PasswordError[]] {
  return validate<PasswordError>(password, [
    pw => (pw.length < 8 ? 'Length' : null),
    pw => (pw.match(/[a-z]/) === null ? 'Lowercase' : null),
    pw => (pw.match(/[A-Z]/) === null ? 'Uppercase' : null),
    pw => (pw.match(/[^A-Za-z]|\s/) === null ? 'Nonalpha' : null)
  ]);
}

interface Props {
  children?: React.ReactNode;
  className?: string;
  errors: PasswordError[];
}

export default function PasswordRequirements({
  children,
  className,
  errors
}: Props) {
  return (
    <Container className={className}>
      <Requirement passed={!errors.includes('Length')}>
        At least 8 characters
      </Requirement>
      <Requirement passed={!errors.includes('Lowercase')}>
        One lowercase character
      </Requirement>
      <Requirement passed={!errors.includes('Uppercase')}>
        One uppercase character
      </Requirement>
      <Requirement passed={!errors.includes('Nonalpha')}>
        One number, symbol, or whitespace character
      </Requirement>
      {children}
    </Container>
  );
}
