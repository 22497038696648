import { useAPI } from '../lib/API';
import { useEffect, useState } from 'react';
import CampaignType from 'data/CampaignType';
import { getCampaignTypes } from 'requests/campaigns';


export const useCampaignTypes = () => {
    const [campaignTypes, setCampaignsTypes] = useState<CampaignType[]>([]);

    const campaignTypeReq = useAPI({
        deferFn: getCampaignTypes,
        onResolve: (result: any) => {
            setCampaignsTypes(result.data);
        },
        onReject: (err: any) => { },
    });

    useEffect(() => {
        campaignTypeReq.run([]);
    }, []);

    return { campaignTypes, campaignTypeReq };

}