import FlexboxContainerComponent from '../../UI/FlexboxContainer';
import Button from '../../UI/Button';
import Spinner from '../../UI/Spinner';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Close } from '@material-ui/icons';
import { fileSizeToMb } from '../priceebook.utils';
import { PricebookFile } from '../pricebook.types';
import { FILE_LOAD_STATUS } from '../pricebook.enums';
import { Background, Main, Shade, Text } from 'styles/themeColors';

const CloseIcon = styled(Close)`
  cursor: pointer;
`;


const StyledSpan = styled.span`
  text-align: left;
  font-size: 14px;
  margin-top: 4px;
`;

const UploadError = styled.span`
  text-align: left;
  font-size: 12px;
  color: ${Main.error};
`;

const ButtonLabel = styled.span<{ isFileUploaded?: boolean }>`
  width: ${({ isFileUploaded }) => (isFileUploaded ? '90%' : '100%')};
  text-align: center;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CONSTANTS = {
  requiredFileSize: 20
};

const TEXT_LABELS = {
  UPLOADING_PROCESS: 'Uploading. Do not close this window',
  REQUIRED_FILES_SIZE: `Up to ${CONSTANTS.requiredFileSize} Mb`,
  SELECT_FILE: 'Select .xlsx file',
}

const ERROR_LABELS = {
  WRONG_COLUMN_SET: 'Wrong column set. Please compare to the template',
  WRONG_FILE_TYPE: 'You can only upload .xlsx file format',
}

const DYNAMIC_LABELS = {
  WRONG_FILE_SIZE: (currentSizeInMb: string) => `${currentSizeInMb} MB. Please upload files below ${CONSTANTS.requiredFileSize} MB`,
  CORRECT_FILE_SIZE: (currentSizeInMb: string) => `${currentSizeInMb} MB.`,
}

const uploadButtonBackground = (fileLoadStatus: FILE_LOAD_STATUS) => {
  if(fileLoadStatus === FILE_LOAD_STATUS.INITIAL) {
    return Background.white;
  }
  if(fileLoadStatus === FILE_LOAD_STATUS.SUCCESS) {
    return Background.navy;
  }
  return Main.error
}

interface FileInputProps {
  pricebookFile: PricebookFile;
  fileLoadStatus: FILE_LOAD_STATUS;
  inputRef:  React.MutableRefObject<HTMLInputElement | null>;
  fileUploading: boolean;
  handleUploadClick: () => void;
  handleClear: () => void;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const PricebookUploadFileInput = ({
  pricebookFile,
  fileLoadStatus,
  inputRef,
  fileUploading = false,
  handleUploadClick,
  handleClear,
  handleFileChange
}: FileInputProps) => {

  const currentFileSize = fileSizeToMb(pricebookFile.uploadedFile?.size || 0).toPrecision(4);
  const isFileUploaded = !!pricebookFile.uploadedFile;
  const currentFileName = pricebookFile.uploadedFile?.name || '';

  return (
    <FlexboxContainerComponent flexDirection="column" alignItems="center">
      <FlexboxContainerComponent
        justifyContent="center"
        margin="32px 0px 0px 0px"
      >
        <Button
          bgColor={Background.white}
          color={
            fileLoadStatus === FILE_LOAD_STATUS.INITIAL
              ? Shade.shade830
              : Text.whitePrimary
          }
          borderColor={Shade.shade550}
          padding="10px 0"
          width="320px"
          //remove ternar and use disable
          onClick={isFileUploaded ? () => {} : handleUploadClick}
          cursor={isFileUploaded ? 'initial' : 'pointer'}
          background={uploadButtonBackground(fileLoadStatus)}
        >
          <FlexboxContainerComponent width="100%">
            <ButtonLabel>
              {isFileUploaded
                ? currentFileName
                : TEXT_LABELS.SELECT_FILE}
            </ButtonLabel>

            {isFileUploaded && (
              <FlexboxContainerComponent>
                {' '}
                <CloseIcon id="close-icon" onClick={handleClear} />{' '}
              </FlexboxContainerComponent>
            )}
          </FlexboxContainerComponent>
        </Button>
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </FlexboxContainerComponent>
      <FlexboxContainerComponent width="320px">
        {fileLoadStatus === FILE_LOAD_STATUS.INITIAL &&
          <StyledSpan>
            {TEXT_LABELS.REQUIRED_FILES_SIZE}
          </StyledSpan>
        }
        {fileLoadStatus === FILE_LOAD_STATUS.FILE_SIZE_ERROR &&
          <UploadError>
            {DYNAMIC_LABELS.WRONG_FILE_SIZE(currentFileSize)}
          </UploadError>
        }
        {fileLoadStatus === FILE_LOAD_STATUS.FILE_TYPE_ERROR &&
          <UploadError>{ERROR_LABELS.WRONG_FILE_TYPE}</UploadError>
        }
        {fileLoadStatus === FILE_LOAD_STATUS.FILE_TEMPLATE_ERROR &&
          <UploadError>
            {ERROR_LABELS.WRONG_COLUMN_SET}
          </UploadError>
        }
        {!fileUploading && fileLoadStatus === FILE_LOAD_STATUS.SUCCESS &&
          <StyledSpan>
            {DYNAMIC_LABELS.CORRECT_FILE_SIZE(currentFileSize)}
          </StyledSpan>
        }
        {fileUploading && fileLoadStatus < 3 &&
          <StyledSpan>
            <Spinner color="black" size={14} />
            {TEXT_LABELS.UPLOADING_PROCESS}
          </StyledSpan>
        }
      </FlexboxContainerComponent>
    </FlexboxContainerComponent>
  );
}

export default PricebookUploadFileInput;