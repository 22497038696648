import React, { useState } from 'react';
import { useAsync } from 'react-async';
import { navigate } from 'hookrouter';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Invite from 'data/Invite';
import Button from 'components/UI/Button';
import ErrorMessage from 'view/ErrorMessage';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import PageHeader from 'view/PageHeader';
import PasswordInput from 'view/PasswordInput';
import PasswordRequirements, {
  validatePassword
} from 'view/PasswordRequirements';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, Size } from 'lib/styles';
import { acceptInvite, loadInvite } from 'requests/invite';
import { Background } from 'styles/themeColors';

const Actions = styled.section`
  border-top: 1px solid ${Color.gray};
  display: flex;
  justify-content: center;
  padding: ${Size.medium} ${Size.large};
`;

const Content = styled.div`
  background-color: ${Background.white};
  border: 1px solid ${Color.gray};
  border-radius: 2px;
  margin: ${Size.huge} auto;
  max-width: 600px;
`;

const ContentSection = styled.section`
  padding: ${Size.medium} ${Size.large};
`;

const ErrorText = styled.p`
  font-size: 1.25em;
  text-align: center;
`;

const WelcomeText = styled.p`
  font-size: 1.5em;
`;

interface IntroProps {
  invite: Invite;
  onJoin: () => void;
}

function VendorIntro({ invite, onJoin }: IntroProps) {
  return (
    <Content>
      <ContentSection>
        <WelcomeText>
          <b>{invite.creator.name}</b> has invited you to join OFMP's Seller
          Hub.
        </WelcomeText>
        <p>
          OFMP is the intelligent oilfield platform easing O&amp;G’s digital
          evolution by making commercial efficiency and quality information
          accessible to all.
        </p>
        <p>
          You’re now one click away from experiencing the quick and seamless
          process of getting your products online. What are you waiting for?
        </p>
      </ContentSection>
      <Actions>
        <Button onClick={onJoin}>Join Seller Hub</Button>
      </Actions>
    </Content>
  );
}

interface SetupProps {
  invite: Invite;
}

function VendorSetup({ invite }: SetupProps) {
  const [password, setPassword] = useState('');
  const [passwordValid, errors] = validatePassword(password);
  const acceptReq = useAsync({
    deferFn: acceptInvite,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Account setup! Welcome aboard!');
      navigate('/');
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  function saveAndJoin() {
    acceptReq.run(invite, password);
  }

  let errorMessage = null;
  if (acceptReq.error) {
    errorMessage = <ErrorMessage message={acceptReq.error.message} />;
  }

  return (
    <Content>
      <ContentSection>
        <WelcomeText>
          Welcome, <b>{invite.user.name}</b>! Please start by creating a password.
        </WelcomeText>
        <PasswordInput
          autoFocus
          disabled={acceptReq.isPending}
          label="Password"
          onChange={setPassword}
          value={password}
        />
        <PasswordRequirements errors={errors} />
        {errorMessage}
      </ContentSection>
      <Actions>
        <Button
          disabled={!passwordValid || acceptReq.isPending}
          onClick={saveAndJoin}
        >
          Save and sign in
        </Button>
      </Actions>
    </Content>
  );
}

interface Props {
  inviteId: string;
}

export default function InviteAccept({ inviteId }: Props) {
  const [joining, setJoining] = useState(false);
  const inviteReq = useAsync({ inviteId, promiseFn: loadInvite });

  let content = null;
  if (inviteReq.error) {
    content = (
      <Content>
        <ErrorText>{inviteReq.error.message}</ErrorText>
      </Content>
    );
  } else if (inviteReq.isLoading || !inviteReq.data) {
    content = <LoadingIndicator />;
  } else if (joining) {
    content = <VendorSetup invite={inviteReq.data} />;
  } else {
    content = (
      <VendorIntro invite={inviteReq.data} onJoin={() => setJoining(true)} />
    );
  }

  return (
    <>
      <PageHeader />
      {content}
    </>
  );
}
