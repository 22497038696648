import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Pill from 'components/UI/Pill';
import { useAPI } from 'lib/API';
import { replacePricebooksForUser } from 'requests/pricebook';
import { Background, Border, Text } from 'styles/themeColors';
import { PricebookState, PricebookType } from 'data/Pricebook';
import PricebooksPreview from './PricebooksPreview';
import { DefaultConfirmationModal } from '../components/PricebookUploadConfirmationModal';
import PricebookUploadStepper from '../components/PricebookUploadStepper';
import PricebookUploadVerification from '../components/PricebookUploadVerification';
import PricebookUploadActionButtons from '../components/PricebookUploadActionButtons';
import PricebookUploadFileInput from '../components/PricebookUploadFileInput';
import { CONFIRM_MODAL_TYPE, FILE_LOAD_STATUS } from '../pricebook.enums';
import { PricebookFile } from '../pricebook.types';
import {
  useCurrentPricebook,
  usePricebookStatusObserver,
} from '../pricebook.hooks';
import {
  checkFileSize,
  checkFileType,
  filenameWithoutExtension,
  verifyFileTemplate,
} from '../priceebook.utils';
import readExcelFile from 'read-excel-file';
import Vendor from 'data/Vendor';
import Customer from 'data/Customer';

const Title = styled.h5`
  font-size: 16px;
  font-weight: 700;
`;
const SpanItalic = styled.span`
  padding: 0px 8px 32px 8px;
  display: inline-block;
  color: #53595e;
  font-style: italic;
  font-size: 16px;
  font-weight: 700;
`;

const TEXT_LABELS = {
  PUBLISH_NOW_MODAL_HEADING: 'Publish new prices now?',
  PUBLISH_NOW_MODAL_WARNING_TEXT:
    'This may disrupt users which are placing orders at the moment.',
  DELETE_PRICEBOOK_HEADING: 'Delete active pricebook?',
  DELETE_PRICEBOOK_WARNING_TEXT:
    'Pricebook will be scheduled for deletion this midnight. A completion reference prices will be used.',
  NEW_PRICEBOOK_VERIFIED_TEXT:
    'New prices will replace current at 00:00. You can close this window.',
  PRICEBOOK_UPLOAD_TITLE: 'New Pricebook (Draft)',
  CANCEL_DRAFT_HEADING: 'Cancel Draft',
  CANCEL_DRAFT_WARNING_TEXT:
    'New upload will be canceled. Old prices will remain the same.',
};

const getModalLabels = (modalType: CONFIRM_MODAL_TYPE | null) => {
  if (modalType === CONFIRM_MODAL_TYPE.CANCEL) {
    return [
      TEXT_LABELS.CANCEL_DRAFT_HEADING,
      TEXT_LABELS.CANCEL_DRAFT_WARNING_TEXT,
    ];
  }
  if (modalType === CONFIRM_MODAL_TYPE.DELETE) {
    return [
      TEXT_LABELS.DELETE_PRICEBOOK_HEADING,
      TEXT_LABELS.DELETE_PRICEBOOK_WARNING_TEXT,
    ];
  }
  if (modalType === CONFIRM_MODAL_TYPE.PUBLISH) {
    return [
      TEXT_LABELS.PUBLISH_NOW_MODAL_HEADING,
      TEXT_LABELS.PUBLISH_NOW_MODAL_WARNING_TEXT,
    ];
  }
  return ['', ''];
};

const initialFileState: PricebookFile = { uploadedFile: null, uploadDate: 0 };

const extraPricebookDetails = {
  priceBookState: PricebookState.UPLOADED,
  previousState: PricebookState.UPLOADED,
  priceBookType: PricebookType.COMPANY,
};

interface Props {
  show: boolean;
  onClose: () => void;
  vendorName?: string;
  selectedAssignedBy?: Vendor;
  selectedAssignedTo?: Customer;
  companyId?: string;
  companyName?: string;
  pricebookPreviewId?: number;
  hasSelectedVendor?: boolean;
}

const PricebooksReplaceModal = ({
  show,
  onClose,
  vendorName,
  companyId,
  companyName,
  pricebookPreviewId,
  hasSelectedVendor = false,
}: Props) => {
  //callbacks required for custom hooks
  const onForceDeleteCallback = () => {
    handleClear();
    onClose();
  };

  const publishCallback = (priceBookState: string) => {
    if(priceBookState === PricebookState.PUBLISHED){
      setForsePublishing(false);
      setWatchNewPricebook(true);
    }
  };

  const onGetReplacementPricebookCallback = () => {
    setStep(2);
  };

  const cancelDraftCallback = () => {
    setDefaultState();
    //method from props
    onClose();
  };

  //state
  const [modalShowType, setModalShowType] = useState<CONFIRM_MODAL_TYPE | null>(
    null
  );
  const [fileLoadStatus, setFileLoadStatus] = useState<FILE_LOAD_STATUS>(
    FILE_LOAD_STATUS.INITIAL
  );
  const [step, setStep] = useState<number>(0);
  const [pricebookFile, setPricebookFile] =
    useState<PricebookFile>(initialFileState);
  const [fileUploading, setFileUploading] = useState(false);
  const [isForcePublishing, setForsePublishing] = useState<boolean>(false);
  const [watchNewPricebook, setWatchNewPricebook] = useState(false);

  //refs
  const inputRef = useRef<HTMLInputElement | null>(null);

  //custom hooks
  const {
    pricebookData,
    clearPricebookData,
    getPricebookById,
    pricebookDelete,
    pricebookDeleteReverse,
    pricebookDeleteForce,
    pricebooksPublish,
    pricebooksCancelDraft,
    getPricebookByReplacementId,
  } = usePricebookStatusObserver(
    onForceDeleteCallback,
    publishCallback,
    cancelDraftCallback,
    onGetReplacementPricebookCallback
  );
  const currentPricebookData = useCurrentPricebook(pricebookPreviewId);

  //replace pricebook
  const replacePricebook = useAPI({
    deferFn: replacePricebooksForUser,
    onResolve: (result: any) => {
      getPricebookById(result.id);
      setStep(step + 1);
      setFileUploading(false);
    },
    onReject: (err: any) => {
      setFileUploading(false);
    },
  });

  useEffect(() => {
    if (currentPricebookData) {
      getPricebookByReplacementId(currentPricebookData.id);
      setStep(1);
    }
  }, [currentPricebookData]);

  //callbacks
  const setDefaultState = () => {
    setFileLoadStatus(FILE_LOAD_STATUS.INITIAL);
    setFileUploading(false);
    setPricebookFile(initialFileState);
    setStep(1);
    clearPricebookData();
  };
  const handleClear = () => {
    if (inputRef.current != null) {
      inputRef.current.value = '';
    }
    setDefaultState();
  };
  const handleUploadClick = () => {
    if (inputRef.current != null) {
      inputRef.current.value = '';
    }
    setFileLoadStatus(FILE_LOAD_STATUS.INITIAL);
    setFileUploading(false);
    setPricebookFile(initialFileState);
    inputRef.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    setPricebookFile({
      uploadedFile: file,
      uploadDate: Date.now(),
    });

    if (!checkFileSize(file)) {
      setFileLoadStatus(FILE_LOAD_STATUS.FILE_SIZE_ERROR);
    } else if (!checkFileType(file)) {
      setFileLoadStatus(FILE_LOAD_STATUS.FILE_TYPE_ERROR);
    } else if (
      !(await verifyFileTemplate(
        file,
        currentPricebookData?.priceBookType === PricebookType.COMPANY
          ? PricebookType.COMPANY
          : currentPricebookData?.priceBookType === PricebookType.GEOGRAPHY
          ? PricebookType.GEOGRAPHY
          : currentPricebookData?.priceBookType === PricebookType.REFERENCE
          ? PricebookType.REFERENCE
          : ''
      ))
    ) {
      setFileLoadStatus(FILE_LOAD_STATUS.FILE_TEMPLATE_ERROR);
    } else {
      setFileLoadStatus(FILE_LOAD_STATUS.SUCCESS);
    }
  };

  const onDeleteConfirmPricebookHandler = () => {
    setModalShowType(CONFIRM_MODAL_TYPE.DELETE);
  };

  const onCancelDeletionPricebookHandler = () => {
    pricebookDeleteReverse();
    setModalShowType(null);
  };

  const onDeleteNowPricebookHandler = () => {
    pricebookDeleteForce();
  };

  //On 'next' button click callback
  const uploadPricebookFile = async () => {
    setFileUploading(true);
    const pricebookData =
      pricebookFile.uploadedFile &&
      (await readExcelFile(pricebookFile.uploadedFile));
    replacePricebook.run(
      pricebookData,
      filenameWithoutExtension(pricebookFile.uploadedFile?.name),
      {
        ...extraPricebookDetails,
        replacementPricebookId: currentPricebookData?.id || pricebookPreviewId,
        priceBookId: currentPricebookData?.id || pricebookPreviewId,
        vendorId: currentPricebookData?.vendorId,
        customerId: currentPricebookData?.customerId,
        companyId: companyId || currentPricebookData?.company?.companyId,
        versionId: currentPricebookData?.versionId || 1,
        warehouseId: currentPricebookData?.warehouse?.code,
        priceBookType: currentPricebookData?.priceBookType,
        countryId: currentPricebookData?.country?.id,
      },
      pricebookFile.uploadDate
    );
  };

  const [modalTitle, modalBodyText] = getModalLabels(modalShowType);
  const onModalClose = () => setModalShowType(null);
  const onModalClick = () => {
    if (modalShowType === CONFIRM_MODAL_TYPE.CANCEL) {
      pricebooksCancelDraft();
    }
    if (modalShowType === CONFIRM_MODAL_TYPE.DELETE) {
      pricebookDelete();
    }
    if (modalShowType === CONFIRM_MODAL_TYPE.PUBLISH) {
      setForsePublishing(true);
      pricebooksPublish();
    }
    setModalShowType(null);
  };

  const CompanyPricebookHeading = (
    <Title>
      <SpanItalic>Assigned by</SpanItalic>
      {currentPricebookData && currentPricebookData?.vendor?.name
        ? currentPricebookData.vendor.name
        : vendorName}
      <SpanItalic>for</SpanItalic>
      {companyName || currentPricebookData?.company?.companyName}
    </Title>
  );

  const GeographyPricebookHeading = (
    <Title>
      <SpanItalic>Assigned by</SpanItalic>
      {currentPricebookData && currentPricebookData?.vendor?.name
        ? currentPricebookData.vendor.name
        : vendorName}
      <SpanItalic>for</SpanItalic>
      {currentPricebookData?.warehouse?.name}
    </Title>
  );

  const ReferencePricebookHeading = (
    <Title>
      <SpanItalic>Assigned by</SpanItalic>
      {currentPricebookData && currentPricebookData?.vendor?.name
        ? currentPricebookData.vendor.name
        : vendorName}
      <SpanItalic>for</SpanItalic>
      {currentPricebookData?.country?.countryName}
    </Title>
  );

  return (
    <>
      <DefaultConfirmationModal
        showModal={!!modalShowType}
        onClose={onModalClose}
        onClick={onModalClick}
        title={modalTitle}
        bodyText={modalBodyText}
      />
      <Modal
        width="825px"
        showModal={!modalShowType && show}
        onClose={() => {
          handleClear();
          onClose();
        }}
        showButtons={false}
        title={
          currentPricebookData?.priceBookType === PricebookType.COMPANY
            ? 'Company pricebook'
            : currentPricebookData?.priceBookType === PricebookType.GEOGRAPHY
            ? 'Geography pricebook'
            : currentPricebookData?.priceBookType === PricebookType.REFERENCE
            ? 'Reference pricebook'
            : ''
        }
        modalFooterMargin="0"
        left="50vw"
        marginLeft="-412px"
        iconName={
          currentPricebookData?.priceBookType === PricebookType.COMPANY
            ? 'PricebookModalIcon'
            : currentPricebookData?.priceBookType === PricebookType.GEOGRAPHY
            ? 'PinDrop'
            : currentPricebookData?.priceBookType === PricebookType.REFERENCE
            ? 'Public'
            : ''
        }
        showCloseIcon
        top="calc(50vh - 356px)"
      >
        {currentPricebookData?.priceBookType === PricebookType.COMPANY
          ? CompanyPricebookHeading
          : currentPricebookData?.priceBookType === PricebookType.GEOGRAPHY
          ? GeographyPricebookHeading
          : currentPricebookData?.priceBookType === PricebookType.REFERENCE
          ? ReferencePricebookHeading
          : null}
        {currentPricebookData && (
          <PricebooksPreview
            pricebook={watchNewPricebook ? pricebookData : currentPricebookData}
            onDeleteConfirmPricebookHandler={onDeleteConfirmPricebookHandler}
            onCancelDeletionPricebookHandler={onCancelDeletionPricebookHandler}
            onDeleteNowPricebookHandler={onDeleteNowPricebookHandler}
            hideReplaceButton={true}
            hideDeleteButton={true}
          />
        )}
        {pricebookData?.priceBookState !== PricebookState.PUBLISHED && (
          <FlexboxContainerComponent
            bgColor={Background.light}
            border={`1px solid ${Border.light}`}
            borderRadius="6px"
            padding="24px"
            flexDirection="column"
          >
            <FlexboxContainerComponent
              alignItems="baseline"
              margin="0 0 32px 0"
            >
              <Pill
                margin="0 0 0px 8px"
                size="xsPlus"
                padding="4px"
                bgColor={Background.shaded}
                color={Text.placeholder}
              >
                {TEXT_LABELS.PRICEBOOK_UPLOAD_TITLE}
              </Pill>
            </FlexboxContainerComponent>
            <PricebookUploadStepper
              step={step}
              publicationState={
                pricebookData?.priceBookState === PricebookState.VALIDATED ||
                pricebookData?.priceBookState === PricebookState.PROCESSING
              }
            />
            {step === 1 && (
              <PricebookUploadFileInput
                handleFileChange={handleFileChange}
                handleUploadClick={handleUploadClick}
                inputRef={inputRef}
                pricebookFile={pricebookFile}
                fileUploading={fileUploading}
                handleClear={handleClear}
                fileLoadStatus={fileLoadStatus}
              />
            )}
            {step === 2 && (
              <PricebookUploadVerification
                pricebookFile={pricebookFile}
                pricebookData={pricebookData}
                isForcePublish={isForcePublishing}
              />
            )}
            <PricebookUploadActionButtons
              onNextButtonClick={uploadPricebookFile}
              currentPricebookData={currentPricebookData}
              onCancelButtonClick={() => {
                setModalShowType(CONFIRM_MODAL_TYPE.CANCEL);
              }}
              onPublishButtonClick={() => {
                setModalShowType(CONFIRM_MODAL_TYPE.PUBLISH);
              }}
              step={step}
              pricebookData={pricebookData}
              fileUploading={fileUploading}
              fileLoadStatus={fileLoadStatus}
              disableForcePublish={isForcePublishing}
              hasSelectedVendor={hasSelectedVendor}
            />
          </FlexboxContainerComponent>
        )}
      </Modal>
    </>
  );
};

export default PricebooksReplaceModal;
