import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Modal from 'components/UI/Modal';
import Input from 'components/UI/Input';
import { renderToast } from 'components/UI/ToastNotification';
import { useAuth } from 'lib/Auth';
import Attribute, { AttributeValueRequest } from 'data/Attribute';
import { addAttributeValue } from 'requests/atribute';

interface Props {
  onClose: () => void;
  attribute: Attribute;
  addValue: (newValue: AttributeValueRequest) => void;
  title: string;
  showModal: boolean;
};

const AddAttributeValueModal = ({ onClose, attribute, addValue, title, showModal }: Props) => {
  const [newValue, setNewValue] = useState<AttributeValueRequest>({ id: 0, attributeId: 0, name: '', code: '', displayOrder: 0 });
  const { token } = useAuth();

  useEffect(() => {
    setNewValue({ ...newValue, attributeId: attribute.id, displayOrder: attribute.values.length + 1 })
  }, [attribute]);

  const handleClick = () => {
    addAttributeValue(token!, newValue).then(res => {
      renderToast(toast.TYPE.SUCCESS, 'Value created successfully.');
      addValue(res);
    }).catch(err => {
      renderToast(toast.TYPE.ERROR, err.message);
    });

    onClose();
  };

  const cantSubmit = newValue.name === '' || attribute.values.map(el => el.name).includes(newValue.name);

  return (
    <Modal
      showModal={showModal}
      showCloseIcon
      onClose={onClose}
      onClick={handleClick}
      disabled={cantSubmit}
      title={title}
      primaryButtonText='Save'
    >
      <Input
        id='add-attribute-value-modal-name-id'
        placeholder='Attribute value'
        labelName="Name"
        onInputChange={name => setNewValue({ ...newValue, name, attributeId: attribute.id })}
        value={newValue.name}
      />
    </Modal>
  );
};

AddAttributeValueModal.defaultProps = {};

export default AddAttributeValueModal;
