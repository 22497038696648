import React from 'react';
import styled from 'styled-components';
import Icon from 'view/Icon';
import Tooltip from 'components/UI/Tooltip';
import { Main } from 'styles/themeColors';

const IconWrapper = styled.div`
  display: flex;
`;

const StyledIcon = styled(Icon)<any>`
  color: ${({ productRedesignFlag }) => productRedesignFlag ? `${Main.brandLight}` : `${Main.accent}`};
`;

interface Props {
  className?: string;
  size?: number;
  tooltipText: React.ReactNode;
  width?: string;
  productRedesignFlag?: boolean;
};

export default function InfoIcon({
  className,
  size,
  tooltipText,
  width,
  productRedesignFlag = false
}: Props) {
  return (
    <div className={className}>
      <Tooltip
        content={tooltipText}
        width={width === 'auto' ? undefined : width}
      >
        <IconWrapper>
          <StyledIcon productRedesignFlag={productRedesignFlag} name="info" size={size} />
        </IconWrapper>
      </Tooltip>
    </div>
  );
};

InfoIcon.defaultProps = {
  size: 18,
  width: '20rem'
};
