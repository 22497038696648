import { TOGGLE_OPEN_SPLIT_ORDERS } from 'redux/constants/actionTypes';
import { GeneralAction } from 'redux/types/actions';

export const toggleOpenSplitOrders = (
  stateKey: string,
  value: string
): GeneralAction => ({
  type: TOGGLE_OPEN_SPLIT_ORDERS,
  stateKey,
  value
});
