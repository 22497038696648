import React from 'react';
import styled from 'styled-components';
import { Main, Shade } from 'styles/themeColors';

const StepperContainer = styled.div<any>`
  width: ${({ stepperWidth }) => stepperWidth}px;
  height: ${({ stepperHeight }) => stepperHeight}px;
  display: flex;
  margin-top: 3px;
`;

const Step = styled.div<any>`
  background-color: ${({ stepsColor }) => stepsColor};
  width: ${({ stepWidth }) => stepWidth}px;
  height: ${({ stepHeight }) => stepHeight}px;
  margin-left: ${({ isFirst }) => isFirst ? '0px' : '2px'};
  
  border-radius: ${({ isFirst, isLast }) => isFirst ? '4px 0px 0px 4px' : isLast ? '0px 4px 4px 0px' : 'none'};
`;

interface Props {
  numberOfSteps: number;
  currentStep: number;
  stepsColor: string;
  stepperWidth: number;
  stepperHeight: number;
};

export default function LineStepper({
  numberOfSteps,
  currentStep,
  stepsColor,
  stepperWidth,
  stepperHeight
}: Props) {
  const steps: any = [];
  const stepWidth = stepperWidth / numberOfSteps;

  for (let i = 0; i < numberOfSteps; i++) {
    steps.push(
      <Step
        key={i}
        stepWidth={stepWidth}
        stepHeight={stepperHeight}
        stepsColor={i + 1 <= currentStep ? stepsColor : Shade.shade340}
        isFirst={i === 0}
        isLast={i === numberOfSteps - 1}
      />
    );
  }

  return (
    <StepperContainer stepperWidth={stepperWidth} stepperHeight={stepperHeight}>
      {steps}
    </StepperContainer>
  );
};

LineStepper.defaultProps = {
  stepsColor: Main.success,
  stepperHeight: 5
};
