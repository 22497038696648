import React from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Border, Text } from 'styles/themeColors';
import { FontWeight } from 'lib/styles';
import { TEXT_LABELS } from 'constants/order';
import { DatePicker } from '@material-ui/pickers';
import DatePickerInput from 'components/UI/DatePickerInput';
import { onlyWeekdaysAllowed } from 'utils/product';

const LabelText = styled.span`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
  margin-bottom: 5px;
  color: ${Text.primary};
  font-size: 15px;
`;

const TextArea = styled.textarea`
  border: 1px solid ${Border.main};
  border-radius: 6px;
  padding: 7px;
`;

export interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: (value: string) => void;
  title: string;  
  disabled?: boolean;
  rentalStartDate: string;
  rentalEndDate: string;
  reason: string;
  setRentalStartDate: (date: string) => void;
  setRentalEndDate: (date: string) => void;
  setReason: (reason: string) => void;
  weekdaysOnly: boolean;
};

const ModifyRentalDatesModal = ({
  show,
  onClose,
  onConfirm,
  title,
  disabled = false,
  rentalEndDate,
  rentalStartDate,
  setRentalStartDate,
  setRentalEndDate,
  reason,
  setReason,
  weekdaysOnly
}: Props) => {
  return (
    <Modal
      showButtons
      showModal={show}
      title={title}
      onClose={onClose}
      onClick={onConfirm}
      disabled={disabled}
      ghostButtonText={'Cancel'}
      primaryButtonText={'Modify rental'}
      width='730px'
      left='30%'
    >
        <FlexboxContainer flexDirection="column">
          <FlexboxContainer flexDirection='row' gap='4px'>
            <FlexboxContainer flexDirection="column" width='100%'>
              <LabelText>{TEXT_LABELS.RENTAL_START_DATE}</LabelText>
              <DatePicker
                value={rentalStartDate}
                inputVariant="outlined"
                format="yyyy MMM dd"
                TextFieldComponent={DatePickerInput}
                onChange={(date) => setRentalStartDate(date?.toString()!)}
                autoOk
                shouldDisableDate={(date) => onlyWeekdaysAllowed(date, weekdaysOnly)}
              /> 
          </FlexboxContainer>
          <FlexboxContainer flexDirection="column" width='100%'>
            <LabelText>{TEXT_LABELS.RENTAL_END_DATE}</LabelText>
              <DatePicker
                value={rentalEndDate}
                inputVariant="outlined"
                format="yyyy MMM dd"
                TextFieldComponent={DatePickerInput}
                onChange={(date) => setRentalEndDate(date?.toString()!)}
                autoOk
                shouldDisableDate={(date) => onlyWeekdaysAllowed(date, weekdaysOnly)}
              />
           </FlexboxContainer>
        </FlexboxContainer>
        <FlexboxContainer flexDirection="column" margin='20px 0px'>
          <LabelText>{TEXT_LABELS.RENTAL_MODIFICATOION_REASON}</LabelText>
          <TextArea maxLength={500} rows={5} value={reason} onChange={(event: any) => setReason(event.target.value)} />
        </FlexboxContainer>
        </FlexboxContainer>
    </Modal>
  );
};

export default ModifyRentalDatesModal;
