import { useAPI } from '../../lib/API';
import {
  cancelDraftPricebook,
  deletePricebook,
  forceDeletePricebook,
  forcePublishPricebooks,
  getPricebookbyReplacementId,
  getPricebooksById,
  reverseDeletePricebook,
} from '../../requests/pricebook';
import { useEffect, useState } from 'react';
import { renderToast } from '../UI/ToastNotification';
import { toast } from 'react-toastify';
import { PricebookExtended, PricebookState, PricebookType } from '../../data/Pricebook';
import { errorLogUrl } from './priceebook.utils';
import {
  loadCompanyCustomers,
  loadCompanyCustomersDefer,
} from '../../requests/customer';
import Customer, { CustomerExtended } from '../../data/Customer';
import { useAuth } from '../../lib/Auth';
import { loadVendors } from '../../requests/vendor';
import Vendor from '../../data/Vendor';
import Company from '../../data/Company';
import { loadCompanies } from '../../requests/company';
import { Option } from '../../common/Companies/PricebooksTab';

const TEXT_LABELS = {
  UPLOAD_PRICEBOOK_ERROR_TOAST_TEXT:
    'Pricebook upload failed. Errors were found during the file verification.',
};

const DYNAMIC_LABELS = {
  SUCCESS_PUBLISH_TOAST_TEXT: (priceBookName: string, priceBookType: string) =>
    `New ${
      priceBookType === PricebookType.COMPANY
        ? 'company pricebook'
        : priceBookType === PricebookType.GEOGRAPHY
        ? 'warehouse pricebook'
        : priceBookType === PricebookType.REFERENCE
        ? 'reference pricebook'
        : ''
    } "${priceBookName}" is published. New prices are applied to the products`,
};

export const useCurrentPricebook = (pricebookId: number | undefined) => {
  const [currentPricebookData, setCurrentPricebookdata] =
    useState<PricebookExtended | null>(null);

  const pricebookById = useAPI({
    deferFn: getPricebooksById,
    onResolve: (result: any) => {
      setCurrentPricebookdata(result);
    },
    onReject: (err: any) => {
      renderToast(toast.TYPE.ERROR, JSON.stringify(err), {
        showErrorLogButton: true,
      });
    },
  });

  useEffect(() => {
    pricebookId && pricebookById.run(pricebookId);
  }, [pricebookId]);

  return currentPricebookData;
};

export const usePricebookStatusObserver = (
  forceDeleteCallback?: () => void,
  publishCallback?: (priceBookState: string) => void,
  cancelDraftCallback?: () => void,
  onGetReplacementPricebookCallback?: () => void
) => {
  const [pricebookData, setPricebookData] = useState<PricebookExtended | null>(
    null
  );

  const pricebookId = pricebookData?.id;
  const pricebookState = pricebookData?.priceBookState;

  //TODO replace with long polling
  useEffect(() => {
    if (!pricebookId) {
      return;
    }
    const interval = setInterval(() => {
      if (
        pricebookState === PricebookState.UPLOADED ||
        pricebookState === PricebookState.PROCESSING
      )
        pricebookById.run(pricebookId);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [pricebookId, pricebookState]);

  const getPricebookById = (pricebookId: number | undefined) => {
    pricebookId && pricebookById.run(pricebookId);
  };

  const getPricebookByReplacementId = (pricebookId: number | undefined) => {
    pricebookId && pricebookbyReplacementIdCall.run(pricebookId);
  };

  const clearPricebookData = () => {
    setPricebookData(null);
  };

  //API hooks
  const pricebookbyReplacementIdCall = useAPI({
    deferFn: getPricebookbyReplacementId,
    onResolve: (result: any) => {
      if (result && result?.id) {
        setPricebookData(result);
        onGetReplacementPricebookCallback &&
          onGetReplacementPricebookCallback();
      }
    },
    onReject: (err: any) => {
      renderToast(toast.TYPE.ERROR, JSON.stringify(err), {
        showErrorLogButton: true,
      });
    },
  });

  const pricebookById = useAPI({
    deferFn: getPricebooksById,
    onResolve: (result: any) => {
      publishCallback && publishCallback(result.priceBookState);
      if (result.priceBookState === PricebookState.PUBLISHED) {
        renderToast(
          toast.TYPE.SUCCESS,
          DYNAMIC_LABELS.SUCCESS_PUBLISH_TOAST_TEXT(
            result.priceBookName,
            result.priceBookType
          )
        );
      } else if (
        result.priceBookState === PricebookState.IMPORT_FAILED ||
        result.priceBookState === PricebookState.VALIDATION_FAILED
      ) {
        renderToast(
          toast.TYPE.ERROR,
          TEXT_LABELS.UPLOAD_PRICEBOOK_ERROR_TOAST_TEXT,
          {
            showErrorLogButton: true,
            errorHref: errorLogUrl(result.pathToFile),
          }
        );
      }
      setPricebookData(result);
    },
    onReject: (err: any) => {
      renderToast(
        toast.TYPE.ERROR,
        err?.message || TEXT_LABELS.UPLOAD_PRICEBOOK_ERROR_TOAST_TEXT,
        {
          showErrorLogButton: true,
        }
      );
    },
  });

  const pricebookDeleteCall = useAPI({
    deferFn: deletePricebook,
    onResolve: (result: any) => {
      if (result.id) {
        setPricebookData(result);
      }
    },
    onReject: (err: any) => {
      renderToast(toast.TYPE.ERROR, JSON.stringify(err), {
        showErrorLogButton: true,
      });
    },
  });

  const pricebookDeleteReverseCall = useAPI({
    deferFn: reverseDeletePricebook,
    onResolve: (result: any) => {
      if (result.id) {
        setPricebookData(result);
      }
    },
    onReject: (err: any) => {
      renderToast(toast.TYPE.ERROR, JSON.stringify(err), {
        showErrorLogButton: true,
      });
    },
  });

  const pricebookDeleteForceCall = useAPI({
    deferFn: forceDeletePricebook,
    onResolve: (result: any) => {
      forceDeleteCallback && forceDeleteCallback();
    },
    onReject: (err: any) => {
      renderToast(toast.TYPE.ERROR, JSON.stringify(err), {
        showErrorLogButton: true,
      });
    },
  });

  const pricebooksPublishCall = useAPI({
    deferFn: forcePublishPricebooks,
    onResolve: (result: any) => {
      getPricebookById(pricebookId);
    },
    onReject: (err: any) => {
      renderToast(toast.TYPE.ERROR, JSON.stringify(err), {
        showErrorLogButton: true,
      });
    },
  });

  const pricebookCancelDraftCall = useAPI({
    deferFn: cancelDraftPricebook,
    onResolve: (result: any) => {
      cancelDraftCallback && cancelDraftCallback();
    },
    onReject: (err: any) => {
      renderToast(toast.TYPE.ERROR, JSON.stringify(err), {
        showErrorLogButton: true,
      });
    },
  });

  //callbacks that run api calls
  const pricebookDelete = () => {
    pricebookDeleteCall.run(pricebookId);
  };
  const pricebookDeleteReverse = () => {
    pricebookDeleteReverseCall.run(pricebookId);
  };
  const pricebookDeleteForce = () => {
    pricebookDeleteForceCall.run(pricebookId);
  };

  const pricebooksPublish = () => {
    pricebooksPublishCall.run(pricebookId);
  };

  const pricebooksCancelDraft = () => {
    pricebookCancelDraftCall.run(pricebookId);
  };

  return {
    pricebookData,
    clearPricebookData,
    getPricebookById,
    getPricebookByReplacementId,
    pricebookDelete,
    pricebookDeleteReverse,
    pricebookDeleteForce,
    pricebooksPublish,
    pricebooksCancelDraft,
  };
};

export const useNewPricebookAssignmentState = (
  companyCustomers?: CustomerExtended[],
  selectedVendor?: Vendor
): [
  {
    selectedAssignedTo: Company | undefined;
    assignedToList: Company[];
    onSelectAssignedTo: (assignedTo: Option | undefined) => void;
  },
  {
    selectedAssignedBy: Vendor | undefined;
    assignedByList: Vendor[];
    onSelectAssignedBy: (assignedBy: Option | undefined) => void;
  },
  {
    companyUsers: CustomerExtended[];
    selectedCompanyUser: CustomerExtended | undefined;
    onSelectCompanyUser: (companyUser: Option | undefined) => void;
  }
] => {
  const { token } = useAuth();

  const [selectedCompanyUser, setSelectedCompanyUser] =
    useState<CustomerExtended>();
  const [selectedAssignedBy, setSelectedAssignedBy] = useState<Vendor>();
  const [selectedAssignedTo, setSelectedAssignedTo] = useState<Company>();

  const [assignedToList, setAssignedToList] = useState<Company[]>([]);
  const [companyUsers, setCompanyUsers] = useState<CustomerExtended[]>([]);
  const [assignedByList, setAssignedByList] = useState<Vendor[]>([]);

  const hasStaticCompanyCustomers = companyCustomers && companyCustomers.length;

  // api calls
  const getVendorsList = useAPI({
    deferFn: loadVendors,
    onResolve: (result) => {
      setAssignedByList(result);
    },
    onReject: (err) => {
      renderToast(toast.TYPE.ERROR, err.message);
    },
  });

  const getCompaniesList = useAPI({
    deferFn: loadCompanies,
    onResolve: (result: any) => {
      if (result.companies) {
        setAssignedToList(result.companies);
      }
    },
    onReject: (err) => {
      renderToast(toast.TYPE.ERROR, err.message);
    },
  });

  const getCompanyCustomers = useAPI({
    deferFn: loadCompanyCustomersDefer,
    onResolve: (result) => {
      setCompanyUsers(result as unknown as CustomerExtended[]);
    },
    onReject: (err) => {
      renderToast(toast.TYPE.ERROR, err.message);
    },
  });

  //on select callback
  const onSelectCompanyUser = (companyUser: Option | undefined) => {
    if (!companyUser || companyUser.id === 'All') {
      setSelectedCompanyUser(undefined);
    } else if (companyUser?.value) {
      const foundCustomer = companyUsers.find(
        (cu: any) => cu.email[0].emailAddress === companyUser.value
      );
      if (foundCustomer) {
        setSelectedCompanyUser(foundCustomer);
      }
    }
  };

  const onSelectAssignedBy = (assignedBy: Option | undefined) => {
    if (assignedBy?.value) {
      const foundVendor = assignedByList.find(
        (ve: any) => ve.name === assignedBy.value
      );
      if (foundVendor) {
        setSelectedAssignedBy(foundVendor);
      }
    } else if (!assignedBy) {
      setSelectedAssignedBy(undefined);
    }
  };

  const onSelectAssignedTo = (assignedTo: Option | undefined) => {
    if (assignedTo?.value) {
      const foundCompany = assignedToList.find(
        (company: any) => company.companyId === assignedTo.id
      );
      if (foundCompany) {
        setSelectedAssignedTo(foundCompany);
      }
    } else if (!assignedTo) {
      setSelectedAssignedTo(undefined);
    }
  };

  // use effects for fetching assignment data
  useEffect(() => {
    if (selectedAssignedTo?.companyId && !hasStaticCompanyCustomers) {
      getCompanyCustomers.run(selectedAssignedTo?.id, token);
    } else {
      setCompanyUsers([]);
    }
    onSelectCompanyUser(undefined);
  }, [selectedAssignedTo, hasStaticCompanyCustomers]);

  useEffect(() => {
    if (token) {
      getVendorsList.run([token]);
    }
  }, [token]);

  useEffect(() => {
    if (!hasStaticCompanyCustomers) {
      getCompaniesList.run(null, null, null, selectedVendor?.id || null);
    }
  }, [selectedVendor?.id, hasStaticCompanyCustomers]);

  useEffect(() => {
    if (selectedVendor) {
      setSelectedAssignedBy(selectedVendor);
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (hasStaticCompanyCustomers) {
      setCompanyUsers(companyCustomers);
    }
  }, [hasStaticCompanyCustomers, companyCustomers]);

  return [
    { selectedAssignedTo, assignedToList, onSelectAssignedTo },
    { selectedAssignedBy, assignedByList, onSelectAssignedBy },
    { selectedCompanyUser, companyUsers, onSelectCompanyUser },
  ];
};
