import React, { useRef, useEffect, memo, useState } from 'react';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import QuillToolbar, { formats, modules } from './RichTextEditorToolBar';
import { MAX_DESCRIPTION_LENGTH } from 'constants/product';
import { stripHtml } from 'utils/product';

export interface Props {
  onBlur: Function;
  value: string;
  disabled?: boolean;
  placeholder: string;
  isInvalid?: boolean;
  productRedesignFlag?: boolean;
  maxLength?: number;
  setDescriptionCharacters?: (length: number) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 2em;
  width: 100%;
`;

const TextareaContainer = styled.div`
  position: relative;
`;

const RichTextEditorComponent = ({ onBlur, value, disabled, placeholder, isInvalid, productRedesignFlag = false, maxLength = MAX_DESCRIPTION_LENGTH, setDescriptionCharacters }: Props) => {
  const quillRef = useRef<ReactQuill>(null);
  const [content, setContent] = useState<string>('');

  useEffect(()=>{
    setContent(value);
  }, [value])

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();

      quill.on('text-change', (delta, oldDelta, source) => {
        const plainText = stripHtml(quill.root.innerHTML);
        setDescriptionCharacters?.(MAX_DESCRIPTION_LENGTH - plainText.length);
        if (plainText.length > maxLength) {
          quill.deleteText(maxLength, plainText.length);
        }
      });
    }
  }, [quillRef, maxLength]);

  const handleChange = (newContent: string) => {
    setContent(newContent)
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      onBlur(quill.root.innerHTML);
    }
  }

  return (
    <Container
      id={productRedesignFlag ? "html-editor-container-redesign" : "html-editor-container"}
      className={isInvalid ? 'invalid' : ''}
    >
      <QuillToolbar />
      <TextareaContainer>
        <ReactQuill
          ref={quillRef}
          value={content}
          onChange={handleChange}
          id="html-editor"
          theme="snow"
          placeholder={placeholder}
          modules={modules}
          formats={formats}
          readOnly={disabled}
          style={{ minHeight: productRedesignFlag ? '300px' : 'auto' }}
        />
      </TextareaContainer>
    </Container>
  );
};

export default memo(RichTextEditorComponent);
