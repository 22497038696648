import React from 'react';
import { ProductValidationTier } from './productValidationTiers';

export function getTooltipTextForTier(validationTier: ProductValidationTier) {
  return {
    name: (
      <>
        <p>
          Your product name must be at least{' '}
          <strong>{validationTier.requirements.name.min} words</strong> long,
          although we recommend{' '}
          <strong>
            {validationTier.recommendations.name.min}-
            {validationTier.recommendations.name.max} words
          </strong>{' '}
          for easy identification. Be sure to include the product type and
          model/series in your product name.
        </p>

        <p>
          Example: <em>SX Greaseless Series™ wireline sheave</em>
        </p>
      </>
    ),
    description: (
      <>
        <p>
          Your product description must be at least{' '}
          <strong>
            {validationTier.requirements.description.min} words
          </strong>{' '}
          long, although we recommend a minimum of{' '}
          <strong>
            {validationTier.recommendations.description.min} words
          </strong>{' '}
          if you wish to maximize its effectiveness.
        </p>

        <p>
          Be sure to include its top benefits and unique features – help it
          sell! Put yourself in your buyer's shoes to better understand the kind
          of information needed to purchase your product.
        </p>
      </>
    ),
    documents: getDocumentsTooltip(validationTier),
    productImages: (
      <>
        <p>
          Although only{' '}
          <strong>{validationTier.requirements.productImages.min}</strong>{' '}
          {validationTier.requirements.productImages.min === 1 ? 'is' : 'are'}{' '}
          required, we recommend that you add at least{' '}
          <strong>
            {validationTier.recommendations.productImages.min} product images
          </strong>
          .
        </p>
        <p>
          Be sure to include full product images, along with images that zoom in
          on the key features of your product (e.g., those that you decided to
          highlight in your product description). Ask yourself, "What would a
          buyer like to see before making a purchase?"
        </p>

        <p>
          <em>
            Each product image must have a white background and a minimum
            resolution of 500x500 pixels.
          </em>
        </p>
      </>
    ),
    contextualImages: (
      <>
        <p>Contextual imagery refers to:</p>
        <ol>
          <li>
            Images that show your product being used by actual people. The
            inclusion of human features (e.g., hands, faces) in imagery is known
            to boost sales.
          </li>
          <li>
            Images, graphics, or drawings that show where your product is used
            in relation to other products.
          </li>
        </ol>
        <p>
          While not required, contextual imagery is one of the biggest
          contributors to high-performing products on our platform. We highly
          recommend that you add it either now or in the future.
        </p>
      </>
    ),
    videos: getVideosTooltip(validationTier)
  };
}

function getVideosTooltip(validationTier: ProductValidationTier) {
  let introText = (
    <>
      <p>
        Let's be honest: everybody loves a good video. And, the more expensive
        the product, the more imagery and information buyers require to
        confidently make their purchases.
      </p>

      <p>
        While only <strong>1</strong> is required, we recommend that you include
        at least <strong>2 product videos</strong> on your page, so that buyers
        can get more acquainted with the features and handling of your product.
      </p>
    </>
  );

  if (validationTier.priceStart === 0) {
    introText = (
      <p>
        Videos are not required, but let's be honest: everybody loves a good
        video. If you have a product video, then definitely include it.
      </p>
    );
  } else if (validationTier.priceStart === 151) {
    introText = (
      <p>
        Videos are not required, but let's be honest: everybody loves a good
        video. We recommend that you include at least{' '}
        <strong>1 product video</strong> on your page, so that buyers can get
        more acquainted with the features and handling of your product.
      </p>
    );
  }

  return (
    <>
      {introText}
      <p>
        <em>
          Please note: each video must be a maximum of 100 MB, with a minimum
          resolution of 1280x720 pixels.
        </em>
      </p>
    </>
  );
}

function getDocumentsTooltip(validationTier: ProductValidationTier) {
  let introText = (
    <p>
      Although only <strong>2</strong> are required, we recommend that you add
      at least <strong>5 supporting documents</strong>. This can be a
      combination of spec sheets, product manuals, schematics, or anything else
      you think a buyer might need to rest assured knowing that he/she is buying
      the right product.
    </p>
  );

  if (validationTier.priceStart === 0) {
    introText = (
      <p>
        Although not required, we recommend that you add at least{' '}
        <strong>1 supporting document</strong>. This can be a spec sheet,
        product manual, or anything else you think a buyer might need to rest
        assured knowing that he/she is buying the right product.
      </p>
    );
  } else if (validationTier.priceStart === 151) {
    introText = (
      <p>
        Although only <strong>1</strong> is required, we recommend that you add
        at least <strong>2 supporting documents</strong>. This can be a
        combination of spec sheets, product manuals, schematics, or anything
        else you think a buyer might need to rest assured knowing that he/she is
        buying the right product.
      </p>
    );
  }

  return (
    <>
      {introText}
      <p>
        <em>
          Each document must be 25 MB or less. For clarity, please use this
          format when naming the document: Brand name_Product name_Document
          type_Month/Year
        </em>
      </p>
    </>
  );
}
