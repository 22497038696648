

export default interface ProductStock {
    id?: number;
    stockId?: number;
    productVariantId?: number;
    warehouseId?: number;
    availableForPickup?: boolean;
    availableForShipping?: boolean;
    deliveryLeadDays?: number;
    postOrderShippingCost?: boolean;
    available?: boolean;
    type?: string;
}


export const empty: ProductStock = {
    availableForPickup: false,
    availableForShipping: false,
    deliveryLeadDays: 1,
    postOrderShippingCost: false,
    available: false,
    type:''
};