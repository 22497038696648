import React from 'react';
import styled, { css } from 'styled-components';
import { Sizes } from 'types/enumTypes';
import { Height } from 'styles/themeHeights';
import { Space } from 'styles/themeSpaces';
import { Main, Shade, Background } from 'styles/themeColors';
import { BorderRadius } from 'lib/styles';

const Container = styled.div<any>`
  border-radius: ${BorderRadius.medium};
  margin: ${({ margin }) => margin};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Background.white};
  max-width: fit-content;

  ${({ color }) => color && css`
  color: ${color};
`}
  ${({ bgColor }) => bgColor && css`
    background-color: ${bgColor};
  `}

  ${({ theme }) => theme === 'accent' && css`
    background-color: ${Main.accent};
  `}

  ${({ theme }) => theme === 'warning' && css`
    background-color: ${Main.warning};
    color: ${Background.black};
  `}

  ${({ theme }) => theme === 'danger' && css`
    background-color: ${Main.error};
  `}

  ${({ theme }) => theme === 'success' && css`
    background-color: ${Main.success};
  `}

  ${({ size }) => size === 'm' && css`
    height: ${Height.m};
    width: ${Height.m};
    padding: ${Space.spBasePlus};
  `}

  ${({ size }) => size === 's' && css`
    height: ${Height.sPlus};
    width: ${Height.sPlus};
    padding: ${Space.spHalfPlus};
  `}

  ${({ size }) => size === 'xs' && css`
    height: ${Height.xsPlus};
    width: ${Height.xsPlus};
    padding: 1px 3px;
  `}

  ${({ size }) => size === 'xsPlus' && css`
    padding: ${Space.spHalf};
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  `}
`;

const Input = styled.div<any> `
  font-size: 12px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
  height: 16px;
  padding: ${({ padding }) => padding};
`;

interface Props {
  children?: string;
  className?: string;
  theme?: string;
  size?: Sizes;
  margin?: string;
  padding?: string;
  color?: string;
  bgColor?: string;
};

const Pill = ({ children, className, theme, margin, size, padding, color, bgColor }: Props) => {
  if (typeof children !== 'string' || children.length === 0) return null;

  return (
    <Container className={className} theme={theme} margin={margin} size={size} bgColor={bgColor} color={color}>
      <Input padding={padding}>
        {children}
      </Input>
    </Container>
  );
};

Pill.defaultProps = {};

export default React.memo(Pill);
