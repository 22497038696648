import React, { useEffect, useState } from 'react';
import Modal from 'components/UI/Modal';
import InputComponent from 'components/UI/Input';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { Space } from 'styles/themeSpaces';
import Button from 'components/UI/Button';
import { Main } from 'styles/themeColors';
import { CircularProgress } from '@material-ui/core';

export interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: (value: string) => void;
  saveAnyway: (value: string) => void;
  salesOrderNumber: string;
  isLoading?: boolean;
  isError?: boolean;
  setError: (value: boolean) => void;
  isTimeout?: boolean;
  setTimeout: (value: boolean) => void;
}
const SalesOrderModal = ({
  show,
  onClose,
  onConfirm,
  saveAnyway,
  salesOrderNumber,
  isLoading,
  isError,
  setError,
  isTimeout,
  setTimeout
}: Props) => {
  const [sONumber, setSalesOrderNumber] = useState<string>(salesOrderNumber);

  useEffect(() => {
    setSalesOrderNumber(salesOrderNumber);
    setTimeout(false);
    setError(false);
  }, [salesOrderNumber]);

  return (
    <Modal
      showModal={show}
      onClose={onClose}
      title={
        !isTimeout
          ? 'Sales order number'
          : 'Sales order integration does not respond'
      }
      ghostButtonText="Cancel"
      primaryButtonText="Save"
      showButtons={false}
      modalFooterMargin="0"
    >
      {!isTimeout ? (
        <FlexboxContainerComponent flexDirection="column">
          <InputComponent
            id="order-billing-source-offline-input-id"
            labelName="Sales order #"
            labelPosition="top"
            value={sONumber}
            placeholder=""
            maxLength="100"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSalesOrderNumber(e.target.value)
            }
            isInvalid={isError}
            errorMessage=''
            name="sales-order-number"
          />
          {isError && (
            <p style={{ color: '#C82135', fontSize: '12px', margin: 0 }}>
              Such order number is not found
            </p>
          )}
        </FlexboxContainerComponent>
      ) : (
        <FlexboxContainerComponent flexDirection="column">
          <p style={{ color: '#0E141A', fontSize: '16px', margin: 0 }}>
          Please try again later or contact Customer support.
          </p>
        </FlexboxContainerComponent>
      )}
      {!isError && !isTimeout ? (
        <FlexboxContainerComponent
          className="modal-footer"
          justifyContent="flex-end"
          margin={`${Space.sp4x} 0 0 0`}
        >
          <Button
            id="modal-ghost-button"
            isGhost
            color="#666c73"
            borderColor='#666c73'
            onClick={() => {
              setSalesOrderNumber(salesOrderNumber);
              setError(false);
              onClose();
            }}
            margin={`0 ${Space.spHalf} 0 0`}
          >
            Cancel
          </Button>
          <Button
            disabled={isLoading}
            borderColor={isLoading ? '#BFC2C5' : Main.accent}
            color={isLoading ? '#BFC2C5' : 'white'}
            bgColor={isLoading ? Main.disabled : ''}
            id="modal-main-button"
            onClick={() => onConfirm(sONumber)}
          >
            {isLoading ? <CircularProgress size="16px" /> : <></>}
            Save
          </Button>
        </FlexboxContainerComponent>
      ) : (
        <FlexboxContainerComponent
          className="modal-footer"
          justifyContent="flex-end"
          margin={`${Space.sp4x} 0 0 0`}
        >
          <Button
            id="modal-ghost-button"
            isGhost
            color="#666C73"
            borderColor="#C1C9D1"
            margin={`0 ${Space.spHalf} 0 0`}
            onClick={() => {setTimeout(false);saveAnyway(sONumber)}}
          >
            {isLoading ? <CircularProgress size="16px" /> : <></>} Save the
            number anyway
          </Button>
          <Button
            borderColor={Main.error}
            bgColor={Main.error}
            id="modal-main-button"
            onClick={() => {
              setSalesOrderNumber(salesOrderNumber);
              setTimeout(false);
              setError(false);
              onClose();
            }}
          >
            Cancel
          </Button>
        </FlexboxContainerComponent>
      )}
    </Modal>
  );
};

export default SalesOrderModal;
