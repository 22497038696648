import { createGlobalStyle } from 'styled-components';
import { theme } from 'OnboardingComponents/OnboardingPageTheme';

export const GlobalStyle = createGlobalStyle<{ theme: typeof theme }>`
  @font-face {
    font-style: italic;
    font-weight: 100;
    font-display: swap;
    src: local("Lato Hairline Italic"), local(Lato-HairlineItalic), url(//fonts.gstatic.com/s/lato/v16/S6u-w4BMUTPHjxsIPx-mPCY.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6u-w4BMUTPHjxsIPx-oPCI.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6u-w4BMUTPHjxsIPx-mPCQ.woff) format("woff");
  }

  @font-face {
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local("Lato Light Italic"), local(Lato-LightItalic), url(//fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI9w2_FQfp.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI9w2_Gwft.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI9w2_FQfr.woff) format("woff");
  }

  @font-face {
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: local("Lato Italic"), local(Lato-Italic), url(//fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAUi-u.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-q.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAUi-s.woff) format("woff");
  }

  @font-face {
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: local("Lato Bold Italic"), local(Lato-BoldItalic), url(//fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI5wq_FQfp.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI5wq_Gwft.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI5wq_FQfr.woff) format("woff");
  }

  @font-face {
    font-style: italic;
    font-weight: 900;
    font-display: swap;
    src: local("Lato Black Italic"), local(Lato-BlackItalic), url(//fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI3wi_FQfp.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI3wi_Gwft.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6u_w4BMUTPHjxsI3wi_FQfr.woff) format("woff");
  }

  @font-face {
    font-weight: 100;
    font-display: swap;
    src: local("Lato Hairline"), local(Lato-Hairline), url(//fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh30AUi-u.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh30AXC-q.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHh30AUi-s.woff) format("woff");
  }

  @font-face {
    font-weight: 300;
    font-display: swap;
    src: local("Lato Light"), local(Lato-Light), url(//fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwaPHQ.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwiPGQ.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh7USSwaPHw.woff) format("woff");
  }

  @font-face {
    font-weight: 400;
    font-display: swap;
    src: local("Lato Regular"), local(Lato-Regular), url(//fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwWg.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXg.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwWA.woff) format("woff");
  }

  @font-face {
    font-weight: 700;
    font-display: swap;
    src: local("Lato Bold"), local(Lato-Bold), url(//fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwaPHQ.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwaPHw.woff) format("woff");
  }

  @font-face {
    font-weight: 900;
    font-display: swap;
    src: local("Lato Black"), local(Lato-Black), url(//fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwaPHQ.eot?#) format("eot"),url(//fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwiPGQ.woff2) format("woff2"),url(//fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwaPHw.woff) format("woff");
  }
`;
