import { Size } from 'lib/styles';
import * as React from 'react';
import styled from 'styled-components';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Background, Main } from 'styles/themeColors';
import Slab from 'view/Slab';
import { useStyles } from './CampaignList';
import { Grid } from '@material-ui/core';
import CampaignLocalization from './CampaignLocalization';
import { CampaignStats } from 'data/Campaign';
import { roundOff } from 'utils/campaign';


const CardSlab = styled(Slab) <any>`
  padding: ${Size.medium} 3rem;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent
  linear-gradient(90deg, ${Main.brandDark} 0%, ${Main.brandLight} 100%) 0% 0%
  no-repeat padding-box;
  box-shadow: 5px 2px 3px #0000004b;
  border-radius: ${BorderRadius.m};
  color: ${Background.white};
  min-width: 250px;
  margin-right: 20px;
  font-weight: 600;
`;


interface Props {
    stats: CampaignStats
    loading: boolean
}  

const CampaignStatistics = (props: Props) => {

    const classes = useStyles();
    const { loading,stats } = props;
    const {
        activeCampaigns,
        campaignsBudget,
        launchCompanies,
        totalCampaigns
    } = stats;
 
    return ( 
        <Grid container className={classes.gridPadding} style={{gap:10}}>
        <CardSlab
            label={CampaignLocalization.activeCampaigns}
            large
            loading={loading}
        >
            {activeCampaigns}
        </CardSlab>

        <CardSlab
            label={CampaignLocalization.totalCampaigns}
            large
            loading={loading}
        >
            {totalCampaigns}
        </CardSlab>

        <CardSlab
            label={CampaignLocalization.campaignsBudget}
            large
            loading={loading}
        >
            {roundOff(campaignsBudget)}
        </CardSlab>

        <CardSlab
            label={CampaignLocalization.launchCompanies}
            large
            loading={loading}
        >
            {launchCompanies}
        </CardSlab>
    </Grid>
     );
}
 
export default CampaignStatistics;