import { get, getBlob, postFormData, post, put } from 'lib/API';

export const getDocumentsForConsignment = async (
  [consignmentId]: any[],
  { token }: any
): Promise<any> => {
  return await get(`v1/documents/consignmentDocuments/${consignmentId}`, token);
};

export const uploadDocumentsForConsignment = async (
  [consignmentId, documents]: any[],
  { token }: any
): Promise<any> => {
  return await postFormData(`v1/documents/consignmentDocuments/${consignmentId}`, documents, token);
};

export const getDocumentCategories = async (
  [vendorId]: any[],
  { token }: any
): Promise<any> => {
  return await get(`v1/documents/documentCategories/${vendorId}`, token);
};

export const getDocumentsForOrder = async (
  [orderId]: any[],
  { token }: any
): Promise<any> => {
  return await get(`v1/documents/orderDocuments/${orderId}`, token);
};

export const downloadDocument = async (
  [documentId, relativePath]: any[],
  { token }: any
): Promise<{ document: any, extension: string | null }> => {
  const extension = /\.[0-9a-z]+$/.exec(relativePath);

  return {
    document: await getBlob(`v1/documents/${documentId}?relativePath=${relativePath}`, token),
    extension: extension ? extension[0] : null
  }
};

export const sendEmailToCustomer = async (
  [customerId, emailBody]: any[],
  { token }: any
): Promise<any> => {
  return await post(`v1/documents/sendDocuments/${customerId}`, token, emailBody);
};

export const create = async ([newCategory, vendorId]: any[], { token }: any): Promise<any> => {
  return await post(`v1/documents/documentCategories/${vendorId}`, token, newCategory);
};

export const update = async ([category, vendorId]: any[], { token }: any): Promise<any> => {
  return await put(`v1/documents/documentCategories/${vendorId}/${category.id}`, token, category);
};

export const createDocumentCategory = async (
  [categories, vendorId]: any[],
  { token }: any
): Promise<any> => {
  return Promise.all(
    categories.map((category: any) => {
      return create([category, vendorId], { token } )
    })
  )
};

export const updateDocumentCategory = async (
  [categories, vendorId]: any[],
  { token }: any
): Promise<any> => {
  return Promise.all(
    categories.map((category: any) => {
      return update([category, vendorId], { token })
    })
  )
};

export const getCompanyDocuments = async (
  [companyId, page, size, offset, vendorId, vendorCode, email]: any[],
  { token }: any
): Promise<any> => {
  let url = `v1/documents/companyDocuments/${companyId}?currentPage=${page}&pageSize=${size}&sharedOnly=true`;
  if(email){
    url += `&userEmail=${encodeURIComponent(email)}`
  }
  if(vendorCode){
    url += `&sharedWith=${vendorCode}`
  }
  return await get(url, token);
};


export const getDocumentsForTicket = async (
  [ticketId]: any[],
  { token }: any
): Promise<any> => {
  return await get(`v1/documents/ticketDocuments/${ticketId}`, token);
};

export const uploadDocumentsForTicket = async (
  [ticketId, documents]: any[],
  { token }: any
): Promise<any> => {
  return await postFormData(`v1/documents/ticketDocuments/${ticketId}`, documents, token);
};
