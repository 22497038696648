import { createGlobalStyle } from 'styled-components';
import { Main, Text, Background, Shade, Border } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { Height } from 'styles/themeHeights';
import { BorderRadius } from 'styles/themeBorderRadius';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${Background.white};
    font-family: 'Lato', sans-serif !important;
    line-height: 1.6;
    margin: 0px;
  }

  a {
    color: ${Main.accent};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  textarea {
    font-family: 'Lato', sans-serif !important;
  }

  h1 {
    font-size: 48px;
    line-height: 56px;
  }

  h2 {
    font-size: 40px;
    line-height: 44px;
  }

  h3 {
    font-size: 32px;
    line-height: 36px;
  }

  h4 {
    font-size: 24px;
    line-height: 28px;
  }

  h5 {
    font-size: 20px;
    line-height: 24px;
  }

  h6 {
    font-size: 16px;
    line-height: 20px;
  }

  h1, h2, h3, h4, h5, h6 {
    color: ${Text.primary};
    font-weight: bold;
    margin-block-start: 0;
    margin-block-end: 0;
  }

  /* Start of MaterialUI style overrides */

  span.MuiCheckbox-colorSecondary.Mui-checked {
    color: ${Main.accent};
  }

  div #react-autowhatever-1 ul.react-autosuggest__suggestions-list {
    list-style-type: none !important;
  }

  div #react-autowhatever-1 ul.react-autosuggest__suggestions-list li.react-autosuggest__suggestion {
    margin-left: -30px;
  }

  div #react-autowhatever-1 ul.react-autosuggest__suggestions-list li.react-autosuggest__suggestion:hover {
    background-color: ${Background.shaded};
    padding: 5px;
    border-radius: ${BorderRadius.m};
    border: solid 1px ${Border.main};
  }

  div #react-autowhatever-1 ul.react-autosuggest__suggestions-list li.react-autosuggest__suggestion span:hover {
    background-color: ${Background.shaded};
  }

  div#wrapper-autosuggest-input>div.MuiInputBase-root.MuiInput-root.MuiInputBase-adornedEnd:hover {
    border: 1px solid ${Main.accent}!important;
  }

  .MuiList-root {
    li.document-category:last-child {
      color: ${Main.accent};
    }
  }

  /* End of MaterialUI style overrides */

  /* Start of custom classes */

  .border {
    border-radius: ${BorderRadius.m};
    border: 1px solid;
    box-sizing: border-box;
  }

  .display1 {
    font-weight: bold;
    font-size: 56px;
    line-height: 64px;
    color: ${Text.primary};
  }

  .display2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 56px;
    color: ${Text.primary};
  }

  .display3 {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: ${Text.primary};
  }

  .base {
    font-size: 16px;
    line-height: 20px;
    color: ${Text.primary};
  }

  .base-paragraph {
    font-size: 16px;
    line-height: 24px;
    color: ${Text.primary};
  }

  .base-strong {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: ${Text.primary};
  }

  .base-italic {
    font-style: italic;
    font-size: 16px;
    line-height: 20px;
    color: ${Text.primary};
  }


  .dense {
    font-size: 14px;
    line-height: 18px;
    color: ${Text.primary};
  }

  .dense-strong {
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: ${Text.primary};
  }

  .dense-italic {
    font-style: italic;
    font-size: 14px;
    line-height: 18px;
    color: ${Text.primary};
  }

  .caption {
    font-size: 12px;
    line-height: 16px;
    color: ${Text.primary};
  }

  .base-paragraph-link {
    line-height: 24px;
  }

  .sidenav {
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    text-transform: uppercase;
    color: ${Text.primary};
  }

  .tab {
    font-weight: bold !important;
    font-size: 16px !important;
    line-height: 18px !important;  
    letter-spacing: 0.02em !important;
    text-align: center !important;
    text-transform: none !important;
    color: ${Text.primary};
  }

  .badge {
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 16px !important;
    border-radius: ${BorderRadius.m} !important;
    color: ${Text.primary};
  }

  .card-light-elevation {
    background: ${Background.white};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: ${BorderRadius.m};
  }

  .card-elevation {
    background: ${Background.white};
    box-shadow: 0px 6px 16px rgba(47, 66, 83, 0.14);
    border-radius: ${BorderRadius.m};
  }

  .card-foreground-elevation {
    background: ${Background.white};
    box-shadow: 0px 16px 20px rgba(47, 66, 83, 0.15);
    border-radius: ${BorderRadius.m};
  }

  .dropdown-elevation {
    background: ${Background.white};
    box-shadow: 0px 4px 8px rgba(75, 78, 81, 0.2);
    border-radius: ${BorderRadius.m};
  }

  .modal-elevation {
    background: ${Background.white};
    box-shadow: 0px 16px 20px rgba(47, 66, 83, 0.15);
    border-radius: ${BorderRadius.m};
  }

  .modal-max-height {
    max-height: 630px !important;
  }

  .ticket-dispute-modal-max-height {
    max-height: 660px !important;
  }

  /* End of custom classes */

  /* Start of scrollbar styles */

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    background: ${Background.white};
    border: 2px solid ${Background.white};;
  }

  ::-webkit-scrollbar-thumb {
    background: ${Shade.shade230};
    border-radius: ${BorderRadius.m};
    border: 2px solid ${Background.white};;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${Shade.shade230};
  }

  /* End of scrollbar styles */

  /* Start of input/textarea placeholder styles */

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${Text.placeholder};
    font-style: italic;
    font-size: 16px;
    line-height: 20px;
    opacity: 1; /* Firefox */
    font-family: 'Lato', sans-serif;
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: ${Text.placeholder};
    font-style: italic;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Lato', sans-serif;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: ${Text.placeholder};
    font-style: italic;
    font-size: 16px;
    line-height: 20px;
    font-family: 'Lato', sans-serif;
  }

  /* End of input/textarea placeholder styles */

  /* Start of toastify custom styles */

  .Toastify__toast-container {
    padding: 0;
  }

  .Toastify__toast {
    background: ${Background.white};
    box-shadow: 0px 16px 20px rgba(47, 66, 83, 0.15);
    border-radius: ${BorderRadius.m};
    color: ${Text.primary};
    width: 320px;
    height: auto;
    padding: ${Space.spBasePlus};
    margin-bottom: 0;
  }

  .Toastify__close-button {
    color: ${Text.disabled};
    margin-left: ${Space.spBasePlus};
    height: ${Height.s};
    width: ${Height.s};
  }

  .Toastify__close-button > svg {
    height: 14px;
    width: 14px;
  }

  .Toastify__toast-body {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: ${Text.primary}
  }

  /* End of toastify custom styles */

  /* Start material Icons styles */

  .material-symbols-rounded.filled {
    font-variation-settings: 'FILL' 1, 'GRAD' 0;
  }

  /* End material Icons styles */

  /* Make placeholder color style consistent over all app */
  input::placeholder {
  color: #71777B !important; /* Replace with the desired color value */ 
  opacity: 1 !important; 
  }

  .text-underlined {
    text-decoration: underline;
  }
`;

export default GlobalStyle;
