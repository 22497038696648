export enum FILE_LOAD_STATUS {
  INITIAL,
  SUCCESS,
  FILE_SIZE_ERROR,
  FILE_TEMPLATE_ERROR,
  FILE_TYPE_ERROR,
}

//First value is 1, to get 'true' while type casting
export enum CONFIRM_MODAL_TYPE {
  CANCEL = 1,
  DELETE,
  PUBLISH,
}