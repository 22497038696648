import React, { useState } from 'react';
import styled from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { DatePicker } from '@material-ui/pickers';
import { FontWeight } from 'lib/styles';
import { Text } from 'styles/themeColors';
import DatePickerInput from 'components/UI/DatePickerInput';
import * as Cons from 'data/Consignment';
import { TEXT_LABELS } from 'constants/order';
import { formatDateTime } from 'utils/formatters';

const LabelText = styled.span`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
  margin-bottom: 5px;
  color: ${Text.primary};
  font-size: 15px;
`;

interface Props {
  shippingConsignmentRequest: Cons.ShippingConsignmentRequest;
  consignmentData: Cons.ConsignmentRentDates;
}

export default function RentalNewDates({ consignmentData, shippingConsignmentRequest}: Props) {
  
  const showNewStartDate = () => {
    if(consignmentData?.newRentalStartDate){
      return formatDateTime(consignmentData?.newRentalStartDate);
    }else if(shippingConsignmentRequest?.rentalNewStartDate){
      return formatDateTime(shippingConsignmentRequest.rentalNewStartDate);
    }else{
      return null;
    }
  }

  const showNewEndDate = () => {
    if(consignmentData?.newRentalEndDate){
      return formatDateTime(consignmentData?.newRentalEndDate);
    }else if(shippingConsignmentRequest?.rentalNewEndDate){
      return formatDateTime(shippingConsignmentRequest.rentalNewEndDate);
    }else{
      return null;
    }
  }

  return (
    <>
      <FlexboxContainer flexDirection="row" width="100%" margin="10px 0px">
        <FlexboxContainer
          flexDirection="column"
          width="400px"
          margin="0 0 0 4px"
        >
          <LabelText>{TEXT_LABELS.CURRENT_START_DATE}</LabelText>
          <DatePicker
            value={formatDateTime(consignmentData.rentalStartDate)}
            inputVariant="outlined"
            format="yyyy MMM dd"
            TextFieldComponent={DatePickerInput}
            onChange={() => {
              return;
            }}
            disabled
            autoOk
          />
        </FlexboxContainer>
        <FlexboxContainer
          flexDirection="column"
          width="400px"
          margin="0 0 0 4px"
        >
          <LabelText>{TEXT_LABELS.CURRENT_END_DATE}</LabelText>
          <DatePicker
            value={formatDateTime(consignmentData.rentalEndDate)}
            inputVariant="outlined"
            format="yyyy MMM dd"
            TextFieldComponent={DatePickerInput}
            onChange={() => {
              return;
            }}
            disabled
            autoOk
          />
        </FlexboxContainer>
      </FlexboxContainer>
      {/* <FlexboxContainer flexDirection="row" width="100%" margin="10px 0px">
        <FlexboxContainer
          flexDirection="column"
          width="400px"
          margin="0 0 0 4px"
        >
          <LabelText>{TEXT_LABELS.NEW_START_DATE}</LabelText>
          <DatePicker
            value={showNewStartDate()}
            inputVariant="outlined"
            format="yyyy MMM dd"
            TextFieldComponent={DatePickerInput}
            onChange={() => null}
            autoOk
            disabled
          />
        </FlexboxContainer>
        <FlexboxContainer
          flexDirection="column"
          width="400px"
          margin="0 0 0 4px"
        >
          <LabelText>{TEXT_LABELS.NEW_END_DATE}</LabelText>
          <DatePicker
            value={showNewEndDate()}
            inputVariant="outlined"
            format="yyyy MMM dd"
            TextFieldComponent={DatePickerInput}
            onChange={() => null}
            autoOk
            disabled
          />
        </FlexboxContainer>
      </FlexboxContainer> */}
    </>
  );
}
