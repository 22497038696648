import React from 'react';
import styled from 'styled-components';
import { PricebookExtended, PricebookType } from 'data/Pricebook';
import Icon from 'view/Icon';
import { Text } from 'styles/themeColors';


const RowCompanyPricebooksIcon = styled(Icon).attrs({
  name: 'RowCompanyPricebooksIcon',
  size: 20,
})<{ showHierarchy?: boolean; }>`
  color: ${Text.placeholder} !important;
  margin-left: ${({ showHierarchy }) => showHierarchy ? '64px' : 0};
  margin-right: 5px;
`;

const RowReferencePricebooksIcon = styled(Icon).attrs({
  name: 'Public',
  size: 24,
})`
  color: ${Text.placeholder} !important;
  margin-right: 5px;
`;

const RowGeographyPricebooksIcon = styled(Icon).attrs({
  name: 'PinDrop',
  size: 24,
})<{ showHierarchy?: boolean; }>`
  color: ${Text.placeholder} !important;
  margin-left: ${({ showHierarchy }) => showHierarchy ? '64px' : 0};
  margin-right: 5px;
`;

const PricebookHierarchyArrow = styled(Icon).attrs({
  name: 'PricebookHierarchyArrow',
  size: 38,
})`
  color: ${Text.placeholder} !important;
  position: absolute;
  transform: translate(20px, -14px);
`;

interface Props {
  pricebook: PricebookExtended;
  showAsHierarchy?: boolean;
}
const HierarchyCell = ({ pricebook, showAsHierarchy = false }: Props) => {
  const isCompany = pricebook?.priceBookType === PricebookType.COMPANY;
  const isGeography = pricebook?.priceBookType === PricebookType.GEOGRAPHY;
  const isReference = pricebook?.priceBookType === PricebookType.REFERENCE;
  const showHierarchyArrow = showAsHierarchy && (isCompany || isGeography);

  return <>
    {isReference && (<RowReferencePricebooksIcon />)}
    {showHierarchyArrow && <PricebookHierarchyArrow />}
    {isCompany && (<RowCompanyPricebooksIcon showHierarchy={showHierarchyArrow}/>)}
    {isGeography && (<RowGeographyPricebooksIcon showHierarchy={showHierarchyArrow}/>)}
    {pricebook.priceBookType?.toLowerCase()}
  </>
}

export default HierarchyCell;
