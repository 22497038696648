import React from 'react';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { currencyFormatter, formatDateTime } from 'utils/formatters';
import OrderInformationTable from './OrderInformationTable';
import { CreditInfo } from 'data/VendorCompany';
import { ACH, CREDIT_CARD, PURCHASE_ORDER } from 'constants/paymentTypes';
import Pill from 'components/UI/Pill';
import { isDateOlderThan } from 'utils/date';
import InputComponent from 'components/UI/Input';
import styled from 'styled-components';
import { Space } from 'styles/themeSpaces';

const InfoSectionWrapper = styled(FlexboxContainer)`
  row-gap: ${Space.sp3x};
  column-gap: ${Space.sp3x};
`;

interface OrderCustomer {
  customerId: string;
  companyName: string;
  email: string;
  phone: string;
  fullName: string;
};

interface OrderDetailsInformationProps {
  customer: OrderCustomer;
  companyName: string;
  newCompanyName: string;
  credit?: CreditInfo;
  paymentMethod: string;
  taxExempt: string;
  deliveryAddress?: string;
  warehouse?: string
  show: boolean;
  showShippingInformation?: boolean;
  pickupLocation?: string;
  creditCheck?: string;
  pickupContactDetails?: string;
};

const OrderDetailsInformation = ({
  customer,
  companyName,
  newCompanyName,
  credit,
  paymentMethod,
  taxExempt,
  deliveryAddress,
  warehouse,
  show,
  showShippingInformation,
  pickupLocation,
  creditCheck,
  pickupContactDetails

}: OrderDetailsInformationProps) => {
  const renderOrderType = (paymentMethod: string) => {
    switch (paymentMethod) {
      case PURCHASE_ORDER:
        return 'Purchase order (PO)';
      case CREDIT_CARD:
        return 'Credit card';
      case ACH:
        return ACH;
      default:
        return '';
    }
  };

  const showConnectionIssueDate = credit && credit.isReadOnly && isDateOlderThan(credit.lastUpdated!, 1);
  const connectionIssueDateInputColor = credit && credit.isReadOnly && isDateOlderThan(credit.lastUpdated!, 5)
    ? 'error'
    : 'warning';

  const creditDataSource =
    <InputComponent
      disabled
      id="order-billing-source-offline-input-id"
      labelName="Credit data source offline since"
      labelPosition="top"
      value={formatDateTime(credit?.lastUpdated)}
      backgroundColor={connectionIssueDateInputColor}
    />

  const renderCreditCheck = (creditCheck?: string) => {
    switch (creditCheck) {
      case 'Passed':
      case 'Override':
        return (<FlexboxContainer><Pill theme='success' size='xsPlus' padding='4px'>{creditCheck}</Pill></FlexboxContainer>)
      case 'Failed':
      case 'Rejected':
        return (<FlexboxContainer><Pill theme='danger' size='xsPlus' padding='4px'>{creditCheck}</Pill></FlexboxContainer>);
      default:
        return (<FlexboxContainer><Pill theme='danger' size='xsPlus' padding='4px'> Not available </Pill></FlexboxContainer>);
    }
  };

  const creditCheckStatus = renderCreditCheck(creditCheck)

  const reformatIfNotAvailable = (value: any) => {
    if (!value && value !== 0) return ' ';

    const credit = currencyFormatter.format(value);

    return credit;
  };

  const creditLimitValue = reformatIfNotAvailable(credit?.totalCreditLimit);
  const availableCreditValue = reformatIfNotAvailable(credit?.availableCreditLimit);

  return (
    <InfoSectionWrapper flexWrap='wrap'>
      <OrderInformationTable
        heading='Customer'
        rowName1='Company'
        rowValue1={companyName}
        rowName2='Full name'
        rowValue2={customer.fullName}
        rowName3='Email'
        rowValue3={customer.email}
        rowName4='Phone'
        rowValue4={customer.phone}
        secondaryText={newCompanyName}
      />
      {
        paymentMethod === PURCHASE_ORDER
          ? (
            <OrderInformationTable
              heading='Billing'
              rowName1='Payment method'
              rowValue1={renderOrderType(paymentMethod)}
              rowName2='Credit check'
              rowValue2={creditCheckStatus}
              rowName3="Credit available"
              rowValue3={credit ? `${availableCreditValue} / ${creditLimitValue}` : null}
              rowName4={showConnectionIssueDate ? 'Credit data source' : null}
              rowValue4={showConnectionIssueDate ? creditDataSource : null}
              rowName5='Tax exempt'
              rowValue5={taxExempt}
            />
          )
          : (
            <OrderInformationTable
              heading='Billing'
              rowName1='Payment method'
              rowValue1={renderOrderType(paymentMethod)}
              rowName2='Tax exempt'
              rowValue2={taxExempt}
            />
          )
      }
      {
        show
          ? (
            showShippingInformation ? (
              <OrderInformationTable
                heading='Shipping'
                rowName1='Ship to'
                rowValue1={deliveryAddress || ''}
                rowName2='From warehouse'
                rowValue2={warehouse || ''}
              />
            )
              : (
                <OrderInformationTable
                  heading='Pickup'
                  rowName1='Pickup location'
                  rowValue1={pickupLocation || ''}
                  rowName2='Warehouse'
                  rowValue2={warehouse || ''}
                  rowName3='Pickup contact'
                  rowValue3={pickupContactDetails || ''}
                />
              )
          ) : null
      }
    </InfoSectionWrapper>
  );
};

export default React.memo(OrderDetailsInformation);
