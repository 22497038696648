import React from 'react';
import styled from 'styled-components';
import { Size } from 'lib/styles';
import shTitle from 'images/sh-ofmp-transparent-bg.png';
import { navigate } from 'hookrouter';

const Sidebar = styled.div`
  background: #18222B;
  display: flex;
  flex-direction: column;
  width: 220px;
  justify-content: flex-start;
  align-items: center;
  padding: ${Size.small} ${Size.medium} 0px;
  z-index: 999;
  min-height: 100vh;
  max-height: 100%;
`;

const Nav = styled.nav`
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 50px;
  padding: 0 30px;
  margin-top: 20px; 
`;

const UserNav = styled.div`
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 30px;
`;
export const Img = styled.img`
  height: auto;
  width: 80%;
  margin-top: 10px;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
  align-self: flex-start;
`;

export interface Props {
  navLinks: React.ReactNode;
  userLinks: React.ReactNode;
}

const PageSideNav = ({ navLinks, userLinks }: Props) => {

  return (
    <Sidebar>
      <Img alt="Seller Hub" onClick={() => navigate("/")} src={shTitle}/>
      <Nav>{navLinks}</Nav>
      <UserNav>{userLinks}</UserNav>
    </Sidebar>
  );
}

export default PageSideNav;