export function alpha(a: any, b: any): number {
  return String(a)
    .toLowerCase()
    .localeCompare(String(b).toLowerCase());
}

export function alphaProp(
  key: any,
  a: Record<any, any>,
  b: Record<any, any>
): number {
  return String(a[key])
    .toLowerCase()
    .localeCompare(String(b[key]).toLowerCase());
}

export function unique<T>(x: T, index: number, xs: T[]): boolean {
  return xs.indexOf(x) === index;
}
