import { DateTime } from 'luxon';

export const formatDateTime = (
  date?: string,
  displayTime: boolean = false,
  displayWeekday: boolean = false,
  placeholder: string = ''
) => {
  if (!date) {
    return placeholder;
  }

  const newDate = new Date(date).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'long',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).split(/\s/g);

  if (displayTime && displayWeekday) {
    return `${newDate[3]} ${newDate[1]} ${newDate[2]} ${newDate[0]} ${newDate[4]}`.replace(/,/, '').replace(/,([^,]*)$/, '$1');
  } else if (displayTime) {
    return `${newDate[3]} ${newDate[1]} ${newDate[2]} ${newDate[4]}`.replace(/,/, '');
  } else if (displayWeekday) {
    return `${newDate[3]} ${newDate[1]} ${newDate[2]} ${newDate[0]}`.replace(/,/, '').replace(/,([^,]*)$/, '$1');
  }

  return `${newDate[3]} ${newDate[1]} ${newDate[2]}`.replace(/,/g, '');
};

export const getLocalTimezone = () => {
  const dt = DateTime.local()

  return dt.offsetNameShort;
};

export const formatAddress = (address: string) => {
  return address.split(',').join(', ');
};

export const formatDeliveryAddress = (
  addressLine1: string,
  addressLine2: string,
  town: string,
  state: string,
  postalCode: string
) => {
  if (!addressLine1 || !town || !state || !postalCode) {
    return '';
  }

  return `${addressLine1}${addressLine2 ? ' ' + addressLine2 : ''}, ${town}, ${state}, ${postalCode}`;
};

export const formatAddressMultiline = (
  addressLine1: string,
  addressLine2: string,
  town: string,
  region: string | undefined,
  postalCode: string,
  country: string
) => {
  const line1 = `${addressLine1 ? addressLine1 : ''}${addressLine2 ? ' ' + addressLine2 : ''}`;
  const line2 = `${town ? town : ''}, ${region ? region : ''}, ${postalCode ? postalCode : ''}`;
  const line3 = `${country ? country : ''}`

  return { line1, line2, line3 };
};

export const formatPhone = (phone: string) => {
  if (!phone) {
    return '';
  }

  const newPhone = phone.split(/[- ]/);

  return `${newPhone[0]} ${newPhone[1]} ${newPhone[2]}-${newPhone[3]}`;
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const currencySign = (country:any,value:number) =>{
  return country === 'US' ? currencyFormatter.format(value) : `C${currencyFormatter.format(value)}`
}

export const capitalize = (sentence: string, placeholder: string = '') => {
  if (!sentence) return placeholder;

  let words = sentence.toLowerCase().split(' ');

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].substring(1);
  }

  return words.join(' ');
};

export const getYoutubeVideoId = (url: string | undefined) => {
  if(!url){
    return null;
  }
  const videoIdMatch = url.match(/^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[?&][^\s]*)?$/);
  if (videoIdMatch && videoIdMatch[1]) {
    return videoIdMatch[1];
  }
  return null;
}


// Helper function to capitalize the first letter of the field name
export const capitalizeFirstLetter = (str:string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}