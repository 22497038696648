import { getPropertyByKeyPath } from './object';
import { sortDirection } from '../constants/sort';

export const latest = (array: any[], dateKey: string) => {
  const dateFrom = (date: any): any => new Date(date);

  const latestDate = new Date(Math.max.apply(null, array.map((item) => dateFrom(item[dateKey]))));

  return array.find(item => item[dateKey].toString().localeCompare(latestDate));
};

export const first = (array: any) => {
  if (array && array.length > 0) {
    return array[0];
  }

  return null;
};

export const any = (array?: any[]) => array && array.some(item => item);

export const merge = (
  olds: any[],
  news: any[],
  compareKey: any,
  keepKeys: any[]
) => {
  if (!any(news)) return olds;

  return news.map(newItem => {
    const duplicate = olds.find(oldItem => oldItem[compareKey] === newItem[compareKey]);
    const model = { ...newItem };

    if (!duplicate) return model;

    for (const keepKey of keepKeys) {
      // overwrite the new model with the old model's value only if its available
      if (duplicate[keepKey]) model[keepKey] = duplicate[keepKey];
    }

    return model;
  });
};

export const groupObjectsByField = (
  objects: any[],
  fieldPath: string,
  sort: string = sortDirection.ASC,
  itemsPerPage: number = 10
): any[][] => {
  const allFieldValues = objects.map(object => getPropertyByKeyPath(fieldPath, object));
  
  if (sort === sortDirection.DESC) {
    allFieldValues.sort().reverse();
  }

  const uniqueFieldValues = new Set<any>(allFieldValues);
  const groupedObjects: any[][] = [];

  uniqueFieldValues.forEach((uniqueFieldValue) => {
    // Group objects by unique field value
    const group = objects.filter(
      (object) => getPropertyByKeyPath(fieldPath, object) === uniqueFieldValue
    );

    // If group length exceeds itemsPerPage, split into smaller groups
    if (group.length > itemsPerPage) {
      for (let i = 0; i < group.length; i += itemsPerPage) {
        groupedObjects.push(group.slice(i, i + itemsPerPage));
      }
    } else {
      groupedObjects.push(group);
    }
  });

  return groupedObjects;
};

export const separateWithLengthLimit = (objects: any[], limit: number) => {
  const result = [];

  for (let i = 0; i < objects.length; i += limit) {
    result.push(objects.slice(i, i + limit));
  }

  return result;
}
