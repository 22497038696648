import React, { useState, useEffect } from 'react';
import { useRoutes, usePath, navigate } from 'hookrouter';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import * as User from 'data/User';
import Vendor from 'data/Vendor';
import FeatureFlag from 'data/FeatureFlag';
import adminRoutes from 'admin/routes';
import vendorRoutes from 'editor/routes';
import SideNavigation from 'common/SideNavigation';
import VendorSelectorLogo from 'view/VendorSelectorLogo';
import VendorSelectorModal from 'view/VendorSelectorModal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Breadcrumbs from 'components/UI/Breadcrumb';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { renderToast } from 'components/UI/ToastNotification';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { Color, Percent } from 'lib/styles';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import Button from 'components/UI/Button';
import { Space } from 'styles/themeSpaces';
import usePathState from 'hooks/usePathState';
import { loadFeatureFlags } from 'requests/featureFlag';
import { loadVendor, loadVendors } from 'requests/vendor';
import { loadUserPreferences } from 'requests/user';
import { Main } from 'styles/themeColors';
import { ArrowBackIos } from '@material-ui/icons';
import { fetchMetaData } from '../requests/meta';
import { save } from '../lib/SessionStorage';

const PageLayout = styled.div`
  display: inline-flex;
  width: ${Percent.full};
`;

const MainPage = styled.div`
  width: ${Percent.full};
  display: flex;
  justify-content: center;
  overflow-x: auto;
`;


const BackButton = styled(Button)`
  margin-left: ${Space.sp3x};
  margin-top: ${Space.sp3x};
`;

const Root = () => {
  const { user, token } = useAuth();
  const path = usePath();
  const isVendor = User.isVendor(user!);
  const [userRoutes, setUserRoutes] = useState({});
  const [vendorsList, setVendorsList] = useState<Vendor[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);
  const [showVendorSelector, setShowVendorSelector] = useState<Boolean>(false);
  const [vendorState, setVendorState] = useState(false);
  const [featureFlags, setFeatureFlags] = useState<FeatureFlag>({});
  const [userPreferences, setUserPreferences] = useState<User.UserPreferences | null>(null);
  const crumbs = useBreadcrumbs(path);
  const showBreadcrumbs = path.includes('details') && !path.includes('product-details') && !path.includes('document') && !path.includes('order-details') && !path.includes('split-order-details') && !path.includes('company-details');
  const { currentPath, previousPath } = usePathState();

  const metaDataReq = useAPI({
    promiseFn: fetchMetaData,
    onResolve: result => {
      if(result.meta) {
        const { brands, countries, productStatuses, vendors, countryStates } = result.meta;
        if(brands) save('brands', brands);
        if(countries) save('countries', countries);
        if(productStatuses) save('productStatuses', productStatuses);
        if(countryStates) save('countryStates', countryStates);

        if(result.meta.featureFlags) {
          setFeatureFlags(result.meta.featureFlags);
        }
        
        if (isVendor) {
          setSelectedVendor(vendors);
          setVendorsList([vendors]);
        } else {
          setVendorsList(vendors);
        }
      }
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const userPreferencesReq = useAPI({
    promiseFn: loadUserPreferences,
    onResolve: result => {
      setUserPreferences(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    if (isVendor) {
      setUserRoutes(vendorRoutes);
    } else {
      setUserRoutes(adminRoutes);
    }
  }, []);

  useEffect(() => {
    hideVendorSelectorModal();
  }, [path]);

  const updateVendor = (vendorName: string) => {
    if (vendorsList) {
      const newVendor = vendorsList.find(vendor => vendor.name === vendorName);
      setSelectedVendor(newVendor ? newVendor : null);
      setVendorState(true);
    }
  }

  const showVendorSelectorModal = () => {
    setShowVendorSelector(true);
  };

  const hideVendorSelectorModal = () => {
    setShowVendorSelector(false);
  };

  let page = useRoutes(userRoutes);

  if (page !== null && !isVendor) {
    page = page({ user, selectedVendor, updateVendor, vendorsList, featureFlags, userPreferences, userPreferencesReq, setVendorsList });
  } else if (page !== null && isVendor) {
    page = page({ user, selectedVendor, updateVendor,vendorsList, featureFlags, userPreferences, userPreferencesReq, setVendorsList });
  }

  if (metaDataReq.isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <PageLayout>
      <SideNavigation user={user!} featureFlags={featureFlags} />
      <MainPage>
        <FlexboxContainer flexDirection="column" width="100%">
          <FlexboxContainer justifyContent="space-beetwen" bgColor={Color.lightGray}>
            {
              currentPath.includes('document') && previousPath?.includes('order-details')
                ? (
                  <BackButton
                    isGhost
                    color={Main.accent}
                    borderColor="transparent"
                    onClick={() => navigate(previousPath)}
                    leftIcon={() => <ArrowBackIos />}
                  >
                    Back
                  </BackButton>
                )
                : null
            }
            {showBreadcrumbs ? <FlexboxContainer padding="10px 30px"><Breadcrumbs crumbs={crumbs} /> </FlexboxContainer> : null}
          </FlexboxContainer>
          {page}
          {
            isVendor
              ? null
              : <VendorSelectorLogo onClick={showVendorSelectorModal} selectedVendor={selectedVendor!} />
          }
          {
            showVendorSelector
              ? <VendorSelectorModal
                isVendor={vendorState}
                setIsVendor={setVendorState}
                updateVendor={updateVendor}
                selectedVendor={selectedVendor!}
                vendors={vendorsList}
                hideVendorSelectorModal={hideVendorSelectorModal}
              />
              : null
          }
        </FlexboxContainer>
      </MainPage>
    </PageLayout>
  );
}

export default Root;
