import React from 'react';

import { HorizontalRule, Card, Heading1, Heading2, Heading4, Paragraph, List, ListItem, InlineLink } from 'OnboardingComponents/Elements';


const PromoResources = () => {
  return (
    <Card>
      <Heading1>Promo resources</Heading1>
      <Heading4>Just a few pre-designed creative elements to get you started promoting your availability on OFMP</Heading4>
      <Paragraph>Once you have submitted your products and received approval for them to go live, it’s time to get people to visit your pages. There are many channels immediately at your disposal for making this happen. The content and creative that we’ve built for you below should make it that much easier to implement key tactics quickly and see those sales come in!</Paragraph>
      <HorizontalRule />

      <Heading2 id="Email">Email</Heading2>
      <Paragraph>This is a banner that fits perfectly under your email signature and is a must-have if you want to get the word out there. All you have to do is download one of the banners (choose from our static and animated ones) and insert it into your email signature settings.</Paragraph>
      <Paragraph>In case your tech team is swamped, here are some instructions for adding visuals to your signature: for <InlineLink href="https://www.uaex.edu/support-units/information-technology/O365/mail/mail_sig_outlook.aspx">Outlook</InlineLink> and <InlineLink href="https://www.ditoweb.com/blog/2014/04/make-image-in-your-gmail-signature/">Gmail</InlineLink>. If you’re using Gmail, the visual you insert needs to be hosted publicly, so use <InlineLink href="/resources/OFMP_Email Banner Static.png" target="_blank">this URL for the static banner</InlineLink> and <InlineLink href="/resources/OFMP_Animated Email Banner.gif" target="_blank">this URL for the animated one</InlineLink>.</Paragraph>
      <Paragraph>Don’t forget that you should also make it easy for readers to go search for your products after seeing your banner. For this, be sure to hyperlink your chosen banner with this URL: <InlineLink href="https://www.theofmp.com/">https://www.theofmp.com/</InlineLink></Paragraph>
      <HorizontalRule />

      <Heading2 id="Auto-reply email">Auto-reply email</Heading2>
      <Paragraph>We have also designed a simple auto-reply email template that you can use for web inquiries that you receive regarding your products. This shows your website visitors that you acknowledge their request and will reply to them ASAP, but that they can also find your products ready for purchase on OFMP if they’re in a hurry.</Paragraph>
      <Paragraph>You can find <InlineLink href="/resources/Auto-Reply Web Inquiries.pdf" target="_blank">that template here</InlineLink> for copying and pasting, along with <InlineLink href="/resources/OFMP_Email_InquiryBanner.gif" target="_blank">the animated banner</InlineLink> that we suggest including for better engagement.</Paragraph>
      <HorizontalRule />

      <Heading2 id="Social media">Social media</Heading2>
      <Paragraph>What better way to get customers talking about how your products are available online than by sparking the conversation where they are? We hope you’re using social media channels like LinkedIn, Facebook, Twitter, and Instagram to find your audience and create a community for your brand -- or at least planning to.</Paragraph>
      <Paragraph>Here are some great social media cover photos that you can post across the platforms you use to garner traction on your OFMP product pages:</Paragraph>
      <List>
        <ListItem>
          <InlineLink href="/resources/social/OFMP Facebook Cover Photo.png" target="_blank">Facebook cover photo</InlineLink>
        </ListItem>
        <ListItem>
          <InlineLink href="/resources/social/OFMP Linkedin Business Cover Photo.png" target="_blank">LinkedIn cover photo</InlineLink>
        </ListItem>
        <ListItem>
          <InlineLink href="/resources/social/OFMP Twitter Header Photo.png" target="_blank">Twitter header image</InlineLink>
        </ListItem>
        <ListItem>
          <InlineLink href="/resources/social/OFMP YouTube Cover Photo.png" target="_blank">YouTube cover photo</InlineLink>
        </ListItem>
      </List>
      <Paragraph>And here are some additional images, as well:</Paragraph>
      <List>
        <ListItem>
          <InlineLink href="/resources/social/OFMP_ForSellers_FindUs_Facebook.png" target="_blank">Facebook post image</InlineLink>
        </ListItem>
        <ListItem>
          <InlineLink href="/resources/social/OFMP_ForSellers_FindUs_LinkedIn.png" target="_blank">LinkedIn post image</InlineLink>
        </ListItem>
        <ListItem>
          <InlineLink href="/resources/social/OFMP_ForSellers_FindUs_Twitter.png" target="_blank">Twitter post image</InlineLink>
        </ListItem>
        <ListItem>
          <InlineLink href="/resources/social/OFMP Insta Story.png" target="_blank">Instagram story image</InlineLink>
        </ListItem>
      </List>
      <HorizontalRule />

      <Heading2 id="Stamp">Stamp</Heading2>
      <Paragraph>This is a cool one. We’ve created a digital stamp that you can add to any social media imagery, videos, flyers, brochures, banners, or other materials that you design for your brand. It’s an easy, non-intrusive way to remind current and potential customers that your products are available on OFMP, regardless of the campaign you’re running. We’ve even created multiple color combinations of the stamp that <InlineLink href="/resources/OFMP_Stamp.zip" target="_blank">you can download</InlineLink>.</Paragraph>
      <HorizontalRule />

      <Heading2 id="Sticker">Sticker</Heading2>
      <Paragraph>We all know that it’s the little things that matter. And we also know that oilfield folks really like their stickers. That’s why we’ve designed some clean, slick ones that are ready for print. <InlineLink href="/resources/OFMP_Sticker.zip" target="_blank">Download this file</InlineLink>, choose the color you want (black or white), send it to your local printer, and bring the stickers with you to events and customer meetings on and off the field. They’ll love it.</Paragraph>
      <HorizontalRule />

      <Heading2 id="Roll-up">Roll-up</Heading2>
      <Paragraph>If you thought you were tired of roll-ups, then you’re in for a surprise. We’ve managed to turn this classic event tactic into a modern and eye-catching presentation of why customers should visit OFMP for their oilfield needs. Use it to educate passersby on the newly-available option of purchasing oil and gas products like yours quickly and securely online.</Paragraph>
      <Paragraph>The <InlineLink href="/resources/OFMP_Roll-up.zip" target="_blank">ready-for-print file is here</InlineLink> all ready to go.</Paragraph>
    </Card>
  );
};

export default PromoResources;
