import React, { Fragment } from 'react';
import { Heading2, Paragraph } from 'OnboardingComponents/Elements';

const Newsletter = () => (
  <Fragment>
    <Heading2 id='newsletter'>Your newsletter</Heading2>
    <Paragraph>If you have a newsletter, include a carousel section with the top products that you have available on The OFMP and add a “Buy Now” button that people can use to click through to purchase them.</Paragraph>
    <Paragraph>Whenever you present a new product, specify its availability on The Oilfield Marketplace. You can mention something along the lines of “Prices next to products. Secure payment. Nationwide delivery..” and a “Buy Now” button for better engagement (and sales). </Paragraph>
    <Paragraph>In any imagery that you use to promote your products via your newsletter(s), you can use the “Now on TheOFMP.com” stamp to quickly communicate their availability for immediate purchase.</Paragraph>
  </Fragment>
);

export default Newsletter;
