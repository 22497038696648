import React from 'react';
import { Quill } from 'react-quill';
import Icon from './Icon';
import styled from 'styled-components';

const insertCharacter = (character: string, quill: any) => {
  const cursorPosition = quill.getSelection().index;
  quill.insertText(cursorPosition, character);
  quill.setSelection(cursorPosition + 1);
};

function insertCopyright(this: { quill: any }) {
  insertCharacter('©', this.quill);
}

function insertTM(this: { quill: any }) {
  insertCharacter('™', this.quill);
}

function insertRTM(this: { quill: any }) {
  insertCharacter('®', this.quill);
}

function insertSM(this: { quill: any }) {
  insertCharacter('℠', this.quill);
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: insertCopyright,
      TM: insertTM,
      RTM: insertRTM,
      SM: insertSM,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
  clipboard: {
    matchVisual: false
}
};

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
];

const ToolbarButtonIcon = styled(Icon).attrs((props) => ({
  size: 16,
}))``;

// Quill Toolbar component
export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      <button className="ql-strike" />
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="ql-formats">
      <button className="ql-undo">
        <ToolbarButtonIcon name={'Copyright'} />
      </button>
      <button className="ql-TM">
        <ToolbarButtonIcon name={'Trademark'} />
      </button>
      <button className="ql-RTM">
        <ToolbarButtonIcon name={'RegisteredTrademark'} />
      </button>
      <button className="ql-SM">
        <ToolbarButtonIcon name={'ServiceMark'} />
      </button>
    </span>
  </div>
);

export default QuillToolbar;
