import InputComponent from "components/UI/Input";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import FlexboxContainerComponent from "components/UI/FlexboxContainer";
import { Text } from "styles/themeColors";
import { vendorValidations } from "constants/errorMessages";
import { REMINDERS_DAYS_LIMIT, VENDOR_GENERAL_SETTINGS } from "constants/vendor";



const Description = styled.p<{ disabled?: boolean }>`
    margin: 0;
    color: ${({ disabled }) => (disabled ? Text.disabled : Text.primary)};
`
interface GeneralSettingsProps {
    disabled: boolean;
    vendor: any;
    setVendor: (vendor: any) => void;
    errors: any;
    setErrors: any;
}

const GeneralSettings: FunctionComponent<GeneralSettingsProps> = (props) => {
    const { disabled, vendor, setVendor, errors } = props;

    const onReminderDaysChange = (rentReminderDays: string) => {
        const days = parseInt(rentReminderDays) || '';
        
        // Validate the input
        if (!days || (days < REMINDERS_DAYS_LIMIT.MIN || days > REMINDERS_DAYS_LIMIT.MAX)) {
            props.setErrors({ 
                ...props.errors, 
                rentReminderDays: vendorValidations.REMINDERS_DAYS_LIMIT.replace('{MIN}', REMINDERS_DAYS_LIMIT.MIN.toString()).replace('{MAX}', REMINDERS_DAYS_LIMIT.MAX.toString())
            });
        } else {
            props.setErrors({ 
                ...props.errors, 
                rentReminderDays: '' 
            });
        }

        setVendor({ ...vendor, rentReminderDays: days });
    };

    return (
        <FlexboxContainerComponent flexDirection="column">
            <InputComponent
                id='reminder-days'
                type="number"
                disabled={disabled}
                placeholder={VENDOR_GENERAL_SETTINGS.REMINDER_DAYS_PLACEHOLDER}
                labelName={VENDOR_GENERAL_SETTINGS.REMINDER_DAYS_LABEL}
                onInputChange={onReminderDaysChange}
                value={vendor.rentReminderDays}
                margin="0 0 0 0"
                width="30%"
                isInvalid={errors.rentReminderDays}
                errorMessage={errors.rentReminderDays}
            />
            <Description disabled={disabled}>
                {VENDOR_GENERAL_SETTINGS.REMINDER_DESCRIPTION}
            </Description>
        </FlexboxContainerComponent>
    );
};

export default GeneralSettings;