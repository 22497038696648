import styled from 'styled-components';

export const GridContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const GridItem = styled.div`
  flex: 1;
  margin-right: 1rem;

  ${GridContainer} > &:last-child {
    margin-right: 0;
  }
`;

