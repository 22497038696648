import React, { useState, useEffect, ChangeEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Badge from '@material-ui/core/Badge';
import TabPanel from 'components/UI/TabPanel';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Main, Text, Background } from 'styles/themeColors';
import { BorderRadius } from 'styles/themeBorderRadius';
import { MainColors } from 'types/enumTypes';
import { Space } from 'styles/themeSpaces';
import { Height } from 'styles/themeHeights';
import NavLink from 'view/NavLink';
import { navigate } from 'hookrouter';
import { TAB_NUMBERS, WAREHOUSE_ERROR } from 'constants/product';

const TabsContainer = styled.div<any>`
  .app-bar {
    background-color: ${Background.white};
    border: none;
    box-shadow: none;
  }
`;

const StyledTabs = styled(Tabs) <any>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid !important;
  border-color: ${({ theme }: Props) => Main[theme]} !important;

  .Mui-selected {
    background-color: #FC780B !important;
  }
`;

const StyledTab = styled(Tab) <any>`
  color: ${({ color }) => color} !important;
  min-width: 10px !important;
  max-width: 240px !important;
  min-height: 36px !important;
  opacity: 1 !important;
  border-radius: ${({ borderRadius }: Props) => borderRadius ? borderRadius : `${BorderRadius.m} ${BorderRadius.m} 0 0`} !important;
  padding-top: ${({ padding }: Props) => padding ? padding : '0px' } !important;
  padding-bottom: ${({ padding }: Props) => padding ? padding : '0px' } !important;
  margin: ${({ margin }: Props) => margin ? margin : '0px' } !important;
  font-family: 'Lato', sans-serif !important;

  ${({$active}) => !$active && css `
    &:hover {
      background-color: ${({ theme }: Props) => theme === 'accent' 
        ? 'rgba(73, 141, 218, 0.1)'
        : Main['brandLightest']} !important;
    }
  `}

  .MuiTab-wrapper div {
    align-items: center !important;
  }
`;

// Don't pass color and bgColor props to MaterialUI Badge component to avoid console warnings
const StyledBadge = styled(({color, bgColor, ...rest}: any) => <Badge {...rest} />)`
  .MuiBadge-badge{
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color};
    min-width: 20px;
    position: static;
    border-radius: ${BorderRadius.m} !important;
    margin-left: ${Space.spHalf};
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    transform: none !important;
  }
`;

interface TabBadge {
  content: string;
  color: string;
  backgroundColor: string;
};

interface Tab {
  value: string;
  badge: TabBadge;
  link: string
};

interface Props {
  className?: string;
  tabs: Tab[];
  activeTab?: number;
  margin?: string;
  padding?: string;
  height?: string;
  borderRadius?:string;
  tabPanelPadding?: string;
  theme: MainColors;
  setActiveTab?: (number: number | undefined) => void;
  canGoToNextTab: (tabNum: number)=>boolean;
  variantErrorsCount ?: number;
  warehouseErrorCount?: number;
  loading: boolean
};

const TabsBarComponent = ({
  className,
  tabs,
  activeTab,
  margin,
  padding,
  height,
  borderRadius,
  theme,
  setActiveTab,
  canGoToNextTab,
  variantErrorsCount,
  warehouseErrorCount,
  loading
}: Props) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    newValue !== 0 && canGoToNextTab && canGoToNextTab(newValue) && setValue(newValue);
    if(setActiveTab){
      setActiveTab(newValue);
    }
  };

  useEffect(() => {
    if (activeTab || activeTab === 0) {
      setValue(activeTab);
    }
  }, [activeTab]);

  return (
    <TabsContainer>
      <AppBar
        position="static"
        className="app-bar"
        style={{ padding, margin, height }}
      >
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="Tabs"
          TabIndicatorProps={{ style: { display: 'none' } }}
          theme={theme}
        >
          {
            tabs.map((tab, index) => {
              const badge = tab.badge.content ? (
                <StyledBadge
                  badgeContent={tab.badge.content}
                  color={tab.badge.color }
                  bgColor={tab.badge.backgroundColor}
                  classes={{ badge: 'badge' }}
                />
              ) : null;

              const renderTab = (
                <FlexboxContainer
                >
                  {tab.value}
                  {badge}
                  { index === TAB_NUMBERS.VARIANTS && variantErrorsCount ? <p className='error-badge'> {variantErrorsCount} </p> : ''  }
                  { index === TAB_NUMBERS.WAREHOUSES && warehouseErrorCount && !loading ? <p className='error-badge'> {warehouseErrorCount} </p> : ''  }
                </FlexboxContainer>
              );

              return (
                <StyledTab
                onClick={()=>{
                  navigate(tab.link)
                }}
                  className={`tab ${className}`}
                  key={index}
                  label={renderTab}
                  color={value === index ? Text.whitePrimary : Text.secondary}
                  theme={theme}
                  length={tabs.length}
                  padding={padding}
                  margin={margin}
                  borderRadius={borderRadius}
                  $active={value === index}
                  {...a11yProps(index)}
                />
              )
            })
          }
        </StyledTabs>
      </AppBar>
    </TabsContainer>
  );
};

TabsBarComponent.defaultProps = {
  activeTab: 0,
  theme: 'accent',
  className: '',
  margin: '0',
  height: Height.m
};

const a11yProps = (index: number) => {
  return {
    id: `company-tab-${index}`,
    'aria-controls': `company-tabpanel-${index}`,
  };
};

export default TabsBarComponent;
