import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { FontSize } from 'lib/styles';
import Vendor from 'data/Vendor';
import { useAuth } from 'lib/Auth';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { formatDateTime } from 'utils/formatters';
import UploadButton from 'view/UploadButton';
import { Main, Border, Gradients } from 'styles/themeColors';
import Textarea from 'components/UI/Textarea';
import Button from 'components/UI/Button';
import { renderToast } from 'components/UI/ToastNotification';
import { navigate, useQueryParams } from 'hookrouter';
import Select from 'components/UI/Select';
import { isVendor, isVendorAdmin } from 'data/User';
import { getDocumentCategories, uploadDocumentsForConsignment, createDocumentCategory, updateDocumentCategory } from 'requests/documents';
import { useAPI } from 'lib/API';
import { Background } from 'styles/themeColors';
import { removeAtIndex, addOrReplace, update } from 'lib/Array';
import { formatBytes } from 'utils/convertors';
import Modal from 'components/UI/Modal';
import Input from 'components/UI/Input';
import Icon from 'view/Icon';
import { DeleteForever } from '@material-ui/icons';
import { orderDocuments } from 'constants/errorMessages';
import { MAX_COMMENT_LENGTH, validateComment } from 'lib/validation';

const Heading = styled.h3`
  font-size: ${FontSize.large};
  margin: 0 20px 16px;
`;

const Container = styled.div`
  border: 1px solid ${Border.light};
  box-sizing: border-box;
  margin: 0 20px 16px;
  padding: 16px;
  border-radius: 6px;
  background-color: ${Background.white};
`;

const ModalContainer = styled.div`
  overflow: auto;
  padding: 8px;
  background-color: #F0F1F4;
  max-height: 350px;
  border-radius: 6px;
  flex-direction: column;

  ::-webkit-scrollbar-track {
    background: transparent;
    border: none;
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid #f0f1f4;
  }
`;

const CurrentDate = styled.span`
  min-width: 110px;
`;

const StyledFlexboxContaier = styled(FlexboxContainer) <any>`
  height: 94px;
  padding: 6px 0 0 6px;
  border: 1px solid ${Border.light};
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 2px 3px 3px rgba(39, 44, 57, 0.15);
`;

const StyledFlexbox = styled(FlexboxContainer) <any>`
  padding: 6px;
`;

const FileUploadButton = styled(UploadButton) <any>`
  width: 40%;
  margin: 8px 0 0;
  border-color: ${Main.accent};
  display: flex;
  align-items: center;
 
  ${({ $withAnimation }) => $withAnimation && css`
    background: ${Gradients.navyToSuccessGreen};
    background-size: 300% 100%;
    background-position: 100% 0%;
    border-color: transparent !important;
    
    animation: backgroundColorChange 1s linear; /* Alternate animation */
    animation-fill-mode: forwards;
  
    @keyframes backgroundColorChange {
      from {
        background-position: 100% 0;
      }
      to {
        background-position: -1px 0;
      }
    }
  `}

  ${({ $isDisabled }) => $isDisabled && css`
    pointer-events: none;
    cursor: auto;
  `}

  ${({ $fullWidth }) => $fullWidth && css`
    width: 100%;
  `}

  ${({ color }) => color && css`
    color: ${color};
  `}
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 20px;
`
const Span = styled.span`
  height: 36px;
  padding: 8px;
`;

interface Props {
  consignmentId?: string;
  selectedVendor: Vendor;
  vendorsList?: Vendor[];
};

const OrderDocuments = ({
  consignmentId,
  selectedVendor,
  vendorsList
}: Props) => {
  const [queryParams, setQueryParams] = useQueryParams();
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  const [isUploadMedia, setIsUploadMedia] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [categoriesToUpdate, setCategoriesToUpdate] = useState<any[]>([]);
  const [requiredStatus, setRequiredStatus]= useState<any[]>([]);
  const [newDocuments, setNewDocuments] = useState<any>({
    imageFile: [],
    comment: '',
    fileCategories: [],
    creationDate: new Date().toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    }),
    createdBy: ''
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [isCommentInvalid, setIsCommentInvalid] = useState(false);
  const { user } = useAuth();


  const onCommentChange = (value: string) => {
    setIsCommentInvalid(false);
    setNewDocuments({ ...newDocuments, comment: value });
  };

  const getDocumentCategoriesReq = useAPI({
    deferFn: getDocumentCategories,
    onResolve: result => {
      setCategories(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const uploadDocumentsForConsignmentReq = useAPI({
    deferFn: uploadDocumentsForConsignment,
    onResolve: () => {
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
      setIsDisabled(false);
    }
  });

  useEffect(()=>{
    if(uploadDocumentsForConsignmentReq.isSettled && !uploadDocumentsForConsignmentReq.isPending){
      navigate(`/orders/order-details/${consignmentId?.slice(4)}`);
      renderToast(toast.TYPE.SUCCESS, 'All attachments added to order');
      setIsDisabled(false);
    }
  }, [uploadDocumentsForConsignmentReq.isSettled, uploadDocumentsForConsignmentReq.isPending, consignmentId])

  const createDocumentCategoryReq = useAPI({
    deferFn: createDocumentCategory,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'New category created successfully.');
      getDocumentCategoriesReq.reload();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const updateDocumentCategoryReq = useAPI({
    deferFn: updateDocumentCategory,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Category updated successfully.');
      getDocumentCategoriesReq.reload();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    if (queryParams.vendorId) {
      getDocumentCategoriesReq.run(queryParams.vendorId);
    }
  }, [queryParams.vendorId]);

  useEffect(() => {
    if ((isVendor(user!) || isVendorAdmin(user!)) && selectedVendor) {
      setNewDocuments({ ...newDocuments, createdBy: selectedVendor.name });
    } else if (vendorsList && vendorsList.length > 0 && queryParams.vendorId) {
      const vendor = vendorsList.find(vendor => vendor.id === parseInt(queryParams.vendorId));

      setNewDocuments({ ...newDocuments, createdBy: vendor!.name });
    }
  }, [vendorsList, selectedVendor, queryParams.vendorId]);

  const uploadMedia = async (file: File) => {
    setIsUploadingMedia(true);

    setNewDocuments({
      ...newDocuments,
      imageFile: [...newDocuments.imageFile, file]
    });

    setIsUploadingMedia(false)
    setIsUploadMedia(true);
    setRequiredStatus([...requiredStatus,false]);
  };

  const currentDate = new Date();
  const formattedDate = formatDateTime(currentDate.toDateString());

  const setCategoryValue = (value: any, fileName: any) => {
    const foundCategory = categories.find((category: any) => category.name === value);

    if (foundCategory) {
      const newFileCategory = {
        fileName,
        fileCategory: foundCategory.id
      };

      const fileCategories = addOrReplace(
        newDocuments.fileCategories,
        obj => obj.fileName,
        newFileCategory
      );

      setNewDocuments({ ...newDocuments, fileCategories });
      
      let index= newDocuments.imageFile.findIndex((x:any)=> x.name== fileName );
      let copyRequiredStatus= [...requiredStatus];
      copyRequiredStatus[index]=false;
      setRequiredStatus([...copyRequiredStatus]);
    }
  };

  const expandedCategories = [...categories, {
    id: -1,
    name: '',
    isEditable: true,
    isVisible: true
  }]

  const deleteCategory = (index: number) => {
    const updatedCategoriesToDelete = update(categories, index, category => ({ ...category, isVisible: false }));

    setCategories(updatedCategoriesToDelete);
    setCategoriesToUpdate([...categoriesToUpdate, updatedCategoriesToDelete[index]]);
  }

  const categoryNameChange = (index: number, newName: string) => {
    const updateCategories = update(categories, index, category => ({ ...category, name: newName }));
    const updatedCategories = addOrReplace(
      categoriesToUpdate,
      category => category.id,
      updateCategories[index]
    );
    setCategories(updateCategories)
    setCategoriesToUpdate(updatedCategories);
  }

  const addCategory = (index: number) => {
    if (expandedCategories.length - 1 === index && expandedCategories[index - 1].name) {
      setCategories([...categories, {
        name: '',
        isEditable: true,
        isVisible: true
      }])
    }
  }

  const onCancelModalClick = () => {
    getDocumentCategoriesReq.reload()
    setShowEditCategoryModal(false)
  };

  const onConfirmModalClick = () => {
    const filteredCategoriesToUpdate = categoriesToUpdate.filter((updateCategory: any) => updateCategory.id && updateCategory.id !== undefined && updateCategory.id !== null && updateCategory.name);

    let reloaded = false;
    if (filteredCategoriesToUpdate.length > 0) {
      updateDocumentCategoryReq.run(filteredCategoriesToUpdate, queryParams.vendorId);
      reloaded = true;
    }

    const newCategories = categories.filter((category: any) => !category.id && category.id === undefined && category.name);
    if (newCategories.length > 0) {
      createDocumentCategoryReq.run(newCategories, queryParams.vendorId);
      reloaded = true;
    }

    if(!reloaded) getDocumentCategoriesReq.reload();

    setShowEditCategoryModal(false)
  };

  const onAttachDocuments = () => {
    if (
      newDocuments.imageFile.length > 0 &&
      newDocuments.imageFile.length === newDocuments.fileCategories.length
    ) {
      setIsDisabled(true);
      const { imageFile, fileCategories, ...rest } = newDocuments;
      imageFile.map(async (item: any, index: number) => {
        uploadDocumentsForConsignmentReq.run(consignmentId, {
          ...rest,
          imageFile: [item],
          fileCategories: [fileCategories[index]],
        });
      });
    } else { 
     let requiredStatusCopy= [...requiredStatus];
     newDocuments.imageFile.forEach((x:any,index:number) => {
        requiredStatusCopy[index]=newDocuments.fileCategories.find((category:any)=> category.fileName=== x.name) ? false : true; 
      });
      setRequiredStatus([...requiredStatusCopy]);
    }
  };
  
  const onDeleteClick = (index: number) => {
    setNewDocuments({
      ...newDocuments,
      imageFile: removeAtIndex(newDocuments.imageFile, index),
      fileCategories: newDocuments.fileCategories.filter(
        (fileCategory: any) => fileCategory.fileName !== newDocuments.imageFile[index].name
      )
    });
  };

  const editCategory = {
    value: 'Edit categories'
  };

  const categoriesOptions = categories
    .filter(category => category.isVisible)
    .map((category: any) => ({
      value: category.name
    }));

  const onBlur = () => {
    setIsCommentInvalid(validateComment(newDocuments.comment, MAX_COMMENT_LENGTH));
  }

  const customCategories = expandedCategories.map((category, index) => {
    return (
      category.isEditable && category.isVisible ? (
        <FlexboxContainer key={index} margin='0 0 4px' padding='8px' bgColor='white' borderRadius='6px' alignItems='center'>
          <FlexboxContainer flexDirection='raw' width='100%' alignItems='center'>
            <Input
              id={category.name}
              width='662px'
              placeholder='+ Add category name'
              value={category.name || ''}
              onInputChange={(newName) => categoryNameChange(index, newName)}
              onClick={() => addCategory(index)}
            />
            {
              index !== categories.length
                ? <DeleteForever className='material-symbols-rounded filled' onClick={() => deleteCategory(index)} style={{ fontSize: 28, marginLeft: 8, cursor: 'pointer' }} />
                : null
            }
          </FlexboxContainer>
        </FlexboxContainer>
      ) : (
        category.isVisible
          ? (
            <FlexboxContainer key={index} margin='0 0 4px' padding='8px' bgColor='white' borderRadius='6px'>
              <Span> {category.name} </Span>
            </FlexboxContainer>
          ) : null
      )
    )
  });

  const editCategoryModal = (
    <Modal
      width='800px'
      top='20%'
      left='30%'
      onClose={onCancelModalClick}
      onClick={onConfirmModalClick}
      showModal={showEditCategoryModal}
      title='Edit categories'
      primaryButtonText='Save'
    >
      <ModalContainer>
        {customCategories}
      </ModalContainer>
    </Modal>
  );

  const cardsList = newDocuments.imageFile.map((card: any, index: number) => {
    const foundFileCategory = newDocuments.fileCategories.find((fileCategory: any) => fileCategory.fileName === card.name);
    const category = foundFileCategory ? categories.find((category: any) => category.id === foundFileCategory.fileCategory) : {};

    return (
      <FlexboxContainer justify-content="space-between" className={`test-${index}`} key={index} margin={'0 0 4px'}>
        <CurrentDate>{index === 0 ? formattedDate : ''}</CurrentDate>
        <FlexboxContainer flexDirection='column' width='100%'>
          <StyledFlexboxContaier flex-direction='column'>
            <StyledFlexbox>
              <Icon name='Draft' size={34} />
            </StyledFlexbox>
            <FlexboxContainer width="100%">
              <FlexboxContainer width="40%" flexDirection="column">
                <FileUploadButton
                  spinner={isUploadingMedia}
                  fileUploaded={isUploadMedia}
                  theme='success'
                  $isDisabled
                  $fullWidth
                  $withAnimation
                  acceptTypes={['image/jpg', 'image/png', 'application/doc', 'application/pdf', 'application/rtf']}
                  label={card.name}
                />
                <Label>{formatBytes(card.size, 1)}</Label>
              </FlexboxContainer>
              <FlexboxContainer margin='8px 0 0 8px' width='60%'>
                <Select
                   isInvalid={requiredStatus[index]}
                  options={[...categoriesOptions, editCategory]}
                  optionClassName="document-category"
                  defaultValue="Select category"
                  value={category && category.name ? category.name : ''}
                  onChange={
                    event => event.target.value === "Edit categories"
                      ? setShowEditCategoryModal(true)
                      : setCategoryValue(event.target.value, card.name)
                  }
                />
                <FlexboxContainer margin='6px 0 0 14px'>
                  <DeleteForever className='material-symbols-rounded filled' onClick={() => onDeleteClick(index)} style={{ marginRight: 6, cursor: 'pointer' }} />
                </FlexboxContainer>
              </FlexboxContainer>
            </FlexboxContainer>
          </StyledFlexboxContaier>
        </FlexboxContainer>
      </FlexboxContainer>
    );
  });

  return (
    <FlexboxContainer
      flexDirection="column"
      borderRadius='6px'
      padding="16px"
      width="100%"
      bgColor={Background.shaded}
    >
      <Heading>New set of documents</Heading>
      <Container>
        {cardsList}
        <FlexboxContainer>
          <CurrentDate>{newDocuments.imageFile.length === 0 ? formattedDate : ''}</CurrentDate>
          <FlexboxContainer flexDirection='column' width='100%'>
            <FlexboxContainer flexDirection='column' width='100%'>
                <StyledFlexboxContaier flex-direction='column'>
                  <StyledFlexbox >
                  <Icon name='Draft' size={34} />
                  </StyledFlexbox>
                  <FlexboxContainer flexDirection='column' width='100%'>
                    <FileUploadButton
                      isGhost
                      color={Main.accent}
                      icon
                      acceptTypes={['image/jpg', 'image/png', 'application/doc', 'application/pdf', 'application/rtf']}
                      label="Select file"
                      onChange={uploadMedia}
                    />
                    <Label>Pdf, jpg, png, rtf, doc</Label>
                  </FlexboxContainer>
                </StyledFlexboxContaier>
              </FlexboxContainer>
            <FlexboxContainer flexDirection='column' padding={'16px 0 0'}>
              <Textarea
                maxLength={1024}
                id='comment-id'
                label='Leave a comment for your customer'
                placeholder='Optional'
                rows={3}
                value={newDocuments.comment}
                onChange={onCommentChange}
                isInvalid={isCommentInvalid}
                onBlur={onBlur}
                errorMessage={orderDocuments.MAX_COMMENT_LENGTH}
              />
            </FlexboxContainer>
          </FlexboxContainer>
        </FlexboxContainer>
        <FlexboxContainer justifyContent='flex-end' margin="16px 0">
          <Button
            isGhost
            margin="0 6px 0 6px"
            isSecondary
            borderColor='#c1c9d1'
            onClick={() => navigate(`/orders/order-details/${consignmentId?.slice(4)}`)}
          >
            Cancel
          </Button>
          <Button
            disabled={isDisabled || isCommentInvalid}
            bgColor={Background.navy}
            borderColor={Background.navy}
            onClick={onAttachDocuments}
          >
            Attach to order
          </Button>
        </FlexboxContainer>
      </Container>
      {editCategoryModal}
    </FlexboxContainer>
  );
};

export default OrderDocuments;
