export const isDateOlderThan = (date: string, days: number) => {
  const today: any = new Date();
  const exactDate: any = new Date(date);
  const daysHours = 24 * days;
  const daysMiliseconds = 60 * 60 * daysHours * 1000;

  return (today - exactDate) > daysMiliseconds;
};

export const numberOfDaysPassed = (date: string) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const today: any = new Date();
  const exactDate: any = new Date(date);

  return Math.round(Math.abs((today - exactDate) / oneDay));
};

export const toDate = (dateString?: string) => {
  if (!dateString) return null;
  const date = new Date(dateString);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate()); // Strip time portion
};

export const isWeekend = (datetime: any) => {
  const day = datetime?.weekday;
  return day === 6 || day === 7; // Disable Sunday (7) and Saturday (6)
}