import Vendor from 'data/Vendor';
import BrandVendor from './BrandVendor';

export interface Logo {
  id: number;
  name: string;
  path: string;
  fileType: string;
  createdOn: string;
};

export default interface Brand {
  id: number;
  name: string;
  description: string;
  code: string;
  media?: Logo[];
  oldMedia?: Logo[];
  keywords: string[];
  vendor: Vendor | null;
  /* Automation Properties Added */
  baseUrl?: string;
  consumerKey?: string;
  consumerSecret?: string;
  authenticationUrl?: string;
  isMaterialApiEnabled?: boolean;
  isStockApiEnabled?: boolean;
  isSalesOrderApiEnabled?: boolean;
  brandVendor?: BrandVendor;
};

export type PartialBrand = Pick<Brand, 'name' | 'media' | 'description' | 'keywords'> & { vendorId: number };

export const empty: Omit<Brand, 'id'> = {
  name: '',
  code: '',
  description: '',
  keywords: [],
  vendor: null,
  media: [{
    id: 0,
    name: '',
    path: '',
    fileType: '',
    createdOn: ''
  }],
  /* Automation Properties Added */
  baseUrl: '',
  authenticationUrl: '',
  consumerKey: '',
  consumerSecret: '',
  isMaterialApiEnabled: false,
  isStockApiEnabled: false,
  isSalesOrderApiEnabled: false,
};
