import { UPDATE_PAGE_FILTER } from 'redux/constants/actionTypes';
import { UpdatePageFilter } from 'redux/types/actions';

export const updatePageFilter = (
  stateKey: string,
  value: boolean
): UpdatePageFilter => ({
  type: UPDATE_PAGE_FILTER,
  stateKey,
  value
});
