import React from 'react';
import styled, { css } from 'styled-components';
import { A, usePath } from 'hookrouter';
import { Size } from 'lib/styles';

const Link = styled(A)<any>`
  &:not(:first-of-type) {
    margin-top: ${Size.medium};
  }

  ${({ current }) =>
    current &&
    css`
      font-weight: 700;
      text-decoration: underline !important;
    `}
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  href: string;
}

export default function SectionLink({ children, className, href }: Props) {
  const path = usePath();

  return (
    <Link className={className} current={path === href ? 1 : 0} href={href}>
      {children}
    </Link>
  );
}
