import {
    getProductValidationTier,
    ProductValidationTierRequirements,
    ProductValidationMinMax
} from 'lib/productValidationTiers';
import Product, * as Prod from 'data/Product';
import Variant from 'data/Variant';
import { isMaterialApiEnabled } from 'utils/materialNumber';
import { Role } from './User';
import { validateRentalRanges } from 'utils/variant';
import { WAREHOUSE_TYPE } from 'constants/warehouseDeliveryOptions';
import { PRICE_TYPE } from 'constants/product';
import { COUNTRY_ISO } from 'constants/country';
import { TEXT_LABELS } from 'constants/productDetails';

export enum ProductErrorType {
    Error = 'Error',
    Warning = 'Warning'
}

export enum VariantErrorType {
    DuplicateUniqueValue = 'DuplicateUniqueValue',
    DuplicateVariant = 'DuplicateVariant',
    MissingValue = 'MissingValue',
    InvalidSKUValue = 'InvalidSKUValue',
    InvalidSKULength = 'InvalidSKULength',
    SKUValidation = 'SKUValidation',
    EmptyAttributeValue = 'EmptyAttributeValue',
    InvalidMaterialNumber = 'InvalidMaterialNumber',
    InvalidMaterialNumberLength = 'InvalidMaterialNumberLength',
    DuplicateMaterialNumber = 'DuplicateMaterialNumber',
    MaterialNumberValidation = 'MaterialNumberValidation',
    EmptyMaterialNumber = 'EmptyMaterialNumber',
    EmptyInventory = 'EmptyInventory',
    EmptySku = 'EmptySku',
    RentMissingPriceUS = 'RentMissingPriceUS',
    RentMissingPriceCA = 'RentMissingPriceCA',
    RentMissingRangeUS = 'RentMissingRangeUS',
    RentMissingRangeCA = 'RentMissingRangeCA',
    RentRangeSkipped = 'RentRangeSkipped',
    RentRangeOrder = 'RentRangeOrder',
    RequiredRentalRange = 'RequiredRentalRange',
    EmptyConsignmentType = 'EmptyConsignmentType',
}

export type ProductError = {
    key: keyof ProductValidationTierRequirements;
    type: ProductErrorType;
};

export interface Validation {
    productErrors: ProductError[];
    totalCount: number;
    variantErrors: VariantError[];
}

export interface VariantError {
    type: VariantErrorType;
    variants: Variant[];
    key?:string
}

export interface MissingValueVariantError extends VariantError {
    type: VariantErrorType.MissingValue;
    key: string;
}

export interface MissingVariantAttributeValueError extends VariantError {
    type: VariantErrorType.EmptyAttributeValue;
    key: string;
}

export interface InvalidSKUValueError extends VariantError {
    type: VariantErrorType.InvalidSKUValue;
    key: string;
}

export interface InvalidSKULengthError extends VariantError {
    type: VariantErrorType.InvalidSKULength;
    key: string;
}

export interface SKUValidationError extends VariantError {
    type: VariantErrorType.SKUValidation;
    key: string;
}

export interface InvalidMaterialNumberError extends VariantError {
    type: VariantErrorType.InvalidMaterialNumber;
    key: string;
}

export interface EmptyMaterialNumberError extends VariantError {
    type: VariantErrorType.EmptyMaterialNumber;
    key: string;
}

export interface EmptyInventoryError extends VariantError {
    type: VariantErrorType.EmptyInventory;
    key: string;
}

export interface InvalidMaterialNumberLengthError extends VariantError {
    type: VariantErrorType.InvalidMaterialNumberLength;
    key: string;
}

export interface MaterialNumberValidationError extends VariantError {
    type: VariantErrorType.MaterialNumberValidation;
    key: string;
}

export interface EmptySKUError extends VariantError {
    type: VariantErrorType.EmptySku;
    key: string;
}

export interface EmptyRentalRangeUS extends VariantError {
    type: VariantErrorType.RentMissingRangeUS;
    key: string;
}

export interface EmptyRentalRangeCA extends VariantError {
    type: VariantErrorType.RentMissingRangeCA;
    key: string;
}

export interface EmptyRentalPriceUS extends VariantError {
    type: VariantErrorType.RentMissingPriceUS;
    key: string;
}

export interface EmptyRentalPriceCA extends VariantError {
    type: VariantErrorType.RentMissingPriceCA;
    key: string;
}

export interface SkippedRentalRange extends VariantError {
    type: VariantErrorType.RentRangeSkipped;
    key: string;
}

export interface RentRangeOrder extends VariantError {
    type: VariantErrorType.RentRangeOrder;
    key: string;
}

export interface EmptyConsignmentType extends VariantError {
    type: VariantErrorType.EmptyConsignmentType;
    key: string;
}

export function validateProduct(product: Product, role: Role | undefined): Validation {
    let variantErrors = [
        ...getDuplicateVariantErrors(product),
        ...getMissingValuesVariantErrors(product),
        ...getDuplicateUniqueValueVariantErrors(product),
        ...getInvalidSKUValueErrors(product),
        ...getInvalidSKULengthErrors(product),
        ...getMissingPriceVariantErrors(product),
        ...getSkuValidationErrors(product),
        ...getMissingAttributeValueNameErrors(product),
        ...getEmptyVariantError(product),
        ...getRentalValidatonError(product),
        ...getDuplicateUniqueMaterialNumberErrors(product),
        ...getInvalidMaterialNumberErrors(product),
        ...getInvalidMaterialNumberLengthErrors(product),
        ...getEmptyConsignmentTypeErrors(product)
    ];

    if(isMaterialApiEnabled(product?.brand?.brandVendor!, product?.vendor!)){
        variantErrors = [
            ...variantErrors, 
            ...getEmptyMaterialErrors(product)
        ]
    }

    const productErrors = getProductErrors(product);

    return {
        productErrors,
        variantErrors,
        totalCount: variantErrors.length + productErrors.length
    };
}


function getDuplicateUniqueValueVariantErrors({
    variants
}: Product): VariantError[] {
    return variants.reduce(
        function (duplicateVariants, variant, index, variants) {
            const existingDuplicateGroup = duplicateVariants.find(
                (v, i) => v.variants[0].sku === variant.sku
            );

            if (existingDuplicateGroup) {
                existingDuplicateGroup.variants.push(variant);
                return duplicateVariants;
            }

            const duplicate = variants.find(
                (v, i) => v.sku === variant.sku && i !== index
            );
            if (duplicate) {
                duplicateVariants.push({
                    type: VariantErrorType.DuplicateUniqueValue,
                    variants: [variant]
                });
            }
            return duplicateVariants;
        },
        [] as VariantError[]
    );
}

function getDuplicateUniqueMaterialNumberErrors({
    variants
}: Product): VariantError[] {
    return variants.reduce(
        function (errors: VariantError[], variant, index, variants) {
            
            if (!variant.materialNumber) {
                return errors;
            }
            
            if(variant.materialNumber && typeof variant.materialNumber === 'string' && variant.materialNumber.trim() === ''){
                return errors;
            }

            const duplicateIndex = variants.findIndex(
                (v, i) => v.materialNumber === variant.materialNumber && i !== index
            );

            if (duplicateIndex !== -1) {
                const existingErrorIndex = errors.findIndex(
                    (error) => error.variants[0].materialNumber === variant.materialNumber
                );

                if (existingErrorIndex !== -1) {
                    errors[existingErrorIndex].variants.push(variant);
                } else {
                    errors.push({
                        type: VariantErrorType.DuplicateMaterialNumber,
                        variants: [variant]
                    });
                }
            }

            return errors;
        },
        [] as VariantError[]
    );
}


function getInvalidSKUValueErrors({ variants }: Product): VariantError[] {
    return variants.reduce((errors: InvalidSKUValueError[], variant) => {
        if (/^[a-zA-Z0-9-_/.]*$/.test(variant.sku) === false) {
            errors.push({
                key: 'sku',
                type: VariantErrorType.InvalidSKUValue,
                variants: [variant]
            });
        }

        return errors;
    }, []);
}

function getInvalidMaterialNumberErrors({ variants }: Product): VariantError[] {
    return variants.reduce((errors: InvalidMaterialNumberError[], variant) => {
        if (/^[a-zA-Z0-9-_/.]*$/.test(variant.materialNumber || '') === false) {
            errors.push({
                key: 'materialNumber',
                type: VariantErrorType.InvalidMaterialNumber,
                variants: [variant]
            });
        }
        return errors;
    }, []);
}

function getEmptyMaterialErrors({ variants }: Product): VariantError[] {
    return variants.reduce((errors: EmptyMaterialNumberError[], variant) => {
        if (!variant.materialNumber) {
            errors.push({
                key: 'materialNumber',
                type: VariantErrorType.EmptyMaterialNumber,
                variants: [variant]
            });
        }
        return errors;
    }, []);
}

export function getEmptyInventoryErrors({ variants, warehouses }: Product): VariantError[] {
    return variants.reduce((errors: EmptyInventoryError[], variant) => {
        warehouses.some((warehouse)=>{
            if(!warehouse.stock){
                return errors.push({
                    key: 'inventory',
                    type: VariantErrorType.EmptyInventory,
                    variants: [variant]
                });
            }
        });
        return errors;
    }, []);
}

function getInvalidSKULengthErrors({ variants }: Product): VariantError[] {
    return variants.reduce((errors: InvalidSKULengthError[], variant) => {
        const maxSKULength = process.env.REACT_APP_SKU_MAX_LENGTH ? parseInt(process.env.REACT_APP_SKU_MAX_LENGTH) : 100;

        if (variant.sku.length > maxSKULength) {
            errors.push({
                key: 'sku',
                type: VariantErrorType.InvalidSKULength,
                variants: [variant]
            });
        }

        return errors;
    }, []);
}

function getInvalidMaterialNumberLengthErrors({ variants }: Product): VariantError[] {
    return variants.reduce((errors: InvalidMaterialNumberLengthError[], variant) => {
        const maxSKULength = process.env.REACT_APP_SKU_MAX_LENGTH ? parseInt(process.env.REACT_APP_SKU_MAX_LENGTH) : 100;

        const materialNumber = variant.materialNumber || '';
        if (materialNumber.length > maxSKULength) {
            errors.push({
                key: 'materialNumber',
                type: VariantErrorType.InvalidMaterialNumberLength,
                variants: [variant]
            });
        }

        return errors;
    }, []);
}

function getEmptyConsignmentTypeErrors({ variants }: Product): VariantError[] {
    return variants.reduce((errors: EmptyConsignmentType[], variant) => {
        if (!variant.purchase && !variant.rent) {
            errors.push({
                key: TEXT_LABELS.CONSIGNMENT_TYPE,
                type: VariantErrorType.EmptyConsignmentType,
                variants: [variant]
            });
        }

        return errors;
    }, []);
}

function getDuplicateVariantErrors(product: Product): VariantError[] {
    const duplicateVariants = Prod.getDuplicateVariants(product.variants);

    return duplicateVariants.map(variants => ({
        type: VariantErrorType.DuplicateVariant,
        variants
    }));
}

function getMissingValuesVariantErrors({ variants }: Product): MissingValueVariantError[] {
    return variants.reduce((errors: MissingValueVariantError[], variant) => {
        errors.push(
            ...checkObjectForBlankKeys(variant),
        );
        return errors;
    }, []);
}

function getMissingPriceVariantErrors(product: Product): MissingValueVariantError[] {
    const errors: MissingValueVariantError[] = []
    for (let i = 0; i < product.variants.length; i++) {
        const variant = product.variants[i];
        const productStocks = variant.productStocks;

        productStocks!.forEach((productStock) => {
            if (variant.purchase) {
                const warehouseId = productStock.warehouseId;
                const associatedWarehouse = product.warehouses.find((warehouse) => warehouse.id === warehouseId)

                if (!!associatedWarehouse && associatedWarehouse.type !== WAREHOUSE_TYPE.RENT) {
                    // Check if pricePerUnit is set for this productStock's country 
                    const countryId = associatedWarehouse?.address?.country?.id;
                    const productVariantPrices = variant.productVariantPrices;
                    const isPricePerUnitExists = !!productVariantPrices!.find((productVariantPrice) => productVariantPrice.countryId === countryId)?.pricePerUnit
                    if (!isPricePerUnitExists && !errors.find((error) => error.key === `pricePerUnit-${countryId}`)) {
                        errors.push({
                            type: VariantErrorType.MissingValue,
                            key: `pricePerUnit-${countryId}`,
                            variants: [variant]
                        });
                    }
                }
            }
        })
    }

    return errors;
}


function getMissingAttributeValueNameErrors(product: Product): MissingVariantAttributeValueError[] {
    const errors: MissingVariantAttributeValueError[] = []
    for (let i = 0; i < product.variants.length; i++) {
        const variant = product.variants[i];
        const attributeValues = variant.attributeValues;
        attributeValues!.forEach((attributeValue) => {
            if(attributeValue.name == null || (typeof attributeValue.name === "string" && attributeValue.name.trim().length === 0)){
              const attributeObject = product.attributes.find(attr => attr.id === attributeValue.attributeId )
                attributeObject && errors.push({
                    type: VariantErrorType.EmptyAttributeValue,
                    key:   attributeObject?.name,
                    variants: [variant]
                });
            }
        })

        // Compare product attribute with variant attribute and find missing and append in errors
        const productAttributes = product.attributes;
        const variantAttributes = variant.attributeValues;
        const missingAttributes = productAttributes.filter(productAttribute => {
            return !variantAttributes.some(variantAttribute => variantAttribute.attributeId === productAttribute.id)
        })

        missingAttributes.forEach(missingAttribute => {
            errors.push({
                type: VariantErrorType.EmptyAttributeValue,
                key: missingAttribute.name,
                variants: [variant]
            });
        })
    }
    return errors;
}


function checkObjectForBlankKeys(
    obj: Record<string, any>,
    variant: Variant = obj as Variant
) {
    return Object.keys(obj).reduce((errors: MissingValueVariantError[], key) => {
        const value = obj[key];
        let error = false;
        let listToNotValidate = ['width', 'length', 'height', 'weight', 'vendorPartNumber', 'manufacturingPartNumber', 
        'code', 'publishedOn', 'deliveryLeadDays', 'postOrderShippingCost', 'pricePerUnit', 'itemType', 'errorMessage', 
        'variantValidationStatus', 'materialKey', 'baseUrl', 'authenticationUrl', 'consumerKey', 'consumerSecret', 
        'materialNumber', 'AttributeValues', 'rentalCode', 'rangeUnits', 'range', 'rangePrice', 'purchase', 'rent'];

        if (listToNotValidate.includes(key)) {
            error = false;
        } else if (!value && typeof value !== 'boolean') {
            error = true;
        } else if (typeof value === 'string' && value.trim().length === 0) {
            error = true;
        } else if (typeof value === 'number' && value === 0) {
            error = true;
        }

        if (error) {
            errors.push({
                key,
                type: VariantErrorType.MissingValue,
                variants: [variant]
            });
        }

        return errors;
    }, []);
}

function getProductErrors(product: Product): ProductError[] {
    const productTier = getProductValidationTier(product);
    const keysToValidate: Array<keyof ProductValidationTierRequirements> = [
        'name',
        // 'description',
        'productImages',
        'contextualImages',
        'videos',
        'documents',
        'brand',
        'sku',
        'category',
        'superCategory'
    ];

    return keysToValidate.reduce((errors: ProductError[], key) => {
        const count = Prod.getValueCount(product, key);

        const errorType = validateCount(
            count,
            productTier.requirements[key],
            productTier.recommendations[key]
        );

        if (errorType !== null) {
            errors.push({
                key,
                type: errorType
            });
        }

        return errors;
    }, []);
}

function validateCount(
    count: number,
    requiredMinMax: ProductValidationMinMax,
    recommendedMinMax: ProductValidationMinMax
): ProductErrorType | null {
    if (
        count < requiredMinMax.min ||
        (requiredMinMax.max && count > requiredMinMax.max)
    ) {
        return ProductErrorType.Error;
    }

    if (
        count < recommendedMinMax.min ||
        (recommendedMinMax.max && count > recommendedMinMax.max)
    ) {
        return ProductErrorType.Warning;
    }

    return null;
}

function getSkuValidationErrors({ variants, vendor, brandVendor, status }: Product): (SKUValidationError | MaterialNumberValidationError)[] {
    return variants.reduce((errors: (SKUValidationError | MaterialNumberValidationError)[], variant) => {
        if ((variant.variantValidationStatus?.name === 'INVALID' || (variant.variantValidationStatus == null && status !== "draft")) && brandVendor?.isMaterialApiEnabled) {
            if (vendor?.isSlbVendor) {
                errors.push({
                    key: 'materialNumber',
                    type: VariantErrorType.MaterialNumberValidation,
                    variants: [variant]
                });
            } else {
                errors.push({
                    key: 'sku',
                    type: VariantErrorType.SKUValidation,
                    variants: [variant]
                });
            }
        }

        return errors;
    }, []);
}

function getEmptyVariantError({ variants }: Product){
    const errors: EmptySKUError[] = []
   if(!variants.length){
    errors.push({
        key: 'sku',
        type: VariantErrorType.EmptySku,
        variants: []
    })
   }
   return errors
}

function getRentalValidatonError({ variants, warehouses }: Product){
    return variants.reduce((errors: (EmptyRentalPriceCA | EmptyRentalPriceUS | EmptyRentalRangeCA | EmptyRentalRangeUS | SkippedRentalRange | RentRangeOrder)[], variant) => {
        const hasUSWarehouse = warehouses.some(x => x.type === PRICE_TYPE.RENT && x.address.country.countryIso === COUNTRY_ISO.US);
        if(hasUSWarehouse){
            let rentalErrors = validateRentalRanges(variant.rentalPriceUS, COUNTRY_ISO.US, variant)
            errors = [...errors, ...rentalErrors];
        }

        const hasCAWarehouse = warehouses.some(x => x.type === PRICE_TYPE.RENT && x.address.country.countryIso === COUNTRY_ISO.CA)
        if(hasCAWarehouse){
            let rentalErrors = validateRentalRanges(variant.rentalPriceCA, COUNTRY_ISO.CA, variant)
            errors = [...errors, ...rentalErrors];
        }

        return errors;
    }, []);
}

