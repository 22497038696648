import React from 'react';
import { FontSize } from 'lib/styles';
import styled from 'styled-components';
import { Space } from 'styles/themeSpaces';
import { Text } from 'styles/themeColors';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import { Edit } from '@material-ui/icons';
import { Height } from 'styles/themeHeights';

const Heading = styled.h3`
  font-size: ${FontSize.medium};
  line-height: 28px;
  margin-bottom: 12px;
`;

const ColumnName = styled.div<any>`
  min-width: 120px;
  font-size: ${Space.sp2x};
  line-height: 20px;
  color: ${({ $color }) => ($color ? $color : Text.primary)};
  margin-right: ${Space.sp3x};
`;

const ColumnValue = styled.div<any>`
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : '16px')};
  line-height: ${({ $lineHeight }) => ($lineHeight ? $lineHeight : '20px')};
  color: ${({ $color }) => ($color ? $color : Text.primary)};
  background-color: ${({ $bgcolor }) => ($bgcolor ? $bgcolor : 'transparent')};
  padding: ${({ $padding }) => ($padding ? $padding : '0px')};
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
  border-radius: 6px;
`;

const HyperlinkButton = styled.h3`
  text-decoration: underline;
  font-size: 16px;
  color: #498dda;
  display: inline-block;

  &:hover {
    cursor: pointer;
  }
`;
interface Props {
  salesOrderId?: any;
  deliveryStatus?: any;
  orderRejectionStatus?: any;
  buttonClick: (value: boolean) => void;
  openSalesOrderDetails: (value: boolean) => void;
  isTimeout?: boolean;
  isAdmin?: boolean;
}
const SalesOrder = ({
  salesOrderId,
  deliveryStatus,
  orderRejectionStatus,
  buttonClick,
  openSalesOrderDetails,
  isTimeout,
  isAdmin,
}: Props) => {
  return (
    <FlexboxContainerComponent
      flexDirection="column"
      padding="16px"
      width="100%"
      margin='0px 0px 24px 0px'
    >
      <FlexboxContainerComponent>
        <Heading>Sales order</Heading>
      </FlexboxContainerComponent>
      <FlexboxContainerComponent alignItems="center" flexDirection="row">
        <ColumnName>Sales order #</ColumnName>
        <ColumnValue $padding="0px 24px 0px 0px">{salesOrderId}</ColumnValue>
        {isAdmin ? (
          salesOrderId ? (
            <Button
              size="s"
              leftIcon={(size: string) => (
                <Edit
                  style={{ height: Height.s, width: '12px', color: '#666C73' }}
                />
              )}
              color="#666C73"
              bgColor="transparent"
              borderColor="#C1C9D1"
              onClick={() => {
                buttonClick(true);
              }}
            >
              Edit
            </Button>
          ) : (
            <Button
              size="s"
              leftIcon={(size: string) => (
                <Edit
                  style={{ height: '12px', width: '12px', color: 'white' }}
                />
              )}
              onClick={() => {
                buttonClick(true);
              }}
            >
              Enter
            </Button>
          )
        ) : (
          <></>
        )}
      </FlexboxContainerComponent>
      {salesOrderId && !isTimeout ? (
        orderRejectionStatus && deliveryStatus ? (
          orderRejectionStatus != '' && deliveryStatus != '' ? (
            <FlexboxContainerComponent flexDirection="column">
              <FlexboxContainerComponent
                margin="6px 0px"
                alignItems="center"
                flexDirection="row"
              >
                <ColumnName>Rejection status</ColumnName>
                <ColumnValue $color="white" $padding="4px" $bgcolor="#18222B" $fontSize='12px' $weight='700' $lineHeight='12px'>
                  {orderRejectionStatus.toString().toUpperCase()}
                </ColumnValue>
              </FlexboxContainerComponent>
              <FlexboxContainerComponent
                alignItems="center"
                flexDirection="row"
              >
                <ColumnName>Delivery status</ColumnName>
                <ColumnValue $color="white" $padding="4px" $bgcolor="#18222B" $fontSize='12px' $weight='700' $lineHeight='12px'>
                  {deliveryStatus.toString().toUpperCase()}
                </ColumnValue>
              </FlexboxContainerComponent>
              <FlexboxContainerComponent>
                <HyperlinkButton
                  onClick={() => {
                    openSalesOrderDetails(true);
                  }}
                >
                  Show all
                </HyperlinkButton>
              </FlexboxContainerComponent>
            </FlexboxContainerComponent>
          ) : (
            <FlexboxContainerComponent flexDirection="column">
              <FlexboxContainerComponent
                margin="6px 0px"
                alignItems="center"
                flexDirection="row"
              >
                <ColumnName>Rejection status</ColumnName>
                <ColumnValue $color="#71777C" $padding="4px" $bgcolor="#F0F1F4">
                  NOT AVAILABLE
                </ColumnValue>
              </FlexboxContainerComponent>
              <FlexboxContainerComponent
                alignItems="center"
                flexDirection="row"
              >
                <ColumnName>Delivery status</ColumnName>
                <ColumnValue $color="#71777C" $padding="4px" $bgcolor="#F0F1F4">
                  NOT AVALIABLE
                </ColumnValue>
              </FlexboxContainerComponent>
              <FlexboxContainerComponent>
                <HyperlinkButton
                  onClick={() => {
                    openSalesOrderDetails(true);
                  }}
                >
                  Show all
                </HyperlinkButton>
              </FlexboxContainerComponent>
            </FlexboxContainerComponent>
          )
        ) : (
          <FlexboxContainerComponent
            padding="18px 0px"
            alignItems="center"
            flexDirection="row"
          >
            <ColumnValue
              $color="#71777C"
              $weight="700"
              $padding="4px"
              $bgcolor="#F0F1F4"
            >
              Such SO does not exist
            </ColumnValue>
          </FlexboxContainerComponent>
        )
      ) : isTimeout && salesOrderId ? (
        <FlexboxContainerComponent
          padding="18px 0px"
          alignItems="center"
          flexDirection="row"
        >
          <ColumnValue $weight="700" $padding="4px" $bgcolor="#FFC107">
            Sales order integration does not respond
          </ColumnValue>
        </FlexboxContainerComponent>
      ) : (
        <></>
      )}
    </FlexboxContainerComponent>
  );
};

export default SalesOrder;
