import React from 'react';
import styled from 'styled-components';
import Customer from 'data/Customer';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import SectionHeading from 'view/SectionHeading';
import { formatPhone } from 'utils/formatters';
import { Color, FontSize, FontWeight } from 'lib/styles';
import { BorderRadius } from "styles/themeBorderRadius";
import Button from 'components/UI/Button';
import { Main, Background } from 'styles/themeColors';
import Checkbox from "components/UI/Checkbox";
import { useState, useEffect } from 'react';
import { useAPI } from 'lib/API';
import { updateTaxExempted } from 'requests/customer';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import { navigate } from 'hookrouter';
import { useAuth } from 'lib/Auth';



const Label = styled.label`
  color: ${Color.dimGray};
  font-size: ${FontSize.small};
`;

const Value = styled.div`
  font-size: 15px;
  font-weight: ${FontWeight.bold};
  margin-bottom: 10px;
`;

const CancelButton = styled(Button)`
  margin-right: 5px;
  color: ${Color.black} !important;
  background-color: ${Background.white};
  border: 1px solid ${Color.veryLightGray} !important;
`;

const SaveButton = styled(Button)`
  padding: 10px;
  border-radius: ${BorderRadius.m};
  background-color: ${Main.brandDark};
  border-color: ${Main.disabled} !important;
`;

const CheckboxContainer = styled.div`
    margin-top: 20px;
`;

const Description = styled.p`
    margin: 0;
`;

interface Props {
  customerDetails: Customer;
};

const CustomerInformation = ({
  customerDetails
}: Props) => {
  const { token } = useAuth();
  const [isTaxExemptedEnabled, setIsTaxExemptedEnabled] = useState(false);
  
  const updateTaxExemptedReq = useAPI({
    deferFn: updateTaxExempted,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Tax exemption status updated.');
    },
    onReject: (err) => {
      renderToast(toast.TYPE.ERROR, err.message);
    },
  });
  
  const onChangeTaxExempted = () => {
    setIsTaxExemptedEnabled(!isTaxExemptedEnabled);
  };

  useEffect(() => {
    if(customerDetails.isTaxExempted){
      setIsTaxExemptedEnabled(customerDetails.isTaxExempted);
    }
  }, [customerDetails.isTaxExempted])

  const handleSave = () => {
    updateTaxExemptedReq.run(customerDetails.id, isTaxExemptedEnabled, token);
  };
  return (
    <>
      <FlexboxContainer justifyContent="space-between" alignItems="center">
        <SectionHeading margin="10px 0">General information</SectionHeading>
      </FlexboxContainer>
      <FlexboxContainer justifyContent="space-between" width="80%" margin="10px 0 0 0">
        <FlexboxContainer flexDirection="column" justifyContent="space-between" alignItems="flex-start">
          <Label>Company name entered by customer</Label>
          <Value>{customerDetails.companyName}</Value>
        </FlexboxContainer>
        <FlexboxContainer flexDirection="column" justifyContent="space-between" alignItems="flex-start">
          <Label>Company email</Label>
          <Value>{customerDetails.email}</Value>
        </FlexboxContainer>
        <FlexboxContainer flexDirection="column" justifyContent="space-between" alignItems="flex-start">
          <Label>Phone</Label>
          <Value>{formatPhone(customerDetails.phone)}</Value>
        </FlexboxContainer>
      </FlexboxContainer>
          {/* <div style={{ width: '100%', border: 'none', display: 'flex', alignItems: 'center' }}>
          <FlexboxContainer>
            <CheckboxContainer>
            <Checkbox
                id="tax-exempted-check"
                name="taxExempted"
                label="Tax Exempted"
                appearance="none"
                checkboxSize="s"
                checked={isTaxExemptedEnabled} 
                onChange={onChangeTaxExempted} 
                theme={'brandLight'}
                bgColor={Main.brandLight}
              />
            </CheckboxContainer>
          </FlexboxContainer>
          </div> */}

          {/* <FlexboxContainer width="100%" border="none">
        <div style={{ marginTop: '50', border: 'none', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <CancelButton key="cancel" onClick={() => { setIsTaxExemptedEnabled(false) }}>
              Cancel
          </CancelButton>
          <SaveButton key="save" onClick={handleSave}>
            Save
          </SaveButton>
        </div>
      </FlexboxContainer> */}


    </>
  );
};

export default CustomerInformation;
