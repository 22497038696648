import React, { Fragment } from 'react';
import { A } from 'hookrouter';
import styled from 'styled-components';
import Icon from 'view/Icon'
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { BorderRadius } from 'lib/styles';

const Link = styled(A) <any>`
  color: ${Text.primary};

  &:hover {
    text-decoration: none;
  }
`;

const BreadcrumbContainer = styled(FlexboxContainer)`
  cursor: pointer;
  margin-left: ${Space.spHalf};
  margin-right: ${Space.spHalf};
  border-radius: ${BorderRadius.medium};

  &:hover {
    background: rgba(123, 97, 255, 0.05);
  }
`;

const StyledSlashIcon = styled(Icon).attrs({ name: 'Slash', size: 16 })`
  color: ${Text.primary};
`;

interface Route {
  name: string;
  path: string;
};

interface Props {
  crumbs: Route[];
  containerMargin?: string;
  containerPadding?: string;
  splitOrderName?: JSX.Element | string | null;
};

const Breadcrumb = ({ crumbs, containerMargin, containerPadding, splitOrderName }: Props) => {
  if (crumbs.length <= 1) {
    return null;
  }

  return (
    <FlexboxContainer margin={containerMargin} padding={containerPadding}>
      {
        crumbs.map(({ name, path }, key) =>
          key + 1 === crumbs.length ? (
            <Fragment key={key}>
              {
                name === 'Order details' || name === 'Split order details' || name === 'Company details' 
                  ? splitOrderName
                  : <BreadcrumbContainer key={key} className="base" alignItems="center" padding={Space.spBase}>{name}</BreadcrumbContainer>
              }
            </Fragment>
          ) : (
            <FlexboxContainer key={key} alignItems="center">
              <Link key={key} className="base" href={path}>
                <BreadcrumbContainer padding={Space.spBase}>
                  {name}
                </BreadcrumbContainer>
              </Link>
              <StyledSlashIcon />
            </FlexboxContainer>
          )
        )
      }
    </FlexboxContainer>
  );
};

Breadcrumb.defaultProps = {
  containerMargin: '0',
  containerPadding: '0'
};

export default Breadcrumb;
