import React, { Fragment } from 'react';
import styled from 'styled-components';

import { Heading2, Paragraph, CardCallout, Heading5, GridContainer, GridItem, Img } from 'OnboardingComponents/Elements';

import primaryLogo from 'images/onboardingAssets/logo-example-01.jpg';
import logoMark from 'images/onboardingAssets/logo-example-02.jpg';
import websiteBanners from 'images/onboardingAssets/logo-example-03.jpg';
import singleColorLogo from 'images/onboardingAssets/logo-example-05.jpg';
import badProportions from 'images/onboardingAssets/logo-example-06.jpg';
import badStyling from 'images/onboardingAssets/logo-example-07.jpg';
import badGlows from 'images/onboardingAssets/logo-example-08.jpg';
import badBorders from 'images/onboardingAssets/logo-example-09.jpg';

const LogoGrid = styled(GridContainer)`
  align-items: flex-end;
`;

const PrimaryLogo = () => {
  return (
    <Fragment>
      <Heading2 id='primary-logo'>Primary logo</Heading2>
      <Paragraph>
        The overall lockup is the primary logo and should always be
used when representing the brand in most corporate scenarios
(e.g., ads or collateral material). Please refrain from using the
logomark by itself to represent the brand.
      </Paragraph>
      <CardCallout>
        <Heading5>Primary logo</Heading5>
        <LogoGrid>
          <GridItem>
            <Img src={primaryLogo}></Img>
          </GridItem>
          <GridItem>
            <Img src={logoMark}></Img>
          </GridItem>
        </LogoGrid>
      </CardCallout>
      <Heading5>Clear space</Heading5>
      <Paragraph>To preserve the integrity of the The Oilfield Marketplace logo, always maintain a minimum clear space around the logo. This clear space isolates the logo from competing elements – such as text and graphics. The minimum clear space is defined as a space equal in size to the logomark on each side of the logotype.</Paragraph>
      <CardCallout>
        <Heading5>Website homepage banners:</Heading5>
        <Img src={websiteBanners}></Img>
      </CardCallout>
      <Heading5>Logo guidelines</Heading5>
      <Paragraph>To preserve the integrity of the The Oilfield Marketplace logo, always maintain a minimum clear space around the logo. This clear space isolates the logo from competing elements – such as text and graphics. The minimum clear space is defined as a space equal in size to the logomark on each side of the logotype.</Paragraph>
      <CardCallout>
        <Heading5>Logo guidelines examples:</Heading5>
        <LogoGrid>
          <GridItem>
            <Paragraph>Do use the full color logo</Paragraph>
            <Img src={primaryLogo}></Img>
          </GridItem>
          <GridItem>
            <Paragraph>Do use the one color logo</Paragraph>
            <Img src={singleColorLogo}></Img>
          </GridItem>
        </LogoGrid>
        <LogoGrid>
          <GridItem>
            <Paragraph>Don't distort the proportions of the logo</Paragraph>
            <Img src={badProportions}></Img>
          </GridItem>
          <GridItem>
            <Paragraph>Don't use any colors strokes or gradients outside of the approved set</Paragraph>
            <Img src={badStyling}></Img>
          </GridItem>
        </LogoGrid>
        <LogoGrid>
          <GridItem>
            <Paragraph>Don't add gradients or glows</Paragraph>
            <Img src={badGlows}></Img>
          </GridItem>
          <GridItem>
            <Paragraph>Don't add any boxes around the lockup</Paragraph>
            <Img src={badBorders}></Img>
          </GridItem>
        </LogoGrid>
      </CardCallout>
    </Fragment>
  );
};

export default PrimaryLogo;
