import FeatureFlag from 'data/FeatureFlag';
import User, { Role } from 'data/User';
import Vendor from "data/Vendor";

export const checkUserSalesOrderVendorOption = (user: User | null, selectedVendor: Vendor | undefined, tabValue: string) => {

    const isAdminOrSuperAdmin = user?.role === Role.Admin || user?.role === Role.SuperAdmin;
    const isVendorAdmin = user?.role === Role.VendorAdmin;
    
    return selectedVendor?.isSlbVendor
      ? isVendorAdmin || isAdminOrSuperAdmin
      : isAdminOrSuperAdmin;

}

export const isSalesOrderEnabled = (
    flags: FeatureFlag, 
    value: string = ''
  ) => {
  
    return (value === 'Sales Order')
      ? flags.salesOrder && Boolean(flags.salesOrder.enabled)
      : true;
  };