export const COUNTRY_ISO = {
    US: 'US',
    CA: 'CA'
}

export const COUNTRY_NAMES = {
    USA: 'USA',
    CANADA: 'Canada'
}

export const CURRENCY_ISO = {
    USD: 'USD',
    CAD: 'CAD'
}