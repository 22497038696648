import { useState, useEffect } from 'react';

interface Route {
  name: string;
  path: string;
};

const useBreadcrumbs = (path: string) => {
  const [crumbs, setCrumbs] = useState<Route[]>([]);

  useEffect(() => {
    const links = path
      .split('/')
      .filter(link => link !== '' && !(/\d/.test(link)) && link !== 'page')
      .map(link => {
        return {
          name: link.charAt(0).toUpperCase() + link.slice(1).replace(/-/g, ' '),
          path: path.slice(0, path.lastIndexOf(link) + link.length)
        }
      });

    setCrumbs([{ name: 'Home', path: '/' }, ...links]);
  }, [path]);

  return crumbs;
};

export default useBreadcrumbs;
