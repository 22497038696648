import Input from 'components/UI/Input';
import Modal from 'components/UI/Modal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Size } from 'lib/styles';

const Content = styled.div`
  padding: ${Size.large};
`;

const IDInput = styled(Input)``;

interface Props {
  className?: string;
  onAdd: (videoId: string) => void;
  onClose: () => void;
  showModal: boolean;
  refreshModal?: boolean;
};

export default function AddVideoModal({ className, onAdd, onClose, showModal, refreshModal = false }: Props) {
  const [videoId, setVideoId] = useState('');

  useEffect(() => {
    setVideoId('');
  }, [refreshModal]);

  return (
    <Modal
      showModal={showModal}
      onClose={onClose}
      width="auto"
      showCloseIcon
      title='Input video ID'
      onClick={() => onAdd(videoId)}
      primaryButtonText='Add'
      disabled={!videoId}
    >
      <Content>
        <IDInput
          id='video-id'
          placeholder='Insert video id'
          labelName="Video ID"
          labelHidden={true}
          onInputChange={setVideoId}
          value={videoId}
          maxLength='20'
        />
      </Content>
    </Modal>
  );
};

AddVideoModal.defaultProps = {};
