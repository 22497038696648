import React, { useState } from 'react';
import styled from 'styled-components';
import { WarningRounded } from '@material-ui/icons';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Textarea from 'components/UI/Textarea';
import Button from 'components/UI/Button';
import { formatDateTime } from 'utils/formatters';
import { Space } from 'styles/themeSpaces';
import { Main, Text } from 'styles/themeColors';
import { BorderRadius } from 'styles/themeBorderRadius';
import { MainColors } from 'types/enumTypes';

const CardContainer = styled.div`
  width: 592px;
  margin: ${Space.sp2x} auto;
`;

const Date = styled.span`
  margin-right: ${Space.sp2x};
`;

const H6 = styled.h6`
  margin-bottom: ${Space.spBase};
`;

const TextPrimary = styled.span`
  margin-bottom: ${Space.spBase};
`;

const TextSecondary = styled.span`
  margin-right: ${Space.spHalf};
  margin-bottom: ${Space.spHalf};
  color: ${Text.secondary} !important;
  width: 25%;
`;

interface Props {
  className?: string;
  theme: MainColors;
  checkFailedDate?: string;
  ordersOnHoldNumber: number;
  customerName: string;
  billingAddress: string;
  companyName: string;
  comment: string;
  setComment: (value: string) => void;
  setIsModalOpen: (value: boolean) => void;
  setModalTheme: (value: MainColors) => void;
};

const ActionCard = ({
  className,
  theme,
  checkFailedDate,
  ordersOnHoldNumber,
  customerName,
  billingAddress,
  companyName,
  comment,
  setComment,
  setIsModalOpen,
  setModalTheme
}: Props) => {
  const [isDirty, setIsDirty] = useState(false);

  const onBlockClick = () => {
    if (comment) {
      setIsModalOpen(true);
      setModalTheme('error');
    } else {
      setIsDirty(true);
    }
  };

  const onClearClick = () => {
    if (comment) {
      setIsModalOpen(true);
      setModalTheme('success');
    } else {
      setIsDirty(true);
    }
  };

  return (
    <CardContainer className={`card-foreground-elevation ${className}`}>
      <FlexboxContainer
        flexDirection="column"
        padding={`${Space.sp2x} ${Space.sp3x}`}
        bgColor={Main[theme]}
        borderRadius={`${BorderRadius.m} ${BorderRadius.m} 0 0`}
      >
        <FlexboxContainer alignItems="center">
          <WarningRounded style={{ height: '56px', width: '56px', color: Text.primary }} />
          <FlexboxContainer flexDirection="column">
            <h4>Check failed</h4>
            <FlexboxContainer>
              <Date className="base">{formatDateTime(checkFailedDate, true)}</Date>
              <span className="base">{ordersOnHoldNumber} order(s) on hold</span>
            </FlexboxContainer>
          </FlexboxContainer>
        </FlexboxContainer>
        <p className="base">
          The customer failed automated Dow Jones (OFMP Compliance) check and requires a manual review
        </p>
      </FlexboxContainer>
      <FlexboxContainer
        flexDirection="column"
        padding={`${Space.sp2x} ${Space.sp3x}`}
      >
        <H6>Latest info sent for the check</H6>
        <FlexboxContainer>
          <TextSecondary className="base">Customer name</TextSecondary>
          <TextPrimary className="base">{customerName}</TextPrimary>
        </FlexboxContainer>
        <FlexboxContainer>
          <TextSecondary className="base">Billing address</TextSecondary>
          <TextPrimary className="base">{billingAddress}</TextPrimary>
        </FlexboxContainer>
        <FlexboxContainer>
          <TextSecondary className="base">Company</TextSecondary>
          <TextPrimary className="base">{companyName}</TextPrimary>
        </FlexboxContainer>
        <FlexboxContainer
          margin={`${Space.sp3x} 0 0 0`}
        >
          <Textarea
            id="dow-jones-comment"
            value={comment}
            onChange={setComment}
            required
            dirty={isDirty}
            label="Internal comment required"
            placeholder="Will be seen by OFMP staff only"
          />
        </FlexboxContainer>
      </FlexboxContainer>
      <FlexboxContainer
        justifyContent="center"
        padding={`0 ${Space.sp3x} ${Space.sp2x}`}
      >
        <Button width='100%' theme="error" onClick={onBlockClick}>
          Block customer &amp; cancel orders
        </Button>
        <Button width='100%' theme="success" onClick={onClearClick}>
          Clear customer &amp; release orders
        </Button>
      </FlexboxContainer>
    </CardContainer>
  );
};

ActionCard.defaultProps = {
  className: '',
  theme: 'warning'
};

export default ActionCard;
