/* eslint-disable react/display-name, react/prop-types */

import React from 'react';
import { AsyncState } from 'react-async';
import Vendor from 'data/Vendor';
import FeatureFlag, { FeatureFlagValues } from 'data/FeatureFlag';
import User, { UserPreferences } from 'data/User';
import VendorsList from 'admin/VendorsList';
import UsersList from 'admin/UsersList';
import CategoriesList from 'admin/CategoriesList';
import AttributesList from './AttributesList';
import AccountSettings from 'common/AccountSettings';
import Dashboard from 'common/Dashboard';
import ProductList from 'common/ProductList';
import ProductDetails from 'common/ProductDetails';
import BrandDetails from 'common/Brands/BrandDetails';
import BrandsList from 'common/Brands/BrandsList';
import WarehouseList from 'common/WarehouseList';
import OrderDetails from 'components/Consignments/OrderDetails';
import OrderDocuments from 'components/Consignments/OrderDocuments';
import SplitOrderContainer from 'components/Orders/SplitOrderContainer';
import CompaniesList from 'common/Companies/CompaniesList';
import CompanyDetails from 'common/Companies/CompanyDetails';
import CustomerDetails from 'common/Customers/CustomerDetails';
import CustomersList from 'common/Customers/CustomersList';
import OrdersList from 'components/Orders/OrderList/OrdersList';
import PowerBiReports from 'common/PowerBiReports/PowerBiReports';
import PricebooksTab from 'common/Companies/PricebooksTab';
import NotFound from 'components/UI/NotFound';
import UpsertVendor from 'components/Vendor/UpsertVendor';
import { grantAccess } from 'utils/authorization';
import ProductListV2 from 'components/Product/ProductListing/ProductListingComponent';
import ProductDetailsNewDesign from 'common/ProductDetailsV2';
import CampaignList from 'components/Campaign/CampaignList';

interface AdminProps {
  selectedVendor: Vendor;
  updateVendor: (name: string) => void;
  vendorsList: Vendor[];
  featureFlags: FeatureFlag;
  userPreferences: UserPreferences;
  userPreferencesReq: AsyncState<any>;
  user: User;
};

const routes = {
  '/':
    () =>
    ({ selectedVendor, vendorsList }: AdminProps) =>
      <Dashboard selectedVendor={selectedVendor} vendorsList={vendorsList} />,
  '/account*': () => () => <AccountSettings />,
  '/brands':
    () =>
    ({ selectedVendor }: AdminProps) =>
      <BrandsList selectedVendor={selectedVendor} />,
  '/warehouses':
    () =>
    ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
      (
        <WarehouseList
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ),
  '/products': 
    () => 
    ({ selectedVendor, featureFlags, user, vendorsList }: AdminProps) => 
      grantAccess(featureFlags, FeatureFlagValues.PRODUCT_V2, user)
      ? <ProductListV2 vendorsList={vendorsList} selectedVendor={selectedVendor}/> : 
        <ProductList
          base="/products/"
          editable status="draft"
          selectedVendor={selectedVendor} 
        />,
  '/products/page/:page':
    ({ page }: any) =>
    ({ selectedVendor }: AdminProps) =>
      (
        <ProductList
          base="/products/"
          editable
          page={page}
          status="draft"
          selectedVendor={selectedVendor}
        />
      ),
  '/products/ready':
    () =>
    ({ selectedVendor }: AdminProps) =>
      (
        <ProductList
          base="/products/ready/"
          editable
          status="ready"
          selectedVendor={selectedVendor}
        />
      ),
  '/products/ready/page/:page':
    ({ page }: any) =>
    ({ selectedVendor }: AdminProps) =>
      (
        <ProductList
          page={page}
          base="/products/ready/"
          editable
          status="ready"
          selectedVendor={selectedVendor}
        />
      ),
  '/products/published':
    () =>
    ({ selectedVendor }: AdminProps) =>
      (
        <ProductList
          base="/products/published/"
          editable
          status="published"
          selectedVendor={selectedVendor}
        />
      ),
  '/products/published/page/:page':
    ({ page }: any) =>
    ({ selectedVendor }: AdminProps) =>
      (
        <ProductList
          page={page}
          base="/products/published/"
          editable
          status="published"
          selectedVendor={selectedVendor}
        />
      ),
  '/products/new-product':
    () =>
    ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
      featureFlags?.productV2?.enabled ? (
        <ProductDetailsNewDesign
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
          view='newProduct'
        />
      ) : (
        <ProductDetails
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ),
  '/products/new-product/variants':
    () =>
    ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
      featureFlags?.productV2?.enabled ? (
        <ProductDetailsNewDesign
          view="variants"
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ) : (
        <ProductDetails
          view="variants"
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ),
  '/products/new-product/preview':
    () =>
    ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
      featureFlags?.productV2?.enabled ? (
        <ProductDetailsNewDesign
          view="preview"
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ) : (
        <ProductDetails
          view="preview"
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ),
  '/products/product-details/:id/preview':
    ({ id }: any) =>
    ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
      featureFlags?.productV2?.enabled ? (
        <ProductDetailsNewDesign
          view="preview"
          productId={id}
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ) : (
        <ProductDetails
          view="preview"
          productId={id}
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ),
  '/products/product-details/:id/variants':
    ({ id }: any) =>
    ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
      featureFlags?.productV2?.enabled ? (
        <ProductDetailsNewDesign
          view="variants"
          productId={id}
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ) : (
        <ProductDetails
          view="variants"
          productId={id}
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ),
      '/products/product-details/:id/warehouses':
      ({ id }: any) =>
      ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
        featureFlags?.productV2?.enabled ? (
          <ProductDetailsNewDesign
            view="warehouses"
            productId={id}
            selectedVendor={selectedVendor}
            vendorsList={vendorsList}
            updateVendor={updateVendor}
            featureFlags={featureFlags}
          />
        ) : (
          <ProductDetails
            productId={id}
            selectedVendor={selectedVendor}
            vendorsList={vendorsList}
            updateVendor={updateVendor}
            featureFlags={featureFlags}
          />
        ),
  // '/products/product-details/:id/view': ({ id }: any) => () => <ProductReview productId={id} />,
  '/products/product-details/:id':
    ({ id }: any) =>
    ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
      featureFlags?.productV2?.enabled ? (
        <ProductDetailsNewDesign
          productId={id}
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ) : (
        <ProductDetails
          productId={id}
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ),
  '/brands/brand-details/:id':
    ({ id }: any) =>
    ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
      (
        <BrandDetails
          brandId={id}
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ),
  '/brands/new-brand':
    () =>
    ({ selectedVendor, updateVendor, vendorsList, featureFlags }: AdminProps) =>
      (
        <BrandDetails
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          updateVendor={updateVendor}
          featureFlags={featureFlags}
        />
      ),
  '/vendors': () => () => <VendorsList />,
  '/vendors/create':
    () =>
    ({ featureFlags }: AdminProps) =>
      <UpsertVendor featureFlags={featureFlags} />,
  '/vendors/update/:id':
    ({ id }: any) =>
    ({ setVendorsList, featureFlags }: any) =>
      (
        <UpsertVendor
          featureFlags={featureFlags}
          vendorId={id}
          setVendorsListCallBack={setVendorsList}
        />
      ),
  '/users':
    () =>
    ({ vendorsList }: AdminProps) =>
      <UsersList vendorsList={vendorsList} />,
  '/attributes':
    () =>
    ({ selectedVendor }: AdminProps) =>
      <AttributesList selectedVendor={selectedVendor} />,
  '/reports':
    () =>
    ({ selectedVendor, featureFlags, vendorsList }: AdminProps) =>
      (
        <PowerBiReports
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
          featureFlags={featureFlags}
        />
      ),
  '/categories': () => () => <CategoriesList />,
  '/orders':
    () =>
    ({ selectedVendor, featureFlags, user }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.ORDER, user) ? (
        <OrdersList
          selectedVendor={selectedVendor}
          featureFlags={featureFlags}
        />
      ) : (
        <NotFound />
      ),

  '/orders/page/:page':
    ({ page }: any) =>
    ({ selectedVendor, featureFlags, user }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.ORDER, user) ? (
        <OrdersList
          featureFlags={featureFlags}
          selectedVendor={selectedVendor}
          page={page}
        />
      ) : (
        <NotFound />
      ),

  '/orders/order-details/:id?':
    ({ id }: any) =>
    ({ featureFlags, user }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.ORDER, user) ? (
        <OrderDetails consignmentId={'cons' + id} featureFlags={featureFlags} />
      ) : (
        <NotFound />
      ),

  '/orders/order-details/:id/document/:documentId':
    ({ id }: any) =>
    ({ selectedVendor, featureFlags, user }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.ORDER, user) ? (
        <OrderDocuments
          consignmentId={'cons' + id}
          selectedVendor={selectedVendor}
        />
      ) : (
        <NotFound />
      ),
  '/orders/order-details/:id/new-document':
    ({ id }: any) =>
    ({ vendorsList, selectedVendor, featureFlags, user }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.ORDER, user) ? (
        <OrderDocuments
          consignmentId={'cons' + id}
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
        />
      ) : (
        <NotFound />
      ),

  '/orders/split-order-details/:id':
    ({ id }: any) =>
    ({ featureFlags, user }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.ORDER, user) ? (
        <SplitOrderContainer orderId={id} featureFlags={featureFlags} />
      ) : (
        <NotFound />
      ),

  '/companies':
    () =>
    ({
      selectedVendor,
      featureFlags,
      userPreferences,
      userPreferencesReq,
      user,
    }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.COMPANY, user) ? (
        <CompaniesList
          selectedVendor={selectedVendor}
          userPreferences={userPreferences}
          userPreferencesReq={userPreferencesReq}
        />
      ) : (
        <NotFound />
      ),

  '/companies/page/:page':
    ({ page }: any) =>
    ({
      selectedVendor,
      featureFlags,
      userPreferences,
      userPreferencesReq,
      user,
    }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.COMPANY, user) ? (
        <CompaniesList
          selectedVendor={selectedVendor}
          page={page}
          userPreferences={userPreferences}
          userPreferencesReq={userPreferencesReq}
        />
      ) : (
        <NotFound />
      ),

  '/companies/company-details/:id':
    ({ id }: any) =>
    ({ selectedVendor, updateVendor, featureFlags, user }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.COMPANY, user) ? (
        <CompanyDetails
          featureFlags={featureFlags}
          companyId={id}
          selectedVendor={selectedVendor}
          updateVendor={updateVendor}
        />
      ) : (
        <NotFound />
      ),

  '/customers/customer-details/:id':
    ({ id }: any) =>
    ({ featureFlags, user }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.COMPANY, user) ? (
        <CustomerDetails customerId={id} featureFlags={featureFlags} />
      ) : (
        <NotFound />
      ),

  '/customers':
    () =>
    ({
      selectedVendor,
      userPreferences,
      userPreferencesReq,
      featureFlags,
      user,
    }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.COMPANY, user) ? (
        <CustomersList
          selectedVendor={selectedVendor}
          userPreferences={userPreferences}
          userPreferencesReq={userPreferencesReq}
          featureFlags={featureFlags}
        />
      ) : (
        <NotFound />
      ),

  '/customers/page/:page':
    ({ page }: any) =>
    ({
      selectedVendor,
      userPreferences,
      userPreferencesReq,
      featureFlags,
      user,
    }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.COMPANY, user) ? (
        <CustomersList
          selectedVendor={selectedVendor}
          page={page}
          userPreferences={userPreferences}
          userPreferencesReq={userPreferencesReq}
          featureFlags={featureFlags}
        />
      ) : (
        <NotFound />
      ),
  '/pricebooks':
    () =>
    ({ selectedVendor, featureFlags, user }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.COMPANY, user) ? (
        <PricebooksTab
          selectedVendor={selectedVendor}
          isPricebookPage={true}
          companyCustomers={[]}
        />
      ) : (
        <NotFound />
      ),

'/marketing':
    () =>
    ({ selectedVendor, featureFlags, user,vendorsList }: AdminProps) =>
      grantAccess(featureFlags, FeatureFlagValues.MARKETING, user) ? (
        <CampaignList
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
        />
      ) : (
        <NotFound />
      ),

  '*': () => () => <NotFound />,
};

export default routes;
