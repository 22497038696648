import { createStore, applyMiddleware, Reducer } from 'redux';
import { createEpicMiddleware, EpicMiddleware } from 'redux-observable';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { persistStore, persistReducer } from 'redux-persist';
import localforage from 'localforage';
import rootReducer from 'redux/reducers';
import rootEpic from 'redux/epics';
import initialState from 'redux/reducers/initialState';
import { State } from 'redux/types/state';

const persistStorage = localforage.createInstance({
  name: 'sellerhub',
});

const epicMiddleware: EpicMiddleware<any, any, State> = createEpicMiddleware();

const persistConfig = {
  key: 'root',
  storage: persistStorage,
  throttle: 2000
};

// TODO - replace with correct type once redux-persist actually makes _persist optional param
// https://github.com/rt2zz/redux-persist/pull/1170
const persistedReducer: Reducer<any, any> = persistReducer(
  persistConfig,
  rootReducer
);

const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(epicMiddleware))
);

export const persistor = persistStore(store);

epicMiddleware.run(rootEpic);

export default store;
