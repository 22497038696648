import { useEffect } from 'react';

interface KeyboardListenersProp {
  enable?: boolean;
  onEsc?: () => void;
  onEnter?: () => void;
};

const useKeyboardListeners = ({
  onEsc,
  onEnter,
  enable = true
}: KeyboardListenersProp) => {
  useEffect(() => {
    const escHandler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onEsc && onEsc();
      }
    };

    const enterHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onEnter && onEnter();
      }
    };
    
    if (onEsc && enable) document.addEventListener('keydown', escHandler);
    if (onEnter && enable) document.addEventListener('keydown', enterHandler);

    return () => {
      if (onEsc) document.removeEventListener('keydown', escHandler);
      if (onEnter) document.removeEventListener('keydown', enterHandler);
    };
  }, [enable, onEsc, onEnter]);
};

export default useKeyboardListeners;
