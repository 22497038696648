import Pill from 'components/UI/Pill';
import React from 'react';
import styled, { css } from 'styled-components';
import { Color, Size } from 'lib/styles';
import { navigate, usePath } from 'hookrouter';
import { Main } from 'styles/themeColors';

const Content = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
`;

const Link = styled.span<any>`
  border-bottom: 3px solid transparent;
  color: ${Main.accent};
  cursor: pointer;
  display: inline-block;
  font-weight: 500;
  line-height: calc(4em - 8px);
  padding: 0px ${Size.tiny};
  text-transform: uppercase;

  ${props => props.current && css`
    border-color: ${Main.accent};
  `}

  ${props => !props.isSideNav && css`
    &:not(:first-child) {
      margin-left: ${Size.large};
    }

    &:not(:last-child) {
      margin-right: ${Size.large};
    }
  `}

  ${props => props.isSideNav && css`
    color: ${Color.darkerGray};
    padding: 0;
    border-bottom: 0px solid transparent !important;
    line-height: 36px !important;
  `}

  ${props => props.current && props.isSideNav && css`
    color: ${Main.brandLight};
    border: transparent;
  `}

  ${props => props.disabled && css`
    cursor: not-allowed;
    opacity: 0.6;
  `}
`;

const StyledPill = styled(Pill)`
  margin-left: ${Size.small};
  min-width: 1.35rem;
  text-align: center;
  padding: 1px 3px;
  line-height: 16px;
`;

const Text = styled.div``;

export interface Props {
  atBasePath?: boolean;
  children?: React.ReactNode;
  className?: string;
  current?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  pillCount?: number;
  to: string;
  isSideNav?: boolean;
};

export default function NavLink({
  atBasePath,
  children,
  className,
  current,
  disabled,
  onClick,
  pillCount,
  to,
  isSideNav,
}: Props) {
  const path = usePath();

  const clickHandlers: Function[] = [];

  const pill =
    pillCount && pillCount > 0 ? (
      <StyledPill theme="danger" size='xs'>{String(pillCount)}</StyledPill>
    ) : null;

  if (to) {
    clickHandlers.push(() => navigate(to));
  }

  if (onClick) {
    clickHandlers.push(onClick);
  }

  function runClickHandlers(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    clickHandlers.forEach(handler => handler(e));
  }

  const isCurrent = current || atBasePath ? path.startsWith(to) : path === to;

  return (
    <Link
      className={className}
      current={isCurrent}
      disabled={disabled}
      onClick={runClickHandlers}
      isSideNav={isSideNav}
    >
      <Content>
        <Text>{children}</Text>
        {pill}
      </Content>
    </Link>
  );
};

NavLink.defaultProps = {
  atBasePath: false,
  current: false,
  disabled: false
};
