import SalesOrderConfig from 'data/SalesOrderConfig';
import { get, put } from 'lib/API';

export const validateVendorCompany = async (
  [vendorId, companyId, vendorCompanyId]: any[],
  { token }: any
): Promise<any> => {
  return await get(`v1/vendorCompany/${vendorCompanyId}?vendorId=${vendorId}&companyId=${companyId}`, token);
};

export const saveCompanySalesOrderConfig = async (salesOrderConfig: SalesOrderConfig, token: any, vendorId: number | undefined, companyId: string): Promise<any> => {
  return await put(`v1/vendorCompany/salesOrderConfig/${companyId}/${vendorId}`, token, salesOrderConfig);
};

export const getCompanySalesOrderConfig = async ([vendorId, companyId]: any[], { token }: any): Promise<any> => {
  return await get(`v1/vendorCompany/salesOrderConfig/${companyId}/${vendorId}`, token);
};
 
