import React, { useEffect } from 'react';
import { navigate } from 'hookrouter';
import styled, { css } from 'styled-components';
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from '@material-ui/icons';
import Select from 'components/UI/Select';
import Input from 'components/UI/Input';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { FontSize } from 'lib/styles';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Background, Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { Height } from 'styles/themeHeights';
import { defaultPaginationSelectOptions } from '../../constants/paginationOptions';
import { handleOnSizeChange } from '../../utils/grid';

const PaginationText = styled.span`
  font-size: ${FontSize.medium};
  padding: 15px 0;
`;

const StyledPageInput = styled(Input) <any>`
  margin: 0;
  border-radius: ${BorderRadius.m};

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

const StyledFirstPage = styled(FirstPage) <any>`
  width: 24px !important;
  height: ${Height.s} !important;
`;

const StyledLastPage = styled(LastPage) <any>`
  width: 24px !important;
  height: ${Height.s} !important;
`;

const StyledNavigateBefore = styled(NavigateBefore) <any>`
  width: 24px !important;
  height: ${Height.s} !important;
`;

const StyledNavigateNext = styled(NavigateNext) <any>`
  width: 24px !important;
  height: ${Height.s} !important;
`;

const IconWrapper = styled.div<any>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px !important;
  height: ${Height.m} !important;
  cursor: pointer;
  background-color: ${Background.shaded};
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};

  &:hover {
    filter: brightness(0.97);
  }

  ${({ disabled }) => disabled && css`
    cursor: auto;

    &:hover {
      filter: brightness(1);
    }
  `}
`;

interface Props {
  itemsPerPage: number;
  setItemsPerPage: Function;
  selectorOptions: any[];
  currentPage: number;
  setCurrentPage: Function;
  totalPages: number;
  baseRoute: string;
  shouldRedirect?: boolean;
  shouldHandleOnSizeChange?: boolean;
};

const Pagination = ({
  itemsPerPage,
  setItemsPerPage,
  selectorOptions,
  currentPage,
  setCurrentPage,
  totalPages,
  baseRoute,
  shouldRedirect,
  shouldHandleOnSizeChange
}: Props) => {
  const onPageChange = (value: number) => {
    const newValue = value > totalPages
      ? totalPages
      : value < 1 || isNaN(value)
        ? 1
        : value;

    setCurrentPage(newValue);
  };

  useEffect(() => {
    if (shouldRedirect) {
      navigate(`/${baseRoute}/page/${currentPage}`);
    }
  }, [currentPage, shouldRedirect]);

  return (
    <FlexboxContainer
      justifyContent="space-between"
      alignItems="center"
      bgColor={Background.shaded}
      borderRadius={BorderRadius.m}
      padding={Space.spBase}
    >
      <FlexboxContainer>
        <></>
      </FlexboxContainer>
      <FlexboxContainer justifyContent="center" alignItems="center">
        <IconWrapper marginRight={Space.sp3x} disabled={currentPage === 1}>
          <StyledFirstPage
            onClick={() => setCurrentPage(1)}
            style={{ color: currentPage === 1 ? Text.disabled : Background.black }}
          />
        </IconWrapper>
        <IconWrapper marginRight={Space.sp3x} disabled={currentPage === 1}>
          <StyledNavigateBefore
            onClick={() => setCurrentPage(currentPage === 1 ? 1 : currentPage - 1)}
            style={{ color: currentPage === 1 ? Text.disabled : Background.black }}
          />
        </IconWrapper>
        <StyledPageInput
          type="number"
          value={currentPage}
          onCurrencyChange={(value: string) => onPageChange(parseInt(value))}
          centerText
          height="48px"
          width="58px"
          margin="0 5px 0 0"
        />
        <PaginationText>of {totalPages}</PaginationText>
        <IconWrapper marginLeft={Space.sp3x} disabled={currentPage === totalPages}>
          <StyledNavigateNext
            onClick={() => setCurrentPage(currentPage === totalPages ? totalPages : currentPage + 1)}
            style={{ color: currentPage === totalPages ? Text.disabled : Background.black }}
          />
        </IconWrapper>
        <IconWrapper marginLeft={Space.sp3x} disabled={currentPage === totalPages}>
          <StyledLastPage
            onClick={() => setCurrentPage(totalPages)}
            style={{ color: currentPage === totalPages ? Text.disabled : Background.black }}
          />
        </IconWrapper>
      </FlexboxContainer>
      <FlexboxContainer flexDirection='flex-end'>
        <Select
          options={selectorOptions}
          onChange={(event: any) => shouldHandleOnSizeChange ?
            handleOnSizeChange(
              parseInt(event.target.value.split(' ')[0]),
              setItemsPerPage as (size: number) => void,
              setCurrentPage as (size: number) => void) :
            setItemsPerPage( isNaN(event.target.value.split(' ')[0]) ? event.target.value.split(' ')[0] : parseInt(event.target.value.split(' ')[0]))}
          value={itemsPerPage + ' Items'}
          width="150px"
        />
      </FlexboxContainer>
    </FlexboxContainer>
  );
};

Pagination.defaultProps = {
  itemsPerPage: 50,
  selectorOptions: defaultPaginationSelectOptions,
  currentPage: 1,
  totalPages: 1,
  shouldRedirect: true,
  adjustPageOnOptionChange: false
};

export default React.memo(Pagination);
