import React, { useState } from 'react';
import { useAsync } from 'react-async';
import { navigate } from 'hookrouter';
import styled from 'styled-components';
import { resetPassword, verifyResetToken } from 'requests/user';
import Button from 'components/UI/Button';
import ModalButtonSet from 'view/ModalButtonSet';
import PasswordInput from 'view/PasswordInput';
import PasswordRequirements, {
  validatePassword
} from 'view/PasswordRequirements';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import Link from 'view/Link';
import { Color, Size } from 'lib/styles';
import { Background, Main } from 'styles/themeColors';

const CenteredContent = styled.div`
  text-align: center;
`;

const Container = styled.form`
  background-color: ${Background.white};
  border: 1px solid ${Color.gray};
  border-radius: 2px;
  margin: ${Size.huge} auto;
  max-width: 600px;
`;

const Content = styled.div``;

const ContentSection = styled.section`
  padding: ${Size.medium} ${Size.large} ${Size.large};
`;

const Heading = styled.div`
  font-size: 24px;
  padding: ${Size.large} 0;
`;

const StyledLink = styled(Link)`
  margin: 0 !important;
`;

const StyledPasswordInput = styled(PasswordInput)`
  margin-bottom: ${Size.medium};
`;

interface Props {
  className?: string;
  resetId: string;
}

export default function ResetPassword({ className, resetId }: Props) {
  const [password, setPassword] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  const verifyResetTokenReq = useAsync({
    promiseFn: verifyResetToken,
    resetId
  });

  const resetPasswordReq = useAsync({
    deferFn: resetPassword,
    onResolve: () => {
      navigate('/');
    }
  });

  const [validPassword, passwordValidationErrors] = validatePassword(password);

  function submit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (validPassword) {
      resetPasswordReq.run(resetId, password);
    }
  }

  let content = <LoadingIndicator />;
  let buttons = null;

  if (verifyResetTokenReq.error) {
    content = (
      <>
        <Heading>Password reset link has expired.</Heading>
        <CenteredContent>
          Would you like to try resetting your password again?{' '}
          <StyledLink to="/reset-password">Click here</StyledLink>
        </CenteredContent>
      </>
    );
  } else if (!verifyResetTokenReq.isLoading && !confirmed) {
    content = (
      <>
        <Heading>
          We heard you forgot your password. No worries, happens to the best of
          us! Just click below to choose a new one.
        </Heading>
        <Content>
          If you didn't ask to reset your password, then please ignore this
          message and the email it came from. Your password will remain as it
          was before: safe and secure.
        </Content>
      </>
    );

    buttons = (
      <>
        <Button onClick={() => navigate('/')} isGhost color={Main.accent} margin="0 10px 0 0">
          Cancel
        </Button>
        <Button onClick={() => setConfirmed(true)}>
          Reset password
        </Button>
      </>
    );
  }

  if (confirmed) {
    content = (
      <>
        <Heading>Choose a new password</Heading>
        <StyledPasswordInput
          disabled={resetPasswordReq.isLoading}
          onChange={value => setPassword(value)}
          label="Password"
          value={password}
        />
        <PasswordRequirements errors={passwordValidationErrors} />
      </>
    );

    buttons = (
      <Button
        disabled={resetPasswordReq.isLoading || !validPassword}
      >
        Update
      </Button>
    );
  }

  let modalButtonSet = null;

  if (buttons) {
    modalButtonSet = <ModalButtonSet>{buttons}</ModalButtonSet>;
  }

  return (
    <Container className={className} onSubmit={submit}>
      <ContentSection>{content}</ContentSection>
      {modalButtonSet}
    </Container>
  );
}

ResetPassword.defaultProps = {};
