import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Button from 'components/UI/Button';
import ErrorMessage from 'view/ErrorMessage';
import Input from 'components/UI/Input';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, Size } from 'lib/styles';
import { useAPI } from 'lib/API';
import Vendor from 'data/Vendor';
import Select from 'components/UI/Select';
import RadioButton from 'components/UI/RadioButton';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { createInvite } from 'requests/invite';
import { createVendor } from 'requests/vendor';
import { Space } from 'styles/themeSpaces';

const Actions = styled.div`
  border-top: 1px solid ${Color.gray};
  display: flex;
  justify-content: center;
  padding: ${Size.large} ${Size.huge};
`;

const Form = styled.form``;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Size.large} ${Size.huge};

  & > *:not(:first-child) {
    margin-top: ${Size.large};
  }
`;

interface Props {
  onFinish: () => void;
  role: 'Vendor' | 'Admin' | 'None' | 'VendorAdmin';
  vendors?: Vendor[];
}

export default function InviteForm({ onFinish, role, vendors }: Props) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const [code, setCode] = useState('');
  const [errorText, setErrorText] = useState('');
  const [isVendorSelected, setIsVendorSelected] = useState(true);

  const inviteReq = useAPI({
    deferFn: createInvite,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Invite created.');
      onFinish();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });
  const createVendorReq = useAPI({
    deferFn: createVendor,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Vendor created.');
      onFinish();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  let vendorsList: any[] = [];
  let vendorSelector = null;
  let checkboxSelector = null;
  let emailInput = null;
  let buttonLabel = 'Create vendor';
  let errorMessage = null;

  function onKeyUp(e: React.FormEvent<HTMLInputElement>): any {
    if (code.trim().length < 2 || code.trim().length > 8) {
      setErrorText('vendor code is not within specified range');
    }
    else {
      setErrorText('');
    }
  }

  const setVendorValue = (event: any) => {
    if (vendors) {
      const foundVendor = vendors.find(vendor => vendor.name === event.target.value);

      if (foundVendor) {
        setVendor(foundVendor);
      }
    }
  };

  let codeInput: any = (
    <Input
      id="vendor-code-id"
      disabled={createVendorReq.isPending}
      labelName="Vendor code"
      onChange={e => setCode(e.target.value)}
      value={code}
      placeholder="type vendor code (2-8 characters)"
      onKeyUp={onKeyUp}
    />
  );

  if (vendors) {
    vendors.forEach(vendor => {
      vendorsList.push(({ value: vendor.name }));
    });

    if (role === 'Vendor') {
      vendorSelector = (
        <FlexboxContainer padding={Space.spHalfPlus}>
          <Select
            label="Vendors"
            options={vendorsList}
            onChange={setVendorValue}
            defaultValue="Select vendor"
            value={vendor ? vendor.name : ''}
            labelPosition='top'
            width="100%"
          />
        </FlexboxContainer>
      );
    }

    checkboxSelector = role === 'Vendor' ? (
      <FlexboxContainer flexDirection="row">
        <RadioButton
          id='1'
          name='Vendor'
          label='Vendor'
          checked={isVendorSelected}
          onChange={() => setIsVendorSelected(true)}
        />
        <RadioButton
          id='2'
          checked={!isVendorSelected}
          label='Vendor Admin'
          name='Vendor Admin'
          onChange={() => setIsVendorSelected(false)}
        />
      </FlexboxContainer>
    ) : null;

    emailInput = (
      <Input
        id="email-id"
        disabled={inviteReq.isPending}
        labelName={role === 'Vendor' ? 'Contact email' : 'Business email'}
        onChange={e => setEmail(e.target.value)}
        type="email"
        placeholder='Type email'
        value={email}
      />
    );

    codeInput = null;

    buttonLabel = 'Send invitation';
  }

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    if (role === 'None') {
      createVendorReq.run(name, code);
    } else {
      inviteReq.run(email, name, role, vendor ? vendor.id : null);
    }
  }

  const cantSubmit =
    (role !== 'None' && inviteReq.isPending) ||
    (role !== 'None' && email.trim().length === 0) ||
    name.trim().length === 0 ||
    (role === 'Vendor' && !vendor?.name) ||
    (role === 'None' && createVendorReq.isPending) ||
    (role === 'None' && (code.trim().length < 2 || code.trim().length > 8));

  if (inviteReq.error && !errorText) {
    setErrorText(inviteReq.error.message);
  }

  if (errorText) {
    errorMessage = <ErrorMessage message={errorText} />
  }

  if (role === 'Vendor') {
    role = isVendorSelected ? 'Vendor' : 'VendorAdmin';
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Inputs>
        {checkboxSelector}
        <Input
          id="name-id"
          disabled={inviteReq.isPending}
          labelName='Name'
          onChange={e => setName(e.target.value)}
          value={name}
          placeholder='Type name'
        />
        {emailInput}
        {codeInput}
        {vendorSelector}
        {errorMessage}
      </Inputs>
      <Actions>
        <Button disabled={cantSubmit}>
          {buttonLabel}
        </Button>
      </Actions>
    </Form>
  );
}
