import React, { useState, useEffect, MouseEvent } from 'react';
import { AsyncState } from 'react-async';
import { toast } from 'react-toastify';
import { CustomersPagination, CustomerVM } from 'data/Customer';
import { UserPreferences, isVendor } from 'data/User';
import Page from 'components/UI/Page';
import PageHeading from 'components/UI/PageHeading';
import MatTable from 'components/MaterialTable/MatTable';
import { renderToast } from 'components/UI/ToastNotification';
import { useAPI } from 'lib/API';
import customerColumns from 'constants/customerColumns';
import { useAuth } from 'lib/Auth';
import { DOW_JONES_BLOCKED } from 'constants/dowJonesStatuses';
import { loadCustomers } from 'requests/customer';
import { filterDowJonesRelations } from 'utils/dowJones';
import FeatureFlag from 'data/FeatureFlag';
import { createUserPreferences, saveUserPreferences } from 'requests/user';
import Vendor from 'data/Vendor';
import { CUSTOMER_TABLE_COLUMN_ORDER_USER_PREFERENCES_KEY } from 'constants/userPreferenceColumnKeys';
import { mapCustomerGridColumns, mapCustomerData } from 'utils/gridColumnMappings';
import {
  applyPaginationFilter,
  applySearchFilter,
  applySortFilter,
  calculatePages,
  mergePaginationData,
  updatePreferences,
  handleOnSizeChange,
  handleOnSortChange,
  handleOnSearchKeyChange,
  handleOnRowClick,
  handleOnColumnDrag,
  handleOnColumnHidden
} from 'utils/grid';
import useFetchGridDataWithPagination from 'hooks/useFetchGridWithPagination';

interface Props {
  selectedVendor?: Vendor;
  page?: string;
  userPreferences?: UserPreferences;
  userPreferencesReq: AsyncState<any>;
  featureFlags: FeatureFlag;
};

const CustomersList = ({
  selectedVendor,
  page,
  userPreferences,
  userPreferencesReq,
  featureFlags
}: Props) => {
  const { user } = useAuth();
  const isVendorAcc = isVendor(user!);
  const [currentPage, setCurrentPage] = useState<number>(page ? parseInt(page) : 1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [showDowJonesFailedCustomers, setShowDowJonesFailedCustomers] = useState(false);
  const [columns, setColumns] = useState(customerColumns);
  const [sortBy, setSortBy] = useState(customerColumns.find(c => c.defaultSort !== undefined))
  const [customers, setCustomers] = useState<CustomersPagination>({
    page: 1,
    totalPages: 1,
    size: 1,
    totalItems: 1,
    customers: []
  });

  // const mergeCustomers = (news: CustomerVM[]) => mergePaginationData(customers, 'customers', 'customerId', news, setCustomers);
  const updateUserPreferences = (oldOnes: any, newOnes: any) => updatePreferences(oldOnes, newOnes, userPreferencesUpdateReq, userPreferencesCreateReq);

  const onSizeChange = (newSize: number) => handleOnSizeChange(newSize, setItemsPerPage, setCurrentPage);

  const onSortChange = (index: number, direction: string) => handleOnSortChange(index, direction, customerColumns, setSortBy);

  const onSearchTermSet = (value: string) => handleOnSearchKeyChange(value, setSearchTerm, setCurrentPage);

  const onRowClick = (_?: MouseEvent<Element>, row?: any) => handleOnRowClick(`/customers/customer-details/${row!.id}`, { companiesPage: page });

  const onColumnDragged = (sourceIndex: number, destinationIndex: number) => handleOnColumnDrag(
    sourceIndex,
    destinationIndex,
    columns,
    userPreferences,
    CUSTOMER_TABLE_COLUMN_ORDER_USER_PREFERENCES_KEY,
    setColumns,
    updateUserPreferences
  );

  const onChangeColumnHidden = (newColumns: any[]) => handleOnColumnHidden(
    newColumns,
    userPreferences,
    CUSTOMER_TABLE_COLUMN_ORDER_USER_PREFERENCES_KEY,
    setColumns,
    updateUserPreferences
  );

  const onSwitchChange = () => {
    setShowDowJonesFailedCustomers(!showDowJonesFailedCustomers);
    setCurrentPage(1);
  };

  const applySwitchFilter = (customers: CustomerVM[], switchKey: boolean) => {
    return customers.filter(customer =>
      switchKey
        ? customer.dowJones.length > 0 && customer.dowJones.find(dowJones => dowJones.status === DOW_JONES_BLOCKED)
        : true
    );
  };

  const onAPIError = (err: any) => renderToast(toast.TYPE.ERROR, err.message);

  const customerFirstPageRequest = useAPI({
    deferFn: loadCustomers,
    onResolve: (result: CustomersPagination) => {
      const mappedCustomers = mapCustomerData(result.customers);
      setCustomers({ ...result, customers: mappedCustomers });
    },
    onReject: onAPIError,
    debugLabel: 'customersFirstPageReq'
  });

  const customerRestPagesRequest = useAPI({
    deferFn: loadCustomers,
    onResolve: (result: CustomersPagination) => {
      const mappedCustomers = mapCustomerData(result.customers);
      setCustomers({ ...result, customers: mappedCustomers });
      // mergeCustomers(mappedCustomers);
    },
    onReject: onAPIError,
    debugLabel: 'customersRestPagesReq'
  });

  const userPreferencesCreateReq = useAPI({
    deferFn: createUserPreferences,
    onResolve: () => userPreferencesReq.reload(),
    onReject: onAPIError,
    debugLabel: 'userPreferencesCreateReq'
  });

  const userPreferencesUpdateReq = useAPI({
    deferFn: saveUserPreferences,
    onResolve: () => userPreferencesReq.reload(),
    onReject: onAPIError,
    debugLabel: 'userPreferencesUpdateReq'
  });

  /* fetch customers */
  useFetchGridDataWithPagination(
    'customers',
    customerFirstPageRequest,
    customerRestPagesRequest,
    customers,
    setCurrentPage,
    itemsPerPage,
    selectedVendor && selectedVendor.id,
    currentPage,
  );

  /* if we have user column preferences use them. */
  useEffect(() => {
    if (
      userPreferences &&
      userPreferences.customerTableColumnOrder &&
      userPreferences.customerTableColumnOrder.length > 0
    ) {
      setColumns(userPreferences.customerTableColumnOrder);
    }
  }, [userPreferences]);

  const mappedColumns = mapCustomerGridColumns(columns, featureFlags, sortBy, isVendorAcc, currentPage);
  const initialColumnsFilteredByFlags = customerColumns.filter(column => filterDowJonesRelations(featureFlags, column.title));
  const switchers = [
    {
      label: 'Compliance check failed',
      checked: showDowJonesFailedCustomers,
      onChange: onSwitchChange
    }
  ].filter(switcher => filterDowJonesRelations(featureFlags, switcher.label));

  const customersFromSwitch = applySwitchFilter(customers.customers, showDowJonesFailedCustomers);
  const customersFromSearch = applySearchFilter(customersFromSwitch, ['customerId', 'fullName', 'email', 'companyName', 'displayName', 'createdDate', 'companyNameByCustomer'], searchTerm);
  const customersFromSort = applySortFilter(customersFromSearch, sortBy);
  // const customersFromPagination = applyPaginationFilter(customersFromSort, currentPage, itemsPerPage);
  // const pagesCount = calculatePages(customersFromSearch.length, itemsPerPage);
  const pagesCount = customers.totalPages;

  const isLoading = currentPage === 1
    ? customerFirstPageRequest.isLoading
    : (customerFirstPageRequest.isLoading || customerRestPagesRequest.isLoading);

  return (
    <Page>
      <PageHeading>Customers</PageHeading>
      <MatTable
        columns={mappedColumns}
        data={customersFromSort}
        isLoading={isLoading}
        onRowClick={onRowClick}
        onColumnDragged={onColumnDragged}
        onOrderChange={onSortChange}
        draggable
        toolbar
        overrideToolbar
        wrapperWidth="92%"
        switchers={isVendorAcc ? [] : switchers}
        customSearch={{
          placeholder: 'Search',
          value: searchTerm,
          onChange: onSearchTermSet
        }}
        customPagination={{
          itemsPerPage,
          setItemsPerPage: onSizeChange,
          currentPage,
          setCurrentPage,
          totalPages: pagesCount,
          baseRoute: 'customers'
        }}
        initialColumns={initialColumnsFilteredByFlags}
        onUpdateColumns={onChangeColumnHidden}
      />
    </Page>
  );
};

export default CustomersList;
