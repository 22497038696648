import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Select from 'components/UI/Select';
import Input from 'components/UI/Input';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { DatePicker } from '@material-ui/pickers';
import { FontWeight } from 'lib/styles';
import { Main, Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import DatePickerInput from 'components/UI/DatePickerInput';
import Button from 'components/UI/Button';
import * as Cons from 'data/Consignment';
import RentalDates from './components/RentalDates';
import { CONSIGNMENT_TYPES } from 'constants/order';
import DateView from 'components/UI/DateView';
import { formatDateTime } from 'utils/formatters';

const LabelText = styled.span`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
  margin-bottom: 5px;
  color: ${Text.primary};
  font-size: 15px;
`;

interface Props {
  shippingCarrierValue: string;
  shippingCarriers: any[];
  onChange: (event: any) => void;
  trackingId: string;
  onTrackingIdChange: (e: ChangeEvent<HTMLInputElement>) => void;
  deliveryDate?: string | null;
  onDeliveryDateChange: (value: any) => void;
  button?: JSX.Element;
  isRental?: boolean;
  consignmentData ?: Cons.ConsignmentDetails,
  setShowModal?: (show: boolean) => void;
};

const ShippingDetails = ({
  shippingCarrierValue,
  shippingCarriers,
  onChange,
  trackingId,
  onTrackingIdChange,
  deliveryDate,
  onDeliveryDateChange,
  button,
  isRental,
  consignmentData,
  setShowModal
}: Props) => {
  return (
    <>
      <FlexboxContainer flexDirection="row" width="100%" justifyContent="space-between">
        <FlexboxContainer flexDirection="row" alignItems="center" padding="5px 0 0 0" >
          <FlexboxContainer width='200px' margin={`0 ${Space.spHalf} 0 0`}>
            <Select
              noBoxShadow
              options={shippingCarriers}
              value={shippingCarrierValue}
              defaultValue="Select"
              onChange={onChange}
              labelPosition='top'
              label="Carrier"
              width="100%"
            />
          </FlexboxContainer>
          <Input
            id="tracking-id"
            labelName="Tracking #"
            placeholder="Enter tracking number"
            value={trackingId}
            onChange={onTrackingIdChange}
            width="224px"
            maxLength='25'
          />
          <FlexboxContainer flexDirection="column" width='150px' margin='0 0 0 4px'>
            <LabelText>Est. delivery</LabelText>
            {
              consignmentData?.consignmentType === CONSIGNMENT_TYPES.PURCHASE ? 
              <DatePicker
                value={deliveryDate}
                onChange={onDeliveryDateChange}
                inputVariant="outlined"
                format="yyyy MMM dd"
                TextFieldComponent={DatePickerInput}
                autoOk
              /> :
              <DateView date={formatDateTime(deliveryDate!)} onClick={() => setShowModal?.(true)} />
            }
          </FlexboxContainer>
        </FlexboxContainer>
        <FlexboxContainer margin="30px 20px 0 4px" justifyContent="flex-end" alignItems="flex-end">
          { isRental ? 
            <Button
            className='mr-5'
            onClick={() => setShowModal?.(true)}
            isGhost
            color={Main.accent}
          >
            Edit
          </Button>
          : ''}
            {button}
          </FlexboxContainer>
      </FlexboxContainer>
    { isRental ? <RentalDates datesFormat='yyyy MMM dd' consignmentData={consignmentData} isRental={isRental} showIcon={false} /> : ''}
    </>
  );
};

export default React.memo(ShippingDetails);
