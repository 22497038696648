import { WAREHOUSE_DELIVERY_OPTIONS } from 'constants/productColumns';
import { DELIVERY_OPTIONS } from 'constants/warehouseDeliveryOptions';
import Warehouse from 'data/Warehouse';
import Product, * as Prod from 'data/Product';
import Currency from 'data/Currency';
import { PRICE_TYPE } from 'constants/product';
import { COUNTRY_ISO } from 'constants/country';
import Variant from 'data/Variant';
import { getItems } from 'lib/SessionStorage';
import Country from 'data/Country';

export const parsePointOfServicePayload = (pos: any) => ({
  posId: pos.posId,
  nickname: pos.nickname,
  name: pos.name,
  code: pos.code,
  street: pos.street,
  city: pos.city,
  state: pos.state,
  zip: pos.zip,
  display: pos.display,
  latitude: pos.latitude,
  longitude: pos.longitude,
  stateIsoCode: pos.stateIsoCode,
  regionIsoCode: pos.regionIsoCode,
  countryIsoCode: pos.countryIsoCode,
  shortIsoCode: pos.shortIsoCode,
  countryId: Number(pos.country?.id),
});

export const mapDeliveryOption = (warehouse: Warehouse) => {
  if (warehouse.delivery && warehouse.pickup) {
    return DELIVERY_OPTIONS.SHIPPING_AND_PICKUP;
  } else if (warehouse.delivery) {
    return DELIVERY_OPTIONS.SHIPPING_ONLY;
  } else if (warehouse.pickup) {
    return DELIVERY_OPTIONS.PICKUP_ONLY;
  } else {
    return DELIVERY_OPTIONS.SHIPPING_AND_PICKUP;
  }
};

export const warehouseDeliveryMood = (warehouse: Prod.ProductWarehouse) => {
  let status;
  if (warehouse.delivery && warehouse.pickup) {
    return (status = WAREHOUSE_DELIVERY_OPTIONS.SHIPPING_AND_PICKUP);
  } else if (warehouse.delivery) {
    return (status = WAREHOUSE_DELIVERY_OPTIONS.SHIPPING_ONLY);
  } else if (warehouse.pickup) {
    return (status = WAREHOUSE_DELIVERY_OPTIONS.PICKUP_ONLY);
  } else {
    return (status = 'none');
  }
};

export const getUpdateVariantsOnWarehouseAdd = (
  product: Product,
  updatedWarehouses: any[],
  currencies: Currency[]
) => {
  const updatedVariants = product.variants.map((variant) => {
    updatedWarehouses.forEach((warehouse: any) => {
      // Create product stock for each warehouse if not already exists
      if (
        !variant.productStocks?.some(
          (stock) => stock.warehouseId === warehouse.id && stock.type === warehouse.type
        )
      ) {
        variant.productStocks!.push({
          warehouseId: warehouse.id,
          available: true,
          availableForPickup: product.availableForPickup,
          availableForShipping: product.shipping,
          postOrderShippingCost: product.postOrderShippingCost,
          deliveryLeadDays: product.deliveryLeadDays,
          type: warehouse?.type
        });
      }

      // Create product variant price for each country if not already exists
      const countryId = warehouse.address?.country?.id;
      if (
        !variant.productVariantPrices?.some(
          (price) => price.countryId === countryId
        )
      ) {
        variant.productVariantPrices!.push({
          countryId: countryId,
          pricePerUnit: 0,
          itemsPerUnit: 1,
          productVariantId: variant.id,
          currencyId: currencies?.find(
            (currency) => currency.countryId === countryId
          )?.id,
        });
      }

      if(warehouse.type === PRICE_TYPE.RENT){
        if(warehouse.address.country.countryIso === COUNTRY_ISO.US && (!variant.rentalPriceUS || variant.rentalPriceUS?.length === 0)){
          const emptyRentalPriceObj = { id: undefined, range: null, rangeUnits: 'Days', rangePrice: 0 }
          variant.rentalPriceUS = Array.from({ length: 5 }, () => ({ ...emptyRentalPriceObj })) as any;
        }else if(warehouse.address.country.countryIso === COUNTRY_ISO.CA && (!variant.rentalPriceCA || variant.rentalPriceCA?.length === 0)){
          const emptyRentalPriceObj = { id: undefined, range: null, rangeUnits: 'Days', rangePrice: 0 }
          variant.rentalPriceCA = Array.from({ length: 5 }, () => ({ ...emptyRentalPriceObj })) as any;
        }
      }
    });

    variant.dirty = true;

    return variant;
  });

  return updatedVariants;
};


export const getFieldNameForWarehouseColumn = (key: string) => {
  switch (key) {
    case 'available':
      return 'In stock'
    case 'deliveryLeadDays':
      return 'Delivery lead days'
    case 'postOrderShippingCost':
      return 'Post order shipping cost'
    default:
      return key
  }
};

export const getColumnMetaDataForWarehouse = (key: string, label: string, warehouse:any) => {
  return {
    key,
    label,
    isWarehouseColumn: true,
    warehouse: warehouse
  }
}

const getCountriesUniqueListFromWarehouses = (warehouses:  Prod.ProductWarehouse[]) => {
  const countryIds = Array.from(
    new Set(warehouses.map(warehouse => warehouse?.address?.country.id))
  );
  return countryIds;
}


/**
 * Updates variants by filtering productVariantPrices based on available countries in warehouses.
 *
 * @param {Array} updatedVariants - The array of updated variants.
 * @param {Array} updatedWarehouses - The array of updated warehouses.
 * @returns {Array} - The updated variants with filtered productVariantPrices.
 */
export const updateVariantPricesWithAvailableCountries = (variants: Variant[], updatedWarehouses: Prod.ProductWarehouse[]) => {

  // Recompute the list of country IDs based on the updated warehouses

  const countryIds = getCountriesUniqueListFromWarehouses(updatedWarehouses);

  const updatedVariants = variants.map(variant => {
    const updatedProductVariantPrices = variant?.productVariantPrices?.filter(price =>
      countryIds.includes(price.countryId)
    );
    return {
      ...variant,
      productVariantPrices: updatedProductVariantPrices,
    };
  });

  return updatedVariants;
}


/**
 * Updates variants by filtering productVariantRanges based on available countries in warehouses.
 *
 * @param {Array} updatedVariants - The array of updated variants.
 * @param {Array} updatedWarehouses - The array of updated warehouses.
 * @returns {Array} - The updated variants with filtered productVariantPrices.
 */
export const updateVariantRangesWithAvailableCountries = (variants: Variant[], updatedWarehouses: Prod.ProductWarehouse[]) => {
  // Recompute the list of country IDs based on the updated warehouses

  const countryIds = getCountriesUniqueListFromWarehouses(updatedWarehouses);
  const countries = getItems('countries');

  // Find the specific countries for CA and US based on CountryIsoCode
  const canadaCountry = countries.find((country: Country) => country.countryIso === COUNTRY_ISO.CA);
  const usaCountry = countries.find((country: Country) => country.countryIso === COUNTRY_ISO.US);


  const updatedVariants = variants.map(variant => {
    const updatedRentalPriceCA = canadaCountry && countryIds.includes(canadaCountry.id)
      ? variant.rentalPriceCA
      : [];

    const updatedRentalPriceUS = usaCountry && countryIds.includes(usaCountry.id)
      ? variant.rentalPriceUS
      : []; // Empty array if no US country ID exists in countryIds

    return {
      ...variant,
      rentalPriceCA: updatedRentalPriceCA,
      rentalPriceUS: updatedRentalPriceUS,
    };
  });

  return updatedVariants;
}

export const getWarehousesByType = (warehouses: Warehouse[], type: string): Warehouse[] => {
  return warehouses.filter(warehouse => warehouse?.type === type || (!warehouse?.type && type === PRICE_TYPE.PURCHASE));
};