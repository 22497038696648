import React, { useState, useEffect, ReactNode } from 'react'
import Input from 'components/UI/Input';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import PointOfService from 'data/PointOfService';
import styled from 'styled-components';
import { Percent } from 'lib/styles';
import Country from 'data/Country';
import { getStates } from 'requests/country';
import { useAPI } from 'lib/API';
import Select from 'components/UI/Select';
import PosAddFormValidator from 'data/PosFormValidator';
import { warehousePosValidations } from 'constants/errorMessages';

interface Props {
  pickupLocation: PointOfService;
  handleChange: (value: string, name: string) => void;
  country: Country | undefined;
  posValidator: PosAddFormValidator;
  onInputBlur: (value: string, validatorFunction: () =>void ) => void;
  validateZipCode: () => void;
  validateCity: () => void;
  validateStreet: () => void;
  validateFullname: () => void;
  validateNickname: () => void;
  validateId: () => void;
};


const FourRowDiv = styled.div`
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-row-gap: 15px;
`;


const JustifySplitDiv = styled.div`
  width: ${Percent.full};
  display: flex;
  justify-content: space-between;
`;

interface Option {
  value: string;
  id?: string;
  icon?: ReactNode;
  badge?: any;
}

const PickupLocationEditForm = ({ pickupLocation, handleChange, country, posValidator, onInputBlur, validateZipCode, validateCity, validateStreet, validateFullname, validateNickname, validateId }: Props) => {
  const [statesList, setStates] = useState<Option[]>([]);

  const wareHouseCountryStates = useAPI({
    deferFn: getStates,
    onResolve: (result: any) => {
      const options = result.map((state:any) => ({
        value: state.state,
        id: state.id?.toString(),
        countryIso: state.countryIsoCode
      }));
      setStates([...options]);
    },
    onReject: (err: any) => { },
  });

  useEffect(() => {
    wareHouseCountryStates.run([country?.countryIso]);
  }, [country]);

  return (
    <FlexboxContainer flexDirection="column" width="100%" >

      <FourRowDiv>
        <FlexboxContainer>
          <JustifySplitDiv>
          <div style={{"width" : "33%"}}>
            <Input
                id='fullname-id'
                labelName="Fullname"
                placeholder='Fullname'
                value={pickupLocation.name}
                width="100%"
                name="name"
                onInputChange={(val) => handleChange(val, 'name')}
                displayAsterisk
                isInvalid={posValidator.name}
                onBlur={()=>onInputBlur && onInputBlur('name', validateFullname)}
                errorMessage={warehousePosValidations.INVALID_FULLNAME}
              />
          </div>
          <div style={{"width" : "33%"}}>
            <Input
              id='nickname-id'
              labelName="Nickname"
              placeholder='Nickname'
              value={pickupLocation.nickname}
              width="100%"
              name="nickname"
              onInputChange={(val) => handleChange(val, 'nickname')}
              displayAsterisk
              isInvalid={posValidator.nickname}
              onBlur={()=>onInputBlur && onInputBlur('nickname', validateNickname)}
              errorMessage={warehousePosValidations.INVALID_NICKNAME}
            />
            </div>
             <div style={{"width" : "33%"}}>
            <Input
              id='pickup-location-edit-form-id'
              labelName="ID"
              placeholder='Id'
              value={pickupLocation.posId}
              width="100%"
              name="posId"
              onInputChange={(val) => handleChange(val, 'posId')}
              displayAsterisk
              isInvalid={posValidator.posId}
              onBlur={()=>onInputBlur && onInputBlur('posId', validateId)}
              errorMessage={warehousePosValidations.INVALID_ID}
            />
            </div>
          </JustifySplitDiv>

        </FlexboxContainer>

        <FlexboxContainer>
          <Input
            id='country-id'
            labelName="Country"
            placeholder='Country'
            value={country?.countryName || ''}
            width="33%"
            name="country"
            disabled
            readOnly
          />
        </FlexboxContainer>

        <FlexboxContainer>
          <Input
            id='street-id'
            labelName="Street address"
            placeholder='Street'
            value={pickupLocation.street}
            width="100%"
            name="street"
            onInputChange={(val) => handleChange(val, 'street')}
            displayAsterisk
            isInvalid={posValidator.street}
            onBlur={()=>onInputBlur && onInputBlur('street', validateStreet)}
            errorMessage={warehousePosValidations.INVALID_STREET}
          />
        </FlexboxContainer>

        <FlexboxContainer >
          <JustifySplitDiv>
          <div style={{"width" : "33%"}}>
            <Input
              id='city-id'
              labelName="City"
              placeholder='City'
              value={pickupLocation.city}
              width="100%"
              name="city"
              onInputChange={(val) => handleChange(val, 'city')}
              displayAsterisk
              isInvalid={posValidator.city}
              onBlur={()=>onInputBlur && onInputBlur('city', validateCity)}
              errorMessage={warehousePosValidations.INVALID_CITY}
            />
            </div>
            <div style={{"width" : "33%"}}>
              <Select
                label='State / Province'
                labelPosition="top"
                options={statesList}
                displayAsterisk
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const state: any = statesList.find((state:Option) => state.value === event.target.value)
                  handleChange(state.value, 'state')
                }}
                value={statesList.find(state => state.value === String(pickupLocation.state))?.value || ''}
                defaultValue="Select"
                width="100%"
                height="36px"
              />
            </div>
            <div style={{"width" : "33%"}}>
              <Input
                id='zip-code-id'
                placeholder='Zip code / Postal code'
                labelName="Zip code / Postal code"
                value={pickupLocation.zip}
                width="100%"
                name="zip"
                onInputChange={(val) => handleChange(val, 'zip')}
                displayAsterisk
                isInvalid={posValidator.zip}
                onBlur={()=>onInputBlur && onInputBlur('zip', validateZipCode)}
                errorMessage={warehousePosValidations.INVALID_ZIP}
              />
            </div>
          </JustifySplitDiv>

        </FlexboxContainer>
      </FourRowDiv>




    </FlexboxContainer>
  )
};

export default PickupLocationEditForm;
