import React, { useState, useEffect, Ref, ElementType } from 'react';
import styled, { css } from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Background, Border, Main, Text } from 'styles/themeColors';
import { Height } from 'styles/themeHeights';
import { Space } from 'styles/themeSpaces';
import CurrencyInputComponent from 'react-currency-input-field';

const Label = styled.label<any>`
    color: ${Text.primary};
    font-size: ${Space.sp2x};
    line-height: ${Height.xsPlus};
`;

const StyledInput = styled(CurrencyInputComponent) <any>`
    text-align: ${({ $textAlign }) => $textAlign};
    border-color: ${({ $error }) => $error ? Main.error : Border.main} !important;
    background-color: ${({ disabled }) => disabled ? Main.disabled : Background.white};
    box-sizing: border-box;
    border-radius: ${BorderRadius.m};
    width: ${({ width }) => width};
    height: 100%;
    padding: ${Space.spBase};
    outline: none !important;

    ::placeholder,
    ::-webkit-input-placeholder {
        font-style: italic;
    }

    &:disabled {
        ${({ $blocked }) => $blocked && css`
        color: ${Text.primary};
        border: none;
        `}
    }

    svg.MuiSvgIcon-root MuiSelect-icon {
        color: ${Text.primary};
    }

    &:focus {
        box-shadow: 0 0 0 3px ${({ $error }) => $error ? 'rgba(200, 33, 53, 0.4)' : 'rgba(73, 141, 218, 0.4)'};
    }

    &:hover {
        ${({ $error, disabled }) => !disabled && css`
            border: 1px solid ${$error ? Main.error : Main.accent};
        `}
    }
`;

const StyledErrorMessage = styled.span`
    color: ${Main.error} !important;
    margin-top: ${Space.spQuarter};
`;

const InputContainer = styled(FlexboxContainer) <any>`
    padding-left: inherit;
    width: ${({ $labelPosition }) => $labelPosition === 'top' ? '100%' : '55%'};

    ${({ $blocked }) => $blocked && css`
        .Mui-disabled {
            border: none;
        }
    `}
`;

const LabelContainer = styled(FlexboxContainer) <any>`
    width: ${({ $labelPosition }) => $labelPosition === 'top' ? '100%' : '156px'};
    margin-bottom: ${({ $labelPosition }) => $labelPosition === 'top' ? '8px' : '0px'};
    margin-right: ${({ $labelPosition }) => $labelPosition === 'left' ? '4px' : '0px'};
`;

const Description = styled.span<any>`
    color: ${Text.primary};
    line-height: ${Height.xsPlus};
    word-wrap: break-word;
    font-size: ${Space.spBasePlus};
    margin-top: ${Space.spQuarter};
`;


interface CurrencyInputOnChangeValues {
    float: number | null;
    formatted: string;
    value: string;
  };

interface Props {
    id: string;
    className?: string;
    name?: string;
    type: string;
    value: any;
    placeholder?: string;
    labelName?: string;
    labelPosition?: 'top' | 'left';
    description?: string;
    width?: string;
    isInvalid?: boolean;
    required?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    blocked?: boolean;
    dirty: boolean;
    textAlign: string;
    selectAllOnFirstClick?: boolean;
    allowDecimals?: boolean;
    allowNegativeValue?: boolean;
    maxLength?: number;
    customInput?: ElementType;
    decimalsLimit?: number;
    decimalScale?: number;
    defaultValue?: number | string;
    fixedDecimalLength?: number;
    onValueChange?: (
        value: string | undefined,
        name?: string,
        values?: CurrencyInputOnChangeValues
    ) => void;
    prefix?: string;
    suffix?: string;
    step?: number;
    decimalSeparator?: string;
    groupSeparator?: string;
    disableGroupSeparators?: boolean;
    disableAbbreviations?: boolean;
    intlConfig?: any;
    ref?: Ref<HTMLInputElement>;
    transformRawValue?: (rawValue: string) => string;
    children?: JSX.Element;
};

const CurrencyInput = (props: Props) => {
    const {
        id,
        className,
        name,
        type,
        value,
        placeholder,
        labelName,
        labelPosition,
        description,
        width,
        isInvalid,
        required,
        errorMessage,
        disabled,
        blocked,
        dirty,
        textAlign,
        selectAllOnFirstClick,
        children
    } = props;

    const {
        allowDecimals,
        allowNegativeValue,
        maxLength,
        customInput,
        decimalsLimit,
        decimalScale,
        defaultValue,
        fixedDecimalLength,
        onValueChange,
        prefix,
        suffix,
        step,
        decimalSeparator,
        groupSeparator,
        disableGroupSeparators,
        disableAbbreviations,
        intlConfig,
        ref,
        transformRawValue
    } = props;

    const currencyInputProps = {
        allowDecimals,
        allowNegativeValue,
        maxLength,
        customInput,
        decimalsLimit,
        decimalScale,
        defaultValue,
        fixedDecimalLength,
        onValueChange,
        prefix,
        suffix,
        step,
        decimalSeparator,
        groupSeparator,
        disableGroupSeparators,
        disableAbbreviations,
        intlConfig,
        ref,
        transformRawValue
    };


  const [isDirty, setIsDirty] = useState<boolean>(dirty);
  const [currencyValue, setCurrencyValue] = useState(props.value);

  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty]);

  useEffect(() => {
    setCurrencyValue(props.value)
  }, [props.value]);

  const handleClick = () => {
    if (selectAllOnFirstClick && !isDirty) {
      (document.getElementById(id) as HTMLInputElement)?.select();
    }
  };

  const label = labelName && (
    <LabelContainer $labelPosition={labelPosition}>
      <Label>
        {labelName}
      </Label>
    </LabelContainer>
  );

  const currencyInputElement = (
      <StyledInput
        id={id}
        className={`base ${disabled ? '' : 'border'} ${className}`}
        name={name}
        type={type}
        placeholder={disabled ? '' : placeholder}
        disabled={disabled || blocked}
        onBlur={() => setIsDirty(true)}
        width="100%"
        $error={(required && isDirty && !value) || isInvalid}
        $blocked={blocked}
        $textAlign={textAlign}
        onClick={handleClick}
        style={{outline: 'none'}}
        value={currencyValue}
        {...currencyInputProps}
      />
  );

  const error = ((required && isDirty && !value) || isInvalid) && (
    <StyledErrorMessage className="caption">
      {errorMessage}
    </StyledErrorMessage>
  );

  const descriptionElement = (
    <Description>
      {description}
    </Description>
  );

  return (
    <FlexboxContainer
      flexWrap='wrap'
      padding={Space.spHalf}
      width={width}
      justifyContent='space-between'
    >
      {labelPosition === 'left' && label}
      <InputContainer
        $labelPosition={labelPosition}
        flexDirection='column'
        $blocked={blocked}
      >
        {labelPosition === 'top' && label}
        {currencyInputElement}
        {error}
        {!error && descriptionElement}
        {children}
      </InputContainer>
    </FlexboxContainer>
  );
};

CurrencyInput.defaultProps = {
  className: '',
  type: 'text',
  labelPosition: 'top',
  placeholder: 'Leave a comment',
  width: '100%',
  errorMessage: 'This field is required',
  disabled: false,
  blocked: false,
  required: false,
  isInvalid: false,
  dirty: false,
  textAlign: 'left',
  padding: Space.spHalf,
  allowNegativeValue: false
};

export default CurrencyInput;

