import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Space } from 'styles/themeSpaces';
import { Main, Text, Shade } from 'styles/themeColors';
import { Height } from 'styles/themeHeights';
import { Sizes, MainColors } from 'types/enumTypes';

const StyledButton = styled.button<any>`
  background-color: ${({ theme }: Props) => Main[theme]};
  color: ${({ $color }) => $color};
  border-color: ${({ theme }: Props) => Main[theme]} !important;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  cursor: ${({ cursor }) => cursor ? cursor : 'pointer'};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: fit-content;
  margin: ${({ margin }) => margin};
  font-family: 'Lato', sans-serif !important;

  ${({ padding }) => padding ? css`
    padding: ${padding} !important;
  ` : css`
  padding: 0 ${Space.spBasePlus};
  `} 

  ${({ bgColor }) => bgColor && css`
    background-color: ${bgColor};
  `}

  ${({ bg }) => bg && css`
    background: ${bg};
  `}
  ${({ borderColor }) => borderColor && css`
    border-color: ${borderColor} !important;
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    opacity: 0.6;
  `}

  ${({ size }) => size === 'm' && css`
    height: ${Height.m};
    padding: 0 ${Space.spBasePlus};
  `}

  ${({ size }) => size === 's' && css`
    height: ${Height.s};
    padding: 0 ${Space.spHalf};
  `}

  ${({ iconButton, size }) => iconButton && size === 'm' && css`
    height: ${Height.m};
    width: 40px;
    min-width: 40px;
  `}

  ${({ iconButton, size }) => iconButton && size === 's' && css`
    height: ${Height.s};
    width: 24px;
    min-width: 24px;
  `}

  &:hover {
    filter: brightness(0.97);
  }

  ${({ isGhost, theme, borderColor }: Props) => isGhost && css`
    border-color: ${borderColor ? borderColor : Main[theme]} !important;
    background-color: transparent;
  `}

  ${({ isSecondary }: Props) => isSecondary && css`
    background-color: ${Shade.shade550};
    border-color: ${Shade.shade550};
  `}

  ${({ isGhost, isSecondary }: Props) => isGhost && isSecondary && css`
    border-color: ${Shade.shade550};
    background-color: transparent;
    color: ${Shade.shade550} !important;
  `}

  ${({ $width }) => $width && css`
    width: ${$width};
  `}
`;

export interface Props {
  children?: ReactNode;
  id?: string;
  className?: string;
  theme: MainColors;
  disabled?: boolean;
  width?: string;
  margin?: string;
  bgColor?: string;
  color?: string;
  borderColor?: string;
  size?: Sizes;
  leftIcon?: (size: string) => ReactNode;
  rightIcon?: (size: string) => ReactNode;
  onClick?: (event: any) => void;
  isGhost?: boolean;
  isSecondary?: boolean;
  padding?: string;
  background?: string;
  cursor?: string;
  type?: 'button' | 'submit' | 'reset';
};

const Button = ({
  children,
  id,
  className,
  theme,
  disabled,
  leftIcon,
  rightIcon,
  margin,
  bgColor,
  color,
  borderColor,
  size,
  onClick,
  isGhost,
  isSecondary,
  width,
  padding,
  background,
  cursor,
  type
}: Props) => {
  const marginLeft = leftIcon && children ? '4px' : '0';
  const marginRight = rightIcon && children ? '4px' : '0';

  return (
    <StyledButton
      $width={width}
      id={id}
      className={`border ${className}`}
      theme={theme}
      disabled={disabled}
      size={size}
      bgColor={bgColor}
      $color={color}
      borderColor={borderColor}
      margin={margin}
      onClick={onClick}
      isGhost={isGhost}
      isSecondary={isSecondary}
      iconButton={!children}
      padding={padding}
      bg={background}
      cursor={cursor}
      type={type}
    >
      {leftIcon ? leftIcon('16px') : null}
      {
        children &&
        <span style={{ marginLeft, marginRight, display: 'flex', alignItems: 'center', width: '100%' }}>
          {children}
        </span>
      }
      {rightIcon ? rightIcon('16px') : null}
    </StyledButton>
  )
};

Button.defaultProps = {
  className: '',
  theme: 'accent',
  disabled: false,
  color: Text.whitePrimary,
  size: 'm',
  isGhost: false,
  isSecondary: false
};

export default Button;
