import { get, del, postFile } from 'lib/API';
import Media from 'data/Media';
import { UploadOptionsImage, UploadOptions, MediaImage } from 'data/Media';

export const deleteMedia = async (id: number, path: string, token: string) => {
  return await del(`v1/media/${id}?path=${path}`, token);
};

export async function postMedia(args: UploadOptionsImage & { vendorId?: number }): Promise<MediaImage>;
export async function postMedia(args: UploadOptions & { vendorId?: number }): Promise<Media>;
export async function postMedia({ file, filename, token, type, vendorId }: UploadOptions & { vendorId?: number }): Promise<Media> {
  let fName = '';
  if(filename && filename.trim() !== '') {
    fName = 'name=' + filename.replaceAll(' ', '-').toLowerCase() + '&';
  }
  return await postFile(`v1/media?${fName}type=${type}${vendorId && vendorId !== 0 ? '&vendorId=' + vendorId : ''}`, file, token);
};

export const getMedia = async ([mediaId]: any, { token }: any): Promise<Media> => {
  return await get(`v1/media/${mediaId}`, token);
};
