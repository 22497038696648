
export const validateHiddenValue = (value: string): boolean => {

    const hiddenValue = value ? value.toLowerCase() : "";
    // Check if the value is neither "yes" nor "no"
    if (hiddenValue !== "yes" && hiddenValue !== "no" && hiddenValue !== "") {
        return false;
    } else {
        return true;
    }
}

export const validatePriceValue = (value: string): boolean => {


    const currencyPriceRegex = /^\d+(\.\d{1,2})?$/;

    if(!value || currencyPriceRegex.test(value)){
        return true;
    }
    else{
        return false
    }
}