import React, { useRef, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SuperCategory, * as SuperCat from 'data/SuperCategory';
import Modal from 'components/UI/Modal';
import ErrorMessage from 'view/ErrorMessage';
import Input from 'components/UI/Input';
import { renderToast } from 'components/UI/ToastNotification';
import { useAPI } from 'lib/API';
import { createSuperCategory, saveSuperCategory } from 'requests/superCategory'


export interface Props {
  onClose: () => void;
  superCategories: SuperCat.SuperCategorySummary[];
  selectedSuperCategoryId: number | null;
  setShouldReload: any;
  showModal: boolean;
};

export default function SuperCategoryModal({ onClose, superCategories, selectedSuperCategoryId, setShouldReload, showModal }: Props) {
  const [newSuperCategory, setNewSuperCategory] = useState<SuperCat.PartialSuperCategory>({
    name: ''
  });
  const [superCategoryToUpdate, setSuperCategoryToUpdate] = useState<SuperCategory | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const createReq = useAPI({
    deferFn: createSuperCategory,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Category created.');
      setShouldReload(true);
      onClose();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const updateReq = useAPI({
    deferFn: saveSuperCategory,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Category updated.');
      setShouldReload(true);
      onClose();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    const selectedSuperCategory = superCategories.find(superCategory => superCategory.id === selectedSuperCategoryId);
    setSuperCategoryToUpdate(selectedSuperCategory!);
  }, [selectedSuperCategoryId]);

  function handleClick() {
    if (superCategoryToUpdate) {
      updateReq.run(superCategoryToUpdate);
    } else {
      createReq.run(newSuperCategory);
    }
  }

  const cantSubmit =
    superCategoryToUpdate
      ? (updateReq.isPending || (superCategoryToUpdate !== null && superCategoryToUpdate.name.trim().length === 0))
      : (createReq.isPending || newSuperCategory.name.trim().length === 0);

  let errorMessage = null;
  if (createReq.error) {
    errorMessage = <ErrorMessage message={createReq.error.message} />;
  } else if (updateReq.error) {
    errorMessage = <ErrorMessage message={updateReq.error.message} />;
  }

  return (
    <Modal
      showModal={showModal}
      showCloseIcon
      onClose={onClose}
      onClick={handleClick}
      disabled={cantSubmit}
      primaryButtonText='Save'
      title={superCategoryToUpdate ? 'Edit Category' : 'Create Category'}
    >
      <Input
        id='super-category-modal-id'
        placeholder='Category name'
        disabled={createReq.isPending || updateReq.isPending}
        labelName="Name"
        onInputChange={
          superCategoryToUpdate
            ? name => setSuperCategoryToUpdate({ ...superCategoryToUpdate!, name })
            : name => setNewSuperCategory({ ...newSuperCategory, name })
        }
        value={superCategoryToUpdate && superCategoryToUpdate.name ? superCategoryToUpdate.name : newSuperCategory.name}
      />
      {errorMessage}
    </Modal>
  );
};

SuperCategoryModal.defaultProps = {};
