import BrandVendor from "data/BrandVendor";
import { ProductVendor } from "data/Product";
import { Role } from "data/User";

export const showMaterialNumber = (brandVendor: BrandVendor , isSlbVendor: boolean = false, role: Role | undefined) => {
    return brandVendor?.isMaterialApiEnabled && (isSlbVendor || role === Role.Admin || role === Role.SuperAdmin);
}

export const isMaterialApiEnabled = (brandVendor: BrandVendor, vendor: ProductVendor) => {
    return brandVendor?.isMaterialApiEnabled && vendor?.isSlbVendor;
}