import React from 'react';
import styled from 'styled-components';
import * as Prod from 'data/Product';
import Card from 'view/Card';
import Checkbox from 'components/UI/Checkbox';
import Slab from 'view/Slab';
// import Pill from 'components/UI/Pill';
import { Size } from 'lib/styles';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Background } from 'styles/themeColors';
import placeholderImage from '../images/placeholder-image.jpg';

const Actions = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: ${Size.tiny};
    width: 100%;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const CountSlab = styled(Slab)`
  margin: 0px ${Size.medium};
  padding-top: 16px;
`;

const ProductDetail = styled.div`
  flex: 1;
  font-size: 0.9em;
`;

const ProductDetails = styled.div`
  display: flex;
`;

const ProductImage = styled.img<any>`
  height: 64px;
  width: 64px;
`;

const ProductInfo = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px ${Size.medium};
`;

const ProductLine = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const ProductName = styled.div`
  font-size: 1.6em;
  font-weight: 700;
`;

const Version = styled.div`
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;

const PlaceHolderImage = styled.img`
  background-color: ${Background.white};
  height: 64px;
  margin-right: ${Size.medium};
  object-fit: contain;
  object-position: 50%;
  padding: ${Size.tiny};
  width: 64px;
`;


// const IssuesContainer = styled.div`
//   align-items: center;
//   display: flex;
//   justify-content: flex-end;
//   min-width: 6rem;
//   margin-right: 10px;
// `;

type SortOrder = 'Most recent' | 'Vendor';

interface Props {
  actions?: React.ReactNode;
  onToggleSelection?: () => void;
  product: Prod.ProductCard;
  selected?: boolean;
  sortOrder: SortOrder;
  errorCount?: number;
}

export default function ProductSubmissionCard({
  actions,
  onToggleSelection,
  product,
  selected = false,
  sortOrder,
  errorCount
}: Props) {
  //const errorsPillText = errorCount > 0 ? `${errorCount} Issues` : '';

  const updatedString =
    new Date(product.updatedOn).toLocaleDateString(undefined, {
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }) || '';

  let details: string[] = [];
  switch (sortOrder) {
    case 'Most recent':
      details = [product.vendor ? product.vendor.name : ''];
      break;

    case 'Vendor':
      details = [
        `${product.status === 'ready' ? 'Submitted' : 'Published'
        }: ${updatedString}`
      ];
      break;
  }

  const detailItems = details.map((d, i) => (
    <ProductDetail key={i}>{d}</ProductDetail>
  ));
  let selection = null;
  if (onToggleSelection) {
    selection =
      <FlexboxContainer margin="16px 0 0 0">
        <Checkbox id='product-submission-id' checkboxSize='xs' appearance="none" checked={selected} onChange={onToggleSelection} />
      </FlexboxContainer>
  }

  let wrappedActions = null;
  if (actions) {
    wrappedActions = <Actions>{actions}</Actions>;
  }

  let version = null;
  if (product.version > 1) {
    version = <Version>Version {product.version}</Version>;
  }

  const productImage = product.productImages && product.productImages.length > 0 ? <ProductImage src={process.env.REACT_APP_MEDIA_URL! + product.productImages[0].path} /> :
    <PlaceHolderImage alt={`${product.name} placeholder-logo`} src={placeholderImage} />;
  return (
    <ProductLine key={product.id}>
      {selection}
      <Card key={product.id}>
        {productImage}
        <ProductInfo>
          <Version>{version}</Version>
          <ProductName>{product.name}</ProductName>
          <ProductDetails>{detailItems}</ProductDetails>
        </ProductInfo>
        <CountSlab
          label={product.variantsCount === 1 ? 'Variant' : 'Variants'}
        >
          {product.variantsCount}
        </CountSlab>
        {/* <IssuesContainer>
          <Pill theme="danger">{errorsPillText}</Pill>
        </IssuesContainer> */}
        <Actions>{wrappedActions}</Actions>
      </Card>
    </ProductLine>
  );
}
