import Button from 'components/UI/Button';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Main, Background } from 'styles/themeColors';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Icon } from '@material-ui/core';

const Container = styled.div<any>``;

const HiddenInput = styled.input`
  display: none;
`;

const UploadButton = styled(Button).attrs({ as: 'label' }) <any> `
  background-color: ${Main.accent};
  color: ${Background.white};
  border-radius: ${BorderRadius.m};
  border: 1px solid;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: brightness(0.97);
  }
`;

export interface Props {
  acceptTypes: string[];
  onChange: (files: File | File[]) => void;
  disabled?: boolean;
  text?: string;
  productRedesignFlag?: boolean;
  borderColor?: string;
  backgroundColor?: string;
  color?: string;
  leftIcon?: (size: string) => React.ReactNode,
  allowMultiple?: boolean;
};

export default function FileUploader({ 
  acceptTypes, 
  onChange, 
  disabled, 
  text='Upload', 
  productRedesignFlag=false, 
  borderColor='#c1c9d1',
  backgroundColor='white',
  color='#666C73',
  leftIcon,
  allowMultiple = false}: Props) {
  const [highlight, setHighlight] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const accept = acceptTypes.join(', ');

  function dragHover(over: boolean) {
    return (ev: React.DragEvent<HTMLDivElement>) => {
      ev.preventDefault();
      setHighlight(over);
    };
  }

  function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    if (ev.target.files && ev.target.files.length === 1) {
      onChange(ev.target.files[0]);
      if (inputRef && inputRef.current) {
        inputRef.current.value = '';
      }
    }else{
      const files = Array.from(ev.target.files!);
      onChange(files);
    }
  }

  function handleDrop(ev: React.DragEvent<HTMLDivElement>) {
    ev.preventDefault();
    setHighlight(false);

    if (ev.dataTransfer.files.length === 1) {
      const file = ev.dataTransfer.files[0];

      if (acceptTypes.length > 0 && acceptTypes.indexOf(file.type) === -1) {
        return window.console.warn(`Only file types allowed: ${acceptTypes}`);
      }

      onChange(file);
    }
  }

  function noop(ev: React.DragEvent<HTMLDivElement>) {
    ev.preventDefault();
  }

  const handleButtonClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Container
      highlight={highlight}
      onDragEnter={dragHover(true)}
      onDragExit={dragHover(false)}
      onDragOver={noop}
      onDrop={handleDrop}
    >
      {
        productRedesignFlag ? 
        <Button
          theme='disabled'
          color={color}
          background={backgroundColor}
          borderColor={borderColor}
          leftIcon={leftIcon}
          onClick={handleButtonClick}
          disabled={disabled}
        >
          {text}
          <HiddenInput
            accept={accept}
            onChange={handleChange}
            ref={inputRef}
            type="file"
            disabled={disabled}
            multiple={allowMultiple}
          />
        </Button> : 
        <UploadButton size="s" disabled={disabled}>
          Upload
          <HiddenInput
            accept={accept}
            onChange={handleChange}
            ref={inputRef}
            type="file"
            disabled={disabled}
          />
        </UploadButton>
      }
    </Container>
  );
};

FileUploader.defaultProps = {
  acceptTypes: []
};

FileUploader.propTypes = {
  acceptTypes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func
};
