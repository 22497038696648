import React from 'react';
import styled from 'styled-components';
import FlexboxContainer from '../../../UI/FlexboxContainer';
import Icon from 'view/Icon';

import { Background, Main, Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { FontSize } from 'styles/themeSizes';
import { Height } from 'styles/themeHeights';

import { PricebookExtended, PricebookState } from 'data/Pricebook';
import {
  DELETED,
  REPLACEMENT_FAILED,
  REPLACEMENT_IN_PROGRESS,
  VERIFICATION_FAILED, VERIFIED,
  VERIFYING
} from 'constants/pricebookStatus';

const PricebookLabelContainer = styled(FlexboxContainer)`
  align-self: end;
  padding: 0 ${Space.spBasePlus} ${Space.sp3x} 0;
`;

const ReplacementLabel = styled.span`
  padding-right: ${Space.spBase};
  color: ${Text.secondary};
  font-size: ${FontSize.subMedium};
  line-height: ${Height.xsMedium};
`;

const Value = styled.div<any>`
  font-size: ${Space.sp2x};
  line-height: 20px;
  background-color: ${({ $bgcolor }) => ($bgcolor ? $bgcolor : 'transparent')};
  padding: ${({ $padding }) => ($padding ? $padding : '0px')};
  color: ${({ $color }) => ($color ? $color : Text.primary)};
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
  border-radius: 6px;
  width: fit-content;
  align-self: self-end;
`;

const pricebookStatesToShowLabel = [
  PricebookState.UPLOADED,
  PricebookState.VALIDATION_FAILED,
  PricebookState.VALIDATED,
  PricebookState.DELETE
];

interface Props {
  pricebook: PricebookExtended;
  showReplacementInProgress: boolean;
  showReplacementFailed: boolean
}

const StateLabel = ({ pricebook, showReplacementInProgress, showReplacementFailed }: Props) => {
  const pricebookState =
    pricebook.replacementPricebookState || pricebook.priceBookState;

  const showComponent = showReplacementInProgress || showReplacementFailed || pricebookStatesToShowLabel.includes(pricebookState);

  return !showComponent ? <></> :
    <PricebookLabelContainer
    flexDirection="row"
    flexWrap="nowrap"
    alignItems="center"
  >
    {(showReplacementInProgress || showReplacementFailed) && (
      <Icon name="InProgress" size={16} />
    )}
    {showReplacementInProgress && (
      <ReplacementLabel>{REPLACEMENT_IN_PROGRESS}</ReplacementLabel>
    )}
    {showReplacementFailed && (
      <ReplacementLabel>{REPLACEMENT_FAILED}</ReplacementLabel>
    )}
    {pricebookState === PricebookState.UPLOADED && (
      <Value
        $bgcolor={Main.warning}
        $weight="700"
        $padding={Space.spHalf}
        $color={Text.primary}
      >
        {VERIFYING}
      </Value>
    )}
    {pricebookState === PricebookState.VALIDATION_FAILED && (
      <Value
        $bgcolor={Main.error}
        $weight="700"
        $padding={Space.spHalf}
        $color={Text.whitePrimary}
      >
        {VERIFICATION_FAILED}
      </Value>
    )}
    {pricebookState === PricebookState.VALIDATED && (
      <Value
        $bgcolor={Main.success}
        $weight="700"
        $padding={Space.spHalf}
        $color={Text.whitePrimary}
      >
        {VERIFIED}
      </Value>
    )}
    {pricebookState === PricebookState.DELETE && (
      <Value
        $bgcolor={Background.shaded}
        $weight="700"
        $padding={Space.spHalf}
        $color={Text.placeholder}
      >
        {DELETED}
      </Value>
    )}
  </PricebookLabelContainer>
}


export default StateLabel;