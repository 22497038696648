export const add = <T>(xs: T[], x: T): T[] => {
  return xs.concat(x);
};

export const addAtIndex = <T>(xs: T[], x: T, index: number) => {
  const i = Math.max(index, 0);

  return xs.slice(0, i).concat(x, xs.slice(i));
};

export const addOrReplace = <T>(
  xs: T[],
  identify: (a: T) => string | number,
  x: T
): T[] => {
  const existingIndex = find(xs, identify, x);

  if (existingIndex > -1) {
    return set(xs, existingIndex, x);
  }

  return xs.concat(x);
};

// Used to toggle values in array (add if it doesn't exist in the array, remove if it already exists)
export const addOrRemove = <T>(
  xs: T[],
  identify: (a: T) => string | number,
  x: T
): T[] => {
  const existingIndex = find(xs, identify, x);

  if (existingIndex > -1) {
    return remove(xs, x);
  }

  return xs.concat(x);
};

export const addOrReplaceEach = <T>(
  xs: T[],
  identify: (a: T) => string | number,
  ys: T[]
): T[] => {
  return ys.reduce((zs, z) => addOrReplace(zs, identify, z), xs);
};

export const flat = <T>(xs: T[][]) => {
  return xs.reduce((acc, val) => acc.concat(val), [] as T[]);
};

export const find = <T>(
  xs: T[],
  identify: (a: T) => string | number,
  x: T
): number => {
  const id = identify(x);

  return xs.findIndex(y => identify(y) === id);
};

export const findAndUpdate = <T>(
  xs: T[],
  findFn: (a: T) => boolean,
  updateFn: (a: T) => T
): T[] => {
  return update(xs, xs.findIndex(findFn), updateFn);
};

export const move = <T>(array: T[], oldIndex: number, newIndex: number) => {
  if (
    oldIndex < 0 ||
    oldIndex > array.length ||
    newIndex < 0 ||
    newIndex > array.length
  ) {
    return array;
  }

  const newArray = [...array];

  newArray.splice(newIndex, 0, newArray.splice(oldIndex, 1)[0]);

  return newArray;
};

export const remove = <T>(xs: T[], x: T): T[] => {
  const index = xs.indexOf(x);

  return removeAtIndex(xs, index);
};

export const removeAtIndex = <T>(xs: T[], index: number): T[] => {
  return xs.slice(0, index).concat(xs.slice(index + 1));
};

export const replace = <T>(xs: T[], findFn: (a: T) => boolean, y: T): T[] => {
  const index = xs.findIndex(findFn);

  if (index < 0) return xs;

  return set(xs, index, y);
};

export const replaceValue = <T>(xs: T[], oldValue: T, newValue: T): T[] => {
  const index = xs.indexOf(oldValue);

  if (index < 0) return xs;

  return set(xs, index, newValue);
};

export const set = <T>(xs: T[], index: number, x: T): T[] => {
  return xs.slice(0, index).concat(x, xs.slice(index + 1));
};

export const sift = <T>(items: T[], predicate: (a: T) => boolean): [T[], T[]] => {
  return items.reduce(
    ([pass, fail]: [T[], T[]], item) => {
      if (predicate(item)) return [pass.concat(item), fail];
      return [pass, fail.concat(item)];
    },
    [[], []]
  );
};

export const update = <T>(xs: T[], index: number, updateFn: (a: T) => T): T[] => {
  return set(xs, index, updateFn(xs[index]));
};

export const swap = <T>(arr: T[], index1: number, index2: number) => {
  return arr.map((val, index) => {
    if (index === index1) return arr[index2];

    if (index === index2) return arr[index1];

    return val;
  })
};

export const orderByArray = (values: any[], orderType: string, direction?: 'asc' | 'desc') => {
  const sortedArray = values.sort((a, b) => {
    if (a[orderType] < b[orderType]) {
      return -1;
    }

    if (a[orderType] > b[orderType]) {
      return 1;
    }

    return 0;
  });

  if (direction === 'desc') {
    sortedArray.reverse();
  }

  return sortedArray;
};

export const orderByArrayObjects = (data: any[], orderType: string, direction: string) => {

  const field = orderType.split('.');
  let len = field.length;
  let sortedArray: any[] = [];
  if (direction === 'desc') {
    sortedArray = data.slice().sort((a, b) => {
      let i = 0;
      while (i < len) { a = a[field[i]]; b = b[field[i]]; i++; }
      if (!a || a === '') return 1;
      if (!b || b === '') return -1;
      if (a > b) {
        return -1;
      } else if (a < b) {
        return 1;
      } else {
        return 0;
      }
    });
  }
  else {

    sortedArray = data.slice().sort((a, b) => {
      let i = 0;
      while (i < len) { a = a[field[i]]; b = b[field[i]]; i++; }
      if (!a || a === '') return 1;
      if (!b || b === '') return -1;
      if (a < b) {
        return -1;
      } else if (a > b) {
        return 1;
      } else {
        return 0;
      }
    });

  }

  return sortedArray;


}

export const arraysAreEqual = <T>(a: T[], b: T[]): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) {
      return false;
    }
  }

  return true;
};

export const findLastIndex = <T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number => {
  let l = array.length;

  while (l--) {
    if (predicate(array[l], l, array)) {
      return l;
    }
  }

  return -1;
};
