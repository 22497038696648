import { useAPI } from '../lib/API';
import { getCurrencies } from '../requests/currency';
import { useEffect, useState, useCallback } from 'react';
import Currency from '../data/Currency';


export const useCurrencies = () => {
    const [currencies, setCurrencies] = useState<Currency[]>([]);

    const currencyReq = useAPI({
        deferFn: getCurrencies,
        onResolve: (result: any) => {
            setCurrencies(result);
        },
        onReject: (err: any) => { },
    });

    useEffect(() => {
        updateCurrencies();
    }, []);

    const updateCurrencies = useCallback(() => {
        currencyReq.run()
    }, [currencyReq]);

    return { currencies, updateCurrencies }
}