import React from 'react';

import { Heading1, Heading4, DownloadLink, HorizontalRule, Card } from 'OnboardingComponents/Elements';

import SalesTeam from './sections/SalesTeam';
// import Faqs from './sections/Faqs';
import Website from './sections/Website';
import Newsletter from './sections/Newsletter';
// import SocialMedia from './sections/SocialMedia';
// import Events from './sections/Events';
import MultiBrand from './sections/MultiBrand';

const SellerGuidelinesPage = () => {
  return (
    <Card>
      <Heading1>Seller guidelines</Heading1>
      <Heading4>How to best utilize your brand channels to grow your sales on OFMP</Heading4>        
      <DownloadLink href="/resources/OFMP_ProductGuidelines_Nov2019.pdf" target="_blank">Download the seller guidelines PDF</DownloadLink>

      <HorizontalRule />

      <SalesTeam />
      <HorizontalRule />

      <Website />
      <HorizontalRule />

      <Newsletter />
      <HorizontalRule />

      <MultiBrand />
    </Card>
  );
};

export default SellerGuidelinesPage;
