export default interface Pricebook {
  id: number;
  priceBookId: string;
  priceBookName: string;
  validFrom: string;
  validTo: string;
  priceBookType: string;
  active: boolean;
}

export interface PricebookExtended
  extends Omit<Pricebook, 'validTo' | 'validFrom'> {
  customer?: {
    firstName: string;
    lastName: string;
  };
  company?: {
    companyName: string;
    companyId: string;
    id: number;
  };
  priceBookState: PricebookState;
  previousState: PricebookState;
  priceBookVendorId: number;
  pathToFile: string;
  createdOn: string;
  published: string;
  vendor: {
    name: string;
  };
  vendorId: number;
  customerId: number | null;
  versionId: number;
  validFrom: string | null;
  validTo: string | null;
  replacementPricebookId?: number;
  replacementPricebookState?: PricebookState;
  country?: {
    countryName: string;
    countryIso: string;
    id: number;
  };
  warehouse?: {
    name: string;
    warehouseId: string;
    code: string;
    id: number;
  };
}

export interface PricebookForHierarchy extends PricebookExtended {
  lowLevelHierarchy?: boolean,
  isTheFirstRowForVendor?: boolean;
}

export type PricebookResponse = Pricebook & {
  countryName?: string;
  countryIso?: string;
  pricebook?: Pricebook;
  code?: string;
  name?: string;
};

export enum PricebookState {
  DRAFT = 'DRAFT',
  UPLOADED = 'UPLOADED',
  VALIDATED = 'VALIDATED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  PROCESSING = 'PROCESSING',
  IMPORTED = 'IMPORTED',
  IMPORT_FAILED = 'IMPORT_FAILED',
  PUBLISHED = 'PUBLISHED',
  DELETE = 'DELETE',
  DELETED = 'DELETED',
  REPLACEMENT = 'REPLACEMENT',
}

export enum PricebookType {
  REFERENCE = 'REFERENCE',
  COUNTRY = 'COUNTRY',
  GEOGRAPHY = 'GEOGRAPHY',
  COMPANY = 'COMPANY',
}

export const PricebookStateNames = [
  'DRAFT',
  'UPLOADED',
  'VALIDATED',
  'VALIDATION_FAILED',
  'PROCESSING',
  'IMPORTED',
  'IMPORT_FAILED',
];

export const PricebookTemplateUrl = '/Favorites Template.xlsx';
