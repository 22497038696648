import React from 'react';
import styled from 'styled-components';
import * as Prod from 'data/Product';
import Card from 'view/Card';
// import Pill from 'component/UI/Pill';
import { Size } from 'lib/styles';
import placeholderImage from '../images/placeholder-image.jpg';
import { Background } from 'styles/themeColors';

const Categories = styled.div`
  display: inline-block;
  font-size: 0.85em;
  overflow-x: hidden;
  padding-right: ${Size.small};
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
  width: 50%;
`;

const Count = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1;
  padding-bottom: ${Size.small};
  width: 3rem;
`;

const ProductImage = styled.img<any>`
  cursor: pointer;
  height: 64px;
  width: 64px;
`;

const Info = styled.div`
  flex: 1;
  overflow-x: hidden;
  margin-left: ${Size.medium};
`;

// const IssuesContainer = styled.div`
//   align-items: center;
//   display: flex;
//   justify-content: flex-end;
//   min-width: 6rem;
// `;

const Name = styled.span`
  display: block;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  margin-top: ${Size.small};
  overflow-x: hidden;
  padding-bottom: ${Size.small};
  padding-right: ${Size.medium};
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const VariantLabel = styled.label`
  display: block;
  font-size: 0.85em;
`;

const Variants = styled.div`
  margin-right: ${Size.medium};
  margin-top: ${Size.small};
`;

const Version = styled.div`
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
`;

const PlaceHolderImage = styled.img`
  background-color: ${Background.white};
  height: 64px;
  margin-right: ${Size.medium};
  object-fit: contain;
  object-position: 50%;
  padding: ${Size.tiny};
  width: 64px;
`;

interface Props {
  errorCount?: number;
  onClick?: () => void;
  product: Prod.ProductCard;
  selectable?: boolean;
  selected?: boolean;
}

export default function ProductCard({ onClick, product, errorCount }: Props) {
  //const errorsPillText = errorCount > 0 ? `${errorCount} Issues` : '';

  let version = null;
  if (product.version > 1) {
    version = <Version>Version {product.version}</Version>;
  }

  const productImage = product.productImages && product.productImages.length > 0 ? <ProductImage src={process.env.REACT_APP_MEDIA_URL! + product.productImages[0].path} /> :
    <PlaceHolderImage alt={`${product.name} placeholder-logo`} src={placeholderImage} />;
  return (
    <Card onClick={onClick}>
      {productImage}

      <Info>
        {version}
        <Name>{product.name}</Name>
        <Categories>
          {product.category ? product.category.name : null}
        </Categories>
      </Info>
      <Variants>
        <Count>{product.variantsCount}</Count>
        <VariantLabel>
          {product.variantsCount === 1 ? 'Variant' : 'Variants'}
        </VariantLabel>
      </Variants>
      {/* <IssuesContainer>
        <Pill theme="danger">{errorsPillText}</Pill>
      </IssuesContainer> */}
    </Card>
  );
}

ProductCard.defaultProps = {
  selectable: false,
  selected: false
};
