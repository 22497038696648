import { Color, Size } from 'lib/styles';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Checkbox from 'components/UI/Checkbox';
import { Main } from 'styles/themeColors';
import CurrencyFormatter from 'common/CurrencyFormatter';
import { TEXT_LABELS } from 'constants/productDetails';
import { PRODUCT_VARIANT_FIELDS_CONFIG } from 'constants/variant';
import { getProductVariantFieldConfig } from 'utils/variant';


const Td = styled.td<any>`
  border: 1px solid ${({ borderColor }) => borderColor};
  color: ${({ color }) => color};
  padding: 0;
  position: relative;
  border-right-width: 0;
  white-space: nowrap;

  &:nth-last-child(2) {
    border-right-width: 1px;
  }


  ${props => props.error && css`
   background-color: ${Color.errorRedTransparent};
  border: 1px solid ${Main.error};
`}
`;

const TdContent = styled.div`
  align-items: vertical;
  display: flex;
`;

const TdContentCheckbox = styled.div`
  align-items: vertical;
  display: flex;
  justify-content: center;
  color: ${({ color }) => color};
`;

const SharedCellStyles = css`
  background-color: transparent;
  box-sizing: border-box;
  color: inherit;
  font-size: 14px;
  line-height: 1.5;
  min-width: 100%;
  padding: ${Size.tiny} ${Size.small};
`;


const SuggestionList = styled.ul`
  padding: 5px;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
  z-index: 150;
  position: absolute;
  top: 15px;
  background-color: white;
  border: 1px solid black;
`;

const ListItem = styled.li`
  display: block;
  transition-duration: 0.5s;
  padding: 5px;
  list-style-type: none;

  &:hover {
    cursor: pointer;
    background: lightgrey;
  }
`;

const Input = styled.input`
  ${SharedCellStyles}
  border: none;
`;

const UnfocusedCell = styled.div`
  ${SharedCellStyles}
`;
export interface Props {
  error: boolean;
  color?: string;
  onChange: (value: string | number | boolean) => void;
  value: string | number | boolean;
  columnIndex: number;
  suggestionValuesList: string[];
  clearSuggestions: () => void;
  originalId?: number;
  publishedSKUList: string[];
  disabled?: boolean;
  isUnitPriceColumn?: boolean;
  isRentalColumn?: boolean;
  currencyIso?: string;
  data?: {key : string | undefined};
  focusedMode:  boolean,
  theme?: string,
  textColor?: string,
  className?: string
};

function TableEditableCell({
  error,
  color,
  onChange,
  value: propValue,
  columnIndex,
  suggestionValuesList,
  clearSuggestions,
  originalId,
  publishedSKUList,
  disabled,
  isUnitPriceColumn = false,
  isRentalColumn = false,
  currencyIso = '',
  theme,
  textColor,
  className,
  data
}: Props) {

  const [value, setValue] = useState(propValue);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    setValue(propValue);
  }, [propValue]);

  const type = typeof value === 'number' || isRentalColumn ? 'number' : typeof value === 'boolean' ? 'checkbox' : 'text';

  function convertToNumber(value: string) {
    return type === 'number' ? parseFloat(value) : value;
  }

  function inputRef(element: HTMLInputElement) {
    if (element) {
      element.style.width = '0';
      element.style.minWidth = '0';
      element.style.width = `${element.scrollWidth}px`;
      element.style.minWidth = '100%';
    }
  }

  function onBlur(e: React.FormEvent<HTMLInputElement>) {
    setFocused(false);
    const parsedValue = convertToNumber(e.currentTarget.value);
    onChange(parsedValue);
    clearSuggestions();
  }

  function onChangeWrapper(e: React.FormEvent<HTMLInputElement>) {
    const parsedValue = convertToNumber(e.currentTarget.value);
    setValue(parsedValue);
    if (e.currentTarget.value !== propValue) {
      const parsedValue = convertToNumber(e.currentTarget.value);
      onChange(parsedValue);
    }
  }

  function onFocus(e: React.FocusEvent<HTMLInputElement>) {
    e.target.select();
  }

  function onSelection(value: string) {
    setValue(value)
  }

  let contents = (
    <TdContent>
      <UnfocusedCell
        onClick={() => setFocused(true)}
        onFocus={() => setFocused(true)}
        tabIndex={0}
      >
        {String(value).length === 0 ? '\u00A0' : value}
      </UnfocusedCell>
    </TdContent>
  );

  if (type === TEXT_LABELS.CHECK_BOX && typeof value === 'boolean') {
    contents = (
      <TdContentCheckbox>
        <Checkbox
          id="table-editable-cell-checkbox-id"
          checkboxSize="xs"
          appearance="none"
          checked={value}
          onChange={value => onChange(value)}
          disabled={disabled}
          theme={theme === TEXT_LABELS.BLACK ? 'lightGray' : theme === TEXT_LABELS.WARNING ? value ? 'brandLight' : 'lightGray' : 'accent' }
          bgColor={ theme === TEXT_LABELS.BLACK ? Color.darkerGray : theme === TEXT_LABELS.WARNING ? Main.brandLight  : Main.accent }
        />
      </TdContentCheckbox>
    );
  }

  if (focused && type !== 'checkbox') {
    contents = (
      <TdContent>
        <Input
          ref={inputRef}
          onChange={onChangeWrapper}
          onBlur={onBlur}
          onFocus={onFocus}
          type={type}
          value={value && typeof value !== 'boolean' ? value : type === 'number' ? 0 : ''}
          autoFocus
          width="100%"
          maxLength={getProductVariantFieldConfig(data?.key)?.maxLength}
          disabled={disabled}
          className={theme === TEXT_LABELS.BLACK ? 'focused-input-field' : '' }
        />
        {suggestionValuesList.length > 0 ?
          <SuggestionList>
            {suggestionValuesList!.map((el, i) => (<ListItem key={i} onMouseDown={() => onSelection(el)}>{el}</ListItem>))}
          </SuggestionList>
          : null}
      </TdContent>
    );
  }

  if (isUnitPriceColumn) {
    // Render currenct formatter component
    const perUnitPrice = value as number
    contents = <CurrencyFormatter
      value={`${perUnitPrice}`}
      onChange={onChange}
      disabled={disabled}
      currencyIso={currencyIso}
      error={error}
      className={theme ?  'currency-format' : ''}
      textAlign='left'
    />
  }


  return (
    <Td columnIndex={columnIndex} error={error} className={theme && error ? 'warning-td' : className ? className : theme ? 'primary-td' : ''} color={ textColor || null } borderColor={color}>
      {contents}
    </Td>
  );
};

export default React.memo(TableEditableCell);
