import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import ConsignmentEntry from 'data/ConsignmentEntry';
import { CANCELED, CANCELING, MANUFACTURING, PACKING, PROCESSING, SPLIT_CONSIGNMENT } from 'constants/consignmentStates';
import { FontWeight } from 'lib/styles';
import { Text } from 'styles/themeColors';
import { formatDateTime, currencySign } from 'utils/formatters';
import MatTable from 'components/MaterialTable/MatTable';
import productColumns from 'constants/productColumns';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { CONSIGNMENT_TYPES } from 'constants/order';
import OrderTag from 'components/UI/OrderTag';
import { getPillTheme } from 'utils/consignment';
import Pill from 'components/UI/Pill';
import CurrencyInput from 'components/UI/CurrencyInput';

const ProductImage = styled.img<any>`
  cursor: pointer;
  max-height: 96px;
  max-width: 96px;
  margin: 0 auto;
  align-self: baseline;
`;

const TableFooterColumn = styled.span<any>`
  width: 200px;
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const Label = styled.span<any>`
  width: 200px;
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const TableFooterData = styled.span<any>`
  width: 104px;
  height: 20px;
  text-align: right;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
  width: 172px;
  padding-right: 12px;
`;

const TableColumn = styled.span<any>`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-height: 18px;
  width: 234px;
  color: ${Text.secondary};
  margin-bottom: 4px;

  ${({ color }) => color && css`
  color: ${color};
`}

${({ textDecoration }) => textDecoration && css`
  text-decoration: ${textDecoration};
`}

${({ fontSize }) => fontSize && css`
  font-size: ${fontSize};
`}

${({ fontWeight }) => fontWeight && css`
  font-weight: ${fontWeight};
`}

  ${({ deleted }) => deleted && css`
  opacity: 0.5;
`}

${({ width }) => width && css`
  width: ${width};
`}
`;

const TableData = styled.td<any>`
  text-align: center;
  vertical-align: top;

  &:first-child:not(:only-child):not([rowspan]) {
    text-align: left;
    font-weight: ${FontWeight.bold};
  }

  &:first-child:not(:only-child)[rowspan] {
    text-align: left;
  }

  &:last-child:not(:only-child)[rowspan] {
    padding-right: 10px;
    text-align: right;
    margin-right: 0;
    font-weight: ${FontWeight.bold};
  }

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ textDecoration }) => textDecoration && css`
    text-decoration: ${textDecoration};
  `}

  ${({ fontSize }) => fontSize && css`
    font-size: ${fontSize};
  `}

  ${({ fontWeight }) => fontWeight && css`
    font-weight: ${fontWeight};
  `}

  ${({ deleted }) => deleted && css`
    opacity: 0.5;
  `}
`;

const Consignment = styled.span<any>`
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const mapLabelForEntry = (type: string) => {
    switch (type) {
        case PROCESSING:
        case PACKING:    
            return 'In stock';
        case MANUFACTURING:
            return 'Manufacturing';
        case CANCELED:
        case CANCELING:    
            return 'Canceled';
        default:
            return '';
    }
}

interface TableValue {
    product: JSX.Element,
    quantity: JSX.Element,
    unitPrice: JSX.Element,
    subTotal: JSX.Element
};

interface Props {
    showSummary?: boolean;
    isRental?: boolean;
    consignmentEntries: ConsignmentEntry[];
    setAdditionalShippingCostsValues: (value: any) => void;
    errorMessage?: string;
    consignmentId: string;
    state: string;
    deliveryDate?: string;
    deliveryCost: number;
    additionalShippingCostsValues: any[];
    isInvalid: boolean;
    totalShippingCost: number;
    splitConsignmentBody: any;
};


const onValueChange = (value: any, setAdditionalShippingCostsValues: any, additionalShippingCostsValues: any, state: any) => {
    const findConsignment = (shippingCostValues: any) => shippingCostValues.state === state;

    const additionalShippingCost = additionalShippingCostsValues.map((shippingCost:any) => {
        if (findConsignment(shippingCost)) {
            return {
                ...shippingCost,
                additionalShippingCost: !isNaN(value) ? value : 0
            };
        }
        return shippingCost;
    });

    setAdditionalShippingCostsValues(additionalShippingCost);
}

const SplitPostOrderShippingCostRent = ({
    totalShippingCost,
    consignmentId,
    consignmentEntries,
    errorMessage,
    state,
    deliveryDate,
    deliveryCost,
    additionalShippingCostsValues,
    setAdditionalShippingCostsValues,
    isInvalid,
    isRental,
    splitConsignmentBody
}: Props) => {

    const findConsignmentValue = additionalShippingCostsValues.find((cons: any) => cons.state === state);
    const additionalShippingCost = findConsignmentValue?.additionalShippingCost;
     
    const shippingCost = (
        <FlexboxContainerComponent flexDirection="column" alignItems='flex-end' width='100%'>
            <FlexboxContainerComponent padding='16px 0'>
                <Label>Shipping cost</Label>
                <TableFooterData>&nbsp;</TableFooterData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent padding='0 0 16px 0'>
                <TableFooterColumn>Included in products</TableFooterColumn>
                <TableFooterData> ${deliveryCost}</TableFooterData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent alignItems='center'>
                <TableFooterColumn>Added post-order</TableFooterColumn>
                <FlexboxContainerComponent margin="8px 0">
                    <CurrencyInput
                        selectAllOnFirstClick
                        id="added-post-order-id"
                        labelName=""
                        placeholder=""
                        value={typeof additionalShippingCost !== 'string' ? additionalShippingCost.toFixed(2) : additionalShippingCost}
                        onValueChange={(value: any) => onValueChange(value, setAdditionalShippingCostsValues, additionalShippingCostsValues, state)}
                        width="172px"
                        isInvalid={isInvalid}
                        errorMessage={errorMessage}
                        decimalsLimit={2}
                    ><label style={{ "position": "absolute", "right": '56px', 'padding': '5px', 'color': '#71777C' }}>/ {Number(totalShippingCost).toFixed(2)}</label></CurrencyInput>
                </FlexboxContainerComponent>
            </FlexboxContainerComponent>
        </FlexboxContainerComponent>
    );

    const queryParameters = new URLSearchParams(window.location.search);
    const country = queryParameters.get("country");

    const productColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {

        
        const entryDetailsBeforeEmulate = splitConsignmentBody?.entries?.find((entry: any) => entry.entryNumber === consignmentEntry.entryNumber); 
        const rentalStartDate = state === MANUFACTURING ? entryDetailsBeforeEmulate?.manufactureDate : entryDetailsBeforeEmulate?.rentalStartDate;
        const rentalEndDate = state === MANUFACTURING ? entryDetailsBeforeEmulate?.manufactureEndDate : entryDetailsBeforeEmulate?.rentalEndDate;

        return (
            <Fragment key={consignmentEntry.id}>
                <FlexboxContainerComponent flexDirection='row'>
                    <FlexboxContainerComponent padding='0 12px 0 0' flexDirection='column'>
                        <OrderTag type={isRental ? CONSIGNMENT_TYPES.RENT : CONSIGNMENT_TYPES.PURCHASE} margin={'0px 0px 10px 0px'} />
                        <ProductImage src={consignmentEntry.productImages && consignmentEntry.productImages.length > 0 ? process.env.REACT_APP_MEDIA_URL! + consignmentEntry.productImages[0].path : ''} />
                    </FlexboxContainerComponent>
                    <FlexboxContainerComponent flexDirection='column'>
                        <FlexboxContainerComponent justifyContent='flex-start' margin='0 0 12px' padding='0px 0 0'>
                            <TableData>{consignmentEntry.productName}</TableData>
                        </FlexboxContainerComponent>
                        <FlexboxContainerComponent flexDirection='row'>
                            <FlexboxContainerComponent flexDirection='column'>
                                <FlexboxContainerComponent>
                                    <TableColumn>SKU</TableColumn>
                                    {/* <TableColumn>{consignmentEntry.sku}</TableColumn> */}
                                    <TableColumn deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}> {consignmentEntry.productSKU ? consignmentEntry.productSKU : ''} </TableColumn>
                                </FlexboxContainerComponent>

                                {
                                    consignmentEntry.selectableAttributes && consignmentEntry.selectableAttributes.map(atr => {
                                        const atrValue = consignmentEntry.attributeValues.find(val => val.attributeId === atr.id);
                                        return (
                                            <FlexboxContainerComponent><TableColumn>{atr.name}</TableColumn><TableColumn>{atrValue ? atrValue.name : ''}</TableColumn></FlexboxContainerComponent>
                                        )
                                    })
                                }

                                {
                                    consignmentEntry.pricebookName && consignmentEntry.pricebookId &&
                                    <FlexboxContainerComponent>
                                        <TableColumn>Pricebook name / ID</TableColumn>
                                        <TableColumn>
                                            {`${consignmentEntry.pricebookName} / ${consignmentEntry?.priceBook?.PricebookVendorId}`}
                                        </TableColumn>
                                    </FlexboxContainerComponent>
                                }
                                <FlexboxContainerComponent>
                                    <TableColumn color={Text.primary}>{"Est. delivery date"}</TableColumn>
                                    <TableColumn color={Text.primary}>{deliveryDate ? formatDateTime(deliveryDate, false, false) : formatDateTime(rentalStartDate, false, false)}</TableColumn>
                                </FlexboxContainerComponent>

                           
                                    <FlexboxContainerComponent>
                                        <TableColumn color={Text.primary}>Date range</TableColumn>
                                        <TableColumn color={Text.primary}>{formatDateTime(rentalStartDate, false, false) + " - " + formatDateTime(rentalEndDate, false, false)}</TableColumn>
                                    </FlexboxContainerComponent>
                                

                            </FlexboxContainerComponent>

                        </FlexboxContainerComponent>
                    </FlexboxContainerComponent>
                </FlexboxContainerComponent>
            </Fragment>
        )
    });

    const quantityColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {
        return (
            <Fragment key={consignmentEntry.id}>
                <FlexboxContainerComponent justifyContent='flex-end' >
                    <TableData>{consignmentEntry?.quantity}</TableData>
                </FlexboxContainerComponent>
            </Fragment>
        )
    });

    const unitPriceColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {

        const unitPrice = consignmentEntry.basePrice !== consignmentEntry.discountedPrice ? consignmentEntry.discountedPrice : consignmentEntry.basePrice;

        return (
            <Fragment key={consignmentEntry.id}>
                <FlexboxContainerComponent justifyContent='flex-end'>
                    {
                        <TableData>{currencySign(country, unitPrice)}</TableData>
                    }
                </FlexboxContainerComponent>
            </Fragment>
        )
    });

    const subTotalColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {
        return (
            <Fragment key={consignmentEntry.id}>
                <FlexboxContainerComponent justifyContent='flex-end'>
                    <TableData>{currencySign(country, consignmentEntry.totalPrice)}</TableData>
                </FlexboxContainerComponent>
                <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
                    {
                        consignmentEntry.surcharge > 0
                            ? <TableData rowSpan={2}>+{currencySign(country, consignmentEntry.surcharge)} <br /> surcharge</TableData>
                            : <TableData>&nbsp;</TableData>
                    }
                </FlexboxContainerComponent>
            </Fragment>
        )
    });

    const data: TableValue[] = [];

    consignmentEntries.forEach((c, index) => {
        data.push({
            product: productColumn[index],
            quantity: quantityColumn[index],
            unitPrice: unitPriceColumn[index],
            subTotal: subTotalColumn[index]
        })
    });


    const pillTheme = getPillTheme(state!);

    const pill = <Pill margin="10px 10px 10px 0px" theme={pillTheme} bgColor={pillTheme} size="xsPlus" padding="4px">{mapLabelForEntry(state)}</Pill>;


    const entryDetailsBeforeEmulate = splitConsignmentBody?.entries?.find((entry: any) => entry?.entryNumber === consignmentEntries[0]?.entryNumber); 
    const rentalStartDate = state === MANUFACTURING ? entryDetailsBeforeEmulate?.manufactureDate : entryDetailsBeforeEmulate?.rentalStartDate;
    const rentalEndDate = state === MANUFACTURING ? entryDetailsBeforeEmulate?.manufactureEndDate : entryDetailsBeforeEmulate?.rentalEndDate;


    return (
        <>
            <FlexboxContainerComponent flexDirection='column' gap='10px' margin='0px'>
                <FlexboxContainerComponent flexDirection='row' gap='10px' margin='0px' alignItems='center'>
                    <Consignment>Order # {consignmentId}</Consignment>
                    {pill}
                    {state !== CANCELED && <FlexboxContainerComponent alignItems='center'>
                        <TableColumn color={Text.primary} width={'134px'}>{"Est. delivery date"}</TableColumn>
                        <TableColumn color={Text.primary} width={'134px'}>{formatDateTime(deliveryDate ? deliveryDate : rentalStartDate, false, false)}</TableColumn>
                    </FlexboxContainerComponent>
                    }
                </FlexboxContainerComponent>
                <FlexboxContainerComponent alignItems='center'>
                    <TableColumn color={Text.primary} width={'134px'}>Start rent date</TableColumn>
                    <TableColumn color={Text.primary} width={'134px'}>{formatDateTime(rentalStartDate, false, false)}</TableColumn>
                </FlexboxContainerComponent>
                <FlexboxContainerComponent alignItems='center'>
                    <TableColumn color={Text.primary} width={'134px'}>End rent date</TableColumn>
                    <TableColumn color={Text.primary} width={'134px'}>{formatDateTime(rentalEndDate, false, false)}</TableColumn>
                </FlexboxContainerComponent>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent padding='0 0' justifyContent="space-between" alignItems="center">
                <p>{'Order items'}</p>
            </FlexboxContainerComponent>
            <MatTable
                columns={productColumns}
                data={data}
                toolbar
                overrideToolbar
                borders={true}
                rowStyle={{ backgroundColor: "#fff" }}
                customHeaderBorders={true}
            />
            {shippingCost}

        </>
    );
};

SplitPostOrderShippingCostRent.defaultProps = {
    showSummary: true
}

export default React.memo(SplitPostOrderShippingCostRent);
