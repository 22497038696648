export interface Response {
  isLoading: boolean;
  loadingFinished: boolean;
  isError: boolean;
  error: any;
};

export const response: Response = {
  isLoading: false,
  loadingFinished: false,
  isError: false,
  error: null
};
