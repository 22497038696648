export const PRODUCT_CONSTANTS = {
    ALL_COUNTRIES: 'All countries',
    MOST_RECENT: 'Most recent',
    VENDOR: 'Vendor',
    PRODUCTS: 'products',
    REQUEST_SORT: 'requests-sort',
    REQUEST_SORT_BY_COUNTRY: 'requests-sort-country',
    PUBLISHED_PRODUCT_PATH: '/products/published',
    REQUEST_SORT_COUNTRY_ISO: 'requests-sort-country-iso',
    PRODUCT_IMAGE_VALIDATION_MESSAGE: 'Invalid file type. Please upload an image (JPEG, PNG).',
    PRODUCT_DOCS_VALIDATION_MESSAGE: 'Invalid file type. Please upload a file (PDF).',
    ERROR_FIELD : 'error-field'
}

export const PRODUCT_VALIDATION_STATUSES = [{id: 1, name: 'PENDING'}, {id: 2, name:'VALID'}, {id: 3, name:'INVALID'}];

export const MAX_DESCRIPTION_LENGTH = 5000;
export const WAREHOUSE_ERROR = 1;

export const MAX_PRODUCT_NAME_LENGTH = 100;

export const PRODUCT_STATUS = {
    PUBLISHED: 'published',
    DRAFT: 'draft',
    READY: 'ready',
}

export const PRICE_TYPE = {
    PURCHASE: 'PURCHASE',
    RENT: 'RENT',
}

export const TAB_NUMBERS = {
    DETAILS: 0,
    WAREHOUSES: 1,
    VARIANTS: 2
}

export const VALID_IMAGE_TYPES = ['image/jpeg', 'image/png']

export const VALID_DOCS_TYPES = [
    'application/pdf',
    'application/x-pdf',
    'application/x-bzpdf',
    'application/x-gzpdf'
];

export const VALIDATION_STATUS = {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    PARTIAL: 'PARTIAL'
}

export const PRODUCT_COLUMNS_STORAGE_KEY = 'productColumnsV2';