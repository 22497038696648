import * as React from 'react';
import styled from 'styled-components';
import { Background, Border } from 'styles/themeColors';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { downloadDocument } from 'requests/documents';
import { useAPI } from 'lib/API';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import { companyDocumentOptions } from 'constants/documentOptions';
import Icon from 'view/Icon';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { MoreVert } from '@material-ui/icons';

const Card = styled.div<any>`
  align-items: center;
  background: ${Background.white};
  border: 1px solid ${Border.light};
  border-radius: 6px;
  display: flex;
  padding: 6px;
  box-shadow: 2px 3px 3px rgba(39, 44, 57, 0.15);
  cursor: pointer;
  width: 100%;
  margin-bottom: 6px;
`;

const StyledFlexboxContaier = styled(FlexboxContainer) <any>`
  .MuiSvgIcon-root {
    font-size: 36px;
  }
`;

const Label = styled.span`
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
`;

interface TicketDocumentProps {
    ticketDocument: any;
    margin?: string;
}

const TicketDocument: React.FC<TicketDocumentProps> = ({ ticketDocument, margin = '0px' }) => {

    const [open, setOpen] = React.useState(false);
    const [downloadedDocument, setDownloadedDocument] = React.useState<any>();
    const [selectedOption, setSelectedOption] = React.useState('');

    const download = (doc: any) => {
        const a = document.createElement('a');

        a.style.display = 'none';
        document.body.appendChild(a);

        const url = window.URL.createObjectURL(doc);

        a.href = url;
        a.download = ticketDocument.name;
        a.click();

        window.URL.revokeObjectURL(url);
    };

    const showInOtherTab = (doc: any) => {
        const url = window.URL.createObjectURL(doc);
        window.open(url, '_blank');
        window.URL.revokeObjectURL(url);
    };


    React.useEffect(() => {
        if (downloadedDocument && downloadedDocument.document) {
            if (selectedOption === 'Preview') {
                if (canBePreviewed(downloadedDocument.extension)) {
                    showInOtherTab(downloadedDocument.document);
                } else {
                    download(downloadedDocument.document);
                }
            } else if (selectedOption === 'Download') {
                download(downloadedDocument.document);
            }
        }
        setSelectedOption('');
    }, [selectedOption, downloadedDocument]);

    const canBePreviewed: (ext: string) => boolean = (ext: string) => {
        return ['.jpg', '.png', '.pdf', '.jpeg', '.tiff'].includes(ext);
    };

    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: any) => {
        if (anchorRef.current && (anchorRef.current as any).contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const downloadDocumentReq = useAPI({
        deferFn: downloadDocument,
        onResolve: result => {
            setDownloadedDocument(result);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    React.useEffect(() => {
        if (ticketDocument) {
            downloadDocumentReq.run(ticketDocument.id, ticketDocument.url)
        }
    }, [])


    const onSelect = (name: string) => {
        setSelectedOption(name);
    }

    const prevOpen = React.useRef(open);

    React.useEffect(() => {
        if (prevOpen.current && !open) {
            if (anchorRef && anchorRef.current) {
                (anchorRef.current as HTMLElement).focus();
            }
        }

        prevOpen.current = open;
    }, [open]);


    function handleListKeyDown(event: any) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }


    return (
    <>
        <Card ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            margin={margin}
        >
            <StyledFlexboxContaier justifyContent='flex-start' >
                <Icon name='Draft' size={34}/>
            </StyledFlexboxContaier>
            <FlexboxContainer flexDirection='column' width="100">
                <Label>{ticketDocument.name}</Label>
            </FlexboxContainer>
            {
                downloadedDocument && downloadedDocument.document &&
                <FlexboxContainer margin='0 0 0 auto'>
                    <MoreVert className='material-symbols-rounded' tabIndex={0} style={{ fontSize: 28, margin: 8, color: '#53595E' }} />
                </FlexboxContainer>
            }
        </Card>

        <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" transition disablePortal style={{zIndex: 100}}>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        margin: 6
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {
                                    (companyDocumentOptions).map(option => (
                                        <MenuItem onClick={(event) => { onSelect(option.title); handleClose(event) }}>
                                            {option.title}
                                        </MenuItem>
                                    ))
                                }
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper></>);
}

export default TicketDocument;