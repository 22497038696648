import React from 'react';
import { navigate } from 'hookrouter';
import { formatDateTime, formatPhone } from 'utils/formatters';
import styled from 'styled-components';
import Button from 'components/UI/Button';
import { filterDowJonesRelations } from 'utils/dowJones';
import DowJonesResult from 'data/DowJonesResult';
import { WarningRounded, Block } from '@material-ui/icons';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Height } from 'styles/themeHeights';
import {  Main, Text } from 'styles/themeColors';
import { unique } from 'lib/filters';
import { Space } from 'styles/themeSpaces';
import { BorderRadius } from 'styles/themeBorderRadius';
import { ACH } from 'constants/paymentTypes';
import { customSort } from 'utils/grid';
import LineStepper from 'components/UI/LineStepper';
import FeatureFlag from 'data/FeatureFlag';
import { SHIPPING } from 'constants/deliveryModes';
import Order from 'data/Order';
import { CustomerVM } from 'data/Customer';
import {
  DOW_JONES_BLOCKED,
  DOW_JONES_REVIEWED_CLEAR,
  DOW_JONES_CLEAR,
  DOW_JONES_REVIEWED_BLOCKED,
} from 'constants/dowJonesStatuses';
import {
  CHECKING_CREDIT,
  ORDER_SENT_TO_OEM,
  READY,
  CANCELING,
  CANCELED,
  ON_HOLD,
  PROCESSING,
  MANUFACTURING,
  SPLIT_CONSIGNMENT,
  PACKING,
  SHIPPED_READY_FOR_PICKUP,
  READY_TO_SHIP,
  READY_FOR_PICKUP,
  DELIVERED_PICKED_UP,
  DELAYED,
  AWAITS_SHIPPING,
  AWAITS_SHIPPING_APPROVAL,
  DOW_JONES_FAILED,
  SHIPPED,
  PICKED_UP,
  RENT_IN_PROGRESS,
  RENT_RETURN_PENDING,
  RENT_COMPLETED,
  MODIFIED_BY_CUSTOMER,
  MODIFIED_BY_VENDOR,
  RENT_UPDATED,
  TICKET_IN_PROGRESS,
} from 'constants/consignmentStates';
import Vendor from 'data/Vendor';

const Label = styled.label`
  color: red;
`;

const ErrorText = styled.span`
  color: ${Main.error} !important;
`;

const SecondaryText = styled.span`
  color: ${Text.secondary} !important;
`;

const ClickableContainer = styled(FlexboxContainer)`
  cursor: pointer;
`;

export const mapCompanyGridColumns = (columns: any[], sortBy: any) => {
  return columns.map((column: any) => {
    if (column.field === 'vendor.vendorCompanyId') {
      column.render = (params: any) => {
        const value =
          params.vendor && params.vendor.vendorCompanyId ? (
            params.vendor.vendorCompanyId
          ) : (
            <Button isGhost color={Main.accent}>
              Assign
            </Button>
          );

        return value;
      };
    }

    if (column.field === 'customerSince') {
      column.render = (params: any) => formatDateTime(params.customerSince);
    }

    if (sortBy?.field === column.field) {
      column.defaultSort = sortBy?.defaultSort;
    } else {
      column.defaultSort = undefined;
    }

    column.customSort = customSort(sortBy);

    return column;
  });
};

export const mapCustomerGridColumns = (
  columns: any[],
  featureFlags: any,
  sortBy: any,
  isVendor: boolean,
  currentPage: number
) => {
  return columns
    .filter((column: any) =>
      filterDowJonesRelations(featureFlags, column.title)
    )
    .map((column: any) => {
      if (column.field === 'fullName') {
        column.render = (params: any) => (
          <span style={{ fontWeight: 'bolder', textTransform: 'capitalize' }}>
            {params.fullName}
          </span>
        );
      }

      if (column.field === 'phone') {
        column.render = (params: any) => formatPhone(params.phone);
      }

      if (column.field === 'createdDate') {
        column.render = (params: any) => formatDateTime(params.createdDate);
      }

      if (column.field === 'dowJonesStatus') {
        column.render = (params: any) =>
          getStatus(
            params.dowJonesStatus,
            params.dowJonesTimestamp,
            params.id,
            isVendor,
            currentPage
          );
      }

      if (sortBy?.field === column.field) {
        column.defaultSort = sortBy?.defaultSort;
      } else {
        column.defaultSort = undefined;
      }

      column.customSort = customSort(sortBy);

      return column;
    });
};

export const mapCustomerData = (customers: CustomerVM[]) => {
  return customers.map((customer) => {
    let companyName = '';
    let displayName = '';
    let companyNameList = customer.companies.map(
      (company) => company.companyName
    );
    let displayNameList = customer.companies.map(
      (company) => company.displayName
    );
    companyNameList.filter(unique);
    displayNameList.filter(unique);

    if (companyNameList.length > 0) {
      displayName = companyNameList.slice(0, 2).join(' | ');
    }

    if (displayNameList.length > 0) {
      companyName = displayNameList.slice(0, 2).join(' | ');
    }

    if (companyNameList.length > 2) {
      displayName += ` | +${companyNameList.length - 2} more...`;
    }

    if (displayNameList.length > 2) {
      companyName += ` | +${displayNameList.length - 2} more...`;
    }

    const dowJonesFailedResult = customer.dowJones.find(
      (dowJones: DowJonesResult) => dowJones.status === DOW_JONES_BLOCKED
    );
    const dowJonesStatus = dowJonesFailedResult
      ? dowJonesFailedResult.status
      : customer.dowJones.length > 0
      ? customer.dowJones[0].status
      : '';
    const dowJonesTimestamp = dowJonesFailedResult
      ? dowJonesFailedResult.dowJonesTimeStamp
      : '';

    return {
      ...customer,
      companyName,
      displayName,
      dowJonesStatus,
      dowJonesTimestamp,
    };
  });
};

export const mapOrderGridColumns = (
  columns: any[],
  isVendorUser: boolean,
  featureFlags: any,
  sortBy: any,
  selectedVendor?: Vendor
) => {
  return columns.map((column: any, index: number) => {
    if (column.field === 'orderId') {
      column.render = (params: any) => (
        <div style={{ minWidth: '112px' }}>{params.orderId}</div>
      );
    }

    if (column.field === 'created') {
      column.render = (params: any) => formatDateTime(params.created);
    }

    if (column.field === 'paymentType') {
      column.render = (params: any) => {
        return params.paymentType === ACH
          ? ACH
          : params.paymentType
          ? params.paymentType.charAt(0).toUpperCase() +
            params.paymentType.slice(1).toLowerCase().replace(/_/, ' ')
          : '';
      };
    }

    if (sortBy?.field === column.field) {
      column.defaultSort = sortBy?.defaultSort;
    } else {
      column.defaultSort = undefined;
    }

    if (column.field === 'status') {
      column.render = (params: any) => {
        const dowJonesStatus = params.dowJones ? params.dowJones.status : '';
        const deliveryTypes = params.consignments
          .map(
            (consignment: any) => consignment.consignmentEntries[0].deliveryMode
          )
          .filter(unique);
        const { displayState, currentStep, stepsColor } = getSteps(
          params.state,
          deliveryTypes,
          isVendorUser,
          dowJonesStatus,
          featureFlags,
          selectedVendor
        );

        return (
          <>
            <div>{displayState}</div>
            <LineStepper
              numberOfSteps={5}
              currentStep={currentStep}
              stepperWidth={90}
              stepsColor={stepsColor}
            />
          </>
        );
      };
    }

    column.customSort = customSort(sortBy);

    return column;
  });
};

export const mapOrderData = (
  orders: Order[],
  isVendorUser: boolean,
  featureFlags: any,
  selectedVendor?: Vendor
) => {
  return orders.map((order) => {
    const updatedByList = order.consignments.every(
      (consignment) => consignment.updatedBy === order.consignments[0].updatedBy
    );

    const statesAreEqual = order.consignments.every(
      (consignment) => consignment.state === order.consignments[0].state
    );
    const warehousesAreEqual = order.consignments.every(
      (consignment) =>
        consignment.warehouse.name === order.consignments[0].warehouse.name
    );
    const deliveryTypesAreEqual = order.consignments.every(
      (consignment) =>
        consignment.consignmentEntries[0].deliveryMode ===
        order.consignments[0].consignmentEntries[0].deliveryMode
    );
    const vendorsAreEqual = order.consignments.every(
      (consignment) => consignment.vendorId === order.consignments[0].vendorId
    );

    order.state = statesAreEqual
      ? order.consignments[0].state
      : SPLIT_CONSIGNMENT;
    order.warehouse = warehousesAreEqual
      ? order.consignments[0].warehouse.name
      : 'Multiple';
    order.vendor = vendorsAreEqual
      ? order.consignments[0].vendor
        ? order.consignments[0].vendor.name
        : ''
      : 'Multiple';
    order.deliveryType = deliveryTypesAreEqual
      ? order.consignments[0].consignmentEntries[0].deliveryMode === SHIPPING
        ? 'Delivery'
        : 'Pickup'
      : 'Pickup / Delivery';

    order.updatedBy = updatedByList
      ? order.consignments[0].updatedBy
        ? order.consignments[0].updatedBy
        : ''
      : 'Multiple';
    order.customer.fullName =
      order.customer.firstName + ' ' + order.customer.lastName;

    const dowJonesStatus = order.dowJones ? order.dowJones.status : '';
    const deliveryTypes = order.consignments
      .map((consignment: any) => consignment.consignmentEntries[0].deliveryMode)
      .filter(unique);

    const { displayState } = getSteps(
      order.state,
      deliveryTypes,
      isVendorUser,
      dowJonesStatus,
      featureFlags,
      selectedVendor
    );
    order.status = displayState;

    return order;
  });
};

const getStatus = (
  dowJonesStatus: any,
  dowJonesFailedDate: string,
  customerId: number,
  isVendor: boolean,
  currentPage: number
) => {
  let displayStatus = dowJonesStatus;

  switch (dowJonesStatus) {
    case DOW_JONES_REVIEWED_CLEAR:
      displayStatus = (
        <span className="base">
          {isVendor ? 'Compliant' : 'Cleared manually'}
        </span>
      );
      break;
    case DOW_JONES_CLEAR:
      displayStatus = (
        <span className="base">{isVendor ? 'Compliant' : 'Clear'}</span>
      );
      break;
    case DOW_JONES_BLOCKED:
      displayStatus = isVendor ? (
        <SecondaryText className="base-italic">Under Review</SecondaryText>
      ) : (
        <ClickableContainer
          justifyContent="flex-start"
          bgColor={Main.warning}
          borderRadius={BorderRadius.m}
          padding={Space.spHalf}
          minWidth="224px"
          onClick={() =>
            navigate(`/customers/customer-details/${customerId}`, false, {
              customerPage: currentPage,
              activeTab: 1,
            })
          }
        >
          <WarningRounded
            style={{
              height: Height.xsPlus,
              width: '20px',
              marginRight: Space.spHalf,
            }}
          />
          <FlexboxContainer flexDirection="column" alignItems="flex-start">
            <span className="base-strong">Requires review</span>
            <span className="base">
              Failed at {formatDateTime(dowJonesFailedDate, true)}
            </span>
          </FlexboxContainer>
        </ClickableContainer>
      );
      break;
    case DOW_JONES_REVIEWED_BLOCKED:
      displayStatus = (
        <FlexboxContainer
          justifyContent="flex-start"
          alignItems="flex-start"
          padding="4px"
        >
          <Block
            style={{ color: Main.error, height: Height.xsPlus, width: '16px' }}
          />
          <ErrorText className="base">Blocked manually</ErrorText>
        </FlexboxContainer>
      );
      break;
    default:
      displayStatus = '';
  }

  return displayStatus;
};

export const getSteps = (
  consignmentState: string,
  deliveryTypes: string[],
  isVendor: boolean,
  dowJonesStatus: string,
  featureFlags: FeatureFlag,
  selectedVendor?: Vendor,
  isInnerItem: boolean = false
) => {
  let displayState = consignmentState;
  let currentStep = 0;
  let stepsColor = Main.success;
  const isDowJonesBlocked =
    (dowJonesStatus === DOW_JONES_BLOCKED ||
      consignmentState === DOW_JONES_FAILED) &&
    featureFlags.dowJones &&
    Boolean(featureFlags.dowJones.enabled);
  if (isDowJonesBlocked) {
    if (isVendor || selectedVendor) {
      displayState = 'Compliance Check';
      currentStep = 2;
    } else if(isInnerItem) {
      displayState = 'Compliance Fail';
      currentStep = 2;
      stepsColor = Main.error;
    } else {
      displayState = 'Dow Jones Failed';
      currentStep = 2;
      stepsColor = Main.error;
    }

    return { displayState, currentStep, stepsColor };
  }

  switch (consignmentState) {
    case CHECKING_CREDIT:
      displayState = 'Checking Credit';
      currentStep = 2;
      break;
    case READY:
    case ORDER_SENT_TO_OEM:
      if (isVendor) {
        displayState = isDowJonesBlocked ? 'Compliance Check' : 'New';
        currentStep =  1;
      } else {
        displayState = isDowJonesBlocked ? 'Compliance Fail' : 'New';
        currentStep = 1;
        stepsColor = isDowJonesBlocked ? Main.error : Main.success;
      }
      break;
    case DOW_JONES_FAILED:
      if (isVendor) {
        displayState = 'Compliance Check';
        currentStep = 2;
      } else {
        displayState = 'Compliance Fail';
        currentStep = 2;
        stepsColor = Main.error;
      }
      break;
    case AWAITS_SHIPPING:
      displayState = 'Shipping cost required';
      currentStep = 1;
      stepsColor = Main.warning;
      break;
    case AWAITS_SHIPPING_APPROVAL:
      displayState = 'Shipping cost approval';
      currentStep = 1;
      stepsColor = Main.warning;
      break;
    case CANCELED:
      displayState = 'Canceled';
      currentStep = 5;
      stepsColor = Main.error;
      break;
    case CANCELING:
      displayState = isVendor ? 'Canceled' : 'Canceling';
      currentStep = 5;
      stepsColor = Main.error;
      break;
    case ON_HOLD:
      displayState = 'On Hold';
      currentStep = 1;
      stepsColor = Main.error;
      break;
    case PROCESSING:
      displayState = 'Processing';
      currentStep = 2;
      break;
    case SPLIT_CONSIGNMENT:
      displayState = 'Split Order';
      currentStep = 2;
      break;
    case MANUFACTURING:
      displayState = 'Manufacturing';
      currentStep = 2;
      break;
    case PACKING:
      displayState = 'Preparing';
      currentStep = 2;
      break;
    case SHIPPED_READY_FOR_PICKUP:
      displayState =
        deliveryTypes.length > 1
          ? 'Shipped / Ready for Pickup'
          : deliveryTypes[0] === SHIPPING
          ? 'Ready to ship'
          : 'Ready for Pickup';
      currentStep = 3;
      break;
    case READY_TO_SHIP:
      displayState = 'Ready to ship';
      currentStep = 3;
      break;
    case READY_FOR_PICKUP:
      displayState = 'Ready for Pickup';
      currentStep = 3;
      break;
    case DELIVERED_PICKED_UP:
      displayState =
        deliveryTypes.length > 1
          ? 'Delivered / Picked up'
          : deliveryTypes[0] === SHIPPING
          ? 'Shipped'
          : 'Picked up';
      currentStep = 3;
      break;
    case SHIPPED:
      displayState = 'Shipped';
      currentStep = 3;
      break;
    case PICKED_UP:
      displayState = 'Picked up';
      currentStep = 3;
      break;
    case RENT_IN_PROGRESS:
      displayState = 'Rental in progress';
      currentStep = 3;
      break;
    case TICKET_IN_PROGRESS:
      displayState = 'Ticket in progress';
      currentStep = 3;
      stepsColor = Main.error;
      break;  
    case RENT_RETURN_PENDING:
      displayState = 'Rental return pending';
      currentStep = 4;
      break;
    case RENT_COMPLETED:
      displayState = 'Rental completed';
      currentStep = 5;
      break;
    case DELAYED:
      displayState = 'Delayed';
      currentStep = 3;
      stepsColor = Main.warning;
      break;
    case MODIFIED_BY_CUSTOMER:
      displayState = 'Rent modified by customer';
      currentStep = 3;
      stepsColor = Main.warning;
      break;
    case MODIFIED_BY_VENDOR:
      displayState = 'Rent modified by vendor';
      currentStep = 3;
      stepsColor = Main.warning;
      break;
    case RENT_UPDATED:
      displayState = 'Rent Updated';
      currentStep = 3;
      stepsColor = Main.warning;
      break;
    default:
      currentStep = 1;
      stepsColor = Main.success;
  }

  return { displayState, currentStep, stepsColor };
};

export const mapCompanyDocumentsGridColumns = (columns: any[], sortBy: any) => {
  return columns.map((column: any) => {
    if (column.field === 'creationTime') {
      column.render = (params: any) => formatDateTime(params.creationTime);
    }

    if (column.field === 'category.name') {
      column.render = (params: any) => {
        const value =
          params.category && params.category.name ? params.category.name : '';

        return value;
      };
    }

    if (column.field === 'expirationDate') {
      column.render = (params: any) => {
        const currentDate = new Date();
        const value =
          new Date(params.expirationDate).setUTCHours(0, 0, 0, 0) >
          new Date(currentDate).setUTCHours(0, 0, 0, 0) ? (
            formatDateTime(params.expirationDate)
          ) : (
            <Label>Expired</Label>
          );
        return value;
      };
    }

    if (sortBy?.field === column.field) {
      column.defaultSort = sortBy?.defaultSort;
    } else {
      column.defaultSort = undefined;
    }

    column.customSort = customSort(sortBy);

    return column;
  });
};
