import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import {
  ListItemText,
  ListItemIcon,
  ListItem,
  List,
  Menu,
  IconButton,
  Checkbox,
  ListSubheader
} from '@material-ui/core';
import Icon from 'view/Icon'
import Button from '../UI/Button';
import { Border, Main } from 'styles/themeColors';
import { Settings as SettingsIcon } from '@material-ui/icons';

const StyledList = styled(List)<any>`
  width: ${({ $width }) => $width};
  max-width: 360;
  padding: 12px!important;
`;

const StyledListV2 = styled(List)<any>`
  width: 400px;
  padding: 0px !important;
  padding-bottom: 8px !important;
  max-height: auto !important;
`;


const StyledListHeader = styled(ListSubheader)`
  font-weight: bold !important;
  position: static !important;
  text-align: center;
`;

const ListItemHeader = styled.div<any>`
  font-size: 17px !important;
  padding: ${({ $index }) => $index > 0 ? '7px 0px 0px 14px': '3px 14px'};  
  border-top: ${({ $index }) => $index > 0 ? `1px solid ${Border.light}`: '0px'};
  margin-top: ${({ $index }) => $index > 0 ? '5px': '0px'};
`;

interface Props {
  columns: any[];
  onUpdateColumns: (columns: any[]) => void;
  initialColumns: any[];
  showButton?: boolean;
  showIcon?: boolean;
  variant?: 'VARIANT' | null;
};

const MatTableColumnsButton = ({
  columns,
  onUpdateColumns,
  initialColumns,
  showButton = false,
  showIcon = false,
  variant
}: Props) => {
  const [myColumns, setMyColumns] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChecked = (title: string) => {
    const updatedColumns = myColumns.map(myColumn => {
      if (myColumn?.title === title) {
        return { ...myColumn, hidden: !myColumn.hidden };
      }

      return { ...myColumn };
    });

    setMyColumns(updatedColumns);
    onUpdateColumns(updatedColumns);
  };

  useEffect(() => {
    if(variant === 'VARIANT'){
      setMyColumns(columns);
    }else{
      const mapColumnsByInitialOrder = initialColumns.map((initialColumn: any) => columns.find(column => column.field === initialColumn.field));
    
      setMyColumns(mapColumnsByInitialOrder);
    }
  }, [columns, initialColumns, variant])

  const getListComponent = () => {
    switch(variant){
      case 'VARIANT': 
        return <StyledListV2>
        {
          myColumns.map((myColumn, index) => {
            if(!myColumn || myColumn.isRemoved) return null;
            if(myColumn.isHeading){
              return <ListItemHeader $index={index}>{myColumn.title}</ListItemHeader>
            }else{
              return (
                <ListItem 
                  key={myColumn.title} 
                  role={undefined} 
                  dense 
                  button 
                  onClick={() => onChecked(myColumn.title)}
                  style={{padding: '5px', paddingLeft: '14px', paddingRight: '14px'}}
                  disabled={myColumn.removable === false || myColumn.isHeading}>
                    <ListItemText primary={myColumn.title} />
                    {
                      myColumn.isHeading === true ? null :
                      <Checkbox
                        edge="start"
                        checked={!(myColumn.hidden)}
                        tabIndex={-1}
                        disableRipple
                        style={{padding: 0}}
                      />
                    }
              </ListItem>
              )
            }
          })
        }
      </StyledListV2>
      
      default:
        return <StyledList>
        <StyledListHeader>VIEW COLUMNS</StyledListHeader>
        {
          myColumns.map(myColumn => {
            if(!myColumn) return null;
            return (
              <ListItem 
                key={myColumn.title} 
                role={undefined} 
                dense 
                button 
                onClick={() => onChecked(myColumn.title)}
                disabled={myColumn.removable === false}>
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={!(myColumn.hidden)}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText primary={myColumn.title}/>
            </ListItem>
            )
          })
        }
      </StyledList>
    }
  }

  return (
    <>
      {!showButton && <IconButton size="medium" onClick={handleOpen}>
        <Icon name='Columns' />
      </IconButton>}
      {showButton && <Button onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }} margin="25px 10px 0 0" bgColor="#FC780B" borderColor={Main.brandDark}>
              {
                showIcon && <SettingsIcon style={{ marginRight: '5px' }} />
              }
              Column settings
            </Button>}
      <Menu
        id="material-table-columns-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        className='column-settings-container'
      >
        {getListComponent()}
      </Menu>
    </>
  );
};

export default React.memo(MatTableColumnsButton);
