import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useQueryParams } from 'hookrouter';
import Customer, * as Cust from 'data/Customer';
import InfoAndCompanies from 'common/Customers/InfoAndCompanies';
import ComplianceCheck from 'common/Customers/ComplianceCheck';
import CustomerVendorDetails from 'common/Customers/CustomerVendorDetails';
import EditorSection from 'view/EditorSection';
import EditorNameInput from 'view/EditorNameInput';
import Page from 'components/UI/Page';
import Tabs from 'components/UI/Tabs';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, FontSize } from 'lib/styles';
import { useAPI } from 'lib/API';
import { CUSTOMER_TABS } from 'constants/tabs';
import { DOW_JONES_BLOCKED } from 'constants/dowJonesStatuses';
import { Main, Text, Background } from 'styles/themeColors';
import { useAuth } from 'lib/Auth';
import { isVendor } from 'data/User';
import { loadCustomer } from 'requests/customer';
import FeatureFlag from 'data/FeatureFlag';
import { filterDowJonesRelations } from 'utils/dowJones';

interface Props {
  customerId: string;
  featureFlags: FeatureFlag;
};

const CustomerDetails = ({
  customerId,
  featureFlags
}: Props) => {
  const { user } = useAuth();
  const isVendorAcc = isVendor(user!);
  const [customerDetails, setCustomerDetails] = useState<Customer>({ id: 0, ...Cust.empty });
  const [queryParams, setQueryParams] = useQueryParams();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(parseInt(queryParams.activeTab));
    setQueryParams({ activeTab: undefined });
  }, []);

  const customerDetailsReq = useAPI({
    customerId,
    promiseFn: loadCustomer,
    onResolve: result => {
      setCustomerDetails(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const mappedTabs = CUSTOMER_TABS
    .filter(tab => filterDowJonesRelations(featureFlags, tab.value))
    .map((tab, index) => {
      const dowJonesFailedResult = customerDetails.dowJones.find(dowJones => dowJones.status === DOW_JONES_BLOCKED);

      if (index === 1 && dowJonesFailedResult) {
        return {
          ...tab,
          badge: {
            content: 'Failed',
            color: Text.primary,
            backgroundColor: Main.warning
          }
        }
      }

      return tab;
    });

  return (
    <Page bgColor={Background.white} padding="10px 30px" isLoading={customerDetailsReq.isLoading}>
      <EditorSection
        disablePaddingBottom={true}
        noBorder
      >
        <EditorNameInput
          onChange={() => { }}
          placeholder="Customer name..."
          value={customerDetails.firstName + ' ' + customerDetails.lastName}
          fontSize={FontSize.huge}
          showDelimiter={false}
          disabled
          disabledBgColor={Background.white}
          disabledColor={Color.black}
        />
      </EditorSection>
      {isVendorAcc ?
        <CustomerVendorDetails customerDetails={customerDetails} /> :
        (
          <Tabs tabs={mappedTabs} activeTab={activeTab}>
            <InfoAndCompanies customerDetails={customerDetails} />
            <ComplianceCheck
              dowJonesResults={customerDetails.dowJones}
              customerName={customerDetails.firstName + ' ' + customerDetails.lastName}
              companyName={customerDetails.companyName}
              customerDetailsReq={customerDetailsReq}
              setActiveTab={setActiveTab}
            />
          </Tabs>
        )
      }
    </Page>
  );
};

export default CustomerDetails;
