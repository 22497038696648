import { post, put } from 'lib/API';

export const createCategory = async ([newCategory, mainCategoryId]: any[], { token }: any): Promise<any> => {
  return await post('v1/categories', token, {
    name: newCategory.name,
    mainCategoryId,
    mediaId: newCategory.media && newCategory.media.length > 0 ? newCategory.media[0].id : null
  });
};

export const saveCategory = async ([categoryToUpdate]: any[], { token }: any): Promise<any> => {
  return await put(`v1/categories/${categoryToUpdate.id}`, token, {
    name: categoryToUpdate.name,
    mediaId: categoryToUpdate.media && categoryToUpdate.media.length > 0 ? categoryToUpdate.media[0].id : null
  });
};
