import Customer, { CustomerExtended, CustomersPagination } from 'data/Customer';
import { get,put } from 'lib/API';

export const loadCustomers = async (
  [size, page, offset, vendorId]: any[],
  { token }: any
): Promise<CustomersPagination> => {
  return await get('v1/customers', token, { size, page, offset, vendorId });
};

export const loadCustomer = async ({
  customerId,
  token,
}: any): Promise<Customer> => {
  return await get(`v1/customers/${customerId}`, token);
};

export const loadCompanyCustomers = async ({
  companyId,
  token,
}: any): Promise<Customer[]> => {
  return await get(`v1/customers/companyCustomers/${companyId}`, token);
};

export const loadCompanyCustomersDefer = async (
  [companyId]: any,
  { token }: any
): Promise<CustomerExtended[]> => {
  return await get(`v1/customers/companyCustomers/${companyId}`, token);
};

export const updateTaxExempted = async (
  [customerId, isTaxExempted]: any[],
  { token }: any
): Promise<any> => {
  return await put(`v1/customers/${customerId}`, token, {
    taxExempted: isTaxExempted
   });
};
