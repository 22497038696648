import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { latoFont } from 'OnboardingComponents/Elements/Typography';
import { Color, FontSize, FontWeight } from 'lib/styles';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Icon from 'view/Icon';
import Button from 'components/UI/Button';
import PickupLocationEditForm from 'view/PickupLocationEditForm';
import { renderToast } from 'components/UI/ToastNotification';
import PointOfService from 'data/PointOfService';
import { useAPI } from 'lib/API';
import { removePointOfService } from 'requests/pointOfService';
import { Main, Background } from 'styles/themeColors';
import Country from 'data/Country';
import { MAX_CITY_LENGTH, MAX_FULLNAME_LENGTH, MAX_ID_LENGTH, MAX_NICKNAME_LENGTH, MAX_STREET_LENGTH, validatePostalCode, validateWarehousePosField } from 'lib/validation';
import PosFormValidator, * as pv from 'data/PosFormValidator';

const Nickname = styled.div`
  ${latoFont}
  font-size: ${FontSize.large};
  font-weight: ${FontWeight.bold};
  line-height: 1.5;
  text-align: left;
  color: ${Color.black};
`;

const PickupLocationId = styled.span`
  ${latoFont}
  font-size: 16px;
  font-weight: ${FontWeight.bold};
  line-height: 2.6;
  text-align: left;
  color: rgba(139, 144, 149, 0.9);
  margin-left: 22px;
`;

const Name = styled.div`
  ${latoFont}
  font-size: 16px;
  font-weight: ${FontWeight.bold};
  line-height: 1.63;
  text-align: left;
  color: ${Color.black};
`;

const Address = styled.div`
  ${latoFont}
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  color: ${Color.black};
`;

const EditIcon = styled(Icon).attrs({ size: 20 })`
  color: ${Main.accent};

  ${props => props.name === 'edit' && css`
    &:hover{
      color: ${Main.warning};
    }
  `}

  ${props => props.name === 'check' && css`
    color: ${Main.success};

    &:hover{
      color: ${Main.success};
    }
  `}

  ${props => props.name === 'x' && css`
    color: ${Main.error};

    &:hover{
      color: ${Main.error};
    }
  `}
`;

const ColoredLine = styled.p`
  background-color: ${Color.veryLightGray};
  height: 3px;
`;

const Coordinate = styled.span`
  margin-left: 5px;
  font-size: 12px;
  line-height: 1.71;
  text-align: left;
  color: rgba(139, 144, 149, 0.9);
`;

interface Props {
  pickupLocation: PointOfService;
  removePOS: (posId: number) => void;
  upsertPOS: (pos: PointOfService) => void;
  editingId: number | null;
  isExistingPOS?: boolean;
  setEditingId: (id: number | null) => void;
  removePOSOnCancel?: () => void;
  reloadWarehousesReq: () => void;
  country?: Country;
};

const PickupLocationCard = ({ pickupLocation, removePOS, upsertPOS, editingId, setEditingId, removePOSOnCancel, reloadWarehousesReq, isExistingPOS, country }: Props) => {
  const [currentPickupLocation, setCurrentPickupLocation] = useState<PointOfService>(pickupLocation);
  const [editing, setEditing] = useState<boolean>(editingId === currentPickupLocation.id);
  const [posValidator, setPosValidator] = useState<PosFormValidator>({...pv.empty});

  const deletePOSRequest = useAPI({
    deferFn: removePointOfService,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Pick-up location deleted successfully.');
      removePOS(currentPickupLocation.id);
      reloadWarehousesReq();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    setEditing(editingId === currentPickupLocation.id);
  }, [editingId]);

  useEffect(() => {
    if (currentPickupLocation) {
      setCurrentPickupLocation(pickupLocation);
    }
  }, [pickupLocation]);

  let actionButtons = null;

  const handleChange = (value: string, attribute: string) => {
    setCurrentPickupLocation({
      ...currentPickupLocation,
      [attribute]: value
    })
    setPosValidator({
      ...posValidator,
      [attribute]: false
    })
  };

  const onInputBlur = (name: string, validatorFunction: () => void) => {
    (currentPickupLocation as any)[name]?.length > 0 ? 
    setPosValidator({
      ...posValidator,
      [name]: validatorFunction()
    }) : 
      setPosValidator({
        ...posValidator,
        [name]: false
      });
  }

  const cancel = () => {
    if(!isExistingPOS){
      removePOS(editingId || 0);
    }

    setEditingId(null);
    setCurrentPickupLocation(pickupLocation);
    setPosValidator(pv.empty)

    if (removePOSOnCancel) {
      removePOSOnCancel();
    }
  };

  const save = () => {
    setEditingId(null);
    upsertPOS({ ...currentPickupLocation, countryId: Number(country?.id) });
  };

  const edit = () => {
    setEditingId(currentPickupLocation.id);
  };

  const deletePOS = () => {
    if (isExistingPOS) {
      deletePOSRequest.run(currentPickupLocation);
    } else {
      removePOS(currentPickupLocation.id);
    }
  };

  const validateFullnameInWarehouse = () => {
    return !validateWarehousePosField(currentPickupLocation.name, MAX_FULLNAME_LENGTH);
  }

  const validateNicknameInWarehouse = () => {
    return !validateWarehousePosField(currentPickupLocation.nickname, MAX_NICKNAME_LENGTH);
  }

  const validateIdInWarehouse = () => {
    return !validateWarehousePosField(String(currentPickupLocation.posId), MAX_ID_LENGTH);
  }

  const validateZipInWarehouse = () => {
    return !validatePostalCode(currentPickupLocation.zip, country?.id);
  }

  const validateCityInWarehouse = () => {
    return !validateWarehousePosField(currentPickupLocation.city, MAX_CITY_LENGTH);
  }

  const validateStreetInWarehouse = () => {
    return !validateWarehousePosField(currentPickupLocation.street, MAX_STREET_LENGTH);
  }

  if (!editing) {
    actionButtons = (
      <FlexboxContainer justifyContent="flex-end">
        <EditIcon disabled={editing} name="edit" onClick={edit} />
        <EditIcon name="x" onClick={deletePOS} />
      </FlexboxContainer>)
  }

  if (editing) {
    actionButtons = (
      <FlexboxContainer justifyContent="flex-end">
        <EditIcon name="edit-3" disabled={true} />
      </FlexboxContainer>)
  }

  const isSaveDisabled = !currentPickupLocation.nickname
    || !currentPickupLocation.name
    || !currentPickupLocation.posId
    || !currentPickupLocation.street
    || !currentPickupLocation.city
    || !currentPickupLocation.state
    || !currentPickupLocation.zip
    || posValidator.city
    || posValidator.street
    || posValidator.zip
    || posValidator.state
    || posValidator.name
    || posValidator.nickname
    || posValidator.posId

  const editForm = editing ?
    (
      <FlexboxContainer flexDirection="column" width="100%" >
        <ColoredLine />
        <PickupLocationEditForm 
          pickupLocation={currentPickupLocation} 
          handleChange={handleChange} 
          country={country}
          posValidator={posValidator} 
          onInputBlur={onInputBlur} 
          validateZipCode={validateZipInWarehouse} 
          validateCity={validateCityInWarehouse} 
          validateStreet={validateStreetInWarehouse} 
          validateFullname={validateFullnameInWarehouse} 
          validateNickname={validateNicknameInWarehouse} 
          validateId={validateIdInWarehouse} 
        />
        <FlexboxContainer border="1px 0 0 0 solid lightgrey" margin="20px 0 0 0" justifyContent="flex-end">
          <Button margin="0 10px 0 0" isGhost color={Main.accent} onClick={cancel}>
            Cancel
          </Button>
          <Button onClick={save} disabled={isSaveDisabled}>
            Save
          </Button>
        </FlexboxContainer>
      </FlexboxContainer>
    ) : null;

  return (
    <FlexboxContainer
      flexDirection="column"
      bgColor={Background.white}
      boxShadow="0 1px 5px 0 rgba(0, 0, 0, 0.16)"
      margin="20px 0 24px 0"
      padding="22px 21px 23px 15px;"
    >
      <FlexboxContainer justifyContent="space-between" alignItems="center">
        <FlexboxContainer justifyContent="flex-start">
          <Nickname>{currentPickupLocation.nickname}</Nickname>
          <PickupLocationId >{currentPickupLocation.posId}</PickupLocationId>
        </FlexboxContainer>
        {actionButtons}
      </FlexboxContainer>
      <FlexboxContainer>
        <Name>
          {currentPickupLocation.name}
        </Name>
      </FlexboxContainer>
      <FlexboxContainer justifyContent="flex-start">
        <Address>
          {`${country?.countryName || ''} ${currentPickupLocation.street || ''} ${currentPickupLocation.city || ''} ${currentPickupLocation.state || ''} ${currentPickupLocation.zip || ''}`}
        </Address>
      </FlexboxContainer>
      <FlexboxContainer justifyContent="flex-start">
        <Coordinate>
          {currentPickupLocation.longitude}
        </Coordinate>
        <Coordinate>
          {currentPickupLocation.latitude}
        </Coordinate>
      </FlexboxContainer>
      {editForm}
    </FlexboxContainer>
  )
};

export default PickupLocationCard;
