import { navigate } from 'hookrouter';
import { swap } from 'lib/Array';
import React from 'react';
import { getPropertyByKeyPath } from './object';

export type SortByConfig = {
  field: string,
  defaultSort?: string
}

export const applySearchFilter = (
  items: any[],
  properties: string[],
  searchKey: string
) => {
  const searchForKeyInProperties = (item: any, properties: string[], searchKey: string) => {
    if (!searchKey) return true;

    for (let prop of properties) {
      const nestedProps = prop.split('.'); // Split nested properties
      let nestedItem = item;

      // Traverse through nested properties
      for (const nestedProp of nestedProps) {
        if (!nestedItem || typeof nestedItem !== 'object') {
          break; // Break if the property doesn't exist or is not an object
        }
        nestedItem = nestedItem[nestedProp];
      }
      // check added to convert numeric price to string for search
      if(typeof nestedItem === 'number'){
        nestedItem = nestedItem.toString();
      }
      if (nestedItem && nestedItem.toLowerCase().includes(searchKey.toLowerCase())) {
        return true; // Found a match
      }
    }

    return false;
  };

  return items.filter(item => searchForKeyInProperties(item, properties, searchKey));
};

export const customSort = (sortBy: SortByConfig) => (a: any, b: any) => {
  if (a == null || b == null) {
    return a == null ? 1 : -1;
  }

  if (typeof a === 'number' || typeof b === 'number') {
    return (sortBy.defaultSort === 'asc')
      ? a - b
      : b - a;
  }


  const dateA = new Date(a);
  const dateB = new Date(b);

  if (dateA instanceof Date && dateB instanceof Date && dateA.toString() !== "Invalid Date" && dateB.toString() !== "Invalid Date") {
    return sortBy.defaultSort === 'asc'
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  }

  const floatA = parseFloat(a);
  const floatB = parseFloat(b);

  if(!isNaN(floatA) && !isNaN(floatB)){
    return (sortBy.defaultSort === 'asc')
    ? floatA - floatB
    : floatB - floatA;
  }

  const stringA = a.toString();
  const stringB = b.toString();

  return (sortBy.defaultSort === 'asc')
    ? stringA.localeCompare(stringB)
    : stringB.localeCompare(stringA);
};

export const applySortFilter = (
  items: any[],
  sortBy?: SortByConfig
) => {
  if (!sortBy || !sortBy.field) return items;
  return items.sort((a: any, b: any) => {
    const first = getPropertyByKeyPath(sortBy.field, a);
    const second = getPropertyByKeyPath(sortBy.field, b);
    const sort = customSort(sortBy);

    return sort(first, second);
  });
};

export const applyPaginationFilter = (
  items: any[],
  pageIndex: number,
  pageSize: number
) => {
  return items.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
};

export const calculatePages = (
  itemsCount: number,
  pageSize: number
) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);

  return (pagesCount === 0) ? 1 : pagesCount;
};

export const mergePaginationData = (
  data: any,
  component: string,
  uniqueKey: string,
  newData: any[],
  setData: React.SetStateAction<any>
) => {
  setData((prevState: any) => {
    if (prevState[component].length > 0) {
      return {
        ...prevState,
        [component]: [
          ...prevState[component],
          ...newData.filter(n => !prevState[component].some((d: any) => d[uniqueKey] === n[uniqueKey]))
        ]
      }
    }
    return { ...prevState, [component]: newData }
  });
};

export const updatePreferences = (
  oldOnes: any,
  newOnes: any,
  updateRequest: any,
  createRequest: any
) => {
  if (!oldOnes) {
    createRequest.run(newOnes);
    return;
  }

  updateRequest.run(newOnes, newOnes.userId);
};

export const handleOnSizeChange = (
  newSize: number,
  changeSize: (size: number) => void,
  changePage: (page: number) => void
) => {
  changeSize(newSize);
  changePage(1);
};

export const handleOnSortChange = (
  index: number,
  direction: any,
  columns: any[],
  changeSort: (column: any) => void
) => {
  const column = columns[index];
  column.defaultSort = direction;

  changeSort({ ...column, tableData: undefined });
};

export const handleOnSearchKeyChange = (
  searchKey: string,
  changeSearch: (value: string) => void,
  changePage: (page: number) => void
) => {
  changeSearch(searchKey);
  changePage(1);
};

export const handleOnRowClick = (
  url: any,
  params: any,
  doNothing: boolean = false
) => {
  if (doNothing) return;

  navigate(url, false, params);
};

export const handleOnColumnDrag = (
  sourceIndex: number,
  destinationIndex: number,
  columns: any[],
  oldPreferences: any,
  componentTableKey: string,
  changeColumns: (columns: any[]) => void,
  updatePreferences: (oldOnes: any[], newOnes: any[]) => void
) => {
  const newColumns = swap(columns, sourceIndex, destinationIndex).map(item => ({ ...item, tableData: undefined }));
  const newPreferences = { ...oldPreferences, [componentTableKey]: newColumns };

  changeColumns(newColumns);
  updatePreferences(oldPreferences, newPreferences);
};

export const handleOnColumnHidden = (
  newColumns: any[],
  oldPreferences: any,
  componentTableKey: string,
  changeColumns: (columns: any[]) => void,
  updatePreferences: (oldOnes: any[], newOnes: any[]) => void
) => {
  const newPreferences = { ...oldPreferences, [componentTableKey]: newColumns };

  changeColumns(newColumns);
  updatePreferences(oldPreferences, newPreferences);
};
