import React from 'react';
import { LeftNav, StyledNavLink, NavList, NavItem, StandardLink } from 'OnboardingComponents/Elements';
import { navigate } from 'hookrouter';

export const NavPanel: React.FunctionComponent<{ view?: string }> = ({ view }) => {
  return (
    <LeftNav as="nav">
      <NavList>
        <NavItem>
          <StyledNavLink active={(view === 'welcome')} onClick={() => navigate('/onboarding/welcome')}>Welcome to OFMP</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink active={(view === 'process-guidelines')} onClick={() => navigate('/onboarding/process-guidelines')}>Process guidelines</StyledNavLink>
        </NavItem>
        <NavItem>
          <StyledNavLink active={(view === 'product-guidelines')} onClick={() => navigate('/onboarding/product-guidelines')}>Product guidelines</StyledNavLink>
          {(view === 'product-guidelines') &&
            <NavList>
              <NavItem>
                <StandardLink href='#tone-of-voice'>Tone of voice</StandardLink>
              </NavItem>
              <NavItem>
                <StandardLink href='#product-naming'>Product naming</StandardLink>
              </NavItem>
              <NavItem>
                <StandardLink href='#product-description'>Product description</StandardLink>
              </NavItem>
              <NavItem>
                <StandardLink href='#product-imagery'>Product imagery</StandardLink>
              </NavItem>
              <NavItem>
                <StandardLink href='#supporting-documents'>Supporting documents</StandardLink>
              </NavItem>
            </NavList>}
        </NavItem>
        <NavItem>
          <StyledNavLink active={(view === 'seller-guidelines')} onClick={() => navigate('/onboarding/seller-guidelines')}>Seller guidelines</StyledNavLink>
          {(view === 'seller-guidelines') &&
            <NavList>
              <NavItem>
                <StandardLink href='#sales-team'>Sales Teams</StandardLink>
              </NavItem>
              {/* <NavItem>
                <StandardLink href='#faqs'>FAQs</StandardLink>
              </NavItem> */}
              <NavItem>
                <StandardLink href='#website'>Website</StandardLink>
              </NavItem>
              <NavItem>
                <StandardLink href='#newsletter'>Newsletter</StandardLink>
              </NavItem>
              {/* <NavItem>
                <StandardLink href='#social-media'>Social Media</StandardLink>
              </NavItem> */}
              {/* <NavItem>
                <StandardLink href='#events'>Events</StandardLink>
              </NavItem> */}
              <NavItem>
                <StandardLink href='#multi-brand'>Multi-brand</StandardLink>
              </NavItem>
            </NavList>}
        </NavItem>
        <NavItem>
          <StyledNavLink active={(view === 'brand-communication')} onClick={() => navigate('/onboarding/brand-communication')}>Brand &amp; communication</StyledNavLink>
          {(view === 'brand-communication') &&
            <NavList>
              <NavItem>
                <StandardLink href='#intro'>Intro</StandardLink>
              </NavItem>
              <NavItem>
                <StandardLink href='#why-we-exist'>Why we exist</StandardLink>
              </NavItem>
              <NavItem>
                <StandardLink href='#primary-logo'>Primary logo</StandardLink>
              </NavItem>
            </NavList>}
        </NavItem>
        <NavItem>
          <StyledNavLink active={(view === 'promo-resources')} onClick={() => navigate('/onboarding/promo-resources')}>Promo resources</StyledNavLink>
        </NavItem>
      </NavList>
    </LeftNav>
  );
};

export default NavPanel;
