import { createTheme } from '@material-ui/core';

export const themeMui = createTheme({
  typography: {
    fontFamily: 'Lato',
  },
  shape: {
    borderRadius: 6,
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 4px 8px rgba(75, 78, 81, 0.2)'
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0,
      }
    }
  },
});
