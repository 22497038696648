export const TEXT_LABELS = {
   EDIT_RENTAL : 'Edit Rental',
   RENTAL_SHIPPED_MSG : 'Your rental was shipped',
   END_DATE : 'End Date',
   START_DATE: 'Start Date',
   CURRENT_END_DATE : 'Current end date',
   CURRENT_START_DATE: 'Current start date',
   NEW_END_DATE : 'New end date',
   NEW_START_DATE: 'New start date',
   PURCHASE_SHIPPED_MSG  : 'Your obligation for fulfillment is complete',
   RENTAL_START_DATE: 'Rental start date',
   RENTAL_END_DATE: 'Rental end date',
   NEW_DELIVERY_DATE: 'New delivery date',
   NEW_RENTAL_DATES: 'Provide new rental dates',
   RENTAL_MODIFICATOION_REASON: 'Indicate the reason for rental modification',
   PROCESSING_PAYMENT: 'Processing payment',
   PLEASE_WAIT_FOR_A_MINUTE: 'Please wait, this can take up to a minute.',
   RENTAL_COMPLETED: 'Your rental has been completed',
   ORDER_UPDATED_SUCCESSFULLY: 'The order state is updated successfully.',
   PLEASE_WAIT: 'Please wait...',
   RENTAL_DETAILS: 'Rental details',
   MODIFY_RENTAL: 'Modify rental',
   RENTAL_MODIFICATION: 'Rental modification',
   RENTAL_PERIOD_FINISHED: 'Rental period finished',
   OPEN_DISPUTE: 'Open dispute',
   RETRY_PAYMENT: 'Retry payment',
   CONFIRM_RENTAL_COMPLETION: 'Confirm rental completion',
   RENT_DATES_MODIFIED: 'Rent dates were modified',
   RENT_MODIFIED_BY_CUSTOMER: 'The rent was modified by customer from their account on the website',
   PREVIOUS_RENT_DATES: 'Previous rent dates:',
   NEW_RENT_DATES: 'New dates:',
   RENT_DATES: 'Rent dates:',
   NEW_RENTAL_DATES_LABEL: 'New rent dates:',
   EDT_CHANGES: 'Edit changes',
   CONFIRM_CHANGES: 'Confirm changes',
   REJECT_CHANGES: 'Reject changes',
   RENT_WAS_MODIFIED: 'Rent was modified',
   RENT_MODIFICATION_REASON: 'Reason for rental modification:',
   NO_RENT_MODIFICATION_REASON: 'No reason provided.',
   RENT_WAS_MODIFIED_DETAILS: 'Rent modifications were sent to customer. If customer approves modifications via website, order will be modified automatically. If changes were agreed with customer by different means, please confirm modifications below, or reject changes.',
   RENT_MODIFIED_BY_CUSTOMER_ON_HOLD: 'The rent was modified by customer from their account on the website',
   RENT_MODIFIED_BY_VENDOR_ON_HOLD: 'The rent was modified by vendor',
   CONFIRM_MODIFICATION: 'Confirm modification',
   TICKET_OPENED_BY_CUSTOMER: 'Ticket is opened by customer',
   TICEKT_RESOLUTION_BY_VENDOR: 'Ticket resolution sent to the customer',
   CUSTOMER_OPENED_TICKET: 'Customer opened the ticket from the account on the website',
   VENDOR_RESOLUTION_SUBMITTED: 'Awaiting Customer Action on Resolution',
   TICKET_REASON: 'Ticket reason:',
   RESOLVE_TICKET: 'Resolve ticket',
   ATTACHMENTS: 'Attachments',
   RESOLVE_TICKET_DESCRIPTION: 'You can propose other conditions for resolving the ticket by making adjustment to rental dates. You can also leave a comment to customer for clarification.',
   ADD_INFO: 'Add information for your client',
   OPTIONAL: '(optional)',
   ATTACH_DOCUMENTS: 'Attach set of documents',
   DOCUMENTS: 'Documents',
   RENTAL_UPDATE: 'Rental update',
   ESTIMATED_DELIVERY: 'Est. delivery',
   ESTIMATED_PICKUP: 'Est. pickup',
   READY_FOR_PICKUP: 'Ready for Pickup',
   EDIT: 'Edit',
   INVALID_FILE_TYPE: 'Unsupported file type provided.'
}

export const CONSIGNMENT_TYPES = {
   RENT : 'RENT',
   PURCHASE:'PURCHASE'
}