import React, { ReactNode, useEffect, useState } from 'react';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Modal from 'components/UI/Modal';
import Select from 'components/UI/Select';
import { Link } from '@material-ui/icons';
import styled from 'styled-components';
import InputComponent from 'components/UI/Input';
import Button from 'components/UI/Button';
import Customer from 'data/Customer';
import Vendor from 'data/Vendor';
import { Space } from 'styles/themeSpaces';
import Country from 'data/Country';

const Label = styled.span<any>`
  font-size: 16px;
  line-height: 20px;
  color: ${({ $color }) => $color};
`;
const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm?: (value: string) => void;
  isLoading?: boolean;
  userType?: string;
  assigneeList: Vendor[];
  setAssignee: (assignee: Option) => void;
  assignee?: Option;
  selectedVendor?: Vendor;
  assignedByError: boolean;
  countries: Country[];
  country?: Option;
  countryError?: boolean;
  setSelectedCountry: (country: Option) => void;
}

interface Option {
  value: string;
  id?: string;
  icon?: ReactNode;
  badge?: any;
}

const NewReferencePBModal = ({
  show,
  onClose,
  onConfirm,
  assigneeList,
  setAssignee,
  assignee,
  countries,
  countryError,
  country,
  setSelectedCountry,
  selectedVendor,
  assignedByError
}: Props) => {
  const [assignedByList, setAssignedByList] = useState<Option[]>([]);
  const [countriesList, setCountriesList] = useState<Option[]>([]);
  const [cancelModal, setCancelModal] = useState(false);

  useEffect(() => {
    const options = assigneeList.map((assignee: any) => ({
      value: assignee.name,
      id: assignee.code,
    }));
    setAssignedByList([...options]);
  }, [assigneeList]);

  useEffect(() => {
    const options = countries.map((country: Country) => ({
      value: country.countryName,
      id: country.id?.toString(),
    }));
    setCountriesList([...options]);
  }, [countries]);
  
  const onSelectCountry = (event: any) => {
    setSelectedCountry(event.target);
    console.log(event.target);
  };

  const onSelectAssignee = (event: any) => {
    setAssignee(event.target);
    console.log(event.target);
  };

  return (
    <>
    <Modal
      width="794px"
      showModal={cancelModal ? false : show}
      onClose={onClose}
      showButtons={false}
      title="New reference pricebook"
      modalFooterMargin="0"
      left="50vw"
      marginLeft="-397px"
      iconName="Public"
      showCloseIcon
    >
      <FlexboxContainerComponent flexDirection="column">
        <FlexboxContainerComponent
          flexDirection="row"
          width="100%"
          padding="90px 0px 0px 0px"
        >
          {!selectedVendor?.id && (
            <FlexboxContainerComponent flexDirection="column" width="100%">
              <FlexboxContainerComponent padding="0px 0px 8px 0">
                {' '}
                <Label $color={assignedByError ? "#C82135" : ""}>Assigned by vendor</Label>
              </FlexboxContainerComponent>
              <FlexboxContainerComponent
                flexDirection="row"
                alignItems="center"
                width="100%"
              >
                <Select
                  options={assignedByList}
                  onChange={onSelectAssignee}
                  value={assignee?.value}
                  defaultValue="Select"
                  width="100%"
                  height="36px"
                  isInvalid={assignedByError}
                />
                <Link style={{ height: '25', width: '30', color: '#0E141A' }} />
              </FlexboxContainerComponent>
            </FlexboxContainerComponent>
          )}
            <FlexboxContainerComponent
              flexDirection="column" width="100%"
            >
              <FlexboxContainerComponent padding="0px 0px 8px 0">
                {' '}
                <Label $color={countryError ? "#C82135" : ""}>Assigned to country</Label>
              </FlexboxContainerComponent>
              <Select
                labelPosition="top"
                options={countriesList}
                onChange={onSelectCountry}
                value={country?.value}
                defaultValue="Select"
                width="100%"
                height="36px"
                isInvalid={countryError}
              />
            </FlexboxContainerComponent>
        </FlexboxContainerComponent>

        <FlexboxContainerComponent
          padding="104px 0 0 0"
          justifyContent="flex-end"
        >
          <Button id="modal-main-button" bgColor="#FC780B" onClick={onConfirm}>
            Next
          </Button>
        </FlexboxContainerComponent>
      </FlexboxContainerComponent>
    </Modal>
    </>);
};

export default NewReferencePBModal;
