import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { navigate, usePath, useQueryParams, A } from 'hookrouter';
import { DatePicker } from '@material-ui/pickers';
import { LocalShipping } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { Email } from '@material-ui/icons';
import * as Cons from 'data/Consignment';
import ConsignmentEntry from 'data/ConsignmentEntry';
import * as User from 'data/User';
import ShippingCarrier from 'data/ShippingCarrier';
import ManufactureModal from 'components/Consignments/Modals/ManufactureModal';
import OrderCancelModal from 'components/Consignments/Modals/OrderCancelModal';
import Modal from 'components/UI/Modal';
import { FontSize, FontWeight, Size } from 'lib/styles';
import OrderFulfillmentTable from 'components/Consignments/OrderFulfillmentTable';
import Icon from 'view/Icon';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Input from 'components/UI/Input';
import Divider from 'components/UI/Divider';
import DatePickerInput from 'components/UI/DatePickerInput';
import Page from 'components/UI/Page';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { renderToast } from 'components/UI/ToastNotification';
import { useAPI } from 'lib/API';
import { removeAtIndex } from 'lib/Array';
import { useAuth } from 'lib/Auth';
import Breadcrumbs from 'components/UI/Breadcrumb';
import { Space } from 'styles/themeSpaces';
import { BorderRadius } from 'lib/styles';
import { LensRounded } from '@material-ui/icons';
import {
  CHECKING_CREDIT,
  ORDER_SENT_TO_OEM,
  READY,
  NEW,
  CANCELING,
  CANCELED,
  ON_HOLD,
  PROCESSING,
  MANUFACTURING,
  SPLIT_CONSIGNMENT,
  PACKING,
  SHIPPED_READY_FOR_PICKUP,
  DELIVERED_PICKED_UP,
  DELAYED,
  AWAITS_SHIPPING,
  AWAITS_SHIPPING_APPROVAL,
  SHIPPED,
  PICKED_UP,
  DOW_JONES_FAILED,
  READY_TO_SHIP,
  READY_FOR_PICKUP,
  BUYER_DELAY_CANCEL,
  BUYER_FREIGHT_COST_CANCEL,
  BUYER_FREIGHT_COST_TIMEOUT,
  PROCESSING_PACKING,
  RENT_IN_PROGRESS,
  RENT_RETURN_PENDING,
  RENT_COMPLETED,
  OFMP_CANCEL,
  DJ_CANCEL,
  VENDOR_REJECT,
  VENDOR_FREIGHT_COST_CANCEL,
  VENDOR_CREDIT_LIMIT_CANCEL,
  SYSTEM_CREDIT_CARD_FAILURE,
  CANCELATION_REASON_MESSAGES,
  MODIFIED_BY_CUSTOMER,
  MODIFIED_BY_VENDOR,
  RENT_UPDATED,
  HIDE_STATUS_ON_ORDER_HISTORY,
  TICKET_IN_PROGRESS,
  HIDE_CANCEL_CONSIGNMENT_STATES,
  BUYER_GENERAL_CANCEL
} from 'constants/consignmentStates';
import { CONSIGNMENT_CREDIT_OVERRIDE, CONSIGNMENT_CREDIT_REJECTED } from 'constants/consignmentCreditStatuses';
import { DOW_JONES_BLOCKED, DOW_JONES_CLEAR, DOW_JONES_REVIEWED_BLOCKED, DOW_JONES_REVIEWED_CLEAR } from 'constants/dowJonesStatuses';
import { SHIPPING } from 'constants/deliveryModes';
import { mapCurrentAndPreviousValuesInArray, parseConsignmentId, parseRecipientName, recalculateRentalDates, retrieveConsignmentCreditCheckStatusText, stringifyPickupContact } from 'utils/consignment';
import OrderCancellationEmailModal from 'components/Orders/OrderCancellationEmailModal';
import { callStockAPI, cancellationOrderEmailRequest } from 'requests/order';
import { emulateSplitConsignment, updateRentalState, updateSalesOrderNumber } from 'requests/consignment';
import { getDocumentsForConsignment, getDocumentsForOrder } from 'requests/documents';
import {
  loadConsignment,
  updateAdditionalShippingCost,
  updateDelivery,
  updateDispatch,
  updateFulfillment,
  updateState
} from 'requests/consignment';
import { Main, Text, Background, Shade, Border } from 'styles/themeColors';
import Button from 'components/UI/Button';
import ConsignmentCancelallationModal from 'components/Consignments/Modals/ConsignmentCancelationModal';
import FeatureFlag from 'data/FeatureFlag';
import { getCompanyCredit, overrideCompanyCredit } from 'requests/apigee';
import ConsignmentShortPaymentsBanner from 'components/Consignments/Banners/ConsignmentShortPaymentsBanner';
import { loadShippingCarriers, loadShippingCarriersByCountry } from 'requests/shippingCarrier';
import { CreditInfo } from 'data/VendorCompany';
import ShippingDetails from 'components/Consignments/ShippingDetails';
import PickupDetails from 'components/Consignments/PickupDetails';
import Documents from 'components/Consignments/Documents';
import OrderDetailsInformation from 'components/Orders/OrderDetailsInformation';
import { capitalize, formatDateTime, getLocalTimezone } from 'utils/formatters';
import { getSteps } from 'utils/gridColumnMappings';
import Pill from 'components/UI/Pill';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import Banner from 'components/UI/Banner';
import BannerWraper from 'components/UI/BannerWraper';
import ConfirmOrder from 'components/Consignments/ConfirmOrder'
import OrderInformationTable from 'components/Orders/OrderInformationTable';
import Select from 'components/UI/Select';
import CurrencyInput from 'components/UI/CurrencyInput';
import Checkbox from 'components/UI/Checkbox';
import ShippingCostRequiredItems from 'components/Consignments/ShippingCostRequiredItems';
import Textarea from 'components/UI/Textarea';
import SplitPostOrderShippingCost from './SplitPostOrderShippingCost';
import { SocketContext } from 'context/socket';
import { getSalesOrderForConsignment } from 'requests/salesOrder';
import SalesOrder from 'components/Orders/SalesOrder';
import SalesOrderModal from './Modals/SalesOrderModal';
import SalesOrderDetailsModal from './Modals/SalesOrderDetailModal';
import { getCountryName } from 'utils/country';
import { stockAPIMessage } from 'constants/errorMessages';
import { STS_PENDING, STS_SUCCESSFUL, SYNC_RESPONSES } from 'constants/syncToSapStates';
import RentalDates from './components/RentalDates';
import { CONSIGNMENT_TYPES, TEXT_LABELS } from 'constants/order';
import RentalOrderStates, { RentModifiedOnHold } from './RentalOrderStates';
import { PAYMENT_STATUS } from '../../constants/paymentStatus';
import RentalNewDates from './components/RentalNewDates';
import { CONSIGNMENT_MODIFICATION_ACTORS, CONSIGNMENT_MODIFICATION_STATUS } from 'constants/consignmentEntry';
import OrderProgress from './OrderProgress';
import { PRICE_TYPE } from 'constants/product';
import TicketHistory from 'components/Ticket/TicketHistory';
import OrderTag from 'components/UI/OrderTag';
import { printComponent } from 'utils/print';
import ModifyEstDateWithRentaModal from './Modals/ModifyEstDateWithRentModal';
import SplitPostOrderShippingCostRent from './SplitPostOrderShippingCostRent';
import { toDate } from 'utils/date';

const StyledLocalShipping = styled(LocalShipping) <any>`
  font-size: 44px !important;
  color: ${Text.primary} !important;
  margin: 0 16px;
`;

const StyledWarehouseIcon = styled(Icon).attrs({ name: 'Warehouse', size: 44 })`
  color: ${Text.primary} !important;
  margin: 0 16px !important;
`;

const LensRoundedIcon = styled(LensRounded) <any>`
  font-size: 10px !important;
  margin-left: 5px;
`;

const PlacedDate = styled.span`
  margin: 12px 0 32px 16px;
  font-size: 14px;
  font-style: italic;
  line-height: 18px;
`;

const StyledSlashIcon = styled(Icon).attrs({ name: 'Slash', size: 16 })`
  color: ${Text.primary};
`;

const SubHeading = styled.div`
  font-size: ${FontSize.medium};
  font-weight: ${FontWeight.bold};
  margin-bottom: 10px;
`;

const Content = styled.div`
  flex: 80%;
  padding: 0 10px;
  margin-bottom: 20px;
`;

const Section = styled.div`
  margin-top: ${Space.sp3x};
`;

const LabelText = styled.span`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
`;
const WarningMessage = styled.span`
  padding: 16px 0;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`;

const Message = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
`;

const Label = styled.span`
  font-weight: 400;
  line-height: 24px;
  color: ${Text.primary};
  font-size: 16px;
  padding: 16px;
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

const Link = styled(A) <any>`
  color: ${Text.primary};

  &:hover {
    text-decoration: none;
  }
`;

const BreadcrumbContainer = styled(FlexboxContainer)`
  cursor: pointer;
  margin-left: ${Space.spHalf};
  margin-right: ${Space.spHalf};
  border-radius: ${BorderRadius.medium};

  &:hover {
    background: rgba(123, 97, 255, 0.05);
  }
`;

const WarningIcon = styled(Icon).attrs({ name: 'WarningBlack', size: 20 })`
  margin-right: ${Size.medium};
  margin-top: ${Size.tiny};
`;

const PrintIcon = styled(Icon).attrs({ name: 'Print', size: 30 })``;

interface Props {
  consignmentId: string;
  featureFlags: FeatureFlag;
};

const OrderDetails = ({ consignmentId, featureFlags }: Props) => {
  const { user } = useAuth();
  const path = usePath();
  const crumbs = useBreadcrumbs(path);
  const [queryParams, setQueryParams] = useQueryParams();
  const [previousUrl, setPreviousUrl] = useState('');
  const [consignmentDetailsResponse, setConsignmentDetailsResponse] = useState<Cons.ConsignmentDetails>(Cons.emptyConsignmentDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOnHoldOpen, setIsModalOnHoldOpen] = useState(false);
  const [isConfirmFulfillmentModalOpen, setIsConfirmFulfillmentModalOpen] = useState(false);
  const [salesOrderModalOpen, setSalesOrderModalOpen] = useState(false);
  const [salesOrderDetailsModalOpen, setSalesOrderDetailsModalOpen] = useState(false);
  const [salesOrderLoading, setSalesOrderLoading] = useState(false);
  const [salesOrderError, setSalesOrderError] = useState(false);
  const [salesOrderTimeout, setSalesOrderTimeout] = useState(false);
  const [salesOrderModalTimeout, setSalesOrderModalTimeout] = useState(false);
  const [isDispatchModalOpen, setIsDispatchModalOpen] = useState(false);
  const [isManufactureModalOpen, setIsManufactureModalOpen] = useState(false);
  const [isPickupModalOpen, setIsPickupModalOpen] = useState(false);
  const [isDelayModalOpen, setIsDelayModalOpen] = useState(false);
  const [isDelayedAcceptModalOpen, setIsDelayedAcceptModalOpen] = useState(false);
  const [isConfirmOrderModalOpen, setIsConfirmOrderModalOpen] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [shippingCarriers, setShippingCarriers] = useState<ShippingCarrier[]>([]);
  const [isFulfillmentConfirmModalOpen, setIsFulfillmentConfirmModalOpen] = useState(false);
  const [shippingConsignmentRequest, setShippingConsignmentRequest] = useState<Cons.ShippingConsignmentRequest>({
    shippingCarrier: {
      id: 0,
      name: ''
    },
    trackingId: '',
    deliveryDate: '',
    attachDocuments: false,
    rentalNewStartDate: '',
    rentalNewEndDate: ''
  });
  const isRental = consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.RENT;
  const [pickupConsignmentRequest, setPickupConsignmentRequest] = useState<Cons.PickupConsignmentRequest>({
    warehouseId: 0,
    referenceNumber: '',
    pickupDate: '',
    attachDocuments: false
  });
  const [dispatchRequest, setDispatchRequest] = useState<Cons.DispatchRequest>({
    dispatchDate: '',
    pickedUpBy: ''
  });
  const [fulfillmentRequest, setFulfillmentRequest] = useState<ConsignmentEntry[]>([]);
  const [isPreparationStep, setIsPreparationStep] = useState(false);
  const [isFulfillmentStep, setIsFulfillmentStep] = useState(false);
  const [isAwaitingDispatchStep, setIsAwaitingDispatchStep] = useState(false);
  const [isRentalStep, setIsRentalStep] = useState(false);
  const [isFulfillmentCompleteStep, setIsFulfillmentCompleteStep] = useState(false);
  const [isDelayedStep, setIsDelayedStep] = useState(false);
  const [isShipping, setIsShipping] = useState(false);
  const [cancellationEmailPopup, setCancellationEmailPopup] = useState(false);
  const isVendor = User.isVendor(user!);
  const [cancellationConsignmentPopup, setCancellationConsignmentPopup] = useState(false);
  const [consignmentApigeeCreditStatus, setConsignmentApigeeCreditStatus] = useState<string>();
  const [credit, setCredit] = useState<CreditInfo>();
  const [documents, setDocuments] = useState<any>({});
  const [salesOrderResult, setSalesOrderResult] = useState<any>({});
  const [orderDocuments, setOrderDocuments] = useState([]);
  const [showVendorDocuments, setShowVendorDocuments] = useState(false);
  const [openUpdateOrderModel, setOpenUpdateOrderModal] = useState(false);
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [openOptionalInfoSection, setOpenOptionalInfoSection] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [isInvalid, setIsInvalid] = useState(false);
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false);
  const [isPostOrderShippingCostSplitFormOpen, setIsPostOrderShippingCostSplitFormOpen] = useState(false);
  const [splitConsignments, setSplitConsignments] = useState<any>([]);
  const [liveUpdates, setLiveUpdates] = useState(false);
  const [additionalShippingCostRequest, setAdditionalShippingCostRequest] = useState<Cons.AdditionalShippingCostRequest>({
    shippingCarrier: {
      id: 0,
      name: ''
    },
    totalAdditionalShippingCost: '0',
    deliveryDate: '',
    shippingAdditionalInfo: ''
  });
  const [additionalShippingCostsValues, setAdditionalShippingCostsValues] = useState<Cons.SplitConsignmentShippingCostRequest[]>([]);
  const [splitConsignmentBody, setSplitConsignmentBody] = useState<any>({});
  const [originalDeliveryDate, setOriginalDeliveryDate] = useState<string>();
  const [originalReferenceNumber, setOriginalReferenceNumber] = useState<string>();
  const [rentalStartDate, setRentalStartDate] = useState<string | null>(null);
  const [rentalEndDate, setRentalEndDate] = useState<string | null>(null);
  const [reason, setReason] = useState('');
  const [loader, setLoader] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const socket = useContext(SocketContext);
  const [isReviewPage, setIsReviewPage] = useState(false);
  const [isReviewPageMounted, setIsReviewPageMounted] = useState(false);
  const [hiddenStates, setHiddenStates] = useState(HIDE_CANCEL_CONSIGNMENT_STATES);

  const cleanConsignmentApigeeCreditResult = () => setConsignmentApigeeCreditStatus(undefined);

  const handleConsignmentOnHoldBannerAccept = () => {
    setConsignmentApigeeCreditStatus(CONSIGNMENT_CREDIT_OVERRIDE);

    const { modificationEntry } = consignmentDetailsResponse;

    const isOnHoldWithRequestedModification = (actor: string) =>
      isRental &&
      consignmentDetailsResponse.state === ON_HOLD &&
      modificationEntry?.status === CONSIGNMENT_MODIFICATION_STATUS.REQUESTED &&
      modificationEntry?.requestedBy === actor;


    if (isOnHoldWithRequestedModification(CONSIGNMENT_MODIFICATION_ACTORS.VENDOR)) {
      onAcceptClick(MODIFIED_BY_VENDOR);
      return;
    }

    if (isOnHoldWithRequestedModification(CONSIGNMENT_MODIFICATION_ACTORS.BUYER)) {
      onAcceptClick(MODIFIED_BY_CUSTOMER);
      return;
    }

    if (isOnHoldWithRequestedModification(CONSIGNMENT_MODIFICATION_ACTORS.SYSTEM)) {
      onAcceptClick(MODIFIED_BY_CUSTOMER);
      return;
    }

    onAcceptClick(ORDER_SENT_TO_OEM);
  };

  const handleShowCancellationEmailPopup = () => setCancellationEmailPopup(true);
  const handleCloseCancellationEmailPopup = () => setCancellationEmailPopup(false);
  const handleShowCancellationConsignmentPopup = () => setCancellationConsignmentPopup(true);
  const handleCloseCancellationConsignmentPopup = () => setCancellationConsignmentPopup(false);

  const showConsignmentShortPaymentsBanner = Boolean(
    consignmentDetailsResponse.order.paymentType === 'PURCHASE_ORDER' &&
    consignmentDetailsResponse.vendorCompanyId &&
    (consignmentDetailsResponse.state === DELIVERED_PICKED_UP || consignmentDetailsResponse.state === SHIPPED || consignmentDetailsResponse.state === PICKED_UP)
  );

  const handleConsignmentCancellationConfirm = (consignmentCancelingReason: string) => {
    updateStateReq.run(consignmentId, CANCELING, consignmentCancelingReason, user?.role);
  };

  const isOrderFulfillmentButtonDisabled = fulfillmentRequest.some(
    (consignmentEntry: ConsignmentEntry) => {
      return (
        consignmentEntry.radioButtonValue &&
        (
          (consignmentEntry.radioButtonValue === 'instock' &&
            !consignmentEntry.isDeleted &&
            ((!consignmentEntry.committedDate && !consignmentEntry.preliminaryDeliveryDate) ||
              consignmentEntry.committedQuantity < 1 ||
              (consignmentEntry.rentalStartDate && consignmentEntry.rentalEndDate && consignmentEntry.rentalStartDate > consignmentEntry.rentalEndDate)
            )
          ) ||
          (consignmentEntry.radioButtonValue === 'manufacture' &&
            !consignmentEntry.isDeleted &&
            ((!consignmentEntry.manufactureDate && !consignmentEntry.preliminaryDeliveryDate) ||
              consignmentEntry.manufactureQuantity < 1 ||
              (consignmentEntry.rentalStartDate && consignmentEntry.rentalEndDate && consignmentEntry.rentalStartDate > consignmentEntry.rentalEndDate)
            )
          )
        )
      );
    }
  );

  const isDeliveryButtonDisabled =
    !shippingConsignmentRequest.shippingCarrier.name ||
    !shippingConsignmentRequest.trackingId ||
    !shippingConsignmentRequest.deliveryDate;

  const isPickupButtonDisabled =
    !pickupConsignmentRequest.referenceNumber ||
    !pickupConsignmentRequest.pickupDate;

  const navigateBack = previousUrl
    ? previousUrl
    : `/orders/page/${queryParams.ordersPage}`;

  const navigateBackQueryParams = previousUrl
    ? { activeTab: 1, ordersPage: parseInt(queryParams.ordersPage) }
    : {};

  const renderCancelingMessage = (cancelationReason: string) => {
    switch (cancelationReason) {
      case OFMP_CANCEL:
        return CANCELATION_REASON_MESSAGES.OFMP_CANCEL;
      case DJ_CANCEL:
        return CANCELATION_REASON_MESSAGES.DJ_CANCEL;
      case VENDOR_REJECT:
        return CANCELATION_REASON_MESSAGES.VENDOR_REJECT;
      case VENDOR_FREIGHT_COST_CANCEL:
        return CANCELATION_REASON_MESSAGES.VENDOR_FREIGHT_COST_CANCEL;
      case VENDOR_CREDIT_LIMIT_CANCEL:
        return CANCELATION_REASON_MESSAGES.VENDOR_CREDIT_LIMIT_CANCEL;
      case SYSTEM_CREDIT_CARD_FAILURE:
        return CANCELATION_REASON_MESSAGES.SYSTEM_CREDIT_CARD_FAILURE;
      case BUYER_DELAY_CANCEL:
        return CANCELATION_REASON_MESSAGES.BUYER_DELAY_CANCEL;
      case BUYER_FREIGHT_COST_TIMEOUT:
        return CANCELATION_REASON_MESSAGES.BUYER_FREIGHT_COST_TIMEOUT;
      case BUYER_FREIGHT_COST_CANCEL:
        return CANCELATION_REASON_MESSAGES.BUYER_FREIGHT_COST_CANCEL;
      case BUYER_GENERAL_CANCEL:
        return CANCELATION_REASON_MESSAGES.BUYER_GENERAL_CANCEL;
      default:
        return CANCELATION_REASON_MESSAGES.VENDOR_REJECT;
    }
  };

  const renderCancelingText = (cancelationReason: any) => {
    if ([OFMP_CANCEL, VENDOR_REJECT, BUYER_GENERAL_CANCEL].includes(cancelationReason.reasonCode)) {

      if (cancelationReason?.cancelationMessage) {
        return `Cancelation reason: ${cancelationReason?.cancelationMessage}`
      }
      else {
        return ``;
      }

    }
  };

  const onDeclineClick = () => {
    setIsModalOpen(true);
  };

  const onDeclinePostOrderShippingCostClick = () => {
    setIsDeclineModalOpen(true)
  };

  const onAcceptClick = (state: string) => {
    if (state === PROCESSING && consignmentDetailsResponse.consignmentEntries[0]?.brandVendor?.isStockApiEnabled) {
      getStocks.run(consignmentDetailsResponse);
    } else {
      updateStateReq.run(consignmentId, state);
      setShouldRedirect(false);
    }
  };

  const cancelConfirmFulfillmentModal = () => {
    setIsConfirmFulfillmentModalOpen(false);
  }

  const onModalClose = () => {
    setIsModalOpen(false);
    setIsFulfillmentConfirmModalOpen(false);
    setIsConfirmFulfillmentModalOpen(false);
    setIsDeclineModalOpen(false);
    setIsModalOnHoldOpen(false);
    setIsDispatchModalOpen(false);
    setIsManufactureModalOpen(false);
    setIsPickupModalOpen(false);
    setIsDelayModalOpen(false);
    setIsDelayedAcceptModalOpen(false);
    setIsConfirmOrderModalOpen(false);
    setIsPostOrderShippingCostSplitFormOpen(false);
    setSalesOrderModalOpen(false);
    setSalesOrderDetailsModalOpen(false);
    onDeliveryDateChange(originalDeliveryDate);
    onPickupDateChange(originalDeliveryDate);
  };

  const onUpdatePreparationClick = (attachDocuments: boolean, isConfirmed: boolean = true) => {
    setIsConfirmOrderModalOpen(false);
    const requestBody = isShipping
      ? { ...shippingConsignmentRequest, attachDocuments }
      : { ...pickupConsignmentRequest, attachDocuments };

    if (isShipping) {
      setShippingConsignmentRequest({ ...shippingConsignmentRequest, attachDocuments });
    } else {
      setPickupConsignmentRequest({ ...pickupConsignmentRequest, attachDocuments });
    }

    if (
      (consignmentDetailsResponse.availableDate && isShipping && shippingConsignmentRequest.deliveryDate && new Date(shippingConsignmentRequest.deliveryDate).setUTCHours(0, 0, 0, 0) > new Date(consignmentDetailsResponse.availableDate).setUTCHours(0, 0, 0, 0)) ||
      (consignmentDetailsResponse.availableDate && !isShipping && pickupConsignmentRequest.pickupDate && new Date(pickupConsignmentRequest.pickupDate).setUTCHours(0, 0, 0, 0) > new Date(consignmentDetailsResponse.availableDate).setUTCHours(0, 0, 0, 0))
    ) {
      setIsDelayModalOpen(true);
    } else {
      deliveryUpdateReq.run(consignmentId, requestBody, isConfirmed);
    }
  };

  const onConfirmFulfillmentModal = () => {
    if (fulfillmentRequest.length > 1 && consignmentDetailsResponse.totalAdditionalShippingCost) {
      onModalClose();
      setIsReviewPage(false);
      setIsReviewPageMounted(false)
      splitConsignmentReq.run(consignmentId, splitConsignmentBody);
    } else {
      onModalClose();
      setIsConfirmFulfillmentModalOpen(true);
    }
  }

  const fulfillmentModal = (
    <Modal
      showModal={isFulfillmentConfirmModalOpen}
      onClose={onModalClose}
      onGhostButtonClick={onModalClose}
      onClick={onConfirmFulfillmentModal}
      title="Estimated dates may be set wrong"
      primaryButtonText="Yes, proceed with the split"
      ghostButtonText="Cancel"
      theme='error'
    >
      <FlexboxContainer flexDirection='column' width='100%'>
        <WarningMessage>You have set the same estimated date for:</WarningMessage>
        <Message><LensRoundedIcon /> items which are in stock</Message>
        <Message><LensRoundedIcon /> and items which yet to be manufactured.</Message>
        <WarningMessage>Are you sure all dates are correct?</WarningMessage>
      </FlexboxContainer>
    </Modal>
  );

  let sumAdditionalShippingValuesItems = additionalShippingCostsValues.reduce((total, entry) => {
    return Number(total) + Number(entry.additionalShippingCost);
  }, 0);

  sumAdditionalShippingValuesItems = parseFloat(sumAdditionalShippingValuesItems?.toFixed(2)) || 0;


  const onConfirmFulfillmentClick = () => {
    const allConsignmentsAreDeleted = fulfillmentRequest.every((consignment) => consignment.isDeleted);

    if (fulfillmentRequest.length > 1 && !allConsignmentsAreDeleted) {
      if (consignmentDetailsResponse.totalAdditionalShippingCost) {
        if ((sumAdditionalShippingValuesItems > consignmentDetailsResponse.totalAdditionalShippingCost) || (sumAdditionalShippingValuesItems < consignmentDetailsResponse.totalAdditionalShippingCost)) {
          setIsInvalid(true);
          setErrorMessage(`sum of proposed split should always be equal to total. Current total: ${sumAdditionalShippingValuesItems} `)
        } else {
          setIsConfirmFulfillmentModalOpen(true);
          setIsInvalid(false);
        }

      } else {
        const foundCommittedEntry = fulfillmentRequest.find(entry => entry.committedDate);
        const foundManufactureEntry = fulfillmentRequest.find(entry => entry.manufactureDate);
        const isOrderFulfillmentDateSame = foundCommittedEntry?.committedDate === foundManufactureEntry?.manufactureDate;

        if (!foundCommittedEntry?.committedDate && !foundManufactureEntry?.manufactureDate) {
          setIsFulfillmentConfirmModalOpen(true);
        } else if (isOrderFulfillmentDateSame) {
          setIsFulfillmentConfirmModalOpen(true);
        } else {
          setIsConfirmFulfillmentModalOpen(true);
        }
      }
    } else {
      setIsConfirmFulfillmentModalOpen(true);
    }
  };

  const onConfirmDispatchClick = () => {
    if (isShipping) {
      setIsDispatchModalOpen(true);
    } else {
      setIsPickupModalOpen(true);
    }
  };

  const onConfirmManufacturingClick = () => {
    setIsManufactureModalOpen(true);
  };

  const onAcceptDelayClick = () => {
    setIsDelayedAcceptModalOpen(true);
  };

  const onViewPaymentStatus = () => {
    // TODO - go to payments page!!!
  };

  const onConfirmDeclineModalClick = (value: string) => {
    updateStateReq.run(consignmentId, CANCELING, value, user?.role);
    setShouldRedirect(false);
    onModalClose();
  };

  const onConfirmCancelModalClick = (value: string, reasonType?: string) => {
    updateStateReq.run(consignmentId, CANCELING, value, user?.role, null, reasonType);
    setShouldRedirect(false);
    onModalClose();
  };

  const handleOnHoldBannerDeclineConfirmation = (value: string) => {
    onConfirmCancelModalClick(value, VENDOR_CREDIT_LIMIT_CANCEL);
    setConsignmentApigeeCreditStatus(CONSIGNMENT_CREDIT_REJECTED);
  };

  const onContinueClick = (isConfirmed: boolean = true) => {
    if (documents && documents?.vendorFiles?.length > 0 && consignmentDetailsResponse.state === PACKING) {
      setIsConfirmOrderModalOpen(true);
    } else {
      const confirmed = typeof isConfirmed === 'boolean' ? isConfirmed : true;
      onUpdatePreparationClick(false, confirmed);
    }
  };

  const onShippingCarrier = (event: any) => {
    const foundShippingCarrier = shippingCarriers.find(shCarrier => shCarrier.name === event.target.value);

    if (foundShippingCarrier) {
      setAdditionalShippingCostRequest({ ...additionalShippingCostRequest, shippingCarrier: foundShippingCarrier });
    }
  };

  const onDeliveryDateSelect = (value: any) => {
    setAdditionalShippingCostRequest({ ...additionalShippingCostRequest, deliveryDate: value });
  };

  const onAdditionalShippingCostInput = (value: any) => {
    const parsedValue = value?.trim();
    setAdditionalShippingCostRequest({
      ...additionalShippingCostRequest,
      totalAdditionalShippingCost: !isNaN(parsedValue) ? parsedValue : '0'
    });
  }

  const onAdditionalShippingInfo = (value: string) => {
    if (value.length <= 500) {
      setAdditionalShippingCostRequest({ ...additionalShippingCostRequest, shippingAdditionalInfo: value });
      setErrorMessage('');
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
      setErrorMessage('Max 500 characters allowed');
    }
  }

  const onConfirmShippingCost = () => {
    additionalShippingCostUpdateReq.run(consignmentId, additionalShippingCostRequest);
    setShouldRedirect(false);
  }

  const onConfirmFulfillmentModalClick = () => {
    let newConsignmentEntries: ConsignmentEntry[] = fulfillmentRequest;

    for (let i = 0; i < newConsignmentEntries.length; i++) {
      const foundDeletedIndex = newConsignmentEntries.findIndex((entry, index) => index !== i && entry.id === newConsignmentEntries[i].id && entry.isDeleted);
      const foundEntryIndex = newConsignmentEntries.findIndex((entry, index) => index !== i && entry.id === newConsignmentEntries[i].id && !entry.isDeleted);

      if (foundDeletedIndex !== -1) {
        newConsignmentEntries[i].cancelQuantity = newConsignmentEntries[foundDeletedIndex].cancelQuantity;
        newConsignmentEntries[i].cancellationReason = newConsignmentEntries[foundDeletedIndex].cancellationReason;
        newConsignmentEntries = removeAtIndex(newConsignmentEntries, foundDeletedIndex);
      }

      // If items are split, we need to update the committed and manufacture dates for purchase or rent respectively
      if (foundEntryIndex !== -1) {

        if (consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.RENT) {
          newConsignmentEntries[i].committedQuantity = newConsignmentEntries[foundEntryIndex].committedQuantity > 0 ? newConsignmentEntries[foundEntryIndex].committedQuantity : newConsignmentEntries[i].committedQuantity;
          newConsignmentEntries[i].manufactureQuantity = newConsignmentEntries[foundEntryIndex].manufactureQuantity > 0 ? newConsignmentEntries[foundEntryIndex].manufactureQuantity : newConsignmentEntries[i].manufactureQuantity;
          newConsignmentEntries[i].cancellationReason = newConsignmentEntries[foundEntryIndex].cancellationReason ? newConsignmentEntries[foundEntryIndex].cancellationReason : newConsignmentEntries[i].cancellationReason;
          newConsignmentEntries[i].committedDate = newConsignmentEntries[foundEntryIndex].committedQuantity > 0 ? newConsignmentEntries[foundEntryIndex].rentalStartDate : newConsignmentEntries[i].rentalStartDate;
          newConsignmentEntries[i].committedEndDate = newConsignmentEntries[foundEntryIndex].committedQuantity > 0 ? newConsignmentEntries[foundEntryIndex].rentalEndDate : newConsignmentEntries[i].rentalEndDate;
          newConsignmentEntries[i].manufactureDate = newConsignmentEntries[foundEntryIndex].manufactureQuantity > 0 ? newConsignmentEntries[foundEntryIndex].rentalStartDate : newConsignmentEntries[i].rentalStartDate;
          newConsignmentEntries[i].manufactureEndDate = newConsignmentEntries[foundEntryIndex].manufactureQuantity > 0 ? newConsignmentEntries[foundEntryIndex].rentalEndDate : newConsignmentEntries[i].rentalEndDate;
        }

        if (consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.PURCHASE) {
          newConsignmentEntries[i].committedQuantity = newConsignmentEntries[foundEntryIndex].committedQuantity > 0 ? newConsignmentEntries[foundEntryIndex].committedQuantity : newConsignmentEntries[i].committedQuantity;
          newConsignmentEntries[i].committedDate = newConsignmentEntries[foundEntryIndex].committedQuantity > 0 ? newConsignmentEntries[foundEntryIndex].committedDate : newConsignmentEntries[i].committedDate;
          newConsignmentEntries[i].manufactureQuantity = newConsignmentEntries[foundEntryIndex].manufactureQuantity > 0 ? newConsignmentEntries[foundEntryIndex].manufactureQuantity : newConsignmentEntries[i].manufactureQuantity;
          newConsignmentEntries[i].manufactureDate = newConsignmentEntries[foundEntryIndex].manufactureQuantity > 0 ? newConsignmentEntries[foundEntryIndex].manufactureDate : newConsignmentEntries[i].manufactureDate;
          newConsignmentEntries[i].cancellationReason = newConsignmentEntries[foundEntryIndex].cancellationReason ? newConsignmentEntries[foundEntryIndex].cancellationReason : newConsignmentEntries[i].cancellationReason;
        }

        newConsignmentEntries = removeAtIndex(newConsignmentEntries, foundEntryIndex);
      }

      if (foundEntryIndex === -1) {

        if (consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.PURCHASE) {
          newConsignmentEntries[i].committedDate = newConsignmentEntries[i].committedQuantity > 0 ? newConsignmentEntries[i].committedDate : '';
          newConsignmentEntries[i].manufactureDate = newConsignmentEntries[i].manufactureQuantity > 0 ? newConsignmentEntries[i].manufactureDate : '';
        }

        if (consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.RENT) {
          newConsignmentEntries[i].rentalStartDate = newConsignmentEntries[i].rentalStartDate || '';
          newConsignmentEntries[i].rentalEndDate = newConsignmentEntries[i].rentalEndDate || '';
          newConsignmentEntries[i].committedDate = newConsignmentEntries[i].committedQuantity > 0 ? newConsignmentEntries[i].rentalStartDate : '';
          newConsignmentEntries[i].committedEndDate = newConsignmentEntries[i].committedQuantity > 0 ? newConsignmentEntries[i].rentalEndDate : '';
          newConsignmentEntries[i].manufactureDate = newConsignmentEntries[i].manufactureQuantity > 0 ? newConsignmentEntries[i].rentalStartDate : '';
          newConsignmentEntries[i].manufactureEndDate = newConsignmentEntries[i].manufactureQuantity > 0 ? newConsignmentEntries[i].rentalEndDate : '';
        }
      }
    }


    if (consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.RENT && additionalShippingCostsValues.length > 0) {
      additionalShippingCostsValues.forEach((asp) => {
        const findEntry = newConsignmentEntries?.find((entry) => asp?.entryNumbers?.includes(entry.entryNumber));
        if (findEntry) {
          asp.rentalStartDate = asp?.state === MANUFACTURING ? new Date(findEntry.manufactureDate).setUTCHours(0, 0, 0, 0) : new Date(findEntry.committedDate).setUTCHours(0, 0, 0, 0);
          asp.rentalEndDate = asp?.state === MANUFACTURING ? new Date(findEntry.manufactureEndDate!).setUTCHours(0, 0, 0, 0) : new Date(findEntry.committedEndDate!).setUTCHours(0, 0, 0, 0);
        }

        delete asp.entryNumbers;
      })
    }


    const consignmentEntriesWithAdditionalShippingValues = [newConsignmentEntries, additionalShippingCostsValues];

    const payload = {
      consignment: consignmentEntriesWithAdditionalShippingValues,
      consignmentType: consignmentDetailsResponse.consignmentType
    }

    fulfillmentReq.run(consignmentId, payload);
    onModalClose();
    setIsReviewPage(false);
    setIsReviewPageMounted(false)
  };

  const updates = liveUpdates
    ? (
      <Banner
        loadSpinner={true}
        title={`Order split in progress`}
        boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
        text='Please wait, this can take up to a minute.'
        bgColor={Background.white}
      />
    ) : null;


  const onConfirmSplitClick = () => {
    const foundCommittedEntry = fulfillmentRequest.find(entry => entry.committedDate || entry.committedQuantity);
    const foundManufactureEntry = fulfillmentRequest.find(entry => entry.manufactureDate || entry.manufactureQuantity);
    let entries: any = [];
    fulfillmentRequest.length > 0 && fulfillmentRequest.map((entry, i) => {
      let committedDate;
      let manufactureDate;

      if (entry.manufactureQuantity > 0) {
        manufactureDate = entry?.manufactureDate ? new Date(entry?.manufactureDate).setUTCHours(0, 0, 0, 0) : new Date(entry.preliminaryDeliveryDate).setUTCHours(0, 0, 0, 0);
        committedDate = 0;

      } else {
        committedDate = entry?.committedDate ? new Date(entry?.committedDate).setUTCHours(0, 0, 0, 0) : new Date(entry.preliminaryDeliveryDate).setUTCHours(0, 0, 0, 0);
        manufactureDate = 0;
      }

      let dd: any = {
        entryNumber: entry.entryNumber,
        quantity: entry.quantity,
        productId: entry?.productId,
        committedQuantity: entry?.committedQuantity ? entry?.committedQuantity : 0,
        manufactureQuantity: entry?.manufactureQuantity ? entry?.manufactureQuantity : 0,
        cancelQuantity: entry.cancelQuantity ? entry.cancelQuantity : 0,
        committedDate: committedDate,
        manufactureDate: manufactureDate,
      }

      // Update payload for rent
      if (consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.RENT) {
        dd = {
          ...dd,
          rentalStartDate: entry?.rentalStartDate ? new Date(entry.rentalStartDate).setUTCHours(0, 0, 0, 0) : 0,
          rentalEndDate: entry?.rentalEndDate ? new Date(entry.rentalEndDate).setUTCHours(0, 0, 0, 0) : 0,
          committedDate: entry?.committedQuantity > 0 ? new Date(entry.rentalStartDate).setUTCHours(0, 0, 0, 0) : 0,
          committedEndDate: entry?.committedQuantity > 0 ? new Date(entry.rentalEndDate).setUTCHours(0, 0, 0, 0) : 0,
          manufactureDate: entry?.manufactureQuantity > 0 ? new Date(entry.rentalStartDate).setUTCHours(0, 0, 0, 0) : 0,
          manufactureEndDate: entry?.manufactureQuantity > 0 ? new Date(entry.rentalEndDate).setUTCHours(0, 0, 0, 0) : 0,
        }
      }

      // check added for checking entry duplication
      const existed = entries.find((ent: any) => ent.productId === entry.productId)
      if (existed) {

        if (dd.committedQuantity > 0) {
          existed.committedQuantity = dd.committedQuantity;
          existed.committedDate = dd.committedDate;
          existed.committedEndDate = dd.committedEndDate;

        }

        if (dd.manufactureQuantity > 0) {
          existed.manufactureDate = dd.manufactureDate;
          existed.manufactureQuantity = dd.manufactureQuantity;
          existed.manufactureEndDate = dd.manufactureEndDate;
        }

        if (dd.cancelQuantity > 0) {
          existed.cancelQuantity = dd.cancelQuantity;
          existed.committedDate = dd.manufactureDate;
          existed.committedEndDate = dd.manufactureEndDate;
        }
      } else {
        entries.push(dd);
      }
    });

    const modifiedPayload = entries.map((item: any) => {
      return {
        entryNumber: item.entryNumber,
        quantity: item.quantity,
        committedQuantity: item.committedQuantity,
        manufactureQuantity: item.manufactureQuantity,
        cancelQuantity: item.cancelQuantity,
        committedDate: item.committedDate || item.rentalStartDate || 0,
        manufactureDate: item.manufactureDate,
        rentalStartDate: item.rentalStartDate || 0,
        rentalEndDate: item.rentalEndDate || 0,
        committedEndDate: item.committedEndDate || item.rentalEndDate || 0,
        manufactureEndDate: item.manufactureEndDate || 0
      }
    });

    const isOrderFulfillmentDateSame = foundCommittedEntry?.committedDate === foundManufactureEntry?.manufactureDate;
    const splitConsignmentBody = {
      availableDate: consignmentDetailsResponse.availableDate ? new Date(consignmentDetailsResponse.availableDate).getTime() : 0,
      consignmentId: consignmentDetailsResponse.consignmentId,
      state: consignmentDetailsResponse.state,
      entries: modifiedPayload
    }

    setSplitConsignmentBody(splitConsignmentBody);

    if (!foundCommittedEntry?.committedDate && !foundManufactureEntry?.manufactureDate) {
      setIsFulfillmentConfirmModalOpen(true);
    } else if (isOrderFulfillmentDateSame) {
      setIsFulfillmentConfirmModalOpen(true);
    } else {
      console.log("🚀 ~ onConfirmSplitClick ~ splitConsignmentBody:", splitConsignmentBody)
      splitConsignmentReq.run(consignmentId, splitConsignmentBody);
      setIsReviewPage(false);
      setIsReviewPageMounted(false)
    }
  };

  const onConfirmDispatchModalClick = () => {
    dispatchUpdateReq.run(consignmentId, dispatchRequest);
    setShouldRedirect(false);
    onModalClose();
  };

  const onConfirmDelayModalClick = () => {
    const requestBody = isShipping ? shippingConsignmentRequest : pickupConsignmentRequest;

    deliveryUpdateReq.run(consignmentId, requestBody);
    setShouldRedirect(false);
    onModalClose();
  };

  const onConfirmDelayAccept = (state: string) => {
    const statesToCheck = [PACKING, MANUFACTURING];

    if (!statesToCheck.includes(state)) {
      state = isShipping ? READY_TO_SHIP : READY_FOR_PICKUP;
    }
    updateStateReq.run(consignmentId, state);
    setShouldRedirect(false);
    onModalClose();
  };

  const onConfirmManufacture = (newAvailableDate: string | null = null, state: string = PACKING) => {
    const statesToCheck = [PACKING, MANUFACTURING, READY_FOR_PICKUP, READY_TO_SHIP]

    if (!statesToCheck.includes(state)) return;
    updateStateReq.run(consignmentId, state, null, null, null, null, newAvailableDate);
    setShouldRedirect(false);
    onModalClose();
  };

  const onShippingCarrierSelect = (event: any) => {
    const foundShippingCarrier = shippingCarriers.find(shCarrier => shCarrier.name === event.target.value);

    if (foundShippingCarrier) {
      setShippingConsignmentRequest({ ...shippingConsignmentRequest, shippingCarrier: foundShippingCarrier });
    }
  };

  const onTrackingIdChange = (value: string) => {
    value = value?.trim();
    setShippingConsignmentRequest({ ...shippingConsignmentRequest, trackingId: value });
  };



  const onDeliveryDateChange = (value: any, newRentalStartDate?: string, newRentalEndDate?: string) => {
    const state = { ...shippingConsignmentRequest, deliveryDate: value }
    if (newRentalStartDate) state['rentalNewStartDate'] = newRentalStartDate;
    if (newRentalEndDate) state['rentalNewEndDate'] = newRentalEndDate

    setShippingConsignmentRequest(state);

  };

  const onReferenceIdChange = (value: string) => {
    value = value?.trim()
    setPickupConsignmentRequest({ ...pickupConsignmentRequest, referenceNumber: value });
  };

  const onPickupDateChange = (value: any, newRentalStartDate?: string, newRentalEndDate?: string) => {
    const state = { ...pickupConsignmentRequest, pickupDate: value }
    if (newRentalStartDate) state['rentalNewStartDate'] = newRentalStartDate;
    if (newRentalEndDate) state['rentalNewEndDate'] = newRentalEndDate

    setPickupConsignmentRequest(state);
  };

  const onDispatchDateChange = (value: string) => {
    setDispatchRequest({ ...dispatchRequest, dispatchDate: value });
  };


  const onPickedUpByChange = (value: string) => {
    setDispatchRequest({ ...dispatchRequest, pickedUpBy: value });
  };

  const setPreparationStep = (consignment: Cons.ConsignmentDetails) => {
    const isPackingState = consignment.state === PACKING;
    const isRentConsignment = consignment.consignmentType === CONSIGNMENT_TYPES.RENT;
    const isPurchaseOrder = consignment.order?.paymentType === 'PURCHASE_ORDER';
    const wasPreviouslyProcessing = consignment.previousState === PROCESSING;

    if (isPackingState && isRentConsignment && !isPurchaseOrder) {
      setIsPreparationStep(!wasPreviouslyProcessing);
    } else {
      setIsPreparationStep(isPackingState);
    }
  };

  const consignmentReq = useAPI({
    deferFn: loadConsignment,
    onResolve: result => {
      setConsignmentDetailsResponse({ ...result, deliveryType: result.consignmentEntries[0].deliveryMode });
      setOriginalDeliveryDate(result.availableDate);
      setOriginalReferenceNumber(result.referenceNumber || result.consignmentId.substring(4));
      setFulfillmentRequest(result.consignmentEntries);
      setShippingConsignmentRequest({
        ...shippingConsignmentRequest,
        shippingCarrier: result.shippingCarrier || '',
        trackingId: result.trackingId || '',
        deliveryDate: result.availableDate,
        rentalNewStartDate: result?.modificationEntry?.newStartDate,
        rentalNewEndDate: result?.modificationEntry?.newEndDate
      });
      setPickupConsignmentRequest({
        ...pickupConsignmentRequest,
        referenceNumber: result.referenceNumber || result.consignmentId.substring(4),
        pickupDate: result.availableDate
      });
      setDispatchRequest({
        dispatchDate: result.dispatchDate || new Date().toString(),
        pickedUpBy: result.pickedUpBy?.trim() || parseRecipientName(result.pickupContact)
      });
      setPreparationStep(result);
      setIsFulfillmentStep(result.state === PROCESSING);
      setIsAwaitingDispatchStep(result.state === SHIPPED_READY_FOR_PICKUP || result.state === READY_FOR_PICKUP || result.state === READY_TO_SHIP);
      setIsRentalStep([RENT_IN_PROGRESS, RENT_RETURN_PENDING, RENT_COMPLETED, MODIFIED_BY_CUSTOMER, MODIFIED_BY_VENDOR, TICKET_IN_PROGRESS].includes(result.state));
      setIsFulfillmentCompleteStep(result.state === DELIVERED_PICKED_UP || result.state === SHIPPED || result.state === PICKED_UP);
      setIsDelayedStep(result.state === DELAYED);
      setIsShipping(result.consignmentEntries[0].deliveryMode === SHIPPING);

      if (result?.modificationEntry?.initiatedFrom === RENT_IN_PROGRESS) {
        setHiddenStates([...HIDE_CANCEL_CONSIGNMENT_STATES, MODIFIED_BY_CUSTOMER, MODIFIED_BY_VENDOR]);
      }
      else {
        setHiddenStates([...HIDE_CANCEL_CONSIGNMENT_STATES]);
      }



      const shouldShowVendorDocumentsDocuments = [
        MANUFACTURING,
        PACKING,
        PROCESSING,
        SHIPPED_READY_FOR_PICKUP,
        READY_TO_SHIP,
        READY_FOR_PICKUP,
        SHIPPED,
        PICKED_UP,
        DELIVERED_PICKED_UP,
        RENT_IN_PROGRESS,
        RENT_RETURN_PENDING
      ].includes(result.state);

      setShowVendorDocuments(shouldShowVendorDocumentsDocuments);

      if (result.vendorCompanyId) {
        getCompanyCreditReq.run(result.vendorId, result.vendorCompanyId);
      }
      if (result.salesOrderNumber) {
        getSalesOrderSingle.run(result.salesOrderNumber);
      }
      shippingCarriersReq.run(result.countryId)
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const updateConsignmentSalesOrder = useAPI({
    deferFn: updateSalesOrderNumber,
    onResolve: (result) => {
      setConsignmentDetailsResponse({ ...consignmentDetailsResponse, salesOrderNumber: result.salesOrderNumber });
      setSalesOrderLoading(false);
      onModalClose();
      if (salesOrderError) {
        setSalesOrderResult({});
      }
      setSalesOrderError(false);
      setSalesOrderLoading(false);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
      setSalesOrderResult({});
    }
  });

  const salesOrderUpdateFn = (salesOrderNumber: string) => {
    if (salesOrderNumber != "") {
      setSalesOrderLoading(true);
      getSalesOrder.run(salesOrderNumber);
    }
    else {
      saveSalesOrderNumber(salesOrderNumber);
    }
  }

  const saveSalesOrderNumber = (salesOrderNumber: string) => {
    setSalesOrderLoading(true);
    updateConsignmentSalesOrder.run(consignmentDetailsResponse.consignmentId, salesOrderNumber);
  }
  const getDocumentsReq = useAPI({
    deferFn: getDocumentsForConsignment,
    onResolve: result => {
      setDocuments(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const getSalesOrderSingle = useAPI({
    deferFn: getSalesOrderForConsignment,
    onResolve: (result) => {
      setSalesOrderResult(result[0]);
    },
    onReject: (err: any) => {
      if (err?.code != 404) {
        setSalesOrderTimeout(true);
      }
    },
  });

  const getSalesOrder = useAPI({
    deferFn: getSalesOrderForConsignment,
    onResolve: (result) => {
      renderToast(toast.TYPE.SUCCESS, 'Sales order data received');
      setSalesOrderResult(result[0]);
      setSalesOrderError(false);
      setSalesOrderTimeout(false);
      setSalesOrderModalTimeout(false);
      setSalesOrderLoading(false);
      onModalClose();
      updateConsignmentSalesOrder.run(
        consignmentDetailsResponse.consignmentId,
        result[0].salesOrder
      );
    },
    onReject: (err: any) => {
      setSalesOrderLoading(false);
      if (err?.code == 404) {
        setSalesOrderError(true);
      } else {
        setSalesOrderTimeout(true);
        setSalesOrderModalTimeout(true);
      }
    },
  });

  const getDocumentsForOrderReq = useAPI({
    deferFn: getDocumentsForOrder,
    onResolve: result => {
      setOrderDocuments(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const getCompanyCreditReq = useAPI({
    deferFn: getCompanyCredit,
    onResolve: (result: CreditInfo) => setCredit(result),
    onReject: err => renderToast(toast.TYPE.ERROR, err.message)
  });

  useEffect(() => {
    setPreviousUrl(queryParams.navigateBack);
    setQueryParams({ navigateBack: undefined });
    consignmentReq.run(consignmentId, user?.vendorId);
    getDocumentsReq.run(consignmentId);
  }, []);

  useEffect(() => {
    if (consignmentDetailsResponse.orderId) {
      getDocumentsForOrderReq.run(consignmentDetailsResponse.orderId);
    }
  }, [consignmentDetailsResponse.orderId]);

  const shippingCarriersReq = useAPI({
    deferFn: loadShippingCarriersByCountry,
    onResolve: (result) => setShippingCarriers(result)
  });

  const updateStateReq = useAPI({
    deferFn: updateState,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'The order state is updated successfully.');

      consignmentReq.reload();
      // if (shouldRedirect) {
      //   navigate(navigateBack, false, navigateBackQueryParams);
      // }
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const getStocks = useAPI({
    deferFn: callStockAPI,
    onResolve: (result: any) => {
      let IsFullFilled = true;
      let stockData: any = [];
      for (const data of result) {
        const cons = consignmentDetailsResponse.consignmentEntries.find((con) => con.id === data.consId);
        const requiredQuantity = cons?.quantity;
        if (data.openSalesOrderQty >= requiredQuantity!) {
        } else {
          IsFullFilled = false;
          stockData.push(data)
        }
      }
      if (IsFullFilled) {
        updateStateReq.run(consignmentId, PROCESSING_PACKING);
        setShouldRedirect(false);
      } else {
        updateStateReq.run(consignmentId, PROCESSING, null, null, stockData);
        setShouldRedirect(false);
      }
    },
    onReject: err => {
      // show info message
      renderToast(toast.TYPE.ERROR, stockAPIMessage);
      // By pass errors to run happy flow
      updateStateReq.run(consignmentId, PROCESSING);
      setShouldRedirect(false);
    }
  });

  const fulfillmentReq = useAPI({
    deferFn: updateFulfillment,
    onResolve: (result) => {

      renderToast(toast.TYPE.SUCCESS, 'The order details are updated successfully.');
      if (fulfillmentRequest.length > 1 && consignmentDetailsResponse.totalAdditionalShippingCost) {
        setLiveUpdates(true);
      } else if (fulfillmentRequest.length > 1 && consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.RENT && result.state === SPLIT_CONSIGNMENT) {
        setLiveUpdates(true);
        setIsFulfillmentStep(false);
      } else {
        consignmentReq.reload();
      }
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    socket.on('updateConsignment', () => {
      if (fulfillmentRequest.length > 1 && consignmentDetailsResponse.totalAdditionalShippingCost) {
        onConfirmFulfillmentClick();
      }
    })

    return () => {
      socket.off('updateConsignment', () => {
        consignmentReq.reload();
        setLiveUpdates(false);
        navigate(`/orders/order-details/${consignmentDetailsResponse.consignmentId?.slice(4)}`);
      });
    }
  }, [socket]);

  useEffect(() => {
    socket.on('updateConsignment', () => {

      // Reload consignment if payment is not paid when on manufacturing and packing state for non rental order
      if (consignmentDetailsResponse.order.paymentType !== 'PURCHASE_ORDER') {
        if ([MANUFACTURING, PACKING].includes(consignmentDetailsResponse.state) && consignmentDetailsResponse.paymentStatus === 'NOT_PAID' && !isRental) {
          consignmentReq.reload();
        }
      }

      if (consignmentDetailsResponse.state === RENT_COMPLETED && consignmentDetailsResponse.paymentStatus === PAYMENT_STATUS.NOT_PAID) {
        consignmentReq.reload();
      }
    });

    return () => {
      socket.off('updateConsignment', () => {
        if ([MANUFACTURING, PACKING].includes(consignmentDetailsResponse.state) && consignmentDetailsResponse.paymentStatus === 'PAID') {
          consignmentReq.reload();
        }
      });
    }
  }, [socket, consignmentDetailsResponse]);

  const splitConsignmentReq = useAPI({
    deferFn: emulateSplitConsignment,
    onResolve: (result) => {
      const splitedConsignments = result.map((splitConsignment: any) => {
        const newEntries = splitConsignment.entries.map((entry: any) => {
          const foundEntry = consignmentDetailsResponse.consignmentEntries.find(consEntry => consEntry.sku === entry.productSKU);
          const selectableAttributes = foundEntry && foundEntry.selectableAttributes;
          const attributeValues = foundEntry && foundEntry.attributeValues;
          const productImages = foundEntry && foundEntry.productImages;
          const priceBook = foundEntry?.priceBook;
          const pricebookId = foundEntry?.pricebookId;
          const pricebookName = foundEntry?.pricebookName;
          return { ...entry, selectableAttributes, attributeValues, productImages, priceBook, pricebookId, pricebookName }
        })
        return { ...splitConsignment, entries: newEntries }
      });

      const shippingCostsValues = result.map((consignment: any) => {
        const notCanceledEntry = result.filter((splitConsignment: any) => splitConsignment.state !== 'CANCELLING').length;

        if (consignment.state !== 'CANCELLING') {
          return {
            ...additionalShippingCostsValues,
            state: consignment.state,
            estimatedAvailableDate: consignment.availableDate || consignmentDetailsResponse.availableDate,
            additionalShippingCost: (consignmentDetailsResponse.totalAdditionalShippingCost / notCanceledEntry),
            entryNumbers: consignment.entries?.map((entry: any) => entry.entryNumber),
          }
        } else {
          return {
            ...additionalShippingCostsValues,
            state: consignment.state,
            estimatedAvailableDate: consignment.availableDate || consignmentDetailsResponse.availableDate,
            additionalShippingCost: 0,
            entryNumbers: consignment.entries?.map((entry: any) => entry.entryNumber),
          }
        }
      })

      setAdditionalShippingCostsValues(shippingCostsValues);

      setSplitConsignments(splitedConsignments);
      setIsFulfillmentStep(false);
      setIsPostOrderShippingCostSplitFormOpen(true);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const deliveryUpdateReq = useAPI({
    deferFn: updateDelivery,
    onResolve: result => {
      // const notificationMessage = result.state === DELAYED
      //   ? 'Delivery information has been updated. Customer will be notified by email.'
      //   : 'The order details are updated successfully.';
      const notificationMessage = 'Delivery information has been updated. Customer will be notified by email.'
      renderToast(toast.TYPE.SUCCESS, notificationMessage);
      consignmentReq.reload();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const additionalShippingCostUpdateReq = useAPI({
    deferFn: updateAdditionalShippingCost,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'The order details are updated successfully.');
      consignmentReq.reload();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const dispatchUpdateReq = useAPI({
    deferFn: updateDispatch,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'The order details are updated successfully.');

      consignmentReq.reload();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const cancellationOrderEmailReq = useAPI({
    deferFn: cancellationOrderEmailRequest,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Successfully sent');
      consignmentReq.reload();
    },
    onReject: (err: any) => renderToast(toast.TYPE.ERROR, err.message)
  });

  const overrideCompanyCreditApigeeReq = useAPI({
    deferFn: overrideCompanyCredit,
    onResolve: () => consignmentReq.reload(),
    onReject: (err: any) => renderToast(toast.TYPE.ERROR, err.message)
  });

  useEffect(() => {
    if (consignmentApigeeCreditStatus && updateStateReq.isResolved && consignmentDetailsResponse.vendorCompanyId) {
      overrideCompanyCreditApigeeReq.run(
        consignmentDetailsResponse.vendorId,
        consignmentDetailsResponse.vendorCompanyId,
        consignmentDetailsResponse.orderId,
        consignmentDetailsResponse.consignmentId,
        credit?.totalCreditLimit,
        credit?.availableCreditLimit,
        consignmentApigeeCreditStatus
      );

      cleanConsignmentApigeeCreditResult();
    }
  }, [updateStateReq, consignmentApigeeCreditStatus])

  useEffect(() => {
    const startDateChanged = formatDateTime(rentalStartDate!) !== formatDateTime(consignmentDetailsResponse.rentalStartDate);
    const endDateChanged = formatDateTime(rentalEndDate!) !== formatDateTime(consignmentDetailsResponse.rentalEndDate);

    const estDate = consignmentDetailsResponse.deliveryType === 'SHIPPING' ? shippingConsignmentRequest.deliveryDate : pickupConsignmentRequest.pickupDate;
    const carrier = shippingConsignmentRequest.shippingCarrier?.name;
    const trackingId = consignmentDetailsResponse.deliveryType === 'SHIPPING' ? shippingConsignmentRequest.trackingId : pickupConsignmentRequest.referenceNumber

    if ((rentalStartDate && rentalStartDate) && (startDateChanged || endDateChanged)) {
      const startDate = new Date(rentalStartDate!);
      const endDate = new Date(rentalEndDate!);
      const estimatedDate = new Date(estDate!);

      if (estimatedDate <= startDate && startDate <= endDate) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    } else if (estDate && trackingId) {
      if (consignmentDetailsResponse.deliveryType === 'SHIPPING') {
        if (carrier) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      } else {
        setIsDisabled(false);
      }
    } else {
      setIsDisabled(true);
    }
  }, [rentalStartDate, rentalEndDate, consignmentDetailsResponse, shippingConsignmentRequest, pickupConsignmentRequest, showModal]);


  const refreshConsignment = () => {
    consignmentReq.run(consignmentId, user?.vendorId);
  }

  const updateRentalStateReq = useAPI({
    deferFn: updateRentalState,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, TEXT_LABELS.ORDER_UPDATED_SUCCESSFULLY);
      refreshConsignment();
      setLoader(false);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
      setLoader(false);
    }
  });

  const handleRefresh = () => {
    setRentalStartDate(null);
    setRentalEndDate(null);
    setReason('');
    setShowModal(false);
    setShippingConsignmentRequest({
      ...shippingConsignmentRequest,
      shippingCarrier: consignmentDetailsResponse?.shippingCarrier || '',
      trackingId: consignmentDetailsResponse?.trackingId || '',
      deliveryDate: consignmentDetailsResponse?.availableDate,
      rentalNewStartDate: consignmentDetailsResponse?.modificationEntry?.newStartDate,
      rentalNewEndDate: consignmentDetailsResponse?.modificationEntry?.newEndDate
    });
    setPickupConsignmentRequest({
      ...pickupConsignmentRequest,
      referenceNumber: consignmentDetailsResponse?.referenceNumber || consignmentDetailsResponse?.consignmentId.substring(4),
      pickupDate: consignmentDetailsResponse?.availableDate
    });
  }

  const handleSubmit = () => {
    setShowModal(false);

    const rentalStart = toDate(rentalStartDate!);
    const rentalEnd = toDate(rentalEndDate!);
    const consignmentStart = toDate(consignmentDetailsResponse.rentalStartDate);
    const consignmentEnd = toDate(consignmentDetailsResponse.rentalEndDate);

    if (
      rentalStart &&
      rentalEnd &&
      (rentalStart.getTime() !== consignmentStart?.getTime() ||
        rentalEnd.getTime() !== consignmentEnd?.getTime())
    ) {
      setLoader(true);
      const trackingId = consignmentDetailsResponse.deliveryType === SHIPPING ? shippingConsignmentRequest.trackingId : pickupConsignmentRequest.referenceNumber;
      const estDate = consignmentDetailsResponse.deliveryType === SHIPPING ? shippingConsignmentRequest.deliveryDate : pickupConsignmentRequest.pickupDate;
      const carrier = shippingConsignmentRequest.shippingCarrier;
      updateRentalStateReq.run(consignmentDetailsResponse?.consignmentId, RENT_UPDATED, null, rentalStartDate, rentalEndDate, reason, null, null, trackingId, estDate, carrier);
    } else {
      if (consignmentDetailsResponse.state === PACKING) {
        onContinueClick(false);
      } else {
        isFulfillmentCompleteStep ? onViewPaymentStatus() : onConfirmOrderInfoUpdate();
      }
    }
  }

  useEffect(() => {
    handleRefresh();
  }, [consignmentDetailsResponse])

  const disableCancelConsignmentButton = () => {
    if (consignmentDetailsResponse?.consignmentType === PRICE_TYPE.RENT && (hiddenStates.includes(consignmentDetailsResponse.state))) {
      return true;
    } else if (consignmentDetailsResponse?.consignmentType === PRICE_TYPE.PURCHASE && [CANCELING, CANCELED, AWAITS_SHIPPING_APPROVAL, READY, READY_FOR_PICKUP, READY_TO_SHIP, AWAITS_SHIPPING, SHIPPED, PICKED_UP].includes(consignmentDetailsResponse.state)) {
      return true
    } else {
      return false;
    }
  }

  if (
    shippingCarriersReq.isLoading ||
    consignmentReq.isLoading ||
    updateStateReq.isLoading ||
    additionalShippingCostUpdateReq.isLoading ||
    fulfillmentReq.isLoading ||
    deliveryUpdateReq.isLoading ||
    dispatchUpdateReq.isLoading ||
    overrideCompanyCreditApigeeReq.isLoading ||
    getCompanyCreditReq.isLoading ||
    splitConsignmentReq.isLoading
  ) {
    return <LoadingIndicator />;
  }

  const modal = (
    <OrderCancelModal
      show={isModalOpen}
      onClose={onModalClose}
      onConfirm={onConfirmDeclineModalClick}
      title="Are you sure you want to decline this order?"
      label="Please, provide reason for declining"
      confirmButtonText="Confirm decline"
      cancelButtonText="Cancel"
    />
  );

  const sumCanceledItems = consignmentDetailsResponse.consignmentEntries.reduce((total, entry) => {
    return total + entry.quantity;
  }, 0);

  const declinePostOrderShippingCostModal = (
    <OrderCancelModal
      show={isDeclineModalOpen}
      onClose={onModalClose}
      onConfirm={onConfirmDeclineModalClick}
      title="Are you sure you want to decline this order?"
      theme='error'
      borderColor={Border.main}
      color={Shade.shade550}
      label={`${consignmentDetailsResponse.consignmentEntries[0] ? sumCanceledItems : ''} items will be canceled. This action can not be undone`}
      label2='Please, provide reason for declining'
      confirmButtonText="Confirm decline"
      cancelButtonText="Back to items"
    />
  );

  const confirmFulfillmentModal = (
    <Modal
      showModal={isConfirmFulfillmentModalOpen}
      onClose={cancelConfirmFulfillmentModal}
      onClick={onConfirmFulfillmentModalClick}
      title="Order fulfillment"
      primaryButtonText="Process order"
    >
      <FlexboxContainer>
        <Span>
          All changes are final and cannot be undone. Please confirm your action.
        </Span>
      </FlexboxContainer>
    </Modal>
  );

  const modalOnHold = (
    <OrderCancelModal
      show={isModalOnHoldOpen}
      onClose={onModalClose}
      onConfirm={handleOnHoldBannerDeclineConfirmation}
      title="Are you sure you want to cancel this order?"
      label="Please, provide reason for canceling"
      confirmButtonText="Confirm cancel"
      cancelButtonText="Back"
    />
  );

  const dispatchModal = (
    <Modal
      showModal={isDispatchModalOpen}
      onClose={onModalClose}
      onClick={onConfirmDispatchModalClick}
      title="Order dispatch"
      primaryButtonText="Confirm dispatch"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          All changes are final and cannot be undone and the buyer will be notified if action is confirmed.
        </Span>
      </FlexboxContainer>
    </Modal>
  );

  const onConfirmShippingUpdate = () => {
    setIsManufactureModalOpen(false);
    setIsDelayModalOpen(true);
  };

  const handleUpdateOrderInfoModalClose = () => {
    setOpenUpdateOrderModal(false);
    onDeliveryDateChange(originalDeliveryDate);
    onPickupDateChange(originalDeliveryDate);
    onReferenceIdChange(originalReferenceNumber!);
  }

  const onConfirmOrderInfoUpdate = () => {
    const requestBody = isShipping ? shippingConsignmentRequest : pickupConsignmentRequest;

    if (
      (isShipping && shippingConsignmentRequest.deliveryDate && new Date(shippingConsignmentRequest.deliveryDate).setUTCHours(0, 0, 0, 0) > new Date(consignmentDetailsResponse.availableDate).setUTCHours(0, 0, 0, 0)) ||
      (consignmentDetailsResponse.availableDate && !isShipping && pickupConsignmentRequest.pickupDate && new Date(pickupConsignmentRequest.pickupDate).setUTCHours(0, 0, 0, 0) > new Date(consignmentDetailsResponse.availableDate).setUTCHours(0, 0, 0, 0))
    ) {
      setOpenUpdateOrderModal(false)
      setIsDelayModalOpen(true);
    } else {
      deliveryUpdateReq.run(consignmentId, requestBody);
      setShouldRedirect(false);
      setOpenUpdateOrderModal(false);
    }
  };

  // generic delay modal
  const manufactureModal = (
    <ManufactureModal
      show={isManufactureModalOpen}
      onClose={onModalClose}
      onConfirmCancel={onConfirmCancelModalClick}
      onConfirmShippingUpdate={onConfirmShippingUpdate}
      onConfirmManufacture={onConfirmManufacture}
      title={`Indicate how to proceed due to ${(consignmentDetailsResponse.previousState === MANUFACTURING || consignmentDetailsResponse.state === MANUFACTURING) ? "manufacturing" : "the"} delay`}
      warningMessage=""
      confirmButtonText="Continue"
      shippingDate={consignmentDetailsResponse.availableDate}
      rentalStartDate={consignmentDetailsResponse.rentalStartDate}
      rentalEndDate={consignmentDetailsResponse.rentalEndDate}
      isRental={isRental}
      consignmentEntries={consignmentDetailsResponse.consignmentEntries}
      shippingConsignmentRequest={shippingConsignmentRequest}
      pickupConsignmentRequest={pickupConsignmentRequest}
      setShippingConsignmentRequest={setShippingConsignmentRequest}
      setPickupConsignmentRequest={setPickupConsignmentRequest}
      deliveryUpdateReq={deliveryUpdateReq}
      consignmentId={consignmentId}
      deliveryType={consignmentDetailsResponse.deliveryType}
      state={consignmentDetailsResponse.state!}
      previousState={consignmentDetailsResponse.previousState!}
    />
  );

  const pickupModal = (
    <Modal
      showModal={isPickupModalOpen}
      onClose={onModalClose}
      onClick={onConfirmDispatchModalClick}
      title="Order pickup"
      primaryButtonText="Confirm pickup"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          All changes are final and cannot be undone and the buyer will be notified if action is confirmed.
        </Span>
      </FlexboxContainer>
    </Modal>
  );

  const delayModal = (
    <Modal
      title={isShipping ? 'Confirm shipping update' : 'Confirm pickup update'}
      onClose={onModalClose}
      showModal={isDelayModalOpen}
      theme="accent"
      onClick={() => onConfirmDelayModalClick()}
      primaryButtonText="Confirm update"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          {`Due to update to the ${isShipping ? 'delivery' : 'ready for pick-up'} date customer will have 24 hours to cancel this order`}
        </Span>
      </FlexboxContainer>
    </Modal>
  );

  const delayAcceptModal = (
    <Modal
      showModal={isDelayedAcceptModalOpen}
      onClose={onModalClose}
      onClick={() => onConfirmDelayAccept(consignmentDetailsResponse.previousState)}
      title="Delayed order acceptance"
      primaryButtonText="Confirm acceptance"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          All changes are final and cannot be undone. Please confirm acceptance from the buyer.
        </Span>
      </FlexboxContainer>
    </Modal>
  );

  const newOrderButtons = (
    <FlexboxContainer
      flexDirection='row'
      justifyContent='flex-end'
      align-items="flex-end"
    >
      <Button
        isGhost
        theme='error'
        color={Main.error}
        margin={`0 ${Space.spHalf}`}
        onClick={onDeclineClick}
      >
        Decline order
      </Button>
      <Button
        disabled={getStocks.isLoading}
        onClick={() => {
          onAcceptClick(PROCESSING)
        }}
      >
        Accept order
      </Button>

    </FlexboxContainer>
  );

  const buttons = (
    <FlexboxContainer
      flexDirection='row'
      justifyContent='flex-end'
    >
      <Button
        isGhost
        borderColor={Shade.shade550}
        color={Background.navy}
        margin={`0 ${Space.spHalf}`}
        onClick={() => setIsModalOnHoldOpen(true)}
      >
        Decline order
      </Button>
      {
        !consignmentDetailsResponse.vendorCompanyId
          ? (
            <Button
              borderColor={Background.navy}
              bgColor={Background.navy}
              onClick={() => navigate(`/companies/company-details/${consignmentDetailsResponse.order.company && consignmentDetailsResponse.order.company.id}`)}
              disabled={!consignmentDetailsResponse.order.companyId}
            >
              Set up company relations
            </Button>
          )
          : (
            <>
              <Button
                onClick={handleConsignmentOnHoldBannerAccept}
                bgColor={Background.navy}
                borderColor={Background.navy}
              >
                Override credit deficit &amp; Accept order
              </Button>
              {
                !consignmentDetailsResponse.readOnly
                  ? (
                    <Button
                      onClick={() => navigate(`/companies/company-details/${consignmentDetailsResponse.order.company && consignmentDetailsResponse.order.company.id}`)}
                      margin={`0 0 0 ${Space.spHalf}`}
                      borderColor={Background.navy}
                      bgColor={Background.navy}
                    >
                      Raise company's credit
                    </Button>
                  )
                  : null
              }
            </>
          )
      }
    </FlexboxContainer>
  );

  const orderActions = consignmentDetailsResponse.state === ORDER_SENT_TO_OEM && consignmentDetailsResponse.order.customer.dowJonesStatus !== DOW_JONES_BLOCKED
    ? (
      <Banner
        bgColor={Background.white}
        boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
        title='New order'
        bannerHtml={
          <FlexboxContainer>
            All pre-order checks have been successfully completed.<br />If you are able to fullfill one or more line items please accept the order below.
          </FlexboxContainer>
        }
        buttons={newOrderButtons}
      />
    ) : null;

  const orderOnHoldAfterCheckingCreditActions = consignmentDetailsResponse.state === ON_HOLD && consignmentDetailsResponse.order.customer.dowJonesStatus !== DOW_JONES_BLOCKED
    ? (
      <FlexboxContainer flexDirection='column'>
        <Banner
          bgColor={Main.warning}
          title={!consignmentDetailsResponse.vendorCompanyId ? 'Automation found no credit' : 'Automation found insufficient credit'}
          text={!consignmentDetailsResponse.vendorCompanyId ? 'Setup required.' : ''}
          buttons={buttons}
        />
        {
          consignmentDetailsResponse.modificationEntry?.status === CONSIGNMENT_MODIFICATION_STATUS.REQUESTED &&
          <RentModifiedOnHold consignmentData={consignmentDetailsResponse} refreshConsignment={refreshConsignment} />
        }
      </FlexboxContainer>

    ) : null;

  const manufacturingDelayedButtons = (
    <FlexboxContainer
      flexDirection='row'
      justifyContent='flex-end'
    >
      {
        (consignmentDetailsResponse.previousState !== READY_FOR_PICKUP && consignmentDetailsResponse.previousState !== READY_TO_SHIP) &&
        <Button
          isGhost
          borderColor={Shade.shade550}
          color={Background.navy}
          onClick={onConfirmManufacturingClick}
          margin="0 10px 0 0"
        >
          {
            isShipping ? 'Update shipping info' : 'Update pickup info'
          }
        </Button>
      }

      <Button
        borderColor={Background.navy}
        bgColor={Background.navy}
        onClick={onAcceptDelayClick}
      >
        Confirm delayed order acceptance
      </Button>
    </FlexboxContainer>
  );

  const delayedActions = consignmentDetailsResponse.state === DELAYED
    ? (
      <Banner
        bgColor={Main.warning}
        title='Waiting for the customer to accept order delay'
        text='This order still within a 24-hour window for the buyer to cancel without any penalty.'
        text1={isShipping
          ? <>New estimated delivery date: <strong>{formatDateTime(consignmentDetailsResponse.availableDate)}</strong></>
          : <>Estimated delivery for pickup: <strong>{formatDateTime(consignmentDetailsResponse.availableDate)}</strong></>
        }
        text2={isRental && consignmentDetailsResponse?.oldRentalStartDate && consignmentDetailsResponse?.oldRentalEndDate
          ? <>Previous rent dates: <strong>{formatDateTime(consignmentDetailsResponse.oldRentalStartDate)}</strong> - <strong>{formatDateTime(consignmentDetailsResponse.oldRentalEndDate)}</strong></>
          : ''
        }
        text3={
          isRental && consignmentDetailsResponse?.rentalStartDate && consignmentDetailsResponse?.rentalEndDate
            ? <>{(consignmentDetailsResponse?.oldRentalStartDate && consignmentDetailsResponse?.oldRentalEndDate) ? 'New rent dates:' : 'Rent dates:'} <strong>{formatDateTime(consignmentDetailsResponse.rentalStartDate)}</strong> - <strong>{formatDateTime(consignmentDetailsResponse.rentalEndDate)}</strong></>
            : ''
        }
        buttons={manufacturingDelayedButtons}
      />
    ) : null;

  const orderCancelingActions = (
    [CANCELING, CANCELED].includes(consignmentDetailsResponse.state) &&
    consignmentDetailsResponse.order.customer.dowJonesStatus !== DOW_JONES_REVIEWED_BLOCKED &&
    (!isVendor && consignmentDetailsResponse.state === CANCELING && consignmentDetailsResponse.cancellationReason) ||
    (consignmentDetailsResponse.state === CANCELED && consignmentDetailsResponse.order.customer.dowJonesStatus !== DOW_JONES_REVIEWED_BLOCKED) ||
    (!isVendor && consignmentDetailsResponse.state === CANCELING && consignmentDetailsResponse.cancelationReason)
  )
    ? (
      <Banner
        titleColor={Background.white}
        bgColor={Main.error}
        title={renderCancelingMessage(consignmentDetailsResponse?.cancelationReason?.reasonCode)}
        text={renderCancelingText(consignmentDetailsResponse?.cancelationReason)}
        textAreaMarginBottom="0"
        color={Background.white}
      />
    )
    : null;

  const handleCancellationEmailConfirm = () => {
    cancellationOrderEmailReq.run(consignmentDetailsResponse.order.id);
  };

  const cancellationOrderEmailModal = (
    <OrderCancellationEmailModal
      show={cancellationEmailPopup}
      onClose={handleCloseCancellationEmailPopup}
      onConfirm={handleCancellationEmailConfirm} />
  );

  const modifyRentalDatesModal = (
    <ModifyEstDateWithRentaModal
      rentalEndDate={rentalEndDate}
      rentalStartDate={rentalStartDate}
      reason={reason}
      setReason={setReason}
      setRentalEndDate={setRentalEndDate}
      setRentalStartDate={setRentalStartDate}
      show={showModal}
      onClose={handleRefresh}
      onConfirm={handleSubmit}
      title={TEXT_LABELS.RENTAL_UPDATE}
      disabled={isDisabled}
      originalRentalStartDate={consignmentDetailsResponse.rentalStartDate}
      originalRentalEndDate={consignmentDetailsResponse.rentalEndDate}
      shippingCarriers={shippingCarriers}
      isShipping={consignmentDetailsResponse.deliveryType === 'SHIPPING'}
      carrier={shippingConsignmentRequest.shippingCarrier ? shippingConsignmentRequest.shippingCarrier.name : ''}
      setCarrier={onShippingCarrierSelect}
      trackingId={consignmentDetailsResponse.deliveryType === 'SHIPPING' ? shippingConsignmentRequest.trackingId : pickupConsignmentRequest.referenceNumber}
      setTrackingId={consignmentDetailsResponse.deliveryType === 'SHIPPING' ? onTrackingIdChange : onReferenceIdChange}
      estDate={consignmentDetailsResponse.deliveryType === 'SHIPPING' ? shippingConsignmentRequest.deliveryDate : pickupConsignmentRequest.pickupDate}
      setEstDate={consignmentDetailsResponse.deliveryType === 'SHIPPING' ? onDeliveryDateChange : onPickupDateChange}
      weekdaysOnly={consignmentDetailsResponse.weekdaysOnly!}
    />
  );

  const dowJonesBannerButton = (
    <Button
      borderColor={consignmentDetailsResponse.order.cancelEmailSentDate ? Main.error : Background.white}
      bgColor={consignmentDetailsResponse.order.cancelEmailSentDate ? Main.error : Background.white}
      color={consignmentDetailsResponse.order.cancelEmailSentDate ? Text.whitePrimary : `${Text.primary}`}
      onClick={handleShowCancellationEmailPopup}
      leftIcon={(size) => <Email style={{ fontSize: size }} />}
      disabled={!!consignmentDetailsResponse.order.cancelEmailSentDate}
    >
      {
        consignmentDetailsResponse.order.cancelEmailSentDate
          ? `Cancellation email sent at ${formatDateTime(consignmentDetailsResponse.order.cancelEmailSentDate, true)}`
          : 'Send Cancelation email to the customer'
      }
    </Button>
  );

  const dowJonesBanner = consignmentDetailsResponse.state === CANCELED && consignmentDetailsResponse.order.customer.dowJonesStatus === DOW_JONES_BLOCKED || consignmentDetailsResponse.order.customer.dowJonesStatus === DOW_JONES_REVIEWED_BLOCKED
    ? (
      <Banner
        bgColor={Main.error}
        title="This customer has failed OFMP compliance check"
        text="Order is canceled"
        color={Background.white}
        titleColor={Background.white}
        buttons={dowJonesBannerButton}
        textAreaMarginBottom="0"
        titleMarginBottom={Space.spBasePlus}
      />
    ) : null;

  const orderReadyActions = consignmentDetailsResponse.state === CHECKING_CREDIT && consignmentDetailsResponse.order.customer.dowJonesStatus !== DOW_JONES_BLOCKED
    ? (
      <BannerWraper>
        <Banner
          margin='0'
          bgColor={Background.white}
          title='Credit check is currently in progress'
          text="Please wait, this can take up to 1 minute. Upon completion of the credit check, you will be able to start the order processing."
          textAreaMarginBottom="0"
          titleMarginBottom={Space.spBasePlus}
        />
      </BannerWraper>
    ) : null;

  const isManufacturingDatePassed = consignmentDetailsResponse.state === MANUFACTURING && new Date().setUTCHours(0, 0, 0, 0) > new Date(consignmentDetailsResponse.availableDate).setUTCHours(0, 0, 0, 0);

  const orderManufacturingButtons = isManufacturingDatePassed
    ? (
      <FlexboxContainer
        flexDirection='row'
        justifyContent='flex-end'
      >
        <Button
          borderColor={Background.navy}
          bgColor={Background.navy}
          onClick={onConfirmManufacturingClick}
        >
          Provide new date / Cancel order
        </Button>
      </FlexboxContainer>
    )
    : (
      <FlexboxContainer
        flexDirection='row'
        justifyContent='flex-end'
      >
        <Button
          isGhost
          borderColor={isManufacturingDatePassed ? Shade.shade550 : Main.accent}
          color={isManufacturingDatePassed ? Background.navy : Main.accent}
          onClick={onConfirmManufacturingClick}
          margin="0 4px 0 0"
        >
          Provide new date / Cancel order
        </Button>
        <Button
          onClick={() => onConfirmManufacture(null, PACKING)}
        >
          Confirm manufacturing complete
        </Button>
      </FlexboxContainer>
    );

  const orderManufacturingActionsPayment = (
    <Banner
      loadSpinner={true}
      title='Processing payment'
      boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
      text='Please wait, this can take up to a minute.'
      bgColor={Background.white}
    />
  );

  const orderManufacturingActions = (
    <Banner
      boxShadow={isManufacturingDatePassed ? '' : '0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'}
      bgColor={isManufacturingDatePassed ? Main.warning : Background.white}
      title={
        isManufacturingDatePassed
          ? isShipping
            ? 'Manufacturing delay: estimated delivery date has passed'
            : 'Manufacturing delay: estimated ready for pickup date has passed'
          : 'Manufacturing'
      }
      text={
        isShipping
          ? `Estimated delivery ${formatDateTime(shippingConsignmentRequest.deliveryDate)}`
          : `Estimated ready for pickup ${formatDateTime(pickupConsignmentRequest.pickupDate)}`
      }
    >
      <FlexboxContainer width='100%' justifyContent='space-between' alignItems='flex-end'>
        {isRental ? <RentalDates consignmentData={consignmentDetailsResponse} datesFormat='yyyy MMM dd' showIcon={false} margin='0px' /> : <div></div>}
        {orderManufacturingButtons}
      </FlexboxContainer>
    </Banner>
  );

  const getManufacturingBanner = () => {
    if (consignmentDetailsResponse?.state === MANUFACTURING)
      if (consignmentDetailsResponse.order.paymentType !== 'PURCHASE_ORDER' &&
        consignmentDetailsResponse.paymentStatus === 'NOT_PAID' && !isRental) return orderManufacturingActionsPayment
      else return orderManufacturingActions
    return null;
  }

  const paymentBox = (
    <FlexboxContainer width="35%" justifyContent="flex-end" height="170px">
      <ConsignmentShortPaymentsBanner
        show={showConsignmentShortPaymentsBanner}
        consignment={consignmentDetailsResponse}
        reload={() => consignmentReq.reload()} />
    </FlexboxContainer>
  );

  const orderFulfilled = isFulfillmentCompleteStep
    ? (
      <Banner
        color={Background.white}
        titleColor={Background.white}
        bgColor={Main.success}
        title={isRental ? TEXT_LABELS.RENTAL_SHIPPED_MSG : TEXT_LABELS.PURCHASE_SHIPPED_MSG}
        text={isShipping ? 'For shipping tracking please use carrier services.' : ''}
        paymentBox={showConsignmentShortPaymentsBanner ? paymentBox : null}
        titleMarginBottom={Space.spBasePlus}
        textAreaMarginBottom={Space.spBasePlus}
      >
        <FlexboxContainer width='100%' justifyContent='space-between' alignItems="flex-start">
          <FlexboxContainer width='50%' flexWrap='wrap'>
            <OrderInformationTable
              color={Background.white}
              rowName1={isShipping ? 'Carrier' : 'Reference #'}
              rowValue1={isShipping ? shippingConsignmentRequest.shippingCarrier && shippingConsignmentRequest.shippingCarrier.name || '' : pickupConsignmentRequest.referenceNumber}
              rowName2={isShipping ? 'Tracking #' : 'Ready for pickup'}
              rowValue2={isShipping ? shippingConsignmentRequest.trackingId : formatDateTime(pickupConsignmentRequest.pickupDate)}
              rowName3={isShipping ? 'Dispatched' : 'Collection date'}
              rowValue3={formatDateTime(dispatchRequest.dispatchDate)}
              rowName4={isShipping ? 'Est. delivery' : 'Recepient name'}
              rowValue4={isShipping ? formatDateTime(shippingConsignmentRequest.deliveryDate) : dispatchRequest.pickedUpBy}
              padding="0"
            />
          </FlexboxContainer>
          {isRental ? <FlexboxContainer width='50%' flexWrap='wrap' margin='38px 0px 0px 0px'>
            <OrderInformationTable
              color={Background.white}
              rowName3={'Start Date'}
              rowValue3={formatDateTime(consignmentDetailsResponse.rentalStartDate)}
              rowName4={'End Date'}
              rowValue4={formatDateTime(consignmentDetailsResponse.rentalEndDate)}
              padding="0"
            />
          </FlexboxContainer> : ''}
        </FlexboxContainer>
      </Banner>
    ) : null

  const updateOrderInfoModal = (
    <Modal
      width='700px'
      left="30vw"
      showModal={openUpdateOrderModel}
      onClose={
        handleUpdateOrderInfoModalClose
      }
      onClick={onConfirmOrderInfoUpdate}
      title={
        isShipping ? 'Update shipping info' : 'Update pickup info'
      }
      disabled={(isShipping && !shippingConsignmentRequest.trackingId) || (!isShipping && !pickupConsignmentRequest.referenceNumber)}
    >
      {
        isShipping
          ? <>
            <FlexboxContainer
              flexDirection="row"
              width="100%"
              alignItems="flex-end"
              justifyContent="center"
            >
              <FlexboxContainer width="160px" margin={`0 ${Space.spHalf} 0 0`}>
                <Select
                  noBoxShadow
                  options={shippingCarriers.map(shCarrier => ({ value: shCarrier.name }))}
                  value={shippingConsignmentRequest.shippingCarrier ? shippingConsignmentRequest.shippingCarrier.name : ''}
                  defaultValue="Select carrier from the list"
                  onChange={onShippingCarrierSelect}
                  labelPosition='top'
                  label="Carrier"
                  width="100%"
                  height="36px"
                />
              </FlexboxContainer>
              <Input
                id="tracking-id"
                labelName="Tracking #"
                placeholder="Enter tracking number"
                value={shippingConsignmentRequest.trackingId}
                onChange={e => onTrackingIdChange(e.target.value)}
                width="224px"
                maxLength="50"
              />
              <FlexboxContainer flexDirection="column" width="150px" margin={`0 0 0 ${Space.spHalf}`}>
                <LabelText>Est. delivery</LabelText>
                <div onClick={(e) => { e.stopPropagation(); console.log("here") }}>
                  <DatePicker
                    value={
                      shippingConsignmentRequest.deliveryDate
                        ? new Date(shippingConsignmentRequest.deliveryDate).toUTCString()
                        : null
                    }
                    onChange={(value: any) => {
                      if (isRental) {
                        const { newRentalStartDate, newRentalEndDate } = recalculateRentalDates(value, consignmentDetailsResponse.rentalStartDate, consignmentDetailsResponse.rentalEndDate)
                        onDeliveryDateChange(new Date(value).toUTCString(), newRentalStartDate, newRentalEndDate)
                      }

                      if (!isRental) {
                        onDeliveryDateChange(new Date(value).toUTCString())
                      }
                    }}
                    inputVariant="outlined"
                    format="yyyy MMM dd"
                    TextFieldComponent={DatePickerInput}
                    autoOk
                  />
                </div>
              </FlexboxContainer>
            </FlexboxContainer>
            {
              isRental && <RentalNewDates consignmentData={consignmentDetailsResponse} shippingConsignmentRequest={shippingConsignmentRequest} />
            }
          </> : (<>

            <FlexboxContainer
              flexDirection='row'
              width='100%'
            >
              <FlexboxContainer width="100%" justifyContent="space-between" alignItems="center" padding="5px 0">
                <Input
                  id="reference-id"
                  labelName="Reference #"
                  placeholder="Enter reference number"
                  value={pickupConsignmentRequest.referenceNumber}
                  onChange={e => onReferenceIdChange(e.target.value)}
                  width="50%"
                  maxLength="50"
                />
                <FlexboxContainer flexDirection="column" width="50%" margin={`0 0 0 ${Space.spHalf}`}>
                  <LabelText>Ready for pickup</LabelText>
                  <DatePicker
                    value={
                      pickupConsignmentRequest.pickupDate
                        ? new Date(pickupConsignmentRequest.pickupDate).toUTCString()
                        : null
                    }
                    onChange={(value: any) => {

                      if (isRental) {
                        const { newRentalStartDate, newRentalEndDate } = recalculateRentalDates(value, consignmentDetailsResponse.rentalStartDate, consignmentDetailsResponse.rentalEndDate)
                        onPickupDateChange(new Date(value).toUTCString(), newRentalStartDate, newRentalEndDate)
                      }

                      if (!isRental) {
                        onPickupDateChange(new Date(value).toUTCString())
                      }
                    }}
                    inputVariant="outlined"
                    format="yyyy MMM dd"
                    TextFieldComponent={DatePickerInput}
                    autoOk
                    onClick={() => setShowModal(true)}
                  />
                </FlexboxContainer>
              </FlexboxContainer>


            </FlexboxContainer>

            {/* <FlexboxContainer width="100%" alignItems="center" padding="5px 0">
              <div>
                <WarningIcon />
              </div>
              <div>
                Please ensure you communicate any changes in the pickup date with the customer. The new pickup date will become the new rental start date, but the length of rental period will remain the same.
              </div>
            </FlexboxContainer> */}
          </>
          )
      }
    </Modal>
  );


  const awaitingDispatchActions = isAwaitingDispatchStep
    ? (
      <Banner
        boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
        bgColor={Background.white}
        title={isShipping ? 'Awaiting order dispatch' : 'Order is ready and awaits pickup'}
        text={loader ? TEXT_LABELS.PLEASE_WAIT : ''}
        loadSpinner={loader}
      >
        {
          !loader &&
          <>
            <ConfirmOrder
              onClick={() => consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.PURCHASE ? setOpenUpdateOrderModal(true) : setShowModal(true)}
              rowName1={isShipping ? 'Carrier' : 'Reference #'}
              rowName2={isShipping ? 'Tracking #' : 'Ready for pickup'}
              rowValue1={
                isShipping
                  ? shippingConsignmentRequest.shippingCarrier
                    ? shippingConsignmentRequest.shippingCarrier.name
                    : ''
                  : pickupConsignmentRequest.referenceNumber
              }
              rowValue2={
                isShipping
                  ? shippingConsignmentRequest.trackingId
                  : pickupConsignmentRequest.pickupDate
                    ? formatDateTime(pickupConsignmentRequest.pickupDate)
                    : ''
              }
              rowName3={
                isShipping
                  ? 'Est. delivery'
                  : ''
              }
              rowValue3={
                isShipping
                  ? formatDateTime(shippingConsignmentRequest.deliveryDate)
                  : ''
              }
            />
            <FlexboxContainer width='100%' justifyContent='space-between' >
              <FlexboxContainer width='80%'>
                <FlexboxContainer
                  flexDirection="column"
                  width="150px"
                  margin='0 4px 0 0'
                >
                  <LabelText>
                    {
                      isShipping ? 'Dispatch date' : 'Collection date'
                    }
                  </LabelText>
                  <DatePicker
                    value={
                      dispatchRequest.dispatchDate
                        ? new Date(dispatchRequest.dispatchDate).toUTCString()
                        : null
                    }
                    onChange={(value: any) => onDispatchDateChange(new Date(value).toUTCString())}
                    inputVariant="outlined"
                    format="yyyy MMM dd"
                    disabled={isFulfillmentCompleteStep}
                    TextFieldComponent={DatePickerInput}
                    autoOk
                  />
                </FlexboxContainer>
                {
                  !isShipping
                    ? (
                      <Input
                        id="recipient-name-id"
                        labelName="Recipient name"
                        placeholder="Enter recipent name"
                        value={dispatchRequest.pickedUpBy}
                        onChange={e => onPickedUpByChange(e.target.value)}
                        width="272px"
                        disabled={isFulfillmentCompleteStep}
                        readOnly={isFulfillmentCompleteStep}
                      />
                    ) : null
                }
              </FlexboxContainer>
              <FlexboxContainer
                justifyContent="flex-end"
                alignItems="flex-end"
                width="100%"
              >
                {isRental ?
                  <Button
                    onClick={() => setShowModal(true)}
                    className='mr-5'
                    isGhost
                    color={Main.accent}
                  >
                    {TEXT_LABELS.EDIT}
                  </Button>
                  : ''}

                <Button
                  onClick={isFulfillmentCompleteStep ? onViewPaymentStatus : onConfirmDispatchClick}
                >
                  {
                    isFulfillmentCompleteStep
                      ? 'View status of my payment'
                      : isShipping
                        ? 'Confirm order dispatch'
                        : 'Confirm order pickup'
                  }
                </Button>
              </FlexboxContainer>
            </FlexboxContainer>
          </>
        }
        <FlexboxContainer>
          {isRental ? <RentalDates consignmentData={consignmentDetailsResponse} datesFormat='yyyy MMM dd' isRental={isRental} /> : ''}
        </FlexboxContainer>
      </Banner>
    ) : null;

  const rentalSteps = isRentalStep ?
    <RentalOrderStates consignmentData={consignmentDetailsResponse} paymentStatus={consignmentDetailsResponse.paymentStatus} refreshConsignment={refreshConsignment} shippingCarriers={shippingCarriers} /> : null;

  const deliveryOrder = isShipping
    ? (
      <FlexboxContainer flexDirection="column">
        <ShippingCostRequiredItems
          deliveryDate={consignmentDetailsResponse.availableDate}
          subTotal={consignmentDetailsResponse.subTotal}
          consignmentEntries={consignmentDetailsResponse.consignmentEntries}
          isPreparationStep={isPreparationStep}
          totalTax={consignmentDetailsResponse.totalTax}
          totalSurcharges={consignmentDetailsResponse.totalSurcharges}
          totalDeliveryCost={consignmentDetailsResponse.totalDeliveryCost}
          totalPriceWithTax={consignmentDetailsResponse.totalPriceWithTax}
        />
      </FlexboxContainer>
    ) : null;

  const shippingDetailsButton = consignmentDetailsResponse.state === PACKING
    ? (
      <Button
        onClick={onContinueClick}
        disabled={isDeliveryButtonDisabled}
      >
        Confirm order ready to ship
      </Button>
    ) : (
      <Button
        onClick={onContinueClick}
        disabled={isDeliveryButtonDisabled}
      >
        Update shipping information
      </Button>
    );

  const pickupDetailsButton = consignmentDetailsResponse.state === PACKING ? (
    <Button
      onClick={onContinueClick}
      disabled={isPickupButtonDisabled}
    >
      Confirm order ready to pickup
    </Button>
  ) : (
    <Button
      onClick={onContinueClick}
      disabled={isPickupButtonDisabled}
    >
      Update pickup information
    </Button>
  )

  const pickupShippingDetails = (consignmentDetailsResponse.order.paymentType !== 'PURCHASE_ORDER' && consignmentDetailsResponse.paymentStatus === 'NOT_PAID' && consignmentDetailsResponse.consignmentType === 'PURCHASE')
    ? (
      <Banner
        loadSpinner={true}
        title='Processing payment'
        boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
        text='Please wait, this can take up to a minute.'
        bgColor={Background.white}
      />
    ) : isShipping ? (
      <Banner
        title='Preparing for shipment'
        text={loader ? TEXT_LABELS.PLEASE_WAIT : 'Shipping details below will be shared with the customer on submission.'}
        bgColor={Background.white}
        loadSpinner={loader}
        boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
      >
        {
          !loader &&
          <ShippingDetails
            shippingCarriers={shippingCarriers.map(shCarrier => ({ value: shCarrier.name }))}
            shippingCarrierValue={shippingConsignmentRequest.shippingCarrier ? shippingConsignmentRequest.shippingCarrier.name : ''}
            onChange={onShippingCarrierSelect}
            trackingId={shippingConsignmentRequest.trackingId}
            onTrackingIdChange={e => onTrackingIdChange(e.target.value)}
            deliveryDate={
              shippingConsignmentRequest.deliveryDate
                ? new Date(shippingConsignmentRequest.deliveryDate).toUTCString()
                : null
            }
            onDeliveryDateChange={(value: any) => onDeliveryDateChange(new Date(value).toUTCString())}
            button={shippingDetailsButton}
            isRental={isRental}
            consignmentData={consignmentDetailsResponse}
            setShowModal={setShowModal}
          />
        }
      </Banner>
    ) : (
      <Banner
        title='Preparing for pickup'
        boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
        text={loader ? TEXT_LABELS.PLEASE_WAIT : 'Pickup details below will be shared with the customer on submission.'}
        bgColor={Background.white}
        loadSpinner={loader}
      >
        {
          !loader &&
          <PickupDetails
            warehouseName={consignmentDetailsResponse.warehouse.name}
            referenceNumber={pickupConsignmentRequest.referenceNumber}
            consignmentId={consignmentDetailsResponse.consignmentId}
            pickupDate={
              pickupConsignmentRequest.pickupDate
                ? new Date(pickupConsignmentRequest.pickupDate).toUTCString()
                : null
            }
            onReferenceIdChange={e => onReferenceIdChange(e.target.value)}
            onPickupDateChange={(value: any) => onPickupDateChange(new Date(value).toUTCString())}
            button={pickupDetailsButton}
            isRental={isRental}
            consignmentData={consignmentDetailsResponse}
            setShowModal={setShowModal}
          />
        }
      </Banner>
    );

  const pickupOrder = !isShipping
    ? (
      <FlexboxContainer flexDirection="column">
        <ShippingCostRequiredItems
          deliveryDate={consignmentDetailsResponse.availableDate}
          subTotal={consignmentDetailsResponse.subTotal}
          consignmentEntries={consignmentDetailsResponse.consignmentEntries}
          isPreparationStep={isPreparationStep}
          totalTax={consignmentDetailsResponse.totalTax}
          totalSurcharges={consignmentDetailsResponse.totalSurcharges}
          totalPriceWithTax={consignmentDetailsResponse.totalPriceWithTax}
        />
      </FlexboxContainer>
    ) : null;

  const orderPreparationBanner = isPreparationStep
    ? (
      <FlexboxContainer margin='20px 0' flexDirection='column'>
        {
          pickupShippingDetails
        }
      </FlexboxContainer>
    ) : null;

  const salesOrderComponent = user && <SalesOrder isAdmin={User.userIsAllowed(user, [User.Role.Admin, User.Role.SuperAdmin, User.Role.VendorAdmin])} buttonClick={setSalesOrderModalOpen} openSalesOrderDetails={setSalesOrderDetailsModalOpen} salesOrderId={consignmentDetailsResponse.salesOrderNumber} orderRejectionStatus={salesOrderResult.orderRejectionStatus} deliveryStatus={salesOrderResult.deliveryStatus} isTimeout={salesOrderTimeout} />
  const orderPreparation = isPreparationStep
    ? (
      <FlexboxContainer
        flexDirection="column"
        margin="0 0 10px 0"
      >
        <Content>
          {
            isShipping ? deliveryOrder : pickupOrder
          }
        </Content>
        {featureFlags?.salesOrderCreation && featureFlags?.salesOrderCreation.enabled && salesOrderComponent}
        <Documents
          orderNumber={consignmentDetailsResponse.order.orderId}
          customerEmail={consignmentDetailsResponse.order.customer.email}
          consignmentId={consignmentDetailsResponse.consignmentId.slice(4)}
          vendorId={consignmentDetailsResponse.vendorId}
          documents={documents}
          orderDocuments={orderDocuments}
          showVendorDocuments={showVendorDocuments}
        />
      </FlexboxContainer>
    ) : null;

  const renderOrderDetailsTitle = (heading: string, padding = '') => {
    return (
      <FlexboxContainer padding={padding ? padding : '0px 16px 0px 13px'} justifyContent="space-between" alignItems="center">
        <SubHeading>{heading}</SubHeading>
      </FlexboxContainer>
    );
  };

  const orderProducts = !isPreparationStep && !isFulfillmentStep
    ? (
      <>
        {
          consignmentDetailsResponse.consignmentEntries.length > 0
            ? (
              <Section>
                {renderOrderDetailsTitle('Order items')}
                <ShippingCostRequiredItems
                  deliveryDate={consignmentDetailsResponse.availableDate}
                  subTotal={consignmentDetailsResponse.subTotal}
                  consignmentEntries={consignmentDetailsResponse.consignmentEntries}
                  totalTax={consignmentDetailsResponse.totalTax}
                  totalSurcharges={consignmentDetailsResponse.totalSurcharges}
                  totalDeliveryCost={consignmentDetailsResponse.totalDeliveryCost}
                  totalPriceWithTax={consignmentDetailsResponse.totalPriceWithTax}
                  state={consignmentDetailsResponse.state}
                />
              </Section>
            ) : null
        }
        {featureFlags?.salesOrderCreation && featureFlags?.salesOrderCreation.enabled && salesOrderComponent}
        <Documents
          orderNumber={consignmentDetailsResponse.order.orderId}
          customerEmail={consignmentDetailsResponse.order.customer.email}
          consignmentId={consignmentDetailsResponse.consignmentId.slice(4)}
          vendorId={consignmentDetailsResponse.vendorId}
          documents={documents}
          orderDocuments={orderDocuments}
          showVendorDocuments={showVendorDocuments}
        />
      </>
    ) : null;


  // Sort splitted consignments by state in descending order
  splitConsignments.sort((a: any, b: any) => {
    const stateOrder = [CANCELED, CANCELING, MANUFACTURING, PROCESSING];
    return stateOrder.indexOf(b.state) - stateOrder.indexOf(a.state);
  });

  const postOrderShippingCostSplitForm = isPostOrderShippingCostSplitFormOpen
    ? (
      <Banner
        bgColor={Background.white}
        boxShadow={'0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'}
      >
        {renderOrderDetailsTitle('Distribute shipping cost', '0px')}
        {
          splitConsignments.map((cons: any) => {

            return isRental ? (
              <SplitPostOrderShippingCostRent
                totalShippingCost={consignmentDetailsResponse.totalAdditionalShippingCost}
                isInvalid={isInvalid}
                errorMessage={errorMessage}
                setAdditionalShippingCostsValues={setAdditionalShippingCostsValues}
                additionalShippingCostsValues={additionalShippingCostsValues}
                consignmentId={cons.consignmentId.slice(4)}
                state={cons.state}
                deliveryCost={cons.deliveryCost}
                deliveryDate={cons.availableDate}
                consignmentEntries={cons.entries}
                isRental={isRental}
                splitConsignmentBody={splitConsignmentBody}
              />
            ) :
              (
                <SplitPostOrderShippingCost
                  totalShippingCost={consignmentDetailsResponse.totalAdditionalShippingCost}
                  isInvalid={isInvalid}
                  errorMessage={errorMessage}
                  setAdditionalShippingCostsValues={setAdditionalShippingCostsValues}
                  additionalShippingCostsValues={additionalShippingCostsValues}
                  consignmentId={cons.consignmentId.slice(4)}
                  state={cons.state}
                  deliveryCost={cons.deliveryCost}
                  deliveryDate={cons.availableDate}
                  consignmentEntries={cons.entries}
                />
              )
          })
        }


        <FlexboxContainer margin="20px 0 20px 0" justifyContent="flex-end" alignItems="flex-end">
          <Button
            isGhost
            theme='disabled'
            color={Shade.shade550}
            margin={`0 ${Space.spHalf}`}
            onClick={() => {
              // navigate(navigateBack, false, navigateBackQueryParams)
              setIsFulfillmentStep(true);
              setIsInvalid(false);
              setIsPostOrderShippingCostSplitFormOpen(false);
              setAdditionalShippingCostsValues([]);
              // Scroll to top
              window.scrollTo(0, 0)
            }
            }
          >
            Back
          </Button>
          <Button
            onClick={onConfirmFulfillmentClick}
            disabled={isOrderFulfillmentButtonDisabled || splitConsignmentReq.isLoading}
          >
            Confirm
          </Button>
        </FlexboxContainer>
      </Banner>

    ) : null;



  const fulfillmentTable = isFulfillmentStep
    ? (
      <Banner
        bgColor={Background.white}
        boxShadow={'0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'}
      >

        <OrderFulfillmentTable
          fulfillmentRequest={fulfillmentRequest}
          setFulfillmentRequest={setFulfillmentRequest}
          renderOrderDetailsTitle={renderOrderDetailsTitle}
          deliveryMode={consignmentDetailsResponse.consignmentEntries[0].deliveryMode}
          consignmentType={consignmentDetailsResponse.consignmentType}
          consignmentDetailsResponse={consignmentDetailsResponse}
          setIsReviewPage={setIsReviewPage}
          isReviewPage={isReviewPage}
          setIsReviewPageMounted={setIsReviewPageMounted}
          weekdaysOnly={consignmentDetailsResponse.weekdaysOnly}
          isOrderFulfillmentButtonDisabled={isOrderFulfillmentButtonDisabled}
        />

        {!isReviewPage && <>
          <FlexboxContainer margin="20px 0 20px 0" justifyContent="flex-end" alignItems="flex-end">
            <Button
              onClick={fulfillmentRequest.length > 1 && consignmentDetailsResponse.totalAdditionalShippingCost ? onConfirmSplitClick : onConfirmFulfillmentClick}
              disabled={isOrderFulfillmentButtonDisabled}
            >
              Confirm
            </Button>
          </FlexboxContainer>
        </>
        }


        {isReviewPage && isReviewPageMounted && <FlexboxContainer margin="20px 0 20px 0" justifyContent="flex-end" alignItems="flex-end">

          <Button
            isGhost
            margin={`0 ${Space.spHalf}`}
            color={Main.accent}
            onClick={() => {
              setIsReviewPage(false)
              setIsReviewPageMounted(false)
              // Scroll to top
              window.scrollTo(0, 0)
            }
            }
          >
            Back
          </Button>


          <Button
            onClick={fulfillmentRequest.length > 1 && consignmentDetailsResponse.totalAdditionalShippingCost ? onConfirmSplitClick : onConfirmFulfillmentClick}
            disabled={isOrderFulfillmentButtonDisabled}
          >
            Confirm
          </Button>
        </FlexboxContainer>
        }
      </Banner>
    ) : null;

  const orderFulfillmentTable = isFulfillmentStep
    ? (
      <FlexboxContainer
        flexDirection="column"
        margin="0 0 10px 0">
        {featureFlags?.salesOrderCreation && featureFlags?.salesOrderCreation.enabled && salesOrderComponent}
        <Documents
          orderNumber={consignmentDetailsResponse.order.orderId}
          customerEmail={consignmentDetailsResponse.order.customer.email}
          consignmentId={consignmentDetailsResponse.consignmentId.slice(4)}
          vendorId={consignmentDetailsResponse.vendorId}
          documents={documents}
          orderDocuments={orderDocuments}
          showVendorDocuments={showVendorDocuments}
        />
      </FlexboxContainer>
    ) : null;

  const {
    displayState,
  } = getSteps(
    consignmentDetailsResponse.state,
    [consignmentDetailsResponse.consignmentEntries[0]?.deliveryMode],
    isVendor,
    consignmentDetailsResponse.order.customer.dowJonesStatus!,
    featureFlags
  );

  const pillTheme = [READY, ORDER_SENT_TO_OEM, MANUFACTURING, PROCESSING, READY_TO_SHIP, READY_FOR_PICKUP, SHIPPED_READY_FOR_PICKUP, SHIPPED, PICKED_UP, DELIVERED_PICKED_UP, PACKING, AWAITS_SHIPPING, RENT_IN_PROGRESS, RENT_COMPLETED].includes(consignmentDetailsResponse.state) && consignmentDetailsResponse.order.customer.dowJonesStatus !== DOW_JONES_BLOCKED
    ? 'success'
    : [ON_HOLD, DELAYED, RENT_RETURN_PENDING, MODIFIED_BY_CUSTOMER, MODIFIED_BY_VENDOR, RENT_UPDATED].includes(consignmentDetailsResponse.state) || (!isVendor && consignmentDetailsResponse.order.customer.dowJonesStatus === DOW_JONES_BLOCKED)
      ? 'warning'
      : [AWAITS_SHIPPING_APPROVAL, CHECKING_CREDIT].includes(consignmentDetailsResponse.state) || (isVendor && consignmentDetailsResponse.order.customer.dowJonesStatus === DOW_JONES_BLOCKED)
        ? Background.navy
        : 'danger';

  // const sapPillTheme = consignmentDetailsResponse.syncToSap === STS_SUCCESSFUL ? 'success' : consignmentDetailsResponse.syncToSap === STS_PENDING ? 'warning' : 'danger';


  const pillThemeSplitting = liveUpdates ? Background.navy : ([MANUFACTURING, PACKING, RENT_COMPLETED].includes(consignmentDetailsResponse.state) && consignmentDetailsResponse.order.paymentType !== 'PURCHASE_ORDER' && consignmentDetailsResponse.paymentStatus === PAYMENT_STATUS.NOT_PAID && ([RENT_COMPLETED].includes(consignmentDetailsResponse.state))) ? Background.navy : consignmentDetailsResponse.paymentStatus === 'FAILED' && (!isRental || [RENT_COMPLETED].includes(consignmentDetailsResponse.state)) ? Background.red : pillTheme

  const pill = <Pill margin="0 0 20px 16px" theme={pillThemeSplitting} bgColor={pillThemeSplitting} size="xsPlus" padding="4px">{liveUpdates ? 'SPLITTING' : ([MANUFACTURING, PACKING, RENT_COMPLETED].includes(consignmentDetailsResponse.state) && consignmentDetailsResponse.order.paymentType !== 'PURCHASE_ORDER' && consignmentDetailsResponse.paymentStatus === PAYMENT_STATUS.NOT_PAID && (!isRental || [RENT_COMPLETED].includes(consignmentDetailsResponse.state))) ? 'CHARGING' : consignmentDetailsResponse.paymentStatus === 'FAILED' && ([RENT_COMPLETED].includes(consignmentDetailsResponse.state)) ? 'PAYMENT FAILED' : displayState}</Pill>;
  /* const sapPill = consignmentDetailsResponse.syncToSap ? (
    <Pill margin="0 0 20px 6px" theme={sapPillTheme} bgColor={sapPillTheme} size="xsPlus" padding="4px">{consignmentDetailsResponse.syncToSap === STS_SUCCESSFUL ? SYNC_RESPONSES.SUCCESSFUL : consignmentDetailsResponse.syncToSap === STS_PENDING ? SYNC_RESPONSES.PENDING : SYNC_RESPONSES.FAILED}</Pill>) : null; */

  const creditCheck = consignmentDetailsResponse.order.paymentType === 'PURCHASE_ORDER' && consignmentDetailsResponse.order.customer.dowJonesStatus !== DOW_JONES_BLOCKED
    ? retrieveConsignmentCreditCheckStatusText(consignmentDetailsResponse)
    : ' ';

  const orderInformation = isShipping
    ? (
      <OrderDetailsInformation
        credit={credit}
        creditCheck={creditCheck}
        show={true}
        showShippingInformation={true}
        customer={consignmentDetailsResponse.order.customer}
        companyName={
          consignmentDetailsResponse.order.company
            ? consignmentDetailsResponse.order.companyName
            : ''
        }
        newCompanyName={
          consignmentDetailsResponse.order.company && consignmentDetailsResponse.order.company.companyHistoryNameCount > 0 && consignmentDetailsResponse.order.companyName !== consignmentDetailsResponse.order.company.companyName
            ? consignmentDetailsResponse.order.company.companyName
            : ''
        }
        paymentMethod={consignmentDetailsResponse.order.paymentType}
        taxExempt={consignmentDetailsResponse.totalTax > 0 ? 'No' : 'Yes'}
        warehouse={consignmentDetailsResponse ? consignmentDetailsResponse.warehouse.name : ''}
        deliveryAddress={
          consignmentDetailsResponse.deliveryAddress
            ? capitalize(`${consignmentDetailsResponse.deliveryAddress.addressLine1 ? consignmentDetailsResponse.deliveryAddress.addressLine1 + ',' : ''} 
                          ${consignmentDetailsResponse.deliveryAddress.addressLine2 ? consignmentDetailsResponse.deliveryAddress.addressLine2 + ',' : ''} 
                          ${consignmentDetailsResponse.deliveryAddress.town ? consignmentDetailsResponse.deliveryAddress.town + ',' : ''} 
                          ${consignmentDetailsResponse.deliveryAddress.state ? consignmentDetailsResponse.deliveryAddress.state + ',' : ''} 
                          ${consignmentDetailsResponse.deliveryAddress.postalCode || ''}`) +
            `${consignmentDetailsResponse.deliveryAddress.countryIsoCode && ', ' + getCountryName(consignmentDetailsResponse.deliveryAddress.countryIsoCode)}`
            : ''
        }
        pickupContactDetails={consignmentDetailsResponse ? stringifyPickupContact(consignmentDetailsResponse.pickupContact) : ''}
      />
    ) : (
      <OrderDetailsInformation
        creditCheck={creditCheck}
        credit={credit}
        show={true}
        showShippingInformation={false}
        customer={consignmentDetailsResponse.order.customer}
        companyName={
          consignmentDetailsResponse.order.company
            ? consignmentDetailsResponse.order.companyName
            : ''
        }
        newCompanyName={
          consignmentDetailsResponse.order.company && consignmentDetailsResponse.order.company.companyHistoryNameCount > 0 && consignmentDetailsResponse.order.companyName !== consignmentDetailsResponse.order.company.companyName
            ? consignmentDetailsResponse.order.company.companyName
            : ''
        }
        paymentMethod={consignmentDetailsResponse.order.paymentType}
        taxExempt={consignmentDetailsResponse.totalTax > 0 ? 'No' : 'Yes'}
        pickupLocation={
          consignmentDetailsResponse.consignmentEntries[0]
            ? consignmentDetailsResponse.consignmentEntries[0].pointOfServiceLocation
            : ''
        }
        warehouse={consignmentDetailsResponse ? consignmentDetailsResponse.warehouse.name : ''}
        pickupContactDetails={consignmentDetailsResponse ? stringifyPickupContact(consignmentDetailsResponse.pickupContact) : ''}
      />
    );

  const cancellationConsignmentModal = (
    <ConsignmentCancelallationModal
      show={cancellationConsignmentPopup}
      onClose={handleCloseCancellationConsignmentPopup}
      onConfirm={handleConsignmentCancellationConfirm}
      title='Canceling consignment'
      labels={['Please provide a cancelation reason which will be sent as a part of the “Cancelation notification” email to both Customer and Vendor']}
      textareaPlaceholder='Unfortunately, we are forced to cancel these items due to...'
    />
  );

  const confirmOrderModal = (
    <Modal
      showModal={isConfirmOrderModalOpen}
      onClose={onModalClose}
      onGhostButtonClick={() => onUpdatePreparationClick(false)}
      onClick={() => onUpdatePreparationClick(true)}
      title="Include all attached documents into notification email sent to the customer?"
      primaryButtonText="Include"
      ghostButtonText="Send email without documents"
    >
      <></>
    </Modal>
  );

  const dowJonesBlockedAdminButton = consignmentDetailsResponse.order.customer.dowJonesStatus === DOW_JONES_BLOCKED || consignmentDetailsResponse.state === DOW_JONES_FAILED
    ? (
      <Button
        borderColor={Main.accent}
        onClick={() => navigate(`/customers/customer-details/${consignmentDetailsResponse.order.customer.id}`, false, { activeTab: 1 })}
      >
        Review customer's compliance status
      </Button>
    )
    : null;

  const consignmentDowJonesBaner = [NEW, READY, ORDER_SENT_TO_OEM, CHECKING_CREDIT, ON_HOLD].includes(consignmentDetailsResponse.state) && consignmentDetailsResponse.order.customer.dowJonesStatus === DOW_JONES_BLOCKED
    ? (
      isVendor
        ? (
          <BannerWraper>
            <Banner
              margin='0'
              title='The order is in OFMP compliance check status'
              text='This processing can take a few days, you will be notified of the status update by email.'
              bgColor={Background.white}
              textAreaMarginBottom="0"
              titleMarginBottom={Space.spBasePlus}
            />
          </BannerWraper>
        ) : (
          <Banner
            title='The customer has failed the Dow Jones compliance check and requires a review'
            text='Till then this order will stay on hold'
            bgColor={Main.warning}
            buttons={dowJonesBlockedAdminButton}
            textAreaMarginBottom="0"
            titleMarginBottom={Space.spBasePlus}
          />
        )
    ) : null;

  const updateOptionalInfo = (checked: any) => {
    if (checked) {
      setCheckboxValue(true);
      setOpenOptionalInfoSection(true);
    } else {
      setCheckboxValue(false);
      setOpenOptionalInfoSection(false);
    }
  }

  const consignmentShippingCostApproval = consignmentDetailsResponse.state === AWAITS_SHIPPING_APPROVAL ? (
    <BannerWraper>
      <Banner
        margin='0'
        title='The order is sent to the customer for the shipping cost approval'
        text={`Sent on ${formatDateTime(consignmentDetailsResponse.updated, true)}`}
        text1='You will be notified of the status update by email.'
        textMargin='12px'
        bgColor={Background.white}
        textAreaMarginBottom="0"
        titleMarginBottom={Space.spBasePlus}
      />
    </BannerWraper>
  ) : null

  const consignmentDowJones = consignmentDetailsResponse.state === DOW_JONES_FAILED
    ? (
      isVendor
        ? (
          <BannerWraper>
            <Banner
              margin='0'
              title='The order is in OFMP compliance check status'
              text='This processing can take a few days, you will be notified of the status update by email.'
              bgColor={Background.white}
              textAreaMarginBottom="0"
              titleMarginBottom={Space.spBasePlus}
            />
          </BannerWraper>
        ) : (
          <Banner
            title='The customer has failed the Dow Jones compliance check and requires a review'
            text='Till then this order will stay on hold'
            bgColor={Main.warning}
            buttons={dowJonesBlockedAdminButton}
            textAreaMarginBottom="0"
            titleMarginBottom={Space.spBasePlus}
          />
        )
    ) : null;

  const consignmentEntriesWithPostOrderShippingCost = consignmentDetailsResponse.consignmentEntries.filter(consignmentEntry => consignmentEntry.postOrderShippingCost)


  const optionalInfoDatesAndCarrier = <FlexboxContainer
    flexDirection="row"
    width="401px"
    alignItems="flex-start"
    padding='0 0 0 16px'
  >
    <FlexboxContainer width="230px">
      <Select
        noBoxShadow
        options={shippingCarriers.map(shCarrier => ({ value: shCarrier.name }))}
        value={additionalShippingCostRequest.shippingCarrier ? additionalShippingCostRequest.shippingCarrier.name : ''}
        defaultValue="Select"
        onChange={onShippingCarrier}
        labelPosition='top'
        label="Carrier"
        height="38px"
      />
    </FlexboxContainer>
    <FlexboxContainer flexDirection="column" width="150px" margin={`0 0 0 ${Space.spHalf}`}>
      <LabelText>Est. delivery</LabelText>
      <DatePicker
        placeholder='Select'
        minDate={new Date()}
        value={
          additionalShippingCostRequest.deliveryDate
            ? new Date(additionalShippingCostRequest.deliveryDate).toUTCString()
            : null
        }
        onChange={(value: any) => onDeliveryDateSelect(new Date(value).toUTCString())}
        inputVariant="outlined"
        format="yyyy MMM dd"
        TextFieldComponent={DatePickerInput}
        autoOk
      />
    </FlexboxContainer>
  </FlexboxContainer>;


  const consignmentShippingCostRequired = [DOW_JONES_CLEAR, DOW_JONES_REVIEWED_CLEAR].includes(consignmentDetailsResponse.order.customer.dowJonesStatus!) && consignmentDetailsResponse.state === AWAITS_SHIPPING
    ? (
      <BannerWraper>
        <Banner
          margin='0'
          title='Some order items require manual input of shipping cost'
          bgColor={Background.white}
          textAreaMarginBottom="0"
          titleMarginBottom={Space.spBasePlus}
        >
          {
            consignmentEntriesWithPostOrderShippingCost && (
              <ShippingCostRequiredItems
                deliveryDate={consignmentDetailsResponse.availableDate}
                subTotal={consignmentDetailsResponse.subTotal}
                showSummary={false}
                consignmentEntries={consignmentEntriesWithPostOrderShippingCost}
                state={consignmentDetailsResponse.state}
              />
            )
          }
          <Label>Provide consolidated shipping cost for the items above. Recalculated order total will be sent to the customer for approval.</Label>
          <FlexboxContainer flexDirection='row' justifyContent='space-between' padding='16px;'>
            <CurrencyInput
              width='200px'
              selectAllOnFirstClick
              id='additional-shipping-cost-input-id'
              prefix='$'
              textAlign='right'
              labelName='Shipping cost'
              labelPosition='top'
              placeholder=''
              decimalsLimit={2}
              value={additionalShippingCostRequest.totalAdditionalShippingCost.toString()}
              onValueChange={onAdditionalShippingCostInput}
              allowDecimals
              decimalScale={2}
              decimalSeparator='.'
            />
            <FlexboxContainer
              flexDirection='row'
              justifyContent='flex-end'
              align-items="flex-end"
              padding='36px 0px 0px'
            >
              <Button
                isGhost
                theme='disabled'
                color={Shade.shade550}
                margin={`0 ${Space.spHalf}`}
                onClick={onDeclinePostOrderShippingCostClick}
              >
                Decline order
              </Button>
              <Button
                onClick={onConfirmShippingCost}
              >
                Confirm shipping cost
              </Button>
            </FlexboxContainer>
          </FlexboxContainer>
          <FlexboxContainer padding='6px 16px 6px'>
            <Checkbox
              theme='disabled'
              bgColor={Main.brandLight}
              id='shipping-cost-optional-info-id'
              appearance="none"
              checkboxSize="s"
              checked={checkboxValue}
              label="Optional info"
              onChange={(checked: any) => updateOptionalInfo(checked)}
            />
          </FlexboxContainer>
          {
            openOptionalInfoSection && (
              <FlexboxContainer flexDirection='column'>
                {!isRental && optionalInfoDatesAndCarrier}
                <FlexboxContainer width='401px' margin='22px 0 0 0' padding='0 0 0 16px'>
                  <Textarea
                    errorMessage={errorMessage}
                    id='comment-id'
                    label='Comment'
                    isInvalid={isInvalid}
                    placeholder=''
                    value={additionalShippingCostRequest.shippingAdditionalInfo ? additionalShippingCostRequest.shippingAdditionalInfo : ''}
                    onChange={onAdditionalShippingInfo}
                  />
                </FlexboxContainer>
              </FlexboxContainer>
            )
          }
        </Banner>
      </BannerWraper>
    ) : consignmentDetailsResponse.order.customer.dowJonesStatus === DOW_JONES_BLOCKED && consignmentDetailsResponse.state === AWAITS_SHIPPING ? (
      isVendor
        ? (
          <BannerWraper>
            <Banner
              margin='0'
              title='The order is in OFMP compliance check status'
              text='This processing can take a few days, you will be notified of the status update by email.'
              bgColor={Background.white}
              textAreaMarginBottom="0"
              titleMarginBottom={Space.spBasePlus}
            />
          </BannerWraper>
        ) : (
          <Banner
            title='The customer has failed the Dow Jones compliance check and requires a review'
            text='Till then this order will stay on hold'
            bgColor={Main.warning}
            buttons={dowJonesBlockedAdminButton}
            textAreaMarginBottom="0"
            titleMarginBottom={Space.spBasePlus}
          />
        )
    ) : null;

  const splitName = consignmentDetailsResponse.consignmentCount > 1 && !isVendor
    ? (
      <FlexboxContainer>
        <Link className="base" href={`/orders/split-order-details/${consignmentDetailsResponse.order.orderId}`}>
          <BreadcrumbContainer padding={Space.spBase}>
            Split order # {consignmentDetailsResponse.order.orderId}
          </BreadcrumbContainer>
        </Link>
        <FlexboxContainer padding={`${Space.spBase} 0`} alignItems="center">
          <StyledSlashIcon />
        </FlexboxContainer>
        <BreadcrumbContainer padding={`0 ${Space.spBase}`} alignItems="center">
          {
            isShipping ?
              `Delivery order # ${consignmentDetailsResponse.consignmentId.slice(4)}`
              : `Pickup order # ${consignmentDetailsResponse.consignmentId.slice(4)}`
          }
        </BreadcrumbContainer>
      </FlexboxContainer>
    )
    : (
      <BreadcrumbContainer padding={`0 ${Space.spBase}`} alignItems="center">
        {
          isShipping
            ? `Delivery order # ${consignmentDetailsResponse.consignmentId.slice(4)}`
            : `Pickup order # ${consignmentDetailsResponse.consignmentId.slice(4)}`
        }
      </BreadcrumbContainer>
    );

  // Filter and sort consignment history changes
  const consignmentHistory = (consignmentDetailsResponse?.consignmentHistoryChanges || [])
    .filter((item) => {
      if (item.toConsignmentStatus === NEW) return true;
      else {
        return !HIDE_STATUS_ON_ORDER_HISTORY.includes(item.toConsignmentStatus)
          && item.fromConsignmentStatus !== item.toConsignmentStatus
      }
    }
    ).sort((a, b) => new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime());


  // Fields to compare between current and previous items
  const fieldsToCompareForHistoryChange = [
    'tracking',
    'deliveryDate',
    'carrierName',
    'rentStartDate',
    'rentEndDate'
  ];

  const mappedHistory = mapCurrentAndPreviousValuesInArray(consignmentHistory, fieldsToCompareForHistoryChange).sort((a, b) => new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime());

  const consignmentHistoryView =
    <Banner
      boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
      bgColor={Background.white}
    >
      <>
        <FlexboxContainer justifyContent='space-between'>
          {renderOrderDetailsTitle('Order progress')}
          <Button
            onClick={() => printComponent('order-progress-printable-content', `Order History ${parseConsignmentId(consignmentDetailsResponse.consignmentId)}`, 'Order Progress')}
            leftIcon={() => <PrintIcon />}
            background="white"
            borderColor="white"
          />
        </FlexboxContainer>
        <OrderProgress
          consignmentHistoryChanges={mappedHistory || []}
          consignmentState={consignmentDetailsResponse?.state}
          featureFlags={featureFlags}
        />
      </>
    </Banner>

  const ticketHistoryView =
    <TicketHistory tickets={consignmentDetailsResponse?.tickets!} consignmentId={consignmentDetailsResponse.consignmentId} deliveryType={consignmentDetailsResponse.deliveryType} />

  return (
    <Page>
      {modal}
      {declinePostOrderShippingCostModal}
      {modalOnHold}
      {fulfillmentModal}
      {confirmFulfillmentModal}
      {dispatchModal}
      {manufactureModal}
      {pickupModal}
      {delayModal}
      {delayAcceptModal}
      {confirmOrderModal}
      <SalesOrderModal show={salesOrderModalOpen} isLoading={salesOrderLoading} onClose={onModalClose} onConfirm={salesOrderUpdateFn} salesOrderNumber={salesOrderResult.salesOrder || consignmentDetailsResponse.salesOrderNumber} isError={salesOrderError} isTimeout={salesOrderModalTimeout} saveAnyway={saveSalesOrderNumber} setError={setSalesOrderError} setTimeout={setSalesOrderModalTimeout} />
      <SalesOrderDetailsModal show={salesOrderDetailsModalOpen} salesOrderDetails={salesOrderResult} onClose={onModalClose} />
      <FlexboxContainer justifyContent='space-between'>
        <Breadcrumbs containerPadding="0 0 24px 8px" crumbs={crumbs} splitOrderName={splitName} />
        {isReviewPage && <Button
          onClick={() => printComponent('order-update-preview-printable-content', `Order Update Preview ${consignmentDetailsResponse.consignmentId}`, 'Order update preview')}
          leftIcon={() => <PrintIcon />}
          background="white"
          borderColor="white"
        />
        }
      </FlexboxContainer>
      <FlexboxContainer justifyContent="space-between" width="100%">
        <FlexboxContainer justifyContent="column" alignItems="center">
          {isShipping ? <StyledLocalShipping /> : <StyledWarehouseIcon />}
          <h3>
            {isShipping ? 'Delivery order' : 'Pickup order'} &nbsp; # {consignmentDetailsResponse.consignmentId.slice(4)}
          </h3>
          {pill}
          {/* 
            Commented as it is not moved to stage
            {sapPill}
           */}
        </FlexboxContainer>
        <FlexboxContainer
          justifyContent="flex-end"
          margin='0 20px 0 0'
        >
          {
            !isReviewPage && (
              (consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.PURCHASE && !isVendor && consignmentDetailsResponse.order.customer.dowJonesStatus !== DOW_JONES_BLOCKED) ||
              (consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.RENT && !(hiddenStates.includes(consignmentDetailsResponse.state)))) && (
              <Button
                borderColor='white'
                isGhost
                theme='error'
                color={Main.error}
                onClick={handleShowCancellationConsignmentPopup}
                disabled={disableCancelConsignmentButton()}
              >
                Cancel consignment
              </Button>
            )
          }

        </FlexboxContainer>
      </FlexboxContainer>
      <FlexboxContainer flexDirection='row' gap='10px' margin='0px 20px'>
        <OrderTag type={consignmentDetailsResponse.consignmentType} />
        <PlacedDate>Placed {formatDateTime(consignmentDetailsResponse.consignmentDate, true)} {getLocalTimezone()}</PlacedDate>
      </FlexboxContainer>
      <Divider />
      {orderActions}
      {dowJonesBanner}
      {consignmentDowJonesBaner}
      {consignmentShippingCostRequired}
      {consignmentShippingCostApproval}
      {consignmentDowJones}
      {orderPreparationBanner}
      {fulfillmentTable}
      {postOrderShippingCostSplitForm}
      {updates}
      {orderReadyActions}
      {orderOnHoldAfterCheckingCreditActions}
      {orderCancelingActions}
      {getManufacturingBanner()}
      {awaitingDispatchActions}
      {rentalSteps}
      {orderFulfilled}
      {delayedActions}
      {orderInformation}
      {mappedHistory.length > 1 && consignmentHistoryView}
      {ticketHistoryView}
      {orderProducts}
      {orderFulfillmentTable}
      {orderPreparation}
      {cancellationOrderEmailModal}
      {cancellationConsignmentModal}
      {updateOrderInfoModal}
      {modifyRentalDatesModal}
    </Page>
  );
};

export default React.memo(OrderDetails);
