import Modal from 'components/UI/Modal';
import React from 'react';
import styled from 'styled-components';
import { Size } from 'lib/styles';
import ReactPlayerLoader from '@brightcove/react-player-loader'

interface Props {
  name: string;
  onClose: () => void;
  videoId: string;
  showModal: any;
}

const Container = styled.div`
  padding: ${Size.large} ${Size.medium} ${Size.medium};

  .brightcove-react-player-loader {
    .video-js {
      width: 100%;
    }
  }
`;

const Video = styled(ReactPlayerLoader) <any>``;

export default function VideoPlayModal({ name, onClose, videoId, showModal }: Props) {
  return (
    <Modal onClose={onClose} showCloseIcon title={name} showButtons={false} showModal={true}>
      <Container>
        <Video
          className="video-js"
          accountId="6076300345001"
          videoId={videoId}
        />
      </Container>
    </Modal>
  );
};

VideoPlayModal.defaultProps = {};
