import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { useAPI } from 'lib/API';
import { Check } from '@material-ui/icons';
import { Height } from 'styles/themeHeights';
import readExcelFile from 'read-excel-file';
import { SocketContext } from 'context/socket';
import UploadComponent from 'components/Product/UploadFileComponent';
import VerificationCompletionComponent from 'components/Product/VerificationCompletionComponent';
import { TEXT_LABELS, STEPS, STATUS } from '../../constants/productVariantImport';
import { checkFileSize, checkFileType, filenameWithoutExtension } from 'components/Pricebooks/priceebook.utils';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import { mapDataToString, verifyProductFileTemplate } from 'utils/campaign';
import { getUserCampaign, validateCampaign } from 'requests/campaigns';
import FooterComponent from './FooterComponent';
import { IMPORT_STATUS } from 'constants/campaign';

const SubTitle = styled.h5<any>`
  margin: 0px !important;
  font-size: 16px;
  line-height: 16px;
`;

const StepNumber = styled.span<any>`
  font-size: 16px;
  line-height: 20px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  padding: 2px 5px;
  margin-bottom: 4px;
  background: ${({ $bg }) => ($bg ? '#F7991C' : '#bfc2c5')};
`;

export type UploadingStatus = {
  statusId: number,
  fileName: string,
  passedCount: number,
  failedCount: number,
  totalCount: number,
  createdOn: string,
  originalFileLocation?: string,
  validatedFileLocation?: string
}

interface Props {
  show: boolean;
  onClose: () => void;
  isUploading: boolean;
  setIsUploading: (value: boolean) => void;
  loadCampaigns: () => void;
  isValidating: boolean;
  setIsValidating: (value: boolean) => void;
  userId: string;
  vendorIds: number[];
}

const ImportCampaignModal: React.FC<Props> = ({
  show,
  onClose,
  isUploading,
  setIsUploading,
  loadCampaigns,
  isValidating,
  setIsValidating,
  userId,
  vendorIds
}) => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [uploadFileSizeError, setUploadFileSizeError] = useState(false);
  const [uploadFileTypeError, setUploadFileTypeError] = useState(false);
  const [uploadFileTemplateError, setUploadFileTemplateError] = useState(false);
  const [excelData, setExcelData] = useState<any>();
  const [step, setStep] = useState<number>(1);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [uploadingStatus, setUploadingStatus] = useState<UploadingStatus>();
  const [excelFile, setExcelFile] = useState<string>();
  const [file, setFile] = useState<any>({
    imageFile: [],
    uploadDate: new Date(),
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const socket = useContext(SocketContext);

  const handleClear = () => {
    if (inputRef.current != null) {
      inputRef.current.value = '';
    }
    setUploadFileSizeError(false);
    setUploadFileTypeError(false);
    setUploadFileTemplateError(false);
    setUploadSuccess(false);
    setFileUploading(false);
    setFile({
      imageFile: [],
      uploadDate: '',
    });
    setStep(STEPS.UPLOADING)
  };

  const handleUploadClick = () => {
    if (inputRef.current != null) {
      inputRef.current.value = '';
    }
    setUploadFileSizeError(false);
    setUploadFileTypeError(false);
    setUploadFileTemplateError(false);
    setUploadSuccess(false);
    setFileUploading(false);
    setFile({
      imageFile: [],
      uploadDate: '',
    });
    inputRef.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFile({
      imageFile: [e.target.files[0]],
      uploadDate: Date.now(),
    });
    if (!checkFileSize(e.target.files[0])) {
      setUploadFileSizeError(true);
      setFileUploading(false);
      return;
    } else if (checkFileType(e.target.files[0])) {
      setUploadFileTypeError(false);
    } else {
      setUploadFileTypeError(true);
      return;
    }
  };

  const productTemplateVerify = async () => {
    const importFile = file.imageFile[0];
    const data = await readExcelFile(file.imageFile[0]);
    setExcelData(mapDataToString(data));
    setExcelFile(importFile.name);

    const templateVerification = await verifyProductFileTemplate(importFile);
    if (templateVerification) {
      setFileUploading(false);
      setUploadFileTemplateError(false);
      setUploadSuccess(true);
    } else {
      setUploadFileTemplateError(true);
      setUploadSuccess(false);
      return;
    }
  };

  useEffect(() => {
    if (
      file.imageFile &&
      file.imageFile.length > 0 &&
      !uploadFileSizeError &&
      !uploadFileTypeError
    ) {
      productTemplateVerify();
    }
  }, [file.imageFile, uploadFileSizeError, uploadFileTypeError]);

  const gotoNextStep = () => {
    setFileUploading(true);
    setIsValidating(true);
    uploadCampaignData.run(
      excelData,
      filenameWithoutExtension(excelFile),
      file.uploadDate,
      vendorIds
    );
  };

  const getCampaignImportResult = useAPI({
    deferFn: getUserCampaign,
    onResolve: (result: any) => {
      if(!result){
        setStep(STEPS.UPLOADING)
        return
      }
      if(result?.data?.statusId === STATUS.PENDING){
        setIsUploading(true)
      }
      if (result?.data?.statusId === STATUS.COMPLETED) {
        setStep(STEPS.COMPLETION);
        setFileUploading(false);
        setIsUploading(false)
        isValidating && loadCampaigns();
        setIsValidating(false);
      }
      setUploadingStatus(result?.data);
    },
    onReject: (err: any) => {console.log('err:', err)},
  });

  useEffect(() => {
    if (userId) {
        getCampaignImportResult.run(userId);

        const progressEvent = `marketingCampaignProgress_${userId}`;
        const campaignEvent = `marketingCampaign_${userId}`;

        const handleProgress = (result: any) => {
            if(step !== STEPS.VERIFICATION){
              setStep(STEPS.VERIFICATION);
            }
            setProgressPercent(result.progressPercent);
        };

        const handleCampaign = (result: any) => {
            if (result?.status === IMPORT_STATUS.COMPLETED) {
                setStep(STEPS.COMPLETION);
                setFileUploading(false);
                setIsUploading(false);
                setIsValidating(false);
                setUploadingStatus(result?.importData);
                if(result?.importData?.totalCount > 0 && result?.importData?.passedCount > 0) loadCampaigns();
            }
            setProgressPercent(0);
        };

        // Add socket listeners
        socket.on(progressEvent, handleProgress);
        socket.on(campaignEvent, handleCampaign);

        // Close the socket listeners
        return () => {
            socket.off(progressEvent, handleProgress);
            socket.off(campaignEvent, handleCampaign);
        };
    }
}, [userId]);


  const uploadCampaignData = useAPI({
    deferFn: validateCampaign,
    onResolve: (result: any) => {
        setExcelFile(result.blobName)
        setFileUploading(false);
        setStep(step + 1);
        setUploadingStatus(result.importData);
    },
    onReject: (err: any) => {
      setStep(STEPS.UPLOADING);
      setUploadFileSizeError(false);
      setUploadFileTypeError(false);
      setUploadFileTemplateError(false);
      setUploadSuccess(false);
      setFile({
        imageFile: [],
        uploadDate: '',
      });  
      setFileUploading(false);
      console.log(err);
      renderToast(toast.TYPE.ERROR, err.message, {
        autoClose: 5000
      });
    }
  });

  const isStepTwoFileVerification =
    step !== STEPS.UPLOADING &&
    (!uploadingStatus || uploadingStatus?.statusId === STATUS.PENDING);
  const showNextButton =
    isStepTwoFileVerification || (uploadSuccess && step === STATUS.PENDING);
  const disableNextButton = isStepTwoFileVerification || fileUploading;

  return (
    <Modal
      width="825px"
      showModal={cancelModal ? false : show}
      onClose={() => {
        onClose();
      }}
      showButtons={false}
      title={'Marketing events upload'}
      modalFooterMargin="0"
      left="50vw"
      marginLeft="-412px"
      iconName={'PricebookModalIcon'}
      showCloseIcon
      top="15%"
    >
      <FlexboxContainerComponent
        bgColor="#F9F9FA"
        border="1px solid #E1E6EB"
        borderRadius="6px"
        padding="24px"
        flexDirection="column"
      >
        <FlexboxContainerComponent margin="0 auto" flexDirection="row">
          <FlexboxContainerComponent
            flexDirection="column"
            alignItems="center"
            width="24px"
          >
            <StepNumber $bg={step >= STEPS.UPLOADING}>
              {step === STEPS.UPLOADING ? (
                <Check
                  style={{
                    color: '#FFFFFF',
                    height: Height.xsPlus,
                    width: '12px',
                  }}
                />
              ) : (
                <>{STEPS.UPLOADING}</>
              )}
            </StepNumber>
            <SubTitle>{TEXT_LABELS.UPLOAD}</SubTitle>
          </FlexboxContainerComponent>
          <hr
            style={{
              width: '150px',
              color: '#C1C9D1',
              margin: '12px 4px',
              height: '1px',
            }}
          ></hr>
          <FlexboxContainerComponent
            flexDirection="column"
            alignItems="center"
            width="24px"
          >
            <StepNumber $bg={step >= STEPS.VERIFICATION}>
              {step === STEPS.VERIFICATION ? (
                <Check
                  style={{
                    color: '#FFFFFF',
                    height: Height.xsPlus,
                    width: '12px',
                  }}
                />
              ) : (
                <>{STEPS.VERIFICATION}</>
              )}
            </StepNumber>
            <SubTitle>{TEXT_LABELS.VERIFICATION}</SubTitle>
          </FlexboxContainerComponent>
          <hr
            style={{
              width: '150px',
              color: '#C1C9D1',
              margin: '12px 4px',
              height: '1px',
            }}
          ></hr>
          <FlexboxContainerComponent
            flexDirection="column"
            alignItems="center"
            width="24px"
          >
            <StepNumber $bg={step >= STEPS.COMPLETION}>
              {step === STEPS.COMPLETION ? (
                <Check
                  style={{
                    color: '#FFFFFF',
                    height: Height.xsPlus,
                    width: '12px',
                  }}
                />
              ) : (
                <>{STEPS.COMPLETION}</>
              )}
            </StepNumber>
            <SubTitle>{TEXT_LABELS.COMPLETED}</SubTitle>
          </FlexboxContainerComponent>
        </FlexboxContainerComponent>
        {step === STEPS.UPLOADING && (
          <UploadComponent
            uploadSuccess={uploadSuccess}
            uploadFileSizeError={uploadFileSizeError}
            uploadFileTypeError={uploadFileTypeError}
            uploadFileTemplateError={uploadFileTemplateError}
            file={file}
            handleUploadClick={handleUploadClick}
            handleClear={handleClear}
            inputRef={inputRef}
            handleFileChange={handleFileChange}
            fileUploading={fileUploading}
          />
        )}
        {(step === STEPS.VERIFICATION || step === STEPS.COMPLETION) && (
          <VerificationCompletionComponent
            uploadingStatus={uploadingStatus}
            progressPercent={progressPercent}
            step={step}
            importedLabel={TEXT_LABELS.CAMPAIGNS_IMPORTED}
            failedLabel={TEXT_LABELS.CAMPAIGNS_FAILED}
          />
        )}
        <FooterComponent 
            showNextButton={showNextButton} disableNextButton={disableNextButton}
            gotoNextStep={gotoNextStep}
            uploadSuccess={uploadSuccess}
            uploadingStatus={uploadingStatus}
            step={step}
            handleClear={handleClear}
        />
      </FlexboxContainerComponent>
    </Modal>
  );
};

export default React.memo(ImportCampaignModal);
