const ProductLocalization = {
  errors: {
    newProduct: 'Please select vendor before you try to create product.',
    clone: 'A version for this product already exists in draft or ready to publish. Please edit that one, or delete it and try again.',
    publish: 'You can only publish products in Ready to publish status.',
    live: 'You can only push products in Published status.',
    delete: 'Only products in Draft status can be deleted',
    materialApi: 'Products publish failed. There were issues in material numbers / SKUs while validation Material API',
    exportMaterialApi: 'Product(s) go-live failed. There were issues in material numbers / SKUs while validation Material API'
  },
  success: {
    clone: 'A new version of the product created successfully.',
    delete: 'Draft products deleted successfully.',
    statusUpdate: 'Product(s) status updated successfully.'
  },
  warning: {
    update: 'Please note: modifying a published product requires that we create a new version of it in your "Drafts". The current version will remain live on the site and will be updated once your new version has been submitted and approved by an admin.',
    revise: `Please note: we'll move your product back into "Drafts" where you can revise it. It will no longer be visible to the admin until you submit the product again for publishing.`,
    materialApi: 'Some products were published, others have issues in material numbers / SKUs while validation Material API',
    exportMaterialApi: 'Some products were made live, others have issues in material numbers / SKUs while validation Material API'
  },
  listing: {
    filter: {
      vendorSelect: 'Select vendor',
      countrySelect: 'Select country',
      brandSelect: 'Select brand',
      search: 'Search the product',
    },
  },
};

export default ProductLocalization;
