import React, { forwardRef, Ref } from 'react';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { Icons } from 'material-table';
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  SaveAlt,
  Search
} from '@material-ui/icons';
import Icon from 'view/Icon';
import { Text } from 'styles/themeColors';
import { Height } from 'styles/themeHeights';
import { Space } from 'styles/themeSpaces';

const MatTableIcons: Icons = {
  Add: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <AddBox ref={ref} {...props} />),
  Check: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => (
    <ArrowDownward {...props} ref={ref} style={{ color: Text.primary, width: '24px', height: Height.s, marginLeft: Space.spHalf }} />
  )),
  ViewColumn: forwardRef((props: SvgIconProps, ref: Ref<SVGSVGElement>) => <Icon name="Columns" {...props} ref={ref} />)
};

export default MatTableIcons;
