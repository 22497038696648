import React from 'react';
import styled from 'styled-components';
import { Size } from 'lib/styles';
import { Main, Background } from 'styles/themeColors';

const Container = styled.div`
  background-color: ${Main.error};
  border-radius: ${Size.small};
  color: ${Background.white};
  padding: ${Size.small} ${Size.medium};
  text-align: center;
`;

const Message = styled.p`
  margin: 0px;
`;

export interface Props {
  className?: string;
  message: string;
};

export default function ErrorMessage({ className, message }: Props) {
  return (
    <Container className={className}>
      <Message>{message}</Message>
    </Container>
  );
};
