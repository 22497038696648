import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Background, Border, Text } from 'styles/themeColors';
import { Asset } from './AssetCard';
import { Size } from 'lib/styles';
import Icon from 'view/Icon';
import { VideoAsset } from './VideoAssetCard';
import { PlayCircleFilled, CloseOutlined } from '@material-ui/icons';


const Card = styled.div`
  align-items: self-start;
  background: ${Background.white};
  border: 1px solid ${Border.light};
  border-radius: 6px;
  display: flex;
  padding: 6px;
  min-height: 56px !important;
  box-shadow: 2px 3px 3px rgba(39, 44, 57, 0.15);
  align-items: center;
  margin-top: 4px;
`;

const StyledFlexboxContaier = styled(FlexboxContainer) <any>`
  .MuiSvgIcon-root {
    font-size: 36px;
  }
`;

const Label = styled.span`
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
`;

const Thumbnail = styled.div<any>`
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  margin-right: ${Size.small};

  ${({ thumbnailShadow }) => thumbnailShadow && css`
    padding: ${Size.xs};
  `}
`;

const ThumbnailImg = styled.img`
  height: 100%;
  object-fit: contain;
`;

const DeleteAction = styled.div`
  align-items: center;
  color: ${Text.secondary};
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: ${Size.medium};
`;

const DeleteIcon = styled(Icon).attrs({ name: 'Trash', size: 14 })`
  margin-right: ${Size.tiny};
`;

interface Props {
  asset: Asset | VideoAsset;
  className?: string;
  editableName?: boolean;
  index: number;
  onDelete: (index: number) => void;
  onNameChange?: (name: string) => void;
  renderThumbnail?: (asset: Asset ) => React.ReactNode;
  disabled?: boolean;
};

const AssetCardV2 = ({
  asset,
  className,
  editableName,
  index,
  onDelete,
  onNameChange,
  renderThumbnail,
  disabled
}: Props) => {
  let { id, displayName, path, type, name, videoId } = {} as any;

  if (isAsset(asset)) {
    ({ id, displayName, path, type } = asset);
  } else if (isVideoAsset(asset)) {
    ({ name, videoId } = asset);
    displayName = name;
  }

  function isAsset(asset: Asset | VideoAsset): asset is Asset {
    return (asset as Asset).displayName !== undefined;
  }
  
  function isVideoAsset(asset: Asset | VideoAsset): asset is VideoAsset {
    return (asset as VideoAsset).videoId !== undefined;
  }

  function getThumbnailComponent() {
    if (typeof path === 'string' && type !== 'document') {
      return (
        <Thumbnail thumbnailShadow={true}>
          <ThumbnailImg src={process.env.REACT_APP_MEDIA_URL + path} />
        </Thumbnail>
      );
    }
    if(videoId){
      return (
        <PlayCircleFilled style={{ fontSize: 40, marginRight: 8 }} />
      );
    }
    if (renderThumbnail) {
      return (
        <Icon name='Draft' size={34} />
      );
    }

    return null;
  }
  return (
    <Fragment>
      <Card
        aria-haspopup="true">
        <StyledFlexboxContaier justifyContent='flex-start'>
        {getThumbnailComponent()}
        </StyledFlexboxContaier>
        <FlexboxContainer flexDirection='row' width="100%" justifyContent='space-between' alignItems='center'>
          <Label>{displayName}</Label>
          <DeleteAction onClick={() => onDelete(index)}>
            <CloseOutlined style={{display: disabled ? 'none' : 'block'}} />
          </DeleteAction>
        </FlexboxContainer>
      </Card>
    </Fragment>
  );
};

export default AssetCardV2;
