import React, { useEffect } from 'react';
import styled from 'styled-components';
import { navigate } from 'hookrouter';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { CANCELED, CANCELING, MANUFACTURING, PROCESSING } from 'constants/consignmentStates';
import { FontSize } from 'styles/themeSizes';
import { FontWeight, Size } from 'lib/styles';
import Icon from 'view/Icon';
import { ConsignmentDetails } from 'data/Consignment';
import { CONSIGNMENT_TYPES } from 'constants/order';
import OrderPreviewItem from './OrderPreviewItem';
import ConsignmentEntry from 'data/ConsignmentEntry';
import OrderPreviewItemPrintable from './OrderPreviewItemPrintable';
import { useAPI } from 'lib/API';
import { emulateSplitConsignment } from 'requests/consignment';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';

const SubHeading = styled.div`
  font-size: ${FontSize.subLarge};
  font-weight: ${FontWeight.bold};
  margin-bottom: 10px;
`;

const WarningIcon = styled(Icon).attrs({ name: 'WarningBlack', size: 20 })`
  margin-right: ${Size.medium};
  margin-top: ${Size.tiny};
`;

type SplittedItems = ConsignmentEntry[][];

/**
 * Function to split items by rental dates
 * @param items - Array of consignment entries
 * @returns Array of arrays of consignment entries
 */
function splitByRentalDates(items: ConsignmentEntry[]) {
    // Function to convert dates to a comparable format
    const formatDate = (date: any) => new Date(date).getTime();

    // Object to group items by rental dates
    const groupedItems: { [key: string]: ConsignmentEntry[] } = {};

    items.forEach((item) => {
        const startDate = formatDate(item.rentalStartDate);
        const endDate = formatDate(item.rentalEndDate);
        const key = `${startDate}-${endDate}`;

        if (!groupedItems[key]) {
            groupedItems[key] = [];
        }
        groupedItems[key].push(item);
    });

    // Convert the grouped items object to an array of arrays
    return Object.values(groupedItems);
}


interface OrderProcessPreviewProps {
    consignmentId?: string;
    setIsReviewPageMounted?: (isMounted: boolean) => void;
    setIsReviewPage?: (isReviewPage: boolean) => void;
}

const OrderProcessPreview: React.FC<OrderProcessPreviewProps> = ({ consignmentId,setIsReviewPageMounted,setIsReviewPage }) => {

    const [splittedConsignment, setSplittedConsignment] = React.useState<any[]>([]);
    const orderPreviewData = JSON.parse(sessionStorage.getItem('previewRouteData') || '');
    const consignmentDetailsResponse: ConsignmentDetails = orderPreviewData?.consignmentDetailsResponse || {};
    const fulfillmentRequest: ConsignmentEntry[] = orderPreviewData?.fulfillmentRequest || [];

    const splitConsignmentReq = useAPI({
        deferFn: emulateSplitConsignment,
        onResolve: (result) => {
            const splitedConsignments = result.map((splitConsignment: any) => {
                const newEntries = splitConsignment.entries.map((spEntry: any) => {
                    const foundEntry = consignmentDetailsResponse.consignmentEntries.find(consEntry => consEntry.sku === spEntry.productSKU);
                    const selectableAttributes = foundEntry && foundEntry.selectableAttributes;
                    const attributeValues = foundEntry && foundEntry.attributeValues;
                    const productImages = foundEntry && foundEntry.productImages;

                    const splitConsignmentBody = JSON.parse(sessionStorage.getItem('splitConsignmentBody') || '');
                    const entryDetailsBeforeEmulate = splitConsignmentBody?.entries?.find((entry: any) => entry.entryNumber === spEntry.entryNumber);
                    const rentalStartDate = splitConsignment.state === MANUFACTURING ? entryDetailsBeforeEmulate?.manufactureDate : entryDetailsBeforeEmulate?.rentalStartDate;
                    const rentalEndDate = splitConsignment.state === MANUFACTURING ? entryDetailsBeforeEmulate?.manufactureEndDate : entryDetailsBeforeEmulate?.rentalEndDate;
                    const orignalConsignmentEntry = consignmentDetailsResponse?.consignmentEntries.find((entry: any) => entry.sku === spEntry.productSKU);
                    const deliveryMode = orignalConsignmentEntry?.deliveryMode;
                    const priceBook = orignalConsignmentEntry?.priceBook;
                    const pricebookId = orignalConsignmentEntry?.pricebookId;
                    const pricebookName = orignalConsignmentEntry?.pricebookName;

                    return {
                        ...spEntry, selectableAttributes, attributeValues, productImages, rentalStartDate, rentalEndDate, deliveryMode,
                        priceBook: priceBook,
                        pricebookId: pricebookId,
                        pricebookName: pricebookName
                    }
                })

                const entryNumbers = splitConsignment.entries?.map((entry: any) => entry.entryNumber);
                return { ...splitConsignment, entries: newEntries, entryNumbers }
            });

            setSplittedConsignment(splitedConsignments);
            setIsReviewPageMounted && setIsReviewPageMounted(true);

        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
            setIsReviewPageMounted && setIsReviewPageMounted(false);
            setIsReviewPage && setIsReviewPage(false);
        }
    });

    useEffect(() => {

        let entries: any = [];
        fulfillmentRequest.length > 0 && fulfillmentRequest.map((entry, i) => {
            let committedDate;
            let manufactureDate;

            if (entry.manufactureQuantity > 0) {
                manufactureDate = entry?.manufactureDate ? new Date(entry?.manufactureDate).setUTCHours(0, 0, 0, 0) : new Date(entry.preliminaryDeliveryDate).setUTCHours(0, 0, 0, 0);
                committedDate = 0;

            } else {
                committedDate = entry?.committedDate ? new Date(entry?.committedDate).setUTCHours(0, 0, 0, 0) : new Date(entry.preliminaryDeliveryDate).setUTCHours(0, 0, 0, 0);
                manufactureDate = 0;
            }

            let dd: any = {
                entryNumber: entry.entryNumber,
                quantity: entry.quantity,
                productId: entry?.productId,
                committedQuantity: entry?.committedQuantity ? entry?.committedQuantity : 0,
                manufactureQuantity: entry?.manufactureQuantity ? entry?.manufactureQuantity : 0,
                cancelQuantity: entry.cancelQuantity ? entry.cancelQuantity : 0,
                committedDate: committedDate,
                manufactureDate: manufactureDate,
            }

            // Update payload for rent
            if (consignmentDetailsResponse.consignmentType === CONSIGNMENT_TYPES.RENT) {
                dd = {
                    ...dd,
                    rentalStartDate: entry?.rentalStartDate ? new Date(entry.rentalStartDate).setUTCHours(0, 0, 0, 0) : 0,
                    rentalEndDate: entry?.rentalEndDate ? new Date(entry.rentalEndDate).setUTCHours(0, 0, 0, 0) : 0,
                    committedDate: entry?.committedQuantity > 0 ? new Date(entry.rentalStartDate).setUTCHours(0, 0, 0, 0) : 0,
                    committedEndDate: entry?.committedQuantity > 0 ? new Date(entry.rentalEndDate).setUTCHours(0, 0, 0, 0) : 0,
                    manufactureDate: entry?.manufactureQuantity > 0 ? new Date(entry.rentalStartDate).setUTCHours(0, 0, 0, 0) : 0,
                    manufactureEndDate: entry?.manufactureQuantity > 0 ? new Date(entry.rentalEndDate).setUTCHours(0, 0, 0, 0) : 0,
                }
            }

            // check added for checking entry duplication
            const existed = entries.find((ent: any) => ent.productId === entry.productId)
            if (existed) {
                if (dd.committedQuantity > 0) {
                    existed.committedQuantity = dd.committedQuantity;
                    existed.committedDate = dd.committedDate;
                    existed.committedEndDate = dd.committedEndDate;

                }

                if (dd.manufactureQuantity > 0) {
                    existed.manufactureDate = dd.manufactureDate;
                    existed.manufactureQuantity = dd.manufactureQuantity;
                    existed.manufactureEndDate = dd.manufactureEndDate;
                }

                if (dd.cancelQuantity > 0) {
                    existed.cancelQuantity = dd.cancelQuantity;
                    existed.committedDate = dd.manufactureDate;
                    existed.committedEndDate = dd.manufactureEndDate;
                }

            } else {
                entries.push(dd);
            }
        });

        const modifiedPayload = entries.map((item: any) => {
            return {
                entryNumber: item.entryNumber,
                quantity: item.quantity,
                committedQuantity: item.committedQuantity,
                manufactureQuantity: item.manufactureQuantity,
                cancelQuantity: item.cancelQuantity,
                committedDate: item.committedDate || item.rentalStartDate || 0,
                manufactureDate: item.manufactureDate,
                rentalStartDate: item.rentalStartDate || 0,
                rentalEndDate: item.rentalEndDate || 0,
                committedEndDate: item.committedEndDate || item.rentalEndDate || 0,
                manufactureEndDate: item.manufactureEndDate || 0
            }
        });


        const splitConsignmentBody = {
            availableDate: consignmentDetailsResponse.availableDate ? new Date(consignmentDetailsResponse.availableDate).getTime() : 0,
            consignmentId: consignmentDetailsResponse.consignmentId,
            state: consignmentDetailsResponse.state,
            entries: modifiedPayload
        }

        sessionStorage.setItem('splitConsignmentBody', JSON.stringify(splitConsignmentBody));
        
        splitConsignmentReq.run(consignmentId, splitConsignmentBody);
    }, [consignmentId]);


    if (splitConsignmentReq.isLoading) {
        return <LoadingIndicator />;
    }

    
    // Sort splitted consignments by state in descending order
    splittedConsignment.sort((a, b) => {
        const stateOrder = [CANCELED, CANCELING,MANUFACTURING, PROCESSING];
        return stateOrder.indexOf(b.state) - stateOrder.indexOf(a.state);
    });


    return (
        <>
            <FlexboxContainer justifyContent='space-between'>
                <FlexboxContainer padding='0' justifyContent="space-between" alignItems="center">
                    <SubHeading>{'Order update preview'}</SubHeading>
                </FlexboxContainer>
            </FlexboxContainer>

            <FlexboxContainer width="100%" alignItems="center" padding="0 0" margin='10px 0px'>
                <div>
                    <WarningIcon />
                </div>
                <div style={{ opacity: 0.7 }}>
                    Click '<em>Confirm</em>' to proceed with the order only after all changes have been confirmed with customer.
                </div>
            </FlexboxContainer>

            {
                splittedConsignment.length > 0 && splittedConsignment.map((item: any, index: number) => (
                    <OrderPreviewItem
                        key={index} // Provide a unique key for each mapped item
                        deliveryDate={item.availableDate || consignmentDetailsResponse.availableDate}
                        consignmentEntries={item.entries}
                        state={item.state}
                        isRental={true}
                        consignmentId={item.consignmentId}
                        deliveryTax={item.deliveryTax}
                        deliveryCost={item.deliveryCost}
                        orderTotalCost={item.totalPriceWithTax}
                    />
                ))
            }

            <div id="order-update-preview-printable-content" style={{ display: 'none' }}>

                {
                    splittedConsignment.length > 0 && splittedConsignment.map((item: any, index: number) => (
                        <OrderPreviewItemPrintable
                            key={index} // Provide a unique key for each mapped item
                            deliveryDate={item.availableDate || consignmentDetailsResponse.availableDate}
                            consignmentEntries={item.entries}
                            state={item.state}
                            isRental={true}
                            consignmentId={item.consignmentId}
                            deliveryTax={item.deliveryTax}
                            deliveryCost={item.deliveryCost}
                            orderTotalCost={item.totalPriceWithTax}
                        />
                    ))
                }

            </div>
        </>);
}

export default OrderProcessPreview;