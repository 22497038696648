const CampaignLocalization = {
    listing: {
        filter: {
          vendorSelect: 'Select vendor',
          countrySelect: 'Select country',
          brandSelect: 'Select brand',
          search: 'Search the product',
          campaignTypeSelect: 'Select campaign type',
          companySelect: 'Select company',
          startDate: 'Start date',
          endDate: 'End date',
        },
      },
    searchPlaceholder: 'Search the marketing campaign',
    marketingCampaigns: 'Marketing campaigns',
    activeCampaigns: 'ACTIVE CAMPAIGNS',
    totalCampaigns: 'TOTAL CAMPAIGNS',
    campaignsBudget: 'CAMPAIGNS BUDGET',
    launchCompanies: 'LAUNCH COMPANIES',
    events: 'Events',
    exportXLSX: 'Export XLSX',
}

export default CampaignLocalization;