import { get, post } from 'lib/API';

export const getVendorCompanyRecord = async (
  [vendorId, ofmpCompanyId]: any[],
  { token }: any
): Promise<any> => {
  return await get(`v1/apigee/company-vendor/${vendorId}/${ofmpCompanyId}`, token);
};

export const getVendorCompanyInfo = async (
  [ofmpCompanyId]: any[],
  { token }: any
): Promise<any> => {
  return await get(`v1/apigee/company-vendor/${ofmpCompanyId}`, token);
};

export const createVendorCompanyRecord = async (
  [vendorId, ofmpCompanyId, vendorCompanyId]: any[],
  { token }: any
): Promise<any> => {
  return await post(`v1/apigee/company-vendor/${vendorId}/${ofmpCompanyId}`, token, {
    vendorCompanyId
  });
};

export const getCompanyCredit = async (
  [vendorId, vendorCompanyId]: any[],
  { token }: any
): Promise<any> => {
  return await get(`v1/apigee/company-credit-details/${vendorId}/${vendorCompanyId}`, token);
};

export const updateCompanyCredit = async (
  [vendorId, vendorCompanyId, totalCreditLimit]: any[],
  { token }: any
): Promise<any> => {
  return await post(`v1/apigee/company-credit-update/${vendorId}/${vendorCompanyId}`, token, {
    totalCreditLimit
  });
};

export const overrideCompanyCredit = async (
  [
    vendorId, 
    vendorCompanyId, 
    orderId, 
    consignmentId, 
    totalCreditLimit,
    availableCreditLimit,
    status,
    overrideAmount
  ]: any[],
  { token }: any
) => {
  return await post(`v1/apigee/company-credit-override/${vendorId}/${vendorCompanyId}`, token, {
    orderId,
    consignmentId,
    totalCreditLimit,
    availableCreditLimit,
    status,
    overrideAmount
  });
};
