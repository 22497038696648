const initialVendorColumns = [
  {
    title: 'Customer Since',
    field: 'customerSince',
    type: 'date',
    dateSetting: { locale: 'en-US' },
    defaultSort: 'desc'
  },
  {
    title: 'Name',
    field: 'displayName',
    width: '35%'
  },
  {
    title: 'Status',
    field: 'status',
    removable: false
  },
  {
    title: 'ID',
    field: 'vendor.vendorCompanyId',
    width: '20%',
    removable: false,
  },
  {
    title: 'Country',
    field: 'countryName',
    width: '13%',
  }
];

export default initialVendorColumns;
