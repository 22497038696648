import React from 'react';
import { useRoutes } from 'hookrouter';
import styled from 'styled-components';
import User, * as UserMethods from 'data/User';
import AccountSettingsPassword from 'common/AccountSettingsPassword';
import AccountSettingsUser from 'common/AccountSettingsUser';
import AccountSettingsNotifications from 'common/AccountSettingsNotifications';
import SectionLink from 'view/SectionLink';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import PageHeading from 'components/UI/PageHeading';
import { Color, Size } from 'lib/styles';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { Space } from 'styles/themeSpaces';
import { Background } from 'styles/themeColors';
import { loadInvites } from 'requests/invite';

const Container = styled.div`
  margin: ${Space.sp2x} auto;
  padding: 0 ${Space.sp2x};
  width: 100%;
`;

const Content = styled.div`
  flex-grow: 1;
  padding: ${Size.medium};
`;

const Nav = styled.nav`
  border-right: 1px solid ${Color.gray};
  display: flex;
  flex-direction: column;
  min-width: 200px;
  padding: ${Size.medium};
`;

const Settings = styled.div`
  background-color: ${Background.white};
  border: 1px solid ${Color.gray};
  border-radius: ${Size.tiny};
  display: flex;
  margin: 0px auto;
  width: 100%;
  margin-top: ${Size.large};
`;

const Subtitle = styled.p`
  color: ${Color.black};
  margin: 0px auto;
  width: 100%;
`;

const Table = styled.table`
  width: 100%;

  th {
    text-align: left;
  }
`;

interface ContentProps {
  user: User;
}

function InvitationsContent() {
  const invitesReq = useAPI({ promiseFn: loadInvites });

  let details = null;
  if (invitesReq.isLoading || !invitesReq.data) {
    details = <LoadingIndicator />;
  } else if (invitesReq.data.length > 0) {
    const rows = invitesReq.data.map(invite => (
      <tr key={invite.id}>
        <td>{new Date(invite.created).toDateString()}</td>
        <td>{invite.user.email}</td>
        <td>
          <a href={`/accept-invite/${invite.id}`}>{invite.status}</a>
        </td>
      </tr>
    ));

    details = (
      <Table>
        <thead>
          <tr>
            <th>Invited</th>
            <th>Invitee</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
    );
  } else {
    details = <p>No invites sent.</p>;
  }

  return (
    <Content>
      <h2>Invitations</h2>
      {details}
    </Content>
  );
}

export default function AccountSettings() {
  const { user } = useAuth();
  let navLinks = null;

  const adminRoutes = {
  '/': () => () => (
    <Content>
      <AccountSettingsUser />
    </Content>
  ),
  '/invitations': () => () => (
    <InvitationsContent />
  ),
  '/password': () => () => (
    <Content>
      <AccountSettingsPassword />
    </Content>
  ),
  '/notifications': () => () => (
    <Content>
      <AccountSettingsNotifications />
    </Content>
  )
};

const vendorRoutes = {
  '/': () => () => (
    <Content>
      <AccountSettingsUser />
    </Content>
  ),
  '/password': () => () => (
    <Content>
      <AccountSettingsPassword />
    </Content>
  )
};

  const userRoutes = user && UserMethods.isVendor(user) ? vendorRoutes : adminRoutes;
  const displayContent = useRoutes(userRoutes);

  if (!UserMethods.isVendor(user!)) {
    navLinks = (
      <>
        <SectionLink href="/account/invitations">Invitations</SectionLink>
        <SectionLink href="/account/notifications">Notifications</SectionLink>
      </>
    );
  }

  return (
    <Container>
      <PageHeading>Account settings</PageHeading>
      <Subtitle>Change your profile and account settings.</Subtitle>
      <Settings>
        <Nav>
          <SectionLink href="/account">Account</SectionLink>
          <SectionLink href="/account/password">Password</SectionLink>
          {navLinks}
        </Nav>
        {displayContent !== null && displayContent()}
      </Settings>
    </Container>
  );
}
