import Button from 'components/UI/Button';
import { renderToast, updateToast } from 'components/UI/ToastNotification';
import Vendor from 'data/Vendor';
import { navigate } from 'hookrouter';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import Product, * as Prod from 'data/Product';
import Currency from 'data/Currency';
import Tooltip from 'components/UI/Tooltip';
import styled from 'styled-components';
import { TEXT_LABELS } from 'constants/productDetails';
import { Background, Shade } from 'styles/themeColors';
import { MAX_PRODUCT_NAME_LENGTH } from 'constants/product';

const TooltipContent = styled.div`
  text-align: left;
  white-space: initial;
  width: 15rem;
`;

interface Props {
  loading: boolean;
  detailsUrl: string;
  saveDisabled: boolean;
  setSaveDisabled: (value: boolean) => void;
  productDetails: Product | undefined;
  currentVendor: Vendor;
  token: string | null;
  currencies: Currency[];
  productErrorsCount: number;
  variantErrorsCount: number;
  setIsSubmitModalOpen: (value: boolean) => void;
  setValidation: (value: boolean) => void;
  view: string;
  keepWarehouse: boolean;
  setKeepWarehouse: (value: boolean) => void;
  loadProducts: () => void;
  warehouseLength?: number;
  disabled ?: boolean;
  setLoading: (val: boolean) => void;
  setIsErrorHighlight: (val: boolean) => void;
}
export default function HeaderButtonComponent({
  loading,
  detailsUrl,
  saveDisabled,
  setSaveDisabled,
  productDetails,
  currentVendor,
  token,
  currencies,
  variantErrorsCount,
  productErrorsCount,
  setIsSubmitModalOpen,
  setValidation,
  view,
  keepWarehouse,
  setKeepWarehouse,
  loadProducts,
  warehouseLength,
  disabled,
  setLoading,
  setIsErrorHighlight
}: Props) {


  const warehouseValidated = (productDetails: Product | undefined) => {
    const hasVariantsHavePurchaseType = productDetails?.variants.some((variant) => variant.purchase );
    const hasVariantsHaveRentType = productDetails?.variants.some((variant) => variant.rent );

    const purchaseWarehouseAdded = productDetails?.warehouses.some((warehouse) => warehouse.type === TEXT_LABELS.PURCHASE_C );
    const rentWarehouseAdded = productDetails?.warehouses.some((warehouse) => warehouse.type === TEXT_LABELS.RENT_C );
    const toastId = TEXT_LABELS.SAVE_PRODUCT;
    if((hasVariantsHavePurchaseType &&  hasVariantsHaveRentType) && (!purchaseWarehouseAdded && !rentWarehouseAdded)){
      renderToast(
        toast.TYPE.ERROR,
        TEXT_LABELS.BOTH_WAREHOUSE_ERROR,
        {
          toastId,
          autoClose: false,
        }
      );
      return false
    }
    if(hasVariantsHavePurchaseType && !purchaseWarehouseAdded ){
      renderToast(
        toast.TYPE.ERROR,
        TEXT_LABELS.PURCHASE_WAREHOUSE_ERROR,
        {
          toastId,
          autoClose: false,
        }
      );
      return false
    }

    if(hasVariantsHaveRentType && !rentWarehouseAdded ){
      renderToast(
        toast.TYPE.ERROR,
        TEXT_LABELS.RENT_WAREHOUSE_ERROR,
        {
          toastId,
          autoClose: false,
        }
      );
      return false
    }
    return true
  }

  function save() {
    if (!productDetails || !currentVendor) return;

    const toastId = TEXT_LABELS.SAVE_PRODUCT;

    //condition to check if warehouse added
   if(!warehouseValidated(productDetails)){
    return;
   }
    if(saveDisabled){
      renderToast(
        toast.TYPE.ERROR,
        TEXT_LABELS.SAVE_PRODUCT_ERROR,
        {
          toastId,
          autoClose: false,
        }
      );
      setIsErrorHighlight(true)
      return
    }

    if (currentVendor) {
      setSaveDisabled(true);
      if(productDetails.name.length > MAX_PRODUCT_NAME_LENGTH){
        renderToast(
          toast.TYPE.ERROR,
          TEXT_LABELS.PRODUCT_NAME_LENGTH_ERROR,
          {
            toastId,
            autoClose: false,
          }
        );
        setSaveDisabled(false);
        return;
      }else{
        renderToast(
          toast.TYPE.INFO,
          TEXT_LABELS.PRODUCT_SAVING,
          {
            toastId,
            autoClose: false,
          }
        );
        setLoading(true)
        Prod.saveLocalDraft(
          productDetails,
          currentVendor,
          token as string,
          currencies
        )
          .then((res) => {
            setSaveDisabled(false);
            if (res?.message) {
              updateToast(toastId, toast.TYPE.ERROR, res.message, {
                autoClose: 5000,
              });
            } else {
              updateToast(
                toastId,
                toast.TYPE.SUCCESS,
                TEXT_LABELS.DRAFT_SAVED_SUCCESS,
                {
                  autoClose: 5000,
                }
              );
              loadProducts()
              if(keepWarehouse){
                navigate(`${detailsUrl}/variants`)
              } else if (!productDetails.id) {
                navigate(`/products/product-details/${res.id}`);
              }
            }
          })
          .catch((err) => {
            setSaveDisabled(false);
  
            updateToast(toastId, toast.TYPE.ERROR, err.message, {
              autoClose: 5000,
            });
          });
      }
    } else {
      renderToast(toast.TYPE.INFO, TEXT_LABELS.SELECT_VENDOR_BEFORE_SAVE);
      return;
    }
  }

  useEffect(() => {
    if (keepWarehouse) {
      save();
      setKeepWarehouse(false);
    }
  }, [keepWarehouse]);

  const validateSubmit = () => {
    if (productDetails && productDetails.warehouses.length === 0) {
      setValidation(true);
    }if(!warehouseValidated(productDetails)){
      return;
    } 
    else {
      setIsSubmitModalOpen(true);
    }
  };

  const previewButton = (
    <Button
      disabled={loading}
      key="preview"
      bgColor="#FFFFFF"
      color={Background.navy}
      borderColor={Shade.shade550}
      onClick={() => navigate(`${detailsUrl}/preview`)}
      margin="5px 0px"
    >
      {TEXT_LABELS.PREVIEW}
    </Button>
  );

  const saveButton = (
    <Button
      disabled={disabled}
      key="save"
      onClick={save}
      bgColor="#FC780B"
      borderColor="#FC780B"
      color="#FFFFFF"
      margin="5px 0px"
    >
      {TEXT_LABELS.SAVE}
    </Button>
  );

  const submitButton = (
    <Button
      margin="5px 4px"
      bgColor="#FC780B"
      borderColor="#FC780B"
      color="#FFFFFF"
      disabled={loading || disabled || !warehouseLength || productErrorsCount + variantErrorsCount > 0}
      key="submit"
      onClick={validateSubmit}
    >
      {TEXT_LABELS.SUBMIT}
    </Button>
  );

  const submitButtonWithTooltip =
    ((productErrorsCount + variantErrorsCount  > 0) || !warehouseLength) ? (
      <Tooltip
        content={
          <TooltipContent>
            {TEXT_LABELS.PRODUCT_MAY_NOT_PUBLISHED}
          </TooltipContent>
        }
        key="submit"
        size="large"
      >
        {submitButton}
      </Tooltip>
    ) : (
      submitButton
    );
  return (
    <>
      {view !== TEXT_LABELS.WAREHOUSES ? previewButton : ''}
      {submitButtonWithTooltip}
      {saveButton}
    </>
  );
}
