import React, { Fragment } from 'react';
import { Heading2, Heading5, Paragraph, Card, GridContainer, GridItemCallout } from 'OnboardingComponents/Elements';

const SalesTeam = () => (
  <Fragment>
    <Heading2 id='sales-team'>Your sales teams</Heading2>
    <Paragraph>Industry researchers have found that B2B “customers now wait until they are 57% through the purchase process before contacting a rep.” This bestows great responsibility upon the product content – including descriptions, imagery, and additional technical documents – that you place on The OFMP. It also gives your sales representatives additional time for nurturing high-value relationships as more and more buyers turn to eCommerce.</Paragraph>
    <Paragraph>The question remains: how can you effectively communicate the benefits of selling online to sales folks that don’t yet understand the benefits of transforming their tactics? The following are a few proven approaches to get you started...</Paragraph>

    <GridContainer>
      <GridItemCallout>
        <Card>
          <Heading5>Equate eCommerce business with an increase in earnings</Heading5>
          <Paragraph>Convince sales folks that eCommerce does not endanger their bonuses – even make a prediction as to how their sales will increase if they help grow the platform.</Paragraph>
        </Card>
      </GridItemCallout>
      <GridItemCallout>
        <Card>
          <Heading5>Communicate the benefits of digital vs sales “as-is”</Heading5>
          <Paragraph>Show the industry obstacles – specifically within the lens of sales – generated by the lack of an online marketplace.</Paragraph>
        </Card>
      </GridItemCallout>
      <GridItemCallout>
        <Card>
          <Heading5>Adopt a contagious pioneer mentality</Heading5>
          <Paragraph>Paint the bigger picture of what being a pioneer in our industry means and how it garners more recognition, more brand value, and subsequently more sales.</Paragraph>
        </Card>
      </GridItemCallout>
    </GridContainer>
  </Fragment>
);

export default SalesTeam;
