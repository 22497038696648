import React from 'react';
import Autosuggest from 'react-autosuggest';
import Keyword from 'data/Keyword';

const AutosuggestRenderInput = ({ addTag, ...props }: any) => {
  const handleOnChange = (e: any, { newValue, method }: any) => {
    if (method === 'enter') {
      e.preventDefault();
    } else {
      props.onChange(e);
    }
  }

  const inputValue = (props.value && props.value.trim().toLowerCase()) || '';
  const inputLength = inputValue.length;

  let suggestions = props.keywords.filter((keyword: Keyword) => {
    return keyword.name.toLowerCase().slice(0, inputLength) === inputValue;
  })

  return (
    <Autosuggest
      ref={props.ref}
      suggestions={suggestions}
      shouldRenderSuggestions={(value: any) => Boolean(value && value.trim().length > 0)}
      getSuggestionValue={(suggestion: any) => suggestion.name}
      renderSuggestion={(suggestion: any) => <span>{suggestion.name}</span>}
      inputProps={{ ...props, onChange: handleOnChange }}
      onSuggestionSelected={(e: any, { suggestion }: any) => {
        addTag(suggestion.name)
      }}
      onSuggestionsClearRequested={() => { }}
      onSuggestionsFetchRequested={() => { }}
    />
  )
};

export default AutosuggestRenderInput;
