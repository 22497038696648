import React from 'react';
import styled, { css } from 'styled-components';
import { Background } from 'styles/themeColors';

const DividerContainer = styled.div<any>`
  position: relative;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  height: ${({ height }) => height};
  content: "";
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
  background-color: ${({ bgColor }) => bgColor};

  &:last-child:not(:only-child) {
    display: none;
  }

  ${({ noMarginX }) => noMarginX && css`
    margin-left: 0;
    margin-right: 0;
  `}

  ${({ marginY }) => marginY && css`
    margin-top: ${marginY};
    margin-bottom: ${marginY};
  `}
`;

export interface Props {
  bgColor?: string;
  className?: string;
  maxWidth?: string;
  height?: string;
  noMarginX?: boolean;
  marginY?: string;
  width?: string;
}

const Divider = ({
  bgColor,
  className,
  maxWidth,
  height,
  noMarginX,
  marginY,
  width
}: Props) => {
  const sharedProps = {
    bgColor,
    maxWidth,
    height,
    noMarginX,
    marginY,
    width
  };

  return (
    <DividerContainer {...sharedProps} />
  );
}

Divider.defaultProps = {
  bgColor: Background.white,
  width: '100%',
  height: '1px',
  noMarginX: false,
};

export default Divider;