import React from 'react';
import styled from 'styled-components';
import { Color, Size } from 'lib/styles';

const Container = styled.div`
  padding: ${Size.tiny} 0;
`;

const Placeholder = styled.div`
  align-items: center;
  background: ${Color.lightGray};
  border: 1px solid ${Color.gray};
  border-radius: ${Size.tiny};
  display: flex;
  /* Compensate for border width to keep things from jumping around */
  height: calc(4rem + 2px);
  justify-content: center;
`;

interface Props {
  className?: string;
  icon: React.ReactNode;
}

export default function AssetCardPlaceholder({ className, icon }: Props) {
  return (
    <Container className={className}>
      <Placeholder>{icon}</Placeholder>
    </Container>
  );
}

AssetCardPlaceholder.defaultProps = {};
