import React from 'react';
import Vendor from 'data/Vendor';
import OrdersList from 'components/Orders/OrderList/OrdersList';
import FeatureFlag from 'data/FeatureFlag';

interface Props {
  id: number;
  companyId: string;
  featureFlags: FeatureFlag,
  selectedVendor?: Vendor;
};

const OrderHistoryTab = ({
  id,
  companyId,
  selectedVendor,
  featureFlags
}: Props) => {
  return (
    <OrdersList
      selectedVendor={selectedVendor}
      shouldRedirect={false}
      headingText="Past orders"
      pagePadding="0"
      navigateBackUrl={`/companies/company-details/${id}`}
      companyId={companyId}
      featureFlags={featureFlags}
    />
  );
};

export default OrderHistoryTab;
