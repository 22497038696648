import Order from '../../../data/Order';
import { CANCELED, CANCELING, DELIVERED_PICKED_UP, PICKED_UP, SHIPPED } from '../../../constants/consignmentStates';
import { applySortFilter, SortByConfig } from '../../../utils/grid';
import { COUNTRY_SELECTOR_DEFAULT } from './orderlist.enums';
import { DOW_JONES_BLOCKED, DOW_JONES_REVIEWED_BLOCKED } from 'constants/dowJonesStatuses';

const applyCountryFilter = (orders: Order[], selectedCountry?: string) => {
  if (!selectedCountry || selectedCountry === COUNTRY_SELECTOR_DEFAULT) return orders;

  return orders.filter(order => order.company?.companyCountry?.countryName === selectedCountry);
}
const applyCompanyFilter = (orders: Order[], companyId?: string) => {
  if (!companyId) return orders;

  return orders.filter(order => order.companyId === companyId);
}

const applySwitchFilter = (orders: Order[], switchKey: boolean) => {
  return orders.filter(order => {
    const consignmentStates = order.consignments.map(consignment => consignment.state);

    if (switchKey) {
      return consignmentStates.some(state =>
        ![DELIVERED_PICKED_UP, CANCELED, CANCELING, PICKED_UP, SHIPPED].includes(state)
      );
    }

    return true;
  });
};

const applyCanceledSwitchFilter = (orders: Order[], switchKey: boolean) => {
  return orders.filter(order =>
    switchKey
      ? order.state === CANCELED && 
      !order.cancelEmailSentDate && 
      (order.dowJones?.status === DOW_JONES_BLOCKED || order.dowJones?.status === DOW_JONES_REVIEWED_BLOCKED)
      : true
  );
};

const applyOrderSortAndFiltering = (
  orders: Order[],
  selectedCountry: string,
  companyId: string | undefined,
  showOpen: boolean,
  showPendingCancellationEmail: boolean,
  sortBy: SortByConfig | undefined) => {
  const callQue = [
    {
      callback: applyCountryFilter,
      params: selectedCountry
    },
    {
      callback: applyCompanyFilter,
      params: companyId
    },
    {
      callback: applySwitchFilter,
      params: showOpen
    },
    {
      callback: applyCanceledSwitchFilter,
      params: showPendingCancellationEmail
    },
    {
      callback: applySortFilter,
      params: sortBy
    }
  ]

  return callQue.reduce((processedOrders, callData)=>{
    const {callback, params} = callData;
    // @ts-ignore
    return callback(processedOrders, params);
  }, orders)
}

export {
  applyOrderSortAndFiltering
}