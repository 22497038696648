import React, { ChangeEvent, useEffect, useState } from 'react';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Input from 'components/UI/Input';
import { DatePicker } from '@material-ui/pickers';
import DatePickerInput from 'components/UI/DatePickerInput';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import { consignmentNewShortPayment } from 'requests/consignment';
import { useAPI } from 'lib/API';
import { toast } from 'react-toastify';
import { renderToast } from 'components/UI/ToastNotification';
import CurrencyInput from 'components/UI/CurrencyInput';

const DatePickerContainer = styled(FlexboxContainer)`
    margin-right: 4px;
`;

interface Props {
  show: boolean;
  consignmentId: string;
  outstandingCredit: number;
  paidAmount: number;
  onCancel: () => void;
  reload: () => void;
}

const paidDefaultValue = { value: '', invalid: false };
const transactionDefaultValue = { value: '', invalid: false };
const dateDefaultValue = new Date().toUTCString();

const ConsignmentShortPaymentModal = ({
  show,
  consignmentId,
  outstandingCredit,
  onCancel,
  reload
}: Props) => {

  const [paid, setPaid] = useState(paidDefaultValue);
  const [date, setDate] = useState(dateDefaultValue);
  const [transaction, setTransaction] = useState(transactionDefaultValue);

  const consignmentReq = useAPI({
    deferFn: consignmentNewShortPayment,
    onReject: err => renderToast(toast.TYPE.ERROR, err.message)
  });

  const isPaidInvalid = (value?: string) => {
    const number = value && Number(value);

    return (number)
      ? (number === 0 || number > outstandingCredit)
      : true;
  };

  const isTransactionInvalid = (value?: any) => {
    return !value;
  };

  const paidInputErrorMessage = (paid.invalid && Number(paid.value) > outstandingCredit)
    ? 'Credit can not be overpaid'
    : 'This field is required';

  const paidDescriptionMessage = 'Credit can not be overpaid';

  const handleOnChange = (value?: string) => {
    setPaid({
      ...paid,
      value: value ? value : '',
      invalid: isPaidInvalid(value)
    });
  };

  const handleDateChange = (value: any) => setDate(new Date(value).toUTCString());

  const handleTransactionNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTransaction({
      ...transaction,
      value: e.target.value,
      invalid: isTransactionInvalid(e.target.value)
    });
  };

  const reset = () => {
    setPaid(paidDefaultValue);
    setDate(dateDefaultValue);
    setTransaction(transactionDefaultValue);
  };

  const handleAddShortPayment = () => {
    const invalidPaid = isPaidInvalid(paid.value);
    const invalidTransaction = isTransactionInvalid(transaction.value);

    if (invalidPaid) {
      setPaid({ ...paid, invalid: invalidPaid });
      return;
    }

    if (invalidTransaction) {
      setTransaction({ ...transaction, invalid: invalidTransaction });
      return;
    }

    consignmentReq.run(
      consignmentId,
      Number(paid.value),
      transaction.value,
      date
    );

    handleClose();
    reload();
    renderToast(toast.TYPE.SUCCESS, 'Credit utilization updated');
  };

  const handleClose = () => {
    reset();
    onCancel();
  };

  useEffect(() => {
    if (outstandingCredit) setPaid({ ...paid, value: outstandingCredit.toString() });
  }, [outstandingCredit])

  return (
    <Modal
      showButtons
      showModal={show}
      title='Order credit'
      ghostButtonText='Cancel'
      primaryButtonText='Add'
      onClose={handleClose}
      onClick={handleAddShortPayment}
    >
      <CurrencyInput
        disabled
        blocked
        textAlign='right'
        id='short-payment-modal-outstanding-credit-input-id'
        labelName='Outstanding credit'
        value={outstandingCredit.toFixed(2)}
        labelPosition='left'
        width='100%'
        prefix='$'
      />
      <CurrencyInput
        required
        selectAllOnFirstClick
        id='short-payment-modal-paid-credit-input-id'
        name="short-payment-modal-paid-credit-input-name"
        prefix='$'
        textAlign='right'
        labelName='Paid amount to add'
        value={paid.value}
        isInvalid={paid.invalid}
        labelPosition='left'
        placeholder=''
        errorMessage={paidInputErrorMessage}
        description={paidDescriptionMessage}
        decimalsLimit={2}
        onValueChange={handleOnChange}
      />
      <DatePickerContainer width='100%' padding='4px'>
        <label className='base'>
          Date of payment
        </label>
        <div style={{ marginLeft: '15.5%', width: '40%' }}>
          <DatePicker
            value={date}
            onChange={handleDateChange}
            inputVariant="outlined"
            format="yyyy MMM dd"
            TextFieldComponent={DatePickerInput}
            autoOk
          />
        </div>
      </DatePickerContainer>
      <Input
        required
        id='short-payment-modal-transaction-input-id'
        labelName='Transaction number'
        value={transaction.value}
        isInvalid={transaction.invalid}
        type='number'
        onChange={handleTransactionNumberChange}
        labelPosition='left'
        width='100%'
        placeholder=''
      />
    </Modal>
  );
};

export default ConsignmentShortPaymentModal;