import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Textarea from 'components/UI/Textarea';

const Label = styled.label`
  margin-bottom: 16px;
`;

interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: (reasonComment: string) => void;
  title: string;
  labels: string[];
  textareaPlaceholder?: string;
}

const ConsignmentCancelationModal = ({
  show,
  onClose,
  onConfirm,
  title,
  labels,
  textareaPlaceholder
}: Props) => {

  const [cancelReason, setCancelReason] = useState('');
  const [isDirty, setIsDirty] = useState(false);

  const handleCancelation = () => {
    setCancelReason('');
    setIsDirty(false);
    onClose();
  };

  const handleConfirmation = () => {
    if (!cancelReason) {
      setIsDirty(true);
      return;
    } 
    
    onConfirm(cancelReason);
    handleCancelation();
  };

  return (
    <Modal
      showButtons
      showModal={show}
      title={title}
      ghostButtonText='Do not cancel'
      primaryButtonText='Confirm cancelation'
      onClose={handleCancelation}
      onClick={handleConfirmation}
      theme='error'
    >
      <FlexboxContainer flexDirection="column">
        {labels.map((label, index) => (
          <Label key={index} className='base'>
            {label}
          </Label>
        ))}
        <Textarea
          required
          id='consignment-cancelation-reason-textarea-id'
          placeholder={textareaPlaceholder}
          label='Cancelation reason (required)'
          value={cancelReason}
          onChange={setCancelReason}
          dirty={isDirty}
        />
      </FlexboxContainer>
    </Modal>
  );
};

export default ConsignmentCancelationModal;
