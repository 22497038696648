import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { FontSize, FontWeight, Color } from 'lib/styles';

const Heading = styled.div<any>`
  color: ${Color.black};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${FontWeight.bold};
  margin: ${({margin}) => margin};
`;

interface Props {
  children: ReactNode;
  fontSize?: string;
  margin?: string;
};

const SectionHeading = ({
  children,
  fontSize,
  margin
}: Props) => {
  return <Heading fontSize={fontSize} margin={margin}>{children}</Heading>;
};

SectionHeading.defaultProps = {
  fontSize: FontSize.large
};

export default SectionHeading;
