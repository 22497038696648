import { put, post } from 'lib/API';
import { AttributeImportResponse, AttributeValueRequest, MergeRequest, ReorderRequest } from 'data/Attribute';

export const createAttributes = async (
  [attributes, vendorId]: any,
  { token }: any
): Promise<any[]> => {
  return Promise.allSettled(
    attributes.map((attribute: {name: string, isSelectable: boolean}) => {
      return create(attribute.name, vendorId, token!, undefined, attribute.isSelectable);
    })
  );
};

export const create = (attributeName: string, vendorId: number, token: string, productId?: number, isSelectable?: boolean): Promise<any> => {
  return post('v1/attributes', token, {
    name: attributeName,
    vendorId,
    productId,
    isSelectable
  });
};

export const addAttributeValue = async (token: string, attributeValue: any): Promise<AttributeValueRequest> => {
  return await post('v1/attributeValue', token, attributeValue);
};

export const addAttributeValues = async (token: string, attributeValues: any): Promise<AttributeImportResponse> => {
  return await post('v1/attributeValue/attributeValues', token, attributeValues);
};

export const mergeRequest = async (token: string, mergeReq: MergeRequest, param: string): Promise<any> => {
  return await post(`v1/${param}/merge`, token, mergeReq);
};

export const reoderRequest = async (token: string, mergeReq: ReorderRequest): Promise<any> => {
  return await put('v1/attributes', token, mergeReq);
};

export const updateAttributeValue = async (token: string, value: AttributeValueRequest): Promise<any> => {
  return await put(`v1/attributeValue/${value.id}`, token, value);
};
