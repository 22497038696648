import { Text } from 'styles/themeColors';
import { capitalize, currencySign, formatDateTime } from '../../utils/formatters';
import { Column } from 'material-table';

const CampaignTableColumns:Column<any>[] = [
  {
    title: 'Vendor',
    field: 'vendorId',
    headerStyle: { color: Text.primary },
    width: '13%',
    sorting: true,
    render: (rowData) => capitalize(rowData.vendor?.name, 'N/A'),
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'Campaign Title',
    field: 'title',
    headerStyle: { color: Text.primary },
    width: '13%',
    sorting: true,
    render: (rowData) => capitalize(rowData.title, 'N/A'),
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'Country',
    field: 'countryId',
    headerStyle: { color: Text.primary },
    width: '10%',
    sorting: true,
    render: (rowData) => capitalize(rowData.country?.countryName, 'N/A'),
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'GTM id',
    field: 'eventId',
    headerStyle: { color: Text.primary },
    width: '9%',
    sorting: true,
    render: (rowData) => rowData.eventId || 'N/A',
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'Campaign Type',
    field: 'campaignTypeId',
    headerStyle: { color: Text.primary },
    width: '13%',
    sorting: true,
    render: (rowData) => capitalize(rowData.campaignType?.name, 'N/A'),
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'Start Date',
    field: 'startDate',
    headerStyle: { color: Text.primary },
    width: '9%',
    sorting: true,
    render: (rowData) => formatDateTime(rowData.startDate, false, false),
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'End Date',
    field: 'endDate',
    headerStyle: { color: Text.primary },
    width: '9%',
    sorting: true,
    render: (rowData) => formatDateTime(rowData.endDate, false, false, 'N/A'),
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'Launch Company',
    field: 'campaignLaunchCompanyName',
    headerStyle: { color: Text.primary },
    width: '13%',
    sorting: true,
    render: (rowData) => capitalize(rowData.launchCompany?.companyName, 'N/A'),
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'Launch Person',
    field: 'launchPerson',
    headerStyle: { color: Text.primary },
    width: '12%',
    sorting: true,
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'Budget',
    field: 'budget',
    headerStyle: { color: Text.primary },
    width: '9%',
    sorting: true,
    render: (rowData) => currencySign(rowData.country?.countryIso, rowData.budget),
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'Actual Spend',
    field: 'actualSpend',
    headerStyle: { color: Text.primary },
    width: '11%',
    sorting: true,
    render: (rowData) => currencySign(rowData.country?.countryIso, rowData.actualSpend),
    cellStyle: { padding: '0px 5px 0px 0px'},
  },
  {
    title: 'Actions',
    field: 'actions',
    removable: false,
    width: '10%',
    sorting: false,
    cellStyle: { padding: '0px 5px 0px 0px'},
  }
]

export { CampaignTableColumns};