import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import Spinner from 'components/UI/Spinner';
import { Close } from '@material-ui/icons';
import {
  fileSizeToMb,
} from '../Pricebooks/priceebook.utils';
import { TEXT_LABELS } from '../../constants/productVariantImport';

const Span2 = styled.span`
  text-align: left;
  font-size: 14px;
  margin-top: 4px;
`;

const UploadError = styled.span`
  text-align: left;
  font-size: 12px;
  color: #c82135;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
`;

type File = {
    imageFile: [{name: string, size: number}]
}

interface Props {
    uploadSuccess: Boolean,
    file: File,
    handleClear: () => void,
    handleUploadClick: () => void,
    handleFileChange: (e: ChangeEvent<HTMLInputElement>) => void,
    inputRef: React.MutableRefObject<HTMLInputElement | null>,
    uploadFileSizeError: Boolean,
    uploadFileTypeError: Boolean,
    uploadFileTemplateError: Boolean,
    fileUploading: Boolean,
  }

export default function UploadComponent({uploadSuccess,fileUploading, file, handleUploadClick, handleClear, inputRef, handleFileChange,
    uploadFileSizeError,
    uploadFileTypeError,
    uploadFileTemplateError
}: Props) {

    
  return (
    <FlexboxContainerComponent flexDirection="column" alignItems="center">
      <FlexboxContainerComponent
        justifyContent="center"
        margin="32px 0px 0px 0px"
      >
        <Button
          bgColor="#FFFFFF"
          color={
            uploadSuccess ||
            uploadFileSizeError ||
            uploadFileTypeError ||
            uploadFileTemplateError
              ? '#FFFFFF'
              : '#18222B'
          }
          borderColor="#666C73"
          padding="10px 0"
          width="320px"
          onClick={file.imageFile.length > 0 ? () => {} : handleUploadClick}
          cursor={file.imageFile.length > 0 ? 'initial' : 'pointer'}
          background={
            uploadSuccess
              ? '#18222B'
              :  uploadFileSizeError ||
              uploadFileTypeError ||
              uploadFileTemplateError
              ? '#C82135'
              : '#FFFFFF'
          }
        >
          <div></div>
          <FlexboxContainerComponent width="100%">
            <div
              style={{
                width: file.imageFile.length > 0 ? '90%' : '100%',
                textAlign: 'center',
                alignSelf: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
               {file.imageFile.length > 0
                ? file.imageFile[0]?.name
                : TEXT_LABELS.SELECT_XLXS}
            </div>

            {file.imageFile.length > 0 ? (
              <FlexboxContainerComponent>
                <CloseIcon id="close-icon" onClick={handleClear} />{' '}
              </FlexboxContainerComponent>
            ) : (
              ''
            )}
          </FlexboxContainerComponent>
        </Button>
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </FlexboxContainerComponent>
      <FlexboxContainerComponent width="320px">
        {uploadFileSizeError ? (
          <UploadError>
            {Number(fileSizeToMb(file.imageFile[0]?.size)).toPrecision(4)} MB.
            {TEXT_LABELS.UPLOAD_SIZE_LESS_THAN_20}
          </UploadError>
        ) : uploadFileTypeError ? (
          <UploadError>{TEXT_LABELS.FILE_FORMAT_XLXS}</UploadError>
        ) : uploadFileTemplateError ? (
          <UploadError>
            {TEXT_LABELS.WRONG_COLUMN_SET}
          </UploadError>
        ) : fileUploading &&
          !uploadFileSizeError &&
          !uploadFileTemplateError &&
          !uploadFileTypeError ? (
          <Span2>
            <Spinner color="black" size={14} /> {TEXT_LABELS.DONOT_CLOSE_WINDOW}
          </Span2>
        ) : uploadSuccess ? (
          <Span2>
            {Number(fileSizeToMb(file.imageFile[0]?.size)).toPrecision(4)}mb
          </Span2>
        ) : (
          <Span2>{TEXT_LABELS.UPTO_20_MB}</Span2>
        )}
      </FlexboxContainerComponent>
    </FlexboxContainerComponent>
  )

}