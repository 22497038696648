import React, { FunctionComponent, useEffect, useState } from "react";
import Page from "components/UI/Page";
import { Background, Main } from "styles/themeColors";
import TabsComponent from "components/UI/Tabs";
import { VENDOR_TABS } from "constants/tabs";
import FlexboxContainerComponent from "components/UI/FlexboxContainer";
import styled from "styled-components";
import Button from "components/UI/Button";
import { BorderRadius } from "styles/themeBorderRadius";
import { Color } from "lib/styles";
import AutomationSettings from "./AutomationSettings";
import { useAuth } from "lib/Auth";
import { useAPI } from "lib/API";
import { createVendor, loadVendor, loadVendors, saveVendor } from "requests/vendor";
import { renderToast } from "components/UI/ToastNotification";
import { toast } from "react-toastify";
import { postMedia } from "requests/media";
import ErrorMessage from 'view/ErrorMessage';
import { mapDataToVendor, validateVendorData } from "utils/vendor";
import { navigate } from "hookrouter";
import AboutVendorAdminView from "./AboutVendorAdminView";
import FeatureFlag from "data/FeatureFlag";
import GeneralSettings from "./GeneralSettings";
import { REMINDERS_DAYS_LIMIT } from "constants/vendor";


const hasDataInRequiredFields = (vendor: any) => {

    if (vendor.name.trim().length === 0) {
        return false;
    }

    if (vendor.code.trim().length === 0) {
        return false;
    }

    if (vendor.media.length === 0) {
        return false;
    }
    if (vendor.rentReminderDays === undefined || vendor.rentReminderDays === '') {
        return false;
    }

    const reminderDays = parseInt(vendor.rentReminderDays);
    if (isNaN(reminderDays) || reminderDays < REMINDERS_DAYS_LIMIT.MIN || reminderDays > REMINDERS_DAYS_LIMIT.MAX) {
        return false;
    }

    return true;
}

interface UpsertVendorProps {
    vendorId?: number;
    setVendorsListCallBack?: any
    featureFlags: FeatureFlag
}

const UpsertVendor: FunctionComponent<UpsertVendorProps> = ({ vendorId, setVendorsListCallBack, featureFlags }) => {

    const { token } = useAuth();
    const [vendor, setVendor] = useState<any>({
        name: '',
        code: '',
        description: '',
        media: [],
        isMaterialApiEnabled: false,
        isStockApiEnabled: false,
        isSlbVendor: true,
        isSalesApiEnabled: false,
        rentReminderDays: REMINDERS_DAYS_LIMIT.MIN
    });

    const [isMediaUploading, setIsMediaUploading] = useState(false);
    const [media, setMedia] = useState<MediaImage | undefined>();

    const vendorsReq = useAPI({ 
        deferFn: loadVendors,
        onResolve: (res) => {
            setVendorsListCallBack(res);
            renderToast(toast.TYPE.SUCCESS, 'Vendor updated.');
            navigate('/vendors');
          },
          onReject: err => {
          } 
    });
    const [errors, setErrors] = useState<any>({});
    const [initialVendor, setInitialVendor] = useState<any>({});


    const vendorReq = useAPI({ deferFn: loadVendor });


    const createReq = useAPI({
        deferFn: createVendor,
        onResolve: () => {
            renderToast(toast.TYPE.SUCCESS, 'Vendor created.');
            navigate('/vendors');
            
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    const updateReq = useAPI({
        deferFn: saveVendor,
        onResolve: () => {
            vendorsReq.run([token]);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    useEffect(() => {
        if (vendorId) {
            vendorReq.run(vendorId);
        }

        return () => {
            vendorReq.cancel();
        }
    }, []);

    useEffect(() => {
        if (vendorReq.data) {
            const initialVendor = mapDataToVendor(vendorReq.data);
            setVendor(initialVendor);
            setInitialVendor(initialVendor);
        }
    }, [vendorReq.data]);
    

    const handleClick = () => {

        const { isValid, errors } = validateVendorData(vendor);

        if (!isValid) {
            setErrors(errors);
            return;
        }

        if (vendorId) {
            updateReq.run({...vendor,id:vendorId});
            setErrors({});
        } else {
            createReq.run(vendor);
            setErrors({});
        }
    }

    const uploadImage = async (file: File) => {
        try{
            setIsMediaUploading(true);

            const image = await postMedia({
                file,
                token: token as string,
                type: 'vendor_logo',
                vendorId
            })

            setMedia(image as any);
        }catch(err: any){
        }finally{
            setIsMediaUploading(false);
        }
    }

    useEffect(()=>{
        if(media)
        setVendor({...vendor, media: [media]})
    }, [media])

    const cantSubmit =
        vendorId
            ? (updateReq.isPending || vendorsReq.isPending || !hasDataInRequiredFields(vendor) || JSON.stringify(vendor) === JSON.stringify(initialVendor))
            : (createReq.isPending || !hasDataInRequiredFields(vendor) || JSON.stringify(vendor) === JSON.stringify(initialVendor));


    let errorMessage = null;
    if (createReq.error) {
        errorMessage = <ErrorMessage message={createReq.error.message} />;
    } else if (updateReq.error) {
        errorMessage = <ErrorMessage message={updateReq.error.message} />;
    }

    return (
        <Page bgColor={Background.white} width="85%">
            <TabsComponent tabs={VENDOR_TABS} theme='brandDark' borderRadius='20px' margin='8px 24px 8px 0' height='auto' >
                <AboutVendorAdminView
                    vendor={vendor}
                    setVendor={setVendor}
                    uploadImage={uploadImage}
                    disabled={createReq.isPending || updateReq.isPending}
                    vendorId={vendorId}
                    errors={errors}
                    setErrors={setErrors}
                    isMediaUploading={isMediaUploading}
                />
                <AutomationSettings
                    disabled={createReq.isPending || updateReq.isPending}
                    vendor={vendor}
                    setVendor={setVendor}
                    featureFlags={featureFlags}
                />
                 <GeneralSettings
                    disabled={createReq.isPending || updateReq.isPending}
                    vendor={vendor}
                    setVendor={setVendor}
                    errors={errors}
                    setErrors={setErrors}
                 />

            </TabsComponent>
            {errorMessage}
            <FlexboxContainerComponent justifyContent="right">
                <CancelButton key="cancel" onClick={() => navigate('/vendors')}>
                    Cancel
                </CancelButton>
                <SaveButton key="save" onClick={handleClick} disabled={cantSubmit} >
                    Save
                </SaveButton>
            </FlexboxContainerComponent>
        </Page>
    );
}

export default UpsertVendor;

const CancelButton = styled(Button)`
  margin-right: 5px;
  color: ${Color.black} !important;
  background-color: ${Background.white};
  border-color: ${Main.disabled} !important;
`;

const SaveButton = styled(Button)`
  padding: 10px;
  border-radius: ${BorderRadius.m};
  background-color: ${Main.brandDark};
  border-color: ${Main.disabled} !important;
`;


