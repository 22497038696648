import FlexboxContainerComponent from '../../UI/FlexboxContainer';
import { formatDateTime } from '../../../utils/formatters';
import { PricebookExtended, PricebookState } from '../../../data/Pricebook';
import Spinner from '../../UI/Spinner';
import React from 'react';
import styled from 'styled-components';
import { Space } from '../../../styles/themeSpaces';
import { Background, Main, Text } from '../../../styles/themeColors';
import { PricebookFile } from '../pricebook.types';
import { pathToFileToFilename } from '../priceebook.utils';

const ColumnName = styled.div`
  width: 160px;
  font-size: ${Space.sp2x};
  line-height: 24px;
  color: ${Text.primary};
  padding-top: ${Space.spHalf};
  margin-right: ${Space.sp3x};
`;

const ColumnValue = styled.div<{
  $bgcolor?: string,
  $padding?: string,
  $color?: string,
  $weight?: string,
}>`
  font-size: ${Space.sp2x};
  line-height: 20px;
  background-color: ${({ $bgcolor }) => ($bgcolor || 'transparent')};
  padding: ${({ $padding }) => ($padding || '0px')};
  color: ${({ $color }) => ($color || Text.primary)};
  font-weight: ${({ $weight }) => ($weight || '400')};
  border-radius: 6px;
`;

const PricebookPublishText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;

const TEXT_LABELS = {
  PUBLISH_NOW_MODAL_HEADING: 'Publish new prices now?',
  DELETE_PRICEBOOK_HEADING: 'Delete active pricebook?',
  DELETE_PRICEBOOK_WARNING_TEXT:
    'Pricebook will be scheduled for deletion this midnight. A completion reference prices will be used.',
  NEW_PRICEBOOK_VERIFIED_TEXT:
    'New prices will replace current at 00:00. You can close this window.',
  PRICEBOOK_UPLOAD_TITLE: 'New Pricebook (Draft)',
  VERIFYING: 'Verifying, please wait',
  PUBLISHING: 'Publishing, please wait',
  VERIFICATION_FAILED: 'Verification failed',
  VERIFIED: 'Verified. Publication scheduled',
  DEFAULT_FILENAME: 'default.xlsx',
};

const COLUMN_NAMES = {
  UPLOADED: 'Uploaded',
  FILENAME: 'Filename',
  STATUS: 'Status',
  NAME: 'Name',
  ID: 'ID'
}

interface PricebookVerificationProps {
  pricebookFile: PricebookFile;
  pricebookData: PricebookExtended | null,
  isForcePublish: boolean,
}

const PricebookUploadVerification = ({
  pricebookFile,
  pricebookData,
  isForcePublish
}: PricebookVerificationProps) => {

  const filename = pricebookData ? `${pathToFileToFilename(pricebookData.pathToFile)}.xlsx` :
    pricebookFile.uploadedFile?.name || TEXT_LABELS.DEFAULT_FILENAME;

  const creationDate = formatDateTime(
    pricebookData
      ? new Date(pricebookData.createdOn).toDateString()
      : new Date(pricebookFile.uploadDate).toDateString(),
    false);

  return (
    <FlexboxContainerComponent flexDirection='column' margin='32px 0px 0px 0px'>
      {pricebookData ? (
        <FlexboxContainerComponent flexDirection='column'>
          <FlexboxContainerComponent alignItems='center' flexDirection='row'>
            <ColumnName>{COLUMN_NAMES.FILENAME}</ColumnName>
            <ColumnValue>
              {filename}
            </ColumnValue>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent alignItems='center' flexDirection='row'>
            <ColumnName>{COLUMN_NAMES.UPLOADED}</ColumnName>
            <ColumnValue>
              {creationDate}
            </ColumnValue>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent alignItems='center' flexDirection='row'>
            <ColumnName>{COLUMN_NAMES.STATUS}</ColumnName>
            {(!!pricebookData && pricebookData.priceBookState === PricebookState.UPLOADED) && (
              <ColumnValue $bgcolor={Main.warning} $weight='700' $padding='4px'>
                <Spinner size={16} /> {TEXT_LABELS.VERIFYING}
              </ColumnValue>
            )}
            {(isForcePublish || pricebookData.priceBookState === PricebookState.PROCESSING) && (
              <ColumnValue $bgcolor={Main.warning} $weight='700' $padding='4px'>
                <Spinner size={16} /> {TEXT_LABELS.PUBLISHING}
              </ColumnValue>
            )}
            {pricebookData.priceBookState === PricebookState.VALIDATION_FAILED && (
              <ColumnValue
                $bgcolor={Main.error}
                $weight='700'
                $padding='4px'
                $color={Background.white}
              >
                {TEXT_LABELS.VERIFICATION_FAILED}
              </ColumnValue>
            )}
            {(pricebookData.priceBookState === PricebookState.VALIDATED && !isForcePublish) && (
              <ColumnValue
                $bgcolor={Main.success}
                $weight='700'
                $padding='4px'
                $color={Background.white}
              >
                {TEXT_LABELS.VERIFIED}
              </ColumnValue>
              )}
          </FlexboxContainerComponent>
          {(pricebookData.priceBookState === PricebookState.VALIDATED && !isForcePublish) && (
            <>
              <FlexboxContainerComponent
                alignItems='center'
                flexDirection='row'
              >
                <ColumnName>{COLUMN_NAMES.NAME}</ColumnName>
                <ColumnValue>{pricebookData.priceBookName}</ColumnValue>
              </FlexboxContainerComponent>
              <FlexboxContainerComponent
                alignItems='center'
                flexDirection='row'
              >
                <ColumnName>{COLUMN_NAMES.ID}</ColumnName>
                <ColumnValue>{pricebookData.priceBookVendorId}</ColumnValue>
              </FlexboxContainerComponent>
              <PricebookPublishText>
                {TEXT_LABELS.NEW_PRICEBOOK_VERIFIED_TEXT}
              </PricebookPublishText>
            </>
          )}
        </FlexboxContainerComponent>
      ) : (
        <FlexboxContainerComponent flexDirection='row' justifyContent='center'>
          <Spinner />
        </FlexboxContainerComponent>
      )}
    </FlexboxContainerComponent>
  );
}

export default PricebookUploadVerification;