import React from 'react';
import Spinner from 'components/UI/Spinner';
import styled from 'styled-components';
import { Space } from 'styles/themeSpaces';

const Container = styled.div<Props>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  margin: ${({ margin }) => margin ? margin : `${Space.sp4x} auto`};
`;

interface Props {
  className?: string;
  size?: number;
  margin? : string;
};

const LoadingIndicator = ({ className, size, margin }: Props) => {
  return (
    <Container className={className} size={size} margin={margin}>
      <Spinner size={size} />
    </Container>
  );
};

LoadingIndicator.defaultProps = {
  size: 64
};

export default LoadingIndicator;
