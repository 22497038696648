import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Vendor from 'data/Vendor';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, service, Embed, Report } from 'powerbi-client';
import { getReports, getReportsData } from 'requests/powerBiReports';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import FeatureFlag from 'data/FeatureFlag';
import usePathState from 'hooks/usePathState';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Breadcrumbs from 'components/UI/Breadcrumb';
import { usePath } from 'hookrouter';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import Tabs from 'components/UI/Tabs';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { Role, isAdmin, isSuperAdmin, isVendor, isVendorAdmin } from 'data/User';
import { loadBrands } from 'requests/brand';
import Brand from 'data/Brand';

const STOCKS_REPORT = 'Stocks Information';

const Page = styled.div`
  width: 100%;
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #0e141a;
`;

const Container = styled.div`
  padding: 6px 24px;
  .report-style-class {
    width: 100%;
    height: 100vh;
  }
  iframe {
    border: none;
  }
`;

interface Props {
  selectedVendor?: Vendor;
  featureFlags: FeatureFlag;
  vendorsList: Vendor[];
}

interface ReportData {
  id: number;
  viewCode: string;
  reportId: string;
  userRole: number;
}
const PowerBiReports = ({ selectedVendor, featureFlags, vendorsList }: Props) => {
  const { token, user } = useAuth();
  const [reportInfo, setReportInfo] = useState<any>({});
  const [report, setReport] = useState<Report>();
  const [activeTab, setActiveTab] = useState<number | undefined>(0);
  const { currentPath } = usePathState();
  const [previousPath, setPreviousPath] = useState(currentPath);
  const [reports, setReports] = useState<ReportData[]>([]);
  const [powerBiTabs, setPowerBiTabs] = useState<any[]>([]);
  const [stockTab, setStockTab] = useState<any[]>([]);
  const path = usePath();
  const crumbs = useBreadcrumbs(path);

  const getTabName = (tabID: number) => {
    return powerBiTabs[tabID].value;
  };
  const getRoleId = (roleName?: string): number => {
    switch (roleName) {
      case 'Admin':
        return 1;
        break;
      case 'Vendor':
        return 2;
        break;
      case 'SuperAdmin':
        return 3;
        break;
      case 'VendorAdmin':
        return 4;
        break;
      default:
        return -1;
    }
  };

  const getPowerBiReportReq = useAPI({
    deferFn: getReportsData,
    onResolve: (result) => {
      setReportInfo(result);
    },
    onReject: (err) => {
      setReportInfo({});
    },
  });

  const brandsReq = useAPI({
    deferFn: loadBrands,
    onResolve: (brands: Brand[]) => {}
  });


  useEffect(() => {

    if (brandsReq.data && brandsReq.data.length > 0) {
      const brands = brandsReq.data;
      const isStockApiEnabled = brands.some(brand => brand.brandVendor?.isStockApiEnabled);
      if (isStockApiEnabled) {
        setPowerBiTabs([...powerBiTabs, ...stockTab])
      }
    }


  }, [brandsReq.data]);

  const handleReportTabs = () => {

    const userRoleId = getRoleId(user?.role);
    const userReports = reports.filter(
      (report) => report.userRole === userRoleId
    );

    var Tabs = Array.from(new Set(userReports.map((item: any) => item.viewCode)));
      Tabs = Tabs.map((tab) => {
        return {
          value: tab,
          badge: {
            content: '',
            color: '',
            backgroundColor: '',
          },
        };
      });

      if (featureFlags.stocks && featureFlags.stocks.enabled) {
        // Check if the user is admin or super admin
        if (isAdmin(user!) || isSuperAdmin(user!)) {
          // Check if any vendor has stock API enabled
          const isStockEnabled = vendorsList.some(obj => obj.isStockApiEnabled);
          setPowerBiTabs(isStockEnabled ? Tabs : Tabs.filter((item: any) => item.value !== STOCKS_REPORT));
        } else if (isVendor(user!) || isVendorAdmin(user!)) {
          // If user is vendor or vendor admin, filter out STOCKS_REPORT from powerBiTabs and set it to stockTab
          const stockTab = Tabs.filter((item: any) => item.value === STOCKS_REPORT);
          setPowerBiTabs(Tabs.filter((item: any) => item.value !== STOCKS_REPORT));
          setStockTab(stockTab);
          // Fetch brands data
          brandsReq.run(user?.vendorId);
        }
      } else {
        // If stocks feature is not enabled, filter out STOCKS_REPORT from powerBiTabs
        setPowerBiTabs(Tabs.filter((item: any) => item.value !== STOCKS_REPORT));
      }
  }

  const getPowerBiReports = useAPI({
    deferFn: getReports,
    onResolve: (result) => {
      setReports(result);
    },
    onReject: (err) => {
      setReportInfo({});
      console.log('err', err);
    },
  });

  useEffect(() => {
    getPowerBiReports.run([token]);
  }, []);

  useEffect(() => {
    if(featureFlags) {
      if(vendorsList || isVendor(user!)) {
        handleReportTabs();
      }
    }
  }, [featureFlags, vendorsList, reports]);

   useEffect(() => {
    setReportInfo({});
    getPowerBiReportReq.cancel();
    if (activeTab != undefined && user && reports.length > 0 && powerBiTabs.length > 0) {
      const tabName = getTabName(activeTab);
      const userRoleId =
        selectedVendor &&
        (user.role === Role.Admin || user.role === Role.SuperAdmin)
          ? getRoleId(Role.VendorAdmin)
          : getRoleId(user.role);
      const report = reports.find(
        (report) => report.viewCode == tabName && report.userRole == userRoleId
      );
      getPowerBiReportReq.run(
        [token],
        selectedVendor?.name,
        report?.reportId,
        tabName
      );
    }
  }, [selectedVendor, activeTab, user, reports, powerBiTabs]);

  const breadcrumbs = currentPath.includes('reports') ? (
    <FlexboxContainer padding="10px 14px" flexDirection="row">
      <Breadcrumbs containerPadding="0px" crumbs={crumbs} />
    </FlexboxContainer>
  ) : (
    <Breadcrumbs crumbs={crumbs} />
  );

  return featureFlags.powerBiReport && featureFlags.powerBiReport.enabled ? (
    <Page>
      {breadcrumbs}
      <Container>
        <Title>Reports</Title>
        <FlexboxContainerComponent margin="33px 0px" flexDirection="column">
          <Tabs
            setActiveTab={setActiveTab}
            height="auto"
            tabs={powerBiTabs}
            activeTab={activeTab}
            theme="brandLight"
            borderRadius="20px"
            margin="0 24px 8px 0"
            tabPanelPadding="0 !important"
          >
            {reports.map(() => (
              <PowerBIEmbed
                key={reportInfo['Vendor']}
                embedConfig={{
                  type: 'report',
                  id: reportInfo.ReportId,
                  embedUrl: reportInfo.EmbedUrl,
                  accessToken: reportInfo.EmbedToken,
                  tokenType: models.TokenType.Embed,
                  permissions: models.Permissions.All,
                  settings: {
                    filterPaneEnabled: true,
                    navContentPaneEnabled: true,
                  },
                }}
                eventHandlers={
                  new Map([
                    [
                      'loaded',
                      function () {
                        console.log('Report loaded');
                      },
                    ],
                    [
                      'rendered',
                      function () {
                        console.log('Report rendered');
                      },
                    ],
                    [
                      'error',
                      function (event?: service.ICustomEvent<any>) {
                        if (event) {
                          console.log(event.detail);
                        }
                      },
                    ],
                  ])
                }
                cssClassName={'report-style-class'}
                getEmbeddedComponent={(embeddedReport: Embed) => {
                  setReport(embeddedReport as Report);
                }}
              />
            ))}
          </Tabs>
        </FlexboxContainerComponent>
      </Container>
    </Page>
  ) : null;
};

export default PowerBiReports;
