import Attribute from "data/Attribute";
import readExcelFile from "read-excel-file";

const checkFixedHeaders = (
  attribute: Attribute,
  headers: string[]
) => {
  let fixedHeaders = [
    { name: attribute?.name, index: headers.indexOf(attribute?.name) },
  ];
  /* Validate Static Headers */
  function validateHeaders(fixedHeaders:any) {
   return fixedHeaders.length === 1;
  }
  const isValid = validateHeaders(fixedHeaders);
  if(!isValid){
    return false;
  }
  const result = fixedHeaders.map((item: any) => {
    if (item.index === -1) {
      return false;
    } else {
      return true;
    }
  });
  return !result.includes(false);
};


export const verifyAttributeValuesFileTemplate = async (
  file: File | null,
  attribute: Attribute,
) => {
  if (!file) {
    return false;
  }
  const attributeValuesFileData = await readExcelFile(file);
  const fixedHeadersResult = checkFixedHeaders(attribute, attributeValuesFileData[0]);
  return fixedHeadersResult;

};