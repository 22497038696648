import React from 'react';
import styled, { css } from 'styled-components';
import { Close, Visibility } from '@material-ui/icons';
import Backdrop from 'components/UI/Backdrop';
import Button from 'components/UI/Button';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Space } from 'styles/themeSpaces';
import { Main } from 'styles/themeColors';
import { MainColors } from 'types/enumTypes';
import Icon from 'view/Icon';

const ModalIcon = styled(Icon).attrs({ size: 25 })<any>``;

const ModalComponent = styled.div<any>`
  max-height: 100%;
  overflow-y: auto;
  position: fixed;
  top: ${({ $top }) => $top};
  margin-left: ${({ $marginLeft }) => $marginLeft};
  left: ${({ $left }) => ($left ? $left : '40vw')};
  width: ${({ $width }) => $width};
  z-index: 200;
  padding: ${Space.sp3x};
  visibility: ${({ $visibility }) => $visibility};
`;

const Title = styled.h4<any>`
  margin: 0px !important;
  ${({ isError }) =>
    isError &&
    css`
      color: ${Main.error};
    `}
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
`;

interface Props {
  children?: React.ReactNode;
  className?: string;
  showModal: boolean;
  showCloseIcon?: boolean;
  showButtons?: boolean;
  showGhostButton?: boolean;
  isError?: boolean;
  title: string;
  width: string;
  left?: string;
  marginTop?: string;
  marginLeft?: string;
  top?: string;
  theme: MainColors;
  color?: string;
  borderColor?: string;
  disabled?: boolean;
  primaryButtonText: string;
  ghostButtonText: string;
  onClose: (event: any) => void;
  onClick?: (event: any) => void;
  onGhostButtonClick?: (event: any) => void;
  modalFooterMargin?: string;
  iconName?: string;
  primaryButtonColor?: string;
  visibility?: string;
}

const Modal = ({
  children,
  className,
  showModal,
  showCloseIcon,
  showButtons,
  showGhostButton,
  isError,
  title,
  width,
  top,
  left,
  marginLeft,
  marginTop,
  theme,
  color,
  borderColor,
  disabled,
  primaryButtonText,
  ghostButtonText,
  onClose,
  onClick,
  onGhostButtonClick,
  modalFooterMargin,
  iconName,
  primaryButtonColor,
  visibility = 'visible'
}: Props) => {
  if (!showModal) {
    return null;
  }

  const buttons = showButtons ? (
    <>
      {showGhostButton ? (
        <Button
          id="modal-ghost-button"
          isGhost
          theme="accent"
          color={color ? color : Main.accent}
          borderColor={borderColor ? borderColor : Main.accent}
          onClick={onGhostButtonClick ? onGhostButtonClick : onClose}
          margin={`0 ${Space.spHalf} 0 0`}
        >
          {ghostButtonText}
        </Button>
      ) : null}
      <Button
        id="modal-main-button"
        theme={theme}
        disabled={disabled}
        onClick={onClick}
        background={primaryButtonColor}
        borderColor={primaryButtonColor}
      >
        {primaryButtonText}
      </Button>
    </>
  ) : null;

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent closing the modal on Enter
    }
  };

  return (
    <>
      <Backdrop onClick={onClose} />
      <ModalComponent
        className={`modal-elevation ${className}`}
        $width={width}
        $top={top}
        $left={left}
        $marginLeft={marginLeft}
        onKeyDown={handleKeyDown} // Prevent closing on Enter key
        tabIndex={-1} // Allow the modal to capture keyboard events
        $visibility={visibility}
      >
        <FlexboxContainer
          className="modal-header"
          justifyContent="space-between"
          alignItems="center"
          margin={`0 0 ${Space.sp2x} 0`}
        >
          <FlexboxContainer alignItems='center' flexDirection='row'>
          {iconName ? <ModalIcon name={iconName} /> : null}
            <Title isError={isError}>{title}</Title>
          </FlexboxContainer>
          {showCloseIcon ? (
            <CloseIcon id="close-icon" onClick={onClose} />
          ) : null}
        </FlexboxContainer>
        <div className="base">{children}</div>
        <FlexboxContainer
          className="modal-footer"
          justifyContent="flex-end"
          margin={modalFooterMargin ? modalFooterMargin : `${Space.sp4x} 0 0 0`}
        >
          {buttons}
        </FlexboxContainer>
      </ModalComponent>
    </>
  );
};

Modal.defaultProps = {
  className: '',
  showModal: false,
  showCloseIcon: false,
  showButtons: true,
  showGhostButton: true,
  width: '440px',
  top: '30%',
  theme: 'accent',
  primaryButtonText: 'Confirm',
  ghostButtonText: 'Cancel',
};

export default Modal;
