import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import Select from '../../UI/Select';
import Vendor from '../../../data/Vendor';
import { getItems } from '../../../lib/SessionStorage';
import { capitalize, capitalizeFirstLetter } from '../../../utils/formatters';
import { Background, Main } from '../../../styles/themeColors';
import { Size } from '../../../lib/styles';
import { useAPI } from '../../../lib/API';
import { getTopProductBrands } from '../../../requests/product';
import MatTableColumnsButton from '../../MaterialTable/MatTableColumnsButton';
import ProductColumns from './ProductListingColumns';
import { save } from '../../../lib/LocalStorage';
import { Column } from 'material-table';
import ProductLocalization from '../ProductLocalization';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { PRODUCT_COLUMNS_STORAGE_KEY } from 'constants/product';

const useStyles = makeStyles(() =>
  createStyles({
    brandImage: {
      backgroundColor: Background.white,
      height: 90,
      objectFit: 'contain',
      objectPosition: '50%',
      padding: Size.tiny,
      width: 90,
    },
    brandBox: {
      padding: '16px',
      textAlign: 'center',
      height: '100px',
      width: '100px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid ${Main.lightGray}`,
      '&:hover': {
        border: `1px solid ${Main.brandLight}`,
      },
    },
    brandText: {
      color: 'var(--main-primary-color, #FC780B)',
      textAlign: 'center',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 700,
    },
    selected: {
      borderRadius: '4px',
      border: '1px solid #F57003',
      background: '#FFF',
    },
    filterBackground: {
      background: '#F0F1F4',
      padding: '10px 20px',
    },
    gridSpacing: {
      padding: '0 20px',
      marginTop: '24px',
    },
    columnSettings: {
      alignContent: 'center',
      justifyContent: 'flex-end',
      display: 'grid'
    },
    tableMargin: {
      marginTop: '24px'
    },
  })
);
interface Props {
  selectedVendorId?: number;
  vendorsList: Vendor[];
  filterUpdate: (filters: ProductListFilter) => void;
  updateColumnSettings: Function;
  productColumns: Column<any>[];
  loading: boolean;
}

interface SelectedOption {
  value: string;
  id?: string;
}

export interface ProductListFilter {
  publishStatus: SelectedOption;
  brand: SelectedOption[];
  vendor: SelectedOption;
  country: SelectedOption;
}

const ProductListingFilters = ({ productColumns, selectedVendorId, vendorsList, filterUpdate, updateColumnSettings, loading }: Props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const status = queryParameters.get("status"); 

  const classes = useStyles();

  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [top5Brands, setTop5Brands] = useState<any[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<ProductListFilter>({
    publishStatus: { value: 'All' },
    brand: [],
    vendor: { value: ProductLocalization.listing.filter.vendorSelect },
    country: { value: ProductLocalization.listing.filter.countrySelect },
  });

  const isMounted = React.useRef(true);

  React.useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // fetching data from session storage
  const brands = getItems('brands');
  const [brandList, setBrandList] = useState(brands);
  const productStatuses = getItems('productStatuses');
  const countries = getItems('countries');

  const topBrandReq = useAPI({
    deferFn: getTopProductBrands,
    onResolve: (result: any) => {
      if (isMounted.current && result && result.data) {
        setTop5Brands(result.data);
      }
    },
    onReject: (err: any) => { console.log('err:', err); },
  });

  const handleBrandBoxClick = (brand?: any) => {
    if (brand) {
      // if selectedBrands already have this brand then remove it else add it
      if (selectedBrands.indexOf(brand.name) > -1) {
        setSelectedBrands(selectedBrands.filter((item) => item !== brand.name));
        setSelectedFilters({
          ...selectedFilters,
          brand: selectedFilters.brand.filter(
            (item) => item.value !== brand.name
          ),
        });
      } else {
        setSelectedBrands([...selectedBrands, brand.name]);
        setSelectedFilters({
          ...selectedFilters,
          brand: [...selectedFilters.brand, { value: brand.name, id: brand.id }],
        });
      }
    } else {
      setSelectedBrands([]);
      setSelectedFilters({
        ...selectedFilters,
        brand: [],
      });
    }
  }

  React.useEffect(() => {
    filterUpdate(selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  React.useEffect(() => {
    selectedVendorId ? setBrandList(brands.filter((brand: any) => brand.brandVendor.vendorId === selectedVendorId)): setBrandList(brands);
    topBrandReq.run(selectedVendorId, 5);
  }, [selectedVendorId]);

  React.useEffect(() => {
    if(status){
      const publishStatus = productStatuses?.find((x: any) => x.name === status);
      if(publishStatus){
        setSelectedFilters({...selectedFilters, publishStatus: {...publishStatus, value: capitalizeFirstLetter(publishStatus.name)}});
      }
    }
  }, [status])

  return (
    <>
      {top5Brands.length > 0 && <Grid item xs={12} sm={12} md={10}>
        <Grid container className={classes.gridSpacing} style={{width: '700px'}}>
          {top5Brands.map((brand: any, index: number) => (
            <Grid item xs={6} sm={4} md={2} key={index}>
              <Paper elevation={0} className={`${classes.brandBox} ${selectedBrands.indexOf(brand.name) > -1 && classes.selected}`} onClick={() => { handleBrandBoxClick(brand) }}>
                <img
                  className={classes.brandImage}
                  alt={`${brand.name} logo`}
                  src={process.env.REACT_APP_MEDIA_URL + brand?.media[0]?.path}
                />
              </Paper>
            </Grid>
          ))}

          <Grid item xs={6} sm={4} md={2}>
            <Paper elevation={0} className={`${classes.brandBox} ${selectedBrands.length === 0 && classes.selected}`} onClick={() => {handleBrandBoxClick()}}>
              <Typography variant="h6" className={classes.brandText}>
                Show all
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>}
      <Grid item xs={12} sm={12} md={12} className={classes.tableMargin}>
        <Grid container spacing={1} className={classes.filterBackground}>
          <Grid item xs={12} sm={6} md={2}>
            <Select
              label="Publish Status"
              disabled={loading}
              labelPosition="top"
              options={
                productStatuses
                  ? productStatuses.map((status: any) => ({
                      value: capitalize(status.name),
                      id: status.id,
                    }))
                  : []
              }
              onChange={(event) => {
                setSelectedFilters({
                  ...selectedFilters,
                  publishStatus: {
                    value: event.target.value,
                    id: event.target.id,
                  },
                });
              }}
              value={selectedFilters.publishStatus.value}
              defaultValue="All"
              width="100%"
              displayEmpty="All"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Select
              label="Brand"
              disabled={loading}
              labelPosition="top"
              options={
                brandList.map((brand: any) => ({
                  value: brand.name,
                  id: brand.id,
                })) || []
              }
              onChange={(event) => {
                const selBrands = event.target.value; // array of selection
                const selectedItem = event.target.selected;
                setSelectedBrands(selBrands);

                if (selBrands.indexOf(selectedItem) > -1) {
                  setSelectedFilters({
                    ...selectedFilters,
                    brand: [
                      ...selectedFilters.brand,
                      { value: selectedItem, id: event.target.id },
                    ],
                  });
                } else {
                  setSelectedFilters({
                    ...selectedFilters,
                    brand: selectedFilters.brand.filter(
                      (brand) => brand.value !== selectedItem
                    ),
                  });
                }
              }}
              value={selectedBrands}
              defaultValue={ProductLocalization.listing.filter.brandSelect}
              width="100%"
              multiple={true}
              limitTags={1}
            />
          </Grid>

          {!selectedVendorId && vendorsList && <Grid item xs={12} sm={6} md={2}>
            <Select
              label="Vendor"
              labelPosition="top"
              disabled={loading}
              options={vendorsList.map((vendor: Vendor) => ({
                value: vendor.name,
                id: vendor.id + '',
              }))}
              onChange={(event) => {
                setSelectedFilters({
                  ...selectedFilters,
                  vendor: { value: event.target.value, id: event.target.id },
                });
              }}
              value={selectedFilters.vendor.value}
              defaultValue={ProductLocalization.listing.filter.vendorSelect}
              width="100%"
              displayEmpty={ProductLocalization.listing.filter.vendorSelect}
            />
          </Grid>}

          <Grid item xs={12} sm={6} md={selectedVendorId ? 4: 2}>
            <Select
              label="Country"
              disabled={loading}
              labelPosition="top"
              options={countries.map((country: any) => ({
                value: country.countryName,
                id: country.countryIso,
              }))}
              onChange={(event) => {
                setSelectedFilters({
                  ...selectedFilters,
                  country: { value: event.target.value, id: event.target.id },
                });
              }}
              value={selectedFilters.country.value}
              defaultValue={ProductLocalization.listing.filter.countrySelect}
              width="100%"
              displayEmpty={ProductLocalization.listing.filter.countrySelect}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} className={classes.columnSettings}>
            <MatTableColumnsButton 
              columns={productColumns} 
              onUpdateColumns={(columns) => {
                save(PRODUCT_COLUMNS_STORAGE_KEY, columns);
                updateColumnSettings(columns);
              }} 
              initialColumns={ProductColumns} showButton />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductListingFilters;
