import React from 'react';
import styled from 'styled-components';
import VendorCompanyCard from 'components/Company/VendorCompanyCard';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Space } from 'styles/themeSpaces';
import { VendorCompanyInfo } from 'data/VendorCompany';

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface Props {
  isConnected: boolean;
  hasVendorInterface: boolean;
  activeIndicator: boolean;
  vendorCompanyId: string;
  vendorCompanyDetails: VendorCompanyInfo;
};

const AssignModal = ({ isConnected, hasVendorInterface, activeIndicator, vendorCompanyId, vendorCompanyDetails }: Props) => {
  return (
    <FlexboxContainer flexDirection="column">
      {
        vendorCompanyDetails.companyName ? (
          <VendorCompanyCard
            isConnected={isConnected}
            vendorCompanyId={vendorCompanyId}
            vendorCompanyDetails={vendorCompanyDetails}
          />
        ) : null
      }
      {
        !hasVendorInterface || activeIndicator
          ? <Span>Please make sure this ID is correct. It can be changed only via OFMP customer support</Span>
          : (
            <>
              <Span>
                Please check if your input is correct.
              </Span>
              <Span>
                For any questions please contact OFMP support team at <a href="mailto: support@ofmp.com">support@ofmp.com</a>
              </Span>
            </>
          )
      }
    </FlexboxContainer >
  );
};

export default AssignModal;
