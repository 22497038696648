import FlexboxContainer from 'components/UI/FlexboxContainer';
import React from 'react';
import styled from 'styled-components';
import { Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import Pill from 'components/UI/Pill';
import { formatDateTime } from 'utils/formatters';
import { getMappedTicketDisplayState, getTicketPillTheme } from 'utils/ticket';
import TicketEntry from 'data/TicketHistory';
import { TicketStatus } from 'data/Ticket';
import Button from 'components/UI/Button';
import Icon from 'view/Icon';
import { SHIPPING } from 'constants/deliveryModes';

const Container = styled(FlexboxContainer)`
    flex-direction: row;
`;

const Row = styled(FlexboxContainer)`
    flex-direction: row;
    padding: 2px 0;
`;

const Column = styled(FlexboxContainer)`
  margin-bottom: 0.8rem;
  flex-direction: column;
`;

const Label = styled.span`
    min-width: 200px;
    font-size: ${Space.sp2x};
    line-height: 20px;
    color: ${Text.primary};
`;

const Value = styled.span`
    min-width: 188px;
    font-size: ${Space.sp2x};
    line-height: 20px;
    color: ${Text.primary};
`;

const PrintIcon = styled(Icon).attrs({ name: 'Print', size: 30 })``;

const ButtonWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-end;
`;

const PreviousValue = styled.span`
    text-decoration: line-through;
    color: ${Text.secondary}; // Lighter color for previous value
    font-size: ${Space.sp2x};

`;

const CurrentValue = styled.span`
    font-weight: bold;
    color: ${Text.primary}; // Normal color for current value
    font-size: ${Space.sp2x};
    margin-left: 5px;
`;

interface TicketHistoryItemType {
    item: TicketEntry;
    handlePrint?: () => void;
    deliveryType?: string;
}

const TicketHistoryItem: React.FC<TicketHistoryItemType> = ({ item, handlePrint, deliveryType }) => {
    const {
        description,
        status,
        updatedOn,
        rentalEndDate,
        rentalStartDate,
        modifiedRentalEndDate,
        modifiedRentalStartDate,
        originalDeliveryDate,
        newDeliveryDate
    } = item || {};

    const renderDateValue = (previous: any, current: any, formatter = (val: any, _: any) => val) => {
        return previous && current && previous !== current ? (
            <>
                {previous && <PreviousValue>{formatter(previous, false)}</PreviousValue>}
                {current && <CurrentValue>{formatter(current, false)}</CurrentValue>}
            </>
        ) : (
            previous && <Value>{formatter(previous, false)}</Value>
        );
    };

    const { displayState } = getMappedTicketDisplayState(status);

    const pillTheme = getTicketPillTheme(status);

    const pill = <Pill margin="0 0 0 0" theme={pillTheme} bgColor={pillTheme} size="xsPlus" padding="4px">{displayState}</Pill>;

    return <>
        <Container gap='14rem'>
            <Column>
                <Row>
                    <Label>Status:</Label>
                    <Value>{pill}</Value>
                </Row>
            </Column>
            {
                status === TicketStatus.CLOSED.toString() &&
                <Column>
                    <Row>
                        <Value style={{fontWeight: 'bold'}}>{`Resolved on ${formatDateTime(updatedOn)}`}</Value>
                    </Row>
                </Column>
            }
            {
                handlePrint &&
                <ButtonWrapper>
                    <Button
                        onClick={handlePrint}
                        leftIcon={() => <PrintIcon />}
                        background="transparent"
                        borderColor="transparent"
                    />
                </ButtonWrapper>
            }
        </Container>

        <Container gap='14rem'>
            <Column>
                <Row>
                    <Label>Change date:</Label>
                    <Value>{formatDateTime(updatedOn)}</Value>
                </Row>
            </Column>
            <Column>
                <Row>
                    <Label>{deliveryType === SHIPPING ? 'Est. delivery:' : 'Est. pickup:'}</Label>
                    <Value>
                        {renderDateValue(originalDeliveryDate, newDeliveryDate, formatDateTime)}
                    </Value>
                </Row>
            </Column>
        </Container>

        <Container gap='14rem'>
            <Column>
                <Row>
                    <Label>Previous rent start date:</Label>
                    <Value>{formatDateTime(rentalStartDate)}</Value>
                </Row>
            </Column>
            <Column>
                <Row>
                    <Label>Previous rent end date:</Label>
                    <Value>{formatDateTime(rentalEndDate)}</Value>
                </Row>
            </Column>
        </Container>

        <Container gap='14rem'>
            <Column>
                <Row>
                    <Label>New rent start date:</Label>
                    <Value>{formatDateTime(modifiedRentalStartDate)}</Value>
                </Row>
            </Column>
            <Column>
                <Row>
                    <Label>New rent end date:</Label>
                    <Value>{formatDateTime(modifiedRentalEndDate)}</Value>
                </Row>
            </Column>
        </Container>

        <Container>
            <Column>
                <Row>
                    <Label style={{fontWeight: 'bold'}}>Rent ticket information:</Label>
                    <Value>{description}</Value>
                </Row>
            </Column>
        </Container>
    </>
}

export default React.memo(TicketHistoryItem);