import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Input from 'components/UI/Input';
import { Background, Main } from 'styles/themeColors';
import 'react-toastify/dist/ReactToastify.css';
import Vendor from 'data/Vendor';
import { BorderRadius } from "styles/themeBorderRadius";
import { Color } from 'lib/styles';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import { getCompanySalesOrderConfig, validateVendorCompany } from 'requests/vendorCompany';
import Button from 'components/UI/Button';
import { useAPI } from 'lib/API';
import {
    saveCompanySalesOrderConfig,
} from 'requests/vendorCompany';
import { useAuth } from 'lib/Auth';
import SalesOrderConfig from 'data/SalesOrderConfig';
import { navigate } from 'hookrouter';

interface Props {
    selectedVendor?: Vendor;
    companyId: string;
    switchTab : any
}

const Container = styled(FlexboxContainerComponent)`
  flex-direction: column;
  align-items: left;
  max-width: 30%;
  margin: 0 auto;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
`;

const Prompt = styled.p`
  text-align: left;
  color: ${Color.darkerGray};
  font-style: italic;
`;

const ViewSwitch = styled.p`
  text-align: left;
  max-width: 30%;
  color: ${Color.black};
  font-style: italic;
  flex-direction: column;
  margin: 0 auto;
`;

const CancelButton = styled(Button)`
  margin-right: 5px;
  color: ${Color.black} !important;
  background-color: ${Background.white};
  border: 1px solid ${Color.veryLightGray} !important;
`;

const SaveButton = styled(Button)`
  padding: 10px;
  border-radius: ${BorderRadius.m};
  background-color: ${Main.brandDark};
  border-color: ${Main.disabled} !important;
  color: #FFFFFF;
`;

const VendorButton = styled(Button)`
    border-radius: ${BorderRadius.m};
    background-color: ${Main.brandDark};
    border-color: ${Main.disabled} !important;
    color: #FFFFFF;
    margin-top: 40px;
    padding: 10px 80px;
`;


const initialSalesConfig = {
    operationalApprover: '',
    distributionChannel: '',
    division: '',
};

const SUCCESS_MESSAGE = 'Sales order settings for {Vendor} were updated. They will be used for all future order.';
const ERROR_REQURIED_MESSAGE = 'Please fill in all fields before saving.';

const SalesOrder: React.FC<Props> = ({ selectedVendor, companyId, switchTab  }) => {
    const { token } = useAuth();
    const [salesOrderConfig, setsalesOrderConfig] = useState<SalesOrderConfig>(initialSalesConfig);
    const [disabled, setDisabled] = useState(true);
    const [operationalApproverError, setOperationalApproverError] = useState(false);
    const [distributionChannelError, setDistributionChannelError] = useState(false);
    const [divisionError, setDivisionError] = useState(false);
    const [dataFetched, setDataFetched] = useState(false);
    const [savingData, setSavingData] = useState(false);


    const updateSalesDataReq = useAPI({
        deferFn: (salesOrderConfig: SalesOrderConfig[]) => {
            return saveCompanySalesOrderConfig(salesOrderConfig[0], token, selectedVendor?.id, companyId);
        },
        onResolve: () => {
            renderToast(toast.TYPE.SUCCESS, SUCCESS_MESSAGE.replace("{Vendor}", selectedVendor?.name!));
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        },
    });

    const vendorCompanyReq = useAPI<SalesOrderConfig>({
        deferFn: getCompanySalesOrderConfig,
        onResolve: (result) => {
            if (result) {
                if(result.distributionChannel && result.division && result.operationalApprover){
                    const mappedData = { distributionChannel: result.distributionChannel, division: result.division, operationalApprover: result.operationalApprover };
                    setsalesOrderConfig({ ...mappedData });
                    setDataFetched(true);
                }
            }
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    useEffect(() => {
        if (selectedVendor?.id) {
            vendorCompanyReq.run(selectedVendor?.id, companyId);
        }
    }, [selectedVendor, companyId]);

    useEffect(() => {
        updateDisabledState();
    }, [salesOrderConfig]);

    useEffect(() => {
        switchTab(4);
    }, []);


    const updateDisabledState = () => {
        const isDisabled =
            operationalApproverError ||
            distributionChannelError ||
            divisionError ||
            !(salesOrderConfig.operationalApprover && salesOrderConfig.distributionChannel && salesOrderConfig.division);
        setDisabled(isDisabled);
    };

    useEffect(() => {
        updateDisabledState();
    }, [operationalApproverError, distributionChannelError, divisionError]);

    useEffect(() => {
        setOperationalApproverError(false);
        setDistributionChannelError(false);
        setDivisionError(false);
    }, []);

    const saveClick = () => {
        if (!salesOrderConfig.operationalApprover || !salesOrderConfig.distributionChannel || !salesOrderConfig.division) {
            renderToast(toast.TYPE.INFO, ERROR_REQURIED_MESSAGE);
            return;
        }
        setSavingData(true); 
        updateSalesDataReq.run(salesOrderConfig);

    };

    const cancelClick = () => {
        setsalesOrderConfig(initialSalesConfig);
    };

    const navigateToCompaniesPage = () => {
        switchTab(0);
    };
    
    return (
        <>
            {!selectedVendor ? ( 
               <>
               <Wrapper>
                   <ViewSwitch style={{maxWidth: '35%'}}>
                       Please choose the vendor from the vendor tab to proceed further.
                   </ViewSwitch>
                   <VendorButton onClick={navigateToCompaniesPage}>
                        Go to Vendor
                   </VendorButton>
               </Wrapper>
           </>
           
            ) :
                (
                    <Container >
                        <Prompt>Please note: all fields below are required for the Sales Order to work.</Prompt>


                        <div style={{ marginBottom: '30px', marginTop: '30px' }}>
                            <Input
                                labelName='Operational Approver'
                                id='operational-approver'
                                width="100%"
                                name="operational-approver"
                                value={salesOrderConfig.operationalApprover}
                                onInputChange={(value) => {
                                    setsalesOrderConfig({ ...salesOrderConfig, operationalApprover: value });
                                    setOperationalApproverError(value.length > 100);
                                }}
                                isInvalid={operationalApproverError}
                                errorMessage='Max 100 characters allowed'
                                disabled={dataFetched || savingData}
                            />
                        </div>

                        <div style={{ marginBottom: '25px' }}>
                            <Input
                                labelName="Distribution Channel / Business line"
                                id='distribution-channel'
                                width="100%"
                                name="distribution-channel"
                                value={salesOrderConfig.distributionChannel}
                                onInputChange={(value) => {
                                    setsalesOrderConfig({ ...salesOrderConfig, distributionChannel: value });
                                    setDistributionChannelError(value.length > 5);
                                }}
                                isInvalid={distributionChannelError}
                                errorMessage='Max 5 characters allowed'
                                disabled={dataFetched || savingData}
                            />
                        </div>

                        <div style={{ marginBottom: '25px' }}>
                            <Input
                                labelName="Division"
                                id='division-id'
                                width="100%"
                                name="division-name"
                                value={salesOrderConfig.division}
                                onInputChange={(value) => {
                                    setsalesOrderConfig({ ...salesOrderConfig, division: value });
                                    setDivisionError(value.length > 5);
                                }}
                                isInvalid={divisionError}
                                errorMessage='Max 5 characters allowed'
                                disabled={dataFetched || savingData}
                            />
                        </div>

                        <div style={{ border: 'none', display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <CancelButton key="cancel" onClick={cancelClick} disabled={dataFetched || savingData}>
                                Cancel
                            </CancelButton>
                            <SaveButton key="save" onClick={saveClick} disabled={disabled || dataFetched || savingData}>
                                Save
                            </SaveButton>
                        </div>
                    </Container >
                )}
        </>
    );
};

export default SalesOrder;