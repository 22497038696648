import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { navigate } from 'hookrouter';
import Button from 'components/UI/Button';
import RenderTag from 'components/UI/RenderTag';
import Modal from 'components/UI/Modal';
import TagsInputComponent from 'view/TagsInputComponent';
import { renderToast } from 'components/UI/ToastNotification';
import { MailOutline } from '@material-ui/icons';
import { Color, FontSize, FontWeight, Percent, BorderRadius } from 'lib/styles';
import { useAuth } from 'lib/Auth';
import { useAPI } from 'lib/API';
import { validateCommaSeparatedEmails } from 'lib/validation';
import { saveUser } from 'requests/user';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Space } from 'styles/themeSpaces';
import { Main } from 'styles/themeColors';

const SubHeader = styled.h3`
  margin: 30px 0;
`;

const InputHelperErrorMessage = styled.div<any>`
  color: ${Main.error};
  font-size: 15px;
`;

const LabelText = styled.label`
  color: ${Color.black};
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
`;

const InputHelperMessage = styled.div<any>`
  color: ${Color.darkerGray};
  font-size: ${FontSize.tiny};
`;

const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;

const StyledMailOutlineIcon = styled(MailOutline) <any>`
  align-self: flex-start;
  margin-top: 10px;
`;

const InputContainer = styled.div <any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Color.veryLightGray};
  border-radius: ${BorderRadius.small};
  width: ${Percent.half};
  padding-right: 5px;
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface Props {
  className?: string;
};

const UserNotifications = ({ className }: Props) => {
  const { updateUser, user } = useAuth();
  const [adminMailbox, setAdminMailbox] = useState(user!.adminMailbox || '');
  const [customerMailbox, setCustomerMailbox] = useState(user!.customerMailbox || '');
  const [deadLetterMailbox, setDeadLetterMailbox] = useState(user!.deadLetterMailbox || '');
  const [isAdminMailboxValid, setIsAdminMailboxValid] = useState(true);
  const [showAccountNotificationModal, setShowAccountNotificationModal] = useState(false);
  const [isCustomerMailboxValid, setIsCustomerMailboxValid] = useState(true);
  const [isDeadLetterMailboxValid, setIsDeadLetterMailboxValid] = useState(true);
  const [adminTags, setAdminTags] = useState<string[]>(adminMailbox.split(',').filter(value => value !== ''));
  const [customerTags, setCustomerTags] = useState<string[]>(customerMailbox.split(',').filter(value => value !== ''));
  const [deadLetterTags, setDeadLetterTags] = useState<string[]>(deadLetterMailbox.split(',').filter(value => value !== ''));
  const inputMaxLength = 200;

  const userSaveReq = useAPI({
    deferFn: saveUser,
    onResolve: () => {
      if (user) {
        updateUser({ ...user, adminMailbox, customerMailbox, deadLetterMailbox });

        renderToast(toast.TYPE.SUCCESS, 'User information saved.');
      }
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    setAdminMailbox(adminTags.join());
  }, [adminTags]);

  useEffect(() => {
    setCustomerMailbox(customerTags.join());
  }, [customerTags]);

  useEffect(() => {
    setDeadLetterMailbox(deadLetterTags.join());
  }, [deadLetterTags]);

  useEffect(() => {
    const valid = validateCommaSeparatedEmails(adminMailbox);
    setIsAdminMailboxValid(valid);
  }, [adminMailbox]);

  useEffect(() => {
    const valid = validateCommaSeparatedEmails(customerMailbox);
    setIsCustomerMailboxValid(valid);
  }, [customerMailbox]);

  useEffect(() => {
    const valid = validateCommaSeparatedEmails(deadLetterMailbox);
    setIsDeadLetterMailboxValid(valid);
  }, [deadLetterMailbox]);

  const saveUserAccountSettings = () => {
    userSaveReq.run({ ...user, adminMailbox, customerMailbox, deadLetterMailbox });
  };

  const onConfirmCancelModalClick = () => {
    setShowAccountNotificationModal(false);
    navigate('/');
  };

  const confirmCancelModal = (
    <Modal
      showModal={showAccountNotificationModal}
      onClose={() => setShowAccountNotificationModal(false)}
      onClick={onConfirmCancelModalClick}
      title="Are you sure you want to cancel account notification update?"
      primaryButtonText="Confirm cancel"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          If you leave this page, all unsaved changes will be lost.
        </Span>
      </FlexboxContainer>
    </Modal>
  )

  const isSaveDisabled =
    userSaveReq.isLoading ||
    !isAdminMailboxValid ||
    !isCustomerMailboxValid ||
    !isDeadLetterMailboxValid ||
    adminMailbox.length > inputMaxLength ||
    customerMailbox.length > inputMaxLength ||
    deadLetterMailbox.length > inputMaxLength;

  return (
    <div className={className}>
      {confirmCancelModal}
      <h2>Notifications</h2>
      <SubHeader>Administrator</SubHeader>
      <LabelText htmlFor="adminEmail">Administrator service mailbox</LabelText>
      <InputContainer>
        <TagsInputComponent
          value={adminTags}
          onChange={setAdminTags}
          addKeys={[9, 13, 188]}
          inputProps={{
            className: 'react-tagsinput-input',
            placeholder: adminTags.length === 0 ? 'Enter email address' : ''
          }}
          tagProps={{
            className: 'react-tagsinput-tag',
            closeIconName: 'HighlightOff',
            color: 'red'
          }}
          renderTag={RenderTag}
          withDelimiter
        />
        <StyledMailOutlineIcon style={{ color: Color.shuttleGray }} />
      </InputContainer>
      <InputHelperMessage>Add multiple message recipients separated by commas</InputHelperMessage>
      {isAdminMailboxValid ? null : <InputHelperErrorMessage>Please enter a valid email address</InputHelperErrorMessage>}
      {adminMailbox.length < inputMaxLength ? null : <InputHelperErrorMessage>You cannot enter more than 200 characters</InputHelperErrorMessage>}
      <SubHeader>Customer service</SubHeader>
      <LabelText htmlFor="customerEmail">Customer service mailbox</LabelText>
      <InputContainer>
        <TagsInputComponent
          value={customerTags}
          onChange={setCustomerTags}
          addKeys={[9, 13, 188]}
          inputProps={{
            className: 'react-tagsinput-input',
            placeholder: customerTags.length === 0 ? 'Enter email address' : ''
          }}
          tagProps={{
            className: 'react-tagsinput-tag',
            closeIconName: 'HighlightOff',
            color: 'red'
          }}
          renderTag={RenderTag}
          withDelimiter
        />
        <StyledMailOutlineIcon style={{ color: Color.shuttleGray }} />
      </InputContainer>
      <InputHelperMessage>Add multiple message recipients separated by commas</InputHelperMessage>
      {isCustomerMailboxValid ? null : <InputHelperErrorMessage>Please enter a valid email address</InputHelperErrorMessage>}
      {customerMailbox.length < inputMaxLength ? null : <InputHelperErrorMessage>You cannot enter more than 200 characters</InputHelperErrorMessage>}
      <SubHeader>Dead letter queue</SubHeader>
      <LabelText htmlFor="adminEmail">Dead letter messages mailbox</LabelText>
      <InputContainer>
        <TagsInputComponent
          value={deadLetterTags}
          onChange={setDeadLetterTags}
          addKeys={[9, 13, 188]}
          inputProps={{
            className: 'react-tagsinput-input',
            placeholder: deadLetterTags.length === 0 ? 'Enter email address' : ''
          }}
          tagProps={{
            className: 'react-tagsinput-tag',
            closeIconName: 'HighlightOff',
            color: 'red'
          }}
          renderTag={RenderTag}
          withDelimiter
        />
        <StyledMailOutlineIcon style={{ color: Color.shuttleGray }} />
      </InputContainer>
      <InputHelperMessage>Add multiple message recipients separated by commas</InputHelperMessage>
      {isDeadLetterMailboxValid ? null : <InputHelperErrorMessage>Please enter a valid email address</InputHelperErrorMessage>}
      {deadLetterMailbox.length < inputMaxLength ? null : <InputHelperErrorMessage>You cannot enter more than 200 characters</InputHelperErrorMessage>}
      <ButtonsContainer>
        <Button disabled={userSaveReq.isLoading} margin="0 10px 0 0" isGhost color={Main.accent} onClick={() => setShowAccountNotificationModal(true)}>
          Cancel
        </Button>
        <Button disabled={isSaveDisabled} onClick={saveUserAccountSettings}>
          Save changes
        </Button>
      </ButtonsContainer>
    </div>
  );
};

export default UserNotifications;
