import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Category, * as Cat from 'data/Category';
import * as SuperCat from 'data/SuperCategory';
import Input from 'components/UI/Input';
import UploadButton from 'view/UploadButton';
import Modal from 'components/UI/Modal';
import ErrorMessage from 'view/ErrorMessage';
import InfoIcon from 'components/UI/InfoIcon';
import { renderToast } from 'components/UI/ToastNotification';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { Size, Color } from 'lib/styles';
import { postMedia } from 'requests/media';
import { createCategory, saveCategory } from 'requests/category';

const Logo = styled.img`
  flex: 1;
  height: 3.5rem;
  margin-left: ${Size.medium};
  object-fit: contain;
  object-position: center;
`;

const LogoContainer = styled.div`
  align-items: flex-end;
  display: flex;
  margin-top: ${Size.medium};
`;

const Label = styled.label`
  display: block;
`;

const StyledInfoIcon = styled(InfoIcon)`
  display: inline-block;
  margin-left: ${Size.small};
  vertical-align: text-bottom;
`;

const BlockUploadButton = styled(UploadButton)`
  display: block;
`;

const EmptyLogo = styled.div`
  background-color: ${Color.darkGray};
  height: 64px;
  margin-right: ${Size.medium};
  padding: ${Size.tiny};
  width: 64px;
`;

interface Props {
  onClose: () => void;
  superCategories: SuperCat.SuperCategorySummary[];
  selectedSuperCategoryId: number | null;
  selectedCategoryId: number | null;
  setShouldReload: any;
  showModal: boolean;
}

export default function CategoryModal({
  onClose,
  superCategories,
  selectedSuperCategoryId,
  selectedCategoryId,
  setShouldReload,
  showModal
}: Props) {
  const { token } = useAuth();
  const [newCategory, setNewCategory] = useState<Cat.PartialCategory>({
    name: ''
  });
  const [categoryToUpdate, setCategoryToUpdate] = useState<Category | null>(null);


  useEffect(() => {
    const selectedSuperCategory = superCategories.find(superCategory => superCategory.id === selectedSuperCategoryId);
    const selectedCategory = selectedSuperCategory ? selectedSuperCategory.categories.find(category => category.id === selectedCategoryId) : null;
    setCategoryToUpdate(selectedCategory!);
  }, [selectedSuperCategoryId, selectedCategoryId, superCategories]);

  const createReq = useAPI({
    deferFn: createCategory,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Category created.');
      setShouldReload(true);
      onClose();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const updateReq = useAPI({
    deferFn: saveCategory,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Category updated.');
      setShouldReload(true);
      onClose();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const handleClick = () => {
    if (categoryToUpdate) {
      updateReq.run(categoryToUpdate);
    } else {
      createReq.run(newCategory, selectedSuperCategoryId);
    }
  }

  const uploadImage = async (file: File) => {
    const filename = categoryToUpdate && categoryToUpdate.name ? categoryToUpdate.name : newCategory.name;
    const image = await postMedia({
      file,
      filename,
      token: token as string,
      type: 'category'
    });

    if (categoryToUpdate) {
      setCategoryToUpdate({ ...categoryToUpdate, media: [image] });
    } else {
      setNewCategory({ ...newCategory, media: [image] });
    }
  }

  const cantSubmit =
    categoryToUpdate
      ? (updateReq.isPending || (categoryToUpdate !== null && categoryToUpdate.name.trim().length === 0))
      : (createReq.isPending || newCategory.name.trim().length === 0);

  let errorMessage = null;
  if (createReq.error) {
    errorMessage = <ErrorMessage message={createReq.error.message} />;
  } else if (updateReq.error) {
    errorMessage = <ErrorMessage message={updateReq.error.message} />;
  }

  const categoryLogo =
    categoryToUpdate && categoryToUpdate.media && categoryToUpdate.media.length > 0
      ? <Logo src={process.env.REACT_APP_MEDIA_URL + categoryToUpdate.media[0].path} />
      : newCategory && newCategory.media && newCategory.media.length > 0
        ? <Logo src={process.env.REACT_APP_MEDIA_URL + newCategory.media[0].path} />
        : <EmptyLogo />;

  return (
    <Modal
      showModal={showModal}
      onClose={onClose}
      showCloseIcon
      onClick={handleClick}
      disabled={cantSubmit}
      title={categoryToUpdate ? 'Edit Subcategory' : 'Create Subcategory'}
      primaryButtonText='Save'
    >
      <Input
        id='category-modal-name'
        placeholder='Subcategory name'
        disabled={createReq.isPending || updateReq.isPending}
        labelName="Name"
        onInputChange={
          categoryToUpdate
            ? name => setCategoryToUpdate({ ...categoryToUpdate!, name })
            : name => setNewCategory({ ...newCategory, name })
        }
        value={categoryToUpdate && categoryToUpdate.name ? categoryToUpdate.name : newCategory.name}
      />
      <LogoContainer>
        <Label>
          Logo{' '}
          <StyledInfoIcon
            tooltipText="Logos should be at least 400x100 pixels."
            width="auto"
          />
          <BlockUploadButton
            acceptTypes={['image/*']}
            label="Choose"
            disabled={categoryToUpdate && categoryToUpdate.name ? categoryToUpdate.name.trim() === '' : newCategory.name.trim() === ''}
            onChange={uploadImage}
          />
        </Label>
        {categoryLogo}
      </LogoContainer>
      {errorMessage}
    </Modal>
  );
};

CategoryModal.defaultProps = {};
