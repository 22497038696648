import FlexboxContainer from '../../UI/FlexboxContainer';
import PricebooksTableRow from '../Modals/PricebooksTableRow';
import React from 'react';
import { PricebookExtended, PricebookForHierarchy } from '../../../data/Pricebook';
import { groupObjectsByField } from '../../../utils/array';
import { getPricebooksForPageHierarchy } from '../priceebook.utils';

interface Props {
  pricebooks: PricebookExtended[];
  isAdmin: boolean;
  onPreview: (
    previewId: number,
    pricebookType?: string,
    companyName?: string,
    vendorName?: string
  ) => void;
  onReplaceClick?: (pricebookId: number) => void;
  currentPage: number;
  itemsPerPage: number;
  showAsHierarchy: boolean;
}

const PricebookTable = ({
  pricebooks,
  isAdmin,
  onPreview,
  onReplaceClick,
  currentPage,
  itemsPerPage,
  showAsHierarchy
}: Props) => {
  const pricebooksToShow: PricebookExtended[] | PricebookForHierarchy[] = [];
  if(!showAsHierarchy) {
    pricebooksToShow.push(
      ...pricebooks.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
    );
  } else {
    const tmp = getPricebooksForPageHierarchy(pricebooks, itemsPerPage, currentPage) || [];
    pricebooksToShow.push(
      ...tmp
    )
  }

  return <FlexboxContainer flexDirection="column">
      {pricebooksToShow
        .map((priceBookRow: any, index: number) => {
          return (
            priceBookRow && (
              <PricebooksTableRow
                onPreview={onPreview}
                onReplaceClick={onReplaceClick}
                pricebook={priceBookRow}
                isAdmin={isAdmin}
                key={priceBookRow?.id || index}
                lowLevelHierarchy={!!priceBookRow.lowLevelHierarchy}
                hideVendorName={showAsHierarchy && !priceBookRow.isTheFirstRowForVendor}
              />
            )
          );
        })}
    </FlexboxContainer>
}

export default PricebookTable