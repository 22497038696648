import React, { Fragment } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Product, * as Prod from 'data/Product';
import Variant from 'data/Variant';
import {
  validateProduct,
  VariantError,
  VariantErrorType,
  getEmptyInventoryErrors
} from 'data/ProductValidation';
import ExportXLS from 'common/XlsVariantTable';
import DownloadXLS from 'common/XlsDownloadVariantTable';
import AttributesEditor from 'common/AttributesEditor';
import AttributeValueEditor from 'common/AttributeValueEditor';
import VariantIssueSelector from 'common/VariantIssueSelector';
import Button from 'components/UI/Button';
import ConfirmModal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import Table from 'view/Table';
import { renderToast } from 'components/UI/ToastNotification';
import * as Arr from 'lib/Array';
import { Size } from 'lib/styles';
import { unique } from 'lib/filters';
import Vendor from 'data/Vendor';
import Attribute from 'data/Attribute';
import { createVariant } from 'utils/variant';
import { Space } from 'styles/themeSpaces';
import { Main, Background } from 'styles/themeColors';
import FeatureFlag from 'data/FeatureFlag';
import Currency from 'data/Currency';
import { Role } from 'data/User';
import ProductStock from 'data/ProductsStock';
import Country from 'data/Country';

const ClearAllFilters = styled.a`
  cursor: pointer;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: inline-block;
  margin: 6rem 0px 0px;
  width: 100%;
  padding: ${Size.medium};
`;

const VariantActions = styled.div`
  align-items: center;
  display: flex;
  min-width: 40rem;
  width: 100%;
  justify-content: space-between;
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

type AttributeType = 'classification' | 'selectable';


interface Props {
  loading: boolean;
  product: Product;
  update: (product: Product) => void;
  selectedVendor: Vendor;
  disabled?: boolean;
  vendorAttributes: Attribute[];
  publishedSKUList: string[];
  reloadVendorAttributes: (value: boolean) => void;
  featureFlags: FeatureFlag;
  currencies: Currency[];
  role?: Role;
  stockFlag?: boolean;
  countries: Country[];
}

interface State {
  addingAttribute: boolean;
  addingAttributeType: null | AttributeType;
  editingAttributes: boolean;
  editingAttributeValues: null | number;
  editingVariantKey: string;
  selectedIssue: VariantError[];
  suggestionValuesList: string[],
  previousAttributeKey: string;
  showDeleteVariantModal: boolean;
  variantToDelete: Variant | null;
}

export default class VariantTable extends React.Component<Props, State> {
  state: State = {
    addingAttribute: false,
    addingAttributeType: null,
    editingAttributes: false,
    editingAttributeValues: null,
    editingVariantKey: '',
    selectedIssue: [],
    suggestionValuesList: [],
    previousAttributeKey: '',
    showDeleteVariantModal: false,
    variantToDelete: null
  };

  getActiveModal = () => {
    if (this.state.editingAttributeValues || this.state.editingVariantKey) {
      const attributes = Prod.getAllUniqueAttributesWithKey(
        this.props.product,
        this.props.product.attributes,
        this.state.editingAttributeValues,
        this.state.editingVariantKey
      );

      const foundProductAttribute = this.props.product.attributes.find(attribute => attribute.id === this.state.editingAttributeValues);

      return (
        <AttributeValueEditor
          showModal={this.state.editingAttributeValues || this.state.editingVariantKey}
          attributes={attributes}
          attributeKey={this.state.editingAttributeValues}
          selectedVendor={this.props.selectedVendor}
          onClose={() =>
            this.setState({
              editingAttributeValues: null,
              editingVariantKey: ''
            })
          }
          onSave={this.updateAttributes}
          foundProductAttributeName={foundProductAttribute ? foundProductAttribute.name : this.state.editingVariantKey}
        />
      );
    } else {
      return (
        <AttributesEditor
          showModal={this.state.editingAttributes}
          onClose={() =>
            this.setState(state => ({
              ...state,
              editingAttributes: false
            }))
          }
          product={this.props.product}
          update={this.props.update}
          selectedVendor={this.props.selectedVendor!}
          vendorAttributes={this.props.vendorAttributes.map(el => { return { id: el.id, code: el.code, name: el.name } })}
          reloadVendorAttributes={this.props.reloadVendorAttributes}
        />
      );
    }
  };

  isErrorColumn = (key: string, sku: string) => {
    const { selectedIssue } = this.state;
    const selectedIssueTemp = selectedIssue.filter(issue =>  {
      const existIssueInVariant = issue.variants.find( variant => variant.sku ===  sku )
      return existIssueInVariant && sku ? true : false
    })
    const selectedIssueTypes = selectedIssueTemp.map(selectedIssueObj => selectedIssueObj.type)
    const selectedIssueKeys = selectedIssueTemp.filter(item => (item.type === "MissingValue" || item.type === "EmptyAttributeValue")).map(selectedIssueObj => selectedIssueObj?.key)


    if (selectedIssueTypes.length) {
      if (
        selectedIssueTypes.includes(VariantErrorType.SKUValidation)  || 
        selectedIssueTypes.includes(VariantErrorType.DuplicateUniqueValue) ||
        selectedIssueTypes.includes(VariantErrorType.InvalidSKUValue) ||
        selectedIssueTypes.includes(VariantErrorType.InvalidSKULength) ||
        selectedIssueTypes.includes(VariantErrorType.InvalidMaterialNumberLength)
      ) {
       if(key === 'sku') return true;
      }
      if(
        selectedIssueTypes.includes(VariantErrorType.MaterialNumberValidation)  || 
        selectedIssueTypes.includes(VariantErrorType.InvalidMaterialNumber)  || 
        selectedIssueTypes.includes(VariantErrorType.DuplicateMaterialNumber) ||
        selectedIssueTypes.includes(VariantErrorType.EmptyMaterialNumber)
      ){
      if(key === 'materialNumber')  return true
      }

      if (selectedIssueTypes.includes(VariantErrorType.MissingValue)) {
        return selectedIssueKeys.includes(key)
      }

      if(
        selectedIssueTypes.includes(VariantErrorType.EmptyAttributeValue)
      ){
        return selectedIssueKeys.includes(key);
      }

      if (selectedIssueTypes.includes(VariantErrorType.DuplicateVariant)) {
        return this.props.product.attributes.map(attribute => attribute.name).includes(key);
      }

    }

    return false;
  };

  onAttributesEdit = () => {
    this.setState(state => ({
      ...state,
      editingAttributes: true
    }));
  };

  onAttributeValuesEdit = (key: number, headerKey: string) => {
    this.setState(state => ({
      ...state,
      editingAttributeValues: key,
      editingVariantKey: headerKey
    }));
  };

  onCellEdit = (
    newAttributeValue: string | number | boolean,
    variant: Variant,
    attributeKey: string,
    keyId: number | null = null,
    extras: any = {}
  ) => {

    if (attributeKey === 'hidden' && this.props.product.hidden === true) {
      renderToast(toast.TYPE.INFO, 'The product is hidden. If you want to show individual variants, please uncheck the "Hide product" checkbox on the Details page.');
      return;
    }

    if(this.props.product?.vendor?.isSlbVendor){
      if(attributeKey === 'materialNumber'){
        variant.variantValidationStatus = undefined;
      }
    }else{
      if(attributeKey === 'sku'){
        variant.variantValidationStatus = undefined;
      }
    }

    const foundVariantIndex = this.props.product.variants.findIndex(prodVariant => prodVariant === variant);

    // Update existing variant object state, if found
    if (foundVariantIndex !== -1) {
      // Set product unit price column value per variant
      if (attributeKey === 'pricePerUnit') {
        const { countryId } = extras;
        const newProduct = Prod.setProductVariantPriceValue(this.props.product, foundVariantIndex, keyId, attributeKey, newAttributeValue, countryId)
        this.props.update(newProduct);
        return
      }

      // Set product stock columns value per variant
      if (attributeKey === 'available' || attributeKey === 'deliveryLeadDays' || attributeKey === 'postOrderShippingCost') {
        const { warehouseId } = extras;
        const newProduct = Prod.setProductStockValue(this.props.product, foundVariantIndex, keyId, attributeKey, newAttributeValue, warehouseId)
        this.props.update(newProduct);
        return
      }

      // Set classifications/technical specifications column value per variant
      const foundVendorAttribute = keyId
        ? this.props.vendorAttributes.find(attribute => attribute.id === keyId)
        : this.props.vendorAttributes.find(attribute => attribute.name.toLowerCase() === attributeKey.toLowerCase());
      const foundProductAttribute = keyId
        ? this.props.product.attributes.find(attribute => attribute.id === keyId)
        : this.props.product.attributes.find(attribute => attribute.name.toLowerCase() === attributeKey.toLowerCase());

      if (foundVendorAttribute && foundProductAttribute && typeof newAttributeValue === 'string') {
        const foundVendorValue = foundVendorAttribute.values.find(value => value.name.toLowerCase() === newAttributeValue.toLowerCase());
        const newValue = foundVendorValue
          ? { ...foundVendorValue, attributeId: foundVendorAttribute.id, displayOrder: 0, isSelectable: foundProductAttribute.isSelectable }
          : { id: 0, attributeId: foundVendorAttribute.id, code: '', name: newAttributeValue, displayOrder: 0, isSelectable: foundProductAttribute.isSelectable };

        let newProduct: Product;

        newProduct = Prod.setAttribute(
          this.props.product,
          variant,
          foundVariantIndex,
          newValue,
          foundProductAttribute.id
        );

        this.props.update(newProduct);
        this.setState({ suggestionValuesList: foundVendorAttribute.values.map(el => el.name.toLowerCase()).filter(el => el.includes(newAttributeValue.toLowerCase())) });
      }
      else {
        // Set basic columns value per variant
        const newProduct = Prod.setAttribute(
          this.props.product,
          variant,
          foundVariantIndex,
          null,
          0,
          attributeKey,
          newAttributeValue
        );

        this.props.update(newProduct);
      }
    }

    // If the variant is not found, create a new variant object
    if (foundVariantIndex === -1) {
      if (newAttributeValue !== '' && newAttributeValue !== 0) {
        let warehouseIds : any = [];
        if(this.props.product.variants[0]){
          warehouseIds = this.props.product.variants[0].productStocks?.map(x => x.warehouseId);
        }
        this.onCreateVariant(newAttributeValue, attributeKey, extras, warehouseIds);
      }
    }

  };

  onCreateVariant = (newAttributeValue: string | number | boolean, attributeKey: string, extras: any, warehouseIds: any) => {
    const variants = [
      ...this.props.product.variants,
      createVariant({}, this.props.product.attributes)
    ];

    let newProduct = { ...this.props.product, variants }

    // Set product unit price column value per variant
    if (attributeKey === 'pricePerUnit') {
      const { countryId } = extras;
      const _newProduct = Prod.setProductVariantPriceValue(newProduct, variants.length - 1, null, attributeKey, newAttributeValue, countryId)
      this.props.update(_newProduct);
      return
    }

    // Set product stock columns value per variant
    if (attributeKey === 'available' || attributeKey === 'deliveryLeadDays' || attributeKey === 'postOrderShippingCost') {
      const { warehouseId } = extras;
      const _newProduct = Prod.setProductStockValue(newProduct, variants.length - 1, null, attributeKey, newAttributeValue, warehouseId)
      this.props.update(_newProduct);
      return
    }

    if(warehouseIds?.length > 0){
      let variantProductStocks: ProductStock[] = [];
      warehouseIds.map((warehouseId: number) => {
        const vProductStock: ProductStock = {
          warehouseId,
          availableForPickup: false,
          availableForShipping: false,
          deliveryLeadDays: 1,
          postOrderShippingCost: false,
          available: true
        }

        variantProductStocks.push(vProductStock);
      })

      let variantsCopy = [...variants]
      variantsCopy[variants.length - 1].productStocks = variantProductStocks;

      this.props.update({...newProduct, variants: variantsCopy});
      return;
    }

    const foundVendorAttribute = this.props.vendorAttributes.find(attribute => attribute.name.toLowerCase() === attributeKey.toLowerCase());
    const foundProductAttribute = newProduct.attributes.find(attribute => attribute.name.toLowerCase() === attributeKey.toLowerCase());

    if (foundVendorAttribute && foundProductAttribute && typeof newAttributeValue === 'string') {
      const foundVendorValue = foundVendorAttribute.values.find(value => value.name === newAttributeValue);

      const newValue = foundVendorValue
        ? { ...foundVendorValue, attributeId: foundVendorAttribute.id, displayOrder: 0, isSelectable: foundProductAttribute.isSelectable }
        : { id: 0, attributeId: foundVendorAttribute.id, code: '', name: newAttributeValue, displayOrder: 0, isSelectable: foundProductAttribute.isSelectable };

      newProduct = Prod.setAttribute(
        newProduct,
        variants[variants.length - 1],
        variants.length - 1,
        newValue,
        foundProductAttribute.id
      );

      this.props.update(newProduct);
    } else {
      newProduct = Prod.setAttribute(
        newProduct,
        variants[variants.length - 1],
        variants.length - 1,
        null,
        0,
        attributeKey,
        newAttributeValue
      );

      this.props.update(newProduct);
    }
  };

  onRowDeleteConfirm = () => {
    const variants = Arr.remove(this.props.product.variants, this.state.variantToDelete!);
    this.props.update({ ...this.props.product, variants });
    this.onDeleteVariantModalClose();
  };

  onRowDelete = (variant: Variant) => {
    this.setState(state => ({
      ...state,
      showDeleteVariantModal: true,
      variantToDelete: variant
    }));
  };

  onDeleteVariantModalClose = () => {
    this.setState(state => ({
      ...state,
      showDeleteVariantModal: false
    }));
  };

  deleteVariantModal = () => {
    if (this.state.showDeleteVariantModal) {
      return (
        <ConfirmModal
          showModal
          onClose={this.onDeleteVariantModalClose}
          onClick={this.onRowDeleteConfirm}
          title="Delete variant"
          ghostButtonText="Back"
        >
          <FlexboxContainer flexDirection="column">
            <Span>
              Are you sure you want to delete the variant?
            </Span>
          </FlexboxContainer>
        </ConfirmModal>
      );
    }
  };

  clearSuggestions = () => {
    this.setState({ suggestionValuesList: [] });
  };

  updateAttributes = (newAttributeValues: Prod.VariantUpdate[]) => {
    const foundVendorAttribute = this.props.vendorAttributes.find(attribute => attribute.id === this.state.editingAttributeValues);
    const foundProductAttribute = this.props.product.attributes.find(attribute => attribute.id === this.state.editingAttributeValues);

    if (foundVendorAttribute && foundProductAttribute) {
      const foundVendorValue = foundVendorAttribute.values.find(value => value.name === newAttributeValues[0].new);
      if (this.state.editingAttributeValues) {
        const newValue = foundVendorValue
          ? { ...foundVendorValue, attributeId: foundVendorAttribute.id, displayOrder: 0, isSelectable: foundProductAttribute.isSelectable }
          : { id: 0, attributeId: foundVendorAttribute.id, code: '', name: newAttributeValues[0].new, displayOrder: 0, isSelectable: foundProductAttribute.isSelectable };

        const newProduct = Prod.updateAttributeValuesWithKey(
          this.props.product,
          newAttributeValues,
          newValue
        );

        this.props.update(newProduct);
        this.setState({
          editingAttributeValues: null
        });
      }
    } else {
      if (this.state.editingVariantKey) {
        const newProduct = Prod.updateAttributeValuesWithKey(
          this.props.product,
          newAttributeValues,
          null,
          this.state.editingVariantKey
        );

        this.props.update(newProduct);
        this.setState({
          editingVariantKey: ''
        });
      }
    }
  };

  // updateAttributeValueOrdering = (newOrdering: Prod.AttributeValueOrdering) => {
  //     const { update, product } = this.props;
  //     update({ ...product, attributeOrdering: newOrdering });
  // }

  render() {
    const { loading, product, disabled, publishedSKUList, currencies,selectedVendor,stockFlag } = this.props;
    if (loading) {
      return <LoadingIndicator />;
    }

    const newVariants = [...product.variants];

    const {
      attributes
    } = product;

    const validationErrors = validateProduct(product, this.props.role);
    const InventoryError = getEmptyInventoryErrors(product);
    // Add the last row in the table (empty variant)
    if (newVariants.length === 0 || (newVariants.length > 0 && newVariants[newVariants.length - 1].sku)) {
      newVariants.push(createVariant({}, attributes));
    }

    const tableRows = newVariants
      .map(variant => {
        // let focused = false;
        // if (this.state.selectedIssue?.length) {
        //   const existVariant = this.state.selectedIssue.find(vari => (vari.variants.some(
        //     selectedVariant => selectedVariant.sku === variant.sku
        //   ))) 
        //   focused = existVariant ? true : false
        // }
        return {
          variant,
          errorInventoryTypes: InventoryError
            .filter(error => error.variants.includes(variant))
            .map(error => error.type)
            .filter(unique),
          errorTypes: validationErrors.variantErrors
            .filter(error => error.variants.includes(variant))
            .map(error => error.type)
            .filter(unique),
          focused: true
        };
      })
      // .sort((a, b) => {
      //   if (a.focused === b.focused) return 0;
      //   if (a.focused) return -1;
      //   return 1;
      // });

    let clearAllFiltersButton = null;

    if (this.state.selectedIssue.length) {
      clearAllFiltersButton = (
        <ClearAllFilters
          onClick={e => {
            e.preventDefault();
            this.setState({ selectedIssue: [] });
          }}
        >
          Clear all filters
        </ClearAllFilters>
      );
    }

    const exportButton = <ExportXLS product={product} disabled={disabled} role={this.props.role} countries={this.props.countries}  rentFlag={this.props?.featureFlags?.rent?.enabled}/>;
    const anchorData =  <DownloadXLS product={product} disabled={disabled} role={this.props.role} />;

    return (
      <Fragment>
        <Container>
          {this.getActiveModal()}
          {this.deleteVariantModal()}
          <InnerContainer>
            <VariantActions>
              <VariantIssueSelector
                errors={validationErrors.variantErrors}
                onSelect={(error: VariantError[]) => this.setState({ selectedIssue: error })}
                visible={
                  validationErrors.variantErrors.length > 0 ||
                  this.state.selectedIssue.length > 0
                }
                isSlbVendor={product.vendor?.isSlbVendor}
              />
              {clearAllFiltersButton}
              <FlexboxContainer justifyContent="flex-end">
                {exportButton}
                {anchorData}
                <Button
                  margin="0 15px 0 10px"
                  onClick={this.onAttributesEdit}
                  isGhost
                  color={Main.accent}
                  disabled={disabled}
                >
                  Edit attributes
                </Button>
              </FlexboxContainer>
            </VariantActions>


            <Table
              featureFlags={this.props.featureFlags}
              classifications={product.attributes.filter(attribute => !attribute.isSelectable)}
              focusedMode={this.state.selectedIssue.length > 0}
              isErrorColumn={this.isErrorColumn}
              onAttributeEdit={this.onAttributeValuesEdit}
              onRowDelete={this.onRowDelete}
              onCellEdit={this.onCellEdit}
              rows={tableRows}
              suggestionValuesList={this.state.suggestionValuesList}
              attributes={product.attributes.filter(attribute => attribute.isSelectable)}
              clearSuggestions={this.clearSuggestions}
              originalId={product.originalId}
              disabled={disabled}
              product={product}
              publishedSKUList={publishedSKUList}
              currencies={currencies}
              selectedVendor={selectedVendor}
              stockFlag={stockFlag}
            />
          </InnerContainer>
        </Container>
        {/*<AttributeOrdering variants={product.variants} existingOrder={product.attributeOrdering || {}} updateProduct={this.updateAttributeValueOrdering} />*/}
      </Fragment>
    );
  }
}
