import React from 'react';
import styled, { css } from 'styled-components';
import { Background } from 'styles/themeColors';
import { Height } from 'styles/themeHeights';
import { Space } from 'styles/themeSpaces';

const Header = styled.header<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0px;
  width: 70vw;
  height: ${Height.xxxl};
  background-color: ${Background.white};
  padding: ${Space.spBase} ${Space.sp2x};
  z-index: 100;

  ${({ fixedLeft }) => fixedLeft && css`
    left: ${fixedLeft};
  `}
`;

const Left = styled.div`
  display: flex;
  flex: 1;
`;

const Middle = styled.div`
  text-align: center;
`;

const Right = styled.div`
  flex: 1;
  min-height: 1em;
  text-align: right;
  display: flex;
  margin-left: 20%;
`;

export interface Props {
  left: React.ReactNode;
  middle?: React.ReactNode;
  right?: React.ReactNode;
  fixedLeft?: string;
};

const FixedHeader = ({ left, middle, right, fixedLeft }: Props) => (
  <Header className="card-elevation" fixedLeft={fixedLeft}>
    <Left>{left}</Left>
    <Middle>{middle}</Middle>
    <Right>{right}</Right>
  </Header>
);

export default FixedHeader;
