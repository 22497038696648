import React from 'react';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as Prod from 'data/Product';
import Button from 'components/UI/Button';
import styled from 'styled-components';
import { Main } from 'styles/themeColors';
import { COUNTRY_ISO } from 'constants/country';
import { WAREHOUSE_TYPE } from 'constants/warehouseDeliveryOptions';

const ExportXLSButton = styled(Button).attrs()`
  margin-left: auto;
`;

const ExportXLS = ({ product, disabled, role, countries, rentFlag }) => {

  let columnsData = [];
  let warehouseColsObj = {};
  let warehouseCurrencyObj = {};
  let variantRentalPriceRangePrices = {};
  let warehouseRentalOption = [];
  // if variants are present else create empty columns
  if (product.variants.length > 0) {
    product.variants.forEach(variantObject => {
      let variant = {
            'SKU': variantObject && variantObject.sku ? variantObject.sku : '',
            'Vendor Part Number': variantObject && variantObject.vendorPartNumber ? variantObject.vendorPartNumber : '',
            'Purchase': variantObject && variantObject.purchase ? 'Yes' : 'No',
            ...(rentFlag && { 'Rent': variantObject?.rent ? 'Yes' : 'No' }), // Conditionally add Rent property
            'Item Type': variantObject && variantObject?.itemType ? variantObject?.itemType : '',
            'Hidden': variantObject && variantObject.hidden ? 'Yes' : 'No',
            'Weight': variantObject && variantObject.weight ? variantObject.weight : 0,
            'Material Number':  variantObject && variantObject.materialNumber ? variantObject.materialNumber : ''
          };

      if(!variantObject.productStocks.length){
        product.warehouses.forEach(warehouse => {
          if((rentFlag && warehouse?.type ===  WAREHOUSE_TYPE.RENT) || warehouse?.type ===  WAREHOUSE_TYPE.PURCHASE){
            warehouseColsObj[`In stock "${warehouse.code} ${warehouse.name} ${warehouse?.type}" warehouse`] = 'No'
            warehouseColsObj[`Lead days "${warehouse.code} ${warehouse.name} ${warehouse?.type}" warehouse`] = product?.deliveryLeadDays ? product?.deliveryLeadDays : 1
            if(product?.postOrderShippingCost && warehouse.delivery){
              warehouseColsObj[`Post-order shipping cost "${warehouse.code} ${warehouse.name} ${warehouse?.type}" warehouse`] = product?.postOrderShippingCost ? 'Yes' : 'No'
            }
          }
        });
      }else{
        variantObject.productStocks.forEach(productStock => {
            product.warehouses.forEach(warehouse => {
              if((rentFlag && warehouse?.type ===  WAREHOUSE_TYPE.RENT) || warehouse?.type ===  WAREHOUSE_TYPE.PURCHASE){
                if (warehouse.id === productStock.warehouseId && warehouse.type === productStock.type) {
                  warehouseColsObj[`In stock "${warehouse.code} ${warehouse.name} ${warehouse?.type}" warehouse`] = productStock.available ? 'Yes' : 'No'
                  warehouseColsObj[`Lead days "${warehouse.code} ${warehouse.name} ${warehouse?.type}" warehouse`] = productStock.deliveryLeadDays ? productStock.deliveryLeadDays : 0
                  if(product?.postOrderShippingCost && warehouse.delivery){
                    warehouseColsObj[`Post-order shipping cost "${warehouse.code} ${warehouse.name} ${warehouse?.type}" warehouse`] = productStock.postOrderShippingCost ? 'Yes' : 'No'
                  }
                }
              }
            });
        });
      }
      product.warehouses.forEach(warehouse => {
        if(countries.length > 0){
          if(warehouse?.address?.country?.countryIso === COUNTRY_ISO.US && warehouse.type === WAREHOUSE_TYPE.PURCHASE){
            warehouseCurrencyObj["USA price (USD)"] = 0;   
          }else if(warehouse?.address?.country?.countryIso === COUNTRY_ISO.CA && warehouse.type === WAREHOUSE_TYPE.PURCHASE){
            warehouseCurrencyObj["Canada price (CAD)"] = 0; 
          }else if(rentFlag && warehouse?.address?.country?.countryIso === COUNTRY_ISO.US && warehouse.type === WAREHOUSE_TYPE.RENT){
            warehouseRentalOption.push(COUNTRY_ISO.US);
            for(let i = 1; i <= 5; i++){
              variantRentalPriceRangePrices[`Price Range ${i} (US) [Range]`] = '';
              variantRentalPriceRangePrices[`Price Range ${i} (US) [Range Units]`] = '';
              variantRentalPriceRangePrices[`Price Range ${i} (US) [Range price]`] = 0;
            }
          }else if(rentFlag && warehouse?.address?.country?.countryIso === COUNTRY_ISO.CA && warehouse.type === WAREHOUSE_TYPE.RENT){
            warehouseRentalOption.push(COUNTRY_ISO.CA);
            for(let i = 1; i <= 5; i++){
              variantRentalPriceRangePrices[`Price Range ${i} (CA) [Range]`] = '';
              variantRentalPriceRangePrices[`Price Range ${i} (CA) [Range Units]`] = '';
              variantRentalPriceRangePrices[`Price Range ${i} (CA) [Range price]`] = 0;
            }
          }
        }
      });

      variantObject.productVariantPrices.forEach(productVariantPrice => {
        if (countries.length > 0) {
          const country = countries.filter(cn => cn.id === productVariantPrice.countryId);
          if(COUNTRY_ISO.US === country[0]?.countryIso && warehouseCurrencyObj["USA price (USD)"] === 0 && productVariantPrice.priceType === WAREHOUSE_TYPE.PURCHASE){
            warehouseCurrencyObj["USA price (USD)"] = productVariantPrice.pricePerUnit ? productVariantPrice.pricePerUnit : 0   
          }else if(COUNTRY_ISO.CA === country[0]?.countryIso && warehouseCurrencyObj["Canada price (CAD)"] === 0 && productVariantPrice.priceType === WAREHOUSE_TYPE.PURCHASE){
            warehouseCurrencyObj["Canada price (CAD)"] = productVariantPrice.pricePerUnit ? productVariantPrice.pricePerUnit : 0
          }
        }
      });
      if(rentFlag && variantObject?.rentalPriceCA?.length > 0 && warehouseRentalOption.includes(COUNTRY_ISO.CA)){
        variantObject.rentalPriceCA.forEach((rentalPrice,index) => {
          variantRentalPriceRangePrices[`Price Range ${index+1} (CA) [Range]`] = rentalPrice.range || '';
          variantRentalPriceRangePrices[`Price Range ${index+1} (CA) [Range Units]`] = 'Days'
          variantRentalPriceRangePrices[`Price Range ${index+1} (CA) [Range price]`] = rentalPrice.rangePrice || 0;
        });
      }
      if(rentFlag && variantObject?.rentalPriceUS?.length > 0 && warehouseRentalOption.includes(COUNTRY_ISO.US)){
        variantObject.rentalPriceUS.forEach((rentalPrice,index) => {
          variantRentalPriceRangePrices[`Price Range ${index+1} (US) [Range]`] = rentalPrice.range || '';
          variantRentalPriceRangePrices[`Price Range ${index+1} (US) [Range Units]`] = 'Days'
          variantRentalPriceRangePrices[`Price Range ${index+1} (US) [Range price]`] = rentalPrice.rangePrice || 0;
        });
      }

      const { selectableAttributes, classificationAttributes } = Prod.separateAttributes(product.attributes);
      const variantAttributes = Prod.getAttributesForVariant([...selectableAttributes, ...classificationAttributes], variantObject.attributeValues);
      columnsData.push({ ...variant, ...warehouseColsObj, ...warehouseCurrencyObj, ...variantAttributes, ...variantRentalPriceRangePrices });
    });
  } else {
    const variant = {
      'SKU': '',
      'Vendor Part Number': '',
      'Purchase': 'Yes',
      ...(rentFlag && { 'Rent' : 'Yes' }), // Conditionally add Rent property
      'Item Type': '',
      'Hidden': 'No',
      'Weight': 0,
      'Material Number': ''
    };

    product.warehouses.forEach(warehouse => {
      warehouseColsObj[`In stock "${warehouse.code} ${warehouse.name} ${warehouse?.type}" warehouse`] = 'No'
      warehouseColsObj[`Lead days "${warehouse.code} ${warehouse.name} ${warehouse?.type}" warehouse`] = product.deliveryLeadDays || 0;
      if(product?.postOrderShippingCost && warehouse.delivery){
          warehouseColsObj[`Post-order shipping cost "${warehouse.code} ${warehouse.name} ${warehouse?.type}" warehouse`] = product?.postOrderShippingCost ? 'Yes' : 'No'
                }
    });
    product.warehouses.forEach(warehouse => {
      if (countries.length > 0) {
        const country = countries.filter(cn => cn.id === warehouse?.address?.country?.id);
        if(country[0].countryIso === COUNTRY_ISO.US && warehouse.type === WAREHOUSE_TYPE.PURCHASE){
          warehouseCurrencyObj["USA price (USD)"] = 0;   
        }else if(country[0].countryIso === COUNTRY_ISO.CA && warehouse.type === WAREHOUSE_TYPE.PURCHASE){
          warehouseCurrencyObj["Canada price (CAD)"] = 0; 
        }else if(rentFlag && country[0].countryIso === COUNTRY_ISO.US && warehouse.type === WAREHOUSE_TYPE.RENT){
          for(let i = 1; i <= 5; i++){
            variantRentalPriceRangePrices[`Price Range ${i} (US) [Range]`] = '';
            variantRentalPriceRangePrices[`Price Range ${i} (US) [Range Units]`] = '';
            variantRentalPriceRangePrices[`Price Range ${i} (US) [Range price]`] = 0;
          }
        }else if(rentFlag && country[0].countryIso === COUNTRY_ISO.CA && warehouse.type === WAREHOUSE_TYPE.RENT){
          for(let i = 1; i <= 5; i++){
            variantRentalPriceRangePrices[`Price Range ${i} (CA) [Range]`] = '';
            variantRentalPriceRangePrices[`Price Range ${i} (CA) [Range Units]`] = '';
            variantRentalPriceRangePrices[`Price Range ${i} (CA) [Range price]`] = 0;
          }
        }
      }
    });

    const { selectableAttributes, classificationAttributes } = Prod.separateAttributes(product.attributes);
    const attributeNames = [...selectableAttributes.map(x => x.name), ...classificationAttributes.map(x => x.name)]
    let attributesObj = {}
    attributeNames.forEach(attr => {
      attributesObj[attr] = ''
    });
    columnsData.push({ ...variant, ...warehouseColsObj, ...warehouseCurrencyObj, ...attributesObj, ...variantRentalPriceRangePrices });
  }

  const selectableAttributes = product.attributes.filter(attribute => attribute.isSelectable);
  const classificationAttributes = product.attributes.filter(attribute => !attribute.isSelectable);

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Variants');

    // Add headers
    const headers = Object.keys(columnsData[0]);
    const headerDefinitions = headers.map(el => {
      if (selectableAttributes.find(attribute => attribute.name === el)) {
        return { title: `Selectable attribute [${el}]` }
      }
      if (classificationAttributes.find(attribute => attribute.name === el)) {
        return { title: `Technical specification [${el}]` }
      }
      return { title: el };
    });
    
    const headerRow = worksheet.addRow(headerDefinitions.map(definition => definition.title));
    headerRow.eachCell((cell) => {
      cell.alignment = { horizontal: 'center', vertical: 'center' };
      cell.font = { size: 10 }
    })

    // Add data rows
    columnsData.forEach((rowData) => {
      const dataRow = worksheet.addRow(Object.values(rowData));
      dataRow.eachCell((cell) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
        cell.font = { size: 10 }

        // Get header name for the cell from header row
        const headerName = headerRow.getCell(cell.col).value;

        // Check if the cell belongs to a selectable attribute
        if (headerName.startsWith('Selectable attribute') || headerName.startsWith('Technical specification')) {
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'b3ffb3' } };
        } else {
          cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'b3ecff' } };
        }
      });
    });

    // Auto-size columns
    worksheet.columns.forEach((column) => {
      let maxColumnLength = 0;
      column.eachCell((cell) => {
        const columnLength = cell.value ? cell.value.toString().length : 0;
        if (columnLength > maxColumnLength) {
          maxColumnLength = columnLength;
        }
      });
      column.width = maxColumnLength < 10 ? 10 : maxColumnLength + 2;
    });

    // Save workbook as Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const fileName = `${product.name || 'unnamed-product'}.xlsx`;
    saveAs(blob, fileName);
  };


  return (
    <div>
      <ExportXLSButton isGhost color={Main.accent} disabled={disabled} onClick={handleExport}>Export XLSX</ExportXLSButton>
    </div>
  );
}

export default ExportXLS;
