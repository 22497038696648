import FlexboxContainerComponent from '../../UI/FlexboxContainer';
import { Check } from '@material-ui/icons';
import { Height } from 'styles/themeHeights';
import React from 'react';
import styled from 'styled-components';
import { Background, Border, Main, Shade } from 'styles/themeColors';

const SubTitle = styled.h5`
  margin: 0px !important;
  font-size: 16px;
  line-height: 16px;
`;

const StepNumber = styled.span<{$completed?: boolean}>`
  font-size: 16px;
  line-height: 20px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  color: ${Background.white};
  padding: 2px 7px;
  margin-bottom: 4px;
  background: ${({ $completed }) => ($completed ? Main.brandLight : Shade.shade230)};
`;

const StyledHr = styled.hr`
  width: 150px;
  color: ${Border.main};
  margin: 12px 4px;
  height: 1px;
`;

const StyledCheck = styled(Check)`
  color: ${Background.white};
  height: ${Height.xsPlus} !important;
  width: 12px !important;
`;

interface PricebookUploadStepperProps {
  step: number;
  publicationState: boolean;
}

const PricebookUploadStepper = ({step, publicationState}: PricebookUploadStepperProps) => {
  return (
    <FlexboxContainerComponent margin='0 auto' flexDirection='row'>
      <FlexboxContainerComponent
        flexDirection='column'
        alignItems='center'
        width='24px'
      >
        <StepNumber $completed={step >= 1}>
          {step > 1 ? (
            <StyledCheck/>
          ) : (
            <>1</>
          )}
        </StepNumber>
        <SubTitle>Upload</SubTitle>
      </FlexboxContainerComponent>
      <StyledHr/>
      <FlexboxContainerComponent
        flexDirection='column'
        alignItems='center'
        width='24px'
      >
        <StepNumber $completed={step >= 2}>
          {publicationState ? (
            <StyledCheck/>
          ) : (
            <>2</>
          )}
        </StepNumber>
        <SubTitle>Verification</SubTitle>
      </FlexboxContainerComponent>
      <StyledHr/>
      <FlexboxContainerComponent
        flexDirection='column'
        alignItems='center'
        width='24px'
      >
        <StepNumber $completed={publicationState}>3</StepNumber>
        <SubTitle>Publication</SubTitle>
      </FlexboxContainerComponent>
    </FlexboxContainerComponent>
  );
};

export default PricebookUploadStepper;
