import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Vendor from 'data/Vendor';
import styled from 'styled-components';
import Warehouse from 'data/Warehouse';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { latoFont } from 'OnboardingComponents/Elements/Typography';
import PickupLocationCard from 'common/PickupLocationCard';
import PointOfService, * as POS from 'data/PointOfService';
import AddWarehouse from 'common/AddWarehouse';
import { renderToast } from 'components/UI/ToastNotification';
import { useAPI } from 'lib/API';
import { Color, Percent, FontWeight } from 'lib/styles';
import { createPointOfService, savePointOfService } from 'requests/pointOfService';
import { Background, Main } from 'styles/themeColors';
import { loadVendor } from 'requests/vendor';
import FeatureFlag from 'data/FeatureFlag';

const Content = styled.div`
  flex: 80%;
  background-color: ${Background.white};
  padding: 10px;
  background-color: ${Color.whiteSmoke};
`;

const DetailsHeader = styled.div`
  display: flex;
  width: ${Percent.full};
  justify-content: start;
`;

const AddNew = styled.span`
  margin-left: 24px;
  ${latoFont}
  font-size: 16px;
  font-weight: ${FontWeight.bold};
  line-height: 1.5;
  text-align: left;
  color: ${Main.accent};

  &:hover{
    cursor: pointer;
  }

  &:active {
    -webkit-box-shadow: inset 0px 0px 10px ${Color.veryLightGray};
  }
`;

interface Props {
  warehouse: Warehouse;
  addingWh: boolean;
  setAddingNew: (state: boolean) => void;
  vendorsList?: Vendor[];
  selectedVendor: Vendor;
  selectedWarehouseOption: number | null;
  reloadWarehousesReq: () => void;
  updateVendor: (name: string) => void;
  setSelectedWarehouse?: any
  featureFlags: FeatureFlag;
};

const WarehouseDetails = ({
  warehouse,
  addingWh,
  setAddingNew,
  vendorsList,
  selectedVendor,
  selectedWarehouseOption,
  reloadWarehousesReq,
  updateVendor,
  setSelectedWarehouse,
  featureFlags
}: Props) => {

  const [currentWh, setCurrentWh] = useState<Warehouse>(warehouse);
  const [adding, setAdding] = useState<boolean>(addingWh);
  const [editingPickupLocationId, setEditingPickupLocationId] = useState<number | null>(null);
  const [isUpdateWarehouseDisabled, setIsUpdateWarehouseDisabled] = useState<boolean>(true);
  const [vendor, setVendor] = useState<Vendor>({ ...selectedVendor });

  const vendorReq = useAPI({ deferFn: loadVendor });

  useEffect(() => {

      if (selectedVendor) {
          vendorReq.run(selectedVendor?.id);
      }

  }, [selectedVendor])

  useEffect(() => {
      if (vendorReq.data) {
          setVendor(vendorReq.data);
      }
  }, [vendorReq.data]);


  useEffect(() => {
    setAdding(addingWh)
  }, [addingWh]);

  const updatePOSReq = useAPI({
    deferFn: savePointOfService,
    onResolve: (result) => {
      setCurrentWh({
        ...currentWh,
        pointsOfService: currentWh.pointsOfService.map((el, i) => {
          if (el.id === result.id) {
            el = result;
          }

          return el;
        })
      });

      renderToast(toast.TYPE.SUCCESS, 'Pick-up location updated successfully.');
      reloadWarehousesReq();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const createPOSReq = useAPI({
    deferFn: createPointOfService,
    onResolve: (result) => {
      setCurrentWh({
        ...currentWh,
        pointsOfService: currentWh.pointsOfService.map((el, i) => {
          if (el.id === 0) {
            el = result;
          }

          return el;
        })
      });

      renderToast(toast.TYPE.SUCCESS, 'Pick-up location created successfully.');
      reloadWarehousesReq();
    },
    onReject: err => {
      setCurrentWh({ ...currentWh, pointsOfService: currentWh.pointsOfService.filter(el => el.id !== 0) });
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const upsertPOS = async (pos: PointOfService) => {
    if (pos.id !== 0) {
      updatePOSReq.run(pos, currentWh.id);
    }
    else {
      createPOSReq.run(pos, currentWh.id);
    }
  };

  let details = null;
  let detailsCard = null;
  if (!adding) {
    detailsCard = selectedVendor
      ? <h1>Please select a warehouse!</h1>
      : <h1>Please select a vendor!</h1>;
  }

  useEffect(() => {
    if (currentWh) {
      setCurrentWh(warehouse);
    }
  }, [warehouse]);

  const addNewPOS = () => {
    if (currentWh.pointsOfService.filter(el => el.id === 0).length !== 1) {
      setEditingPickupLocationId(0);
      setCurrentWh({ ...currentWh, pointsOfService: [...currentWh.pointsOfService, { id: 0, countryId: Number(currentWh.country?.id), ...POS.empty }] });
    }
  };

  const removePOS = (posId: number) => {
    setCurrentWh({ ...currentWh, pointsOfService: [...currentWh.pointsOfService.filter(el => el.id !== posId)] });
  };

  const removePOSOnCancel = () => {
    setCurrentWh({ ...currentWh, pointsOfService: currentWh.pointsOfService.filter(el => el.id !== 0) });
  };

  if (!adding && selectedWarehouseOption !== null && warehouse.id !== 0) {
    let cards = currentWh.pointsOfService.map((el, i) => (
      <PickupLocationCard
        key={i}
        upsertPOS={upsertPOS}
        pickupLocation={{
          ...el,
          street: el.address?.addressLine1,
          city: el.address?.town,
          zip: el.address?.postalCode,
          state: el.state
        }}
        editingId={editingPickupLocationId}
        setEditingId={setEditingPickupLocationId}
        removePOSOnCancel={removePOSOnCancel}
        removePOS={removePOS}
        isExistingPOS={true}
        reloadWarehousesReq={reloadWarehousesReq}
        country={currentWh.country}
      />
    ));

    detailsCard = (
      <Content>
        <DetailsHeader>
          Pick-up locations <AddNew onClick={() => addNewPOS()}>+Add new</AddNew>
        </DetailsHeader>
        {cards}
      </Content>
    )
  } else if (!adding && selectedWarehouseOption === null && warehouse.id !== 0) {
    details = (
      <AddWarehouse
        setAddingWarehouse={setAddingNew}
        vendorsList={vendorsList}
        selectedVendor={vendor}
        reloadWarehousesReq={reloadWarehousesReq}
        updateVendor={updateVendor}
        currentWarehouse={currentWh}
        isDisabled={isUpdateWarehouseDisabled}
        OptionsDisabled={true}
        setIsDisabled={setIsUpdateWarehouseDisabled}
        customDisabled={true}
        setSelectedWarehouse={setSelectedWarehouse}
        featureFlags={featureFlags}
      />
    );

    detailsCard = null;
  }

  if (adding) {
    details = (
      <AddWarehouse
        setAddingWarehouse={setAddingNew}
        vendorsList={vendorsList}
        selectedVendor={vendor}
        reloadWarehousesReq={reloadWarehousesReq}
        updateVendor={updateVendor}
        customDisabled={false}
        featureFlags={featureFlags}
      />
    );
  }

  let content = (
    <Content>
      {details}
      {detailsCard}
    </Content>
  );

  if (createPOSReq.isLoading || updatePOSReq.isLoading || vendorReq.isLoading) {
    content = (
      <Content>
        <LoadingIndicator />
      </Content>
    );
  }

  return content;
};

export default WarehouseDetails;
