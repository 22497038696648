import { useAPI } from '../lib/API';
import { getCountries } from '../requests/country';
import { useEffect, useState, useCallback } from 'react';
import Country from '../data/Country';


export const useCountries = () => {
  const [countries, setCountries] = useState<Country[]>([]);

  const countriesReq = useAPI({
    deferFn: getCountries,
    onResolve: (result: any) => {
      setCountries(result);
    },
    onReject: (err: any) => { },
  });

  useEffect(() => {
    updateCountries();
  }, []);

  const updateCountries = useCallback(() => {
    countriesReq.run()
  }, [countriesReq]);

  return {countries, updateCountries}
}