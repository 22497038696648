import React from 'react';
import styled from 'styled-components';
import { Text, Border, Main, Background } from 'styles/themeColors';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import OrderConsignmentEntryList from 'components/Orders/OrderConsignmentEntryList';
import { navigate } from 'hookrouter';
import Icon from 'view/Icon';
import Consignment from 'data/Consignment';
import { SHIPPING } from 'constants/deliveryModes';
import { currencySign } from 'utils/formatters';
import OrderTag from 'components/UI/OrderTag';

interface Props {
  consignment: Consignment;
  currencyIso: string;
};

const CardHeaderItem = styled.label<any>`
  text-align: ${({ $textAlign }) => $textAlign ? $textAlign : 'center'};
  width: ${({ $width }) => $width};
  color: ${Text.primary};
`;

const CardButtonIcon = styled(Icon)`
  width: 167px;

  svg {
    height: 24px;
    width: 100%;
  }
`;

const CardDivider = styled.hr<any>`
  border: 1px solid ${({ $borderColor }) => $borderColor};
  margin: 0 5px;
`;

const CardContainer = styled.div`
  background: ${Background.white};
  border: 1px solid ${Border.light};
  box-sizing: border-box;
  box-shadow: 0px 6px 16px rgba(47, 66, 83, 0.14);
  border-radius: 6px;
  margin-bottom: 14px;
  padding: 15px;
`;

const Link = styled.a<any>`
  font-weight: ${({ $fontWeight }) => $fontWeight};
  color: ${({ $color }) => $color};
  margin-right: 16px;
  cursor: pointer;
`;

const Span = styled.span<any>`
  font-weight: ${({ $fontWeight }) => $fontWeight};
  font-style: ${({ $fontStyle }) => $fontStyle};
  color: ${({ $color }) => $color};
  margin-right: 16px;
`;

const OrderConsignment = ({ consignment, currencyIso }: Props) => {
  const icon = consignment.consignmentEntries[0].deliveryMode === SHIPPING
    ? 'DeliveryFlag'
    : 'PickupFlag';

  const handleNavigateConsignment = () => {
    navigate(`/orders/order-details/${consignment.consignmentId.slice(4)}`, false);
  };

  const header = (
    <FlexboxContainer
      justifyContent='space-between'
      width='100%'
      padding='5px'
    >
      <FlexboxContainer flexDirection='row' width='100'>
        <CardHeaderItem
          className='base'
          $textAlign='left'
        >
          <Link
            $fontWeight='bold'
            $color={Main.accent}
            onClick={handleNavigateConsignment}
          >
            Order {consignment.consignmentId.slice(4)}
          </Link>
          Vendor &nbsp;
          <Span $color={Main.accent}>
            {consignment.vendor.name}
          </Span>
        </CardHeaderItem>
        <OrderTag type={consignment.consignmentType} margin='0px' />
      </FlexboxContainer>
      <CardButtonIcon name={icon} />
    </FlexboxContainer>
  );

  const section = (
    <OrderConsignmentEntryList consignmentEntries={consignment.consignmentEntries} currencyIso={currencyIso} />
  );

  const footer = (
    <>
      <CardDivider $borderColor={Border.main} />
      <FlexboxContainer
        width='100%'
        flexDirection='column'
      >
        {
          consignment.totalSurcharges
            ? (
              <FlexboxContainer
                width='100%'
                justifyContent='space-between'
                padding='5px'>
                <Span $fontStyle='italic'>
                  Includes surcharges
                </Span>
                <Span $fontStyle='italic'>
                  +{currencySign(currencyIso,consignment.totalSurcharges)}
                </Span>
              </FlexboxContainer>
            )
            : null
        }
        <FlexboxContainer
          justifyContent='space-between'
          width='100%'
          padding='5px'
        >
          <Span $fontWeight='bold'>
            Tax
          </Span>
          <Span $fontWeight='bold'>
            {currencySign(currencyIso,consignment.totalTax)}
          </Span>
        </FlexboxContainer>
        <FlexboxContainer
          width='100%'
          justifyContent='space-between'
          padding='5px'
        >
          <Span $fontWeight='bold'>
            Shipping
          </Span>
          <Span $fontWeight='bold'>
            {currencySign(currencyIso,consignment.totalDeliveryCost)}
          </Span>
        </FlexboxContainer>
      </FlexboxContainer>
      <CardDivider $borderColor={Background.navy} />
      <FlexboxContainer
        width='100%'
        justifyContent='space-between'
        padding='5px'
      >
        <Span $fontWeight='bold'>
          Order total
        </Span>
        <Span $fontWeight='bold'>
          {currencySign(currencyIso,consignment.totalPriceWithTax)}
        </Span>
      </FlexboxContainer>
    </>
  );

  return (
    <CardContainer>
      {header}
      {section}
      {footer}
    </CardContainer>
  );
};

export default OrderConsignment;
