import { COUNTRY_ISO, COUNTRY_NAMES, CURRENCY_ISO } from "constants/country";

export const getCountryName = (countryIsoCode: string) => {
    switch (countryIsoCode) {
        case COUNTRY_ISO.US:
            return COUNTRY_NAMES.USA
        case COUNTRY_ISO.CA:
            return COUNTRY_NAMES.CANADA
        default:
            return ''
    }
}


export const getCurrencySymbol = (currencyIso: string): string => {

    switch (currencyIso) {
        case CURRENCY_ISO.USD:
            return '$';
        case CURRENCY_ISO.CAD:
            return 'C$';
        default:
            return '';
    }
}
export const getCountryIsoByCurrency = (currencyIso: string) => {
    switch (currencyIso) {
        case CURRENCY_ISO.USD:
            return COUNTRY_ISO.US
        case CURRENCY_ISO.CAD:
            return COUNTRY_ISO.CA
        default:
            return COUNTRY_ISO.US
    }
}
