import { Text } from 'styles/themeColors';

const customerColumns = [
  {
    title: 'Customer Name',
    field: 'fullName',
    headerStyle: { color: Text.primary },
    width: '17%',
    removable: false
  },
  {
    title: 'Customer Phone',
    field: 'phone',
    headerStyle: { color: Text.primary },
    width: '17%'
  },
  {
    title: 'Company Email',
    field: 'email',
    headerStyle: { color: Text.primary }
  },
  {
    title: 'Compliance check',
    field: 'dowJonesStatus',
    headerStyle: { color: Text.primary },
    width: '15%',
    removable: false
  },
  {
    title: 'Company Legal Name',
    field: 'companyName',
    headerStyle: { color: Text.primary },
    width: '17%'
  },
  {
    title: 'OFMP Company Name',
    field: 'displayName',
    headerStyle: { color: Text.primary },
    width: '17%'
  },
  {
    title: 'Country',
    field: 'countryName',
    headerStyle: { color: Text.primary },
    width: '13%',
    hiddenByColumnsButton: true
  },
  {
    title: 'Created Date',
    field: 'createdDate',
    headerStyle: { color: Text.primary },
    width: '13%',
    defaultSort: 'desc'
  },
  {
    title: 'Company name input by customer',
    field: 'companyNameByCustomer',
    headerStyle: { color: Text.primary },
    width: '13%',
    hidden: true,
    hiddenByColumnsButton: true
  }
];

export default customerColumns;
