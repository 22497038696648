export interface Resource {
    id?: number | string;
    url: string;
    title: string;
}

export interface ResourceFormValidator {
    vendorIds: boolean;
    resourceTitle: boolean;
    resourceUrl: boolean;
}

export const emptyResourceObject = {
    resourceUrl: '',
    resourceTitle: ''
}

export const emptyFormObject = {
    vendorIds: true,
    resourceTitle: false,
    resourceUrl: false,
}