import React, { useState, useEffect } from 'react';
import Vendor from 'data/Vendor';
import styled from 'styled-components';
import Button from 'components/UI/Button';
import WarehouseDetails from 'common/WarehouseDetails';
import Warehouse, * as Wh from 'data/Warehouse';
import WarehouseSidebar from 'common/WarehouseSidebar';
import PageHeading from 'components/UI/PageHeading';
import { useAPI } from 'lib/API';
import { Color, Percent } from 'lib/styles';
import { loadWarehouses } from 'requests/warehouse';
import FeatureFlag from 'data/FeatureFlag';

const Wrapper = styled.div`
  width: ${Percent.full};
  margin: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${Percent.full};
  background-color: ${Color.whiteSmoke};
  padding: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin: 0 0 8px 0;
  border-bottom: 1px solid ${Color.veryLightGray};
  color: ${Color.black};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

interface Props {
  selectedVendor?: Vendor;
  vendorsList?: Vendor[];
  updateVendor: (name: string) => void;
  featureFlags: FeatureFlag;
};

const WarehouseListNew = ({ selectedVendor, vendorsList, updateVendor,featureFlags }: Props) => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>({ id: 0, ...Wh.empty });
  const [selectedWarehouseOption, setSelectedWarehouseOption] = useState<number | null>(null)
  const [addingWarehouse, setAddingWarehouse] = useState<boolean>(false);

  const warehousesReq = useAPI({
    deferFn: loadWarehouses,
    onResolve: result => {
      const foundWarehouse: any = result.find(warehouse => warehouse.id === selectedWarehouse.id);

      if (foundWarehouse) {
        setSelectedWarehouse({
          ...foundWarehouse,
          country: foundWarehouse.address.country,
          street: foundWarehouse.address.addressLine1,
          city: foundWarehouse.address.town,
          zip: foundWarehouse.address.postalCode
        });
      }
    }
  });

  useEffect(() => {
    if (warehousesReq.data) {
            const formatData = warehousesReq.data.map((wh: any) => ({
        ...wh,
        country: wh.address?.country,
        street: wh.address?.addressLine1,
        city: wh.address?.town,
        zip: wh.address?.postalCode
      }));
      setWarehouses(formatData);
    }
  }, [warehousesReq.data]);

  useEffect(() => {
    if (selectedVendor) {
      warehousesReq.run(selectedVendor ? selectedVendor.id : null);
    } else {
      setWarehouses([]);
    }

    setSelectedWarehouse({ id: 0, ...Wh.empty });
  }, [selectedVendor]);

  const setWarehouse = (id: number) => {
        const wh = warehouses.find(wh => wh.id === id);
    if (wh) {
      setSelectedWarehouse({
        ...wh,
        country: wh.address?.country,
        street: wh.address?.addressLine1,
        city: wh.address?.town,
        zip: wh.address?.postalCode
      });
    }
    else {
      setSelectedWarehouse({ id: 0, ...Wh.empty });
    }
  };

  const reloadWarehousesReq = () => {
    warehousesReq.reload();
  };

  const onAddWarehouse = () => {
    setAddingWarehouse(true);
    setSelectedWarehouse({ id: 0, ...Wh.empty });
  };

  return (
    <Wrapper>
      <Header>
        <PageHeading>Warehouses</PageHeading>
        <Button
          isSecondary
          disabled={addingWarehouse || !selectedVendor}
          onClick={onAddWarehouse}
        >
          Add warehouse
        </Button>
      </Header>
      <ContentContainer>
        <WarehouseSidebar
          warehouses={warehouses}
          selectedWarehouseId={selectedWarehouse.id}
          setSelectedWarehouse={setWarehouse}
          selectedWarehouseOption={selectedWarehouseOption}
          setSelectedWarehouseOption={setSelectedWarehouseOption}
          addingWarehouse={addingWarehouse}
        />
        <WarehouseDetails
          vendorsList={vendorsList}
          addingWh={addingWarehouse}
          warehouse={selectedWarehouse}
          setAddingNew={setAddingWarehouse}
          selectedVendor={selectedVendor!}
          selectedWarehouseOption={selectedWarehouseOption}
          reloadWarehousesReq={reloadWarehousesReq}
          updateVendor={updateVendor}
          setSelectedWarehouse={setSelectedWarehouse}
          featureFlags={featureFlags}
        />
      </ContentContainer>
    </Wrapper>
  );
};

export default WarehouseListNew;
