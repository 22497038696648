export enum Main {
  accent = '#498DDA',
  success = '#2CAE44',
  warning = '#FFC107',
  error = '#C82135',
  error2 = '#87102A',
  newError = '#FFCB93',
  disabled = '#E9ECEF',
  brandDark = '#FC780B',
  brandLight = '#F7991C',
  brandLighter = '#f7991c4a',
  brandLightest = '#fff8f3',
  white = '#FFFFFF',
  black = '#000000',
  gray = '#0E141A',
  lightGray = '#C1C9D1',
};

export enum Text {
  primary = '#0E141A',
  secondary = '#53595E',
  placeholder = '#71777C',
  disabled = '#BFC2C5',
  whitePrimary = '#FFFFFF'
};

export enum Border {
  main = '#C1C9D1',
  light = '#E1E6EB',
  brand = '#FFCB93'
};

export enum Background {
  black = '#000000',
  navy = '#18222B',
  shaded = '#F0F1F4',
  light = '#F9F9FA',
  white = '#FFFFFF',
  lightBrand = '#FFF5EB',
  transparent = '#00000000',
  red = '#C82135',
};

export enum Shade {
  shade830 = '#18222B',
  shade760 = '#313942',
  shade650 = '#4A525A',
  shade550 = '#666C73',
  shade450 = '#82888D',
  shade340 = '#A0A4A9',
  shade230 = '#BFC2C5',
  shade120 = '#DFE0E1',
  shade020 = '#F9F9FA'
};

export const Gradients = {
  navyToSuccessGreen: `linear-gradient(90deg, ${Main.success} 49%, ${Shade.shade760} 51%)`,
};
