import Modal from 'components/UI/Modal';
import React, { useState } from 'react';
import Checkbox from 'components/UI/Checkbox';
import styled from 'styled-components';
import { Main } from 'styles/themeColors';

const Span = styled.span`
  color: ${Main.error};
  font-size: 12px;
`;

interface Props {
  show: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const initialCheckbox = { checked: false, dirty: false };

const OrderCancellationEmailModal = ({ show, onConfirm, onClose }: Props) => {
  const [checkbox, setCheckbox] = useState(initialCheckbox);

  const handleOnChange = () => {
    setCheckbox({
      ...checkbox,
      checked: !checkbox.checked,
      dirty: true
    });
  };

  const handleOnConfirm = () => {
    if (!checkbox.checked) {
      setCheckbox({ ...checkbox, dirty: true });
      return;
    }
    
    onConfirm();
    handleOnClose();
  };

  const handleOnClose = () => {
    setCheckbox(initialCheckbox);
    onClose();
  };

  const errorOccurred = checkbox.dirty && !checkbox.checked;

  return (
    <Modal 
      showButtons
      showModal={show}
      title='Send Cancellation email to the customer'
      primaryButtonText='Send email'
      onClose={handleOnClose}
      onClick={handleOnConfirm}
    >
      <Checkbox 
        id='order-cancellation-email-modal-checkbox-id'
        checkboxSize='l'
        checkboxPosition='top'
        checked={checkbox.checked}
        label='I confirm that I have already made a phone call and informed the customer about the Order cancelation reasons'
        onChange={handleOnChange}
        errorOccurred={errorOccurred} />
        {errorOccurred && <Span>Confirmation is required</Span>}
    </Modal>
  )
};

export default OrderCancellationEmailModal;