import Button from 'components/UI/Button';
import Link from './Link';
import React from 'react';
import { Main } from 'styles/themeColors'
import styled, { css } from 'styled-components';
import { Color, Size } from 'lib/styles';
import * as Prod from 'data/Product';

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const Elipsis = styled.div`
  color: ${Color.darkerGray};
  margin: ${Size.small};
`;

const NextPrevButton = styled<any>(Button).attrs({
  isSecondary: true,
  isGhost: true,
  size: 'small'
})`
  border: 2px solid ${Main.accent};
  background-color: transparent;
  color: ${Main.accent} !important;
  font-size: 16px;
  line-height: 16px;
  border-radius: 6px;
  padding: 6px 20px;

  &:first-child {
    margin-right: ${Size.large};
  }

  &:last-child {
    margin-left: ${Size.large};
  }

  ${props =>
    props.hidden &&
    css`
      visibility: hidden;
    `}
`;

const PageLinks = styled.div`
  display: flex;
  align-items: center;
`;

const PageNumber = styled(Link)<any>`
  font-size: 1.2rem;
  margin: 0 ${Size.tiny} !important;
  padding: 0 ${Size.small};
`;

interface Props {
  base?: string;
  className?: string;
  page?: number;
  pageData?: Prod.ProductList;
}

const elipsis = '…';

export default function ProductPagination({
  base = '',
  className,
  page = 1,
  pageData
}: Props) {
  if (!pageData || pageData.totalPages <= 1) return null;

  function buildLinks() {
    if (!pageData) return [];
    let pageNumbers = [];
    if (pageData.totalPages <= 10) {
      pageNumbers = new Array(pageData.totalPages).fill(0).map((_, i) => i + 1);
    } else {
      const { page } = pageData;
      let startElipsis = true;
      let endElipsis = true;
      let start = page - 2;
      let end = page + 2;
      if (start <= 1) {
        const delta = 1 - start;
        startElipsis = false;
        end = end + delta + 2;
        start = 1;
      } else if (end >= pageData.totalPages) {
        const delta = end - pageData.totalPages;
        endElipsis = false;
        start = start - delta - 2;
        end = pageData.totalPages;
      }

      for (let i = start; i <= end; i++) {
        pageNumbers.push(i);
      }

      if (startElipsis) {
        pageNumbers.unshift(1, elipsis);
      }

      if (endElipsis) {
        pageNumbers.push(elipsis, pageData.totalPages);
      }
    }

    return pageNumbers.map((pageNum, index) => {
      if (pageNum === elipsis) {
        return <Elipsis key={index}>{elipsis}</Elipsis>;
      }

      const type = pageNum === page ? 'div' : Link;

      return (
        <PageNumber as={type} key={index} to={`${base}page/${pageNum}`}>
          {pageNum}
        </PageNumber>
      );
    });
  }

  const links = buildLinks();

  return (
    <Container className={className}>
      <NextPrevButton
        as={Link}
        hidden={page <= 1}
        to={`${base}page/${page - 1}`}
      >
        Previous
      </NextPrevButton>
      <PageLinks>{links}</PageLinks>
      <NextPrevButton
        as={Link}
        hidden={page >= pageData.totalPages}
        to={`${base}page/${page + 1}`}
      >
        Next
      </NextPrevButton>
    </Container>
  );
}

ProductPagination.defaultProps = {};
