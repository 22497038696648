import React from 'react';

import { Card, Heading1, Heading4, DownloadLink } from 'OnboardingComponents/Elements';


const ProcessGuidelines = () => {
  return (
    <Card>
      <Heading1>Process guidelines</Heading1>
      <Heading4>A quick view of how easy it is to go from sign-up to first purchase at OFMP – spread the word <span role="img" aria-label="Raising Hands Emoji">🙌</span></Heading4>
      <DownloadLink href="/resources/OFMP_ProcessGuidelines.pdf" target="_blank">Download the process guidelines PDF</DownloadLink>
    </Card>
  );
};

export default ProcessGuidelines;
