import React, { useEffect, useState, useCallback } from 'react';
import { useAuth } from 'lib/Auth';
import { Main } from 'styles/themeColors';
import { currencySign, formatDateTime } from 'utils/formatters';
import { getAllCampaigns } from 'requests/campaigns';
import styled from 'styled-components';
import Button from 'components/UI/Button';
import { getItems } from 'lib/SessionStorage';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import { CampaignTableColumns } from './CampaignTableColumns';

const ExportXLSButton = styled(Button)`
  margin-left: auto;
`;

const CampaignExport = (props) => {
    const { filters, searchTerm, selectedVendorId } = props;
    const [tablesData, setTablesData] = useState([]);
    const { token } = useAuth();

    const fetchCampaigns = useCallback(async (myQuery) => {
        const result = await getAllCampaigns?.(myQuery, token);
        if (result?.data && result.data.length > 0) {
            const campaigns = result.data.map((campaign) => {
                return {
                    title: campaign.title,
                    countryId: campaign.country?.countryName,
                    campaignTypeId: campaign.campaignType?.name,
                    startDate: formatDateTime(campaign.startDate, false, false),
                    endDate: formatDateTime(campaign.endDate, false, false),
                    campaignLaunchCompanyName: campaign.launchCompany?.companyName,
                    launchPerson: campaign.launchPerson,
                    budget: currencySign(campaign.country?.countryIso, campaign.budget),
                    actualSpend: currencySign(campaign.country?.countryIso, campaign.actualSpend),
                    eventId: campaign.eventId,
                    vendorId: campaign.vendor?.name
                };
            });

            setTablesData(campaigns);
        }
    }, [token]);

    useEffect(() => {
        const countries = getItems('countries');
        let params = `pageSize=1000&page=1`;

        if (filters) {
            if (filters.campaignTypes && filters.campaignTypes.length > 0) {
                params += `&campaignTypeIds=${filters.campaignTypes.map((ct) => ct.id).join(',')}`;
            }
            if (filters.launchCompanies && filters.launchCompanies.length > 0) {
                params += `&launchCompanyIds=${filters.launchCompanies.map((lc) => lc.id).join(',')}`;
            }
            if (filters.vendor && filters.vendor.length > 0 && !selectedVendorId) {
                params += `&vendorIds=${filters.vendor.map((v) => v.id).join(',')}`;
            }
            if (filters.country.id) {
                const countryId = countries.find((c) => c.countryIso === filters.country.id)?.id;
                params += `&countryId=${countryId}`;
            }
            if (filters.startDate) {
                params += `&startDate=${new Date(filters.startDate).toISOString()}`;
            }
            if (filters.endDate) {
                params += `&endDate=${new Date(filters.endDate).toISOString()}`;
            }
        }

        if (selectedVendorId) {
            params += `&vendorIds=${selectedVendorId}`;
        }

        if (searchTerm && searchTerm.trim() !== '') {
            params += `&search=${searchTerm}`;
        }

        fetchCampaigns(params);

    }, [filters, searchTerm, fetchCampaigns, selectedVendorId]);

    const generateExcel = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('campaigns');

            // Add headers
            const headers = CampaignTableColumns
                .filter(c => c.field !== 'actions')
                .map(col => ({ header: col.title, key: col.field, width: 20 }));

            worksheet.columns = headers;

            // Style headers
            const headerRow = worksheet.getRow(1);
            headerRow.font = { bold: true };
            headerRow.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFE0E0E0' }
            };

            // Add data
            tablesData.forEach(row => {
                const rowData = {};
                CampaignTableColumns
                    .filter(c => c.field !== 'actions')
                    .forEach(col => {
                        rowData[col.field] = row[col.field] || '';
                    });
                worksheet.addRow(rowData);
            });

            // Generate and save file
            const buffer = await workbook.xlsx.writeBuffer();
            const blob = new Blob([buffer], { 
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' 
            });
            saveAs(blob, 'campaigns.xlsx');
        } catch (error) {
            console.error('Error generating Excel:', error);
        }
    };

    return (
        <ExportXLSButton 
            isGhost 
            color={Main.black} 
            disabled={!tablesData.length} 
            borderColor={Main.disabled}
            onClick={generateExcel}
        >
            Export XLSX
        </ExportXLSButton>
    );
};

export default React.memo(CampaignExport);