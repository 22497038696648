import React, { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { CheckCircle, WarningRounded, Cancel } from '@material-ui/icons';
import { Main } from 'styles/themeColors';
import { Height } from 'styles/themeHeights';
import { Space } from 'styles/themeSpaces';

export type NotificationType = 'warning' | 'error' | 'success' | 'info' | 'dark' | 'default';
interface ToastOptions {
  toastId?: string;
  className?: string;
  onOpen?: <T = {}>(props: T) => void;
  onClose?: <T = {}>(props: T) => void;
  style?: React.CSSProperties;
  progress?: number | string;
  delay?: number;
  autoClose?: false | number;
  type?: NotificationType;
  render?: ReactNode;
  showErrorLogButton?: boolean;
  errorHref?: string;
};

const renderMessage = (type: string, message: string | JSX.Element, showErrorLogButton?: boolean, errorHref?: string) => {
  const styles = {
    width: '40px',
    height: Height.l,
    marginRight: `${Space.spBasePlus}`
  };

  const errorLogButtonDivStyle = {
    marginTop: '20px',
  };

  const icon = renderIcon(type, styles);

  return (
    <div style={{ display: 'flex',justifyContent:'start',alignItems:'center' }}>
      {icon}
      <div>
        <div>
          {message}
        </div>
        {showErrorLogButton &&
          <div style={errorLogButtonDivStyle}>
            <a href={errorHref}>
              See error log
            </a>
          </div>
        }
      </div>
    </div>
  );
};

const renderIcon = (type: string, styles: any) => {
  switch (type) {
    case 'success':
      return <CheckCircle style={{ ...styles, color: Main.success }} />;
    case 'warning':
      return <WarningRounded style={{ ...styles, color: Main.warning }} />;
    case 'error':
      return <Cancel style={{ ...styles, color: Main.error }} />;
    default:
      return null;
  }
};

export const updateToast = (toastId: string, type: NotificationType, message: string, options: ToastOptions) => {
  const toastMsg = renderMessage(type, message);

  return toast.update(toastId, { ...options, type, render: toastMsg });
};

export const renderToast = (type: NotificationType, message: string | JSX.Element, options?: ToastOptions) => {
  const toastMsg = renderMessage(type, message, options?.showErrorLogButton, options?.errorHref);

  switch (type) {
    case 'warning':
      return toast.warning(toastMsg, options);
    case 'error':
      return toast.error(toastMsg, options);
    case 'success':
      return toast.success(toastMsg, options);
    case 'info':
      return toast.info(toastMsg, options);
    case 'dark':
      return toast.dark(toastMsg, options);
    default:
      return toast(toastMsg, options)
  }
};
