import React from 'react';
import styled, { css } from 'styled-components';
import { Size } from 'lib/styles';
import { navigate } from 'hookrouter';
import { Main } from 'styles/themeColors';

type Theme = 'danger';

const Span = styled.span<Props>`
  color: ${Main.accent};
  cursor: pointer;
  font-weight: 500;
  display: inline-block;

  &:not(:first-child) {
    margin-left: ${Size.medium};
  }

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    opacity: 0.6;
  `}

  ${({ theme }) => theme === 'danger' && css`
    color: ${Main.error};
  `}
`;

interface Props {
  atBasePath?: boolean;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
  innerRef?: React.RefObject<HTMLSpanElement>;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  theme?: Theme;
  to?: string;
};

export default function Link({
  atBasePath,
  children,
  className,
  disabled,
  innerRef,
  onClick,
  theme,
  to
}: Props) {
  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (to) navigate(to);
    if (typeof onClick === 'function') onClick(event);
  };

  return (
    <Span
      className={className}
      disabled={disabled}
      ref={innerRef}
      onClick={handleClick}
      theme={theme}
    >
      {children}
    </Span>
  );
};

Link.defaultProps = {
  atBasePath: false,
  disabled: false
};
