import React, { useState } from 'react';
import { AsyncState } from 'react-async';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Customer from 'data/Customer';
import DowJonesResult from 'data/DowJonesResult';
import Address from 'data/Address';
import ActionCard from 'common/Customers/ActionCard';
import DowJonesCard from 'common/Customers/DowJonesCard';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import Modal from 'components/UI/Modal';
import { renderToast } from 'components/UI/ToastNotification';
import { saveOrder } from 'requests/order';
import { useAPI } from 'lib/API';
import { formatDeliveryAddress } from 'utils/formatters';
import { PROCESSING, CANCELED } from 'constants/orderStates';
import {
  DOW_JONES_CLEAR,
  DOW_JONES_BLOCKED,
  DOW_JONES_REVIEWED_CLEAR,
  DOW_JONES_REVIEWED_BLOCKED
} from 'constants/dowJonesStatuses';
import { Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { MainColors } from 'types/enumTypes';
import { READY, RENT_UPDATED } from 'constants/consignmentStates';
import { saveConsignment } from 'requests/consignment';
import { CONSIGNMENT_MODIFICATION_STATUS } from 'constants/consignmentEntry';

const StyledText = styled.span`
  color: ${Text.secondary} !important;
`;

const Span = styled.span`
  margin: ${Space.spBase} 0px;
`;

interface Props {
  dowJonesResults: DowJonesResult[];
  customerName: string;
  companyName: string;
  customerDetailsReq: AsyncState<Customer>;
  setActiveTab: (value: number) => void;
};

const ComplianceCheck = ({
  dowJonesResults,
  customerName,
  companyName,
  customerDetailsReq,
  setActiveTab
}: Props) => {
  const [comment, setComment] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTheme, setModalTheme] = useState<MainColors>('success');

  const updateOrderStateReq = useAPI({
    deferFn: saveOrder,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Orders updated successfully');
      customerDetailsReq.reload();
      setActiveTab(1);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const updateConsignmentStateReq = useAPI({
    deferFn: saveConsignment,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Orders updated successfully');
      customerDetailsReq.reload();
      setActiveTab(1);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  if (updateOrderStateReq.isLoading) {
    return <LoadingIndicator />;
  } else if(updateConsignmentStateReq.isLoading) {
    return <LoadingIndicator />;
  }

  if (dowJonesResults.length === 0) {
    return (
      <FlexboxContainer
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100vh"
      >
        <StyledText className="base">
          No compliance checks have been performed  for this customer yet
        </StyledText>
      </FlexboxContainer>
    );
  }

  const title = modalTheme === 'success'
    ? 'Clear customer'
    : 'Block the customer';

  const dowJonesFailedResult = dowJonesResults.find(dowJones => dowJones.status === DOW_JONES_BLOCKED && dowJones.consignmentId === null);
  const secondDowJones = dowJonesResults.find(dowJones => dowJones.status === DOW_JONES_BLOCKED && dowJones.consignment?.state === 'DOW_JONES_FAILED');
  const ordersOnHoldNumber = dowJonesResults.filter(dowJones => dowJones.status === DOW_JONES_BLOCKED).length;
  const consignmentOnHoldNumber = dowJonesResults.filter(dowJones => dowJones.consignment?.state === 'DOW_JONES_FAILED' && dowJones.status === DOW_JONES_BLOCKED).length;

  const modalBody = modalTheme === 'success'
    ? (
      <span>{secondDowJones ? consignmentOnHoldNumber : ordersOnHoldNumber} order(s) will be released and sent to vendors</span>
    )
    : (
      <>
        <Span>{secondDowJones ? consignmentOnHoldNumber : ordersOnHoldNumber} order(s) will be cancelled</Span>
        <Span>Customer will be seen as “Blocked manually” till their next order</Span>
        <Span>The “Order cancellation” email(s) will not be sent to the customer. You have to trigger that email manually from the “Order” page</Span>
      </>
    );

  const billingAddress = dowJonesFailedResult && dowJonesFailedResult.order.billingAddress
    ? formatDeliveryAddress(
      dowJonesFailedResult.order.billingAddress.addressLine1,
      dowJonesFailedResult.order.billingAddress.addressLine2,
      dowJonesFailedResult.order.billingAddress.town,
      dowJonesFailedResult.order.billingAddress.state,
      dowJonesFailedResult.order.billingAddress.postalCode
    )
    : secondDowJones && secondDowJones.order.billingAddress
    ? formatDeliveryAddress(
      secondDowJones.order.billingAddress.addressLine1,
      secondDowJones.order.billingAddress.addressLine2,
      secondDowJones.order.billingAddress.town,
      secondDowJones.order.billingAddress.state,
      secondDowJones.order.billingAddress.postalCode
    ): '';

  const actionCard = dowJonesFailedResult
    ? (
      <ActionCard
        checkFailedDate={dowJonesFailedResult.dowJonesTimeStamp}
        ordersOnHoldNumber={ordersOnHoldNumber}
        customerName={customerName}
        billingAddress={billingAddress}
        companyName={companyName}
        comment={comment}
        setComment={setComment}
        setIsModalOpen={setIsModalOpen}
        setModalTheme={setModalTheme}
      />
    ) : secondDowJones
    ? (
      <ActionCard
        checkFailedDate={secondDowJones.dowJonesTimeStamp}
        ordersOnHoldNumber={consignmentOnHoldNumber}
        customerName={customerName}
        billingAddress={billingAddress}
        companyName={companyName}
        comment={comment}
        setComment={setComment}
        setIsModalOpen={setIsModalOpen}
        setModalTheme={setModalTheme}
      />
    ): null;

  const dowJonesHistory = dowJonesResults
    .filter((result, index) => !(index === 0 && result.status === DOW_JONES_BLOCKED));

  const dowJonesResultCards = dowJonesHistory
    .map(result => {
      const theme = [DOW_JONES_CLEAR, DOW_JONES_REVIEWED_CLEAR].includes(result.status)
        ? 'success'
        : result.status === DOW_JONES_BLOCKED
          ? 'warning'
          : 'error';

      const updatedBy = [DOW_JONES_REVIEWED_BLOCKED, DOW_JONES_REVIEWED_CLEAR].includes(result.status)
        ? `by ${result.updatedBy}`
        : 'Automated';

      const resultBillingAddress = result && result.order.billingAddress
        ? formatDeliveryAddress(
          result.order.billingAddress.addressLine1,
          result.order.billingAddress.addressLine2,
          result.order.billingAddress.town,
          result.order.billingAddress.state,
          result.order.billingAddress.postalCode
        )
        : '';

      return (
        <DowJonesCard
          key={result.id}
          theme={theme}
          dowJonesStatus={result.status}
          updatedBy={updatedBy}
          updatedOn={result.updatedDate}
          comment={result.comments}
          customerName={customerName}
          billingAddress={resultBillingAddress}
          companyName={companyName}
        />
      )
    });


    const updateConsignmentState = (consignmentIds:any, consignmentState:any, previousState:any, dowJonesIds:any, dowJoneStatus:any, comments:any) => {
      if (consignmentIds.length > 0) {
        updateConsignmentStateReq.run(
          {
            consignmentIds,
            state:consignmentState,
            previousState
          },
          {
            dowJonesIds,
            status:dowJoneStatus,
            comments
          }
        );
      }
    };


  const onModalClick = () => {
    const dowJonesResultsToUpdate = dowJonesResults.filter(dowJones => dowJones.status === DOW_JONES_BLOCKED && dowJones.consignmentId === null);
    const secondDowJonesResultsToUpdate = dowJonesResults.filter(dowJones => dowJones.status === DOW_JONES_BLOCKED && dowJones.consignment?.state === 'DOW_JONES_FAILED');

    if(dowJonesResultsToUpdate.length > 0) {
      updateOrderStateReq.run(
        {
          orderIds: dowJonesResultsToUpdate.map(dowJones => dowJones.order.id),
          state: modalTheme === 'success' ? PROCESSING : CANCELED,
          previousState: dowJonesResults[0].order.state
        },
        {
          dowJonesIds: dowJonesResultsToUpdate.map(dowJones => dowJones.id),
          status: modalTheme === 'success' ? DOW_JONES_REVIEWED_CLEAR : DOW_JONES_REVIEWED_BLOCKED,
          comments: comment
        }
      );
     } else if(secondDowJonesResultsToUpdate.length > 0) {
      
      const rentModifiedConsignmentIds:any[] = [];
      const nonRentModifiedConsignmentIds:any[] = [];
      const dowJonesIds = secondDowJonesResultsToUpdate.map(dowJones => dowJones.id);
      
      secondDowJonesResultsToUpdate.forEach(secondDowJones => {
        const consignment = secondDowJones.consignment;
        if (consignment) {
          const hasRequestedModification = consignment.modificationEntries?.some(x => x.status === CONSIGNMENT_MODIFICATION_STATUS.REQUESTED);
          if (hasRequestedModification) {
            rentModifiedConsignmentIds.push(consignment.consignmentId);
          } else {
            nonRentModifiedConsignmentIds.push(consignment.consignmentId);
          }
        }
      });
      
      const previousState = secondDowJonesResultsToUpdate[0]?.consignment?.state;
      const dowJoneStatus = modalTheme === 'success' ? DOW_JONES_REVIEWED_CLEAR : DOW_JONES_REVIEWED_BLOCKED;

      updateConsignmentState(
        rentModifiedConsignmentIds,
        modalTheme === 'success' ? RENT_UPDATED : CANCELED,
        previousState,
        dowJonesIds,
        dowJoneStatus,
        comment
      );
      
      updateConsignmentState(
        nonRentModifiedConsignmentIds,
        modalTheme === 'success' ? READY : CANCELED,
        previousState,
        dowJonesIds,
        dowJoneStatus,
        comment
      );
     }

    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        showButtons
        showModal={isModalOpen}
        theme={modalTheme}
        title={title}
        onClick={onModalClick}
        onClose={() => setIsModalOpen(false)}
      >
        <FlexboxContainer flexDirection="column">
          {modalBody}
        </FlexboxContainer>
      </Modal>
      {actionCard}
      <FlexboxContainer
        flexDirection="column"
        width="512px"
        margin={`${Space.sp2x} auto`}
      >
        {dowJonesHistory.length > 0 ? <StyledText className="base">Check history</StyledText> : null}
        {dowJonesResultCards}
      </FlexboxContainer>
    </>
  );
};

export default ComplianceCheck;
