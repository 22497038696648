import React, {  } from 'react';
import styled from 'styled-components';
import { Color, Size } from 'lib/styles';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { TEXT_LABELS } from 'constants/order';
import { Border } from 'styles/themeColors';

const AttachmentsLabel = styled.h5`
    margin-bottom: 8px;
`;

const DocumentsContainer = styled.div<{bgColor: string}>`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
  padding: ${Size.medium};
  background: ${(props) => (props.bgColor)};
  border-radius: 6px;
  margin: 12px 0px;
  border: 1px solid ${Border.light};
`;

interface Props {
  className?: string;
  children: JSX.Element;
  bgColor?: string;
};

const TicketDocuments = ({
  className,
  children,
  bgColor = 'white'
}: Props) => {
  return (
    <>
      <AttachmentsLabel>{TEXT_LABELS.DOCUMENTS}</AttachmentsLabel>
      <FlexboxContainer flexDirection='column' width='100%'>
        <DocumentsContainer bgColor={bgColor}>
          {children}
        </DocumentsContainer>
      </FlexboxContainer>
    </>
  );
};

export default TicketDocuments;