import React, { useState } from 'react';
import styled from 'styled-components';
import Vendor from 'data/Vendor';
import InviteModal from 'admin/InviteModal';
import Button from 'components/UI/Button';
import Card from 'view/Card';
import Slab from 'view/Slab';
import SortingArrow from 'view/SortingArrow';
import PageHeading from 'components/UI/PageHeading';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { Color, Size, Percent, FontSize } from 'lib/styles';
import { useAPI } from 'lib/API';
import { loadUsers } from 'requests/user';
import { Background } from 'styles/themeColors';

const Header = styled.header`
  border-bottom: 1px solid ${Color.darkGray};
  display: flex;
  line-height: 1;
  margin: 0px auto;
  padding: ${Size.medium} 0px;
  width: ${Percent.full};
`;

const Actions = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const List = styled.ul`
  margin: 0px auto;
  width: ${Percent.full};
  padding: 0px;
  background-color: ${Background.white};
`;

const NameSlab = styled(Slab)`
  flex-grow: 1;
  font-size: ${FontSize.tiny};
  line-height: 1.63;
  color: ${Color.black};
  display: flex;
  align-items: center;
  width: 20%;
  word-break: break-word;
  padding: 10px;
`;

const HeaderSlab = styled(NameSlab)`
  font-size: ${FontSize.small};
  width: 20%;
`;

const AddUserButton = styled(Button)`
  align-items: center;
  display: inline-flex;
`;

const IconContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 5px;
  vertical-align: top;
`;

const Page = styled.div`
  margin: ${Size.large} 0px;
  width: ${Percent.full};
  padding: 0 30px;
`;

const EmptyDiv = styled.div`
  background-color: ${Background.white};
  height: 64px;
  margin-right: ${Size.medium};
  object-fit: contain;
  object-position: 50%;
  padding: ${Size.tiny};
  width: 64px;
`;

const UserImage = styled.div<any>`
  background-color: ${Color.gray};
  height: 64px;
  margin-right: ${Size.medium};
  object-fit: contain;
  object-position: 50%;
  padding: ${Size.tiny};
  width: 64px;
  cursor: pointer;
`;

const ImageHolder = styled.div`
  display: inline-flex;
  flex-direction: row;
`;

export interface Props {
  vendorsList: Vendor[];
};

export default function UsersList({ vendorsList }: Props) {
  const [inviting, setInviting] = useState(false);
  const usersReq = useAPI({ promiseFn: loadUsers });

  let usersList = null;
  if (usersReq.isLoading) {
    usersList = <LoadingIndicator />;
  } else if (usersReq.data) {
    const usersMap = usersReq.data.map(userCard => {
      let lastLogin = null;
      if (userCard.lastLogin) {
        lastLogin = new Date(
          userCard.lastLogin
        ).toLocaleDateString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        });
      }
      return (
        <Card key={userCard.id}>
          <ImageHolder>
            <UserImage src=""></UserImage>
          </ImageHolder>
          <NameSlab label="">{userCard.name}</NameSlab>
          <NameSlab label="">{userCard.vendor}</NameSlab>
          <NameSlab label="">{userCard.email}</NameSlab>
          <NameSlab label="">{lastLogin}</NameSlab>
        </Card>
      );
    })

    usersList = (
      <List>
        <Card>
          <EmptyDiv />
          <HeaderSlab label="">Name
            <IconContainer>
              <SortingArrow direction="top" margin="0 0 2px 0" color={Color.darkerGray} />
              <SortingArrow direction="bottom" color={Color.darkerGray} />
            </IconContainer>
          </HeaderSlab>
          <HeaderSlab label="">Vendor
            <IconContainer>
              <SortingArrow direction="top" margin="0 0 2px 0" color={Color.darkerGray} />
              <SortingArrow direction="bottom" color={Color.darkerGray} />
            </IconContainer>
          </HeaderSlab>
          <HeaderSlab label="">Email
            <IconContainer>
              <SortingArrow direction="top" margin="0 0 2px 0" color={Color.darkerGray} />
              <SortingArrow direction="bottom" color={Color.darkerGray} />
            </IconContainer>
          </HeaderSlab>
          <HeaderSlab label="">Last SignIn
            <IconContainer>
              <SortingArrow direction="top" margin="0 0 2px 0" color={Color.darkerGray} />
              <SortingArrow direction="bottom" color={Color.darkerGray} />
            </IconContainer>
          </HeaderSlab>
        </Card>
        {usersMap}
      </List>
    );
  }

  return (
    <Page>
      <InviteModal
        onClose={() => setInviting(false)}
        vendors={vendorsList}
        showModal={inviting}
      />
      <Header>
        <PageHeading>Users</PageHeading>
        <Actions>
          <AddUserButton onClick={() => setInviting(true)}>Invite User</AddUserButton>
        </Actions>
      </Header>
      {usersList}
    </Page>
  );
};
