import React, { useState } from 'react';
import { navigate } from 'hookrouter';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import * as Prod from 'data/Product';
import * as User from 'data/User';
import { validateProduct } from 'data/ProductValidation';
import ConfirmModal from 'components/UI/Modal';
import PDPPreview from 'common/PDPPreview';
import Button from 'components/UI/Button';
import FixedHeader from 'view/FixedHeader';
import Icon from 'view/Icon';
import Link from 'view/Link';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import ValidationStatus from 'view/ValidationStatus';
import VariantsValidationStatus from 'view/VariantsValidationStatus';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, Size } from 'lib/styles';
import { getStash } from 'lib/stash';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import {
  ProductValidationTierRequirements,
  getProductValidationTier
} from 'lib/productValidationTiers';
import { Space } from 'styles/themeSpaces';
import { Background } from 'styles/themeColors';
import FlexboxContainer from 'components/UI/FlexboxContainer';

const EdgeLink = styled(Link)`
  border-right: 1px solid ${Color.gray};
  line-height: 4;
  margin-left: -${Size.medium};
  padding: 0px ${Size.medium};
  display: flex;
  align-items: center;
`;

const Page = styled.div`
  -webkit-overflow-scrolling: touch;
  background-color: ${Background.white};
  color: ${Color.black};
  height: 100%;
  margin-left: 10px;
  padding: 0 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 30px;
`;

const StyledValidationStatus = styled(ValidationStatus)`
  margin: 0 ${Size.medium};
`;

const StyledVariantsValidationStatus = styled(VariantsValidationStatus)`
  margin: 0 ${Size.medium};
`;

const ValidationContainer = styled.div`
  align-items: center;
  display: flex;
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface Props {
  productId: string;
}

export default function ProductReview({ productId }: Props) {
  const { user, token } = useAuth();
  const productReq = useAPI({ promiseFn: Prod.loadLocalDraft, productId });
  const [showPublishModal, setShowPublishModal] = useState(false);

  const requestsPath = getStash('requests-path');

  const prodUpdateReq = useAPI({
    deferFn: Prod.updateProductsStatus,
    onResolve: result => {
      const message = result.status === 'ready'
        ? 'Product ready to be published.'
        : 'Product published successfully.';

      renderToast(toast.TYPE.SUCCESS, message);
      navigate(`/products/${result.status}`);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  if (!productReq.data) {
    return <LoadingIndicator />;
  }

  function getVariantsValidationCount() {
    if (productReq.data) {
      const productValidation = validateProduct(productReq.data, user?.role);

      return productValidation.variantErrors.length;
    }
  }

  function getValidationCount(key: keyof ProductValidationTierRequirements) {
    if (productReq.data !== undefined) {
      const validationTier = getProductValidationTier(productReq.data);
      return {
        count: Prod.getValueCount(productReq.data, key),
        recommended: validationTier.recommendations[key].min,
        required: validationTier.requirements[key].min
      };
    }

    return { count: 0, recommended: 0, required: 0 };
  }

  const publish = () => {
    if (productReq.data && token) {
      const newStatus = productReq.data.status === 'draft' ? 'ready' : 'published';

      if (productReq.data.id) {
        prodUpdateReq.run([productReq.data.id], newStatus);
      }
    }

    setShowPublishModal(false);
  }

  const publishModal = (
    <ConfirmModal
      showModal={showPublishModal}
      onClose={() => setShowPublishModal(false)}
      onClick={publish}
      title="Publish product"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          Are you sure you want to publish?
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )

  let closeUrl;
  if (requestsPath !== undefined) {
    closeUrl = requestsPath as string;
  } else {
    closeUrl =
      productReq.data && productReq.data.status === 'published'
        ? '/products/published'
        : '/products/ready';
  }

  const closeLink = (
    <EdgeLink disabled={productReq.isLoading} key="closeLink" to={closeUrl}>
      <Icon name="x" />
    </EdgeLink>
  );

  let publishButton = null;
  if (productReq.data && productReq.data.status !== 'published' && !User.isVendor(user!)) {
    publishButton = (
      <Button
        disabled={productReq.isLoading}
        key="publishButton"
        onClick={() => setShowPublishModal(true)}
      >
        Publish
      </Button>
    );
  }

  let validation = (
    <ValidationContainer key="validation">
      <StyledValidationStatus
        heading="Name"
        validation={getValidationCount('name')}
        showTooltip={false}
      />
      <StyledValidationStatus
        heading="Description"
        validation={getValidationCount('description')}
        showTooltip={false}
      />
      <StyledValidationStatus
        heading="Images"
        validation={getValidationCount('productImages')}
        showTooltip={false}
      />
      <StyledValidationStatus
        heading="Images"
        validation={getValidationCount('contextualImages')}
        showTooltip={false}
      />
      <StyledValidationStatus
        heading="Videos"
        validation={getValidationCount('videos')}
        showTooltip={false}
      />
      <StyledValidationStatus
        heading="Documents"
        validation={getValidationCount('documents')}
        showTooltip={false}
      />
      <StyledValidationStatus
        heading="Brand"
        validation={getValidationCount('brand')}
        showTooltip={false}
      />
      <StyledVariantsValidationStatus
        heading="Variants"
        variantsValidation={getVariantsValidationCount()}
      />
    </ValidationContainer>
  );

  return (
    <Page>
      {publishModal}
      <FixedHeader left={[closeLink, validation]} right={[publishButton]} />
      <PDPPreview loading={productReq.isLoading} product={productReq.data} />
    </Page>
  );
}
