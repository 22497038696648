
export default interface PointOfService {
  id: number;
  posId: string;
  nickname: string;
  name: string;
  code: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  display?: boolean;
  latitude?: string;
  longitude?: string;
  stateIsoCode?: string;
  regionIsoCode?: string;
  countryIsoCode?: string;
  shortIsoCode?: string;
  countryId?: number,
  address?: any
};

export type PartialPointOfService = Pick<PointOfService, 'name' | 'street'| 'city'| 'state'| 'zip'>;

export const empty: Omit<PointOfService, 'id'> = {
  posId: '',
  nickname: '',
  name: '',
  code: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  countryId: 0,
  address: {}
};
