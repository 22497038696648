import React, { useState, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Main, Border } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { LabelPosition } from 'types/position';

const StyledTextarea = styled.textarea<any>`
  resize: none;
  width: 100%;
  padding: ${Space.spBase} ${Space.spHalf} 0;
  border-color: ${({ $error }) => $error ? Main.error : Border.main} !important;

  &:focus {
    box-shadow: 0 0 0 3px ${({ $error }) => $error ? 'rgba(200, 33, 53, 0.4)' : 'rgba(73, 141, 218, 0.4)'};
  }

  &:hover {
    border-color: ${({ $error }) => $error ? Main.error : Main.accent} !important;
  }
`;

const StyledErrorMessage = styled.span`
  color: ${Main.error} !important;
  margin-top: ${Space.spQuarter};
`;

interface Props {
  id: string;
  className?: string;
  name?: string;
  placeholder: string;
  value: string;
  disabled?: boolean;
  required?: boolean;
  dirty: boolean;
  label?: string;
  labelPosition?: LabelPosition;
  errorMessage: string;
  rows: number;
  maxLength?: number;
  onChange: (value: string) => void;
  isInvalid?: boolean;
  onBlur?: () => void;
};

const Textarea = ({
  id,
  className,
  name,
  placeholder,
  value,
  disabled,
  required,
  dirty,
  label,
  labelPosition,
  errorMessage,
  rows,
  maxLength,
  onChange,
  isInvalid,
  onBlur,
}: Props) => {
  const [isDirty, setIsDirty] = useState<boolean>(dirty);

  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty]);

  const error = (required && (isDirty && !value) || isInvalid) && (
    <StyledErrorMessage className='caption'> 
      {errorMessage} 
    </StyledErrorMessage>
  );

  const labelStyle = labelPosition === 'top'
    ? { marginBottom: `${Space.spBase}` }
    : { marginRight: `${Space.spHalfPlus}` };

  const labelElement = <label htmlFor={id} style={labelStyle} className="base">{label}</label>;

  return (
    <FlexboxContainer
      alignItems="flex-start"
      displayFlex={label && labelPosition === 'top' ? 'flex' : 'inline-flex'}
      flexDirection={label && labelPosition === 'top' ? 'column' : 'row'}
      width="100%"
    >
      {label ? labelElement : null}
      <StyledTextarea
        id={id}
        className={`base border ${className}`}
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        rows={rows}
        maxLength={maxLength}
        onBlur={() => {setIsDirty(true); onBlur && onBlur() }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
        $error={(required && (isDirty && !value) || isInvalid)}
      />
      {error}
    </FlexboxContainer>
  );
};

Textarea.defaultProps = {
  className: '',
  placeholder: 'Leave a comment',
  errorMessage: 'This field is required',
  disabled: false,
  isInvalid: false,
  required: false,
  dirty: false,
  labelPosition: 'top',
  rows: 5
};

export default Textarea;
