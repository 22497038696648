import React from 'react';
import styled, { css } from 'styled-components';
import { Color, Size } from 'lib/styles';
import { Background, Main } from 'styles/themeColors';

const Arrow = styled.span`
  background-color: transparent;
  font-size: 2.4rem;
  font-weight: 700;
  text-align: right;
  margin-left: ${Size.medium};
`;

const Container = styled.div`
  border-bottom: 1px solid ${Color.gray};
  display: flex;
  flex: 1;
  margin-top: ${Size.medium};
  padding: ${Size.medium};

  ${({ onClick }) => onClick && css`
    background-color: ${Background.white};
    border: 1px solid ${Main.accent};
    border-radius: ${Size.small};
    color: ${Main.accent};
    cursor: pointer;
  `}
`;

Container.displayName = 'CardContainer';

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-grow: 1;
  cursor: inherit;
`;

interface Props {
  children: React.ReactNode;
  onClick?: () => void;
};

export default function ProductSummary({ children, onClick }: Props) {
  let arrow = null;
  if (onClick) {
    arrow = <Arrow>&rsaquo;</Arrow>;
  }

  return (
    <Container onClick={onClick}>
      <Content>{children}</Content>
      {arrow}
    </Container>
  );
};
