import React, { useState, useEffect, useRef } from 'react';
import { Close } from '@material-ui/icons';
import { useAsync } from 'react-async';
import { usePath } from 'hookrouter';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Product, * as Prod from 'data/Product';
import SuperCategory from 'data/SuperCategory';
import Vendor from 'data/Vendor';
import Brand from 'data/Brand';
import Category from 'data/Category';
import Warehouse from 'data/Warehouse';
import * as Med from 'data/Media';
import * as User from 'data/User';
import Keyword from 'data/Keyword';
import VideoThumbnail from 'common/VideoThumbnail';
import AddVideoModal from 'view/AddVideoModal';
import AssetCardList from 'view/AssetCardList';
import VideoAssetCardList from 'view/VideoAssetCardList';
import AutosuggestRenderInput from 'view/Autosuggest';
import Button from 'components/UI/Button';
import Checkbox from 'components/UI/Checkbox';
import FileUploader from 'view/FileUploader';
import Icon from 'view/Icon';
import Input from 'components/UI/Input';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import EditorNameInput from 'view/EditorNameInput';
import EditorSection from 'view/EditorSection';
import RichTextEditor from 'view/RichTextEditor';
import Select from 'components/UI/Select';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Breadcrumbs from 'components/UI/Breadcrumb';
import RenderTag from 'components/UI/RenderTag';
import { Asset } from 'view/AssetCard';
import TagsInputComponent from 'view/TagsInputComponent';
import { renderToast } from 'components/UI/ToastNotification';
import * as Arr from 'lib/Array';
import * as Obj from 'lib/Object';
import { Color, Size, BorderRadius, Percent, FontSize, FontWeight } from 'lib/styles';
import { getPolicyKey } from 'lib/brightcove';
import { getTooltipTextForTier } from 'lib/tooltipText';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import {
  getProductValidationTier,
  ProductValidationTierRequirements
} from 'lib/productValidationTiers';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { loadBrands } from 'requests/brand';
import { postMedia } from 'requests/media';
import { createVideo } from 'requests/video';
import { loadWarehouses } from 'requests/warehouse';
import { loadKeywords } from 'requests/keyword';
import { loadSuperCategories } from 'requests/superCategory';
import { Background, Main, Text } from 'styles/themeColors';
import FeatureFlag from 'data/FeatureFlag';
import ConfirmModal from 'components/UI/Modal';
import { Space } from 'styles/themeSpaces';
import { PRODUCT_CONSTANTS, WAREHOUSE_DELIVERY_OPTIONS } from 'constants/productColumns';
import Currency from 'data/Currency';

const Container = styled.div`
  margin: 0px auto 0 auto;
  width: ${Percent.full};
  padding: ${Size.medium};
`;

const DocumentIcon = styled(Icon).attrs({ name: 'PDFFile', size: 26 })`
  color: ${Color.darkerGray};
`;

const ImageIcon = styled(Icon).attrs({ name: 'Image', size: 30 })`
  color: ${Color.darkerGray};
`;

const InputButton = styled(Button) <any>`
  padding: 0.25rem 0.5rem;
`;

const LabelText = styled.label`
  color: ${Color.darkGray};
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
`;

const LabelTextOption = styled.label<any>`
  color: ${({ $error }) => $error ? Main.error : Text.primary} !important;
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
`;

const InputSet = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InputContainer = styled.div <any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Color.veryLightGray};
  border-radius: ${BorderRadius.small};
  width: 90%;
  padding-right: 5px;
  margin-top: 10px;
`;

const ProductEditorInput = styled.div`
  flex: 1;
  margin-right: ${Size.large};

  &:last-child {
    margin-right: 0;
  }

  > * {
    width: ${Percent.full};
  }
`;

const Label = styled.label`
  color: ${Color.dimGray};
  font-size: ${FontSize.small};
`;

const StyledInput = styled(Input)`
  input {
    background-color: ${Background.white};
    border-radius: ${BorderRadius.medium};
    height: 40px;
  }

  span {
    color: ${Color.darkGray};
    font-size: 15px;
  }
`;

const VideoIcon = styled(Icon).attrs({ name: 'Video', size: 30 })`
  color: ${Color.darkerGray};
`;

const UniqueIdentifier = styled.span`
  font-size: ${FontSize.small};
  font-weight: ${FontWeight.bold};
  letter-spacing: 1px;
  color: ${Color.black};
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface Props {
  loading: boolean;
  product: Product;
  update: (product: Product) => void;
  selectedVendor: Vendor;
  disabled?: boolean;
  featureFlags: FeatureFlag;
  validation?: boolean;
  setSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  currencies: Currency[];
};

export default function ProductEditor({
  loading,
  product,
  update,
  selectedVendor,
  disabled,
  setSaveDisabled,
  featureFlags,
  validation,
  currencies
}: Props) {
  const { user, token } = useAuth();
  const path = usePath();
  const [isAddingVideo, setIsAddingVideo] = useState(false);
  const [keywordsList, setKeywordsList] = useState<Keyword[]>([]);
  const [isUploadingMedia, setIsUploadingMedia] = useState(false);
  const [uploadingMediaType, setUploadingMediaType] = useState('');
  const [isUploadingMediaContextual, setIsUploadingMediaContextual] = useState(false);
  const isVendor = User.isVendor(user!);
  const warehousesReq = useAPI({ deferFn: loadWarehouses });
  const superCategoriesReq = useAPI({ deferFn: loadSuperCategories });
  const brandsReq = useAPI({ deferFn: loadBrands });
  const [keywordsTags, setKeywordsTags] = useState<string[]>([]);
  const crumbs = useBreadcrumbs(path);
  const [delivery, setDelivery] = useState<boolean>(false);
  const [pickup, setPickup] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [filterWarehouses, setFilterWarehouses] = useState<any>([]);
  const [error, setError] = useState(false);
  const ref = useRef<null | HTMLElement>(null);

  useEffect(() => {
    setKeywordsTags(product.keywords)
  }, [product.keywords])

  // on submit scroll to warehouse validations
  useEffect(() => {
    if(validation){
      ref.current?.scrollIntoView({behavior: 'smooth', block: "nearest", inline: "nearest"});
    }
  }, [validation])

  useEffect(() => {
    const Warehouses = product.warehouses;
    setShippingModes(Warehouses);
  }, [])

  const keywordsReq = useAPI({
    promiseFn: loadKeywords,
    onResolve: (result) => {
      setKeywordsList(result);
    }
  });

  const createVideoReq = useAPI({
    deferFn: createVideo,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Video created.');
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const brightcovePolicyKeyReq = useAsync({
    promiseFn: getPolicyKey
  });

  useEffect(() => {
    setError(false);
    const mediaNames = product.documents
      .concat(product.productImages.concat(product.contextualImages))
      .map(media => media.displayName);

    if (
      loading ||
      !(product && product.name.trim()) ||
      mediaNames.some((name) => name.trim() === '')
    ) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [loading, product]);

  useEffect(() => {
    warehousesReq.run(selectedVendor ? selectedVendor.id : null);
    brandsReq.run(selectedVendor ? selectedVendor.id : null);
  }, [selectedVendor]);

  useEffect(() => {
    if (
      selectedVendor &&
      (!product.vendor || product.vendor!.id !== selectedVendor.id)
    ) {
      const { id, name, code } = selectedVendor;
      updateProduct('vendor', { id, name, code });
    }
  }, [selectedVendor]);

  useEffect(() => {
    superCategoriesReq.run([token]);
  }, []);

  useEffect(() => {
    if (createVideoReq.data) {
      updateProduct(
        'videos',
        Arr.add(product.videos, {
          id: createVideoReq.data.id,
          videoId: createVideoReq.data.videoId,
          name: createVideoReq.data.name,
        })
      );
    }
  }, [createVideoReq.data]);

  async function addVideo(videoId: string) {
    if (selectedVendor) {
      createVideoReq.run({ videoId, name: product.name }, selectedVendor.id);
    }

    setIsAddingVideo(false);
  }

  async function attachMedia(
    file: File,
    type: Med.MediaType,
    isContextual: boolean
  ) {
    let media;

    if (token && selectedVendor) {
      setUploadingMediaType(type);
      setIsUploadingMediaContextual(isContextual);
      setIsUploadingMedia(true);
      
      const filename = product.name;
      media = await postMedia({
        file,
        filename,
        token,
        type,
        vendorId: selectedVendor.id,
      });
      setUploadingMediaType('');
      setIsUploadingMediaContextual(false);
      setIsUploadingMedia(false);
    } else {
      renderToast(toast.TYPE.INFO, 'Please select a vendor before you try to upload media.');
      return;
    }

    const productKey = isContextual
      ? 'contextualImages'
      : type === 'product_image'
        ? 'productImages'
        : 'documents';

    updateProduct(productKey, [
      ...product[productKey],
      {
        id: media.id,
        name: media.name,
        displayName: media.name,
        path: media.path,
        type: media.type,
        isContextual
      }
    ]);
  }

  function deleteProductMedia(mediaId: number) {
    if (product.id) {
      Prod.deleteProductMedia(product.id, mediaId, token!);
    }
  }

  function deleteProductVideo(videoId: number) {
    if (product.id) {
      Prod.deleteProductVideo(product.id, videoId, token!);
    }
  }

  function onKeywordsChange(keywords: string[]) {
    setKeywordsTags(keywords);
    updateProduct('keywords', keywords);
  }

  function getValidationCount(key: keyof ProductValidationTierRequirements) {
    return {
      count: Prod.getValueCount(product, key),
      recommended: validationTier.recommendations[key].min,
      required: validationTier.requirements[key].min
    };
  }

  function getValidationForPresence(items: any[]) {
    const count = items.reduce((sum, item) => {
      if (
        typeof item === 'object' &&
        item !== undefined &&
        item !== null &&
        Object.keys(item).length !== 0
      ) {
        return sum + 1;
      }

      if (typeof item !== 'object' && item) {
        return sum + 1;
      }

      return sum;
    }, 0);

    return {
      count,
      required: items.length,
      recommended: items.length
    };
  }

  function 
  richTextEditorOnBlur(value: string) {
    updateProduct('description', value);
  }

  function updateBrand(event: any) {
    const brand = brands.find(brand => {
      return brand.name === event.target.value
    })

    // Update material number for each variant if material api is enabled
    const updatedVariants = product.variants.map(variant => {
      variant.materialNumber = '';
      return variant;
    });

    update({
      ...product,
      brand:brand || null,
      variants: updatedVariants
    });

  }

  function updateCategory(event: any) {
    const category = categories.find(cat => cat.name === event.target.value);
    updateProduct('category', category || null);
  }

  function updateProduct<K extends keyof Product>(key: K, value: Product[K]) {
    const updatedProduct = Obj.set(product, key, value);
    update(updatedProduct);
  }

  function updateHidden(value: boolean) {
    const updatedVariants = product.variants.map(variant => {
      variant.hidden = value;
      return variant;
    });
    update({ ...product, hidden: value, variants: updatedVariants });
  }

  function updatePickup(value: boolean) {
    if (delivery) {
      setError(true);
      renderToast(toast.TYPE.ERROR, PRODUCT_CONSTANTS.PICKUP_VALIDATE_MESSAGE);
      return;
    }
    const updatedVariants = product.variants.map(variant => {
      variant?.productStocks && variant.productStocks.map((productStock)=>{
        productStock.postOrderShippingCost = false;
        productStock.availableForPickup = value;
        return productStock;
      });
      return variant;
    });
    update({ ...product, availableForPickup: value, postOrderShippingCost: false, variants: updatedVariants });
  }

  function updatePostOrderShippingCost(value: boolean) {

    const updatedVariants = product.variants.map(variant => {
      variant?.productStocks && variant.productStocks.map((productStock)=>{
        productStock.postOrderShippingCost = value;
        productStock.availableForPickup = false;
        return productStock;
      });
      return variant;
    });
    update({ ...product, availableForPickup: false, postOrderShippingCost: value, variants: updatedVariants });
  }

  function updateSuperCategory(event: any) {
    const superCategory = superCategories.find(cat => cat.name === event.target.value);
    if (superCategory) {
      const updatedProduct = {
        ...product,
        superCategory,
        category: null
      };

      update(updatedProduct);
    }
  }

  function setShippingModes(updatedWarehouses: any) {
    const pickupCheck = updatedWarehouses.some((wr: any) => wr.pickup === true);
    const deliveryCheck = updatedWarehouses.some((wr: any) => wr.delivery === true);
    if (pickupCheck && deliveryCheck) {
      setPickup(false);
      setDelivery(false);
    } else if (deliveryCheck) {
      setDelivery(deliveryCheck);
      setPickup(false);
    } else if (pickupCheck) {
      setPickup(pickupCheck);
      setDelivery(false);
    } else {
      setPickup(false);
      setDelivery(false);
    }
  }

  function updateWarehouse(event: string) {

    const warehouse = warehouses.find(warehouse => warehouse.name === event);
    const isWarehouseExist = product.warehouses.some(wr => wr.name === event);

    if (!warehouse) return;

    if (isWarehouseExist) {
      removeWarehouse(event);
    } else {
      addWarehouse(warehouse);
    }
  }

  function getUpdateVariantsOnWarehouseAdd(product: Product, updatedWarehouses: any[]) {
      const updatedVariants = product.variants.map(variant => {
      updatedWarehouses.forEach((warehouse: any) => {
        // Create product stock for each warehouse if not already exists
        if (!variant.productStocks?.some(stock => stock.warehouseId === warehouse.id)) {
          variant.productStocks!.push({
            warehouseId: warehouse.id,
            available: true,
            availableForPickup: product.availableForPickup,
            availableForShipping: product.shipping,
            postOrderShippingCost: product.postOrderShippingCost,
            deliveryLeadDays: product.deliveryLeadDays,
          });
        }

        // Create product variant price for each country if not already exists
        const countryId = warehouse.address?.country?.id;
        if (!variant.productVariantPrices?.some(price => price.countryId === countryId)) {
          variant.productVariantPrices!.push({
            countryId: countryId,
            pricePerUnit: 0,
            itemsPerUnit: 1,
            productVariantId: variant.id,
            currencyId: currencies?.find(currency => currency.countryId === countryId)?.id
          })
        }
      })

      return variant;
    });

    return updatedVariants
  }

  function getUpdateVariantsOnWarehouseRemove(product: Product, updatedWarehouses: any[]) {

    const warehouseIds = updatedWarehouses.map(wr => wr.id);
    const uniqueCountryIds = updatedWarehouses.map(wr => wr.address?.country?.id).filter((value, index, self) => self.indexOf(value) === index);

    const updatedVariants = product.variants.map(variant => {
      variant.productStocks = variant.productStocks?.filter(stock => warehouseIds.includes(stock.warehouseId))
      variant.productVariantPrices = variant.productVariantPrices?.filter(price => uniqueCountryIds.includes(price.countryId))
      return variant;
    });

    return updatedVariants
  }

  function removeWarehouse(warehouseName: string) {
    const updatedWarehouses = product.warehouses.filter(wr => wr.name !== warehouseName);
    setFilterWarehouses(updatedWarehouses);
    setShowDeleteModal(true);
  }


  function addWarehouse(warehouse: any) {
    const updatedWarehouses = [...product.warehouses, warehouse];
    setShippingModes(updatedWarehouses);

    const updatedVariants = getUpdateVariantsOnWarehouseAdd(product, updatedWarehouses);


    update({
      ...product, warehouses: [...product.warehouses, {
        id: warehouse.id,
        name: warehouse.name,
        code: warehouse.code,
        pickup: warehouse.pickup,
        delivery: warehouse.delivery,
        address: warehouse.address,
        type: warehouse.type
      }],
      variants: updatedVariants,
      availableForPickup: false,
      postOrderShippingCost: false,
    });

  }

  if (loading || keywordsReq.isLoading) {
    return <LoadingIndicator />;
  }

  const validationTier = getProductValidationTier(product);
  const tooltipValidationText = getTooltipTextForTier(validationTier);

  let superCategories: SuperCategory[] = superCategoriesReq.data
    ? superCategoriesReq.data
    : [];

  let categories: Category[] = [];
  if (product.superCategory) {
    const selectedSuperCategory = superCategories.find(
      cat => cat.code === product.superCategory!.code
    );

    if (selectedSuperCategory) {
      categories = selectedSuperCategory.categories;
    }
  }

  let brands: Brand[] = brandsReq.data ? brandsReq.data : [];

  let warehouses: Warehouse[] = warehousesReq.data ? warehousesReq.data : [];

  let warehouseDeliveryMood = (warehouse: Warehouse) => {
    let status;
    if (warehouse.delivery && warehouse.pickup) {
      return status = WAREHOUSE_DELIVERY_OPTIONS.SHIPPING_AND_PICKUP;
    } else if (warehouse.delivery) {
      return status = WAREHOUSE_DELIVERY_OPTIONS.SHIPPING_ONLY;
    } else if (warehouse.pickup) {
      return status = WAREHOUSE_DELIVERY_OPTIONS.PICKUP_ONLY;
    } else {
      return status = 'none'
    }
  }

  let warehouseName = (warehouse: Warehouse) => {
    return `${warehouse.address?.country?.countryName} - ${warehouse.name} (${warehouseDeliveryMood(warehouse)}) - ${warehouse.address?.addressLine1}, ${warehouse.address?.town}, ${warehouse.address?.regionIsoCode?.split("-")[1] || ''}  ${warehouse.address?.postalCode}`;
  }

  function handleDeletion() {
    setShippingModes(filterWarehouses);
    const updatedVariants = getUpdateVariantsOnWarehouseRemove(product, filterWarehouses);
    update({
      ...product, warehouses: filterWarehouses, variants: updatedVariants,
      availableForPickup: false, postOrderShippingCost: false,
    });

    setShowDeleteModal(false);
  }

  const deleteModal = (
    <ConfirmModal
      showModal={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      onClick={handleDeletion}
      title={PRODUCT_CONSTANTS.HEADER_MODAL_WAREHOUSE}
      ghostButtonText="Cancel"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          {PRODUCT_CONSTANTS.WAREHOUSE_REMOVAL_VALIDATE_MESSAGE}<span>{<br />}{<br />}</span>{PRODUCT_CONSTANTS.UNDONE}
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )

  const onDeliveryLeadDayChange = (value: number) => {
   
    // Update lead days for all product stocks on changing lead days for product
    const updatedVariants = product.variants.map(variant => {
      variant.productStocks?.map(stock => {
        stock.deliveryLeadDays = value;
        return stock;
      });
      return variant;
    });

    update({
      ...product,
      deliveryLeadDays: value,
      variants: updatedVariants
    });

  }

  return (
    <Container>
      <AddVideoModal
        showModal={isAddingVideo}
        onAdd={addVideo}
        onClose={() => {
          setIsAddingVideo(false);
        }}
      />
      {deleteModal}
      <Breadcrumbs crumbs={crumbs} />
      <EditorSection
        heading="Product name"
        infoIconText={tooltipValidationText.name}
        disablePaddingBottom={false}
        validation={getValidationCount('name')}
      >
        <EditorNameInput
          placeholder="Product name..."
          onChange={value => updateProduct('name', value)}
          value={product.name}
          disabled={disabled}
        />
        {product.code ? <UniqueIdentifier>OFMP Unique Identifier: {product.code}</UniqueIdentifier> : null}
      </EditorSection>
      <EditorSection
        heading="Product identification"
        validation={getValidationForPresence([
          product.sku
        ])}
        countType="item"
      >
        <InputSet>
          <FlexboxContainer justifyContent="space-between" alignItems="center" padding="5px 10px" width="90%">
            <StyledInput
              id='vendor-sku-id'
              labelName="Vendor SKU"
              placeholder="Enter vendor SKU"
              infoIconText="Part number listed on OFMP site  associated with this product"
              onInputChange={(value) => updateProduct('sku', value)}
              width="32%"
              value={product.sku || ''}
              disabled={disabled}
            />
            <StyledInput
              id='vendor-erp-id'
              labelName="Vendor ERP"
              placeholder="Enter vendor ERP"
              infoIconText="Part number associated with this product in your internal ERP system"
              onInputChange={(value) => updateProduct('vendorPartNumber', value)}
              width="32%"
              value={product.vendorPartNumber || ''}
              disabled={disabled}
              isOptional
            />
            <StyledInput
              id='vendor-mpn-id'
              labelName="Vendor MPN"
              placeholder="Enter vendor MPN"
              infoIconText="Part number associated with this product in your manufacturing system"
              onInputChange={(value) =>
                updateProduct('manufacturingPartNumber', value)
              }
              width="32%"
              value={product.manufacturingPartNumber || ''}
              disabled={disabled}
              isOptional
            />
          </FlexboxContainer>
        </InputSet>
      </EditorSection>
      <EditorSection
        heading="Product description"
        infoIconText={tooltipValidationText.description}
        disablePaddingBottom={false}
        validation={getValidationCount('description')}
      >
        <RichTextEditor
          placeholder="Product description..."
          onBlur={richTextEditorOnBlur}
          value={product.description}
          disabled={disabled}
        />
      </EditorSection>
      <EditorSection
        heading="Brand / Classification"
        validation={getValidationForPresence([
          product.superCategory,
          product.category,
          product.brand
        ])}
        countType="item"
      >
        <InputSet>
          <FlexboxContainer justifyContent="space-between" alignItems="center" padding="5px 10px" width="90%">
            <Select
              label="Category"
              labelPosition='top'
              options={superCategories.map(cat => ({ value: cat.name }))}
              onChange={updateSuperCategory}
              value={product.superCategory ? product.superCategory.name : ''}
              defaultValue="Select category"
              disabled={disabled}
              width="100%"
            />
            <Select
              disabled={disabled || categories.length === 0}
              label="Subcategory"
              labelPosition='top'
              options={categories.map(cat => ({ value: cat.name }))}
              onChange={updateCategory}
              value={product.category ? product.category.name : ''}
              defaultValue="Select subcategory"
              width="100%"
            />
            <Select
              label="Brand"
              labelPosition='top'
              options={brands.map(brand => ({ value: brand.name }))}
              onChange={updateBrand}
              value={product.brand ? product.brand.name : ''}
              defaultValue="Select brand"
              disabled={disabled || (!isVendor && !selectedVendor.name)}
              width="100%"
            />
          </FlexboxContainer>
        </InputSet>
      </EditorSection>
      <EditorSection
        heading="Keywords"
        infoIconText="Enter the keyword associated with your product"
      >
        <FlexboxContainer flexDirection="column" padding="5px 10px">
          <LabelText htmlFor="keywords">Product keyword(s)</LabelText>
          <InputContainer>
            <TagsInputComponent
              value={keywordsTags}
              onChange={onKeywordsChange}
              inputProps={{
                className: 'react-tagsinput-input',
                placeholder: keywordsTags.length === 0 ? 'Enter product keyword(s)' : '',
                keywords: keywordsList
              }}
              tagProps={{ className: 'react-tagsinput-tag-custom' }}
              renderTag={RenderTag}
              renderInput={AutosuggestRenderInput}
            />
            {keywordsTags.length > 0 ? <Close style={{ color: Color.shuttleGray }} onClick={() => onKeywordsChange([])} /> : null}
          </InputContainer>
        </FlexboxContainer>
      </EditorSection>
      <EditorSection
        heading="Logistics"
        validation={getValidationForPresence([
          product.deliveryLeadDays,
          product.warehouses
        ])}
      >
        <LabelTextOption $error={validation}>{PRODUCT_CONSTANTS.PRODUCT_AVAILABILITY_MESSAGE}</LabelTextOption>
        <InputSet>
          <FlexboxContainer justifyContent="space-between" padding="5px 10px" alignItems="center" width="90%">
            <ProductEditorInput>
              {
                warehouses.map((warehouse) => {
                  return <Checkbox
                    key={warehouse.id}
                    id={warehouse.id.toString()}
                    checked={product.warehouses.some((wr) => wr.id === warehouse.id)}
                    appearance="none"
                    checkboxSize="xs"
                    errorOccurred={(error && (warehouse.delivery && !warehouse.pickup)) || validation}
                    label={warehouseName(warehouse)}
                    onChange={() => {
                      updateWarehouse(warehouse.name);
                    }}
                    disabled={disabled || (!isVendor && !selectedVendor.name)}
                  />
                })
              }
            </ProductEditorInput>
          </FlexboxContainer>
        </InputSet>
        {validation && <LabelTextOption ref={ref} $error={validation}>{PRODUCT_CONSTANTS.WAREHOUSE_SELECTION_WARNING}</LabelTextOption>}
        <InputSet>
          <FlexboxContainer justifyContent="space-between" padding="20px 10px" alignItems="center" width="90%">
            <ProductEditorInput>
              <LabelTextOption>Options</LabelTextOption>
              <Checkbox
                id='product-editor-checkbox-pickup-id'
                appearance="none"
                checkboxSize="xs"
                checked={product.availableForPickup}
                label={PRODUCT_CONSTANTS.PICKUP_CHECKBOX_LABEL}
                errorOccurred={error}
                onChange={val => {
                  updatePickup(val);
                }}
                disabled={disabled}
              />
              {
                featureFlags.postOrderShippingCost && featureFlags.postOrderShippingCost.enabled &&
                (
                  <Checkbox
                    id='product-editor-checkbox-postOrderShippingCost-id'
                    appearance="none"
                    checkboxSize="xs"
                    checked={product.postOrderShippingCost}
                    label={PRODUCT_CONSTANTS.ALLOW_POST_ORDER_SHIPPING}
                    onChange={val => {
                      updatePostOrderShippingCost(val);
                    }}
                    disabled={disabled || pickup || product.availableForPickup}
                  />
                )
              }
              <Checkbox
                id='product-editor-checkbox-hide-product-id'
                appearance="none"
                checkboxSize="xs"
                checked={product.hidden}
                label="Hide product"
                onChange={val => updateHidden(val)}
                disabled={disabled}
              />
            </ProductEditorInput>
          </FlexboxContainer>
        </InputSet>
        <InputSet>
          <FlexboxContainer justifyContent="space-between" padding="20px 10px" alignItems="center" width="40%">
            <ProductEditorInput>
              <StyledInput
                id='delivery-lead-days'
                labelName="Default product lead days"
                width="20%"
                onInputChange={val => onDeliveryLeadDayChange(parseInt(val))}
                placeholder="Shipping..."
                type="number"
                value={
                  product.deliveryLeadDays ? String(product.deliveryLeadDays) : '1'
                }
                disabled={disabled}
              />
              <Label>{PRODUCT_CONSTANTS.DELIVERY_LEAD_DAYS_TEXT}</Label>
            </ProductEditorInput>
          </FlexboxContainer>
        </InputSet>
      </EditorSection>

      <EditorSection
        actions={
          <FileUploader
            acceptTypes={[
              'application/pdf',
              'application/x-pdf',
              'application/x-bzpdf',
              'application/x-gzpdf'
            ]}
            onChange={file => attachMedia(file as File, 'document', false)}
            disabled={disabled}
          />
        }
        countType="document"
        heading="Supporting documents"
        infoIconText={tooltipValidationText.documents}
        validation={getValidationCount('documents')}
      >
        <AssetCardList
          assets={product.documents.map(document => ({
            id: document.id,
            name: document.name,
            displayName: document.displayName,
            path: document.path,
            type: document.type,
            isContextual: false
          }))}
          editableName={true}
          listId="documents"
          onDelete={deleteProductMedia}
          onUpdate={(assets: Asset[]) => updateProduct('documents', assets)}
          placeholderIcon={<DocumentIcon />}
          renderThumbnail={() => <DocumentIcon />}
          disabled={disabled}
          isUploadingMedia={
            isUploadingMedia && uploadingMediaType === 'document'
          }
        />
      </EditorSection>

      <EditorSection
        actions={
          <FileUploader
            acceptTypes={['image/jpeg', 'image/png']}
            onChange={file => attachMedia(file as File, 'product_image', false)}
            disabled={disabled || !product.name}
          />
        }
        countType="image"
        heading="Main product image"
        infoIconText={tooltipValidationText.productImages}
        validation={getValidationCount('productImages')}
      >
        <AssetCardList
          assets={product.productImages.map(image => ({
            id: image.id,
            name: image.name,
            displayName: image.displayName,
            path: image.path,
            type: image.type,
            isContextual: false
          }))}
          editableName
          listId="product-images"
          onDelete={deleteProductMedia}
          onUpdate={(assets: Asset[]) => updateProduct('productImages', assets)}
          placeholderIcon={<ImageIcon />}
          disabled={disabled}
          isUploadingMedia={
            isUploadingMedia &&
            uploadingMediaType === 'product_image' &&
            !isUploadingMediaContextual
          }
        />
      </EditorSection>

      <EditorSection
        actions={
          <FileUploader
            acceptTypes={['image/jpeg', 'image/png']}
            onChange={file => attachMedia(file as File, 'product_image', true)}
            disabled={disabled}
          />
        }
        countType="image"
        heading="Secondary product images"
        infoIconText={tooltipValidationText.contextualImages}
        validation={getValidationCount('contextualImages')}
      >
        <AssetCardList
          assets={product.contextualImages.map(image => ({
            id: image.id,
            name: image.name,
            displayName: image.displayName,
            path: image.path,
            type: image.type,
            isContextual: true
          }))}
          editableName
          listId="contextual-images"
          onDelete={deleteProductMedia}
          onUpdate={(assets: Asset[]) =>
            updateProduct('contextualImages', assets)
          }
          placeholderIcon={<ImageIcon />}
          disabled={disabled}
          isUploadingMedia={
            isUploadingMedia &&
            uploadingMediaType === 'product_image' &&
            isUploadingMediaContextual
          }
        />
      </EditorSection>

      <EditorSection
        heading="Videos"
        infoIconText={tooltipValidationText.videos}
        actions={
          <InputButton
            onClick={() => {
              setIsAddingVideo(true);
            }}
            disabled={disabled}
          >
            Input ID
          </InputButton>
        }
        countType="video"
        validation={getValidationCount('videos')}
      >
        <VideoAssetCardList
          assets={product.videos.map(video => ({
            id: video.id,
            name: video.name,
            videoId: video.videoId
          }))}
          editableName
          listId="videos"
          onUpdate={assets => {
            updateProduct(
              'videos',
              assets.map(asset => ({
                id: 0,
                videoId: asset.videoId,
                name: asset.name
              }))
            );
          }}
          onDelete={deleteProductVideo}
          placeholderIcon={<VideoIcon />}
          renderThumbnail={asset => {
            if (brightcovePolicyKeyReq.isLoading) return null;
            return (
              <VideoThumbnail
                policyKey={brightcovePolicyKeyReq.data}
                videoId={asset.videoId}
              />
            );
          }}
          disabled={disabled}
        />
      </EditorSection>
    </Container>
  );
};
