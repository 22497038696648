import React from 'react';
import spinner from 'images/spinner.png';
import spinnerBlack from 'images/spinnerBlack.png';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Img = styled.img<Props>`
  animation: ${spin} 1.25s linear infinite;
  width: ${(ps) => ps.size}px;
`;

interface Props {
  size: number;
  color?: string;
}

const Spinner = ({ size, color }: Props) => {
  if (color == 'black') {
    return <Img alt="SpinnerBlack" size={size} src={spinnerBlack} />;
  } else return <Img alt="Spinner" size={size} src={spinner} />;
};

Spinner.defaultProps = {
  size: 32,
};

export default Spinner;
