import React from 'react';
import { usePath } from 'hookrouter';
import styled, { css } from 'styled-components';
import User, * as UserMethods from 'data/User';
import FeatureFlag, { FeatureFlagValues } from 'data/FeatureFlag';
import NavLink from 'view/NavLink';
import PageSideNav from 'view/PageSideNav';
import Icon from 'view/Icon';
import { useAuth } from 'lib/Auth';
import { Color } from 'lib/styles';
import {
  ViewList,
  Folder,
  ViewModule,
  SupervisorAccount,
  Domain,
  Grain,
  Dns,
  AccountCircle,
  ExitToApp,
  ShoppingCart,
  BarChart,
  Settings as SettingsIcon
} from '@material-ui/icons';
import { ReactComponent as RequestQuoteIcon } from 'images/request_quote.svg';
import { ReactComponent as ResponseQuoteIconHighlist } from 'images/pricebook-highlight.svg';
import { grantAccess } from 'utils/authorization';
import { Main } from 'styles/themeColors';

const InlineFlexContainer = styled.div`
  align-items: center;
  display: inline-flex;
`;

const StyledSpan = styled.span<any>`
  margin-left: 5px;
  font-weight: bold;
`;

const CompanyIcon = styled(Icon).attrs({ size: 25 }) <any>`
  margin-right: 0 !important;

  path {
    ${({ fillColor }) => fillColor && css`
      fill: ${fillColor};
    `}
  }
`;

const CustomersIcon = styled(Icon).attrs({ size: 32 }) <any>`
  margin-right: 0 !important;

  path {
    ${({ fillColor }) => fillColor && css`
      fill: ${fillColor};
    `}
  }
`;

const HomeIcon = styled(Icon).attrs({ size: 21 }) <any>`
  margin-right: 1px !important;
  margin-left: 2px !important;

  path {
    ${({ fillColor }) => fillColor && css`
      fill: ${fillColor};
    `}
  }
`;

const CampaignIcon = styled(Icon).attrs({ name: 'Campaign',size: 18 }) <any>`
  margin-right: 0 !important;

  path {
    ${({ fillColor }) => fillColor && css`
      fill: ${fillColor};
    `}
  }
`;


const separator = <div style={{ marginTop: '15px' }} />;

interface Props {
  user: User;
  featureFlags: FeatureFlag;
};

export default function SideNavigation({ user, featureFlags }: Props) {
  const { logout } = useAuth();
  const path = usePath();
  const isVendor = UserMethods.isVendor(user);

  const navLinks = (
    <>
      <NavLink to="/" isSideNav={true}>
        <InlineFlexContainer>
          <HomeIcon name="Home" fillColor={path === "/" ? Main.brandLight : Color.darkerGray} /><StyledSpan>Home</StyledSpan>
        </InlineFlexContainer>
      </NavLink>
      {
        grantAccess(featureFlags, FeatureFlagValues.ORDER, user) ? (
          <NavLink to="/orders" isSideNav={true} atBasePath>
            <InlineFlexContainer>
              <ShoppingCart /><StyledSpan>Orders</StyledSpan>
            </InlineFlexContainer>
          </NavLink>
        ) : null
      }

      {separator}

      {
        grantAccess(featureFlags, FeatureFlagValues.COMPANY, user) ? (
          <NavLink isSideNav={true} atBasePath to="/companies">
            <InlineFlexContainer>
              <CompanyIcon name="Company" fillColor={path.includes('/companies') ? Main.brandLight : Color.darkerGray} />
              <StyledSpan>Companies</StyledSpan>
            </InlineFlexContainer>
          </NavLink>
        ) : null
      }
 
      {
        grantAccess(featureFlags, FeatureFlagValues.COMPANY, user) ? (
          <NavLink isSideNav={true} atBasePath to="/customers">
            <InlineFlexContainer style={{ marginLeft: '-5px' }}>
              <CustomersIcon name='CustomersNav' fillColor={path.includes('/customers') ? Main.brandLight : Color.darkerGray} />
              <StyledSpan>Customers</StyledSpan>
            </InlineFlexContainer>
          </NavLink>
        ) : null
      }

      {separator}

      {
        isVendor
          ? null
          : (
            <NavLink isSideNav={true} atBasePath to="/vendors">
              <InlineFlexContainer>
                <Dns /><StyledSpan>Vendors</StyledSpan>
              </InlineFlexContainer>
            </NavLink>
          )
      }

      {
        isVendor
          ? null
          : (
            <NavLink isSideNav={true} atBasePath to="/users">
              <InlineFlexContainer>
                <SupervisorAccount /><StyledSpan>Users</StyledSpan>
              </InlineFlexContainer>
            </NavLink>
          )
      }

      {isVendor ? null : separator}

      <NavLink isSideNav={true} atBasePath to="/products">
        <InlineFlexContainer>
          <ViewList /><StyledSpan>Products</StyledSpan>
        </InlineFlexContainer>
      </NavLink>

      <NavLink isSideNav={true} atBasePath to="/brands">
        <InlineFlexContainer>
          <Folder /><StyledSpan>Brands</StyledSpan>
        </InlineFlexContainer>
      </NavLink>

      {
        isVendor
          ? null
          : (
            <NavLink isSideNav={true} atBasePath to="/categories">
              <InlineFlexContainer>
                <ViewModule /><StyledSpan>Categories</StyledSpan>
              </InlineFlexContainer>
            </NavLink>
          )
      }

      <NavLink isSideNav={true} atBasePath to="/attributes">
        <InlineFlexContainer>
          <Grain /><StyledSpan>Attributes</StyledSpan>
        </InlineFlexContainer>
      </NavLink>

      <NavLink isSideNav={true} atBasePath to="/warehouses">
        <InlineFlexContainer>
          <Domain /><StyledSpan>Warehouses</StyledSpan>
        </InlineFlexContainer>
      </NavLink>

      <NavLink isSideNav={true} atBasePath to="/pricebooks">
        <InlineFlexContainer>
          {
            path.includes('/pricebooks') ? (<ResponseQuoteIconHighlist />) : (<RequestQuoteIcon />)
          }
          <StyledSpan>Pricebooks</StyledSpan>
        </InlineFlexContainer>
      </NavLink>

      {separator}
      
      {
        featureFlags.powerBiReport &&  featureFlags.powerBiReport.enabled
          ? (
            <NavLink isSideNav={true} atBasePath to="/reports">
            <InlineFlexContainer>
              <BarChart /><StyledSpan>Reports</StyledSpan>
            </InlineFlexContainer>
          </NavLink>
          ) : null
      }

      {
        featureFlags.marketingCampaign &&  featureFlags.marketingCampaign.enabled
          ? (
            <NavLink isSideNav={true} atBasePath to="/marketing">
            <InlineFlexContainer>
              <CampaignIcon name='Marketing' fillColor={path.includes('/marketing') ? Main.brandLight : Color.darkerGray} />
              <StyledSpan>Marketing</StyledSpan>
            </InlineFlexContainer>
          </NavLink>
          ) : null
      }

    </>
  );

  const userLinks = (
    <>
      <NavLink isSideNav={true} atBasePath to="/account">
        <InlineFlexContainer>
          <AccountCircle />
          <StyledSpan>Account</StyledSpan>
        </InlineFlexContainer>
      </NavLink>
      {
        isVendor && <NavLink isSideNav={true} atBasePath to="/settings">
        <InlineFlexContainer>
          <SettingsIcon />
          <StyledSpan>Settings</StyledSpan>
        </InlineFlexContainer>
      </NavLink>
      }
      <NavLink isSideNav={true} atBasePath to="#" onClick={logout}>
        <InlineFlexContainer>
          <ExitToApp />
          <StyledSpan>Logout</StyledSpan>
        </InlineFlexContainer>
      </NavLink>
    </>
  );

  return <PageSideNav navLinks={navLinks} userLinks={userLinks} />;
};
