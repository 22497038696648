import React from 'react';
import styled from 'styled-components';
import { Close, HighlightOff } from '@material-ui/icons';


const StyledTag = styled.div<any>`
  display: flex;
  align-items: center;
  padding: 2px;
`;

interface Props {
  tag: string;
  key: string;
  disabled: boolean;
  onRemove: (key: string) => void;
  classNameRemove: string;
  getTagDisplayValue: (value: string) => void;
  closeIconName?: string;
  color?: string;  
};

const RenderTag = ({
  tag,
  key,
  disabled,
  onRemove,
  classNameRemove,
  getTagDisplayValue,
  closeIconName,
  color = 'white',
  ...other
}: Props) => {
  return (
    <StyledTag key={key} {...other}>
      {getTagDisplayValue(tag)}
      {
        !disabled
          ? closeIconName === 'HighlightOff'
            ? (
              <HighlightOff
                className={classNameRemove}
                onClick={() => onRemove(key)}
                style={{ color }}
              />
            )
            : (
              <Close
                className={classNameRemove}
                onClick={() => onRemove(key)}
                style={{ color, fontSize: '18px', marginLeft: '3px' }}
              />
            )
          : null
      }
    </StyledTag>
  );
};

RenderTag.defaultProps = {
  closeIconName: 'Close'
}

export default RenderTag;