// MyContext.js
import React, { createContext, useState } from 'react';
import { CampaignStats } from 'data/Campaign';

// Create Context
export const CampaignContext = createContext<any>({});

// Create a provider component
export const CampaignContextProvider = ({ children }: any) => {
    const [stats, setStats] = useState<CampaignStats>({
        activeCampaigns: 0,
        campaignsBudget: 0,
        launchCompanies: 0,
        totalCampaigns:  0
    });
    const [loading, setStatsLoading] = useState<boolean>(false);

    // Function to update the campaignStats
    const updateStats = (newData: any) => {
        setStats(newData);
    };

    return (
        <CampaignContext.Provider value={{ stats, updateStats, loading, setStatsLoading }}>
            {children}
        </CampaignContext.Provider>
    );
};
