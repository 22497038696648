import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Vendor, { ChangedValues, MergedAttributes } from 'data/Vendor';
import { isVendor } from 'data/User';
import Attribute, { AttributeValue, AttributeValueRequest, empty } from 'data/Attribute';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import DroppableColumn from 'view/DroppableColumn';
import InfoIcon from 'components/UI/InfoIcon';
import PageHeading from 'components/UI/PageHeading';
import { renderToast, updateToast } from 'components/UI/ToastNotification';
import { Color, Size, Percent, FontWeight } from 'lib/styles';
import { move, orderByArray } from 'lib/Array';
import { useAuth } from 'lib/Auth';
import { reoderRequest } from 'requests/atribute';
import { updateAttributeList, mergeAttributes } from 'lib/attributesScreenHelper';
import MergeAttributeModal from 'common/MergeAttributesModal';
import AddAttributeValueModal from 'common/AddAttributeValueModal';
import { getVendorAttributes } from 'requests/vendor';
import { Main, Background } from 'styles/themeColors';
import ImportXLSXAttributeValueModal from 'common/ImportXLSXAttributeValueModal';
import { ATTRIBUTE_CONSTANTS } from 'constants/attributes';

const List = styled.ul`
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  color: ${Color.black};
  width: ${Percent.full};
  height: 1200px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flext-start;
`;

const StyledInfoIcon = styled(InfoIcon)`
  display: flex;
  margin-left: ${Size.huge};
  margin-top: 0.1rem;
`;

const Checkbox = styled.input`
  width: 18px;
  height: 18px;

  &:hover{
    background-color: ${Main.success} !important;
    cursor: pointer;
  }
`;

const ListItem = styled.li<any>`
  margin: 0px;
  padding: 18px 14px;
  display: block;
  border: 1px solid ${Color.veryLightGray};
  cursor: pointer;
  font-size: ${Size.medium};
  font-weight: ${FontWeight.bold};
  background-color: ${Background.white};

  &:hover {
    border-color: ${Main.accent};
  }

  &:active {
    -webkit-box-shadow: inset 0px 0px 10px ${Color.darkGray};
  }

  ${({ active }) => active && `
    background: ${Color.pattensBlue};
    -webkit-box-shadow: inset 0px 0px 5px ${Color.darkGray};
    -moz-box-shadow: inset 0px 0px 5px ${Color.darkGray};
    box-shadow: inset 0px 0px 5px ${Color.darkGray};
    outline: none;
  `}
`;

const ValuesCount = styled.span`
  display: flex;
  color: ${Main.accent};
  font-size: ${Size.medium};
`;

const AttributesTitle = styled.div`
  padding-left: 15px;
  display: flex;
  font-size: 22px;
  font-weight: ${FontWeight.bold};
  justify-content: center;
  align-items: center;
`;

const SelectAttributeMessage = styled.div`
  text-align: center;
  vertical-align: middle;
  font-weight: ${FontWeight.bold};
  font-size: 32pt;
  color: ${Main.accent};
  width: ${Percent.full};
`;

export interface Props {
  selectedVendor: Vendor;
};

const AttributesList = ({ selectedVendor }: Props) => {
  const [vendorAttributes, setVendorAttributes] = useState<Attribute[]>([]);
  const [selectedAttribute, setSelectedAttribute] = useState<Attribute>(empty);
  const [stateToReset, setStateToReset] = useState<Attribute[]>([])
  const [valuesToMerge, setValuesToMerge] = useState<AttributeValue[]>([]);
  const [mergingValues, setMergingValues] = useState<boolean>(false);
  const [changedValues, setChangedValues] = useState<ChangedValues[]>([]);
  const [addingValue, setAddingValue] = useState<boolean>(false);
  const [importModal, setImportModal] = useState<boolean>(false);
  const [attributesToMerge, setAttributesToMerge] = useState<Attribute[]>([]);
  const [mergingAttributes, setMergingAttributes] = useState(false);
  const [changedAttributes, setChangedAttributes] = useState<MergedAttributes[]>([]);
  const { user, token } = useAuth();

  useEffect(() => {
    if (selectedVendor) {
      setSelectedAttribute(empty);
      getVendorAttributes(token!, selectedVendor.id!).then(attributes => {
        setVendorAttributes(attributes);
        setStateToReset(attributes);
      });
    }
  }, [selectedVendor]);

  const handleChange = (index: number, e: string) => {
    const newAttribute = { ...selectedAttribute, values: selectedAttribute.values.map((el, i) => i === index ? el = { ...el, name: e } : el) };

    setSelectedAttribute(newAttribute);
    setVendorAttributes(updateAttributeList(vendorAttributes, newAttribute));
  };

  const displayValues = (code: string) => {
    const foundAttribute = vendorAttributes.find(el => el.code === code);

    if (selectedAttribute.code === code) {
      setSelectedAttribute(empty);
    } else {
      setSelectedAttribute(foundAttribute!);
    }
  };

  const attributeNames = vendorAttributes.map((el, i) => {
    return (
      <Draggable draggableId={el.code} key={el.code} index={i}>
        {(provided) => (
          <ListItem
            ref={provided.innerRef}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            active={selectedAttribute.code === el.code}
            key={el.code} onClick={() => displayValues(el.code)}
          >
            <FlexboxContainer maxWidth="100%" justifyContent="space-between" >
              {mergingAttributes
                ? <Checkbox type="checkbox" onChange={() => setCheckedAttribute(i)} />
                : null
              }
              {i + 1}. {el.name}
              <ValuesCount>({el.values.length})</ValuesCount>
            </FlexboxContainer>
          </ListItem>
        )}
      </Draggable>
    );
  });

  const onDragEndAttributes = (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const reorderedAttributes = move(vendorAttributes, source.index, destination.index)

    setVendorAttributes(reorderedAttributes);
  };

  const onDragEndValues = (result: any) => {
    const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const reorderedValues = move(selectedAttribute.values, source.index, destination.index);
    const newAttribute = { ...selectedAttribute, values: reorderedValues }

    setSelectedAttribute(newAttribute);
    setVendorAttributes(updateAttributeList(vendorAttributes, newAttribute));
  };

  const addValue = (newValue: AttributeValueRequest) => {
    setAddingValue(false);

    const newAttribute = { ...selectedAttribute, values: [...selectedAttribute.values, { ...newValue, order: newValue.displayOrder }] }
    setSelectedAttribute(newAttribute);
    setVendorAttributes(updateAttributeList(vendorAttributes, newAttribute));
  };

  const importXLSXAttributeValue = (newAttributeValues: AttributeValueRequest[]) => {
    const newAttribute = {
      ...selectedAttribute,
      values: [
        ...selectedAttribute.values,
        ...newAttributeValues.map((newValue) => ({
          id: newValue.id,
          attributeId: newValue.attributeId,
          code: newValue.code,
          name: newValue.name,
          displayOrder: newValue.displayOrder,
          order: newValue.displayOrder
        }))
      ]
    };
    setSelectedAttribute(newAttribute);
    setVendorAttributes(updateAttributeList(vendorAttributes, newAttribute));
  }

  const setChecked = (index: number) => {
    if (!valuesToMerge.some(el => el.code === selectedAttribute.values[index].code)) {
      setValuesToMerge([...valuesToMerge, selectedAttribute.values[index]]);
    } else {
      setValuesToMerge(valuesToMerge.filter(el => el.code !== selectedAttribute.values[index].code))
    }
  };

  const setCheckedAttribute = (index: number) => {
    if (!attributesToMerge.some(el => el.code === vendorAttributes[index].code)) {
      setAttributesToMerge([...attributesToMerge, vendorAttributes[index]]);
    } else {
      setAttributesToMerge(attributesToMerge.filter(el => el.code !== vendorAttributes[index].code));
    }
  };

  const onModalClose = () => {
    if (mergingValues || valuesToMerge.length > 0) {
      setMergingValues(false);
      setValuesToMerge([]);
    }

    if (mergingAttributes || attributesToMerge.length > 0) {
      setMergingAttributes(false);
      setAttributesToMerge([]);
    }
    setImportModal(false);
    setAddingValue(false);
  };

  const toggleMerging = () => {
    setMergingValues(!mergingValues);
  };

  const sort = (direction: 'asc' | 'desc') => {
    setSelectedAttribute({ ...selectedAttribute, values: orderByArray(selectedAttribute.values, 'name', direction) });
  };

  const toggleMergingAttributes = () => {
    setMergingAttributes(!mergingAttributes);
  };

  const resetWithoutSave = () => {
    const resetSelectedAttribute = stateToReset.find(attr => attr.code === selectedAttribute.code)!;

    setSelectedAttribute(resetSelectedAttribute);
    setVendorAttributes(stateToReset);
  };

  const mergeModalValues = (newValue: AttributeValue) => {
    const filteredValues = selectedAttribute.values.filter(el => !valuesToMerge.map(v => v.code).includes(el.code));

    const newAttribute = {
      ...selectedAttribute,
      values: [...filteredValues.map((el, i) => { return { ...el, order: i } }), { ...newValue, order: filteredValues.length + 1 }]
    };

    const changes = {
      attributeCode: selectedAttribute.code,
      oldValues: valuesToMerge,
      newValue: newValue
    }
    setChangedValues([...changedValues, changes]);

    setSelectedAttribute(newAttribute);
    setVendorAttributes(updateAttributeList(vendorAttributes, newAttribute));

    setValuesToMerge([]);
    setMergingValues(false);
  };

  const mergeModalAttributes = (selectedCode: string, selectedName: string) => {
    const mergedAttribute = mergeAttributes([...attributesToMerge], selectedCode, selectedName);
    const filteredAttributes = vendorAttributes.filter(el => !attributesToMerge.map(a => a.code).includes(el.code));

    setVendorAttributes([...filteredAttributes, mergedAttribute]);
    setSelectedAttribute(mergedAttribute);
    setChangedAttributes([...changedAttributes, { oldAttributes: [...attributesToMerge], newAttribute: mergedAttribute }]);
    setAttributesToMerge([]);
    setMergingAttributes(false);

    renderToast(toast.TYPE.SUCCESS, 'Attributes merged, please check for duplicate values.');
  };

  const updateVendorAttributes = () => {
    const toastId = 'updateAttributes';
    const reoderRequestArray = vendorAttributes.map(el => ({ id: el.id!, displayOrder: el.order, values: el.values.map(v => ({ id: v.id!, displayOrder: v.order })) }))

    renderToast(toast.TYPE.INFO, 'The attributes are being saved, please wait...', {
      toastId,
      autoClose: false
    });

    reoderRequest(token as string, { attributes: reoderRequestArray })
      .then(result => {
        updateToast(toastId, toast.TYPE.SUCCESS, 'Attributes updated successfully.', {
          autoClose: 5000
        });
      }).catch(err => {
        updateToast(toastId, toast.TYPE.ERROR, err.message, {
          autoClose: 5000
        });
      });

    setChangedValues([]);
    setChangedAttributes([]);
  };

  return (
    <FlexboxContainer justifyContent="flex-start" minWidth="100%">
      <MergeAttributeModal
        valuesToMerge={valuesToMerge}
        mergeValues={mergeModalValues}
        onClose={onModalClose}
        showModal={!mergingValues && valuesToMerge.length > 0}
      />
      <MergeAttributeModal
        attributesToMerge={attributesToMerge}
        mergeAttributes={mergeModalAttributes}
        onClose={onModalClose}
        showModal={!mergingAttributes && attributesToMerge.length > 0}
      />
      <AddAttributeValueModal
        attribute={selectedAttribute}
        addValue={addValue}
        onClose={onModalClose}
        title="Add attribute value:"
        showModal={addingValue}
      />
      <ImportXLSXAttributeValueModal
        attribute={selectedAttribute}
        importXLSX={importXLSXAttributeValue}
        onClose={onModalClose}
        title={ATTRIBUTE_CONSTANTS.IMPORT_TITLE}
        showModal={importModal}
      />
      <FlexboxContainer minWidth="100%" flexDirection="column">
        <FlexboxContainer minWidth="100%" padding="12px" justifyContent="space-between" bgColor={Background.white} boxShadow="0px 5px lightgrey" margin="0 0 8px 0">
          <AttributesTitle>
            <PageHeading>Attributes - ({attributeNames.length})</PageHeading>
            <StyledInfoIcon tooltipText='List of all the attributes for the selected vendor.' />
          </AttributesTitle>
          <Button disabled={selectedAttribute.values.map(el => el.name).some(el => el === '')} onClick={updateVendorAttributes}>Save</Button>
        </FlexboxContainer>
        <FlexboxContainer>
          <FlexboxContainer flexDirection="column" minWidth="25%">
            <FlexboxContainer minWidth="100%" padding="12px" justifyContent="center" bgColor={Background.white} boxShadow="0px 5px lightgrey" margin="0 0 8px 0">
              {!isVendor(user!)
                ? <Button onClick={toggleMergingAttributes}>{mergingAttributes ? 'Merge' : 'Select to merge'}</Button>
                : null
              }
            </FlexboxContainer>
            <DragDropContext onDragEnd={onDragEndAttributes}>
              {!selectedVendor ?
                <SelectAttributeMessage>
                  Please select a vendor
                </SelectAttributeMessage>
                :
                <Droppable droppableId="attributes-list">
                  {(provided) => (
                    <List
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {attributeNames}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              }
            </DragDropContext>
          </FlexboxContainer>
          <DragDropContext onDragEnd={onDragEndValues}>
            {selectedAttribute.name === '' ?
              <SelectAttributeMessage>
                Please select an attribute
              </SelectAttributeMessage>
              :
              <DroppableColumn
                droppableId="attribute-values-list"
                selectedAttribute={selectedAttribute}
                changeValue={handleChange}
                setAdding={setAddingValue}
                setImportModal={setImportModal}
                updateVendorAttributes={updateVendorAttributes}
                toggleMerging={toggleMerging}
                mergingValues={mergingValues}
                setChecked={setChecked}
                sort={sort}
                resetWithoutSave={resetWithoutSave}
              />
            }
          </DragDropContext>
        </FlexboxContainer>
      </FlexboxContainer>
    </FlexboxContainer >
  );
};

export default AttributesList;
