import React from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Border, Main, Text } from 'styles/themeColors';
import { FontWeight } from 'lib/styles';
import { TEXT_LABELS } from 'constants/order';
import { DatePicker } from '@material-ui/pickers';
import DatePickerInput from 'components/UI/DatePickerInput';
import ShippingCarrier from 'data/ShippingCarrier';
import Select from 'components/UI/Select';
import Input from 'components/UI/Input';
import Icon from 'view/Icon';
import { onlyWeekdaysAllowed } from 'utils/product';

const LabelText = styled.span<any>`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
  margin-bottom: 5px;
  color: ${Text.primary};
  font-size: 15px;
  color: ${({ color }) => color || 'inherit'};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
`;

const TextArea = styled.textarea`
  border: 1px solid ${Border.main};
  border-radius: 6px;
  padding: 7px;
`;

const IconAsterisk = styled.span<any>`
  color: ${({ $error }) => $error ? Main.error : 'red'} ;
  width: 5px;
  height: 5px;
`;

const WarningIcon = styled(Icon).attrs({ name: 'WarningBlack', size: 20 })``;

export interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: (value: string) => void;
  title: string;  
  disabled?: boolean;
  originalRentalStartDate: string;
  originalRentalEndDate: string;
  rentalStartDate: string | null;
  rentalEndDate: string | null;
  reason: string;
  setRentalStartDate: (date: string | null) => void;
  setRentalEndDate: (date: string | null) => void;
  setReason: (reason: string) => void;
  carrier?: string;
  setCarrier?: (event: any) => void;
  trackingId?: string;
  setTrackingId?: (trackingId: string) => void;
  estDate?: string;
  setEstDate?: (date: string) => void;
  isShipping: boolean;
  shippingCarriers?: ShippingCarrier[];
  isRentalModificationRequired?: boolean;
  weekdaysOnly: boolean;
};

const ModifyEstDateWithRentaModal = ({
  show,
  onClose,
  onConfirm,
  title,
  disabled = false,
  rentalEndDate,
  rentalStartDate,
  setRentalStartDate,
  setRentalEndDate,
  reason,
  setReason,
  originalRentalEndDate,
  originalRentalStartDate,
  carrier,
  setCarrier,
  trackingId,
  setTrackingId,
  estDate,
  setEstDate,
  isShipping,
  shippingCarriers = [],
  isRentalModificationRequired = false,
  weekdaysOnly
}: Props) => {
  return (
    <Modal
      showButtons
      showModal={show}
      title={title}
      onClose={onClose}
      onClick={onConfirm}
      disabled={disabled}
      ghostButtonText={'Cancel'}
      primaryButtonText={'Confirm'}
      width='576px'
      left='35%'
      top='20%'
    >
        <FlexboxContainer flexDirection="column" gap='16px'>
            {
                isShipping ? 
                <FlexboxContainer flexDirection='row' gap='4px'>
                    <Select
                        noBoxShadow
                        options={shippingCarriers.map(shCarrier => ({ value: shCarrier.name }))}
                        value={carrier}
                        defaultValue="Select"
                        onChange={setCarrier!}
                        labelPosition='top'
                        label="Carrier"
                        width="100%"
                        height="36px"
                    />
                    <Input
                        id="tracking-id"
                        labelName="Tracking #"
                        placeholder="Enter tracking number"
                        value={trackingId}
                        onChange={(e) => setTrackingId?.(e.target.value)}
                        maxLength="25"
                        separation='5px'
                    />
                    <FlexboxContainer flexDirection="column" width='100%' margin='3px 0px'>
                        <LabelText>{TEXT_LABELS.ESTIMATED_DELIVERY}</LabelText>
                        <DatePicker
                            value={estDate!}
                            inputVariant="outlined"
                            format="yyyy MMM dd"
                            TextFieldComponent={DatePickerInput}
                            onChange={(date) => setEstDate?.(date?.toString()!)}
                            autoOk
                        /> 
                    </FlexboxContainer>
                </FlexboxContainer> :
                <FlexboxContainer flexDirection='row' gap='4px'>
                    <Input
                        id="reference-id"
                        labelName="Reference #"
                        placeholder="Enter reference number"
                        value={trackingId}
                        onChange={(e) => setTrackingId?.(e.target.value)}
                        maxLength="50"
                        separation='5px'
                    />
                     <FlexboxContainer flexDirection="column" width='100%' margin='3px 0px'>
                        <LabelText>{TEXT_LABELS.READY_FOR_PICKUP}</LabelText>
                        <DatePicker
                            value={estDate!}
                            inputVariant="outlined"
                            format="yyyy MMM dd"
                            TextFieldComponent={DatePickerInput}
                            onChange={(date) => setEstDate?.(date?.toString()!)}
                            autoOk
                        /> 
                    </FlexboxContainer>
                </FlexboxContainer>
            }
        <FlexboxContainer flexDirection='row' gap='4px'>
            <FlexboxContainer flexDirection="column" width='100%'>
              <LabelText>{TEXT_LABELS.CURRENT_START_DATE}</LabelText>
              <DatePicker
                value={originalRentalStartDate}
                inputVariant="outlined"
                format="yyyy MMM dd"
                TextFieldComponent={DatePickerInput}
                onChange={(date) => setRentalStartDate(date?.toString()!)}
                autoOk
                disabled
              /> 
          </FlexboxContainer>
          <FlexboxContainer flexDirection="column" width='100%'>
            <LabelText>{TEXT_LABELS.CURRENT_END_DATE}</LabelText>
              <DatePicker
                value={originalRentalEndDate}
                inputVariant="outlined"
                format="yyyy MMM dd"
                TextFieldComponent={DatePickerInput}
                onChange={(date) => setRentalEndDate(date?.toString()!)}
                autoOk
                disabled
              />
           </FlexboxContainer>
        </FlexboxContainer>
          <FlexboxContainer flexDirection='row' gap='4px'>
            <FlexboxContainer flexDirection="column" width='100%'>
              <FlexboxContainer gap='4px'>
                <LabelText>{TEXT_LABELS.NEW_START_DATE}</LabelText>
                {
                  isRentalModificationRequired ? 
                  <IconAsterisk>*</IconAsterisk> :
                  <LabelText italic color={Text.placeholder}>{TEXT_LABELS.OPTIONAL}</LabelText>
                }
              </FlexboxContainer>
              <DatePicker
                value={rentalStartDate!}
                inputVariant="outlined"
                format="yyyy MMM dd"
                TextFieldComponent={DatePickerInput}
                onChange={(date) => setRentalStartDate(date?.toString()!)}
                autoOk
                shouldDisableDate={(date) => onlyWeekdaysAllowed(date, weekdaysOnly)}
              /> 
          </FlexboxContainer>
          <FlexboxContainer flexDirection="column" width='100%'>
            <FlexboxContainer gap='4px'>
              <LabelText>{TEXT_LABELS.NEW_END_DATE}</LabelText>
              {
                isRentalModificationRequired ? 
                <IconAsterisk>*</IconAsterisk> :
                <LabelText italic color={Text.placeholder}>{TEXT_LABELS.OPTIONAL}</LabelText>
              }
            </FlexboxContainer>
            <DatePicker
              value={rentalEndDate!}
              inputVariant="outlined"
              format="yyyy MMM dd"
              TextFieldComponent={DatePickerInput}
              onChange={(date) => setRentalEndDate(date?.toString()!)}
              autoOk
              shouldDisableDate={(date) => onlyWeekdaysAllowed(date, weekdaysOnly)}
            />
           </FlexboxContainer>
        </FlexboxContainer>
        <FlexboxContainer flexDirection="column" margin='20px 0px'>
          <LabelText>{TEXT_LABELS.RENTAL_MODIFICATOION_REASON}</LabelText>
          <TextArea maxLength={500} rows={5} value={reason} onChange={(event: any) => setReason(event.target.value)} />
        </FlexboxContainer>
        <FlexboxContainer flexDirection='row' gap='4px' alignItems='center'> 
          <WarningIcon />
          <LabelText>Please verify the estimated delivery date and the rental start date. {!isRentalModificationRequired ? "If the rental dates have not changed, please leave the 'New Start Date' and 'New End Date' fields blank." : null}</LabelText>
        </FlexboxContainer>
         </FlexboxContainer>
    </Modal>
  );
};

export default ModifyEstDateWithRentaModal;
