import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import MatTable from 'components/MaterialTable/MatTable';
import Modal from 'components/UI/Modal'
import { renderToast } from 'components/UI/ToastNotification';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { getVendorsForCompany } from 'requests/vendor';
import { useAPI } from 'lib/API';
import columns from 'constants/vendorRelationsColumns';
import { Main, Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface Props {
  companyId: string;
  page?: string;
  updateVendor?: (name: string) => void;
};

const VendorRelationsTab = ({
  companyId,
  page,
  updateVendor
}: Props) => {
  const [vendors, setVendors] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [showModal, setShowModal] = useState(false);
  const [clickedVendorName, setClickedVendorName] = useState('');

  const calculatePages = (vendorsCount: number, pageSize: number) => {
    const pagesCount = Math.ceil(vendorsCount / pageSize);

    return (pagesCount === 0) ? 1 : pagesCount;
  };

  const applyPaginationFilter = (vendors: any[], pageIndex: number, pageSize: number) => {
    return vendors.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
  };

  const vendorsFromPagination = applyPaginationFilter(vendors, currentPage, itemsPerPage);
  const pagesCount = calculatePages(vendors.length, itemsPerPage);

  const onSizeChange = (newSize: number) => {
    setItemsPerPage(newSize);
    setCurrentPage(1);
  };

  const vendorsLoadReq = useAPI({
    deferFn: getVendorsForCompany,
    onResolve: result => {
      setVendors(result)
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    if (companyId) {
      vendorsLoadReq.run(companyId);
    }
  }, [companyId]);

  const onRowClick = (event?: any, rowData?: any) => {
    setClickedVendorName(rowData.name);
    setShowModal(true);
  };

  const onConfirmSwitchVendorClick = (name: string) => {
    if (updateVendor) {
      updateVendor(name);
    }
  };

  const mappedColumns = columns.map((column: any) => {
    if (column.field === 'vendorCompanyId') {
      column.render = (params: any) => (
        <span style={{ color: params.vendorCompanyId === 'Not assigned' ? Main.error : Text.primary }}>
          {params.vendorCompanyId}
        </span>
      );
    }

    return column;
  });

  return (
    <>
      <MatTable
        columns={mappedColumns}
        data={vendorsFromPagination}
        isLoading={vendorsLoadReq.isLoading}
        onRowClick={onRowClick}
        toolbar
        overrideToolbar
        customPagination={{
          itemsPerPage,
          setItemsPerPage: onSizeChange,
          currentPage,
          setCurrentPage,
          totalPages: pagesCount,
          baseRoute: '/companies',
          shouldRedirect: false
        }}
      />
      <Modal
        title="Switch to vendor view"
        onClose={() => setShowModal(false)}
        showModal={showModal}
        theme="accent"
        onClick={() => onConfirmSwitchVendorClick(clickedVendorName)}
        primaryButtonText="Switch"
      >
        <FlexboxContainer flexDirection="column">
          <Span>
            You have to switch from Admin to {clickedVendorName} view to be able to see or edit Vendor / Company relations.
          </Span>
        </FlexboxContainer>
      </Modal>
    </>
  );
};

export default VendorRelationsTab;
