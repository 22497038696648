import styled, { css } from 'styled-components';

export const latoFont = css`
  font-weight: 400;
  color: ${({ theme }) => theme.textBlack};
  margin-top: 0;
`;

export const Heading1 = styled.h1`
  ${latoFont}
  font-size: 32px;
  font-weight: 900;
  line-height: 36px;
  margin-top: 48px;
  margin-bottom: 16px;
`;

export const Heading2 = styled.h3`
  ${latoFont}
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
`;

export const Heading3 = styled.h2`
  ${latoFont}
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 16px;
`;

export const Heading4 = styled.h4`
  ${latoFont}
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 16px;
`;

export const Heading5 = styled.h5`
  ${latoFont}
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 16px;
`;

export const Paragraph = styled.p<{ bold?: boolean, italic?: boolean}>`
  ${latoFont}
  font-style: ${({ italic }) => italic ? 'italic' : 'normal'};
  font-weight: ${({ bold }) => bold ? 700 : 400};
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 16px;
`;
