import { send } from 'lib/API';

export async function getPolicyKey() {
  const request = new Request(
    'https://players.brightcove.net/6076300345001/default_default/config.json',
    {
      method: 'GET'
    }
  );

  const response = await send(request);
  return response.video_cloud.policy_key;
}

export async function getVideoThumbnail({ policyKey, videoId }: any) {
  const headers = { Accept: `application/json;pk=${policyKey}` };
  const request = new Request(
    `https://edge.api.brightcove.com/playback/v1/accounts/6076300345001/videos/${videoId}`,
    {
      headers,
      method: 'GET'
    }
  );

  const response = await send(request);
  return response.thumbnail_sources[0].src;
}
