import React, { useContext, useEffect, useState } from 'react';
import { navigate } from 'hookrouter';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Product, * as Prod from 'data/Product';
import Vendor, { SkuValidation } from 'data/Vendor';
import Attribute from 'data/Attribute';
import { ProductErrorType, validateProduct } from 'data/ProductValidation';
import VariantTable from 'common/VariantTable';
import PDPPreview from 'common/PDPPreview';
import ConfirmModal from 'components/UI/Modal';
import ProductEditor from 'common/ProductEditor';
import ImportModal from 'common/ImportModal';
import Button from 'components/UI/Button';
import FixedHeader from 'view/FixedHeader';
import Icon from 'view/Icon';
import Link from 'view/Link';
import NavLink from 'view/NavLink';
import UploadButton from 'view/UploadButton';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import Tooltip from 'components/UI/Tooltip';
import { renderToast, updateToast } from 'components/UI/ToastNotification';
import { Color, Percent, Size } from 'lib/styles';
import { getStash } from 'lib/stash';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import spreadsheetImport from 'lib/spreadsheetImport';
import { createAttributes } from 'requests/atribute';
import { getVendorAttributes } from 'requests/vendor';
import { Space } from 'styles/themeSpaces';
import { Background } from 'styles/themeColors';
import { merge } from 'utils/array';
import FeatureFlag from 'data/FeatureFlag';
import { VariantAttributeValue } from '../data/Variant';
import { useCurrencies } from 'hooks/useCurrencies';
import { PRODUCT_CONSTANTS } from 'constants/productColumns';
import { productVariantMessages } from 'constants/errorMessages';
import { useCountries } from 'hooks/useCountries';
import { SocketContext } from 'context/socket';
import UploadProductVariant from 'components/Product/ProductVariantImportModal';
import { getProductById, loadProductDetails } from 'requests/product';
import { STATUS } from 'constants/productVariantImport';
import { MAX_PRODUCT_NAME_LENGTH } from 'constants/product';

const EdgeLink = styled(Link)`
  border-right: 1px solid ${Color.gray};
  line-height: 4;
  margin-left: -${Size.medium};
  padding: 0px ${Size.medium};
  display: flex;
  align-items: center;
`;

const Page = styled.div<any>`
  -webkit-overflow-scrolling: touch;
  background-color: ${Background.white};
  color: ${Color.black};
  height: ${Percent.full};
  margin-left: 10px;
  width: ${Percent.full};
  max-width: ${({ width }) => width}px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 30px;
`;

const TooltipContent = styled.div`
  text-align: left;
  white-space: initial;
  width: 15rem;
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

export interface Props {
  productId?: string;
  view: 'details' | 'preview' | 'variants';
  selectedVendor: Vendor;
  vendorsList?: Vendor[];
  updateVendor: (name: string) => void;
  featureFlags: FeatureFlag;
};

const attributeErrorMessage = (serverMessage: string = ''): string => {
  return 'Some attributes were omitted.' + (serverMessage ? ` Reason: ${serverMessage}` : '');
}

const TEXT_LABELS = {
  PRODUCT_SUBMITTED_MESSAGE: 'Product submitted successfully.',
  PRODUCT_VALIDATION_START_MESSAGE:'Validation has been started. This may take a few minutes.',
  PRODUCT_NAME_LENGTH_ERROR: `The product name cannot exceed ${MAX_PRODUCT_NAME_LENGTH} characters. Please shorten the name and try again.`
};

export default function ProductDetails({ productId, view, selectedVendor, vendorsList, updateVendor, featureFlags }: Props) {
  const [vendorAttributes, setVendorAttributes] = useState<Attribute[]>([]);
  const [shouldReloadVendorAttributes, setShouldReloadVendorAttributes] = useState(false);
  const [importProduct, setImportProduct] = useState<Product | null>(null);
  const [importedAttributeNames, setImportedAttributeNames] = useState<Array<{ name: string; isSelectable: boolean }>>([]);
  const [attributesList, setAttributesList] = useState<Prod.ProductAttribute[]>([]);
  const [isImporting, setIsImporting] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [publishedSKUList, setPublishedSKUList] = useState<string[]>([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [variantsUploading, setVariantsUploading] = useState(false);
  const { token, user } = useAuth();
  // Fetch currencies using useCurrencies hook on mount
  const { currencies } = useCurrencies();
  const { countries } = useCountries();

  const [validation, setValidation] = useState(false);
  const [showProductVariantModal, setShowProductVariantModal] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const socket = useContext(SocketContext);
  // TODO: to remove feature flag from implementation.
  const draftReq = useAPI<Product>({
    productId,
    isFeatureFlagEnabled: true,
    promiseFn: Prod.loadLocalDraft
  });

  const loadProducts = useAPI({
    deferFn: loadProductDetails,
    onResolve: (result: any) => {
      draftReq.setData(result)
      socket.off(`product_${productId}`)
    },
    onReject: (err: any) => {console.log('err:', err)},
  });

  const updateLoadProduct = () => {
    productId && loadProducts.run(productId)
  }

  const producVariantStatusById = useAPI({
    deferFn: getProductById,
    onResolve: (result: any) => {
    result?.statusId === STATUS.PENDING ? setVariantsUploading(true)  :   setVariantsUploading(false)
    },
    onReject: (err: any) => {console.log('err:', err)},
  });

  const productsReq = useAPI({
    deferFn: Prod.countProductByName,
    onResolve: result => {
      if (result.totalItems > 0) {
        renderToast(toast.TYPE.ERROR, 'Product name already exist. Please update your product', {
          autoClose: 5000
        });
        navigate(previousLibraryPath);
      }
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const createAttributesReq = useAPI({
    deferFn: createAttributes,
    onResolve: result => handleCreateAttributesResults(result as unknown as Promise<PromiseSettledResult<Prod.ProductAttribute>[]>),
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const handleCreateAttributesResults = async (results: Promise<PromiseSettledResult<Prod.ProductAttribute>[]>) => {
    const settledResults = await results;
    let errorMessage = '';
    const correctAttributes = settledResults.reduce((attributes:any, currentResult) => {
      if (currentResult.status === 'fulfilled') {
        return attributes.concat(currentResult.value);
      }
      errorMessage = currentResult.reason?.message;
      return attributes;
    }, []);

    if (errorMessage.length) {
      renderToast(toast.TYPE.WARNING, attributeErrorMessage(errorMessage));
    }

    setAttributesList(correctAttributes);
  }

  let currentVendor: Vendor = selectedVendor;

  useEffect(() => {
    setValidation(false);
    if (draftReq.data && (draftReq.data.status === 'published' || draftReq.data.originalId) && publishedSKUList.length === 0) {
      setPublishedSKUList(draftReq.data!.variants.map(variant => variant.sku));
    }
    // draftReq?.data?.id &&  producVariantStatusById.run(draftReq?.data?.id)
  }, [draftReq.data]);

  useEffect(() => {
    draftReq?.data?.id &&  producVariantStatusById.run(draftReq?.data?.id)
  }, [draftReq?.data?.id]);



  useEffect(() => {
    if (selectedVendor) {
      getVendorAttributes(token!, selectedVendor.id).then((attributes) => {
        setVendorAttributes(attributes)
        setShouldReloadVendorAttributes(false);
      });
    }
  }, [selectedVendor, shouldReloadVendorAttributes]);

  useEffect(() => {
    if (vendorsList && vendorsList.length > 0 && draftReq.data && draftReq.data.vendor) {
      const currentVendor = vendorsList.find(vendor => vendor.id === draftReq.data!.vendor!.id) || selectedVendor;

      updateVendor(currentVendor.name);
    }
  }, [vendorsList, draftReq.data]);

  useEffect(() => {
    if (importedAttributeNames.length > 0) {
      createAttributesReq.run(importedAttributeNames, currentVendor.id);
    }
  }, [importedAttributeNames]);

  useEffect(() => {
    if (attributesList.length > 0 && importProduct) {
      const newImportProduct = importProduct;
      const modifiedImportedAttributeNames = importedAttributeNames.map((atr)=>{
        return atr.name;
      });
      newImportProduct.variants.forEach(variant => {
        const newVariantAttributes: VariantAttributeValue[] = [];
        variant.attributeValues.forEach((attributeValue, index) => {
          const validatedAttribute = attributesList.find((attribute) => modifiedImportedAttributeNames[index] === attribute.name);
          if (validatedAttribute) {
            newVariantAttributes.push({ ...attributeValue, attributeId: validatedAttribute.id, isSelectable: attributeValue.isSelectable })
          }
        })
        variant.attributeValues = newVariantAttributes;
      });

      // newImportProduct.attributes = Prod.classifyAttributes(newImportProduct, attributesList);
      newImportProduct.attributes = attributesList;
      newImportProduct.variants = newImportProduct.variants.map(variant => {
        const newAttributeValues = variant.attributeValues.map(value => {
          // const foundAttribute = newImportProduct.attributes.find(attribute => attribute.id === value.attributeId);

          return { ...value, isSelectable: value.isSelectable };
        });

        return { ...variant, attributeValues: newAttributeValues };
      });
      setImportProduct(importProduct);
    }
  }, [attributesList]);

  const previousLibraryPath: string = getStash('products') || '/products';

  const reloadVendorAttributes = (value: boolean) => {
    setShouldReloadVendorAttributes(value);
  };

  function save() {

    if (!draftReq.data || !currentVendor) return;

    const toastId = 'saveProduct';

    if (currentVendor) {
      setSaveDisabled(true);
      if(draftReq.data.name.length > MAX_PRODUCT_NAME_LENGTH){
        renderToast(
          toast.TYPE.ERROR,
          TEXT_LABELS.PRODUCT_NAME_LENGTH_ERROR,
          {
            toastId,
            autoClose: false,
          }
        );
        setSaveDisabled(false);
        return;
      }else{
      renderToast(toast.TYPE.INFO, 'The product is being saved, please wait...', {
        toastId,
        autoClose: false
      });

      Prod.saveLocalDraft(draftReq.data, currentVendor, token as string, currencies)
        .then((res) => {
          setSaveDisabled(false);
          if(res?.message){
            updateToast(toastId, toast.TYPE.ERROR, res.message, {
              autoClose: 5000
            });
          }else{
            updateToast(toastId, toast.TYPE.SUCCESS, 'Draft saved successfully.', {
              autoClose: 5000
            });
            navigate(previousLibraryPath);
          }
        })
        .catch(err => {
          setSaveDisabled(false);

          updateToast(toastId, toast.TYPE.ERROR, err.message, {
            autoClose: 5000
          });
        });
      }
    } else {
      renderToast(toast.TYPE.INFO, 'Please select a vendor before you try to save a product.');
      return;
    }
  }

  const submitModal = (
    <ConfirmModal
      showModal={isSubmitModalOpen}
      onClose={() => setIsSubmitModalOpen(false)}
      onClick={submit}
      title="Submit product"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          Are you sure you want to submit this product?
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )

  function submit() {
    if (!draftReq.data || !currentVendor) {
      return;
    }

    const toastId = 'submitProduct';

    if (currentVendor) {
      renderToast(toast.TYPE.INFO, 'The product is being submitted, please wait...', {
        toastId,
        autoClose: false
      });

      Prod.submit(draftReq.data, currentVendor, token as string,currencies, true)
        .then((res) => {
          if(res?.message){
            updateToast(toastId, toast.TYPE.ERROR, res.message, {
              autoClose: 5000
            });
          }else{
            if(res?.isValidationEnabled){
              updateToast(toastId, toast.TYPE.WARNING, TEXT_LABELS.PRODUCT_VALIDATION_START_MESSAGE, {
                autoClose: 2500
              });
                socket.on(`skuValidation_${user?.id}_${res?.id}`, ({status, productName}: SkuValidation)=>{
                  if(status){
                    renderToast(toast.TYPE.SUCCESS, `Validation has been completed successfully. You may publish the product ${productName} further.`, {
                      autoClose: 5000,
                      toastId
                    });
                    updateToast(toastId, toast.TYPE.SUCCESS, `Validation has been completed successfully. You may publish the product ${productName} further.`, {
                      autoClose: 5000,
                    });
                  }else{
                    renderToast(toast.TYPE.ERROR, `Validation has been completed. Product ${productName} has issues with material number(s).
                    Please contact your system administrator.`, {
                      autoClose: 5000,
                      toastId
                    });
                    updateToast(toastId,toast.TYPE.ERROR, `Validation has been completed. Product ${productName} has issues with material number(s). 
                    Please contact your system administrator.`, {
                      autoClose: 5000,
                    });
                  }
                });  
            }
            else{
              updateToast(toastId, toast.TYPE.SUCCESS, TEXT_LABELS.PRODUCT_SUBMITTED_MESSAGE, {
                autoClose: 5000
              });
            }
            
            navigate(previousLibraryPath);
          }
        })
        .catch(err => {
          updateToast(toastId, toast.TYPE.ERROR, err.message, {
            autoClose: 5000
          });
        });
    } else {
      renderToast(toast.TYPE.INFO, 'Please select a vendor before you try to submit a product.');
      return;
    }

    setIsSubmitModalOpen(false);
  }

  function update(product: Product) {
    draftReq.setData(product);
  }

  const importFile = async (file: File) => {
    if (!currentVendor) {
      renderToast(toast.TYPE.INFO, 'Please select a vendor before you try to import a product.');
      return;
    }

    const setImportAttributes = (attributeNames: Array<{ name: string; isSelectable: boolean }>): void => {
      setImportedAttributeNames(attributeNames);
    };

    const toastId = 'importProduct';
    setIsImporting(true);

    renderToast(toast.TYPE.INFO, 'The product(s) are being imported, please wait...', {
      toastId,
      autoClose: false
    });

    try {

      const product = await spreadsheetImport(
        file,
        setImportAttributes,
        draftReq.data!,
        currencies,
        countries,
        user?.role
      );

      updateToast(toastId, toast.TYPE.SUCCESS, 'Product imported successfully.', {
        autoClose: 5000
      });

      if (!product) {
        throw new Error(productVariantMessages.NO_PRODUCT_FOUND);
      }
      setImportProduct(product);

    } catch (err) {
      // Next line needs to be ignored due to typescript not being able to add types to err in catch
      // @ts-ignore
      if (err.message === productVariantMessages.NO_PRODUCT_FOUND) {
        updateToast(toastId, toast.TYPE.ERROR, productVariantMessages.NO_PRODUCT_FOUND_INVALID, {
          autoClose: 5000
        });
      }
      // @ts-ignore
      else if (err.message === productVariantMessages.WAREHOUSES_INVALID) {
        updateToast(toastId, toast.TYPE.ERROR, productVariantMessages.WAREHOUSES_INVALID, {
          autoClose: 5000
        });
      }
      // @ts-ignore
      else if (err.message === productVariantMessages.CURRENCY_INVALID) {
        updateToast(toastId, toast.TYPE.ERROR, productVariantMessages.CURRENCY_INVALID, {
          autoClose: 5000
        });
      }
      // @ts-ignore
      else if (err.message === productVariantMessages.FIXED_HEADER_INVALID) {
        updateToast(toastId, toast.TYPE.ERROR, productVariantMessages.FIXED_HEADER_INVALID, {
          autoClose: 5000
        });
      }
      // @ts-ignore
      else if (err.message === productVariantMessages.HIDDEN_ATTRIBUTE_INVALID) {
        updateToast(toastId, toast.TYPE.ERROR, productVariantMessages.HIDDEN_ATTRIBUTE_INVALID, {
          autoClose: 5000
        });
      }
      // @ts-ignore
      else if (err.message === productVariantMessages.NUMBER_ATTRIBUTE_INVALID) {
        updateToast(toastId, toast.TYPE.ERROR, productVariantMessages.NUMBER_ATTRIBUTE_INVALID, {
          autoClose: 5000
        });
      }
      else {
        updateToast(toastId, toast.TYPE.ERROR, productVariantMessages.GENERIC_INVALID, {
          autoClose: 5000
        });
      }
    } finally {
      setIsImporting(false);
    }

    return <LoadingIndicator />;
  };

  const stopImporting = () => {
    setIsImporting(false);

    setImportProduct(null);
  };

  function validateDataForVariantTable() {

    if(draftReq.data && !draftReq.data.brand){
      renderToast(toast.TYPE.WARNING, PRODUCT_CONSTANTS.BRAND_SELECTION_WARNING);
      return;
    }

    if (draftReq.data && (draftReq.data.warehouses.length === 0)) {
      renderToast(toast.TYPE.WARNING, PRODUCT_CONSTANTS.WAREHOUSE_SELECTION_WARNING_TOAST);
      return;
    }

    if (draftReq.data && !draftReq.data.id ) {
      renderToast(toast.TYPE.WARNING, PRODUCT_CONSTANTS.SAVE_WARNING);
      return;
    }

    navigate(`${detailsUrl}/variants`);
    
  }

  const validateSubmit = () => {
    if (draftReq.data && draftReq.data.warehouses.length === 0) {
      setValidation(true);
    } else {
      setIsSubmitModalOpen(true)
    }
  }

  const saveImports = () => {
    if (!importProduct || !draftReq.data) {
      return;
    }

    const updatedProduct = {
      ...draftReq.data,
      superCategory: importProduct.superCategory ? importProduct.superCategory : draftReq.data.superCategory,
      category: importProduct.category ? importProduct.category : draftReq.data.category,
      name: importProduct.name ? importProduct.name : draftReq.data.name,
      deliveryLeadDays: importProduct.deliveryLeadDays ? importProduct.deliveryLeadDays : draftReq.data.deliveryLeadDays,
      attributes: merge(draftReq.data.attributes, importProduct.attributes, 'name', ['id', 'code']),
      variants: merge(draftReq.data.variants, importProduct.variants, 'sku', ['id', 'code'])
    };

    draftReq.setData(updatedProduct);

    if (importProduct.name && selectedVendor && importProduct.name !== draftReq.data.name) {
      productsReq.run(selectedVendor.id, importProduct.name);
    }
    setImportProduct(null);
  };

  let routeView = null;

  const renderView = (view: string) => {
    const commonProps = {
      featureFlags,
      loading: draftReq.isLoading,
      product: draftReq.data!,
      update,
      selectedVendor: currentVendor,
      disabled: draftReq?.data?.status !== 'draft' || variantsUploading,
      stockFlag: draftReq?.data?.status !== 'published'
    };

    switch (view) {
      case 'preview':
        return <PDPPreview loading={commonProps.loading} product={commonProps.product} />;
      case 'variants':
        return (
          <VariantTable
            {...commonProps}
            vendorAttributes={vendorAttributes!}
            publishedSKUList={publishedSKUList}
            reloadVendorAttributes={reloadVendorAttributes}
            currencies={currencies}
            role={user?.role}
            countries={countries}
          />
        );
      case 'details':
        return (
          <ProductEditor {...commonProps} setSaveDisabled={setSaveDisabled} currencies={currencies} validation={validation} />
        );
      default:
        return null;
    }
  };

  if (draftReq.data && currentVendor) {
    routeView = renderView(view);
  }

  let closeUrl = '/products';
  if (previousLibraryPath !== undefined) {
    closeUrl = previousLibraryPath as string;
  } else if (draftReq.data) {
    switch (draftReq.data.status) {
      case 'published':
        closeUrl = '/products/published';
        break;
      case 'ready':
        closeUrl = '/products/ready';
        break;
    }
  }

  const closeLink = (
    <EdgeLink
      disabled={draftReq.isLoading}
      key="closeLink"
      onClick={Prod.clearLocalDraft}
      to={closeUrl}
    >
      <Icon name="x" />
    </EdgeLink>
  );

  let productErrorsCount = 0;
  let variantErrorsCount = 0;
  if (draftReq.isFulfilled) {
    const productValidation = validateProduct(draftReq.data, user?.role);

    productErrorsCount = productValidation.productErrors.filter(
      ({ type }) => type === ProductErrorType.Error
    ).length;

    variantErrorsCount = productValidation.variantErrors.length;
  }

  const detailsUrl = productId ? `/products/product-details/${productId}` : '/products/new-product';

  const editorLink = (
    <NavLink
      disabled={draftReq.isLoading}
      key="detailsLink"
      pillCount={productErrorsCount}
      to={detailsUrl}
    >
      Details
    </NavLink>
  );

  const variantsLink = (
    <NavLink
      disabled={draftReq.isLoading}
      key="variantsLink"
      pillCount={variantErrorsCount}
      onClick={validateDataForVariantTable}
      to={draftReq.data && (draftReq.data.warehouses.length === 0 || !draftReq.data.brand || !draftReq.data.id) ? `` : `${detailsUrl}/variants`}
    >
      Variants
    </NavLink>
  );

  const previewLink = (
    <NavLink
      disabled={draftReq.isLoading}
      key="previewLink"
      to={`${detailsUrl}/preview`}
    >
      Preview
    </NavLink>
  );

  const saveButton = (
    <Button disabled={saveDisabled} key="save" onClick={save}>
      Save
    </Button>
  );

  const submitButton = (
    <Button
      margin="0 10px 0 10px"
      disabled={
        draftReq.isLoading ||
        productErrorsCount + variantErrorsCount > 0     
      }
      key="submit"
      onClick={validateSubmit}
    >
      Submit
    </Button>
  );

  const submitButtonWithTooltip = productErrorsCount + variantErrorsCount > 0
    ? (
      <Tooltip
        content={
          <TooltipContent>
            This product may not be published until all issues have been resolved
          </TooltipContent>
        }
        key="submit"
        size="large"
      >
        {submitButton}
      </Tooltip>
    )
    : submitButton

  const importExcelButton = featureFlags?.productV2?.enabled ?   
   ( <Button
    onClick={()=>setShowProductVariantModal(true)}
   >
      Import XLSX
    </Button>)
    :  (
    <UploadButton
      acceptTypes={['.xls', '.xlsx']}
      label="Import XLSX"
      onChange={importFile}
      key={uuidv4()}
    />
  );

  let header = <FixedHeader left={[closeLink, editorLink, variantsLink, previewLink]} right={[]} />;

  if (draftReq.data && draftReq.data.status === 'draft') {
    if (view === 'variants') {
      header = (
        <FixedHeader
          left={[closeLink, editorLink, variantsLink, previewLink]}
          right={[importExcelButton, submitButtonWithTooltip, saveButton]}
        />
      );
    } else {
      header = (
        <FixedHeader
          left={[closeLink, editorLink, variantsLink, previewLink]}
          right={[submitButtonWithTooltip, saveButton]}
        />
      );
    }
  }


  const uploadVariantModal = (
    <UploadProductVariant
      show={showProductVariantModal}
      onClose={()=>setShowProductVariantModal(false)}
      product={draftReq.data!}
      role={user?.role}
      variantsUploading={variantsUploading}
      setVariantsUploading={setVariantsUploading}
      loadProducts={updateLoadProduct}
      featureFlags={featureFlags}
      isValidating={isValidating}
      setIsValidating={setIsValidating}
    />
  );

  return (
    <Page >
      <FlexboxContainer justifyContent="center" width="100%" alignItems="center">
        <ImportModal
          showModal={isImporting || importProduct}
          isLoading={isImporting || createAttributesReq.isLoading}
          onCancel={stopImporting}
          onImport={saveImports}
          updatedProduct={importProduct}
        />
        {header}
        {routeView}
        {submitModal}
      </FlexboxContainer>
      {showProductVariantModal && uploadVariantModal}
    </Page>
  );
};

ProductDetails.defaultProps = {
  view: 'details'
};
