import FlexboxContainerComponent from '../../UI/FlexboxContainer';
import Button from '../../UI/Button';
import {
  PricebookExtended,
  PricebookState,
  PricebookType,
} from '../../../data/Pricebook';
import React from 'react';
import styled from 'styled-components';
import Icon from '../../../view/Icon';
import { Background, Main, Shade, Text } from '../../../styles/themeColors';
import { FILE_LOAD_STATUS } from '../pricebook.enums';
import { errorLogUrl } from '../priceebook.utils';
import { useAuth } from '../../../lib/Auth';
import { Role } from '../../../data/User';

const ButtonIcon = styled(Icon).attrs({ size: 16 })<any>`
  color: ${Shade.shade550};
`;

const StyledFileDownloadIcon = styled(Icon).attrs({
  name: 'FileDownloadIconWhite',
  size: 20,
})`
  color: white !important;
  margin: 0px 8px 10px 0px;
`;

const DownloadTemplate = styled.a`
  display: flex;
  height: 36px;
  background-color: transparent !important;
  color: ${Shade.shade550} !important;
  border-color: ${Shade.shade550} !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;
const ButtonIconLarge = styled(Icon).attrs({ size: 24 })<any>`
  color: ${Text.placeholder};
  margin-right: 2px;
`;

const DownloadErrorLog = styled.a`
  display: flex;
  height: 36px;
  background-color: ${Background.black} !important;
  color: ${Text.whitePrimary} !important;
  border-color: ${Shade.shade550} !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;

const BUTTON_LABELS = {
  NEXT: 'Next',
  CANCEL_DRAFT: 'Cancel draft',
  DOWNLOAD_TEMPLATE: 'Download .xlsx template',
  DOWNLOAD_ERROR_LOG: 'Download error log',
  PUBLISH_NOW: 'Publish now',
};

interface PricebookActionButtons {
  pricebookData: PricebookExtended | null;
  currentPricebookData: PricebookExtended | null
  disableForcePublish: boolean;
  fileLoadStatus: FILE_LOAD_STATUS;
  fileUploading: boolean;
  step: number;
  hasSelectedVendor?: boolean;
  onNextButtonClick: () => void;
  onCancelButtonClick: () => void;
  onPublishButtonClick: () => void;
}

const PricebookUploadActionButtons = ({
  pricebookData,
  fileLoadStatus,
  disableForcePublish,
  fileUploading,
  step,
  onNextButtonClick,
  onCancelButtonClick,
  onPublishButtonClick,
  hasSelectedVendor = false,
  currentPricebookData,
}: PricebookActionButtons) => {
  const { user } = useAuth();

  const isOFMPAdmin =
    user?.role !== Role.Vendor &&
    user?.role !== Role.VendorAdmin &&
    !hasSelectedVendor;

  const isStepTwoFileVerification =
    step !== 1 &&
    (!pricebookData ||
      pricebookData?.priceBookState === PricebookState.UPLOADED);

  const showNextButton =
    isStepTwoFileVerification ||
    (fileLoadStatus === FILE_LOAD_STATUS.SUCCESS && step === 1);
  const disableNextButton = isStepTwoFileVerification || fileUploading;

  const showDownloadErrorLogButton =
    pricebookData &&
    pricebookData.priceBookState === PricebookState.VALIDATION_FAILED;

  const showPublishNowButton =
    pricebookData &&
    isOFMPAdmin &&
    pricebookData.priceBookState === PricebookState.VALIDATED;

  const showDownloadTemplateButton =
    fileLoadStatus !== FILE_LOAD_STATUS.SUCCESS && step === 1;

  return (
    <FlexboxContainerComponent
      padding="24px 0px"
      justifyContent={step === 1 ? 'right' : 'space-between'}
    >
      {step !== 1 && (
        <Button
          id="modal-main-button"
          bgColor={Background.light}
          color={Main.error}
          borderColor={Main.error}
          onClick={onCancelButtonClick}
          disabled={disableForcePublish}
        >
          {BUTTON_LABELS.CANCEL_DRAFT}
        </Button>
      )}
      {showNextButton && (
        <Button
          id="modal-main-button"
          bgColor={disableNextButton ? Main.disabled : Main.brandDark}
          borderColor={disableNextButton ? Main.disabled : Main.brandDark}
          color={disableNextButton ? Text.disabled : Text.whitePrimary}
          onClick={onNextButtonClick}
          disabled={disableNextButton}
        >
          {BUTTON_LABELS.NEXT}
        </Button>
      )}
      {showDownloadTemplateButton && (
        <DownloadTemplate
          href={
            currentPricebookData?.priceBookType === PricebookType.COMPANY
              ? '/Favorites Template.xlsx'
              : currentPricebookData?.priceBookType === PricebookType.REFERENCE
              ? '/Reference_Template.xlsx'
              : '/Geography_Template.xlsx'
          }
        >
          <FlexboxContainerComponent>
            <ButtonIcon name="FileDownloadIcon" />{' '}
            <FlexboxContainerComponent>
              {BUTTON_LABELS.DOWNLOAD_TEMPLATE}
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
        </DownloadTemplate>
      )}
      {showDownloadErrorLogButton && (
        <DownloadErrorLog href={errorLogUrl(pricebookData.pathToFile)}>
          <StyledFileDownloadIcon />
          {BUTTON_LABELS.DOWNLOAD_ERROR_LOG}
        </DownloadErrorLog>
      )}
      {showPublishNowButton && (
        <Button
          margin="0 4px 0 0"
          borderColor={Text.placeholder}
          bgColor={Background.light}
          onClick={onPublishButtonClick}
          disabled={disableForcePublish}
          color={Text.placeholder}
        >
          <ButtonIconLarge name="PricebookDeleteWarning" />
          {BUTTON_LABELS.PUBLISH_NOW}
        </Button>
      )}
    </FlexboxContainerComponent>
  );
};

export default PricebookUploadActionButtons;
