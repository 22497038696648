import React, {  } from 'react';
import Modal from '../UI/Modal';
import Vendor from 'data/Vendor';
import { TEXT_LABELS } from 'constants/campaign';
import SelectComponent from 'components/UI/Select';
import { getVendorValue } from 'utils/campaign';
import { Border, Main, Text } from 'styles/themeColors';

export interface Props {
  onClose: () => void;
  onConfirm: () => void;
  selectedVendors: number[];
  setSelectedVendors: (value: number[]) => void;
  showModal: boolean;
  vendorsList: Vendor[];
}

const SelectVendorModal = ({ onClose, onConfirm, selectedVendors, setSelectedVendors, showModal, vendorsList }: Props) => {
  return (
    <Modal
      showModal={showModal}
      onClose={onClose}
      onClick={onConfirm}
      title={TEXT_LABELS.PLEASE_SELECT_VENDORS}
      width='642px'
      left='50vw'
      top="30vh"
      showCloseIcon
      marginLeft="-342px"
      iconName={'PricebookModalIcon'}
      disabled={selectedVendors.length === 0}
      borderColor={Border.main}
      primaryButtonColor={Main.brandDark}
      color={Text.secondary}
    >
      <SelectComponent
        label="Vendor"
        labelPosition="top"
        options={vendorsList.map((vendor: Vendor) => ({
            value: vendor.name,
            id: vendor.id + '',
        }))}
        onChange={(event: any) => {
            const selVendors = event.target.value;
            const selectedItem = event.target.selected;
            const formValue = selectedVendors || [];
            const vendorId = vendorsList?.find((vendor: Vendor) => vendor.name === selectedItem)?.id;
            const updatedVendors = selVendors.indexOf(selectedItem) > -1
                ? [...formValue, Number(vendorId)]
                : formValue.filter((vendor: number) => vendor !== Number(vendorId));
            setSelectedVendors(updatedVendors);
        }}
        value={selectedVendors?.map(vendorId => getVendorValue(vendorsList, vendorId)!.toString())}
        defaultValue="Select Vendor"
        multiple
        displayAsterisk
        errroLabelPostion='bottom'
    />
    </Modal>
  );
}

export default React.memo(SelectVendorModal);