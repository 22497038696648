import React, { useContext, useEffect, useState, Suspense } from 'react';
import { navigate } from 'hookrouter';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Product, * as Prod from 'data/Product';
import Vendor from 'data/Vendor';
import Attribute from 'data/Attribute';
import { ProductError, ProductErrorType, validateProduct } from 'data/ProductValidation';
import PDPPreview from 'common/PDPPreview';
import Button from 'components/UI/Button';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, Percent } from 'lib/styles';
import { getStash } from 'lib/stash';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { createAttributes } from 'requests/atribute';
import { getVendorAttributes } from 'requests/vendor';
import { Background, Main } from 'styles/themeColors';
import FeatureFlag from 'data/FeatureFlag';
import Variant, { VariantAttributeValue } from '../data/Variant';
import { useCurrencies } from 'hooks/useCurrencies';
import { PRODUCT_CONSTANTS } from 'constants/productColumns';
import { SocketContext } from 'context/socket';
import UploadProductVariant from 'components/Product/ProductVariantImportModal';
import {
  getColumnSettings,
  getProductById,
  getVariantsByProductId,
  loadProductDetails,
  updateColumnSettings,
} from 'requests/product';
import { STATUS } from 'constants/productVariantImport';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import TabsBarComponent from 'components/UI/Tabsbar';
import {
  ROUTES,
  TEXT_LABELS,
  attributeErrorMessage,
  productDetailsTab,
} from 'constants/productDetails';
import SubmitModal from 'components/Product/VariantListing/SubmitModal';
import HeaderButtonComponent from 'components/Product/Common/HeaderButtonComponent';
import { useCountries } from 'hooks/useCountries';
import ProductEditorV2 from 'common/ProductEditorV2';
import UserConfiguration from 'data/UserConfiguration';
import { compareWarehouseIds } from 'utils/product';
import KeepOrDiscardModal from 'components/Product/VariantListing/KeepModal';
import Warehouse from 'data/Warehouse';
import { TAB_NUMBERS } from 'constants/product';
const VariantTableV2 = React.lazy(() => import('common/VariantTableV2'));

const Page = styled.div<any>`
  -webkit-overflow-scrolling: touch;
  background-color: ${Background.white};
  color: ${Color.black};
  height: ${Percent.full};
  margin-left: 10px;
  width: ${Percent.full};
  max-width: ${({ width }) => width}px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 30px;
`;
export interface Props {
  productId?: string;
  view: 'details' | 'preview' | 'variants' | 'newProduct' | 'warehouses';
  selectedVendor: Vendor;
  vendorsList?: Vendor[];
  updateVendor: (name: string) => void;
  featureFlags: FeatureFlag;
}

interface Pagination {
  actual: number;
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalRecords: number;
}

export interface Sorting {
  header: string;
  direction: string;
  sortField?: string;
}

export default function ProductDetailsNewDesign({
  productId,
  view,
  selectedVendor,
  vendorsList,
  updateVendor,
  featureFlags,
}: Props) {
  const [vendorAttributes, setVendorAttributes] = useState<Attribute[]>([]);
  const [shouldReloadVendorAttributes, setShouldReloadVendorAttributes] =
    useState(false);
  const [importProduct, setImportProduct] = useState<Product | null>(null);
  const [importedAttributeNames] = useState<
    Array<{ name: string; isSelectable: boolean }>
  >([]);
  const [attributesList, setAttributesList] = useState<Prod.ProductAttribute[]>(
    []
  );
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [warehouseConfirmationModalOpen, setWarehouseConfirmationModalOpen] = useState(false);
  const [publishedSKUList, setPublishedSKUList] = useState<string[]>([]);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [variantsUploading, setVariantsUploading] = useState(false);
  const { token, user } = useAuth();
  // Fetch currencies using useCurrencies hook on mount
  const { countries } = useCountries();
  const { currencies } = useCurrencies();
  const [validation, setValidation] = useState(false);
  const [showProductVariantModal, setShowProductVariantModal] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState<number[]>([]);
  const [activeTabs, setActiveTabs] = useState<number | undefined>(0);
  const [productDetails, setProductDetail] = useState<Product>();
  const [productVariants, setProductVariants] = useState<Variant[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingVariants, setLoadingVariants] = useState<boolean>(false);
  const [hasVariantRemoved, setHasVariantRemoved] = useState<boolean>(false);
  const [paginationObj, setPaginationObj] = useState<Pagination>();
  const [itemsPerPage, setItemsPerPage] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [skuForSearch, setSkuForSearch] = useState<string>('');
  const [priceType, setPriceType] = useState<string>('');
  const [sortByHeader, setSortByHeader] = useState<Sorting>();
  const [allVariantsSelection, setAllVariantsSelection] = React.useState(false);
  const [keepWarehouse, setKeepWarehouse] = React.useState(false);
  const [initialWarehouses, setInitialWarehouses] = React.useState<Warehouse[]>([]);
  const [isErrorHighlight, setIsErrorHighlight] = React.useState(false);
  const [columnSettings, setColumnSettings] = React.useState<any>();
  const [isValidating, setIsValidating] = useState(false);
  let productErrorsCount = 0;
  let productErrors: ProductError[] = [] ;
  let variantErrorsCount = 0;
  if (!loading && productDetails) {
    const productValidation = validateProduct(
      productDetails! as Product,
      user?.role
    );
     productErrors = productValidation.productErrors;
    productErrorsCount = productValidation.productErrors.filter(
      ({ type }) => type === ProductErrorType.Error
    ).length;

    variantErrorsCount = productValidation.variantErrors.length;
  }
  
  const socket = useContext(SocketContext);
  // TODO: to remove feature flag from implementation.
  const draftReq = useAPI<Product>({
    undefined,
    isFeatureFlagEnabled: true,
    promiseFn: Prod.loadLocalDraft,
  });

  const columnSettingsReq = useAPI<UserConfiguration>({
    deferFn: getColumnSettings,
    onResolve: (result: any) => {
      setColumnSettings(result);
    },
    onReject: (err: any) => {
      console.log('err:', err);
    }
  });
  

  const updateColumnSettingsReq = useAPI<UserConfiguration>({
    deferFn: updateColumnSettings,
    onResolve: (result: any) => {
      setColumnSettings(result);
    },
    onReject: (err: any) => {
      console.log('err:', err);
    },
  });

  useEffect(() => {
    columnSettingsReq.run();
  }, [])

  const loadProducts = useAPI({
    deferFn: loadProductDetails,
    onResolve: (result: any) => {
      setProductDetail(result);
      setInitialWarehouses(result.warehouses)
      setLoading(false);
      socket.off(`product_${productId}`);
    },
    onReject: (err: any) => {
      console.log('err:', err);
    },
  });

  const getVariants = useAPI({
    deferFn: getVariantsByProductId,
    onResolve: (result: any) => {
      setLoadingVariants(false);
      setProductVariants(result.data);
      setPaginationObj(result.meta);
    },
    onReject: (err: any) => {
      console.log('err:', err);
    },
  });

  const updateLoadProduct = () => {
    setLoading(true)
    setHasVariantRemoved(false);
    productId && loadProducts.run(productId);
  };

  const loadProductVariants = (resetPagination = false) => {

    const paginationObj = {
      currentPage: resetPagination ? 1 : currentPage,
      itemsPerPage,
      sortByHeader: resetPagination ? null : sortByHeader,
      skuForSearch: resetPagination ? '' : skuForSearch,
      priceType: resetPagination ? 'All' : priceType
    };

    productId && getVariants.run(productId, paginationObj);
  };

  useEffect(() => {
    view !== TEXT_LABELS.NEW_PRODUCT && setLoading(true);
    view !== TEXT_LABELS.NEW_PRODUCT &&  updateLoadProduct();
  }, [productId]);

  useEffect(() => {
    view !== TEXT_LABELS.NEW_PRODUCT && setLoadingVariants(true);
    const handler = setTimeout(() => {
      loadProductVariants();
      hasVariantRemoved && updateLoadProduct(); //due to delete case
      setSelectedVariants([]);
    }, 500);
    return () => {
      clearTimeout(handler);
    };
  }, [skuForSearch, itemsPerPage, currentPage, sortByHeader, priceType]);

  const producVariantStatusById = useAPI({
    deferFn: getProductById,
    onResolve: (result: any) => {
      result?.statusId === STATUS.PENDING
        ? setVariantsUploading(true)
        : setVariantsUploading(false);
    },
    onReject: (err: any) => {
      console.log('err:', err);
    },
  });

  const createAttributesReq = useAPI({
    deferFn: createAttributes,
    onResolve: (result) =>
      handleCreateAttributesResults(
        result as unknown as Promise<
          PromiseSettledResult<Prod.ProductAttribute>[]
        >
      ),
    onReject: (err) => {
      renderToast(toast.TYPE.ERROR, err.message);
    },
  });

  const handleCreateAttributesResults = async (
    results: Promise<PromiseSettledResult<Prod.ProductAttribute>[]>
  ) => {
    const settledResults = await results;
    let errorMessage = '';
    const correctAttributes = settledResults.reduce(
      (attributes: any, currentResult) => {
        if (currentResult.status === 'fulfilled') {
          return attributes.concat(currentResult.value);
        }
        errorMessage = currentResult.reason?.message;
        return attributes;
      },
      []
    );

    if (errorMessage.length) {
      renderToast(toast.TYPE.WARNING, attributeErrorMessage(errorMessage));
    }

    setAttributesList(correctAttributes);
  };

  let currentVendor: Vendor = selectedVendor;

  useEffect(() => {
    setValidation(false);
    if (
      productDetails &&
      (productDetails.status === TEXT_LABELS.PUBLISHED ||
        productDetails.originalId) &&
      publishedSKUList.length === 0
    ) {
      setPublishedSKUList(productVariants!.map((variant) => variant.sku));
    }
  }, [productDetails]);

  useEffect(() => {
    productDetails?.id && producVariantStatusById.run(productDetails?.id);
  }, [productDetails?.id]);

  useEffect(() => {
    if (selectedVendor) {
      getVendorAttributes(token!, selectedVendor.id).then((attributes) => {
        setVendorAttributes(attributes);
        setShouldReloadVendorAttributes(false);
      });
    }
  }, [selectedVendor, shouldReloadVendorAttributes]);

  useEffect(() => {
    if (
      vendorsList &&
      vendorsList.length > 0 &&
      productDetails &&
      productDetails.vendor
    ) {
      const currentVendor =
        vendorsList.find(
          (vendor) => vendor.id === productDetails!.vendor!.id
        ) || selectedVendor;

      updateVendor(currentVendor.name);
    }
  }, [vendorsList, productDetails]);

  useEffect(() => {
    if (importedAttributeNames.length > 0) {
      createAttributesReq.run(importedAttributeNames, currentVendor.id);
    }
  }, [importedAttributeNames]);

  useEffect(() => {
    if (attributesList.length > 0 && importProduct) {
      const newImportProduct = importProduct;
      const modifiedImportedAttributeNames = importedAttributeNames.map(
        (atr) => {
          return atr.name;
        }
      );
      newImportProduct.variants.forEach((variant) => {
        const newVariantAttributes: VariantAttributeValue[] = [];
        variant.attributeValues.forEach((attributeValue, index) => {
          const validatedAttribute = attributesList.find(
            (attribute) =>
              modifiedImportedAttributeNames[index] === attribute.name
          );
          if (validatedAttribute) {
            newVariantAttributes.push({
              ...attributeValue,
              attributeId: validatedAttribute.id,
              isSelectable: attributeValue.isSelectable,
            });
          }
        });
        variant.attributeValues = newVariantAttributes;
      });

      // newImportProduct.attributes = Prod.classifyAttributes(newImportProduct, attributesList);
      newImportProduct.attributes = attributesList;
      newImportProduct.variants = newImportProduct.variants.map((variant) => {
        const newAttributeValues = variant.attributeValues.map((value) => {
          // const foundAttribute = newImportProduct.attributes.find(attribute => attribute.id === value.attributeId);

          return { ...value, isSelectable: value.isSelectable };
        });

        return { ...variant, attributeValues: newAttributeValues };
      });
      setImportProduct(importProduct);
    }
  }, [attributesList]);

  const previousLibraryPath: string =
    getStash(TEXT_LABELS.PRODUCTS) || ROUTES.PRODUCTS;

  const reloadVendorAttributes = (value: boolean) => {
    setShouldReloadVendorAttributes(value);
  };

  useEffect(() => {
    if (view === TEXT_LABELS.VARIANTS) {
      setActiveTabs(TAB_NUMBERS.VARIANTS);
    } else if (view === TEXT_LABELS.DETAILS) {
      setActiveTabs(TAB_NUMBERS.DETAILS);
    }else if (view === TEXT_LABELS.WAREHOUSES) {
      setActiveTabs(TAB_NUMBERS.WAREHOUSES);
    }
  }, [view]);

  const isWarehousesEdited = !compareWarehouseIds(productDetails?.warehouses.map(warehouse => warehouse.id) || [] , initialWarehouses.map(warehouse => warehouse.id) || [] )

  function update(product: Product) {
    draftReq.setData(product);
    setProductDetail(product);
  }

  function updateColumnSettingsFn(columnSettings: any){
    updateColumnSettingsReq.run(columnSettings);
  }

  function validateDataForVariantTable(tabNum: number) {
    if (productDetails && !productDetails.brand) {
      renderToast(
        toast.TYPE.WARNING,
        PRODUCT_CONSTANTS.BRAND_SELECTION_WARNING
      );
      return false;
    }

    if (productDetails && !productDetails.warehouses.length && tabNum === TAB_NUMBERS.VARIANTS) {
      renderToast(
        toast.TYPE.WARNING,
        PRODUCT_CONSTANTS.WAREHOUSE_SELECTION_WARNING_TOAST
      );
      return false;
    }

    if (productDetails && !productDetails.id) {
      renderToast(toast.TYPE.WARNING, PRODUCT_CONSTANTS.SAVE_WARNING);
      return false;
    }

    if(isWarehousesEdited && tabNum === TAB_NUMBERS.VARIANTS){
      setWarehouseConfirmationModalOpen(true)
      return false;
    }

    if(saveDisabled && (tabNum === TAB_NUMBERS.VARIANTS ||  tabNum === TAB_NUMBERS.WAREHOUSES) ){
    const toastId = TEXT_LABELS.SAVE_PRODUCT;
      renderToast(
        toast.TYPE.ERROR,
        TEXT_LABELS.SAVE_PRODUCT_ERROR,
        {
          toastId,
          autoClose: false,
        }
      );
      setIsErrorHighlight(true)
      return false
    }

    return true;
  }

  let routeView = null;
  const importExcelButton = (
    <Button
      margin="0 4px 0 10px"
      onClick={() => setShowProductVariantModal(true)}
      bgColor={Background.navy}
      borderColor={Background.navy}
      width='118px'
      disabled={productDetails?.status !== TEXT_LABELS.DRAFT}
    >
      {TEXT_LABELS.IMPORT_XLSX}
    </Button>
  );


  const keepWarehouses = () => {
    setKeepWarehouse(true)
    setWarehouseConfirmationModalOpen(false)
  }

  const discardWarehouses = () => {
    setKeepWarehouse(false)
    updateLoadProduct()
    setWarehouseConfirmationModalOpen(false)
    if (!initialWarehouses.length) {
      renderToast(
        toast.TYPE.WARNING,
        PRODUCT_CONSTANTS.WAREHOUSE_SELECTION_WARNING_TOAST
      );
    }else{
      navigate(`${detailsUrl}/variants`)
    }
  }

  const renderView = (view: string) => {
    const errorKeys = productErrors.filter(error => error.type === PRODUCT_CONSTANTS.ERROR).map(error => error.key)
    const commonProps = {
      featureFlags,
      loading: loading,
      product: productDetails!,
      update,
      selectedVendor: currentVendor,
      disabled:
        productDetails?.status !== TEXT_LABELS.DRAFT || variantsUploading,
      stockFlag: productDetails?.status !== TEXT_LABELS.PUBLISHED,
    };

    const customPagination = {
      itemsPerPage: itemsPerPage,
      setItemsPerPage: (value: number) => {
        setItemsPerPage(value);
        setCurrentPage(1);
      },
      currentPage: currentPage,
      setCurrentPage: (value: number) => setCurrentPage(value),
      totalPages: paginationObj?.totalPages,
      baseRoute: ROUTES.PRODUCT_DETAIL,
      shouldRedirect: false,
    };
    switch (view) {
      case TEXT_LABELS.C_PREVIEW:
        return (
          <PDPPreview
            loading={commonProps.loading}
            product={commonProps.product}
          />
        );
      case TEXT_LABELS.VARIANTS:
        return (
          <Suspense fallback={<LoadingIndicator />}>
            <VariantTableV2
              {...commonProps}
              productVariants={productVariants!}
              setProductVariants={setProductVariants}
              customPagination={customPagination!}
              loadingVariants={loadingVariants}
              setSkuForSearch={setSkuForSearch}
              setPriceType={setPriceType}
              skuForSearch={skuForSearch}
              sortByHeader={sortByHeader}
              setSortByHeader={setSortByHeader}
              vendorAttributes={vendorAttributes!}
              publishedSKUList={publishedSKUList}
              reloadVendorAttributes={reloadVendorAttributes}
              currencies={currencies}
              role={user?.role}
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
              importExcelButton={importExcelButton}
              setHasVariantRemoved={setHasVariantRemoved}
              countries={countries}
              allVariantsSelection={allVariantsSelection}
              setAllVariantsSelection={setAllVariantsSelection}
              columnSettingsData={columnSettings}
              updateColumnSettings={updateColumnSettingsFn}
            />
          </Suspense>
        );
      case TEXT_LABELS.DETAILS:
        return (
          <ProductEditorV2
            {...commonProps}
            setSaveDisabled={setSaveDisabled}
            currencies={currencies}
            validation={validation}
            errorKeys={errorKeys}
            isErrorHighlight={isErrorHighlight}
            setProductVariants={setProductVariants}
          />
        );
      case TEXT_LABELS.NEW_PRODUCT:
        return (
          <ProductEditorV2
            {...commonProps}
            product={draftReq.data!}
            setSaveDisabled={setSaveDisabled}
            currencies={currencies}
            validation={validation}
            errorKeys={errorKeys}
            isErrorHighlight={isErrorHighlight}
            setProductVariants={setProductVariants}
          />
        );
        case TEXT_LABELS.WAREHOUSES:
          return (
            <Suspense fallback={<LoadingIndicator />}>
            <VariantTableV2
              {...commonProps}
              productVariants={productVariants!}
              setProductVariants={setProductVariants}
              customPagination={customPagination!}
              loadingVariants={loadingVariants}
              setSkuForSearch={setSkuForSearch}
              sortByHeader={sortByHeader}
              setSortByHeader={setSortByHeader}
              vendorAttributes={vendorAttributes!}
              publishedSKUList={publishedSKUList}
              reloadVendorAttributes={reloadVendorAttributes}
              currencies={currencies}
              role={user?.role}
              selectedVariants={selectedVariants}
              setSelectedVariants={setSelectedVariants}
              importExcelButton={importExcelButton}
              setHasVariantRemoved={setHasVariantRemoved}
              countries={countries}
              allVariantsSelection={allVariantsSelection}
              setAllVariantsSelection={setAllVariantsSelection}
              skuForSearch={skuForSearch}
              warehouseMode
              columnSettingsData={columnSettings}
              updateColumnSettings={updateColumnSettingsFn}
            />
          </Suspense>
          );
      default:
        return null;
    }
  };

  if (productDetails && currentVendor) {
    routeView = renderView(view);
  }

  if (draftReq.data && view === TEXT_LABELS.NEW_PRODUCT && currentVendor) {
    routeView = renderView(view);
  }


  const detailsUrl = productId
    ? `${ROUTES.PRODUCT_DETAIL}${productId}`
    : ROUTES.NEW_PRODUCT;

  const uploadVariantModal = (
    <UploadProductVariant
      show={showProductVariantModal}
      onClose={() => setShowProductVariantModal(false)}
      product={productDetails!}
      role={user?.role}
      variantsUploading={variantsUploading}
      setVariantsUploading={setVariantsUploading}
      loadProducts={updateLoadProduct}
      loadProductVariants={loadProductVariants}
      featureFlags={featureFlags}
      isValidating={isValidating}
      setIsValidating={setIsValidating}
    />
  );

  // warehouse tab error icon will display on these conditions
  let warehouseErrorCount = 0;
  const hasVariantsHavePurchaseType = productDetails?.variants.some((variant) => variant.purchase );
  const hasVariantsHaveRentType = productDetails?.variants.some((variant) => variant.rent );
  const purchaseWarehouseAdded = productDetails?.warehouses.some((warehouse) => warehouse.type === TEXT_LABELS.PURCHASE_C );
  const rentWarehouseAdded = productDetails?.warehouses.some((warehouse) => warehouse.type === TEXT_LABELS.RENT_C );
  if((hasVariantsHavePurchaseType &&  hasVariantsHaveRentType) && (!purchaseWarehouseAdded && !rentWarehouseAdded)){
    warehouseErrorCount = 2
  }else if(hasVariantsHavePurchaseType && !purchaseWarehouseAdded){
    warehouseErrorCount = 1
  }else if(hasVariantsHaveRentType && !rentWarehouseAdded){
    warehouseErrorCount = 1
  }

  return (
    <Page>
      <FlexboxContainer
        justifyContent="center"
        width="100%"
        alignItems="center"
      >
        <FlexboxContainerComponent width="100%" flexDirection="column">
          <FlexboxContainerComponent
            width="100%"
            margin="30px 0 0 0"
            flexDirection="row"
            justifyContent="space-between"
          >
            <TabsBarComponent
              height="auto"
              tabs={productDetailsTab(detailsUrl, productDetails, isWarehousesEdited, saveDisabled)}
              activeTab={activeTabs}
              theme="brandDark"
              borderRadius="20px"
              margin="0 24px 8px 0"
              tabPanelPadding="0 !important"
              canGoToNextTab={validateDataForVariantTable}
              variantErrorsCount={variantErrorsCount}
              warehouseErrorCount={warehouseErrorCount}
              loading={loading}
            ></TabsBarComponent>
            <div className='tabs-border'></div>
            <FlexboxContainerComponent flexDirection="row">
              <HeaderButtonComponent
                loading={loading}
                setLoading={setLoading}
                detailsUrl={detailsUrl}
                saveDisabled={saveDisabled}
                setSaveDisabled={setSaveDisabled}
                productDetails={productDetails}
                currentVendor={currentVendor}
                token={token}
                currencies={currencies}
                variantErrorsCount={variantErrorsCount}
                productErrorsCount={productErrorsCount}
                warehouseLength={productDetails?.warehouses.length}
                setIsSubmitModalOpen={setIsSubmitModalOpen}
                setValidation={setValidation}
                view={view}
                keepWarehouse={keepWarehouse}
                setKeepWarehouse={setKeepWarehouse}
                loadProducts={updateLoadProduct}
                disabled={productDetails?.status !== TEXT_LABELS.DRAFT}
                setIsErrorHighlight={setIsErrorHighlight}
              />
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
          {loading ? <LoadingIndicator /> : routeView}
        </FlexboxContainerComponent>
      </FlexboxContainer>
      {showProductVariantModal && uploadVariantModal}
      <SubmitModal
        setIsSubmitModalOpen={setIsSubmitModalOpen}
        isSubmitModalOpen={isSubmitModalOpen}
        productDetails={productDetails}
        currentVendor={currentVendor}
        previousLibraryPath={previousLibraryPath}
        token={token}
        currencies={currencies}
        user={user}
        socket={socket}
      />
       <KeepOrDiscardModal
        isSubmitModalOpen={warehouseConfirmationModalOpen}
        keep={keepWarehouses}
        discard={discardWarehouses}
      />
    </Page>
  );
}

ProductDetailsNewDesign.defaultProps = {
  view: TEXT_LABELS.DETAILS,
};
