import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Warehouse from 'data/Warehouse';
import { latoFont } from 'OnboardingComponents/Elements/Typography';
import { Color, FontWeight, Percent } from 'lib/styles';
import { Background, Main } from 'styles/themeColors';

const Container = styled.div`
  flex: 20%;
  background-color: ${Background.white};
  padding: 10px;
  flex-direction: column;
  border-right: 1px solid ${Color.veryLightGray};;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`;

const NameSlab = styled.div<any>`
  margin: 0 5px;
  ${latoFont}
  font-size: 18px;
  font-weight: ${FontWeight.bold};
  line-height: 1.71;
  text-align: left;
  color: ${Color.black};
  word-break: break-all;

  &:hover{
    cursor: pointer;
  }

  &:active {
    -webkit-box-shadow: inset 0px 0px 10px ${Color.darkGray};
  }

  ${({ active }) => active && `
    color: ${Main.brandLight};
  `}

  ${({ hidden }) => hidden && `
    display: none;
  `}
`;

const SubList = styled.ul<any>`
  font-size: 10pt;
  width: ${Percent.full};
  list-style-type: none;

  ${({ display }) => display && `
    display: ${display};
  `}
`;

const ListItem = styled.li<any>`
  font-size: 10pt;
  width: ${Percent.full};
  list-style-type: none;

  ${({ active }) => active && `
    color: ${Main.brandLight};
  `}

  &:hover{
    cursor: pointer;
  }

  &:active {
    -webkit-box-shadow: inset 0px 0px 10px ${Color.darkGray};
  }
`;

const ItemContainer = styled.div`
  margin: 0 0 10px 0;
`;

interface Props {
  warehouses: Warehouse[];
  selectedWarehouseId: number;
  selectedWarehouseOption: number | null;
  setSelectedWarehouse: (id: number) => void;
  setSelectedWarehouseOption: (id: number | null) => void;
  addingWarehouse: boolean;
};

const WarehouseSidebar = ({
  warehouses,
  selectedWarehouseId,
  selectedWarehouseOption,
  setSelectedWarehouse,
  setSelectedWarehouseOption,
  addingWarehouse,
}: Props) => {
  const [hidden, setHidden] = useState<boolean>(addingWarehouse);

  useEffect(() => {
    setHidden(addingWarehouse)
  }, [addingWarehouse]);

  const onWarehouseClick = (el: Warehouse) => {
    setSelectedWarehouse(el.id);
    setSelectedWarehouseOption(null);
  };

  let whNames = null;

  if (warehouses.length > 0) {
    whNames = warehouses.map((el, i) => (
      <ItemContainer key={i}>
        <NameSlab hidden={hidden} onClick={() => onWarehouseClick(el)} active={el.id === selectedWarehouseId}>
          {el.name}
        </NameSlab>
        <SubList display={el.id === selectedWarehouseId && !hidden && el.pickup ? 'block' : 'none' }>
          <ListItem onClick={() => setSelectedWarehouseOption(0)} active={selectedWarehouseOption === 0}>Pick-up locations</ListItem>
          {/* <ListItem onClick={() => setSelectedWarehouseOption(1)} active={selectedWarehouseOption === 1}>Shipping tables</ListItem> */}
        </SubList>
      </ItemContainer>
    ));
  }

  return (
    <Container>
      {addingWarehouse ? null : whNames}
    </Container>
  )
};

export default WarehouseSidebar;
