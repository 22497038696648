import React from 'react';
import Spinner from 'components/UI/Spinner';
import styled from 'styled-components';
import { Color } from 'lib/styles';
import { Main } from 'styles/themeColors';

const Container = styled.div<any>`
  color: ${({ onClick }) => (onClick ? Main.accent : Color.black)};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'normal')};
`;

const Label = styled.label<any>`
  cursor: inherit;
  display: block;
  font-size: 0.8em;
  white-space: nowrap;
  font-weight: ${({ heavy }) => (heavy ? '700' : '400')}
  line-height: 2.4;
`;

const Value = styled.span<any>`
  display: block;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: 700;
  line-height: 1;
`;

interface Props {
  children: React.ReactNode;
  className?: string;
  label: React.ReactNode;
  large: boolean;
  loading?: boolean;
  onClick?: () => void;
};

export default function Slab({
  children,
  className,
  label,
  large,
  loading,
  onClick
}: Props) {
  const spinnerSize = large ? 40 : 16;
  const value = loading ? <Spinner size={spinnerSize} /> : children;

  return (
    <Container className={className} onClick={onClick}>
      <Value fontSize={large ? '3em' : '1.6em'}>{value}</Value>
      <Label heavy={large ? 1 : 0}>{label}</Label>
    </Container>
  );
};

Slab.defaultProps = {
  large: false
};
