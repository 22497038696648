import * as Arr from 'lib/Array';
import VideoAssetCard, { VideoAsset } from './VideoAssetCard';
import AssetCardPlaceholder from './AssetCardPlaceholder';
import React from 'react';
import styled from 'styled-components';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import AssetCardV2 from './AssetCardV2';

const Container = styled.div``;

const DroppableContainer = styled.div``;

interface Props {
    assets: VideoAsset[];
    className?: string;
    editableName?: boolean;
    listId: string;
    onDelete?: (videoId: number) => void;
    onUpdate: (assets: VideoAsset[]) => void;
    placeholderIcon: React.ReactNode;
    renderThumbnail?: (asset: VideoAsset) => React.ReactNode;
    disabled?: boolean;
    productRedesignFlag?: boolean;
    setMediaId?: (id: number) => void;
}

export default function VideoAssetCardList({
    assets,
    className,
    editableName,
    listId,
    onDelete,
    onUpdate,
    placeholderIcon,
    renderThumbnail,
    disabled,
    productRedesignFlag = false,
    setMediaId
}: Props) {
    function deleteAsset(index: number) {
        const assetId = assets[index].id;

        if (onDelete) {
          onDelete(assetId);
    
          if(productRedesignFlag){
            setMediaId && setMediaId(assetId)
          }else{
            onUpdate(Arr.removeAtIndex(assets, index));
          }
        }
    }

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        onUpdate(Arr.move(assets, result.source.index, result.destination.index));
    }

    function onNameChange(index: number, newName: string) {
        const newAssets = Arr.update(assets, index, asset => {
            return { ...asset, name: newName };
        });
        onUpdate(newAssets);
    }

    const assetElements = assets.map((asset, index) => {
        return (
            productRedesignFlag ? 
            <AssetCardV2
                asset={asset}
                index={index}
                editableName={editableName}
                key={asset.id}
                onDelete={deleteAsset}
                onNameChange={newName => onNameChange(index, newName)}
                renderThumbnail={renderThumbnail as any}
                disabled={disabled}
            />
            :
            <VideoAssetCard
                asset={asset}
                index={index}
                editableName={editableName}
                key={asset.id}
                onDelete={deleteAsset}
                onNameChange={newName => onNameChange(index, newName)}
                renderThumbnail={renderThumbnail}
                disabled={disabled}
            />
        );
    });

    let content = productRedesignFlag ? null : <AssetCardPlaceholder icon={placeholderIcon} />;

    if (assetElements.length > 0) {
        content = (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={listId} isDropDisabled={disabled}>
                    {provided => (
                        <DroppableContainer
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {assetElements}
                            {provided.placeholder}
                        </DroppableContainer>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    return <Container className={className}>{content}</Container>;
}

VideoAssetCardList.defaultProps = {
    editableName: false
};
