import React from 'react';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Icon from 'view/Icon';
import { PRODUCT_VARIANT_TYPE, XLS_TEXT_CONSTANTS } from 'constants/productVariantImport';
import { WAREHOUSE_TYPE } from 'constants/warehouseDeliveryOptions';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

const ButtonIcon = styled(Icon).attrs({ size: 16 })`
  color: #666c73;
`;
const DownloadTemplate = styled.div`
  display: flex;
  height: 36px;
  background-color: transparent !important;
  color: #666c73 !important;
  border-color: #666c73 !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;

const DownloadXLS = ({ product, role, rentFlag }) => {
  const getVariantData = (product, role) => {
    let variant = {
          SKU: XLS_TEXT_CONSTANTS.SKU,
          'Vendor Part Number': XLS_TEXT_CONSTANTS.VENDOR_PART_NUMBER,
          Purchase:XLS_TEXT_CONSTANTS.PURCHASE,
          ...(rentFlag && { 'Rent': XLS_TEXT_CONSTANTS.RENT }), // Conditionally add Rent property
          'Item Type': XLS_TEXT_CONSTANTS.ITEM_TYPE,
          Hidden: XLS_TEXT_CONSTANTS.HIDDEN,
          Weight: XLS_TEXT_CONSTANTS.WEIGHT,
          [XLS_TEXT_CONSTANTS.MATERIAL_NUMBER_KEY]: XLS_TEXT_CONSTANTS.MATERIAL_NUMBER
        };

    return variant;
  };

  const getWarehouseData = (product) => {
    const warehouseColsObj = {};
    const warehouseCurrencyObj = {};

    product.warehouses.forEach((warehouse) => {
      if ((rentFlag && warehouse?.type === WAREHOUSE_TYPE.RENT) || warehouse?.type === WAREHOUSE_TYPE.PURCHASE) {
        warehouseColsObj[`In stock "${warehouse.code} ${warehouse.name} ${warehouse.type}" warehouse`] = XLS_TEXT_CONSTANTS.IN_STOCK;
        warehouseColsObj[`Lead days "${warehouse.code} ${warehouse.name} ${warehouse.type}" warehouse`] = XLS_TEXT_CONSTANTS.LEAD_DAYS;

        if (product.postOrderShippingCost && warehouse.delivery) {
          warehouseColsObj[`Post-order shipping cost "${warehouse.code} ${warehouse.name} ${warehouse.type}" warehouse`] = XLS_TEXT_CONSTANTS.POST_ORDER_SHIPPING_COST;
        }
      }
      if (warehouse?.type === WAREHOUSE_TYPE.PURCHASE) {
        if (warehouse?.address?.country?.countryName === XLS_TEXT_CONSTANTS.UNITED_STATE) {
          warehouseCurrencyObj[XLS_TEXT_CONSTANTS.USA_DOLLAR] = XLS_TEXT_CONSTANTS.USA_PRICE;
        } else if (warehouse?.address?.country?.countryName === XLS_TEXT_CONSTANTS.CANADA) {
          warehouseCurrencyObj[XLS_TEXT_CONSTANTS.CANADA_CAD] = XLS_TEXT_CONSTANTS.CANADA_PRICE;
        }
      }
    });

    return { warehouseColsObj, warehouseCurrencyObj };
  };

  const getVariantAttributes = () => {
    return {
      [XLS_TEXT_CONSTANTS.SELECTABLE_ATTRIBUTE_COLUMN_KEY]: XLS_TEXT_CONSTANTS.SELECTABLE_ATTRIBUTE,
      [XLS_TEXT_CONSTANTS.TECHNICAL_ATTRIBUTE_COLUMN_KEY]: XLS_TEXT_CONSTANTS.TECHNICAL_SPECIFICATION,
    };
  };

  const getVariantPriceRanges = (product) => {
    const priceRangesColObj = {};
    product.warehouses.forEach(warehouse => {
      if (rentFlag && warehouse?.address?.country?.countryName === XLS_TEXT_CONSTANTS.UNITED_STATE && warehouse.type === WAREHOUSE_TYPE.RENT) {
        for (let i = 1; i <= 5; i++) {
          priceRangesColObj[`Price Range ${i} (US) [Range]`] = XLS_TEXT_CONSTANTS.PRICE_RANGE;
          priceRangesColObj[`Price Range ${i} (US) [Range Units]`] = XLS_TEXT_CONSTANTS.PRICE_RANGE_UNIT;
          priceRangesColObj[`Price Range ${i} (US) [Range price]`] = XLS_TEXT_CONSTANTS.PRICE_RANGE_PRICE;
        }
      } else if (rentFlag && warehouse?.address?.country?.countryName === XLS_TEXT_CONSTANTS.CANADA && warehouse.type === WAREHOUSE_TYPE.RENT) {
        for (let i = 1; i <= 5; i++) {
          priceRangesColObj[`Price Range ${i} (CA) [Range]`] = XLS_TEXT_CONSTANTS.PRICE_RANGE;
          priceRangesColObj[`Price Range ${i} (CA) [Range Units]`] = XLS_TEXT_CONSTANTS.PRICE_RANGE_UNIT;
          priceRangesColObj[`Price Range ${i} (CA) [Range price]`] = XLS_TEXT_CONSTANTS.PRICE_RANGE_PRICE;
        }
      }
    });
    return priceRangesColObj;
  }

  const createWorksheet = (workbook, data, columns) => {
    const worksheet = workbook.addWorksheet('Variants');
  
    // Set worksheet columns
    worksheet.columns = columns.map((col) => ({
      header: col.title,
      key: col.title,
      width: col.width.wch,
    }));
  
    // Add a single data row to initialize headers
    worksheet.addRow(data[0]);

    // Apply styles to the header row (row 1)
    const headerRow = worksheet.getRow(1);
    headerRow.eachCell((cell) => {
      // Apply background color to headers
      const cellConfig = columns.find(x => x.title === cell.value);
      
      cell.fill = cellConfig.style.fill;
      cell.alignment = cellConfig.style.alignment;
      cell.font = cellConfig.style.font;
    });

    // Style the rest of the rows (data rows)
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) { // Skip the header row
        row.eachCell((cell) => {
          cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
          cell.font = { size: 10 }; // Regular text
        });
      }
    });
  };

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const variant = getVariantData(product, role);
    const { warehouseColsObj, warehouseCurrencyObj } = getWarehouseData(product);
    const variantAttributes = getVariantAttributes();
    const variantPriceRanges = getVariantPriceRanges(product);

    const columnsData = [
      {
        ...variant,
        ...warehouseColsObj,
        ...warehouseCurrencyObj,
        ...variantAttributes,
        ...variantPriceRanges
      },
    ];

    const columns = Object.keys(columnsData[0]).map(key => {
      let fgColor;
      if (key === PRODUCT_VARIANT_TYPE.PURCHASE || key === PRODUCT_VARIANT_TYPE.RENT) {
        fgColor = 'b3ffb3';
      } else if (key in variant) {
        fgColor = 'b3ecff';
      } else if (key in warehouseColsObj) {
        fgColor = 'f4ca16';
      } else if (key === XLS_TEXT_CONSTANTS.CANADA_CAD) {
        fgColor = 'b3ffb3';
      } else if (key in warehouseCurrencyObj) {
        fgColor = 'f4ca16';
      } else {
        fgColor = 'b3ffb3';
      }

      return {
        title: key,
        width: { wch: 50 },
        style: {
          fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: fgColor } },
          font: { size: 10 },
          alignment: { horizontal: 'center', vertical: 'bottom', wrapText: true },
        },
      };
    });

    createWorksheet(workbook, columnsData, columns);

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${product.name || XLS_TEXT_CONSTANTS.OFMP_TEMPLATE}.xlsx`);
  };

  return (
    <div>
      <DownloadTemplate onClick={handleDownload}>
        <FlexboxContainerComponent>
          <ButtonIcon name="FileDownloadIcon" />
          <FlexboxContainerComponent>
            {XLS_TEXT_CONSTANTS.DOWNLOAD_XLXS}
          </FlexboxContainerComponent>
        </FlexboxContainerComponent>
      </DownloadTemplate>
    </div>
  );
};

export default DownloadXLS;