import React, { useState } from 'react';
import {
    Grid,
    createStyles,
    makeStyles,
} from '@material-ui/core';

import Select from '../UI/Select';
import Vendor from '../../data/Vendor';
import { getItems } from '../../lib/SessionStorage';
import { Background } from '../../styles/themeColors';
import { Column } from 'material-table';
import CampaignLocalization from './CampaignLocalization';
import styled from 'styled-components';
import { DatePicker } from '@material-ui/pickers';
import DatePickerInput from 'components/UI/DatePickerInput';
import { getVendorValue } from 'utils/campaign';
import LaunchCompany from 'data/LaunchCompany';
import CampaignType from 'data/CampaignType';
import { useAuth } from 'lib/Auth';

const useStyles = makeStyles(() =>
    createStyles({

        selected: {
            borderRadius: '4px',
            border: '1px solid #F57003',
            background: '#FFF',
        },
        filterBackground: {
            padding: '10px 20px',
            background: Background.shaded,
        },
        gridSpacing: {
            padding: '0 20px',
            marginTop: '24px',
        },
        columnSettings: {
            alignContent: 'center',
            justifyContent: 'flex-end',
            display: 'grid'
        },
        tableMargin: {
            marginTop: '24px'
        },
    })
);


const LabelText = styled.div`
    color: #0E141A;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
    margin-right: 0px;
    margin-top: 0px;
`;

interface Props {
    selectedVendorId?: number;
    vendorsList: Vendor[];
    filterUpdate: (filters: CampaignListFilter) => void;
    campaignColumns: Column<any>[];
    loading: boolean;
    launchCompanies: LaunchCompany[];
    campaignTypes: CampaignType[];
}

interface SelectedOption {
    value: string;
    id?: string;
}

export interface CampaignListFilter {
    launchCompanies: SelectedOption[];
    campaignTypes: SelectedOption[];
    vendor: SelectedOption[];
    country: SelectedOption;
    startDate?: string | null;
    endDate?: string | null;
}




const CampaignListingFilters = ({ selectedVendorId, vendorsList, filterUpdate, loading, launchCompanies, campaignTypes }: Props) => {

    const classes = useStyles();
    
    const [selectedFilters, setSelectedFilters] = useState<CampaignListFilter>({
        launchCompanies: [],
        campaignTypes: [],
        vendor: [],
        country: { value: CampaignLocalization.listing.filter.countrySelect },
        startDate: null,
        endDate: null
    });

    const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
    const [selectedCampaigns, setSelectedCampaigns] = useState<string[]>([]);
    const [selectedVendors, setSelectedVendors] = useState<string[]>([]);
    const isMounted = React.useRef(true);
    // fetching data from session storage
    const countries = getItems('countries');

    React.useEffect(() => {
        isMounted.current = true;
        return () => {
            isMounted.current = false;
        };
    }, []);


    React.useEffect(() => {
        filterUpdate(selectedFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters]);


    React.useEffect(() => {
        const vendorValue = getVendorValue(vendorsList, selectedVendorId!);
    
        setSelectedVendors(selectedVendorId ? [vendorValue!] : []);
    
        // Reset the selected filters when the vendor is changed
        setSelectedFilters({
            launchCompanies: [],
            campaignTypes: [],
            vendor: selectedVendorId ? [{ value: vendorValue!, id: selectedVendorId.toString() }] : [],
            country: { value: CampaignLocalization.listing.filter.countrySelect },
            startDate: null,
            endDate: null
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVendorId]);  // eslint-disable-next-line react-hooks/exhaustive-deps

    return (
        <>

            <Grid item xs={12} sm={12} md={12}>
                <Grid container spacing={1} className={classes.filterBackground}>

                    {vendorsList && <Grid item xs={12} sm={6} md={3}>
                        <Select
                            label="Vendor"
                            labelPosition="top"
                            disabled={(!!selectedVendorId) || loading}
                            options={vendorsList.map((vendor: Vendor) => ({
                                value: vendor.name,
                                id: vendor.id + '',
                            }))}
                            onChange={(event) => {
                                const selVendors = event.target.value; // array of selection
                                const selectedItem = event.target.selected;
                                setSelectedVendors(selVendors);

                                if (selVendors.indexOf(selectedItem) > -1) {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        vendor: [
                                            ...selectedFilters.vendor,
                                            { value: selectedItem, id: event.target.id },
                                        ],
                                    });
                                } else {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        vendor: selectedFilters.vendor.filter(
                                            (vd) => vd.value !== selectedItem
                                        ),
                                    });
                                }
                            }}
                            value={selectedVendors}
                            defaultValue={CampaignLocalization.listing.filter.vendorSelect}
                            width="100%"
                            multiple
                            limitTags={1}
                        />
                    </Grid>}


                    <Grid item xs={12} sm={6} md={3}>
                        <Select
                            label="Country"
                            disabled={loading}
                            labelPosition="top"
                            options={countries.map((country: any) => ({
                                value: country.countryName,
                                id: country.countryIso,
                            }))}
                            onChange={(event) => {
                                setSelectedFilters({
                                    ...selectedFilters,
                                    country: { value: event.target.value, id: event.target.id },
                                });
                            }}
                            value={selectedFilters.country.value}
                            defaultValue={CampaignLocalization.listing.filter.countrySelect}
                            width="100%"
                            displayEmpty={CampaignLocalization.listing.filter.countrySelect}
                        />
                    </Grid>


                    <Grid item xs={12} sm={6} md={3}>
                        <Select
                            label="Launch Company"
                            disabled={loading}
                            labelPosition="top"
                            options={
                                launchCompanies.map((launchCompany: LaunchCompany) => ({
                                    value: launchCompany.companyName,
                                    id: launchCompany.id + '',
                                }))
                            }
                            onChange={(event) => {
                                const selCompanies = event.target.value; // array of selection
                                const selectedItem = event.target.selected;
                                setSelectedCompanies(selCompanies);

                                if (selCompanies.indexOf(selectedItem) > -1) {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        launchCompanies: [
                                            ...selectedFilters.launchCompanies,
                                            { value: selectedItem, id: event.target.id },
                                        ],
                                    });
                                } else {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        launchCompanies: selectedFilters.launchCompanies.filter(
                                            (launchCompany) => launchCompany.value !== selectedItem
                                        ),
                                    });
                                }
                            }}
                            value={selectedCompanies}
                            defaultValue={CampaignLocalization.listing.filter.companySelect}
                            width="100%"
                            multiple={true}
                            limitTags={1}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <Select
                            label="Campaign Type"
                            disabled={loading}
                            labelPosition="top"
                            options={
                                campaignTypes.map((campaignType: CampaignType) => ({
                                    value: campaignType.name,
                                    id: campaignType.id + '',
                                }))
                            }
                            onChange={(event) => {
                                const selCampaigns = event.target.value; // array of selection
                                const selectedItem = event.target.selected;
                                setSelectedCampaigns(selCampaigns);

                                if (selCampaigns.indexOf(selectedItem) > -1) {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        campaignTypes: [
                                            ...selectedFilters.campaignTypes,
                                            { value: selectedItem, id: event.target.id },
                                        ],
                                    });
                                } else {
                                    setSelectedFilters({
                                        ...selectedFilters,
                                        campaignTypes: selectedFilters.campaignTypes.filter(
                                            (campaignType) => campaignType.value !== selectedItem
                                        ),
                                    });
                                }
                            }}
                            value={selectedCampaigns}
                            defaultValue={CampaignLocalization.listing.filter.campaignTypeSelect}
                            width="100%"
                            multiple={true}
                            limitTags={1}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <LabelText>{CampaignLocalization.listing.filter.startDate}</LabelText>
                        <DatePicker
                            value={selectedFilters.startDate}
                            inputVariant="outlined"
                            format="yyyy MMM dd"
                            TextFieldComponent={DatePickerInput}
                            onChange={(value:any) => {
                                setSelectedFilters({
                                    ...selectedFilters,
                                    startDate: new Date(value)?.toUTCString(),
                                });
                            }}
                            autoOk
                            placeholder='Select date'
                            error={false}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={3}
                    >
                        <LabelText>{CampaignLocalization.listing.filter.endDate}</LabelText>
                        <DatePicker
                            value={selectedFilters.endDate}
                            inputVariant="outlined"
                            format="yyyy MMM dd"
                            TextFieldComponent={DatePickerInput}
                            onChange={(value:any) => {
                                setSelectedFilters({
                                    ...selectedFilters,
                                    endDate: new Date(value)?.toUTCString(),
                                });
                            }}
                            autoOk
                            placeholder='Select date'
                            error={false}

                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default React.memo(CampaignListingFilters);
