export default interface PosAddFormValidator {
    street: boolean;
    city: boolean;
    state: boolean;
    zip: boolean;
    name: boolean;
    nickname: boolean;
    posId: boolean;
}

export const empty: PosAddFormValidator = {
    street: false,
    city: false,
    state: false,
    zip: false,
    name: false,
    nickname: false,
    posId: false,
}