import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  VariantError,
  VariantErrorType,
  MissingValueVariantError
} from 'data/ProductValidation';
import VariantIssueOption from 'common/VariantIssueOption';
import Icon from 'view/Icon';
import Popover from 'components/UI/Popover';
import { Size } from 'lib/styles';
import { fieldName } from 'utils/variant';
import { Main } from 'styles/themeColors';

const Container = styled.div<any>`
  margin-right: ${Size.large};
  max-width: 20rem;
  outline: none;
  position: relative;
  user-select: none;

  ${props => !props.visible && css`
    visibility: hidden;
  `}
`;

const Description = styled.div`
  font-size: 0.8rem;
`;

const Heading = styled.div`
  align-items: center;
  color: ${Main.accent};
  display: flex;
  font-size: 1.5rem;
  font-weight: 700;
`;

const HeadingIcon = styled(Icon).attrs({ name: 'CaretDropdown', size: 10 })`
  margin-left: ${Size.medium};
  margin-top: ${Size.tiny};
`;

const HeadingText = styled.div``;

const Selected = styled.div`
  cursor: pointer;
`;

const StyledPopover = styled<any>(Popover)`
  min-width: 100%;
  width: auto;
`;

function getErrorText(error: VariantError) {
  switch (error.type) {
    case VariantErrorType.DuplicateVariant:
      const skus = error.variants.map(variant => variant.sku).join(', ');
      return {
        name: 'Duplicate variant',
        description: `Variants with SKUs ${skus} have duplicate selectable attributes`,
        error
      };
    case VariantErrorType.MissingValue:
      return {
        name: 'Missing value',
        description: `Value missing for ${fieldName(
          (error as MissingValueVariantError).key
        )}`,
        error
      };
    case VariantErrorType.DuplicateUniqueValue:
      return {
        name: 'Duplicate value',
        description: `SKU ${error.variants[0].sku} is used with multiple variants`,
        error
      };
    case VariantErrorType.InvalidSKUValue:
      return {
        name: 'Invalid value for SKU',
        description: `SKU ${error.variants[0].sku} should only contain letters, numbers, slashes(/), dashes(-) or underscores(_)`,
        error
      };
    case VariantErrorType.InvalidSKULength:
      return {
        name: 'Invalid length for SKU',
        description: `SKU ${error.variants[0].sku} should be less than ${process.env.REACT_APP_SKU_MAX_LENGTH!} characters long`,
        error
      };
    case VariantErrorType.SKUValidation:
      return {
        name: 'SKU validation failed',
        description:  `SKU ${error.variants[0].sku}: ${error.variants[0].errorMessage || 'Please re-validate the SKU'}` || '',
        error
      }
    case VariantErrorType.EmptyAttributeValue:
      return {
        name: 'Attribute value is empty',
        description: `Attributes Value can not be empty`,
        error
      };
    case VariantErrorType.InvalidMaterialNumber:
      return {
        name: 'Invalid value for material number',
        description: `Material number ${error.variants[0].materialNumber} should only contain letters, numbers, slashes(/), dashes(-) or underscores(_)`,
        error
      }
    case VariantErrorType.MaterialNumberValidation:
      return {
        name: 'Material number validation failed',
        description:  `Material number ${error.variants[0].materialNumber}: ${error.variants[0].errorMessage || 'Please re-validate the material number'}` || '',
        error
      }
    case VariantErrorType.InvalidMaterialNumberLength:
      return {
        name: 'Invalid value for material number',
        description: `Material number ${error.variants[0].materialNumber} should be less than ${process.env.REACT_APP_SKU_MAX_LENGTH!} characters long`,
        error
      }
    case VariantErrorType.DuplicateMaterialNumber:
      return {
        name: 'Duplicate value',
        description: `Material number ${error.variants[0].materialNumber} is used with multiple variants`,
        error
      };
    case VariantErrorType.EmptyMaterialNumber:
      return {
        name: 'Material number value is empty',
        description: `Material number value can not be empty`,
        error
      };  
    case VariantErrorType.EmptySku:
      return {
        name: 'Empty SKU',
        description: `Sku can not be empty in variant`,
        error
      };
    case VariantErrorType.RentMissingPriceCA:
      return {
        name: 'Invalid CA rental data',
        description: 'Price is mandatory for first record',
        error
      }
    case VariantErrorType.RentMissingPriceUS:
      return {
        name: 'Invalid US rental data',
        description: 'Price is mandatory for first record',
        error
      }
    case VariantErrorType.RentMissingRangeCA:
      return {
        name: 'Invalid CA rental data',
        description: 'Range is mandatory for first record',
        error
      }
    case VariantErrorType.RentMissingRangeUS:
      return {
        name: 'Invalid US rental data',
        description: 'Range is mandatory for first record',
        error
      }
    case VariantErrorType.RentRangeSkipped:
      return {
        name: 'Skipped Rental Range',
        description: 'Price ranges must be populated in order',
        error
      }  
    case VariantErrorType.RentRangeOrder:
      return {
        name: 'Invalid Rental Range Order',
        description: 'Rental ranges should be in ascending order',
        error
      }  
    case VariantErrorType.RequiredRentalRange:
      return {
        name: 'Rental price range required',
        description: 'Rental variants must have at least one price range set',
        error
      }  
    case VariantErrorType.EmptyConsignmentType:
      return {
        name: 'Consignment type required',
        description: 'Either purchase or rent should be selected',
        error
      }  
    default:
      return { name: '', description: '', error };
  }
};

interface Props {
  className?: string;
  errors: VariantError[];
  onSelect: (error: VariantError[]) => void;
  visible: boolean;
  isSlbVendor?: boolean;
  productRedesignFlag?: boolean;
};

export default function VariantIssueSelector({
  className,
  errors,
  onSelect,
  visible,
  isSlbVendor = false,
  productRedesignFlag = false
}: Props) {
  const [open, setOpen] = useState(false);

  let selectedText = {
    name: `${errors.length} issue${errors.length > 1 ? 's' : ''}`,
    description: 'Click to resolve issues'
  };

  // if (value) {
    // selectedText = getErrorText(value);
  // }

  const selected = (
    <Selected>
      <Heading>
        <HeadingText className='variant-issue-selector-heading-text'>{selectedText.name}</HeadingText>
        <HeadingIcon className='heading-icon-black' />
      </Heading>
      <Description
       onClick={() => {
        onSelect(errors);
      }}
      className='variant-issue-selector-description'
      >{selectedText.description}</Description>
    </Selected>
  );

  let popover = null;

  if (open) {
    const formattedErrors = errors.map((index) => {return getErrorText(index)});
    popover = (
      <StyledPopover
        maxHeight={500}
        options={formattedErrors}
        renderOption={(error: typeof formattedErrors[0]) => (
          <VariantIssueOption error={error} productRedesignFlag={productRedesignFlag} />
        )}
      />
    );
  }

  return (
    <Container
      className={className}
      onBlur={() => setOpen(false)}
      onClick={() => setOpen(!open)}
      tabIndex={0}
      visible={visible}
    >
      {selected}
      {popover}
    </Container>
  );
};

VariantIssueSelector.defaultProps = {};
