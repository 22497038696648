import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Text } from 'styles/themeColors';
import { MainColors } from 'types/enumTypes';

const Label = styled.label<any>`
  font-size: 16px;
  color: ${Text.primary};
`;

const Label2 = styled.label<any>`
  font-size: 16px;
  color: ${Text.primary};
  margin-top: 24px;
  margin-bottom: 8px;
`;

export interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: (value: string) => void;
  title: string;
  label2?: string;
  label?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  theme?: MainColors;
  borderColor?: string;
  color?: string;
};

const OrderCancelModal = ({
  show,
  onClose,
  onConfirm,
  title,
  label,
  label2,
  confirmButtonText,
  cancelButtonText,
  theme,
  borderColor,
  color
}: Props) => {
  const [cancellationReason, setCancellationReason] = useState<string>('');

  return (
    <Modal
    theme={theme}
    borderColor={borderColor}
    color={color}
    showButtons
    showModal={show}
    title={title}
    onClose={onClose}
    onClick={() => onConfirm(cancellationReason)}
    disabled={!cancellationReason}
    ghostButtonText={cancelButtonText}
    primaryButtonText={confirmButtonText}
    >
        <FlexboxContainer flexDirection="column">
          <Label>{label}</Label>
          <Label2>{label2}</Label2>
          <textarea rows={5} value={cancellationReason} onChange={(event: any) => setCancellationReason(event.target.value)} />
        </FlexboxContainer>
    </Modal>
  );
};

export default OrderCancelModal;
