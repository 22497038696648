import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { useAPI } from 'lib/API';
import { Check } from '@material-ui/icons';
import { Height } from 'styles/themeHeights';
import readExcelFile from 'read-excel-file';
import { Role } from 'data/User';
import { getProductById, uploadProductForUser } from 'requests/product';
import { SocketContext } from 'context/socket';
import UploadComponent from 'components/Product/UploadFileComponent';
import VerificationCompletionComponent from 'components/Product/VerificationCompletionComponent';
import FooterComponent from 'components/Product/FooterComponent';
import { TEXT_LABELS, STEPS, STATUS } from '../../constants/productVariantImport';
import { checkFileSize, checkFileType, filenameWithoutExtension } from 'components/Pricebooks/priceebook.utils';
import { verifyProductFileTemplate } from 'utils/product';
import Product from 'data/Product';
import { renderToast, updateToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import FeatureFlag from 'data/FeatureFlag';

const SubTitle = styled.h5<any>`
  margin: 0px !important;
  font-size: 16px;
  line-height: 16px;
`;

const StepNumber = styled.span<any>`
  font-size: 16px;
  line-height: 20px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  padding: 2px 7px;
  margin-bottom: 4px;
  background: ${({ $bg }) => ($bg ? '#F7991C' : '#bfc2c5')};
`;

export type UploadingStatus = {
  statusId: number,
  fileName: string,
  passedCount: number,
  failedCount: number,
  totalCount: number,
  createdOn: string,
  originalFileLocation?: string,
  validatedFileLocation?: string
}

interface Props {
  show: boolean;
  onClose: () => void;
  product: Product;
  role?: Role;
  variantsUploading: boolean;
  setVariantsUploading: (value: boolean) => void;
  loadProducts: () => void;
  loadProductVariants?: (resetPagination: boolean) => void;
  featureFlags: FeatureFlag;
  isValidating: boolean;
  setIsValidating: (value: boolean) => void;
}

const UploadProductVariant: React.FC<Props> = ({
  show,
  onClose,
  product,
  role,
  variantsUploading,
  setVariantsUploading,
  loadProducts,
  loadProductVariants,
  featureFlags,
  isValidating,
  setIsValidating
}) => {
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [uploadFileSizeError, setUploadFileSizeError] = useState(false);
  const [uploadFileTypeError, setUploadFileTypeError] = useState(false);
  const [uploadFileTemplateError, setUploadFileTemplateError] = useState(false);
  const [productData, setProductData] = useState<any>();
  const [step, setStep] = useState<number>(1);
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [uploadingStatus, setUploadingStatus] = useState<UploadingStatus>();
  const [productFile, setProductFile] = useState<string>();
  const [file, setFile] = useState<any>({
    imageFile: [],
    uploadDate: new Date(),
  });
  const inputRef = useRef<HTMLInputElement | null>(null);
  const socket = useContext(SocketContext);

  const handleClear = () => {
    if (inputRef.current != null) {
      inputRef.current.value = '';
    }
    setUploadFileSizeError(false);
    setUploadFileTypeError(false);
    setUploadFileTemplateError(false);
    setUploadSuccess(false);
    setFileUploading(false);
    setFile({
      imageFile: [],
      uploadDate: '',
    });
    setStep(1)
  };

  const handleUploadClick = () => {
    if (inputRef.current != null) {
      inputRef.current.value = '';
    }
    setUploadFileSizeError(false);
    setUploadFileTypeError(false);
    setUploadFileTemplateError(false);
    setUploadSuccess(false);
    setFileUploading(false);
    setFile({
      imageFile: [],
      uploadDate: '',
    });
    inputRef.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFile({
      imageFile: [e.target.files[0]],
      uploadDate: Date.now(),
    });
    if (!checkFileSize(e.target.files[0])) {
      setUploadFileSizeError(true);
      setFileUploading(false);
      return;
    } else if (checkFileType(e.target.files[0])) {
      setUploadFileTypeError(false);
    } else {
      setUploadFileTypeError(true);
      return;
    }
  };

  const productTemplateVerify = async () => {
    const productFile = file.imageFile[0];
    const productData = await readExcelFile(file.imageFile[0]);
    setProductData(productData);
    setProductFile(productFile.name);

    const templateVerification = await verifyProductFileTemplate(
      productFile,
      product,
      role,
      featureFlags?.rent?.enabled
    );
    if (templateVerification) {
      setFileUploading(false);
      setUploadFileTemplateError(false);
      setUploadSuccess(true);
    } else {
      setUploadFileTemplateError(true);
      setUploadSuccess(false);
      return;
    }
  };

  useEffect(() => {
    if (
      file.imageFile &&
      file.imageFile.length > 0 &&
      !uploadFileSizeError &&
      !uploadFileTypeError
    ) {
      productTemplateVerify();
    }
  }, [file.imageFile, uploadFileSizeError, uploadFileTypeError]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (uploadingStatus?.statusId === STATUS.PENDING)
        productById.run(product.id);
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  });

  const gotoNextStep = () => {
    setFileUploading(true);
    setIsValidating(true);
    uploadProductsVariant.run(
      productData,
      filenameWithoutExtension(productFile),
      product.id,
      file.uploadDate
    );
  };

  const productById = useAPI({
    deferFn: getProductById,
    onResolve: (result: any) => {
      if(!result){
        setStep(1)
        return
      }
      if(result.statusId === STATUS.PENDING){
        setVariantsUploading(true)
      }
      if (result.statusId === STATUS.COMPLETED) {
        setStep(3);
        setFileUploading(false);
        setVariantsUploading(false)
        isValidating && loadProducts();
        isValidating && loadProductVariants?.(true);
        setIsValidating(false);
      }
      setUploadingStatus(result);
    },
    onReject: (err: any) => {console.log('err:', err)},
  });

  useEffect(() => {
    if (product.id && productById) {
      setStep(2);
      productById.run(product.id);
    }
    socket.on(`product_${product?.id}`, (result: any) => {
      result.progressPercent === 100 && loadProducts()
      setProgressPercent(result.progressPercent);
    });
  }, []);

  const uploadProductsVariant = useAPI({
    deferFn: uploadProductForUser,
    onResolve: (result: any) => {
      setProductFile(result.blobName)
      productById.run(product.id);
      setStep(step + 1);
      setFileUploading(false);
    },
    onReject: (err: any) => {
      setStep(1);
      setUploadFileSizeError(false);
      setUploadFileTypeError(false);
      setUploadFileTemplateError(false);
      setUploadSuccess(false);
      setFile({
        imageFile: [],
        uploadDate: '',
      });  
      setFileUploading(false);
      renderToast(toast.TYPE.ERROR, err.message, {
        autoClose: 5000
      });
    }
  });

  const isStepTwoFileVerification =
    step !== 1 &&
    (!uploadingStatus || uploadingStatus?.statusId === STATUS.PENDING);
  const showNextButton =
    isStepTwoFileVerification || (uploadSuccess && step === STATUS.PENDING);
  const disableNextButton = isStepTwoFileVerification || fileUploading;

  return (
    <Modal
      width="825px"
      showModal={cancelModal ? false : show}
      onClose={() => {
        handleClear();
        onClose();
      }}
      showButtons={false}
      title={'Product variant upload'}
      modalFooterMargin="0"
      left="50vw"
      marginLeft="-412px"
      iconName={'PricebookModalIcon'}
      showCloseIcon
      top="15%"
    >
      <FlexboxContainerComponent
        bgColor="#F9F9FA"
        border="1px solid #E1E6EB"
        borderRadius="6px"
        padding="24px"
        flexDirection="column"
      >
        <FlexboxContainerComponent margin="0 auto" flexDirection="row">
          <FlexboxContainerComponent
            flexDirection="column"
            alignItems="center"
            width="24px"
          >
            <StepNumber $bg={step >= STEPS.UPLOADING}>
              {step === STEPS.UPLOADING ? (
                <Check
                  style={{
                    color: '#FFFFFF',
                    height: Height.xsPlus,
                    width: '12px',
                  }}
                />
              ) : (
                <>{STEPS.UPLOADING}</>
              )}
            </StepNumber>
            <SubTitle>{TEXT_LABELS.UPLOAD}</SubTitle>
          </FlexboxContainerComponent>
          <hr
            style={{
              width: '150px',
              color: '#C1C9D1',
              margin: '12px 4px',
              height: '1px',
            }}
          ></hr>
          <FlexboxContainerComponent
            flexDirection="column"
            alignItems="center"
            width="24px"
          >
            <StepNumber $bg={step >= STEPS.VERIFICATION}>
              {step === STEPS.VERIFICATION ? (
                <Check
                  style={{
                    color: '#FFFFFF',
                    height: Height.xsPlus,
                    width: '12px',
                  }}
                />
              ) : (
                <>{STEPS.VERIFICATION}</>
              )}
            </StepNumber>
            <SubTitle>{TEXT_LABELS.VERIFICATION}</SubTitle>
          </FlexboxContainerComponent>
          <hr
            style={{
              width: '150px',
              color: '#C1C9D1',
              margin: '12px 4px',
              height: '1px',
            }}
          ></hr>
          <FlexboxContainerComponent
            flexDirection="column"
            alignItems="center"
            width="24px"
          >
            <StepNumber $bg={step >= STEPS.COMPLETION}>
              {step === STEPS.COMPLETION ? (
                <Check
                  style={{
                    color: '#FFFFFF',
                    height: Height.xsPlus,
                    width: '12px',
                  }}
                />
              ) : (
                <>{STEPS.COMPLETION}</>
              )}
            </StepNumber>
            <SubTitle>{TEXT_LABELS.COMPLETED}</SubTitle>
          </FlexboxContainerComponent>
        </FlexboxContainerComponent>
        {step === STEPS.UPLOADING && (
          <UploadComponent
            uploadSuccess={uploadSuccess}
            uploadFileSizeError={uploadFileSizeError}
            uploadFileTypeError={uploadFileTypeError}
            uploadFileTemplateError={uploadFileTemplateError}
            file={file}
            handleUploadClick={handleUploadClick}
            handleClear={handleClear}
            inputRef={inputRef}
            handleFileChange={handleFileChange}
            fileUploading={fileUploading}
          />
        )}
        {(step === STEPS.VERIFICATION || step === STEPS.COMPLETION) && (
          <VerificationCompletionComponent
            uploadingStatus={uploadingStatus}
            progressPercent={progressPercent}
            step={step}
          />
        )}
        <FooterComponent showNextButton={showNextButton} disableNextButton={disableNextButton}
         gotoNextStep={gotoNextStep}
         uploadSuccess={uploadSuccess}
         product={product}
         role={role}
         uploadingStatus={uploadingStatus}
         step={step}
         handleClear={handleClear}
         featureFlags={featureFlags}
         />
      </FlexboxContainerComponent>
    </Modal>
  );
};

export default UploadProductVariant;
