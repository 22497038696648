import styled, { AnyStyledComponent } from 'styled-components';
import { Card, GridItem } from 'OnboardingComponents/Elements';

export const Callout = (sc: AnyStyledComponent) => styled(sc)`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.lightBlue};
`;

export const CardCallout = Callout(Card);

export const GridItemCallout = Callout(GridItem);
