// import { hot } from 'react-hot-loader'; // must be first loaded
import React from 'react';
import { useRoutes } from 'hookrouter';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import styled from 'styled-components';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { toast, Slide } from 'react-toastify';
import Root from 'common/Root';
import InviteAccept from 'common/InviteAccept';
import ResetPassword from 'common/ResetPassword';
import ResetPasswordEmailEntry from 'common/ResetPasswordEmailEntry';
import ErrorBoundary from 'common/ErrorBoundary';
import LoginPage from 'components/Auth/Login';
import { SocketProvider } from 'context/socket';
import { AuthProvider, useAuth } from 'lib/Auth';
import { Color } from 'lib/styles';
import GlobalStyle from 'styles/global';
import 'react-toastify/dist/ReactToastify.css';
import store, { persistor } from 'redux/store';
import { MuiThemeProvider } from '@material-ui/core';
import { themeMui } from './styles/themeMui';
import { CampaignContextProvider } from 'context/campaign';

const Container = styled.div`
  color: ${Color.black};
  overflow-x: hidden;
`;

const nonAuthRoutes = {
  '/accept-invite/:id': ({ id }) => <InviteAccept inviteId={id} />,
  '/reset-password': () => <ResetPasswordEmailEntry />,
  '/reset-password/:id': ({ id }) => <ResetPassword resetId={id} />
};

// Lazy load toast only when needed
toast.configure({
  autoClose: 5000,
  limit: 1,
  position: 'top-right',
  hideProgressBar: true,
  toastClassName: 'modal-elevation',
  transition: Slide,
  style: { left: 'calc(100vw - 346px)' }
});

function Main() {
  const { user } = useAuth();
  const nonAuthPage = useRoutes(nonAuthRoutes);
  const userStorageData = window.sessionStorage.getItem('auth');

  let page = null;
  if (nonAuthPage) {
    page = nonAuthPage;
  } else {
    if (user && userStorageData !== null) {
      page = <Root />;
    } else {
      page = <LoginPage />;
    }
  }

  return (
    <Container>
      {page}
    </Container>
  );
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <AuthProvider>
          <SocketProvider>
            <CampaignContextProvider>
            <MuiThemeProvider theme={themeMui}>
              <MuiPickersUtilsProvider utils={LuxonUtils}>
                <GlobalStyle />
                <ErrorBoundary>
                  <Main />
                </ErrorBoundary>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
            </CampaignContextProvider>
          </SocketProvider>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
