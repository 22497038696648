import React from 'react';
import styled, { css } from 'styled-components';
import Variant from 'data/Variant';
import Product, * as Prod from 'data/Product';
import { VariantErrorType } from 'data/ProductValidation';
import Icon from 'view/Icon';
import TableEditableCell from 'view/TableEditableCell';
import { Column } from 'view/Table';
import Tooltip from 'components/UI/Tooltip';
import { Size } from 'lib/styles';
import { Main, Background } from 'styles/themeColors';
import { CheckCircle, WarningRounded } from '@material-ui/icons';


const Validation = styled.div<any>`
  align-items: center;
  background-color: ${Main.error};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: ${Size.medium};
  justify-content: center;
  position: relative;
  width: ${Size.medium};

  ${props => props.theme === 'valid' && `background-color: ${Main.success}`}
`;

const DeleteIcon = styled(Icon).attrs({ name: 'x', size: 15 })`
  vertical-align: middle;
`;

const TableButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const TdAction = styled.td`
  border: none;
  padding: 0 ${Size.tiny};
`;

const TooltipError = styled.div``;

const Tr = styled.tr<any>`
  ${props => props.unfocused && css`
    opacity: 0.3;
    pointer-events: none;

    td {
      border-color: rgba(58, 119, 209, 0.3);
    }
  `}
`;

const ValidationIcon = styled(Icon).attrs({ size: 14 })`
  color: ${Background.white};
  margin-right: 0 !important;
`;

const ValidationTd = styled(TdAction)`
  padding-left: 0.75rem;
  width: 2.25rem;
`;

const errorMessages = {
  [VariantErrorType.DuplicateUniqueValue]: 'Duplicate SKU',
  [VariantErrorType.DuplicateVariant]: 'Duplicate variant',
  [VariantErrorType.MissingValue]: 'Missing or blank values',
  [VariantErrorType.InvalidSKUValue]: 'The value for SKU is invalid. Valid characters are A-Z a-z 0-9 . _ - \\ /. No spaces are allowed',
  [VariantErrorType.InvalidSKULength]: `The SKU should be less than ${process.env.REACT_APP_SKU_MAX_LENGTH!} characters long`,
  [VariantErrorType.SKUValidation]: `The SKU validation has been failed`,
  [VariantErrorType.EmptyAttributeValue]: `Empty Attribute value`,
  [VariantErrorType.InvalidMaterialNumber]: 'The value for SKU is invalid. Valid characters are A-Z a-z 0-9 . _ - \\ /. No spaces are allowed',
  [VariantErrorType.EmptyMaterialNumber]: 'Material number Value can not be empty',
  [VariantErrorType.InvalidMaterialNumberLength]: `The SKU should be less than ${process.env.REACT_APP_SKU_MAX_LENGTH!} characters long`,
  [VariantErrorType.DuplicateMaterialNumber]: 'Duplicate material number',
  [VariantErrorType.MaterialNumberValidation]: 'MaterialNumberValidation',
  [VariantErrorType.EmptyInventory]: 'Inventory not updated',
  [VariantErrorType.EmptySku]: 'Sku value is missing in variant',
  [VariantErrorType.RentMissingPriceUS]: 'Price is mandatory for first record',
  [VariantErrorType.RentMissingPriceCA]: 'Price is mandatory for first record',
  [VariantErrorType.RentMissingRangeUS]: 'Range is mandatory for first record',
  [VariantErrorType.RentMissingRangeCA]: 'Range is mandatory for first record',
  [VariantErrorType.RentRangeSkipped]: 'Price ranges must be populated in order',
  [VariantErrorType.RentRangeOrder]: 'Rent ranges should be is ascending order',
  [VariantErrorType.RequiredRentalRange]: 'Rental variants must have at least one price range set',
  [VariantErrorType.EmptyConsignmentType]: 'Either purchase or rent should be selected'
};

export interface Row {
  variant: Variant;
  errorTypes: VariantErrorType[];
  errorInventoryTypes: VariantErrorType[];
  focused: boolean;
}

interface Props {
  className?: string;
  columns: Column[];
  errorTypes: Row['errorTypes'];
  errorInventoryTypes: Row['errorInventoryTypes'];
  focused: Row['focused'];
  focusedMode: boolean;
  onCellEdit: (
    newAttributeValue: string | number | boolean,
    variant: Variant,
    attributeKey: string,
    keyId?: number,
    extras?: Object
  ) => void;
  onRowDelete: (variant: Variant) => void;
  showDeleteButton: boolean;
  variant: Variant;
  attributes: Prod.ProductAttribute[];
  classifications: Prod.ProductAttribute[];
  suggestionValuesList: string[];
  clearSuggestions: () => void;
  originalId?: number;
  product: Product;
  disabled?: boolean;
  publishedSKUList: string[];
  selectedWarehouses: Prod.ProductWarehouse[];
  productStocks?: any[];
  availableCountries?: any[];
  isErrorColumn: (val: string, sku: string) => boolean,
  stockFlag?: boolean;
}

function VariantTableRow({
  className,
  columns,
  errorTypes,
  errorInventoryTypes,
  stockFlag,
  focused,
  focusedMode,
  onCellEdit,
  onRowDelete,
  showDeleteButton,
  variant,
  suggestionValuesList,
  attributes,
  classifications,
  clearSuggestions,
  originalId,
  product,
  disabled,
  publishedSKUList,
  selectedWarehouses,
  productStocks = [],
  availableCountries = [],
  isErrorColumn
}: Props) {


  let tooltipErrors = null;

  if (errorTypes.length > 0) {
    tooltipErrors = errorTypes.map(errorType => (
      <TooltipError key={errorType}>{errorMessages[errorType]}</TooltipError>
    ));
  }

  let icon: React.ReactNode = null;

  if (tooltipErrors) {
    icon = (
      <Tooltip content={tooltipErrors}>
        <Validation>
          <ValidationIcon name="x" />
        </Validation>
      </Tooltip>
    );
  }

  if (!tooltipErrors && variant.sku) {
    icon = (
      <Validation theme="valid">
        <ValidationIcon name="check" />
      </Validation>
    );
  }

  if (variant.variantValidationStatus?.name === 'PENDING' && product?.brandVendor?.isMaterialApiEnabled) {
    icon = (
      <Tooltip content={`${product.vendor?.isSlbVendor ? 'Material number' : 'SKU'} is being validated.`}>
        <WarningRounded style={{ color: Main.warning }} />
      </Tooltip>
    );
  }else if(variant.variantValidationStatus?.name === 'VALID' && product?.brandVendor?.isMaterialApiEnabled){
    icon = (
      <Tooltip content={`${product.vendor?.isSlbVendor ? 'Material number' : 'SKU'} is validated.`}>
           <Validation theme="valid">
            <ValidationIcon name="check" />
          </Validation>
      </Tooltip>
    );
  }else if(variant.variantValidationStatus?.name === 'INVALID' && product?.brandVendor?.isMaterialApiEnabled){
    icon = (
      <Tooltip content={`${product.vendor?.isSlbVendor ? 'Material number' : 'SKU'} is not validated.`}>
        <Validation>
          <ValidationIcon name="x" />
        </Validation>
      </Tooltip>
    );
  }
  // show warning message on published product view for N/A inventory
  if(errorInventoryTypes.length > 0 && !stockFlag && product?.brandVendor?.isStockApiEnabled){
    icon = (
      <Tooltip content={errorMessages['EmptyInventory']}>
        <WarningRounded style={{ color: Main.warning }} />
      </Tooltip>
    );
  }

  const deleteButton = product.status === 'draft' && showDeleteButton  ? (
    <TableButton onClick={() => onRowDelete(variant)} disabled={disabled}>
      <DeleteIcon />
    </TableButton>
  ) : null;

  const variantAttributes = product.attributes && variant.attributeValues
    ? Prod.getAttributesForVariant(
      attributes,
      variant.attributeValues.filter(attributeValue => attributeValue.isSelectable)
    )
    : [];

  const variantClassifications = product.attributes && variant.attributeValues
    ? Prod.getAttributesForVariant(
      classifications,
      variant.attributeValues.filter(attributeValue => !attributeValue.isSelectable)
    )
    : [];

  const rowValues: any = {
    ...variant,
    ...variantAttributes,
    ...variantClassifications,
  };


  // If we have warehouses, we need to add the warehouse columns
  if (selectedWarehouses?.length) {
    selectedWarehouses.forEach((warehouse) => {
      const productStock = variant.productStocks?.find((productStock) => {
        return productStock.warehouseId === warehouse.id
      })

      if(warehouse?.stock){
        rowValues[`Inventory-${warehouse.id}`] = warehouse.stock.inventory.OpenSalesOrderQty;
      }else{
        rowValues[`Inventory-${warehouse.id}`] = 'N/A';
      }

      if (productStock) {
        rowValues[`available-${warehouse.id}`] = productStock?.available || false;
        rowValues[`deliveryLeadDays-${warehouse.id}`] = productStock?.deliveryLeadDays || 1;
        rowValues[`postOrderShippingCost-${warehouse.id}`] = productStock?.postOrderShippingCost || false;
      }

      if (!productStock) {
        rowValues[`available-${warehouse.id}`] = false;
        rowValues[`deliveryLeadDays-${warehouse.id}`] = product?.deliveryLeadDays || 1;
        rowValues[`postOrderShippingCost-${warehouse.id}`] = false;
      }


    });
  }

  // Add unit prices keys in row values for available countries
  if (availableCountries?.length) {
    availableCountries.forEach((country) => {
      const unitPrice = variant?.productVariantPrices?.find((unitPrice) => {
        return unitPrice.countryId === country.id
      })

      rowValues[`pricePerUnit-${country.id}`] = unitPrice?.pricePerUnit ? unitPrice?.pricePerUnit : 0;
    });
  }


  return (
    <Tr className={className} unfocused={focusedMode && !focused}>
      <ValidationTd>{icon}</ValidationTd>
      {columns.map((d, di) => {
        function onChange(value: string | number | boolean) {
          let keyId = d.keyId

          // If the column is a warehouse column, we need to find the keyId
          if (d.isWarehouseColumn) {
            const warehouseId = d.key?.split('-')[1]
            const attributeKey = d.key?.split('-')[0]
            keyId = productStocks.find((productStock: any) => {
              return productStock.productVariantId === variant.id && productStock.warehouseId === parseInt(warehouseId)
            }
            )?.id
            onCellEdit(value, variant, attributeKey, keyId, { warehouseId: parseInt(warehouseId, 10) });
          } else if (d.isUnitPriceColumn) {
            const countryId = d.key?.split('-')[1]
            const attributeKey = d.key?.split('-')[0]
            keyId = variant?.productVariantPrices?.find((unitPrice: any) => {
              return unitPrice.countryId === parseInt(countryId)
            }
            )?.id
            onCellEdit(value, variant, attributeKey, keyId, { countryId: parseInt(countryId, 10) });
          }
          else {
            onCellEdit(value, variant, d.key, keyId);
          }
        }

        const borderColor = variant.hidden === true ? Main.warning : Main.accent;
        // const isError = errorTypes.length > 0 && focusedMode && focused && d.error;
        let isError =  focusedMode && focused && isErrorColumn(d.key, variant.sku);
        if(!variant.sku && d.key === 'sku'  ){
          if((!product.variants.length) || ( product.variants.length && !product.variants[0].sku )){
            isError = focusedMode
          }
        }
        const extras: any = {}
        if (d.isUnitPriceColumn) {
          const countryId = d.key?.split('-')[1]
          extras['countryId'] = parseInt(countryId, 10)
          extras['currencyIso'] = availableCountries.find((country) => country.id === parseInt(countryId, 10))?.currency?.currencyIso
          extras['isUnitPriceColumn'] = true
        }

        return (
          <TableEditableCell
            error={isError}
            color={borderColor}
            key={di}
            columnIndex={di}
            onChange={onChange}
            value={rowValues[d.key]}
            suggestionValuesList={suggestionValuesList}
            clearSuggestions={clearSuggestions}
            originalId={originalId}
            publishedSKUList={publishedSKUList}
            disabled={disabled}
            data={d}
            focusedMode={focusedMode}
            {...extras}
          />
        );
      })}
      <TdAction>{deleteButton}</TdAction>
    </Tr>
  );
}

export default React.memo(VariantTableRow, (prevProps, nextProps) => {
  const propKeys: Array<keyof Props> = [
    'className',
    'columns',
    'errorTypes',
    'focused',
    'focusedMode',
    'onCellEdit',
    'onRowDelete',
    'showDeleteButton',
    'variant',
    'clearSuggestions',
    'suggestionValuesList'
  ];

  return propKeys
    .map(key => {
      if (key === 'errorTypes') {
        if (prevProps[key].length !== nextProps[key].length) {
          return false;
        }

        return prevProps[key]
          .map((val, i) => val === nextProps[key][i])
          .every(isEqual => isEqual);
      }
      return prevProps[key] === nextProps[key];
    })
    .every(isEqual => isEqual);
});

VariantTableRow.defaultProps = {};
