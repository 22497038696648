import { get, put, post } from 'lib/API';
import User from 'data/User';

export const loadUsers = async ({ token }: any): Promise<User[]> => {
  return await get('v1/users', token);
};

export const resetPassword = ([resetId, password]: string[]): Promise<any> => {
  return post(`v1/users/reset-password/${resetId}`, undefined, { newPassword: password });
};

export const saveUser = ([user]: User[], { token }: any): Promise<any> => {
  return put('v1/users', token, {
    name: user.name,
    administratorEmail: user.administratorEmail,
    adminMailbox: user.adminMailbox,
    customerMailbox: user.customerMailbox,
    deadLetterMailbox: user.deadLetterMailbox
  });
};

export const sendResetPasswordEmail = (email: string): Promise<any> => {
  return post('v1/users/reset-password', undefined, { email });
};

export const updatePassword = (
  [password, newPassword]: string[],
  { token }: any
): Promise<any> => {
  return put('v1/users/update-password', token, { password, newPassword });
};

export const verifyResetToken = ({ resetId }: any): Promise<any> => {
  return get(`v1/users/reset-password/${resetId}`, undefined);
};

export const loadUserPreferences = ({ token }: any) => {
  return get('v1/userPreferences', token);
};

export const createUserPreferences = ([userPreferences]: any[], { token }: any): Promise<any> => {
  const { companyTableColumnOrder, customerTableColumnOrder } = userPreferences;

  return post('v1/userPreferences', token, { companyTableColumnOrder, customerTableColumnOrder });
};

export const saveUserPreferences = ([userPreferences, userId]: any[], { token }: any): Promise<any> => {
  const { companyTableColumnOrder, customerTableColumnOrder } = userPreferences;

  return put(`v1/userPreferences/${userId}`, token, { companyTableColumnOrder, customerTableColumnOrder });
};
