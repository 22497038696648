import React, { useState, useEffect, useRef } from 'react';
import { Close } from '@material-ui/icons';
import { useAsync } from 'react-async';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Product, * as Prod from 'data/Product';
import SuperCategory from 'data/SuperCategory';
import Vendor from 'data/Vendor';
import Brand from 'data/Brand';
import Category from 'data/Category';
import * as Med from 'data/Media';
import * as User from 'data/User';
import Keyword from 'data/Keyword';
import VideoThumbnail from 'common/VideoThumbnail';
import AddVideoModal from 'view/AddVideoModal';
import AssetCardList from 'view/AssetCardList';
import VideoAssetCardList from 'view/VideoAssetCardList';
import Button from 'components/UI/Button';
import Checkbox from 'components/UI/Checkbox';
import FileUploader from 'view/FileUploader';
import Icon from 'view/Icon';
import Input from 'components/UI/Input';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import EditorSection from 'view/EditorSection';
import RichTextEditor from 'view/RichTextEditor';
import Select from 'components/UI/Select';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import RenderTag from 'components/UI/RenderTag';
import { Asset } from 'view/AssetCard';
import TagsInputComponent from 'view/TagsInputComponent';
import { renderToast } from 'components/UI/ToastNotification';
import * as Arr from 'lib/Array';
import * as Obj from 'lib/Object';
import { Color, Size, BorderRadius, Percent, FontWeight } from 'lib/styles';
import { getPolicyKey } from 'lib/brightcove';
import { getTooltipTextForTier } from 'lib/tooltipText';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import {
  getProductValidationTier} from 'lib/productValidationTiers';
import { loadBrands } from 'requests/brand';
import { postMedia } from 'requests/media';
import { createVideo } from 'requests/video';
import { loadWarehouses } from 'requests/warehouse';
import { loadKeywords } from 'requests/keyword';
import { loadSuperCategories } from 'requests/superCategory';
import { Background, Main } from 'styles/themeColors';
import FeatureFlag from 'data/FeatureFlag';
import ConfirmModal from 'components/UI/Modal';
import { Space } from 'styles/themeSpaces';
import { PRODUCT_CONSTANTS } from 'constants/productColumns';
import Currency from 'data/Currency';
import PDPPreviewImages from './PDPPreviewImages';
import Video from 'data/Video';
import VideoPlayModal from 'view/VideoPlayModal';
import InfoIcon from 'components/UI/InfoIcon';
import Tooltip from 'components/UI/Tooltip';
import { MAX_DESCRIPTION_LENGTH, VALID_IMAGE_TYPES, PRODUCT_CONSTANTS as PRODUCT_CONST, VALID_DOCS_TYPES, PRICE_TYPE } from 'constants/product';
import { getWarehousesByType } from 'utils/warehouse';
import Variant from 'data/Variant';

const Container = styled.div`
  margin: 0px auto 0 auto;
  width: ${Percent.full};
`;

const DocumentIcon = styled(Icon).attrs({ name: 'PDFFile', size: 26 })`
  color: ${Color.darkerGray};
`;

const ImageIcon = styled(Icon).attrs({ name: 'Image', size: 30 })`
  color: ${Color.darkerGray};
`;

const LabelText = styled.label`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
`;

const InputContainer = styled.div <any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Color.veryLightGray};
  border-radius: ${BorderRadius.medium};
  padding-right: 5px;
`;

const StyledInput = styled(Input)`
  input {
    background-color: ${Background.white};
    border-radius: ${BorderRadius.medium};
    height: 40px;
  }

  span {
    color: ${Color.darkGray};
    font-size: 15px;
  }
`;

const VideoIcon = styled(Icon).attrs({ name: 'Video', size: 30 })`
  color: ${Color.darkerGray};
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

const StyledSelect = styled.div`
    position: absolute;
    z-index: 999;
    margin-top: 70px;
    background-color: white;
    border: 1px solid ${Main.lightGray};
    border-radius: 5px;
    max-height: 400px;
    overflow-y: scroll;

    div {
      padding: 10px;

      &:hover {
        background-color: ${Main.brandLighter};
        cursor: pointer;
      }
    }
`;

const StyledInfoIcon = styled(InfoIcon)`
  display: flex;
  margin-left: ${Size.small};
  margin-top: 0.1rem;
`;

const CarousalContainer = styled.div<any>`
  min-height: 500px;
  width: 720px;
  display: flex;
  justifyContent: center
`;

const Label = styled.div<any>`
  font-size: 15px;
  color: ${({ color }) => color ? color : Main.black};
`;

interface Props {
  loading: boolean;
  product: Product;
  update: (product: Product) => void;
  selectedVendor: Vendor;
  disabled?: boolean;
  featureFlags: FeatureFlag;
  validation?: boolean;
  setSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  currencies: Currency[];
  errorKeys: string[];
  isErrorHighlight: boolean
  setProductVariants?: (value: Variant[]) => void;
};

type MediaType = 'documents' | 'contextualImages' | 'videos' | null;

export default function ProductEditorV2({
  loading,
  product,
  update,
  selectedVendor,
  disabled,
  setSaveDisabled,
  featureFlags,
  validation,
  currencies,
  errorKeys,
  isErrorHighlight,
  setProductVariants
}: Props) {
  const { user, token } = useAuth();
  const [isAddingVideo, setIsAddingVideo] = useState(false);
  const [keywordsList, setKeywordsList] = useState<Keyword[]>([]);
  const [isUploadingProductImagery, setIsUploadingProductImagery] = useState(false);
  const [isUploadingContextualImages, setIsUploadingContextualImages] = useState(false);
  const [isUploadingDocuments, setIsUploadingDocuments] = useState(false);
  const isVendor = User.isVendor(user!);
  const warehousesReq = useAPI({ deferFn: loadWarehouses });
  const superCategoriesReq = useAPI({ deferFn: loadSuperCategories });
  const brandsReq = useAPI({ deferFn: loadBrands });
  const [keywordsTags, setKeywordsTags] = useState<string[]>([]);
  const [delivery, setDelivery] = useState<boolean>(false);
  const [pickup, setPickup] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showProductMediaDeleteModal, setShowProductMediaDeleteModal] = useState<MediaType>(null);

  const [filterWarehouses, setFilterWarehouses] = useState<any>([]);
  const [error, setError] = useState(false);
  const [mediaId, setMediaId] = useState<number>(0);
  const ref = useRef<null | HTMLElement>(null);
  const [modalVideo, setModalVideo] = useState<Video | null>(null);
  const [keyword, setKeyword] = useState('');
  const [suggestedKeywords, setSuggestedKeywords] = useState<Keyword[]>([]);
  const [descriptionCharacters, setDescriptionCharacters] = useState(MAX_DESCRIPTION_LENGTH);
  const [refreshVideoModal, setRefreshVideoModal] = useState(false);

  useEffect(() => {
    setKeywordsTags(product.keywords)
  }, [product.keywords])

  // on submit scroll to warehouse validations
  useEffect(() => {
    if(validation){
      ref.current?.scrollIntoView({behavior: 'smooth', block: "nearest", inline: "nearest"});
    }
  }, [validation])

  useEffect(() => {
    const Warehouses = product.warehouses;
    setShippingModes(Warehouses);
  }, [])

  useEffect(() => {
    handleKeywordSuggestions();
  }, [keyword])

  const keywordsReq = useAPI({
    promiseFn: loadKeywords,
    onResolve: (result) => {
      setKeywordsList(result);
    }
  });

  const createVideoReq = useAPI({
    deferFn: createVideo,
    onResolve: () => {
      setRefreshVideoModal(!refreshVideoModal);
      renderToast(toast.TYPE.SUCCESS, 'Video created.');
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const brightcovePolicyKeyReq = useAsync({
    promiseFn: getPolicyKey
  });

  useEffect(() => {
    setError(false);
    const mediaNames = product.documents
      .concat(product.productImages.concat(product.contextualImages))
      .map(media => media.displayName);
    if (
      loading ||
      !(product && product.name.trim()) ||
      !(product && product.sku.trim()) ||
      !(product && product.category) ||
      !(product && product.superCategory) ||
      !(product && product.brand) ||
      !(product && product.productImages.length) ||
      mediaNames.some((name) => name.trim() === '')
    ) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [loading, product]);

  useEffect(() => {
    warehousesReq.run(selectedVendor ? selectedVendor.id : null);
    brandsReq.run(selectedVendor ? selectedVendor.id : null);
  }, [selectedVendor]);

  useEffect(() => {
    if (
      selectedVendor &&
      (!product.vendor || product.vendor!.id !== selectedVendor.id)
    ) {
      const { id, name, code } = selectedVendor;
      updateProduct('vendor', { id, name, code });
    }
  }, [selectedVendor]);

  useEffect(() => {
    superCategoriesReq.run([token]);
  }, []);

  useEffect(() => {
    if (createVideoReq.data) {
      updateProduct(
        'videos',
        Arr.add(product.videos, {
          id: createVideoReq.data.id,
          videoId: createVideoReq.data.videoId,
          name: createVideoReq.data.name,
        })
      );
    }
  }, [createVideoReq.data]);

  async function addVideo(videoId: string) {
    if (selectedVendor) {
      createVideoReq.run({ videoId, name: product.name }, selectedVendor.id);
    }

    setIsAddingVideo(false);
  }

  async function attachMedia(
    file: File,
    type: Med.MediaType,
    isContextual: boolean
  ) {
    let media: any;

    if (token && selectedVendor) {
      

      if(type === 'product_image'){

        if(!VALID_IMAGE_TYPES.includes(file.type)){
          renderToast(toast.TYPE.ERROR, PRODUCT_CONST.PRODUCT_IMAGE_VALIDATION_MESSAGE);
        return
        }

        if(isContextual){
          setIsUploadingContextualImages(true)
        }else{
          setIsUploadingProductImagery(true)
        }
      }else if(type === 'document'){
        if(!VALID_DOCS_TYPES.includes(file.type)){
          renderToast(toast.TYPE.ERROR, PRODUCT_CONST.PRODUCT_DOCS_VALIDATION_MESSAGE);
        return
        }
        setIsUploadingDocuments(true)
      }
     
      
      const filename = product.name;
      media = await postMedia({
        file,
        filename,
        token,
        type,
        vendorId: selectedVendor.id,
      })
      .catch((err) => {
        renderToast(toast.TYPE.ERROR, 'Failed to upload media.');
        return;
      })
      .finally(()=> {
        setIsUploadingContextualImages(false);
        setIsUploadingProductImagery(false);
        setIsUploadingDocuments(false);
      })
    } else {
      renderToast(toast.TYPE.INFO, 'Please select a vendor before you try to upload media.');
      return;
    }

    if(!media) return;

    const productKey = isContextual
      ? 'contextualImages'
      : type === 'product_image'
        ? 'productImages'
        : 'documents';

    if(productKey === 'productImages'){ 
      // Replace the existing main image with the new one
      updateProduct(productKey, [
        {
          id: media.id,
          name: media.name,
          displayName: media.name,
          originalFileName: media.originalFileName,
          path: media.path,
          type: media.type,
          isContextual
        }
      ]);
    } else {
      updateProduct(productKey, [
        ...product[productKey],
        {
          id: media.id,
          name: media.name,
          displayName: media.name,
          originalFileName: media.originalFileName,
          path: media.path,
          type: media.type,
          isContextual
        }
      ]);
    }

  }

  function deleteProductMedia(mediaId: number) {
    if(showProductMediaDeleteModal && product[showProductMediaDeleteModal]){
      let asset;
      for(let asset_ of product[showProductMediaDeleteModal]){
        if(asset_.id === mediaId){
          asset = asset_;
        }
      }

      if(asset){
        updateProduct(showProductMediaDeleteModal, Arr.remove(product[showProductMediaDeleteModal], asset) as any);
        if (product.id) {
          if(showProductMediaDeleteModal === 'videos'){
            Prod.deleteProductVideo(product.id, mediaId, token!)
            .finally(()=>{
              setMediaId(0);
            });
          }else{
            Prod.deleteProductMedia(product.id, mediaId, token!)
            .finally(()=>{
              setMediaId(0);
            });
          }
        }
      }
    }
    setMediaId(0);
    setShowProductMediaDeleteModal(null);
  }


  function onKeywordsChange(keywords: string[]) {
    setKeywordsTags(keywords);
    updateProduct('keywords', keywords);
    setSuggestedKeywords([]);
    setKeyword('');
  }

  function richTextEditorOnBlur(value: string) {
    updateProduct('description', value);
  }

  function updateBrand(event: any) {
    const brand = brands.find(brand => {
      return brand.name === event.target.value
    })

    // Update material number for each variant if material api is enabled
    const updatedVariants = product.variants.map(variant => {
      variant.materialNumber = '';
      return variant;
    });

    update({
      ...product,
      brand:brand || null,
      variants: updatedVariants
    });

  }

  function updateCategory(event: any) {
    const category = categories.find(cat => cat.name === event.target.value);
    updateProduct('category', category || null);
  }

  function updateProduct<K extends keyof Product>(key: K, value: Product[K]) {
    const updatedProduct = Obj.set(product, key, value);
    update(updatedProduct);
  }

  function updateHidden(value: boolean) {
    const updatedVariants = product.variants.map(variant => {
      variant.hidden = value;
      return variant;
    });
    update({ ...product, hidden: value, variants: updatedVariants });
  }

  function updatePickup(value: boolean) {
    if (delivery) {
      setError(true);
      renderToast(toast.TYPE.ERROR, PRODUCT_CONSTANTS.PICKUP_VALIDATE_MESSAGE);
      return;
    }
    const updatedVariants = product.variants.map(variant => {
      variant?.productStocks && variant.productStocks.map((productStock)=>{
        productStock.postOrderShippingCost = false;
        productStock.availableForPickup = value;
        return productStock;
      });
      variant.dirty = true;
      return variant;
    });
    setProductVariants && setProductVariants(updatedVariants);
    update({ ...product, availableForPickup: value, postOrderShippingCost: false, variants: updatedVariants });
  }

  function updatePostOrderShippingCost(value: boolean) {

    const updatedVariants = product.variants.map(variant => {
      variant?.productStocks && variant.productStocks.map((productStock)=>{
        productStock.postOrderShippingCost = value;
        productStock.availableForPickup = false;
        return productStock;
      });

      variant.dirty = true;
      return variant;
    });

    setProductVariants && setProductVariants(updatedVariants);
    update({ ...product, availableForPickup: false, postOrderShippingCost: value, variants: updatedVariants });
  }

  function updateSuperCategory(event: any) {
    const superCategory = superCategories.find(cat => cat.name === event.target.value);
    if (superCategory) {
      const updatedProduct = {
        ...product,
        superCategory,
        category: null
      };

      update(updatedProduct);
    }
  }

  function setShippingModes(updatedWarehouses: any) {
    const purchaseWarehouses = getWarehousesByType(updatedWarehouses, PRICE_TYPE.PURCHASE);
    const rentWarehouses = getWarehousesByType(updatedWarehouses, PRICE_TYPE.RENT);

    const pickupCheckPurchase = purchaseWarehouses.some((wr: any) => wr.pickup === true);
    const deliveryCheckPurchase = purchaseWarehouses.some((wr: any) => wr.delivery === true);
    const pickupCheckRent = rentWarehouses.some((wr: any) => wr.pickup === true);
    const deliveryCheckRent = rentWarehouses.some((wr: any) => wr.delivery === true);

    const pickupCheck = pickupCheckPurchase && pickupCheckRent;
    const deliveryCheck = deliveryCheckPurchase && deliveryCheckRent; 
   
    if (pickupCheck && deliveryCheck) {
      setPickup(false);
      setDelivery(false);
    } else if (deliveryCheck) {
      setDelivery(true);
      setPickup(false);
    } else if (pickupCheck) {
      setPickup(true);
      setDelivery(false);
    } else {
      setPickup(false);
      setDelivery(false);
    }
  }

  if (loading || keywordsReq.isLoading) {
    return <LoadingIndicator />;
  }

  const validationTier = getProductValidationTier(product);
  const tooltipValidationText = getTooltipTextForTier(validationTier);

  let superCategories: SuperCategory[] = superCategoriesReq.data
    ? superCategoriesReq.data
    : [];

  let categories: Category[] = [];
  if (product.superCategory) {
    const selectedSuperCategory = superCategories.find(
      cat => cat.code === product.superCategory!.code
    );

    if (selectedSuperCategory) {
      categories = selectedSuperCategory.categories;
    }
  }

  let brands: Brand[] = brandsReq.data ? brandsReq.data : [];

  function handleKeywordSuggestions() {
    const suggestions = keywordsList.filter(x => keyword && x.name.toLocaleLowerCase().startsWith(keyword.toLowerCase()));
    setSuggestedKeywords(suggestions);
  }

  function handleKeywordAddition(keyword: string) {
    let keywordsCopy = [...keywordsTags]
    if(!keywordsTags.includes(keyword)){
      keywordsCopy = [...keywordsCopy, keyword]
    }
    onKeywordsChange(keywordsCopy);
  }

  const deleteModal = (
    <ConfirmModal
      showModal={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      onClick={deleteProductMedia}
      title={PRODUCT_CONSTANTS.HEADER_MODAL_WAREHOUSE}
      ghostButtonText="Cancel"
      color={Main.brandDark}
      borderColor={Main.brandDark}
      primaryButtonColor={Main.brandDark}
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          {PRODUCT_CONSTANTS.WAREHOUSE_REMOVAL_VALIDATE_MESSAGE}<span>{<br />}{<br />}</span>{PRODUCT_CONSTANTS.UNDONE}
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )

  const deleteProductMediaModal = (
    <ConfirmModal
      showModal={showProductMediaDeleteModal != null}
      onClose={() => setShowProductMediaDeleteModal(null)}
      onClick={() => deleteProductMedia(mediaId)}
      title={PRODUCT_CONSTANTS.DELETE_PRODUCT_MEDIA}
      ghostButtonText="Cancel"
      color={Main.brandDark}
      borderColor={Main.brandDark}
      primaryButtonColor={Main.brandDark}
    >
      <></>
    </ConfirmModal>
  );

  const onDeliveryLeadDayChange = (value: number) => {
   
    // Update lead days for all product stocks on changing lead days for product
    const updatedVariants = product.variants.map(variant => {
      variant.productStocks?.map(stock => {
        stock.deliveryLeadDays = value;
        return stock;
      });
      return variant;
    });

    update({
      ...product,
      deliveryLeadDays: value,
      variants: updatedVariants
    });

  }

  let modalVideoEl = null;
  if (modalVideo) {
    modalVideoEl = (
      <VideoPlayModal
        showModal={modalVideo}
        name={modalVideo.name}
        onClose={() => setModalVideo(null)}
        videoId={modalVideo.videoId}
      />
    );
  }

  return (
    <Container>
      <AddVideoModal
        showModal={isAddingVideo}
        onAdd={addVideo}
        onClose={() => {
          setIsAddingVideo(false);
        }}
        refreshModal={refreshVideoModal}
      />
      {deleteModal}
      {deleteProductMediaModal}
      {modalVideoEl}
      {/* <Breadcrumbs crumbs={crumbs} /> */}

      <FlexboxContainer flexDirection='row' gap='40px' margin='20px 0px'>
        <CarousalContainer>
          <FlexboxContainer
            className={errorKeys.includes('productImages') && isErrorHighlight ? PRODUCT_CONST.ERROR_FIELD : ''}
          flexDirection='column' gap='16px' width='100%' justifyContent={product.productImages.length > 0 ? 'flex-start' : 'center'}>
            <PDPPreviewImages
              images={product.productImages.map(x => x.path)}
              secondaryImages={product.contextualImages.map(x =>x.path)}
              onVideoClick={video => setModalVideo(video)}
              videos={product.videos}
              productRedesignFlag
              isUploadingProductImagery={isUploadingProductImagery}
            />
            <div style={{alignSelf: 'center'}}>
              {
                isUploadingProductImagery ? <LoadingIndicator size={20} /> : 
                <Tooltip
                  content={'Product name is required'}
                  hidden={!!product.name}
                >
                  <FileUploader
                    acceptTypes={['image/jpeg', 'image/png']}
                    onChange={file => attachMedia(file as File, 'product_image', false)}
                    disabled={disabled || !product.name}
                    text='Upload main product image'
                    productRedesignFlag
                    borderColor={Main.brandDark}
                    backgroundColor={Main.brandDark}
                    color='white'
                  />
                </Tooltip>
              }
            </div>
            </FlexboxContainer>
          </CarousalContainer>
        <FlexboxContainer flexDirection='column' width='100%' gap='24px'>
          <StyledInput
            id="product-name"
            labelName="Product name"
            placeholder="Enter product name"
            onInputChange={value => updateProduct('name', value)}
            value={product.name}
            disabled={disabled}
            className={errorKeys.includes('name')  && isErrorHighlight ? PRODUCT_CONST.ERROR_FIELD : ''}
            isInvalid={errorKeys.includes('name')  && isErrorHighlight}
          />

          <EditorSection
            subLabel="Product description"
            infoIconText={tooltipValidationText.description}
            disablePaddingBottom
            noBorder
            isOptional
            productRedesignFlag
          >
            <RichTextEditor
              placeholder="Enter description"
              onBlur={richTextEditorOnBlur}
              value={product.description}
              disabled={disabled}
              productRedesignFlag
              maxLength={MAX_DESCRIPTION_LENGTH}
              setDescriptionCharacters={setDescriptionCharacters}
            />
            <FlexboxContainer flexDirection='row' gap='5px'>
               <Label>Characters remaining:</Label>
               <Label color={Main.brandDark}>{descriptionCharacters}</Label>
            </FlexboxContainer>
          </EditorSection>
        </FlexboxContainer>
      </FlexboxContainer>

      <FlexboxContainer justifyContent="space-between" alignItems="center" gap={'12px'} margin='16px 0'>
        <Select
          label="Category"
          labelPosition='top'
          options={superCategories.map(cat => ({ value: cat.name }))}
          onChange={updateSuperCategory}
          value={product.superCategory ? product.superCategory.name : ''}
          defaultValue="Select category"
          disabled={disabled}
          width="100%"
          className={errorKeys.includes('category')  && isErrorHighlight ? PRODUCT_CONST.ERROR_FIELD : ''}
          isRedLable={errorKeys.includes('category')  && isErrorHighlight}
          displayAsterisk
        />
        <Select
          disabled={disabled || categories.length === 0}
          label="Subcategory"
          labelPosition='top'
          options={categories.map(cat => ({ value: cat.name }))}
          onChange={updateCategory}
          value={product.category ? product.category.name : ''}
          defaultValue="Select subcategory"
          width="100%"
          className={errorKeys.includes('superCategory')  && isErrorHighlight ? PRODUCT_CONST.ERROR_FIELD : ''}
          isRedLable={errorKeys.includes('superCategory')  && isErrorHighlight}
          displayAsterisk
        />
        <Select
          label="Brand"
          labelPosition='top'
          options={brands.map(brand => ({ value: brand.name }))}
          onChange={updateBrand}
          value={product.brand ? product.brand.name : ''}
          defaultValue="Select brand"
          disabled={disabled || (!isVendor && !selectedVendor.name)}
          width="100%"
          className={errorKeys.includes('brand') && isErrorHighlight ? PRODUCT_CONST.ERROR_FIELD : ''}
          isRedLable={errorKeys.includes('brand')  && isErrorHighlight}
          displayAsterisk
        />
      </FlexboxContainer>

      <FlexboxContainer justifyContent="space-between" gap={'12px'} margin='16px 0px'>
        <StyledInput
          id='delivery-lead-days'
          labelName="Default product lead days"
          placeholder="Enter product lead days"
          onInputChange={val => onDeliveryLeadDayChange(parseInt(val))}
          value={
            product.deliveryLeadDays ? String(product.deliveryLeadDays) : ''
          }
          disabled={disabled}
          width="100%"
          labelText='Can be overridden on warehouse and variant levels'
          productRedesignFlag
        />
        <Select
          disabled
          label="Target audience"
          labelPosition='top'
          options={categories.map(cat => ({ value: cat.name }))}
          onChange={updateCategory}
          value={''} // TBD
          defaultValue="Select target audience"
          width="100%"
        />
        <Container />
      </FlexboxContainer>

      <FlexboxContainer flexDirection="column" width='100%' gap='8px'>
        <FlexboxContainer flexDirection='row' alignItems='center'>
          <LabelText htmlFor="keywords">Keywords</LabelText>
          <StyledInfoIcon productRedesignFlag tooltipText={'Enter keywords'} />
        </FlexboxContainer>
        <InputContainer>
          <TagsInputComponent
            value={keywordsTags}
            onChange={onKeywordsChange}
            addOnBlur={false}
            className='react-tagsinput-redesign'
            inputProps={{
              className: 'react-tagsinput-input-redesign',
              placeholder: keywordsTags.length === 0 ? 'Enter product keyword(s)' : '',
              keywords: keywordsList,
              value: keyword,
              onChange: (e: any) => setKeyword(e.target.value)
            }}
            tagProps={{ className: 'react-tagsinput-tag-custom' }}
            renderTag={RenderTag}
            disabled={disabled}
          />
          {keywordsTags.length > 0 ?
            <Close style={{ color: Color.shuttleGray, cursor: 'pointer' }} onClick={() => onKeywordsChange([])} />: null}
        </InputContainer>
        {
          suggestedKeywords.length > 0 &&
          <StyledSelect>
            {
              suggestedKeywords.map((keyword: Keyword, index: number)=>(
                <div key={index} onClick={()=> handleKeywordAddition(keyword.name)}>
                  {keyword.name}
                </div>
              ))
            }
          </StyledSelect>
        }
      </FlexboxContainer>
      
      <FlexboxContainer justifyContent="space-between" alignItems="center" width="100%" gap={'12px'} margin='18px 0px'>
        <StyledInput
          id='vendor-sku-id'
          labelName="Vendor SKU"
          infoIconText="Part number listed on OFMP site  associated with this product"
          placeholder="Enter vendor SKU"
          onInputChange={(value) => updateProduct('sku', value)}
          value={product.sku || ''}
          disabled={disabled}
          productRedesignFlag
          className={errorKeys.includes('sku') && isErrorHighlight ? PRODUCT_CONST.ERROR_FIELD : ''}
          isInvalid={errorKeys.includes('sku')  && isErrorHighlight}
        />
        <StyledInput
          id='vendor-erp-id'
          labelName="Vendor ERP"
          infoIconText="Part number associated with this product in your internal ERP system"
          placeholder="Enter vendor ERP"
          onInputChange={(value) => updateProduct('vendorPartNumber', value)}
          value={product.vendorPartNumber || ''}
          disabled={disabled}
          isOptional
          productRedesignFlag
        />
        <StyledInput
          id='vendor-mpn-id'
          labelName="Vendor MPN"
          placeholder="Enter vendor MPN"
          infoIconText="Part number associated with this product in your manufacturing system"
          onInputChange={(value) =>
            updateProduct('manufacturingPartNumber', value)
          }
          value={product.manufacturingPartNumber || ''}
          disabled={disabled}
          isOptional
          productRedesignFlag
        />
      </FlexboxContainer>

      <LabelText htmlFor="logistics">Logistics options</LabelText>
      <FlexboxContainer justifyContent="space-between" alignItems="center" width="100%" gap={'12px'} margin='0px 0px 12px 0px'>
        <FlexboxContainer width='100%'>
          <Checkbox
            id='product-editor-checkbox-pickup-id'
            appearance="none"
            className='align-checkbox'
            checkboxSize="s"
            checked={product.availableForPickup}
            label={PRODUCT_CONSTANTS.PICKUP_CHECKBOX_LABEL}
            errorOccurred={error}
            onChange={val => {
              updatePickup(val);
            }}
            disabled={disabled}
            theme={product.availableForPickup ? 'brandLight' : 'lightGray' }
            bgColor={product.availableForPickup ? Main.brandLight : Main.accent }
          />
        </FlexboxContainer>
        {
          featureFlags.postOrderShippingCost && featureFlags.postOrderShippingCost.enabled &&
          (
          <FlexboxContainer width='100%'>
            <Checkbox
              id='product-editor-checkbox-postOrderShippingCost-id'
              appearance="none"
              className='align-checkbox'
              checkboxSize="s"
              checked={product.postOrderShippingCost}
              label={PRODUCT_CONSTANTS.ALLOW_POST_ORDER_SHIPPING}
              onChange={val => {
                updatePostOrderShippingCost(val);
              }}
              disabled={disabled || pickup || product.availableForPickup}
              theme={product.postOrderShippingCost ? 'brandLight' : 'lightGray' }
              bgColor={product.postOrderShippingCost ? Main.brandLight : Main.accent }
            />
          </FlexboxContainer>
          )
        }
        <FlexboxContainer width='100%'>
          <Checkbox
            id='product-editor-checkbox-hide-product-id'
            appearance="none"
            className='align-checkbox'
            checkboxSize="s"
            checked={product.hidden}
            label="Hide product"
            onChange={val => updateHidden(val)}
            disabled={disabled}
            theme={product.hidden ? 'brandLight' : 'lightGray' }
            bgColor={product.hidden ? Main.brandLight : Main.accent }
          />
        </FlexboxContainer>
      </FlexboxContainer>

      <FlexboxContainer alignItems='center'>
        <LabelText htmlFor="rental configuration">Rental configuration</LabelText>
        <StyledInfoIcon productRedesignFlag tooltipText={'If checked, all rental variants of this product will be billed for weekdays only'} />
      </FlexboxContainer>
      <FlexboxContainer width='100%'>
        <Checkbox
          id='product-editor-checkbox-weekdays-only'
          appearance="none"
          className='align-checkbox'
          checkboxSize="s"
          checked={product.weekdaysOnly}
          label="Weekdays only"
          onChange={val => updateProduct('weekdaysOnly', val)}
          disabled={disabled}
          theme={product.weekdaysOnly ? 'brandLight' : 'lightGray' }
          bgColor={product.weekdaysOnly ? Main.brandLight : Main.accent }
        />
      </FlexboxContainer>


      <FlexboxContainer justifyContent='space-between' gap='32px'>
        <EditorSection
          actions={
            isUploadingDocuments ? null :
            <FileUploader
              acceptTypes={[
                'application/pdf',
                'application/x-pdf',
                'application/x-bzpdf',
                'application/x-gzpdf'
              ]}
              onChange={file => attachMedia(file as File, 'document', false)}
              disabled={disabled}
              productRedesignFlag
              leftIcon={() => <Icon name="FileUploadV2" size={17} />}
            />
          }
          countType="document"
          heading="Supporting documents"
          infoIconText={PRODUCT_CONSTANTS.SUPPORTING_DOCS_TOOLTIP}
          productRedesignFlag
          // validation={getValidationCount('documents')}
        >
          <AssetCardList
            assets={product.documents.map(document => ({
              id: document.id,
              name: document.name,
              displayName: document.originalFileName? document.originalFileName: document.displayName,
              path: document.path,
              type: document.type,
              isContextual: false
            }))}
            productRedesignFlag
            editableName={true}
            listId="documents"
            onDelete={()=> setShowProductMediaDeleteModal('documents')}
            onUpdate={(assets: Asset[]) => updateProduct('documents', assets)}
            placeholderIcon={<DocumentIcon />}
            renderThumbnail={() => <DocumentIcon />}
            disabled={disabled}
            isUploadingMedia={isUploadingDocuments}
            setMediaId={setMediaId}
          />
        </EditorSection>

        <EditorSection
          actions={
            isUploadingContextualImages ? null :
            <FileUploader
              acceptTypes={['image/jpeg', 'image/png']}
              onChange={file => attachMedia(file as File, 'product_image', true)}
              disabled={disabled}
              productRedesignFlag
              leftIcon={() => <Icon name="FileUploadV2" size={17} />}
            />
          }
          countType="image"
          heading="Secondary product images"
          infoIconText={PRODUCT_CONSTANTS.SECONDARY_IMAGES_TOOLTIP}
          productRedesignFlag
          // validation={getValidationCount('contextualImages')}
        >
          <AssetCardList
            assets={product.contextualImages.map(image => ({
              id: image.id,
              name: image.name,
              displayName: image.originalFileName? image.originalFileName : image.displayName,
              path: image.path,
              type: image.type,
              isContextual: true
            }))}
            productRedesignFlag
            editableName
            listId="contextual-images"
            onDelete={()=> setShowProductMediaDeleteModal('contextualImages')}
            onUpdate={(assets: Asset[]) =>
              updateProduct('contextualImages', assets)
            }
            placeholderIcon={<ImageIcon />}
            disabled={disabled}
            isUploadingMedia={isUploadingContextualImages}
            setMediaId={setMediaId}
          />
        </EditorSection>

        <EditorSection
          heading="Videos"
          infoIconText={PRODUCT_CONSTANTS.VIDEOS_TOOLTIP}
          actions={
            <Button
              theme='disabled'
              color='#666C73'
              borderColor='#c1c9d1'
              bgColor={Main.white}
              leftIcon={() => <Icon name="FileUploadV2" size={17} />}
              onClick={() => setIsAddingVideo(true)}
              disabled={disabled}
            >
              Input ID
            </Button>
          }
          countType="video"
          productRedesignFlag
          // validation={getValidationCount('videos')}
        >
          <VideoAssetCardList
            assets={product.videos.map(video => ({
              id: video.id,
              name: video.name,
              videoId: video.videoId
            }))}
            editableName
            productRedesignFlag
            listId="videos"
            onUpdate={assets => {
              updateProduct(
                'videos',
                assets.map(asset => ({
                  id: 0,
                  videoId: asset.videoId,
                  name: asset.name
                }))
              );
            }}
            onDelete={()=> setShowProductMediaDeleteModal('videos')}
            placeholderIcon={<VideoIcon />}
            renderThumbnail={asset => {
              if (brightcovePolicyKeyReq.isLoading) return null;
              return (
                <VideoThumbnail
                  policyKey={brightcovePolicyKeyReq.data}
                  videoId={asset.videoId}
                />
              );
            }}
            disabled={disabled}
            setMediaId={setMediaId}
          />
        </EditorSection>
      </FlexboxContainer>
    </Container>
  );
};
