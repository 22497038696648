import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import { Space } from 'styles/themeSpaces';

interface ClearAdornmentProps {
  onClear: () => void;
  position: 'start' | 'end';
  size: 'small' | 'large';
  show: boolean;
};

const ClearAdornment = ({
  onClear,
  position,
  size,
  show
}: ClearAdornmentProps) => {
  return show 
    ? (
        <InputAdornment 
          position={position}
          style={{ cursor: 'pointer', marginRight: Space.spBase }}
        >
          <Clear 
            fontSize={size}
            onClick={onClear} 
          />
      </InputAdornment>
      )
    : null;
};

ClearAdornment.defaultProps = {
  position: 'end',
  size: 'small',
  show: true
};

export default ClearAdornment;
