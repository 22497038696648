import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Color, Size, BorderRadius, Percent } from 'lib/styles';
import { Background } from 'styles/themeColors';

const Container = styled.div`
  background-color: ${Color.lightGray};
  display: flex;
  flex-direction: column;
  padding: 4rem 0 0;
  margin: 0 auto;
  width: ${Percent.full};
`;

const Window = styled.div`
  background: ${Background.white};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  flex: 1;
  width: ${Percent.full};
  margin: 0 auto;
`;

const TitleBar = styled.div`
  background: ${Color.darkGray};
  height: ${Size.large};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center;
  padding-left: ${Size.small};
`;

const FakeButton = styled.div`
  width: 10px;
  height: 10px;
  border-radius: ${BorderRadius.huge};
  border: 2px solid ${Color.darkerGray};
  margin-right: ${Size.tiny};
`;

interface Props {
  children: ReactNode;
};

const PDPPreviewModal = ({ children }: Props) => {
  return (
    <Container>
      <Window>
        <TitleBar>
          <FakeButton />
          <FakeButton />
          <FakeButton />
        </TitleBar>
        {children}
      </Window>
    </Container>
  );
};

export default PDPPreviewModal;
