import React, { useState, useEffect, MouseEvent } from 'react';
import { AsyncState } from 'react-async';
import { toast } from 'react-toastify';
import * as Comp from 'data/Company';
import Vendor from 'data/Vendor';
import * as User from 'data/User';
import { UserPreferences } from 'data/User';
import Page from 'components/UI/Page';
import PageHeading from 'components/UI/PageHeading';
import { renderToast } from 'components/UI/ToastNotification';
import MatTable from 'components/MaterialTable/MatTable';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import initialColumns from 'constants/companyColumns';
import { loadCompanies } from 'requests/company';
import { createUserPreferences, saveUserPreferences } from 'requests/user';
import initialVendorColumns from 'constants/vendorCompanyColumns';
import Company from 'data/Company';
import { COMPANY_TABLE_COLUMN_ORDER_USER_PREFERENCES_KEY } from 'constants/userPreferenceColumnKeys';
import { mapCompanyGridColumns } from 'utils/gridColumnMappings';
import useFetchGridData from 'hooks/useFetchGridData';
import {
  applyPaginationFilter,
  applySearchFilter,
  applySortFilter,
  calculatePages,
  mergePaginationData,
  updatePreferences,
  handleOnSizeChange,
  handleOnSortChange,
  handleOnSearchKeyChange,
  handleOnRowClick,
  handleOnColumnDrag,
  handleOnColumnHidden
} from 'utils/grid';

interface Props {
  selectedVendor?: Vendor;
  page?: string;
  userPreferences: UserPreferences;
  userPreferencesReq: AsyncState<any>
};

const CompaniesList = ({
  selectedVendor,
  page,
  userPreferences,
  userPreferencesReq
}: Props) => {
  const { user } = useAuth();
  const isVendor = User.isVendor(user!);
  const filteredColumns = isVendor ? initialVendorColumns : initialColumns;
  const [columns, setColumns] = useState(filteredColumns);
  const [sortBy, setSortBy] = useState(columns.find(c => c.defaultSort !== undefined));
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const [companies, setCompanies] = useState<Comp.CompaniesList>({
    page: 1,
    totalPages: 1,
    size: 1,
    totalItems: 1,
    companies: []
  });

  const mergeCompanies = (news: Company[]) => mergePaginationData(companies, 'companies', 'companyId', news, setCompanies);

  const updateUserPreferences = (oldOnes: any, newOnes: any) => updatePreferences(oldOnes, newOnes, userPreferencesUpdateReq, userPreferencesCreateReq);

  const onSizeChange = (newSize: number) => handleOnSizeChange(newSize, setItemsPerPage, setCurrentPage);

  const onSortChange = (index: number, direction: string) => handleOnSortChange(index, direction, columns, setSortBy);

  const onSearchTermSet = (value: string) => handleOnSearchKeyChange(value, setSearchTerm, setCurrentPage);

  const onRowClick = (_?: MouseEvent<Element>, row?: any) => handleOnRowClick(`/companies/company-details/${row!.id}`, { companiesPage: page });

  const onColumnDragged = (sourceIndex: number, destinationIndex: number) => handleOnColumnDrag(
    sourceIndex,
    destinationIndex,
    columns,
    userPreferences,
    COMPANY_TABLE_COLUMN_ORDER_USER_PREFERENCES_KEY,
    setColumns,
    updateUserPreferences
  );

  const onChangeColumnHidden = (newColumns: any[]) => handleOnColumnHidden(
    newColumns,
    userPreferences,
    COMPANY_TABLE_COLUMN_ORDER_USER_PREFERENCES_KEY,
    setColumns,
    updateUserPreferences
  );

  const onAPIError = (err: any) => renderToast(toast.TYPE.ERROR, err.message);

  const companiesFirstPageReq = useAPI({
    deferFn: loadCompanies,
    onResolve: (result: Comp.CompaniesList) => setCompanies(result),
    onReject: onAPIError,
    debugLabel: 'companiesFirstPageReq'
  });

  const companiesRestPagesReq = useAPI({
    deferFn: loadCompanies,
    onResolve: (result: Comp.CompaniesList) => mergeCompanies(result.companies),
    onReject: onAPIError,
    debugLabel: 'companiesRestPagesReq'
  });

  const userPreferencesCreateReq = useAPI({
    deferFn: createUserPreferences,
    onResolve: () => userPreferencesReq.reload(),
    onReject: onAPIError,
    debugLabel: 'userPreferencesCreateReq'
  });

  const userPreferencesUpdateReq = useAPI({
    deferFn: saveUserPreferences,
    onResolve: () => userPreferencesReq.reload(),
    onReject: onAPIError,
    debugLabel: 'userPreferencesUpdateReq'
  });

  /* fetch companies */
  useFetchGridData(
    'companies',
    companiesFirstPageReq,
    companiesRestPagesReq,
    companies,
    setCurrentPage,
    itemsPerPage,
    selectedVendor && selectedVendor.id
  );

  /* if we have user column preferences use them. */
  useEffect(() => {
    if (
      userPreferences &&
      userPreferences.companyTableColumnOrder &&
      userPreferences.companyTableColumnOrder.length > 0
    ) {
      setColumns(userPreferences.companyTableColumnOrder);
    }
  }, [userPreferences]);

  const mappedColumns = mapCompanyGridColumns(columns, sortBy);
  const companiesFromSearch = applySearchFilter(companies.companies, ['displayName', 'companyName'], searchTerm);
  const companiesFromSort = applySortFilter(companiesFromSearch, sortBy);
  const companiesFromPagination = applyPaginationFilter(companiesFromSort, currentPage, itemsPerPage);
  const pagesCount = calculatePages(companiesFromSearch.length, itemsPerPage);

  const isLoading = (currentPage === 1)
    ? companiesFirstPageReq.isLoading
    : (companiesFirstPageReq.isLoading || companiesRestPagesReq.isLoading);

  return (
    <Page>
      <PageHeading>Companies</PageHeading>
      <MatTable
        columns={mappedColumns}
        data={companiesFromPagination}
        isLoading={isLoading}
        onRowClick={onRowClick}
        onColumnDragged={onColumnDragged}
        draggable
        toolbar
        overrideToolbar
        onOrderChange={onSortChange}
        customSearch={{
          placeholder: 'Search',
          value: searchTerm,
          onChange: onSearchTermSet
        }}
        initialColumns={isVendor ? initialVendorColumns : initialColumns}
        onUpdateColumns={onChangeColumnHidden}
        customPagination={{
          itemsPerPage,
          setItemsPerPage: onSizeChange,
          currentPage,
          setCurrentPage,
          totalPages: pagesCount,
          baseRoute: 'companies'
        }}
      />
    </Page>
  );
};

export default CompaniesList;
