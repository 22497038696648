import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { FontWeight} from 'lib/styles';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { SPLIT_CONSIGNMENT } from 'constants/consignmentStates';
import ConsignmentEntry from 'data/ConsignmentEntry';
import { Main, Text } from 'styles/themeColors';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { currencyFormatter, formatDateTime} from 'utils/formatters';
import { MANUFACTURING, PROCESSING } from 'constants/consignmentStates';
import CurrencyInput from 'components/UI/CurrencyInput';
import { findAndUpdate } from 'lib/Array';

const TableFooterColumn = styled.span<any>`
  margin: 16px 0 0;
  width: 144px;
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const TableColumn = styled.span<any>`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  width: 234px;
  color: ${Text.secondary};
  height: 18px;
  margin-bottom: 8px;

  ${({ color }) => color && css`
  color: ${color};
`}

${({ textDecoration }) => textDecoration && css`
  text-decoration: ${textDecoration};
`}

${({ fontSize }) => fontSize && css`
  font-size: ${fontSize};
`}

${({ fontWeight }) => fontWeight && css`
  font-weight: ${fontWeight};
`}

  ${({ deleted }) => deleted && css`
  opacity: 0.5;
`}
`;

const TableData = styled.td<any>`
  padding-top: 16px;
  text-align: center;
  vertical-align: top;

  &:first-child:not(:only-child):not([rowspan]) {
    text-align: left;
    font-weight: ${FontWeight.bold};
  }

  &:first-child:not(:only-child)[rowspan] {
    text-align: left;
  }

  &:last-child:not(:only-child)[rowspan] {
    padding-right: 10px;
    text-align: right;
    margin-right: 0;
    font-weight: ${FontWeight.bold};
  }

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ textDecoration }) => textDecoration && css`
    text-decoration: ${textDecoration};
  `}

  ${({ fontSize }) => fontSize && css`
    font-size: ${fontSize};
  `}

  ${({ fontWeight }) => fontWeight && css`
    font-weight: ${fontWeight};
  `}

  ${({ deleted }) => deleted && css`
    opacity: 0.5;
  `}
`;

const ProductImage = styled.img<any>`
  padding: 16px;
  cursor: pointer;
  height: 160px;
  width: 128px;
  margin: 16px 32px 64px 0;
`;

const TableFooterData = styled.span<any>`
  width: 174px;
  height: 20px;
  text-align: right;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const Label = styled.span<any>`
  font-weight: bold;
  width: 143px;
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const Consignment = styled.span<any>`
  font-weight: bold;
  width: 200px;
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const Label1 = styled.span<any>`
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
  padding-right: 8px;
`;
const Label2 = styled.span<any>`
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  color: ${Main.error};
  font-size: 16px;
  line-height: 20px;
`;

interface Props {
  consignmentEntries: ConsignmentEntry[];
  setAdditionalShippingCostsValues: (value: any) => void;
  errorMessage?: string;
  consignmentId: string;
  state?: string;
  deliveryDate?: string;
  deliveryCost: number;
  additionalShippingCostsValues: any[];
  isInvalid: boolean;
  totalShippingCost: number
};


const onValueChange = (value: any, setAdditionalShippingCostsValues: any, additionalShippingCostsValues: any, state: any) => {
  const findConsignment = (shippingCostValues: any) => shippingCostValues.state === state;

  const additionalShippingCost = additionalShippingCostsValues.map((shippingCost:any) => {
      if (findConsignment(shippingCost)) {
          return {
              ...shippingCost,
              additionalShippingCost: !isNaN(value) ? value : 0
          };
      }
      return shippingCost;
  });

  setAdditionalShippingCostsValues(additionalShippingCost);
}


const SplitPostOrderShippingCost = ({
  totalShippingCost,
  consignmentId,
  consignmentEntries,
  errorMessage,
  state,
  deliveryDate,
  deliveryCost,
  additionalShippingCostsValues,
  setAdditionalShippingCostsValues,
  isInvalid
}: Props) => {

const findConsignmentValue = additionalShippingCostsValues.find((cons: any) => cons.state === state);
const additionalShippingCost = findConsignmentValue?.additionalShippingCost;

  const shippingCost = (
    <FlexboxContainerComponent flexDirection="column" alignItems='flex-end' width='100%'>
      <FlexboxContainerComponent alignItems='flex-start' padding='16px 0 0'>
        <Label>Shipping cost</Label>
        <TableFooterData>&nbsp;</TableFooterData>
      </FlexboxContainerComponent>
      <FlexboxContainerComponent>
        <TableFooterColumn>Included in products</TableFooterColumn>
        <TableFooterData> ${deliveryCost}</TableFooterData>
      </FlexboxContainerComponent>
      <FlexboxContainerComponent>
        <TableFooterColumn>Added post-order</TableFooterColumn>
        <FlexboxContainer margin="8px 0">
        <CurrencyInput
          selectAllOnFirstClick
          id="added-post-order-id"
          labelName=""
          placeholder=""
          value={typeof additionalShippingCost !== 'string' ? additionalShippingCost.toFixed(2) : additionalShippingCost}
          onValueChange={(value: any) => onValueChange(value, setAdditionalShippingCostsValues, additionalShippingCostsValues, state)}
          width="172px"
          isInvalid={isInvalid}
          errorMessage={errorMessage}
          decimalsLimit={2}
        ><label style={{"position":"absolute", "right":'56px','padding':'5px','color':'#71777C'}}>/ {Number(totalShippingCost).toFixed(2)}</label></CurrencyInput>
        </FlexboxContainer>
      </FlexboxContainerComponent>
    </FlexboxContainerComponent>
  );

  const productColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {
    return (
      <Fragment key={consignmentEntry.id}>
        <FlexboxContainer flexDirection='row' border='1px solid #E1E6EB' width='100%' padding="16px 8px ">
          <FlexboxContainerComponent flexDirection='row' width="70%">
            <ProductImage src={consignmentEntry.productImages && consignmentEntry.productImages.length > 0 ? process.env.REACT_APP_MEDIA_URL! + consignmentEntry.productImages[0].path : ''} />
            <FlexboxContainerComponent flexDirection='column'>
              <FlexboxContainerComponent justifyContent='flex-start' margin='0 0 12px' padding='16px 0 0'>
                <TableData deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT} textDecoration={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT ? 'line-through' : 'normal'}>{consignmentEntry.productName}</TableData>
              </FlexboxContainerComponent>
              <FlexboxContainerComponent flexDirection='row'>
                <FlexboxContainerComponent flexDirection='column'>
                  <TableColumn>SKU:</TableColumn>
                  {
                    consignmentEntry.selectableAttributes && consignmentEntry.selectableAttributes.map(atr => {
                      return (
                        <TableColumn>{atr.name}</TableColumn>
                      )
                    })
                  }
                  <TableColumn>Pricebook name / ID</TableColumn>
                  <TableColumn>Est. delivery date</TableColumn>
                </FlexboxContainerComponent>
                <FlexboxContainerComponent flexDirection='column'>
                  <TableColumn deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}> {consignmentEntry.productSKU ? consignmentEntry.productSKU : ''} </TableColumn>
                  {
                    consignmentEntry.selectableAttributes && consignmentEntry.selectableAttributes.map(atr => {
                      const atrValue = consignmentEntry.attributeValues.find(val => val.attributeId === atr.id);
                      return (
                        <TableColumn>{atrValue ? atrValue.name : ''}</TableColumn>
                      )
                    })
                  }
                  <TableColumn>{consignmentEntry.pricebookName && consignmentEntry?.priceBook?.PricebookVendorId ? `${consignmentEntry.pricebookName} / ${consignmentEntry?.priceBook?.PricebookVendorId}` : ''}</TableColumn>
                  <TableColumn>{deliveryDate ? formatDateTime(deliveryDate) : formatDateTime(consignmentEntry.preliminaryDeliveryDate)}</TableColumn>
                </FlexboxContainerComponent>
              </FlexboxContainerComponent>
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent flexDirection='row' width='20%'>
            <FlexboxContainerComponent justifyContent='flex-end' >
              <TableData deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>{consignmentEntry.quantity}</TableData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
              <TableData>&nbsp;</TableData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
              <TableData>&nbsp;</TableData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
              <TableData>&nbsp;</TableData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
              <TableData>&nbsp;</TableData>
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent flexDirection='row' width='20%'>
            <FlexboxContainerComponent justifyContent='flex-end'>
              {
                consignmentEntry.discountedPrice !== consignmentEntry.basePrice
                  ? <TableData color={Main.error} deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>{currencyFormatter.format(consignmentEntry.discountedPrice)}</TableData>
                  : <TableData deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>{currencyFormatter.format(consignmentEntry.basePrice)}</TableData>
              }
            </FlexboxContainerComponent>
            <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
              <TableData>&nbsp;</TableData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
              <TableData>&nbsp;</TableData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
              <TableData>&nbsp;</TableData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
              <TableData>&nbsp;</TableData>
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent justifyContent='flex-end'>
            <TableData deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>{currencyFormatter.format(consignmentEntry.totalPrice)}</TableData>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
            {
              consignmentEntry.surcharge > 0
                ? <TableData rowSpan={2} deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>+{currencyFormatter.format(consignmentEntry.surcharge)} <br /> surcharge</TableData>
                : <TableData>&nbsp;</TableData>
            }
          </FlexboxContainerComponent>
          {/* <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
            {
              consignmentEntry.discountedPrice !== consignmentEntry.basePrice
                ? <TableData color={Main.error} deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>{currencyFormatter.format(consignmentEntry.basePrice - consignmentEntry.discountedPrice)} ({calculateDiscountPercent(consignmentEntry.basePrice, consignmentEntry.discountedPrice)}%)</TableData>
                : <TableData>&nbsp;</TableData>
            }
          </FlexboxContainerComponent> */}
          <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
            <TableData>&nbsp;</TableData>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
            <TableData>&nbsp;</TableData>
          </FlexboxContainerComponent>
        </FlexboxContainer>
    </Fragment>
  )
});


  return (
    <>
      <FlexboxContainer flexDirection='column'>
          <FlexboxContainer flexDirection='row' padding='24px 0 8px'>
            <Consignment>Order # {consignmentId}</Consignment>
            {
              state === MANUFACTURING
              ? <Label1>Manufaturing</Label1>
              : state === PROCESSING
              ? <Label1>In stock</Label1>
              : <Label2>Canceled</Label2>
            }
            <FlexboxContainer margin='0 0 0 24px'>
              <Label1>{[MANUFACTURING, PROCESSING].includes(state!) ? 'Est. delivery' : ''}</Label1>
            <Label1>{[MANUFACTURING, PROCESSING].includes(state!) ? formatDateTime(deliveryDate) : ''}</Label1>
            </FlexboxContainer>
          </FlexboxContainer>
          <FlexboxContainer flexDirection="column">
            {productColumn}
            {shippingCost}
          </FlexboxContainer>
        </FlexboxContainer>
    </>
  )
};

export default React.memo(SplitPostOrderShippingCost);
