import React from 'react';
import styled from 'styled-components';
import { navigate } from 'hookrouter';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Color, FontSize } from 'lib/styles';
import Button from 'components/UI/Button';
import DocumentCardGroup from 'components/Consignments/DocumentCardGroup';
import { Text } from 'styles/themeColors';
import Icon from 'view/Icon';

const Heading = styled.h3`
  font-size: ${FontSize.large};
  line-height: 28px;
  margin-bottom: 12px;
`;

const LabelText = styled.span`
  display: block;
  text-align: left;
  margin: 12px 0;
  color: ${Text.primary};
  font-size: 16px;
  font-weight: bold;
`;

interface Props {
  consignmentId?: string;
  vendorId: string;
  documents?: any;
  showVendorDocuments?: boolean;
  orderDocuments: any;
  customerEmail: string;
  orderNumber: string;
};

const Documents = ({
  consignmentId,
  vendorId,
  documents,
  showVendorDocuments,
  orderDocuments,
  customerEmail,
  orderNumber
}: Props) => {
  const hasDocuments = orderDocuments.documents && orderDocuments.documents.length > 0;

  return (
    <>
      {
        (hasDocuments || showVendorDocuments) && (
          <FlexboxContainer
            flexDirection="column"
            borderRadius='6px'
            borderColor={Color.lightGray}
            padding="16px"
            bgColor={Color.lightGray}
            width="100%"
          >
            <Heading id="documents">Documents</Heading>
            {
              hasDocuments && (
                <>
                  <LabelText>From customer</LabelText>
                  <DocumentCardGroup
                    cards={orderDocuments.documents}
                    customerEmail={customerEmail}
                    orderNumber={orderNumber}
                    createdBy={hasDocuments ? orderDocuments.documents[0].createdBy : ''}
                    comment={hasDocuments ? orderDocuments.documents[0].comment : ''}
                    date={hasDocuments ? orderDocuments.documents[0].creationTime : ''}
                  />
                </>
              )
            }
            {
              showVendorDocuments
                ? (
                  <>
                    <LabelText>For customer</LabelText>
                    {
                      documents.vendorFiles && documents.vendorFiles.map((vendorFile: any, index: number) => (
                        <DocumentCardGroup
                          orderNumber={orderNumber}
                          createdBy={vendorFile.createdBy}
                          customerEmail={customerEmail}
                          key={index}
                          cardForCustomer
                          cards={vendorFile.documents}
                          date={vendorFile.creationTime}
                          comment={vendorFile.comment || ''}
                          consignmentId={consignmentId}
                          documentId={vendorFile.id}
                        />
                      ))
                    }
                    <Button
                      margin='0 0 0 12px'
                      onClick={() => navigate(`/orders/order-details/${consignmentId}/new-document`, false, { vendorId })}
                      theme='disabled'
                      color='#666C73'
                      borderColor='#c1c9d1'
                      bgColor={Color.lightGray}
                      leftIcon={() => <Icon name="FileUploadV2" size={17} />}
                    >
                      Attach set of documents
                    </Button>
                  </>
                )
                : null
            }
          </FlexboxContainer>
        )
      }
    </>
  );
};

export default React.memo(Documents);
