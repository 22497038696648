import React from 'react';
import styled from 'styled-components';
import * as User from 'data/User';
import { useAuth } from 'lib/Auth';
import { Color, Size } from 'lib/styles';
import { Main } from 'styles/themeColors';

const Container = styled.div`
  color: ${Color.black};
  font-size: 1.2em;
  font-weight: 500;
  margin: ${Size.huge} auto;
  text-align: center;
  width: 350px;

  a {
    color: ${Main.accent};
    font-size: 0.9em;
  }
`;

export default function LibraryInstructions() {
  const { user } = useAuth();
  const isVendor = User.isVendor(user!);

  return (
    <Container>
      <p>
        This is where we will store all of your created products that have not
        been submitted for approval.
      </p>
      <p>
        <a href="/TheOFMP-Product-Import-Template.xlsx">
          Download import template
        </a>
      </p>
      <p>
        {
          isVendor && <a href="/#">View best practices</a>
        }
      </p>
    </Container>
  );
};
