import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import * as Usr from 'data/User';
import Button from 'components/UI/Button';
import ErrorMessage from 'view/ErrorMessage';
import PasswordInput from 'view/PasswordInput';
import PasswordRequirements, {
  validatePassword
} from 'view/PasswordRequirements';
import Requirement from 'view/Requirement';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, Size } from 'lib/styles';
import { useAPI } from 'lib/API';
import { updatePassword } from'requests/user';

const Container = styled.form``;

const ContentPasswordInput = styled(PasswordInput)`
  margin-bottom: ${Size.medium};
`;

const CurrentPasswordInput = styled(ContentPasswordInput)`
  border-bottom: 1px solid ${Color.shade};
  margin-bottom: ${Size.medium};
  padding-bottom: ${Size.medium};
`;

const Passwords = styled.div`
  display: flex;
`;

const PasswordInputs = styled.div`
  flex: 1;
  margin: 0 ${Size.large} 0 0;
`;

const PasswordRequirementsWrapper = styled.div``;
const PasswordRequirementsTitle = styled.div`
  font-weight: 500;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin-bottom: ${Size.medium};
`;

interface Props {
  className?: string;
}

export default function AccountSettingsPassword({ className }: Props) {
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');

  const userPasswordUpdateReq = useAPI({
    deferFn: updatePassword,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Password updated successfully.');

      setPassword('');
      setNewPassword('');
      setVerifyPassword('');
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const [passwordValid, errors] = validatePassword(newPassword);
  const passwordsMatch =
    newPassword.length > 0 && newPassword === verifyPassword;

  function save(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    userPasswordUpdateReq.run(password, newPassword);
  }

  let errorComponent = null;
  if (userPasswordUpdateReq.error) {
    errorComponent = (
      <StyledErrorMessage message={userPasswordUpdateReq.error.message} />
    );
  }

  const saveDisabled =
    userPasswordUpdateReq.isLoading ||
    password.length === 0 ||
    newPassword.length === 0 ||
    verifyPassword.length === 0 ||
    !passwordValid ||
    !passwordsMatch;

  return (
    <Container className={className} onSubmit={save}>
      <h2>Password</h2>
      <p>Change your password.</p>

      <Passwords>
        <PasswordInputs>
          <CurrentPasswordInput
            label="Current password"
            onChange={setPassword}
            placeholder="Enter current password"
            value={password}
          />
          <ContentPasswordInput
            label="New password"
            onChange={setNewPassword}
            placeholder="Enter new password"
            value={newPassword}
          />
          <ContentPasswordInput
            label="Verify password"
            onChange={setVerifyPassword}
            placeholder="Verify new password"
            value={verifyPassword}
          />
        </PasswordInputs>
        <PasswordRequirementsWrapper>
          <PasswordRequirementsTitle>
            New password requirements
          </PasswordRequirementsTitle>
          <PasswordRequirements errors={errors} />
          <Requirement passed={passwordsMatch}>
            Passwords must match
          </Requirement>
        </PasswordRequirementsWrapper>
      </Passwords>
      {errorComponent}
      <Button disabled={saveDisabled}>
        Save changes
      </Button>
    </Container>
  );
}
