import React, { ReactNode, useLayoutEffect, useRef } from 'react';
import { useState } from 'react';
import styled, { css } from 'styled-components';
import { Select, Input, InputAdornment, MenuItem, ListItemIcon, ListItemText, Checkbox, Chip } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import StyledBadge from 'components/UI/Badge';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Background, Border, Main, Text } from 'styles/themeColors';
import { Height } from 'styles/themeHeights';
import { Space } from 'styles/themeSpaces';
import CloseIcon from '@material-ui/icons/Close';


const SelectElement = styled(Select) <any>`
  border-color: ${({ $error }) => $error ? Main.error : Border.main} !important;
  width: ${({ width }) => width ?? '100%'};
  height: ${({ height }) => height ?? Height.m};
  background: ${Background.white};
  font-size: ${Space.sp2x};
  border-radius: ${BorderRadius.m};
  border-color: ${({ $error }) => $error ? Main.error : Border.main};
  box-sizing: border-box;
  border: 1px solid ${Border.main};

  ${({ $noBorder }) => $noBorder && css`
    border: none;
  `}

  ${({ $noBoxShadow }) => $noBoxShadow && css`
    box-shadow: none;
  `}

  ${({ disabled }) => disabled && css`
    cursor: not-allowed;
    opacity: 0.6;
  `}
  
  .MuiSelect-select {
    padding-left: 8px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg.MuiSvgIcon-root.MuiSelect-icon {
    color: ${Text.primary} !important;
  }
`;

const StyledListItemText = styled(ListItemText) <any> `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

const OptionElement = styled(MenuItem)`
  background: ${Background.white};
  display: flex;
  line-height: ${Height.xsPlus};
  font-size: ${Space.sp2x};
  padding: 8px 16px !important;

   .MuiCheckbox-root {
    color: #FA8334; // Orange for selected checkbox
  }
`;

const SelectedValue = styled.span`
  padding: 3px;
  font-family: 'Lato';
  text-align: center;
`;

const PlaceHolder = styled.span`
  padding: 3px;
  color: #71777C;
  font-family: 'Lato';
`;

const ListItem = styled(ListItemIcon) <any>`
  width: 15%;
  align-items: center;
`;

const Description = styled.span<any>`
  color: ${Text.placeholder};
  line-height: ${Height.xsPlus};
  word-wrap: break-word;
  white-space: normal;
  font-size: ${Space.spBasePlus};
  margin-top: ${Space.spQuarter};
  display: block;
  font-style: italic;
`;

const Label = styled.label<any>`
  color: ${Text.primary};
  font-size: ${Space.sp2x};
  line-height: ${Height.xsPlus};
  margin-bottom: ${({ $labelPosition, separation }) => $labelPosition === 'top' ? separation : '0px'};
  margin-right: ${({ $labelPosition }) => $labelPosition === 'left' ? '4px' : '0px'};
  margin-top: ${({ separation }) => separation === '8px' ? '0px' : '4px'};
`;

const ErrorLabel = styled.label<any>`
  color: #C82135;
  font-size: 14px;
  line-height: ${Height.xsPlus};
  margin-bottom: ${({ $labelPosition }) => $labelPosition === 'top' ? '8px' : '0px'};
  margin-right: ${({ $labelPosition }) => $labelPosition === 'left' ? '4px' : '0px'};
  margin-top: -15px;
  `;

  const ErrorLabelBottom = styled.label<any>`
  color: #C82135;
  font-size: 12px;
  line-height: ${Height.xs};
  margin-top: 2px;
  `;

export const SelectContainer = styled(FlexboxContainer) <any>`
  padding-left: inherit;
  width: ${({ width }) => width ?? '100%'};

  .MuiSelect-select {
    &:focus {
      background-color: transparent !important;
    
    }
  }
`;

const IconAsterisk = styled.span`
  color: red;
  width: 5px;
  height: 5px;
  margin-left: 5px;
`;


SelectElement.displayName = 'SelectElement';
OptionElement.displayName = 'OptionElement';

interface OptionBadge {
  content: string;
  color: string;
  backgroundColor: string;
};

export interface Option {
  value: string;
  id?: string;
  icon?: ReactNode;
  badge?: OptionBadge;
};

interface Props {
  optionClassName?: string;
  value?: string | string[];
  defaultValue?: string;
  onChange: (event: any) => void;
  width?: string;
  containerWidth?: string;
  height?: string;
  label?: string;
  labelPosition?: 'top' | 'left';
  description?: string;
  options: Option[];
  disabled?: boolean;
  selectIcon?: ReactNode;
  placeholder?: string;
  noBorder?: boolean;
  noBoxShadow?: boolean;
  isInvalid?: boolean;
  alignItems?: string;
  displayAsterisk?: boolean;
  displayEmpty?: string;
  multiple?: boolean;
  className ?: string;
  isRedLable ?: boolean;
  separation?: string;
  errroLabelPostion?: 'top' | 'bottom';
  limitTags?: number;
  errorMessage?: string;
};

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      maxHeight: 250,
      marginTop: '2px',
    },
    list: {
      paddingRight: 0,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
      height: 24,
    },
  })
);

const SelectComponent = ({
  optionClassName,
  value,
  defaultValue,
  onChange,
  width,
  containerWidth,
  height,
  label,
  labelPosition,
  description,
  options,
  disabled,
  selectIcon,
  noBorder,
  noBoxShadow,
  isInvalid,
  alignItems='stretch',
  displayAsterisk=false,
  displayEmpty,
  multiple=false,
  className,
  isRedLable,
  separation = '8px',
  errroLabelPostion = 'top',
  limitTags = 3,
  errorMessage='Required'
}: Props) => {
  const [dirty, setDirty] = useState(false);
  const [selectOptionWidth, setSelectOptionWidth] = useState(0);
  const selectContainerRef = useRef<HTMLInputElement | null>(null);
  const classes = useStyles();

  const selectMenuProps = {
    classes: { paper: classes.paper },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    style: {
      maxWidth: `${selectOptionWidth || 0}px`
    },
    MenuListProps: {
      style: {
        marginRight: -25,
        width: '100%',
        maxHeight: 250,
        overflowY: 'auto',
      }
    }
  };

  useLayoutEffect(() => {
    const optionWidth = selectContainerRef?.current?.offsetWidth;
    if(optionWidth) {
      setSelectOptionWidth(optionWidth);
    }
  }, []);

  const onChangeHandler = (event: any, element: any) => {
    const { id, value } = element?.props;

    if (id) {
      event.target.id = id;
    }
    if (multiple) {
      event.target.selected = value;
    }

    if (!dirty) {
      setDirty(true);
    }

    if (value === 'All') {
      // Toggle all options
      event.target.value =
        value.length === options.length ? [] : options.map((opt) => opt.value);
    }

    onChange(event);
  };

  const labelElement = label && (
    <FlexboxContainer>
      <Label $labelPosition={labelPosition} className={isRedLable && 'red-label'} separation={separation}>
        {label}
      </Label>
      { displayAsterisk ? <IconAsterisk> * </IconAsterisk> : null}
  </FlexboxContainer>
  );

  const startIcon = selectIcon && (
    <InputAdornment position="start">
      {selectIcon}
    </InputAdornment>
  );

  const input = (
    <Input
      disableUnderline
      startAdornment={startIcon}
    />
  );

  
 const handleChipDelete = (chipToDelete: string,id: any) => {
  if (Array.isArray(value)) {
    const newValue = value.filter((chip: string) => chip !== chipToDelete);
    onChange({ target: { value: newValue, selected: chipToDelete, id } });
  } else {
    console.warn("Attempted to remove a chip in single-select mode, which is not allowed.");
  }
};


const renderSelectedValue = (value: any) => {
  if ((defaultValue && !value) || (defaultValue && value === defaultValue) || (multiple && value.length === 0)) {
    return (
      <PlaceHolder>
        <em>{defaultValue}</em>
      </PlaceHolder>
    )
  }

  const limitedValues = multiple && value.length > 0 && limitTags ? value.slice(0, limitTags) : value;
  const remainingCount = multiple && value.length > limitTags ? value.length - limitTags : 0;

  return (
    <SelectedValue>
      {multiple ? (
        <>
          {limitedValues.map((val: any) => (
            <Chip
              key={val}
              label={val}
              disabled={disabled}
              onDelete={(event) => {
                event.stopPropagation(); // Ensure delete logic prevents dropdown interaction
                handleChipDelete(val, event.target.id); // Call the delete handler
              }}
              deleteIcon={<CloseIcon style={{ color: Main.white }} />}
              onMouseDown={(event) => {
                event.stopPropagation(); // Prevent the select dropdown from opening
              }}
              className={classes.chip}
              style={{
                backgroundColor: Main.brandDark,
                color: Main.white,
                maxWidth: 170,
              }}
            />
          ))}
          {remainingCount > 0 && (
            <span
              // label={`+${remainingCount} more`}
              // className={classes.chip}
              style={{
                // backgroundColor: Main.brandDark,
                color: Main.brandDark,
                padding: 5,
                fontSize: 14,
              }}
            >
              +{remainingCount} more
            </span>
          )}
        </>
      ) : (
        value
      )}
    </SelectedValue>
  );
};


  const enhancedOptions = displayEmpty ? [{ value: displayEmpty, id: '' }, ...options]: options;
  return (
    <FlexboxContainer
      displayFlex="flex"
      flexWrap="wrap"
      width={containerWidth || '100%'}
      alignItems={alignItems}
    >
      {
        labelPosition === 'left' ?
          (
            <FlexboxContainer>
              {labelPosition && labelElement}
            </FlexboxContainer>
          ) : null
      }
      <SelectContainer
        width={width}
        flexDirection="column"
      >
        {labelPosition === 'top' && labelElement}
        <SelectElement
          $noBorder={noBorder}
          $noBoxShadow={noBoxShadow}
          $error={isInvalid}
          defaultValue={null}
          input={input}
          value={value}
          onChange={onChangeHandler}
          disabled={disabled}
          MenuProps={selectMenuProps}
          renderValue={renderSelectedValue}
          height={height}
          displayEmpty
          ref={selectContainerRef}
          multiple={multiple}
          className={className}
        >
          {
            enhancedOptions.map((option:any, key) => (
              <OptionElement
                key={key}
                value={option.value}
                id={option?.id}
                className={optionClassName}
              >
                {multiple && <>
                  <StyledListItemText disableTypography={true} primary={option.value} />
                  <Checkbox checked={value && value.length > 0 ? value.indexOf(option.value) > -1: false} />
                </>}
                {!multiple && <FlexboxContainer
                  flexDirection="row"
                  justifyContent="space-between"
                  width="100%"
                >
                  {
                    option.icon && (
                      <ListItem marginThreshold={0}>
                        {option.icon}
                      </ListItem>
                    )
                  }
                  <FlexboxContainer
                    flexDirection="column"
                    width="100%"
                  >
                    {!multiple && <StyledListItemText disableTypography={true} primary={option.value} />}
                    {option.description && <Description>{option.description}</Description>}
                  </FlexboxContainer>
                  {
                    option.badge && (
                      <ListItem>
                        <StyledBadge
                          badgeContent={option.badge.content}
                          color={option.badge.color}
                          bgColor={option.badge.backgroundColor}
                          classes={{ badge: 'badge' }}
                        />
                      </ListItem>
                    )
                  }
                </FlexboxContainer>}
              </OptionElement>
            ))
          }
        </SelectElement>
      {errroLabelPostion === 'bottom' && isInvalid && <ErrorLabelBottom>{errorMessage}</ErrorLabelBottom>}
      </SelectContainer>
      {errroLabelPostion === 'top' && isInvalid && <ErrorLabel>{errorMessage}</ErrorLabel>}
    </FlexboxContainer>
  );
};

export default SelectComponent;
