import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { toast } from 'react-toastify';
import Modal from 'components/UI/Modal';
import { renderToast } from 'components/UI/ToastNotification';
import { useAuth } from 'lib/Auth';
import Attribute, { AttributeValueRequest } from 'data/Attribute';
import { addAttributeValues } from 'requests/atribute';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import UploadComponent from 'components/Product/UploadFileComponent';
import { checkFileSize, checkFileType } from 'components/Pricebooks/priceebook.utils';
import readExcelFile from 'read-excel-file';
import { ATTRIBUTE_CONSTANTS } from 'constants/attributes';
import { STATUS, STEPS, TEXT_LABELS } from 'constants/productVariantImport';
import styled from 'styled-components';
import { Check } from '@material-ui/icons';
import { Height } from 'styles/themeHeights';
import FooterComponent from 'components/Product/FooterComponent';
import { UploadingStatus } from 'components/Product/ProductVariantImportModal';
import VerificationCompletionComponent from 'components/Product/VerificationCompletionComponent';
import { verifyAttributeValuesFileTemplate } from 'utils/attribute';

const SubTitle = styled.h5<any>`
  margin: 0px !important;
  font-size: 16px;
  line-height: 16px;
`;

const StepNumber = styled.span<any>`
  font-size: 16px;
  line-height: 20px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  padding: 2px 7px;
  margin-bottom: 4px;
  background: ${({ $bg }) => ($bg ? '#F7991C' : '#bfc2c5')};
`;

interface Props {
  onClose: () => void;
  attribute: Attribute;
  importXLSX: (newValues: AttributeValueRequest[]) => void;
  title: string;
  showModal: boolean;
};

const ImportXLSXAttributeValueModal = ({ onClose, attribute, importXLSX, title, showModal }: Props) => {
  const { token } = useAuth();
  const [uploadFileSizeError, setUploadFileSizeError] = useState(false);
  const [uploadFileTypeError, setUploadFileTypeError] = useState(false);
  const [uploadFileTemplateError, setUploadFileTemplateError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [attributeValuesFile, setAttributeValuesFile] = useState<string>();
  const [attributeValuesData, setAttributeValuesData] = useState<any>();
  const [uploadingStatus, setUploadingStatus] = useState<UploadingStatus>();
  const [file, setFile] = useState<any>({
    imageFile: [],
    uploadDate: new Date(),
  });
  const inputRef = useRef<HTMLInputElement | null>(null);

  const reset = () => {
    if (inputRef.current != null) {
      inputRef.current.value = '';
    }
    setUploadFileSizeError(false);
    setUploadFileTypeError(false);
    setUploadFileTemplateError(false);
    setFile({
      imageFile: [],
      uploadDate: '',
    });
    setUploadSuccess(false);
    setFileUploading(false);
  }

  const handleClear = () => {
    reset();
    setTemplateError(false);
    setStep(1);
  };

  const onCloseModel = () => {
    handleClear();
    onClose();
  }

  const handleUploadClick = () => {
    reset();
    setTemplateError(false);
    inputRef.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFile({
      imageFile: [e.target.files[0]],
      uploadDate: Date.now(),
    });
    if (!checkFileSize(e.target.files[0])) {
      setUploadFileSizeError(true);
      setFileUploading(false);
      return;
    } 
    if (!checkFileType(e.target.files[0])) {
      setUploadFileTypeError(true);
      return;
    }
    setUploadSuccess(true);
  };

  const uploadXLSXAttributeValues = async (XLSXData:any) => {

    // Skip the first element
    const filterDataOfXLSX = XLSXData.slice(1);
    const attributeValues = [];
    for(const attributeValue of filterDataOfXLSX){
      attributeValues.push({ 
        attributeId: attribute.id, 
        name: attributeValue[0], 
        code: '', 
        displayOrder: attribute.values.length + 1
      });
    }
    addAttributeValues(token!, attributeValues).then(res => {
      res.counts.fileName = attributeValuesFile;
      if(res.counts.totalCount === res.counts.passedCount){
        renderToast(toast.TYPE.SUCCESS, ATTRIBUTE_CONSTANTS.SUCCESS);
      }else if(res.counts.passedCount === 0){
        renderToast(toast.TYPE.ERROR, ATTRIBUTE_CONSTANTS.IMPORT_FAILED_MESSAGE);
      }else{
        renderToast(toast.TYPE.SUCCESS, TEXT_LABELS.PARTIAL_SUCCESS);
      }
      setStep(3);
      setFileUploading(false);
      setUploadingStatus(res.counts);
      importXLSX(res.result);
    }).catch(err => {
      reset();
      setTemplateError(true);
      renderToast(toast.TYPE.ERROR, ATTRIBUTE_CONSTANTS.IMPORT_FAILED_MESSAGE, {
        autoClose: 5000
      });
    });
  }

   const showNextButton = (uploadSuccess && step === STATUS.PENDING);
   const disableNextButton = fileUploading;

   const gotoNextStep = async () => {
      setFileUploading(true);
      await uploadXLSXAttributeValues(attributeValuesData);
  };

  const attributeValuesTemplateVerify = async () => {
    const attributeValuesFile = file.imageFile[0];
    const attributeValuesData = await readExcelFile(file.imageFile[0]);
    setAttributeValuesData(attributeValuesData);
    setAttributeValuesFile(attributeValuesFile.name);

    const templateVerification = await verifyAttributeValuesFileTemplate(
      attributeValuesFile,
      attribute
    );
    if (templateVerification) {
      setFileUploading(false);
      setUploadFileTemplateError(false);
      setUploadSuccess(true);
    } else {
      setUploadFileTemplateError(true);
      setUploadSuccess(false);
      return;
    }
  };

  useEffect(() => {
    if (
      file.imageFile &&
      file.imageFile.length > 0 &&
      !uploadFileSizeError &&
      !uploadFileTypeError
    ) {
        attributeValuesTemplateVerify();
    }
  }, [file.imageFile, uploadFileSizeError, uploadFileTypeError]);

   return (
    <Modal
      width="825px"
      showModal={showModal}
      onClose={onCloseModel}
      showButtons={false}
      title={title}
      modalFooterMargin="0"
      left="50vw"
      marginLeft="-412px"
      iconName={'PricebookModalIcon'}
      showCloseIcon
      top="15%"
    >
      <FlexboxContainerComponent
        bgColor="#F9F9FA"
        border="1px solid #E1E6EB"
        borderRadius="6px"
        padding="24px"
        flexDirection="column"
      >
        <FlexboxContainerComponent margin="0 auto" flexDirection="row">
          <FlexboxContainerComponent
            flexDirection="column"
            alignItems="center"
            width="24px"
          >
            <StepNumber $bg={step >= STEPS.UPLOADING}>
              {step === STEPS.UPLOADING ? (
                <Check
                  style={{
                    color: '#FFFFFF',
                    height: Height.xsPlus,
                    width: '12px',
                  }}
                />
              ) : (
                <>{STEPS.UPLOADING}</>
              )}
            </StepNumber>
            <SubTitle>{TEXT_LABELS.UPLOAD}</SubTitle>
          </FlexboxContainerComponent>
          <hr
            style={{
              width: '150px',
              color: '#C1C9D1',
              margin: '12px 4px',
              height: '1px',
            }}
          ></hr>
          <FlexboxContainerComponent
            flexDirection="column"
            alignItems="center"
            width="24px"
          >
            <StepNumber $bg={step >= STEPS.COMPLETION}>
              {step === STEPS.COMPLETION ? (
                <Check
                  style={{
                    color: '#FFFFFF',
                    height: Height.xsPlus,
                    width: '12px',
                  }}
                />
              ) : (
                <>{STEPS.VERIFICATION}</>
              )}
            </StepNumber>
            <SubTitle>{TEXT_LABELS.COMPLETED}</SubTitle>
          </FlexboxContainerComponent>
        </FlexboxContainerComponent>
        {step === STEPS.UPLOADING && (
          <UploadComponent
            uploadSuccess={uploadSuccess}
            uploadFileSizeError={uploadFileSizeError}
            uploadFileTypeError={uploadFileTypeError}
            uploadFileTemplateError={uploadFileTemplateError}
            file={file}
            handleUploadClick={handleUploadClick}
            handleClear={handleClear}
            inputRef={inputRef}
            handleFileChange={handleFileChange}
            fileUploading={fileUploading}
          />
        )}
        {step === STEPS.COMPLETION && (
          <VerificationCompletionComponent
            uploadingStatus={uploadingStatus}
            step={step}
          />
        )}
        <FooterComponent showNextButton={showNextButton} disableNextButton={disableNextButton}
         gotoNextStep={gotoNextStep}
         uploadSuccess={uploadSuccess}
         attribute={attribute}
         role={undefined}
         uploadingStatus={uploadingStatus}
         step={step}
         handleClear={handleClear}
        />
        {templateError && (
          <FlexboxContainerComponent flexDirection="column">
            <FlexboxContainerComponent alignItems="center" flexDirection="row">
              <span style={{ color: '#c82135' }}>*{ATTRIBUTE_CONSTANTS.IMPORT_FAILED_MESSAGE}</span>
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
        )}
      </FlexboxContainerComponent>
    </Modal>
  );

};
ImportXLSXAttributeValueModal.defaultProps = {};
export default ImportXLSXAttributeValueModal;
