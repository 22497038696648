import { useAPI } from '../lib/API';
import { useEffect, useState } from 'react';
import { getEventIds } from 'requests/campaigns';

export const useCampaignsEventIds = () => {
    const [eventIds, setEventIds] = useState<any[]>([]);

    const eventIdsReq = useAPI({
        deferFn: getEventIds,
        onResolve: (result: any) => {
            setEventIds(result?.data);
        }
    });

    useEffect(() => {
        eventIdsReq.run([]);
    }, []);

    return { eventIds, eventIdsReq };

}