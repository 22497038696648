import React, { Fragment } from 'react';
import { Heading2, Heading4, Heading5, Paragraph, CardCallout, Img } from 'OnboardingComponents/Elements';

import productPageEx from 'images/onboardingAssets/product-page-example.jpg';

const Website = () => (
  <Fragment>
    <Heading2 id='website'>Your website</Heading2>
    <Paragraph>Customers that visit your website generally have the main objective of learning more about your product offerings and how to quickly make a purchase. Therefore, they need to understand that they can now do that on The OFMP and they need to know this as soon as they land on your homepage, a product page, or your “About Us” page.</Paragraph>

    <Heading4>A few effective suggestions:</Heading4>
    <CardCallout>
      <Heading5>Homepage banner:</Heading5>
      <Paragraph bold italic>“Get the [Brand] products you need on The Oilfield Marketplace! Prices next to products. Secure payment. Nationwide delivery. Visit TheOFMP.com today.”</Paragraph>
    </CardCallout>
    <CardCallout>
      <Heading5>Homepage showcase of top products:</Heading5>
      <Paragraph>Present the top products that you have available on The OFMP (e.g., via a carousel) and add a “Buy Now” button underneath them. Be sure to mention The OFMP somewhere for users to clearly understand that they will be redirected to a different site.</Paragraph>
    </CardCallout>
    <CardCallout>
      <Heading5>Product pages:</Heading5>
      <Paragraph>Consider adding a “Buy Now” button on the page of every product that you have available on The OFMP (Schlumberger does this well).</Paragraph>
      <Img src={productPageEx}></Img>
    </CardCallout>
    <CardCallout>
      <Heading5>Your &quot;About&quot; page:</Heading5>
      <Paragraph>Complete your “About Us” page (or whatever you have that’s similar) with information regarding your presence on The Oilfield Marketplace and the availability of your products online for sale within US land.</Paragraph>
    </CardCallout>
  </Fragment>
);

export default Website;
