import React from 'react';
import styled from 'styled-components';
import { ProductMedia } from 'data/Product';
import documentIcon from 'images/document-icon.svg';
import { Color, FontSize, FontWeight, Percent } from 'lib/styles';
import { Main } from 'styles/themeColors';

const Name = styled.h1`
  font-size: 28px;
  font-weight: ${FontWeight.bold};
  margin: 0 0 10px;
  line-height: 34px;
`;

const Price = styled.div`
  font-size: ${FontSize.large};
  margin-bottom: 25px;
`;

const LogoWrapper = styled.div`
  padding: 30px 0;
  border-top: 1px solid ${Color.veryLightGray};
`;

const Logo = styled.img`
  width: ${Percent.full};
  max-width: 210px;
`;

const Description = styled.div`
  padding: 30px 0;
  border-top: 1px solid ${Color.veryLightGray};

  ul {
    padding-left: 15px;
  }
`;

const ProductDetails = styled.div`
  padding: 30px 0;
  border-bottom: 1px solid ${Color.veryLightGray};
`;

const DetailKey = styled.span`
  display: inline-block;
  font-weight: ${FontWeight.bold};
  margin-right: 1em;
`;

const Documents = styled.ul`
  list-style: none;
  padding: 0;
`;

const Document = styled.li``;

const DocumentLink = styled.a`
  display: block;
  color: ${Main.accent};
  padding: 5px 0 8px;

  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(${documentIcon});
    background-repeat: no-repeat;
    vertical-align: middle;
    margin-right: 15px;
    margin-bottom: 3px;
  }
`;

interface Props {
  documents: ProductMedia[];
  logo?: string;
  description: string;
  name: string;
  price: string;
  productDetails: Record<string, any>;
};

export default function PDPPreviewDetails({
  documents,
  logo,
  description,
  name,
  price,
  productDetails
}: Props) {
  let documentItems = null;
  if (documents) {
    documentItems = documents.map(document => (
      <Document key={document.displayName}>
        <DocumentLink href={process.env.REACT_APP_MEDIA_URL + document.path} target="_blank">
          View {document.displayName}
        </DocumentLink>
      </Document>
    ));
  }

  const details = Object.keys(productDetails).map(key => {
    const value = productDetails[key];
    if (value !== null && value !== undefined && value.length > 0) {
      return (
        <div key={key}>
          <DetailKey>{key}:</DetailKey>
          <span>{productDetails[key]}</span>
        </div>
      );
    }
    return null;
  });

  return (
    <>
      <Name>{name}</Name>
      <Price>{price}</Price>
      <LogoWrapper>
        <Logo src={logo ? logo : ''} />
      </LogoWrapper>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      <ProductDetails>{details}</ProductDetails>
      <Documents>{documentItems}</Documents>
    </>
  );
};
