import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  MouseEvent
} from 'react';
import { useQueryParams } from 'hookrouter';
import { toast } from 'react-toastify';
import Order, * as Ord from 'data/Order';
import Vendor from 'data/Vendor';
import { isVendor } from 'data/User';
import ConsignmentsTable from 'components/Orders/ConsignmentsTable';
import Page from 'components/UI/Page';
import PageHeading from 'components/UI/PageHeading';
import { renderToast } from 'components/UI/ToastNotification';
import MatTable from 'components/MaterialTable/MatTable';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { replace } from 'lib/Array';
import { loadOrders } from 'requests/order';
import { SocketContext } from 'context/socket';
import {
  updatePageFilter,
  toggleOpenSplitOrders
} from 'redux/actions';
import initialColumns from 'constants/orderColumns';
import FeatureFlag from 'data/FeatureFlag';
import { mapOrderGridColumns, getSteps, mapOrderData } from 'utils/gridColumnMappings';
import useFetchGridData from 'hooks/useFetchGridData';
import { useAppSelector, useAppDispatch } from 'hooks/useReactRedux';
import {
  applyPaginationFilter,
  applySearchFilter,
  calculatePages,
  mergePaginationData,
  handleOnSearchKeyChange,
  handleOnSizeChange,
  handleOnSortChange,
  handleOnRowClick,
  SortByConfig
} from 'utils/grid';
import {
  applyOrderSortAndFiltering,
} from './orderlist.utils';
import { useCountrySelector, useTrackTableColumnsWidth } from './orderlist.hooks';

interface Props {
  selectedVendor?: Vendor;
  page?: string;
  shouldRedirect?: boolean;
  pagePadding?: string;
  headingText?: string;
  navigateBackUrl?: string;
  companyId?: string;
  featureFlags: FeatureFlag;
};

const OrdersList = ({
  selectedVendor,
  page,
  shouldRedirect,
  pagePadding,
  headingText,
  navigateBackUrl,
  companyId,
  featureFlags
}: Props) => {
  const { user } = useAuth();
  const tableRef = useRef<{tableContainerDiv?: any}>();
  const isVendorUser = isVendor(user!);
  const [queryParams, setQueryParams] = useQueryParams();
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState('');
  const filteredInitialColumns = initialColumns.filter(column => isVendorUser ? column.field !== 'vendor' : true);
  const [sortBy, setSortBy] = useState<SortByConfig | undefined>(filteredInitialColumns.find(c => c.defaultSort !== undefined));
  const [ordersResponse, setOrdersResponse] = useState<Ord.OrdersList>({
    page: 1,
    totalPages: 1,
    size: 1,
    totalItems: 1,
    orders: []
  });
  const {columnWidths, updateColumnWidth} = useTrackTableColumnsWidth(tableRef)
  const socket = useContext(SocketContext);

  const dispatch = useAppDispatch();
  const {
    filters: { showOpen, showPendingCancelationEmail },
    openSplitOrders
  } = useAppSelector(state => state.orders.config);

  const {selectedCountry, countrySelector} = useCountrySelector();

  useEffect(() => {
    if (queryParams.ordersPage) {
      setCurrentPage(queryParams.ordersPage);
      setQueryParams({ country: undefined, ordersPage: undefined });
    }
  }, []);

  const onConsignmentsUpdate = (data: any) => {

    console.log('isVendorUser', isVendorUser)

    if(isVendorUser){
      orderReq.run(itemsPerPage, 1, 0, user?.vendorId, data.orderId);
    }
    else{
      orderReq.run(itemsPerPage, 1, 0, null, data.orderId);
    }
  };

  useEffect(() => {
    socket.on('updateOrder', onConsignmentsUpdate);

    return () => {
      socket.off('updateOrder', onConsignmentsUpdate);
    }
  }, [socket]);

  const onAPIError = (err: any) => renderToast(toast.TYPE.ERROR, err.message);

  const mergeOrders = (newOrders: Order[]) => mergePaginationData(ordersResponse, 'orders', 'orderId', newOrders, setOrdersResponse);

  const onSortChange = (index: number, direction: string) => handleOnSortChange(index, direction, filteredInitialColumns, setSortBy);

  const onSearchTermSet = (value: string) => handleOnSearchKeyChange(value, setSearchTerm, setCurrentPage);

  const onRowClick = (_?: MouseEvent<Element>, row?: any) => {
    const url = (row!.consignments.length > 1)
      ? `/orders/split-order-details/${row!.orderId}`
      : `/orders/order-details/${row!.consignments[0].consignmentId.slice(4)}`;

    const params = { country: row.company.companyCountry.countryIso, ordersPage: currentPage };
    const doNothing = isVendorUser && row!.consignments.length > 1;

    handleOnRowClick(url, params, doNothing);
  };

  const onSizeChange = (newSize: number) => handleOnSizeChange(newSize, setItemsPerPage, setCurrentPage);

  const onOpenOrdersSwitchChange = () => {
    dispatch(
      updatePageFilter('config.filters.showOpen', !showOpen)
    );
    setCurrentPage(1);
  };

  const onCancelOrdersSwitchChange = () => {
    dispatch(
      updatePageFilter(
        'config.filters.showPendingCancelationEmail',
        !showPendingCancelationEmail
      )
    );
    setCurrentPage(1);
  };

  const ordersFirstPageReq = useAPI({
    deferFn: loadOrders,
    onResolve: (result: Ord.OrdersList) => {
      const mappedOrders = mapOrderData(result.orders, isVendorUser, featureFlags, selectedVendor);
      setOrdersResponse({ ...result, orders: mappedOrders });
    },
    onReject: onAPIError,
    debugLabel: 'ordersFirstPageReq'
  });

  const ordersRestPagesReq = useAPI({
    deferFn: loadOrders,
    onResolve: (result: Ord.OrdersList) => {
      const mappedOrders = mapOrderData(result.orders, isVendorUser, featureFlags, selectedVendor);
      mergeOrders(mappedOrders);
    },
    onReject: onAPIError,
    debugLabel: 'ordersRestPagesReq'
  });

  const orderReq = useAPI({
    deferFn: loadOrders,
    onResolve: (result: Ord.OrdersList) => {
      const mappedOrders = mapOrderData(result.orders, isVendorUser, featureFlags, selectedVendor);

      setOrdersResponse((prevState: Ord.OrdersList) => {
        if (mappedOrders.length > 0) {
          const foundOrder = prevState.orders.find(order => order.orderId === mappedOrders[0].orderId)

          if (foundOrder) {
            const newOrders = replace(prevState.orders, order => order.orderId === mappedOrders[0].orderId, mappedOrders[0]);

            return {
              ...prevState,
              orders: newOrders
            }
          } else {
            const newOrders = [...prevState.orders];

            newOrders.unshift(mappedOrders[0]);

            return {
              ...prevState,
              orders: newOrders
            }
          }
        }

        return prevState;
      });
    },
    onReject: onAPIError,
    debugLabel: 'ordersTestPagesReq'
  });

  /* fetch orders */
  useFetchGridData(
    'orders',
    ordersFirstPageReq,
    ordersRestPagesReq,
    ordersResponse,
    setCurrentPage,
    itemsPerPage,
    selectedVendor && selectedVendor.id,
    undefined,
    currentPage
  );

  const filteredAndSortedOrders = applyOrderSortAndFiltering(ordersResponse.orders, selectedCountry, companyId, showOpen, showPendingCancelationEmail, sortBy)
  const ordersFromSearch = applySearchFilter(filteredAndSortedOrders, ['orderId', 'status', 'vendor', 'warehouse', 'deliveryType', 'company.companyCountry.countryName', 'customer.fullName', 'displayCompanyName', 'created', 'paymentType', 'updatedBy', 'totalPriceWithTax'], searchTerm);
  const ordersFromPagination = applyPaginationFilter(ordersFromSearch, currentPage, itemsPerPage);  
  const pagesCount = calculatePages(ordersFromSearch.length, itemsPerPage);

  const isLoading = (currentPage === 1)
    ? ordersFirstPageReq.isLoading
    : (ordersFirstPageReq.isLoading || ordersRestPagesReq.isLoading);

  const mappedColumns = mapOrderGridColumns(filteredInitialColumns, isVendorUser, featureFlags, sortBy, selectedVendor);

  useEffect(() => updateColumnWidth(), [currentPage, ordersFromPagination.length, itemsPerPage, updateColumnWidth])

  const detailPanel = [{
    tooltip: 'Show more',
    render: (rowData: Order) => {
      if (rowData.consignments.length === 1) {
        return null;
      }
      return (
        <ConsignmentsTable
          consignments={rowData.consignments}
          isVendor={isVendorUser}
          getSteps={getSteps}
          navigateBackUrl={navigateBackUrl}
          page={currentPage}
          dowJonesStatus={rowData.dowJones ? rowData.dowJones.status : ''}
          featureFlags={featureFlags}
          columnWidths={columnWidths}
          country={rowData.company?.companyCountry?.countryIso}
        />
      );
    }
  }];

  const switchers = isVendorUser
    ? [
      {
        label: 'Show open orders only',
        checked: showOpen,
        onChange: onOpenOrdersSwitchChange
      }
    ]
    : [
      {
        label: 'Show open orders only',
        checked: showOpen,
        onChange: onOpenOrdersSwitchChange
      },
      {
        label: 'Pending cancelation email only',
        checked: showPendingCancelationEmail,
        onChange: onCancelOrdersSwitchChange
      }
    ];

  const selectors = [countrySelector];

  const ordersData = ordersFromPagination.map(order => ({
    ...order,
    tableData: {
      ...order.tableData,
      showDetailPanel: openSplitOrders.includes(order.orderId) ? detailPanel[0].render : false
    }
  }));

  const onToggleDetailPanel = (path: number[], render: Function) => {
    if (ordersFromPagination.length > 0) {
      dispatch(toggleOpenSplitOrders('config.openSplitOrders', ordersFromPagination[path[0]].orderId));
    }
  };

  return (
    <Page padding={pagePadding}>
      <PageHeading>{headingText}</PageHeading>
      <MatTable
        columns={mappedColumns}
        data={ordersFromPagination}
        isLoading={isLoading}
        onRowClick={onRowClick}
        wrapperWidth="83%"
        toolbar
        overrideToolbar
        onOrderChange={onSortChange}
        detailPanel={detailPanel}
        onToggleDetailPanel={onToggleDetailPanel}
        switchers={switchers}
        customSearch={{
          placeholder: 'Search',
          value: searchTerm,
          onChange: onSearchTermSet
        }}
        selectors={selectors}
        tableRef={tableRef}
        disableBorderCollapse={true}
        customPagination={{
          itemsPerPage,
          setItemsPerPage: onSizeChange,
          currentPage,
          setCurrentPage,
          totalPages: pagesCount,
          baseRoute: 'orders',
          shouldRedirect
        }}
      />
    </Page>
  );
};

OrdersList.defaultProps = {
  headingText: 'Orders'
};

export default React.memo(OrdersList);
