import { Badge } from '@material-ui/core'
import styled from 'styled-components';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Space } from 'styles/themeSpaces';

const StyledBadge = styled(Badge)<any>`
  .MuiBadge-badge{
    background-color: ${({ $bgColor }) => $bgColor};
    color: ${({ $color }) => $color};
    min-width: 20px;
    position: static;
    border-radius: ${({ $borderRadius }) => $borderRadius} !important;
    margin-left: ${Space.spHalf};
  }

  .MuiBadge-anchorOriginTopRightRectangle {
    transform: none !important;
  }
`;

StyledBadge.defaultProps = {
  $borderRadius: BorderRadius.m
};

export default StyledBadge;
