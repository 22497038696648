import {
  API_CALL_STARTED,
  API_CALL_FINISHED,
  API_CALL_FAILED
} from 'redux/constants/actionTypes';
import { StateKey } from 'redux/types/epics';
import { ApiCallStarted, ApiCallFinished, ApiCallFailed } from 'redux/types/actions';

export const apiCallStarted = (
  stateKey: StateKey,
  request?: any
): ApiCallStarted => ({
  type: API_CALL_STARTED,
  stateKey,
  request
});

export const apiCallFinished = (
  stateKey: StateKey,
  response: any
): ApiCallFinished => ({
  type: API_CALL_FINISHED,
  stateKey,
  response,
});

export const apiCallFailed = (
  stateKey: StateKey,
  error: any
): ApiCallFailed => ({
  type: API_CALL_FAILED,
  stateKey,
  error,
});
