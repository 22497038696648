import Icon from 'view/Icon';
import React from 'react';
import styled, { css } from 'styled-components';
import { Size } from 'lib/styles';
import { navigate } from 'hookrouter';
import { Background, Main } from 'styles/themeColors';

const Container = styled.div`
  background-color: ${Background.white};
  border: 1px solid ${Main.accent};
  border-radius: ${Size.small};
  flex: 1;
  padding: ${Size.medium};

  &:not(:first-of-type) {
    margin-left: ${Size.medium};
  }

  &:not(:last-of-type) {
    margin-right: ${Size.medium};
  }

  ${({ onClick }) =>
    onClick &&
    css`
      color: ${Main.accent};
      cursor: pointer;
    `}
`;

const Content = styled.p`
  margin: ${Size.medium} 0px 0px;
`;

const Heading = styled.div`
  display: flex;
`;

const Title = styled.div`
  flex: 1;
  font-weight: 700;
`;

interface Props {
  children?: React.ReactNode;
  className?: string;
  icon?: string;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  title: string;
  to?: string;
}

export default function LinkCard({
  children,
  className,
  icon,
  onClick,
  title,
  to
}: Props) {
  let content = null;
  if (children) {
    content = <Content>{children}</Content>;
  }

  let iconNode = null;
  if (icon) {
    iconNode = <Icon name={icon} />;
  }

  function handleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (to) navigate(to);
    if (typeof onClick === 'function') onClick(event);
  }

  return (
    <Container className={className} onClick={handleClick}>
      <Heading>
        <Title>{title}</Title>
        {iconNode}
      </Heading>
      {content}
    </Container>
  );
}
