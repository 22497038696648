import Category from 'data/Category';
import SuperCategory from 'data/SuperCategory';
import Vendor from 'data/Vendor';
import Media from 'data/Media';
import Warehouse from 'data/Warehouse';
import Brand from 'data/Brand';
import Video from 'data/Video';
import Attribute from 'data/Attribute';
import Variant, * as Var from 'data/Variant';
import { get, post, put, del } from 'lib/API';
import * as LocalStorage from 'lib/LocalStorage';
import * as Arr from 'lib/Array';
import * as Obj from 'lib/Object';
import { unique } from 'lib/filters';
import { createVariant, formatPrice, getTotalProductVariantPrices } from 'utils/variant';
import ProductStock, { empty as emptyProductStock } from './ProductsStock';
import { empty as emptyProductVariantPrice } from './ProductVariantPrice';
import Currency from './Currency';
import BrandVendor, {empty as emptyBrandVendor} from './BrandVendor';

export enum ProductStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  READY = 'ready'
}

export default interface Product {
  id?: number;
  originalId?: number;
  name: string;
  code: string;
  version: number;
  hidden: boolean;
  description: string;
  keywords: string[];
  availableForPickup: boolean;
  shipping: boolean;
  deliveryLeadDays: number;
  status: 'draft' | 'published' | 'ready';
  sku: string;
  vendorPartNumber?: string;
  manufacturingPartNumber?: string;
  category: ProductCategory | null;
  superCategory: ProductSuperCategory | null;
  brand: ProductBrand | null;
  vendor: ProductVendor | null;
  warehouses: ProductWarehouse[];
  documents: ProductMedia[];
  productImages: ProductMedia[];
  contextualImages: ProductMedia[];
  videos: Video[];
  attributes: ProductAttribute[];
  variants: Variant[];
  postOrderShippingCost: boolean;
  brandVendor: BrandVendor;
  variantsToDelete?: number[];
  restrictedStateIds: number[];
  rentalCode?: string;
  weekdaysOnly: boolean;
}

type ProductRequest = Pick<Product,
  'id' |
  'name' |
  'version' |
  'hidden' |
  'description' |
  'shipping' |
  'availableForPickup' |
  'deliveryLeadDays' |
  'postOrderShippingCost' |
  'status'>
  & { brandId: number }
  & { vendorId: number }
  & { subCategoryId: number }
  & { warehouses: { id: number }[] }
  & { media: Pick<ProductMedia, 'id' | 'isContextual'>[] }
  & { videos: { id: number }[] }
  & { attributes: Pick<ProductAttribute, 'id' | 'displayOrder' | 'isSelectable'>[] }
  & {
    variants: Pick<Variant,
      'sku' |
      'pricePerUnit' |
      'itemsPerUnit'
      & {
        attributeValues: Pick<Var.VariantAttributeValue,
          'name' |
          'attributeId' |
          'displayOrder'>
      }[]>[]
  }

export type ProductCategory = Pick<Category, 'id' | 'name' | 'code'>;

export type ProductSuperCategory = Pick<SuperCategory, 'id' | 'name' | 'code'>;

export type ProductBrand = Pick<Brand, 'id' | 'name' | 'code' | 'media' | 'brandVendor'>;

export type ProductVendor = Pick<Vendor, 'id' | 'name' | 'code' | 'isSlbVendor'>;

export type ProductWarehouse = Pick<Warehouse, 'id' | 'name' | 'code' | 'pickup' | 'delivery' | 'address' | 'type'> & { productStocks?: ProductStock[] } & {stock?:any};

export type ProductMedia = Pick<Media, 'id' | 'name' | 'path' | 'type' | 'originalFileName'> & { isContextual: boolean, displayName: string };

export type ProductAttribute = Pick<Attribute, 'id' | 'code' | 'name'> & { displayOrder: number, isSelectable: boolean };

export type ProductCard = Pick<Product, 'id' | 'name' | 'code' | 'version' | 'category' | 'vendor' | 'productImages' | 'status'> & { updatedOn: string, variantsCount: number };

export interface ProductGroup {
  id: string;
  count: number;
  products: ProductCard[];
}

export interface ProductList {
  page: number;
  totalPages: number;
  size: number;
  totalItems: number;
  count?: number;
  groupsCount?: number;
  products?: ProductCard[];
  groups?: ProductGroup[];
}

export interface ProductCounts {
  counts: {
    draft: {
      products: number,
      variants: number;
    };
    ready: {
      products: number,
      variants: number;
    };
    published: {
      products: number,
      variants: number;
    };
  };
}
export interface ProductCount {
  totalItems: number;
}

export interface SeparateAttributes {
  selectableAttributes: ProductAttribute[];
  classificationAttributes: ProductAttribute[];
}

export interface VariantUpdate {
  new: string;
  original: string;
}

type EmptyProduct = Omit<Product, 'id'>;

export const empty: EmptyProduct = {
  name: '',
  code: '',
  version: 1,
  hidden: false,
  description: '',
  keywords: [],
  availableForPickup: false,
  shipping: false,
  deliveryLeadDays: 1,
  status: 'draft',
  sku: '',
  vendorPartNumber: '',
  manufacturingPartNumber: '',
  category: null,
  superCategory: null,
  brand: null,
  vendor: null,
  warehouses: [],
  documents: [],
  productImages: [],
  contextualImages: [],
  videos: [],
  attributes: [],
  variants: [],
  postOrderShippingCost: false,
  brandVendor: emptyBrandVendor,
  restrictedStateIds: [],
  weekdaysOnly: false
};

export function loadAllGrouped({
  status,
  page,
  group = 'updatedAt',
  sortDirection,
  vendorId,
  token,
  countryIso,
}: any): Promise<ProductList> {
  if (group === 'updatedAt') {
    sortDirection = 'desc';
  }

  let params = { size: 20, page, group, sortDirection, status } as any;
  if (vendorId) {
    params = { ...params, vendorId }
  }
  if (countryIso) {
    params = { ...params, countryIso }
  }
  return get('v1/products', token, params);
}

export const updateProductsStatus = async (
  [productIds, status]: any[],
  { token }: any
) => {
  return await put(`v1/products/status/${status}`, token, { productIds });
};

export const exportProducts = async (
  [productIds]: any[],
  { token }: any
) => {
  return await post(`v1/export`, token, { selectedProducts: productIds });
};


export function create(
  variant: Variant = createVariant({}, []),
  product: Partial<Product> = empty,
  attributes: ProductAttribute[] = []
): Product {
  return {
    ...empty,
    name: product.name || '',
    brand: product.brand || null,
    category: product.category || null,
    deliveryLeadDays: product.deliveryLeadDays || 1,
    description: product.description || '',
    superCategory: product.superCategory || null,
    variants: [variant],
    attributes,
    warehouses: product.warehouses || []
  };
}

export function createVersion(product: Product): Product {
  return {
    ...Obj.omit(product, ['id']),
    originalId: product.id,
    status: 'draft',
    version: product.version + 1
  };
}

export async function loadSummary([vendorId]: any, { token }: any): Promise<ProductCounts> {
  const endpoint = `v1/products/counts${vendorId ? '?vendorId=' + vendorId : ''}`;

  return await get(endpoint, token);
}

export function loadProductDetails({ productId, token, isFeatureFlagEnabled }: any): Promise<Product> {
  const routePath = 'v2/products/details';

  return get(`${routePath}/${productId}`, token);
}

export async function loadLocalDraft({
  productId,
  token,
  isFeatureFlagEnabled
}: any): Promise<Product> {
  if (!productId) return Promise.resolve(create());

  const storedLocalDraft = await LocalStorage.load('localDraft');
  if (storedLocalDraft && storedLocalDraft.id === productId) {
    return storedLocalDraft;
  }

  return loadProductDetails({ productId, token, isFeatureFlagEnabled });
}

export async function saveLocalDraft(
  localDraft: Product,
  selectedVendor: Vendor,
  token: string,
  currencies: Currency[] = [],
  isSubmit: boolean = false,
): Promise<any> {
  const {
    id,
    name,
    sku,
    vendorPartNumber,
    manufacturingPartNumber,
    version,
    hidden,
    description,
    shipping,
    availableForPickup,
    deliveryLeadDays,
    status,
    brand,
    vendor,
    category,
    warehouses,
    documents,
    productImages,
    contextualImages,
    videos,
    attributes,
    variants,
    keywords,
    postOrderShippingCost,
    brandVendor,
    variantsToDelete,
    restrictedStateIds,
    rentalCode,
    weekdaysOnly
  } = localDraft;


  const productRequest = {
    id,
    name,
    sku,
    isSubmit: isSubmit,
    vendorPartNumber,
    manufacturingPartNumber,
    version,
    hidden,
    description,
    shipping,
    availableForPickup,
    deliveryLeadDays,
    status,
    keywords,
    postOrderShippingCost,
    brandId: brand ? brand.id : null,
    vendorId: vendor ? vendor.id : selectedVendor.id,
    isSlbVendor: vendor?.isSlbVendor,
    subCategoryId: category ? category.id : null,
    warehouses: warehouses.length > 0 ? warehouses.map(warehouse => ({ id: warehouse.id, type: warehouse.type })) : [],
    media: documents.concat(productImages.concat(contextualImages)).map((media, index) => ({ id: media.id, isContextual: media.isContextual, displayName: media.displayName, displayOrder: index + 1 })),
    videos: videos.map(video => ({ id: video.id })),
    attributes: attributes.map(attribute => ({ id: attribute.id, displayOrder: attribute.displayOrder, isSelectable: attribute.isSelectable })),
    variants: getFilteredVariants(variants, availableForPickup, shipping, currencies),
    variantsToDelete,
    restrictedStateIds,
    rentalCode,
    weekdaysOnly
  };
  if (!localDraft.id) {
    
    return post(`v2/products`, token, productRequest as ProductRequest);
  }
  let response = await put(`v2/products/${localDraft.id}`, token, productRequest);
  if(response.id && localDraft.status === ProductStatus.DRAFT && isSubmit && !response.isValidationEnabled){
    await put(`v1/products/status/ready`, token, { productIds: [localDraft.id] });
  }
  return response;
}

const getFilteredVariants = (variants:Variant[], availableForPickup: boolean, shipping: boolean, currencies: Currency[] = []) => {
  return variants.filter(variant => variant.sku && variant.dirty).map(variant => ({
      id: variant.id,
      code: variant.code,
      sku: variant.sku,
      vendorPartNumber: variant.vendorPartNumber,
      manufacturingPartNumber: variant.manufacturingPartNumber || null,
      itemType: variant.itemType || '',
      pricePerUnit: variant.pricePerUnit,
      itemsPerUnit: variant.itemsPerUnit,
      weight: variant.weight,
      deliveryLeadDays: variant.deliveryLeadDays ? variant.deliveryLeadDays : 1,
      hidden: variant.hidden,
      postOrderShippingCost: variant.postOrderShippingCost ? variant.postOrderShippingCost : false,
      validationStatus: variant.variantValidationStatus?.id || null,
      materialKey: variant.materialKey,
      materialNumber: variant.materialNumber,
      rent: variant.rent,
      purchase: variant.purchase,
      rentalCode: variant.rentalCode,
      rentalPriceUS: variant.rentalPriceUS,
      rentalPriceCA: variant.rentalPriceCA,
      attributeValues: variant.attributeValues
        ? variant.attributeValues.map((value: any) => ({
          name: value.name,
          attributeId: value.attributeId,
          displayOrder: value.displayOrder,
          isSelectable: value.isSelectable
        }))
        : [],
      productStocks: variant.productStocks ? variant.productStocks.map((stock: any) => ({
        id: stock.id,
        warehouseId: stock.warehouseId,
        availableForPickup,
        availableForShipping: shipping,
        deliveryLeadDays: stock.deliveryLeadDays,
        postOrderShippingCost: stock.postOrderShippingCost,
        available: stock.available,
        type: stock?.type
      })) : [],
      productVariantPrices: getTotalProductVariantPrices(variant, currencies)
    }))
}

export const cloneProduct = async (
  [productId]: any[],
  { token }: any
) => {
  return post(`v1/products/clone/${productId}`, token);
};

export function deleteDrafts(
  productIds: number[],
  token: string
): Promise<any> {
  return post(`v1/products/delete`, token, productIds);
}

export function deleteProductAttribute(
  productId: number,
  attributeId: number,
  token: string
): Promise<any> {
  return del(`v1/productAttributes/${productId}/${attributeId}`, token).then(result => result);
}

export function deleteProductMedia(productId: number, mediaId: number, token: string): Promise<any> {
  return del(`v1/productMedia?productId=${productId}&mediaId=${mediaId}`, token);
}

export function deleteProductVideo(productId: number, videoId: number, token: string): Promise<any> {
  return del(`v1/productVideos?productId=${productId}&videoId=${videoId}`, token);
}

export async function submit(product: Product, selectedVendor: Vendor, token: string,currencies:Currency[], isSubmit: boolean) {
  return await saveLocalDraft(product, selectedVendor, token,currencies, isSubmit);
}

export function appendDrafts(products: Product[], selectedVendor: Vendor, token: string): Promise<any> {
  return Promise.all(
    products.map((product: Product) => saveLocalDraft(product, selectedVendor, token))
  );
}

export function clearLocalDraft(): Promise<any> {
  return LocalStorage.clear('localDraft');
}
export function getVariantsWithAttributes(
  product: Product,
  selectedAttributes: Record<string, any>
): Variant[] {
  return product.variants.filter(el => !el.hidden).filter(variant => {
    return Object.keys(selectedAttributes).every(
      key => {
        if (selectedAttributes[key]) {
          return variant.attributeValues.find(attributeValue => attributeValue.id === selectedAttributes[key].id)
        }
      }
    );
  });
}

export function areMissingOrInvalidPrices(product: Product): boolean {
  return product.variants.some(
    p => !p.hasOwnProperty('pricePerUnit') || isNaN(p.pricePerUnit)
  );
}

export function getMaxPrice(product: Product): null | number {
  if (areMissingOrInvalidPrices(product)) return null;
  return product.variants.reduce((maxPrice: null | number, variant) => {
    if (maxPrice === null) return variant.pricePerUnit;
    return Math.max(maxPrice, variant.pricePerUnit);
  }, null);
}

export function getMinPrice(product: Product): null | number {
  if (areMissingOrInvalidPrices(product)) return null;
  return product.variants.reduce((minPrice: null | number, variant) => {
    if (minPrice === null) return variant.pricePerUnit;
    return Math.min(minPrice, variant.pricePerUnit);
  }, null);
}

export function getClassifications(
  product: Product,
  matchingVariant: Variant
): Record<string, any> {
  return product.attributes.filter(el => !el.isSelectable).reduce((res: any, attr) => {
    const value = matchingVariant.attributeValues.find(el => el.attributeId === attr.id);
    return value ? { ...res, [attr.name]: value.name } : { ...res, [attr.name]: '' }
  }, {});
}

export function getFormattedPrice(
  product: Product,
  matchingVariants: Variant[]
): any {
  if (matchingVariants.length === 1) {
    if (matchingVariants[0].pricePerUnit === null) return 'N/A';
    return formatPrice(matchingVariants[0].pricePerUnit);
  }

  const minPrice = getMinPrice(product);
  const maxPrice = getMaxPrice(product);
  if (minPrice === null || maxPrice === null) {
    return 'N/A';
  }
  return `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`;
}

export interface SelectedValue {
  id: number;
  name: string;
}

export function getAttributeOptions(
  product: Product,
  attributeKey: string,
  selectedAttributes: Record<string, any>,
  attributeOrder: ProductAttribute[]
): SelectedValue[] {
  const filteredSelectedAttributes = attributeOrder
    .slice(0, attributeOrder.map(el => el.name).indexOf(attributeKey))
    .reduce(
      (attrs, attr) => {
        if (selectedAttributes.hasOwnProperty(attr.name)) {
          attrs[attr.name] = selectedAttributes[attr.name];
        }
        return attrs;
      },
      {} as Record<string, SelectedValue>
    );

  const variants = getVariantsWithAttributes(
    product,
    filteredSelectedAttributes
  );
  const attributes = collectAttributes(attributeOrder, variants);

  return attributes[attributeKey];
}

export type AttributeValueOrdering = Record<string, Array<SelectedValue>>;
export const mergeOrdering = (existing: Array<string>, current: Array<string>) => {
  // Find any newly added attributes, make sure values are unique.
  const existingSet = new Set(existing);
  const currentSet = new Set(current);

  // This filters out any attributes which have been renamed & effectively "removed" without deletion.
  const stillExisting = Array.from(existingSet).filter((x) => currentSet.has(x));

  // Determine and lexical sort the non-manually-sorted values.
  const unordered = Array.from(currentSet).filter((x) => !existingSet.has(x)).sort();

  // Append any new attributes, missing from the existing order, to the end.
  return [
    ...stillExisting,
    ...unordered,
  ];
}

export const collectAttributes = (attributes: Array<ProductAttribute>, variants: Array<Variant>): AttributeValueOrdering => {
  return attributes.flatMap((attribute): any => {
    const foundValues = variants.flatMap(variant => {
      return variant.attributeValues
        .filter(varAttr => varAttr.attributeId === attribute.id)
        .map(attr => ({ id: attr.id, name: attr.name, displayOrder: attr.displayOrder }));
    });

    const sortedValues = foundValues.sort((a, b) => a.displayOrder < b.displayOrder ? -1 : 1);

    return { [attribute.name]: sortedValues };
  }).reduce((obj, item): object => ({
    ...obj,
    [Object.keys(item).toString()]: Object.values(item).flat().filter((thing: any, i, arr: any[]) => arr.findIndex(t => t.id === thing.id) === i),
  }), {});
}

export function getValueCount(product: Product, key: keyof Product): number {
  let value = product[key];

  if (value instanceof Array) {
    return value.length;
  }

  if (key === 'description' && product[key]) {
    value = product[key].replace(/<[^>]*>/g, ' ');
  }

  if (typeof value === 'string') {
    return value
      .split(/\s+/)
      .map(word => word.trim())
      .filter(word => word.length > 0).length;
  }

  if (value) {
    return 1;
  }

  return 0;
}

export function getDuplicateVariants(variants: Variant[], selectableAttributeKeys?: string[]): Variant[][] {
  return variants
    .reduce((result: Variant[][], variant: Variant) => {
      let variantValues = variant.attributeValues
        ? variant.attributeValues.filter(value => value.isSelectable).sort((a: any, b: any) => a.code.localeCompare(b.code)).map(value => value.name)
        : [];

      if (selectableAttributeKeys) {
        variantValues = selectableAttributeKeys;
      }

      const existingGroup = result.find((group: any) => {
        const groupVariantValues = group[0].attributeValues ?
          group[0].attributeValues.filter((value: any) => value.isSelectable).sort((a: any, b: any) => a.code.localeCompare(b.code)).map((value: any) => value.name)
          : [];

        return Arr.arraysAreEqual(variantValues, groupVariantValues);
      });

      if (existingGroup) {
        existingGroup.push(variant);
        return result;
      }

      result.push([variant]);

      return result;
    }, [])
    .filter(group => group.length >= 2);
}

export function getAllAttributesWithKey(product: Product, attributesList: ProductAttribute[], attributeKey: number | null, variantKey?: string): any[] {
  const { variants } = product;

  if (attributeKey && attributesList.map(attribute => attribute.id).includes(attributeKey)) {
    return variants.map(variant => {
      const foundValue = variant.attributeValues.find(attributeValue => attributeValue.attributeId === attributeKey);

      return foundValue ? foundValue.name : '';
    });
  }

  if (variantKey) {
    return variants.map(variant => (variant as Record<string, any>)[variantKey]);
  }

  return [];
}

export function getAllUniqueAttributesWithKey(
  product: Product,
  attributesList: ProductAttribute[],
  attributeKey: number | null,
  variantKey?: string
): any[] {
  return getAllAttributesWithKey(product, attributesList, attributeKey, variantKey).filter(unique);
}

export function classifyAttributes(product: Product, attributesList: ProductAttribute[]): ProductAttribute[] {
  const attributes: any[] = [];

  const [nonUniqueAttributes, uniqueAttributes] = Arr.sift(
    attributesList,
    (attribute: ProductAttribute) => getAllUniqueAttributesWithKey(product, attributesList, attribute.id!).length > 1
  );

  let duplicateVariants = getDuplicateVariants(product.variants);

  while (nonUniqueAttributes.length > 0 && duplicateVariants.length > 0) {
    const attributeToSwitch = nonUniqueAttributes.shift();

    attributes.push(attributeToSwitch);
  }

  const classificationAttributes: any[] = nonUniqueAttributes.concat(uniqueAttributes).map(attribute => {
    attribute.isSelectable = false;

    return attribute;
  });

  const selectableAttributes = attributes.map(attribute => {
    attribute.isSelectable = true;

    return attribute;
  });

  return selectableAttributes
    .sort((a, b) => a.id > b.id ? 1 : -1)
    .concat(classificationAttributes.sort((a, b) => a.id > b.id ? 1 : -1));
}

export const getAttributesForVariant = (attributes: ProductAttribute[], attributeValues: Var.VariantAttributeValue[]): any => {
  //TODO: Remove code when flow is mature
  // if (attributeValues.length === 0) {
  //   return {};
  // }

  return attributes.reduce((obj, item): object => {
    const attributeValue = attributeValues.find(value => value.attributeId === item.id);

    return {
      ...obj,
      [item.name]: attributeValue ? attributeValue.name : ''
    }
  }, {});
}

// Extract product stock keys in main object


export const separateAttributes = (attributes: ProductAttribute[]): SeparateAttributes => {
  return {
    selectableAttributes: attributes.filter(attribute => attribute.isSelectable),
    classificationAttributes: attributes.filter(attribute => !attribute.isSelectable)
  }
}

export function updateAttributeValuesWithKey(
  product: Product,
  attributeUpdates: VariantUpdate[],
  newValue: Var.VariantAttributeValue | null = null,
  variantKey: string = ''
): Product {
  const variants = product.variants.map(variant => {
    return attributeUpdates.reduce((newVariant, update) => {
      const valueToChangeIndex = newVariant.attributeValues.findIndex(valueToChange => valueToChange.name === update.original);

      // Update attribute values
      if (newValue && valueToChangeIndex !== -1) {
        newVariant.attributeValues[valueToChangeIndex] = newValue;
        return newVariant;
      }

      // Update variant values
      if (variantKey && (newVariant as Record<string, any>)[variantKey] === update.original) {
        return {
          ...newVariant,
          [variantKey]: typeof update.original === 'number' ? parseFloat(update.new) : update.new
        };
      }

      return newVariant;
    }, variant);
  });

  return { ...product, variants };
}

// Update product variants product stock array
export function updateProductStocks(
  product: Product,
  variantIndex: number,
  newProductStocks: any[]
): Product {
  const variants = product.variants.map((variant, index) => {
    if (index === variantIndex) {
      return { ...variant, productStocks: newProductStocks };
    }

    return variant;
  });

  return { ...product, variants };
}

// Set product variant stock value by finding stock object by product stock id
export function setProductStockValue(
  product: Product,
  variantIndex: number,
  productStockId: number | null,
  key: string,
  value: string | number | boolean,
  warehouseId: number,
  warehouseType?: string
): Product {
  const variants = product.variants.map((variant, index) => {
    if (index === variantIndex) {

      // If product stock id is null, create new product stock object
      if (!productStockId) {
        if (!variant.productStocks?.find(ps => ps.warehouseId === warehouseId && ps.type === warehouseType)) {
          return {
            ...variant,
            dirty: true,
            productStocks: [
              ...variant.productStocks!,
              {
                ...emptyProductStock,
                warehouseId,
                type:warehouseType,
                [key]: value
              }
            ]
          };
        }
        else {
          // If product stock id is null and product stock object already exists, update product stock object
          return {
            ...variant,
            dirty: true,
            productStocks: variant.productStocks?.map((ps: any) => {
              if (ps.warehouseId === warehouseId) {
                ps[key] = value
              }
              return ps
            })
          };
        }
      }


      // If product stock id is not null, find product stock object by product stock id and update it
      if (productStockId) {
        let productStockIndex = variant.productStocks!.findIndex(productStock => productStock.id === productStockId);
        const productStock = variant.productStocks![productStockIndex] || {};
        return {
          ...variant,
          dirty: true,
          productStocks: Arr.set(variant.productStocks!, productStockIndex, { ...productStock, warehouseId, [key]: value })
        };
      }

    }

    return variant;
  });

  return { ...product, variants };
}

// Set variants stock value by finding stock object by product stock id
export function setVariantsProductStockValue(
  variants: Variant[],
  variantIndex: number,
  productStockId: number | null,
  key: string,
  value: string | number | boolean,
  warehouseId: number,
  warehouseType: string
): Variant[]{
  const variantsUpdated = variants.map((variant, index) => {
    if (index === variantIndex) {
      // If product stock id is null, create new product stock object
      if (!productStockId) {
        if (!variant.productStocks?.find(ps => ps.warehouseId === warehouseId)) {
          return {
            ...variant,
            dirty: true,
            productStocks: [
              ...variant.productStocks!,
              {
                ...emptyProductStock,
                warehouseId,
                type:warehouseType,
                [key]: value
              }
            ]
          };
        }
        else {
          // If product stock id is null and product stock object already exists, update product stock object
          return {
            ...variant,
            dirty: true,
            productStocks: variant.productStocks?.map((ps: any) => {
              if (ps.warehouseId === warehouseId) {
                ps[key] = value
              }
              return ps
            })
          };
        }
      }

      // If product stock id is not null, find product stock object by product stock id and update it
      if (productStockId) {
        let productStockIndex = variant.productStocks!.findIndex(productStock => productStock.stockId === productStockId);
        const productStock = variant.productStocks![productStockIndex] || {};
        return {
          ...variant,
          dirty: true,
          productStocks: Arr.set(variant.productStocks!, productStockIndex, { ...productStock, warehouseId, [key]: value })
        };
      }

    }

    return variant;
  });

  return variantsUpdated;
}

// Set product variant unit price value by finding unit price object by product variant price id
export function setProductVariantPriceValue(
  product: Product,
  variantIndex: number,
  productVariantPriceId: number | null,
  key: string,
  value: string | number | boolean,
  countryId: number,
): Product {
  const variants = product.variants.map((variant, index) => {
    if (index === variantIndex) {

      if (!productVariantPriceId) {
        if (!variant.productVariantPrices?.find(pvp => pvp.countryId === countryId)) {
          return {
            ...variant,
            dirty: true,
            productVariantPrices: [
              ...variant.productVariantPrices!,
              {
                ...emptyProductVariantPrice,
                countryId,
                [key]: value
              }
            ]
          };
        }
        else {
          return {
            ...variant,
            dirty: true,
            productVariantPrices: variant.productVariantPrices?.map((pvp: any) => {
              if (pvp.countryId === countryId) {
                pvp[key] = value
              }
              return pvp
            })
          };
        }
      }

      if (productVariantPriceId) {
        let productVariantPriceIndex = variant.productVariantPrices!.findIndex(productVariantPrice => productVariantPrice.id === productVariantPriceId);
        productVariantPriceIndex = productVariantPriceIndex === -1 ? 0 : productVariantPriceIndex;
        const productVariantPrice = variant.productVariantPrices![productVariantPriceIndex] || {};

        return {
          ...variant,
          productVariantPrices: Arr.set(variant.productVariantPrices!, productVariantPriceIndex, { ...productVariantPrice, countryId, [key]: value })
        };
      }
    }

    return variant;
  });

  return { ...product, variants };
}

export function setVariantPriceValue(
  variants: Variant[],
  variantIndex: number,
  productVariantPriceId: number | null,
  key: string,
  value: string | number | boolean,
  countryId: number,
): Variant[] {
  const variantsUpdated = variants.map((variant, index) => {
    if (index === variantIndex) {
      variant.dirty = true;
      
      if (!productVariantPriceId) {
        if (!variant.productVariantPrices?.find(pvp => pvp.countryId === countryId)) {
          return {
            ...variant,
            productVariantPrices: [
              ...variant.productVariantPrices!,
              {
                ...emptyProductVariantPrice,
                countryId,
                [key]: value
              }
            ]
          };
        }
        else {
          return {
            ...variant,
            productVariantPrices: variant.productVariantPrices?.map((pvp: any) => {
              if (pvp.countryId === countryId) {
                pvp[key] = value
              }
              return pvp
            })
          };
        }
      }

      if (productVariantPriceId) {
        let productVariantPriceIndex = variant.productVariantPrices!.findIndex(productVariantPrice => productVariantPrice.id === productVariantPriceId);
        productVariantPriceIndex = productVariantPriceIndex === -1 ? 0 : productVariantPriceIndex;
        const productVariantPrice = variant.productVariantPrices![productVariantPriceIndex] || {};

        return {
          ...variant,
          productVariantPrices: Arr.set(variant.productVariantPrices!, productVariantPriceIndex, { ...productVariantPrice, countryId, [key]: value })
        };
      }
    }

    return variant;
  });

  return variantsUpdated;
}


export function setAttribute(
  product: Product,
  variant: Variant,
  variantIndex: number,
  newValue: Var.VariantAttributeValue | null = null,
  attributeId: number = 0,
  variantKey: string = '',
  variantValue: string | number | boolean = ''
): Product {
  let newVariant = product.variants[variantIndex];
  newVariant.dirty = true;

  if (variantIndex !== -1) {
    const valueToChangeIndex = product.variants[variantIndex].attributeValues
      ? product.variants[variantIndex].attributeValues.findIndex(valueToChange => valueToChange.attributeId === attributeId)
      : -1;

    // Update attribute values
    if (newValue && valueToChangeIndex !== -1) {
      newVariant.attributeValues[valueToChangeIndex] = newValue;
    } else if (newValue) {
      newVariant.attributeValues.push(newValue);
    }

    // Update variant values
    if (variantKey) {
      newVariant = { ...newVariant, [variantKey]: variantValue, variantValidationStatus: variant.variantValidationStatus };
    }
  }

  return {
    ...product,
    variants: Arr.set(product.variants, variantIndex, newVariant)
  };
}

export function setVariantAttribute(
  variants: Variant[],
  variant: Variant,
  variantIndex: number,
  newValue: Var.VariantAttributeValue | null = null,
  attributeId: number = 0,
  variantKey: string = '',
  variantValue: string | number | boolean = ''
): Variant[] {
  let newVariant = variant;

  if (variantIndex !== -1) {
    const valueToChangeIndex = variants[variantIndex].attributeValues
      ? variants[variantIndex].attributeValues.findIndex(valueToChange => valueToChange.attributeId === attributeId)
      : -1;

    // Update attribute values
    if (newValue && valueToChangeIndex !== -1) {
      newVariant.attributeValues[valueToChangeIndex] = newValue;
    } else if (newValue) {
      newVariant.attributeValues.push(newValue);
    }

    // Update variant values
    if (variantKey) {
      newVariant = { ...newVariant, [variantKey]: variantValue };
    }
  }
  const variantsUpdate =  Arr.set(variants, variantIndex, newVariant)
  return variantsUpdate;
}

export async function countProductByName([vendorId, name]: any, { token }: any): Promise<ProductCount> {
  const endpoint = `v1/products/count${vendorId ? '?vendorId=' + vendorId : ''}${name ? '&name=' + name : ''}`;

  return await get(endpoint, token);
}
