import styled from 'styled-components';

export const DownloadLink = styled.a`
  &&& {
    display: inline-block;
    background-color: ${({ theme }) => theme.charcoal};
    color: white;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 700;
    padding: 12px 24px;
    font-size: 14px;
  }
`;
