import React from 'react';
import styled from 'styled-components';

const FlexboxContainer = styled.div<any>`
  display: ${({ displayFlex }) => displayFlex};
  flex-direction: ${({ flexDirection }) => flexDirection};
  flex-wrap: ${({ flexWrap }) => flexWrap};
  flex-flow: ${({ flexFlow }) => flexFlow};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};
  align-content: ${({ alignContent }) => alignContent};
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  max-width: ${({ maxWidth }) => maxWidth};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  background-color: ${({ bgColor }) => bgColor};
  border: ${({ border }) => border};
  border-color: ${({ borderColor }) => borderColor};
  border-radius: ${({ borderRadius }) => borderRadius};
  border-right: ${({ borderRight }) => borderRight};
  border-bottom: ${({ borderBottom }) => borderBottom};
  border-top: ${({ borderTop }) => borderTop};
  border-left: ${({ borderLeft }) => borderLeft};
  box-shadow: ${({ boxShadow }) => boxShadow};
  text-decoration: ${({ textDecoration }) => textDecoration};
  gap: ${({ gap }) => gap}
`;

interface Props {
  children: React.ReactNode;
  bgColor?: string;
  className?: string;
  margin?: string;
  maxWidth?: string;
  width?: string;
  minWidth?: string;
  height?: string;
  displayFlex?: string;
  flexDirection?: string;
  flexWrap?: string;
  flexFlow?: string;
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
  border?: string;
  borderColor?: string;
  borderRadius?: string;
  borderRight?: string;
  borderBottom?: string;
  borderTop?: string;
  borderLeft?: string;
  padding?: string;
  boxShadow?: string;
  textDecoration?: string;
  onClick?: () => void;
  gap?: string;
}

const FlexboxContainerComponent = ({
  children,
  bgColor,
  className,
  margin,
  maxWidth,
  width,
  minWidth,
  height,
  displayFlex,
  flexDirection,
  flexWrap,
  flexFlow,
  justifyContent,
  alignItems,
  alignContent,
  border,
  borderColor,
  borderRadius,
  borderRight,
  borderBottom,
  borderTop,
  borderLeft,
  padding,
  boxShadow,
  textDecoration,
  onClick,
  gap = '0px',
}: Props) => {
  const sharedProps = {
    className,
    bgColor,
    maxWidth,
    width,
    minWidth,
    height,
    margin,
    displayFlex,
    flexDirection,
    flexWrap,
    flexFlow,
    justifyContent,
    alignItems,
    alignContent,
    border,
    borderColor,
    borderRadius,
    borderRight,
    borderBottom,
    borderTop,
    borderLeft,
    padding,
    boxShadow,
    textDecoration,
    onClick,
    gap
  };

  return (
    <FlexboxContainer {...sharedProps}>
      {children}
    </FlexboxContainer>
  );
}

FlexboxContainer.defaultProps = {
  displayFlex: 'flex',
  textDecoration: 'none'
};

export default FlexboxContainerComponent;
