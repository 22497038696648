import React, { ReactNode, useEffect, useState } from 'react';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Modal from 'components/UI/Modal';
import Select from 'components/UI/Select';
import { Link } from '@material-ui/icons';
import styled from 'styled-components';
import Button from 'components/UI/Button';
import Vendor from 'data/Vendor';
import { Space } from 'styles/themeSpaces';
import Warehouse from 'data/Warehouse';
import InputComponent from 'components/UI/Input';
import { Autocomplete } from '@material-ui/lab';
import { Border, Text } from 'styles/themeColors';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { TextField, TextFieldProps } from '@material-ui/core';

const Label = styled.span<any>`
  font-size: 16px;
  line-height: 20px;
  color: ${({ $color }) => $color};
`;
const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

const AutocompleteStyled = styled(Autocomplete) <any> `
  .MuiOutlinedInput-notchedOutline {
    border-color: ${Border.main};
  }
  
  .MuiSvgIcon-root {
    color: ${Text.primary};
  }
`;

// can't be moved to autocomplete styles since the listbox is not a child of autocomplete
const autoCompleteClasses = makeStyles(() =>
  createStyles({
    listbox: {
      height: 250,
      overflowX: 'hidden'
    }
  })
);

interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm?: (value: string) => void;
  isLoading?: boolean;
  userType?: string;
  assigneeList: Vendor[];
  warehouses: Warehouse[];
  setAssignee: (assignee: Option) => void;
  setWarehouse: (warehouse: Option | undefined) => void;
  assignee?: Option;
  selectedVendor?: Vendor;
  assignedByError: boolean;
  warehouseError: boolean;
  warehouse?: Option;
}

interface Option {
  value: string;
  id?: string;
  icon?: ReactNode;
  badge?: any;
}

const NewGeographyPricebooksModal = ({
  show,
  onClose,
  onConfirm,
  assigneeList,
  warehouses,
  setWarehouse,
  setAssignee,
  assignee,
  selectedVendor,
  assignedByError,
  warehouseError,
  warehouse
}: Props) => {

  const [warehouseList, setWarehouseList] = useState<Option[]>([]);
  const [assignedByList, setAssignedByList] = useState<Option[]>([]);
  const [cancelModal, setCancelModal] = useState(false);
  const classes = autoCompleteClasses();

  const selectedWarehouseOption = typeof warehouse !== 'string' ? {
    value: warehouse?.value || '',
    id: warehouse?.value
  } as Option : undefined;

  useEffect(() => {
    const options = warehouses.map((warehouse: any) => ({
      value: warehouse.name,
      id: warehouse.id,
      description: warehouse.address?.country?.countryName || ''
    }));

    setWarehouseList([...options]);
  }, [warehouses]);

  useEffect(() => {
    const options = assigneeList.map((a: any) => ({
      value: a.name,
      id: a.code,
    }));
    setAssignedByList([...options]);
  }, [assigneeList]);


  const onSelectAssignee = (event: any) => {
    setAssignee(event.target);
  };

  const onSelectWarehouse = (event: any,newValue: Option) => {
    setWarehouse(newValue);
  }

  const onBlur = (event: any) => {
    if (!event.target.value.length) {
      setWarehouse && setWarehouse(undefined);
    }
  };

  return (
    <>
      <Modal
        width="794px"
        showModal={cancelModal ? false : show}
        onClose={onClose}
        showButtons={false}
        title="New geography pricebook"
        modalFooterMargin="0"
        left="50vw"
        marginLeft="-397px"
        iconName="PinDrop"
        showCloseIcon
      >
        <FlexboxContainerComponent flexDirection="column">
          <FlexboxContainerComponent
            flexDirection="row"
            width="100%"
            padding="90px 0px 0px 0px"
          >
            {!selectedVendor?.id && (
              <FlexboxContainerComponent flexDirection="column" width="100%">
                <FlexboxContainerComponent padding="0px 0px 8px 0">
                  {' '}
                  <Label $color={assignedByError ? "#C82135" : ""}>Assigned by vendor</Label>
                </FlexboxContainerComponent>
                <FlexboxContainerComponent
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                >
                  <Select
                    options={assignedByList}
                    onChange={onSelectAssignee}
                    value={assignee?.value}
                    defaultValue="Select"
                    width="100%"
                    height="36px"
                    isInvalid={assignedByError}
                  />
                  <Link style={{ height: '25', width: '30', color: '#0E141A' }} />
                </FlexboxContainerComponent>
              </FlexboxContainerComponent>
            )}
            <FlexboxContainerComponent flexDirection="row" width="100%">
              {!warehouseList ?
                <InputComponent
                  id='assigned-to'
                  labelName={'Assigned to warehouse'}
                  labelPosition='top'
                  value={warehouse?.value as string}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  }}
                  errorMessage=''
                  name='assigned-to'
                  blocked={true}
                ></InputComponent> :
                <FlexboxContainerComponent flexDirection="column" width="100%">
                  <FlexboxContainerComponent padding="0px 0px 8px 0">
                    {' '}
                    <Label $color={warehouseError ? "#C82135" : ""}>Assigned to warehouse</Label>
                  </FlexboxContainerComponent>
                  <FlexboxContainerComponent
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                  >

                    <AutocompleteStyled
                      classes={{ listbox: classes.listbox }}
                      id="assigned-to"
                      options={warehouseList}
                      getOptionLabel={(option: any) => option.value}
                      style={{
                        width: `100%`
                      }}
                      value={selectedWarehouseOption}
                      disableClearable={true}
                      onChange={onSelectWarehouse}
                      onBlur={onBlur}
                      renderInput={AutoCompleteInput}
                      renderOption={WarehouseOptionItem}
                    />
                  </FlexboxContainerComponent>
                </FlexboxContainerComponent>
              }
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>

          <FlexboxContainerComponent
            padding="104px 0 0 0"
            justifyContent="flex-end"
          >
            <Button id="modal-main-button" bgColor="#FC780B" onClick={onConfirm}>
              Next
            </Button>
          </FlexboxContainerComponent>
        </FlexboxContainerComponent>
      </Modal>
    </>);
};

export default NewGeographyPricebooksModal;

const AutoCompleteInput = (params: JSX.IntrinsicAttributes & TextFieldProps) => {
  return <TextField
    {...params}
    variant='outlined'
    inputProps={{
      ...params.inputProps,
      style: {
        padding: 0,
        textOverflow: 'ellipsis'
      },
      placeholder: 'Start typing warehouse name',
    }}
  />
};

const WarehouseOptionItem = (option: any) => (
  <div style={{ 
    display: 'flex',
    flexDirection: 'column',
    width:'100%'}}>
    <div
      style={
        { fontSize: '14px', fontWeight: 'bold' }
      }>
      {option.value}
    </div>
    <div style={{ fontSize: '12px', fontStyle: 'italic', color: Text.placeholder }}>
      {option.description}
    </div>
  </div>
)