import Ticket, { TicketStatus } from "data/Ticket";
import React, { useState } from "react";
import TicketProgress from "./TicketProgress";
import styled from "styled-components";
import { Background, Main } from "styles/themeColors";
import Banner from "components/UI/Banner";
import { FontSize } from "styles/themeSizes";
import { FontWeight, Size } from "lib/styles";
import Button from "components/UI/Button";
import FlexboxContainer from "components/UI/FlexboxContainer";
import { printComponent } from "utils/print";
import Icon from 'view/Icon';
import { parseConsignmentId } from "utils/consignment";

const Wrapper = styled.div`
   padding: 0 16px 16px 16px;
`;

interface TicketHistoryProps {
    consignmentId: string;
    tickets: Ticket[];
    deliveryType?: string;
}

const UnderlinedButton = styled.button`
  background: none;
  border: none;
  color: ${Main.accent};
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  margin: 20px 0px 0px 0px;

  &:hover {
    text-decoration: none;
  }
`;

const Heading = styled.div`
  font-size: ${FontSize.big};
  font-weight: ${FontWeight.bold};
  padding: 0px 16px 16px 16px;
`;

const PrintIcon = styled(Icon).attrs({ name: 'Print', size: 30 })``;

const TicketHistory: React.FC<TicketHistoryProps> = (props) => {
    const { consignmentId, tickets, deliveryType  } = props;

    const [isExpanded, setIsExpanded] = useState(false);

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    if (!tickets?.length) return null;

    const isClosed = tickets[0].entries[0].status !== TicketStatus.CLOSED;

    return (
        <Banner
            boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
            bgColor={Background.white}
        >
            <FlexboxContainer justifyContent='space-between'>
                <Heading>Ticket history</Heading>
                {
                    isClosed &&
                    <Button 
                        onClick={() => printComponent('ticket-history-printable-content-0', `Ticket history ${parseConsignmentId(consignmentId)}`, 'Ticket History')}
                        leftIcon={() => <PrintIcon />}
                        background="white"
                        borderColor="white"
                    />
                }
            </FlexboxContainer>
            
            <Wrapper>
                {!isExpanded && (
                    <TicketProgress
                        ticketEntries={tickets[0].entries}
                        showExpand={false}
                        consignmentId={consignmentId}
                        itemKey={0}
                        deliveryType={deliveryType}
                        ticketId={tickets[0].code}
                    />
                )}

                {isExpanded &&
                    tickets?.map((ticket, index) => {
                        const isClosed = ticket.status === TicketStatus.CLOSED.toString();

                        return (
                            <TicketProgress
                                ticketEntries={ticket?.entries}
                                key={index}
                                alreadyExpanded={!isClosed}
                                showExpand={isClosed}
                                ticketId={ticket.code}
                                margin={isClosed && index !== 0 ? '16px 0px' : '0px'}
                                consignmentId={consignmentId}
                                itemKey={index}
                                deliveryType={deliveryType}
                            />
                        );
                    })
                }

                {
                    (!isClosed && tickets.length === 1)  || (tickets.length === 1 && tickets[0]?.entries.length === 1) ? null :
                    <UnderlinedButton className="expand" onClick={toggleIsExpanded}>{isExpanded ? "Collapse" : "Expand"}</UnderlinedButton>
                }

                {
                    tickets?.map((ticket, index) => {
                        const isClosed = ticket.status === TicketStatus.CLOSED.toString();
                        return (
                            <div id={`ticket-history-printable-content-${index}`} style={{display:'none'}}>
                                <TicketProgress
                                    ticketEntries={ticket?.entries}
                                    key={index}
                                    alreadyExpanded={true}
                                    ticketId={ticket.code}
                                    showExpand={false}
                                    margin={isClosed ? '16px 0px' : '0px'}
                                    showAsOpened
                                    showDocuments={false}
                                    consignmentId={consignmentId}
                                    itemKey={index}
                                    deliveryType={deliveryType}
                                />
                            </div>
                        );
                    })
                }

            </Wrapper>
    </Banner>
    );
}

export default React.memo(TicketHistory);