import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { VendorCompanyInfo } from 'data/VendorCompany';
import VendorCompanyCard from 'components/Company/VendorCompanyCard';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { Space } from 'styles/themeSpaces';
import { Text, Border, Background } from 'styles/themeColors';
import { isDateOlderThan } from 'utils/date';
import { formatDateTime } from 'utils/formatters';

const TextSecondary = styled.span`
  color: ${Text.secondary} !important;
  margin-top: ${Space.spBasePlus};
`;

interface Props {
  className?: string
  vendorCompanyId: string;
  assignedCompanyName: string;
  lastUpdated: string;
  vendorCompanyDetails: VendorCompanyInfo;
  isConnected?: boolean;
  isInvalid: boolean;
  hasVendorInterface: boolean;
  setIsInvalid: (value: boolean) => void;
  onClick: (value: string) => void;
};

const CompanyUIDCard = ({
  className,
  vendorCompanyId,
  assignedCompanyName,
  lastUpdated,
  vendorCompanyDetails,
  isConnected,
  isInvalid,
  hasVendorInterface,
  setIsInvalid,
  onClick
}: Props) => {
  const [value, setValue] = useState(vendorCompanyId);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    if (vendorCompanyId) {
      setValue(vendorCompanyId);
    }
  }, [vendorCompanyId]);

  const vendorMessage = hasVendorInterface
    ? 'Specify your source system ID to enable integration and features like Credit check'
    : 'Set your own unique ID for the company, e.g. your ERP system ID';

  const errorMessage = isInvalid
    ? `The ID is already assigned by your company to ${assignedCompanyName}. Please use a unique one`
    : 'ID is required';

  const connectionStatus = lastUpdated && isDateOlderThan(lastUpdated, 1)
    ? `Your source system offline since ${formatDateTime(lastUpdated, true)}`
    : 'Your source system connected';

  const connectionBgColor = lastUpdated && isDateOlderThan(lastUpdated, 5)
    ? 'error'
    : lastUpdated && isDateOlderThan(lastUpdated, 1)
      ? 'warning'
      : 'success';

  const onAssignClick = () => {
    setDirty(true);

    if (value) {
      onClick(value);
    }
  };

  const onInputChange = (value: string) => {
    setIsInvalid(false);
    setValue(value);
  };

  return (
    <FlexboxContainer
      className={`border ${className}`}
      width="512px"
      flexDirection="column"
      padding={Space.sp2x}
      margin={`0 ${Space.spHalf} 0 0`}
      borderColor={`${Border.light} !important`}
      bgColor={Background.light}
    >
      <h5>Company Unique Identifier</h5>
      <TextSecondary className="dense-italic">
        {vendorMessage}
      </TextSecondary>
      <FlexboxContainer
        justifyContent="space-between"
        alignItems="center"
        height="66px"
        margin={`${Space.spBasePlus} 0`}
      >
        <div className="base">Company UID</div>
        <Input
          id="companyUID"
          placeholder=""
          value={value}
          width="70%"
          errorMessage={errorMessage}
          blocked={isConnected}
          dirty={dirty}
          isInvalid={isInvalid}
          onChange={e => onInputChange(e.target.value)}
          required
        />
      </FlexboxContainer>
      {
        isConnected && vendorCompanyDetails.companyName
          ? (
            <VendorCompanyCard
              isConnected={isConnected}
              vendorCompanyId={vendorCompanyId}
              connectionStatus={connectionStatus}
              connectionBgColor={connectionBgColor}
              vendorCompanyDetails={vendorCompanyDetails}
            />
          )
          : null
      }
      <FlexboxContainer justifyContent={isConnected ? 'flex-start' : 'flex-end'}>
        {
          isConnected
            ? (
              <div>
                Want to change UID? Please <a href="mailto: customerservice@theofmp.com">contact OFMP support</a>
              </div>
            )
            : <Button onClick={onAssignClick}>Assign</Button>
        }
      </FlexboxContainer>
    </FlexboxContainer>
  );
};

CompanyUIDCard.defaultProps = {
  vendorCompanyId: '',
  isConnected: false
};

export default CompanyUIDCard;
