import React, { useState, useEffect, useRef } from 'react';
import { usePath } from 'hookrouter';
import styled from 'styled-components';
import Vendor from 'data/Vendor';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import RadioButton from 'components/UI/RadioButton';
import Select from 'components/UI/Select';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Main, Text, Background } from 'styles/themeColors';
import adminImage from 'images/vendorSelector/admin.png';
import { Color } from 'lib/styles';

export interface Props {
  isVendor: boolean;
  setIsVendor: React.Dispatch<React.SetStateAction<boolean>>;
  vendors: Vendor[];
  updateVendor: (name: string) => void;
  selectedVendor: Partial<Vendor>;
  hideVendorSelectorModal: () => void;
};

const StyledVendorSelectorModal = styled.div<any>`
  position: fixed;
  width: 500px;
  height: 500px;
  background-color: ${Background.navy};
  left: calc(100vw - 510px);
  bottom: 0;
  padding: 0 20px;
  border-radius: 5px 5px 0 0;
  z-index: 100;
`;

const Header = styled.p`
  color: ${Text.whitePrimary};
  font-size: 35px;
  font-weight: bold;
  margin-top: 0;
`;

const StyledSpan = styled.span`
  color: ${Main.brandDark};
`;

const Container = styled(FlexboxContainer)`
  background-color: ${Background.white};
  margin-bottom: 15px;
  width: 100%;
  border-radius: 4px;
`;

const StyledInput = styled(Input)`
  padding: 10px 10px 0 10px;
  background-color: white
`;

const CancelButton = styled(Button)`
  background-color: ${Background.white};
  color: ${Color.black} !important;
`;

const StyledVendorSelectorLogo = styled.div<any>`
  width: 100px;
  height: 102px;
  background-color: ${Main.brandDark};
  margin: -3rem 0 auto 0;
  background: url(${({ vendorImage }) => vendorImage}) 0% 0% no-repeat padding-box;
  background-size: contain;
  margin-left: auto;
`;

const VendorSelectorModal = ({
  isVendor,
  setIsVendor,
  vendors,
  updateVendor,
  selectedVendor,
  hideVendorSelectorModal
}: Props) => {
  const path = usePath();
  const [vendorInputDisabled, setVendorInputDisabled] = useState(path.startsWith('/products/') && !(path.endsWith('ready') || path.endsWith('published') || path.includes('new') || path.includes('/page')));
  const [currentVendor, setCurrentVendor] = useState<string | null>(selectedVendor && selectedVendor.name ? selectedVendor.name : null);

  const inputEl = useRef<HTMLInputElement | null>(null);

  const vendorsList = vendors.map(vendor => ({ value: vendor.name }));
  
  const [vendorImage, setVendorImage] = React.useState<string>(''); // State to hold the image URL

  React.useEffect(() => {
    // Dynamically import the image based on the selected vendor code
    if(selectedVendor && selectedVendor.code) {
      import(`images/vendorSelector/${selectedVendor.code}.png`)
        .then((image) => {
          setVendorImage(image.default)
        })
        .catch((error) => {
          console.error('Error loading image:', error);
          setVendorImage(adminImage);
        });
    } else {
      setVendorImage(adminImage);
    }
  }, [selectedVendor]);

  useEffect(() => {
    setIsVendor(!!selectedVendor);
  }, []);

  const selectAdmin = () => {
    setIsVendor(false);

    setCurrentVendor(null);
  };

  const selectVendor = () => {
    setIsVendor(true);

    if (inputEl.current) {
      inputEl.current.focus();
    }
  };

  const onSelectValue = (event: any) => {
    const foundVendor = vendors.find(vendor => vendor.name === event.target.value);

    if (foundVendor) {
      setCurrentVendor(foundVendor.name);
    }
  };

  const onSaveClick = () => {
    updateVendor(currentVendor ? currentVendor : '');
    hideVendorSelectorModal();
  };

  return (
    <StyledVendorSelectorModal>
      <FlexboxContainer justifyContent="space-between" alignItems="center">
        <Header>
          View as
          <StyledSpan>{isVendor ? ' vendor' : ' admin'}</StyledSpan>
        </Header>
        <StyledVendorSelectorLogo vendorImage={vendorImage} />
      </FlexboxContainer>

      <Container>
        <RadioButton
          id='user-id'
          name="user"
          onChange={() => selectAdmin()}
          value="Admin"
          checked={!isVendor}
          disabled={vendorInputDisabled}
        />
        <StyledInput
          id='admin-id'
          value="Admin"
          type="text"
          disabled
          disabledBgColor="white"
        />
      </Container>
      <Container height="4em" margin="0 0 10px 0">
        <RadioButton
          id='user-radio-button-id'
          name="user"
          onChange={() => selectVendor()}
          value="Vendor"
          checked={isVendor}
          disabled={vendorInputDisabled}
        />
        <Select
          options={vendorsList}
          value={currentVendor ? currentVendor : ''}
          defaultValue="Select vendor"
          onChange={onSelectValue}
          disabled={vendorInputDisabled || !isVendor}
          noBorder
          width="100%"
          height='55px'
          noBoxShadow
        />
      </Container>
      <FlexboxContainer justifyContent="center" alignItems="flex-end">
        <CancelButton margin="0 10px 0 0" onClick={hideVendorSelectorModal} borderColor="transparent">Cancel</CancelButton>
        <Button onClick={onSaveClick} borderColor="transparent">Save</Button>
      </FlexboxContainer>
    </StyledVendorSelectorModal>
  );
};

export default VendorSelectorModal;
