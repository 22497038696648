import Checkbox from "components/UI/Checkbox";
import FlexboxContainerComponent from "components/UI/FlexboxContainer";
import RadioList from "components/UI/RadioList";
import FeatureFlag from "data/FeatureFlag";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Main, Text } from "styles/themeColors";

const CheckboxContainer = styled.div`
    margin-top: 20px;
`

const Description = styled.p<{ disabled?: boolean }>`
    margin: 0;
    color: ${({ disabled }) => (disabled ? Text.disabled : Text.primary)};
`

export const VENDOR_OPTIONS = {
    SLB_VENDOR: 'SLB_VENDOR',
    NON_SLB_VENDOR: 'NON_SLB_VENDOR',
};

export const VENDOR_OPTIONS_LABELS = {
    [VENDOR_OPTIONS.SLB_VENDOR]: 'SLB Vendors',
    [VENDOR_OPTIONS.NON_SLB_VENDOR]: 'Non-SLB Vendors',
};

interface AutomationSettingsProps {
    disabled: boolean;
    vendor: any;
    setVendor: (vendor: any) => void;
    featureFlags: FeatureFlag
}

const AutomationSettings: FunctionComponent<AutomationSettingsProps> = (props) => {
    const { disabled, vendor, setVendor } = props;
    const [selectedVendorOption, setSelectedVendorOption] = useState(vendor.isSlbVendor ? VENDOR_OPTIONS.SLB_VENDOR : VENDOR_OPTIONS.NON_SLB_VENDOR);

    // Update the vendor object when the selected option changes
    useEffect(() => {
        setVendor({ ...vendor, isSlbVendor: selectedVendorOption === VENDOR_OPTIONS.SLB_VENDOR });
    }, [selectedVendorOption]);

    const onChangeMaterialApi = (isMaterialApiEnabled: boolean) => {
        setVendor({ ...vendor, isMaterialApiEnabled: isMaterialApiEnabled, isStockApiEnabled: false });
    }

    const onChangeStockApi = (isStockApiEnabled: boolean) => {
        setVendor({ ...vendor, isStockApiEnabled });
    };
    
    const onChangeSalesApi = (isSalesApiEnabled: boolean) => {
        setVendor({ ...vendor, isSalesApiEnabled });
    };

    return (
        <FlexboxContainerComponent flexDirection="column">
            <RadioList
                isDisabled={disabled}
                options={VENDOR_OPTIONS_LABELS}
                selectedOption={selectedVendorOption}
                setSelectedOption={setSelectedVendorOption}
                color={Main.brandLight}
            />

            <CheckboxContainer>
                <Checkbox
                    id="material-api-check"
                    name="isMaterialApiEnabled"
                    disabled={disabled}
                    label="Material API"
                    checked={vendor.isMaterialApiEnabled}
                    appearance="none"
                    checkboxSize="s"
                    onChange={onChangeMaterialApi}
                    theme={'brandLight'}
                    bgColor={Main.brandLight}
                />

                <Description>
                    This API helps you stay organized by tracking all the products used by your company via unique identifier, SKU number. However, if you prefer, you can still revert to the manual process by deselecting the same checkbox.
                </Description>

            </CheckboxContainer>
                
           {
            (props.featureFlags.stocks && props.featureFlags.stocks.enabled) ? 
            <CheckboxContainer>
            <Checkbox
                id="Stock-api-check"
                name="isStockApiEnabled"
                disabled={!vendor.isMaterialApiEnabled || disabled}
                label="Stock API"
                checked={vendor.isStockApiEnabled}
                appearance="none"
                checkboxSize="s"
                onChange={onChangeStockApi}
                theme={'brandLight'}
                bgColor={Main.brandLight}
            />

            <Description disabled={!vendor.isMaterialApiEnabled}>
                This API helps you automate your inventory management. However, if you prefer, you can still revert to the manual process by deselecting the same checkbox.
            </Description>
            </CheckboxContainer> : null
           }

           {
            (props.featureFlags.salesOrder && props.featureFlags.salesOrder.enabled) &&
            <CheckboxContainer>
            <Checkbox
                id="Sales-api-check"
                name="isSalesApiEnabled"
                disabled={disabled}
                label="Sales Order API"
                checked={vendor.isSalesApiEnabled}
                appearance="none"
                checkboxSize="s"
                onChange={onChangeSalesApi}
                theme={'brandLight'}
                bgColor={Main.brandLight}
            />

            <Description >
            This API helps you automate sales order creation. However, if you prefer, you can still revert to the manual process by deselecting the same checkbox.
            </Description>
            </CheckboxContainer>
           }

        </FlexboxContainerComponent>
    );
}

export default AutomationSettings;
