import React from 'react';
import styled, { css } from 'styled-components';
import { Color, Size } from 'lib/styles';
import { Background, Main } from 'styles/themeColors';

const Container = styled.div<any>`
  background-color: ${Background.white};
  border-radius: 6px;
  box-shadow: 0px 0px ${Size.medium} ${Color.shade};
  margin-top: ${Size.small};
  padding: ${Size.small};
  position: absolute;
  width: 100%;
  z-index: 200;
  overflow-y: scroll;

  ${props => props.maxHeight && css`
    max-height: ${props.maxHeight}px;
    overflow-y: auto;
  `}

  ${props => props.direction === 'top' && css`
    bottom: calc(0.5rem + 100%);
  `}
`;

const CurrentIndicator = styled.div<any>`
  background-color: ${Main.accent};
  border-radius: 50%;
  height: 0.5em;
  margin: 0px ${Size.small};
  width: 0.5em;

  ${({ current }) =>
    (current && css`
      opacity: 1;
    `) ||
    css`
      opacity: 0;
    `}
`;

const OptionItem = styled.div`
  align-items: center;
  display: flex;
  padding: ${Size.small} ${Size.medium} ${Size.small} 0px;

  ${({ onClick }) => onClick && css`
    cursor: pointer;
  `}

  &:hover {
    background-color: ${Color.whiteSmoke};
  }
`;

interface BaseOption {
  onClick?: () => void;
};

export interface Option extends BaseOption {
  title: string;
};

interface Props<T, U = T> {
  className?: string;
  maxHeight?: number;
  onClick?: OnClick<T>;
  options: T[];
  value?: U;
  direction?: string;
};

interface PropsCustomRender<T> extends Props<T> {
  renderOption: RenderOption<T>;
};

type OnClick<T> = (option: T) => void;
type RenderOption<T> = (option: T) => React.ReactNode;

function isCustomRender<T>(
  props: Record<string, any>
): props is PropsCustomRender<T> {
  return props.renderOption !== undefined;
};

function renderCustomOptions<T extends BaseOption>(
  options: T[],
  renderOption: RenderOption<T>,
  value?: T,
  onClick?: OnClick<T>
) {
  return options.map((option, index) => {
    let indicator = null;

    if (value) {
      indicator = <CurrentIndicator current={option === value} />;
    }

    let optionOnClick = option.onClick;

    if (!optionOnClick) {
      optionOnClick = () => onClick && onClick(option);
    }

    return (
      <OptionItem key={index} onClick={optionOnClick}>
        {indicator}
        {renderOption(option)}
      </OptionItem>
    );
  });
};

function renderOptions(
  options: Option[],
  value?: string,
  onClick?: OnClick<Option>
) {
  return options.map((option, index) => {
    let indicator = null;

    if (value) {
      indicator = <CurrentIndicator current={option.title === value} />;
    }

    let optionOnClick = option.onClick;

    if (!optionOnClick) {
      optionOnClick = () => onClick && onClick(option);
    }

    return (
      <OptionItem key={index} onClick={option.onClick}>
        {indicator}
        {option.title}
      </OptionItem>
    );
  });
};

export default function Popover<T extends BaseOption>(
  props: Props<Option, string> | PropsCustomRender<T>
) {
  const optionItems = isCustomRender<T>(props)
    ? renderCustomOptions(
      props.options,
      props.renderOption,
      props.value,
      props.onClick
    )
    : renderOptions(props.options, props.value, props.onClick);
  return (
    <Container className={props.className} maxHeight={props.maxHeight} direction={props.direction}>
      {optionItems}
    </Container>
  );
};

Popover.defaultProps = {
  direction: 'bottom'
};
