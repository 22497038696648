export default interface Attribute {
  id: number;
  name: string;
  code: string;
  order: number;
  values: AttributeValue[];
};

export interface RequestAttribute {
  name: string;
  order: number;
  values: RequestAttributeValue[];
};

export type RequestAttributeValue = Omit<Attribute, 'code' | 'values'>;

export type AttributeValue = Omit<Attribute, 'values'> & { attributeId: number };

export type AttributeValueRequest = {
  id: number,
  attributeId: number,
  code: string,
  name: string,
  displayOrder: number
};

export type AttributeImportResponse = {
  result: AttributeValueRequest[],
  counts: any
}

export type PricebookVendorId = {
  PricebookVendorId: string
};

export type MergeRequest = {
  mergeInId: number,
  code: string,
  name: string,
  mergeFromId: number
};

export type ValueOrder = {
  id: number,
  displayOrder: number
};

export type AttributeOrder = {
  id: number,
  displayOrder: number,
  values: ValueOrder[]
};

export type ReorderRequest = {
  attributes: AttributeOrder[]
};

export const empty: Attribute = {
  id: 0,
  name: '',
  code: '',
  order: 0,
  values: []
};
