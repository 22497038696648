export default interface ProductVariantPrice {
    id?: number;
    productVariantId?: number;
    pricePerUnit?: number;
    itemsPerUnit?: number;
    currencyId?: number;
    countryId?: number;
    minRange?: number;
    maxRange?: number;
    priceType?: string;
    priceId?: number;
}

export const empty: ProductVariantPrice = {
    pricePerUnit: 0,
    itemsPerUnit: 1
};