import React from 'react';

import { Card, Heading1, Heading4, Paragraph } from 'OnboardingComponents/Elements';


const Welcome = () => {
  return (
    <Card>
      <Heading1>Welcome to OFMP</Heading1>
      <Heading4>Make your presence on TheOFMP.com known</Heading4>
      <Paragraph>Now that you’ve joined OFMP (Welcome, welcome <span role="img" aria-label="Party Popper Emoji">🎉</span>), you’re probably wondering how you can best communicate the availability of your products online, for purchase, to your customers – both current and prospective.</Paragraph>
      <Paragraph>There are many tools out there for promoting your brand and upping your digital sales, we know, so we’re making things a bit easier for you from the get-go.</Paragraph>
      <Paragraph>In the following sections, you will find some of our recommendations, along with pre-designed materials that you can use, for best communicating your products’ online availability to your target audience(s).</Paragraph>
      <Paragraph>Implement these materials as they are or use them as inspiration when creating your own branded communications. All we ask is that you respect our Brand & Communication Guidelines when utilizing the OFMP brand in your bespoke creations.</Paragraph>
      <Paragraph>And be sure to reach out to our marketing team should you have any questions or requests <span role="img" aria-label="Note Emoji">📝</span> We’ve built a rather sophisticated digital marketing engine in the oil and gas space, and might have just the answers you’re looking for.</Paragraph>
      <Paragraph>Happy selling and, once again, welcome to the family!</Paragraph>
    </Card>
  );
};

export default Welcome;
