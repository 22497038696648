export const MAX_CITY_LENGTH = 35;
export const MAX_STREET_LENGTH = 100;
export const MAX_FULLNAME_LENGTH = 100;
export const MAX_NICKNAME_LENGTH = 255;
export const MAX_ID_LENGTH = 50;
export const MAX_WAREHOUSE_FIELD_LENGTH = 50;
export const MAX_COMMENT_LENGTH = 512;
export const MAX_WAREHOUSE_SAP_PLANTID_LENGTH = 10;
export const MAX_WAREHOUSE_SALES_ORG_LENGTH = 10;
export const MAX_WAREHOUSE_SUB_SEGMENT_LENGTH = 10;
export const MAX_WAREHOUSE_PROJECT_LENGTH = 25;


export function validate<T>(
  subject: any,
  predicates: ((r: any) => T | null)[]
): [boolean, T[]] {
  const fails = predicates.reduce(
    (fs, predicate): T[] => {
      const result = predicate(subject);
      if (result !== null) return [...fs, result];
      return fs;
    },
    [] as T[]
  );

  return [fails.length === 0, fails];
}

export const validateEmail = (value: string) => {
  const regEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

  if (!regEx.test(value)) {
    return false;
  }

  return true;
};

export const validateCommaSeparatedEmails = (value: string) => {
  const regEx = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;

  const result = value.replace(/\s/g, '').split(/,/);

  if (result.some(email => !regEx.test(email))) {
    return false;
  }

  return true;
};

export const validatePostalCode = (postalCode: string, countryId?: number) => {
  const postalCodePatternUS = /^\d{5}(-\d{4})?$/;
  const postalCodePatternCA = /^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/;
  const isCanada = String(countryId) === '40';
  const selectedPostalCodePattern = countryId && isCanada ? postalCodePatternCA:  postalCodePatternUS;
  return selectedPostalCodePattern.test(postalCode);
}

export const validateWarehousePosField = (field: string, maxLength: number) => {
  return field.length <= maxLength
}

export const validateComment = (comment: string, maxLength: number) => {
  return comment.length > maxLength;
}