import React from 'react';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Icon from 'view/Icon';
import { XLS_TEXT_CONSTANTS } from 'constants/productVariantImport';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

const ButtonIcon = styled(Icon).attrs({ size: 16 })`
  color: #666c73;
`;

const DownloadTemplate = styled.div`
  display: flex;
  height: 36px;
  background-color: transparent !important;
  color: #666c73 !important;
  border-color: #666c73 !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;

const DownloadXLS = ({ attribute }) => {
  const getVariantAttributes = () => {
    const data = {};
    data[attribute?.name] = '';
    return data;
  };

  const createColumnsData = (variantAttributes) => {
    const dataArray = [];
    const key = Object.keys(variantAttributes)[0];
    for (let i = 1; i <= 10; i++) {
      dataArray.push({ [key]: `a${i}` });
    }
    return dataArray;
  };

  const handleDownload = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Variants');

    const variantAttributes = getVariantAttributes();
    const columnsData = createColumnsData(variantAttributes);

    // Add column headers
    worksheet.columns = Object.keys(variantAttributes).map(key => ({
      header: key,
      key: key,
      width: 50
    }));

    // Style the header
    worksheet.getRow(1).eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFB3FFB3' }
      };
      cell.font = { size: 10 };
      cell.alignment = { horizontal: 'center', vertical: 'bottom', wrapText: true };
    });

    // Add data
    columnsData.forEach(row => {
      worksheet.addRow(row);
    });

    // Style data cells
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber > 1) { // Skip header row
        row.eachCell((cell) => {
          cell.alignment = { vertical: 'center', horizontal: 'center', wrapText: true };
          cell.font = { size: 10 };
        });
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${attribute.name || XLS_TEXT_CONSTANTS.OFMP_TEMPLATE}.xlsx`);
  };

  return (
    <div>
      <DownloadTemplate onClick={handleDownload}>
        <FlexboxContainerComponent>
          <ButtonIcon name="FileDownloadIcon" />
          <FlexboxContainerComponent>
            {XLS_TEXT_CONSTANTS.DOWNLOAD_XLXS}
          </FlexboxContainerComponent>
        </FlexboxContainerComponent>
      </DownloadTemplate>
    </div>
  );
};

export default DownloadXLS;