import FeatureFlag from 'data/FeatureFlag';

export const filterDowJonesRelations = (
  flags: FeatureFlag, 
  value: string = ''
) => {
  const dowJonesRelations = ['Compliance check', 'Compliance check failed'];
  const isDowJonesRelated = dowJonesRelations.includes(value);

  return (isDowJonesRelated)
    ? flags.dowJones && Boolean(flags.dowJones.enabled)
    : true;
};