import styled from 'styled-components';
export { GlobalStyle } from './GlobalStyles';
export { ArticleContainer } from './ArticleContainer';
export { Card } from './Card';
export { GridContainer, GridItem } from './Grid';
export { Img } from './Img';
export { LeftNav, NavList, NavItem, NavLink as StyledNavLink, StandardLink } from './Nav';
export { Heading1, Heading2, Heading3, Heading4, Heading5, Paragraph } from './Typography';
export { CardCallout, GridItemCallout } from './Callouts';
export { DownloadLink } from './DownloadLink';
export { InlineLink } from './InlineLink';
export { List, ListItem } from './List';

export const HorizontalRule = styled.hr`
  border: none;
  background-color: ${({ theme }) => theme.grey};
  display: block;
  height: 4px;
  margin-top: 48px;
  margin-bottom: 48px;
`;

export const Main = styled.main`
  max-width: 944px;
  margin: auto;
  padding: 1.143rem;
`;

export const ContentPanel = styled.div`
  flex: 1;
  background-color: white;
`;

export const PageLayout = styled.div`
  max-width: 1164px;
  margin: auto;
`;

