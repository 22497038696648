import React, { Fragment } from 'react';
import { Heading2, Paragraph } from 'OnboardingComponents/Elements';

const MultiBrand = () => (
  <Fragment>
    <Heading2 id='multi-brand'>Multi-brand</Heading2>
    <Paragraph>For those sellers that have already published or plan on publishing products from multiple proprietary brands, it is important to consider how those brands collectively promote their availability on The OFMP. </Paragraph>
    <Paragraph>Our suggestion is, first and foremost, to establish a clear tone of voice for your umbrella brand. This will ensure that any messaging stemming from your subsidiary brands aligns with the overarching themes and goals of the parent company (see our Product </Paragraph>
    <Paragraph>Guidelines for an example of how this plays out across your Product Description Pages). It will also allow the unique communications of each proprietary brand to carry that unifying thread and build off of each other’s promotional campaigns in a consistent way.</Paragraph>
    <Paragraph>The second step is to think of your audience for each subsidiary brand and pinpoint any overlaps. Go back to the results of the aforementioned tone of voice exercise and confirm that your distinct brands are complementary in their communications towards similar audiences. </Paragraph>
    <Paragraph>The last step, before we move on to each channel in particular, is design. Take a good amount of time to consider how various design elements communicate your unified messaging strategy while simultaneously adhering to the unique personality of each subsidiary brand. Here, you can either decide to apply the same design language across all brand communications or to simply attach small design elements relating to your seller’s voice to the distinct visuals of all your brands. The choice is yours. </Paragraph>
    <Paragraph>Once you’ve established all of the above, create a document that outlines your strategy and depicts clearly how your departments should be using it to create their materials, speak to customers, and promote your seller’s voice. How long or short this document remains is up to you – important is that it exists and can be distributed internally.</Paragraph>
  </Fragment>
);

export default MultiBrand;
