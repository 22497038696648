import React, { useEffect, useState } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import styled, { css } from 'styled-components';
import { AttributesOrderType, TempAttribute } from 'common/AttributesEditor';
import Icon from 'view/Icon';
import { Color, Size } from 'lib/styles';
import { ProductAttribute } from 'data/Product';
import Attribute from 'data/Attribute';
import { Main, Background } from 'styles/themeColors';

const AddAttributeButton = styled.button`
  align-self: center;
  background: ${Main.accent};
  border: none;
  border-radius: 50%;
  padding: 0;
  height: 20px;
  margin-left: auto;
  margin-right: ${Size.small};
  text-align: center;
  width: 20px;
`;

const AddAttributeIcon = styled(Icon).attrs({ name: 'plus', size: 18 })`
  color: ${Background.white};
`;

const AttributeDiv = styled.div`
  align-items: center;
  background-color: ${Background.white};
  border: 1px solid ${Main.accent};
  border-radius: 3px;
  display: flex;
  width: 100%;
`;

const AttributeName = styled.input`
  border: none;
  font-size: inherit;
  flex-grow: 1;
  padding: ${Size.small};
`;

const Attributes = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  &:first-child {
    padding-right: ${Size.medium};
  }

  &:last-child {
    padding-left: ${Size.medium};
  }
`;

const AttributeWrapper = styled.div<any>`
  padding: ${Size.tiny} 0;
  transition: filter 0.1s, opacity 0.1s;

  ${props => props.isDragging && css`
    filter: grayscale(100%);
    opacity: 0.8;
  `}
`;

const IconButton = styled.button`
  background: none;
  border: none;
  align-self: stretch;
  padding: 0 ${Size.small};
`;

const DeleteIcon = styled(Icon).attrs({ name: 'trash-2', size: 18 })`
  color: ${Main.error};
  margin-left: auto;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
    background: lightred;
  }
`;

const DeleteIconMessage = styled(Icon).attrs({ name: 'trash-2', size: 22 })`
  color: ${Main.error};
  margin-left: auto;
  margin-right: 10px;

  &:hover {
    cursor: pointer;
    background: lightred;
  }
`;

const CheckIcon = styled(Icon).attrs({ name: 'check', size: 18 })`
  color: ${Main.success};
  margin-right: auto;

  &:hover {
    cursor: pointer;
    background: lightgreen;
  }
`;

const CheckIconMessage = styled(Icon).attrs({ name: 'check', size: 22 })`
  color: ${Main.success};
  margin-right: auto;

  &:hover {
    cursor: pointer;
    background: lightgreen;
  }
`;

const DragHandleIcon = styled(Icon).attrs({ name: 'DragHandle', size: 18 })`
  color: ${Color.shade};
`;

const SuggestionList = styled.ul`
  padding: 5px;
  width: 100%;
  max-height: 200px;
  overflow-y: scroll;
`;

const ListItem = styled.li`
  display: block;
  transition-duration: 0.5s;
  padding: 5px;
  list-style-type: none;

  &:hover {
    cursor: pointer;
    background: lightgrey;
  }
`;

const DragHandleIconWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  padding-right: ${Size.tiny};
  padding-left: ${Size.small};
`;

const ItemsContainer = styled.div<any>`
  padding: ${Size.medium} 0;
  flex-grow: 1;
  transition: background-color 0.2s;
  height: 250px;
  overflow-y: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  ${props => props.isDraggingOver && css`
    background: ${Color.lightBlue};
  `}
`;

const Title = styled.h1`
  font-size: ${Size.medium};
  margin: 0;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const ErrorMessage = styled.p`
  font-size: ${Size.medium};
  font-weight: bold;
  color: ${Main.error};
`;

const InfoMessage = styled.div`
  font-size: ${Size.medium};
  font-weight: bold;
  color: ${Main.accent};
`;

export interface Props {
  attributes: TempAttribute[];
  vendorAttributes: Omit<Attribute, 'order' | 'values'>[];
  onAdd: (type: boolean) => void;
  onDelete: (attribute: TempAttribute) => void;
  onRename: (
    attrObj: TempAttribute,
    newName: string
  ) => void;
  onSelection: (attribute: TempAttribute) => void;
  title: string;
  type: AttributesOrderType;
  productVersion: number;
  addAttribute: (attribute: ProductAttribute) => void;
  adding: boolean;
  restrictedAttributeNames: string[];
  nameExists: boolean;
};

export default function AttributesEditorList({
  attributes,
  vendorAttributes,
  productVersion,
  onAdd,
  onDelete,
  onRename,
  onSelection,
  title,
  type,
  addAttribute,
  adding,
  restrictedAttributeNames,
  nameExists
}: Props) {
  const [addingAttr, setAddingAttr] = useState<boolean>(adding);
  useEffect(() => {
    setAddingAttr(adding);
  }, [adding])

  const draggableItems = attributes.map((attributeObj, index) => {
    let errorMessage: any = null;

    if (restrictedAttributeNames.includes(attributeObj.name.toLowerCase())) {
      errorMessage = <ErrorMessage>{attributeObj.name} is reserved and cannot be used as an attribute name.</ErrorMessage>
    }

    if (nameExists && attributeObj.new === true) {
      errorMessage = <ErrorMessage>{attributeObj.name} is duplicate.</ErrorMessage>
    }
    if (adding && attributeObj.new === true) {
      errorMessage = <InfoMessage><span>Check </span> <CheckIconMessage /> <span>or delete </span><DeleteIconMessage /> <span> before saving</span></InfoMessage>
    }

    return (
      <Draggable
        key={attributeObj.id + ""}
        draggableId={attributeObj.id + ""}
        index={index}
      >
        {(provided, snapshot) => (
          <AttributeWrapper
            isDragging={snapshot.isDragging}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <AttributeDiv>
              <DragHandleIconWrapper {...provided.dragHandleProps}>
                <DragHandleIcon />
              </DragHandleIconWrapper>
              <AttributeName
                value={attributeObj.name}
                maxLength={25}
                onChange={e => { return onRename(attributeObj, e.target.value) }}
                disabled={!adding}
              />{
                attributeObj.new && !nameExists && attributeObj.name !== '' && adding && !restrictedAttributeNames.includes(attributeObj.name.toLowerCase()) ?
                  (<IconButton onClick={() => addAttribute(attributeObj)}>
                    <CheckIcon />
                  </IconButton>)
                  : null
              }
              <IconButton onClick={() => onDelete(attributeObj)}>
                <DeleteIcon />
              </IconButton>
            </AttributeDiv>

            {
              vendorAttributes.length > 0 && attributeObj.new && attributeObj.name !== '' ?
                (<SuggestionList>
                  {vendorAttributes.map((el, i) => (
                    <ListItem key={i} onMouseDown={() => onSelection({
                      ...el,
                      isSelectable: type === 'attributes',
                      displayOrder: attributes.length,
                      new: true
                    })}>
                      {el.name}
                    </ListItem>))}
                </SuggestionList>) : null
            }
            {restrictedAttributeNames.includes(attributeObj.name.toLowerCase()) && <ErrorMessage>{attributeObj.name} is reserved and cannot be used as an attribute name.</ErrorMessage>}
            {(nameExists && attributeObj.new === true && attributeObj.name.trim() && !restrictedAttributeNames.includes(attributeObj.name.toLowerCase())) && <ErrorMessage>{attributeObj.name} is duplicate.</ErrorMessage>}
            {errorMessage}
          </AttributeWrapper>
        )}
      </Draggable>
    )
  });

  return (
    <Attributes>
      <TitleContainer>
        <Title>{title}</Title>
        {
          addingAttr ? null :
            <AddAttributeButton>
              <AddAttributeIcon onClick={() => {
                onAdd(type === 'attributes')
              }
              } />
            </AddAttributeButton>
        }
      </TitleContainer>
      <Droppable droppableId={type}>
        {(provided, snapshot) => (
          <ItemsContainer
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={type === 'attributes' ? 'selectable-attributes-list' : "technical-attributes-list"}
          >
            {draggableItems}
            {provided.placeholder}
          </ItemsContainer>
        )}
      </Droppable>
    </Attributes>
  );
};
