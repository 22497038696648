export enum Color {
  black = '#1C1C1C',
  darkGray = '#B0B3B8',
  darkerGray = '#8A9096',
  errorRedTransparent = 'rgba(200, 33, 53, 0.1)',
  gray = '#E2E5E6',
  lightBlue = '#E9F0F8',
  lightGray = '#F0F1F4',
  lighterGray= '#C1C9D1',
  shade = 'rgba(24, 24, 24, 0.25)',
  dimGray = '#636262',
  hexacomBlue = '#106FF9',
  whiteSmoke =  '#F7F7F7',
  linkWater = '#C7D0D8',
  pattensBlue = '#CCE6FF',
  veryLightGray = '#CCCCCC',
  shuttleGray = '#5E6670',
  ivory = '#FDFFEB',
  lightPink = '#FFEBEB',
  lightGreen = '#F1FFF4',
  error = '#C82135',
  carrotOrange = '#F7991C'
};

export enum Breakpoints {
  extraSmall = '480px',
  small = '768px',
  medium = '1024px',
  large = '1200px',
  extraLarge = '1201px'
};

export enum Size {
  huge = '4rem',
  large = '2rem',
  medium = '1rem',
  small = '0.5rem',
  tiny = '0.25rem',
  xs = '0.1rem'
};

export enum Percent {
  full = '100%',
  threeQuarters = '75%',
  half = '50%',
  quarter = '25%'
};

export enum Speed {
  fast = '50ms',
  slow = '150ms'
};

export enum BorderRadius {
  huge = '50%',
  large = '25%',
  medium = '6px',
  small = '4px',
  tiny = '2px'
};

export enum FontSize {
  enormous = '64px',
  huge = '48px',
  large = '24px',
  medium = '20px',
  semi = '16px',
  small = '13px',
  smaller = '12px',
  tiny = '10px'
};

export enum FontWeight {
  ultraBold = '900',
  bold = 'bold',
  medium = '500'
};

export const contentWidth = '1000px';
