import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Product, * as Prod from 'data/Product';
import Attribute from 'data/Attribute';
import ConfirmModal from 'components/UI/Modal';
import AttributesEditorList from 'common/AttributesEditorList';
import Modal from 'components/UI/Modal';
import { renderToast } from 'components/UI/ToastNotification';
import * as Arr from 'lib/Array';
import { Size } from 'lib/styles';
import Vendor from 'data/Vendor';
import { useAuth } from 'lib/Auth';
import { create } from 'requests/atribute';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Space } from 'styles/themeSpaces';
import LoadingIndicator from 'components/UI/LoadingIndicator';

const Content = styled.div`
  display: flex;
  padding: ${Size.small} ${Size.large};
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

export type AttributesOrderType =
  | 'attributes'
  | 'classifications';

export interface StateAttributeItem {
  oldName: string;
  newName: string;
  new: boolean;
  active: boolean;
};

export type TempAttribute = Pick<Prod.ProductAttribute, 'id' | 'code' | 'name' | 'displayOrder' | 'isSelectable'> & { new: boolean };

export interface Props {
  onClose: () => void;
  product: Product;
  update: (product: Product) => void;
  selectedVendor: Vendor;
  vendorAttributes: Omit<Attribute, 'order' | 'values'>[];
  reloadVendorAttributes: (value: boolean) => void;
  showModal: boolean;
};

export default function AttributesEditor({ onClose, product, update, selectedVendor, vendorAttributes, reloadVendorAttributes, showModal }: Props) {
  const mappedAttributes = Arr.orderByArray(product.attributes.map(el => ({ ...el, new: false })), 'displayOrder');
  const [filteredList, setFilteredList] = useState<Omit<Attribute, 'order' | 'values'>[]>(vendorAttributes);
  const [productAttributes, setProductAttributes] = useState<TempAttribute[]>(mappedAttributes);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [addingAttribute, setAddingAttribute] = useState<boolean>(false);
  const [nameExists, setNameExists] = useState<boolean>(false);
  const [showAttributeDeleteModal, setShowAttributeDeleteModal] = useState(false);
  const [tempAttribute, setTempAttribute] = useState<TempAttribute | null>(null);
  const [loading, setLoading] = useState<Boolean>(false)


  const setProductAttributesByDisplayOrder = (prodAttributes: TempAttribute[])=>{
    const sortAttributeProducts = prodAttributes.filter((prod) => prod.isSelectable === true).sort((prod1, prod2) => prod1.displayOrder - prod2.displayOrder)
    const sortClassificationsProducts = prodAttributes.filter((prod) => prod.isSelectable === false).sort((prod1, prod2) => prod1.displayOrder - prod2.displayOrder)
    const mergeSortedProducts = [...sortAttributeProducts, ...sortClassificationsProducts]
    setProductAttributes(mergeSortedProducts.map((el, i) => {
      return ({ ...el, displayOrder: i})
    }));
    setLoading(false)
  }

  useEffect(() => {
    setProductAttributesByDisplayOrder(mappedAttributes)
  }, [product])
  const { token } = useAuth();
  const restrictedAttributeNames = ['sku', 'code', 'description', 'weight', 'price', 'vendor', 'log'];

  function filterAttributes(newName: string) {
    const filtered = vendorAttributes.filter(el => el.name === '' ? false : el.name.toLowerCase().includes(newName.toLowerCase()))

    setFilteredList(filtered);
  }

  function onAdd(isSelectable: boolean) {
      setProductAttributes([...productAttributes, {
        id: 0,
        code: '',
        name: '',
        displayOrder: productAttributes.length,
        isSelectable,
        new: true
      }])
      setAddingAttribute(true);
      const itemsContainer = document.getElementsByClassName(isSelectable ? 'selectable-attributes-list' : 'technical-attributes-list');
      
      if(itemsContainer && itemsContainer.length > 0) {
        setTimeout(() => {
          itemsContainer[0].scrollTo({ behavior: 'smooth', top: itemsContainer[0].scrollHeight});
        }, 100);
      }
  }

  function onDeleteConfirm() {
    setLoading(true)
    if (product.id && tempAttribute && tempAttribute.id !== 0) {
      Prod.deleteProductAttribute(product.id!, tempAttribute.id, token!).then(() =>
        setProductAttributesByDisplayOrder(productAttributes.filter(el => el.id !== tempAttribute.id))).catch(err => {
          if (err.message === 'Attribute id not found') {
            setProductAttributesByDisplayOrder(productAttributes.filter(el => el.id !== tempAttribute.id));
            renderToast(toast.TYPE.ERROR, err.message);
          } else {
            renderToast(toast.TYPE.ERROR, 'There was a problem deleting this attribute.');
          }
        });
    } else if (tempAttribute) {
      setProductAttributesByDisplayOrder(productAttributes.filter(el => el.id !== tempAttribute.id));
    }

    setAddingAttribute(false);
    setShowAttributeDeleteModal(false);
  }

  const onDelete = (attribute: TempAttribute) => {
    setShowAttributeDeleteModal(true);
    setTempAttribute(attribute);
  };

  function addAttribute(attribute: Prod.ProductAttribute) {
    setLoading(true)
    if (selectedVendor) {
      setIsSaveDisabled(true);
      create(attribute.name, selectedVendor.id, token!,product?.id,attribute.isSelectable)
        .then((result) => {
          setProductAttributesByDisplayOrder([...productAttributes.filter(el => !el.new), { ...attribute, id: result.id, code: result.code, new: false }])
          setIsSaveDisabled(false);
        }).catch(err => {
          renderToast(toast.TYPE.ERROR, 'There was a problem while adding this attribute.');
          setIsSaveDisabled(false);
        })
      setAddingAttribute(false);
    }
    else {
      renderToast(toast.TYPE.INFO, 'Please select a vendor before you modify attributes.');
      return;
    }
  }

  function onDragEnd(result: DropResult) {
    if (!result.destination || !result.source || addingAttribute) {
      return;
    }

    const source = result.source.droppableId as AttributesOrderType;
    const destination = result.destination.droppableId as AttributesOrderType;
    const selectableLength = productAttributes.filter(el => el.isSelectable).length;

    const destinationIndexFactor =  source === 'attributes' ? -1 : 0;
    const sourceIndex = source === 'attributes' ? result.source.index : selectableLength + result.source.index;
    const destinationIndex = destination === 'attributes' ? result.destination.index : selectableLength + result.destination.index + destinationIndexFactor;

    let prodAttributes = [...productAttributes];


    const itemToMove = { ...productAttributes[sourceIndex], displayOrder : destinationIndex,  isSelectable: destination === 'attributes' };

    prodAttributes = Arr.removeAtIndex(
      prodAttributes,
      sourceIndex
    )
    
    prodAttributes = Arr.addAtIndex(
      prodAttributes,
      itemToMove,
      destinationIndex
    )

    setProductAttributesByDisplayOrder(prodAttributes)
  }

  function onRename(
    attributeObj: TempAttribute,
    name: string
  ) {
    attributeObj.name = name;
    setNameExists(checkNameExists(name));
    filterAttributes(name);
  }

  function onSelection(attribute: TempAttribute) {
    setProductAttributesByDisplayOrder([...productAttributes.map(el => el.new ? { ...el, name: attribute.name } : el)]);
    setFilteredList([])
  }

  function checkNameExists(attributeName: string) {
    return productAttributes.filter(el => el.name.toLowerCase() === attributeName.toLowerCase()).length > 1;
  }

  function onSave(attributes: TempAttribute[]) {
    if (!selectedVendor) {
      renderToast(toast.TYPE.INFO, 'Please select a vendor before you modify attributes.');
      return;
    }

    let newProduct: Product = {
      ...product,
      attributes: attributes.map(el => el as Prod.ProductAttribute)
    };

    newProduct.variants.forEach(variant => {
      variant.attributeValues.forEach((attributeValue, index) => {
        // Remove values for attributes that do not exist anymore on the product
        if (!newProduct.attributes.map(attribute => attribute.id).includes(attributeValue.attributeId)) {
          variant.attributeValues = Arr.removeAtIndex(variant.attributeValues, index);
        }
      });

      newProduct.attributes.forEach((attribute, index) => {
        // Add values for the new attributes added to the product
        if (!variant.attributeValues.map(value => value.attributeId).includes(attribute.id)) {
          const newValue = { id: 0, attributeId: attribute.id, name: '', code: '', isSelectable: attribute.isSelectable, displayOrder: 0 }
          variant.attributeValues = Arr.addAtIndex(variant.attributeValues, newValue, index);
        }

        // Change isSelectable on values
        const foundValues = variant.attributeValues.filter(attributeValue => attributeValue.attributeId === attribute.id);

        if (foundValues.length > 0) {
          foundValues.forEach(foundValue => {
            if (foundValue.isSelectable !== attribute.isSelectable) {
              foundValue.isSelectable = attribute.isSelectable;
            }
          })
        }
      });
    });

    setAddingAttribute(false);
    update(newProduct);
    reloadVendorAttributes(true);
    onClose();
  }

  const attributeDeleteModal = (
    <ConfirmModal
      showModal={showAttributeDeleteModal}
      onClose={() => setShowAttributeDeleteModal(false)}
      onClick={onDeleteConfirm}
      title="Are you sure you want to remove this attribute?"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          This will remove the attribute from the product.
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )
  return (
    <Modal
      showCloseIcon
      width={'700px'}
      showModal={showModal}
      onClose={() => onSave(productAttributes)}
      onClick={() => onSave(productAttributes)}
      disabled={addingAttribute || isSaveDisabled}
      title='Edit attributes'
      showGhostButton={false}
      primaryButtonText='Close'
    >
      {attributeDeleteModal}
      {
        loading ? 
        <LoadingIndicator />
        :
        <Content>
        <DragDropContext onDragEnd={onDragEnd} >
          <AttributesEditorList
            attributes={productAttributes.filter(el => el.isSelectable)}
            vendorAttributes={filteredList.filter(el => !productAttributes.map(el => el.id).includes(el.id))}
            productVersion={product.version}
            onAdd={onAdd}
            onDelete={onDelete}
            onRename={onRename}
            onSelection={onSelection}
            title="Selectable attributes (max 25)"
            type={'attributes'}
            addAttribute={addAttribute}
            adding={addingAttribute}
            restrictedAttributeNames={restrictedAttributeNames}
            nameExists={nameExists}
          />
          <AttributesEditorList
            attributes={productAttributes.filter(el => !el.isSelectable)}
            vendorAttributes={filteredList.filter(el => !productAttributes.map(el => el.id).includes(el.id))}
            productVersion={product.version}
            onAdd={onAdd}
            onDelete={onDelete}
            onRename={onRename}
            onSelection={onSelection}
            title="Technical specifications (max 25)"
            type={'classifications'}
            addAttribute={addAttribute}
            adding={addingAttribute}
            restrictedAttributeNames={restrictedAttributeNames}
            nameExists={nameExists}
          />
        </DragDropContext>
      </Content>
      }
    </Modal>
  );
};
