import React, { useState, useEffect, ReactNode } from 'react'
import styled, { css } from 'styled-components';
import { latoFont } from 'OnboardingComponents/Elements/Typography';
import { Color, FontSize, FontWeight } from 'lib/styles';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Icon from 'view/Icon';
import Button from 'components/UI/Button';
import Checkbox from 'components/UI/Checkbox';
import WarehouseLocationEditForm from 'view/WarehouseLocationEditForm';
import PointOfService from 'data/PointOfService';
import Warehouse from 'data/Warehouse';
import { Main, Background } from 'styles/themeColors';
import Country from 'data/Country';
import WarehouseAddFormValidator from 'data/WarehouseFormValidator';

const Nickname = styled.div`
  ${latoFont}
  font-size: ${FontSize.large};
  font-weight: ${FontWeight.bold};
  line-height: 1.5;
  text-align: left;
  color: ${Color.black};
`;

const PickupLocationId = styled.span`
  ${latoFont}
  font-size: 16px;
  font-weight: ${FontWeight.bold};
  line-height: 2.6;
  text-align: left;
  color: rgba(139, 144, 149, 0.9);
  margin-left: 22px;
`;

const Name = styled.div`
  ${latoFont}
  font-size: 16px;
  font-weight: ${FontWeight.bold};
  line-height: 1.63;
  text-align: left;
  color: ${Color.black};
`;

const Address = styled.div`
  ${latoFont}
  font-size: 14px;
  line-height: 1.71;
  text-align: left;
  color: ${Color.black};
`;

const EditIcon = styled(Icon).attrs({ size: 20 })`
  color: ${Main.accent};
  cursor: pointer;

  ${props => props.name === 'edit' && css`
    &:hover{
      color: ${Main.warning};
    }
  `}

  ${props => props.name === 'check' && css`
    color: ${Main.success};

    &:hover{
      color: ${Main.success};
    }
  `}

  ${props => props.name === 'x' && css`
    color: ${Main.error};

    &:hover{
      color: ${Main.error};
    }
  `}
`;

const ColoredLine = styled.p`
  background-color: ${Color.veryLightGray};
  height: 3px;
`;

interface Props {
  warehouse: Warehouse;
  isEditable: boolean;
  updateNewWarehouse: (pos: PointOfService) => void;
  handleChange: (value: string, name: string) => void;
  removePOS: (posId: number) => void;
  isDisabled?: boolean;
  customDisabled: boolean;
  countries: Country[];
  warehouseValidator: WarehouseAddFormValidator;
  onInputBlur: (value: string, validatorFunction: () =>void ) => void;
  validateZipCode: () => void;
  validateCity: () => void;
  validateStreet: () => void;
  clearFieldValues: (value: string, name: string) => void;
  handleCancel: ()=> void;
};

interface Option {
  value: string;
  id?: string;
  icon?: ReactNode;
  badge?: any;
}

const WarehouseLocationCard = ({ warehouse, isEditable, updateNewWarehouse, handleChange, removePOS, isDisabled, countries, warehouseValidator, onInputBlur, validateZipCode, validateCity, validateStreet, clearFieldValues, customDisabled, handleCancel }: Props) => {
  const [isPickupLocation, setIsPickupLocation] = useState(false);
  const [editing, setEditing] = useState(false);
  const [countriesList, setCountriesList] = useState<Option[]>([]);

  useEffect(() => {
    setEditing(isEditable);
  }, [isEditable]);

  useEffect(() => {
    const options = countries.map((country: Country) => ({
      value: country.countryName,
      id: country.id?.toString(),
      countryIso: country.countryIso
    }));
    setCountriesList([...options]);
  }, [countries]);


  let actionButtons = null;

  if (!editing && !isDisabled) {
    actionButtons = (
      <FlexboxContainer justifyContent="flex-end">
        <EditIcon disabled={editing} name="edit" onClick={() => setEditing(true)} />
      </FlexboxContainer>
    );
  }

  if (editing && !isDisabled) {
    actionButtons = (
      <FlexboxContainer justifyContent="flex-end">
        <EditIcon name="edit-3" disabled={true} />
      </FlexboxContainer>
    );
  }

  const save = () => {
    setEditing(false)
  };

  const cancel = () => {
    handleCancel();
    if(!isEditable){
      setEditing(false)
    }
  };

  const setPickupLocation = () => {
    if (isPickupLocation === false) {
      updateNewWarehouse({ id: 0, code: '', posId: warehouse.warehouseId, name: warehouse.name, nickname: warehouse.nickname, street: warehouse.street, city: warehouse.city, state: warehouse.state, zip: warehouse.zip, countryId: Number(warehouse.country.id) });
    } else {
      removePOS(0);
    }

    setIsPickupLocation(!isPickupLocation);
  };


  const disableSaveButton = !warehouse.street || !warehouse.city || !warehouse.state || !warehouse.zip || 
                            warehouseValidator.city || warehouseValidator.state || warehouseValidator.street || warehouseValidator.zip

  let editForm = !editing ? null :
    (
      <FlexboxContainer flexDirection="column" width="100%" >
        <ColoredLine />
        <WarehouseLocationEditForm warehouse={warehouse} handleChange={handleChange} isDisabled={!isDisabled} countriesList={countriesList} warehouseValidator={warehouseValidator} onInputBlur={onInputBlur} validateZipCode={validateZipCode} validateCity={validateCity} validateStreet={validateStreet} clearFieldValues={clearFieldValues} customDisabled={customDisabled}/>
        <FlexboxContainer justifyContent="flex-end" alignItems="flex-end">
          {/* <Checkbox id="warehouse-location-card-checkbox-id" checkboxSize="xs" appearance="none" label="Set as pick-up location" checked={isPickupLocation} onChange={setPickupLocation} /> */}
          <FlexboxContainer border="1px 0 0 0 solid lightgrey" margin="20px 0 0 0" justifyContent="flex-end">
            <Button isGhost color={Main.accent} margin="0 10px 0 0" onClick={cancel}>
              Cancel
            </Button>
            <Button onClick={save} disabled={disableSaveButton}>
              Save
            </Button>
          </FlexboxContainer>
        </FlexboxContainer>
      </FlexboxContainer>
    );

  return (
    <FlexboxContainer
      flexDirection="column"
      bgColor={Background.white}
      boxShadow="0 1px 5px 0 rgba(0, 0, 0, 0.16)"
      margin="20px 0 24px 0"
      padding="22px 21px 23px 15px;"
    >
      <FlexboxContainer justifyContent="space-between">
        <FlexboxContainer justifyContent="flex-start">
          <Nickname>{warehouse.nickname}</Nickname>
          <PickupLocationId>{warehouse.warehouseId}</PickupLocationId>
        </FlexboxContainer>
        {actionButtons}
      </FlexboxContainer>
      <FlexboxContainer>
        <Name>
          {warehouse.name}
        </Name>
      </FlexboxContainer>
      <FlexboxContainer justifyContent="flex-start">
        <Address>
          {`${warehouse.country?.countryName || ''} ${warehouse.street || ''} ${warehouse.city || ''} ${warehouse.state || ''} ${warehouse.zip || ''}`}
        </Address>
      </FlexboxContainer>
      {/* <FlexboxContainer justifyContent="flex-start">
            <Coordinate>
              {warehouse.longitude}
            </Coordinate>
            <Coordinate>
              {warehouse.latitude}
            </Coordinate>
      </FlexboxContainer> */}
      {editForm}
    </FlexboxContainer>
  );
};

export default WarehouseLocationCard;
