export const TEXT_LABELS = {
    VARIANTS_IMPORT: 'Variants imported',
    PERCENT_VERIFIED: '% verified',
    STATUS: 'Status',
    UPLOADED: 'Uploaded',
    FILENAME: 'Filename',
    UPLOAD_SIZE_LESS_THAN_20: 'Please upload files below 20 MB',
    FILE_FORMAT_XLXS: 'You can only upload .xlsx file format',
    WRONG_COLUMN_SET: 'Wrong column set. Please compare to the template',
    DONOT_CLOSE_WINDOW: 'Uploading. Do not close this window',
    UPTO_20_MB: 'Up to 20 Mb',
    VARIANTS_FAILED: 'Variants failed',
    UPLOAD: 'Upload',
    VERIFICATION: 'Verification',
    COMPLETED: 'Completed',
    CANCEL_DRAFT: 'Cancel draft',
    NEXT: 'Next',
    DONWLOAD_ERROR_LOGS: 'Download error log',
    DOWNLOAD_REPORT: 'Download report',
    PARTIAL_SUCCESS: 'Import partially successful',
    VERIFICATION_FAILED: 'Verification failed',
    SELECT_XLXS: 'Select .xlsx file',
    REUPLOAD: 'Re-Upload',
    CAMPAIGNS_IMPORTED: 'Campaigns imported',
    CAMPAIGNS_FAILED: 'Campaigns failed',
  };
  
  export const STATUS = {
    PENDING: 1,
    COMPLETED: 2,
  };
  
  export const STEPS = {
    UPLOADING: 1,
    VERIFICATION: 2,
    COMPLETION: 3,
  };
  
  
export const XLS_TEXT_CONSTANTS = {
  SKU: "Please delete this message and insert the SKU number of your variant as seen in OFMP system. This field is required; eg.: '2FA-BITX20'",
  VENDOR_PART_NUMBER:
    "Please delete this message and insert the 'Vendor product number' of your variant, for SLB use SPN. This field is optional.",
  PURCHASE:`States whether the product is available for purchase or rent. Put "Yes" or "No" where it applies. Field cannot be blank.`,
  RENT: `States whether the product is available for purchase or rent. Put "Yes" or "No" where it applies. Field cannot be blank.`,
  ITEM_TYPE: "Please delete this message and insert the 'Item type'",
  HIDDEN:
    "Please delete this message and indicate whether this variant should be hidden. Accepted values 'Yes' or 'No'. Empty cell means 'No'.",
  WEIGHT:
    "Please delete this message and indicate the shipping weight of the variant in kilograms. This field is required; eg.: '50'",
  MATERIAL_NUMBER:
    "Please delete this message and insert the 'Material number' number of your variant as seen in OFMP system. This field is required if Material API is enabled on brand, else optional; eg.: '2FA-BITX20'",
  IN_STOCK:
    "Please delete this message and indicate stocks per given warehouse. Accepted values are 'Yes' or 'No', empty cell means 'Yes'",
  LEAD_DAYS:
    "Please delete this message and indicate the number of days it takes to ship this variant from a given warehouse. This field is required; eg.: '5'",
  POST_ORDER_SHIPPING_COST:
    "Please delete this message and indicate whether the post-order shipping cost applies to this variant. Accepted values are 'Yes' or 'No', empty cell means 'No'",
  USA_PRICE:
    "Please delete this message and insert the price in USD without '$' sign. This field is required; eg.: '19.99'",
  CANADA_PRICE:
    "Please delete this message and insert the price in CAD without '$' sign. This field is required; eg.: '19.99'",
  SELECTABLE_ATTRIBUTE:
    "Please delete this message and indicate the selectable attribute value eg.: '50 inch'",
  TECHNICAL_SPECIFICATION:
    "Please delete this message and indicate the technical specification eg.: 'Green'",
  DOWNLOAD_XLXS: 'Download .xlsx template',
  OFMP_TEMPLATE: 'TheOFMP-Product-Import-Template',
  SELECTABLE_ATTRIBUTE_COLUMN_KEY:
    "Selectable attribute [replace this text in brackets with the selectable attribute name eg.: 'Diameter'; do not delete the brackets; if there are no attributes, delete this column altogether; if there is more than one attribute, then duplicate this column for every. This property is unique for a specific product variant and based on it a buyer will be able to configure the final desired end product]",
  TECHNICAL_ATTRIBUTE_COLUMN_KEY:
    "Technical specification [replace this text in brackets with the technical specification name eg.: 'Color'; do not delete the brackets; if there is no specification, delete this column altogether; if there is more than one specification, then duplicate this column for every. Based on this property there will be a specific text description displayed for a specific variant.]",
  CANADA_CAD: 'Canada price (CAD)',
  USA_DOLLAR: 'USA price (USD)',
  UNITED_STATE: 'United States',
  CANADA: 'Canada',
  MATERIAL_NUMBER_KEY: 'Material Number',
  PRICE_RANGE: `Period of time for which a specific daily price is applied. Example: (Price range 1) Range: 20 would mean 1-20 days. (Price range 2) Range: 40 would mean 21-40 days etc.`,
  PRICE_RANGE_UNIT: `Units of time measurement for the range. Must always be Days`,
  PRICE_RANGE_PRICE: `price per Day for that specific range`
};

export const PRODUCT_VARIANT_TYPE = {
    PURCHASE:'Purchase',
    RENT: 'Rent',
    RENTAL: 'Rental',
    ALL: 'All',
}
