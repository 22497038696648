import { useEffect } from 'react';

const useFetchGridDataWithPagination = (
  componentKey: string,
  firstPageRequest: any,
  restPagesRequest: any,
  pagination: any,
  changePage: (page: number) => void,
  size: number,
  vendorId?: number,
  currentPage?: number,
  orderId?: string,
) => {
  /* get first page data */
  useEffect(() => {
    changePage(1);

    firstPageRequest.run(size, 1, 0, vendorId, orderId);
  }, [vendorId, size])

  /* get rest of the pages data */
  useEffect(() => {
    if (
      pagination &&
      pagination[componentKey].length > 0 &&
      pagination.totalItems > pagination[componentKey].length * pagination.page
    ) {
      // const take = pagination.totalItems;
      const nextPage = currentPage;
      const offset = currentPage ? size * (currentPage - 1) : size;

      restPagesRequest.run(size, nextPage, offset, vendorId, orderId);
    }
  }, [currentPage]);
};

export default useFetchGridDataWithPagination;
