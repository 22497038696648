import React from 'react';
import styled from 'styled-components';
import { Size, contentWidth } from 'lib/styles';

const Container = styled.div`
  margin: ${Size.large} auto;
  max-width: ${contentWidth};
  text-align: center;
`;

const NotFound = () => {
  return (
    <Container>
      <h1>Page not found</h1>
    </Container>
  );
}

export default NotFound;