import React, { useState, useEffect, ReactNode } from 'react'
import Input from 'components/UI/Input';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Warehouse from 'data/Warehouse';
import Select from 'components/UI/Select';
import styled from 'styled-components';
import { Percent } from 'lib/styles';
import { getStates } from 'requests/country';
import { useAPI } from 'lib/API';
import WarehouseAddFormValidator from 'data/WarehouseFormValidator';
import { warehousePosValidations } from 'constants/errorMessages';

interface Props {
  warehouse: Warehouse,
  handleChange: (value: string, name: string) => void;
  isDisabled?: boolean;
  customDisabled: boolean;
  countriesList: Option[],
  onZipBlur?: () => void;
  zipValueInvalid?: boolean;
  warehouseValidator: WarehouseAddFormValidator;
  onInputBlur: (value: string, validatorFunction: () =>void ) => void;
  validateZipCode: () => void;
  validateCity: () => void;
  validateStreet: () => void;
  clearFieldValues: (value: string, name: string) => void;
}
interface Option {
  value: string;
  id?: string;
  icon?: ReactNode;
  badge?: any;
}

const JustifySplitDiv = styled.div`
  width: ${Percent.full};
  display: flex;
  justify-content: space-between;
`;


export default function WarehouseLocationEditForm({ warehouse, handleChange, isDisabled, countriesList, warehouseValidator, onInputBlur, validateZipCode, validateCity, validateStreet, clearFieldValues, customDisabled }: Props) {
  const [statesList, setStates] = useState<Option[]>([]);

  const wareHouseCountryStates = useAPI({
    deferFn: getStates,
    onResolve: (result: any) => {
      const options = result.map((state:any) => ({
        value: state.state,
        id: state.id?.toString(),
        countryIso: state.countryIsoCode
      }));
      setStates([...options]);
    },
    onReject: (err: any) => { },
  });

  useEffect(() => {
    if(warehouse?.country?.countryIso){
      wareHouseCountryStates.run([warehouse.country.countryIso]);
    }
  }, []);

  function onCountryChange(formatCountry:any,countryIso:any){
    clearFieldValues(formatCountry, 'country');
    wareHouseCountryStates.run([countryIso]);
  }

  return (
    <FlexboxContainer bgColor="3px 0 0 0 lightgrey" flexDirection="column" width="100%" >
        <div>
          <Select
            label='Country'
            labelPosition="top"
            options={countriesList}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              const country: any = countriesList.find((country:Option) => country.value === event.target.value)
              const formatCountry: any = { id: country.id, countryName: country.value, countryIso: country.countryIso }
              onCountryChange(formatCountry,country.countryIso)
            }}

            value={countriesList.find(country => country?.id === String(warehouse.country?.id))?.value || ''}
            defaultValue="Select"
            width="33%"
            height="36px"
            disabled={customDisabled}
          // isInvalid={countryError}
          />
        </div>


        <div style={{marginTop: 20}}>
          <Input
            id='street-id'
            labelName="Street address"
            placeholder='Street'
            value={warehouse.street}
            width="100%"
            name="street"
            onInputChange={(val) => handleChange(val, 'street')}
            displayAsterisk={isDisabled}
            isInvalid={warehouseValidator.street}
            onBlur={()=> onInputBlur && onInputBlur('street', validateStreet)}
            errorMessage={warehousePosValidations.INVALID_STREET}
          />
        </div>

        <div style={{marginTop: 20}}>
          <JustifySplitDiv >
            <div style={{width: '33%'}}>
            <Input
              id='city-id'
              labelName="City"
              placeholder='City'
              value={warehouse.city}
              width="100%"
              name="city"
              onInputChange={(val) => handleChange(val, 'city')}
              displayAsterisk={isDisabled}
              isInvalid={warehouseValidator.city}
              onBlur={()=> onInputBlur && onInputBlur('city', validateCity)}
              errorMessage={warehousePosValidations.INVALID_CITY}
            />
            </div>
            <div style={{"width" : "33%"}}>
              <Select
                label='State / Province'
                labelPosition="top"
                options={statesList}
                displayAsterisk={isDisabled}
                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                  const state: any = statesList.find((state:Option) => state.value === event.target.value)
                  handleChange(state.value, 'state')
                }}
                value={statesList.find(state => state.value === String(warehouse.state))?.value || ''}
                defaultValue="Select"
                width="100%"
                height="36px"
              />
            </div>
            <div style={{"width" : "33%"}}>
              <Input
                id='zip-code-id'
                labelName="Zip code / Postal code"
                placeholder='Zip code / Postal code'
                value={warehouse.zip}
                width="100%"
                name="zip"
                onInputChange={(val) => handleChange(val, 'zip')}
                displayAsterisk={isDisabled}
                isInvalid={warehouseValidator.zip}
                onBlur={()=> onInputBlur && onInputBlur('zip', validateZipCode)}
                errorMessage={warehousePosValidations.INVALID_ZIP}
              />
            </div>
          </JustifySplitDiv>
        </div>
    </FlexboxContainer>
  )
};
