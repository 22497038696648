import React, { Fragment } from 'react';

import { Heading2, Heading5, Paragraph, CardCallout } from 'OnboardingComponents/Elements';

const ProductNaming = () => {
  return (
    <Fragment>
      <Heading2 id="product-naming">Product naming</Heading2>
      <Paragraph>Naming your product(s) on The OFMP might seem like an easy task, but we recommend that you take the following into consideration before simply copying and pasting what you’ve written elsewhere onto your new product description page:</Paragraph>
      <CardCallout>
        <Heading5>Example of product naming:</Heading5>
        <Paragraph bold italic>SX Greaseless Series™ Wireline Sheaves – a clear indication of both the model and/or series and the product type, which completes the story once combined with the brand name</Paragraph>
      </CardCallout>
    </Fragment>
  );
};

export default ProductNaming;
