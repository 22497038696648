import React from 'react';
import styled from 'styled-components';
import { Color, Size } from 'lib/styles';
import { Background } from 'styles/themeColors';

const Footer = styled.footer`
  align-items: center;
  background-color: ${Background.white};
  bottom: 0px;
  box-shadow: 0px -0.5rem 0.5rem ${Color.shade};
  display: flex;
  height: ${Size.huge};
  justify-content: space-between;
  padding: ${Size.small} ${Size.medium};
  position: fixed;
  width: 70vw;
  z-index: 100;
`;

const Left = styled.div`
  flex: 1;
`;

const Middle = styled.div`
  text-align: center;
`;

const Right = styled.div`
  flex: 1;
  min-height: 1em;
  text-align: right;
`;

export interface Props {
  left: React.ReactNode;
  middle: React.ReactNode;
  right?: React.ReactNode;
};

const FixedFooter = ({ left, middle, right }: Props) => (
  <Footer>
    <Left>{left}</Left>
    <Middle>{middle}</Middle>
    <Right>{right}</Right>
  </Footer>
);

export default FixedFooter;
