import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { renderToast } from 'components/UI/ToastNotification';
import { useAPI } from 'lib/API';
import { getOrderDetails, saveOrder } from 'requests/order';
import Page from 'components/UI/Page';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import OrderConsignmentList from 'components/Orders/OrderConsignmentList';
import Order, { empty } from 'data/Order';
import ConsignmentCancelationModal from 'components/Consignments/Modals/ConsignmentCancelationModal';
import OrderDowJonesStatusBanner from 'components/UI/Banner';
import { CANCELED } from 'constants/orderStates';
import { DOW_JONES_BLOCKED, DOW_JONES_REVIEWED_BLOCKED } from 'constants/dowJonesStatuses';
import { retrieveOrderDowJonesBannerContent } from 'utils/order';
import * as User from 'data/User';
import { useAuth } from 'lib/Auth';
import Button from 'components/UI/Button';
import FeatureFlag from 'data/FeatureFlag';
import { getDocumentsForOrder } from 'requests/documents';
import Documents from 'components/Consignments/Documents';
import OrderDetailsInformation from './OrderDetailsInformation';
import { formatDateTime, getLocalTimezone } from 'utils/formatters';
import Breadcrumbs from 'components/UI/Breadcrumb';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { usePath } from 'hookrouter';
import { Space } from 'styles/themeSpaces';
import { BorderRadius } from 'lib/styles';
import { Main } from 'styles/themeColors';
import { AWAITS_SHIPPING_APPROVAL } from 'constants/consignmentStates';
import { COUNTRY_ISO, CURRENCY_ISO } from 'constants/country';
import { getCountryIsoByCurrency } from 'utils/country';

const HeadingInformation = styled.h1`
  margin-left: 16px;
  width: 90%;
  font-size: 32px;
  line-height: 36px;
`;

const OrderInformation = styled.span`
  margin: 12px 0 32px 16px;
  font-size: 14px;
  font-style: italic;
  line-height: 18px;
`;

const BreadcrumbContainer = styled(FlexboxContainer)`
  cursor: pointer;
  margin-left: ${Space.spHalf};
  margin-right: ${Space.spHalf};
  border-radius: ${BorderRadius.medium};

  &:hover {
    background: rgba(123, 97, 255, 0.05);
  }
`;

interface SplitOrderProps {
  orderId: string;
  featureFlags: FeatureFlag;
};

const SplitOrderContainer = ({ orderId, featureFlags }: SplitOrderProps) => {
  const [orderDetails, setOrderDetails] = useState<Order>(empty);
  const [cancellationOrderPopup, setCancellationOrderPopup] = useState<boolean>(false);
  const { user } = useAuth();
  const path = usePath();
  const crumbs = useBreadcrumbs(path);
  const isVendor = User.isVendor(user!);
  const { theme, header, labels, button } = retrieveOrderDowJonesBannerContent(orderDetails);
  const [orderDocuments, setOrderDocuments] = useState([]);

  const getDocumentsForOrderReq = useAPI({
    deferFn: getDocumentsForOrder,
    onResolve: result => {
      setOrderDocuments(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const orderDetailsReq = useAPI({
    orderId,
    promiseFn: getOrderDetails,
    onResolve: (details: Order) => {
      details.currencyIso = getCountryIsoByCurrency(details.currencyIso)
      setOrderDetails(details)
    },
    onReject: (error: any) => renderToast(toast.TYPE.ERROR, error.message),
    debugLabel: 'orderDetailsRequest'
  });

  const updateOrderStateReq = useAPI({
    deferFn: saveOrder,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Order cancelled successfully');
      orderDetailsReq.reload();
    },
    onReject: (error: any) => renderToast(toast.TYPE.ERROR, error.message),
    debugLabel: 'orderUpdateStateRequest'
  });

  useEffect(() => {
    if (orderDetails.orderId) {
      getDocumentsForOrderReq.run(orderDetails.orderId);
    }
  }, [orderDetails.orderId]);

  const handleOpenModal = () => setCancellationOrderPopup(true);

  const handleCloseModal = () => setCancellationOrderPopup(false);

  const handleCancelConfirmation = (cancellationReason: string) => {
    updateOrderStateReq.run(
      {
        orderIds: [orderDetails.id],
        state: CANCELED,
        previousState: orderDetails.state,
        orderCancellationReason: cancellationReason
      },
      {
        dowJonesIds: orderDetails.dowJones?.id,
        status: orderDetails.dowJones?.status,
        comments: orderDetails.dowJones?.comments
      });

    orderDetailsReq.reload();
    handleCloseModal();
  };

  const splitOrderName = (
    <BreadcrumbContainer padding={Space.spBase}>
      {`Split order # ${orderDetails.orderId}`}
    </BreadcrumbContainer>
  );

  const isConsignmentNeededShipping = () =>{

    let consignmentsAwaitShippingApproval = orderDetails.consignments.filter((consignment)=>{
      return consignment.state === AWAITS_SHIPPING_APPROVAL;
    });
    return consignmentsAwaitShippingApproval.length > 0 ? true : false;
  }

  const checkConsignmentsState = () =>{
    const statuses = ['READY', 'READY_FOR_PICKUP', 'READY_TO_SHIP', 'CANCELED', 'AWAITS_SHIPPING', 'AWAITS_SHIPPING_APPROVAL'];
      const cancelStatus = orderDetails.consignments.some((consignment)=>{
          if(statuses.includes(consignment.state)){
            return true;
          }else{
            return false;
          }
      });
      return cancelStatus;
  }

  return (
    <Page>
      <FlexboxContainer
        flexDirection='column'
      >
        <Breadcrumbs containerPadding="0 0 24px 8px" crumbs={crumbs} splitOrderName={splitOrderName} />
        <FlexboxContainer
          width='100%'
          justifyContent='space-between'
          padding='0'>
          <HeadingInformation>
            Split order &nbsp; # {orderId}
          </HeadingInformation>
          {/* {
            !isVendor && orderDetails.dowJones.status !== DOW_JONES_BLOCKED && (
              <Button
                borderColor='white'
                isGhost
                theme='error'
                color={Main.error}
                onClick={handleOpenModal}
                disabled={ orderDetails.state === CANCELED || isConsignmentNeededShipping() || checkConsignmentsState()}
              >
                Cancel order
              </Button>
            )
          } */}
        </FlexboxContainer>
        <OrderInformation className='base'>
          Placed {formatDateTime(orderDetails.created, true)} {getLocalTimezone()}
        </OrderInformation>
        {
          featureFlags.dowJones &&
            featureFlags.dowJones.enabled &&
            orderDetails.dowJones &&
            ([DOW_JONES_BLOCKED, DOW_JONES_REVIEWED_BLOCKED].includes(orderDetails.dowJones.status))
            ? (
              <OrderDowJonesStatusBanner
                bgColor={theme}
                title={header}
                text={labels}
                button={button}
              />
            )
            : null
        }
        <OrderDetailsInformation
          show={false}
          customer={orderDetails.customer}
          companyName={orderDetails.companyName}
          newCompanyName={
            orderDetails.company && orderDetails.company.companyHistoryNameCount > 0 && orderDetails.companyName !== orderDetails.company.companyName
              ? orderDetails.company.companyName
              : ''
          }
          paymentMethod={orderDetails.paymentType}
          taxExempt={orderDetails.totalTax > 0 ? 'No' : 'Yes'}
          deliveryAddress={orderDetails.deliveryAddress ? orderDetails.deliveryAddress.addressLine1 : ''}
        />
        <OrderConsignmentList
          totalSurcharges={orderDetails.totalSurcharges}
          consignments={orderDetails.consignments}
          orderTotal={orderDetails.totalPriceWithTax}
          currencyIso={orderDetails.currencyIso}
        />
        <Documents
          vendorId={orderDetails.vendor ? orderDetails.vendor : ''}
          orderDocuments={orderDocuments}
          customerEmail={orderDetails.customer.email}
          orderNumber={orderDetails.orderId}
        />
      </FlexboxContainer>
      <ConsignmentCancelationModal
        show={cancellationOrderPopup}
        title='Canceling order'
        labels={[
          `${orderDetails.consignments.length} consignments will be cancelled`,
          'Please provide a cancelation reason which will be sent as a part of the “Cancelation notification” email to both Customer and Vendor'
        ]}
        textareaPlaceholder='Unfortunately, we are forced to cancel the order due to...'
        onClose={handleCloseModal}
        onConfirm={handleCancelConfirmation}
      />
    </Page>
  );
};

export default SplitOrderContainer;
