import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import Input from 'components/UI/Input';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { DatePicker } from '@material-ui/pickers';
import { FontWeight } from 'lib/styles';
import { Main, Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import DatePickerInput from 'components/UI/DatePickerInput';
import * as Cons from 'data/Consignment';
import RentalDates from './components/RentalDates';
import Button from 'components/UI/Button';
import DateView from 'components/UI/DateView';
import { CONSIGNMENT_TYPES } from 'constants/order';
import { formatDateTime } from 'utils/formatters';

const LabelText = styled.span`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
  margin-bottom: 5px;
  color: ${Text.primary};
  font-size: 15px;
`;
interface Props {
  warehouseName: string;
  referenceNumber: string;
  consignmentId: string;
  pickupDate?: string | null;
  onReferenceIdChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onPickupDateChange: (value: any) => void;
  button?: JSX.Element;
  isRental?: boolean;
  consignmentData ?: Cons.ConsignmentDetails;
  setShowModal?: (show: boolean) => void;
};

const PickupDetails = ({
  warehouseName,
  referenceNumber,
  consignmentId,
  pickupDate,
  onReferenceIdChange,
  onPickupDateChange,
  button,
  isRental,
  consignmentData,
  setShowModal
}: Props) => {
  return (
    <>
      <FlexboxContainer flexDirection="row" width="100%" justifyContent="space-between">
        <FlexboxContainer justifyContent="space-between" alignItems="center">
          <Input
            id="reference-id"
            labelName="Reference #"
            placeholder="Enter reference number"
            value={referenceNumber}
            onChange={onReferenceIdChange}
            width="272px"
          />
           <FlexboxContainer flexDirection="column" width='150px' margin='0 0 0 4px'>
            <LabelText>Ready for pickup</LabelText>
            {
              consignmentData?.consignmentType === CONSIGNMENT_TYPES.PURCHASE ? 
              <DatePicker
                value={pickupDate}
                onChange={onPickupDateChange}
                inputVariant="outlined"
                format="yyyy MMM dd"
                TextFieldComponent={DatePickerInput}
                autoOk
              /> :
              <DateView date={formatDateTime(pickupDate!)} onClick={() => setShowModal?.(true)} />
            }
          </FlexboxContainer>
        </FlexboxContainer>
        <FlexboxContainer margin="30px 0 0 4px" justifyContent="flex-end" alignItems="flex-end">
        { isRental ? 
            <Button
            className='mr-5'
            isGhost
            color={Main.accent}
            onClick={() => setShowModal?.(true)}
          >
            Edit
          </Button>
          : ''}
          {button}
        </FlexboxContainer>
      </FlexboxContainer>
    { isRental ? <RentalDates datesFormat='yyyy MMM dd' consignmentData={consignmentData} isRental={isRental} showIcon={false} /> : ''}

    </>
  );
};

export default React.memo(PickupDetails);
