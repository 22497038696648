import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Main } from 'styles/themeColors';
import TicketEntry from 'data/TicketHistory';
import Divider from 'components/UI/Divider';
import { TicketStatus } from 'data/Ticket';
import TicketHistoryItem from './TicketHistoryItem';
import Button from 'components/UI/Button';
import { Color } from 'lib/styles';
import { useAPI } from 'lib/API';
import { getDocumentsForTicket } from 'requests/documents';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import TicketDocument from 'components/Consignments/TicketDocument';
import TicketDocuments from './TicketDocuments';
import { ArrowDropDownRounded, ArrowDropUpRounded } from '@material-ui/icons';
import { printComponent } from 'utils/print';

const Wrapper = styled.div<{ isClosed: boolean, margin: string }>`
  background-color: ${(props) => (props.isClosed ? Color.lightGray : 'white')};
  padding: ${(props) => (props.isClosed ? '16px' : '0')};
  border-radius: ${(props) => (props.isClosed ? '10px' : '0')};
  border: 1px solid ${(props) => (props.isClosed ? Color.lighterGray : 'white')};
  padding: ${(props) => (props.isClosed ? '16px' : '0')};
  box-shadow: ${(props) => (props.isClosed ? '0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)' : '0')};
  margin: ${(props) => props.margin};
`;

const UnderlinedButton = styled.button`
  background: none;
  border: none;
  color: ${Main.accent};
  text-decoration: underline;
  cursor: pointer;
  font-size: 1rem;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: none;
  }
`;

interface TicketHistoryProps {
    ticketEntries: TicketEntry[];
    ticketId?: string;
    showRecent?: boolean;
    showExpand?: boolean;
    alreadyExpanded?: boolean;
    expandText?: string;
    margin?: string;
    showAsOpened?: boolean;
    showDocuments?: boolean;
    consignmentId: string;
    itemKey: number;
    deliveryType?: string;
}

const TicketProgress: React.FC<TicketHistoryProps> = (props) => {
    const { ticketEntries,
            ticketId = null, 
            showRecent = true, 
            showExpand = true, 
            alreadyExpanded = false, 
            expandText = 'Expand', 
            margin = '0px',
            showAsOpened = false, 
            showDocuments = true, 
            consignmentId,
            itemKey,
            deliveryType
        } = props;

    const [isExpanded, setIsExpanded] = useState(alreadyExpanded);
    const [documents, setDocuments] = React.useState<any>({});

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const getDocumentsReq = useAPI({
        deferFn: getDocumentsForTicket,
        onResolve: result => {
            setDocuments(result);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    useEffect(() => {
        if (ticketId) {
            getDocumentsReq.run(ticketId);
        }
    }, []);

    if (!ticketEntries?.length) return null;

    const isClosed = showAsOpened ? false : ticketEntries[0].status === TicketStatus.CLOSED.toString()

    const flattenedDocuments = documents && documents.vendorFiles && documents.vendorFiles.reduce((acc: any, vendorFile: any) => {
        return acc.concat(vendorFile.documents);
    }, []);

    const buttonIcon = () => isExpanded ? <ArrowDropUpRounded /> : <ArrowDropDownRounded />

    const handlePrint = () => printComponent(`ticket-history-printable-content-${itemKey}`, `Ticket history ${consignmentId}`, 'Ticket History');

    return (
        <Wrapper isClosed={isClosed} margin={margin}>
            {!isExpanded && showRecent && (
                <TicketHistoryItem item={ticketEntries[0]} key={ticketEntries[0].id} handlePrint={isClosed ? handlePrint : undefined} deliveryType={deliveryType} />
            )}

            {!isClosed ? showExpand && !isExpanded && <UnderlinedButton onClick={toggleIsExpanded}>{expandText}</UnderlinedButton>
            : !isExpanded && 
            <Button 
                margin='auto' 
                onClick={toggleIsExpanded} 
                rightIcon={buttonIcon}
            >
                Full ticket history
            </Button> }

            {isExpanded && ticketEntries?.map((item, index) => (
                <React.Fragment key={item.id}>
                    <TicketHistoryItem item={item} handlePrint={index === 0 && isClosed ? handlePrint : undefined} deliveryType={deliveryType} />
                    {isClosed ? 
                    index !== ticketEntries.length - 1 &&
                    index !== 0 && <Divider height="1px" bgColor={Color.lighterGray} marginY='10px' noMarginX />
                        : <Divider height="1px" bgColor={'white'} marginY='10px' noMarginX />}

                    {isClosed && index === 0 && 
                    <Button 
                        margin='auto' 
                        onClick={toggleIsExpanded}
                        rightIcon={buttonIcon}
                    >
                        Full ticket history
                    </Button>}

                    {index === ticketEntries.length - 1 && showExpand && !isClosed && (
                        <UnderlinedButton onClick={toggleIsExpanded}>Collapse</UnderlinedButton>
                    )}
                </React.Fragment>
            ))}

            {
                isExpanded && flattenedDocuments?.length > 0 && showDocuments &&
                <TicketDocuments bgColor={isClosed ? Color.lightGray : 'white'}>
                    {
                        flattenedDocuments?.map((document: any, index: number) => (
                            <TicketDocument ticketDocument={document} key={index} />
                        ))
                    }
                </TicketDocuments>
            }
        </Wrapper>
    );
};

export default React.memo(TicketProgress);
