import React, { ReactNode } from 'react';
import styled from 'styled-components';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { Percent } from 'lib/styles';
import { Space } from 'styles/themeSpaces';

const Wrapper = styled.div<any>`
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  background-color: ${({ bgColor }) => bgColor};
`;

interface Props {
  className?: string;
  children: ReactNode;
  width?: string;
  padding?: string;
  margin?: string;
  bgColor?: string;
  isLoading?: boolean;
};

const Page = ({
  className,
  children,
  width,
  padding,
  margin,
  bgColor,
  isLoading
}: Props) => {
  const sharedProps = {
    width,
    padding,
    margin,
    bgColor
  };

  return (
    <Wrapper className={className} {...sharedProps}>
      { isLoading ? <LoadingIndicator /> : children }
    </Wrapper>
  )
};

Page.defaultProps = {
  width: Percent.full,
  padding: Space.sp3x,
  margin: '0',
  isLoading: false
};

export default Page;
