import React, { useState, useEffect } from 'react';
import Vendor from 'data/Vendor';
import styled, { css } from 'styled-components';
import { toast } from 'react-toastify';
import { Color, BorderRadius, Percent, FontSize, FontWeight } from 'lib/styles';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import Select from 'components/UI/Select';
import Modal from 'components/UI/Modal';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import Warehouse, * as Wh from 'data/Warehouse';
import { latoFont } from 'OnboardingComponents/Elements/Typography';
import PointOfService, * as POS from 'data/PointOfService';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import WarehouseLocationCard from 'common/WarehouseLocationCard';
import PickupLocationCard from 'common/PickupLocationCard';
import { renderToast } from 'components/UI/ToastNotification';
import { useAPI } from 'lib/API';
import { MAX_CITY_LENGTH, MAX_STREET_LENGTH, MAX_WAREHOUSE_FIELD_LENGTH, MAX_WAREHOUSE_PROJECT_LENGTH, MAX_WAREHOUSE_SALES_ORG_LENGTH, MAX_WAREHOUSE_SAP_PLANTID_LENGTH, MAX_WAREHOUSE_SUB_SEGMENT_LENGTH, validateCommaSeparatedEmails, validatePostalCode, validateWarehousePosField } from 'lib/validation';
import Icon from 'view/Icon';
import TagsInputComponent from 'view/TagsInputComponent';
import RenderTag from 'components/UI/RenderTag';
import { Text, Border, Main, Background } from 'styles/themeColors';
import { createPointsOfService } from 'requests/pointOfService';
import { createWarehouse, deleteWarehouse, saveWarehouse } from 'requests/warehouse';
import { Space } from 'styles/themeSpaces';
import RadioList from 'components/UI/RadioList';
import Country from 'data/Country';
import { getCountries } from 'requests/country';
import { mapDeliveryOption, parsePointOfServicePayload } from 'utils/warehouse';
import { DELIVERY_OPTIONS, DELIVERY_OPTIONS_LABELS, USER_ROLE } from 'constants/warehouseDeliveryOptions';
import WarehouseAddFormValidator, * as Whv from 'data/WarehouseFormValidator';
import { warehousePosValidations } from 'constants/errorMessages';
import { useAuth } from 'lib/Auth';
import FeatureFlag from 'data/FeatureFlag';

const Content = styled.div`
  flex: 80%;
  background-color: ${Background.white};
  padding: 0 10px;
  background-color: ${Color.lightGray};
`;
const InputHelperMessage = styled.div<any>`
  color: ${Color.darkerGray};
  font-size: ${FontSize.tiny};
`;

const FinalHelperMessage = styled.div<any>`
  color: ${Color.darkerGray};
  font-size: ${FontSize.medium};
`;

const InputHelperErrorMessage = styled.div<any>`
  color: ${Main.error};
  font-size: 18px;
`;

const StepText = styled.div`
  ${latoFont}
  margin: 24px 24px 24px 0px;
  font-size: 30px;
  font-weight: ${FontWeight.bold};
  line-height: 1;
  text-align: left;
  color: ${Main.brandLight};
`;

const StepCard = styled.div`
  width: ${Percent.full};
  display: flex;
  flex-direction: column;
`;

const JustifySplitDiv = styled.div`
  width: ${Percent.full};
  display: flex;
  justify-content: space-between;
`;

const DescriptionText = styled.p`
  ${latoFont}
  font-size: 16px;
  line-height: 1.54;
  text-align: left;
  color: rgba(0, 0, 0, 0.9);
`;

const AddPOSRectangle = styled(Button)`
  width: ${Percent.full};
  display: flex;
  height: 130px;
  border-radius: ${BorderRadius.small};
  border: dashed 2px ${Border.main};
  align-items: center;
  justify-content: center;
  color: ${Text.placeholder};
  background-color: transparent;
`;

const CreateButton = styled(Button)`
  background-color: ${Main.success};
`;

const EditIcon = styled(Icon).attrs({ size: 20 })`
  color: ${Main.accent};

  ${props => props.name === 'edit' && css`
    &:hover{
      color: ${Main.warning};
    }
  `}

  ${props => props.name === 'check' && css`
    color:${Main.success};

    &:hover{
      color: ${Main.success};
    }
  `}

  ${props => props.name === 'x' && css`
    color: ${Main.error};

    &:hover{
      color: ${Main.error};
    }
  `}
`;

const RemoveIcon = styled(Icon).attrs({
  name: 'RemoveIcon',
  size: 20 })`
  color: ${Main.error};

`;

const InputContainer = styled.div <any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Main.accent};
  border-radius: ${BorderRadius.small};
  width: ${Percent.full};
  padding-right: 5px;
  background-color: ${Background.white};

  ${({ disabled }) => disabled && css`
    background-color: ${Color.lightGray};
  `}
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface Props {
  setAddingWarehouse: (state: boolean) => void;
  vendorsList?: Vendor[];
  selectedVendor: Vendor;
  isDisabled?: boolean;
  OptionsDisabled?: boolean;
  customDisabled: boolean;
  setIsDisabled?: (state: boolean) => void;
  currentWarehouse?: Warehouse;
  reloadWarehousesReq: () => void;
  updateVendor: (name: string) => void;
  setSelectedWarehouse?: (warehouse: Warehouse) => void;
  featureFlags: FeatureFlag;
};

const AddWarehouse = ({
  setAddingWarehouse,
  vendorsList,
  selectedVendor,
  currentWarehouse,
  reloadWarehousesReq,
  updateVendor,
  isDisabled,
  customDisabled,
  setIsDisabled,
  OptionsDisabled,
  setSelectedWarehouse,
  featureFlags
}: Props) => {

  const [newWarehouse, setNewWarehouse] = useState<Warehouse>({ id: 0, ...Wh.empty });
  const [editingPickupLocationId, setEditingPickupLocationId] = useState<number | null>(null);
  const [isFulfillmentEmailValid, setIsFulfillmentEmailValid] = useState(true);
  const [showWarehouseCancelModal, setShowWarehouseCancelModal] = useState(false);
  const [showWarehouseRemoveModal, setShowWarehouseRemoveModal] = useState(false);
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState(DELIVERY_OPTIONS.SHIPPING_AND_PICKUP);
  const [countries, setCountries] = useState<Country[]>([]);
  const [warehouseValidator, setWarehouseValidator] = useState<WarehouseAddFormValidator>({ ...Whv.empty });
  const [currentCountry, setCountry] = useState<string>('');
  const [isConfirmCountryUpdateModalOpen, setIsConfirmCountryUpdateModalOpen] = useState(false);
  const [userValidate, setUserValidation] = useState<boolean>(false);
  const [optionValidation, setOptionValidation] = useState<boolean>(false);
  const { user } = useAuth();

  // validate user on the basis of role
  const validateUserRole = (userRole: any, slbVendor: any) => {
    if (slbVendor && (userRole === USER_ROLE.SuperAdmin || userRole === USER_ROLE.VendorAdmin || userRole === USER_ROLE.Admin)) {
      return true;
    } else if (userRole === USER_ROLE.SuperAdmin || userRole === USER_ROLE.Admin) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (selectedVendor.id) {
      setUserValidation(validateUserRole(user?.role, selectedVendor?.isSlbVendor));
    }
  },[selectedVendor]);

  useEffect(() => {
    if (currentWarehouse) {
      setSelectedDeliveryOption(mapDeliveryOption(currentWarehouse))
      setNewWarehouse(currentWarehouse);
      setCountry(currentWarehouse?.address?.country?.countryName);
    }
  }, [currentWarehouse]);

  useEffect(() => {
    if (selectedDeliveryOption) {
      setOptionValidation(false);
      newWarehouse.deliveryOption = selectedDeliveryOption;
    }
  }, [selectedDeliveryOption]);


  const wareHouseCountries = useAPI({
    deferFn: getCountries,
    onResolve: (result: any) => {
      setCountries(result);
    },
    onReject: (err: any) => { },
  });

  useEffect(() => {
    wareHouseCountries.run()
  }, []);


  const removePOS = (posId: number) => {
    setNewWarehouse({ ...newWarehouse, pointsOfService: [...newWarehouse.pointsOfService.filter(el => el.id !== posId)] })
  };

  const updateNewWarehouse = (pos: PointOfService) => {
    setNewWarehouse({
      ...newWarehouse,
      pointsOfService: [pos, ...newWarehouse.pointsOfService]
    });
  };

  const handleChange = (value: any, name: string) => {
    setNewWarehouse({
      ...newWarehouse,
      [name]: value
    });
    setWarehouseValidator({
      ...warehouseValidator,
      [name]: false
    })
  };

  const handleCancel = () => {
    if (currentWarehouse) {
      setNewWarehouse({
        ...newWarehouse,
        street: (currentWarehouse as any).street || '',
        city: (currentWarehouse as any).city || '',
        state: (currentWarehouse as any).state || '',
        zip: (currentWarehouse as any).zip || '',
        country: (currentWarehouse as any).country || ''
      });
    } else {
      setNewWarehouse({
        ...newWarehouse,
        street: '',
        city: '',
        state: '',
        zip: '',
        country: { id: 0, countryName: '' }
      })
    }
    setWarehouseValidator({ ...warehouseValidator, street: false, city: false, state: false, zip: false })
  }

  const validateZipInWarehouse = () => {
    return !validatePostalCode(newWarehouse.zip, newWarehouse?.country?.id);
  }

  const validateCityInWarehouse = () => {
    return !validateWarehousePosField(newWarehouse.city, MAX_CITY_LENGTH);
  }

  const validateStreetInWarehouse = () => {
    return !validateWarehousePosField(newWarehouse.street, MAX_STREET_LENGTH);
  }

  const validateNicknameInWarehouse = () => {
    return !validateWarehousePosField(newWarehouse.nickname, MAX_WAREHOUSE_FIELD_LENGTH);
  }

  const validateIdInWarehouse = () => {
    return !validateWarehousePosField(newWarehouse.warehouseId, MAX_WAREHOUSE_FIELD_LENGTH);
  }

  const validateFullnameInWarehouse = () => {
    return !validateWarehousePosField(newWarehouse.name, MAX_WAREHOUSE_FIELD_LENGTH);
  }

  const validateSapPlantIdWarehouse = () => {
    return !validateWarehousePosField(newWarehouse.sapPlantId, MAX_WAREHOUSE_SAP_PLANTID_LENGTH);
  }

  const validateSalesOrgWarehouse = () => {
    return !validateWarehousePosField(newWarehouse.salesOrg, MAX_WAREHOUSE_SALES_ORG_LENGTH);
  }

  const validateSubSegmentWarehouse = () => {
    return !validateWarehousePosField(newWarehouse.subSegment, MAX_WAREHOUSE_SUB_SEGMENT_LENGTH);
  }

  const validateProjectWarehouse = () => {
    return !validateWarehousePosField(newWarehouse.project, MAX_WAREHOUSE_PROJECT_LENGTH);
  }

  const handleValueOnBlur = (name: keyof Warehouse) => {
    if(name){
      setNewWarehouse({
        ...newWarehouse,
        [name]: newWarehouse[name].trim()
      });
    }
  }

  const onInputBlur = (name: string, validatorFunction: () => void) => {
    (newWarehouse as any)[name]?.length > 0 ?
      setWarehouseValidator({
        ...warehouseValidator,
        [name]: validatorFunction()
      }) :
      setWarehouseValidator({
        ...warehouseValidator,
        [name]: false
      });

      handleValueOnBlur(name as keyof Warehouse)
  }

  const clearFieldValues = (value: any, name: string) => {
      const pointsOfService =  newWarehouse.pointsOfService.map(pos => {
    if(pos.countryId !== value.id){
    return ({ ...pos, state: '' })
   }
   return pos
   }
   )
    setNewWarehouse({
      ...newWarehouse,
      [name]: value,
      ['street']: '',
      ['city']: '',
      ['zip']: '',
      ['state']: '',
      pointsOfService
    });
    setWarehouseValidator({
      ...warehouseValidator,
      [name]: false
    })
  }

  const cancelConfirmCountryUpdateModal = () => {
    setIsConfirmCountryUpdateModalOpen(false);
  }

  const onConfirmCountryUpdateModalClick = () => {
    setIsConfirmCountryUpdateModalOpen(false);
    const { country, ...rest } = newWarehouse;
    const newWarehousePayload = {
      ...rest,
      countryId: Number(newWarehouse?.country?.id),
      pointsOfService: newWarehouse.pointsOfService.map(pos => parsePointOfServicePayload(pos))
    }
    updateReq.run(newWarehousePayload);
  }

  const confirmCountryUpdateModal = (
    <Modal
      showModal={isConfirmCountryUpdateModalOpen}
      onClose={cancelConfirmCountryUpdateModal}
      onClick={onConfirmCountryUpdateModalClick}
      title="Country Update"
      primaryButtonText="Ok"
    >
      <FlexboxContainer>
        <Span>
          You are trying to change the warehouse country, all existing POS locations will be deleted, click 'Ok' to continue
        </Span>
      </FlexboxContainer>
    </Modal>
  );

  const validateAdministratorEmail = (value: string[]) => {
    setNewWarehouse({
      ...newWarehouse,
      fulfilmentEmails: value && value.toString()
    });

    const valid = validateCommaSeparatedEmails(value && value.toString());
    setIsFulfillmentEmailValid(valid);
  }

  const createPOS = useAPI({
    deferFn: createPointsOfService,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, currentWarehouse ? 'Warehouse updated successfully.' : 'Warehouse created successfully.');
      reloadWarehousesReq();
      setAddingWarehouse(false);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const createReq = useAPI({
    deferFn: createWarehouse,
    onResolve: (result) => {
      renderToast(toast.TYPE.SUCCESS, 'Warehouse created successfully.');
      reloadWarehousesReq();
      setAddingWarehouse(false);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const updateReq = useAPI({
    deferFn: saveWarehouse,
    onResolve: result => {
        renderToast(toast.TYPE.SUCCESS, 'Warehouse updated successfully.');
        reloadWarehousesReq();

      if (setIsDisabled) {
        setIsDisabled(true);
      }
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });


  const deleteWarehouseRequest = useAPI({
    deferFn: deleteWarehouse,
    onResolve: () => {
      setShowWarehouseRemoveModal(false);
      renderToast(toast.TYPE.SUCCESS, 'Warehouse deleted successfully.');
      setNewWarehouse({ id: 0, ...{ ...Wh.empty, pointsOfService: [{ id: 0, ...POS.empty }] }, fulfilmentEmails: '' });
      setAddingWarehouse(false);
      setSelectedWarehouse && setSelectedWarehouse({ id: 0, ...Wh.empty });
      reloadWarehousesReq();
    },
    onReject: err => {
      setShowWarehouseRemoveModal(false);
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });


  const upsertWarehouse = () => {
    const { country, ...rest } = newWarehouse;
    const newWarehousePayload = {
      ...rest,
      countryId: Number(newWarehouse?.country?.id),
      pointsOfService:  selectedDeliveryOption === DELIVERY_OPTIONS.SHIPPING_ONLY ? [] : newWarehouse.pointsOfService.map(pos => parsePointOfServicePayload(pos))
    }

    if (currentWarehouse && setIsDisabled) {
      if (currentCountry !== country?.countryName) {
        setIsConfirmCountryUpdateModalOpen(true);
      } else {
        updateReq.run(newWarehousePayload);
      }
    } else {
      if(newWarehouse.deliveryOption !== DELIVERY_OPTIONS.SHIPPING_ONLY && newWarehouse.pointsOfService.length === 0){
          setOptionValidation(true);
          renderToast(toast.TYPE.ERROR, warehousePosValidations.POS_REQUIRED);
      }else{
        createReq.run(newWarehousePayload, selectedVendor.id);
      }
    }
  };

  const upsertPOS = (pos: PointOfService) => {
    setOptionValidation(false);
    const found = newWarehouse.pointsOfService.find(el => el.id === pos.id);
    if (found) {
      setNewWarehouse({ ...newWarehouse, pointsOfService: newWarehouse.pointsOfService.map(el => el.id === pos.id ? pos : el) });
    } else {
      setNewWarehouse({ ...newWarehouse, pointsOfService: [...newWarehouse.pointsOfService, { ...pos, id: newWarehouse.pointsOfService.length + 1 }] });
    }
  };

  const addNewPOSClick = () => {
    const posCount = newWarehouse.pointsOfService.length;
    setEditingPickupLocationId(posCount + 1);
    setNewWarehouse({ ...newWarehouse, pointsOfService: [...newWarehouse.pointsOfService, { id: posCount + 1, ...POS.empty }] })
  };

  const onConfirmCancelModalClick = () => {
    if (currentWarehouse && setIsDisabled) {
      setIsDisabled(true);
      reloadWarehousesReq();
      setNewWarehouse({...currentWarehouse,
        sapPlantId: currentWarehouse.sapPlantId || '',
        salesOrg: currentWarehouse.salesOrg || '',
        subSegment: currentWarehouse.subSegment || '',
        project: currentWarehouse.project || ''
      });
    } else {
      setNewWarehouse({ id: 0, ...{ ...Wh.empty, pointsOfService: [{ id: 0, ...POS.empty }] }, fulfilmentEmails: '' });
      setAddingWarehouse(false);
    }

    setShowWarehouseCancelModal(false);
  };

  const confirmCancelModal = (
    <Modal
      showModal={showWarehouseCancelModal}
      onClose={() => setShowWarehouseCancelModal(false)}
      onClick={onConfirmCancelModalClick}
      title="Are you sure you want to cancel?"
      primaryButtonText="Confirm cancel"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          Unsaved progress will be lost
        </Span>
      </FlexboxContainer>
    </Modal>
  )
  const onSelectValue = (event: any) => {
    if (vendorsList) {
      const foundVendor = vendorsList.find(vendor => vendor.name === event.target.value);

      if (foundVendor) {
        updateVendor(foundVendor.name);
      }
    }
  };

  const isPOSButtonDisabled = () => {
    if(!newWarehouse.pointsOfService.length || selectedDeliveryOption === DELIVERY_OPTIONS.SHIPPING_ONLY || customDisabled)
    return false
   const result =  newWarehouse.pointsOfService.map(pos => (!pos.nickname
      || !pos.name
      || !pos.posId
      || !pos.street
      || !pos.city
      || !pos.state
      || !pos.zip
      ));
      return result.includes(true)
  }

  let createButtonDisabled = !!(
    !newWarehouse.nickname ||
    !newWarehouse.warehouseId ||
    !newWarehouse.name ||
    ((selectedVendor?.isStockApiEnabled || selectedVendor?.isSalesApiEnabled) && userValidate) && !newWarehouse.sapPlantId ||
    (featureFlags?.salesOrder?.enabled && selectedVendor?.isSalesApiEnabled && userValidate) && !newWarehouse.salesOrg ||
    (featureFlags?.salesOrder?.enabled && selectedVendor?.isSalesApiEnabled && userValidate) && !newWarehouse.subSegment ||
    (featureFlags?.salesOrder?.enabled && selectedVendor?.isSalesApiEnabled && userValidate) && !newWarehouse.project ||
    !newWarehouse.street ||
    !newWarehouse.city ||
    !newWarehouse.state ||
    !newWarehouse.zip ||
    !!(newWarehouse.nickname && newWarehouse.nickname.trim().length < 3) ||
    !!(newWarehouse.warehouseId && newWarehouse.warehouseId.trim().length < 2) ||
    !!(newWarehouse.name && newWarehouse.name.trim().length < 3) ||
    !!(newWarehouse.street && newWarehouse.street.trim().length < 3) ||
    !!(newWarehouse.city && newWarehouse.city.trim().length < 3) ||
    !!(newWarehouse.state && newWarehouse.state.trim().length < 2) ||
    !!(newWarehouse.zip && newWarehouse.zip.trim().length < 5) ||
    !!(newWarehouse.fulfilmentEmails && newWarehouse.fulfilmentEmails.trim() === '') ||
    !isFulfillmentEmailValid ||
    !newWarehouse?.country ||
    warehouseValidator.nickname ||
    warehouseValidator.name ||
    warehouseValidator.warehouseId ||
    (selectedVendor?.isStockApiEnabled && userValidate) && warehouseValidator.sapPlantId ||
    (featureFlags?.salesOrder?.enabled && selectedVendor?.isSalesApiEnabled && userValidate) && warehouseValidator.salesOrg ||
    (featureFlags?.salesOrder?.enabled && selectedVendor?.isSalesApiEnabled && userValidate) && warehouseValidator.subSegment ||
    (featureFlags?.salesOrder?.enabled && selectedVendor?.isSalesApiEnabled && userValidate) && warehouseValidator.project ||
    (featureFlags?.salesOrder?.enabled && selectedVendor?.isSalesApiEnabled && userValidate) && warehouseValidator.sapPlantId ||
    isPOSButtonDisabled()
  );

  let actionButtons = null;

  const onEditClick = () => {
    if (setIsDisabled) {
      setIsDisabled(false);
    }
  }
  
  const onConfirmRemoveWarehouse = () => {
    if (currentWarehouse) {
      
      deleteWarehouseRequest.run(currentWarehouse.id);
    }
  }

  const confirmRemoveWarehouseModal = (
    <Modal
      showModal={showWarehouseRemoveModal}
      onClose={() => setShowWarehouseRemoveModal(false)}
      onClick={onConfirmRemoveWarehouse}
      title="Delete Warehouse"
      primaryButtonText="Delete"
      ghostButtonText="Cancel"
      theme={'brandDark'}
      borderColor={Main.disabled}
      color={Color.black}
      showCloseIcon
      disabled={deleteWarehouseRequest.isPending}
    >
      <FlexboxContainer flexDirection="column">
        <Span>
        Are you sure you want to delete this warehouse?
        </Span>
      </FlexboxContainer>
    </Modal>
  )



  if (isDisabled) {
    actionButtons = (
      <FlexboxContainer justifyContent="flex-end" padding='5px 0 0 0'>
        <EditIcon disabled={isDisabled} name="edit" onClick={onEditClick} />
       
       {/** As a Vendor (vendor admin, superadmin) */}
       {(user?.role === USER_ROLE.SuperAdmin || user?.role === USER_ROLE.VendorAdmin || user?.role === USER_ROLE.Admin) &&  <RemoveIcon disabled={isDisabled}  onClick={() => setShowWarehouseRemoveModal(true)} /> }
      </FlexboxContainer>
    );
  }

  if (!isDisabled) {
    actionButtons = (
      <FlexboxContainer justifyContent="flex-end">
        <EditIcon name="edit-3" disabled={true} />
      </FlexboxContainer>
    );
  }

  const createButtons = (
    <FlexboxContainer border="1px 0 0 0 solid lightgrey" margin="20px 0 0 0" justifyContent="flex-start">
      <Button onClick={() => setShowWarehouseCancelModal(true)} margin="0 10px 0 0" isGhost color={Main.accent}>
        Cancel
      </Button>
      <CreateButton onClick={upsertWarehouse} disabled={createButtonDisabled || createReq.isPending || updateReq.isPending} theme="info">
        Done
      </CreateButton>
    </FlexboxContainer>
  );

  const posStep = (
    <StepCard>
      <StepText style={{ color:  optionValidation  ? 'red' : ''}}>
        {vendorsList && ((selectedVendor?.isStockApiEnabled || selectedVendor.isSalesApiEnabled) && userValidate) ? 'Step 8:' : vendorsList ? 'Step 7:' : ((selectedVendor?.isStockApiEnabled || selectedVendor.isSalesApiEnabled) && userValidate) ? 'Step 7:' : 'Step 6:'} Add your pick-up locations
      </StepText>
      {newWarehouse.pointsOfService.filter(el => el.id !== 0).map((el, i) => (
        <PickupLocationCard
          key={i}
          pickupLocation={{
            ...el,
            street: el.street,
            city: el.city,
            zip: el.zip,
            state: el.state
          }}
          removePOS={removePOS}
          upsertPOS={upsertPOS}
          editingId={editingPickupLocationId}
          setEditingId={setEditingPickupLocationId}
          reloadWarehousesReq={reloadWarehousesReq}
          country={newWarehouse.country}
        />
      ))}
      <AddPOSRectangle disabled={editingPickupLocationId !== null} onClick={addNewPOSClick}>+ Add an aditional pick-up location</AddPOSRectangle>
    </StepCard>
  );

  let dropdownVendors = vendorsList ? (
    <StepCard>
      <StepText>
        Step 1: Choose a vendor
      </StepText>
      <DescriptionText>
        Vendor you wish to add a warehouse to...
      </DescriptionText>
      <Select
        options={vendorsList.map(vendor => ({ value: vendor.name }))}
        value={selectedVendor ? selectedVendor.name : ''}
        defaultValue="Select vendor"
        onChange={onSelectValue}
        width="100%"
        disabled={isDisabled || customDisabled}
      />
    </StepCard>
  ) : null;

  const emailErrorMessage = isFulfillmentEmailValid ? null : <InputHelperErrorMessage>Please enter a valid email address</InputHelperErrorMessage>;
  // const addressErrorMessage = (newWarehouse.street && newWarehouse.street.length < 3) || (newWarehouse.city && newWarehouse.city.length < 3) || (newWarehouse.state && newWarehouse.state.length < 2) || (newWarehouse.zip && newWarehouse.zip.length < 5) ? <InputHelperErrorMessage>Please enter a valid address</InputHelperErrorMessage> : '';
  const nameErrorMessage = newWarehouse.name && newWarehouse.name.length < 3 ? <InputHelperErrorMessage>Please enter a valid warehouse name</InputHelperErrorMessage> : '';
  const nicknameErrorMessage = newWarehouse.nickname && newWarehouse.nickname.length < 3 ? <InputHelperErrorMessage>Please enter a valid nickname</InputHelperErrorMessage> : '';
  const warehouseIdErrorMessage = newWarehouse.warehouseId && newWarehouse.warehouseId.length < 2 ? <InputHelperErrorMessage>Please enter a valid warehouse id</InputHelperErrorMessage> : '';

  let details = (
    <Content>
      {actionButtons}
      {dropdownVendors}
      <StepCard>
        <StepText>
          {vendorsList ? 'Step 2:' : 'Step 1:'} Give your warehouse a nickname and an ID
        </StepText>
        <JustifySplitDiv>
          <div style={{ width: '48%' }}>
            <Input
              id='warehouse-nickname-id'
              labelName="Warehouse nickname"
              placeholder={isDisabled ? '' : 'Insert nickname for your warehouse'}
              value={newWarehouse.nickname || ''}
              onInputChange={(val) => handleChange(val, 'nickname')}
              width="100%"
              disabled={isDisabled}
              displayAsterisk={!isDisabled}
              isInvalid={warehouseValidator.nickname}
              onBlur={() => onInputBlur && onInputBlur('nickname', validateNicknameInWarehouse)}
              errorMessage={warehousePosValidations.INVALID_WAREHOUSE_FIELDS}
            />
          </div>
          <div style={{ width: '48%' }}>
            <Input
              id='warehouse-id'
              labelName="Warehouse ID"
              placeholder={isDisabled ? '' : 'Insert ID for your warehouse'}
              value={newWarehouse.warehouseId || ''}
              onInputChange={(val) => handleChange(val, 'warehouseId')}
              width="100%"
              disabled={isDisabled}
              displayAsterisk={!isDisabled}
              isInvalid={warehouseValidator.warehouseId}
              onBlur={() => onInputBlur && onInputBlur('warehouseId', validateIdInWarehouse)}
              errorMessage={warehousePosValidations.INVALID_WAREHOUSE_FIELDS}
            />
          </div>
        </JustifySplitDiv>
      </StepCard>
      <FlexboxContainer justifyContent="space-between" width="80%" margin="0 20">
        {isDisabled ? null : nicknameErrorMessage}
        {isDisabled ? null : warehouseIdErrorMessage}
      </FlexboxContainer>
      <StepCard>
        <StepText>
          {vendorsList ? 'Step 3:' : 'Step 2:'} Write its full name for reference
        </StepText>
        <JustifySplitDiv>
          <Input
            id='full-warehouse-name-id'
            labelName="Full warehouse name"
            placeholder={isDisabled ? '' : 'Insert name for your warehouse'}
            value={newWarehouse.name}
            onInputChange={(val) => handleChange(val, 'name')}
            width="100%"
            disabled={isDisabled}
            displayAsterisk={!isDisabled}
            isInvalid={warehouseValidator.name}
            onBlur={() => onInputBlur && onInputBlur('name', validateFullnameInWarehouse)}
            errorMessage={warehousePosValidations.INVALID_WAREHOUSE_FIELDS}
          />
        </JustifySplitDiv>
      </StepCard>
      {isDisabled ? null : nameErrorMessage}
      <StepCard>
        <StepText>
          {vendorsList ? 'Step 4:' : 'Step 3:'} Where is your warehouse located?
        </StepText>
        <WarehouseLocationCard
          warehouse={newWarehouse}
          updateNewWarehouse={updateNewWarehouse}
          handleChange={handleChange}
          removePOS={removePOS}
          isEditable={!currentWarehouse}
          isDisabled={isDisabled}
          countries={countries}
          warehouseValidator={warehouseValidator}
          onInputBlur={onInputBlur}
          validateZipCode={validateZipInWarehouse}
          validateCity={validateCityInWarehouse}
          validateStreet={validateStreetInWarehouse}
          clearFieldValues={clearFieldValues}
          customDisabled={customDisabled}
          handleCancel={handleCancel}
        />
      </StepCard>
      {/* {isDisabled ? null : addressErrorMessage} */}
      <StepCard>
        <StepText>
          {vendorsList ? 'Step 5:' : 'Step 4:'} Who will receive fulfillment notifications?
        </StepText>
        <InputContainer disabled={isDisabled}>
          <TagsInputComponent
            value={newWarehouse.fulfilmentEmails?.split(',').filter(value => value !== '') || []}
            onChange={validateAdministratorEmail}
            addKeys={[9, 13, 188]}
            inputProps={{
              className: 'react-tagsinput-input',
              placeholder: newWarehouse.fulfilmentEmails?.split(',').length === 0 ? 'Enter order notifications email recipients' : '',
            }}
            tagProps={{
              className: 'react-tagsinput-tag',
              closeIconName: 'HighlightOff',
              color: isDisabled ? Color.darkerGray : Color.black
            }}
            renderTag={RenderTag}
            withDelimiter
            disabled={isDisabled}
            bgColor={isDisabled ? "transparent" : Background.white}
          />
        </InputContainer>
        <InputHelperMessage>Add multiple message recipients separated by commas</InputHelperMessage>
        {emailErrorMessage}
      </StepCard>
      <StepCard>
        <StepText>
          {vendorsList ? 'Step 6:' : 'Step 5:'} Which delivery options are
          available at this warehouse?
        </StepText>
        <FinalHelperMessage>This selection is final.<br/>Because this selection will affect live products and order, you will not be able to change it once the warehouse is created.</FinalHelperMessage>
        <RadioList
          isDisabled={isDisabled || OptionsDisabled}
          options={DELIVERY_OPTIONS_LABELS}
          selectedOption={selectedDeliveryOption}
          setSelectedOption={setSelectedDeliveryOption}
          color={Main.brandLight}
        />
      </StepCard>
      {
        (selectedVendor?.isStockApiEnabled || selectedVendor.isSalesApiEnabled) && userValidate ?
          <StepCard>
            <StepText>
              {vendorsList ? 'Step 7:' : 'Step 6:'} Provide API Settings
            </StepText>

           {
            featureFlags.salesOrder && featureFlags.salesOrder.enabled && selectedVendor.isSalesApiEnabled &&
            <JustifySplitDiv>
              <div style={{ width: '48%' }}>
                <Input
                  id='warehouse-sales-org'
                  labelName="Sales Org"
                  placeholder={isDisabled ? '' : 'Insert Sales Org for your warehouse'}
                  value={newWarehouse.salesOrg}
                  onInputChange={(val) => handleChange(val, 'salesOrg')}
                  width="100%"
                  disabled={isDisabled}
                  displayAsterisk={!isDisabled}
                  isInvalid={warehouseValidator.salesOrg}
                  onBlur={() => onInputBlur && onInputBlur('salesOrg', validateSalesOrgWarehouse)}
                  errorMessage={warehousePosValidations.INVALID_SALES_ORG_FIELD}
                />
              </div>

              <div style={{ width: '48%' }}>
                <Input
                  id='warehouse-sub-segment'
                  labelName="Sub Segment"
                  placeholder={isDisabled ? '' : 'Insert Sub Segment for your warehouse'}
                  value={newWarehouse.subSegment}
                  onInputChange={(val) => handleChange(val, 'subSegment')}
                  width="100%"
                  disabled={isDisabled}
                  displayAsterisk={!isDisabled}
                  isInvalid={warehouseValidator.subSegment}
                  onBlur={() => onInputBlur && onInputBlur('subSegment', validateSubSegmentWarehouse)}
                  errorMessage={warehousePosValidations.INVALID_SUB_SEGMENT_FIELD}
                />
              </div>

            </JustifySplitDiv>
            }

            <JustifySplitDiv style={{ marginTop: 10 }}>

             {selectedVendor.isSalesApiEnabled && featureFlags.salesOrder && featureFlags.salesOrder.enabled &&
              <div style={{ width: '48%' }}>
                <Input
                  id='warehouse-project'
                  labelName="Project"
                  placeholder={isDisabled ? '' : 'Insert Project for your warehouse'}
                  value={newWarehouse.project}
                  onInputChange={(val) => handleChange(val, 'project')}
                  width="100%"
                  disabled={isDisabled}
                  displayAsterisk={!isDisabled}
                  isInvalid={warehouseValidator.project}
                  onBlur={() => onInputBlur && onInputBlur('project', validateProjectWarehouse)}
                  errorMessage={warehousePosValidations.INVALID_PROJECT_FIELD}
                />
              </div>
              }
              
              <div style={{ width: selectedVendor.isSalesApiEnabled && featureFlags?.salesOrder?.enabled ? '48%' : '100%' }}>
                <Input
                  id='warehouse-plant-id'
                  labelName="Plant/Location"
                  placeholder={isDisabled ? '' : 'Insert Plant/Location for your warehouse'}
                  value={newWarehouse.sapPlantId ? newWarehouse.sapPlantId : ''}
                  onInputChange={(val) => handleChange(val, 'sapPlantId')}
                  width="100%"
                  disabled={isDisabled}
                  displayAsterisk={!isDisabled}
                  isInvalid={warehouseValidator.sapPlantId}
                  onBlur={() => onInputBlur && onInputBlur('sapPlantId', validateSapPlantIdWarehouse)}
                  errorMessage={warehousePosValidations.INVALID_SAP_PLANTID_FIELD}
                />
              </div>

            </JustifySplitDiv>
          </StepCard> : null
      }
      {currentWarehouse ||
        selectedDeliveryOption === DELIVERY_OPTIONS.SHIPPING_ONLY
        ? null
        : posStep}
      {isDisabled ? null : createButtons}
    </Content>
  );

  if (createReq.isLoading || createPOS.isLoading) {
    details = <LoadingIndicator />;
  }

  return (
    <Content>
      {confirmRemoveWarehouseModal}
      {confirmCancelModal}
      {confirmCountryUpdateModal}
      {details}
    </Content>
  )
};

export default AddWarehouse;
