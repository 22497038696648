import React from 'react';

interface Props {
    videoId: string;
}

const YouTubeEmbed = ({ videoId }: Props) => {
  const iframeSrc = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="video-responsive">
      <iframe
        width="510px"
        height="315px"
        frameBorder="0"
        src={iframeSrc}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
};

export default YouTubeEmbed;