import Country from './Country';
import PointOfService from './PointOfService';

export default interface Warehouse {
  id: number;
  warehouseId: string;
  nickname: string;
  code: string;
  name: string;
  street: string;
  city: string;
  state: string;
  zip: string;
  display?: boolean;
  delivery: boolean;
  pickup: boolean;
  pointsOfService: PointOfService[];
  fulfilmentEmails: string;
  deliveryOption: string;
  country: Country;
  address: any,
  sapPlantId: string;
  salesOrg: string;
  subSegment: string;
  project: string;
  type: string;
};

export type PartialWarehouse = Pick<Warehouse, 'name'> & { vendorId: number };

export const empty: Omit<Warehouse, 'id'> = {
  code: '',
  nickname: '',
  warehouseId: '',
  name: '',
  street: '',
  city: '',
  state: '',
  zip: '',
  delivery: true,
  pickup: true,
  pointsOfService: [],
  fulfilmentEmails: '',
  deliveryOption: '',
  country: { id: 0, countryName: '' },
  address: {},
  sapPlantId: '',
  salesOrg: '',
  subSegment: '',
  project: '',
  type: '',
};
