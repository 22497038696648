import React, { useState, FocusEvent } from 'react';
import styled, { css } from 'styled-components';
import { Search } from '@material-ui/icons';
import { Input } from '@material-ui/core';
import Button from 'components/UI/Button';
import ClearAdornment from 'components/UI/ClearAdornment';
import useKeyboardListeners from 'hooks/useKeyboardListeners';
import { Background, Border, Main, Text, Shade } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { Height } from 'styles/themeHeights';
import { BorderRadius } from 'styles/themeBorderRadius';

const SearchContainer = styled.div<any>`
  display: flex;
  align-items: center;
  background-color: ${Background.white};
  border: 1px solid ${Border.main};
  border-radius: ${BorderRadius.m};
  width: ${({ $containerWidth }) => $containerWidth};
  height: ${Height.m};

  ${({ $focused }) => $focused && css`
    box-shadow: 0 0 3px ${Main.accent};
  `}
  
  &:hover {
    border: 1px solid ${Main.accent};
  }
`;

const StyledInput = styled(Input) <any>`
  width: ${({ $inputWidth }) => $inputWidth};
  border: ${({ $inputBorder }) => $inputBorder};
  border-radius: ${BorderRadius.m};
  height: 100%;

  #search-bar {
    padding: ${Space.spBase};
  }
`;

SearchContainer.displayName = 'SearchContainer';
StyledInput.displayName = 'StyledInput';

interface Props {
  placeholder?: string;
  searchValue: string;
  inputBorder?: string;
  inputWidth?: string;
  containerWidth?: string;
  onSearch: (searchValue: string) => void;
};

const SearchBar = ({
  containerWidth,
  placeholder,
  searchValue,
  inputBorder,
  inputWidth,
  onSearch
}: Props) => {
  const [inputFocused, setInputFocused] = useState(false);
  const [value, setValue] = useState(searchValue);

  const onClear = () => {
    onSearch('');
    setValue('');
  };
  const onSearchHandler = () => onSearch(value);

  useKeyboardListeners({ enable: inputFocused, onEsc: onClear, onEnter: onSearchHandler });

  /* 
    Transient props are a new pattern to pass props that are explicitly consumed only by styled components 
    and are not meant to be passed down to deeper component layers.
    Example: $height (dollar sign)
  */
  return (
    <SearchContainer
      onFocus={() => setInputFocused(true)}
      onBlur={() => setInputFocused(false)}
      $containerWidth={containerWidth}
      $focused={inputFocused}
    >
      <StyledInput
        id="search-bar"
        value={value}
        onChange={(event: FocusEvent<HTMLInputElement>) => setValue(event.target.value)}
        onBlur={onSearchHandler}
        disableUnderline
        endAdornment={<ClearAdornment onClear={onClear} show={value.length > 0} />}
        placeholder={placeholder}
        $inputBorder={inputBorder}
        $inputWidth={inputWidth}
      />
      <Button
        id="search-button"
        bgColor={Shade.shade020}
        borderColor={Border.main}
        onClick={onSearchHandler}
        leftIcon={(size: string) => <Search style={{ height: Height.s, width: '24px', color: Text.primary }} />}
      />
    </SearchContainer>
  )
};

SearchBar.defaultProps = {
  placeholder: 'Search',
  inputBorder: 'none',
  inputWidth: '100%'
};

export default SearchBar;
