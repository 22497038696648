import React, { useState } from 'react';
import { Icon, IconButton, MenuItem } from '@material-ui/core';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Popper from '@material-ui/core/Popper';
import { Text } from '../../styles/themeColors';
import { MoreVert } from '@material-ui/icons';

export type ActionMenuItem = {
  title: string,
  id: number,
  callBack?: (event: any, id: number) => void
}

export default function ActionMenu({ actionMenu, data }: { actionMenu: ActionMenuItem[] | undefined, data: any }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleIconClick = (event: any) => { setAnchorEl(event.currentTarget) };
  const handleClose = () => { setAnchorEl(null) };

  const handleMenuItemClick = (menuItem: ActionMenuItem) => {
    if (menuItem && menuItem.callBack) {
      menuItem.callBack(data, menuItem.id);
    }

    handleClose();
  }

  return (
    <div style={{margin: 'auto', zIndex: 10}}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        style={{padding: 14, color: Text.primary}}
      >
        <MoreVert
          onClick={handleIconClick}
          className='material-symbols-rounded'
        />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="left-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
              margin: 6,
            }}
          >
            <Paper style={{ zIndex: 20 }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow" >
                  {
                    (actionMenu ? actionMenu : []).map(menuItem => (
                      <MenuItem
                        key={menuItem.title}
                        onClick={() => { handleMenuItemClick(menuItem) }}>
                        {menuItem.title}
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
