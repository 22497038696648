export function clear(key: string): Promise<void> {
  return new Promise(resolve => {
    window.sessionStorage.removeItem(key);
    resolve();
  });
}

export function load(key: string, defaultValue: any = null): Promise<any> {
  return new Promise(resolve => {
    const data = window.sessionStorage.getItem(key);
    resolve(data === null ? defaultValue : JSON.parse(data));
  });
}

export function save(key: string, data: any): Promise<void> {
  return new Promise(resolve => {
    window.sessionStorage.setItem(key, JSON.stringify(data));
    resolve();
  });
}

export function getItems(key:string) {
  return JSON.parse(sessionStorage.getItem(key) || '[]');
}
