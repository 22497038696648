export default interface BrandVendor {
    brandId: number;
    vendorId: number;
    isMaterialApiEnabled: boolean;
    isStockApiEnabled: boolean
    isSalesOrderApiEnabled: boolean;
};
  
export const empty: BrandVendor = {
    brandId: 0,
    vendorId: 0,
    isMaterialApiEnabled: false,
    isStockApiEnabled: false,
    isSalesOrderApiEnabled: false
};
  