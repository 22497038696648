export const theme = {
  textBlack: '#191919',
  charcoal: '#18222b',
  blue: '#498dda',
  lightBlue: '#ebf0fb',
  grey: '#e2e3e4',
  greyTwo: '#f0f1f4',
  greyThree: '#f7f8fb',
  baseFontSize: '14px'
};
