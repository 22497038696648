import { ConsignmentDetails } from 'data/Consignment';
import { get, put, post } from 'lib/API';

export const updateFulfillment = async (
  [consignmentId, updatedConsignment]: any[],
  { token }: any
): Promise<ConsignmentDetails> => {
  return await put(`v1/consignments/fulfillment/${consignmentId}`, token, updatedConsignment);
};

export const updateDelivery = async (
  [consignmentId, shippingConsignmentRequest, isConfirmed]: any,
  { token }: any
): Promise<ConsignmentDetails> => {
  if (isConfirmed === undefined) isConfirmed = true
  return await put(`v1/consignments/delivery/${consignmentId}`, token, { ...shippingConsignmentRequest, isConfirmed });
};

export const updateDispatch = async (
  [consignmentId, dispatchRequest]: any,
  { token }: any
): Promise<ConsignmentDetails> => {
  return await put(`v1/consignments/dispatch/${consignmentId}`, token, dispatchRequest);
};

export const loadConsignment = async (
  [consignmentId, vendorId]: any,
  { token }: any
): Promise<ConsignmentDetails> => {
  return await get(`v1/consignments/${consignmentId}${vendorId ? '?vendorId=' + vendorId : ''}`, token);
};

export const updateSalesOrderNumber = async (
  [consignmentId, salesOrderNumber]: any,
  { token }: any
): Promise<ConsignmentDetails> => {
  return await put(`v1/consignments/salesOrder/${consignmentId}`, token, { salesOrderNumber: salesOrderNumber });
};

export const updateState = async (
  [consignmentId, state, cancellationReason, cancelledBy, stockQuantity, reasonType, availableDate = null]: any,
  { token }: any
): Promise<ConsignmentDetails> => {
  let payload: any = {
    state,
    cancellationReason: cancellationReason || null,
    cancelledBy: cancelledBy || null,
    stockQuantity: stockQuantity || [],
    reasonType: reasonType || null
  };

  if(availableDate){
    payload['availableDate'] = availableDate
  }

  return await put(`v1/consignments/${consignmentId}/state`, token, payload);
};

export const updateRentalState = async (
  [consignmentId, state, vendorAction = null, newRentalStartDate = null, newRentalEndDate = null, reason = null, paymentStatus, isOverridden = false, trackingId = null, estDate = null, carrier = null]: any,
  { token }: any
): Promise<ConsignmentDetails> => {
  return await put(`v1/consignments/updateRentalState/${consignmentId}`, token, {
    state,
    vendorAction,
    newRentalEndDate,
    newRentalStartDate,
    reason,
    paymentStatus,
    isOverridden,
    trackingId,
    estDate,
    carrier
  });
};

export const saveConsignment = async ([consignment, dowJones]: any[], { token }: any): Promise<any> => {
  return await put(`v1/consignments`, token, {
    consignment,
    dowJones
  });
};

export const consignmentNewShortPayment = async (
  [consignmentId, paid, transactionNumber, date]: any,
  { token }: any
): Promise<any> => {
  return await post(`v1/consignments/shortpayments`, token, {
    consignmentId,
    paid,
    transactionNumber,
    date
  });
};

export const emulateSplitConsignment = async (
  [consignmentId, consignment]: any[],
  { token }: any
): Promise<any> => {

  const modifiedConsignment = {
    ...consignment,
    entries: consignment.entries.map((item: any) => {
      return {
        entryNumber: item.entryNumber,
        quantity: item.quantity,
        committedQuantity: item.committedQuantity,
        manufactureQuantity: item.manufactureQuantity,
        cancelQuantity: item.cancelQuantity,
        committedDate: item.committedDate || 0,
        manufactureDate: item.manufactureDate,
        committedEndDate: item.committedEndDate || 0,
        manufactureEndDate: item.manufactureEndDate || 0
      };
    })
  };

  return await post(`v1/consignments/splitConsignment/${consignmentId}`, token, modifiedConsignment);
};

export const updateAdditionalShippingCost = async (
  [consignmentId, additionalShippingCostRequest]: any,
  { token }: any
): Promise<ConsignmentDetails> => {
  return await put(`v1/consignments/additionalShippingCost/${consignmentId}`, token, additionalShippingCostRequest);
};
