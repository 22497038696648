import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import ConsignmentEntry from 'data/ConsignmentEntry';
import { CANCELED, CANCELING, MANUFACTURING, PACKING, PROCESSING, SPLIT_CONSIGNMENT } from 'constants/consignmentStates';
import { FontWeight } from 'lib/styles';
import { Background, Border, Main, Text } from 'styles/themeColors';
import { formatDateTime, currencySign } from 'utils/formatters';
import MatTable from 'components/MaterialTable/MatTable';
import productColumns from 'constants/productColumns';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { SHIPPING } from 'constants/deliveryModes';
import { IN_STOCK } from 'constants/consignmentEntry';
import { CONSIGNMENT_TYPES } from 'constants/order';
import OrderTag from 'components/UI/OrderTag';
import { getPillTheme } from 'utils/consignment';
import Pill from 'components/UI/Pill';

const ProductImage = styled.img<any>`
  cursor: pointer;
  max-height: 96px;
  max-width: 96px;
  margin: 0 auto;
  align-self: baseline;
`;

const TableFooterColumn = styled.span<any>`
  width: 200px;
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const TableFooterData = styled.span<any>`
  width: 104px;
  height: 20px;
  text-align: right;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const Surcharges = styled.span<any>`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${Text.primary};
`;

const TableColumn = styled.span<any>`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-height: 18px;
  width: 234px;
  color: ${Text.secondary};
  margin-bottom: 4px;

  ${({ color }) => color && css`
  color: ${color};
`}

${({ textDecoration }) => textDecoration && css`
  text-decoration: ${textDecoration};
`}

${({ fontSize }) => fontSize && css`
  font-size: ${fontSize};
`}

${({ fontWeight }) => fontWeight && css`
  font-weight: ${fontWeight};
`}

  ${({ deleted }) => deleted && css`
  opacity: 0.5;
`}

${({ width }) => width && css`
  width: ${width};
`}
`;

const TableData = styled.td<any>`
  text-align: center;
  vertical-align: top;

  &:first-child:not(:only-child):not([rowspan]) {
    text-align: left;
    font-weight: ${FontWeight.bold};
  }

  &:first-child:not(:only-child)[rowspan] {
    text-align: left;
  }

  &:last-child:not(:only-child)[rowspan] {
    padding-right: 10px;
    text-align: right;
    margin-right: 0;
    font-weight: ${FontWeight.bold};
  }

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ textDecoration }) => textDecoration && css`
    text-decoration: ${textDecoration};
  `}

  ${({ fontSize }) => fontSize && css`
    font-size: ${fontSize};
  `}

  ${({ fontWeight }) => fontWeight && css`
    font-weight: ${fontWeight};
  `}

  ${({ deleted }) => deleted && css`
    opacity: 0.5;
  `}
`;

const Consignment = styled.span<any>`
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const mapLabelForEntry = (type: string) => {
  switch (type) {
    case PROCESSING:
    case PACKING:  
      return 'In stock';
    case MANUFACTURING:
      return 'Manufacturing';
    case CANCELED:
    case CANCELING:  
      return 'Canceled';
    default:
      return '';
  }
}

interface TableValue {
  product: JSX.Element,
  quantity: JSX.Element,
  unitPrice: JSX.Element,
  subTotal: JSX.Element
};

interface Props {
  consignmentEntries: ConsignmentEntry[];
  isPreparationStep?: boolean;
  totalDeliveryCost?: number;
  totalPriceWithTax?: number;
  state: string;
  showSummary?: boolean;
  subTotal?: number;
  deliveryDate?: string;
  isRental?: boolean;
  consignmentId?: string;
  deliveryTax?: number;
  deliveryCost?: number;
  orderTotalCost?: number;
};

const OrderPreviewItem = ({
  consignmentEntries,
  state,
  showSummary,
  deliveryDate,
  isRental,
  consignmentId,
  deliveryCost = 0,
  deliveryTax = 0,
  orderTotalCost = 0
}: Props) => {

  let orderSubTotalPrice = 0;
  let totalTax = 0;
  let totalSurcharges = 0;

  const orderPreviewData = JSON.parse(sessionStorage.getItem('previewRouteData') || '');
  const country = orderPreviewData?.country || '';

  const productColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {


    return (
      <Fragment key={consignmentEntry.id}>
        <FlexboxContainerComponent flexDirection='row'>
          <FlexboxContainerComponent padding='0 12px 0 0' flexDirection='column'>
            <OrderTag type={isRental ? CONSIGNMENT_TYPES.RENT : CONSIGNMENT_TYPES.PURCHASE} margin={'0px 0px 10px 0px'} />
            <ProductImage src={consignmentEntry.productImages && consignmentEntry.productImages.length > 0 ? process.env.REACT_APP_MEDIA_URL! + consignmentEntry.productImages[0].path : ''} />
          </FlexboxContainerComponent>
          <FlexboxContainerComponent flexDirection='column'>
            <FlexboxContainerComponent justifyContent='flex-start' margin='0 0 12px' padding='0px 0 0'>
              <TableData>{consignmentEntry.productName}</TableData>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent flexDirection='row'>
              <FlexboxContainerComponent flexDirection='column'>
                <FlexboxContainerComponent><TableColumn>SKU</TableColumn><TableColumn>{consignmentEntry.productSKU}</TableColumn></FlexboxContainerComponent>
                {
                  consignmentEntry.selectableAttributes && consignmentEntry.selectableAttributes.map(atr => {
                    const atrValue = consignmentEntry.attributeValues.find(val => val.attributeId === atr.id);
                    return (
                      <FlexboxContainerComponent><TableColumn>{atr.name}</TableColumn><TableColumn>{atrValue ? atrValue.name : ''}</TableColumn></FlexboxContainerComponent>
                    )
                  })
                }
                {
                  consignmentEntry.pricebookName && consignmentEntry.pricebookId &&
                  <FlexboxContainerComponent>
                    <TableColumn >Pricebook name / ID</TableColumn>
                    <TableColumn >
                      {`${consignmentEntry.pricebookName} / ${consignmentEntry?.priceBook?.PricebookVendorId}`}
                    </TableColumn>
                  </FlexboxContainerComponent>
                }
                <FlexboxContainerComponent>
                  <TableColumn color={Text.primary}>{consignmentEntries[0]?.deliveryMode === SHIPPING ? "Est. delivery date" : "Est. pickup date"}</TableColumn>
                  <TableColumn color={Text.primary}>{formatDateTime(deliveryDate, false, false)}</TableColumn>
                </FlexboxContainerComponent>

                {consignmentEntry?.rentalStartDate && consignmentEntry?.rentalEndDate &&
                  <FlexboxContainerComponent>
                    <TableColumn color={Text.primary}>Date range</TableColumn>
                    <TableColumn color={Text.primary}>{formatDateTime(consignmentEntry.rentalStartDate, false, false) + " - " + formatDateTime(consignmentEntry.rentalEndDate, false, false)}</TableColumn>
                  </FlexboxContainerComponent>
                }

              </FlexboxContainerComponent>

            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
        </FlexboxContainerComponent>
      </Fragment>
    )
  });

  const quantityColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {
    return (
      <Fragment key={consignmentEntry.id}>
        <FlexboxContainerComponent justifyContent='flex-end' >
          <TableData>{consignmentEntry?.quantity}</TableData>
        </FlexboxContainerComponent>
      </Fragment>
    )
  });

  const unitPriceColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {

    const unitPrice = consignmentEntry.basePrice !== consignmentEntry.discountedPrice ? consignmentEntry.discountedPrice : consignmentEntry.basePrice;

    return (
      <Fragment key={consignmentEntry.id}>
        <FlexboxContainerComponent justifyContent='flex-end'>
          {
            <TableData>{currencySign(country, unitPrice)}</TableData>
          }
        </FlexboxContainerComponent>
      </Fragment>
    )
  });

  const subTotalColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {

    totalTax += consignmentEntry?.taxAmount || 0;
    totalSurcharges += consignmentEntry?.surcharge || 0;
    orderSubTotalPrice += consignmentEntry.totalPrice || 0;

    return (
      <Fragment key={consignmentEntry.id}>
        <FlexboxContainerComponent justifyContent='flex-end'>
          <TableData>{currencySign(country, consignmentEntry.totalPrice)}</TableData>
        </FlexboxContainerComponent>
        <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
          {
            consignmentEntry.surcharge > 0
              ? <TableData rowSpan={2}>+{currencySign(country, consignmentEntry.surcharge)} <br /> surcharge</TableData>
              : <TableData>&nbsp;</TableData>
          }
        </FlexboxContainerComponent>
      </Fragment>
    )
  });

  const data: TableValue[] = [];

  consignmentEntries.forEach((c, index) => {
    data.push({
      product: productColumn[index],
      quantity: quantityColumn[index],
      unitPrice: unitPriceColumn[index],
      subTotal: subTotalColumn[index]
    })
  });

  const orderTotal = showSummary && (
    <FlexboxContainerComponent flexDirection="column" alignItems='flex-end' width='100%' padding='16px'>
      <FlexboxContainerComponent padding='16px'>
        <TableFooterColumn>Subtotal</TableFooterColumn>
        <TableFooterData> {orderSubTotalPrice && currencySign(country, orderSubTotalPrice)}</TableFooterData>
      </FlexboxContainerComponent>
      {
        totalSurcharges && totalSurcharges > 0
          ? (
            <FlexboxContainerComponent padding='16px'>
              <Surcharges>includes surcharges</Surcharges>
              <TableFooterData> +{totalSurcharges && currencySign(country, totalSurcharges)}</TableFooterData>
            </FlexboxContainerComponent>
          )
          : null
      }
      {
        deliveryCost ? (
          <FlexboxContainerComponent padding='16px' borderTop={`1px solid ${Border.light}`} borderBottom={`1px solid ${Border.light}`}>
            <TableFooterColumn fontSize="15px">Shipping</TableFooterColumn>
            <TableFooterData fontSize="15px">{currencySign(country, deliveryCost)}</TableFooterData>
          </FlexboxContainerComponent>
        ) : null
      }
      {
        totalTax ? (
          <FlexboxContainerComponent borderTop='1px solid #E1E6EB' padding='16px'>
            <TableFooterColumn>Tax</TableFooterColumn>
            <TableFooterData> {totalTax && currencySign(country, totalTax + deliveryTax)}</TableFooterData>
          </FlexboxContainerComponent>
        ) : null
      }
      <FlexboxContainerComponent borderTop={`2px solid ${Background.navy}`} padding='16px'>
        <TableFooterColumn>Order total</TableFooterColumn>
        <TableFooterData> {currencySign(country, orderTotalCost)}</TableFooterData>
      </FlexboxContainerComponent>
    </FlexboxContainerComponent>
  )

  const pillTheme = getPillTheme(state);

  const pill = <Pill margin="10px 10px 10px 0px" theme={pillTheme} bgColor={pillTheme} size="xsPlus" padding="4px">{mapLabelForEntry(state)}</Pill>;

  return (
    <>
      <FlexboxContainerComponent flexDirection='column' gap='10px' margin='0px'>
        <FlexboxContainerComponent flexDirection='row' gap='10px' margin='0px' alignItems='center'>
        <Consignment>Order # {consignmentId?.slice(4)}</Consignment>
          {pill}
          {state !== CANCELED && <FlexboxContainerComponent alignItems='center'>
            <TableColumn color={Text.primary} width={'134px'}>{consignmentEntries[0]?.deliveryMode === SHIPPING ? "Est. delivery date" : "Est. pickup date"}</TableColumn>
            <TableColumn color={Text.primary} width={'134px'}>{formatDateTime(deliveryDate, false, false)}</TableColumn>
          </FlexboxContainerComponent>
          }
        </FlexboxContainerComponent>
        <FlexboxContainerComponent alignItems='center'>
          <TableColumn color={Text.primary} width={'100px'}>Start rent date</TableColumn>
          <TableColumn color={Text.primary} width={'134px'} fontWeight={'bold'} >{formatDateTime(consignmentEntries[0]?.rentalStartDate, false, false)}</TableColumn>
        </FlexboxContainerComponent>
        <FlexboxContainerComponent alignItems='center'>
          <TableColumn color={Text.primary} width={'100px'}>End rent date</TableColumn>
          <TableColumn color={Text.primary} width={'134px'} fontWeight={'bold'} >{formatDateTime(consignmentEntries[0]?.rentalEndDate, false, false)}</TableColumn>
        </FlexboxContainerComponent>
      </FlexboxContainerComponent>
      <FlexboxContainerComponent padding='0 0' justifyContent="space-between" alignItems="center">
        <p>{'Order items'}</p>
      </FlexboxContainerComponent>
      <MatTable
        columns={productColumns}
        data={data}
        toolbar
        overrideToolbar
        borders={true}
        rowStyle={{ backgroundColor: "#fff" }}
        customHeaderBorders={true}
      />
      {orderTotal}
    </>
  );
};

OrderPreviewItem.defaultProps = {
  showSummary: true
}

export default OrderPreviewItem;
