import styled from 'styled-components';
import { latoFont } from 'OnboardingComponents/Elements/Typography';

export const List = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
  ${latoFont}
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  &::before {
    margin: 0;
    content: "•";
    color: ${({ theme }) => theme.charcoal};
    display: inline-block;
    margin-right: 0.5rem;
  }
`;