export const REMINDERS_DAYS_LIMIT = {
    MIN: 1,
    MAX: 999
};


export const VENDOR_GENERAL_SETTINGS = {
    REMINDER_DESCRIPTION: 'This setting determines how many days in advance we will send a reminder email to the customer before their rental period is complete.',
    REMINDER_DAYS_PLACEHOLDER: 'Enter number of days',
    REMINDER_DAYS_LABEL: 'Reminders Configuration',


};