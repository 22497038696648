import { get, post, put } from 'lib/API';
import SuperCategory, { SuperCategorySummary } from 'data/SuperCategory';

export const loadSuperCategories = async ([token]: any): Promise<SuperCategory[]> => {
  return await get('v1/supercategories', token);
};

export const loadSuperCategoriesSummary = async ([token]: any): Promise<SuperCategorySummary[]> => {
  return await get('v1/supercategories/summary', token);
};

export const createSuperCategory = async ([newCategory]: any[], { token }: any): Promise<any> => {
  return await post('v1/supercategories', token, newCategory);
};

export const saveSuperCategory = async ([supercategoryToUpdate]: any[], { token }: any): Promise<any> => {
  return await put(`v1/supercategories/${supercategoryToUpdate.id}`, token, {
    name: supercategoryToUpdate.name
  });
};
