import { DateRange } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Border, Main } from 'styles/themeColors';

const DateViewContainer = styled.div<any>`
    border-radius: ${BorderRadius.m};
    border-color: ${({ $error }) => $error ? Main.error : Border.main};
    box-sizing: border-box;
    border: 1px solid ${Border.main};
    display: flex;
    flex-direction: row;
    width: 140px;
    padding: 5px 5px 5px 7px;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
`;

const Label = styled.div<any>``;

export interface Props {
  date: string
  onClick?: () => void;
}

const DateView = ({
  date,
  onClick
}: Props) => {
  return (
    <DateViewContainer onClick={onClick!}>
        <Label>{date}</Label>
        <DateRange style={{ color: '#212529' }} />
    </DateViewContainer>
  );
}

export default DateView;