import React, { Fragment } from 'react';

import { Heading2, Paragraph, CardCallout, Heading5 } from 'OnboardingComponents/Elements';

const SupportingDocuments = () => {
  return (
    <Fragment>
      <Heading2 id="supporting-documents">Supporting Documents</Heading2>
      <Paragraph>When it comes to the documents that you choose to attach to your product display page(s), keep these few FAQs in mind.</Paragraph>
      <CardCallout>
        <Heading5>What kind of documents should I attach?</Heading5>
        <Paragraph>We recommend attaching product sheets, product catalogues, and/or case studies highlighting product development and/or usage. Remember that you can add as many documents as you’d like; if you think of something else that you feel the customer might find useful during the purchase process, then definitely include it!</Paragraph>
      </CardCallout>
      <CardCallout>
        <Heading5>In which format do my supporting documents need to be?</Heading5>
        <Paragraph>PDF</Paragraph>
      </CardCallout>
      <CardCallout>
        <Heading5>What is the maximum size for each supporting document?</Heading5>
        <Paragraph>We recommend that each document be kept at 25 MB or less in size.</Paragraph>
      </CardCallout>
      <CardCallout>
        <Heading5>Any tips for how my supporting documents should be named?</Heading5>
        <Paragraph>Definitely make it easy for users to save your documents for future reference by using a consistent taxonomy such as: Brand name_Product name_Document Type_Month/Year</Paragraph>
      </CardCallout>
    </Fragment>
  );
};

export default SupportingDocuments;
