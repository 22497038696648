import ProductVariantPrice from "./ProductVariantPrice";
import ProductStock from "./ProductsStock";
import { RentalPrice } from "./Rental";
import { VariantValidationStatus } from "./VariantValidationStatus";

export default interface Variant {
  id?: number;
  code: string;
  sku: string;
  vendorPartNumber?: string;
  manufacturingPartNumber?: string;
  itemsPerUnit: number;
  pricePerUnit: number;
  hidden: boolean;
  publishedOn?: string;
  availableForPickup: boolean;
  deliveryLeadDays: number;
  shipping: boolean
  weight: number;
  postOrderShippingCost: boolean;
  // height: Number;
  // length: Number;
  // width: Number;
  attributeValues: VariantAttributeValue[];
  stockId?: number;
  priceId?: number;
  productStocks?: ProductStock[];
  productVariantPrices?: ProductVariantPrice[];
  itemType?: string;
  variantValidationStatus?: VariantValidationStatus;
  errorMessage?: string;
  materialKey: number;
  materialNumber?: string;
  dirty?: boolean;
  rent?: boolean;
  purchase?: boolean;
  rentalCode?: string;
  rentalPriceUS: RentalPrice[];
  rentalPriceCA: RentalPrice[];
};

export interface VariantAttributeValue {
  id: number;
  attributeId: number;
  code: string;
  name: string;
  displayOrder: number;
  isSelectable: boolean;
};

export const empty: Variant = {
  code: '',
  sku: '',
  vendorPartNumber: '',
  manufacturingPartNumber: '',
  itemType: '',
  itemsPerUnit: 1,
  pricePerUnit: 0,
  hidden: false,
  availableForPickup: true,
  deliveryLeadDays: 1,
  postOrderShippingCost: true,
  shipping: true,
  weight: 0,
  // height: 0,
  // length: 0,
  // width: 0,
  publishedOn: '',
  attributeValues: [],
  productStocks: [],
  productVariantPrices: [],
  materialKey: 0,
  materialNumber: '',
  rentalPriceUS: [],
  rentalPriceCA: [],
  rent: false,
  purchase: false,
};

export const emptyValue: VariantAttributeValue = {
  id: 0,
  attributeId: 0,
  code: '',
  name: '',
  displayOrder: 0,
  isSelectable: false
};
