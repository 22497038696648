import React from 'react';
import styled from 'styled-components';
import Product from 'data/Product';
import Button from 'components/UI/Button';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import Modal from 'components/UI/Modal';
import { Color, Size, FontSize } from 'lib/styles';
import { Space } from 'styles/themeSpaces';
import { Main } from 'styles/themeColors';

const Buttons = styled.div`
  border-top: 1px solid ${Color.gray};
  display: flex;
  justify-content: space-around;
  padding: ${Size.medium};
`;

const List = styled.div`
  -webkit-overflow-scrolling: touch;
  height: 25rem;
  margin: 0px auto;
  overflow-y: auto;
  padding: ${Size.medium};
  width: calc(100% - 4rem);
`;

const ProductTitle = styled.h3`
  margin: ${Size.medium} 0px 0px;
  font-size:${FontSize.medium};
`;

const Title = styled.h2`
  margin-bottom: ${Size.small};
  padding: ${Space.sp2xPlus};
  text-align: center;
`;

interface Props {
  isLoading: boolean;
  onCancel: () => void;
  onImport: () => void;
  updatedProduct: Product | null;
  showModal: any;
};

const ImportModal = ({
  isLoading,
  onCancel,
  onImport,
  updatedProduct,
  showModal
}: Props) => {
  const details = updatedProduct ? (
    <div key={updatedProduct.name}>
      <ProductTitle>Product Name: {updatedProduct.name}</ProductTitle>
      <ProductTitle>Super Category: {updatedProduct.superCategory ? updatedProduct.superCategory.name : ''}</ProductTitle>
      <ProductTitle>Category: {updatedProduct.category ? updatedProduct.category.name : ''}</ProductTitle>
      <ProductTitle>Number of Variants: {updatedProduct.variants.length}</ProductTitle>
    </div>
  ) : null;

  const content = (
    <>
      <List>{details}</List>
      <Buttons>
        <Button onClick={onCancel} isGhost color={Main.accent}>
          Cancel
        </Button>
        <Button onClick={onImport}>Import</Button>
      </Buttons>
    </>
  );

  return (
    <Modal
      onClose={onCancel}
      showCloseIcon
      title='Import preview'
      showModal={showModal}
      width='600px'
      top="20%"
      showButtons={false}
    >
      {isLoading ? <LoadingIndicator /> : content}
    </Modal>
  );
};

ImportModal.defaultProps = {
  isLoading: false
};

export default ImportModal;
