import React, { useEffect, useState } from 'react';
import { navigate, usePath } from 'hookrouter';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import * as Prod from 'data/Product';
import Vendor from 'data/Vendor';
import * as User from 'data/User';
import ConfirmModal from 'components/UI/Modal';
//import { validateProduct, ProductErrorType } from 'data/ProductValidation';
import ExportButton from 'admin/ExportFooter';
import LibraryInstructions from 'editor/LibraryInstructions';
import ProductCard from 'common/ProductCard';
import ProductSubmissionCard from 'common/ProductSubmissionCard';
import Button from 'components/UI/Button';
import FixedFooter from 'view/FixedFooter';
import Icon from 'view/Icon';
import Link from 'view/Link';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import NavLink from 'view/NavLink';
import PagedCount from 'view/PagedCount';
import Pill from 'components/UI/Pill';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import ProductPagination from 'view/ProductPagination';
import Select from 'components/UI/Select';
import Checkbox from 'components/UI/Checkbox';
// import Tooltip from 'components/UI/Tooltip';
import { renderToast } from 'components/UI/ToastNotification';
import * as Arr from 'lib/Array';
import usePrevious from 'lib/usePrevious';
import { Color, Size, contentWidth } from 'lib/styles';
import { getStash, setStash } from 'lib/stash';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { Space } from 'styles/themeSpaces';
import { Background, Main } from 'styles/themeColors';
import { getCountries } from 'requests/country';
import Country from 'data/Country';
import { Box } from '@material-ui/core';
import { PRODUCT_CONSTANTS } from 'constants/product';

const GroupCount = styled(Pill)`
  display: inline-flex;
  line-height: 16px;
`;

const GroupHeading = styled.h3`
  margin: ${Size.large} 0px 0px;
`;

const Nav = styled.nav`
  border-bottom: 1px solid ${Color.darkGray};
  display: flex;
  padding: ${Size.large} 0 ${Size.large} 2em;
  width: 100%;
`;

const Page = styled.div`
  margin: 0px auto;
  margin-bottom: 6rem;
  width: 100%;
  padding: 0 30px;
`;

const Content = styled.div`
  margin: 0px auto;
  width: 100%;
`;


const StyledProductPagination = styled(ProductPagination)`
  margin: 0px auto;
  margin-top: ${Size.large};
`;

const Tabs = styled.div`
  flex-grow: 1;
`;

const Buttons = styled.div`
  display: flex;
  margin-top: ${Size.small};
  margin-bottom: ${Size.small};
`;

// const TooltipContent = styled.div`
//   white-space: initial;
//   width: 14rem;
// `;

const DownloadTemplateAnchor = styled.a`
  display: inline-block;
  height: 36px;
  background-color: ${Main.accent};
  border-radius: ${Size.tiny};
  color: ${Background.white} !important;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  padding: ${Size.small} ${Size.medium};
  text-align: center;
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

type SortOrder = typeof PRODUCT_CONSTANTS.MOST_RECENT | typeof PRODUCT_CONSTANTS.VENDOR;

interface ProductErrorCount {
  code: string;
  productErrorCount: number;
};

interface Props {
  base: string;
  editable: boolean;
  page?: any;
  status: string;
  selectedVendor: Vendor;
};

const ProductList = ({
  base,
  editable,
  page = 1,
  status,
  selectedVendor
}: Props) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const [allSelected, setAllSelected] = useState<boolean[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [products, setProducts] = useState<Prod.ProductList>();
  const [updatingProductId, setUpdatingProductId] = useState(-1);
  const { token, user } = useAuth();
  const isVendor = User.isVendor(user!);
  const [sortBy, setSortBy] = useState<SortOrder>(
    getStash(PRODUCT_CONSTANTS.REQUEST_SORT) || PRODUCT_CONSTANTS.MOST_RECENT
  );
  const [sortByCountry, setSortByCountry] = useState<string>(
    getStash(PRODUCT_CONSTANTS.REQUEST_SORT_BY_COUNTRY) || PRODUCT_CONSTANTS.ALL_COUNTRIES
  );
  const [filterCountryIso, setFilterCountryIso] = useState<string | null | undefined>(
    getStash(PRODUCT_CONSTANTS.REQUEST_SORT_COUNTRY_ISO) || null
  );

  const sortDirection = 'asc';
  const [pathToNavigate, setPathToNavigate] = useState('');
  const previousStatus = usePrevious(status);
  const previousSortBy = usePrevious(sortBy);
  const previousSortByCountry = usePrevious(sortByCountry);
  const previousSelectedVendor = usePrevious(selectedVendor);
  const previousPage = usePrevious(page);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [countries, setCountries] = useState<Country[]>([]);

  const path = usePath();
  const isPublishedTab = path.startsWith(PRODUCT_CONSTANTS.PUBLISHED_PRODUCT_PATH);
  const sortOrders = [{ value: PRODUCT_CONSTANTS.MOST_RECENT }, { value: PRODUCT_CONSTANTS.VENDOR }];
  const sortOrdersByCountries = [{ key: null, value: PRODUCT_CONSTANTS.ALL_COUNTRIES }, ...(countries.map(x => { return { key: x.countryIso, value: x.countryName } }))];
  let allProducts: Prod.ProductCard[] = [];


  if (currentPage !== page) {
    setCurrentPage(page);
  }

  const getCountryIso = (countryName: string) => {
    const country = sortOrdersByCountries.find(x => x.value === countryName)
    return country?.key;
  }

  const productsReq = useAPI({
    promiseFn: Prod.loadAllGrouped,
    group: isVendor ? '' : sortBy === 'Vendor' ? 'vendor.name' : 'updatedAt',
    sortDirection: isVendor ? '' : sortDirection,
    countryIso: filterCountryIso,
    page,
    status,
    vendorId: selectedVendor && selectedVendor.id ? selectedVendor.id : undefined,
    onResolve: (result) => {
      setProducts(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const countriesReq = useAPI({
    deferFn: getCountries,
    onResolve: (result) => {
      setCountries(result);
    },
    onReject: (err) => { },
  });

  
  //let productErrorCounts: ProductErrorCount[] = [];
  let emptyContent = productsReq.isLoading ? <LoadingIndicator /> : <LibraryInstructions />;

  const prodCloneReq = useAPI({
    deferFn: Prod.cloneProduct,
    onResolve: result => {
      renderToast(toast.TYPE.SUCCESS, 'A new version of the product created successfully.');

      setPathToNavigate(`/products/product-details/${result.id}`);
    },
    onReject: (err: Error & { code?: number }) => {
      if (err.code && err.code === 2.9) {
        renderToast(toast.TYPE.ERROR, 'A version for this product already exists in draft or ready to publish. Please edit that one, or delete it and try again.');
      } else {
        renderToast(toast.TYPE.ERROR, err.message);
      }
    }
  });

  const prodUpdateReq = useAPI({
    deferFn: Prod.updateProductsStatus,
    onResolve: result => {
      renderToast(toast.TYPE.SUCCESS, 'Product(s) status updated successfully.');

      if (result.ids.length === 1 && result.status === 'draft') {
        setPathToNavigate(`/products/product-details/${result.ids[0]}`);
      } else {
        productsReq.reload();
        renderToast(toast.TYPE.SUCCESS, `Products status updated to ${result.status}.`);
        navigate(`/products/${result.status}`);
      }
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  setStash(PRODUCT_CONSTANTS.PRODUCTS, path);
  setStash(PRODUCT_CONSTANTS.REQUEST_SORT, sortBy);
  setStash(PRODUCT_CONSTANTS.REQUEST_SORT_BY_COUNTRY, sortByCountry);
  setStash(PRODUCT_CONSTANTS.REQUEST_SORT_COUNTRY_ISO, filterCountryIso);

  useEffect(() => {
    navigate(pathToNavigate);
  }, [pathToNavigate]);

  useEffect(() => {
    if (
      previousStatus !== status ||
      previousSortBy !== sortBy ||
      previousSortByCountry !== sortByCountry ||
      previousPage !== page ||
      (selectedVendor && previousSelectedVendor && previousSelectedVendor.name !== selectedVendor.name) ||
      selectedVendor && previousSelectedVendor === null ||
      selectedVendor === null && previousSelectedVendor
    ) {
      productsReq.reload();
    }
  }, [
    page,
    previousPage,
    previousSortBy,
    previousStatus,
    productsReq,
    status,
    sortBy,
    sortByCountry,
    selectedVendor,
    previousSelectedVendor,
    countriesReq
  ]);

  useEffect(()=>{
    countriesReq.run();
  }, [])

  if (products) {
    if (products.groups) {
      allProducts = products.groups.reduce((allProducts, group) => {
        allProducts.push(...group.products);
        return allProducts;
      }, [] as Prod.ProductCard[]);
    } else if (products.products) {
      allProducts = products.products;
    }

    if (products.page > products.totalPages) {
      const urlStatus = status === 'draft' ? '' : '/' + status;
      navigate('/products' + urlStatus);
    }
  }

  const handleCountryChange = (event: any) => {
    setSortByCountry(event.target.value as SortOrder)
    const countryIso = getCountryIso(event.target.value);
    setFilterCountryIso(countryIso);
    setStash(PRODUCT_CONSTANTS.REQUEST_SORT_COUNTRY_ISO, countryIso);
  }

  const sortBySelector = (
    <Select
      onChange={(event: any) => setSortBy(event.target.value as SortOrder)}
      options={sortOrders}
      value={sortBy}
      label="Sort by:"
      labelPosition='left'
      width='70%'
      alignItems='center'
    />
  );

  const sortByCountries = (
    <Select
      onChange={handleCountryChange}
      options={sortOrdersByCountries}
      value={sortByCountry}
      label="Filter:"
      labelPosition='left'
      width='70%'
      alignItems='center'
    />
  );

  const onConfirmMessageClick = () => {
    if (status === 'published') {
      prodCloneReq.run(updatingProductId);
    } else {
      prodUpdateReq.run([updatingProductId], 'draft');
    }
  };

  const publishModal = (
    <ConfirmModal
      showModal={showPublishModal}
      onClose={() => setShowPublishModal(false)}
      onClick={handleSubmission}
      title={`Publish ${selectedIds.length > 1 ? 'products' : 'product'}`}
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          {`Are you sure you want to publish ${selectedIds.length} ${selectedIds.length > 1 ? 'products?' : 'this product?'}`}
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )

  const deleteModal = (
    <ConfirmModal
      showModal={showDeleteModal}
      onClose={() => setShowDeleteModal(false)}
      onClick={handleDeletion}
      title={`Delete ${selectedIds.length > 1 ? 'products' : 'product'}`}
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          {`Are you sure you want to delete ${selectedIds.length} ${selectedIds.length > 1 ? 'products?' : 'this product?'}`}
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )

  const confirmMessage = status === 'published'
    ? 'Please note: modifying a published product requires that we create a new version of it in your "Drafts". The current version will remain live on the site and will be updated once your new version has been submitted and approved by an admin.'
    : `Please note: we'll move your product back into "Drafts" where you can revise it. It will no longer be visible to the admin until you submit the product again for publishing.`;

  const updateModal = (
    <ConfirmModal
      showModal={showUpdateModal}
      onClose={() => setShowUpdateModal(false)}
      onClick={onConfirmMessageClick}
      title="Update product"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          {confirmMessage}
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )

  function handleSubmission() {
    let selections = allProducts.filter(
      p => typeof p.id === 'number' && selectedIds.includes(p.id)
    );

    let selectionList: Prod.ProductCard[] = [];
    selections.forEach(selection => {
      selectionList.push(selection);
    });

    const newStatus = selections[0].status === 'draft' ? 'ready' : 'published';

    prodUpdateReq.run(selectedIds, newStatus);
    setSelectedIds([]);
    setShowPublishModal(false);
  }

  function toggleSelection(id?: number) {
    if (id === undefined) return () => null;

    return () => {
      setSelectedIds((ids) => {
        return ids.includes(id)
          ? Arr.remove(ids, id)
          : Arr.add(ids, id)
      });
    };
  }

  function filterProductsByVendor(groupedProducts: Prod.ProductCard[]): Prod.ProductCard[] {
    return groupedProducts.filter(product => {
      if (!selectedVendor) {
        return groupedProducts;
      }

      if (product.vendor && selectedVendor) {
        return product.vendor.id === selectedVendor.id;
      }
      return [];
    });
  };

  function handleDeletion() {
    Prod.deleteDrafts(selectedIds, token as string).then(() => {
      productsReq.reload();
      renderToast(toast.TYPE.SUCCESS, 'Draft products deleted successfully.');
    }).catch(err => {
      renderToast(toast.TYPE.ERROR, err.message);
    });

    setSelectedIds([]);
    setShowDeleteModal(false);
  }

  const onCreateProduct = () => {
    if (selectedVendor) {
      navigate(`/products/new-product`);
    } else {
      renderToast(toast.TYPE.INFO, 'Please select vendor before you try to create product.');
    }
  };

  function renderProductCard(product: Prod.ProductCard, index: number, status: string) {
    const buttonLabel = status === 'published' ? 'Update' : 'Revise';

    const onShowUpdateModal = () => {
      setShowUpdateModal(true);
      setUpdatingProductId(product.id!);
    };

    let productCard = (
      <ProductSubmissionCard
        actions={
          <>
            <Button
              onClick={() => navigate(`/products/product-details/${product.id}`)}
              isGhost
              color={Main.accent}
            >
              View
            </Button>
            <Button
              onClick={onShowUpdateModal}
              isGhost
              color={Main.accent}
            >
              {buttonLabel}
            </Button>
          </>
        }
        key={product.id}
        onToggleSelection={isVendor ? undefined : toggleSelection(product.id)}
        product={product}
        selected={
          product.id !== undefined && selectedIds.includes(product.id)
        }
        sortOrder={isVendor ? sortBy : PRODUCT_CONSTANTS.VENDOR as any}
      //errorCount={productErrorCounts.find(productErrorCount => productErrorCount.code === product.code)!.productErrorCount}
      />
    );

    let selection = null;

    if (status === 'draft') {
      if (editable) {
        selection = (
          <FlexboxContainer margin="16px 16px 0 0">
            <Checkbox
              checkboxSize='xs'
              appearance="none"
              id='checkbox-product-list-id'
              checked={
                product.id !== undefined && selectedIds.includes(product.id)
              }
              onChange={toggleSelection(product.id)}
            />
          </FlexboxContainer>
        );
      }

      productCard = (
        <ProductCard
          // errorCount={productErrorCounts.find(productErrorCount => productErrorCount.code === product.code)!.productErrorCount}
          key={product.name}
          onClick={() => navigate(`/products/product-details/${product.id}`)}
          product={product}
        />
      );
    }

    return (
      <FlexboxContainer flexDirection="flex" justifyContent="space-between" align-items="center" key={product.id}>
        {selection}
        {productCard}
      </FlexboxContainer>
    );
  }

  let content = null;

  if (productsReq.isLoading || !products || (isVendor && prodUpdateReq.isLoading)) {
    content = <LoadingIndicator />;
  }

  // productErrorCounts = allProducts.map(product => {
  //   const productValidation = validateProduct(product);
  //   const productErrorCount = productValidation.productErrors.filter(
  //     ({ type }) => type === ProductErrorType.Error
  //   ).length;
  //   return { code: product.code, productErrorCount: productErrorCount + productValidation.variantErrors.length };
  // });

  if (!isVendor) {
    if (!productsReq.isLoading && products && products.groups) {
      let items = products.groups
        .map(group => {
          let filteredGroupedProducts = group.products;
          if (selectedVendor) {
            filteredGroupedProducts = filterProductsByVendor(filteredGroupedProducts);
          }

          const groupProducts = filteredGroupedProducts.map((product, index) => {
            return renderProductCard(product, index, status);
          });

          let groupLabel = group.id;

          if (sortBy === 'Most recent') {
            groupLabel = new Date(
              group.id
            ).toLocaleDateString('en-US', {
              month: 'long',
              day: 'numeric',
              year: 'numeric'
            });
          }

          let groupHeading = null;

          if (groupProducts.length > 0) {
            groupHeading = (
              <GroupHeading key={group.id} >
                {groupLabel} <GroupCount theme='accent' size='s'>{String(group.count)}</GroupCount>
              </GroupHeading>
            );
          }

          return [
            groupHeading,
            ...groupProducts
          ];
        })
        .flat();

      content = (
        <>
          <PagedCount
            label={allProducts.length === 1 ? 'Product' : 'Products'}
            loading={productsReq.isLoading}
            page={products.page}
            size={products.size}
            total={products.totalItems}
          />
          <div>
            <Checkbox id='checkbox-id' checkboxSize='xs' appearance="none" checked={allSelected[currentPage - 1] === undefined ? false : allSelected[currentPage - 1]} onChange={selectAll} />
            {items}
          </div>
        </>
      );
    }
  }

  if (!productsReq.isLoading && products && products.products) {
    content = allProducts.map((product, index) => {
      return renderProductCard(product, index, status);
    });
  }

  if (allProducts.length === 0) {
    content = emptyContent || <h2>No products found</h2>;
  }

  let footer = null;
  if (editable && selectedIds.length > 0) {
    const selectedText = `${selectedIds.length} Product${selectedIds.length === 1 ? '' : 's'} Selected`;

    // TODO - disable submit if there are errors in products
    // const submitDisabled = isAdmin || isSuperAdmin ? false : selectedIds.some(id => {
    //   if (!allProducts) return false;
    //   const product = allProducts.find(product => product.id === id);
    //   return productErrorCounts.find(productErrorCount => productErrorCount.code === product!.code)!.productErrorCount > 0;
    // });

    let submitLink = (
      <Link disabled={false} key="submit" onClick={() => setShowPublishModal(true)}>
        {/* <Link disabled={submitDisabled} key="submit" onClick={submitDisabled ? () => { } : handleSubmission}> */}
        <Icon name="plus-circle" />
        <span>Submit</span>
      </Link >
    );

    // TODO - disable submit if there are errors in products
    // if (submitDisabled) {
    //   submitLink = (
    //     <Tooltip
    //       content={
    //         <TooltipContent>
    //           Products may not be published until all issues have been resolved.
    //         </TooltipContent>
    //       }
    //       direction="up"
    //       size="large"
    //     >
    //       <span>{submitLink}</span>
    //     </Tooltip>
    //   );
    // }

    const actions = (
      <>
        {status === 'published' || status === 'draft' ? null : submitLink}
        <Link key="delete" onClick={() => setShowDeleteModal(true)} theme="danger">
          <Icon name="trash" />
          <span>Delete</span>
        </Link>
      </>
    );

    footer = <FixedFooter left={selectedText} middle={actions} />;
  }

  function selectAll() {
    let selectedProductsOnPage: number[] = [];

    if (products && products.groups) {
      products.groups.forEach(group => {
        group.products.forEach(product => {
          selectedProductsOnPage.push(product.id!)
        })
      })
    }

    if (!allSelected[currentPage - 1]) {
      const totalSelectedProducts = [...selectedIds].concat(selectedProductsOnPage.filter(el => !selectedIds.includes(el)));
      setSelectedIds(totalSelectedProducts);
    } else {
      setSelectedIds([...selectedIds].filter(el => !selectedProductsOnPage.includes(el)));
    }

    let newSelected = [...allSelected];
    newSelected[currentPage - 1] = !newSelected[currentPage - 1];
    setAllSelected(newSelected);
  }

  function clearSelections() {
    setSelectedIds([]);
    setAllSelected([]);
  }

  return (
    <Page>
      {publishModal}
      {deleteModal}
      {updateModal}
      <Nav>
        <Tabs>
          <NavLink
            current={path.startsWith('/products/page')}
            to="/products"
            onClick={clearSelections}
          >
            Drafts
          </NavLink>
          <NavLink atBasePath to="/products/ready" onClick={clearSelections}>
            Ready to Publish
          </NavLink>
          <NavLink atBasePath to="/products/published" onClick={clearSelections}>
            Published
          </NavLink>
        </Tabs>
        <Buttons>
          <Button onClick={onCreateProduct} margin="0 10px 0 0">New product</Button>
        </Buttons>
      </Nav>
      <FlexboxContainer justifyContent="flex-start" margin="10px auto">
        {
          !isVendor && 
          <Box width={"20%"}>
           {sortBySelector}
          </Box>
        
        }
        <Box width={"20%"}>
          {sortByCountries}
        </Box>
      </FlexboxContainer>
      <Content>{content}</Content>
      <StyledProductPagination
        base={base}
        page={parseInt(page)}
        pageData={products}
      />
      <FlexboxContainer justifyContent="center" margin="0px auto" maxWidth={contentWidth}>
        {!isPublishedTab ? footer : null}
      </FlexboxContainer>
      <FlexboxContainer justifyContent="center" margin="0px auto" maxWidth={contentWidth}>
        {isPublishedTab && User.isSuperAdmin(user!) && <ExportButton selectedProducts={selectedIds} setSelectedProducts={setSelectedIds} products={allProducts}/>}
      </FlexboxContainer>
    </Page>
  );
}

ProductList.defaultProps = {
  editable: false
};

export default ProductList;
