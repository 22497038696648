import React, { useEffect, Fragment, useState } from 'react';
import styled, { css } from 'styled-components';
import { DatePicker } from '@material-ui/pickers';
import { DeleteForever, Replay } from '@material-ui/icons';
import DatePickerInput from 'components/UI/DatePickerInput';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import RadioButton from 'components/UI/RadioButton';
import Input from 'components/UI/Input';
import ConsignmentEntry from 'data/ConsignmentEntry';
import { addAtIndex, removeAtIndex, update } from 'lib/Array';
import { SHIPPING } from 'constants/deliveryModes';
import { Color, FontSize, FontWeight, Percent, Size } from 'lib/styles';
import { IN_STOCK, MANUFACTURE } from '../../constants/consignmentEntry';
import ConsignmentCancelallationModal from 'components/Consignments/Modals/ConsignmentCancelationModal';
import { CANCEL_CONSIGNMENT_MODAL_TEXT } from 'constants/consignmentStates';
import { PRICE_TYPE } from 'constants/product';
import Icon from 'view/Icon';
import Link from 'view/Link';
import * as Cons from 'data/Consignment';
import { CONSIGNMENT_TYPES } from 'constants/order';
import OrderProcessPreview from './OrderProcessReview';
import { getItems } from 'lib/SessionStorage';
import { onlyWeekdaysAllowed } from 'utils/product';

const Table = styled.table`
  width: ${Percent.full};
  border-spacing: 20px 0;
  margin-bottom: 30px;
`;

const TableRow = styled.tr<any>`
  font-size: 15px;

  &:first-child {
    font-weight: ${FontWeight.medium};
  }
`;

const TableHeader = styled.th<any>`
  padding-top: 12px;
  text-align: center;
  width: 30%;
  border-bottom: 1px solid ${Color.shuttleGray};
  margin-right: 10px;
  color: ${Color.black};
  font-size: ${FontSize.small};
  letter-spacing: 1px;

  &:first-child {
    text-align: left;
  }

  &:last-child {
    padding-right: 10px;
    text-align: right;
    margin-right: 0;
  }

  &:nth-child(2n) {
    width: 15%;
  }

  ${({ noBorder }) => noBorder && css`
    border: none;
    width: 3%;
  `}
`;

const TableData = styled.td<any>`
  text-align: center;
  vertical-align: middle;

  &:first-child:not(:only-child):not([rowspan]) {
    text-align: left;
    font-weight: ${FontWeight.bold};
  }

  &:first-child:not(:only-child)[rowspan] {
    text-align: left;
  }

  &:last-child:not(:only-child)[rowspan] {
    padding-right: 10px;
    text-align: right;
    margin-right: 0;
    font-weight: ${FontWeight.bold};
  }

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ textDecoration }) => textDecoration && css`
    text-decoration: ${textDecoration};
  `}

  ${({ deleted }) => deleted && css`
    opacity: 0.5;
  `}
`;


const LabelText = styled.label`
  white-space: nowrap;
  margin: 0 2px;
`;

const StyledDeleteIcon = styled(DeleteForever) <any>`
  cursor: pointer;
`;

const StyledReplayIcon = styled(Replay) <any>`
  cursor: pointer;
`;

const DetailsContainer = styled.div`
  margin: 0 -20px;
`;

const PartNumber = styled.div<any>`
  font-size: ${FontSize.small};
  font-weight: ${FontWeight.medium};
  color: ${Color.darkerGray};

  ${({ deleted }) => deleted && css`
    opacity: 0.5;
  `}
`;


const WarningIcon = styled(Icon).attrs({ name: 'WarningBlack', size: 20 })`
  margin-right: ${Size.medium};
  margin-top: ${Size.tiny};
`;

const getDateFromConsignmentEntry = (consignmentEntry: ConsignmentEntry, specificRadioValue?: string) => {
  const radioButtonValue = specificRadioValue || consignmentEntry.radioButtonValue
  return radioButtonValue === IN_STOCK
    ? consignmentEntry.committedDate
      ? consignmentEntry.committedDate
      : consignmentEntry.postOrderShippingCost && consignmentEntry.postOrderDeliveryDate
        ? consignmentEntry.postOrderDeliveryDate
        : consignmentEntry.preliminaryDeliveryDate
    : consignmentEntry.manufactureDate
      ? consignmentEntry.manufactureDate
      : consignmentEntry.postOrderShippingCost && consignmentEntry.postOrderDeliveryDate
        ? consignmentEntry.postOrderDeliveryDate
        : consignmentEntry.preliminaryDeliveryDate
}

interface Props {
  fulfillmentRequest: ConsignmentEntry[];
  deliveryMode: string;
  setFulfillmentRequest: (value: ConsignmentEntry[]) => void;
  renderOrderDetailsTitle: (heading: string) => any;
  consignmentType: string;
  consignmentDetailsResponse: Cons.ConsignmentDetails;
  setIsReviewPage: (value: boolean) => void;
  isReviewPage: boolean;
  setIsReviewPageMounted?: (value: boolean) => void;
  weekdaysOnly?: boolean;
  isOrderFulfillmentButtonDisabled?: boolean;
};

const OrderFulfillmentTable = ({
  fulfillmentRequest,
  setFulfillmentRequest,
  deliveryMode,
  renderOrderDetailsTitle,
  consignmentType = PRICE_TYPE.PURCHASE,
  consignmentDetailsResponse,
  setIsReviewPage,
  isReviewPage,
  setIsReviewPageMounted,
  weekdaysOnly = false,
  isOrderFulfillmentButtonDisabled = false
}: Props) => {
  const originalFulfillmentRequest = consignmentDetailsResponse?.consignmentEntries || [];
  const [cancellationConsignmentPopup, setCancellationConsignmentPopup] = useState(false);
  const [indexValue, setIndexValue] = useState(0);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  useEffect(() => {

    if (consignmentDetailsResponse?.consignmentType === CONSIGNMENT_TYPES.RENT) {
      // Compare the original fullfillment request with fulfillment request if they are different or their length is not equal then show the preview button
      if (originalFulfillmentRequest.length !== fulfillmentRequest.length) {
        setIsPreviewVisible(true);
      }

      if (originalFulfillmentRequest.length === fulfillmentRequest.length) {
        const isDifferent = originalFulfillmentRequest.some((entry, index) => {
          return entry.rentalStartDate !== fulfillmentRequest[index].rentalStartDate
            || entry.rentalEndDate !== fulfillmentRequest[index].rentalEndDate
        }
        );

        if (isDifferent && fulfillmentRequest.every(entry => entry.radioButtonValue === IN_STOCK || entry.radioButtonValue === MANUFACTURE)) {
          setIsPreviewVisible(true);
        } else {
          setIsPreviewVisible(false);
        }
      }
    }
  }, [fulfillmentRequest]);


  useEffect(() => {
    const newFulfillmentRequest = fulfillmentRequest.map(consignmentEntry => {
      consignmentEntry.radioButtonValue = consignmentEntry.manufactureQuantity > 0 ? MANUFACTURE : IN_STOCK;
      if (consignmentEntry.preliminaryDeliveryDate) {
        const key = consignmentEntry.radioButtonValue === IN_STOCK ? 'committedDate' : 'manufactureDate';

        return {
          ...consignmentEntry,
          [key]: getDateFromConsignmentEntry(consignmentEntry)
        };
      }
      return consignmentEntry;
    });

    setFulfillmentRequest(newFulfillmentRequest);
  }, []);

  useEffect(() => {
    if (typeof originalFulfillmentRequest[0].committedQuantity === 'number') onPagLoadSplitConsignment();
  }, []);

  const getOriginalQuantity = (id: number): number | null => {
    return originalFulfillmentRequest.find((entry) => entry.id === id)?.quantity || null;
  }

  const onDateChange = (value: any, index: number, column: string = '') => {
    const newFulfillmentRequest = update(fulfillmentRequest, index, entry => {
      const key = column ? column : entry.radioButtonValue === IN_STOCK ? 'committedDate' : 'manufactureDate';

      return {
        ...entry,
        [key]: value.toString()
      };
    });

    setFulfillmentRequest(newFulfillmentRequest);
  };

  const onRadioButtonChange = (value: string, index: number) => {
    let newFulfillmentRequest = fulfillmentRequest;
    const foundDuplicateIndex = fulfillmentRequest.findIndex(
      (entry, i) =>
        entry.id === newFulfillmentRequest[index].id &&
        entry.radioButtonValue === value &&
        index !== i
    );

    if (foundDuplicateIndex !== -1) {
      newFulfillmentRequest = update(newFulfillmentRequest, index, entry => {
        return {
          ...entry,
          radioButtonValue: value,
          committedQuantity: value === IN_STOCK ? entry.committedQuantity + newFulfillmentRequest[foundDuplicateIndex].committedQuantity : 0,
          manufactureQuantity: value === IN_STOCK ? 0 : entry.committedQuantity + newFulfillmentRequest[foundDuplicateIndex].manufactureQuantity,
          committedDate: value === IN_STOCK ? getDateFromConsignmentEntry(entry, value) : '',
          manufactureDate: value === IN_STOCK ? '' : getDateFromConsignmentEntry(entry, value),
          cancelQuantity: 0
        };
      });

      newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, foundDuplicateIndex);
    } else {
      newFulfillmentRequest = update(newFulfillmentRequest, index, entry => {
        return {
          ...entry,
          radioButtonValue: value,
          committedQuantity: value === IN_STOCK ? entry.committedQuantity : 0,
          manufactureQuantity: value === IN_STOCK ? 0 : entry.manufactureQuantity,
          committedDate: value === IN_STOCK ? getDateFromConsignmentEntry(entry, value) : '',
          manufactureDate: value === IN_STOCK ? '' : getDateFromConsignmentEntry(entry, value),
          cancelQuantity: 0
        };
      });
    }

    setFulfillmentRequest(newFulfillmentRequest);
  };

  const handleShowCancellationConsignmentPopup = () => setCancellationConsignmentPopup(true);
  const handleCloseCancellationConsignmentPopup = () => setCancellationConsignmentPopup(false);

  // Custom comparison function
  function customSort(a: any, b: any) {
    // Compare IDs
    if (a.id < b.id) return -1;
    if (a.id > b.id) return 1;
    // If IDs are equal, maintain the original order
    return 0;
  }

  const onPagLoadSplitConsignment = () => {
    let newFulfillmentRequest: any = [];
    fulfillmentRequest.map((entry, index) => {
      let key: string = IN_STOCK;
      const foundManufactureIndex = newFulfillmentRequest.findIndex(
        (entry1: any, i: any) =>
          entry1.id === entry.id &&
          entry1.radioButtonValue === MANUFACTURE &&
          index !== i &&
          !entry1.isDeleted
      );
      if (foundManufactureIndex !== -1) {
        newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, foundManufactureIndex);
      }
      if (
        entry.committedQuantity >= entry.quantity ||
        entry.manufactureQuantity >= entry.quantity
      ) {
        return;
      }
      const quantity = key === IN_STOCK
        ? entry.quantity - entry.committedQuantity
        : entry.quantity - entry.manufactureQuantity;

      const manufactureQuantity = key === IN_STOCK
        ? entry.quantity - entry.committedQuantity
        : 0;

      const cancelQuantity = key === IN_STOCK
        ? 0
        : entry.quantity - entry.manufactureQuantity

      const newItem = {
        ...entry,
        quantity,
        committedQuantity: key === IN_STOCK ? 0 : entry.committedQuantity,
        manufactureQuantity,
        cancelQuantity,
        isDeleted: key !== IN_STOCK,
        radioButtonValue: MANUFACTURE
      };
      // Add the new object next to the current index
      newFulfillmentRequest.push(newItem);
    });
    const mergedArray = [...fulfillmentRequest, ...newFulfillmentRequest]
    // Sort the array using the custom comparison function
    mergedArray.sort(customSort);
    setFulfillmentRequest(mergedArray);
  }

  const onInputChange = (key: string, secondaryKey: string, value: string, index: number) => {
    const newFulfillmentRequest = update(fulfillmentRequest, index, entry => {
      const integerValue = parseInt(value.replace(/,/g, ''));
      return {
        ...entry,
        [key]: value && !isNaN(integerValue)
          ? integerValue > entry.quantity
            ? entry.quantity
            : integerValue
          : 0,
        [secondaryKey]: 0
      };
    });

    setFulfillmentRequest(newFulfillmentRequest);
  };

  const onStockInputBlur = (index: number, key: string) => {
    let newFulfillmentRequest = fulfillmentRequest;

    const foundManufactureIndex = newFulfillmentRequest.findIndex(
      (entry, i) =>
        entry.id === newFulfillmentRequest[index].id &&
        entry.radioButtonValue === MANUFACTURE &&
        index !== i &&
        !entry.isDeleted
    );

    const foundCancelIndex = newFulfillmentRequest.findIndex(
      entry =>
        entry.id === newFulfillmentRequest[index].id &&
        entry.isDeleted
    );

    if (foundManufactureIndex !== -1) {
      const manufactureQuantity = foundCancelIndex !== -1
        ? newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].committedQuantity - newFulfillmentRequest[foundCancelIndex].manufactureQuantity
        : newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].committedQuantity;

      newFulfillmentRequest = update(newFulfillmentRequest, foundManufactureIndex, entry => {
        return {
          ...entry,
          quantity: newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].committedQuantity,
          manufactureQuantity
        };
      });

      if (newFulfillmentRequest[index].committedQuantity >= newFulfillmentRequest[index].quantity) {
        if (foundCancelIndex !== -1) {
          newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, foundCancelIndex);
        }

        newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, foundManufactureIndex);
      }

      if (
        newFulfillmentRequest[index + 1] &&
        newFulfillmentRequest[index].radioButtonValue === newFulfillmentRequest[index + 1].radioButtonValue &&
        newFulfillmentRequest[index].id === newFulfillmentRequest[index + 1].id
      ) {
        const quantity = key === IN_STOCK
          ? newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].committedQuantity
          : newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].manufactureQuantity;

        const manufactureQuantity = key === IN_STOCK
          ? newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].committedQuantity
          : 0;

        const cancelQuantity = key === IN_STOCK
          ? 0
          : newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].manufactureQuantity

        const newItem = {
          ...newFulfillmentRequest[index],
          quantity,
          committedQuantity: 0,
          manufactureQuantity: manufactureQuantity,
          cancelQuantity: cancelQuantity,
          isDeleted: key !== IN_STOCK,
          radioButtonValue: MANUFACTURE
        };

        newFulfillmentRequest = addAtIndex(newFulfillmentRequest, newItem, index + 1);
        newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, index + 2);
      }

      if (foundCancelIndex !== -1) {
        if (newFulfillmentRequest[index]?.quantity <= newFulfillmentRequest[index]?.committedQuantity + newFulfillmentRequest[foundManufactureIndex]?.manufactureQuantity) {
          newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, foundCancelIndex);
        } else {
          newFulfillmentRequest = update(newFulfillmentRequest, foundCancelIndex, entry => {
            return {
              ...entry,
              manufactureQuantity: newFulfillmentRequest[index]?.quantity - newFulfillmentRequest[index]?.committedQuantity - newFulfillmentRequest[foundManufactureIndex]?.manufactureQuantity
            };
          });
        }
      }
    } else if (foundCancelIndex !== -1) {
      const quantity = key === IN_STOCK
        ? newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].committedQuantity
        : newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].manufactureQuantity;

      if (quantity === 0) {
        newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, foundCancelIndex);
        setFulfillmentRequest(newFulfillmentRequest);
        return;
      }
      const manufactureQuantity = key === IN_STOCK
        ? newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].committedQuantity
        : 0;

      const cancelQuantity = key === IN_STOCK
        ? 0
        : newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].manufactureQuantity

      newFulfillmentRequest = update(newFulfillmentRequest, foundCancelIndex, entry => {
        return {
          ...entry,
          quantity,
          committedQuantity: 0,
          manufactureQuantity: manufactureQuantity,
          cancelQuantity: cancelQuantity,
          isDeleted: key !== IN_STOCK,
          radioButtonValue: MANUFACTURE
        };
      });

      if (newFulfillmentRequest[index].manufactureQuantity >= newFulfillmentRequest[index].quantity) {
        newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, foundCancelIndex);
      }
    } else {
      if (
        newFulfillmentRequest[index].committedQuantity >= newFulfillmentRequest[index].quantity ||
        newFulfillmentRequest[index].manufactureQuantity >= newFulfillmentRequest[index].quantity
      ) {
        return;
      }

      const quantity = key === IN_STOCK
        ? newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].committedQuantity
        : newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].manufactureQuantity;

      const manufactureQuantity = key === IN_STOCK
        ? newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].committedQuantity
        : 0;

      const cancelQuantity = key === IN_STOCK
        ? 0
        : newFulfillmentRequest[index].quantity - newFulfillmentRequest[index].manufactureQuantity

      const newItem = {
        ...newFulfillmentRequest[index],
        quantity,
        committedQuantity: key === IN_STOCK ? 0 : newFulfillmentRequest[index].committedQuantity,
        manufactureQuantity,
        cancelQuantity,
        isDeleted: key !== IN_STOCK,
        radioButtonValue: MANUFACTURE
      };

      newFulfillmentRequest = addAtIndex(newFulfillmentRequest, newItem, index + 1);
    }

    setFulfillmentRequest(newFulfillmentRequest);
  };

  const onAddLineClick = (index: number) => {
    let newFulfillmentRequest = fulfillmentRequest;

    const canceledEntry = fulfillmentRequest[index];

    if (canceledEntry.radioButtonValue === IN_STOCK) {
      newFulfillmentRequest = update(fulfillmentRequest, index, (entry) => (
        {
          ...entry,
          committedQuantity: entry.cancelQuantity,
          cancelQuantity: 0,
          isDeleted: false,
        }
      ));
      setFulfillmentRequest(newFulfillmentRequest);
      return;
    }

    const foundManufactureIndex = newFulfillmentRequest.findIndex(
      (entry, i) =>
        entry.id === newFulfillmentRequest[index].id &&
        entry.radioButtonValue === MANUFACTURE &&
        index !== i &&
        !entry.isDeleted
    );

    if (foundManufactureIndex === -1) {
      newFulfillmentRequest = update(fulfillmentRequest, index, (entry) => (
        {
          ...entry,
          manufactureQuantity: entry.cancelQuantity,
          cancelQuantity: 0,
          isDeleted: false,
        }
      ));
      setFulfillmentRequest(newFulfillmentRequest);
      return;
    }

    const manufactureEntry = fulfillmentRequest[foundManufactureIndex];
    const originalQuantity = getOriginalQuantity(manufactureEntry.id);

    const sumOfCancelAndManufactureQuantities = canceledEntry.quantity + manufactureEntry.quantity;

    const newManufactureQuantity = (originalQuantity && sumOfCancelAndManufactureQuantities <= originalQuantity) || !originalQuantity ?
      sumOfCancelAndManufactureQuantities :
      originalQuantity;


    newFulfillmentRequest = update(fulfillmentRequest, index, (entry) => (
      {
        ...entry,
        manufactureQuantity: entry.cancelQuantity + manufactureEntry.manufactureQuantity,
        quantity: newManufactureQuantity,
        cancelQuantity: 0,
        isDeleted: false,
      }
    ));

    newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, foundManufactureIndex);

    setFulfillmentRequest(newFulfillmentRequest);
    return;
  };

  const handleConsignmentCancellationConfirm = (consignmentCancelingReason: string) => {
    let newFulfillmentRequest = fulfillmentRequest;

    const foundCancelIndex = newFulfillmentRequest.findIndex(
      entry =>
        entry.id === newFulfillmentRequest[indexValue].id &&
        entry.isDeleted
    );

    const updateIndex = foundCancelIndex !== -1 ? foundCancelIndex : indexValue;

    newFulfillmentRequest = update(newFulfillmentRequest, updateIndex, entry => {
      const newCancelQuantity = foundCancelIndex !== -1
        ? newFulfillmentRequest[foundCancelIndex].cancelQuantity + newFulfillmentRequest[indexValue].committedQuantity + newFulfillmentRequest[indexValue].manufactureQuantity
        : newFulfillmentRequest[indexValue].committedQuantity > 0
          ? newFulfillmentRequest[indexValue].committedQuantity
          : newFulfillmentRequest[indexValue].manufactureQuantity > 0
            ? newFulfillmentRequest[indexValue].manufactureQuantity
            : newFulfillmentRequest[indexValue].quantity;

      const newQuantity = Math.max(newFulfillmentRequest[indexValue].quantity, newFulfillmentRequest[updateIndex].quantity);

      return {
        ...entry,
        quantity: newQuantity,
        committedQuantity: 0,
        manufactureQuantity: 0,
        cancelQuantity: newCancelQuantity,
        radioButtonValue: newFulfillmentRequest[updateIndex].radioButtonValue,
        isDeleted: true,
        cancellationReason: consignmentCancelingReason
      };
    });

    if (foundCancelIndex !== -1) {
      newFulfillmentRequest = removeAtIndex(newFulfillmentRequest, indexValue);
    }
    setFulfillmentRequest(newFulfillmentRequest);
  }

  const onDeleteLineClick = (index: number) => {
    setIndexValue(index);
    handleShowCancellationConsignmentPopup();
  };

  const cancellationConsignmentModal = (
    <ConsignmentCancelallationModal
      show={cancellationConsignmentPopup}
      onClose={handleCloseCancellationConsignmentPopup}
      onConfirm={handleConsignmentCancellationConfirm}
      title={CANCEL_CONSIGNMENT_MODAL_TEXT.TITLE}
      labels={[CANCEL_CONSIGNMENT_MODAL_TEXT.LABEL]}
      textareaPlaceholder={CANCEL_CONSIGNMENT_MODAL_TEXT.PLACEHOLDER}
    />
  );

  const renderOrderTableRows = (consignmentEntries: ConsignmentEntry[]) => {

    return consignmentEntries.map((consignmentEntry, index) => {
      const styledIcon = consignmentEntry.isDeleted
        ? <StyledReplayIcon color="primary" onClick={() => onAddLineClick(index)} />
        : <StyledDeleteIcon onClick={() => onDeleteLineClick(index)} />;

      const datePickerValue = getDateFromConsignmentEntry(consignmentEntry);

      let quantityToShow = (consignmentEntry.radioButtonValue === IN_STOCK ? consignmentEntry.committedQuantity : consignmentEntry.manufactureQuantity) || consignmentEntry?.quantity;

      return (
        <Fragment key={index}>
          <TableRow id={index}>
            <TableData deleted={consignmentEntry.isDeleted} textDecoration={consignmentEntry.isDeleted ? 'line-through' : 'none'}>
              <FlexboxContainer flexDirection="column" alignItems="flex-start">
                {consignmentEntry.productName}
                <PartNumber deleted={consignmentEntry.isDeleted}> Vendor SKU: {consignmentEntry.sku}</PartNumber>
              </FlexboxContainer>
            </TableData>
            <TableData>{quantityToShow}</TableData>
            <TableData>
              <FlexboxContainer alignItems="center">
                <RadioButton
                  id={`instock${index}`}
                  name={`consignment${index}`}
                  onChangeValue={(value) => onRadioButtonChange(value, index)}
                  value="instock"
                  checked={!!(consignmentEntry.radioButtonValue && consignmentEntry.radioButtonValue === IN_STOCK)}
                  disabled={consignmentEntry.isDeleted || consignmentEntry.id === 0 || (consignmentEntries[index - 1] && consignmentEntries[index - 1].id === consignmentEntry.id)}
                />
                <LabelText htmlFor={`instock${index}`}>In stock</LabelText>
                <Input
                  id="in-stock-id"
                  value={consignmentEntry.isDeleted && consignmentEntry.radioButtonValue === IN_STOCK ? consignmentEntry.cancelQuantity.toString() : consignmentEntry.committedQuantity ? consignmentEntry.committedQuantity.toString() : '0'}
                  type="text"
                  width="70px"
                  textAlign='center'
                  disabled={consignmentEntry.isDeleted || (consignmentEntries[index - 1] && consignmentEntries[index - 1].id === consignmentEntry.id)}
                  onCurrencyChange={value => onInputChange('committedQuantity', 'manufactureQuantity', value, index)}
                  onBlur={(e) => onStockInputBlur(index, IN_STOCK)}
                  onClick={() => onRadioButtonChange(IN_STOCK, index)}
                />
              </FlexboxContainer>
            </TableData>
            <TableData>
              <FlexboxContainer alignItems="center">
                <RadioButton
                  id={`manufacture${index}`}
                  name={`consignment${index}`}
                  onChangeValue={(value) => onRadioButtonChange(value, index)}
                  value="manufacture"
                  checked={!!(consignmentEntry.radioButtonValue && consignmentEntry.radioButtonValue === MANUFACTURE)}
                  disabled={consignmentEntry.isDeleted}
                />
                <LabelText htmlFor={`manufacture${index}`}>Manufacture</LabelText>
                <Input
                  id="manufacture-id"
                  value={consignmentEntry.isDeleted && consignmentEntry.radioButtonValue === MANUFACTURE ? consignmentEntry.cancelQuantity.toString() : consignmentEntry.manufactureQuantity.toString()}
                  type="text"
                  width="60px"
                  textAlign='center'
                  disabled={consignmentEntry.isDeleted}
                  onCurrencyChange={value => onInputChange('manufactureQuantity', 'committedQuantity', value, index)}
                  onBlur={() => onStockInputBlur(index, MANUFACTURE)}
                  onClick={() => onRadioButtonChange(MANUFACTURE, index)}
                />
              </FlexboxContainer>
            </TableData>
            {
              consignmentType === PRICE_TYPE.PURCHASE ?
                <TableData>
                  <FlexboxContainer margin='0 0 0 50px' flexDirection="column" width='148px' alignItems='center !important'>
                    <DatePicker
                      value={datePickerValue}
                      onChange={value => onDateChange(value, index)}
                      inputVariant="outlined"
                      format="yyyy MMM dd"
                      TextFieldComponent={DatePickerInput}
                      disabled={consignmentEntry.isDeleted}
                      autoOk
                    />
                  </FlexboxContainer>
                </TableData> :
                <>
                  <TableData>
                    <FlexboxContainer margin='0 0 0 50px' flexDirection="column" width='148px' alignItems='center !important'>
                      <DatePicker
                        value={consignmentEntry.rentalStartDate}
                        onChange={value => onDateChange(value, index, 'rentalStartDate')}
                        inputVariant="outlined"
                        format="yyyy MMM dd"
                        TextFieldComponent={DatePickerInput}
                        disabled={consignmentEntry.isDeleted}
                        autoOk
                        shouldDisableDate={(date) => onlyWeekdaysAllowed(date, weekdaysOnly)}
                      />
                    </FlexboxContainer>
                  </TableData>
                  <TableData>
                    <FlexboxContainer margin='0 0 0 50px' flexDirection="column" width='148px' alignItems='center !important'>
                      <DatePicker
                        value={consignmentEntry.rentalEndDate}
                        onChange={value => onDateChange(value, index, 'rentalEndDate')}
                        inputVariant="outlined"
                        format="yyyy MMM dd"
                        TextFieldComponent={DatePickerInput}
                        disabled={consignmentEntry.isDeleted}
                        autoOk
                        shouldDisableDate={(date) => onlyWeekdaysAllowed(date, weekdaysOnly)}
                      />
                    </FlexboxContainer>
                  </TableData>
                </>
            }
            <TableData>{styledIcon}</TableData>
          </TableRow>
          <TableRow>
            <TableData>&nbsp;</TableData>
          </TableRow>
        </Fragment>
      );
    });
  }

  const onPreviewClick = () => {

    if (isOrderFulfillmentButtonDisabled) return;
    const countryId = consignmentDetailsResponse.countryId;
    const countries = getItems('countries')
    const country = countries?.find((x: any) => x.id === countryId);
    // Saving the data in sessionStorage to be used in OrderProcessPreview screen
    sessionStorage.setItem('previewRouteData', JSON.stringify({ fulfillmentRequest, consignmentDetailsResponse, country: country?.countryIso, originalFulfillmentRequest }));
    setIsReviewPage(true);
  }

  if (isReviewPage) return <OrderProcessPreview consignmentId={consignmentDetailsResponse.consignmentId} setIsReviewPageMounted={setIsReviewPageMounted} setIsReviewPage={setIsReviewPage} />

  return (
    <>
      {
        fulfillmentRequest.length > 0 ? (
          <>
            {renderOrderDetailsTitle('Provide fulfillment details')}

            {isPreviewVisible &&
              <FlexboxContainer width="100%" alignItems="center" padding="0 16px">
                <div>
                  <WarningIcon />
                </div>
                <div style={{
                  opacity: 0.7,
                  paddingLeft: '15px',
                  paddingRight: '15px'
                }}>
                  You have adjusted your order. You can click <Link disabled={isOrderFulfillmentButtonDisabled} className='text-underlined' onClick={onPreviewClick} >Preview</Link> to view the updated prices and how the order will be split.
                  Make sure the customer is aware of any changes. After reviewing the changes with the customer, click 'Confirm' to finalize the order.
                  The customer will receive an email with the updated order details.
                </div>
              </FlexboxContainer>
            }

            <DetailsContainer>
              <Table>
                <thead>
                  <TableRow>
                    <TableHeader>Products</TableHeader>
                    <TableHeader>Qty</TableHeader>
                    <TableHeader colSpan={2}>Availability</TableHeader>
                    {
                      consignmentType === PRICE_TYPE.PURCHASE ?
                        <TableHeader>{deliveryMode === SHIPPING ? 'Est. delivery' : 'Est. pickup'}</TableHeader> :
                        <>
                          <TableHeader>Rent start</TableHeader>
                          <TableHeader>Rent end</TableHeader>
                        </>
                    }
                    <TableHeader noBorder>&nbsp;</TableHeader>
                  </TableRow>
                </thead>
                <tbody>
                  {renderOrderTableRows(fulfillmentRequest)}
                  {cancellationConsignmentModal}
                </tbody>
              </Table>
            </DetailsContainer>
          </>
        ) : null
      }
    </>
  );
};

export default OrderFulfillmentTable;
