import React from 'react';
import Button from 'components/UI/Button';
import styled from 'styled-components';
import { Main } from 'styles/themeColors';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

const DownloadXLSButton = styled(Button).attrs()`
  margin-left: 10px;
`;

function addColumnsToWorksheet(columns, columnsData,worksheet, fillColor, width = 25) {
  // Add columns to worksheet columns
  worksheet.columns = [...worksheet.columns, ...columns.map(col => {
    return ({ header: col, key: col, width })
  })];

  // Add styling to columns
  columns.forEach(col => {
    worksheet.getColumn(col).values = [col];
    worksheet.getColumn(col).eachCell(cell => {
      cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: fillColor } };
      cell.font = { size: 10 };
      cell.alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
    });
  });
}


const DownloadXLS = ({ product, disabled, role }) => {
  let columnsData = [];
  let variant = {
    'SKU': "Please delete this message and insert the SKU number of your variant as seen in OFMP system. This field is required; eg.: '2FA-BITX20'",
    'Vendor Part Number': "Please delete this message and insert the 'Vendor product number' of your variant, for SLB use SPN. This field is optional.",
    'Item Type': "Please delete this message and insert the 'Item type'",
    'Hidden': "Please delete this message and indicate whether this variant should be hidden. Accepted values 'Yes' or 'No'. Empty cell means 'No'.",
    'Weight': "Please delete this message and indicate the shipping weight of the variant in kilograms. This field is required; eg.: '50'",
    'Material Number': "Please delete this message and insert the 'Material number' number of your variant as seen in OFMP system. This field is required if Material API is enabled on brand, else optional; eg.: '2FA-BITX20'",
  };
  const warehouseColsObj = {};
  const warehouseCurrencyObj = {};
  product.warehouses.forEach(warehouse => {
    warehouseColsObj[`In stock "${warehouse.code} ${warehouse.name}" warehouse`] = "Please delete this message and indicate stocks per given warehouse. Accepted values are 'Yes' or 'No', empty cell means 'Yes'"
    warehouseColsObj[`Lead days "${warehouse.code} ${warehouse.name}" warehouse`] = "Please delete this message and indicate the number of days it takes to ship this variant from a given warehouse. This field is required; eg.: '5'"
    if (product.postOrderShippingCost && warehouse.delivery) {
      warehouseColsObj[`Post-order shipping cost "${warehouse.code} ${warehouse.name}" warehouse`] = "Please delete this message and indicate whether the post-order shipping cost applies to this variant. Accepted values are 'Yes' or 'No', empty cell means 'No'"
    }
    if (warehouse?.address?.country?.countryName === "United States") {
      warehouseCurrencyObj["USA price (USD)"] = "Please delete this message and insert the price in USD without '$' sign. This field is required; eg.: '19.99'"
    } else if (warehouse?.address?.country?.countryName === "Canada") {
      warehouseCurrencyObj["Canada price (CAD)"] = "Please delete this message and insert the price in CAD without '$' sign. This field is required; eg.: '19.99'"
    }
  });
  
  const variantAttributes = {
    "Selectable attribute [replace this text in brackets with the selectable attribute name eg.: 'Diameter'; do not delete the brackets; if there are no attributes, delete this column altogether; if there is more than one attribute, then duplicate this column for every. This property is unique for a specific product variant and based on it a buyer will be able to configure the final desired end product]": "Please delete this message and indicate the selectable attribute value eg.: '50 inch'",
    "Technical specification [replace this text in brackets with the technical specification name eg.: 'Color'; do not delete the brackets; if there is no specification, delete this column altogether; if there is more than one specification, then duplicate this column for every. Based on this property there will be a specific text description displayed for a specific variant.]": "Please delete this message and indicate the technical specification eg.: 'Green'"
  }
  columnsData.push({ ...variant, ...warehouseColsObj, ...warehouseCurrencyObj, ...variantAttributes });

  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Variants');

  worksheet.columns = [];
  const columnsV = Object.keys(variant);
  addColumnsToWorksheet(columnsV,columnsData, worksheet, 'b3ecff',25);
  
  const columnsW = Object.keys(warehouseColsObj);
  addColumnsToWorksheet(columnsW,columnsData, worksheet, 'f4ca16',75);
  
  const columnsWC = Object.keys(warehouseCurrencyObj);
  addColumnsToWorksheet(columnsWC,columnsData, worksheet, 'f4ca16',75);
  
  const columnsVA = Object.keys(variantAttributes);
  addColumnsToWorksheet(columnsVA,columnsData, worksheet, 'b3ffb3', 50);
  
  // Add data rows
  columnsData.forEach(obj => {
    const row = worksheet.addRow(Object.values(obj));
    row.eachCell(cell => {
      cell.alignment = { vertical: 'bottom', horizontal: 'center', wrapText: true };
      cell.font = { size: 10 };
    });
  });

  const handleDownload = async () => {
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), `${product.name || 'TheOFMP-Product-Import-Template'}.xlsx`);
  };

  return (
    <div>
      <DownloadXLSButton isGhost color={Main.accent} disabled={disabled} onClick={handleDownload}>Download template</DownloadXLSButton>
    </div>
  );
}

export default DownloadXLS;
