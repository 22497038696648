import React from 'react';
import styled, { css } from 'styled-components';
import { Space } from 'styles/themeSpaces';
import { Text } from 'styles/themeColors';
import FlexboxContainer from 'components/UI/FlexboxContainer';

const Heading = styled.h5`
  font-size: 20px;
  line-height: 24px;
  margin: ${Space.sp2x} 0;
`;

const ColumnName = styled.div<any>`
  min-width: 120px;
  font-size: ${Space.sp2x};
  line-height: 20px;
  color: ${({ $color }) => $color ? $color : Text.primary};
  margin-right: ${Space.sp3x};
`;

const ColumnValue = styled.div<any>`
  min-width: 188px;
  font-size: ${Space.sp2x};
  line-height: 20px;
  color: ${({ $color }) => $color ? $color : Text.primary};
`;

const SecondaryText = styled.span`
  color: ${Text.secondary} !important;
`;

const FlexContainer = styled(FlexboxContainer)<any>`
  flex: 1 1 200px;
  padding: ${Space.sp2x};

  ${({ $padding }) => $padding && css`
    padding: ${$padding};
  `}
`;

interface OrderDetailsInformationProps {
  heading?: string;
  rowName1?: string;
  rowName2?: string;
  rowName3?: string | null;
  rowName4?: string | null;
  rowName5?: string;
  rowValue1?: string;
  rowValue2?: JSX.Element | string | null;
  rowValue3?: string | null;
  rowValue4?: JSX.Element | string | null;
  rowValue5?: string;
  color?: string;
  secondaryText?: string;
  padding?: string;
};

const OrderInformationTable = ({
  heading,
  rowName1,
  rowName2,
  rowName3,
  rowName4,
  rowName5,
  rowValue1,
  rowValue2,
  rowValue3,
  rowValue4,
  rowValue5,
  color,
  secondaryText,
  padding
}: OrderDetailsInformationProps) => {
  return (
    <FlexContainer
      displayFlex='flex'
      flexDirection='column'
      $padding={padding}
    >
      {heading && <Heading>{heading}</Heading>}
      <FlexboxContainer
        alignItems='baseline'
        flexDirection='column'
      >
        <FlexboxContainer alignItems='flex-start' justifyContent='flex-start' padding='6px 0'>
          <ColumnName $color={color}>{rowName1}</ColumnName>
          <ColumnValue $color={color}>
            {rowValue1} {secondaryText ? <SecondaryText className='base-italic'>(now called: {secondaryText})</SecondaryText> : ''}
          </ColumnValue>
        </FlexboxContainer>
        <FlexboxContainer alignItems='flex-start' justifyContent='flex-start' padding='6px 0'>
          <ColumnName $color={color}>{rowName2}</ColumnName>
          <ColumnValue $color={color}>{rowValue2}</ColumnValue>
        </FlexboxContainer>
        {
          rowName3 && rowValue3
            ? (
              <FlexboxContainer alignItems='flex-start' justifyContent='flex-start' padding='6px 0'>
                <ColumnName $color={color}>{rowName3}</ColumnName>
                <ColumnValue $color={color}>{rowValue3}</ColumnValue>
              </FlexboxContainer>
            )
            : null
        }
        {
          rowName4 && rowValue4
            ? (
              <FlexboxContainer alignItems='flex-start' justifyContent='flex-start' padding='6px 0'>
                <ColumnName $color={color}>{rowName4}</ColumnName>
                <ColumnValue $color={color}>{rowValue4}</ColumnValue>
              </FlexboxContainer>
            )
            : null
        }
        {
          rowName5 && rowValue5
            ? (
              <FlexboxContainer alignItems='flex-start' justifyContent='flex-start' padding='6px 0'>
                <ColumnName $color={color}>{rowName5}</ColumnName>
                <ColumnValue $color={color}>{rowValue5}</ColumnValue>
              </FlexboxContainer>
            )
            : null
        }
      </FlexboxContainer>
    </FlexContainer>
  );
};

export default OrderInformationTable;
