import VendorCompanyCredit from 'data/vendorCompanyCredit';
import Address from 'data/Address';

export default interface VendorCompany {
  id: number;
  vendorId: number;
  ofmpCompanyId: string;
  vendorCompanyId: string;
  creditInfo: CreditInfo;
  companyName: string;
  address: Pick<Address, 'addressLine1' | 'addressLine2' | 'town' | 'region' | 'countryIsoCode' | 'postalCode'>;
  accountClassification: string;
  activeIndicator: boolean;
  vendorCompanyCredits: VendorCompanyCredit[];
};

export interface CreditInfo {
  totalCreditLimit: number;
  availableCreditLimit: number;
  utilization: number;
  isReadOnly: boolean;
  lastUpdated?: string;
};

export type VendorCompanyInfo = Pick<
  VendorCompany,
  'companyName' | 'address' | 'accountClassification' | 'activeIndicator'
> & { hasCredit: boolean };

export const emptyVendorCompanyInfo: VendorCompanyInfo = {
  companyName: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    town: '',
    region: '',
    countryIsoCode: '',
    postalCode: ''
  },
  accountClassification: '',
  activeIndicator: false,
  hasCredit: false
};

export const emptyCreditInfo: CreditInfo = {
  totalCreditLimit: 0,
  availableCreditLimit: 0,
  utilization: 0,
  isReadOnly: false
};
