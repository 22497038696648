import styled from 'styled-components';

export const Card = styled.section`
  display: block;
  padding: 24px;
  margin-bottom: 16px;

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;
