import React, { useState, useEffect } from 'react';
import { navigate, useQueryParams } from 'hookrouter';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Company, * as Comp from 'data/Company';
import Vendor from 'data/Vendor';
import { isVendor } from 'data/User';
import { CreditInfo, VendorCompanyInfo, emptyCreditInfo, emptyVendorCompanyInfo } from 'data/VendorCompany';
import VendorGeneralTab from 'common/Companies/VendorGeneralTab';
import OrderHistoryTab from 'common/Companies/OrderHistoryTab';
import SalesOrder from 'common/Companies/SalesOrder';
import PricebooksTab from 'common/Companies/PricebooksTab';
import VendorRelationsTab from 'common/Companies/VendorRelationsTab';
import Page from 'components/UI/Page';
import PageHeading from 'components/UI/PageHeading';
import Tabs from 'components/UI/Tabs';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { renderToast } from 'components/UI/ToastNotification';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import {
  getVendorCompanyRecord,
  createVendorCompanyRecord,
  updateCompanyCredit,
  getCompanyCredit
} from 'requests/apigee';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { COMPANY_TABS } from 'constants/tabs';
import { Space } from 'styles/themeSpaces'
import { Text, Background } from 'styles/themeColors'
import { FontSize } from 'styles/themeSizes';
import FeatureFlag from 'data/FeatureFlag';
import { loadCompany } from 'requests/company';
import CompanyDocumentsList from './CompanyDocumentsList';
import Breadcrumbs from 'components/UI/Breadcrumb';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { usePath } from 'hookrouter';
import { BorderRadius } from 'lib/styles';
import usePathState from 'hooks/usePathState';
import Button from 'components/UI/Button';
import { ArrowBackIos } from '@material-ui/icons';
import { enablePricebooks } from 'utils/pricebooks';
import PricebooksTabOld from './PricebooksTabOld';
import { loadCompanyCustomers } from 'requests/customer';
import Customer from 'data/Customer';
import { checkUserSalesOrderVendorOption, isSalesOrderEnabled } from 'utils/salesorder';

const CompanyAddress = styled.span`
  font-size: ${FontSize.medium};
  line-height: 20px;
  color: ${Text.primary};
`;

const BreadcrumbContainer = styled(FlexboxContainer)`
  cursor: pointer;
  margin-left: ${Space.spHalf};
  margin-right: ${Space.spHalf};
  border-radius: ${BorderRadius.medium};

  &:hover {
    background: rgba(123, 97, 255, 0.05);
  }
`;

const ReturnButton = styled(Button)`
  background-color: ${Background.navy};
  border: 1px solid ${Background.navy};
  color: ${Text.whitePrimary}
  font-size: ${FontSize.medium};
  border-radius: ${BorderRadius.medium}
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  height: ${Space.sp4xPlus}
`;

const BackIcon = styled(ArrowBackIos) <any>`
  height: 22px;
  color: ${Text.whitePrimary};
  position: relative;
`;

interface Props {
  companyId: number;
  featureFlags: FeatureFlag;
  selectedVendor?: Vendor;
  updateVendor?: (name: string) => void;
};

const CompanyDetails = ({
  companyId,
  selectedVendor,
  updateVendor,
  featureFlags
}: Props) => {
  const { user } = useAuth();
  const isVendorAcc = isVendor(user!);
  const [queryParams, setQueryParams] = useQueryParams();
  const [activeTab, setActiveTab] = useState(0);
  const [isConnected, setIsConnected] = useState(false);
  const [vendorCompanyId, setVendorCompanyId] = useState('');
  const [vendorCompanyDetails, setVendorCompanyDetails] = useState<VendorCompanyInfo>(emptyVendorCompanyInfo);
  const [creditInfo, setCreditInfo] = useState<CreditInfo>(emptyCreditInfo);
  const [companyDetails, setCompanyDetails] = useState<Company>({ id: 0, ...Comp.empty });
  const [companyCustomers, setCompanyCustomers] = useState<Customer[]>([]);
  const [lastUpdated, setLastUpdated] = useState<string>('');
  const path = usePath();
  const crumbs = useBreadcrumbs(path);
  const hasVendorInterface = !!companyDetails.vendorInterfaces.find(
    vendorInterface => selectedVendor && vendorInterface.vendorId === selectedVendor.id
  );
  const { currentPath } = usePathState();
  const [previousPath, setPreviousPath] = useState(currentPath);
  useEffect(() => {
    setActiveTab(parseInt(queryParams.activeTab));
    setQueryParams({ activeTab: undefined });

  }, []);

  const switchTab = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };


  useEffect(() => {
    if (selectedVendor && companyDetails.companyId) {
      vendorCompanyGetReq.run(selectedVendor.id, companyDetails.companyId);
    }
  }, [selectedVendor, companyDetails.companyId]);

  const getCompanyCustomers = useAPI({
    companyId,
    promiseFn: loadCompanyCustomers,
    onResolve: result => {
      setCompanyCustomers(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const companyLoadReq = useAPI({
    companyId,
    promiseFn: loadCompany,
    onResolve: (result) => {
      setCompanyDetails(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const vendorCompanyGetReq = useAPI({
    deferFn: getVendorCompanyRecord,
    onResolve: result => {
      setVendorCompanyId(result.vendorCompanyId);
      setVendorCompanyDetails({
        companyName: result.customerResponse ? result.customerResponse.companyName : '',
        address: result.customerAddressResponse ? result.customerAddressResponse : vendorCompanyDetails.address,
        accountClassification: result.customerResponse ? result.customerResponse.accountClassification : '',
        activeIndicator: result.customerResponse ? result.customerResponse.activeIndicator : '',
        hasCredit: result.credit.hasCredit
      });

      setCreditInfo(result.credit);
      setIsConnected(true);

      if (selectedVendor && hasVendorInterface) {
        companyCreditGetReq.run(selectedVendor.id, result.vendorCompanyId);
      }
    },
    onReject: () => {
      setIsConnected(false);
    }
  });

  const vendorCompanyCreateReq = useAPI({
    deferFn: createVendorCompanyRecord,
    onResolve: result => {
      setVendorCompanyId(result.vendorCompanyId);
      setIsConnected(true);

      if (selectedVendor) {
        setCreditInfo(result.credit);

        if (hasVendorInterface) {
          companyCreditGetReq.run(selectedVendor.id, result.vendorCompanyId);
        }
      }

      setVendorCompanyDetails({
        companyName: result.customerResponse ? result.customerResponse.companyName : '',
        address: result.customerAddressResponse ? result.customerAddressResponse : vendorCompanyDetails.address,
        accountClassification: result.customerResponse ? result.customerResponse.accountClassification : '',
        activeIndicator: result.customerResponse ? result.customerResponse.activeIndicator : '',
        hasCredit: result.credit.hasCredit
      });

      renderToast(toast.TYPE.SUCCESS, 'Company’s ID assigned. Credit check enabled');
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const companyCreditGetReq = useAPI({
    deferFn: getCompanyCredit,
    onResolve: result => {
      setLastUpdated(result.lastUpdated);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const companyCreditUpdateReq = useAPI({
    deferFn: updateCompanyCredit,
    onResolve: result => {
      setCreditInfo(result);
      renderToast(toast.TYPE.SUCCESS, `Credit limit for ${companyDetails.displayName} was updated`);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });


  const adminCompanyTabs = featureFlags.pricebooks && featureFlags.pricebooks.enabled ? COMPANY_TABS.filter(tab => {
    if(tab.value === 'Sales Order'){
      return checkUserSalesOrderVendorOption(user, selectedVendor, tab.value) && isSalesOrderEnabled(featureFlags, 'Sales Order');
    }
    return enablePricebooks(featureFlags, tab.value);
  })
  .map((tab, index) => {
    if (index === 0) {
      return {
        ...tab,
        value: 'Vendor relations'
      };
    }

    return tab;
  }) : COMPANY_TABS.map((tab, index) => {
    if (index === 0) {
      return {
        ...tab,
        value: 'Vendor relations'
      };
    }

    return tab;
  });

  const vendorCompanyTabs = COMPANY_TABS.filter(tab => {
    if(tab.value === 'Sales Order'){
      return checkUserSalesOrderVendorOption(user, selectedVendor, tab.value) && isSalesOrderEnabled(featureFlags, 'Sales Order');
    }

    // Enable other tabs based on feature flags
    return enablePricebooks(featureFlags, tab.value); 
});

  if (
    companyLoadReq.isLoading ||
    vendorCompanyGetReq.isLoading ||
    vendorCompanyCreateReq.isLoading ||
    companyCreditGetReq.isLoading ||
    companyCreditUpdateReq.isLoading ||
    getCompanyCustomers.isLoading
  ) {
    return <LoadingIndicator />;
  }

  const companyName = (
    <BreadcrumbContainer padding={Space.spBase}>
      {companyDetails.displayName}
    </BreadcrumbContainer>
  );

  const breadcrumbs = currentPath.includes('company-details') && previousPath?.includes('order-details')
      ? (
        <FlexboxContainer flexDirection='row'>
          <ReturnButton
            onClick={() => navigate(previousPath)}
            leftIcon={(size: string) => <BackIcon style={{ width: size }} />}
          >
            Return to the order
          </ReturnButton>
          <Breadcrumbs containerPadding='10px' crumbs={crumbs} splitOrderName={companyName}/>
        </FlexboxContainer>
      )
      :  <Breadcrumbs crumbs={crumbs} splitOrderName={companyName}/>

  return (
    <Page bgColor={Background.white}>
      {breadcrumbs}
      <FlexboxContainer justifyContent="flex-start" flexDirection="column" bgColor={Background.white} margin={`${Space.sp3x} 0 ${Space.sp4x}`}>
        <PageHeading>{companyDetails.displayName}</PageHeading>
        <FlexboxContainer justifyContent="flex-start">
          <CompanyAddress>
            {
              companyDetails.companyAddress &&
              companyDetails.countryCode &&
              `${companyDetails.companyAddress}, ${companyDetails.countryCode}`
            }
          </CompanyAddress>
        </FlexboxContainer>
      </FlexboxContainer>
      {
        isVendorAcc || selectedVendor
          ? (
            featureFlags.pricebooks && featureFlags.pricebooks.enabled ? <Tabs height='auto' tabs={vendorCompanyTabs} activeTab={activeTab} theme='brandLight' borderRadius='20px' margin='0 24px 8px 0' tabPanelPadding='0 !important'>
            <VendorGeneralTab
              selectedVendor={selectedVendor}
              companyId={companyDetails.companyId}
              companyName={companyDetails.displayName}
              vendorCompanyId={vendorCompanyId}
              lastUpdated={lastUpdated}
              isConnected={isConnected}
              hasVendorInterface={hasVendorInterface}
              creditInfo={creditInfo}
              vendorCompanyDetails={vendorCompanyDetails}
              setVendorCompanyId={setVendorCompanyId}
              setVendorCompanyDetails={setVendorCompanyDetails}
              vendorCompanyCreateReq={vendorCompanyCreateReq}
              companyCreditUpdateReq={companyCreditUpdateReq}
            />
            <OrderHistoryTab
              id={companyId}
              selectedVendor={selectedVendor}
              companyId={companyDetails.companyId}
              featureFlags={featureFlags}
            />
            <CompanyDocumentsList
              selectedVendor={selectedVendor}
              companyId={companyDetails.companyId}
            />
            <PricebooksTab
              selectedVendor={selectedVendor}
              companyId={companyDetails.companyId}
              companyName={companyDetails.companyName}
              companyCustomers={companyCustomers ? companyCustomers : []}
            /> 
            <SalesOrder
              selectedVendor={selectedVendor}
              companyId={companyDetails.companyId}
              switchTab={switchTab}
            />
          </Tabs> : 
          <Tabs tabs={COMPANY_TABS} activeTab={activeTab}>
              <VendorGeneralTab
                selectedVendor={selectedVendor}
                companyId={companyDetails.companyId}
                companyName={companyDetails.displayName}
                vendorCompanyId={vendorCompanyId}
                lastUpdated={lastUpdated}
                isConnected={isConnected}
                hasVendorInterface={hasVendorInterface}
                creditInfo={creditInfo}
                vendorCompanyDetails={vendorCompanyDetails}
                setVendorCompanyId={setVendorCompanyId}
                setVendorCompanyDetails={setVendorCompanyDetails}
                vendorCompanyCreateReq={vendorCompanyCreateReq}
                companyCreditUpdateReq={companyCreditUpdateReq}
              />
              <OrderHistoryTab
                id={companyId}
                selectedVendor={selectedVendor}
                companyId={companyDetails.companyId}
                featureFlags={featureFlags}
              />
              <CompanyDocumentsList
                selectedVendor={selectedVendor}
                companyId={companyDetails.companyId}
              />
              <PricebooksTabOld
                selectedVendor={selectedVendor}
                companyId={companyDetails.companyId}
              />
              <SalesOrder
                selectedVendor={selectedVendor}
                companyId={companyDetails.companyId}
                switchTab={switchTab}
              />
            </Tabs>
          )
          : (
            featureFlags.pricebooks && featureFlags.pricebooks.enabled ? <Tabs height='auto' tabs={adminCompanyTabs} activeTab={activeTab} theme='brandLight' borderRadius='20px' margin='0 24px 8px 0' tabPanelPadding='0 !important'>
            <VendorRelationsTab
              companyId={companyDetails.companyId}
              updateVendor={updateVendor}
            />
            <OrderHistoryTab
              id={companyId}
              selectedVendor={selectedVendor}
              companyId={companyDetails.companyId}
              featureFlags={featureFlags}
            />
            <CompanyDocumentsList
              companyId={companyDetails.companyId}
            />
            <PricebooksTab
              selectedVendor={selectedVendor}
              companyId={companyDetails.companyId}
              companyName={companyDetails.companyName}
              companyCustomers={companyCustomers ? companyCustomers : []}

            />
            {
              featureFlags.salesOrder && featureFlags.salesOrder.enabled ?
              <SalesOrder
              selectedVendor={selectedVendor}
              companyId={companyDetails.companyId}
              switchTab={switchTab}
            /> : null
            }
          </Tabs>: 
          <Tabs tabs={adminCompanyTabs} activeTab={activeTab}>
              <VendorRelationsTab
                companyId={companyDetails.companyId}
                updateVendor={updateVendor}
              />
              <OrderHistoryTab
                id={companyId}
                selectedVendor={selectedVendor}
                companyId={companyDetails.companyId}
                featureFlags={featureFlags}
              />
              <CompanyDocumentsList
                companyId={companyDetails.companyId}
              />
              <PricebooksTabOld
                selectedVendor={selectedVendor}
                companyId={companyDetails.companyId}
              />
              <SalesOrder
                selectedVendor={selectedVendor}
                companyId={companyDetails.companyId}
                switchTab={switchTab}
              />
            </Tabs>
          )
      }
    </Page>
  );
};

export default CompanyDetails;
