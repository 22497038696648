import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { AsyncState } from 'react-async';
import { CreditInfo, emptyVendorCompanyInfo, VendorCompanyInfo } from 'data/VendorCompany';
import CompanyUIDCard from 'components/Company/CompanyUIDCard';
import CreditCheckCard from 'components/Company/CreditCheckCard';
import AssignModal from 'components/Company/Modals/AssignModal';
import ErrorModal from 'components/Company/Modals/ErrorModal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Modal from 'components/UI/Modal'
import { renderToast } from 'components/UI/ToastNotification';
import Vendor from 'data/Vendor';
import { validateVendorCompany } from 'requests/vendorCompany';
import { getVendorCompanyInfo } from 'requests/apigee';
import { useAPI } from 'lib/API';
import { Space } from 'styles/themeSpaces';

interface Props {
  selectedVendor?: Vendor;
  companyId: string;
  companyName: string;
  vendorCompanyId: string;
  lastUpdated: string;
  isConnected: boolean;
  hasVendorInterface: boolean;
  creditInfo: CreditInfo;
  vendorCompanyDetails: VendorCompanyInfo;
  setVendorCompanyId: (value: string) => void;
  setVendorCompanyDetails: (value: VendorCompanyInfo) => void;
  vendorCompanyCreateReq: AsyncState<any>;
  companyCreditUpdateReq: AsyncState<any>;
};

const VendorGeneralTab = ({
  selectedVendor,
  companyId,
  vendorCompanyId,
  companyName,
  lastUpdated,
  isConnected,
  hasVendorInterface,
  creditInfo,
  vendorCompanyDetails,
  setVendorCompanyId,
  setVendorCompanyDetails,
  vendorCompanyCreateReq,
  companyCreditUpdateReq
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [assignedCompanyName, setAssignedCompanyName] = useState('');
  const [vendorCompanyInfoCalled, setVendorCompanyInfoCalled] = useState(true);
  const [error, setError] = useState('');
  const primaryButtonText = !hasVendorInterface || vendorCompanyDetails.activeIndicator
    ? 'Confirm'
    : error === 'Service unavailable'
      ? 'Try again'
      : 'Close';

  const { modal, title } = renderModal(
    vendorCompanyDetails,
    vendorCompanyId,
    companyName,
    isConnected,
    hasVendorInterface,
    vendorCompanyDetails.activeIndicator,
    error
  );

  const vendorCompanyInfoReq = useAPI({
    deferFn: getVendorCompanyInfo,
    onResolve: result => {
      setVendorCompanyDetails({
        companyName: result.companyName,
        address: result.address,
        accountClassification: result.accountClassification,
        activeIndicator: result.activeIndicator,
        hasCredit: result.hasCredit
      });

      setError('');
      setShowModal(true);
    },
    onReject: err => {
      setError(err.message);

      if (err.message === 'API not found' || err.message === 'Service unavailable') {
        setShowModal(true);
      }
    }
  });

  const validateVendorCompanyReq = useAPI({
    deferFn: validateVendorCompany,
    onResolve: (result) => {
      if (result) {
        setAssignedCompanyName(result.company.displayName);
        setIsInvalid(true);
      } else {
        setIsInvalid(false);
        setVendorCompanyInfoCalled(false);

        if (!hasVendorInterface) {
          setVendorCompanyDetails(emptyVendorCompanyInfo);
          setShowModal(true);
        }
      }
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    if (
      vendorCompanyId &&
      hasVendorInterface &&
      !vendorCompanyInfoReq.isLoading &&
      !vendorCompanyInfoCalled
    ) {
      setVendorCompanyInfoCalled(true);
      vendorCompanyInfoReq.run(vendorCompanyId);
    }
  }, [vendorCompanyInfoReq.isLoading, hasVendorInterface, vendorCompanyId, vendorCompanyInfoCalled]);

  const onConfirmAssignModalClick = () => {
    if (selectedVendor && vendorCompanyId) {
      vendorCompanyCreateReq.run(selectedVendor.id, companyId, vendorCompanyId);
      setShowModal(false);
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  const onAssignClick = (value: string) => {
    if (selectedVendor) {
      setVendorCompanyId(value);
      validateVendorCompanyReq.run(selectedVendor.id, companyId, value);
    }
  };

  const onUpdateCreditClick = (value: string) => {
    if (selectedVendor && vendorCompanyId) {
      companyCreditUpdateReq.run(selectedVendor.id, vendorCompanyId, value);
    }
  };

  const reloadVendorCompanyInfoReq = () => {
    vendorCompanyInfoReq.reload();
    setShowModal(false);
  };

  const onModalClick = () => {
    return !hasVendorInterface || vendorCompanyDetails.activeIndicator
      ? onConfirmAssignModalClick()
      : error === 'Service unavailable'
        ? reloadVendorCompanyInfoReq()
        : onClose();
  };

  return (
    <FlexboxContainer justifyContent="flex-start" margin={`${Space.sp3x} 0 0 0`}>
      <CompanyUIDCard
        vendorCompanyId={vendorCompanyId}
        assignedCompanyName={assignedCompanyName}
        lastUpdated={lastUpdated}
        vendorCompanyDetails={vendorCompanyDetails}
        onClick={onAssignClick}
        isInvalid={isInvalid}
        isConnected={isConnected}
        hasVendorInterface={hasVendorInterface}
        setIsInvalid={setIsInvalid}
      />
      <CreditCheckCard
        isConnected={isConnected}
        lastUpdated={lastUpdated}
        creditInfo={creditInfo}
        onClick={onUpdateCreditClick}
      />
      <Modal
        title={title}
        onClose={onClose}
        showModal={showModal}
        isError={!!(hasVendorInterface && (!vendorCompanyDetails.activeIndicator || error))}
        theme="accent"
        onClick={onModalClick}
        top={`
          ${vendorCompanyDetails.companyName
            ? vendorCompanyDetails.activeIndicator
              ? '15%'
              : '10%'
            : '30%'}
        `}
        primaryButtonText={primaryButtonText}
        showGhostButton={!hasVendorInterface || vendorCompanyDetails.activeIndicator || error === 'Service unavailable'}
      >
        {modal}
      </Modal>
    </FlexboxContainer>
  );
};

const renderModal = (
  vendorCompanyDetails: VendorCompanyInfo,
  vendorCompanyId: string,
  companyName: string,
  isConnected: boolean,
  hasVendorInterface: boolean,
  activeIndicator: boolean,
  error?: string
) => {
  let modal = (
    <AssignModal
      vendorCompanyDetails={vendorCompanyDetails}
      vendorCompanyId={vendorCompanyId}
      isConnected={isConnected}
      hasVendorInterface={hasVendorInterface}
      activeIndicator={activeIndicator}
    />
  );
  let title = `Assigning ${vendorCompanyId} for ${companyName}`;

  if (hasVendorInterface) {
    if (!activeIndicator) {
      title = 'Inactive ID can not be assigned';
    } else if (vendorCompanyDetails.companyName) {
      title = 'Assigning UID';
    }

    if (error) {
      modal = <ErrorModal vendorCompanyId={vendorCompanyId} error={error} />;

      switch (error) {
        case 'API not found':
          title = 'ID does not exist';
          break;
        case 'Service unavailable':
          title = 'Unable to connect';
          break;
      }
    }
  }

  return { modal, title };
};

export default VendorGeneralTab;
