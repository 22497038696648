import React from 'react';
import styled from 'styled-components';
import { VendorCompanyInfo } from 'data/VendorCompany';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { formatAddressMultiline } from 'utils/formatters';
import { Space } from 'styles/themeSpaces';
import { Main, Text, Background } from 'styles/themeColors';
import { BorderRadius } from 'styles/themeBorderRadius';
import { MainColors } from 'types/enumTypes';

const ConnectionStatus = styled.div<any>`
  background-color: ${
    ({ isConnected, connectionBgColor }: Props) => isConnected && connectionBgColor
      ? Main[connectionBgColor] : Main.warning
  };
  border-radius: 22px;
  color: ${Text.whitePrimary} !important;
  padding: ${Space.spHalf} ${Space.spBase};
  margin-bottom: ${Space.spBase};
`;

const Title = styled.div`
  margin-bottom: ${Space.spBase};
  color: ${({ color }) => color ? color : Text.secondary} !important;
`;

const VendorCompanyDetail = styled.span <any>`
  margin-right: ${Space.spHalf};
  margin-bottom: ${Space.spHalf};
  color: ${({ color }) => color ? color : Text.secondary} !important;
  width: ${({ quarter }) => quarter ? '35%' : '100%'};
`;

interface Props {
  isConnected: boolean;
  vendorCompanyId: string;
  connectionStatus?: string;
  connectionBgColor?: MainColors;
  vendorCompanyDetails: VendorCompanyInfo;
};

const VendorCompanyCard = ({
  isConnected,
  vendorCompanyId,
  connectionStatus,
  connectionBgColor,
  vendorCompanyDetails,
}: Props) => {
  const { line1, line2, line3 } = formatAddressMultiline(
    vendorCompanyDetails.address.addressLine1,
    vendorCompanyDetails.address.addressLine2,
    vendorCompanyDetails.address.town,
    vendorCompanyDetails.address.region,
    vendorCompanyDetails.address.postalCode,
    'United States of America'
  );

  const title = connectionStatus
    ? (
      <ConnectionStatus className="base-strong" isConnected={isConnected} connectionBgColor={connectionBgColor}>
        {connectionStatus}
      </ConnectionStatus>
    )
    : (
      <Title className="base-strong">
        Your source system data for {vendorCompanyId}
      </Title>
    );

  return (
    <FlexboxContainer
      flexDirection="column"
      bgColor={Background.shaded}
      padding={Space.spBase}
      margin={`0 0 ${Space.spBasePlus}`}
      borderRadius={BorderRadius.m}
    >
      {title}
      <FlexboxContainer>
        <VendorCompanyDetail quarter className="base-italic">Company name</VendorCompanyDetail>
        <VendorCompanyDetail className="base-italic">
          {vendorCompanyDetails.companyName}
        </VendorCompanyDetail>
      </FlexboxContainer>
      <FlexboxContainer>
        <VendorCompanyDetail quarter className="base-italic">Address</VendorCompanyDetail>
        <FlexboxContainer flexDirection="column" width='100%'>
          <VendorCompanyDetail className="base-italic">{line1}</VendorCompanyDetail>
          <VendorCompanyDetail className="base-italic">{line2}</VendorCompanyDetail>
          <VendorCompanyDetail className="base-italic">{line3}</VendorCompanyDetail>
        </FlexboxContainer>
      </FlexboxContainer>
      <FlexboxContainer>
        <VendorCompanyDetail quarter className="base-italic">Account type</VendorCompanyDetail>
        <VendorCompanyDetail className="base-italic">
          {vendorCompanyDetails.accountClassification}
        </VendorCompanyDetail>
      </FlexboxContainer>
      <FlexboxContainer>
        <VendorCompanyDetail quarter className="base-italic">Credit Data</VendorCompanyDetail>
        <VendorCompanyDetail className="base-italic" color={vendorCompanyDetails.hasCredit ? '' : Main.error}>
          {vendorCompanyDetails.hasCredit ? 'Available' : 'Not available'}
        </VendorCompanyDetail>
      </FlexboxContainer>
      <FlexboxContainer>
        <VendorCompanyDetail quarter className="base-italic">ID status</VendorCompanyDetail>
        <VendorCompanyDetail className="base-italic" color={vendorCompanyDetails.activeIndicator ? '' : Main.error}>
          {vendorCompanyDetails.activeIndicator ? 'Active' : 'Inactive'}
        </VendorCompanyDetail>
      </FlexboxContainer>
    </FlexboxContainer>
  );
};

export default VendorCompanyCard;
