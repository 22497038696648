import { Resource } from "data/Resource";
import { del, get, post, send } from "lib/API";

const YOUTUBE_TITLE_URL = process.env.REACT_APP_YOUTUBE_TITLE_URL;

export const getAllResources = async (
    _: any[],
    { token }: any
): Promise<Resource[]> => {
    return await get('v2/resource', token);
};

export const getVendorsResources = async (
    [vendorIds]: any[],
    { token }: any
): Promise<Resource[]> => {
    return await post('v2/resource/getVendorResources', token, { vendorIds: vendorIds });
};

export const createResource = async (
    [resources]: any[],
    { token }: any
): Promise<Resource[]> => {
    return await post('v2/resource', token, resources);
};

export const deleteResource = async (
    [resourceId]: any[],
    { token }: any
): Promise<Resource[]> => {
    return await del(`v2/resource/${resourceId}`, token);
};

export const getYoutubeVideoTitle = async (url: string) => {
    if(url){
        let reqUrl = YOUTUBE_TITLE_URL?.replace('{url}', url);
        if(reqUrl){
            const request = new Request(reqUrl, { method: 'GET' });
            return await send(request);
        }
       return null;
    }
    return null;
}