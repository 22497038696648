import { get, post, formatUrl, del, put } from 'lib/API';
import { PricebookResponse } from 'data/Pricebook';

export const loadPricebooks = async (
  [companyId, vendorId]: any[],
  { token }: any
): Promise<PricebookResponse[]> => {
  const url = formatUrl('v1/pricebooks', { companyId, vendorId });

  return await get(url, token);
};

export const updatePricebooks = async (
  [companyId, vendorId, pricebooks]: any[],
  { token }: any
): Promise<any> => {
  return await post('v1/pricebooks', token, {
    companyId,
    vendorId,
    pricebooks,
  });
};

export const uploadPricebooksForUser = async (
  [fileContent, fileName, pricebook, uploadDate]: any[],
  { token }: any
): Promise<any> => {
  return await post(`v1/pricebooks/pricebooksFile`, token, {
    fileContent,
    fileName,
    pricebook,
    uploadDate,
  });
};

export const forcePublishPricebooks = async (
  [priceBookId]: any[],
  { token }: any
): Promise<any> => {
  return await post(`v1/pricebooks/publishPricebooks`, token, {
    priceBookId,
  });
};

export const getPricebooksById = async (
  [pricebookId]: any[],
  { token }: any
) => {
  return await get(`v1/pricebooks/${pricebookId}`, token);
};

export const getPricebookbyReplacementId = async (
  [replacementPricebookId]: any[],
  { token }: any
) => {
  return await get(`v1/pricebooks/replace/${replacementPricebookId}`, token);
};

export const getPricebooksByCompanyId = async (
  [companyId, vendorId]: any[],
  { token }: any
) => {
  if (vendorId) {
    const url = formatUrl(`v1/pricebooks/company/${companyId}`, { vendorId });

    return await get(url, token);
  } else {
    return await get(`v1/pricebooks/company/${companyId}`, token);
  }
};

export const getPricebooksByVendorId = async (
  [vendorId]: any[],
  { token }: any
) => {
  const url = formatUrl('v1/pricebooks/vendor', { vendorId });
  return await get(url, token);
};

export const getPricebooksByCompanyVendorId = async (
  [companyId, vendorId, customerId]: any[],
  { token }: any
) => {
  const url = formatUrl(`v1/pricebooks/${vendorId}/${companyId}/${customerId}`);

  return await get(url, token);
};

export const getPricebooksByWarehouseVendorId = async (
  [vendorId, warehouseId]: any[],
  { token }: any
) => {
  const url = formatUrl(`v1/pricebooks/warehouse/${vendorId}/${warehouseId}`);

  return await get(url, token);
};

export const getPricebooksByReferenceVendorId = async (
  [vendorId, countryId]: any[],
  { token }: any
) => {
  const url = formatUrl(`v1/pricebooks/reference/${vendorId}/${countryId}`);

  return await get(url, token);
};

export const deletePricebook = async (
  [pricebookId]: any[],
  { token }: any
) => {
  return await del(`v1/pricebooks/${pricebookId}`, token);
};

export const forceDeletePricebook = async (
  [pricebookId]: any[],
  { token }: any
) => {
  return await del(`v1/pricebooks/delete/force/${pricebookId}`, token);
};

export const cancelDraftPricebook = async (
  [pricebookId]: any[],
  { token }: any
) => {
  return await del(`v1/pricebooks/cancel/${pricebookId}`, token);
};

export const reverseDeletePricebook = async (
  [pricebookId]: any[],
  { token }: any
) => {
  return await put(`v1/pricebooks/delete/reverse/${pricebookId}`, token);
};

export const replacePricebooksForUser = async (
  [fileContent, fileName, pricebook, uploadDate]: any[],
  { token }: any
): Promise<any> => {
  return await post(`v1/pricebooks/replace`, token, {
    fileContent,
    fileName,
    pricebook,
    uploadDate,
  });
};
