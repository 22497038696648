import Brand from 'data/Brand';

export const getValueCount = (brand: Brand, key: keyof Brand): number => {
  let value = brand[key];

  if (value instanceof Array) {
    return value.length;
  }

  if (key === 'description' && brand[key]) {
    value = brand[key].replace(/<[^>]*>/g, ' ');
  }

  if (typeof value === 'string') {
    return value
      .split(/\s+/)
      .map(word => word.trim())
      .filter(word => word.length > 0).length;
  }

  if (value) {
    return 1;
  }

  return 0;
};

export const mapBrandVendorToBrand = (result: any) => {
  const brandVendor = {
    baseUrl: result.brandVendor.baseUrl || '',
    authenticationUrl: result.brandVendor.authenticationUrl || '',
    consumerKey: result.brandVendor.consumerKey || '',
    consumerSecret: result.brandVendor.consumerSecret || '',
    isMaterialApiEnabled: result.brandVendor.isMaterialApiEnabled || '',
    isStockApiEnabled: result.brandVendor.isStockApiEnabled || '',
    isSalesOrderApiEnabled: result.brandVendor.isSalesOrderApiEnabled || '',
  };

  return brandVendor;
}
