import { get, post, put, del } from 'lib/API';
import Brand from 'data/Brand';

export const loadBrands = async ([vendorId]: any[], { token }: any): Promise<Brand[]> => {
  return await get(`v1/brands${vendorId ? '?vendorId=' + vendorId : ''}`, token);
};

export const createBrand = async ([newBrand, vendorId]: any[], { token }: any): Promise<any> => {
  return await post('v1/brands', token, {
    name: newBrand.name,
    vendorId,
    description: newBrand.description,
    keywords: newBrand.keywords,
    mediaId: newBrand.media && newBrand.media.length > 0 ? newBrand.media[0].id : null,
    isMaterialApiEnabled: newBrand.isMaterialApiEnabled,
    authenticationUrl: newBrand.authenticationUrl,
    baseUrl: newBrand.baseUrl,
    consumerKey: newBrand.consumerKey,
    consumerSecret: newBrand.consumerSecret,
    isStockApiEnabled: newBrand.isStockApiEnabled,
  });
};

export const saveBrand = async ([brandToUpdate, vendorId]: any[], { token }: any): Promise<any> => {
  return await put(`v1/brands/${brandToUpdate.id}`, token, {
    name: brandToUpdate.name,
    vendorId,
    description: brandToUpdate.description,
    keywords: brandToUpdate.keywords,
    mediaId: brandToUpdate.media && brandToUpdate.media.length > 0 ? brandToUpdate.media[0].id : null,
    isMaterialApiEnabled: brandToUpdate.isMaterialApiEnabled,
    authenticationUrl: brandToUpdate.authenticationUrl,
    baseUrl: brandToUpdate.baseUrl,
    consumerKey: brandToUpdate.consumerKey,
    consumerSecret: brandToUpdate.consumerSecret,
    isStockApiEnabled: brandToUpdate.isStockApiEnabled,
    isSalesOrderApiEnabled: brandToUpdate.isSalesOrderApiEnabled,
  });
};

export const loadBrand = async ([brandId]: any[], { token }: any): Promise<Brand> => {
  return await get(`v1/brands/${brandId}`, token);
};

export const deleteBrandOldMedia = (brandId: number, mediaId: number, token: string): Promise<any> => {
  return del(`v1/brandOldMedia?brandId=${brandId}&mediaId=${mediaId}`, token);
};

export const deleteBrandMedia = (brandId: number, mediaId: number, token: string): Promise<any> => {
  return del(`v1/brandMedia?brandId=${brandId}&mediaId=${mediaId}`, token);
};
