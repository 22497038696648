const companyPricebookColumns = [
  {
    title: 'Geography',
    field: 'priceBookTypes.name',
    width: '20%'
  },
  {
    title: 'Pricebook name',
    field: 'priceBookName',
    width: '35%'
  },
  {
    title: 'Pricebook ID',
    field: 'priceBookId',
    width: '16%'
  },
  {
    title: 'Validity',
    field: 'validateDate',
    type: 'date',
    dateSetting: { locale: 'en-US' },
    width: '23%'
  },
  {
    title: '',
    field: 'clearData',
    width: '6%',
    cellStyle: { padding: '20px 0' }
  }
];

export default companyPricebookColumns;
