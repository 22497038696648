import React from 'react';
import ConfirmModal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import styled from 'styled-components';
import { Space } from 'styles/themeSpaces';
import { TEXT_LABELS } from 'constants/productDetails';
import { renderToast, updateToast } from 'components/UI/ToastNotification';
import Product, * as Prod from 'data/Product';
import { toast } from 'react-toastify';
import { navigate } from 'hookrouter';
import { Socket } from 'socket.io-client';
import { ClientToServerEvents, ServerToClientEvents } from 'types/socket';
import Currency from 'data/Currency';
import User from 'data/User';
import Vendor, { SkuValidation } from 'data/Vendor';

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;
interface Props {
  isSubmitModalOpen: boolean;
  setIsSubmitModalOpen: (value: boolean) => void;
  productDetails: Product | undefined;
  currentVendor: Vendor;
  socket: any;
  previousLibraryPath: string;
  token: string | null;
  currencies: Currency[];
  user: User | null;
}

export default function SubmitModal({
  isSubmitModalOpen,
  setIsSubmitModalOpen,
  productDetails,
  currentVendor,
  socket,
  previousLibraryPath,
  token,
  currencies,
  user,
}: Props) {
  function submit() {
    if (!productDetails || !currentVendor) {
      return;
    }

    const toastId = TEXT_LABELS.TOAST_ID;

    if (currentVendor) {
      renderToast(
        toast.TYPE.INFO,
        TEXT_LABELS.PRODUCT_SUBMITING,
        {
          toastId,
          autoClose: false,
        }
      );

      Prod.submit(
        productDetails,
        currentVendor,
        token as string,
        currencies,
        true
      )
        .then((res) => {
          if (res?.message) {
            updateToast(toastId, toast.TYPE.ERROR, res.message, {
              autoClose: 5000,
            });
          } else {
            if (res?.isValidationEnabled) {
              updateToast(
                toastId,
                toast.TYPE.WARNING,
                TEXT_LABELS.PRODUCT_VALIDATION_START_MESSAGE,
                {
                  autoClose: 2500,
                }
              );
              socket.on(
                `skuValidation_${user?.id}_${res?.id}`,
                ({ status, productName }: SkuValidation) => {
                  if (status) {
                    renderToast(
                      toast.TYPE.SUCCESS,
                      `Validation has been completed successfully. You may publish the product ${productName} further.`,
                      {
                        autoClose: 5000,
                        toastId,
                      }
                    );
                    updateToast(
                      toastId,
                      toast.TYPE.SUCCESS,
                      `Validation has been completed successfully. You may publish the product ${productName} further.`,
                      {
                        autoClose: 5000,
                      }
                    );
                  } else {
                    renderToast(
                      toast.TYPE.ERROR,
                      `Validation has been completed. Product ${productName} has issues with material number(s).
                        Please contact your system administrator.`,
                      {
                        autoClose: 5000,
                        toastId,
                      }
                    );
                    updateToast(
                      toastId,
                      toast.TYPE.ERROR,
                      `Validation has been completed. Product ${productName} has issues with material number(s). 
                        Please contact your system administrator.`,
                      {
                        autoClose: 5000,
                      }
                    );
                  }
                }
              );
            } else {
              updateToast(
                toastId,
                toast.TYPE.SUCCESS,
                TEXT_LABELS.PRODUCT_SUBMITTED_MESSAGE,
                {
                  autoClose: 5000,
                }
              );
            }

            navigate(previousLibraryPath);
          }
        })
        .catch((err) => {
          updateToast(toastId, toast.TYPE.ERROR, err.message, {
            autoClose: 5000,
          });
        });
    } else {
      renderToast(
        toast.TYPE.INFO,
        TEXT_LABELS.SELECT_VENDOR_TO_SUBMIT
      );
      return;
    }

    setIsSubmitModalOpen(false);
  }
  return (
    <ConfirmModal
      showModal={isSubmitModalOpen}
      onClose={() => setIsSubmitModalOpen(false)}
      onClick={submit}
      title={TEXT_LABELS.SUBMIT_PRODUCT}
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>{TEXT_LABELS.SUBMIT_CONFIRMATION}</Span>
      </FlexboxContainer>
    </ConfirmModal>
  );
}
