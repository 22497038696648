import React, { useEffect, useState } from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
    TextFieldProps,
    TextField,
} from '@material-ui/core';
import {
    useForm,
    useFieldArray,
    Controller,
    SubmitHandler
} from 'react-hook-form';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputComponent from 'components/UI/Input';
import SelectComponent from 'components/UI/Select';
import DatePickerInput from 'components/UI/DatePickerInput';
import { DatePicker } from '@material-ui/pickers';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import styled from 'styled-components';
import Campaign, { empty as emptyCampaign} from 'data/Campaign';
import Vendor from 'data/Vendor';
import Country from 'data/Country';
import { getItems } from 'lib/SessionStorage';
import { getVendorValue } from 'utils/campaign';
import { Background, Border, Main, Text } from 'styles/themeColors';
import Button from 'components/UI/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { Color, FontWeight } from 'lib/styles';
import { BorderRadius } from 'styles/themeBorderRadius';
import CampaignType from 'data/CampaignType';
import LaunchCompany from 'data/LaunchCompany';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';

const filter = createFilterOptions();

const LabelText = styled.span<any>`
    font-weight: ${FontWeight.medium};
    color: ${({ error }) => error ? Main.error : Text.primary} !important;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: ${({ $marginBottom }) => $marginBottom ? $marginBottom : '8px'};
    margin-right: 0px;
    margin-top: 0px;
`;

const ErrorLabel = styled.label<any>`
    color: #C82135;
    font-size: 12px;
    line-height: 16px;
    margin-top: ${({ $marginTop }) => $marginTop ? $marginTop : '2px'};
`

const CancelButton = styled(Button)`
  margin-right: 5px;
  color: ${Color.black} !important;
  background-color: ${Background.white};
  border-color: ${Main.disabled} !important;
`;

const SaveButton = styled(Button)`
  padding: 10px;
  border-radius: ${BorderRadius.m};
  background-color: ${Main.brandDark};
  border-color: ${Main.disabled} !important;
`;

const AutocompleteStyled = styled(Autocomplete) <any> `
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({ error }) => error ? Main.error : Border.main} !important;
    color: ${({ error }) => error ? Main.error : Text.primary} !important;
  }
  
  .MuiSvgIcon-root {
    border-color: ${({ error }) => error ? Main.error : Border.main} !important;
    color: ${({ error }) => error ? Main.error : Text.primary} !important;
  }
`;

const IconAsterisk = styled.span<any>`
  color: ${({ $error }) => $error ? Main.error : 'red'} ;
  width: 5px;
  height: 5px;
  margin-left: 5px;
`;

interface CampaignFormProps {
    vendorsList: Vendor[];
    selectedVendorId?: number;
    onClose?: () => void;
    onSave?: (data: any) => void;
    campaignTypes: CampaignType[];
    launchCompanies: LaunchCompany[];
    campaign: Campaign
    eventIds: any[];
    editMode: boolean;
}

// Form Data Interface
interface FormData {
    campaigns: Campaign[];
}

const CampaignForm = ({
    vendorsList,
    selectedVendorId,
    onClose,
    onSave,
    campaignTypes,
    launchCompanies,
    campaign,
    eventIds,
    editMode
}: CampaignFormProps) => {
    const countries = getItems('countries');
    const [expandedPanel, setExpandedPanel] = useState<string | false>('panel-0');

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors, isValid },
        getValues,
        setValue,
        reset,
        trigger,
        clearErrors,
    } = useForm<FormData>({
        defaultValues: {
            campaigns: [{...campaign}]
        },
        mode: 'onBlur',
        shouldFocusError: true,
    });

    useEffect(() => {
        if (campaign) {
            reset({
                campaigns: [{ ...campaign }]
            });
        }
    }, [campaign, reset]);

    const { fields, append, remove } = useFieldArray({
        control,
        name: "campaigns"
    });

    const handleAccordionChange = (panel: string) => (_: any, isExpanded: boolean) => {
        setExpandedPanel(isExpanded ? panel : false);
    };

    const formatData = (data: any) => {
        return {
            ...data,
            campaigns: data.campaigns.map((campaign: any) => {
                return {
                    ...campaign,
                    budget: Number(campaign.budget),
                    actualSpend: Number(campaign.actualSpend),
                    vendors: selectedVendorId ? [selectedVendorId] : campaign.vendors
                };
            }),
        };
    }

    const onSubmit: SubmitHandler<FormData> = (data, event) => {
        event?.preventDefault();
        onSave?.(formatData(data))
    };

    const handleAppendForm = () => {
        if(isValid){
            const index = fields.length;
            append({...emptyCampaign, vendors: selectedVendorId ? [selectedVendorId] : []})
            setExpandedPanel(`panel-${index}`);
        }
    }

    const submitWrapper = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault(); // Prevent default form behavior
    
        const isFormValid = await trigger(); // Validate all fields
    
        if (!isFormValid) {
            // Find all forms with errors and extract their titles
            const errorForms = getValues().campaigns
                .map((campaign, index) => {
                    const formTitle = campaign.title || `New Event ${index + 1}`; // Assuming each campaign has a `title` field
                    return errors.campaigns?.[index] ? formTitle : null;
                })
                .filter((formTitle): formTitle is string => Boolean(formTitle)); // Filter out null values
    
            // Open the accordion for the first error form
            const firstErrorIndex = getValues().campaigns.findIndex((_, index) => errors.campaigns?.[index]);
            if (firstErrorIndex !== -1) {
                setExpandedPanel(`panel-${firstErrorIndex}`);
            }
    
            // Show a toast with all error form titles
            renderToast(
                toast.TYPE.ERROR,
                `Please fill all the required fields in the events: ${errorForms.join(', ')}.`
            );
    
            return; // Stop submission if there are validation errors
        }
    
        // If valid, proceed with submission
        handleSubmit(onSubmit)(event);
    };       

    return (
            <>
                <div style={{ maxHeight: '70vh', overflow: 'auto' }}>
                    {fields.map((field, index) => (
                        <Accordion
                            key={index}
                            expanded={expandedPanel === `panel-${index}`}
                            onChange={handleAccordionChange(`panel-${index}`)}
                        >
                            <AccordionSummary
                                expandIcon={
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                        <ExpandMoreIcon />
                                        {
                                            expandedPanel !== `panel-${index}` &&
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => remove(index)}
                                            >
                                                <DeleteIcon style={{ color: '#ff6600' }} />
                                            </IconButton>
                                        }
                                    </div>
                                }
                                aria-controls={`panel-${index}-content`}
                            >
                                <Typography>
                                    {watch(`campaigns.${index}.title`) || `New Event ${index + 1}`}
                                </Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <FlexboxContainer flexDirection="column" gap="8px" width="100%">
                                    {/* Render Vendors input controller */}
                                    {
                                        !selectedVendorId &&
                                        <Controller
                                            name={`campaigns.${index}.vendors`}
                                            control={control}
                                            rules={{ required: 'Cannot be blank' }}
                                            disabled={!!selectedVendorId}
                                            render={({ field }) => (
                                            <SelectComponent
                                                {...field}
                                                label="Vendor"
                                                labelPosition="top"
                                                disabled={!!selectedVendorId || editMode}
                                                options={vendorsList.map((vendor: Vendor) => ({
                                                    value: vendor.name,
                                                    id: vendor.id + '',
                                                }))}
                                                onChange={(event: any) => {
                                                    clearErrors(`campaigns.${index}.vendors`);
                                                    const selVendors = event.target.value;
                                                    const selectedItem = event.target.selected;
                                                    const formValue = getValues(`campaigns.${index}.vendors`) || [];
                                                    const vendorId = vendorsList.find((vendor: Vendor) => vendor.name === selectedItem)?.id;
                                                    const updatedVendors = selVendors.indexOf(selectedItem) > -1
                                                        ? [...formValue, Number(vendorId)]
                                                            : formValue.filter((vendor: number) => vendor !== Number(vendorId));
                                                        setValue(`campaigns.${index}.vendors`, updatedVendors);
                                                        field.onChange(updatedVendors);
                                                    }
                                                }
                                                value={
                                                        getValues(`campaigns.${index}.vendors`)?.map((vendor: number) => getVendorValue(vendorsList, vendor) as any)
                                                }
                                                defaultValue="Select Vendor"
                                                multiple
                                                displayAsterisk
                                                isInvalid={!!errors.campaigns?.[index]?.vendors}
                                                isRedLable={!!errors.campaigns?.[index]?.vendors}
                                                errroLabelPostion='bottom'
                                                errorMessage={errors.campaigns?.[index]?.vendors?.message}
                                                />
                                            )}
                                        /> 
                                    }

                                    {/* Render Title input controller */}
                                    <Controller
                                        name={`campaigns.${index}.title`}
                                        control={control}
                                        rules={{ required: 'Cannot be blank', maxLength: { value: 50, message: 'Title must not be greater than 50 characters' } }}
                                        render={({ field }) => (

                                            <InputComponent
                                                {...field}
                                                id={`campaign-title-${index}`}
                                                placeholder="Enter event title..."
                                                labelName="Campaign Title"
                                                isInvalid={!!errors.campaigns?.[index]?.title}
                                                errorMessage={errors.campaigns?.[index]?.title?.message}
                                                onChange={(e) => {
                                                    field.onChange(e); // Update the value in the form state
                                                    clearErrors(`campaigns.${index}.title`); // Clear validation error for this field
                                                }}
                                            />
                                        )}
                                    />

                                    {/* Render Country input controller */}
                                    <Controller
                                        name={`campaigns.${index}.countryId`}
                                        control={control}
                                        rules={{ required: 'Cannot be blank' }}
                                        render={({ field }) => (
                                            <SelectComponent
                                                {...field}
                                                label="Country"
                                                labelPosition="top"
                                                options={countries.map((country: Country) => ({
                                                    value: country.countryName,
                                                    id: country.id,
                                                }))}
                                                onChange={(event: any) =>{
                                                    clearErrors(`campaigns.${index}.countryId`);
                                                    field.onChange(Number(event.target.id));
                                                    setValue(`campaigns.${index}.countryId`, Number(event.target.id))
                                                }
                                                }
                                                value={getValues(`campaigns.${index}.countryId`)
                                                    ? countries.find((country: any) => country.id === getValues(`campaigns.${index}.countryId`))
                                                        ?.countryName
                                                    : ''
                                                }
                                                defaultValue="Select event country"
                                                isInvalid={!!errors.campaigns?.[index]?.countryId}
                                                displayAsterisk
                                                isRedLable={!!errors.campaigns?.[index]?.countryId}
                                                errroLabelPostion='bottom'
                                                limitTags={3}
                                                errorMessage={errors.campaigns?.[index]?.countryId?.message}
                                            />
                                        )}
                                    />

                                    {/* Render GTM id input controller */}
                                    <Controller
                                        name={`campaigns.${index}.eventId`}
                                        control={control}
                                        rules={{
                                            maxLength: { 
                                                value: 12, 
                                                message: 'GTM id must not be greater than 12 characters' 
                                            },
                                            pattern: { 
                                                value: /^GTM-[a-z0-9]+$/i, 
                                                message: 'GTM id must start with "GTM-" and have at least one alphanumeric character after it' 
                                            },
                                            validate: {
                                                // Validation to check if the GTM id is not present in the existing eventIds state
                                                uniqueInState: (value) => {
                                                    // Get the current campaign details
                                                    const campaign = getValues(`campaigns.${index}`);
                                                    
                                                    // Find the eventIds for vendors that overlap with the current campaign's vendors
                                                    const vendorEventIds = eventIds
                                                        .filter(event => event.eventId && campaign.vendors?.includes(event.vendorId) && campaign.id !== event.id) // Match vendors in the current campaign
                                                        .map(event => ({
                                                            eventId: event.eventId?.toUpperCase(),
                                                            vendorId: event.vendorId
                                                        }));
                                                
                                                    // Identify vendors using the same GTM id
                                                    const duplicateVendors = vendorEventIds
                                                        .filter(event => event.eventId === value?.toUpperCase())
                                                        .map(event => vendorsList.find(vendor => vendor.id === event.vendorId)?.name)
                                                        .filter(Boolean); // Remove undefined vendor names
                                                
                                                    // Check if the GTM id is already used and return an error message with vendor names
                                                    return (
                                                        duplicateVendors.length === 0 ||
                                                        `This GTM id is already used by vendors: ${duplicateVendors.join(', ')}`
                                                    );
                                                },
                                                
                                                uniqueInForm: (value) => {
                                                    // Get all campaign values
                                                    const formValues = getValues("campaigns").map((campaign, idx) => ({
                                                        eventId: campaign.eventId?.toUpperCase(),
                                                        index: idx + 1 // Use 1-based indexing for user-friendly error messages
                                                    }));
                                                
                                                    // Find occurrences of the current value in the form
                                                    const duplicates = formValues.filter(
                                                        campaign => campaign.eventId && campaign.eventId === value?.toUpperCase()
                                                    );
                                                
                                                    // If duplicates are found, return an error message with their indices
                                                    return (
                                                        duplicates.length <= 1 ||
                                                        `This GTM id is already used in the forms: ${duplicates.map(c => c.index).join(', ')}`
                                                    );
                                                }
                                            }
                                        }}
                                        render={({ field }) => (
                                            <InputComponent
                                                {...field}
                                                id={`campaign-gtmId-${index}`}
                                                placeholder="i.e GTM-346789"
                                                labelName="GTM id"
                                                displayAsterisk={false}
                                                choiceOptional
                                                errorMessage={errors.campaigns?.[index]?.eventId?.message}
                                                isInvalid={!!errors.campaigns?.[index]?.eventId}
                                                onChange={(e) => {
                                                    field.onChange(e); // Update the value in the form state
                                                    clearErrors(`campaigns.${index}.eventId`); // Clear validation error for this field
                                                }}
                                            />
                                        )}
                                    />


                                    {/* Render Campaign Type input controller */}
                                    <Controller
                                        name={`campaigns.${index}.campaignTypeId`}
                                        control={control}
                                        rules={{ required: 'Cannot be blank' }}
                                        render={({ field }) => (
                                            <SelectComponent
                                                {...field}
                                                label="Campaign Type"
                                                labelPosition="top"
                                                options={campaignTypes.map((ct: any) => ({
                                                    value: ct.name,
                                                    id: ct.id,
                                                }))}
                                                onChange={(event: any) => {
                                                    clearErrors(`campaigns.${index}.campaignTypeId`);
                                                    field.onChange(Number(event.target.id));
                                                    setValue(`campaigns.${index}.campaignTypeId`, Number(event.target.id))
                                                }}
                                                value={getValues(`campaigns.${index}.campaignTypeId`)
                                                    ? campaignTypes.find((ct: any) => ct.id === getValues(`campaigns.${index}.campaignTypeId`))
                                                        ?.name
                                                    : ''
                                                }
                                                defaultValue="Select campaign type"
                                                isInvalid={!!errors.campaigns?.[index]?.campaignTypeId}
                                                displayAsterisk
                                                isRedLable={!!errors.campaigns?.[index]?.campaignTypeId}
                                                errroLabelPostion='bottom'
                                                errorMessage='Cannot be blank'
                                            />
                                        )}
                                    />
                                    
                                    <FlexboxContainer flexDirection="row" gap="16px">
                                        <FlexboxContainer flexDirection="column" width="100%">
                                            <Controller
                                                name={`campaigns.${index}.startDate`}
                                                control={control}
                                                rules={{
                                                    required: 'Cannot be blank',
                                                    validate: (startDate) => {
                                                        const endDate = getValues(`campaigns.${index}.endDate`);
                                                        if (!startDate) return 'Start Date is required';
                                                        if (endDate && new Date(startDate) > new Date(endDate)) {
                                                            return 'Start date cannot be after end date';
                                                        }
                                                        return true;
                                                    },
                                                }}
                                                render={({ field }) => (
                                                    <>
                                                        <FlexboxContainer>
                                                            <LabelText error={!!errors.campaigns?.[index]?.startDate}>
                                                                Start Date
                                                            </LabelText>
                                                            <IconAsterisk>*</IconAsterisk>
                                                        </FlexboxContainer>
                                                        <DatePicker
                                                            {...field}
                                                            inputVariant="outlined"
                                                            format="yyyy MMM dd"
                                                            TextFieldComponent={DatePickerInput}
                                                            onChange={(date) => {
                                                                field.onChange(date); // Update the field's value
                                                                clearErrors(`campaigns.${index}.startDate`);
                                                                setValue(`campaigns.${index}.startDate`, date?.toString()!); // Update form state
                                                                trigger(`campaigns.${index}.startDate`); // Explicitly trigger validation
                                                            }}
                                                            autoOk
                                                            label="Start Date"
                                                            placeholder="Start Date"
                                                            invalidDateMessage="Invalid Date"
                                                            error={!!errors.campaigns?.[index]?.startDate}

                                                        />
                                                        {!!errors.campaigns?.[index]?.startDate && (
                                                            <ErrorLabel>{errors.campaigns?.[index]?.startDate?.message}</ErrorLabel>
                                                        )}
                                                    </>
                                                )}
                                            />
                                        </FlexboxContainer>


                                        <FlexboxContainer flexDirection="column" width="100%">
                                            <Controller
                                                name={`campaigns.${index}.endDate`}
                                                control={control}
                                                render={({ field }) => <>
                                                    <LabelText>End Date</LabelText>
                                                    <DatePicker
                                                        {...field}
                                                        inputVariant="outlined"
                                                        format="yyyy MMM dd"
                                                        TextFieldComponent={DatePickerInput}
                                                        onChange={(date) => {
                                                            clearErrors(`campaigns.${index}.endDate`);
                                                            setValue(`campaigns.${index}.endDate`, date?.toString()!);
                                                            trigger(`campaigns.${index}.startDate`);
                                                        }}
                                                        autoOk
                                                        label="End Date"
                                                        placeholder="End Date"
                                                    />
                                                </>}
                                            />
                                        </FlexboxContainer>
                                    </FlexboxContainer>


                                    {/* Render Launch Company input controller */}
                                    <Controller
                                        name={`campaigns.${index}.campaignLaunchCompanyName`}
                                        control={control}
                                        rules={{ required: 'Cannot be blank', maxLength: { value: 150, message: 'Launch company name must not be greater than 150 characters' } }}
                                        render={({ field }) => (

                                            <>
                                            <FlexboxContainer>  
                                                <LabelText $marginBottom={'0px'}
                                                    error={!!errors.campaigns?.[index]?.campaignLaunchCompanyName}
                                                >Launch Company</LabelText>
                                                <IconAsterisk>*</IconAsterisk>
                                            </FlexboxContainer>
                                                <AutocompleteStyled
                                                    {...field}
                                                    id="launch-companies"
                                                    label="Launch Company"
                                                    options={launchCompanies.map((lc: LaunchCompany) => ({
                                                        value: lc.companyName,
                                                        id: lc.companyName,
                                                    }))}
                                                    getOptionLabel={(option: any) => {
                                                        // Value selected with enter, right from the input
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        // Add "xxx" option created dynamically
                                                        if (option.inputValue) {
                                                            return option.inputValue;
                                                        }
                                                        // Regular option
                                                        return option.value;
                                                    }}
                                                    onChange={(event: any, newValue: any) => {
                                                        console.log(newValue);
                                                        const inputValue = newValue.value || newValue;
                                                        const launchCompany = launchCompanies.find((lc => lc.companyName?.toLowerCase() === inputValue?.toLowerCase()))
                                                        // If the input value is not in the list, add it
                                                        if (inputValue && !launchCompany && inputValue.length <= 150) {
                                                            launchCompanies.push({ companyName: inputValue, companyCode: inputValue });
                                                        }

                                                        if(!inputValue){
                                                            setValue(`campaigns.${index}.campaignLaunchCompanyName`, '');
                                                        }
                                                        // Trigger validation and update the value
                                                        clearErrors(`campaigns.${index}.campaignLaunchCompanyName`);
                                                        setValue(`campaigns.${index}.campaignLaunchCompanyName`, launchCompany ? launchCompany.companyName : inputValue);
                                                        trigger(`campaigns.${index}.campaignLaunchCompanyName`);
                                                       
                                                        // setValue(`campaigns.${index}.campaignLaunchCompanyName`, newValue?.inputValue || newValue?.value || '');
                                                        // trigger(`campaigns.${index}.campaignLaunchCompanyName`);
                                                    }}
                                                    onBlur={(e: any) => {
                                                        const value = e.target.value;
                                                        if(!value){
                                                            setValue(`campaigns.${index}.campaignLaunchCompanyName`, '');
                                                            trigger(`campaigns.${index}.campaignLaunchCompanyName`);
                                                        }else{
                                                            setValue(`campaigns.${index}.campaignLaunchCompanyName`, value);
                                                        }
                                                    }}
                                                    onKeyDown={(e: any) => {
                                                        clearErrors(`campaigns.${index}.campaignLaunchCompanyName`);
                                                    }}
                                                    value={getValues(`campaigns.${index}.campaignLaunchCompanyName`)}
                                                    filterOptions={(options: any, params: any) => {
                                                        let filtered = filter(options, params);
                                                        
                                                        filtered = filtered.filter((x: any) => x?.value?.toLowerCase() !== params?.inputValue?.toLowerCase());

                                                        // Suggest the creation of a new value
                                                        if (params.inputValue !== '') {
                                                            filtered.push({
                                                                inputValue: params.inputValue,
                                                                value: `${params.inputValue}`,
                                                            });
                                                        }

                                                        return filtered;
                                                    }}
                                                    disableClearable={true}
                                                    renderInput={AutoCompleteInput}
                                                    renderOption={(option: any) => option.value}
                                                    selectOnFocus
                                                    freeSolo
                                                    error={!!errors.campaigns?.[index]?.campaignLaunchCompanyName}
                                                />
                                                 {!!errors.campaigns?.[index]?.campaignLaunchCompanyName && <ErrorLabel $marginTop='-5px'>{errors.campaigns?.[index]?.campaignLaunchCompanyName?.message}</ErrorLabel>}
                                            </>

                                        )}
                                    />

                                    {/* Render Launch Person input controller */}
                                    <Controller
                                        name={`campaigns.${index}.launchPerson`}
                                        control={control}
                                        rules={{ maxLength: { value: 150, message: 'Launch person name must not be greater than 150 characters' } }}
                                        render={({ field }) => (
                                            <InputComponent
                                                {...field}
                                                id={`campaign-launchPerson-${index}`}
                                                placeholder="Enter launch person"
                                                labelName="Launch Person"
                                                displayAsterisk={false}
                                                choiceOptional
                                                errorMessage={errors.campaigns?.[index]?.launchPerson?.message}
                                                isInvalid={!!errors.campaigns?.[index]?.launchPerson}
                                                onChange={(e) => {
                                                    field.onChange(e); // Update the value in the form state
                                                    clearErrors(`campaigns.${index}.launchPerson`); // Clear validation error for this field
                                                }}
                                            />
                                        )}
                                    />

                                    {/* Render Budget and Actual Spend input controller */}
                                    <FlexboxContainer flexDirection="row" gap="20px" alignItems='flex-start' margin='5px 0px'>
                                        <Controller
                                            name={`campaigns.${index}.budget`}
                                            control={control}
                                            rules={{ required: 'Cannot be blank', min: { value: 1, message: 'Budget must be greater than 0' }, maxLength: { value: 15, message: 'Budget must not be greater than 15 characters' } }}
                                            render={({ field }) => (
                                                <InputComponent
                                                    {...field}
                                                    id={`budget-${index}`}
                                                    type="number"
                                                    placeholder="Enter budget"
                                                    labelName="Budget"
                                                    isInvalid={!!errors.campaigns?.[index]?.budget}
                                                    errorMessage={errors.campaigns?.[index]?.budget?.message}
                                                    onChange={(e) => {
                                                        field.onChange(e); // Update the value in the form state
                                                        clearErrors(`campaigns.${index}.budget`); // Clear validation error for this field
                                                    }}
                                                />
                                            )}
                                        />
                                        
                                        <Controller
                                            name={`campaigns.${index}.actualSpend`}
                                            control={control}
                                            rules={{
                                                validate: (value) => {
                                                    const endDate = watch(`campaigns.${index}.endDate`);
                                                    return endDate && !value 
                                                        ? 'Actual spend is required when end date is provided' 
                                                        : true;
                                                },
                                                maxLength: { value: 15, message: 'Actual spend must not be greater than 15 characters' }
                                            }}
                                            render={({ field }) => (
                                                <InputComponent
                                                    {...field}
                                                    id={`actual-spend-${index}`}
                                                    type="number"
                                                    placeholder="Enter spend"
                                                    labelName="Actual Spend"
                                                    choiceOptional={!watch(`campaigns.${index}.endDate`)}
                                                    displayAsterisk={!!watch(`campaigns.${index}.endDate`)}
                                                    isInvalid={!!errors.campaigns?.[index]?.actualSpend}
                                                    errorMessage={errors.campaigns?.[index]?.actualSpend?.message}
                                                    onChange={(e) => {
                                                        field.onChange(e); // Update the value in the form state
                                                        clearErrors(`campaigns.${index}.actualSpend`); // Clear validation error for this field
                                                    }}
                                                />
                                            )}
                                        />

                                    </FlexboxContainer>

                                </FlexboxContainer>
                            </AccordionDetails>
                        </Accordion>
                    ))}

                </div>

                {/* Add More Events Button */}
                {
                !campaign.id && <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                        onClick={handleAppendForm}
                        borderColor={Main.white}
                        color={Main.brandDark}
                        background={Main.white}
                        type='button'
                        disabled={!isValid}
                    >
                        <AddIcon /> Add more events
                    </Button>
                </div>
                }

                <FlexboxContainerComponent justifyContent="right">
                    <CancelButton key="cancel" onClick={onClose}>
                        Cancel
                    </CancelButton>
                    <SaveButton key="save" type='submit' onClick={submitWrapper}>
                        Save
                    </SaveButton>
                </FlexboxContainerComponent>
            </>
        
    );
};

export default React.memo(CampaignForm);



const AutoCompleteInput = (params: JSX.IntrinsicAttributes & TextFieldProps) => {
    return <TextField
        {...params}
        variant='outlined'
        inputProps={{
            ...params.inputProps,
            style: {
                padding: 0,
            },
            placeholder: 'Enter launch company',

        }}
    />
};

