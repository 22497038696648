import { of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { apiCallFailed, apiCallFinished } from 'redux/actions/epic';
import apiConfig from 'redux/epics/apiConfig';
import { API_CALL_STARTED } from 'redux/constants/actionTypes';
import { State } from 'redux/types/state';
import { ApiCallStarted } from 'redux/types/actions';
import { StateKey, ApiConfig } from 'redux/types/epics';

interface RetryStrategy {
  maxRetryAttempts: number;
  scalingDuration: number;
  excludedStatusCodes: number[];
  stateKey: StateKey
};

export const callApiInternal = (stateKey: StateKey, request: any) => {
  const config: ApiConfig = apiConfig[stateKey];
  const headers = config.headers || {};
  let { url } = config;

  if (config.buildUrl) {
    url = config.buildUrl(request);
  }

  if (config.method === 'post') {
    headers['Content-Type'] = 'application/json';
  }

  return ajax({
    url,
    method: config.method,
    ...(config.method === 'post' && { body: request })
  });
};

export const apiEpic: Epic<ApiCallStarted, any, State, any> = (
  action$,
  store,
  callApi = callApiInternal
) => {
  return action$.pipe(
    ofType(API_CALL_STARTED),
    mergeMap((action) => {
      return callApi(action.stateKey, action.request).pipe(
        map((payload: any) => {
          return apiCallFinished(
            action.stateKey,
            payload.response || payload
          );
        }),
        catchError((err) => {
          console.error('API call error: ', err);
          return of(
            apiCallFailed(action.stateKey, err.response || err)
          );
        })
      );
    })
  );
};
