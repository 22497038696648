import React, { useEffect, useState } from 'react';
import InviteForm from 'admin/InviteForm';
import Modal from 'components/UI/Modal';
import Vendor from 'data/Vendor';
import { useQueryParams } from 'hookrouter';
import Tabs from 'components/UI/Tabs';
import { INVITE_TABS } from 'constants/tabs';

interface Props {
  onClose: () => void;
  vendors?: Vendor[];
  showModal: boolean
}

export default function InviteModal({ onClose, vendors, showModal }: Props) {
  const [queryParams, setQueryParams] = useQueryParams();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    setActiveTab(parseInt(queryParams.activeTab));
    setQueryParams({ activeTab: undefined });
  }, []);

  let role: 'Vendor' | 'Admin' | 'None';

  let buttonContainer = null;
  if (vendors) {
    buttonContainer = (
      <Tabs tabs={INVITE_TABS} activeTab={activeTab} padding='20px'>
        <InviteForm
          onFinish={onClose}
          role='Vendor'
          vendors={vendors}
        />
        <InviteForm
          onFinish={onClose}
          role='Admin'
          vendors={vendors}
        />
      </Tabs>
    );
  }

  if (!vendors) {
    role = 'None';
  }

  return (
    <Modal onClose={onClose} showCloseIcon showModal={showModal} title={vendors ? 'Invite' : 'Create'} showButtons={false} width="600px" top='10%'>
      {buttonContainer}
    </Modal>
  );
};
