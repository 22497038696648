import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import Icon from 'view/Icon';
import { Color, Size } from 'lib/styles';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Input from 'components/UI/Input';
import { Main, Background } from 'styles/themeColors';

const DragHandleIcon = styled(Icon).attrs({ name: 'DragHandle', size: 18 })`
  color: ${Color.shade};
`;

const DragHandleIconWrapper = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  padding: 15px;

  &:hover{
    cursor: grab;
  }

  &:active{
    cursor: grabbing;
  }
`;

const Checkbox = styled.input`
  width: 18px;
  height: 18px;

  &:hover{
    background-color: green !important;
    cursor: pointer;
  }
`;

const Index = styled.span`
  font-size: 20px;
  font-weight: bold;
  width: 25px
`;

const InputContainer = styled.div`
  display: flex;
  padding: 12px;
  width: 100%;

  &:active{
    background-color: ${Color.whiteSmoke};
  }
`;

const EditIcon = styled(Icon).attrs({ size: 20 })`
  color: ${Main.accent};

  ${props => props.name === 'edit' && css`
    &:hover{
      color: ${Main.warning};
    }
  `}

  ${props => props.name === 'check' && css`
    color: ${Main.success};

    &:hover{
      color: ${Main.success};
      font-size: ${Size.medium};
    }
  `}

  ${props => props.name === 'x' && css`
    color: red;

    &:hover{
      color: ${Main.error};
      font-size: ${Size.medium};
    }
  `}
`;

const EditButton = styled.button<any>`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: ${Size.medium};
  margin-top: -0.1em;
  padding: 0;
`;

interface Props {
  draggableId: string;
  index: number;
  value: string;
  changeValue: (index: number, e: string) => void;
  merging: boolean;
  setChecked: (valueId: number) => void;
  inputBorder?: string;
  updateAttributeValue: (index: number, name: string) => void
};

export default function DraggableRow({
  draggableId,
  index,
  value,
  changeValue,
  merging,
  setChecked,
  inputBorder,
  updateAttributeValue
}: Props) {
  const [disabled, setDisabled] = useState(true)
  const [placeholder, setPlaceholder] = useState('')

  if (disabled && value === '') {
    toggleDisabled();
  }
  function toggleDisabled() {

    if (disabled) {
      setTimeout(function () { document.getElementById(index.toString())!.focus(); });
      setPlaceholder('you must insert value');
    }
    if (!((disabled && value !== '') || (!disabled && value === ''))) {
      updateAttributeValue(index, value);
    }
    setDisabled(!disabled);
  }

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <FlexboxContainer bgColor={Background.white} border="1px solid silver">
            <FlexboxContainer borderRight="1px solid lightgrey" margin="0 auto" alignItems="center" padding="7px 10px">
              {merging
                ? <Checkbox type="checkbox" onChange={() => setChecked(index)} />
                : null
              }
              <DragHandleIconWrapper>
                <DragHandleIcon />
              </DragHandleIconWrapper>
              <Index>
                {index + 1}
              </Index>
            </FlexboxContainer>
            <InputContainer>
              <Input
                id={index.toString()}
                onInputChange={e => changeValue(index, e)}
                disabled={disabled}
                width="100%"
                type="text"
                placeholder={placeholder}
                value={value}
              />
              <EditButton disabled={value === '' ? true : false} onClick={toggleDisabled}>
                <EditIcon name={disabled && value !== '' ? 'edit' : !disabled && value === '' ? 'x' : 'check'} />
              </EditButton>
            </InputContainer>
          </FlexboxContainer>
        </div>
      )}
    </Draggable>
  );
};
