import FeatureFlag from 'data/FeatureFlag';
import User, { Role } from 'data/User';
import Vendor from 'data/Vendor';

export const enablePricebooks = (
  flags: FeatureFlag, 
  value: string = ''
) => {

  return (value === 'Pricebooks')
    ? flags.pricebooks && Boolean(flags.pricebooks.enabled)
    : true;
};

export const checkUserPricebookDeleteNowOption = (user: User | null, selectedVendor: Vendor | undefined) => {
  return (user?.role === Role.Admin || user?.role === Role.SuperAdmin)  && !selectedVendor;
}