import Count from 'view/Count';
import React from 'react';

interface Props {
  className?: string;
  label: string;
  loading?: boolean;
  page: number;
  size: number;
  total: number;
}

export default function PagedCount({
  className,
  label,
  loading,
  page,
  size,
  total
}: Props) {
  let count = '';
  if (!loading) {
    if (total > 1) {
      const start = (page - 1) * size + 1;
      const end = Math.min(start + size - 1, total);
      count = `${start} – ${end}`;
    } else {
      count = `${total}`;
    }
  }

  return (
    <Count
      className={className}
      count={count}
      total={total}
      label={label}
      loading={loading}
    />
  );
}

PagedCount.defaultProps = {
  loading: false
};
