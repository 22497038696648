import React from 'react';

import { HorizontalRule, Card, Heading1, Heading4, Paragraph, DownloadLink } from 'OnboardingComponents/Elements';

import { ProductDescription, ProductImagery, ProductNaming, SupportingDocuments, ToneOfVoice } from './sections';

const ProductGuidelines = () => {
  return (
    <Card>
      <Heading1>Product guidelines</Heading1>
      <Heading4>A collection of proven suggestions for how to effectively present your products on OFMP</Heading4>
      <Paragraph>Your brand’s unique qualities should be conveyed throughout every channel you use to interact with your stakeholders – and The OFMP is no exception. Let’s explore some of the main things to consider when showcasing your brand(s) on The Oilfield Marketplace and, more specifically, when filling in the details of your product display page(s). After all, this is your branded real estate on the platform and its contents should all work towards making you your next sale.</Paragraph>
      <DownloadLink href="/resources/OFMP_ProductGuidelines_Nov2019.pdf" target="_blank">Download the product guidelines PDF</DownloadLink>
      <HorizontalRule />

      <ToneOfVoice />
      <HorizontalRule />

      <ProductNaming />
      <HorizontalRule />

      <ProductDescription />
      <HorizontalRule />

      <ProductImagery />
      <HorizontalRule />

      <SupportingDocuments />

    </Card>
  );
};

export default ProductGuidelines;
