import React, { useEffect, useRef, forwardRef } from 'react';
import MaterialTable, { Column, Query } from 'material-table';
import { MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import Pagination from '../UI/Pagination';
import { defaultPaginationSelectOptions } from '../../constants/paginationOptions';
import { useAuth } from '../../lib/Auth';
import { CampaignListFilter } from './CampaignListFilter';
import KeyboardArrowDownOutlined from '@material-ui/icons/KeyboardArrowDownOutlined';
import { getAllCampaigns } from '../../requests/campaigns';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { getItems } from 'lib/SessionStorage';
import { CampaignContext } from 'context/campaign';

const CustomPaginationComponent = (props: any) => {
  const {
    page,
    rowsPerPage,
    count,
    onChangePage,
    onChangeRowsPerPage,
  } = props;
 
  const totalPages = Math.ceil(count / rowsPerPage);

  return (
    <td style={{ display: 'block' }}>
      <Pagination
        itemsPerPage={rowsPerPage}
        currentPage={page + 1}
        totalPages={totalPages > 0 ? totalPages : 1}
        shouldRedirect={false}
        selectorOptions={defaultPaginationSelectOptions}
        setItemsPerPage={(newRowsPerPage: number) => {
          onChangeRowsPerPage({ target: { value: newRowsPerPage } }); // Update rows per page
        }}
        setCurrentPage={(newPage: number) => {
          onChangePage(newPage);
        }}
        baseRoute="marketing"
      />
    </td>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#F7991C',
    },
    secondary: {
      main: '#F7991C',
    },
  },
});

const StyledButton = styled.button<any>`
  background: none;
  border: none;
  color: #FC780B;
  cursor: pointer;
  padding: 0;
  font-size: inherit;
  margin: 0 5px;
`;


const StyledTable = styled.div`
  span {
    &:focus {
      color: black !important;
    }
  }
`;


interface Props {
  selectedVendorId?: number;
  filters: CampaignListFilter;
  searchTerm: string;
  campaignColumns: Column<any>[];
  setShowDeleteModal?: Function;
  setShowPublishModal?: Function;
  setSelectedRows?: Function;
  selectedRows: any[];
  reload?: boolean;
  setReload?: Function;
  setLoading?: Function;
  handleEdit?: Function;
  handleDelete?: Function;
}
interface TableRef {
  onQueryChange: () => void;
}

const CampaignListTable = ({
  selectedVendorId, filters, searchTerm, campaignColumns, setSelectedRows, reload, setReload, setLoading, handleEdit, handleDelete
}: Props) => {

  const {  updateStats,setStatsLoading } = React.useContext(CampaignContext); // Consume context

  const tableRef = React.useRef<TableRef>(null);
  const printTableRef = React.useRef<TableRef>(null);
  const { token } = useAuth();
  const [columns, setColumns] = React.useState(campaignColumns);

  const [tablesData, setTablesData] = React.useState<any>({
    data: [],
    page: 1,
  });
  
  const [conditions, setConditions] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [page, setPage] = React.useState(0);

  // Ref to track if component is mounted
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const actionColumn = {
      render: (rowData: any) => {
        return <FlexboxContainerComponent>
          <StyledButton onClick={() => handleEdit?.(rowData?.id)}>{'Edit'}</StyledButton>
          <StyledButton onClick={() => handleDelete?.(rowData?.id) }>{'Remove'}</StyledButton>
        </FlexboxContainerComponent>
      }
    };


    const updatedColumns = columns.map((col) => {
      if (col.field === 'actions') {
        return { ...col, ...actionColumn };
      }


      const column = campaignColumns.find((column) => column.field === col.field);
      if (column) {
        return {
          ...col,
          hidden: column.hidden,
        };
      } else {
        return col;
      }
    });
    setColumns([...updatedColumns]);
  }, [campaignColumns]);

  useEffect(() => {
    setTablesData((prevData: any) => ({
      ...prevData,
      page: 0,
    }));

    if (tableRef.current) {
      if (typeof tableRef.current.onQueryChange === 'function') {
        tableRef.current.onQueryChange();
      } else {
        console.error("onQueryChange method does not exist on tableRef.current");
      }
    }
  }, [filters, selectedVendorId, searchTerm, reload])

  const handleQuery = (query: Query<any>): string => {
    const countries = getItems('countries');

    setTablesData((prevData: any) => ({
      ...prevData,
      page: query.page,
    }));

    let params = `page=${tablesData.page + 1}&pageSize=${query.pageSize}`;

    if (filters) {

      if (filters.campaignTypes && filters.campaignTypes.length > 0) {
        params += `&campaignTypeIds=${filters.campaignTypes.map((ct) => ct.id).join(',')}`;
      }
      if (filters.launchCompanies && filters.launchCompanies.length > 0) {
        params += `&launchCompanyIds=${filters.launchCompanies.map((lc) => lc.id).join(',')}`;
      }
      if (filters.vendor && filters.vendor.length > 0 && !selectedVendorId) {
        params += `&vendorIds=${filters.vendor.map((v) => v.id).join(',')}`;
      }
      if (filters.country.id) {
        const countryId = countries.find((c:any) => c.countryIso === filters.country.id)?.id;
        params += `&countryId=${countryId}`;
      }
      if (filters.startDate) {
        params += `&startDate=${new Date(filters.startDate).toISOString()}`;
      }
      if (filters.endDate) {
        params += `&endDate=${new Date(filters.endDate).toISOString()}`;
      }
    }

    if (selectedVendorId) {
      
      params += `&vendorIds=${selectedVendorId}`;
    }

    if (searchTerm && searchTerm.trim() !== '') {
      params += `&search=${searchTerm}`;
    }

    if (query.orderBy) {
      params += `&sortField=${String(query.orderBy.field)}&sortOrder=${query.orderDirection}`;
    }

    const updatedColumns = columns.map((column) => {
      if (query.orderBy && column.field === query.orderBy.field) {
        return {
          ...column,
          defaultSort: query.orderDirection,
        };
      } else {
        return {
          ...column,
          defaultSort: undefined
        };
      }
    });
    setColumns(updatedColumns);

    return params;
  }

  const handleRowsPerPageChange = (newPageSize: number) => {
    const totalPages = Math.ceil(tablesData.totalCount / newPageSize);
    const lastPage = Math.min(tablesData.page, totalPages - 1);
  
    setRowsPerPage(newPageSize);
    setTablesData((prevData: any) => ({
      ...prevData,
      page: lastPage,
    }));
  
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange();
    }
  };

  const handlePageChange = (newPage: number) => {
    const totalPages = Math.ceil(tablesData.totalCount / rowsPerPage);
    const lastPage = Math.max(totalPages - 1, 0);
    const validPage = Math.min(newPage - 1, lastPage);
  
    setPage(validPage);
    setTablesData((prevData: any) => ({
      ...prevData,
      page: validPage,
    }));
  
    if (tableRef.current?.onQueryChange) {
      tableRef.current.onQueryChange();
    }
  };

  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <StyledTable>
          <MaterialTable
            tableRef={tableRef}
            columns={columns}
            icons={
              {
                SortArrow: forwardRef((props, ref) => <KeyboardArrowDownOutlined {...props} ref={ref} />),
              }
            }
            data={(query) =>
              new Promise(async (resolve, reject) => {
                query.pageSize = rowsPerPage;
                query.page = page;
                
                // Construct the query string
                const myQuery = handleQuery(query);
                const isQueryChanged = conditions !== myQuery;
            
                // Only update conditions and fetch data if the query actually changes
                if (isQueryChanged || reload) {
                  setConditions(myQuery);
            
                  try {
                    setLoading?.(true);
                    setStatsLoading?.(true);
            
                    // Fetch data
                    const result = await getAllCampaigns?.(myQuery, token);
            
                    if (isMounted.current) {
                      // Update table data state
                      const tableData = {
                        data: result.data,
                        page: result.meta.currentPage - 1,
                        totalCount: result.meta.totalRecords,
                      };
                      setTablesData(tableData);
                      updateStats?.(result.statistics);
                      setReload?.(false);
                      resolve(tableData);

                      if (printTableRef.current) {
                        if (typeof printTableRef.current.onQueryChange === 'function') {
                          printTableRef.current.onQueryChange();
                        } else {
                          console.error("onQueryChange method does not exist on printTableRef.current");
                        }
                      }
                    }
                  } catch (error) {
                    if (isMounted.current) {
                      console.error('API error:', error);
                      reject(error);
                    }
                  } finally {
                    setLoading?.(false);
                    setStatsLoading?.(false);
                  }
                } else {
                  // Resolve with existing data if the query hasn’t changed
                  resolve(tablesData);
                }
              })
            }
            options={{
              sorting: true,
              draggable: false,
              showTitle: false,
              search: false,
              showTextRowsSelected: false,
              selection: true,
              toolbar: false,
              pageSize: rowsPerPage,
              tableLayout: 'fixed',
              maxBodyHeight: 'calc(100vh - 200px)',
              emptyRowsWhenPaging: false,
              headerStyle: {
                color: '#FFF',
                fontSize: '16px',
                height: 50,
                padding: '0px'
              },
              rowStyle: {
                fontSize: '14px',
                wordWrap: 'break-word',
              },
            }}
            components={{
              Pagination: (props) => {
                return <CustomPaginationComponent {...props} 
                  onChangeRowsPerPage={({ target }: any) =>
                    handleRowsPerPageChange(parseInt(target.value, 10))
                  }
                  onChangePage={(newPage: any) =>
                    handlePageChange(newPage)
                  }
                />;
              },
            }}
            onSelectionChange={(rows) => {
              setSelectedRows?.(rows);
            }}
          />
        </StyledTable>
      </MuiThemeProvider>


      {/* Mock table for print purpose */}
      <div id="marketing-campaign-table" style={{ display: 'none' }}>
        <MuiThemeProvider theme={theme}>
          <MaterialTable
          tableRef={printTableRef}
          columns={columns.filter(x => x.title !== 'Actions')}
          data={(query) => 
            new Promise(async (resolve, reject) => {
              resolve(tablesData);
            })
          }
          options={{
            sorting: false,
            draggable: false,
            showTitle: false,
            search: false,
            showTextRowsSelected: false,
            toolbar: false,
            pageSize: tablesData.data.length,
            tableLayout: 'fixed',
            headerStyle: {
              color: '#FFF',
              fontSize: '14px',
              height: 50,
              padding: '0px'
            },
            rowStyle: {
              fontSize: '12px',
              wordWrap: 'break-word',
            },
          }}
          components={{
            Pagination: (props) => {
              return null;
            },
          }}
        />
        </MuiThemeProvider>
      </div>
    </div>
  );
};

export default React.memo(CampaignListTable);
