import React, { useEffect, useState } from 'react';
import { navigate, useControlledInterceptor } from 'hookrouter';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Page from 'components/UI/Page';
import styled from 'styled-components';
import { Text, Border, Background } from 'styles/themeColors';
import Vendor from 'data/Vendor';
import { useAPI } from 'lib/API';
import { loadPricebooks, updatePricebooks } from 'requests/pricebook';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import Input from 'components/UI/Input';
import companyPricebookColumns from 'constants/companyPricebookColumns';
import Icon from 'view/Icon';
import { Clear } from '@material-ui/icons';
import Button from 'components/UI/Button';
import { formatDateTime } from 'utils/formatters';
import MuiDateRangePicker from 'components/UI/DateRangePicker';
import { DateRange } from "materialui-daterange-picker";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { update, addOrReplace, remove } from 'lib/Array';
import Modal from 'components/UI/Modal';
import { Space } from 'styles/themeSpaces';
import { Height } from 'styles/themeHeights';
import { FontSize } from 'styles/themeSizes';
import { GLOBAL_PRICEBOOK, COUNTRY_PRICEBOOK, WAREHOUSE_PRICEBOOK } from 'constants/pricebookTypes';

const StyledPricebookIcon = styled(Icon).attrs({ name: 'PricebookIcon', size: 20 })`
  color: ${Text.placeholder} !important;
`;

const Label = styled.label`
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  padding-top: 10px;
  font-color: ${Text.placeholder};
`;

const LabelText = styled.label`
  font-size: 16px;
  line-height: 20px;
  text-align: left;
  padding-top: 10px;
  padding-left: 10px;
  font-color: ${Text.placeholder};
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

const DescriptionText = styled.p`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 735px;
  height: 20px;
  font-color: ${Text.secondary};
  font-style: italic;
`;

const DateRangeContainer = styled(FlexboxContainer)`
  .materialui-daterange-picker-makeStyles-dateRangePickerContainer-1 {
    position: absolute;
  }
`;

const HeaderTitle = styled.div`
  padding: ${Space.spHalf} ${Space.spBase} ${Space.spHalf} ${Space.sp2x};
  text-align: left;
  background-color: ${Background.shaded};
  color: ${Text.primary};
  cursor: pointer;
  font-weight: bold;
  font-size: ${FontSize.medium};
  line-height: 20px;
  border-right: 1px solid ${Border.main};
  min-height: ${Height.l};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Row = styled.div`
  transition: all 300ms ease 0s;
  padding: ${Space.sp2x} ${Space.spBase} ${Space.sp2x} ${Space.sp2x};
  border-bottom: 1px solid ${Border.light};
  border-top: 1px solid ${Border.light};
  font-size: ${FontSize.medium};
  line-height: 20px;
  background-color: ${Background.white};
  vertical-align: top;
  cursor: pointer;
  display: flex;
  flex-direction: row;

  &:nth-child(2n) {
    background-color: ${Background.light};
  }
`;

const Cell = styled.div``;

interface Props {
  companyId: string;
  selectedVendor?: Vendor;
};

const PricebooksTabOld = ({
  selectedVendor,
  companyId
}: Props) => {
  const [nextPath, confirmNavigation, resetPath, stopInterception] = useControlledInterceptor();
  const [companyPricebooks, setCompanyPricebooks] = useState<any[]>([]);
  const [showClearDataModal, setShowClearDataModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [openIndex, setOpenIndex] = useState(-1);
  const [updatedPricebooks, setUpdatedPricebooks] = useState<any[]>([]);
  const [invalidIndexes, setInvalidIndexes] = useState<any[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const loadWarehousesWithPricebooksReq = useAPI({
    deferFn: loadPricebooks,
    onResolve: result => {
      setCompanyPricebooks(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const updatePricebookReq = useAPI({
    deferFn: updatePricebooks,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Pricebooks updated successfully.');
      setUpdatedPricebooks([]);
      loadWarehousesWithPricebooksReq.reload();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    loadWarehousesWithPricebooksReq.run(companyId, selectedVendor && selectedVendor.id);
  }, []);

  const toggleOpen = () => {
    const newIndex = openIndex ? -1 : openIndex - 1;
    setOpenIndex(newIndex);
  };

  const onClearData = (index: number) => {
    setSelectedIndex(index);
    setShowClearDataModal(true);
  };

  const pricebooksUpdate = (arr: any[], index: number, shouldDelete = false) => {
    const identify = arr[index].priceBookType === GLOBAL_PRICEBOOK
      ? (obj: any) => obj.priceBookType
      : arr[index].pricebook.priceBookType === COUNTRY_PRICEBOOK
        ? (obj: any) => obj.countryName
        : (obj: any) => obj.code;

    const checkIfExist = invalidIndexes.includes(index);

    if (shouldDelete) {
      const newInvalidIndexes = remove(invalidIndexes, index);
      setInvalidIndexes(newInvalidIndexes);
    } else if (!checkIfExist) {
      setInvalidIndexes([...invalidIndexes, index]);
    }

    const newPricebooks = addOrReplace(
      updatedPricebooks,
      identify,
      arr[index]
    );

    setUpdatedPricebooks(newPricebooks);
  };

  const onDeleteClick = () => {
    const updatedPricebooks = update(
      companyPricebooks,
      selectedIndex,
      companyPricebook => {
        if (companyPricebook.pricebook) {
          return {
            ...companyPricebook,
            pricebook: {
              ...companyPricebook.pricebook,
              active: false
            }
          };
        } else {
          return {
            ...companyPricebook,
            active: false
          };
        }
      }
    );

    setCompanyPricebooks(updatedPricebooks);
    setShowClearDataModal(false);
    pricebooksUpdate(updatedPricebooks, selectedIndex, true);
  };

  const onPriceBookNameChange = (priceBookName: string, index: number) => {
    const updatedPricebooks = update(
      companyPricebooks,
      index,
      companyPricebook => {
        if (companyPricebook.pricebook) {
          return { ...companyPricebook, pricebook: { ...companyPricebook.pricebook, priceBookName } };
        } else {
          return { ...companyPricebook, priceBookName };
        }
      }
    );

    setCompanyPricebooks(updatedPricebooks);
    pricebooksUpdate(updatedPricebooks, index);
  };

  const onPriceBookIdChange = (priceBookId: string, index: number) => {
    const updatedPricebooks = update(
      companyPricebooks,
      index,
      companyPricebook => {
        if (companyPricebook.pricebook) {
          return { ...companyPricebook, pricebook: { ...companyPricebook.pricebook, priceBookId } };
        } else {
          return { ...companyPricebook, priceBookId }
        }
      }
    );

    setCompanyPricebooks(updatedPricebooks);
    pricebooksUpdate(updatedPricebooks, index);
  };

  const onDateRangeChange = (dateRange: DateRange, index: number) => {
    const updatedPricebooks = update(
      companyPricebooks,
      index,
      companyPricebook => {
        if (companyPricebook.pricebook) {
          return {
            ...companyPricebook,
            pricebook: {
              ...companyPricebook.pricebook,
              validFrom: dateRange.startDate,
              validTo: dateRange.endDate
            }
          };
        } else {
          return {
            ...companyPricebook,
            validFrom: dateRange.startDate,
            validTo: dateRange.endDate
          };
        }
      }
    );

    setCompanyPricebooks(updatedPricebooks);
    pricebooksUpdate(updatedPricebooks, index);
  };

  const onDateChangeClick = (index: number) => {
    setOpenIndex(index)
    setIsInvalid(false);
  }

  const clearDataModal = (
    <Modal
      onClose={() => setShowClearDataModal(false)}
      onClick={() => onDeleteClick()}
      showModal={showClearDataModal}
      title="Clear line settings?"
      primaryButtonText="Clear"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          This can not be undone.
        </Span>
      </FlexboxContainer>
    </Modal>
  );

  const onCancelModalClick = () => {
    setShowCancelModal(false);
    confirmNavigation();
  };

  const onConfirmModalClick = () => {
    resetPath();
    setShowCancelModal(false);
  };

  const onCancelClick = () => {
    setShowCancelModal(true);
    stopInterception();
    navigate('/companies');
  };

  const getDisplayDateRange = (startDate: any, endDate: any) => {
    return `${formatDateTime(startDate)} - ${formatDateTime(endDate)}`;
  };

  const cancelModal = (
    <Modal
      onClose={onCancelModalClick}
      onClick={onConfirmModalClick}
      showModal={!!(nextPath || showCancelModal)}
      title="You’re leaving the page"
      primaryButtonText="Stay on the page"
      ghostButtonText="Leave"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          Unsaved changes will be lost.
        </Span>
      </FlexboxContainer>
    </Modal>
  );

  const onConfirmClick = () => {
    if (selectedVendor && selectedVendor.id) {
      if (updatedPricebooks.length > 0) {
        const isInvalid = updatedPricebooks.some(updatedPricebook => {
          const currentPricebook = updatedPricebook.pricebook
            ? updatedPricebook.pricebook
            : updatedPricebook;

          return Object.values(currentPricebook).some((value: any) => value === '')
        });

        if (isInvalid) {
          setIsDirty(true);
          setIsInvalid(true);
        } else {
          updatePricebookReq.run(companyId, selectedVendor && selectedVendor.id, updatedPricebooks);
        }
      }
    } else {
      renderToast(toast.TYPE.ERROR, 'You must select a vendor.');
    }
  };

  const header = (
    <FlexboxContainer>
      {companyPricebookColumns.map((companyColumn, index) => {
        return (
          <HeaderTitle key={index} style={{ width: companyColumn.width }}>{companyColumn.title}</HeaderTitle>
        )
      })}
    </FlexboxContainer>
  );

  const table = (
    <FlexboxContainer flexDirection="column">
      {companyPricebooks.map((companyPricebook, index) => {
        const pricebook = companyPricebook.pricebook ? companyPricebook.pricebook : companyPricebook;
        const padding = pricebook.priceBookType === WAREHOUSE_PRICEBOOK
          ? '0 0 0 10%'
          : '';
        const text = pricebook.priceBookType === GLOBAL_PRICEBOOK
          ? 'Global'
          : companyPricebook.countryName
            ? companyPricebook.countryName
            : companyPricebook.name;
        const fieldWidth = pricebook.priceBookType === GLOBAL_PRICEBOOK
          ? '100%'
          : pricebook.priceBookType === COUNTRY_PRICEBOOK
            ? '85%'
            : '75%';
        const startDate = pricebook.validFrom;
        const endDate = pricebook.validTo;
        const value = getDisplayDateRange(startDate, endDate);

        return (
          <Row key={index}>
            <Cell style={{ width: companyPricebookColumns[0].width }}>
              <FlexboxContainer padding={padding}>
                {
                  pricebook.priceBookType !== GLOBAL_PRICEBOOK
                    ? (
                      <>
                        <StyledPricebookIcon />
                        <Label>if</Label>
                      </>
                    )
                    : null
                }
                <LabelText>{text}</LabelText>
              </FlexboxContainer>
            </Cell>
            <Cell style={{ width: companyPricebookColumns[1].width }}>
              <FlexboxContainer displayFlex='flex' justifyContent='flex-end'>
                <Input
                  id={`priceBookName-${index}`}
                  dirty={isDirty}
                  isInvalid={!pricebook.priceBookName && invalidIndexes.includes(index) && isInvalid}
                  value={pricebook.active ? pricebook.priceBookName : ''}
                  width={fieldWidth}
                  placeholder=""
                  onChange={(e) => onPriceBookNameChange(e.target.value, index)}
                />
              </FlexboxContainer>
            </Cell>
            <Cell style={{ width: companyPricebookColumns[2].width }}>
              <Input
                id={`priceBookId-${index}`}
                dirty={isDirty}
                isInvalid={!pricebook.priceBookId && invalidIndexes.includes(index) && isInvalid}
                value={pricebook.active ? pricebook.priceBookId : ''}
                placeholder=""
                onChange={(e) => onPriceBookIdChange(e.target.value, index)}
              />
            </Cell>
            <Cell style={{ width: companyPricebookColumns[3].width }}>
              <DateRangeContainer>
                <Input
                  id="datePickerId"
                  value={pricebook.active ? value : ''}
                  isInvalid={(!value || value === ' - ') && invalidIndexes.includes(index) && isInvalid}
                  dirty={isDirty}
                  onClick={() => onDateChangeClick(index)}
                  placeholder=""
                  endIcon={<DateRangeIcon style={{ marginRight: '10px' }} />}
                />
                <MuiDateRangePicker
                  open={openIndex === index}
                  toggle={toggleOpen}
                  dateRange={{
                    startDate,
                    endDate
                  }}
                  onDateRangeChange={(value) => onDateRangeChange(value, index)}
                />
              </DateRangeContainer>
            </Cell>
            <Cell style={{ width: companyPricebookColumns[4].width }}>
              <FlexboxContainer justifyContent="center" alignItems="center">
                <Clear onClick={() => onClearData(index)} style={{ width: '36px', height: '36px' }} />
              </FlexboxContainer>
            </Cell>
          </Row>
        )
      })}
    </FlexboxContainer>
  );

  return (
    <Page padding='0'>
      <DescriptionText>
        Please fill to set up custom pricebooks for this particular company. Your default pricebooks will be used if left blank
      </DescriptionText>
      {header}
      {table}
      <FlexboxContainer padding='20px 0 10px 0' justifyContent='flex-start'>
        <Button
          isGhost
          isSecondary
          margin='0 5px 0 0'
          onClick={onCancelClick}
        >
          Cancel
        </Button>
        <Button onClick={onConfirmClick}>
          Save
        </Button>
      </FlexboxContainer>
      {clearDataModal}
      {cancelModal}
    </Page>
  );
};

export default PricebooksTabOld;
