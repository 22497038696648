import React, { createContext, useContext, useEffect, useState } from 'react';
import * as SessionStorage from 'lib/SessionStorage';
import User from 'data/User';
import { clearStashes } from 'lib/stash';
import { navigate } from 'hookrouter';
import { post } from 'lib/API';

type Actions = {
  login: (email: string, password: string) => void;
  logout: () => void;
  updateUser: (user: User) => void;
};

type AuthData = {
  token: string | null;
  user: User | null;
};

type AuthRequest = {
  error: string | null;
  isLoading: boolean;
  isPending: boolean;
};

type Auth = Actions & AuthData & AuthRequest;

interface ProviderProps {
  children: React.ReactNode;
}

export const Context = createContext<Auth>({
  error: null,
  isLoading: true,
  isPending: false,
  login: () => { },
  logout: () => { },
  token: null,
  updateUser: () => { },
  user: null
});

function clearSession() {
  SessionStorage.clear('auth');
}

export function useAuth(): Actions & Auth {
  return useContext(Context);
}

function loadSession(): Promise<Auth> {
  return SessionStorage.load('auth', {
    token: null,
    user: null
  });
}

function saveSession({ token, user }: AuthData) {
  SessionStorage.save('auth', { token, user });
}

export function AuthProvider({ children }: ProviderProps) {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isPending, setPending] = useState<boolean>(false);
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    loadSession().then(({ token, user }) => {
      setLoading(false);
      setToken(token);
      setUser(user);
    });
  }, []);

  function login(email: string, password: string) {
    setPending(true);

    post(`v1/auth/login`, undefined, { email, password })
      .then(({ name, role, vendorId, token, administratorEmail, adminMailbox, customerMailbox, deadLetterMailbox, id }) => {
        const user = { email, name, vendorId, role, administratorEmail, adminMailbox, customerMailbox, deadLetterMailbox, id };
        saveSession({ token, user });
        setError(null);
        setPending(false);
        setToken(token);
        setUser(user);
      })
      .catch(err => {
        setError(err.message);
        setPending(false);
      });
  }

  function logout() {
    post(`v1/auth/logout`, token as string).then(() => {
      setToken(null);
      setUser(null);
      clearStashes();
      clearSession();
      navigate('/');
    });
  }

  function updateUser(user: User | null) {
    setUser(user);
    saveSession({ user, token });
  }

  return (
    <Context.Provider
      value={{
        error,
        isLoading,
        isPending,
        login,
        logout,
        token,
        updateUser,
        user
      }}
    >
      {children}
    </Context.Provider>
  );
}
