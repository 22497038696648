import BrandVendor from 'data/BrandVendor';
import * as Prod from 'data/Product';
import { Role } from 'data/User';
import Variant, { VariantAttributeValue, emptyValue, empty } from 'data/Variant';
import { RentalPrice } from 'data/Rental';
import Currency from 'data/Currency';
import { EmptyRentalPriceCA, EmptyRentalPriceUS, EmptyRentalRangeCA, EmptyRentalRangeUS, RentRangeOrder, SkippedRentalRange, VariantErrorType } from 'data/ProductValidation';
import { TEXT_LABELS } from 'constants/productDetails';
import { PRICE_TYPE } from 'constants/product';
import { COUNTRY_ISO, CURRENCY_ISO } from 'constants/country';
import { PRODUCT_VARIANT_FIELDS_CONFIG } from 'constants/variant';

export const createVariant = (variant: Record<string, any> = {}, attributeList: Prod.ProductAttribute[] = []): Variant => {
  let variantAttributeValues: VariantAttributeValue[] = variant.attributeValues ? variant.attributeValues : [];

  if (attributeList.length > 0) {
    variantAttributeValues = [];
    for (let i = 0; i < attributeList.length; i++) {
      let attributeId = attributeList[i].id || emptyValue.attributeId;
      variantAttributeValues.push({ ...emptyValue, attributeId, isSelectable: attributeList[i].isSelectable })
    }
  }

  return { ...empty, ...variant, attributeValues: variantAttributeValues, productStocks: [], productVariantPrices: [] };
};

export const formatPrice = (price: number): string => {
  return `$${price.toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })}`;
};

export function fieldName(key: string): string {

  if (key.split('-')?.length > 1) key = key.split('-')[0];

  switch (key) {
    case 'height':
      return 'Height';
    case 'itemsPerUnit':
      return 'Items per Unit';
    case 'length':
      return 'Length';
    case 'pricePerUnit':
      return 'Price per Unit';
    case 'sku':
      return 'SKU';
    case 'vendorPartNumber':
      return 'Vendor Part Number';
    case 'manufacturingPartNumber':
      return 'Manufacturing Part Number';
    case 'weightPerUnit':
      return 'Weight per Unit';
    case 'weight':
      return 'Weight (kg)';
    case 'width':
      return 'Width';
    case 'hidden':
      return 'Hidden';
    case 'deliveryLeadDays':
      return 'Delivery lead days';
    case 'postOrderShippingCost':
      return 'Post Order Shipping Cost';
    case 'Inventory':
      return 'Inventory';  
    case 'available':
      return 'In Stock';
    case 'itemType':
      return 'Item Type';
    case 'materialNumber':
      return 'Material Number';
    case 'rent':
      return 'Rent';
    case 'purchase':
      return 'Purchase';
    case 'range':
      return 'Range';
    case 'rangeUnits':
      return 'Range Unit';
    case 'rangePrice':
      return 'Range Price';
    default:
      return key;
  }
};

export const getBasicColumns = (brandVendor: BrandVendor, isSlbVendor: boolean = false, role: Role | undefined) => {
  const defaultColumns = ['sku', 'vendorPartNumber', 'weight', 'hidden', 'itemType', 'materialNumber'];

  return defaultColumns;
};

export const getTypeColumns = ( rentFlag:boolean ) => {
  return rentFlag ? [TEXT_LABELS.PURCHASE, TEXT_LABELS.RENT] : [TEXT_LABELS.PURCHASE];
}

export const getRentalColumnsFn = () => {
  return [TEXT_LABELS.RANGE, TEXT_LABELS.RANGE_UNITS, TEXT_LABELS.RANGE_PRICE];
}


export const getColumnMetaData = (key: any) => {
  if (key instanceof Object) {
    return {
      key: key.name,
      label: fieldName(key.name!),
      keyId: key.id,
    };
  } else {
    return {
      key,
      label: fieldName(key),
    };
  }
};

export const getTotalProductVariantPrices = (variant: Variant, currencies: Currency[]) => {
  let productVariantPrices = variant.productVariantPrices ? variant.productVariantPrices.map(variantPrice => {

    return ({
      id: variantPrice.id || variantPrice.priceId,
      productVariantId: variant.id,
      pricePerUnit: variantPrice.pricePerUnit,
      itemsPerUnit: variantPrice.itemsPerUnit,
      countryId: variantPrice.countryId,
      currencyId: currencies.find(currency => currency.countryId === variantPrice.countryId)?.id,
      priceType: variantPrice.priceType || PRICE_TYPE.PURCHASE
    })

  }) : []

  if(variant.rentalPriceUS){
    let oldMaxRange: number | null = 0;
    let mappedRentalPrices = variant.rentalPriceUS.map(variantPrice => {
      
      let obj =  {
        id: variantPrice.id,
        productVariantId: variant.id,
        pricePerUnit: variantPrice.rangePrice,
        itemsPerUnit: 0,
        countryId: currencies.find(x => x.currencyIso === CURRENCY_ISO.USD)?.countryId,
        currencyId: currencies.find(currency => currency.currencyIso === CURRENCY_ISO.USD)?.id,
        priceType: PRICE_TYPE.RENT,
        minRange: oldMaxRange === null ? null : parseInt(oldMaxRange.toString()) + 1,
        maxRange: variantPrice.range
      }

      oldMaxRange = variantPrice.range;
      return obj;
    });

    productVariantPrices = [...productVariantPrices, ...mappedRentalPrices]
  }

  if(variant.rentalPriceCA){
    let oldMaxRange: number | null = 0;
    let mappedRentalPrices = variant.rentalPriceCA.map(variantPrice => {
      let obj = {
        id: variantPrice.id,
        productVariantId: variant.id,
        pricePerUnit: variantPrice.rangePrice,
        itemsPerUnit: 0,
        countryId: currencies.find(x => x.currencyIso === CURRENCY_ISO.CAD)?.countryId,
        currencyId: currencies.find(currency => currency.currencyIso === CURRENCY_ISO.CAD)?.id,
        priceType: PRICE_TYPE.RENT,
        minRange: oldMaxRange === null ? null : parseInt(oldMaxRange.toString()) + 1,
        maxRange: variantPrice.range
      }

      oldMaxRange = variantPrice.range;
      return obj;
    });

    productVariantPrices = [...productVariantPrices, ...mappedRentalPrices]
  }

  return productVariantPrices;
}

export const validateRentalRanges = (rentalRanges: RentalPrice[] | undefined, currencyIso: string, variant: Variant): 
  (EmptyRentalPriceCA | EmptyRentalPriceUS | EmptyRentalRangeCA | EmptyRentalRangeUS | SkippedRentalRange | RentRangeOrder )[] => {
  let errors: any = [];

  if(rentalRanges?.length){
    let intermediateSkippedRanges = [];
    
    for (let i = 1; i < rentalRanges.length; i++) {
      let currentRange = rentalRanges[i]?.range;
      let previousRange = rentalRanges[i - 1]?.range;
      let currentRangePrice = rentalRanges[i]?.rangePrice;
      let previousRangePrice = rentalRanges[i - 1]?.rangePrice;
      
      if(!previousRange){
        intermediateSkippedRanges.push({
          key: `range-${i}-${currencyIso}`,
          type: VariantErrorType.RentRangeSkipped,
          variants: [variant]
        })
      }
      
      if((previousRange && parseInt(previousRange?.toString()!)) || currentRangePrice){
        if(intermediateSkippedRanges.length > 0){
          errors = [...errors, ...intermediateSkippedRanges]
          intermediateSkippedRanges = [];
        }
      }

      if(previousRange && !previousRangePrice){
        errors.push({
          key: `rangePrice-${i}-${currencyIso}`,
          type: VariantErrorType.MissingValue,
          variants: [variant],
        });
      }
  
      if (currentRange && previousRange) {
        if (parseInt(currentRange.toString()) <= parseInt(previousRange.toString())) {
          errors.push({
            key: `range-${i + 1}-${currencyIso}`,
            type: VariantErrorType.RentRangeOrder,
            variants: [variant],
          });
        }
      }
    }

    let rangeExists = rentalRanges.find(x => x.range);

    if(!rangeExists && !!variant.rent){
      errors.push({
        key: `range-1-${currencyIso}`,
        type: VariantErrorType.RequiredRentalRange,
        variants: [variant],
      });
    }
  }
  return errors;
}

export const getPlaceholderValidationStatus = () => {
  return {id: 0, name: 'OK'};
}

export const getProductVariantFieldConfig = (key: string | undefined) => {
  if(!key || !(key in PRODUCT_VARIANT_FIELDS_CONFIG)) 
    return PRODUCT_VARIANT_FIELDS_CONFIG.default;

  return PRODUCT_VARIANT_FIELDS_CONFIG[key];
}