import React from 'react';
import styled from 'styled-components';
import { Main } from 'styles/themeColors';

const Container = styled.div`
  min-width: 17rem;
`;

const Description = styled.div`
  font-size: 0.8rem;
`;

const Heading = styled.div`
  color: ${Main.accent};
`;

const HeadingV2 = styled.div`
  color: ${Main.black};
  font-weight: bold;
`;

interface Props {
  className?: string;
  error: {
    description: string;
    name: string;
  };
  productRedesignFlag?: boolean
}

export default function VariantIssueOption({ className, error, productRedesignFlag=false }: Props) {
  return (
    <Container className={className}>
      {
        productRedesignFlag ? 
         <HeadingV2>{error.name}</HeadingV2> :
         <Heading>{error.name}</Heading>
      }
     
      <Description>{error.description}</Description>
    </Container>
  );
}

VariantIssueOption.defaultProps = {};
