import React, { Fragment } from 'react';

import { Heading2, Paragraph } from 'OnboardingComponents/Elements';

const Intro = () => {
  return (
    <Fragment>
      <Heading2 id='intro'>Intro</Heading2>
      <Paragraph>On the following pages, you’re going to see
how we use various elements to visually
identify who we are and what we do. From
logos to color palettes, typefaces to image
usage, these are the tangible ways we
communicate OFMP (Oilfield Marketplace).</Paragraph>
    </Fragment>
  );
};

export default Intro;
