export const emptyDataSourceExtraMessage = 'Try adjusting your search or filters to find what you are looking for';
export const emptyDataSourceMainMessage = 'No results found';
export const stockAPIMessage = 'Stock API connection failed, Manual process will be followed for this consignment.';

export const warehousePosValidations = {
    INVALID_ZIP: 'Not a valid ZIP / Post code',
    INVALID_CITY: 'Max 35 characters',
    INVALID_STREET: 'Max 100 characters',
    INVALID_ID: 'Max 50 characters',
    INVALID_NICKNAME: 'Max 255 characters',
    INVALID_FULLNAME: 'Max 100 characters',
    INVALID_WAREHOUSE_FIELDS: 'Max 50 characters',
    INVALID_SAP_PLANTID_FIELD: 'Please enter correct Plant/Location detail',
    INVALID_PROJECT_FIELD: 'Max 25 characters',
    INVALID_SUB_SEGMENT_FIELD: 'Max 10 characters',
    INVALID_SALES_ORG_FIELD: 'Max 10 characters',
    POS_REQUIRED: 'Pick up location is required when pickup is selected as a delivery option.'
}

export const productVariantMessages = {
    NO_PRODUCT_FOUND: 'No product found.',
    WAREHOUSES_INVALID: 'Warehouses data incorrect.',
    CURRENCY_INVALID: 'Currency data incorrect.',
    GENERIC_INVALID: 'Unable to import spreadsheet. Data is in an invalid format.',
    HIDDEN_ATTRIBUTE_INVALID: 'Only Yes/No or empty cell is accepted for the "Hidden" column.',
    FIXED_HEADER_INVALID: 'Import file has wrong columns. Please redownload the template to compare.',
    NUMBER_ATTRIBUTE_INVALID: 'You are trying to insert text data into num only fields.',
    NO_PRODUCT_FOUND_INVALID: 'No product found in selected spreadsheet.',
    ZERO_PRICE_VALIDATION: 'Products with a zero variant price are not allowed to be exported.'
};

export const orderDocuments = {
    MAX_COMMENT_LENGTH: 'Max 512 characters'
}

export const vendorValidations = {
    REMINDERS_DAYS_LIMIT: 'Reminder days must be between {MIN} and {MAX}.'
}