import Brand from 'data/Brand';

export interface BrandValidationTier {
  priceStart: number;
  recommendations: BrandValidationTierRequirements;
  requirements: BrandValidationTierRequirements;
}

export interface BrandValidationBoolean {
  value: boolean;
}

export interface BrandValidationMinMax {
  min: number;
  max?: number;
}

export interface BrandValidationTierRequirements {
  description: BrandValidationMinMax;
  media: BrandValidationMinMax;
  name: BrandValidationMinMax;
}

export function getBrandValidationTier(
  brand: Brand
): BrandValidationTier {
  const sortedTiers = brandValidationTiers.sort(
    (a, b) => b.priceStart - a.priceStart
  );
  return sortedTiers[sortedTiers.length - 1];
}

const brandValidationTiers: BrandValidationTier[] = [
  {
    priceStart: 1000,
    recommendations: {
      description: { min: 100 },
      media: { min: 5 },
      name: { min: 1, max: 5 },
    },
    requirements: {
      description: { min: 10 },
      media: { min: 1 },
      name: { min: 1 },
    }
  },
  {
    priceStart: 151,
    recommendations: {
      description: { min: 60 },
      media: { min: 3 },
      name: { min: 1, max: 5 },
    },
    requirements: {
      description: { min: 1 },
      media: { min: 1 },
      name: { min: 1 },
    }
  },
  {
    priceStart: 0,
    recommendations: {
      description: { min: 30 },
      media: { min: 2 },
      name: { min: 1, max: 5 },
    },
    requirements: {
      description: { min: 1 },
      media: { min: 1 },
      name: { min: 1 },
    }
  }
];

export default brandValidationTiers;
