import * as React from 'react';
import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { useAuth } from 'lib/Auth';
import SearchBar from 'components/UI/Search';
import CampaignLocalization from './CampaignLocalization';
import Vendor from 'data/Vendor';
import Button from 'components/UI/Button';
import { Background, Main } from 'styles/themeColors';
import styled from 'styled-components';
import Slab from 'view/Slab';
import { Size } from 'lib/styles';
import { BorderRadius } from 'styles/themeBorderRadius';
import Icon from 'view/Icon';
import Divider from 'components/UI/Divider';
import CampaignListingFilters, { CampaignListFilter } from './CampaignListFilter';
import { CampaignTableColumns } from './CampaignTableColumns';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import CampaignListTable from './CampaignListTable';
import UpsertCampaignModal from './UpsertCampaignModal';
import { useLaunchCompanies } from 'hooks/useLaunchCompanies';
import { useCampaignTypes } from 'hooks/useCampaignTypes';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import CampaignExport from './CampaignExport';
import ImportCampaignModal from './ImportCampaignModal';
import SelectVendorModal from './SelectVendorsModal';
import { printComponent } from 'utils/print';
import Modal from 'components/UI/Modal';
import { deleteCampaign } from 'requests/campaigns';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import { useAPI } from 'lib/API';
import { isVendor } from 'data/User';
import { AddRounded } from '@material-ui/icons';
import { CampaignContext } from 'context/campaign';
import CampaignStatistics from './CampaignStatistics';


const PrintIcon = styled(Icon).attrs({ name: 'Print', size: 32 })``;

const FlexWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
  width: 100%;
`;



export const useStyles = makeStyles(() =>
    createStyles({
        containerGrid: {
            marginTop: '20px',
            width: '100%',
            padding: '5px'
        },
        gridPadding: {
            padding: '0 20px'
        },
        gridMargin: {
            margin: '0 20px'
        }
    })
);

interface Props {
    selectedVendor: Vendor;
    vendorsList: Vendor[];
}


const CampaignList = ({ selectedVendor, vendorsList }: Props) => {

    const { user } = useAuth();
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [filters, setFilters] = React.useState<CampaignListFilter>({
        launchCompanies: [],
        vendor: [],
        country: { value: CampaignLocalization.listing.filter.countrySelect },
        campaignTypes: [],
    });
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
    const [reload, setReload] = React.useState(false);
    const [showModal, setShowModal] = React.useState(false);
    const [showImportModal, setShowImportModal] = React.useState(false);
    const [showVendorModal, setShowVendorModal] = React.useState(false);
    const [isUploading, setIsUploading] = React.useState(false);
    const [isValidating, setIsValidating] = React.useState(false);
    const [vendorIds, setVendorIds] = React.useState<number[]>([]);
    const { launchCompanies = [], launchCompaniesReq } = useLaunchCompanies();
    const { campaignTypes = [], campaignTypeReq } = useCampaignTypes();
    const [campaignId, setCampaignId] = React.useState<number | undefined>(undefined);
    const [showCloseModal, setShowCloseModal] = React.useState(false);

    const { stats,loading:statsLoading } = React.useContext(CampaignContext); // Consume context

    const handleFilterUpdate = (filters: CampaignListFilter) => {
        setFilters(filters);
    };

    const handleResetVendorIds = () => {
        setVendorIds(selectedVendor?.id ? [selectedVendor?.id] : []);
    }


    const onCloseModal = () => {
        // If there are unsaved changes, show the close modal for add event
        if(!campaignId) setShowCloseModal(true);
        else onUpsertModalClose();
    }

    const confirmCloseModal = (
        <Modal
            showModal={showCloseModal}
            onClose={() => setShowCloseModal(false)}
            onClick={() => onUpsertModalClose()}
            title="Unsaved Changes"
            primaryButtonText="Confirm"
            ghostButtonText="Back"
            primaryButtonColor={Main.brandDark}
            color={Main.black}
            borderColor={Main.disabled}
        >
            <p>{`You have unsaved changes. Are you sure you want to close without saving?`}</p>
            <p>{'Any unsaved data will be lost.'}</p>
        </Modal>
    )

    const onUpsertModalClose = () => {
        handleResetVendorIds();
        setShowModal(false);
        setCampaignId(undefined);
        setShowCloseModal(false);
    }

    const handleConfirmVendors = () => {
        setShowVendorModal(false);
        setShowImportModal(true);
    }

    const handleModalOpen = () => {
        onUpsertModalClose();
        if (selectedVendor?.id!) setShowImportModal(true);
        else setShowVendorModal(true);
    }

    const handleImportModalClose = () => {
        setShowImportModal(false);
        handleResetVendorIds();
        setReload(true);
        setCampaignId(undefined);
    }

    const handleEdit = (id: number) => {
        setShowModal(true);
        setCampaignId(id);
    };

    const handleDelete = (id: number | undefined = undefined) => {
        setShowDeleteModal(true);
        setCampaignId(id);
    };

    React.useEffect(() => {
        if (selectedVendor?.id) {
            setVendorIds([selectedVendor?.id])
        }
    }, [selectedVendor])

    const deleteReq = useAPI({
        deferFn: deleteCampaign,
        onResolve: () => {
            renderToast(toast.TYPE.SUCCESS, 'Campaign(s) deleted.');
            setReload(true);
            setShowDeleteModal(false);
            setSelectedRows([]);
            setCampaignId(undefined);
        },
        onReject: (err: any) => {
            renderToast(toast.TYPE.ERROR, err.message);
            setShowDeleteModal(false);
            setSelectedRows([]);
            setCampaignId(undefined);
        }
    });

    const handleRefresh = () => {
        setReload(true);
    }

    const onDelete = () => {

        if (campaignId !== undefined ) {
            deleteReq.run([campaignId]);
        }

        if (selectedRows.length > 0) {
            const ids = selectedRows.map((row) => row.id);
            deleteReq.run(ids);
        }

    }


    const confirmDeleteModal = (
        <Modal
            showModal={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onClick={() => onDelete()}
            title="Remove Event"
            primaryButtonText="Confirm"
            ghostButtonText="Back"
            primaryButtonColor={Main.brandDark}
            color={Main.black}
            borderColor={Main.disabled}
        >
            <p>{`Are you sure you want to delete ${campaignId || selectedRows?.length === 1 ? 'this' : 'these'}  event?`}</p>
        </Modal>
    )

    if (launchCompaniesReq.isLoading || campaignTypeReq.isLoading || deleteReq.isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <Grid container className={classes.containerGrid}>
            {confirmDeleteModal}

            { showModal &&
                <UpsertCampaignModal
                showModal={showModal}
                onClose={onCloseModal}
                selectedVendorId={selectedVendor ? selectedVendor.id : undefined}
                vendorsList={vendorsList}
                handleModalOpen={handleModalOpen}
                campaignId={campaignId}
                handleRefresh={handleRefresh}
                showCloseModal={showCloseModal}
                setShowModal={setShowModal}
                onUpsertModalClose={onUpsertModalClose}
                />
            }

            {confirmCloseModal}

            <ImportCampaignModal
                show={showImportModal}
                onClose={handleImportModalClose}
                isUploading={isUploading}
                setIsUploading={setIsUploading}
                loadCampaigns={() => setReload(true)}
                isValidating={isValidating}
                setIsValidating={setIsValidating}
                userId={user?.id!}
                vendorIds={vendorIds}
            />

           {!isVendor(user!) && <SelectVendorModal
                showModal={showVendorModal}
                onClose={() => setShowVendorModal(false)}
                onConfirm={handleConfirmVendors}
                vendorsList={vendorsList}
                selectedVendors={vendorIds}
                setSelectedVendors={setVendorIds}
            />
           }

            <Grid container className={classes.gridPadding}
                alignItems="center">

                <Grid item xs={12} sm={3} md={3}>
                    <h5>{CampaignLocalization.marketingCampaigns}</h5>
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                    <SearchBar
                        placeholder={CampaignLocalization.searchPlaceholder}
                        searchValue={searchTerm}
                        onSearch={(searchText: string) => {
                            setSearchTerm(searchText);
                            console.log(searchText);
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        onClick={() => {
                            setShowModal(true);
                        }}
                        margin="0 10px 0 0"
                        bgColor="#FC780B"
                        borderColor={Main.brandDark}
                        leftIcon={() => <AddRounded />}
                    >
                        Add new event
                    </Button>
                </Grid>

            </Grid>


            <FlexWrapper>
               <CampaignStatistics stats={stats} loading={statsLoading} />
            </FlexWrapper>

            <FlexWrapper>
                <Grid container className={classes.gridPadding}
                    alignItems="center">

                    <Grid item xs={12} sm={9} md={9} spacing={6}>
                        <h5>{CampaignLocalization.events}</h5>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={3}
                        md={3}
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                    >

                        {/* Export the results */}
                        <CampaignExport
                            filters={filters}
                            searchTerm={searchTerm}
                            selectedVendorId={selectedVendor ? selectedVendor.id : undefined}
                        />


                        <Button
                            onClick={() => printComponent('marketing-campaign-table', 'Marketing Campaigns', 'Marketing Campaigns')}
                            leftIcon={() => <PrintIcon />}
                            background={Main.white}
                            borderColor={Main.white}
                        />
                    </Grid>

                </Grid>

            </FlexWrapper>

            <Divider height="1px" maxWidth='100%' marginY='8px' />

            <CampaignListingFilters
                selectedVendorId={selectedVendor ? selectedVendor.id : undefined}
                vendorsList={vendorsList}
                filterUpdate={handleFilterUpdate}
                campaignColumns={CampaignTableColumns}
                loading={loading}
                launchCompanies={launchCompanies}
                campaignTypes={campaignTypes}
            />

            <Grid item xs={12} sm={12} md={12} className={classes.gridMargin} style={{ marginTop: '20px' }}>
                <CampaignListTable
                    campaignColumns={CampaignTableColumns}
                    filters={filters}
                    searchTerm={searchTerm}
                    selectedVendorId={selectedVendor ? selectedVendor.id : undefined}
                    setShowDeleteModal={setShowDeleteModal}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                    reload={reload}
                    setReload={setReload}
                    setLoading={setLoading}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                />

                <FlexboxContainerComponent margin='24px 0px'>
                    <Button borderColor='#C82135' background='#C82135' onClick={() => handleDelete()} disabled={!selectedRows.length || deleteReq.isLoading}>Delete Selected</Button>
                </FlexboxContainerComponent>
            </Grid>
        </Grid>

    );
}

export default React.memo(CampaignList);