import React from 'react';
import styled from 'styled-components';
import Customer from 'data/Customer';
import CustomerInformation from 'common/Customers/CustomerInformation';
import EditorSection from 'view/EditorSection';
import { Block } from '@material-ui/icons';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Divider from 'components/UI/Divider';
import { Color, FontWeight, FontSize } from 'lib/styles';
import { Height } from 'styles/themeHeights';
import { Main, Text } from 'styles/themeColors';
import {
  DOW_JONES_BLOCKED,
  DOW_JONES_CLEAR,
  DOW_JONES_REVIEWED_BLOCKED,
  DOW_JONES_REVIEWED_CLEAR,
} from 'constants/dowJonesStatuses';

const Highlight = styled.span`
  font-size: 16px;
  margin: 0 4px;
  color: ${({ color }) => color};
`;

const StyledText = styled.span`
  font-size: ${FontSize.small};
  font-weight: ${FontWeight.bold};
  letter-spacing: 1px;
  color: ${({ color }) => color};
`;

const ErrorText = styled.span`
  color: ${Main.error} !important;
`;

const SecondaryText = styled.span`
  margin: 0 4px;
  color: ${Text.secondary} !important;
`;

interface Props {
  customerDetails: Customer;
};

const CustomerVendorDetails = ({
  customerDetails
}: Props) => {
  const getStatus = (dowJonesStatus: any) => {
    let displayStatus = dowJonesStatus;

    switch (dowJonesStatus) {
      case DOW_JONES_REVIEWED_CLEAR:
        displayStatus = <Highlight>Compliant</Highlight>
        break;
      case DOW_JONES_CLEAR:
        displayStatus = <Highlight color={Main.success}>Compliant</Highlight>
        break;
      case DOW_JONES_BLOCKED:
        displayStatus = <SecondaryText className="base-italic">Under Review</SecondaryText>
        break;
      case DOW_JONES_REVIEWED_BLOCKED:
        displayStatus = (
          <>
            <Block style={{ color: Main.error, height: Height.xsPlus, width: "16px", margin: "0 4px 0 4px" }} />
            <ErrorText className="base">Blocked manually</ErrorText>
          </>
        );
        break;
      default:
        displayStatus = '';
    }

    return displayStatus;
  };

  const dowJonesFailedResult = customerDetails.dowJones.find(dowJones => dowJones.status === DOW_JONES_BLOCKED);
  const dowJonesStatus = dowJonesFailedResult
    ? dowJonesFailedResult.status
    : customerDetails.dowJones.length > 0
      ? customerDetails.dowJones[0].status
      : '';

  return (
    <>
      <FlexboxContainer>
        <StyledText color={Color.darkerGray}>OFMP Customer ID: </StyledText>
        <StyledText color={Color.black}>{customerDetails.customerId}</StyledText>
        <FlexboxContainer alignItems="center" margin="0 0 0 40px">
          <StyledText color={Color.darkerGray}>Compliance check: </StyledText>
          {
            getStatus(dowJonesStatus)
          }
        </FlexboxContainer>
      </FlexboxContainer>
      <EditorSection
        disablePaddingBottom={true}
        borderColor={Color.linkWater}
      >
        <CustomerInformation customerDetails={customerDetails} />
      </EditorSection>
      <Divider />
    </>
  );
};

export default CustomerVendorDetails;
