import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Background, Border, Text } from 'styles/themeColors';
import { downloadDocument, sendEmailToCustomer } from 'requests/documents';
import { useAPI } from 'lib/API';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import documentOptions, { companyDocumentOptions } from 'constants/documentOptions';
import Vendor from 'data/Vendor';
import Icon from 'view/Icon';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { formatDateTime } from '../../utils/formatters';
import { MoreVert } from '@material-ui/icons';

const Card = styled.div`
  align-items: self-start;
  background: ${Background.white};
  border: 1px solid ${Border.light};
  border-radius: 6px;
  display: flex;
  padding: 6px;
  box-shadow: 2px 3px 3px rgba(39, 44, 57, 0.15);
  cursor: pointer;
`;

const StyledFlexboxContaier = styled(FlexboxContainer) <any>`
  .MuiSvgIcon-root {
    font-size: 36px;
  }
`;

const Label = styled.span`
  font-size: 16px;
  line-height: 20px;
  margin-left: 10px;
`;

const CategoryName = styled.span`
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
  color: #53595E;
  margin-left: 10px;
`;

const DocumentTime = styled.span`
  width: 100%;
  font-size: 12px;
  color: ${Text.secondary};
  line-height: 16px;
`;

interface Props {
  card: any;
  customerEmail: string;
  cardForCustomer?: boolean;
  vendor?: Vendor,
  date?: string;
  comment?: string;
  orderNumber: string;
  createdBy?: string;
};

const DocumentCard = ({
  card,
  customerEmail,
  cardForCustomer,
  vendor,
  date,
  comment,
  createdBy,
  orderNumber
}: Props) => {
  const [downloadedDocument, setDownloadedDocument] = useState<any>();
  const [selectedOption, setSelectedOption] = useState('');
  const [open, setOpen] = React.useState(false);
  const [emailToCustomerBody, setEmailToCustomerBody] = useState<any>({
    createdBy: '',
    orderNumber: 0,
    attachments: []
  });

  const sendEmailToCustomerReq = useAPI({
    deferFn: sendEmailToCustomer,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Email has been sent to customer');
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const download = (doc: any) => {
    const a = document.createElement('a');

    a.style.display = 'none';
    document.body.appendChild(a);

    const url = window.URL.createObjectURL(doc);

    a.href = url;
    a.download = card.name;
    a.click();

    window.URL.revokeObjectURL(url);
  };

  const showInOtherTab = (doc: any) => {
    const url = window.URL.createObjectURL(doc);
    window.open(url, '_blank');
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (selectedOption === 'Email to customer') {
      sendEmailToCustomerReq.run(customerEmail, emailToCustomerBody)
    }
  }, [selectedOption, emailToCustomerBody]);

  const downloadDocumentReq = useAPI({
    deferFn: downloadDocument,
    onResolve: result => {
      setDownloadedDocument(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(()=>{
    if(card){
      downloadDocumentReq.run(card.id, card.url)
    }
  },[])

  useEffect(() => {
    if (downloadedDocument && downloadedDocument.document) {
      if (selectedOption === 'Preview') {
        if (canBePreviewed(downloadedDocument.extension)) {
          showInOtherTab(downloadedDocument.document);
        } else {
          download(downloadedDocument.document);
        }
      } else if (selectedOption === 'Download') {
        download(downloadedDocument.document);
      }
    }
    setSelectedOption('');
  }, [selectedOption, downloadedDocument]);

  const canBePreviewed: (ext: string) => boolean = (ext: string) => {
    return ['.jpg', '.png', '.pdf', '.jpeg', '.tiff'].includes(ext);
  };

  const onSelect = (name: string) => {
    setSelectedOption(name);

    if (name === 'Email to customer'){
      setEmailToCustomerBody({
        createdBy: createdBy,
        orderNumber: orderNumber,
        attachments: [{
          comment: comment,
          creationTime: date,
          documents: [{
            id: card.id,
            name: card.name,
            mimeType: card.mimeType,
            url: card.url
          }]
        }]
      })
    }
  };

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && (anchorRef.current as any).contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current && !open) {
      if (anchorRef && anchorRef.current) {
        (anchorRef.current as HTMLElement).focus();
      }
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Fragment>
      <Card ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}>
        <StyledFlexboxContaier justifyContent='flex-start'>
        <Icon name='Draft' size={34} />
        </StyledFlexboxContaier>
        <FlexboxContainer flexDirection='column' width="100%" maxWidth='190px'>
          <Label>{card.name}</Label>
          <CategoryName>{card.category?.isVisible ? card.category.name : " — "}</CategoryName>
          {card.creationTime && <DocumentTime>{formatDateTime(card.creationTime, true)}</DocumentTime>}
        </FlexboxContainer>
        {
          downloadedDocument && downloadedDocument.document &&
          <FlexboxContainer margin='0 0 0 auto'>
            <MoreVert className='material-symbols-rounded' tabIndex={0} style={{ fontSize: 28, margin: 8, color: '#53595E' }} />
          </FlexboxContainer>
        }
      </Card>

      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              margin: 6
          }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  {
                    (cardForCustomer ? documentOptions : companyDocumentOptions).map(option => (
                      <MenuItem onClick={(event) => { onSelect(option.title); handleClose(event) }}>
                        {option.title}
                      </MenuItem>
                    ))
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};

export default DocumentCard;
