import React, { ReactNode, useMemo } from 'react';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Modal from 'components/UI/Modal';
import Select from 'components/UI/Select';
import { Link } from '@material-ui/icons';
import styled from 'styled-components';
import InputComponent from 'components/UI/Input';
import Button from 'components/UI/Button';
import { CustomerExtended } from 'data/Customer';
import Vendor from 'data/Vendor';
import Company from 'data/Company';
import { Border, Main, Text } from 'styles/themeColors';
import { TextField, TextFieldProps } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { createCharacterSeparator } from 'utils/string';
import { FontSize } from 'styles/themeSizes';
import { FontWeight } from 'lib/styles';

const Label = styled.span<any>`
  font-size: 16px;
  line-height: 20px;
  
  color: ${({ $hasError }) => $hasError ? Main.error : ""};
`;

const AutocompleteStyled = styled(Autocomplete) <any> `
  .MuiOutlinedInput-notchedOutline {
    border-color: ${Border.main};
  }
  
  .MuiSvgIcon-root {
    color: ${Text.primary};
  }
`;

const LABELS_TEXT = {
  ASSIGN_TO: 'Assigned to company',
  ASSIGN_BY: 'Assigned by',
  COMPANY_USER: 'Company user',
  MODAL_TITLE: 'New company pricebook'
};

const DEFAULT_USER_OPTION = 'All company members';

// can't be moved to autocomplete styles since the listbox is not a child of autocomplete
const autoCompleteClasses = makeStyles(() =>
  createStyles({
    listbox: {
      height: 250,
      overflowX: 'hidden'
    }
  })
);

const commaSeparator = createCharacterSeparator({ char: ',', oneWhiteSpace: true });
const atSeparator = createCharacterSeparator({ char: '@', oneWhiteSpace: true, oneSpaceBeforeChar: true });

const companyNameNormalizer = (companyName: string): string => {
  // 20 is the maximal length of email address that can be shown without adding whitespaces
  return commaSeparator(companyName.length > 20 ? atSeparator(companyName) : companyName);
}

interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm?: (value: string) => void;
  isLoading?: boolean;
  userType?: string;
  selectedCompany?: string | Company;
  assigneeList: Vendor[] | undefined;
  setAssignee: (assignee: Option) => void;
  assignee?: Option;
  companyUsers: CustomerExtended[];
  setCompanyUser: (companyUser: Option) => void;
  companyUser?: Option;
  selectedVendor?: Vendor;
  assignedByError: boolean;
  assignedToError?: boolean;
  companiesList?: Company[];
  setCompany?: (company: Option | undefined) => void;
}

interface Option {
  value: string;
  id?: string;
  icon?: ReactNode;
  badge?: any;
}

const NewPricebooksModal = ({
  show,
  onClose,
  onConfirm,
  assigneeList,
  setAssignee,
  assignee,
  selectedCompany,
  companyUsers,
  setCompanyUser,
  companyUser,
  selectedVendor,
  assignedByError,
  assignedToError,
  companiesList,
  setCompany
}: Props) => {


  const hasSelectedVendor = !!selectedVendor?.id;
  const linkIconStyle = { height: '25', width: '30', color: Text.primary };
  const classes = autoCompleteClasses();

  const mappedCompanyUsers = useMemo(() => ([
    {
      value: DEFAULT_USER_OPTION,
      id: 'All',
    },
    //fix Customer interface (make it have email as array but not string)
    ...companyUsers.map((companyUser: any) => ({
      value: companyUser.email[0]?.emailAddress,
      id: companyUser.customerId,
    })).sort((a, b) => a.value.localeCompare(b.value))
  ]), [companyUsers]);

  const assignedByList = useMemo(() => assigneeList?.map((assignee: any) => ({
    value: assignee.name,
    id: assignee.code,
  })), [assigneeList]);

  const mappedCompaniesList = useMemo(() => companiesList?.sort((a, b) => a.companyName.localeCompare(b.companyName))?.map((company) => ({
    value: companyNameNormalizer(company.companyName),
    id: company.companyId,
    countryName: company.countryName,
    companyDomain: company.domain
  } as Option)), [companiesList]);

  const selectedCompanyOption = typeof selectedCompany !== 'string' ? {
    value: selectedCompany?.companyName || '',
    id: selectedCompany?.companyId
  } as Option : undefined;

  const onSelectCustomer = (event: { target: Option; }) => {
    setCompanyUser(event.target);
  };

  const onSelectAssignee = (event: { target: Option; }) => {
    setAssignee(event.target);
  };

  const onSelectCompany = (event: any, newValue: Option) => {
    setCompany && setCompany(newValue);
  };

  const onBlur = (event: any) => {
    if (!event.target.value.length) {
      setCompany && setCompany(undefined);
    }
  };


  return (
    <>
      <Modal
        width="794px"
        showModal={show}
        onClose={onClose}
        showButtons={false}
        title={LABELS_TEXT.MODAL_TITLE}
        modalFooterMargin="0"
        left="50vw"
        marginLeft="-397px"
        iconName="PricebookModalIcon"
        showCloseIcon
        top="25%"
      >
        <FlexboxContainerComponent flexDirection="column">
          <FlexboxContainerComponent
            flexDirection="row"
            width="100%"
            padding="90px 0px 0px 0px"
          >
            {!hasSelectedVendor && (
              <FlexboxContainerComponent flexDirection="column" width="33%">
                <FlexboxContainerComponent padding="0px 0px 8px 0">
                  {' '}
                  <Label $hasError={assignedByError}>{LABELS_TEXT.ASSIGN_BY}</Label>
                </FlexboxContainerComponent>
                <FlexboxContainerComponent
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                >
                  <Select
                    options={assignedByList || []}
                    onChange={onSelectAssignee}
                    value={assignee?.value || ''}
                    defaultValue="Select"
                    containerWidth={`calc(100% - ${linkIconStyle.width}px)`}
                    width={"100%"}
                    height="36px"
                    isInvalid={assignedByError}
                  />
                  <Link style={linkIconStyle} />
                </FlexboxContainerComponent>
              </FlexboxContainerComponent>
            )}
            <FlexboxContainerComponent flexDirection="row" width={hasSelectedVendor ? '50%' : '33%'}>
              {!mappedCompaniesList ?
                <InputComponent
                  id='assigned-to'
                  labelName={LABELS_TEXT.ASSIGN_TO}
                  labelPosition='top'
                  value={selectedCompany as string}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  }}
                  errorMessage=''
                  name='assigned-to'
                  blocked={true}
                ></InputComponent> :
                <FlexboxContainerComponent flexDirection="column" width="100%">
                  <FlexboxContainerComponent padding="0px 0px 8px 0">
                    {' '}
                    <Label $hasError={assignedToError}>{LABELS_TEXT.ASSIGN_TO}</Label>
                  </FlexboxContainerComponent>
                  <FlexboxContainerComponent
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                  >

                    <AutocompleteStyled
                      classes={{ listbox: classes.listbox }}
                      id="assigned-to"
                      options={mappedCompaniesList}
                      getOptionLabel={(option: any) => option.value}
                      style={{
                        width: `100%`
                      }}
                      value={selectedCompanyOption}
                      disableClearable={true}
                      onChange={onSelectCompany}
                      onBlur={onBlur}
                      renderInput={AutoCompleteInput}
                      renderOption={CompanyOptionItem}
                    />
                  </FlexboxContainerComponent>
                </FlexboxContainerComponent>
              }
            </FlexboxContainerComponent>
            <FlexboxContainerComponent
              flexDirection="row"
              alignItems="center"
              width={hasSelectedVendor ? '50%' : '33%'}
              padding="0px 0px 0px 4px"
            >
              <Select
                label={LABELS_TEXT.COMPANY_USER}
                labelPosition="top"
                options={mappedCompanyUsers}
                onChange={onSelectCustomer}
                value={companyUser ? companyUser.value : DEFAULT_USER_OPTION}
                defaultValue="Select"
                width="100%"
              />
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>

          <FlexboxContainerComponent
            padding="104px 0 0 0"
            justifyContent="flex-end"
          >
            <Button id="modal-main-button" bgColor={Main.brandDark} onClick={onConfirm}>
              Next
            </Button>
          </FlexboxContainerComponent>
        </FlexboxContainerComponent>
      </Modal>
    </>);
};

export default NewPricebooksModal;

const AutoCompleteInput = (params: JSX.IntrinsicAttributes & TextFieldProps) => {
  return <TextField
    {...params}
    variant='outlined'
    inputProps={{
      ...params.inputProps,
      style: {
        padding: 0,
      },
      placeholder: 'Start typing company name',
    }}
  />
};

const CompanyOptionItem = (option: any) => (
  <div style={{ 
    display: 'flex',
    flexDirection: 'column',
    width:'100%'}}>
    <div
      style={
        { fontSize: '14px', fontWeight: 'bold' }
      }>
      {option.value}
    </div>
    <div style={{ fontSize: '12px', fontStyle: 'italic', color: Text.placeholder }}>
      {option.countryName} - @{option.companyDomain}
    </div>
  </div>
)