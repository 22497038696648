import React from 'react';
import { MTableBody } from 'material-table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { FontWeight, FontSize } from 'lib/styles';

const MatTableBody = (props: any) => {
  if (props.options.showEmptyDataSourceMessage && props.renderData.length === 0) {
    const rowHeight = props.options.padding === 'default' ? 49 : 36;
    const height = rowHeight * (props.options.paging && props.options.emptyRowsWhenPaging ? props.pageSize : 1)
    const colSpan = props.columns.reduce((currentVal: number, columnDef: any) => (
      columnDef.hidden ? currentVal : currentVal + 1
    ), 0);

    return (
      <TableBody>
        {
          props.emptyDataSourceHeader ? (
            <TableRow
              style={{ height }}
            >
              <TableCell
                colSpan={colSpan}
                style={{
                  paddingTop: 0,
                  paddingBottom: 0,
                  border: 0,
                  textAlign: 'center',
                  fontWeight: FontWeight.bold,
                  fontSize: FontSize.large
                }}
              >
                {props.emptyDataSourceHeader}
              </TableCell>
            </TableRow>
          ) : null
        }
        <TableRow
          style={{ height }}
        >
          <TableCell
            colSpan={colSpan}
            style={{
              paddingTop: 0,
              paddingBottom: 0,
              border: 0,
              textAlign: 'center',
              fontSize: FontSize.medium
            }}
          >
            {props.localization.emptyDataSourceMessage}
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }

  return <MTableBody {...props} />;
};

export default React.memo(MatTableBody);
