import { response, Response } from 'redux/reducers/initialState/sharedState';

interface Filters {
  showOpen: boolean;
  showPendingCancelationEmail: boolean;
};

interface Config {
  filters: Filters;
  openSplitOrders: string[];
  currentPage: number;
};

interface Orders extends Response {
  data: any[];
  config: Config;
};

const orders: Orders = {
  data: [],
  ...response,
  config: {
    filters: {
      showOpen: false,
      showPendingCancelationEmail: false
    },
    openSplitOrders: [],
    currentPage: 1
  }
};

export default orders;
