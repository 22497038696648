import React, { Component } from 'react';
import styled from 'styled-components';
import { Size } from 'lib/styles';

const Container = styled.div`
  margin: ${Size.large} auto;
  max-width: 100%;
  text-align: center;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  };
    
  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true })
    console.log('Error:', error, errorInfo);
  };

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <h1 style={{ fontSize: '60px' }}>Error</h1>
          <p style={{ fontSize: '30px' }}>We are working hard to resolve it.</p>
        </Container>
      );
    }

    return this.props.children;
  };
};

export default ErrorBoundary;
