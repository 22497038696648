import { Color } from 'lib/styles';
import React from 'react';
import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';
import { Main } from 'styles/themeColors';
import { getCurrencySymbol } from 'utils/country';

interface CurrencyFormatterProps {
    value: string | undefined;
    currencyIso: string;
    disabled?: boolean;
    onChange: (value: string | number | boolean) => void;
    error: boolean;
    className?: string;
    textAlign?: string;
}


const StyledCurrencyFormat = styled(CurrencyInput)`
    color: ${Main.accent};
    font-size: 14px;
    border: none;
    width: 100%;
    text-align: right;
    padding: 5px;
    background-color: transparent !important;
`;

const CurrencyFormatter: React.FC<CurrencyFormatterProps> = ({ value, currencyIso, disabled, onChange, error, className, textAlign='right' }) => {

    const onValueChange = (valueObj: string | undefined) => {
      if (valueObj === '0.00') {
        onChange(Number(0));
        return;
      } else if (valueObj) onChange(valueObj);
    };

    return (
        <StyledCurrencyFormat
            decimalScale={2}
            value={value || 0}
            prefix={getCurrencySymbol(currencyIso)}
            disabled={disabled}
            decimalsLimit={2}
            onValueChange={onValueChange}
            allowNegativeValue={false}
            style={ error ? {backgroundColor: `${Color.errorRedTransparent}`, textAlign: textAlign as any} : {textAlign: textAlign as any} }
            className={className}
        />
    );
}

export default CurrencyFormatter;

