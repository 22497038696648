import Icon from 'view/Icon';
import React from 'react';
import styled, { css } from 'styled-components';
import Tooltip from 'components/UI/Tooltip';
import { Size } from 'lib/styles';
import { Main, Background } from 'styles/themeColors';

const Container = styled.div<any>`
  align-items: flex-start;
  display: flex;

  ${props => props.alignCenter && css`
    align-items: center;
  `}
`;

const Heading = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
`;

const IconCircle = styled.div<any>`
  align-items: center;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: flex;
  height: ${Size.medium};
  justify-content: center;
  margin-right: ${Size.tiny};
  width: ${Size.medium};
`;

const StyledIcon = styled(Icon).attrs({ size: 8 })`
  color: ${Background.white};
`;

const Text = styled.span`
  font-size: 0.875rem;
`;

const TextContainer = styled.div``;

const TooltipText = styled.div`
  font-size: 0.875rem;
`;

interface Props {
  className?: string;
  countType?: string;
  countTypePlural?: string;
  heading?: string;
  showTooltip?: boolean;
  validation: {
    count: number;
    recommended: number;
    required: number;
  };
}

function pluralizeCountType(
  countType: string,
  countTypePlural: string,
  count: number
) {
  if (count === 1) return countType;

  return countTypePlural;
}

export default function ValidationStatus({
  className,
  countType = 'word',
  countTypePlural = countType + 's',
  heading,
  showTooltip = true,
  validation: { count, recommended, required }
}: Props) {
  if (recommended === 0) {
    return null;
  }

  let icon = (
    <IconCircle color={Main.error}>
      <StyledIcon name="ValidationX" />
    </IconCircle>
  );
  if (count >= required) {
    icon = (
      <IconCircle color={Main.warning}>
        <StyledIcon name="ValidationExclamation" />
      </IconCircle>
    );
  }

  if (count >= recommended) {
    icon = (
      <IconCircle color={Main.success}>
        <StyledIcon name="ValidationCheck" />
      </IconCircle>
    );
  }

  if (showTooltip) {
    const tooltipContents = (
      <>
        <TooltipText>
          Required: {required}{' '}
          {pluralizeCountType(countType, countTypePlural, required)}
        </TooltipText>
        <TooltipText>
          Recommended: {recommended}{' '}
          {pluralizeCountType(countType, countTypePlural, recommended)}
        </TooltipText>
      </>
    );

    icon = <Tooltip content={tooltipContents}>{icon}</Tooltip>;
  }

  const outOf = count >= required ? recommended : required;

  let headingComponent = null;

  if (heading) {
    headingComponent = <Heading>{heading}</Heading>;
  }

  return (
    <Container alignCenter={heading === undefined} className={className}>
      {icon}
      <TextContainer>
        {headingComponent}
        <Text>
          {count} / {outOf}
        </Text>
      </TextContainer>
    </Container>
  );
};
