import React from 'react';
import Spinner from 'components/UI/Spinner';
import styled from 'styled-components';
import { Size } from 'lib/styles';

const Container = styled.p`
  font-style: italic;
  margin-bottom: ${Size.large};
  text-align: center;
`;

const CountText = styled.span`
  font-weight: 700;
`;

interface Props {
  className?: string;
  count: number | string;
  label: string;
  loading?: boolean;
  total?: number;
}

export default function Count({ className, count, total, label, loading }: Props) {
  let content = <Spinner size={24} />;

  if (!loading) {
    content = (
      <>
        <CountText>{count} {total ? ' of ' + total : null}</CountText> {label}
      </>
    );
  }

  return <Container>{content}</Container>;
}

Count.defaultProps = {
  loading: false
};
