import React from 'react';
import {
  STEPS,
  TEXT_LABELS,
} from '../../constants/productVariantImport';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import { Role } from 'data/User';
import DownloadXLS from 'common/XlsDownloadVariantTableNew';
import DownloadXLSAttribute from 'common/XlsDownloadAttributeValue';
import Product from 'data/Product';
import { productFileUrl } from '../../utils/product';
import styled from 'styled-components';
import Icon from 'view/Icon';
import { UploadingStatus } from './ProductVariantImportModal';
import { Color } from 'lib/styles';
import { Main } from 'styles/themeColors';
import FeatureFlag from 'data/FeatureFlag';
import Attribute from 'data/Attribute';

const StyledFileDownloadIcon = styled(Icon).attrs({
  name: 'FileDownloadIconWhite',
  size: 20,
})`
  color: white !important;
  margin: 0px 8px 10px 0px;
`;

const DownloadReport = styled.a`
  display: flex;
  height: 36px;
  background-color: black !important;
  color: white !important;
  border-color: #666c73 !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;

interface Props {
  step: number;
  showNextButton: Boolean;
  disableNextButton: boolean;
  gotoNextStep: () => void;
  uploadSuccess: Boolean;
  role: Role | undefined;
  product?: Product;
  attribute?: Attribute;
  uploadingStatus: UploadingStatus | undefined;
  handleClear: ()=> void;
  featureFlags?: FeatureFlag
}

export default function FooterComponent({
  step,
  showNextButton,
  disableNextButton,
  gotoNextStep,
  uploadSuccess,
  role,
  product,
  attribute,
  uploadingStatus,
  handleClear,
  featureFlags
}: Props) {
  return (
    <FlexboxContainerComponent
      padding="24px 0px"
      justifyContent={step === STEPS.COMPLETION ? 'space-between' : 'center'}
    >
      {/* {step === STEPS.VERIFICATION && (
        <Button
          id="modal-main-button"
          bgColor="#F9F9FA"
          color="#C82135"
          borderColor="#C82135"
          disabled={true}
          onClick={() => {
            // setCancelModal(true);
          }}
        >
          {TEXT_LABELS.CANCEL_DRAFT}
        </Button>
      )} */}
      {
        step === STEPS.COMPLETION && 
         ( <Button
          id="modal-main-button"
          bgColor={Main.white}
          borderColor={Color.black}
          color={Color.black}
          onClick={() => {
            handleClear()
          }}
        >
           {TEXT_LABELS.REUPLOAD}
        </Button>
          )
      }
      {showNextButton && step === STEPS.UPLOADING ? (
        <Button
          id="modal-main-button"
          bgColor={disableNextButton ? '#E9ECEF' : '#FC780B'}
          borderColor={disableNextButton ? '#E9ECEF' : '#FC780B'}
          color={disableNextButton ? '#BFC2C5' : '#FFFFFF'}
          onClick={gotoNextStep}
          disabled={disableNextButton}
        >
          {TEXT_LABELS.NEXT}
        </Button>
      ) : !uploadSuccess && step === STEPS.UPLOADING ? (
          attribute ? <DownloadXLSAttribute attribute={attribute} /> : <DownloadXLS product={product} role={role} rentFlag={featureFlags?.rent?.enabled}/>
      ) : !uploadingStatus?.passedCount && step === STEPS.COMPLETION && product ? (
        <DownloadReport
          href={productFileUrl(uploadingStatus?.validatedFileLocation as string)}
        >
          <StyledFileDownloadIcon />
          {TEXT_LABELS.DONWLOAD_ERROR_LOGS}
        </DownloadReport>
      ) : step === STEPS.COMPLETION && uploadingStatus?.passedCount && product  ? (
        <DownloadReport
          href={productFileUrl(uploadingStatus?.validatedFileLocation as string)}
        >
          <StyledFileDownloadIcon />
          {TEXT_LABELS.DOWNLOAD_REPORT}
        </DownloadReport>
      ) : (
        <></>
      )}
    </FlexboxContainerComponent>
  );
}