import React from 'react';
import styled from 'styled-components';
import { BorderRadius } from 'lib/styles';
import { Space } from 'styles/themeSpaces';
import { Background, Border, Text } from 'styles/themeColors';
import Button from 'components/UI/Button';
import FlexboxContainer from 'components/UI/FlexboxContainer';

const Container = styled.div<any>`
  margin: 20px 0;
  padding: ${Space.sp2x};
  background-color: ${Background.light};
  border: 1px solid ${Border.light};
  border-radius: ${BorderRadius.medium};
  width: fit-content;
  min-width: 400px;
`;

const ColumnName = styled.div`
  width: 126px;
  font-size: ${Space.sp2x};
  line-height: 20px;
  color: ${Text.primary};
  padding-top: ${Space.spBasePlus};
  margin-right: ${Space.sp3x}
`;

const ColumnValue = styled.div`
  font-size: ${Space.sp2x};
  line-height: 20px;
  color: ${Text.primary};
  padding-top: ${Space.spBasePlus};
`;


interface Props {
  rowName1: string;
  rowName2: string;
  rowName3?: string;
  rowName4?: string;
  rowValue1: string;
  rowValue2: string;
  rowValue3?: string;
  rowValue4?: string;
  onClick?: (event: any) => void;
};

const ConfirmOrder = ({
  rowName1,
  rowName2,
  rowName3,
  rowName4,
  rowValue1,
  rowValue2,
  rowValue3,
  rowValue4,
  onClick
}: Props) => {
  return (
    <Container>
      <FlexboxContainer flexDirection='colum' width='100%'>
        <FlexboxContainer
          alignItems='baseline'
          width='80%'
          flexWrap='wrap'
          flexDirection='column'
        >
          <FlexboxContainer>
            <ColumnName>{rowName1}</ColumnName>
            <ColumnValue>{rowValue1}</ColumnValue>
          </FlexboxContainer>
          <FlexboxContainer>
            <ColumnName>{rowName2}</ColumnName>
            <ColumnValue>{rowValue2}</ColumnValue>
          </FlexboxContainer>
          <FlexboxContainer>
            {
              rowName3 && <ColumnName>{rowName3}</ColumnName>
            }
            {
              rowValue3 && <ColumnValue>{rowValue3}</ColumnValue>
            }
          </FlexboxContainer>
          <FlexboxContainer>
            {
              rowName4 && <ColumnName>{rowName4}</ColumnName>
            }
            {
              rowValue4 && <ColumnValue>{rowValue4}</ColumnValue>
            }
          </FlexboxContainer>
        </FlexboxContainer>
        <FlexboxContainer justifyContent='flex-end' width='20%'>
          <Button
            isGhost
            isSecondary
            size='m'
            onClick={onClick}
            margin={`0 0 0 ${Space.spBase}`}
            borderColor={Border.main}
          >
            Edit
          </Button>
        </FlexboxContainer>
      </FlexboxContainer>
    </Container>
  )
};

export default ConfirmOrder;
