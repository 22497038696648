import { get } from 'lib/API';
import Company, { CompaniesList, CompanyHierarchy } from 'data/Company';

export const loadCompanies = async (
  [size, page, offset, vendorId]: any[],
  { token }: any
): Promise<CompaniesList> => {

  return await get('v1/companies', token, { size, page, offset, vendorId });
};

export const loadCompany = async ({
  companyId,
  token
}: any): Promise<Company> => {
  return await get(`v1/companies/${companyId}`, token);
};

export const loadHierarchy = async ([companyId]: any, { token }: any): Promise<CompanyHierarchy> => {
  return await get(`v1/companies/hierarchy/${companyId}`, token);
};

