export const CAMPAIGN_HEADERS = [
    "Title",
    "Country",
    "GTM id",
    "Campaign type",
    "Start date",
    "End date",
    "Launch company",
    "Launch person",
    "Budget",
    "Actual Spend"
];

export const TEXT_LABELS = {
    IMPORT_XLSX: 'Import XLSX',
    ADD_NEW_EVENTS: 'Add new events',
    PLEASE_SELECT_VENDORS: 'Please select vendors',
    EDIT_EVENT: 'Edit event',
}

export const IMPORT_STATUS = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED'
}

export const STATUS = {
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    PARTIAL: 'PARTIAL'
}