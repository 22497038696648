import { get } from 'lib/API';

export const getCountries = async (
    _: any[],
  { token }: any
) => {
    return await get('v1/countries', token);
}

export const getStates = async (countryIsoCode: any, { token }: any) => {
  return await get(`v1/countries/states/${countryIsoCode}`, token);
};