const columns = [
  { 
    title: 'Vendor', 
    field: 'name',
    defaultSort: 'asc'
  },
  { 
    title: 'Vendor-assigned ID', 
    field: 'vendorCompanyId'
  },
  { 
    title: 'Credit source system', 
    field: 'creditSourceSystem'
  }
];

export default columns;
