const companyPricebookColumns = [
  {
    title: 'Vendor',
    field: 'pricebookVendor',
    width: '8%',
    sort: true,
    sortKey : 'vendor.name'
  },
  {
    title: 'Hierarchy',
    field: 'pricebookType',
    width: '20%',
    sort: true,
    sortKey : 'priceBookType'
  },
  {
    title: 'Country',
    field: 'country.countryName',
    width: '10%',
    sort: true,
    sortKey : 'country.countryName'
  },
  {
    title: 'Assigned to',
    field: 'pricebookCompany',
    width: '15%',
    sort: true,
    sortKey : 'company.companyName'
  },
  {
    title: 'Name',
    field: 'pricebookName',
    width: '15%',
    sort: true,
    sortKey : 'priceBookName'
  },  
  {
    title: 'ID',
    field: 'pricebookId',
    width: '18%',
    sort: true,
    sortKey : 'priceBookVendorId'
  },
  {
    title: 'Published',
    field: 'pricebookPublishedData',
    type: 'date',
    dateSetting: { locale: 'en-US' },
    width: '10%',
    sort: true,
    sortKey: 'published'
  },
  {
    title: '',
    field: 'clearData',
    width: '4%',
    cellStyle: { padding: '20px 0' },
    sort: false,
  }
];

export default companyPricebookColumns;
