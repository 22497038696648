import { get, put, post } from 'lib/API';
import { OrdersList } from 'data/Order';

export const loadOrders = async (
  [size, page, offset, vendorId, orderId]: any[],
  { token }: any
): Promise<OrdersList> => {
  return await get('v1/orders', token, { size, page, offset, vendorId, orderId });
};

export const saveOrder = async ([order, dowJones]: any[], { token }: any): Promise<any> => {
  return await put(`v1/orders`, token, {
    order,
    dowJones
  });
};

export const getOrderDetails = async ({ orderId, token }: any): Promise<any> => {
  return await get(`v1/orders/${orderId}`, token);
};

export const cancellationOrderEmailRequest = async ([orderId]: any[], { token }: any): Promise<any> => {
  return await post(`v1/orders/cancellation-email`, token, { orderId });
};

export const callStockAPI = async (
  data: any,
  { token }: any
): Promise<any[]> => {
  return await post(`v1/consignments/stockAPI`, token, data);
};