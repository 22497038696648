import { isMaterialApiEnabled } from 'utils/materialNumber';
import { Role } from 'data/User';
import {
  PRODUCT_VARIANT_COLUMNS,
  PRODUCT_VARIANT_COLUMNS_WITH_MATERIAL_NUMBER,
  PRODUCT_VARIANT_COLUMNS_WITH_RENT_AND_MATERIAL,
  PRODUCT_VARIANT_COLUMNS_WITH_RENT_WITHOUT_MATERIAL,
} from 'constants/productColumns';
import readExcelFile from 'read-excel-file';
import Product, { ProductWarehouse } from 'data/Product';
import { COUNTRY_ISO } from 'constants/country';
import { WAREHOUSE_TYPE } from 'constants/warehouseDeliveryOptions';
import { PRODUCT_VARIANT_TYPE } from 'constants/productVariantImport';
import CountryState from 'data/CountryState';
import Country from 'data/Country';
import { isWeekend } from './date';

const PRICE_HEADERS: any = { US: 'USA price (USD)', CA: 'Canada price (CAD)' };
const PRICE_RANGE_HEADERS: any = { US: 'Price Range 1 (US) [Range]', CA: 'Price Range 1 (CA) [Range]' };

export const productFileUrl = (pathToFile: string) => {
  if(pathToFile)
    return (process.env.REACT_APP_MEDIA_URL || '') + '/product-variants/' + pathToFile
  return '';
};

const checkFixedHeaders = (
  product: Product,
  headers: string[],
  role: Role | undefined,
  rentFlag: boolean,
  variantsCount: number
) => {
  const isMaterialNumberEnabled = isMaterialApiEnabled(product?.brand?.brandVendor!, product?.vendor!);
  let fixedHeaders = [
    { name: 'SKU', index: headers.indexOf('SKU') },
    {
      name: 'Vendor Part Number',
      index: headers.indexOf('Vendor Part Number'),
    },
    { name: 'Purchase', index: headers.indexOf(PRODUCT_VARIANT_TYPE.PURCHASE) },
    ...(rentFlag ? [{ name: 'Rent', index: headers.indexOf(PRODUCT_VARIANT_TYPE.RENT) }] : []),
    { name: 'Item Type', index: headers.indexOf('Item Type') },
    { name: 'Hidden', index: headers.indexOf('Hidden') },
    { name: 'Weight', index: headers.indexOf('Weight') },
  ];

  if (isMaterialNumberEnabled) {
    fixedHeaders.push({
      name: 'Material Number',
      index: headers.indexOf('Material Number'),
    });
  }

  /* Validate Static Headers */
  function validateHeaders(fixedHeaders:any, rentFlag:boolean, isMaterialNumberEnabled:boolean) {
    let headersLengthToCheck;
    if (isMaterialNumberEnabled) {
      headersLengthToCheck = rentFlag
        ? PRODUCT_VARIANT_COLUMNS_WITH_RENT_AND_MATERIAL.length
        : PRODUCT_VARIANT_COLUMNS_WITH_MATERIAL_NUMBER.length;
    } else {
      headersLengthToCheck = rentFlag
        ? PRODUCT_VARIANT_COLUMNS_WITH_RENT_WITHOUT_MATERIAL.length
        : PRODUCT_VARIANT_COLUMNS.length;
    }

    return fixedHeaders.length === headersLengthToCheck;
  }

  const isValid = validateHeaders(fixedHeaders, rentFlag, isMaterialNumberEnabled!);
  if(!isValid){
    return false;
  }

  const haveSelectableAttribute = headers.find(header => header.includes('Selectable attribute'))
  if (!haveSelectableAttribute && (product.variants.length > 0 || variantsCount > 1)) {
    return false;
  }

  const result = fixedHeaders.map((item: any) => {
    if (item.index === -1) {
      return false;
    } else {
      return true;
    }
  });
  return !result.includes(false);
};

const checkDynamicHeaders = (product: Product, headers: string[]) => {
  for (const header of headers) {
    if(!header){
      return false;
    }
    if (header.startsWith('In stock') || header.startsWith('Lead days')) {
      const match = header.match(/"([^"]*)"/);
      if (match) {
        const warehouseCode = match[1].split(' ')[0];
        if (!product.warehouses.some(warehouse => warehouse.code === warehouseCode)) {
          return false; // Invalid warehouse
        }
      }
    } else if (header.startsWith('Selectable attribute')) {
      const match = header.match(/\[([^\]]*)\]/);
      if (match) {
        const attributeName = match[1];
        if (!product.attributes.some(attr => attr.name === attributeName && attr.isSelectable)) {
          return false; // Invalid selectable attribute
        }
      }
    } else if (header.startsWith('Technical specification')) {
      const match = header.match(/\[([^\]]*)\]/);
      if (match) {
        const attributeName = match[1];
        if (!product.attributes.some(attr => attr.name === attributeName && !attr.isSelectable)) {
          return false; // Invalid technical specification
        }
      }
    }
  }
  return true
};

/*
  checkPriceHeaders - Checks prices with respect to warehouse locations.
* @param {Object} product - The product object.
* @param {Array} headers - Array of header strings.
* @returns {Array} - Array of validation errors.
*/
const checkPriceHeaders = (product: Product, headers: string[]) => {
  const hasVariantsHavePurchaseType = product?.variants.some((variant) => variant.purchase );
if(hasVariantsHavePurchaseType){
  let currencies = product?.warehouses?.filter(wh => wh.type === WAREHOUSE_TYPE.PURCHASE).map((x) => x.address.countryIsoCode);
  currencies = Array.from(new Set(currencies));
  for (const currency of currencies) {
    const priceFound = headers.includes(PRICE_HEADERS[currency]);
    if (!priceFound) {
      return false
    }
  }
}
  return true
};

/*
  checkDuplicateWarehouses - Checks duplications in given warehouse related headers.
* @param {Array} warehouses - Array of warehouse objects.
* @returns {Array} - Array of validation errors.
*/
const checkDuplicateWarehouses = (warehouses: ProductWarehouse[]) => {
  const duplicates: any = {};
  for (let i = 0; i < warehouses.length; i++) {
    const warehouse: any = warehouses[i];
    const warehouseKey = `${warehouse?.code}-${warehouse?.type}`
    if (duplicates[warehouseKey]) {
      return false
    } else {
      duplicates[warehouseKey] = true;
    }
  }
  return true
};


/*
  checkPriceRangeHeaders - Checks price ranges with respect to warehouse locations.
* @param {Object} product - The product object.
* @param {Array} headers - Array of header strings.
* @returns {Array} - Array of validation errors.
*/
const checkPriceRangeHeaders = (product: Product, headers: string[], rentFlag: boolean) => {
  // TODO: remove commented code when feature is matured
  // let rentalCheck = product.variants.map((x) => x.rent);
  // if(rentalCheck){
  //   let countries = product.warehouses.map((x) => x.address.countryIsoCode);
  //   countries = Array.from(new Set(countries));
  //   for (const currency of countries) {
  // const priceRangeFound = headers.includes(PRICE_RANGE_HEADERS[currency]);
  // if (!priceRangeFound) {
  // return false
  // }
  //   }
  // }
  let check = true;
  for (const warehouse of product?.warehouses) {
    let warehouseCountryCode = warehouse?.address?.countryIsoCode;
    if (warehouseCountryCode === COUNTRY_ISO.US && warehouse.type === WAREHOUSE_TYPE.PURCHASE) {
      const priceFound = headers.includes(PRICE_HEADERS[COUNTRY_ISO.US]);
      if (!priceFound) {
        check = false;
        break;
      }
    } else if (warehouseCountryCode === COUNTRY_ISO.CA && warehouse.type === WAREHOUSE_TYPE.PURCHASE) {
      const priceFound = headers.includes(PRICE_HEADERS[COUNTRY_ISO.CA]);
      if (!priceFound) {
        check = false;
        break;
      }
    } else if (warehouseCountryCode === COUNTRY_ISO.US && warehouse.type === WAREHOUSE_TYPE.RENT && rentFlag) {
      const priceRangeFound = headers.includes(PRICE_RANGE_HEADERS[COUNTRY_ISO.US]);
      if (!priceRangeFound) {
        check = false;
        break;
      }
    } else if (warehouseCountryCode === COUNTRY_ISO.CA && warehouse.type === WAREHOUSE_TYPE.RENT && rentFlag) {
      const priceRangeFound = headers.includes(PRICE_RANGE_HEADERS[COUNTRY_ISO.CA]);
      if (!priceRangeFound) {
        check = false;
        break;
      }
    }
  }
  return check;
};

export const verifyProductFileTemplate = async (
  file: File | null,
  product: Product,
  role: Role | undefined,
  rentFlag: boolean,
) => {
  if (!file) {
    return false;
  }
  const productFileData = await readExcelFile(file);
  const fixedHeadersResult = checkFixedHeaders(product, productFileData[0], role, rentFlag, productFileData.length ? productFileData.length - 1 : 0);
  const dynamicHeadersResult = checkDynamicHeaders(product, productFileData[0]);
  const priceHeadersResult = checkPriceHeaders(product, productFileData[0]);
  const duplicateWarehousesResult = checkDuplicateWarehouses(product.warehouses);
  const priceRangeHeadersResult = checkPriceRangeHeaders(product, productFileData[0], rentFlag);
  return fixedHeadersResult && dynamicHeadersResult && priceHeadersResult && duplicateWarehousesResult && priceRangeHeadersResult;

};

export const stripHtml = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

export const getOptions = (rentFlag: boolean) => {
  const purchaseOption = { value: PRODUCT_VARIANT_TYPE.PURCHASE };
  if (rentFlag) {
    return [{ value: PRODUCT_VARIANT_TYPE.ALL }, { value: PRODUCT_VARIANT_TYPE.RENTAL }, purchaseOption];
  }
  return [purchaseOption];
};

export const compareWarehouseIds = (arr1: number[], arr2: number[]) => {
  if (arr1.length !== arr2.length) return false;

  const sortedArr1 = [...arr1].sort((a, b) => a - b);
  const sortedArr2 = [...arr2].sort((a, b) => a - b);

  return sortedArr1.every((value, index) => value === sortedArr2[index]);
};

interface Item {
  key: string,
  hidden: boolean,
  title: string
}

export const compareObjectArraysUnordered = (arr1: Item[], arr2: Item[]): boolean => {
  if (arr1.length !== arr2.length) return false;

  return arr1.every((item, index) => {
    return item.key === arr2[index].key;
  });
};

export const getCountryStatesForSelectionList = (countryStateList: CountryState[], countryIsoCode: string) => {
  return countryStateList.filter(countryState => countryState.countryIsoCode === countryIsoCode).map(countryState => ({ name: countryState.state, id: countryState.id }));
};

export const mergeRestrictedStateIds = (countries: Country[], restrictedCountryStates: Record<string, number[]>): number[] => {
  return countries.reduce((acc, country) => {
    const countryIso = country.countryIso!;
    if (countryIso in restrictedCountryStates) {
      return acc.concat(restrictedCountryStates[countryIso] || []);
    }
    return acc;
  }, [] as number[]);
}
export const onlyWeekdaysAllowed = (date: any, weekdays: boolean) => {
  return weekdays ? isWeekend(date) : false
}