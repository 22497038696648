import Icon from 'view/Icon';
import React from 'react';
import styled, { css } from 'styled-components';
import { Size } from 'lib/styles';
import { Main, Background } from 'styles/themeColors';

const Container = styled.div<any>`
  align-items: flex-start;
  display: flex;

  ${props => props.alignCenter && css`
      align-items: center;
  `}
`;

const Heading = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
`;

const IconCircle = styled.div<any>`
  align-items: center;
  background-color: ${props => props.color};
  border-radius: 50%;
  display: flex;
  height: ${Size.medium};
  justify-content: center;
  margin-right: ${Size.tiny};
  width: ${Size.medium};
`;

const StyledIcon = styled(Icon).attrs({ size: 8 })`
  color: ${Background.white};
`;

const Text = styled.span`
  font-size: 0.875rem;
`;

const TextContainer = styled.div``;

interface Props {
  className?: string;
  heading?: string;
  variantsValidation?: number;
};

export default function VariantsValidationStatus({
  className,
  heading,
  variantsValidation
}: Props) {
  let icon = (
    <IconCircle color={variantsValidation === 0 ? Main.success : Main.error}>
      <StyledIcon name={variantsValidation === 0 ? 'ValidationCheck' : 'ValidationX'} />
    </IconCircle>
  );

  let headingComponent = null;

  if (heading) {
    headingComponent = <Heading>{heading}</Heading>;
  }

  return (
    <Container alignCenter={heading === undefined} className={className}>
      {icon}
      <TextContainer>
        {headingComponent}
        <Text>
          {variantsValidation}
        </Text>
      </TextContainer>
    </Container>
  );
};
