/* eslint-disable react/display-name, react/prop-types */

import React from 'react';
import { AsyncState } from 'react-async';
import Vendor from 'data/Vendor';
import FeatureFlag, { FeatureFlagValues } from 'data/FeatureFlag';
import User, { UserPreferences } from 'data/User';
import AccountSettings from 'common/AccountSettings';
import Dashboard from 'common/Dashboard';
import ProductDetails from 'common/ProductDetails'
import ProductList from 'common/ProductList';
import ProductReview from 'common/ProductReview';
import BrandDetails from 'common/Brands/BrandDetails';
import OnboardingPage from 'OnboardingComponents/OnboardingPage';
import AttributesList from 'admin/AttributesList';
import BrandsList from 'common/Brands/BrandsList';
import WarehouseList from 'common/WarehouseList';
import OrderDetails from 'components/Consignments/OrderDetails';
import CompaniesList from 'common/Companies/CompaniesList';
import CompanyDetails from 'common/Companies/CompanyDetails';
import CustomerDetails from 'common/Customers/CustomerDetails';
import CustomersList from 'common/Customers/CustomersList';
import OrdersList from '../components/Orders/OrderList/OrdersList';
import PowerBiReports from 'common/PowerBiReports/PowerBiReports';
import NotFound from 'components/UI/NotFound';
import { grantAccess } from 'utils/authorization';
import OrderDocuments from 'components/Consignments/OrderDocuments';
import PricebooksTab from 'common/Companies/PricebooksTab';
import VendorSettings from 'components/Vendor/VendorSettings';
import ProductListV2 from '../components/Product/ProductListing/ProductListingComponent';
import ProductDetailsNewDesign from 'common/ProductDetailsV2';
import CampaignList from 'components/Campaign/CampaignList';

interface EditorProps {
  selectedVendor: Vendor;
  updateVendor: (name: string) => void;
  featureFlags: FeatureFlag;
  userPreferences: UserPreferences;
  userPreferencesReq: AsyncState<any>;
  vendorsList: Vendor[];
  user: User;
};

const routes = {
  '/': () => ({ selectedVendor }: EditorProps) => <Dashboard selectedVendor={selectedVendor} />,
  '/account*': () => () => <AccountSettings />,
  '/brands': () => ({ selectedVendor }: EditorProps) => <BrandsList selectedVendor={selectedVendor} />,
  '/warehouses': () => ({ selectedVendor, updateVendor, featureFlags }: EditorProps) => <WarehouseList selectedVendor={selectedVendor} updateVendor={updateVendor} featureFlags={featureFlags} />,
  '/products': () => ({
    selectedVendor,
    vendorsList,
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.PRODUCT_V2, user)
      ? <ProductListV2 vendorsList={vendorsList} selectedVendor={selectedVendor} />
      : <ProductList base="/products/" editable status="draft" selectedVendor={selectedVendor} />,
  '/products/page/:page': ({ page }: any) => ({ selectedVendor }: EditorProps) => (
    <ProductList base="/products/" editable page={page} status="draft" selectedVendor={selectedVendor} />
  ),
  '/products/ready': () => ({ selectedVendor }: EditorProps) => (
    <ProductList base="/products/ready/" status="ready" selectedVendor={selectedVendor} />
  ),
  '/products/ready/page/:page': ({ page }: any) => ({ selectedVendor }: EditorProps) => (
    <ProductList
      page={page}
      base="/products/ready/"
      status="ready"
      selectedVendor={selectedVendor}
    />
  ),
  '/products/published': () => ({ selectedVendor }: EditorProps) => (
    <ProductList base="/products/published/" status="published" selectedVendor={selectedVendor} />
  ),
  '/products/published/page/:page': ({ page }: any) => ({ selectedVendor }: EditorProps) => (
    <ProductList
      page={page}
      base="/products/published/"
      status="published"
      selectedVendor={selectedVendor}
    />
  ),
  '/products/new-product': () => ({ selectedVendor, updateVendor, featureFlags, vendorsList }: EditorProps) =>
    featureFlags?.productV2?.enabled ? (
      <ProductDetailsNewDesign
        selectedVendor={selectedVendor}
        updateVendor={updateVendor}
        featureFlags={featureFlags}
        vendorsList={vendorsList}
        view='newProduct'
      />
    ) : (
      <ProductDetails selectedVendor={selectedVendor} updateVendor={updateVendor} featureFlags={featureFlags} />),
  '/products/new-product/variants': () => ({ selectedVendor, updateVendor, vendorsList, featureFlags }: EditorProps) =>
    featureFlags?.productV2?.enabled ? (<ProductDetailsNewDesign
      view="variants"
      selectedVendor={selectedVendor}
      vendorsList={vendorsList}
      updateVendor={updateVendor}
      featureFlags={featureFlags}
    />
    ) :
      (
        <ProductDetails view="variants" selectedVendor={selectedVendor} vendorsList={vendorsList} updateVendor={updateVendor} featureFlags={featureFlags} />
      ),
  '/products/new-product/preview': () => ({ selectedVendor, updateVendor, vendorsList, featureFlags }: EditorProps) =>
    featureFlags?.productV2?.enabled ? (
      <ProductDetailsNewDesign
        view="preview"
        selectedVendor={selectedVendor}
        vendorsList={vendorsList}
        updateVendor={updateVendor}
        featureFlags={featureFlags}
      />
    ) :
      (
        <ProductDetails view="preview" selectedVendor={selectedVendor} vendorsList={vendorsList} updateVendor={updateVendor} featureFlags={featureFlags} />
      ),
  '/products/product-details/:id/preview': ({ id }: any) => ({ selectedVendor, updateVendor, featureFlags }: EditorProps) =>
    featureFlags?.productV2?.enabled ? (
      <ProductDetailsNewDesign
        view="preview"
        productId={id}
        selectedVendor={selectedVendor}
        updateVendor={updateVendor}
        featureFlags={featureFlags}
      />
    ) :
      (
        <ProductDetails view="preview" productId={id} selectedVendor={selectedVendor} updateVendor={updateVendor} featureFlags={featureFlags} />
      ),
  '/products/product-details/:id/variants': ({ id }: any) => ({ selectedVendor, updateVendor, featureFlags }: EditorProps) =>
    featureFlags?.productV2?.enabled ? (
      <ProductDetailsNewDesign
        view="variants"
        productId={id}
        selectedVendor={selectedVendor}
        updateVendor={updateVendor}
        featureFlags={featureFlags}
      />
    ) :
      (
        <ProductDetails view="variants" productId={id} selectedVendor={selectedVendor} updateVendor={updateVendor} featureFlags={featureFlags} />
      ),
  '/products/product-details/:id/view': ({ id }: any) => () => <ProductReview productId={id} />,
  '/products/product-details/:id': ({ id }: any) => ({ selectedVendor, updateVendor, featureFlags }: EditorProps) =>
    featureFlags?.productV2?.enabled ? (
      <ProductDetailsNewDesign
        productId={id}
        selectedVendor={selectedVendor}
        updateVendor={updateVendor}
        featureFlags={featureFlags}
      />
    ) : (
      <ProductDetails productId={id} selectedVendor={selectedVendor} updateVendor={updateVendor} featureFlags={featureFlags} />
    ),

  '/products/product-details/:id/warehouses':
    ({ id }: any) =>
      ({ selectedVendor, updateVendor, vendorsList, featureFlags }: EditorProps) =>
        featureFlags?.productV2?.enabled ? (
          <ProductDetailsNewDesign
            view="warehouses"
            productId={id}
            selectedVendor={selectedVendor}
            vendorsList={vendorsList}
            updateVendor={updateVendor}
            featureFlags={featureFlags}
          />
        ) : (
          <ProductDetails
            productId={id}
            selectedVendor={selectedVendor}
            vendorsList={vendorsList}
            updateVendor={updateVendor}
            featureFlags={featureFlags}
          />
        ),

  '/brands/brand-details/:id': ({ id }: any) => ({ selectedVendor, updateVendor, vendorsList, featureFlags }: EditorProps) => (
    <BrandDetails brandId={id} selectedVendor={selectedVendor} vendorsList={vendorsList} updateVendor={updateVendor} featureFlags={featureFlags} />
  ),
  '/brands/new-brand': () => ({ selectedVendor, updateVendor, vendorsList, featureFlags }: EditorProps) => (
    <BrandDetails selectedVendor={selectedVendor} vendorsList={vendorsList} updateVendor={updateVendor} featureFlags={featureFlags} />
  ),
  '/onboarding/welcome': () => () => <OnboardingPage view='welcome' />,
  '/onboarding/process-guidelines': () => () => <OnboardingPage view='process-guidelines' />,
  '/onboarding/product-guidelines': () => () => <OnboardingPage view='product-guidelines' />,
  '/onboarding/seller-guidelines': () => () => <OnboardingPage view='seller-guidelines' />,
  '/onboarding/brand-communication': () => () => <OnboardingPage view='brand-communication' />,
  '/onboarding/promo-resources': () => () => <OnboardingPage view='promo-resources' />,
  '/onboarding*': () => () => <OnboardingPage view='' />,
  '/attributes': () => ({ selectedVendor }: EditorProps) => <AttributesList selectedVendor={selectedVendor} />,
  '/reports': () => ({ selectedVendor, featureFlags, vendorsList }: EditorProps) => <PowerBiReports vendorsList={vendorsList} selectedVendor={selectedVendor} featureFlags={featureFlags} />,
  '/orders': () => ({
    selectedVendor,
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.ORDER, user)
      ? <OrdersList
        featureFlags={featureFlags}
        selectedVendor={selectedVendor}
      />
      : <NotFound />,

  '/orders/page/:page': ({ page }: any) => ({
    selectedVendor,
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.ORDER, user)
      ? <OrdersList
        featureFlags={featureFlags}
        selectedVendor={selectedVendor}
        page={page}
      />
      : <NotFound />,

  '/orders/order-details/:id?': ({ id }: any) => ({
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.ORDER, user)
      ? <OrderDetails
        consignmentId={'cons' + id}
        featureFlags={featureFlags}
      />
      : <NotFound />,

  '/orders/order-details/:id/document/:documentId': ({ id }: any) => ({
    selectedVendor,
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.ORDER, user)
      ? <OrderDocuments
        consignmentId={'cons' + id}
        selectedVendor={selectedVendor}
      />
      : <NotFound />,

  '/orders/order-details/:id/new-document': ({ id }: any) => ({
    selectedVendor,
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.ORDER, user)
      ? <OrderDocuments
        consignmentId={'cons' + id}
        selectedVendor={selectedVendor}
      />
      : <NotFound />,

  '/companies': () => ({
    selectedVendor,
    featureFlags,
    userPreferences,
    userPreferencesReq,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.COMPANY, user)
      ? <CompaniesList
        selectedVendor={selectedVendor}
        userPreferences={userPreferences}
        userPreferencesReq={userPreferencesReq}
      />
      : <NotFound />,

  '/companies/page/:page': ({ page }: any) => ({
    selectedVendor,
    featureFlags,
    userPreferences,
    userPreferencesReq,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.COMPANY, user)
      ? <CompaniesList
        selectedVendor={selectedVendor}
        page={page}
        userPreferences={userPreferences}
        userPreferencesReq={userPreferencesReq}
      />
      : <NotFound />,

  '/companies/company-details/:id': ({ id }: any) => ({
    selectedVendor,
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.COMPANY, user)
      ? <CompanyDetails
        featureFlags={featureFlags}
        companyId={id}
        selectedVendor={selectedVendor}
      />
      : <NotFound />,

  '/customers/customer-details/:id': ({ id }: any) => ({
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.COMPANY, user)
      ? <CustomerDetails
        customerId={id}
        featureFlags={featureFlags}
      />
      : <NotFound />,

  '/customers': () => ({
    selectedVendor,
    userPreferences,
    userPreferencesReq,
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.COMPANY, user)
      ? <CustomersList
        selectedVendor={selectedVendor}
        userPreferences={userPreferences}
        userPreferencesReq={userPreferencesReq}
        featureFlags={featureFlags}
      />
      : <NotFound />,
  '/customers/page/:page': ({ page }: any) => ({
    selectedVendor,
    userPreferences,
    userPreferencesReq,
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.COMPANY, user)
      ? <CustomersList
        selectedVendor={selectedVendor}
        page={page}
        userPreferences={userPreferences}
        userPreferencesReq={userPreferencesReq}
        featureFlags={featureFlags}
      />
      : <NotFound />,
  '/pricebooks': () => ({
    selectedVendor,
    featureFlags,
    user
  }: EditorProps) => grantAccess(featureFlags, FeatureFlagValues.PRICEBOOK, user)
      ?
      <PricebooksTab
        selectedVendor={selectedVendor}
        isPricebookPage={true}
        companyCustomers={[]}
      />
      : <NotFound />,
  '/settings': () => ({ selectedVendor }: EditorProps) => <VendorSettings selectedVendor={selectedVendor} />,

  '/marketing':
    () =>
    ({ selectedVendor, featureFlags, user,vendorsList }: EditorProps) =>
      grantAccess(featureFlags, FeatureFlagValues.MARKETING, user) ? (
        <CampaignList
          selectedVendor={selectedVendor}
          vendorsList={vendorsList}
        />
      ) : (
        <NotFound />
      ),

  '*': () => () => <NotFound />
};

export default routes;
