import React, { useEffect, useState } from 'react';
import Select, { Option } from 'components/UI/Select';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import Thumbnail from 'components/UI/Thumbnail';
import { parseYoutubeThumbnailUrl } from 'utils/resources';
import { RESOURCES_TEXT_LABELS, RESOURCE_ERROR_MESSAGES } from 'constants/resources';
import styled from 'styled-components';
import { BorderRadius, Color } from 'lib/styles';
import { useAPI } from 'lib/API';
import { deleteResource, getAllResources, getVendorsResources, getYoutubeVideoTitle } from 'requests/resource';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import { Resource, ResourceFormValidator, emptyFormObject, emptyResourceObject } from 'data/Resource';
import Vendor from 'data/Vendor';
import { Background, Main } from 'styles/themeColors';
import Input from 'components/UI/Input';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import ConfirmModal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { VendorsResources } from 'data/vendorResources';
import { v4 } from 'uuid';

const StyledInput = styled(Input)`
  input {
    background-color: ${Background.white};
    border-radius: ${BorderRadius.medium};
    height: 40px;
  }

  span {
    color: ${Color.darkGray};
    font-size: 15px;
  }
`;

interface Props {
    vendorsList?: Vendor[];
    setSelectedResource?: (resource: Resource) => void;
    refreshResources: () => void;
    newResources: VendorsResources[]
    setNewResources: (resources: VendorsResources[]) => void;
  }

export default function ResourceVideoForm({
    vendorsList,
    setSelectedResource,
    refreshResources,
    newResources,
    setNewResources
}: Props) {
    const [options, setOptions] = useState<Option[]>([]);
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [resources, setResources] = useState<Resource[]>([]);
    const [resourceForm, setResourceForm] = useState(emptyResourceObject);
    const [formValidation, setFormValidation] = useState<ResourceFormValidator>(emptyFormObject);
    const [resourceToDelete, setResourceToDelete] = useState<number | string>();
    const [isValidatingUrl, setIsValidatingUrl] = useState(false);

    const getAllResourcesReq = useAPI({
        deferFn: getAllResources,
            onResolve: (result) => {
            setResources(result)
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    const getVendorsResourcesReq = useAPI({
        deferFn: getVendorsResources,
            onResolve: (result) => {
            setResources(result);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    const loading = getAllResourcesReq.isLoading || getVendorsResourcesReq.isLoading;

     const selectVendorHandler = (event: any) => {
        setFormValidation({...formValidation, vendorIds: event.target.value.length === 0})
        setSelectedVendors(event.target.value);
    }

    const validateForm = () => {
        return (Object.values(formValidation).some(value => value === true) || !resourceForm.resourceUrl) && !isValidatingUrl;
    }

    const deleteResourceReq = useAPI({
        deferFn: deleteResource,
        onResolve: (result) => {
            renderToast(toast.TYPE.SUCCESS, RESOURCES_TEXT_LABELS.RESOURCE_DELETED_SUCCESSFULLY);
            getAllResourcesReq.run();
            refreshResources();
            setSelectedVendors([]);
        },
        onReject: err => {
        renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    useEffect(() => {
        if(vendorsList){
            const mappedOptions = vendorsList?.map(x => {
                return {id: x.id.toString(), value: x.name} as Option;
            }) || [];
            setOptions([{value: RESOURCES_TEXT_LABELS.ALL}, ...mappedOptions]);
        }
    }, [vendorsList]) 

    useEffect(() => {
        if(selectedVendors.length === 0){
            getAllResourcesReq.run();
        }else{
            if(selectedVendors.includes(RESOURCES_TEXT_LABELS.ALL as never)){
                if(selectedVendors.length === 1){
                    const vendors = vendorsList?.filter(x => x.name && selectedVendors.includes(x.name as never));
                    getVendorsResourcesReq.run(vendors?.map(x => x.id));
                }else{
                    if(selectedVendors[0] === RESOURCES_TEXT_LABELS.ALL){
                        setSelectedVendors(selectedVendors.filter(x => x !== RESOURCES_TEXT_LABELS.ALL))
                        const vendors = vendorsList?.filter(x => x.name && selectedVendors.includes(x.name as never));
                        getVendorsResourcesReq.run(vendors?.map(x => x.id));
                    }else{
                        setSelectedVendors([RESOURCES_TEXT_LABELS.ALL as never])
                        const vendors = vendorsList?.filter(x => x.name && selectedVendors.includes(x.name as never));
                        getVendorsResourcesReq.run(vendors?.map(x => x.id));
                    }
                }
            }else{
                const vendors = vendorsList?.filter(x => x.name && selectedVendors.includes(x.name as never));
                getVendorsResourcesReq.run(vendors?.map(x => x.id));
            }
        }
    }, [selectedVendors]);

    const validateField = (col: string, value: string) => {
        switch (col) {
            case 'resourceUrl':
                return !value;
            default:
                return false;
        }
    };

    const handleDeleteResource = (event: any, id: number | string) => {
        event.stopPropagation();
        setResourceToDelete(id);
    }

    const onInputChange = (col: string, value: string) => {
        setFormValidation(prevState => ({
            ...prevState,
            [col]: validateField(col, value)
        }));

        setResourceForm(prevState => ({
            ...prevState,
            [col]: value
        }));
    };

    const deleteLocalResource = (resourceId: string | undefined | number) => {
        let localResources = newResources.filter(x => x.resource.id !== resourceId);
        setNewResources(localResources);
    } 

    const deleteResourceFn = () => {
        let resourceId = resourceToDelete;
        if(newResources.find(x => x.resource.id === resourceId)){
            deleteLocalResource(resourceId);
        }else{
            deleteResourceReq.run(resourceId);
        }
        setResourceToDelete(undefined);
    }

    const handleAddVideo = async () => {
        let resourceTitle = resourceForm.resourceTitle
        setIsValidatingUrl(true);
        const youtubePayload = await getYoutubeVideoTitle(resourceForm.resourceUrl);
        setIsValidatingUrl(false);

        if(youtubePayload?.error){
            setFormValidation({...formValidation, resourceUrl: true});
            return;
        }else{
            if(!resourceTitle){
                resourceTitle = youtubePayload.title;
            }
        }

        const resource = {
            id: v4(),
            title: resourceTitle,
            url: resourceForm.resourceUrl
        }
        const vendors = vendorsList?.filter(x => x.name && selectedVendors.includes(x.name as never));
        const vendorIds = vendors?.map(x => x.id) || [];
        const isGlobal = selectedVendors.includes(RESOURCES_TEXT_LABELS.ALL as never)
        setNewResources([...newResources, {resource, vendorIds, isGlobal}])
        setFormValidation({...formValidation, resourceTitle: false, resourceUrl: false});
        setResourceForm(emptyResourceObject);
    }

    const deleteResourceModal = (
        <ConfirmModal
          showModal={!!resourceToDelete}
          onClose={() => setResourceToDelete(undefined)}
          onClick={deleteResourceFn}
          title={RESOURCES_TEXT_LABELS.REMOVE_VIDEO}
          ghostButtonText="Cancel"
        >
          <>{RESOURCES_TEXT_LABELS.REMOVE_VIDEO_CONFIMRATION}</>
        </ConfirmModal>
      );

    return(
        <>
            <Select
                label="Vendor"
                labelPosition='top'
                options={options}
                onChange={selectVendorHandler}
                value={selectedVendors}
                defaultValue="Select vendors"
                multiple
                width='50%'
            />
            <FlexboxContainerComponent
                flexDirection='row'
                flexWrap='wrap'
                margin='20px 0px'
            >
            {
                loading ?
                <LoadingIndicator size={50} /> :
                [...resources, ...newResources].length === 0 ?
                <FlexboxContainer
                    padding="25px"
                    border={`1px solid ${Main.lightGray}`}
                    borderRadius='8px'
                    alignContent="center"
                    justifyContent='center'
                    width='100%'
                >
                    <text style={{ color: `${Color.shuttleGray}`, fontStyle: 'italic' }}> {RESOURCES_TEXT_LABELS.NO_RESOURCES_FOR_VENDORS}</text>
                </FlexboxContainer>
                :
                [...resources, ...newResources.map(x => x.resource)].map((resource)=>(
                <Thumbnail 
                    title={resource.title} 
                    url={parseYoutubeThumbnailUrl(resource.url) || ''} 
                    onDelete={(e: any) => handleDeleteResource(e, resource.id!)} 
                    onClick={() => setSelectedResource?.(resource)}  
                    margin='5px'
                />
                ))
            }
            </FlexboxContainerComponent>
            <FlexboxContainerComponent
            flexDirection='row'
            gap='5px'
            alignItems='flex-start'
            margin='30px 0px'
            >
            <StyledInput
                id='video-link'
                labelName="Video link"  
                placeholder="Enter embed link"
                onInputChange={(e) => onInputChange('resourceUrl', e)}
                value={resourceForm.resourceUrl}
                width="100%"
                isInvalid={formValidation.resourceUrl}
                errorMessage={RESOURCE_ERROR_MESSAGES.INVALID_URL}

            />
            <StyledInput
                id='video-title'
                labelName="Video title"
                placeholder="Enter title for the video"
                onInputChange={(e) => onInputChange('resourceTitle', e)}
                value={resourceForm.resourceTitle}
                width="100%"
                labelText='If left blank, YouTube title will be shown.'
            />
            </FlexboxContainerComponent>
            <Button
                isGhost
                color={Main.black}
                onClick={handleAddVideo}
                borderColor={Main.black}
                disabled={validateForm()}
            >
                Add video
            </Button>
            {deleteResourceModal}
        </>
    );
}