export enum Space {
  spQuarter = '2px',
  spHalf = '4px',
  spHalfPlus = '6px',
  spBase = '8px',
  spBasePlus = '12px',
  sp2x = '16px',
  sp2xPlus = '20px',
  sp3x = '24px',
  sp3xPlus = '28px',
  sp4x = '32px',
  sp4xPlus = '36px',
  sp5x = '40px',
  sp6x = '48px',
  sp7x = '56px',
  sp8x = '64px'
};
