import React, { Fragment } from 'react';

import { Heading2, Heading3, Paragraph, CardCallout, ListItem, List } from 'OnboardingComponents/Elements';

const ProductDescription = () => {
  return (
    <Fragment>
      <Heading2 id="product-description">Product description</Heading2>
      <Paragraph>It is highly important that you show potential buyers what your product brings to the table. Therefore, your product descriptions must be compelling, well-structured, and highlight each product’s unique advantages.</Paragraph>
      <Paragraph>When writing your “pitch” for each product, make sure you keep it concise. A good description has an average length of only 100 words and answers the questions below:</Paragraph>
      <Heading3>Marketing intro</Heading3>
      <List>
        <ListItem>What is the product for? What problem does it solve?</ListItem>
        <ListItem>What is the product’s main differentiator?</ListItem>
      </List>
      <Heading3>Technical details</Heading3>
      <List>
        <ListItem>How does the product solve its target problem?</ListItem>
        <ListItem>What secondary benefits does it bring?</ListItem>
        <ListItem>What products does it work best with?
          <List>
            <ListItem>This will prove very valuable for your cross-selling strategy. Make sure you always have in mind not only the products that you offer on OFMP, but those offered by other brands. Find the areas where your products are complementary and use both your product descriptions and attached technical documents to make those links clear to potential buyers.</ListItem>
          </List>
        </ListItem>
        <ListItem>You can list the technical details using bullet points, so that the user has an easy time reading them. Always capitalize the first word. Use a period (full stop) after every bullet point that is a sentence. Use no punctuation after bullets that are not sentences. Make sure they are consistent in their expressions; for example, if one bullet point contains a verb and is actionable, then all the others should, too.</ListItem>
      </List>
      <Heading3>Sales outro</Heading3>
      <List>
        <ListItem>Add any data or statistics that support the value and efficacy of your product.</ListItem>
        <ListItem>End with a call to action. Give the buyer some clear instructions as to what they need to do next.</ListItem>
      </List>
      <CardCallout>
        <Heading3>Example of product description:</Heading3>
        <Paragraph>The following product description is a good example of how to compile the main components touched upon in this section into a description that sells: </Paragraph>
        <Paragraph bold italic>Looking to extend cable life while reducing slippage and vibrations? Safety and functionality join in a durable, no-corrosion sheave: the new SX Greaseless Series™ from SABTEX SHEAVES.</Paragraph>
        <Paragraph bold italic>SABTEX SHEAVES has combined LED illumination, metallic safety colors, built-in handles, and Easy-Slip™ side loading gates to give their products the dependability you need across a wide spectrum of conditions. While mainly designed for recently-released coated wireline cables, these innovative sheaves are compatible with any and all conventional E-lines. A lightweight, thin profile frame and greaseless double-sealed bearing make for a firm grasp. </Paragraph>
        <Paragraph bold italic>This is anything but a short-term investment. The SX Greaseless Series™ Wireline Sheaves are manufactured with nylon composite material and fitted with stainless steel components that form a shield against any kind of corrosion. </Paragraph>
        <Paragraph bold italic>More technical details in the attached PDF. </Paragraph>
        <Paragraph bold italic>Configure your new SABTEX SHEAVES wireline sheave now using the drop-down options on the right and take advantage of their quick delivery. </Paragraph>
        <Paragraph bold italic>Remember, each sheave comes with individual test certificates and a one-year manufacturer warranty!</Paragraph>
      </CardCallout>
    </Fragment>
  );
};

export default ProductDescription;
