import { setProperty } from 'dot-prop';
import apiConfig from 'redux/epics/apiConfig';
import { State } from 'redux/types/state';
import {
  ApiCallStarted,
  ApiCallFinished,
  ApiCallFailed
} from 'redux/types/actions';

export const apiCallStarted = (
  state: State[keyof State],
  action: ApiCallStarted,
  rootKey: keyof State
) => {
  const config = apiConfig[action.stateKey];

  if (rootKey === config.rootKey) {
    setProperty(state, `${action.stateKey}.isLoading`, true);
    setProperty(state, `${action.stateKey}.loadingFinished`, false);
    setProperty(state, `${action.stateKey}.isError`, false);
    setProperty(state, `${action.stateKey}.error`, null);
    setProperty(state, `${action.stateKey}.data`, null);
  }
};

export const apiCallFinished = (
  state: State[keyof State],
  action: ApiCallFinished,
  rootKey: keyof State
) => {
  const config = apiConfig[action.stateKey];

  if (rootKey === config.rootKey) {
    setProperty(state, `${action.stateKey}.isLoading`, false);
    setProperty(state, `${action.stateKey}.loadingFinished`, true);
    setProperty(state, `${action.stateKey}.isError`, false);
    setProperty(state, `${action.stateKey}.data`, action.response);
    setProperty(state, `${action.stateKey}.error`, null);
  }
};

export const apiCallFailed = (
  state: State[keyof State],
  action: ApiCallFailed,
  rootKey: keyof State
) => {
  const config = apiConfig[action.stateKey];

  if (rootKey === config.rootKey) {
    setProperty(state, `${action.stateKey}.isLoading`, false);
    setProperty(state, `${action.stateKey}.loadingFinished`, true);
    setProperty(state, `${action.stateKey}.isError`, true);
    setProperty(state, `${action.stateKey}.error`, null);
    setProperty(state, `${action.stateKey}.data`, action.error);
  }
};
