import React from 'react';
import styled from 'styled-components';
import { CheckCircle, WarningRounded, Block } from '@material-ui/icons';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { formatDateTime } from 'utils/formatters';
import {
  DOW_JONES_CLEAR,
  DOW_JONES_BLOCKED,
  DOW_JONES_REVIEWED_CLEAR,
  DOW_JONES_REVIEWED_BLOCKED
} from 'constants/dowJonesStatuses';
import { Space } from 'styles/themeSpaces';
import { Height } from 'styles/themeHeights';
import { Main, Text, Border, Background } from 'styles/themeColors'
import { MainColors } from 'types/enumTypes';

const CardContainer = styled.div`
  width: 512px;
  margin: ${Space.spBase} auto 0;
  border-color: ${Border.light} !important;
  background-color: ${Background.light} !important;
`;

const UpdatedBy = styled.span`
  color: ${Text.secondary} !important;
`;

const TextPrimary = styled.span<any>`
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const TextSecondary = styled.span`
  margin-right: ${Space.spHalf};
  margin-bottom: ${Space.spHalf};
  color: ${Text.secondary} !important;
  width: 25%;
`;

interface Props {
  className?: string;
  theme: MainColors;
  dowJonesStatus: string;
  updatedBy: string;
  updatedOn: string;
  comment: string;
  customerName: string;
  billingAddress: string;
  companyName: string;
};

const DowJonesCard = ({
  className,
  theme,
  dowJonesStatus,
  updatedBy,
  updatedOn,
  comment,
  customerName,
  billingAddress,
  companyName,
}: Props) => {
  const styles = {
    width: '24px',
    height: Height.s,
    marginRight: `${Space.spHalf}`
  };

  const cardDetails = [DOW_JONES_CLEAR, DOW_JONES_BLOCKED].includes(dowJonesStatus) ? (
    <>
      <FlexboxContainer justifyContent="flex-start">
        <TextPrimary className="base" marginBottom={Space.spBase}>
          {formatDateTime(updatedOn, true)}
        </TextPrimary>
      </FlexboxContainer>
      <FlexboxContainer>
        <TextSecondary className="dense">Customer name</TextSecondary>
        <TextPrimary className="dense" marginBottom={Space.spHalf}>{customerName}</TextPrimary>
      </FlexboxContainer>
      <FlexboxContainer>
        <TextSecondary className="dense">Billing address</TextSecondary>
        <TextPrimary className="dense" marginBottom={Space.spHalf}>
          {billingAddress}
        </TextPrimary>
      </FlexboxContainer>
      <FlexboxContainer>
        <TextSecondary className="dense">Company</TextSecondary>
        <TextPrimary className="dense" marginBottom={Space.spHalf}>{companyName}</TextPrimary>
      </FlexboxContainer>
    </>
  ) : (
    <>
      <TextPrimary className="base" marginBottom={Space.spBase}>
        {formatDateTime(updatedOn, true)}
      </TextPrimary>
      <TextPrimary className="base-italic">{comment}</TextPrimary>
    </>
  );

  return (
    <CardContainer className={`border ${className}`}>
      <FlexboxContainer
        flexDirection="column"
        padding={`${Space.spBase} ${Space.sp2x} ${Space.sp2x}`}
      >
        <FlexboxContainer
          justifyContent="space-between"
          alignItems="center"
          margin={`0 0 ${Space.spBase}`}
        >
          <FlexboxContainer justifyContent="flex-start" alignItems="center">
            {renderIcon(theme, styles)}
            <h6>{renderTitle(dowJonesStatus)}</h6>
          </FlexboxContainer>
          <UpdatedBy className="base-italic">{updatedBy}</UpdatedBy>
        </FlexboxContainer>
        {cardDetails}
      </FlexboxContainer>
    </CardContainer>
  );
};

const renderIcon = (theme: MainColors, styles: any) => {
  switch (theme) {
    case 'success':
      return <CheckCircle style={{ ...styles, color: Main[theme] }} />;
    case 'warning':
      return <WarningRounded style={{ ...styles, color: Main[theme] }} />;
    case 'error':
      return <Block style={{ ...styles, color: Main[theme] }} />;
    default:
      return null;
  }
};

const renderTitle = (status: string) => {
  switch (status) {
    case DOW_JONES_CLEAR:
      return 'Clear';
    case DOW_JONES_BLOCKED:
      return 'Check failed';
    case DOW_JONES_REVIEWED_CLEAR:
      return 'Cleared manually';
    case DOW_JONES_REVIEWED_BLOCKED:
      return 'Blocked manually';
    default:
      return null;
  }
};

DowJonesCard.defaultProps = {
  className: ''
};

export default DowJonesCard;
