import produce from 'immer';
import { Action, ActionType } from 'redux/types/actions';
import { State } from 'redux/types/state';

const createReducer = <
  S extends State,
  H extends Partial<Record<ActionType, (...args: any[]) => void>>
> (initialState: S, handlers: H) => {
  return produce((state: S = initialState, action: Action) => {
    const handler = handlers ? handlers[action.type] : null;

    if (handler) {
      handler(state, action);
    }
  }, initialState);
};

export default createReducer;
