import React, { useState, useEffect, Fragment } from 'react';
import { navigate, setQueryParams } from 'hookrouter';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Vendor from 'data/Vendor';
import * as User from 'data/User';
import * as Prod from 'data/Product';
import InviteModal from 'admin/InviteModal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import Pill from 'components/UI/Pill';
import Slab from 'view/Slab';
import Link from 'view/Link';
import LinkCard from 'view/LinkCard';
import Divider from 'components/UI/Divider';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, Size, Percent, FontWeight } from 'lib/styles';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Border, Background, Main } from 'styles/themeColors';
import { loadSuperCategoriesSummary } from 'requests/superCategory';
import { Space } from 'styles/themeSpaces';
import ResourceVideoModal from 'admin/ResourceVideoModal';
import { Resource } from 'data/Resource';
import Thumbnail from 'components/UI/Thumbnail';
import { parseYoutubeThumbnailUrl } from 'utils/resources';
import PlayVideoModal from 'components/Resources/Modals/PlayVideoModal';
import Select, { Option } from 'components/UI/Select';
import { createResource, getAllResources, getVendorsResources } from 'requests/resource';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { RESOURCES_TEXT_LABELS } from 'constants/resources';
import { VendorsResources } from 'data/vendorResources';
import Carousal from 'components/UI/Carousal';

const CategorySummary = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: ${Size.medium};
  padding: ${Size.small} 0;
`;

const CountPill = styled(Pill)`
  font-size: 0.75rem;
  margin-left: auto;
`;

const Header = styled.div`
  align-items: flex-start;
  display: flex;
  margin: ${Size.medium} auto 0;
  padding: ${Size.medium} ${Size.medium};
  height: auto;
  background: transparent linear-gradient(270deg, #18222b 0%, #313942 100%) 0%
    0% no-repeat padding-box;
  border-radius: ${BorderRadius.m};
`;

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
`;

const Page = styled.div`
  color: ${Color.black};
  margin: 0px auto;
  width: ${Percent.full};
  padding: 0 30px;
`;

const ProductSummaries = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: ${Percent.full};

  &::after {
    content: '';
    flex: 10 0 auto;
    min-width: 32%;
    max-width: 32%;
  }
`;

const SuperCategoryName = styled.span`
  font-size: ${Size.medium};
  align-items: center;
  justify-content: space-between;
  display: flex;
  font-weight: ${FontWeight.bold};
  margin-bottom: ${Size.small};
`;

const SuperCategorySummary = styled.div`
  padding: ${Size.medium};
  margin-bottom: ${Size.medium};
  display: flex;
  flex: 1 1 0;
  min-width: 32%;
  max-width: 32%;
  flex-direction: column;
  background-color: ${Background.white};
  border-color: ${Border.light} !important;
`;

const StyledLink = styled(Link)`
  color: ${Main.brandLight};

  &:not(:first-child) {
    margin-left: 0;
  }
`;

const WelcomeMessage = styled.p`
  font-size: ${Size.medium};
  margin: 0px;
  color: ${Background.white};
`;

const WelcomeMessageHeader = styled.p`
  font-size: ${Size.large};
  color: ${Background.white};
  margin-bottom: 0;
  margin-top: 0;
`;

const CardSlab = styled(Slab)<any>`
  padding: ${Size.medium} 3rem;
  display: flex;
  flex: 1 1 0;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: transparent
    linear-gradient(90deg, ${Main.brandDark} 0%, ${Main.brandLight} 100%) 0% 0%
    no-repeat padding-box;
  box-shadow: 5px 2px 3px #0000004b;
  border-radius: ${BorderRadius.m};
  color: ${Background.white};
  min-width: 15%;
  max-width: 15%;
`;

const StyledLinkCard = styled(LinkCard)`
  background: transparent linear-gradient(270deg, #18222b 0%, #313942 100%) 0%
    0% no-repeat padding-box;
  color: ${Background.white};
  padding: ${Size.large};
  min-width: 30%;
  max-width: 30%;
  box-shadow: 5px 2px 3px #0000004b;
`;

const H1 = styled.h1`
  margin: ${Space.sp2x} 0;
`;

const SelectContainer = styled.div`
  margin: 5px 5px 0px 10px !important;
  width: 100%;
`

export interface Props {
  selectedVendor: Vendor;
  vendorsList?: Vendor[];
}

const Dashboard = ({ selectedVendor, vendorsList }: Props) => {
  const { token, user } = useAuth();
  const [inviting, setInviting] = useState(false);
  const prodSummaryReq = useAPI({ deferFn: Prod.loadSummary });
  const summaryReq = useAPI({ deferFn: loadSuperCategoriesSummary });
  const isVendor = User.isVendor(user!);
  const isAdmin = User.isOfmpAdmin(user!);
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [selectedResource, setSelectedResource] = useState<Resource>();
  const [resourceSelectedVendor, setResourceSelectedVendor] = useState();
  const [resources, setResources] = useState<Resource[]>([]);
  const [newResources, setNewResources] = useState<VendorsResources[]>([]);
  const [vendorListOptions, setVendorListOptions] = useState<Option[]>([]);

  const getAllResourcesReq = useAPI({
    deferFn: getAllResources,
    onResolve: (result) => {
      setResources(result)
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const getVendorsResourcesReq = useAPI({
    deferFn: getVendorsResources,
    onResolve: (result) => {
      setResources(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const loading = getAllResourcesReq.isLoading || getVendorsResourcesReq.isLoading;

  useEffect(() => {
    if (!isVendor) {
      summaryReq.run([token]);
    }

    setQueryParams({status: undefined});
  }, []);

  useEffect(() => {
    if(isAdmin){
      if(!resourceSelectedVendor){
        getAllResourcesReq.run();
      }else{
        const vendor = vendorsList?.find(x => x.name === resourceSelectedVendor);
        let vendorIds = vendor ? [vendor?.id] : []
        getVendorsResourcesReq.run(vendorIds); 
      }
    }
  }, [vendorsList, resourceSelectedVendor])

  useEffect(() => {
    prodSummaryReq.run(selectedVendor ? selectedVendor.id : null);
    if(selectedVendor?.id){
      getVendorsResourcesReq.run([selectedVendor?.id]);
    }else{
      getAllResourcesReq.run();
    }
  }, [selectedVendor]);

  useEffect(() => {
    const options = vendorsList?.map(x => { return { id: x.id.toString(), value: x.name } as Option }) || []
    setVendorListOptions([{value: RESOURCES_TEXT_LABELS.ALL}, ...options]);
  }, [vendorsList])

  const refreshResources = () => {
    setResourceSelectedVendor(undefined);
    setNewResources([]);
    getAllResourcesReq.run();
  }

  const draftProductsCount = prodSummaryReq.data
    ? prodSummaryReq.data.counts.draft.products
    : 0;
  const readyProductsCount = prodSummaryReq.data
    ? prodSummaryReq.data.counts.ready.products
    : 0;
  const publishedProductsCount = prodSummaryReq.data
    ? prodSummaryReq.data.counts.published.products
    : 0;
  const draftsVariantsCount = prodSummaryReq.data
    ? prodSummaryReq.data.counts.draft.variants
    : 0;
  const readyVariantsCount = prodSummaryReq.data
    ? prodSummaryReq.data.counts.ready.variants
    : 0;
  const publishedVariantsCount = prodSummaryReq.data
    ? prodSummaryReq.data.counts.published.variants
    : 0;

  const onCreateProduct = () => {
    if (selectedVendor) {
      navigate(`/products/new-product`);
    } else {
      renderToast(
        toast.TYPE.INFO,
        'Please select vendor before you try to create product.'
      );
    }
  };

  const toggleResourceModal = () => {
    setIsResourceModalOpen(!isResourceModalOpen)
    setNewResources([]);
  }

  const CloseVideoPlayerModal = () => {
    setSelectedResource(undefined);
  }

  const handleResourceVendorSelect = (event: any) => {
    setResourceSelectedVendor(event.target.value)
  }

  const createResourceReq = useAPI({
    deferFn: createResource,
    onResolve: (result) => {
        renderToast(toast.TYPE.SUCCESS, RESOURCES_TEXT_LABELS.RESOURCE_CREATED_SUCCESSFULLY);
        refreshResources();
    },
    onReject: err => {
    renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const saveResources = () => {
    const newResourcesMapped = newResources.map(x => {
      return {
        ...x,
        resource: {
          title: x.resource.title,
          url: x.resource.url,
          isGlobal: x.isGlobal
        }
      }
    })
    createResourceReq.run(newResourcesMapped);
    toggleResourceModal();
  }

  const welcomeMessageHeader = `Welcome back ${user!.name}!`;

  let productSummaries = null;
  let welcomeMessage = null;
  let inviteButton = null;
  let bottomContent = null;
  let resourcesContent = null;
  const centerContent = (
    <ContentWrapper>
      <H1>{isVendor ? 'Products' : 'Insights'}</H1>
      <FlexWrapper>
        <CardSlab
          label="PUBLISHED PRODUCTS"
          large
          loading={isVendor ? summaryReq.isLoading : prodSummaryReq.isLoading}
          onClick={() => navigate('/products', false, { status: 'published' })}>
          {publishedProductsCount}
        </CardSlab>
        <CardSlab
          label="PUBLISHED VARIANTS"
          large
          loading={isVendor ? summaryReq.isLoading : prodSummaryReq.isLoading}
          onClick={() => navigate('/products', false, { status: 'published' })}
        >
          {publishedVariantsCount}
        </CardSlab>
        <CardSlab
          label="READY PRODUCTS"
          large
          loading={isVendor ? summaryReq.isLoading : prodSummaryReq.isLoading}
          onClick={() => navigate('/products', false, { status: 'ready' })}
        >
          {readyProductsCount}
        </CardSlab>
        <CardSlab
          label="READY VARIANTS"
          large
          loading={isVendor ? summaryReq.isLoading : prodSummaryReq.isLoading}
          onClick={() => navigate('/products', false, { status: 'ready' })}
        >
          {readyVariantsCount}
        </CardSlab>
        <CardSlab
          label="DRAFT PRODUCTS"
          large
          loading={isVendor ? summaryReq.isLoading : prodSummaryReq.isLoading}
          onClick={() => navigate('/products', false, { status: 'draft' })}
        >
          {draftProductsCount}
        </CardSlab>
        <CardSlab
          label="DRAFT VARIANTS"
          large
          loading={isVendor ? summaryReq.isLoading : prodSummaryReq.isLoading}
          onClick={() => navigate('/products', false, { status: 'draft' })}
        >
          {draftsVariantsCount}
        </CardSlab>
      </FlexWrapper>
    </ContentWrapper>
  )

  const videosContent = (
    loading ? 
    <LoadingIndicator size={50} /> : 
    resources.length === 0 ? 
    <FlexboxContainer
      padding="25px 40%"
      border={`1px solid ${Main.lightGray}`}
      borderRadius='8px'
      alignContent="center"
      width='100%'
    >
      <text style={{ color: `${Color.shuttleGray}`, fontStyle: 'italic' }}> {RESOURCES_TEXT_LABELS.NO_RESOURCE_VIDEOS}</text>
    </FlexboxContainer> :

    <Carousal>
      {
        resources.map((resource) => (
          <Thumbnail
            title={resource.title} 
            url={parseYoutubeThumbnailUrl(resource.url) || ''} 
            onClick={() => setSelectedResource(resource)} 
            margin='0px 15px 5px 5px'
          />
        ))
      }
    </Carousal>
  )

  if(isAdmin){
    resourcesContent = (
      <ContentWrapper>
        <FlexboxContainer
          flexDirection='row'
          justifyContent='space-between'
          alignItems='center'
          gap='10px'
        >
          <H1>Resources</H1>
          {
            !selectedVendor && 
            <>
              <SelectContainer>
                <Select
                    options={vendorListOptions}
                    onChange={handleResourceVendorSelect}
                    value={resourceSelectedVendor || "Select vendor"}
                    defaultValue="Select vendor"
                    width="300px"
                  />
              </SelectContainer>
              <Button
                isGhost
                color={Main.accent}
                onClick={toggleResourceModal}
                width='122px'
              >
                Edit mode
              </Button>
            </>
          }
      </FlexboxContainer>
          {videosContent}
        </ContentWrapper>
    );
  }

  if (isVendor) {
    welcomeMessage = (
      <>
        <StyledLink onClick={() => navigate('/products', false, { status: 'published' })}>
          {publishedProductsCount === 1
            ? '1 product'
            : `${publishedProductsCount} products`}
        </StyledLink>{' '}
        {publishedProductsCount === 1 ? 'has' : 'have'} been published.
      </>
    );

    bottomContent = (
      <ContentWrapper>
        <H1>Resources</H1>
        {videosContent}
        <FlexWrapper style={{marginTop: 20}}>
          <StyledLinkCard
            title="Setting your product up for success"
            onClick={() => navigate('/onboarding/seller-guidelines')}
          >
            A collection of proven suggestions for how to effectively present
            your products on OFMP.
          </StyledLinkCard>
          <StyledLinkCard
            icon="download"
            title="The how-to of being a better digital seller"
            onClick={() =>
              window.open(
                '/OFMP_SellerGuidelines_Nov2019.pdf',
                '_blank'
              )
            }
          >
            See how you can best utilize your own brand channels to grow your
            sale on OFMP.
          </StyledLinkCard>
          <StyledLinkCard
            icon="folder"
            title="Pre-designed tools for spreading the word"
            onClick={() =>
              window.open(
                'https://drive.google.com/drive/u/2/folders/1_bQyjyKTZHCEmO6tjZXzWB7bVL-9Ecws',
                '_blank'
              )
            }
          >
            Take a look at the effective elements that you can use across your
            marketing.
          </StyledLinkCard>
        </FlexWrapper>
      </ContentWrapper>
    );
   /*  inviteButton = (
      <Button onClick={() => setInviting(true)} borderColor="transparent">
        Invite
      </Button>
    ); */
  } else {
    welcomeMessage = (
      <>
        There {readyProductsCount === 1 ? 'is ' : 'are '}
        <StyledLink onClick={() => navigate('/products', false, { status: 'ready' })}>
          {readyProductsCount}
          {readyProductsCount === 1 ? ' product' : ' products'}
        </StyledLink>{' '}
        ready to publish.
      </>
    );

    if (summaryReq.data) {
      productSummaries = summaryReq.data
        .sort((a, b) => (a.name < b.name ? -1 : 1))
        .map((superCategory, index) => {
          const categorySummaries = superCategory.categories.map((category) => (
            <Fragment key={category.code}>
              <CategorySummary>
                {category.name}
                <CountPill size="s" theme="accent">
                  {category.published.toString()}
                </CountPill>
              </CategorySummary>
              <Divider key={index} height="2px" bgColor={Color.lightGray} />
            </Fragment>
          ));

          const totalCount = superCategory.categories.reduce(
            (total, cat) => total + cat.published,
            0
          );

          return (
            <SuperCategorySummary
              key={superCategory.name}
              className="border modal-elevation"
            >
              <SuperCategoryName>
                {superCategory.name}
                <CountPill size="s" theme="accent">
                  {totalCount.toString()}
                </CountPill>
              </SuperCategoryName>
              {categorySummaries}
            </SuperCategorySummary>
          );
        });
    }

    inviteButton = (
      <Button onClick={() => setInviting(true)} borderColor="transparent">
        Invite
      </Button>
    );

    bottomContent = (
      <ContentWrapper>
        <H1>Products</H1>
        <FlexWrapper>
          <ProductSummaries>{productSummaries}</ProductSummaries>
        </FlexWrapper>
      </ContentWrapper>
    );
  }

  return (
    <Page>
      <InviteModal
        onClose={() => setInviting(false)}
        showModal={inviting}
        vendors={vendorsList}
      />
      <Header>
        <FlexboxContainer
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          margin="0 20px 0"
        >
          <FlexboxContainer flexDirection="column">
            <WelcomeMessageHeader>{welcomeMessageHeader}</WelcomeMessageHeader>
            <WelcomeMessage>{welcomeMessage}</WelcomeMessage>
          </FlexboxContainer>
          <FlexboxContainer>
            <Button
              onClick={onCreateProduct}
              margin="0 10px 0 0"
              borderColor="transparent"
            >
              New product
            </Button>
            {inviteButton}
          </FlexboxContainer>
        </FlexboxContainer>
      </Header>
      {centerContent}
      {resourcesContent}
      {bottomContent}
      <ResourceVideoModal
        onSave={saveResources}
        modalState={isResourceModalOpen}
        toggleModal={toggleResourceModal}
        vendorsList={vendorsList}
        setSelectedResource={setSelectedResource}
        refreshResources={refreshResources}
        newResources={newResources}
        setNewResources={setNewResources}
      />
      <PlayVideoModal
        resource={selectedResource}
        toggleModal={CloseVideoPlayerModal}
        modalState={selectedResource !== undefined}
      />
    </Page>
  );
};

export default Dashboard;