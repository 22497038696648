import Company from 'data/Company'
import DowJonesResult from 'data/DowJonesResult';
import Address from 'data/Address';

export default interface Customer {
  id: number;
  customerId: string;
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  dowJonesStatus?: string;
  companies: CustomerCompany[];
  dowJones: DowJonesResult[];
  addresses: Address[];
  isTaxExempted: boolean;

};

export interface CustomerExtended extends Omit<Customer, 'email'> {
  email: [
    {
      emailAddress: string;
    }
  ];
};

export type CustomerCompany = Pick<Company, 'companyId' | 'companyName' | 'displayName' | 'country' | 'customerRole' | 'addresses' > & { isDeleted?: boolean };

export interface CustomerVM {
  id: string;
  customerId: string;
  fullName: string;
  companies: Company[];
  email: string;
  phone: string;
  createdDate: string;
  companyInputByCustomer: string;
  dowJones: DowJonesResult[];
  dowJonesStatus?: string;
  dowJonesTimestamp?: string;
};

export interface CustomersPagination {
  page: number;
  totalPages: number;
  size: number;
  totalItems: number;
  customers: CustomerVM[];
};

export type OrderCustomerType = Pick<Customer, 'id' | 'customerId' | 'firstName' | 'lastName' | 'companyName' | 'email' | 'phone' | 'dowJonesStatus'> & { fullName: string };

export const empty: Omit<Customer, 'id'> = {
  customerId: '',
  firstName: '',
  lastName: '',
  companyName: '',
  email: '',
  phone: '',
  companies: [],
  dowJones: [],
  addresses: [],
  isTaxExempted: false
};
