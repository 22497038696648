import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import React from 'react';
import styled from 'styled-components';
import { formatDateTime } from 'utils/formatters';
import { Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import Spinner from 'components/UI/Spinner';
import {
  UploadingStatus,
} from './ProductVariantImportModal';
import {
  STATUS,
  STEPS,
  TEXT_LABELS,
}from '../../constants/productVariantImport'
const ColumnName = styled.div`
  width: 160px;
  font-size: ${Space.sp2x};
  line-height: 24px;
  color: ${Text.primary};
  padding-top: ${Space.spHalf};
  margin-right: ${Space.sp3x};
`;

const ColumnValue = styled.div<any>`
  font-size: ${Space.sp2x};
  line-height: 20px;
  background-color: ${({ $bgcolor }) => ($bgcolor ? $bgcolor : 'transparent')};
  padding: ${({ $padding }) => ($padding ? $padding : '0px')};
  color: ${({ $color }) => ($color ? $color : Text.primary)};
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
  border-radius: 6px;
  margin: ${({ margin }) => (margin ? margin : '0px')};
`;
interface Props {
  uploadingStatus: UploadingStatus | undefined;
  step: number;
  progressPercent?: number;
  importedLabel?: string;
  failedLabel?: string;
}
export default function VerificationAndCompletionComponent({
  uploadingStatus,
  step,
  progressPercent,
  importedLabel = TEXT_LABELS.VARIANTS_IMPORT,
  failedLabel = TEXT_LABELS.VARIANTS_FAILED
}: Props) {
  return (
    <FlexboxContainerComponent flexDirection="column" margin="32px 0px 0px 0px">
      {uploadingStatus ? (
        <FlexboxContainerComponent flexDirection="column" gap='4px'>
          <FlexboxContainerComponent alignItems="center" flexDirection="row">
            <ColumnName>{TEXT_LABELS.FILENAME}</ColumnName>
            <ColumnValue>{uploadingStatus.fileName}</ColumnValue>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent alignItems="center" flexDirection="row">
            <ColumnName>{TEXT_LABELS.UPLOADED}</ColumnName>
            <ColumnValue>
            {formatDateTime(uploadingStatus?.createdOn, true)}
            </ColumnValue>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent flexDirection="row">
            <ColumnName>{TEXT_LABELS.STATUS}</ColumnName>
            {step === STATUS.COMPLETED ? (
              <ColumnValue flexDirection="col" >
                <div>
                  {progressPercent}{TEXT_LABELS.PERCENT_VERIFIED}{' '}
                </div>
                <div className="progress-bar">
                  <progress value={progressPercent} max={100} />
                </div>
              </ColumnValue>
            ) : (
              <ColumnValue
                $bgcolor={
                  uploadingStatus?.totalCount === uploadingStatus?.passedCount
                    ? '#2CAE44'
                    : !uploadingStatus?.passedCount
                    ? '#C82135'
                    : '#FFC107'
                }
                $weight="700"
                $padding="4px"
                $color="#FFFFFF"
              >
                {uploadingStatus?.totalCount === uploadingStatus?.passedCount
                  ? TEXT_LABELS.COMPLETED
                  : !uploadingStatus?.passedCount
                  ? TEXT_LABELS.VERIFICATION_FAILED
                  : TEXT_LABELS.PARTIAL_SUCCESS}
              </ColumnValue>
            )}
          </FlexboxContainerComponent>
          {step === STEPS.COMPLETION && (
            <FlexboxContainerComponent alignItems="center" flexDirection="row">
              <ColumnName>{importedLabel}</ColumnName>
              <ColumnValue>{uploadingStatus?.passedCount}</ColumnValue>
            </FlexboxContainerComponent>
          )}

          {step === STEPS.COMPLETION && (
            <FlexboxContainerComponent alignItems="center" flexDirection="row">
              <ColumnName>{failedLabel}</ColumnName>
              <ColumnValue>{uploadingStatus?.failedCount}</ColumnValue>
            </FlexboxContainerComponent>
          )}
        </FlexboxContainerComponent>
      ) : (
        <FlexboxContainerComponent flexDirection="row" justifyContent="center">
          <Spinner />
        </FlexboxContainerComponent>
      )}
    </FlexboxContainerComponent>
  );
}
