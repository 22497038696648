export const MANUFACTURE = 'manufacture';
export const IN_STOCK = 'instock';

export const CONSIGNMENT_MODIFICATION_STATUS = {
    REQUESTED: 'REQUESTED',
    APPROVED: 'APPROVED',
    REJECTED: 'REJECTED'
}

export const CONSIGNMENT_MODIFICATION_ACTORS = {
    VENDOR: 'VENDOR',
    BUYER: 'BUYER',
    SYSTEM: 'SYSTEM'
}