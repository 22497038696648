import React from 'react';

import { Card, HorizontalRule, Heading1, DownloadLink, Heading4 } from 'OnboardingComponents/Elements';

import Intro from './sections/Intro';
import PrimaryLogo from './sections/PrimaryLogo';
// import WhyWeExist from './sections/WhyWeExist';

const BrandCommunicationPage = () => {
  return (
    <Card>
      <Heading1>Brand &amp; communication</Heading1>
      <Heading4>How you can and cannot use the OFMP brand in your communication materials</Heading4>
      <DownloadLink href="/resources/OFMP_BrandCommGuidelines_External_Dec'19.pdf" target="_blank">Download the brand &amp; communication PDF</DownloadLink>
      <HorizontalRule />

      <Intro />
      <HorizontalRule />

      {/* 
      <WhyWeExist />
      <HorizontalRule /> */}

      <PrimaryLogo />
    </Card>
  );
};

export default BrandCommunicationPage;
