import React, { cloneElement, useRef } from 'react';
import styled, { css } from 'styled-components';
import { useRect } from '@reach/rect';
import { Shade, Text } from 'styles/themeColors';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Space } from 'styles/themeSpaces';

const StyledTooltip = styled.div<any>`
  background: ${Shade.shade760};
  border-radius: ${BorderRadius.m};
  color: ${Text.whitePrimary} !important;
  margin-top: ${({ direction }) => direction === 'down' ? '0.4rem' : '-0.4rem'};
  padding: ${Space.spBase};
  pointer-events: none;
  position: absolute;
  visibility: visible;
  white-space: nowrap;

  & *:first-child {
    margin-top: 0;
  }

  & *:last-child {
    margin-bottom: 0;
  }

  ${({ width }) => width && css`
    width: ${width};
    white-space: normal;
  `}
`;

const TooltipContainer = styled.div<any>`
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0;
  transition: 0.1s opacity, 0s visibility 0.1s;
  visibility: hidden;
  z-index: 1000;
  display: ${({ hidden }) => hidden ? 'none' : 'block'};

  *:hover ~ & {
    opacity: 1;
    transition: 0.1s opacity 0.1s;
    visibility: visible;
  }
`;

const TooltipTriangle = styled.div<any>`
  height: 0;
  width: 0;
  margin-left: -0.5rem;
  position: absolute;

  ${({ direction }) => direction === 'down' && css`
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-bottom: 0.4rem solid ${Shade.shade760};
  `}

  ${({ direction }) => direction === 'up' && css`
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    border-top: 0.4rem solid ${Shade.shade760};
    margin-top: -0.4rem;
  `}

  ${({ direction }) => direction === 'left' && css`
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.4rem solid ${Shade.shade760};
  `}

  ${({ direction }) => direction === 'right' && css`
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-right: 0.4rem solid ${Shade.shade760};
  `}
`;

interface Props {
  children: React.ReactElement;
  className?: string;
  content: React.ReactNode;
  direction?: 'down' | 'up' | 'left' | 'right';
  width?: string;
  size?: 'small' | 'large';
  hidden?: boolean;
};

const Tooltip = ({
  children,
  className,
  content,
  direction,
  width,
  size,
  hidden = false
}: Props) => {
  const triggerRef: any = useRef();
  const triggerRect = useRect(triggerRef);
  const tooltipRef = useRef();
  const tooltipRect = useRect(tooltipRef);

  const getStyle = () => {
    if (triggerRect) {
      if (direction === 'up' || direction === 'down') {
        const triggerCenter = triggerRect.left + triggerRect.width / 2;
        const left = tooltipRect && tooltipRect.width
          ? triggerCenter - tooltipRect.width / 2
          : triggerCenter;

        const maxLeft = tooltipRect && tooltipRect.width
          ? window.innerWidth - tooltipRect.width - 2
          : window.innerWidth;

        const top = direction === 'down'
          ? triggerRect.bottom
          : tooltipRect && tooltipRect.height
            ? triggerRect.top - tooltipRect.height
            : triggerRect.top;

        return {
          tooltipStyle: {
            left: Math.min(Math.max(left, 2), maxLeft) + window.scrollX,
            top
          },
          triangleStyle: {
            left: triggerRect.left + triggerRect.width / 2,
            top:
              direction === 'down'
                ? triggerRect.bottom
                : triggerRect.top
          }
        };
      } else {
        const triggerCenter = triggerRect.top + triggerRect.height / 2;
        const top = tooltipRect && tooltipRect.height
          ? triggerCenter - tooltipRect.height / 2
          : triggerCenter;

        const maxTop = tooltipRect && tooltipRect.height
          ? window.innerHeight - tooltipRect.height - 2
          : window.innerHeight;

        const left = direction === 'right'
          ? triggerRect.right + 6
          : tooltipRect && tooltipRect.width
            ? triggerRect.left - tooltipRect.width - 6
            : triggerRect.left - 6

        return {
          tooltipStyle: {
            left,
            top: Math.min(Math.max(top, 2), maxTop) + 5,
          },
          triangleStyle: {
            top: (triggerRect.top + triggerRect.height / 2) - 8,
            left:
              direction === 'right'
                ? triggerRect.right + 8
                : triggerRect.left + 2
          }
        };
      }
    }

    return { tooltipStyle: undefined, triangleStyle: undefined };
  };

  const { tooltipStyle, triangleStyle } = getStyle();

  return (
    <>
      <div ref={triggerRef}>
        {cloneElement(children)}
      </div>
      <TooltipContainer className={className} hidden={hidden}>
        <StyledTooltip
          className="tooltip-contents caption"
          direction={direction}
          width={width}
          ref={tooltipRef}
          size={size}
          style={tooltipStyle}
        >
          {content}
        </StyledTooltip>
        <TooltipTriangle direction={direction} style={triangleStyle} />
      </TooltipContainer>
    </>
  );
};

Tooltip.defaultProps = {
  direction: 'down',
  size: 'small'
};

export default Tooltip;
