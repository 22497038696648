const documentOptions = [
  {
    title: 'Preview',
    id: 1
  },
  {
    title: 'Download',
    id: 2
  },
  {
    title: 'Email to customer',
    id: 3
  }
];

export default documentOptions;

export const companyDocumentOptions = [
  {
    title: 'Preview',
    id: 1
  },
  {
    title: 'Download',
    id: 2
  }
];

export const VALID_TICKET_DOCUMENT_TYPES = [
  'application/pdf',
  'application/x-pdf',
  'application/x-bzpdf',
  'application/x-gzpdf',
  'image/jpg', 
  'image/jpeg', 
  'image/png', 
  'application/doc', 
  'application/rtf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];
