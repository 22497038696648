import React, { useEffect, useState } from 'react';
import { Grid, createStyles, makeStyles } from '@material-ui/core';
import Button from 'components/UI/Button';
import SearchBar from '../../UI/Search';
import Vendor from '../../../data/Vendor';
import ProductListingFilters, {
  ProductListFilter,
} from './ProductListingFilters';
import { renderToast } from '../../UI/ToastNotification';
import { navigate } from 'hookrouter';
import { toast } from 'react-toastify';
import ProductListingTable from './ProductListingTable';
import ProductColumns from './ProductListingColumns';
import { getLocalItem, save, clear } from '../../../lib/LocalStorage';
import ProductLocalization from '../ProductLocalization';
import { Main } from 'styles/themeColors';
import FlexboxContainer from '../../UI/FlexboxContainer';
import Tooltip from 'components/UI/Tooltip';
import styled from 'styled-components';
import { useAuth } from 'lib/Auth';
import * as Prod from 'data/Product';
import { useAPI } from 'lib/API';
import { isAdmin, isSuperAdmin } from 'data/User';
import { PRODUCT_COLUMNS_STORAGE_KEY, PRODUCT_STATUS, VALIDATION_STATUS } from 'constants/product';
import { getItems } from 'lib/SessionStorage';
import { capitalizeFirstLetter, formatDateTime } from 'utils/formatters';


const TooltipContent = styled.div`
  text-align: left;
  white-space: initial;
  width: 15rem;
`;


const useStyles = makeStyles(() =>
  createStyles({
    containerGrid: {
      marginTop: '20px',
      width: '100%',
      padding: '5px'
    },
    gridPadding: {
      padding: '0 20px'
    },
    gridMargin: {
      margin: '0 20px'
    }
  })
);

interface Props {
  selectedVendor: Vendor;
  vendorsList: Vendor[];
}

const ProductListV2 = ({ selectedVendor, vendorsList }: Props) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const status = queryParameters.get("status");
  const publishStatuses = getItems('productStatuses');

  const { user } = useAuth();
  const classes = useStyles();


  useEffect(() => {
    const savedProductColumns = getLocalItem(PRODUCT_COLUMNS_STORAGE_KEY);
    if (savedProductColumns.length === 0) {
      save(PRODUCT_COLUMNS_STORAGE_KEY, ProductColumns);
    } else {
      let copyLocalColumns = ProductColumns.map((x: any) => {
        if (x.title === 'Publish Date') {
          return {
            ...x,
            render: (rowData: any) =>
              rowData.publishedOn ? formatDateTime(rowData.publishedOn, true) : 'N/A',
          }
        }
        return x;
      })
      setLocalProductColumns(copyLocalColumns);
    }

    try {
      clear('productColumns');
    } catch (err: any) {
      console.log(err?.message);
    }
  }, [])

  const [localProductColumns, setLocalProductColumns] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [columns, setColumns] = useState(localProductColumns.length === 0 ? ProductColumns : localProductColumns);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState<ProductListFilter>({
    publishStatus: { value: 'All' },
    brand: [],
    vendor: { value: ProductLocalization.listing.filter.vendorSelect },
    country: { value: ProductLocalization.listing.filter.countrySelect },
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState<'none' | 'update' | 'revise'>('none');
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [reload, setReload] = useState(false);

  const prodExportReq = useAPI({
    deferFn: Prod.exportProducts,
    onResolve: result => {
      let type = toast.TYPE.INFO;
      let message = `Export of ${selectedRows.length} products started...`

      if (result.status === VALIDATION_STATUS.PARTIAL) {
        type = toast.TYPE.WARNING;
        message = `${ProductLocalization.warning.exportMaterialApi}. Export of ${result?.productIds?.length || 0} products started...`;
      } else if (result.status === VALIDATION_STATUS.FAILED) {
        type = toast.TYPE.ERROR;
        message = ProductLocalization.errors.exportMaterialApi;
      }

      renderToast(type, message);
      setSelectedRows(() => []);
      setReload(true);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const handleFilterUpdate = (filters: ProductListFilter) => {
    setFilters(filters);
  };

  const publishProducts = () => {
    if (selectedRows.every(row => row.status === PRODUCT_STATUS.READY)) {
      setShowPublishModal(true);
    } else {
      renderToast(toast.TYPE.ERROR, ProductLocalization.errors.publish);
    }
  };

  const goLiveProducts = async () => {
    if (!selectedRows.every(row => row.status === PRODUCT_STATUS.PUBLISHED)) {
      renderToast(toast.TYPE.ERROR, ProductLocalization.errors.live);
      return;
    }

    prodExportReq.run(selectedRows.map((row) => row.id));
  }

  const onDelete = () => {

    const hasPublishedProducts = selectedRows.filter((row: any) => row.status === PRODUCT_STATUS.PUBLISHED).length > 0;
    const hasReadyProducts = selectedRows.filter((row: any) => row.status === PRODUCT_STATUS.READY).length > 0;
    if (hasPublishedProducts || hasReadyProducts) {
      renderToast(toast.TYPE.ERROR, ProductLocalization.errors.delete);
      return;
    }
    setShowDeleteModal(true)
  }

  useEffect(() => {
    if (status) {
      const publishStatus = publishStatuses?.find((x: any) => x.name === status);
      if (publishStatus) {
        setFilters({ ...filters, publishStatus: { ...publishStatus, value: capitalizeFirstLetter(publishStatus.name) } })
      }
    }
  }, [status])

  return (
    <Grid container className={classes.containerGrid}>
      <Grid container className={classes.gridPadding}>
        <Grid item xs={12} sm={6} md={6}>
          <SearchBar
            placeholder={ProductLocalization.listing.filter.search}
            searchValue={searchTerm}
            onSearch={(searchText: string) => {
              setSearchTerm(searchText);
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          container
          justifyContent="flex-end"
          alignItems="center"
        >

          {(isSuperAdmin(user!)) &&
            <Tooltip
              content={
                <TooltipContent>
                  Push selected published products onto live website.
                </TooltipContent>
              }
              key="submit"
              size="large"
            >
              <Button
                onClick={goLiveProducts}
                margin="0 10px 0 0"
                bgColor={Main.brandDark}
                borderColor={Main.brandDark}
                disabled={selectedRows.length === 0}
              >
                Go live
              </Button>
            </Tooltip>

          }

          {(isSuperAdmin(user!) || isAdmin(user!)) &&

            <Button
              onClick={publishProducts}
              margin="0 10px 0 0"
              bgColor={Main.gray}
              borderColor={Main.gray}
              disabled={selectedRows.length === 0}
            >
              Publish
            </Button>
          }

          <Button
            onClick={() => {
              if (selectedVendor) {
                navigate(`/products/new-product`);
              } else {
                renderToast(
                  toast.TYPE.INFO,
                  ProductLocalization.errors.newProduct
                );
              }
            }}
            margin="0 10px 0 0"
            bgColor="#FC780B"
            borderColor={Main.brandDark}
          >
            New product
          </Button>
        </Grid>
      </Grid>

      <ProductListingFilters
        selectedVendorId={selectedVendor ? selectedVendor.id : undefined}
        vendorsList={vendorsList}
        filterUpdate={handleFilterUpdate}
        updateColumnSettings={setColumns}
        productColumns={columns}
        loading={loading}
      />

      <Grid item xs={12} sm={12} md={12} className={classes.gridMargin} style={{ marginTop: '20px' }}>
        <ProductListingTable
          productColumns={columns}
          filters={filters}
          searchTerm={searchTerm}
          selectedVendorId={selectedVendor ? selectedVendor.id : undefined}
          setShowDeleteModal={setShowDeleteModal}
          setShowUpdateModal={setShowUpdateModal}
          setShowPublishModal={setShowPublishModal}
          setSelectedRows={setSelectedRows}
          showDeleteModal={showDeleteModal}
          showUpdateModal={showUpdateModal}
          showPublishModal={showPublishModal}
          selectedRows={selectedRows}
          reload={reload}
          setReload={setReload}
          setLoading={setLoading}
        />

        <FlexboxContainer margin='24px 0px'>
          <Button
            borderColor='#C82135'
            background='#C82135'
            onClick={onDelete}
            disabled={!selectedRows.length}
          >
            Delete Selected
          </Button>
        </FlexboxContainer>
      </Grid>
    </Grid>
  );
};

ProductListV2.defaultProps = {
  editable: false,
};

export default ProductListV2;
