import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import { Background } from 'styles/themeColors';

const StyledTabPanel = styled.div <any>`
  background-color: ${Background.white};
  padding: ${({ padding }: Props) => padding ? padding : '20px 0 30px'} !important;
`;

interface Props {
  children?: ReactNode;
  index: number;
  value: number;
  padding?: string;
};

const TabPanel = ({
  children,
  padding,
  value,
  index,
  ...other
}: Props) => {
  return (
    <StyledTabPanel
      padding={padding}
      role="tabpanel"
      hidden={value !== index}
      id={`company-tabpanel-${index}`}
      aria-labelledby={`company-tab-${index}`}
      {...other}
    >
      {
        value === index ? (
          <Box>
            {children}
          </Box>
        ) : null
      }
    </StyledTabPanel>
  );
};

export default TabPanel;
