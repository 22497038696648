import { Text } from 'styles/themeColors';

const initialColumns = [
  {
    title: '#',
    field: 'orderId',
    headerStyle: { color: Text.primary, minWidth: '135px !important' },
    cellStyle: { minWidth: '135px !important' },
    width: 'auto'
  },
  {
    title: 'Status',
    field: 'status',
    headerStyle: { color: Text.primary },
    width: '100px'
  },
  {
    title: 'Vendor',
    field: 'vendor',
    headerStyle: { color: Text.primary },
    cellStyle: (cellValue, rowData) => {
      return cellValue === 'Multiple' ? { color: Text.placeholder } : {}
    },
    width: 'auto'
  },
  {
    title: 'Warehouse',
    field: 'warehouse',
    headerStyle: { color: Text.primary },
    cellStyle: (cellValue, rowData) => {
      return cellValue === 'Multiple' ? { color: Text.placeholder } : {}
    },
    width: 'auto'
  },
  {
    title: 'Delivery',
    field: 'deliveryType',
    headerStyle: { color: Text.primary },
    width: 'auto'
  },
  {
    title: 'Country',
    field: 'company.companyCountry.countryName',
    headerStyle: { color: Text.primary },
    width: 'auto'
  },
  {
    title: 'Customer',
    field: 'customer.fullName',
    headerStyle: { color: Text.primary },
    width: 'auto'
  },
  {
    title: 'Company',
    field: 'displayCompanyName',
    headerStyle: { color: Text.primary },
    width: 'auto'
  },
  {
    title: 'Date',
    field: 'created',
    type: 'date',
    dateSetting: { locale: 'en-US' },
    headerStyle: { color: Text.primary },
    width: 'auto',
    defaultSort: 'desc'
  },
  {
    title: 'Payment',
    field: 'paymentType',
    headerStyle: { color: Text.primary },
    width: 'auto'
  },
  {
    title: 'Updated by',
    field: 'updatedBy',
    headerStyle: { color: Text.primary },
    cellStyle: (cellValue, rowData) => {
      return cellValue === 'Multiple' ? { color: Text.placeholder } : {}
    },
    width: 'auto'
  },
  {
    title: 'Total amount',
    field: 'totalPriceWithTax',
    render: rowData => {
      rowData.totalPriceWithTax = Number(rowData.totalPriceWithTax).toFixed(2);
      return rowData.company?.companyCountry?.countryName === 'Canada' ? `C$${rowData.totalPriceWithTax}` : `$${rowData.totalPriceWithTax}`
    },
    headerStyle: { color: Text.primary, flexDirection: 'row' },
    width: 'auto'
  }
];

export default initialColumns;
