import React from 'react';
import styled from 'styled-components';
import { navigate } from 'hookrouter';
import Customer from 'data/Customer';
import CustomerInformation from 'common/Customers/CustomerInformation';
import ActiveAssignments from 'common/Customers/ActiveAssignments';
import SuggestedAssignments from 'common/Customers/SuggestedAssignments';
import EditorSection from 'view/EditorSection';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import { Color, FontWeight, FontSize } from 'lib/styles';
import { Background, Main } from 'styles/themeColors';

const SuccessButton = styled(Button) <any>`
  background-color: ${Main.success};
  font-weight: ${FontWeight.bold};
  border: none;
`;

const NeutralButton = styled(Button) <any>`
  color: ${Color.black} !important;
  background-color: ${Background.white};
  margin-right: 20px;
`;

const StyledText = styled.span`
  font-size: ${FontSize.small};
  font-weight: ${FontWeight.bold};
  letter-spacing: 1px;
  color: ${({ color }) => color};
`;

interface Props {
  customerDetails: Customer;
};

const InfoAndCompanies = ({
  customerDetails
}: Props) => {

  /* TODO: FILL IT WHEN WE HAVE NEW RELATIONS IT WAS LIKE THIS [...customerDetails.potentialCompanies, ...customerDetails.mdmCompanyRecords] */
  const suggestedCompanies: any[] = [];

  return (
    <>
      <StyledText color={Color.darkerGray}>OFMP Customer ID: </StyledText>
      <StyledText color={Color.black}>{customerDetails.customerId}</StyledText>
      <EditorSection
        disablePaddingBottom={true}
        borderColor={Color.linkWater}
      >
        <CustomerInformation customerDetails={customerDetails} />
      </EditorSection>
      {/* <ActiveAssignments companies={customerDetails.companies} /> */}
      {/* <EditorSection disablePaddingBottom noBorder>
        <SuggestedAssignments companies={suggestedCompanies} />
      </EditorSection> */}
      {/* <FlexboxContainer justifyContent="flex-start" alignItems="center" margin="20px 0">
        <NeutralButton onClick={() => navigate('/customers')}>Cancel</NeutralButton>
        <SuccessButton>Save</SuccessButton>
      </FlexboxContainer> */}
    </>
  );
};

export default InfoAndCompanies;
