import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Modal from 'components/UI/Modal';
import styled from 'styled-components';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Pill from 'components/UI/Pill';
import Button from 'components/UI/Button';
import Icon from 'view/Icon';
import { useAPI } from 'lib/API';
import { Space } from 'styles/themeSpaces';
import {
  forcePublishPricebooks,
  getPricebooksById,
  uploadPricebooksForUser,
  deletePricebook,
  reverseDeletePricebook,
  forceDeletePricebook,
  cancelDraftPricebook,
} from 'requests/pricebook';
import { Check } from '@material-ui/icons';
import { Height } from 'styles/themeHeights';
import { Text } from 'styles/themeColors';
import readExcelFile from 'read-excel-file';
import { PricebookState, PricebookType } from 'data/Pricebook';
import Vendor from 'data/Vendor';
import Customer, { CustomerExtended } from 'data/Customer';
import Spinner from 'components/UI/Spinner';
import { Close } from '@material-ui/icons';
import PricebooksPreview from './PricebooksPreview';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import { formatDateTime } from 'utils/formatters';
import { useAuth } from 'lib/Auth';
import { Role } from 'data/User';
import {
  checkFileSize,
  checkFileType,
  errorLogUrl,
  filenameWithoutExtension,
  fileSizeToMb,
  pathToFileToFilename,
  verifyFileTemplate,
} from '../priceebook.utils';
import { checkUserPricebookDeleteNowOption } from 'utils/pricebooks';

const ButtonIcon = styled(Icon).attrs({ size: 16 })<any>`
  color: #666c73;
`;

const StyledFileDownloadIcon = styled(Icon).attrs({
  name: 'FileDownloadIconWhite',
  size: 20,
})`
  color: white !important;
  margin: 0px 8px 10px 0px;
`;

const DownloadTemplate = styled.a`
  display: flex;
  height: 36px;
  background-color: transparent !important;
  color: #666c73 !important;
  border-color: #666c73 !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;
const ButtonIconLarge = styled(Icon).attrs({ size: 24 })<any>`
  color: #71777c;
  margin-right: 2px;
`;

const DownloadErroLog = styled.a`
  display: flex;
  height: 36px;
  background-color: black !important;
  color: white !important;
  border-color: #666c73 !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;

const Title = styled.h5<any>`
  font-size: 16px;
  font-weight: 700;
`;
const SpanItalic = styled.span<any>`
  padding: 0px 8px 32px 8px;
  display: inline-block;
  color: #53595e;
  font-style: italic;
  font-size: 16px;
  font-weight: 700;
`;

const SubTitle = styled.h5<any>`
  margin: 0px !important;
  font-size: 16px;
  line-height: 16px;
`;

const StepNumber = styled.span<any>`
  font-size: 16px;
  line-height: 20px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  text-align: center;
  color: #fff;
  padding: 2px 7px;
  margin-bottom: 4px;
  background: ${({ $bg }) => ($bg ? '#F7991C' : '#bfc2c5')};
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

const Span2 = styled.span`
  text-align: left;
  font-size: 14px;
  margin-top: 4px;
`;

const Span3 = styled.span`
  margin: ${Space.spBase} 2px;
`;

const UploadError = styled.span`
  text-align: left;
  font-size: 12px;
  color: #c82135;
`;

const ColumnName = styled.div`
  width: 160px;
  font-size: ${Space.sp2x};
  line-height: 24px;
  color: ${Text.primary};
  padding-top: ${Space.spHalf};
  margin-right: ${Space.sp3x};
`;

const ColumnValue = styled.div<any>`
  font-size: ${Space.sp2x};
  line-height: 20px;
  background-color: ${({ $bgcolor }) => ($bgcolor ? $bgcolor : 'transparent')};
  padding: ${({ $padding }) => ($padding ? $padding : '0px')};
  color: ${({ $color }) => ($color ? $color : Text.primary)};
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
  border-radius: 6px;
`;

const PricebookPublishText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;

const CloseIcon = styled(Close)`
  cursor: pointer;
`;
enum VerificationStates {
  VERIFIYING,
  VERIFICATION_FAILED,
  VERIFICATION_SUCCESS,
}
interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm?: (value: string) => void;
  vendorName?: string;
  selectedAssignedBy?: Vendor;
  selectedAssignedTo?: CustomerExtended;
  companyId?: string;
  companyName?: string;
  preview?: boolean;
  pricebookPreviewId?: number;
  selectedVendor?: Vendor;
  pricebookPreviewData: any;
  onReplaceClick: (pricebookId: number) => void;
  pricebookType: string;
  /* For Geography Pricebook */
  warehouseId?: string;
  warehouseName?: string;
  /* For Reference Pricebook */
  countryId?: number;
  countryName?: string;
}

const TEXT_LABELS = {
  PUBLISH_NOW_MODAL_HEADING: 'Publish new prices now?',
  DELETE_PRICEBOOK_HEADING: 'Delete active pricebook?',
  DELETE_PRICEBOOK_WARNING_TEXT:
    'Pricebook will be scheduled for deletion this midnight. A completion reference prices will be used.',
  DELETE_NOW_PRICEBOOK_HEADING: 'Delete active pricebook now?',
  DELETE_NOW_PRICEBOOK_WARNING_TEXT:
    'This may disrupt users which are placing orders at the moment.',
  NEW_PRICEBOOK_VERIFIED_TEXT:
    'New prices will replace current at 00:00. You can close this window.',
};

const PricebooksUploadModal = ({
  show,
  onClose,
  onConfirm,
  vendorName,
  selectedAssignedBy,
  selectedAssignedTo,
  companyId,
  companyName,
  preview,
  pricebookPreviewId,
  selectedVendor,
  onReplaceClick,
  pricebookPreviewData,
  pricebookType,
  warehouseId,
  warehouseName,
  countryId,
  countryName,
}: Props) => {
  const { user } = useAuth();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);
  const [verificationState, setVerificationState] =
    useState<VerificationStates | null>(null);
  const [publicationState, setPublicationState] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [pricebookDeleteWarningModal, setPricebookDeleteWarningModal] =
    useState(false);
  const [pricebookDeleteNowWarningModal, setPricebookDeleteNowWarningModal] =
    useState(false);
  const [uploadFileSizeError, setUploadFileSizeError] = useState(false);
  const [uploadFileTypeError, setUploadFileTypeError] = useState(false);
  const [uploadFileTemplateError, setUploadFileTemplateError] = useState(false);
  const [pricebooksData, setPricebooksData] = useState<any>();
  const [pricebooksFileName, setPricebooksFileName] = useState<string>();
  const [step, setStep] = useState<number>(1);
  const [pricebookId, setPricebookId] = useState<number>();
  const [pricebookData, setPricebookData] = useState<any>();
  const [isForcePublish, setIsForcePublish] = useState<boolean>(false);
  const [isNewPB, setIsNewPB] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const isNewPBRef = useRef<boolean>();
  const isAdmin = checkUserPricebookDeleteNowOption(user, selectedVendor);

  const [file, setFile] = useState<any>({
    imageFile: [],
    uploadDate: new Date(),
  });
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (pricebookPreviewData) {
      setPricebookData(pricebookPreviewData);
    }
  }, []);
  isNewPBRef.current = isNewPB;

  const handleClear = () => {
    if (inputRef.current != null) {
      inputRef.current.value = '';
    }
    setUploadFileSizeError(false);
    setUploadFileTypeError(false);
    setUploadFileTemplateError(false);
    setUploadSuccess(false);
    setFileUploading(false);
    setFile({
      imageFile: [],
      uploadDate: '',
    });
    setStep(1);
    setIsNewPB(false);
    setPricebookData(null);
    setPublicationState(false);
  };
  const handleUploadClick = () => {
    if (inputRef.current != null) {
      inputRef.current.value = '';
    }
    setUploadFileSizeError(false);
    setUploadFileTypeError(false);
    setUploadFileTemplateError(false);
    setUploadSuccess(false);
    setFileUploading(false);
    setFile({
      imageFile: [],
      uploadDate: '',
    });
    inputRef.current?.click();
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }
    setFile({
      imageFile: [e.target.files[0]],
      uploadDate: Date.now(),
    });
    if (!checkFileSize(e.target.files[0])) {
      setUploadFileSizeError(true);
      setFileUploading(false);
      return;
    } else if (checkFileType(e.target.files[0])) {
      setUploadFileTypeError(false);
    } else {
      setUploadFileTypeError(true);
      return;
    }
  };

  const pricebookTemplateVerify = async () => {
    const pricebooksFile = file.imageFile[0];
    const pricebooksData = await readExcelFile(file.imageFile[0]);
    setPricebooksData(pricebooksData);
    setPricebooksFileName(pricebooksFile.name);

    const templateVerification = await verifyFileTemplate(
      pricebooksFile,
      pricebookType
    );
    if (templateVerification) {
      setFileUploading(false);
      setUploadFileTemplateError(false);
      setUploadSuccess(true);
    } else {
      setUploadFileTemplateError(true);
      setUploadSuccess(false);
      return;
    }
  };

  useEffect(() => {
    if (
      file.imageFile &&
      file.imageFile.length > 0 &&
      !uploadFileSizeError &&
      !uploadFileTypeError
    ) {
      pricebookTemplateVerify();
    }
  }, [file.imageFile, uploadFileSizeError, uploadFileTypeError]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (
        pricebookData &&
        (pricebookData.priceBookState == PricebookState.UPLOADED ||
          pricebookData.priceBookState == PricebookState.PROCESSING)
      )
        pricebookById.run(pricebookData.id);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  });

  useEffect(() => {
    if (preview) {
      pricebookById.run(pricebookPreviewId);
      setStep(2);
    }
  }, [preview]);

  const pricebooksPublish = useAPI({
    deferFn: forcePublishPricebooks,
    onResolve: (result: any) => {
      pricebookById.run(
        pricebookPreviewId ? pricebookPreviewId : pricebookData.id
      );
    },
    onReject: (err: any) => {},
  });

  const pricebookDelete = useAPI({
    deferFn: deletePricebook,
    onResolve: (result: any) => {
      if (result.id) {
        setPricebookData(result);
      }
    },
    onReject: (err: any) => {},
  });

  const pricebookDeleteReverse = useAPI({
    deferFn: reverseDeletePricebook,
    onResolve: (result: any) => {
      if (result.id) {
        setPricebookData(result);
        setLoading(false);
      }
    },
    onReject: (err: any) => {
      setLoading(false);
    },
  });

  const pricebookDeleteForce = useAPI({
    deferFn: forceDeletePricebook,
    onResolve: (result: any) => {
      handleClear();
      onClose();
      setLoading(false);
    },
    onReject: (err: any) => {
      setLoading(false);
    },
  });

  const pricebookCancelDraft = useAPI({
    deferFn: cancelDraftPricebook,
    onResolve: (result: any) => {
      setCancelModal(false);
      handleClear();
      onClose();
    },
    onReject: (err: any) => {},
  });
  const onForcePublish = () => {
    setIsForcePublish(true);
    setIsNewPB(true);
    if (pricebookData) {
      pricebooksPublish.run(pricebookData.id);
    }
  };

  const handleConfirmPricebookDelete = () => {
    pricebookDelete.run(pricebookData.id);
  };

  const onDeleteConfirmPricebookHandler = () => {
    setPricebookDeleteWarningModal(true);
  };

  const onCancelDeletionPricebookHandler = () => {
    setLoading(true);
    pricebookDeleteReverse.run(pricebookData.id);
    setPricebookDeleteWarningModal(false);
  };

  const onDeleteNowConfirmPricebookHandler = () => {
    setPricebookDeleteNowWarningModal(true);
  };

  const handleDeleteNowPricebook = () => {
    setLoading(true);
    pricebookDeleteForce.run(pricebookData.id);
    setPricebookDeleteNowWarningModal(false);
  };

  const gotoNextStep = () => {
    setVerificationState(VerificationStates.VERIFIYING);
    setFileUploading(true);
    setIsNewPB(true);

    /* For Company, Reference and Geography Pricebook */
    uploadPricebooksUser.run(
      pricebooksData,
      filenameWithoutExtension(pricebooksFileName),
      {
        priceBookId: 'test',
        priceBookState: PricebookState.UPLOADED,
        previousState: PricebookState.UPLOADED,
        vendorId: selectedAssignedBy?.id,
        customerId: selectedAssignedTo?.customerId,
        companyId,
        warehouseId,
        priceBookType: pricebookType,
        countryId,
      },
      file.uploadDate
    );
  };

  const pricebookById = useAPI({
    deferFn: getPricebooksById,
    onResolve: (result: any) => {
      if (step == 1) {
        setStep(step + 1);
        setFileUploading(false);
      }
      if (result.priceBookState === PricebookState.VALIDATED) {
        setPublicationState(true);
      }
      if (result.priceBookState === PricebookState.PUBLISHED) {
        setIsForcePublish(false);
      }
      if (
        isNewPBRef.current &&
        result.priceBookState === PricebookState.PUBLISHED
      ) {
        setIsNewPB(false);
        renderToast(
          toast.TYPE.SUCCESS,
          `New ${
            result.priceBookType === PricebookType.COMPANY
              ? 'company pricebook'
              : result.priceBookType === PricebookType.GEOGRAPHY
              ? 'geography pricebook'
              : result.priceBookType === PricebookType.REFERENCE
              ? 'reference pricebook'
              : ''
          } "${
            result.priceBookName
          }" is published. New prices are applied to the products`
        );
      }
      if (
        isNewPBRef.current &&
        result.priceBookState === PricebookState.VALIDATION_FAILED
      ) {
        setIsNewPB(false);
        renderToast(
          toast.TYPE.ERROR,
          `Pricebook upload failed. Errors were found during the file verification.`,
          {
            showErrorLogButton: true,
            errorHref: errorLogUrl(result.pathToFile),
          }
        );
      }
      setPricebookData(result);
    },
    onReject: (err: any) => {},
  });

  const uploadPricebooksUser = useAPI({
    deferFn: uploadPricebooksForUser,
    onResolve: (result: any) => {
      setPricebookId(result.id);
      pricebookById.run(result.id);
      setStep(step + 1);
      setFileUploading(false);
    },
    onReject: (err: any) => {},
  });

  const confirmCancel = (
    <Modal
      showModal={cancelModal}
      onClose={() => {
        setCancelModal(false);
      }}
      onClick={() => {
        pricebookCancelDraft.run(
          pricebookPreviewId ? pricebookPreviewId : pricebookData.id
        );
      }}
      showGhostButton={true}
      ghostButtonText="Cancel"
      color="#71777C"
      borderColor="#C1C9D1"
      title="Cancel Draft"
      primaryButtonText="Confirm"
      primaryButtonColor="#C82135"
    >
      <FlexboxContainerComponent>
        <Span>
          New upload will be canceled. Old prices will remain the same.
        </Span>
      </FlexboxContainerComponent>
    </Modal>
  );

  const confirmPublish = (
    <Modal
      showModal={publishModal}
      onClose={() => {
        setPublishModal(false);
      }}
      onClick={() => {
        onForcePublish();
        setPublishModal(false);
      }}
      showGhostButton={true}
      ghostButtonText="Cancel"
      color="#71777C"
      borderColor="#C1C9D1"
      title={TEXT_LABELS.PUBLISH_NOW_MODAL_HEADING}
      primaryButtonText="Confirm"
      primaryButtonColor="#C82135"
    >
      <FlexboxContainerComponent>
        <Span>
          This may disrupt users which are placing orders at the moment.
        </Span>
      </FlexboxContainerComponent>
    </Modal>
  );

  const confirmDeletePricebooks = (
    <Modal
      showModal={pricebookDeleteWarningModal}
      onClose={() => {
        setPricebookDeleteWarningModal(false);
      }}
      onClick={() => {
        handleConfirmPricebookDelete();
        setPricebookDeleteWarningModal(false);
      }}
      showGhostButton={true}
      ghostButtonText="Cancel"
      color="#53595E"
      borderColor="#C1C9D1"
      title={TEXT_LABELS.DELETE_PRICEBOOK_HEADING}
      primaryButtonText="Confirm"
      primaryButtonColor="#C82135"
    >
      <FlexboxContainerComponent>
        <Span3>{TEXT_LABELS.DELETE_PRICEBOOK_WARNING_TEXT}</Span3>
      </FlexboxContainerComponent>
    </Modal>
  );

  const confirmDeleteNowPricebook = (
    <Modal
      showModal={pricebookDeleteNowWarningModal}
      onClose={() => {
        setPricebookDeleteNowWarningModal(false);
      }}
      onClick={() => {
        handleDeleteNowPricebook();
        setPricebookDeleteNowWarningModal(false);
      }}
      showGhostButton={true}
      ghostButtonText="Cancel"
      color="#53595E"
      borderColor="#C1C9D1"
      title={TEXT_LABELS.DELETE_NOW_PRICEBOOK_HEADING}
      primaryButtonText="Confirm"
      primaryButtonColor="#C82135"
    >
      <FlexboxContainerComponent>
        <Span>{TEXT_LABELS.DELETE_NOW_PRICEBOOK_WARNING_TEXT}</Span>
      </FlexboxContainerComponent>
    </Modal>
  );

  const UploadComponent = (
    <FlexboxContainerComponent flexDirection="column" alignItems="center">
      <FlexboxContainerComponent
        justifyContent="center"
        margin="32px 0px 0px 0px"
      >
        <Button
          bgColor="#FFFFFF"
          color={
            uploadSuccess ||
            uploadFileSizeError ||
            uploadFileTemplateError ||
            uploadFileTypeError
              ? '#FFFFFF'
              : '#18222B'
          }
          borderColor="#666C73"
          padding="10px 0"
          width="320px"
          onClick={file.imageFile.length > 0 ? () => {} : handleUploadClick}
          cursor={file.imageFile.length > 0 ? 'initial' : 'pointer'}
          background={
            uploadSuccess
              ? '#18222B'
              : uploadFileSizeError ||
                uploadFileTemplateError ||
                uploadFileTypeError
              ? '#C82135'
              : '#FFFFFF'
          }
        >
          <div></div>
          <FlexboxContainerComponent width="100%">
            <div
              style={{
                width: file.imageFile.length > 0 ? '90%' : '100%',
                textAlign: 'center',
                alignSelf: 'center',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {file.imageFile.length > 0
                ? file.imageFile[0].name
                : 'Select .xlsx file'}
            </div>

            {file.imageFile.length > 0 ? (
              <FlexboxContainerComponent>
                {' '}
                <CloseIcon id="close-icon" onClick={handleClear} />{' '}
              </FlexboxContainerComponent>
            ) : (
              ''
            )}
          </FlexboxContainerComponent>
        </Button>
        <input
          type="file"
          ref={inputRef}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </FlexboxContainerComponent>
      <FlexboxContainerComponent width="320px">
        {uploadFileSizeError ? (
          <UploadError>
            {Number(fileSizeToMb(file.imageFile[0].size)).toPrecision(4)} MB.
            Please upload files below 20 MB
          </UploadError>
        ) : uploadFileTypeError ? (
          <UploadError>You can only upload .xlsx file format</UploadError>
        ) : uploadFileTemplateError ? (
          <UploadError>
            Wrong column set. Please compare to the template
          </UploadError>
        ) : fileUploading &&
          !uploadFileSizeError &&
          !uploadFileTemplateError &&
          !uploadFileTypeError ? (
          <Span2>
            <Spinner color="black" size={14} /> Uploading. Do not close this
            window
          </Span2>
        ) : uploadSuccess ? (
          <Span2>
            {Number(fileSizeToMb(file.imageFile[0].size)).toPrecision(4)}mb
          </Span2>
        ) : (
          <Span2>Up to 20 Mb</Span2>
        )}
      </FlexboxContainerComponent>
    </FlexboxContainerComponent>
  );

  const VerificationComponent = (
    <FlexboxContainerComponent flexDirection="column" margin="32px 0px 0px 0px">
      {pricebookData ? (
        <FlexboxContainerComponent flexDirection="column">
          <FlexboxContainerComponent alignItems="center" flexDirection="row">
            <ColumnName>Filename</ColumnName>
            <ColumnValue>
              {pricebookData.pathToFile
                ? `${pathToFileToFilename(pricebookData.pathToFile)}.xlsx`
                : file.imageFile[0]
                ? file.imageFile[0].name
                : ''}
            </ColumnValue>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent alignItems="center" flexDirection="row">
            <ColumnName>Uploaded</ColumnName>
            <ColumnValue>
              {formatDateTime(
                pricebookData
                  ? pricebookData.createdOn
                  : new Date(file.uploadDate).toDateString(),
                true
              )}
            </ColumnValue>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent alignItems="center" flexDirection="row">
            <ColumnName>Status</ColumnName>
            {(pricebookData == undefined ||
              pricebookData.priceBookState == PricebookState.UPLOADED) && (
              <ColumnValue $bgcolor="#FFC107" $weight="700" $padding="4px">
                <Spinner size={16} /> Verifying, please wait
              </ColumnValue>
            )}
            {(isForcePublish ||
              pricebookData.priceBookState == PricebookState.PROCESSING) && (
              <ColumnValue $bgcolor="#FFC107" $weight="700" $padding="4px">
                <Spinner size={16} /> Publishing, please wait
              </ColumnValue>
            )}
            {pricebookData.priceBookState ==
              PricebookState.VALIDATION_FAILED && (
              <ColumnValue
                $bgcolor="#C82135"
                $weight="700"
                $padding="4px"
                $color="#FFFFFF"
              >
                Verification failed
              </ColumnValue>
            )}
            {pricebookData.priceBookState == PricebookState.VALIDATED &&
              !isForcePublish && (
                <>
                  <ColumnValue
                    $bgcolor="#2CAE44"
                    $weight="700"
                    $padding="4px"
                    $color="#FFFFFF"
                  >
                    Verified. Publication scheduled
                  </ColumnValue>
                </>
              )}
          </FlexboxContainerComponent>
          {pricebookData.priceBookState == PricebookState.VALIDATED &&
            !isForcePublish && (
              <>
                <FlexboxContainerComponent
                  alignItems="center"
                  flexDirection="row"
                >
                  <ColumnName>Name</ColumnName>
                  <ColumnValue>{pricebookData.priceBookName}</ColumnValue>
                </FlexboxContainerComponent>
                <FlexboxContainerComponent
                  alignItems="center"
                  flexDirection="row"
                >
                  <ColumnName>ID</ColumnName>
                  <ColumnValue>{pricebookData.priceBookVendorId}</ColumnValue>
                </FlexboxContainerComponent>
                <PricebookPublishText>
                  {TEXT_LABELS.NEW_PRICEBOOK_VERIFIED_TEXT}
                </PricebookPublishText>
              </>
            )}
        </FlexboxContainerComponent>
      ) : (
        <FlexboxContainerComponent flexDirection="row" justifyContent="center">
          <Spinner />
        </FlexboxContainerComponent>
      )}
    </FlexboxContainerComponent>
  );

  const CompanyPricebookHeading = (
    <Title>
      <SpanItalic>Assigned by</SpanItalic>
      {pricebookData && pricebookData?.vendor?.name
        ? pricebookData.vendor.name
        : vendorName}
      <SpanItalic>for</SpanItalic>
      {companyName || pricebookData?.company?.companyName}
    </Title>
  );

  const GeographyPricebookHeading = (
    <Title>
      <SpanItalic>Assigned by</SpanItalic>
      {pricebookData && pricebookData?.vendor?.name
        ? pricebookData.vendor.name
        : vendorName}
      <SpanItalic>for</SpanItalic>
      {warehouseName || pricebookData?.warehouse?.name}
    </Title>
  );

  const ReferencePricebookHeading = (
    <Title>
      <SpanItalic>Assigned by</SpanItalic>
      {pricebookData && pricebookData?.vendor?.name
        ? pricebookData.vendor.name
        : vendorName}
      <SpanItalic>for</SpanItalic>
      {countryName || pricebookData?.country?.countryName}
    </Title>
  );

  const isStepTwoFileVerification =
    step !== 1 &&
    (!pricebookData ||
      pricebookData?.priceBookState === PricebookState.UPLOADED);
  const showNextButton =
    isStepTwoFileVerification || (uploadSuccess && step == 1);
  const disableNextButton = isStepTwoFileVerification || fileUploading;

  return (
    <>
      {' '}
      {confirmCancel}
      {confirmPublish}
      {confirmDeleteNowPricebook}
      {confirmDeletePricebooks}
      <Modal
        width="825px"
        showModal={
          cancelModal ||
          publishModal ||
          pricebookDeleteWarningModal ||
          pricebookDeleteNowWarningModal
            ? false
            : show
        }
        onClose={() => {
          handleClear();
          onClose();
        }}
        showButtons={false}
        title={
          pricebookType === PricebookType.COMPANY
            ? 'Company pricebook'
            : pricebookType === PricebookType.GEOGRAPHY
            ? 'Geography pricebook'
            : pricebookType === PricebookType.REFERENCE
            ? 'Reference pricebook'
            : ''
        }
        modalFooterMargin="0"
        left="50vw"
        marginLeft="-412px"
        iconName={
          pricebookType === PricebookType.COMPANY
            ? 'PricebookModalIcon'
            : pricebookType === PricebookType.GEOGRAPHY
            ? 'PinDrop'
            : pricebookType === PricebookType.REFERENCE
            ? 'Public'
            : ''
        }
        showCloseIcon
        top="15%"
      >
        {pricebookType === PricebookType.COMPANY
          ? CompanyPricebookHeading
          : pricebookType === PricebookType.GEOGRAPHY
          ? GeographyPricebookHeading
          : pricebookType === PricebookType.REFERENCE
          ? ReferencePricebookHeading
          : null}
        {pricebookData &&
          (pricebookData.priceBookState == PricebookState.PUBLISHED ||
            pricebookData.priceBookState == PricebookState.DELETE) && (
            <PricebooksPreview
              pricebook={pricebookData}
              onReplaceClick={onReplaceClick}
              onDeleteConfirmPricebookHandler={onDeleteConfirmPricebookHandler}
              onCancelDeletionPricebookHandler={
                onCancelDeletionPricebookHandler
              }
              onDeleteNowPricebookHandler={onDeleteNowConfirmPricebookHandler}
              isLoading={isLoading}
              isAdmin={isAdmin}
            />
          )}
        {pricebookData &&
        (pricebookData.priceBookState == PricebookState.PUBLISHED ||
          pricebookData.priceBookState == PricebookState.DELETE) ? (
          <></>
        ) : (
          <FlexboxContainerComponent
            bgColor="#F9F9FA"
            border="1px solid #E1E6EB"
            borderRadius="6px"
            padding="24px"
            flexDirection="column"
          >
            <FlexboxContainerComponent
              alignItems="baseline"
              margin="0 0 32px 0"
            >
              <Pill
                margin="0 0 0px 8px"
                size="xsPlus"
                padding="4px"
                bgColor="#F0F1F4"
                color="#71777C"
              >
                New Pricebook (Draft)
              </Pill>
            </FlexboxContainerComponent>
            <FlexboxContainerComponent margin="0 auto" flexDirection="row">
              <FlexboxContainerComponent
                flexDirection="column"
                alignItems="center"
                width="24px"
              >
                <StepNumber $bg={step >= 1}>
                  {step > 1 ? (
                    <Check
                      style={{
                        color: '#FFFFFF',
                        height: Height.xsPlus,
                        width: '12px',
                      }}
                    />
                  ) : (
                    <>1</>
                  )}
                </StepNumber>
                <SubTitle>Upload</SubTitle>
              </FlexboxContainerComponent>
              <hr
                style={{
                  width: '150px',
                  color: '#C1C9D1',
                  margin: '12px 4px',
                  height: '1px',
                }}
              ></hr>
              <FlexboxContainerComponent
                flexDirection="column"
                alignItems="center"
                width="24px"
              >
                <StepNumber $bg={step >= 2}>
                  {publicationState ? (
                    <Check
                      style={{
                        color: '#FFFFFF',
                        height: Height.xsPlus,
                        width: '12px',
                      }}
                    />
                  ) : (
                    <>2</>
                  )}
                </StepNumber>
                <SubTitle>Verification</SubTitle>
              </FlexboxContainerComponent>
              <hr
                style={{
                  width: '150px',
                  color: '#C1C9D1',
                  margin: '12px 4px',
                  height: '1px',
                }}
              ></hr>
              <FlexboxContainerComponent
                flexDirection="column"
                alignItems="center"
                width="24px"
              >
                <StepNumber $bg={publicationState}>3</StepNumber>
                <SubTitle>Publication</SubTitle>
              </FlexboxContainerComponent>
            </FlexboxContainerComponent>
            {step == 1 && UploadComponent}
            {step == 2 && VerificationComponent}
            <FlexboxContainerComponent
              padding="24px 0px"
              justifyContent={step == 1 ? 'right' : 'space-between'}
            >
              {step != 1 && (
                <Button
                  id="modal-main-button"
                  bgColor="#F9F9FA"
                  color="#C82135"
                  borderColor="#C82135"
                  disabled={isForcePublish}
                  onClick={() => {
                    setCancelModal(true);
                  }}
                >
                  Cancel draft
                </Button>
              )}
              {showNextButton ? (
                <Button
                  id="modal-main-button"
                  bgColor={disableNextButton ? '#E9ECEF' : '#FC780B'}
                  borderColor={disableNextButton ? '#E9ECEF' : '#FC780B'}
                  color={disableNextButton ? '#BFC2C5' : '#FFFFFF'}
                  onClick={gotoNextStep}
                  disabled={disableNextButton}
                >
                  Next
                </Button>
              ) : !uploadSuccess && step == 1 ? (
                <DownloadTemplate
                  href={
                    pricebookType === PricebookType.COMPANY
                      ? '/Favorites Template.xlsx'
                      : pricebookType === PricebookType.REFERENCE
                      ? '/Reference_Template.xlsx'
                      : '/Geography_Template.xlsx'
                  }
                >
                  <FlexboxContainerComponent>
                    <ButtonIcon name="FileDownloadIcon" />{' '}
                    <FlexboxContainerComponent>
                      Download .xlsx template
                    </FlexboxContainerComponent>
                  </FlexboxContainerComponent>
                </DownloadTemplate>
              ) : pricebookData &&
                pricebookData.priceBookState ==
                  PricebookState.VALIDATION_FAILED ? (
                <DownloadErroLog href={errorLogUrl(pricebookData.pathToFile)}>
                  <StyledFileDownloadIcon />
                  Download error log
                </DownloadErroLog>
              ) : !isForcePublish &&
                pricebookData &&
                pricebookData.priceBookState == PricebookState.VALIDATED &&
                user?.role != Role.Vendor &&
                user?.role != Role.VendorAdmin &&
                !selectedVendor ? (
                <Button
                  margin="0 4px 0 0"
                  borderColor="#71777C"
                  bgColor="#F9F9FA"
                  onClick={() => {
                    if (
                      !isForcePublish ||
                      pricebookData.priceBookState === PricebookState.PUBLISHED
                    ) {
                      setPublishModal(true);
                    }
                  }}
                  disabled={isForcePublish}
                  color="#71777C"
                >
                  <ButtonIconLarge name="PricebookDeleteWarning" />
                  Publish now
                </Button>
              ) : (
                <></>
              )}
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
        )}
      </Modal>
    </>
  );
};

export default PricebooksUploadModal;
