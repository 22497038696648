import { get } from 'lib/API';
import ShippingCarrier from 'data/ShippingCarrier';

export const loadShippingCarriers = async ({ token }: any): Promise<ShippingCarrier[]> => {
  return await get('v1/shippingCarriers', token);
};

export const loadShippingCarriersByCountry = async ( [countryId]: any, { token }: any): Promise<ShippingCarrier[]> => {
  return await get(`v1/shippingCarriers/${countryId}`, token);
};
