import { PricebookExtended, PricebookState } from '../../../../data/Pricebook';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import React, { MutableRefObject } from 'react';
import { pricebookFileUrl } from '../../priceebook.utils';

interface Props {
  pricebook: PricebookExtended;
  open: boolean;
  setOpen: (open: boolean) => void;
  popperAnchorRef: MutableRefObject<any>;
  doReplace: boolean;
  onPreview: (
    previewId: number,
    pricebookType?: string,
    companyName?: string,
    vendorName?: string
  ) => void;
  onReplaceClick: (pricebookId: number) => void;
}

const onDownloadPricebookClick = (pathToFile: string) => {
  window.open(pricebookFileUrl(pathToFile), '_blank');
};

const ActionsPopper = ({ pricebook, doReplace, open, setOpen, popperAnchorRef, onReplaceClick, onPreview }: Props) => {
  const handleClose = (event: any) => {
    if (
      popperAnchorRef.current &&
      (popperAnchorRef.current as any).contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  const showReplaceButton =
    pricebook.priceBookState === PricebookState.PUBLISHED &&
    !pricebook.replacementPricebookState;

  return (
    <Popper
      open={open}
      anchorEl={popperAnchorRef.current}
      placement="bottom-end"
      transition
      disablePortal
      style={{ zIndex: 1 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            margin: 6,
          }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="menu-list-grow">
                <MenuItem
                  onClick={() => {
                    if (doReplace) {
                      onReplaceClick(pricebook.id);
                    } else {
                      onPreview(
                        pricebook.id,
                        pricebook.priceBookType,
                        pricebook.company?.companyName,
                        pricebook.vendor.name
                      );
                    }
                  }}
                >
                  Preview
                </MenuItem>
                {pricebook.pathToFile && (
                  <MenuItem onClick={() => onDownloadPricebookClick(pricebook.pathToFile)}>
                    Download as .xlsx
                  </MenuItem>
                )}
                {showReplaceButton && (
                  <MenuItem onClick={() => onReplaceClick(pricebook.id)}>
                    Replace
                  </MenuItem>
                )}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>);
};

export default ActionsPopper;