import React from 'react';
import styled from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { DatePicker } from '@material-ui/pickers';
import { FontWeight } from 'lib/styles';
import { Text } from 'styles/themeColors';
import DatePickerInput from 'components/UI/DatePickerInput';
import * as Cons from 'data/Consignment';
import { TEXT_LABELS } from 'constants/order';

const LabelText = styled.span`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
  margin-bottom: 5px;
  color: ${Text.primary};
  font-size: 15px;
`;

interface Props {
  isRental?: boolean;
  consignmentData?: Cons.ConsignmentDetails;
  datesFormat?: "MMM dd, yyyy" | "yyyy MMM dd",
  showIcon?: boolean;
  showNewDates?: boolean;
  showOriginal?: boolean;
  margin?: string;
}

export default function RentalDates({ consignmentData, datesFormat = "MMM dd, yyyy", showIcon = true, showNewDates = false, showOriginal = true, margin="25px 0px"}: Props) {
  return (
    <>
      <FlexboxContainer flexDirection="row" margin={margin}>
        <FlexboxContainer
          flexDirection="column"
          width="150px"
          margin="0 0 0 4px"
        >
          <LabelText>{showNewDates ? TEXT_LABELS.NEW_START_DATE : TEXT_LABELS.START_DATE}</LabelText>
          <DatePicker
            value={showNewDates ? consignmentData?.modificationEntry?.newStartDate : showOriginal ? consignmentData?.rentalStartDate : consignmentData?.modificationEntry?.originalStartDate}
            inputVariant="outlined"
            format={datesFormat}
            TextFieldComponent={(props) => <DatePickerInput {...props} showIcon={showIcon} />}
            onChange={() => {
              return;
            }}
            disabled
            autoOk
          />
        </FlexboxContainer>
        <FlexboxContainer
          flexDirection="column"
          width="150px"
          margin="0 0 0 4px"
        >
          <LabelText>{showNewDates ? TEXT_LABELS.NEW_END_DATE : TEXT_LABELS.END_DATE}</LabelText>
          <DatePicker
            value={showNewDates ? consignmentData?.modificationEntry?.newEndDate : showOriginal ? consignmentData?.rentalEndDate : consignmentData?.modificationEntry?.originalEndDate}
            inputVariant="outlined"
            format={datesFormat}
            TextFieldComponent={(props) => <DatePickerInput {...props} showIcon={showIcon} />}
            onChange={() => {
              return;
            }}
            disabled
            autoOk
            showTodayButton={false}
          />
        </FlexboxContainer>
      </FlexboxContainer>
    </>
  );
}
