import { useEffect } from 'react';

const useFetchGridData = (
  componentKey: string,
  firstPageRequest: any,
  restPagesRequest: any,
  pagination: any,
  changePage: (page: number) => void,
  size: number,
  vendorId?: number,
  orderId?: string,
  currentPage?: number,
) => {
  /* get first page data */
  useEffect(() => { 
    firstPageRequest.run(size, 1, 0, vendorId, orderId);
  }, [vendorId])

  useEffect(() => {
    changePage(currentPage || 1);
  }, [currentPage])

  /* get rest of the pages data */
  useEffect(() => {
    if (
      pagination &&
      pagination[componentKey].length > 0 &&
      pagination.totalItems > pagination[componentKey].length * pagination.page
    ) {
      const take = pagination.totalItems;
      const nextPage = pagination.page + 1;
      const offset = size;

      restPagesRequest.run(take, nextPage, offset, vendorId, orderId);
    }
  }, [pagination]);
};

export default useFetchGridData;
