import { Background, Main } from 'styles/themeColors';
import { ConsignmentDetails } from 'data/Consignment';
import React from 'react';
import { CONSIGNMENT_CREDIT_PASSED, CONSIGNMENT_CREDIT_OVERRIDE } from 'constants/consignmentCreditStatuses';
import styled from 'styled-components';
import Icon from 'view/Icon';
import { CheckCircle, Cancel } from '@material-ui/icons';
import { capitalize, capitalizeFirstLetter } from 'utils/formatters';
import PickupContact from 'data/PickupContact';
import { DOW_JONES_BLOCKED } from 'constants/dowJonesStatuses';
import FeatureFlag from 'data/FeatureFlag';
import {
  CHECKING_CREDIT,
  ORDER_SENT_TO_OEM,
  READY,
  ON_HOLD,
  PROCESSING,
  MANUFACTURING,
  PACKING,
  SHIPPED_READY_FOR_PICKUP,
  READY_TO_SHIP,
  READY_FOR_PICKUP,
  DELIVERED_PICKED_UP,
  DELAYED,
  AWAITS_SHIPPING,
  AWAITS_SHIPPING_APPROVAL,
  DOW_JONES_FAILED,
  SHIPPED,
  PICKED_UP,
  RENT_IN_PROGRESS,
  RENT_RETURN_PENDING,
  RENT_COMPLETED,
  MODIFIED_BY_CUSTOMER,
  MODIFIED_BY_VENDOR,
  RENT_UPDATED,
  NEW,
} from 'constants/consignmentStates';
import { PRICE_TYPE } from 'constants/product';
import { theme } from 'OnboardingComponents/OnboardingPageTheme';
import { PRODUCT_VARIANT_TYPE } from 'constants/productVariantImport';
import { IN_STOCK } from 'constants/consignmentEntry';

const StyledPendingIcon = styled(Icon) <any>`
  margin-left: 5px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const retrieveConsignmentCreditCheckStatusText = (consignment: ConsignmentDetails): string => {
  if (consignment.state === CHECKING_CREDIT) return 'Pending';

  const status = retrieveConsignmentCreditStatus(consignment);

  return capitalize(status);
};

export const retrieveConsignmentCreditCheckStatusIcon = (consignment: ConsignmentDetails): JSX.Element => {
  if (consignment.state === CHECKING_CREDIT) return <StyledPendingIcon name="PendingCredit" fontSize='small' />;

  const status = retrieveConsignmentCreditStatus(consignment);

  return [CONSIGNMENT_CREDIT_PASSED, CONSIGNMENT_CREDIT_OVERRIDE].includes(status)
    ? <CheckCircle
      fontSize='small'
      htmlColor={Main.success}
    />
    : <Cancel
      fontSize='small'
      htmlColor={Main.error}
    />;
};

export const retrieveConsignmentCreditStatus = (consignment: ConsignmentDetails) => {
  return (consignment.consignmentCredit && consignment.consignmentCredit.status);
};

export const parseRecipientName = (pickupContact: PickupContact | any) => {
  return `${pickupContact?.firstName || ''} ${pickupContact?.lastName || ''}`;
}

export const stringifyPickupContact = (pickupContact: PickupContact) => {
  let fields = [];
  fields.push(`${pickupContact?.firstName || ''} ${pickupContact?.lastName || ''}`)
  fields.push(pickupContact?.phone)
  fields.push(pickupContact?.email)

  fields = fields.filter(x => x?.trim());
  return fields.join(", ");
}

/**
 * Recalculate Rental Start and End Dates On Change of Pickup or Shipping Date
 * @param deliveryDateValue New pickup or shipping date
 * @param rentalStartDate Old Rental Start Date
 * @param rentalEndDate Ols Rental End Date
 * @returns New Rental Start and End Dates
 */
export const recalculateRentalDates = (deliveryDateValue:any,rentalStartDate:any, rentalEndDate:any) => {
  const newShippingDate = new Date(deliveryDateValue).toUTCString();

  const oldStart = new Date(rentalStartDate);
  const oldEnd = new Date(rentalEndDate);
  const rentalPeriod = oldEnd.getTime() - oldStart.getTime();

  const newEndDate = new Date(newShippingDate);
  newEndDate.setTime(newEndDate.getTime() + rentalPeriod);

  return {
    newRentalStartDate: newShippingDate,
    newRentalEndDate: newEndDate.toUTCString()
  }
}

/**
 * Returns the display state lable of the consignment
 * @param consignmentState Consignment State
 * @param isVendor 
 * @returns 
 */
export const getMappedDisplayState = (consignmentState: string, isVendor: boolean,dowJonesStatus:string,featureFlags: FeatureFlag) => {
  const isDowJonesBlocked =
  (dowJonesStatus === DOW_JONES_BLOCKED ||
    consignmentState === DOW_JONES_FAILED) &&
  featureFlags.dowJones &&
  Boolean(featureFlags.dowJones.enabled);

  const stateMappings: Record<string, string> = {
      CHECKING_CREDIT: 'Checking Credit',
      READY: isVendor ? (isDowJonesBlocked ? 'Compliance Check' : 'New') : (isDowJonesBlocked ? 'Compliance Fail' : 'New'),
      ORDER_SENT_TO_OEM: isVendor ? (isDowJonesBlocked ? 'Compliance Check' : 'New') : (isDowJonesBlocked ? 'Compliance Fail' : 'New'),
      NEW: isVendor ? (isDowJonesBlocked ? 'Compliance Check' : 'New') : (isDowJonesBlocked ? 'Compliance Fail' : 'New'),
      DOW_JONES_FAILED: isVendor ? 'Compliance Check' : 'Compliance Fail',
      AWAITS_SHIPPING: 'Shipping cost required',
      AWAITS_SHIPPING_APPROVAL: 'Shipping cost approval',
      CANCELED: 'Canceled',
      CANCELING: isVendor ? 'Canceled' : 'Canceling',
      ON_HOLD: 'On Hold',
      PROCESSING: 'Processing',
      SPLIT_CONSIGNMENT: 'Split Order',
      MANUFACTURING: 'Manufacturing',
      PACKING: 'Preparing',
      SHIPPED_READY_FOR_PICKUP: 'Shipped / Ready for Pickup',
      READY_TO_SHIP: 'Ready to ship',
      READY_FOR_PICKUP: 'Ready for Pickup',
      DELIVERED_PICKED_UP: 'Delivered / Picked up',
      SHIPPED: 'Shipped',
      PICKED_UP: 'Picked up',
      RENT_IN_PROGRESS: 'Rental in progress',
      RENT_RETURN_PENDING: 'Rental return pending',
      RENT_COMPLETED: 'Rental completed',
      DELAYED: 'Delayed',
      MODIFIED_BY_CUSTOMER: 'Rent modified by customer',
      MODIFIED_BY_VENDOR: 'Rent modified by vendor',
      RENT_UPDATED: 'Rent Updated',
      TICKET_IN_PROGRESS: 'Ticket in progress',
      IN_STOCK: 'In Stock',
  };

  return { displayState: stateMappings[consignmentState] || '' };
};

const successStates = new Set([NEW,READY, ORDER_SENT_TO_OEM, MANUFACTURING, PROCESSING, READY_TO_SHIP, READY_FOR_PICKUP, SHIPPED_READY_FOR_PICKUP, SHIPPED, PICKED_UP, DELIVERED_PICKED_UP, PACKING, AWAITS_SHIPPING, RENT_IN_PROGRESS, RENT_COMPLETED]);
const warningStates = new Set([ON_HOLD, DELAYED, RENT_RETURN_PENDING, MODIFIED_BY_CUSTOMER, MODIFIED_BY_VENDOR, RENT_UPDATED]);
const navyStates = new Set([AWAITS_SHIPPING_APPROVAL, CHECKING_CREDIT]);

export const getPillTheme = (consignmentState:string,dowJonesStatus:string = '',isVendor:boolean = false) => successStates.has(consignmentState) && dowJonesStatus !== DOW_JONES_BLOCKED
    ? 'success'
    : warningStates.has(consignmentState) || (!isVendor && dowJonesStatus === DOW_JONES_BLOCKED)
        ? 'warning'
        : navyStates.has(consignmentState) || (isVendor && dowJonesStatus === DOW_JONES_BLOCKED)
            ? Background.navy
            : 'danger';

/**
 * Compares current and previous values in an array.
 * @param {Array<Object>} array - The array of objects for which comparison is to be done.
 * @param {Array<string>} fieldsToCompare - The fields to compare in each object.
 * @returns {Array<Object>} - Returns an array with current and previous values for each specified field.
 */
export const mapCurrentAndPreviousValuesInArray = (array: any[], fieldsToCompare: string[]) => {

  // Map the array and compare the current and previous values
  return array.map((item, index) => {
  const previousItem = index === 0 ? item : array[index - 1];

  fieldsToCompare.forEach(field => {
    item[`current${capitalizeFirstLetter(field)}`] = item[field];
    item[`previous${capitalizeFirstLetter(field)}`] = previousItem[field];
  });

  return item;
});

}

/**
 * Maps consignment type to tag color.
 * @param {string} type - The type of consignment.
 */
export const mapConsignmentTypeTag = (type: string) => {
  switch(type) {
    case PRICE_TYPE.PURCHASE:
      return theme.blue;

    case PRICE_TYPE.RENT:
      return Main.success;

    default:
      return theme.blue;
  }
}

/**
 * Maps consignment type to tag label.
 * @param {string} type - The type of consignment.
 */
export const mapConsignmentTypeLabel = (type: string) => {
  switch(type) {
    case PRICE_TYPE.PURCHASE:
      return PRODUCT_VARIANT_TYPE.PURCHASE;

    case PRICE_TYPE.RENT:
      return PRODUCT_VARIANT_TYPE.RENT;

    default:
      return PRODUCT_VARIANT_TYPE.PURCHASE;
  }
}

export const parseConsignmentId = (consignmentId?: string) => {
  if(!consignmentId) return '';
  return consignmentId?.replace('cons', '');
}
