import { apiCallFailed, apiCallFinished, apiCallStarted } from 'redux/reducers/api';
import {
  API_CALL_FAILED,
  API_CALL_FINISHED,
  API_CALL_STARTED,
} from 'redux/constants/actionTypes';
import {
  ApiCallFailed,
  ApiCallFinished,
  ApiCallStarted,
} from 'redux/types/actions';
import { State } from 'redux/types/state';

function registerApiHandlers<T extends keyof State>(rootKey: T) {
  type ReducerInitialState = typeof rootKey;

  return {
    [API_CALL_STARTED]: (
      state: State[ReducerInitialState],
      action: ApiCallStarted
    ) => apiCallStarted(state, action, rootKey),
    [API_CALL_FINISHED]: (
      state: State[ReducerInitialState],
      action: ApiCallFinished
    ) => apiCallFinished(state, action, rootKey),
    [API_CALL_FAILED]: (
      state: State[ReducerInitialState],
      action: ApiCallFailed
    ) => apiCallFailed(state, action, rootKey),
  };
}

export default registerApiHandlers;
