import React from 'react';
import styled from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Main, Background, Shade } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { BorderRadius } from 'styles/themeBorderRadius';
import { LabelPosition } from 'types/position';

const Input = styled.input`
  display: none;

  &:checked + .toggle {
    background-color: ${Shade.shade120};
  }

  &:checked + .toggle > .slider {
    align-self: flex-end;
  }
`;

const Slider = styled.div`
  height: 18px;
  width: 18px;
  background-color: ${Shade.shade450};
  border-radius: ${BorderRadius.xl};
  margin: ${Space.spHalf};
`;

const Label = styled.label<any>`
  text-align: center;
`;

const SwitchLabel = styled.label<any>`
  background-color: ${Shade.shade120};
  width: 46px;
  height: 24px;
  border-radius: ${BorderRadius.l};
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &.toggle-active {
    background-color: ${Main.accent};
    align-items: flex-end;
  }
`;

interface Props {
  id: string;
  className?: string;
  name?: string;
  value?: string;
  checked?: boolean;
  label?: string;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
  labelPosition?: LabelPosition;
};

const Switch = ({
  id,
  className,
  name,
  value,
  checked,
  label,
  onChange,
  disabled,
  labelPosition,
}: Props) => {
  const labelStyle = labelPosition === 'left'
    ? { marginRight: `${Space.spHalfPlus}` }
    : { marginLeft: `${Space.spHalfPlus}` };

  const labelElement = <Label htmlFor={id} className="base" style={labelStyle}>{label}</Label>;

  return (
    <FlexboxContainer
      alignItems="center"
      padding={label ? `${Space.spHalfPlus} ${Space.sp2x} ${Space.spHalfPlus} 0` : '0'}
      displayFlex="inline-flex"
      margin={`0 0 0 ${Space.spBasePlus}`}
    >
      {label && labelPosition === 'left' ? labelElement : null}
      <Input
        id={id}
        className={className}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={e => onChange(e.target.checked)}
        type="checkbox"
      />
      <SwitchLabel htmlFor={id} className={checked ? 'toggle-active' : 'toggle'}>
        <Slider style={{ backgroundColor: checked ? `${Background.white}` : `${Shade.shade450}` }} className="slider" />
      </SwitchLabel>
      {label && labelPosition === 'right' ? labelElement : null}
    </FlexboxContainer>
  );
};

Switch.defaultProps = {
  className: '',
  checked: false,
  disabled: false,
  labelPosition: 'right'
};

export default Switch;
