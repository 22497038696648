import InputComponent from "components/UI/Input";
import { Color, Size } from "lib/styles";
import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Main } from "styles/themeColors";
import UploadButton from 'view/UploadButton';
import placeholderImage from 'images/image_upload.svg';
import RichTextEditor from "view/RichTextEditor";
import { validateVendorCode, validateVendorName } from "utils/vendor";
import LoadingIndicator from "components/UI/LoadingIndicator";


const BlockUploadButton = styled(UploadButton)`
  background-color: ${Main.white};
  color: ${Main.accent} !important;
  border-color: ${Main.accent} !important;
  display: block;
`;

const Label = styled.label`
  display: block;
`;

const Logo = styled.img`
  flex: 1;
  height: 4.3rem;
  object-fit: contain;
  object-position: center;
`;

const LogoContainer = styled.div`
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${Size.medium};
  margin-bottom: 30px;
`;

const EmptyLogo = styled.img`
  flex: 1;
  height: 4.5rem;
  object-fit: contain;
  object-position: center;
`;

const DescriptionContainer = styled.div`
    margin-top: ${Size.medium};
    `;

const ErrorMessage = styled.p`
    color: ${Color.error};
    font-size: 12px;
    margin-top: 2px;
`

interface AboutVendorProps {
    disabled: boolean;
    vendor: any;
    setVendor: (vendor: any) => void;
    uploadImage: any;
    vendorId: any;
    errors: any;
    setErrors: any;
    isMediaUploading: boolean;
}

const AboutVendorAdminView: FunctionComponent<AboutVendorProps> = (props) => {

    const { disabled, vendor, setVendor, uploadImage, vendorId, errors, isMediaUploading } = props;

   const checkVendorDataLoaded = () => {
    return vendor.code ? true : false;
   }

    const onNameInputChange = (name: string) => {
        const errors = validateVendorName(name);

        if (errors.name) {
            props.setErrors({ ...props.errors, ...errors });
        } else {
            props.setErrors({ ...props.errors, name: '' });
        }

        setVendor({ ...vendor, name });
    }


    const onCodeInputChange = (code: string) => {
        const errors = validateVendorCode(code);

        if (errors.code) {
            props.setErrors({ ...props.errors, ...errors });
        } else {
            props.setErrors({ ...props.errors, code: '' });
        }

        setVendor({ ...vendor, code });
    }

    const onDescriptionInputChange = (description: string) => {
        if (checkVendorDataLoaded()) {
            const currentDescription = vendor.description || '';
            if (currentDescription !== description) {
                setVendor({ ...vendor, description });
            }
        }
    };
    

    const nameInput = <InputComponent
        id='vendor-name-id'
        disabled={disabled}
        placeholder='Vendor name'
        labelName="Name"
        onInputChange={onNameInputChange}
        value={vendor.name || ''}
        margin="0 0 20px 0"
        width="30%"
        isInvalid={props.errors.name}
        errorMessage={props.errors.name}
    />

    const codeInput =
        (
            <InputComponent
                id='vendor-code-id'
                disabled={vendorId || disabled}
                labelName="Vendor Code"
                onInputChange={onCodeInputChange}
                value={vendor.code || ''}
                placeholder="Type vendor code (2-8 characters)"
                margin="0 0 20px 0"
                width="30%"
                isInvalid={props.errors.code}
                errorMessage={props.errors.code}
            />
        );

    const vendorLogo =
        isMediaUploading ? <LoadingIndicator size={20} margin="29px" /> :
        vendor && vendor.media && vendor.media.length > 0
            ? <Logo src={process.env.REACT_APP_MEDIA_URL + vendor.media[0]?.path} />
            : <EmptyLogo src={placeholderImage} />;

    const logoContainer =

        <LogoContainer>
            <div style={{ color: props.errors.media && Color.error }}>
                <Label>
                    Vendor Logo
                </Label>
            </div>

            <div style={{ marginTop: 10 }}>
                {vendorLogo}
            </div>

            <div style={{ marginTop: 10 }}>
                <BlockUploadButton
                    acceptTypes={['image/*']}
                    label="Upload Image"
                    onChange={uploadImage}
                    disabled={disabled}
                />
            </div>
            {props.errors.media && <ErrorMessage>{props.errors.media}</ErrorMessage>}
        </LogoContainer>


    const descriptionInput =
        <DescriptionContainer >
            <div style={{ color: props.errors.description && Color.error }}>
                <Label>
                    Description <span style={{ fontStyle: 'italic' }}>(Optional)</span>
                </Label>
            </div>
            <RichTextEditor
                onBlur={onDescriptionInputChange}
                placeholder="Enter your vendor description (Max 512 characters allowed)"
                value={vendor.description || ''}
                disabled={disabled}
                isInvalid={props.errors.description}
                
            />
            {props.errors.description && <ErrorMessage>{props.errors.description}</ErrorMessage>}

        </DescriptionContainer>

    return (
        <>

            {nameInput}
            {codeInput}
            {logoContainer}
            {descriptionInput}
            {/* {errorMessage} */}
        </>
    );
}

export default AboutVendorAdminView;
