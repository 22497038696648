import { setProperty } from 'dot-prop';
import { addOrRemove } from 'lib/Array';
import { UpdatePageFilter, GeneralAction } from 'redux/types/actions';
import { State } from 'redux/types/state';

export const updatePageFilter = (state: State[keyof State], action: UpdatePageFilter) => {
  setProperty(state, action.stateKey, action.value);
};

export const toggleOpenSplitOrders = (state: State[keyof State], action: GeneralAction) => {
  const newOpenSplitOrders = addOrRemove(
    state.config.openSplitOrders,
    value => value,
    action.value
  );

  setProperty(state, action.stateKey, newOpenSplitOrders);
};
