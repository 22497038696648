import React, { useState } from 'react';
import { AsyncState } from 'react-async';
import { DatePicker } from '@material-ui/pickers';
import styled from 'styled-components';
import Radio from '@material-ui/core/Radio';
import * as Cons from 'data/Consignment';
import ConsignmentEntry from 'data/ConsignmentEntry';
import Modal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import DatePickerInput from 'components/UI/DatePickerInput';
import { Color } from 'lib/styles';
import { SHIPPING } from 'constants/deliveryModes';
import { FontWeight } from 'lib/styles';
import { Text } from 'styles/themeColors';
import { TEXT_LABELS } from 'constants/order';
import RentalNewDates from '../components/RentalNewDates';
import Icon from 'view/Icon';
import { MANUFACTURING } from 'constants/consignmentStates';

const WarningIcon = styled(Icon).attrs({ name: 'WarningBlack', size: 20 })``;

const CommentInput = styled.textarea<any>``;

const Label = styled.label<any>`
  font-size: 15px;
  color: ${Color.dimGray};
`;

const WarningMessage = styled.div`
  font-size: 15px;
  text-align: left;
  color: ${Color.dimGray};
`;

const LabelText = styled.span`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
`;

export interface Props {
  show: boolean;
  onClose: () => void;
  onConfirmCancel: (value: string) => void;
  onConfirmShippingUpdate: (value: boolean) => void;
  onConfirmManufacture: (value: string | null, state: string) => void;
  title: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  warningMessage: string;
  shippingDate: string;
  consignmentEntries: ConsignmentEntry[];
  isRental: boolean;
  rentalStartDate: string,
  rentalEndDate: string,
  shippingConsignmentRequest: Cons.ShippingConsignmentRequest;
  pickupConsignmentRequest: Cons.PickupConsignmentRequest;
  setShippingConsignmentRequest: (value: Cons.ShippingConsignmentRequest) => void;
  setPickupConsignmentRequest: (value: Cons.PickupConsignmentRequest) => void;
  deliveryUpdateReq: AsyncState<Cons.ConsignmentDetails>;
  consignmentId: string;
  deliveryType: string;
  state?: string;
  previousState?: string;
};

const ManufactureModal = ({
  show,
  onClose,
  onConfirmCancel,
  onConfirmShippingUpdate,
  onConfirmManufacture,
  title,
  confirmButtonText,
  cancelButtonText,
  warningMessage,
  shippingDate,
  isRental,
  rentalStartDate,
  rentalEndDate,
  consignmentEntries,
  shippingConsignmentRequest,
  pickupConsignmentRequest,
  setShippingConsignmentRequest,
  setPickupConsignmentRequest,
  deliveryUpdateReq,
  consignmentId,
  deliveryType,
  state,
  previousState
}: Props) => {
  const shippingDateUTC = shippingDate ? new Date(shippingDate).toUTCString() : new Date().toUTCString();
  const [cancellationReason, setCancellationReason] = useState('');
  // const [newRentalStartDate, setNewRentalStartDate] = useState(shippingConsignmentRequest?.rentalNewStartDate);
  // const [newRentalEndDate, setNewRentalEndDate] = useState(shippingConsignmentRequest?.rentalNewEndDate);
  const [newShippingDate, setNewShippingDate] = useState(shippingDateUTC);
  const [selectedRadio, setSelectedRadio] = useState('');
  const disabledButton = (selectedRadio === 'cancel' && !cancellationReason) || (selectedRadio === 'shipping' && !newShippingDate);
  const consignmentData = {
    rentalStartDate,
    rentalEndDate,
    // newRentalStartDate,
    // newRentalEndDate
  }

  const onConfirmShippingChange = () => {
    const isShippingRequest = consignmentEntries.every(consignmentEntry => consignmentEntry.deliveryMode === SHIPPING);
    // const requestBody = isShippingRequest
    //   ? { ...shippingConsignmentRequest, deliveryDate: newShippingDate, rentalNewStartDate: newRentalStartDate, rentalNewEndDate: newRentalEndDate }
    //   : { ...pickupConsignmentRequest, pickupDate: newShippingDate, rentalNewStartDate: newRentalStartDate, rentalNewEndDate: newRentalEndDate };
    if (isShippingRequest) {
      setShippingConsignmentRequest({ ...shippingConsignmentRequest, deliveryDate: newShippingDate });
    } else {
      setPickupConsignmentRequest({ ...pickupConsignmentRequest, pickupDate: newShippingDate });
    }

    if (new Date(newShippingDate).setUTCHours(0, 0, 0, 0) > new Date(shippingDateUTC).setUTCHours(0, 0, 0, 0)) {
      onConfirmShippingUpdate(true);
    } 
    // else if (new Date(newShippingDate).setUTCHours(0, 0, 0, 0) < new Date(shippingDateUTC).setUTCHours(0, 0, 0, 0) && new Date(newShippingDate).setUTCHours(0, 0, 0, 0) >= new Date().setUTCHours(0, 0, 0, 0)){
    //   deliveryUpdateReq.run(consignmentId, requestBody);
    // } 
    else {
      // this modal is shown on MANUFACTURING and DELAYED states. Hence, we have to check what the current state is.
      // if the current state is MANUFACTURING, we keep it at MANUFACTURING
      // the it is delayed, then we keep at the previousState
      let nextState = state === MANUFACTURING ? state : previousState;
      onConfirmManufacture(newShippingDate, nextState!);
    }
  };

  const cancellationReasonInput = selectedRadio === 'cancel' ? (
    <FlexboxContainer flexDirection="column" width="90%" margin="0 0 0 40px">
      <Label>Please, provide reason for canceling</Label>
      <CommentInput rows="5" value={cancellationReason} onChange={(event: any) => setCancellationReason(event.target.value)} />
    </FlexboxContainer>
  ) : null;

  const onUpdateShippingDate = (value:any) => {

      const newShippingDate = new Date(value).toUTCString();
      setNewShippingDate(newShippingDate);

      // if(isRental){

      //   const { newRentalStartDate, newRentalEndDate} =  recalculateRentalDates(newShippingDate, rentalStartDate, rentalEndDate);
      //   // The new pickup date will become the new rental start date, but the length of rental period will remain the same.
      //   setNewRentalStartDate(newRentalStartDate);
      //   setNewRentalEndDate(newRentalEndDate);
      // }
  }

  const updateShippingInfo = selectedRadio === 'shipping' ? (
    <>
      <FlexboxContainer flexDirection="column" width="350px" margin="0 0 20px 40px">
        <LabelText>{TEXT_LABELS.NEW_DELIVERY_DATE}</LabelText>
        <DatePicker
          value={newShippingDate}
          onChange={onUpdateShippingDate}
          inputVariant="outlined"
          format="yyyy MMM dd"
          TextFieldComponent={DatePickerInput}
          autoOk
        />
      </FlexboxContainer>
      <FlexboxContainer flexDirection="column" width="350px" margin="0 0 0 40px">
        {/* <LabelText>{TEXT_LABELS.NEW_RENTAL_DATES}</LabelText> */}
      {
        isRental && 
        <>
          <RentalNewDates consignmentData={consignmentData} shippingConsignmentRequest={shippingConsignmentRequest} />
          <FlexboxContainer flexDirection='row' gap='4px' alignItems='center' margin='10px 0px'> 
            <WarningIcon />
            <LabelText>Start and end rental dates cannot be modified during manufacturing. Please ensure any necessary changes are made after manufacturing is complete.</LabelText>
          </FlexboxContainer>
        </>
      }
      </FlexboxContainer>
    </>
  ) : null

  return (
    <Modal
      showButtons
      showModal={show}
      title={title}
      top='10%'
      onClose={onClose}
      onClick={() => selectedRadio === 'shipping' ? onConfirmShippingChange() : onConfirmCancel(cancellationReason)}
      disabled={disabledButton}
      ghostButtonText={cancelButtonText}
      primaryButtonText={confirmButtonText}
    >
        <FlexboxContainer flexDirection="column">
          <WarningMessage>{warningMessage}</WarningMessage>
          <label>
            <Radio
              color="primary"
              value="shipping"
              checked={selectedRadio === 'shipping'}
              onChange={(event) => setSelectedRadio(event.target.value)}
            />
            {
              deliveryType === SHIPPING ? 'Provide new delivery date' : 'Provide new ready for pickup date'
            }
          </label>
          {updateShippingInfo}
          <label>
            <Radio
              color="primary"
              value="cancel"
              checked={selectedRadio === 'cancel'}
              onChange={(event) => setSelectedRadio(event.target.value)}
            />
            Cancel the order
          </label>
          {cancellationReasonInput}       
        </FlexboxContainer>
    </Modal>
  );
};

ManufactureModal.defaultProps = {
  confirmButtonText: 'Confirm',
  cancelButtonText: 'Cancel'
};

export default React.memo(ManufactureModal);
