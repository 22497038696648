import { get, put, post } from 'lib/API';
import Vendor, { VendorSummary } from 'data/Vendor';

export const createVendor = async ([newVendor]: any[], { token }: any): Promise<any> => {
  return await post('v1/vendors', token, {
    name: newVendor.name,
    code: newVendor.code,
    rentReminderDays: newVendor.rentReminderDays,
    description: newVendor.description,
    mediaId: newVendor.media && newVendor.media.length > 0 ? newVendor.media[0].id : null,
    isMaterialApiEnabled: newVendor.isMaterialApiEnabled,
    isStockApiEnabled: newVendor.isStockApiEnabled,
    isSlbVendor: newVendor.isSlbVendor,
    isSalesApiEnabled: newVendor.isSalesApiEnabled
  });
};

export const loadVendor = async ([vendorId]: any[], { token }: any): Promise<Vendor> => {
  return await get(`v1/vendors/${vendorId}`, token);
};

export const loadVendors = async ([token]: any): Promise<Vendor[]> => {
  return await get('v1/vendors', token);
};

export const getVendorsWithDetails = async ([token]: any): Promise<VendorSummary[]> => {
  return get('v1/vendors/details', token);
};

export const loadCurrentVendor = ([token]: any): Promise<Vendor> => {
  return get('v1/vendor/info', token).then(({ vendor }) => vendor);
};

export const saveVendor = async ([vendorToUpdate]: any[], { token }: any): Promise<any> => {
  return await put(`v1/vendors/${vendorToUpdate.id}`, token, {
    name: vendorToUpdate.name,
    mediaId: vendorToUpdate.media && vendorToUpdate.media.length > 0 ? vendorToUpdate.media[0].id : null,
    description: vendorToUpdate.description,
    rentReminderDays: vendorToUpdate.rentReminderDays,
    isMaterialApiEnabled: vendorToUpdate.isMaterialApiEnabled,
    isStockApiEnabled: vendorToUpdate.isStockApiEnabled,
    isSlbVendor: vendorToUpdate.isSlbVendor,
    isSalesApiEnabled: vendorToUpdate.isSalesApiEnabled
  });
};

export const getVendorAttributes = async (token: string, vendorId: number): Promise<any> => {
  return await get(`v1/attributes/vendor/${vendorId}`, token);
};

export const getVendorsForCompany = async ([ companyId ]: any, { token }: any): Promise<any> => {
  return await get(`v1/vendors/vendorCompany/${companyId}`, token);
};
