import React, { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Page from "components/UI/Page";
import TabsComponent from "components/UI/Tabs";
import FlexboxContainerComponent from "components/UI/FlexboxContainer";
import Button from "components/UI/Button";
import LoadingIndicator from "components/UI/LoadingIndicator";
import { renderToast } from "components/UI/ToastNotification";
import AboutVendor from "./AboutVendor";
import { BorderRadius } from "styles/themeBorderRadius";
import { Background, Main } from "styles/themeColors";
import { Color, Size } from "lib/styles";
import { useAuth } from "lib/Auth";
import { useAPI } from "lib/API";
import { loadVendor, saveVendor } from "requests/vendor";
import { postMedia } from "requests/media";
import { validateVendorData } from "utils/vendor";
import Vendor from "data/Vendor";
import { VENDOR_SETTING_TABS } from "constants/tabs";
import placeholderImage from 'images/image_upload.svg';
import GeneralSettings from "./GeneralSettings";

const Header = styled.div`

`
const Logo = styled.img`
  flex: 1;
  height: 3.5rem;
  margin-left: ${Size.medium};
  object-fit: contain;
  object-position: center;
`;

const EmptyLogo = styled.img`
  flex: 1;
  height: 4.5rem;
  object-fit: contain;
  object-position: center;
`;

const VendorName = styled.label`
    font-size: 1.5rem;
    font-weight: bold;
    color: ${Color.black};
    margin-left: ${Size.medium};
`
const hasDataInRequiredFields = (vendor: any) => {

    if (vendor.name.trim().length === 0) {
        return false;
    }

    if (vendor.code.trim().length === 0) {
        return false;
    }

    if (vendor.media.length === 0) {
        return false;
    }

    return true;
}

interface VendorSettingsProps {
    selectedVendor?: Vendor;
}

const VendorSettings: FunctionComponent<VendorSettingsProps> = ({ selectedVendor }) => {

    const { token } = useAuth();
    const [vendor, setVendor] = useState<any>({
        name: selectedVendor?.name || '',
        code: selectedVendor?.code || '',
        description: selectedVendor?.description || '',
        media: selectedVendor?.media || [],
        isMaterialApiEnabled: selectedVendor?.isMaterialApiEnabled || false,
        isSlbVendor: selectedVendor?.isSlbVendor || true,
    });
    const [readOnly, setReadOnly] = useState(true);
    const vendorReq = useAPI({ deferFn: loadVendor });
    const [errors, setErrors] = useState<any>({});

    useEffect(() => {

        if (selectedVendor) {
            vendorReq.run(selectedVendor?.id);
        }

    }, [selectedVendor])

    useEffect(() => {
        if (vendorReq.data) {
            setVendor({
                name: vendorReq.data?.name || '',
                code: vendorReq.data?.code || '',
                description: vendorReq.data?.description || '',
                media: vendorReq.data?.media || [],
                isMaterialApiEnabled: vendorReq.data?.isMaterialApiEnabled || false,
                isSlbVendor: vendorReq.data?.isSlbVendor || true,
                rentReminderDays: vendorReq.data?.rentReminderDays || 1
            })
        }
    }, [vendorReq.data]);



    const updateReq = useAPI({
        deferFn: saveVendor,
        onResolve: () => {
            renderToast(toast.TYPE.SUCCESS, 'Vendor updated.');
            setReadOnly(true);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
            setReadOnly(true);

        }
    });


    const handleClick = () => {
        const { isValid, errors } = validateVendorData(vendor);
        if (!isValid) {
            setErrors(errors);
            return;
        }
        updateReq.run({ ...vendor, id: selectedVendor?.id });
        setErrors({});
    }

    const uploadImage = async (file: File) => {
        const image = await postMedia({
            file,
            token: token as string,
            type: 'vendor_logo',
            vendorId: selectedVendor?.id
        });

        setVendor({ ...vendor, media: [image] });

    }

    const handleCancel = () => {
        vendorReq.run(selectedVendor?.id);
        setErrors({});
        setReadOnly(true);
    }

    const isSaveDisabled = (updateReq.isPending || !hasDataInRequiredFields(vendor))

    const vendorLogo =
        vendor && vendor.media && vendor.media.length > 0
            ? <Logo src={process.env.REACT_APP_MEDIA_URL + vendor.media[0].path} />
            : <EmptyLogo src={placeholderImage} />;

    const vendorName = <VendorName>{vendor.name}</VendorName>;

    // SHow loader if the vendor is not loaded
    if (vendorReq.isPending || !vendorReq.data) {
        return <LoadingIndicator />
    }

    return (
        <Page bgColor={Background.white} width="100%">

            <Header>
                <FlexboxContainerComponent
                    justifyContent="space-between"
                    margin="0 24px 0 0"
                    alignItems="center"
                >
                    <FlexboxContainerComponent
                        alignItems="center"

                    >
                        {vendorLogo}
                        {vendorName}
                    </FlexboxContainerComponent>

                    {readOnly && <div>
                        <EditButton onClick={() => setReadOnly(false)}>
                            Edit
                        </EditButton>
                    </div>
                    }
                </FlexboxContainerComponent>
            </Header>

            <TabsComponent tabs={VENDOR_SETTING_TABS}
                theme='brandDark'
                borderRadius='20px'
                margin='8px 24px 8px 0'
                height='auto'
            >
                <AboutVendor
                    vendor={vendor}
                    setVendor={setVendor}
                    uploadImage={uploadImage}
                    disabled={updateReq.isPending || readOnly}
                    vendorId={selectedVendor?.id}
                    errors={errors}
                    setErrors={setErrors}
                    readOnly={readOnly}
                />
                <GeneralSettings
                    disabled={updateReq.isPending || readOnly}
                    vendor={vendor}
                    setVendor={setVendor}
                    errors={errors}
                    setErrors={setErrors}
                />
            </TabsComponent>

            {!readOnly && <FlexboxContainerComponent justifyContent="right">
                <CancelButton key="cancel" onClick={handleCancel}>
                    Cancel
                </CancelButton>
                <SaveButton key="save" onClick={handleClick} disabled={isSaveDisabled} >
                    Save
                </SaveButton>
            </FlexboxContainerComponent>
            }
        </Page>
    );
}


export default VendorSettings;

const CancelButton = styled(Button)`
  margin-right: 5px;
  color: ${Color.black} !important;
  background-color: ${Background.white};
  border-color: ${Main.disabled} !important;
`;

const SaveButton = styled(Button)`
  padding: 10px;
  border-radius: ${BorderRadius.m};
  background-color: ${Main.brandDark};
  border-color: ${Main.disabled} !important;
`;

const EditButton = styled(Button)`
  padding: 10px;
  border-radius: ${BorderRadius.m};
  background-color: ${Main.accent};
  border-color: ${Main.disabled} !important;
`;

