import FlexboxContainerComponent from "components/UI/FlexboxContainer";
import Modal from "components/UI/Modal";
import { Resource } from "data/Resource";
import React from "react";
import styled from "styled-components";
import { FontSize } from "styles/themeSizes";
import { getYoutubeVideoId } from "utils/formatters";
import YouTubeEmbed from "../YoutubeEmbed";

const LabelText = styled.label`
    margin-top: 20px;
    font-size: ${FontSize.medium}
`;

interface Props {
    resource?: Resource;
    modalState: boolean;
    toggleModal: () => void;
}

const PlayVideoModal = ({modalState, resource, toggleModal}: Props) => {
    return(
        <Modal
            showModal={modalState}
            showCloseIcon={false}
            width={'600px'}
            onClose={toggleModal}
            onClick={() => {}}
            primaryButtonText="Add"
            modalFooterMargin="0"
            top='25%'
            left='34%'
            title=""
            showButtons={false}
            className="play-video-modal"
        >
            <FlexboxContainerComponent flexDirection="column" margin="0px 20px">
                <YouTubeEmbed 
                    videoId={getYoutubeVideoId(resource?.url) || ''}
                />
                <LabelText>{resource?.title}</LabelText>
            </FlexboxContainerComponent>
        </Modal>
    );
}

export default PlayVideoModal;