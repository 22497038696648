import React, { useState } from 'react';
import { navigate } from 'hookrouter';
import styled from 'styled-components';
import { sendResetPasswordEmail } from 'requests/user';
import Button from 'components/UI/Button';
import ModalButtonSet from 'view/ModalButtonSet';
import Input from 'components/UI/Input';
import { Color, Size } from 'lib/styles';
import { Background, Main } from 'styles/themeColors';

const Container = styled.form`
  background-color: ${Background.white};
  border: 1px solid ${Color.gray};
  border-radius: 2px;
  margin: ${Size.huge} auto;
  max-width: 600px;
`;

const Content = styled.div``;

const ContentSection = styled.section`
  padding: ${Size.medium} ${Size.large} ${Size.large};
`;

const Heading = styled.div`
  font-size: 24px;
  padding: ${Size.large} 0;
  text-align: center;
`;

const StyledInput = styled(Input)`
  margin-bottom: ${Size.medium};
`;

interface Props {
  className?: string;
}

export default function ResetPasswordEmailEntry({ className }: Props) {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  function submit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    sendResetPasswordEmail(email);
    setSubmitted(true);
  }

  let content = (
    <>
      <StyledInput
        id='email-id'
        labelName="Email"
        placeholder='Email'
        onInputChange={value => setEmail(value)}
        type="email"
        value={email}
      />
    </>
  );

  let buttons = (
    <>
      <Button onClick={() => navigate('/')} isGhost color={Main.accent} margin="0 10px 0 0">
        Cancel
      </Button>
      <Button>Email link</Button>
    </>
  );

  if (submitted) {
    content = (
      <>
        <Content>
          Your password reset request has been submitted. If there is an account
          associated with this email address, you will receive an email with
          further instructions.
        </Content>
      </>
    );

    buttons = (
      <Button onClick={() => navigate('/')} isGhost color={Main.accent}>
        Return to Login
      </Button>
    );
  }

  return (
    <Container className={className} onSubmit={submit}>
      <ContentSection>
        <Heading>Reset password</Heading>
        {content}
      </ContentSection>
      <ModalButtonSet>{buttons}</ModalButtonSet>
    </Container>
  );
}

ResetPasswordEmailEntry.defaultProps = {};
