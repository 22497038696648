import React, { useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import styled from 'styled-components';
import Video from 'data/Video';
import VideoThumbnail from 'common/VideoThumbnail';
import { getPolicyKey } from 'lib/brightcove';
import { ReactComponent as PDPPreviewPlayIcon } from 'images/pdppreview-play-icon.svg';
import { Color, Percent } from 'lib/styles';
import { Main } from 'styles/themeColors';
import placeholderImage from 'images/placeholder-image.jpg';
import { AddPhotoAlternateOutlined } from '@material-ui/icons';

const MainImageWrapper = styled.div<{productRedesignFlag?: boolean}>`
  width: ${Percent.full};
  padding-top: 100%;
  box-shadow: ${({ productRedesignFlag }) => (productRedesignFlag ? 'none' : '0 0 4px 0 rgba(0, 0, 0, 0.3)')};
  position: relative;
`;

const MainImage = styled.img<{productRedesignFlag?: boolean}>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: ${({ productRedesignFlag }) => (productRedesignFlag ? '100%' : '90%')};
  height: ${({ productRedesignFlag }) => (productRedesignFlag ? '100%' : '90%')};
  margin: auto;
  object-fit: contain;
  border: 1px solid ${Color.gray};
  border-radius: 5px;
`;

const StyledVideoThumbnail = styled(VideoThumbnail)`
  height: ${Percent.full};
  position: absolute;
  top: 0;
  width: ${Percent.full};
  border: 1px solid ${Color.gray};
  border-radius: 6px;
`;

const ThumbnailContainer = styled.div<{productRedesignFlag?: boolean}>`
  display: flex;
  flex-wrap: wrap;
  margin-left: ${({ productRedesignFlag }) => (productRedesignFlag ? '0px' : '-16px')};
  justify-content: ${({ productRedesignFlag }) => (productRedesignFlag ? 'center' : 'unset')};
`;

const ThumbnailWrapper = styled.div<{productRedesignFlag?: boolean}>`
  flex: ${({ productRedesignFlag }) => (productRedesignFlag ? '0 1 15%' : '0 1 25%')}; 
  padding-left: ${({ productRedesignFlag }) => (productRedesignFlag ? '5px' : '16px')};
  padding-top: ${({ productRedesignFlag }) => (productRedesignFlag ? '0px' : '16px')};
`;

const ThumbnailLink = styled.div<any>`
  padding-top: 100%;
  position: relative;
  box-shadow: ${({ productRedesignFlag }) => (productRedesignFlag ? `0px` : '0 0 4px 0 rgba(0, 0, 0, 0.3)')};
  border-radius: ${({ productRedesignFlag }) => (productRedesignFlag ? '6px' : '0px')};
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${Percent.full};
    height: ${Percent.full};
    pointer-events: none;
    border-radius: ${({ productRedesignFlag }) => (productRedesignFlag ? '6px' : '0px')};
    box-shadow: ${({ productRedesignFlag }) => (productRedesignFlag ? ' 0 0 0 4px' : ' 0 0 0 2px')} ${({ active }) => (active ? Main.brandDark : 'transparent')} inset;
    z-index: 10;
  }

  &:hover::after {
    box-shadow: ${({ productRedesignFlag }) => (productRedesignFlag ? ' 0 0 0 4px' : ' 0 0 0 2px')} ${Main.brandDark} inset;
    border-radius: ${({ productRedesignFlag }) => (productRedesignFlag ? '6px' : '0px')};
  }
`;

const Thumbnail = styled.img<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: ${Percent.full};
  height: ${Percent.full};
  object-fit: contain;
  border: ${({ productRedesignFlag }) => (productRedesignFlag ? `1px solid ${Color.gray}` : '0px')};
  border-radius: ${({ productRedesignFlag }) => (productRedesignFlag ? ' 6px' : ' 0px')};
`;

const StyledPDPPreviewPlayIcon = styled(PDPPreviewPlayIcon)`
  bottom: 0;
  height: 60%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 60%;
`;

interface Props {
  images: string[];
  secondaryImages?: string[];
  onVideoClick: (video: Video) => void;
  videos: Video[];
  productRedesignFlag?: boolean;
  isUploadingProductImagery?: boolean;
};

export default function PDPPreviewImages({
  images,
  secondaryImages,
  onVideoClick,
  videos,
  productRedesignFlag=false,
  isUploadingProductImagery=false,
}: Props) {
  const brightcovePolicyKeyReq = useAsync({
    promiseFn: getPolicyKey
  });
  const [selectedImage, setSelectedImage] = useState(0);
  const [carousalImages, setCarousalImages] = useState<string[]>([]);

  useEffect(()=>{
    let totalImages = [...images];
    if(secondaryImages){
      totalImages = [...totalImages, ...secondaryImages]
    }
    if(totalImages.length > 0){
      setCarousalImages(totalImages);
    }
  }, [images, secondaryImages])

  let thumbnailContainer = null;
  if (carousalImages.length > 0) {
    const thumbnails = carousalImages.map((image, i) => (
      <ThumbnailWrapper key={i} productRedesignFlag={productRedesignFlag}>
        <ThumbnailLink
          active={selectedImage === i}
          onClick={() => setSelectedImage(i)}
          productRedesignFlag={productRedesignFlag}
        >
          <Thumbnail src={image ? process.env.REACT_APP_MEDIA_URL + image : ''} productRedesignFlag={productRedesignFlag} />
        </ThumbnailLink>
      </ThumbnailWrapper>
    ));

    const videoThumbnails = videos.map((video, i) => {
      if (brightcovePolicyKeyReq.isLoading) {
        return null;
      }

      return (
        <ThumbnailWrapper key={i} productRedesignFlag={productRedesignFlag}>
          <ThumbnailLink onClick={() => onVideoClick(video)} productRedesignFlag={productRedesignFlag}>
            <StyledVideoThumbnail
              policyKey={brightcovePolicyKeyReq.data}
              videoId={video.videoId}
              productRedesignFlag={productRedesignFlag}
            />
            <StyledPDPPreviewPlayIcon />
          </ThumbnailLink>
        </ThumbnailWrapper>
      );
    });

    thumbnailContainer = (
      <ThumbnailContainer productRedesignFlag={productRedesignFlag}>
        {thumbnails}
        {videoThumbnails}
      </ThumbnailContainer>
    );
  }else{
    return(
      productRedesignFlag && !isUploadingProductImagery ? 
      <AddPhotoAlternateOutlined style={{ fontSize: 36, alignSelf: 'center' }} /> :
      <></>
    );
  }

  const imagePreview = carousalImages[selectedImage] ? process.env.REACT_APP_MEDIA_URL + carousalImages[selectedImage] : placeholderImage;
  return (
    <>
      <MainImageWrapper productRedesignFlag={productRedesignFlag}>
        <MainImage src={imagePreview} productRedesignFlag={productRedesignFlag} />
      </MainImageWrapper>
      {thumbnailContainer}
    </>
  );
};
