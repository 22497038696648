import Button from 'components/UI/Button';
import ErrorMessage from 'view/ErrorMessage';
import Input from 'components/UI/Input';
import Link from 'view/Link';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import PasswordInput from 'view/PasswordInput';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Color, Size } from 'lib/styles';
import { useAuth } from 'lib/Auth';
import { Background, Main } from 'styles/themeColors';
import loginBackground from 'images/sh-login-bg-gray.png';
import shTitle from 'images/sh-ofmp-transparent-bg.png';

const LoginPage = styled.div`
  top: 0;
  left: 0px;
  height: 100vh;
  background: ${Main.brandDark} url(${loginBackground}) 0% 0% no-repeat padding-box;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Bottom = styled.div`
  border-top: 1px solid ${Color.darkGray};
  padding: ${Size.large};
  text-align: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const ForgotPasswordLink = styled(Link)`
  margin: ${Size.medium} 0 0 !important;
  text-align: center;
`;

const Form = styled.form`
  background-color: ${Background.white};
  border: 1px solid ${Color.darkGray};
  border-radius: ${Size.small};
  margin: 0;
  width: 600px;
`;

const Header = styled.header`
  align-items: center;
  background-color: ${Color.black};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.1875rem;
  padding: ${Size.huge} ${Size.medium};
`;

export const Img = styled.img`
  height: 4.1875rem;
  border-radius: 4px;
  position: relative;
`;

const SpacedErrorMessage = styled(ErrorMessage)`
  margin-top: ${Size.medium};
`;

const SpacedInput = styled(Input)``;

const SpacedPasswordInput = styled(PasswordInput) <any>``;

const Title = styled.h2`
  font-weight: 500;
  margin: 0px;
  text-align: center;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Size.large};
  text-align: center;
  gap: ${Size.medium};
`;

export default function Login() {

  const auth = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  if (auth.isLoading) {
    return <LoadingIndicator />;
  }

  const cantSubmit =
    auth.isPending || email.trim().length === 0 || password.trim().length === 0;

  function handleSubmit(event: React.SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();

    if (cantSubmit) return null;
    auth.login(email, password);
  }

  let errorMessage = null;
  if (auth.error) {
    errorMessage = <SpacedErrorMessage message={auth.error} />;
  }

  return (
    <LoginPage>
      <Form onSubmit={handleSubmit}>
        <Header>
          <Img alt="Seller Hub" src={shTitle} />;
        </Header>
        <Top>
          <Title>Sign in</Title>
          <SpacedInput
            id='login-email-id'
            disabled={auth.isPending}
            labelName="Email"
            onInputChange={setEmail}
            placeholder="Enter email"
            type="email"
            value={email}
          />
          <SpacedPasswordInput
            disabled={auth.isPending}
            label="Password"
            onChange={setPassword}
            placeholder="Enter password"
            value={password}
          />
          <ForgotPasswordLink to="/reset-password">
            Problems logging in?
          </ForgotPasswordLink>
          {errorMessage}
        </Top>
        <Bottom>
          <Button disabled={cantSubmit}>
            Sign in
          </Button>
        </Bottom>
      </Form>
    </LoginPage>
  );
}
