import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Background, Border, Text } from 'styles/themeColors';
import Icon from 'view/Icon';
import { FontSize } from 'styles/themeSizes';
import companyPricebookColumns from 'constants/companyPricebookColumnsNew';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Space } from 'styles/themeSpaces';
import { formatDateTime } from 'utils/formatters';
import { BorderRadius } from 'styles/themeBorderRadius';
import { PricebookExtended, PricebookState } from 'data/Pricebook';
import StateLabel from '../components/TableRow/StateLabel';
import HierarchyCell from '../components/TableRow/HierarchyCell';
import ActionsPopper from '../components/TableRow/ActionsPopper';

const MoreVerticalIcon = styled(Icon).attrs({
  name: 'MoreVerticalIcon',
  size: 20,
  cursor: 'pointer',
})`
  color: ${Text.placeholder} !important;
`;

const Row = styled.div`
  transition: all 300ms ease 0s;
  font-size: ${FontSize.medium};
  line-height: 20px;
  vertical-align: top;
  display: flex;
  flex-direction: row;
`;

const RowWrapper = styled(FlexboxContainer)`
  background-color: ${Background.white};
  border: 1px solid ${Border.light};
  border-top: none;
  border-bottom: none;

  &:nth-child(2n) {
    background-color: ${Background.light};
  }

  &:last-child {
    border-bottom: 1px solid ${Border.light};
    margin-bottom: ${Space.sp2x};
    border-radius: 0 0 ${BorderRadius.m} ${BorderRadius.m};
  }
`;

const Cell = styled.div`
  padding: 16px 8px 16px 16px;
  font-weight: 400;
  font-size: 16px;
  color: #0e141a;
`;

const CustomerValue = styled.div<any>`
  font-size: 14px;
  color: ${Text.placeholder};
`;

interface Props {
  pricebook: PricebookExtended;
  isAdmin: boolean;
  onPreview: (
    previewId: number,
    pricebookType?: string,
    companyName?: string,
    vendorName?: string
  ) => void;
  onReplaceClick?: (pricebookId: number) => void;
  lowLevelHierarchy?: boolean;
  hideVendorName?: boolean;
}
const PricebooksTableRow = ({
  pricebook,
  isAdmin = false,
  onPreview,
  onReplaceClick = () => {},
  lowLevelHierarchy = false,
  hideVendorName,
}: Props) => {
  const [showPopper, setShowPoper] = useState(false);
  const popperAnchorRef = useRef(null);

  const handleToggle = () => {
    setShowPoper(!showPopper);
  };

  const showReplacementInProgress =
    pricebook.replacementPricebookState === PricebookState.VALIDATED ||
    pricebook.replacementPricebookState === PricebookState.UPLOADED;

  const showReplacementFailed =
    pricebook.replacementPricebookState === PricebookState.VALIDATION_FAILED;

  return (
    <RowWrapper flexDirection="column" justifyContent="flex-end">
      <Row>
        {isAdmin && (
          <Cell style={{ width: companyPricebookColumns[0].width }}>
            {!hideVendorName ? pricebook.vendor.name : ''}
          </Cell>
        )}
        <Cell
          style={{
            width: companyPricebookColumns[1].width,
            textTransform: 'capitalize',
          }}
        >
          <HierarchyCell pricebook={pricebook} showAsHierarchy={lowLevelHierarchy}/>
        </Cell>
        <Cell style={{ width: companyPricebookColumns[2].width }}>
          {pricebook.country?.countryName}
        </Cell>
        <Cell style={{ width: companyPricebookColumns[3].width }}>
          { pricebook?.warehouse?.name ||
            pricebook?.company?.companyName ||
            pricebook?.country?.countryName}
          <CustomerValue>
            {pricebook.customer
              ? `${pricebook.customer.firstName}  ${pricebook.customer.lastName}`
              : ''}
          </CustomerValue>
        </Cell>
        <Cell style={{ width: companyPricebookColumns[4].width }}>
          {pricebook.priceBookName}
        </Cell>
        <Cell style={{ width: companyPricebookColumns[5].width }}>
          {pricebook.priceBookVendorId}
        </Cell>
        <Cell
          style={{ width: isAdmin ? companyPricebookColumns[6].width : '16%' }}
        >
          {pricebook.priceBookState === PricebookState.PUBLISHED ||
          pricebook.previousState === PricebookState.PUBLISHED
            ? formatDateTime(pricebook.published, false)
            : ''}
        </Cell>
        <Cell
          style={{ width: companyPricebookColumns[7].width }}
          ref={popperAnchorRef}
        >
          <FlexboxContainer justifyContent="center" alignItems="center">
            <MoreVerticalIcon onClick={handleToggle} />
          </FlexboxContainer>
        </Cell>
        <ActionsPopper
          pricebook={pricebook}
          open={showPopper}
          setOpen={setShowPoper}
          popperAnchorRef={popperAnchorRef}
          onPreview={onPreview}
          onReplaceClick={onReplaceClick}
          doReplace={showReplacementFailed || showReplacementInProgress}
        />
      </Row>
      <StateLabel
        pricebook={pricebook}
        showReplacementInProgress={showReplacementInProgress}
        showReplacementFailed={showReplacementFailed}
      />
    </RowWrapper>
  );
};

export default PricebooksTableRow;
