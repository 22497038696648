import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Space } from 'styles/themeSpaces'; 

const Heading = styled.h4<any>`
  margin-bottom: ${Space.spBasePlus};
`;

interface Props {
  children: ReactNode;
};

const PageHeading = ({
  children
}: Props) => {
  return <Heading>{children}</Heading>;
};

export default PageHeading;
