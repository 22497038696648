const initialDocumentsColumns = [
  {
    title: 'Name',
    field: 'name',
    width: '20%',
    removable: false
  },
  {
    title: 'Category',
    field: 'category.name',
    removable: false
  },
  {
    title: 'Added',
    field: 'creationTime',
    type: 'date',
    dateSetting: { locale: 'en-US' },
    defaultSort: 'desc'
  },
  {
    title: 'Valid till',
    field: 'expirationDate',
    type: 'date',
    dateSetting: { locale: 'en-US' },
    defaultSort: 'desc'
  },
];

export default initialDocumentsColumns;
