import React, { useState } from 'react';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import styled from 'styled-components';
import { Text, Border, Background } from 'styles/themeColors';
import Button from 'components/UI/Button';
import { ConsignmentDetails } from 'data/Consignment';
import ConsignmentShortPaymentModal from 'components/Consignments/Modals/ConsignmentShortPaymentModal';
import { sumProperty } from 'utils/math';
import { Space } from 'styles/themeSpaces';

const Container = styled(FlexboxContainer)`
  box-sizing: border-box;
`;

const Header = styled.h5`
  color: ${Text.primary};
  margin-bottom: 16px;
`;

const FlexItem = styled(FlexboxContainer)`
  color: ${Text.primary};
`;

const Label = styled.label<any>`
  padding-bottom: ${({ $paddingBottom }) => $paddingBottom};
  font-weight: ${({ $bold }) => $bold ? 'bold' : 'unset'};
  text-align: ${({ $textAlign }) => $textAlign};
`;

interface Props {
  show: boolean;
  consignment: ConsignmentDetails;
  reload: () => void;
};

const ConsignmentShortPaymentsBanner = ({
  show,
  consignment,
  reload
}: Props) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => setShowPopup(!showPopup);

  const paid = +(sumProperty(consignment.consignmentShortPayments, 'paid').toFixed(2));
  const total = +(consignment.totalPriceWithTax.toFixed(2));
  const outstanding = +(total - paid).toFixed(2);

  const isFullyPaid = (outstanding === 0);

  return (show)
    ? (isFullyPaid)
      ? (
        <Button
          bgColor={Background.white}
          disabled
          color={Text.primary}
        >
          Order credit is fully paid
        </Button>
      )
      : (
        <>
          <Container
            width='295px'
            flexDirection='column'
            borderRadius='6px'
            bgColor={Background.white}
            border={`1px solid ${Border.main}`}
            padding='16px'
          >
            <Header>Order credit</Header>
            <FlexboxContainer width='100%'>
              <FlexItem
                flexDirection='column'
                margin={`0 ${Space.sp4x} 0 0`}
                className='base'>
                <Label $paddingBottom='8px'>Paid</Label>
                <Label $bold $paddingBottom='16px'>${paid}</Label>
              </FlexItem>
              <FlexItem
                className='base'
                flexDirection='column'
                margin={`0 ${Space.sp4x} 0 0`}
              >
                <Label $paddingBottom='8px'>Outstanding</Label>
                <Label $bold $paddingBottom='16px' $textAlign='center'>${outstanding}</Label>
              </FlexItem>
              <FlexItem
                className='base'
                flexDirection='column'
              >
                <Label $paddingBottom='8px'>Total</Label>
                <Label $bold $paddingBottom='16px' $textAlign='center'>${total.toFixed(2)}</Label>
              </FlexItem>
            </FlexboxContainer>
            <Button bgColor={Background.navy} borderColor={Background.navy} color={`${Background.white} !important`} className='base' onClick={togglePopup} width="100%">
              Add paid amount
            </Button>
          </Container>
          {showPopup && (
            <ConsignmentShortPaymentModal
              show={showPopup}
              consignmentId={consignment.consignmentId}
              outstandingCredit={outstanding}
              paidAmount={paid}
              onCancel={togglePopup}
              reload={reload} />
          )}
        </>
      )
    : null;
};

export default ConsignmentShortPaymentsBanner;
