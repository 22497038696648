import { CAMPAIGN_HEADERS } from "constants/campaign";
import Campaign from "data/Campaign";
import Vendor from "data/Vendor";

import readExcelFile from 'read-excel-file';

export const mapDataToCampaign = (data: any): Campaign => {
    return {
        id: data.id,
        title: data.title,
        countryId: data.countryId,
        campaignTypeId: data.campaignTypeId,
        startDate: data.startDate,
        endDate: data.endDate,
        launchPerson: data.launchPerson,
        campaignLaunchCompanyName: data.launchCompany?.companyName,
        budget: data.budget,
        actualSpend: data.actualSpend,
        vendors: data.vendors || [],
        eventId: data.eventId,
    }
}


export const getVendorValue = (vendorsList: Vendor[], selectedVendorId: number) => {
    const vendor = vendorsList.find((vendor) => vendor.id === selectedVendorId);
    return vendor?.name;
}


export const verifyProductFileTemplate = async (file: File | null) => {
    if (!file)  return false;
    
    const data = await readExcelFile(file);
    return validateHeaders(data[0]);
};

const validateHeaders = (headers: any) => {
    const missingHeaders = CAMPAIGN_HEADERS.filter(header => !headers.includes(header));
    return missingHeaders.length === 0;
};

export const campaignFileUrl = (pathToFile: string) => {
    if(pathToFile)
      return (process.env.REACT_APP_MEDIA_URL || '') + '/campaigns/' + pathToFile
    return '';
};

export const mapDataToString = (data: any[]) => {
    return data.map(row => {
        return row.map((value: any) => {
        if (value instanceof Date) {
            return value.toLocaleDateString();
        }
            return value;
        });
    });
};

export const roundOff = (value: number, decimals = 0) => {
    if (typeof value !== 'number' || typeof decimals !== 'number') {
        return value;
    }
    
    const factor = Math.pow(10, decimals);
    return Math.round(value * factor) / factor;
  }