
export enum Role {
  Admin = 'Admin',
  SuperAdmin = 'SuperAdmin',
  Vendor = 'Vendor',
  VendorAdmin = 'VendorAdmin'
};

export default interface User {
  id?: string;
  email: string;
  administratorEmail: string;
  adminMailbox: string;
  customerMailbox: string;
  deadLetterMailbox: string;
  name: string;
  role: Role;
  vendorId: number;
  vendor?: string | null;
  created?: number;
  lastLogin?: number;
};

export interface UserPreferences {
  id: number;
  userId: number;
  companyTableColumnOrder: any[];
  customerTableColumnOrder: any[];
};

export function isAdmin(user: User): boolean {
  return user !== null && user.role === Role.Admin;
};

export function isSuperAdmin(user: User): boolean {
  return user !== null && user.role === Role.SuperAdmin;
};

export function isVendor(user: User): boolean {
  return user !== null && (user.role === Role.Vendor || user.role === Role.VendorAdmin);
};

export function isVendorAdmin(user: User): boolean {
  return user !== null && user.role === Role.VendorAdmin;
};

export function isOfmpAdmin(user: User): boolean {
  return user !== null &&  (user.role === Role.Admin || user.role === Role.SuperAdmin);
};

export function userIsAllowed(user: User, allowedRoles:Role[]): boolean{
  return allowedRoles.includes(user.role);
}