import React from 'react';
import styled from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Space } from 'styles/themeSpaces';

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface Props {
  error: string;
  vendorCompanyId: string;
};

const ErrorModal = ({ error, vendorCompanyId }: Props) => {
  return (
    <FlexboxContainer flexDirection="column">
      {
        error === 'API not found'
          ? (
            <>
              <Span>{vendorCompanyId} does not exist in your source system. Please check if your input is correct.</Span>
              <Span>
                For any questions please contact OFMP support team at <a href="mailto: support@ofmp.com">support@ofmp.com</a>
              </Span>
            </>
          )
          : (
            <>
              <Span>
                Connection can not be established due to technical reasons. Plase try again later.
              </Span>
              <Span>
                If issue repeats, please contact OFMP support team at <a href="mailto: support@ofmp.com">support@ofmp.com</a>
              </Span>
            </>
          )
      }
    </FlexboxContainer >
  );
};

export default ErrorModal;
