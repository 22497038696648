// import './react-hot-loader-config';
// polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import elementClosestPolyfill from 'element-closest';

import 'index.css';
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import React from 'react';
import ReactDOM from 'react-dom';
import { setBasepath } from 'hookrouter';
import { StyleSheetManager } from 'styled-components';

const RootComponent = () => (
  <StyleSheetManager>
    <App />
  </StyleSheetManager>
);

if (process.env.PUBLIC_URL && process.env.PUBLIC_URL.length > 0) {
  setBasepath(process.env.PUBLIC_URL);
}

elementClosestPolyfill(window);
ReactDOM.render(<RootComponent />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
