import { del, get, post, put, } from 'lib/API';
import Warehouse from 'data/Warehouse';

export const loadWarehouses = async ([vendorId]: any[], { token }: any): Promise<Warehouse[]> => {
  return await get(`v1/warehouses${vendorId ? '?vendorId=' + vendorId : ''}`, token);
};

export const createWarehouse = async ([newWarehouse, vendorId]: any[], { token }: any): Promise<any> => {
  return await post('v1/warehouses', token, {
    warehouseId: newWarehouse.warehouseId,
    name: newWarehouse.name,
    nickname: newWarehouse.nickname,
    street: newWarehouse.street,
    city: newWarehouse.city,
    state: newWarehouse.state,
    zip: newWarehouse.zip,
    fulfilmentEmails: newWarehouse.fulfilmentEmails,
    vendorId,
    deliveryOption: newWarehouse.deliveryOption,
    countryId: newWarehouse.countryId,
    sapPlantId: newWarehouse.sapPlantId,
    salesOrg: newWarehouse.salesOrg,
    subSegment: newWarehouse.subSegment,
    project: newWarehouse.project,
    pointOfServices: newWarehouse.pointsOfService
  });
};

export const saveWarehouse = async ([warehouseToUpdate]: any[], { token }: any): Promise<any> => {
  return await put(`v1/warehouses/${warehouseToUpdate.id}`, token, {
    warehouseId: warehouseToUpdate.warehouseId,
    name: warehouseToUpdate.name,
    nickname: warehouseToUpdate.nickname,
    street: warehouseToUpdate.street,
    city: warehouseToUpdate.city,
    state: warehouseToUpdate.state,
    zip: warehouseToUpdate.zip,
    fulfilmentEmails: warehouseToUpdate.fulfilmentEmails,
    deliveryOption: warehouseToUpdate.deliveryOption,
    countryId: warehouseToUpdate.countryId,
    addressId: warehouseToUpdate.addressId,
    sapPlantId: warehouseToUpdate.sapPlantId,
    salesOrg: warehouseToUpdate.salesOrg,
    subSegment: warehouseToUpdate.subSegment,
    project: warehouseToUpdate.project,
    pointOfServices: warehouseToUpdate.pointsOfService
  });
};

export const deleteWarehouse = async ([warehouseId]: any[], { token }: any): Promise<any> => {
  return await del(`v1/warehouses/${warehouseId}`, token);
};