import Button from 'components/UI/Button';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { MainColors } from 'types/enumTypes';
import Spinner from 'components/UI/Spinner';
import Icon from 'view/Icon';
import { renderToast } from '../components/UI/ToastNotification';
import { toast } from 'react-toastify';

const Input = styled.input`
  display: none;
`;

const FileUploadIcon = styled(Icon).attrs({ size: 18 }) <any>`
  path {
      fill: #498dda;
  }
`;

const UPLOAD_ERROR_MESSAGE = 'Error occurred while uploading file';
const FILE_SIZE_LIMIT_MB = 10;
const FILE_SIZE_LIMIT_BYTES = FILE_SIZE_LIMIT_MB * 1024 * 1024;

export interface Props {
  acceptTypes: string[];
  className?: string;
  disabled: boolean;
  label: string;
  onChange?: (file: File) => void;
  isGhost?: boolean;
  theme?: MainColors;
  icon?: boolean;
  spinner?: boolean;
  fileUploaded?: boolean;
}

export default function FileInput({
  acceptTypes,
  className,
  disabled,
  label,
  onChange,
  isGhost,
  icon,
  spinner,
  theme,
  fileUploaded
}: Props) {
  const inputEl = useRef<HTMLInputElement | null>(null);
  const accept = acceptTypes.join(', ');

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (onChange && event.target.files && event.target.files.length === 1) {
      const selectedFile = event.target.files[0];
      if (selectedFile.size <= FILE_SIZE_LIMIT_BYTES) {
        onChange(selectedFile);
      } else {
        renderToast(toast.TYPE.ERROR, `File size exceeds the limit of ${FILE_SIZE_LIMIT_MB}MB`);
      }
    } else {
      renderToast(toast.TYPE.ERROR, UPLOAD_ERROR_MESSAGE);
    }
  }

  function onButtonClick() {
    if (inputEl.current) {
      inputEl.current.value = "";
      inputEl.current.click();
    }
  }

  function onInputClick(event: React.MouseEvent<HTMLInputElement>) {
    event.stopPropagation();
  }

  const icons = spinner
    ? <Spinner size={16} />
    : icon
      ? <FileUploadIcon name='FileUploadV2' size={18} />
      : fileUploaded
        ? <Icon name='Check' size={18} />
        : null;

  return (
    <Button className={className} disabled={disabled} onClick={onButtonClick} isGhost={isGhost} theme={theme}>
      {icons}
      {label}
      <Input
        accept={accept}
        disabled={disabled}
        onChange={handleChange}
        onClick={onInputClick}
        ref={inputEl}
        type="file"
      />
    </Button>
  );
}

FileInput.defaultProps = {
  disabled: false
};
