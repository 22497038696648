import createReducer from 'redux/reducers/createReducer';
import initialState from 'redux/reducers/initialState';
import registerApiHandlers from 'redux/reducers/registerApiHandlers';
import { updatePageFilter, toggleOpenSplitOrders } from 'redux/reducers/orders/pageConfig';
import { UPDATE_PAGE_FILTER, TOGGLE_OPEN_SPLIT_ORDERS } from 'redux/constants/actionTypes';

const handlers = {
  ...registerApiHandlers('orders'),
  [UPDATE_PAGE_FILTER]: updatePageFilter,
  [TOGGLE_OPEN_SPLIT_ORDERS]: toggleOpenSplitOrders
};

export default createReducer(initialState.orders, handlers);
