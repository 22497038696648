import FeatureFlag, { FeatureFlagRoleType, FeatureFlagValues } from 'data/FeatureFlag';
import  User , * as UserFunction from 'data/User';

export const grantAccess = (
    featureFlags: FeatureFlag, 
    key: FeatureFlagValues,
    user: User
) => {
  const requestedFlag = featureFlags[key];
  const isVendor = UserFunction.isVendor(user!);
  const isVendorAdmin = UserFunction.isVendorAdmin(user!);

  if (!requestedFlag) return false;
  
  const userRole =  isVendorAdmin ? ['VENDOR_ADMIN', 'VENDOR'] : isVendor ? ['VENDOR'] : ['ADMIN', 'VENDOR_ADMIN', 'VENDOR'];

  const grantAccess = requestedFlag.enabled && 
  (
    requestedFlag.role === FeatureFlagRoleType.ALL ||
    userRole.includes(requestedFlag.role)
  )
  
  return grantAccess;
};
