import * as React from 'react';
import Banner from 'components/UI/Banner';
import Button from 'components/UI/Button';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { TicketStatus } from 'data/Ticket';
import { TEXT_LABELS } from 'constants/order';
import { formatDateTime } from 'utils/formatters';
import styled from 'styled-components';
import { Background, Main, Text } from 'styles/themeColors';
import { useAPI } from 'lib/API';
import { getDocumentCategories, getDocumentsForTicket, uploadDocumentsForTicket } from 'requests/documents';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import TicketDocument from './TicketDocument';
import { updateRentalState } from 'requests/consignment';
import RentalTicketDisputeModal from './Modals/RentalTicketDisputeModal';
import * as Cons from 'data/Consignment';
import { v4 as uuidv4 } from 'uuid';
import { RESOLVED, TICKET_IN_PROGRESS } from 'constants/consignmentStates';
import { getMappedTicketDisplayState, getTicketPillTheme, mapTicketDocument } from 'utils/ticket';
import DocumentCategory from 'data/DocumentCategory';
import TicketProgress from 'components/Ticket/TicketProgress';
import Pill from 'components/UI/Pill';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { VALID_TICKET_DOCUMENT_TYPES } from 'constants/documentOptions';
import { Space } from 'styles/themeSpaces';
import { SHIPPING } from 'constants/deliveryModes';

const TicketReason = styled.p`
    margin-top: 0px;
`;

const BoldText = styled.strong``;

const AttachmentsLabel = styled.h5`
    margin-top: 8px;
    margin-bottom: 8px;
`;

const PreviousValue = styled.span`
    text-decoration: line-through;
    color: ${Text.secondary}; // Lighter color for previous value
    font-size: ${Space.sp2x};

`;

const CurrentValue = styled.span`
    font-weight: bold;
    color: ${Text.primary}; // Normal color for current value
    font-size: ${Space.sp2x};
    margin-left: 5px;
`;


const Value = styled.span`
    font-weight: bold;
    min-width: 188px;
    font-size: ${Space.sp2x};
    color: ${Text.primary};
`;

const Label = styled.p``;

const FILE_SIZE_LIMIT_MB = 10;
const FILE_SIZE_LIMIT_BYTES = FILE_SIZE_LIMIT_MB * 1024 * 1024;

interface OrderDisputeProps {
    consignmentData?: Cons.ConsignmentDetails;
    refreshConsignment?: () => void;
}

const ActionButton = React.memo(({ handleResolve, isDisabled }: { handleResolve: () => void, isDisabled: boolean }) => (
    <FlexboxContainer flexDirection='row' justifyContent='flex-end' margin='16px 0 0 0'>
        <Button onClick={handleResolve} disabled={isDisabled}>
            {TEXT_LABELS.RESOLVE_TICKET}
        </Button>
    </FlexboxContainer>
));

const DateRange: React.FC<{ label: string, startDate?: string, endDate?: string }> = ({ label, startDate, endDate }) => (
    <FlexboxContainer flexDirection='row' gap='5px'>
        {label}
        <BoldText>{`${formatDateTime(startDate)} - ${formatDateTime(endDate)}`}</BoldText>
    </FlexboxContainer>
);

const TicketDispute: React.FC<OrderDisputeProps> = ({ consignmentData, refreshConsignment }) => {
    let ticketDetails = consignmentData?.tickets?.find(ticket => ticket.status !== TicketStatus.CLOSED);
    const isTicketResolved = consignmentData?.tickets?.find(ticket => ticket.status === TicketStatus.RESOLVED);

    const { rentalStartDate, rentalEndDate, modifiedRentalStartDate, modifiedRentalEndDate, originalDeliveryDate, newDeliveryDate } = ticketDetails || {};
    const description = ticketDetails?.entries[0]?.description;
    
    const [documents, setDocuments] = React.useState<any>({});

    const [showModal, setShowModal] = React.useState(false);
    const [reason, setReason] = React.useState('');
    const [newDocuments, setNewDocuments] = React.useState<any[]>([]);
    const [categories, setCategories] = React.useState<DocumentCategory[]>([]);
    const [newRentalStartDate, setNewRentalStartDate] = React.useState<string | null>(null);
    const [newRentalEndDate, setNewRentalEndDate] = React.useState<string | null>(null);
    const [newEstDate, setNewEstDate] = React.useState<string | null>(null);
    const [loader, setLoader] = React.useState(false);
    const [isDisabled, setIsDisabled] = React.useState(false);

    const getDocumentsReq = useAPI({
        deferFn: getDocumentsForTicket,
        onResolve: result => {
            setDocuments(result);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    const getDocumentCategoriesReq = useAPI({
        deferFn: getDocumentCategories,
        onResolve: result => {
            setCategories(result);
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    const uploadTicketDocumentsReq = useAPI({
        deferFn: uploadDocumentsForTicket,
        onResolve: () => {
            // Documents uploaded successfully
        },
        onReject: err => {
            renderToast(toast.TYPE.ERROR, err.message);
            setIsDisabled(false);
        }
    });

    const updateStateReq = useAPI({
        deferFn: updateRentalState,
        onResolve: () => {
            renderToast(toast.TYPE.SUCCESS, TEXT_LABELS.ORDER_UPDATED_SUCCESSFULLY);
            refreshConsignment?.();
        },
        onReject: err => {
            setLoader(false);
            renderToast(toast.TYPE.ERROR, err.message);
        }
    });

    const handleSubmit = async () => {
        setLoader(true);
        setShowModal(false);

        newDocuments.forEach(async (item: any) => {
            const fileItem: File = new File([item.file], item.displayName, { type: 'application/octet-stream' });
            await uploadDocument(fileItem, item);
        });

        updateStateReq.run(consignmentData?.consignmentId, TICKET_IN_PROGRESS, RESOLVED, newRentalStartDate, newRentalEndDate, reason, false, false, null, newEstDate);
    };

    const uploadDocument = async (fileItem: File, item: any) => {
        const mappedPayload = mapTicketDocument(fileItem, item, categories);
        uploadTicketDocumentsReq.run(ticketDetails?.code, mappedPayload);
    }

    React.useEffect(() => {
        if (ticketDetails) {
            getDocumentsReq.run(ticketDetails?.code);

        }
    }, []);

    React.useEffect(() => {
        if(!reason){
            setIsDisabled(true);
            return;
        }
        
        if(newRentalStartDate && newRentalEndDate){
            const startDate = new Date(newRentalStartDate);
            const endDate = new Date(newRentalEndDate);
            const estDate = newEstDate ? new Date(newEstDate) : null;
        
            if (startDate <= endDate) {
                if(estDate){
                    if(estDate <= startDate){
                        setIsDisabled(false);
                    }else{
                        setIsDisabled(true);
                    }
                }else{
                    setIsDisabled(false);
                }
            } else {
              setIsDisabled(true);
            }
        }else{
            if(newRentalStartDate || newRentalEndDate){
                setIsDisabled(true);
            }else{
                setIsDisabled(false);
            }
        }
      }, [newRentalStartDate, newRentalEndDate, consignmentData, showModal, newEstDate, reason]);

    React.useEffect(() => {
        if (ticketDetails) {
            getDocumentsReq.run(ticketDetails?.code);
            getDocumentCategoriesReq.run(null);
            setNewRentalStartDate(ticketDetails?.modifiedRentalStartDate || null);
            setNewRentalEndDate(ticketDetails?.modifiedRentalEndDate || null);
            setNewEstDate(ticketDetails?.newDeliveryDate || null);
        }
    }, [ticketDetails]);

    const handleRefresh = () => {
        setNewRentalStartDate(ticketDetails?.modifiedRentalStartDate || null);
        setNewRentalEndDate(ticketDetails?.modifiedRentalEndDate || null);
        setNewEstDate(ticketDetails?.newDeliveryDate || null);
        setReason('');
        setShowModal(false);
        setIsDisabled(false);
        setNewDocuments([]);
    }

    const uploadMedia = async (files: File | File[]) => {
        const filesArray = files instanceof File ? [files] : files;

        for (let file of filesArray) {
            // Check file type
            if (!VALID_TICKET_DOCUMENT_TYPES.includes(file.type)) {
                renderToast(toast.TYPE.ERROR, `Error in ${file.name}. ${TEXT_LABELS.INVALID_FILE_TYPE}`);
                continue;
            }

            // Check file size
            if (file.size > FILE_SIZE_LIMIT_BYTES) {
                renderToast(toast.TYPE.ERROR, `Error in ${file.name}. File size exceeds the limit of ${FILE_SIZE_LIMIT_MB}MB`);
                continue;
            }

            const id = uuidv4();
            setNewDocuments((prevDocuments) => [...prevDocuments, { file, id, displayName: file.name }]);
        }
    };

    const onDeleteMedia = async (id: string) => {
        setNewDocuments(newDocuments.filter((x: any) => x.id !== id));
    }

    if (!ticketDetails) return null;

    const flattenedDocuments = documents && documents.vendorFiles && documents.vendorFiles.reduce((acc: any, vendorFile: any) => {
        return acc.concat(vendorFile.documents);
    }, []);

    const { displayState } = getMappedTicketDisplayState(ticketDetails.status);

    const pillTheme = getTicketPillTheme(ticketDetails.status);

    const pill = <Pill margin="0 0 0 0" theme={pillTheme} bgColor={pillTheme} size="xsPlus" padding="4px">{displayState}</Pill>;

    const renderDateValue = (previous: any, current: any, formatter = (val: any, _: any) => val) => {
        return previous && current && previous !== current ? (
            <>
                {previous && <PreviousValue>{formatter(previous, false)}</PreviousValue>}
                {current && <CurrentValue>{formatter(current, false)}</CurrentValue>}
            </>
        ) : (
            previous && <Value>{formatter(previous, false)}</Value>
        );
    };

    return (
        <Banner
            boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
            bgColor={Background.white}
            titleColor={Main.black}
            text=''
        >
            <FlexboxContainer flexDirection='row' gap='10px'>
                <h5>{loader ? TEXT_LABELS.PLEASE_WAIT : isTicketResolved ? TEXT_LABELS.TICEKT_RESOLUTION_BY_VENDOR : TEXT_LABELS.TICKET_OPENED_BY_CUSTOMER}</h5>
                {loader ? <LoadingIndicator size={20} margin='0px' /> : pill}
            </FlexboxContainer>
            {
                !loader && 
                <>
                    <p>{isTicketResolved ? TEXT_LABELS.VENDOR_RESOLUTION_SUBMITTED : TEXT_LABELS.CUSTOMER_OPENED_TICKET}</p>
                    <h6>{TEXT_LABELS.TICKET_REASON}</h6>
                    <TicketReason>{description}</TicketReason>

                    <DateRange label={(modifiedRentalStartDate && modifiedRentalEndDate) ? TEXT_LABELS.PREVIOUS_RENT_DATES : TEXT_LABELS.RENT_DATES} startDate={rentalStartDate} endDate={rentalEndDate} />

                    {modifiedRentalStartDate && modifiedRentalEndDate && (
                        <DateRange label={TEXT_LABELS.NEW_RENTAL_DATES_LABEL} startDate={modifiedRentalStartDate} endDate={modifiedRentalEndDate} />
                    )}

                    <FlexboxContainer flexDirection='row' alignItems='center' gap='5px' height='25px'>
                        <Label>{consignmentData?.deliveryType === SHIPPING ? 'Est. delivery:' : 'Est. pickup:'}</Label>
                        <Value>
                            {renderDateValue(originalDeliveryDate, newDeliveryDate, formatDateTime)}
                        </Value>
                    </FlexboxContainer>
                    

                    {/* Render on-going ticket history */}
                    <TicketProgress
                        ticketEntries={ticketDetails.entries?.slice(1)}
                        showRecent={false}
                        expandText="Full ticket history"
                        margin='15px 0px 5px 0px'
                        consignmentId={consignmentData?.consignmentId!}
                        itemKey={0}
                        deliveryType={consignmentData?.deliveryType}
                    />

                    {/** Render ticket attachments */}
                    {flattenedDocuments?.length > 0 && <AttachmentsLabel>{TEXT_LABELS.ATTACHMENTS}</AttachmentsLabel>}
                    {flattenedDocuments?.map((document: any, index: number) => (
                        <FlexboxContainer key={document?.id} flexDirection='column'>
                            <TicketDocument ticketDocument={document} />
                        </FlexboxContainer>
                    ))}
                    {
                        <ActionButton handleResolve={() => {setShowModal(true)}} isDisabled={!!isTicketResolved} />
                    }
                </>
            }
            <RentalTicketDisputeModal
                rentalEndDate={newRentalEndDate!}
                rentalStartDate={newRentalStartDate!}
                reason={reason}
                setReason={setReason}
                setRentalEndDate={setNewRentalEndDate}
                setRentalStartDate={setNewRentalStartDate}
                show={showModal}
                onClose={handleRefresh}
                onConfirm={handleSubmit}
                title={TEXT_LABELS.RESOLVE_TICKET}
                disabled={isDisabled}
                uploadMedia={uploadMedia}
                documents={newDocuments}
                consignmentData={consignmentData!}
                onDeleteMedia={onDeleteMedia}
                newEstDate={newEstDate!}
                setNewEstDate={setNewEstDate}
            />
        </Banner>
    );
}

export default React.memo(TicketDispute);