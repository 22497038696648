import Button from 'components/UI/Button';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Modal from 'components/UI/Modal';
import { SalesOrder } from 'data/SalesOrder';
import React from 'react';
import styled from 'styled-components';
import { Main } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';

const Container = styled.div`
  height: 60vh;
  overflow: scroll;
  background-color: #f0f1f4;
  border-radius: 6px;
`;

const Prettify = styled.pre`
  margin: 0;
  font-size: 16px;
  white-space: pre-line;
  padding: 0 16px 16px 16px;
`;

interface Props {
  salesOrderDetails: SalesOrder | null;
  onClose: () => void;
  show: boolean;
}
const SalesOrderDetailsModal = ({
  salesOrderDetails,
  onClose,
  show,
}: Props) => {
  return (
    <Modal
      width="540px"
      top="10%"
      showModal={show}
      onClose={onClose}
      title="Data received"
      showButtons={false}
      modalFooterMargin="0"
    >
      <FlexboxContainerComponent flexDirection="column">
        <Container>
          <Prettify>
            {JSON.stringify(salesOrderDetails, null, 2)
              .replace(/{|},|}/g, '')
              .replace(/\[|\]|"/g, '')
              .replace(/,/g, ',')}
          </Prettify>
        </Container>
        <FlexboxContainerComponent
          className="modal-footer"
          justifyContent="flex-end"
        >
          <Button
            id="modal-ghost-button"
            color="#fff"
            bgColor='#18222B'
            onClick={() => {
              onClose();
            }}
            margin={`0 ${Space.spHalf} 0 0`}
          >
            Close
          </Button>
        </FlexboxContainerComponent>
      </FlexboxContainerComponent>
    </Modal>
  );
};

export default SalesOrderDetailsModal;
