import readExcelFile from 'read-excel-file';
import Product, * as Prod from 'data/Product';
import SuperCategory from 'data/SuperCategory';
import Variant, * as Var from 'data/Variant';
import { replace } from 'lib/Array';
import { pick, omit } from 'lib/Object';
import { createVariant } from 'utils/variant';
import ProductVariantPrice from 'data/ProductVariantPrice';
import ProductStock from 'data/ProductsStock';
import { useCurrencies } from 'hooks/useCurrencies';
import Currency from 'data/Currency';
import { productVariantMessages } from 'constants/errorMessages';
import { PRODUCT_VARIANT_COLUMNS, PRODUCT_VARIANT_COLUMNS_WITH_MATERIAL_NUMBER } from 'constants/productColumns';
import { validateHiddenValue, validatePriceValue } from './validateProductVariant';
import { useCountries } from 'hooks/useCountries';
import Country from 'data/Country';
import { Role } from 'data/User';
import { isMaterialApiEnabled } from 'utils/materialNumber';

const ATTRIBUTE_REGEX = /\[([^\]]+)\]/;

type VariantRow = Variant;

interface VariantRowWithAttributes {
  variantRow: VariantRow,
  attributeNames: Array<{ name: string; isSelectable: boolean }>
};

interface GenericHeader {
  name: string;
  index: number;
}

interface FixedAttributes {
  sku: string;
  vendorPartNumber: string;
  itemType: string;
  hidden: boolean;
  weight: number;
  [key: string]: string | boolean | number;
}

const spreadsheetImport = async (
  file: File,
  setImportAttributes: (attributeNames: Array<{ name: string; isSelectable: boolean }>) => void,
  product: Product,
  currencies: Array<Currency>,
  countries: Array<Country>,
  role: Role | undefined,
): Promise<Product> => {


  function createKeyValuePairs(keys:any, values:any) {
    return keys.reduce((result:any, key:any, index:any) => {
      result[key] = values[index];
      return result;
    }, {});
  }
  let keyValuePairs = [];
  const productsData = await readExcelFile(file);
  for (let i = 1; i < productsData.length; i++) {
    keyValuePairs.push(createKeyValuePairs(productsData[0], productsData[i]));
  }
  let modifiedArray = keyValuePairs.map(obj => {
    Object.keys(obj).forEach((key) => {
      if (key.startsWith('Selectable attribute')) {
        obj[key] = {
            dataValue: obj[key],
            isSelectable: true 
        };
      }else if(key.startsWith('Technical specification')){
        obj[key] = {
          dataValue: obj[key],
          isSelectable: false 
        };
      }
    });
    return obj;
  });
  let modifiedProductData =  modifiedArray.map(obj => Object.values(obj));
  modifiedProductData.unshift(productsData[0]);
  const variants = createAllFromSpreadsheet(modifiedProductData, setImportAttributes, product, currencies, countries, 
    isMaterialApiEnabled(product?.brand?.brandVendor!, product?.vendor!)!);
  return appendVariant(product, variants);


};

const appendVariant = (product: Product, variants: Variant[]): Product => {
  return {
    ...product,
    variants
  };
};

export const createAllFromSpreadsheet = (
  data: any[][],
  setImportAttributes: (attributeNames: Array<{ name: string; isSelectable: boolean }>) => void,
  product: Product,
  currencies: Array<Currency>,
  countries: Array<Country>,
  isMaterialNumberEnabled: boolean
): Variant[] => {
  const headers = data[0];

  const variants:Variant[] = data
    .slice(1)
    .map((line, index) => {
      const { variantRow, attributeNames } = variantFromJSON(line, headers, product.warehouses, currencies, isMaterialNumberEnabled);
    
      if(index === 0 && setImportAttributes){
        setImportAttributes(attributeNames);
      }

      variantRow.sku = variantRow.sku.replace(/ /g, '');
      const variant: Variant = createVariant(
        pick(variantRow, Object.keys(Var.empty) as Array<keyof Variant>)
      );

      variant.productVariantPrices = variantRow.productVariantPrices;
      variant.productStocks = variantRow.productStocks?.map(item => {
        item.availableForPickup = product.availableForPickup;
        item.availableForShipping = product.shipping;
        return item;
      });
      return variant;
    });

  return variants;
};

const variantFromJSON = (
  line: Array<any>,
  headers: string[],
  warehouses: Array<Prod.ProductWarehouse>,
  currencies: Array<Currency>,
  isMaterialNumberEnabled: boolean,
): VariantRowWithAttributes => {

  const WAREHOUSE_TAG = 'warehouse';
  const PRICE_TAG = 'price';
  const priceHeaders: Array<GenericHeader> = [];
  let warehouseHeaders: Array<GenericHeader> = [];

  let fixedHeaders = [
    { name: 'SKU', index: headers.indexOf('SKU') },
    { name: 'Vendor Part Number', index: headers.indexOf('Vendor Part Number') },
    { name: 'Item Type', index: headers.indexOf('Item Type') },
    { name: 'Hidden', index: headers.indexOf('Hidden') },
    { name: 'Weight', index: headers.indexOf('Weight') },
  ];

  if(isMaterialNumberEnabled){
    fixedHeaders.push({ name: 'Material Number', index: headers.indexOf('Material Number') });
  }


  /* Validate Static Headers */
  let headersLengthToCheck = isMaterialNumberEnabled ? PRODUCT_VARIANT_COLUMNS_WITH_MATERIAL_NUMBER.length : PRODUCT_VARIANT_COLUMNS.length;
  if(fixedHeaders.length !== headersLengthToCheck){
    throw new Error(productVariantMessages.FIXED_HEADER_INVALID);
  }

  fixedHeaders.forEach(item => {
    if (item.index === -1) {
      throw new Error(productVariantMessages.FIXED_HEADER_INVALID);
    }
  });


  const validatHiddenValue = validateHiddenValue(line[headers.indexOf('Hidden')]);
  if (!validatHiddenValue) throw new Error(productVariantMessages.HIDDEN_ATTRIBUTE_INVALID);

  let fixedAttributes: FixedAttributes = {
    sku: line[headers.indexOf('SKU')].toString() || Var.empty.sku,
    vendorPartNumber: line[headers.indexOf('Vendor Part Number')]
      ? line[headers.indexOf('Vendor Part Number')].toString()
      : Var.empty.vendorPartNumber,
    itemType: line[headers.indexOf('Item Type')]
      ? line[headers.indexOf('Item Type')].toString()
      : Var.empty.itemType,
    hidden: line[headers.indexOf('Hidden')] ? line[headers.indexOf('Hidden')].toString().toLowerCase() === 'yes' ? true : false : false,
    weight: line[headers.indexOf('Weight')] || Var.empty.weight
  };

  if(isMaterialNumberEnabled){
    fixedAttributes["materialNumber"] = line[headers.indexOf('Material Number')] || Var.empty.materialNumber;
  }

  headers.filter((priceHeader) =>
    priceHeader.includes(PRICE_TAG)
  ).forEach((element) => {
    const item = {
      name: element,
      index: headers.indexOf(element)
    }
    priceHeaders.push(item);
  });

  const warehousAlleHeaders = headers.filter(headerItem => headerItem.includes(WAREHOUSE_TAG));
  const aggregatedWarehousesHeaders = warehousAlleHeaders.reduce((result: any, item) => {

    const warehouse = warehouses.find(warehouse =>
      item.toLowerCase().includes(warehouse.code.toLowerCase())
    );

    if (warehouse) {
      const warehouseCode = warehouse.code;
      const exitingWarehouseKeys = result.find((key: any) => key.code === warehouseCode);

      if (exitingWarehouseKeys) {
        exitingWarehouseKeys.items.push(item);
      } else {
        result.push({ ...warehouse, items: [item] });
      }
    }
    else {
      /* Validate if Excel warehouses are not equal to product warehouses. */
      throw new Error(productVariantMessages.WAREHOUSES_INVALID);
    }

    return result;
  }, []);

  for (const priceHeader of priceHeaders) {

    const isValidCurrency = aggregatedWarehousesHeaders.some((item: any) => priceHeader.name.includes(item.address.countryIsoCode));
    /* Validate Currency */
    if (!isValidCurrency) {
      throw new Error(productVariantMessages.CURRENCY_INVALID);
    }
  }

  /* Validate Currency Headers */
  for (const WH of aggregatedWarehousesHeaders) {

    const tempHeaders = WH.items.map((h: any) => {
      return {
        name: h,
        index: headers.indexOf(h)
      };
    })

    warehouseHeaders = [...warehouseHeaders, ...tempHeaders];
  }

  const dynamicHeaders: Array<GenericHeader> = [];
  headers.filter(header =>
    !fixedHeaders.some(fixedHeader => fixedHeader.name === header) &&
    !priceHeaders.some(priceHeader => priceHeader.name === header) &&
    !warehouseHeaders.some(warehouseHeader => warehouseHeader.name === header)
  ).forEach((element) => {
    const item = {
      name: element,
      index: headers.indexOf(element)
    }
    dynamicHeaders.push(item);
  });


  const dynamicAttributes = dynamicHeaders.map((dh) => {

    let attributeMatch = dh.name.match(ATTRIBUTE_REGEX);

    if (attributeMatch && attributeMatch[1]) {
      if (dh.name.startsWith('Selectable attribute')) {
        return {name: attributeMatch[1], isSelectable: true}
      } else {
        return {name: attributeMatch[1], isSelectable: false}
      }
    }
    else {
      throw new Error(productVariantMessages.GENERIC_INVALID);
    }


  });

  const attributeValues = line
  .filter((value, index) =>
    !fixedHeaders.map(fixedHeader => fixedHeader.index).includes(index) &&
    !priceHeaders.map(priceHeader => priceHeader.index).includes(index) &&
    !warehouseHeaders.map(warehouseHeader => warehouseHeader.index).includes(index)
  )
  .reduce((values: Var.VariantAttributeValue[], value: any) => {
    if(typeof value === 'object'){
      values.push({ id: 0, attributeId: 0, code: '', name: value ? value.dataValue.toString() : '', displayOrder: 0, isSelectable: value.isSelectable })
    }else{
      values.push({ id: 0, attributeId: 0, code: '', name: value ? value.toString() : '', displayOrder: 0, isSelectable: false })
    }
    return values;
  }, []);

  /* Product Stocks And Product Variant Prices for Warehouses */

  const productStocks: ProductStock[] = [];
  const productVariantPrices: Array<ProductVariantPrice> = [];

  for (const WH of aggregatedWarehousesHeaders) {

    const productStock: ProductStock = {
      available: line[headers.indexOf(WH.items[0])] ? line[headers.indexOf(WH.items[0])].toString().toLowerCase() === 'yes' ? true : false : false,
      deliveryLeadDays: line[headers.indexOf(WH.items[1])],
      postOrderShippingCost: line[headers.indexOf(WH.items[2])] ? line[headers.indexOf(WH.items[2])].toString().toLowerCase() === 'yes' ? true : false : false,
      warehouseId: WH.id
    }
    productStocks.push(productStock);
  }

  currencies.forEach(currency => {
    const priceHead = priceHeaders.filter((price) => price.name.includes(currency.currencyIso!));

    if (priceHead.length > 0) {

      const isValidPriceValue = validatePriceValue(line[priceHead[0].index]);
      if (!isValidPriceValue) throw new Error(productVariantMessages.NUMBER_ATTRIBUTE_INVALID);

      const productVariantPrice: ProductVariantPrice = {
        countryId: currency.countryId,
        currencyId: currency.id,
        itemsPerUnit: 1,
        pricePerUnit: line[priceHead[0].index]
      };

      productVariantPrices.push(productVariantPrice);
    }

  });
  return { variantRow: { ...Var.empty, ...fixedAttributes, attributeValues, productStocks, productVariantPrices }, attributeNames: dynamicAttributes };
};

const getByKey = <T, U extends keyof T>(
  collection: T[],
  key: U,
  value: T[U]
): T | null => {
  const result = collection.find(item => item[key] === value);
  if (result === undefined) {
    return null;
  }

  return result;
};

const getCategoriesByName = (
  superCategoryName: string,
  categoryName: string,
  superCategories: SuperCategory[]
) => {
  const superCategory = getByKey(superCategories, 'name', superCategoryName);
  let category = null;
  let productSuperCategory = null;
  if (superCategory) {
    category = getByKey(superCategory.categories, 'name', categoryName);
    productSuperCategory = omit(superCategory, ['categories']);
  }

  return { superCategory: productSuperCategory, category };
}

export default spreadsheetImport;
