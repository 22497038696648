import { RENT_DISPUTE_CATEGORY } from "constants/documentApi"
import DocumentCategory from "data/DocumentCategory"
import { TicketStatus } from "data/Ticket";
import { Background } from "styles/themeColors";

const successStates = new Set([TicketStatus.CLOSED.toString()]);
const warningStates = new Set([TicketStatus.RESOLVED.toString(), TicketStatus.REJECTED.toString()]);
const navyStates = new Set([TicketStatus.OPENED.toString()]);

export const mapTicketDocument = (fileItem: File, item: any, categories: DocumentCategory[]) => {
    return {
        imageFile: [fileItem],
        fileCategories: categories.filter((x: DocumentCategory) => x.name === RENT_DISPUTE_CATEGORY)
            .map((x: DocumentCategory) => ({
                ...x,
                fileName: item.displayName,
                fileCategory: x.id
        })),
        creationDate: new Date().toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }),
        createdBy: ''
    }
}

/**
 * Returns the display state lable of the consignment
 * @param ticketState Consignment State 
 * @returns 
 */
export const getMappedTicketDisplayState = (ticketState: string) => {
    const stateMappings: Record<string, string> = {
        OPENED: 'Ticket Opened',
        CLOSED: 'Ticket Closed',
        RESOLVED: 'Proposal from Vendor',
        REJECTED: 'Modified by customer'
    };
  
    return { displayState: stateMappings[ticketState] || '' };
};


export const getTicketPillTheme = (ticketState: string) => 
    successStates.has(ticketState)
    ? 'success'
        : warningStates.has(ticketState)
            ? 'warning'
                : navyStates.has(ticketState)
                    ? Background.navy
                        : 'danger';
