import React from 'react';
import styled, { css } from 'styled-components';
import ValidationStatus from 'view/ValidationStatus';
import InfoIcon from 'components/UI/InfoIcon';
import { Size, FontSize, FontWeight } from 'lib/styles';
import { Main } from 'styles/themeColors';

const Actions = styled.div`
  display: flex;
  margin-left: auto;
`;

const Container = styled.section<any>`
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  padding: ${Size.small} 0 ${Size.large} 0;
  position: relative;
  width: 100%;

  ${({ disablePaddingBottom }) => disablePaddingBottom && css`
    padding-bottom: ${Size.xs} !important;
  `}

  ${({ noBorder }) => noBorder && css`
    border: none;
  `}

  &:last-child {
    border-bottom: none;
  }
`;

const Header = styled.header<any>`
  align-items: center;
  display: flex;
  margin: ${({ productRedesignFlag }) => (productRedesignFlag ? `0 0 ${Size.medium}` : '0px')};
`;

const Heading = styled.h1`
  font-size: ${FontSize.large};
  font-weight: ${FontWeight.medium};
`;

const StyledInfoIcon = styled(InfoIcon)`
  display: flex;
  margin-left: ${Size.small};
  margin-top: 0.1rem;
`;

const StyledValidationStatus = styled(ValidationStatus)`
  bottom: ${Size.tiny};
  position: absolute;
  right: 0;
`;
const Label = styled.h3`
  font-size: ${FontSize.medium};
  font-weight: ${FontWeight.medium};
`;
const SubLabel = styled.h3<{ productRedesignFlag: boolean }>`
  font-size: ${({ productRedesignFlag }) => (productRedesignFlag ? `${FontSize.semi}` : '17px')};
  font-weight: ${FontWeight.medium};
`;
const OptionalLabel = styled.span<{ productRedesignFlag: boolean }>`
  font-size: ${({ productRedesignFlag }) => (productRedesignFlag ? `${FontSize.semi}` : `${FontSize.medium}`)};
  color: grey;
  font-style: italic;
`;

interface Props {
  actions?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  countType?: string;
  countTypePlural?: string;
  disablePaddingBottom?: boolean;
  heading?: string;
  borderColor?: string;
  noBorder?: boolean;
  infoIconText?: React.ReactNode;
  validation?: {
    count: number;
    recommended: number;
    required: number;
  };
  label?: string;
  subLabel?: string; 
  isOptional?: boolean;
  shouldShowValidationStatus?: boolean;
  productRedesignFlag?: boolean;
}

const EditorSection = ({
  actions,
  children,
  className,
  countType = 'word',
  countTypePlural = countType + 's',
  disablePaddingBottom,
  heading,
  infoIconText,
  borderColor,
  noBorder,
  validation,
  label,
  subLabel, 
  isOptional = false,
  shouldShowValidationStatus = true,
  productRedesignFlag = false
  
}: Props) => {
  const validationStatus = validation && shouldShowValidationStatus
    ? (
      <StyledValidationStatus
        validation={validation}
        countType={countType}
        countTypePlural={countTypePlural}
      />
    )
    : null;

  const infoIcon = infoIconText ? <StyledInfoIcon productRedesignFlag={productRedesignFlag} tooltipText={infoIconText} /> : null;

  const header = heading ? (
    <Header>
      <Heading>{heading}</Heading>
      {infoIcon}
      {
        !productRedesignFlag && <Actions>{actions}</Actions>
      }
    </Header>
  ) : null;

  const labelText = label ? (<Label>{label} {isOptional && <OptionalLabel productRedesignFlag={productRedesignFlag}>(optional)</OptionalLabel>} </Label>) : null;
  
  const subLabelText = subLabel ? 
                (<SubLabel productRedesignFlag={productRedesignFlag}>{subLabel} {isOptional && 
                    <OptionalLabel productRedesignFlag={productRedesignFlag}>(optional)</OptionalLabel>}
                </SubLabel>) : null;

  return (
    <Container
      className={className}
      disablePaddingBottom={disablePaddingBottom}
      borderColor={productRedesignFlag ? Main.white : borderColor}
      noBorder={noBorder}
    >
      {header}
      {labelText}
      {subLabelText}
      <div>{children}</div>
      {
        productRedesignFlag && <Actions style={{marginTop: 12}}>{actions}</Actions>
      }
      {validationStatus}
    </Container>
  );
};

EditorSection.defaultProps = {
  disablePaddingBottom: false,
  borderColor: Main.accent,
  noBorder: false
};

export default EditorSection;
