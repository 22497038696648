import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Add, ArrowDownward, ArrowUpward } from '@material-ui/icons';
import Icon from 'view/Icon';
import { Text, Border, Background, Shade } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { Height } from 'styles/themeHeights';
import { FontSize } from 'styles/themeSizes';
import { useAPI } from 'lib/API';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Page from 'components/UI/Page';
import Button from 'components/UI/Button';
import Pagination from 'components/UI/Pagination';
import NewPricebooksModal from 'components/Pricebooks/Modals/NewPricebooksModal';
import NewGeographyPricebooksModal from 'components/Pricebooks/Modals/NewGeographyPricebooksModal';
import Customer, { CustomerExtended } from 'data/Customer';
import PricebooksUploadModal from 'components/Pricebooks/Modals/PricebooksUploadModal';
import NewReferencePBModal from 'components/Pricebooks/Modals/NewReferencePBModal';
import PricebooksReplaceModal from 'components/Pricebooks/Modals/PricebooksReplaceModal';
import { loadVendors } from 'requests/vendor';
import { useAuth } from 'lib/Auth';
import {
  getPricebooksByCompanyId,
  getPricebooksByCompanyVendorId,
  getPricebooksByVendorId,
  getPricebooksByWarehouseVendorId,
  getPricebooksByReferenceVendorId,
} from 'requests/pricebook';
import Vendor from 'data/Vendor';
import { PricebookTemplateUrl } from 'data/Pricebook';
import { extendedPaginationSelectOptions } from 'constants/paginationOptions';
import companyPricebookColumns from 'constants/companyPricebookColumnsNew';
import {
  calculatePages,
  handleOnSearchKeyChange,
  applySearchFilter, applySortFilter
} from '../../utils/grid';
import LoadingIndicator from '../../components/UI/LoadingIndicator';
import {
  emptyDataSourceExtraMessage,
  emptyDataSourceMainMessage,
} from '../../constants/errorMessages';
import SearchBar from 'components/UI/Search';
import PageHeading from 'components/UI/PageHeading';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Public, PinDrop, ArrowDropDownRounded } from '@material-ui/icons';
import { ReactComponent as PricebookModalIcon } from 'images/pricebook-modal-icon.svg';
import Country from 'data/Country';
import { getCountries } from 'requests/country';
import { loadWarehouses } from 'requests/warehouse';
import Warehouse from 'data/Warehouse';
import { PricebookType } from 'data/Pricebook';
import { useNewPricebookAssignmentState } from '../../components/Pricebooks/pricebook.hooks';
import { calculatePagesForHierarchy, pricebookHierarchySort } from '../../components/Pricebooks/priceebook.utils';
import { checkUserPricebookDeleteNowOption } from 'utils/pricebooks';
import PricebookTable from 'components/Pricebooks/components/PricebookTable';
import { sortDirection } from 'constants/sort';

const defaultSort = {
  sortBy: sortDirection.DESC,
  sortColumn: companyPricebookColumns[0].title,
  sortKey: companyPricebookColumns[0].sortKey,
};

const StyledFileDownloadIcon = styled(Icon).attrs({
  name: 'FileDownload',
  size: 20,
})`
  color: ${Text.primary} !important;
  margin: 0px 8px 10px 0px;
`;

const DownloadTemplateButton = styled.a`
  display: flex;
  height: 36px;
  background-color: ${Shade.shade120} !important;
  color: ${Text.primary} !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
`;

const DownloadTemplateDropDown = styled.div`
  display: flex;
  height: 36px;
  background-color: ${Shade.shade120} !important;
  color: ${Text.primary} !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
`;

const HeaderTitle = styled.div`
  padding: ${Space.spHalf} ${Space.spBase} ${Space.spHalf} ${Space.sp2x};
  text-align: left;
  background-color: ${Background.shaded};
  color: ${Text.primary};
  cursor: pointer;
  font-weight: bold;
  font-size: ${FontSize.medium};
  line-height: 20px;
  border-right: 1px solid ${Border.light};
  min-height: ${Height.l};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:first-child {
    border-left: 1px solid ${Border.light};
  }
`;

const StyledMessage = styled.p`
  width: 100%;
  text-align: center;
  color: ${Text.primary};
  font-size: ${FontSize.big};
  font-weight: 400;

  &:first-child {
    font-size: ${FontSize.large};
    font-weight: bold;
  }
`;

const HeaderActionBarWrapper = styled(FlexboxContainer)`
  border: 1px solid ${Border.light};
  border-bottom: none;
`;

interface Props {
  companyId?: string;
  selectedVendor?: Vendor;
  companyName?: string;
  companyCustomers: Customer[];
  isPricebookPage?: boolean;
}

export interface Option {
  value: string;
  id?: string;
  icon?: ReactNode;
  badge?: any;
}

const PricebooksTab = ({
  selectedVendor,
  companyId,
  companyName,
  companyCustomers,
  isPricebookPage,
}: Props) => {
  const { token, user } = useAuth();
  const [selectedCountryOption, setSelectedCountryOption] = useState<Option>();
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  /*  const [selectedCompanyUserOption, setSelectedCompanyUserOption] =
    useState<Option>();
  const [selectedAssignedByOption, setSelectedAssignedByOption] =
    useState<Option>();
  
  const [selectedCompanyUser, setSelectedCompanyUser] = useState<Customer>();
  const [selectedAssignedBy, setSelectedAssignedBy] = useState<Vendor>();
  
  const [assignedByList, setAssignedByList] = useState<Vendor[]>([]); */
  const [countryError, setCountryError] = useState<boolean>(false);
  const [newPBModalOpen, setNewPBModalOpen] = useState<boolean>(false);
  const [newReferencePBModalOpen, setNewReferencePBModalOpen] =
    useState<boolean>(false);
  const [uploadPBModalOpen, setUploadPBModalOpen] = useState<boolean>(false);
  const [replacePBModalOpen, setReplacePBModalOpen] = useState<boolean>(false);
  const [pricebookData, setPricebookData] = useState<any>();
  const [assignedByError, setAssignedByError] = useState<boolean>(false);
  const [warehouseError, setWarehouseError] = useState<boolean>(false);
  const [assignedToError, setAssignedToError] = useState<boolean>(false);
  const [pricebookPreview, setPricebookPreview] = useState<boolean>(false);
  const [pricebookPreviewData, setPricebookPreviewData] = useState<any>();
  const [pricebookPreviewId, setPricebookPreviewId] = useState<number>();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setLoadingState] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSort, setSelectedSort] = useState(defaultSort);
  const isAdmin = checkUserPricebookDeleteNowOption(user, selectedVendor);
  const [newPBDropDownAnchorEl, setNewPBDropDownAnchorEl] = useState(null);
  const [countries, setCountries] = useState<Country[]>([]);
  const [
    downloadTemplateDropDownAnchorEl,
    setDownloadTemplateDropDownAnchorEl,
  ] = useState(null);
  const [newPricebookGeographyModalOpen, setNewPricebookGeographyModalOpen] =
    useState<boolean>(false);
  const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);
  const [selectedWarehouseByOption, setSelectedWarehouseByOption] =
    useState<Option>();
  const [selectedWarehouse, setSelectedWarehouse] = useState<Warehouse>();
  const [pricebookType, setPricebookType] = useState<string>(
    PricebookType.COMPANY
  );
  const [selectedCompanyName, setSelectedCompanyName] = useState<
    string | undefined
  >(companyName);
  const [
    { selectedAssignedTo, assignedToList, onSelectAssignedTo },
    { selectedAssignedBy, assignedByList, onSelectAssignedBy },
    { selectedCompanyUser, companyUsers, onSelectCompanyUser },
  ] = useNewPricebookAssignmentState(
    companyCustomers as unknown as CustomerExtended[],
    selectedVendor
  );

  let origin:boolean = false;
  const selectedAssignedByOption = {
    id: selectedAssignedBy?.id.toString() || '',
    value: selectedAssignedBy?.name || '',
  };
  const selectedCompanyUserOption = selectedCompanyUser
    ? {
      id: selectedCompanyUser?.id.toString() || '',
      value: selectedCompanyUser?.email[0].emailAddress || '',
    }
    : undefined;

  const searchProperties = [
    'priceBookType',
    'company.companyName',
    'priceBookName',
    'priceBookVendorId',
    'customer.fullName',
  ];
  if (isAdmin) {
    searchProperties.push('vendor.name');
  }

  useEffect(() => {
    if(searchTerm?.length > 2){
      setPricebookData(applySearchFilter(
        pricebookData || [],
        searchProperties,
        searchTerm
      ));
    }else{
      origin = true;
      fetchPricebookData();
    }
  }, [searchTerm]);

  const pagesCount = selectedSort.sortKey === 'priceBookType' ?
    calculatePagesForHierarchy(pricebookData, itemsPerPage) :
    calculatePages(
    pricebookData?.length || 0,
    itemsPerPage
  );

  const clearSelects = () => {
    // setSelectedAssignedBy(undefined);
    setSelectedCountry(undefined);
    setSelectedCountryOption(undefined);
    setSelectedWarehouseByOption(undefined);
    onSelectAssignedTo(undefined);
    onSelectAssignedBy(undefined);
    onSelectCompanyUser(undefined);
  };

  useEffect(() => {
    setSelectedCompanyName(companyName);
  }, [companyName]);

  useEffect(() => {
    if (selectedWarehouseByOption?.value) {
      const warehouse = warehouseList.find(
        (ve: any) => ve.name === selectedWarehouseByOption.value
      );
      if (warehouse) {
        setSelectedWarehouse(warehouse);
        setWarehouseError(false);
      }
    }
  }, [selectedCompanyUserOption]);

  useEffect(() => {
    if (selectedAssignedBy?.name) {
      const foundVendor = assignedByList.find(
        (ve: any) => ve.name === selectedAssignedBy.name
      );
      if (foundVendor) {
        /* Updated here for Warehouses */
        warehouseReq.run([foundVendor.id]);
        // setSelectedAssignedBy(foundVendor);
        setSelectedWarehouseByOption({ value: '' });
        setAssignedByError(false);
      }
    }
  }, [selectedAssignedBy]);

  useEffect(() => {
    if (selectedCountryOption?.id) {
      const foundCountry = countries.find(
        (co: any) => co.id?.toString() === selectedCountryOption.id
      );
      if (foundCountry) {
        setSelectedCountry(foundCountry);
        setCountryError(false);
      }
    }
  }, [selectedCountryOption]);

  useEffect(() => {
    if (selectedWarehouseByOption?.value) {
      const warehouse = warehouseList.find(
        (ve: any) => ve.name === selectedWarehouseByOption.value
      );
      if (warehouse) {
        setSelectedWarehouse(warehouse);
        setWarehouseError(false);
      }
    }
  }, [selectedWarehouseByOption]);

  const vendorsReq = useAPI({ deferFn: loadVendors });
  const warehouseReq = useAPI({ deferFn: loadWarehouses });

  const pricebookByCompanyId = useAPI({
    deferFn: getPricebooksByCompanyId,
    onResolve: (result: any) => {
      if(origin){
        const sortByUpdated = selectedSort.sortBy === sortDirection.DESC ? sortDirection.DESC : sortDirection.ASC;
        onHandleSort(
          result,
          sortByUpdated,
          selectedSort.sortColumn,
          true,
          selectedSort.sortKey!,
        );
      }else{
        onHandleSort(
          result,
          selectedSort.sortBy,
          selectedSort.sortColumn,
          firstLoad,
          selectedSort.sortKey!,
        );
      }
      setLoadingState(false);
      setFirstLoad(false);
      origin = false;
    },
    onReject: (err: any) => {
      setLoadingState(false);
    },
  });

  const pricebookByVendorId = useAPI({
    deferFn: getPricebooksByVendorId,
    onResolve: (result: any) => {
      if(origin){
        const sortByUpdated = selectedSort.sortBy === sortDirection.DESC ? sortDirection.ASC : sortDirection.DESC;
        onHandleSort(
          result,
          sortByUpdated,
          selectedSort.sortColumn,
          true,
          selectedSort.sortKey!,
        );
      }else{
        onHandleSort(
          result,
          selectedSort.sortBy,
          selectedSort.sortColumn,
          firstLoad,
          selectedSort.sortKey!,
        );
      }
      setLoadingState(false);
      setFirstLoad(false);
      origin = false;
    },
    onReject: (err: any) => {
      setLoadingState(false);
    },
  });

  const pricebookCountries = useAPI({
    deferFn: getCountries,
    onResolve: (result: any) => {
      setCountries(result);
    },
    onReject: (err: any) => { },
  });

  const pricebookByCompanyVendorId = useAPI({
    deferFn: getPricebooksByCompanyVendorId,
    onResolve: (result: any) => {
      setPricebookType(PricebookType.COMPANY);
      setNewPBModalOpen(false);
      if (result) {
        setPricebookPreviewData(result);
        onPreview(
          result.id,
          result?.priceBookType,
          result?.company?.companyName,
          result?.vendor?.name
        );
      } else {
        setUploadPBModalOpen(true);
      }
    },
    onReject: (err: any) => { },
  });

  const pricebookByRefernceVendorId = useAPI({
    deferFn: getPricebooksByReferenceVendorId,
    onResolve: (result: any) => {
      setPricebookType(PricebookType.REFERENCE);
      setNewReferencePBModalOpen(false);
      if (result) {
        setPricebookPreviewData(result);
        onPreview(
          result.id,
          result?.priceBookType,
          result?.country?.countryName,
          result?.vendor?.name
        );
      } else {
        setUploadPBModalOpen(true);
      }
    },
    onReject: (err: any) => { },
  });

  const pricebookByWarehouseVendorId = useAPI({
    deferFn: getPricebooksByWarehouseVendorId,
    onResolve: (result: any) => {
      setPricebookType(PricebookType.GEOGRAPHY);
      setNewPricebookGeographyModalOpen(false);
      if (result) {
        setPricebookPreviewData(result);
        onPreview(
          result.id,
          result?.priceBookType,
          result?.warehouse?.name,
          result?.vendor?.name
        );
      } else {
        setUploadPBModalOpen(true);
      }
    },
    onReject: (err: any) => { },
  });

  useEffect(() => {
    vendorsReq.run([token]);
    fetchPricebookData();
  }, []);

  useEffect(() => {
    origin = true;
    fetchPricebookData();
    if (selectedVendor) {
      /* If Vendor Admin selected or logged in */
      //setSelectedAssignedBy(selectedVendor); Moved to useNewPricebookAssignmentState hook
      warehouseReq.run([selectedVendor?.id]);
    }
  }, [selectedVendor]);

  useEffect(() => {
    selectedAssignedTo && setAssignedToError(false);
  }, [selectedAssignedTo]);

  useEffect(() => {
    if (selectedAssignedBy) {
      setAssignedByError(false);
      warehouseReq.run([selectedAssignedBy.id]);
      setSelectedWarehouseByOption({ value: '' });
    }
  }, [selectedAssignedBy]);

  useEffect(() => {
    if (warehouseReq.data) {
      setWarehouseList(warehouseReq.data);
    }
  }, [warehouseReq.data]);

  useEffect(() => {
    if (warehouseReq.data) {
      setWarehouseList(warehouseReq.data);
    }
  }, [warehouseReq.data]);

  const fetchPricebookData = () => {
    setLoadingState(true);
    pricebookCountries.run();
    if (isPricebookPage) {
      pricebookByVendorId.run(selectedVendor?.id);
    } else {
      pricebookByCompanyId.run(companyId, selectedVendor?.id);
    }
  };

  const onModalClose = () => {
    origin = true;
    setNewPBModalOpen(false);
    setNewReferencePBModalOpen(false);
    setUploadPBModalOpen(false);
    setReplacePBModalOpen(false);
    setPricebookPreview(false);
    clearSelects();
    setPricebookPreview(false);
    setPricebookPreviewData(null);
    setNewPricebookGeographyModalOpen(false);
    setPricebookPreviewId(undefined);
    setLoadingState(true);
    fetchPricebookData();
  };

  const onSearchTermSet = (value: string) =>
    handleOnSearchKeyChange(value, setSearchTerm, setCurrentPage);

  const onClickNewPricebooks = () => {
    setNewPBDropDownAnchorEl(null);
    setNewPBModalOpen(true);
  };

  const onHandleOpenNewGeographyPricebookModal = () => {
    handleCloseNewPBDropDown();
    setNewPricebookGeographyModalOpen(true);
  };

  const onClickReplacePricebooks = (pricebookId: number) => {
    onModalClose();
    setPricebookPreviewId(pricebookId);
    setReplacePBModalOpen(true);
  };

  const onConfirmRerencePricebooks = () => {
    if (!selectedVendor?.id && selectedAssignedBy == null) {
      setAssignedByError(true);
      return;
    }

    if (!selectedCountry?.id) {
      setCountryError(true);
      return;
    }

    pricebookByRefernceVendorId.run(
      selectedVendor?.id ? selectedVendor?.id : selectedAssignedBy?.id,
      selectedCountry?.id || ''
    );
  };

  const onConfirmNewPricebooks = () => {
    const hasAssignedBySelectionIssue =
      !selectedVendor?.id && !selectedAssignedBy;
    const hasAssignedToSelectionIssue = !companyId && !selectedAssignedTo;

    if (hasAssignedBySelectionIssue || hasAssignedToSelectionIssue) {
      hasAssignedBySelectionIssue &&
        setAssignedByError(hasAssignedBySelectionIssue);
      hasAssignedToSelectionIssue &&
        setAssignedToError(hasAssignedToSelectionIssue);
    } else {
      pricebookByCompanyVendorId.run(
        isPricebookPage ? selectedAssignedTo?.companyId : companyId,
        selectedVendor?.id ? selectedVendor?.id : selectedAssignedBy?.id,
        selectedCompanyUser && selectedCompanyUser.customerId
          ? selectedCompanyUser.customerId
          : ''
      );
    }
  };

  const onconfirmGeographyPricebook = () => {
    if (!selectedVendor?.id && selectedAssignedBy == null) {
      setAssignedByError(true);
    }
    if (!selectedWarehouseByOption?.value) {
      setWarehouseError(true);
    } else {
      pricebookByWarehouseVendorId.run(
        selectedVendor?.id ? selectedVendor?.id : selectedAssignedBy?.id,
        selectedWarehouse?.code
      );
    }
  };

  const onPreview = (
    pricebookPreviewId: number,
    pricebookType?: string,
    companyName?: string,
    vendorName?: string
  ) => {
    setPricebookPreviewId(pricebookPreviewId);
    setUploadPBModalOpen(true);
    if (pricebookType) {
      setPricebookType(pricebookType);
    }
    setSelectedCompanyName(companyName);
  };

  useEffect(() => {
    if (pricebookPreviewId != undefined) {
      setPricebookPreview(true);
    }
  }, [pricebookPreviewId]);

  const onHandleSort = (
    data: any[],
    sortBy: string,
    sortColumn: string,
    isSortAllowed: boolean,
    sortKey: string
  ) => {
    if (isSortAllowed) {
      let sortByUpdated = sortBy === sortDirection.DESC ? sortDirection.ASC : sortDirection.DESC;
      if (sortColumn !== selectedSort.sortColumn) {
        sortByUpdated = sortDirection.ASC;
      }
      setSelectedSort({
        sortBy: sortByUpdated,
        sortColumn,
        sortKey,
      });
      if(sortKey === 'priceBookType') {
        setPricebookData(
          pricebookHierarchySort(data || [], sortByUpdated)
        );
      } else {
        setPricebookData(
          applySortFilter(data || [], {defaultSort: sortByUpdated, field: sortKey})
        );
      }
    }
  };

  const columns = () => {
    const filteredColumns = [];
    for (let i = 0; i < companyPricebookColumns.length; i++) {
      const companyColumn = companyPricebookColumns[i];

      if (
        (companyColumn.field === 'pricebookVendor' && isAdmin) ||
        companyColumn.field !== 'pricebookVendor'
      ) {
        let width = companyColumn.width;

        if (companyColumn.field === 'pricebookPublishedData' && !isAdmin) {
          width = '18%';
        }

        filteredColumns.push(
          <HeaderTitle
            className={
              selectedSort.sortColumn === companyColumn.title
                ? 'header-title-active-sort'
                : 'header-title'
            }
            key={i}
            style={{ width: width }}
            onClick={() =>
              onHandleSort(
                pricebookData,
                selectedSort.sortBy,
                companyColumn.title,
                companyColumn.sort,
                companyColumn.sortKey!
              )
            }
          >
            {companyColumn.title}
            {companyColumn.sort ? (
              selectedSort.sortBy === sortDirection.DESC ? (
                <ArrowDownward
                  className={
                    selectedSort.sortColumn === companyColumn.title
                      ? 'active-sort-icon'
                      : 'hidden-sort-icon'
                  }
                />
              ) : (
                <ArrowUpward
                  className={
                    selectedSort.sortColumn === companyColumn.title
                      ? 'active-sort-icon'
                      : 'hidden-sort-icon'
                  }
                />
              )
            ) : null}
          </HeaderTitle>
        );
      }
    }
    return filteredColumns;
  };

  const enableNewPricebookModal =
    assignedByList && isPricebookPage ? assignedToList : companyCustomers;

  const newPriceBooksModal = (
    <NewPricebooksModal
      selectedVendor={selectedVendor}
      show={newPBModalOpen}
      onClose={onModalClose}
      onConfirm={onConfirmNewPricebooks}
      //assign by props
      assignee={selectedAssignedByOption}
      setAssignee={onSelectAssignedBy}
      assigneeList={assignedByList}
      //company user props
      companyUsers={
        isPricebookPage
          ? companyUsers
          : (companyCustomers as unknown as CustomerExtended[])
      }
      setCompanyUser={onSelectCompanyUser}
      companyUser={selectedCompanyUserOption}
      //assign to props
      selectedCompany={
        isPricebookPage ? selectedAssignedTo : selectedCompanyName
      }
      companiesList={isPricebookPage ? assignedToList : undefined}
      setCompany={onSelectAssignedTo}
      //errors
      assignedByError={assignedByError}
      assignedToError={assignedToError}
    />
  );
  const newReferenceModal = (
    <NewReferencePBModal
      show={newReferencePBModalOpen}
      assigneeList={assignedByList}
      selectedVendor={selectedVendor}
      onClose={onModalClose}
      onConfirm={onConfirmRerencePricebooks}
      assignee={selectedAssignedByOption}
      setAssignee={onSelectAssignedBy}
      assignedByError={assignedByError}
      country={selectedCountryOption}
      setSelectedCountry={setSelectedCountryOption}
      countries={countries}
      countryError={countryError}
    />
  );

  const newGeographyPricebooksModal = (
    <NewGeographyPricebooksModal
      assigneeList={assignedByList}
      warehouses={warehouseList}
      selectedVendor={selectedVendor}
      show={newPricebookGeographyModalOpen}
      onClose={onModalClose}
      onConfirm={onconfirmGeographyPricebook}
      assignee={selectedAssignedByOption}
      warehouse={selectedWarehouseByOption}
      setAssignee={onSelectAssignedBy}
      setWarehouse={setSelectedWarehouseByOption}
      assignedByError={assignedByError}
      warehouseError={warehouseError}
    />
  );

  const uploadPriceBooksModal = (
    <PricebooksUploadModal
      show={uploadPBModalOpen}
      onClose={onModalClose}
      vendorName={
        selectedAssignedBy ? selectedAssignedBy?.name : selectedVendor?.name
      }
      selectedAssignedBy={selectedVendor ? selectedVendor : selectedAssignedBy}
      selectedAssignedTo={selectedCompanyUser}
      companyId={isPricebookPage ? selectedAssignedTo?.companyId : companyId}
      companyName={
        isPricebookPage ? selectedAssignedTo?.companyName : selectedCompanyName
      }
      preview={pricebookPreview}
      pricebookPreviewData={pricebookPreviewData}
      pricebookPreviewId={pricebookPreviewId}
      selectedVendor={selectedVendor}
      onReplaceClick={onClickReplacePricebooks}
      pricebookType={pricebookType}
      /* For Geography Pricebook */
      warehouseId={selectedWarehouse?.code}
      warehouseName={selectedWarehouse?.name}
      /* For Reference Pricebook */
      countryId={selectedCountry?.id}
      countryName={selectedCountry?.countryName}
    />
  );

  const replacePriceBooksModal = (
    <PricebooksReplaceModal
      show={replacePBModalOpen}
      onClose={onModalClose}
      vendorName={
        selectedAssignedBy ? selectedAssignedBy?.name : selectedVendor?.name
      }
      companyId={companyId}
      companyName={selectedCompanyName}
      pricebookPreviewId={pricebookPreviewId}
      hasSelectedVendor={!!selectedVendor}
    />
  );

  const header = <FlexboxContainer>{columns()}</FlexboxContainer>;

  const alternativeContent = isLoading ? (
    <LoadingIndicator />
  ) : (
    <FlexboxContainer flexDirection="column">
      <StyledMessage>{emptyDataSourceMainMessage}</StyledMessage>
      <StyledMessage>{emptyDataSourceExtraMessage}</StyledMessage>
    </FlexboxContainer>
  );

  const onNewPBDropDownClick = (event: any) => {
    setNewPBDropDownAnchorEl(event.currentTarget);
  };

  const handleCloseNewPBDropDown = () => {
    setNewPBDropDownAnchorEl(null);
  };
  const handleCloseNewReferenceDropDown = () => {
    setNewReferencePBModalOpen(true);
    setNewPBDropDownAnchorEl(null);
  };

  const onDownloadTemplateDropDownClick = (event: any) => {
    setDownloadTemplateDropDownAnchorEl(event.currentTarget);
  };

  const handleCloseDownloadTemplateDropDown = () => {
    setDownloadTemplateDropDownAnchorEl(null);
  };

  const newPricebookButtonDropDown = (
    <>
      <Button
        margin="0 4px 0 0"
        borderColor={Text.primary}
        bgColor={Text.primary}
        onClick={onNewPBDropDownClick}
      >
        <Add style={{ width: '24px', height: '24px' }} /> New pricebook{' '}
        <ArrowDropDownRounded style={{ width: '24px', height: '24px' }} />
      </Button>
      <Menu
        style={{ top: '46px', left: '-28px' }}
        id="custom-button-menu"
        anchorEl={newPBDropDownAnchorEl}
        keepMounted
        open={Boolean(newPBDropDownAnchorEl)}
        onClose={handleCloseNewPBDropDown}
      >
        <MenuItem onClick={handleCloseNewReferenceDropDown}>
          <Public
            style={{ width: '24px', height: '24px', marginRight: '6px' }}
          />{' '}
          Reference
        </MenuItem>
        <MenuItem onClick={onHandleOpenNewGeographyPricebookModal}>
          <PinDrop
            style={{ width: '24px', height: '24px', marginRight: '6px' }}
          />{' '}
          Geography
        </MenuItem>
        <MenuItem onClick={onClickNewPricebooks}>
          <PricebookModalIcon
            style={{ width: '24px', height: '24px', marginRight: '6px' }}
          />{' '}
          Company / Customer
        </MenuItem>
      </Menu>
    </>
  );

  const downloadTemplateDropDown = (
    <>
      <DownloadTemplateDropDown onClick={onDownloadTemplateDropDownClick}>
        <StyledFileDownloadIcon />
        Download template
        <ArrowDropDownRounded
          style={{ width: '24px', height: '24px', alignSelf: 'center' }}
        />
      </DownloadTemplateDropDown>
      <Menu
        style={{ top: '46px' }}
        id="custom-button-menu"
        anchorEl={downloadTemplateDropDownAnchorEl}
        keepMounted
        open={Boolean(downloadTemplateDropDownAnchorEl)}
        onClose={handleCloseDownloadTemplateDropDown}
      >
        <MenuItem
          component={Link}
          onClick={handleCloseDownloadTemplateDropDown}
          href="/Reference_Template.xlsx"
        >
          <Public
            style={{ width: '24px', height: '24px', marginRight: '6px' }}
          />{' '}
          Reference
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleCloseDownloadTemplateDropDown}
          href="/Geography_Template.xlsx"
        >
          <PinDrop
            style={{ width: '24px', height: '24px', marginRight: '6px' }}
          />{' '}
          Geography
        </MenuItem>
        <MenuItem
          component={Link}
          onClick={handleCloseDownloadTemplateDropDown}
          href="/Favorites Template.xlsx"
        >
          <PricebookModalIcon
            style={{ width: '24px', height: '24px', marginRight: '6px' }}
          />{' '}
          Company / Customer
        </MenuItem>
      </Menu>
    </>
  );

  return (
    <Page padding={!isPricebookPage ? '0' : undefined}>
      {isPricebookPage && <PageHeading>Pricebooks</PageHeading>}
      {companyCustomers && !isPricebookPage && newPriceBooksModal}
      {newReferencePBModalOpen && newReferenceModal}
      {enableNewPricebookModal && newPriceBooksModal}
      {uploadPBModalOpen && uploadPriceBooksModal}
      {replacePBModalOpen && replacePriceBooksModal}
      {newPricebookGeographyModalOpen && newGeographyPricebooksModal}
      <HeaderActionBarWrapper
        borderRadius="6px 6px 0 0"
        flexDirection="row"
        justifyContent="space-between"
        bgColor={Background.shaded}
      >
        <FlexboxContainer
          borderRadius="6px"
          bgColor={Background.shaded}
          margin="8px"
          justifyContent="flex-start"
          flexDirection="row"
          width="25%"
        >
          <SearchBar
            searchValue={searchTerm}
            onSearch={onSearchTermSet}
            containerWidth="100%"
          />
        </FlexboxContainer>
        <FlexboxContainer
          borderRadius="6px"
          bgColor={Background.shaded}
          margin="8px"
          justifyContent="flex-end"
          flexDirection="row"
        >
          {isPricebookPage && newPricebookButtonDropDown}
          {isPricebookPage && downloadTemplateDropDown}
          {!isPricebookPage && (
            <>
              <Button
                margin="0 4px 0 0"
                borderColor={Text.primary}
                bgColor={Text.primary}
                onClick={onClickNewPricebooks}
              >
                <Add style={{ width: '24px', height: '24px' }} /> New pricebook{' '}
              </Button>
              <DownloadTemplateButton href={PricebookTemplateUrl}>
                <StyledFileDownloadIcon />
                Download template
              </DownloadTemplateButton>
            </>
          )}
        </FlexboxContainer>
      </HeaderActionBarWrapper>
      {header}
      {pricebookData?.length ?
        <PricebookTable
          pricebooks={pricebookData}
          isAdmin={isAdmin}
          onPreview={onPreview}
          onReplaceClick={onClickReplacePricebooks}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          showAsHierarchy={selectedSort.sortKey === 'priceBookType'}
        />
        : alternativeContent}
      <Pagination
        setCurrentPage={setCurrentPage}
        baseRoute={''}
        setItemsPerPage={setItemsPerPage}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        totalPages={pagesCount}
        shouldRedirect={false}
        selectorOptions={extendedPaginationSelectOptions}
        shouldHandleOnSizeChange={true}
      />
    </Page>
  );
};

export default PricebooksTab;
