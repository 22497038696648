import React from 'react';
import styled from 'styled-components';
import { Color, Size, contentWidth } from 'lib/styles';
import shTitle from 'images/sh-ofmp-transparent-bg.png';

const Header = styled.header`
  align-items: center;
  background-color: ${Color.black};
  display: flex;
  height: 5.1875rem;
  justify-content: space-between;
  padding: ${Size.small} ${Size.medium} 0px;
`;

const Nav = styled.nav`
  width: ${contentWidth};
  padding: 0px ${Size.medium};
`;

const UserNav = styled.div`
  flex: 1;
  text-align: right;
`;

export const Img = styled.img`
  height: 4.1875rem;
  border-radius: 4px;
  position: relative;
`;

export interface Props {
  navLinks?: React.ReactNode;
  userLinks?: React.ReactNode;
};

const PageHeader = ({ navLinks, userLinks }: Props) => (
  <Header>
    <Img alt="Seller Hub" src={shTitle} />;
    <Nav>{navLinks}</Nav>
    <UserNav>{userLinks}</UserNav>
  </Header>
);

export default PageHeader;
