import readExcelFile from 'read-excel-file';
import {
  PricebookCompanyTemplateBasicColumn,
  PricebookGeographyTemplateBasicColumn,
  PricebookReferenceTemplateBasicColumn,
  PricebookRentalColumns
} from 'constants/pricebooksTemplateColumns';
import { PricebookExtended, PricebookForHierarchy, PricebookType } from 'data/Pricebook';
import { applySortFilter } from 'utils/grid';
import { groupObjectsByField, separateWithLengthLimit } from 'utils/array';
import { sortDirection } from 'constants/sort';

const requiredFileFormat = '.xlsx';
const requiredFileSize = 20971520;

export const fileSizeToMb = (fileSize:number = 0): number => {
  return fileSize / 1024 ** 2;
};

export const pathToFileToFilename = (pathToFile: string): string => {
  return pathToFile.split('/')[1]?.split('-')[0]
};

export const errorLogUrl = (pathToFile: string) => {
  if(pathToFile)
    return (process.env.REACT_APP_MEDIA_URL || '') + '/pricebooks/' + pathToFile
  return '';
};

export const pricebookFileUrl = (pathToFile: string) => {
  if(pathToFile)
    return (process.env.REACT_APP_MEDIA_URL || '') + '/pricebooks/' + pathToFile
  return '';
};

export const filenameWithoutExtension = (filename: string = ''): string => {
  return filename.split('.')[0];
}

export const checkFileSize = (file: File | null): boolean => !!file && file.size < requiredFileSize;

export const checkFileType = (file: File | null): boolean => {
  if(!file) {
    return false
  }
  return file.name.endsWith(requiredFileFormat);
}

export const verifyFileTemplate = async (file: File | null, pricebookType: string): Promise<boolean> => {
  if (!file) {
    return false;
  }

  const pricebooksData = await readExcelFile(file);
  const firstRow = pricebooksData[0].toString();

  let basicTemplateColumns;
  let rentalTemplateColumns;
  
  if (pricebookType === PricebookType.COMPANY) {
    basicTemplateColumns = PricebookCompanyTemplateBasicColumn.toString();
    rentalTemplateColumns = [...PricebookCompanyTemplateBasicColumn, ...PricebookRentalColumns].toString();
  } else if (pricebookType === PricebookType.REFERENCE) {
    basicTemplateColumns = PricebookReferenceTemplateBasicColumn.toString();
    rentalTemplateColumns = [...PricebookReferenceTemplateBasicColumn, ...PricebookRentalColumns].toString();
  } else {
    basicTemplateColumns = PricebookGeographyTemplateBasicColumn.toString();
    rentalTemplateColumns = [...PricebookGeographyTemplateBasicColumn, ...PricebookRentalColumns].toString();
  }

  return basicTemplateColumns === firstRow || rentalTemplateColumns === firstRow;
};


export const pricebookHierarchySort = (pricebooks: PricebookExtended[], sortType: string) => {
  applySortFilter(pricebooks, {defaultSort: sortType, field: 'vendor.name'});
  const groupedPricebooks = groupObjectsByField(pricebooks, 'vendor.name') as PricebookExtended[][];
  const mappedPricebooks = groupedPricebooks.map((group)=>{
    return pricebookHierarchySortForOneVendor(group);
  });

  return mappedPricebooks.flat(1);
}

const pricebookHierarchySortForOneVendor = (pricebooks: PricebookExtended[]) => {
  const groupedPricebooks = groupObjectsByField(pricebooks, 'country.countryName', sortDirection.DESC) as PricebookExtended[][];

  groupedPricebooks.forEach((group)=>{
    applySortFilter(group, {defaultSort: sortDirection.DESC, field: 'priceBookType'});
  })
  return groupedPricebooks.flat(1);
}

export const calculatePagesForHierarchy = (pricebooks: PricebookExtended[], itemsPerPage: number) => {
  const pages = dividePricebookIntoPagesByGroups(pricebooks, itemsPerPage);
  return pages.length;
};

export const getPricebooksForPageHierarchy = (pricebooks: PricebookExtended[], itemsPerPage: number, currentPage: number): PricebookExtended[] => {
  const pages = dividePricebookIntoPagesByGroups(pricebooks, itemsPerPage);
  return pages[currentPage - 1];
}

const dividePricebookIntoPagesByGroups = (pricebooks: PricebookExtended[], itemsPerPage: number) => {
  const pricebooksGroupedByVendor = groupObjectsByField(pricebooks, 'vendor.name', sortDirection.ASC, itemsPerPage) as PricebookExtended[][];
  const mappedGroups = pricebooksGroupedByVendor.map(group=>mapVendorVisibilityForHierarchy(group));
  if(mappedGroups.length === 1) {
    return separateWithLengthLimit(mappedGroups[0], itemsPerPage) as PricebookForHierarchy[][]
  }
  const pages: PricebookExtended[][] = [];
  let currentPage: PricebookExtended[] = [];

  mappedGroups.forEach((currentGroup)=>{
    if(currentPage.length + currentGroup.length <= itemsPerPage) {
      currentPage.push(...currentGroup);
      return;
    }
    pages.push(currentPage);
    currentPage = [];
    if(currentGroup.length >= itemsPerPage) {
      pages.push(currentGroup);
    } else {
      currentPage.push(...currentGroup);
    }
  })

  if(currentPage.length) {
    pages.push(currentPage);
  }
  return pages;
}

const mapVendorVisibilityForHierarchy = (pricebookGroup: PricebookExtended[]): PricebookForHierarchy[] => {
  // @ts-ignore
  const referencePricebooksCountries: string[] = pricebookGroup.filter((pricebook)=>pricebook.priceBookType === PricebookType.REFERENCE)
    .map(pricebook=>pricebook.country?.countryName)
    .filter((countryName)=>!!countryName);

  const mappedGroup: PricebookForHierarchy[] =  pricebookGroup.map((pricebook) => {
    const isLowLevel =
      pricebook.priceBookType !== PricebookType.REFERENCE
      && !!pricebook.country?.countryName
      && referencePricebooksCountries.includes(pricebook.country?.countryName);

    return {
      ...pricebook,
      lowLevelHierarchy: isLowLevel,
    }
  });
  mappedGroup[0].isTheFirstRowForVendor = true;
  return mappedGroup;
}
