import React, { useState, useEffect, ReactNode, ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { Input, InputAdornment } from '@material-ui/core';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { BorderRadius } from 'styles/themeBorderRadius';
import { Background, Border, Main, Text } from 'styles/themeColors';
import { Height } from 'styles/themeHeights';
import { Space } from 'styles/themeSpaces';
import { Color, FontWeight, Size } from 'lib/styles';
import { MainColors } from 'types/enumTypes';
import InfoIcon from 'components/UI/InfoIcon';

const Label = styled.label<any>`
  color: ${({ $error }) => $error ? Main.error : Text.primary};
  font-size: ${Space.sp2x};
  line-height: ${Height.xsPlus};

  ${({ disabled }) => disabled && css`
    color: ${Color.darkGray};
  `}
`;


const StyledInput = styled.input<any>`
  border-color: ${({ $error }) => $error ? Main.error : Border.main} !important;
  text-align: ${({ $textAlign }) => $textAlign};
  direction: ${({ $direction }) => $direction};
  background-color: ${({ disabled, $backgroundColor }) => $backgroundColor
    ? $backgroundColor
    : disabled
      ? Main.disabled
      : Background.white
  };

  ${({ $disabledBgColor }) => $disabledBgColor && css`
    &:disabled {
      background-color: ${$disabledBgColor};
    }
  `}

  box-sizing: border-box;
  border-radius: ${BorderRadius.m};
  width: ${({ width }) => width};
  height: 36px !important;
  padding: ${Space.spBase};

  ::placeholder,
  ::-webkit-input-placeholder {
    font-style: italic;
    // color: ${Color.darkGray};
  }

  :disabled {
    ${({ $blocked }) => $blocked && css`
      color: ${Text.primary};
      border: none;
    `}
  }

  svg.MuiSvgIcon-root MuiSelect-icon {
    color: ${Text.primary};
  }

  input:focus {
    box-shadow: 0 0 0 3px ${({ $error }) => $error ? 'rgba(200, 33, 53, 0.4)' : 'rgba(73, 141, 218, 0.4)'};
    border: 1px solid ${({ $error }) => $error ? Main.error : Main.accent};
  }

  input:hover {
    ${({ $error, disabled }) => !disabled && css`
      border: 1px solid ${$error ? Main.error : Main.accent};
    `}
  }
`;

const IconAsterisk = styled.span<any>`
  color: ${({ $error }) => $error ? Main.error : 'red'} ;
  width: 5px;
  height: 5px;
  margin-left: 5px;
`;

const StyledErrorMessage = styled.span`
  color: ${Main.error} !important;
  margin-top: ${Space.spQuarter};
`;

const StyledInfoIcon = styled(InfoIcon)<any>`
  display: flex;
  margin-left: ${Size.small};
  margin-top: 0.1rem;
`;

const LabelSubText = styled(FlexboxContainer)<any>`
  color: ${Color.darkGray};
  margin-bottom: ${({ $labelPosition }) => $labelPosition === 'top' ? '8px' : '0px'};
`;

const LabelSubTextRedesign = styled.span<any>`
  color: ${Color.darkerGray};
  font-style: italic;
  margin: -3px 0px;
`;

const InputContainer = styled(FlexboxContainer)<any>`
  padding-left: inherit;
  width: ${({ $labelPosition }) => $labelPosition === 'top' ? '100%' : '55%'};

  ${({ $blocked }) => $blocked && css`
    .Mui-disabled {
      border: none;
    }
  `}
`;

const LabelContainer = styled(FlexboxContainer)<any>`
  width: ${({ $labelPosition, productRedesignFlag }) => productRedesignFlag ? 'auto' : ($labelPosition === 'top' ? '100%' : '156px')};
  margin-bottom: ${({ $labelPosition, separation }) => $labelPosition === 'top' ? separation : '0px'};
  margin-right: ${({ $labelPosition, productRedesignFlag }) => productRedesignFlag ? '4px' : $labelPosition === 'left' ? '4px' : '0px'};
`;


const Description = styled.span<any>`
  color: ${Text.primary};
  line-height: ${Height.xsPlus};
  word-wrap: break-word;
  font-size: ${Space.spBasePlus};
  margin-top: ${Space.spQuarter};
`;

const LabelText = styled.span<any>`
  font-size: 14px;
  font-weight: ${FontWeight.medium};
  color: ${Text.primary};
  margin-left: ${({ productRedesignFlag }) => productRedesignFlag ? '0px' : '5px'};
`;

const OptionalLabel = styled.span<{ $error?: boolean }>`
  font-size: 15px;
  color: ${({ $error }) => $error ? Main.error : 'grey'};
  font-style: italic;
`;


Label.displayName = 'Label';
StyledInput.displayName = 'StyledInput';
InputContainer.displayName = 'InputContainer';

interface Props {
  id: string;
  className?: string;
  name?: string;
  type: string;
  value?: string | number;
  infoIconText?: React.ReactNode;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onInputChange?: (value: string) => void;
  onCurrencyChange?: (value: string) => void;
  onBlur?: (e: any) => void;
  onClick?: () => void;
  onKeyUp?: (e: any) => void;
  onEnter?: (value: string) => void;
  disabledBgColor?: string;
  height?: string;
  checked?: boolean;
  placeholder?: string;
  labelName?: string;
  labelHidden?: boolean;
  labelPosition?: 'top' | 'left';
  description?: string;
  width?: string;
  isInvalid?: boolean;
  required?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  blocked?: boolean;
  dirty: boolean;
  textAlign: string;
  direction: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  selectAllOnFirstClick?: boolean;
  readOnly?: boolean;
  defaultValue?: string;
  backgroundColor?: MainColors;
  displayAsterisk?: boolean;
  isOptional?: boolean;
  maxLength?: string;
  margin?: string;
  labelText?: string;
  choiceOptional?: boolean;
  productRedesignFlag?: boolean;
  separation?: string;
};

const InputComponent = ({
  id,
  className,
  name,
  type,
  value,
  infoIconText,
  onChange,
  onInputChange,
  onCurrencyChange,
  onKeyUp,
  onClick,
  onBlur,
  onEnter,
  disabledBgColor,
  checked,
  height,
  placeholder,
  labelName,
  labelHidden,
  labelPosition,
  description,
  width,
  isInvalid,
  required,
  errorMessage,
  disabled,
  blocked,
  dirty,
  textAlign,
  direction,
  startIcon,
  endIcon,
  selectAllOnFirstClick,
  readOnly,
  defaultValue,
  backgroundColor,
  displayAsterisk,
  isOptional,
  maxLength,
  margin,
  labelText,
  choiceOptional = false,
  productRedesignFlag = false,
  separation = '8px'
}: Props) => {
  const [isDirty, setIsDirty] = useState<boolean>(dirty);

  const inputBackground = backgroundColor && Main[backgroundColor];

  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!disabled && !blocked) {
      if (onChange) {
        onChange(event);
      } else if (onCurrencyChange) {
        onCurrencyChange(parseInt(event.target.value.replace(/,/g, '')).toLocaleString())
      } else if (onInputChange) {
        onInputChange(event.target.value);
      }
    }
  };

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnter && event.key === 'Enter') {
      onEnter((event.target as HTMLInputElement).value);
    }
  }

  const handleClick = () => {
    if (selectAllOnFirstClick && !isDirty) {
      (document.getElementById(id) as HTMLInputElement)?.select();
    }

    if (onClick) {
      onClick();
    }
  };

  const handleBlur = (e: any) => {
    setIsDirty(true);

    if (onBlur) {
      onBlur(e);
    }
  };

  const label = labelName && (
    <FlexboxContainer>
      <LabelContainer $labelPosition={labelPosition} productRedesignFlag={productRedesignFlag} separation={separation}>
        {labelHidden ? null :
          <Label disabled={disabled} $error={(required && isDirty && !value) || isInvalid}>
            {labelName} {!isOptional && !choiceOptional && !displayAsterisk && <IconAsterisk> * </IconAsterisk> } {choiceOptional && <OptionalLabel $error={(required && isDirty && !value) || isInvalid}>(optional)</OptionalLabel>}
          </Label>
        }
        {infoIconText ? <StyledInfoIcon tooltipText={infoIconText} productRedesignFlag={productRedesignFlag}  /> : null}
        {!disabled && displayAsterisk ? <IconAsterisk $error={(required && isDirty && !value) || isInvalid}> * </IconAsterisk> : null}
      </LabelContainer>
      {isOptional ? productRedesignFlag ? 
        <LabelSubTextRedesign>(optional)</LabelSubTextRedesign> : 
        <LabelSubText $labelPosition={labelPosition}>(Optional)</LabelSubText> : null}
    </FlexboxContainer>
  );

  const startInputIcon = startIcon && (
    <InputAdornment position="start">
      {startIcon}
    </InputAdornment>
  );

  const endInputIcon = endIcon && (
    <InputAdornment position="end">
      {endIcon}
    </InputAdornment>
  );

  const input = (
    <>
    <StyledInput
      id={id}
      className={`base ${disabled ? '' : 'border'} ${className}`}
      name={name}
      type={type}
      value={value}
      placeholder={placeholder}
      disabled={disabled || blocked}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyPress={onKeyPress}
      $disabledBgColor={disabledBgColor}
      checked={checked}
      height={height}
      width="100%"
      startAdornment={startInputIcon}
      endAdornment={endInputIcon}
      disableUnderline
      onClick={handleClick}
      $error={(required && isDirty && !value) || isInvalid}
      $blocked={blocked}
      $textAlign={textAlign}
      $direction={direction}
      readOnly={readOnly}
      defaultValue={defaultValue}
      onKeyUp={onKeyUp}
      $backgroundColor={inputBackground}
      maxLength={maxLength}
    />
    {labelText && <LabelText productRedesignFlag={productRedesignFlag}>{labelText}</LabelText>} {}
    </>
  );

  const error = ((required && isDirty && !value) || isInvalid) && (
    <StyledErrorMessage className="caption">
      {errorMessage}
    </StyledErrorMessage>
  );

  const showDescription = description && (
    <Description>
      {description}
    </Description>
  );

  return (
    <FlexboxContainer
      flexWrap='wrap'
      width={width}
      justifyContent='space-between'
      alignItems='center'
      margin={margin}
    >
      {labelPosition === 'left' && label}
      <InputContainer
        $labelPosition={labelPosition}
        flexDirection='column'
        $blocked={blocked}
      >
        {labelPosition === 'top' && label}
        {input}
        {error}
        {showDescription}
      </InputContainer>
    </FlexboxContainer>
  );
};

InputComponent.defaultProps = {
  className: '',
  type: 'text',
  labelPosition: 'top',
  placeholder: 'Leave a comment',
  width: '100%',
  errorMessage: 'This field is required',
  disabled: false,
  blocked: false,
  required: false,
  isInvalid: false,
  dirty: false,
  textAlign: 'left',
  direction: 'ltr',
  padding: Space.spHalf,
  height: '36px',
  margin: ''
};

export default InputComponent;
