import FlexboxContainerComponent from '../../UI/FlexboxContainer';
import Modal from '../../UI/Modal';
import React from 'react';
import styled from 'styled-components';
import { Space } from 'styles/themeSpaces';
import { Border, Main, Text } from 'styles/themeColors';


const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface DefaultActionModalProps {
  onClose: () => void;
  onClick: () => void;
  bodyText: string;
  title: string;
  showModal: boolean;
};

const BUTTON_LABELS = {
  CANCEL: 'Cancel',
  CONFIRM: 'Confirm',
}

export const DefaultConfirmationModal = ({
  onClose,
  onClick,
  bodyText,
  title,
  showModal
}: DefaultActionModalProps) => (
  <Modal
    showModal={showModal}
    onClose={onClose}
    onClick={onClick}
    showGhostButton={true}
    ghostButtonText={BUTTON_LABELS.CANCEL}
    color={Text.placeholder}
    borderColor={Border.main}
    title={title}
    primaryButtonText={BUTTON_LABELS.CONFIRM}
    primaryButtonColor={Main.error}
  >
    <FlexboxContainerComponent>
      <Span>{bodyText}</Span>
    </FlexboxContainerComponent>
  </Modal>
)