import React, { Fragment } from 'react';

import { Heading2, Paragraph } from 'OnboardingComponents/Elements';

const ToneOfVoice = () => {
  return (
    <Fragment>
      <Heading2 id="tone-of-voice">Tone of voice</Heading2>
      <Paragraph>Given that buyers in the Oil and Gas industry have traditionally made their purchases by interacting with a sales person, we advise you to adopt a tone of voice that is formal, yet includes a more personal touch. Technical details are important for garnering the buyers’ trust in the product itself, but speaking to users in a way that sounds friendly and human is also an integral part of those decision-making moments. In your descriptions, show that you understand their needs and underline the issues that your product solves for them.</Paragraph>
      <Paragraph>If you are promoting the products of multiple subsidiary brands within the same parent company, then be sure to review our Seller Guidelines for tips on how to maintain tone consistency across them all.</Paragraph>
    </Fragment>
  );
};

export default ToneOfVoice;
