import Feather from 'feather-icons';
import React, { Ref } from 'react';
import styled, { css } from 'styled-components';
import { Size } from 'lib/styles';

// custom icons
import { ReactComponent as DragHandle } from 'images/drag-handle.svg';
import { ReactComponent as PDFFile } from 'images/pdf-file-icon.svg';
import { ReactComponent as Image } from 'images/image-icon.svg';
import { ReactComponent as Sort } from 'images/sort-icon.svg';
import { ReactComponent as Trash } from 'images/trash-icon.svg';
import { ReactComponent as ValidationCheck } from 'images/validation-check.svg';
import { ReactComponent as ValidationExclamation } from 'images/validation-exclamation.svg';
import { ReactComponent as ValidationX } from 'images/validation-x.svg';
import { ReactComponent as Video } from 'images/video-icon.svg';
import { ReactComponent as Copyright } from 'images/copyright.svg';
import { ReactComponent as Trademark } from 'images/trademark.svg';
import { ReactComponent as RegisteredTrademark } from 'images/registered-trademark.svg';
import { ReactComponent as ServiceMark } from 'images/service-mark.svg';
import { ReactComponent as Document } from 'images/document.svg';
import { ReactComponent as DeliveryFlag } from 'images/delivery-order-flag.svg';
import { ReactComponent as PickupFlag } from 'images/pickup-order-flag.svg';
import { ReactComponent as PendingCredit } from 'images/pending-credit.svg';
import { ReactComponent as Company } from 'images/company.svg';
import { ReactComponent as Columns } from 'images/config-columns.svg';
import { ReactComponent as CustomersNav } from 'images/customers-nav.svg';
import { ReactComponent as FileUpload } from 'images/file_upload.svg';
import { ReactComponent as ForwardToInbox } from 'images/forward_to_inbox.svg';
import { ReactComponent as OutgoingMail } from 'images/outgoing_mail.svg';
import { ReactComponent as Warehouse } from 'images/warehouse.svg';
import { ReactComponent as Slash } from 'images/slash.svg';
import { ReactComponent as PricebookIcon } from 'images/pricebook-icon.svg';
import { ReactComponent as FrontHand } from 'images/front_hand.svg';
import { ReactComponent as FileDownload } from 'images/file_download.svg';
import { ReactComponent as PricebookModalIcon } from 'images/pricebook-modal-icon.svg';
import { ReactComponent as FileDownloadIcon } from 'images/file-download-icon.svg';
import { ReactComponent as FileDownloadIconWhite } from 'images/file-download-icon-white.svg';
import { ReactComponent as MoreVerticalIcon } from 'images/more-vertical.svg';
import { ReactComponent as RowCompanyPricebooksIcon } from 'images/company-icon-pricebooks.svg';
import { ReactComponent as DeleteIconPricebooks } from 'images/delete-icon.svg';
import { ReactComponent as PricebookDeleteWarning } from 'images/pricebook-delete-warning.svg';
import { ReactComponent as InProgress } from 'images/in-progress.svg';
import { ReactComponent as PinDrop } from 'images/pin_drop.svg';
import { ReactComponent as Public } from 'images/public.svg';
import { ReactComponent as RequestQuoteIcon } from 'images/request_quote.svg';
import { ReactComponent as PricebookHierarchyArrow } from 'images/pricebook-hierarchy-arrow.svg';
import { ReactComponent as RemoveIcon } from 'images/remove-icon.svg';
import { ReactComponent as DeleteVariants } from 'images/delete.svg';
import { ReactComponent as CaretDropdown } from 'images/caret-dropdown.svg';
import { ReactComponent as WarningBlack } from 'images/warning.svg';
import { ReactComponent as Print } from 'images/print-icon.svg';
import { ReactComponent as Campaign } from 'images/campaign.svg';
import { ReactComponent as FileUploadV2 } from 'images/file-upload.svg';
import { ReactComponent as Draft } from 'images/draft-file.svg';
import { ReactComponent as Check } from 'images/check.svg';
import { ReactComponent as Home } from 'images/home.svg';

const icons: Record<string, any> = {
  DragHandle,
  PDFFile,
  Image,
  Sort,
  Trash,
  ValidationCheck,
  ValidationExclamation,
  ValidationX,
  Video,
  Copyright,
  Trademark,
  RegisteredTrademark,
  ServiceMark,
  Document,
  DeliveryFlag,
  PickupFlag,
  PendingCredit,
  Company,
  Columns,
  CustomersNav,
  FileUpload,
  ForwardToInbox,
  OutgoingMail,
  Warehouse,
  Slash,
  PricebookIcon,
  FrontHand,
  FileDownload,
  PricebookModalIcon,
  FileDownloadIcon,
  FileDownloadIconWhite,
  MoreVerticalIcon,
  RowCompanyPricebooksIcon,
  DeleteIconPricebooks,
  PricebookDeleteWarning,
  InProgress,
  PinDrop,
  RequestQuoteIcon,
  Public,
  PricebookHierarchyArrow,
  RemoveIcon,
  DeleteVariants,
  CaretDropdown,
  WarningBlack,
  Print,
  Campaign,
  FileUploadV2,
  Draft,
  Check,
  Home
};

const IconWrapper = styled.div<any>`
  display: inline-block;
  height: ${({ size }) => size}px;
  line-height: 0;
  vertical-align: top;
  width: ${({ size }) => size}px;

  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}

  &:first-child:not(:only-child) {
    margin-right: ${Size.small};
  }

  > svg {
    pointer-events: none;
  }
`;

export interface Props {
  className?: string;
  name: string;
  onClick?: (event: any) => void;
  size: number;
  disabled?: boolean;
  ref?: Ref<SVGSVGElement>;
  fillColor?: string; // Add fillColor prop
}

export default function Icon({ className, name, onClick, size, disabled, fillColor }: Props) {
  const sharedProps = {
    className,
    clickable: typeof onClick === 'function',
    onClick,
    size,
    disabled,
    fillColor
  };

  if (name in Feather.icons) {
    const iconSvg = Feather.icons[name].toSvg({
      height: size,
      width: size
    });

    return (
      <IconWrapper
        {...sharedProps}
        dangerouslySetInnerHTML={{ __html: iconSvg }}
      />
    );
  }

  if (icons.hasOwnProperty(name)) {
    const CustomIcon = icons[name];

    return (
      <IconWrapper {...sharedProps}>
        <CustomIcon width={size} height={size} />
      </IconWrapper>
    );
  }

  window.console.error(`No icon found named "${name}"`);
  return null;
}

Icon.defaultProps = {
  size: 24
};
