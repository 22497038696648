import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { BorderRadius } from 'lib/styles';
import { Space } from 'styles/themeSpaces';
import { Shade, Text } from 'styles/themeColors';
import FlexboxContainer from './FlexboxContainer';
import Icon from 'view/Icon';

const Wrapper = styled.div<any>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${Space.spHalf};
  margin: ${Space.sp3x} 0;
  background-color: ${Shade.shade230};
  border-radius: ${BorderRadius.medium}
`;

const StyledFrontHandIcon = styled(Icon).attrs({ name: 'FrontHand', size: 24 })`
  margin-right: ${Space.spBase} !important;
`;

const Title = styled.h6<any>`
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: ${Text.primary}
`;

const Textarea = styled.div<any>`
  font-size: 14px;
  line-height: 18px;
  color: ${Text.primary}
`;


interface Props {
  title: string;
  text: string;
  icon: JSX.Element;
  children: ReactNode;
};

const BannerWraper = ({
  children,
  title,
  text,
  icon
}: Props) => {
  return (
    <Wrapper
      width='100%'
    >
      <FlexboxContainer padding='16px 8px' width='100%'>
        {icon}
        <FlexboxContainer flexDirection='column'>
          <Title>
            {title}
          </Title>
          <Textarea>
            {text}
          </Textarea>
        </FlexboxContainer>
      </FlexboxContainer>
      {children}
    </Wrapper>
  )
};

BannerWraper.defaultProps = {
  title: 'Pre-order: please do not process it as of yet',
  text: 'The order is passing various checks and approvals and could be canceled.',
  icon: <StyledFrontHandIcon />
};

export default BannerWraper;
