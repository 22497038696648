import React from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Border, Text } from 'styles/themeColors';
import { Color, FontWeight } from 'lib/styles';
import { TEXT_LABELS } from 'constants/order';
import { DatePicker } from '@material-ui/pickers';
import DatePickerInput from 'components/UI/DatePickerInput';
import FileUploader from 'view/FileUploader';
import AssetCardV2 from 'view/AssetCardV2';
import Icon from 'view/Icon';
import * as Cons from 'data/Consignment';
import { VALID_TICKET_DOCUMENT_TYPES } from 'constants/documentOptions';
import { RENT_IN_PROGRESS } from 'constants/consignmentStates';
import { onlyWeekdaysAllowed } from 'utils/product';

const DocumentIcon = styled(Icon).attrs({ name: 'PDFFile', size: 26 })`
  color: ${Color.darkerGray};
`;

const LabelText = styled.span<{ color?: string; italic?: boolean }>`
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
  margin-bottom: 5px;
  font-size: 15px;
  color: ${({ color }) => color || 'inherit'};
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
`;


const TextArea = styled.textarea`
  border: 1px solid ${Border.main};
  border-radius: 6px;
  padding: 7px;
`;

export interface Props {
  show: boolean;
  onClose: () => void;
  onConfirm: (value: string) => void;
  title: string;  
  disabled?: boolean;
  rentalStartDate: string;
  rentalEndDate: string;
  reason: string;
  setRentalStartDate: (date: string) => void;
  setRentalEndDate: (date: string) => void;
  setReason: (reason: string) => void;
  uploadMedia: (files: File | File[]) => void;
  documents: any[];
  consignmentData: Cons.ConsignmentDetails;
  onDeleteMedia: (id: string) => void;
  newEstDate: string;
  setNewEstDate: (date: string) => void;
};

const RentalTicketDisputeModal = ({
  show,
  onClose,
  onConfirm,
  title,
  disabled = false,
  rentalEndDate,
  rentalStartDate,
  setRentalStartDate,
  setRentalEndDate,
  reason,
  setReason,
  uploadMedia,
  documents,
  consignmentData,
  onDeleteMedia,
  newEstDate,
  setNewEstDate
}: Props) => {
  return (
    <Modal
      showButtons
      showModal={show}
      title={title}
      onClose={onClose}
      onClick={onConfirm}
      disabled={disabled}
      ghostButtonText={'Cancel'}
      primaryButtonText={TEXT_LABELS.RESOLVE_TICKET}
      width='730px'
      left='30%'
      top='15%'
      className='ticket-dispute-modal-max-height'
    >
      <FlexboxContainer flexDirection="column">
        <LabelText>{TEXT_LABELS.RESOLVE_TICKET_DESCRIPTION}</LabelText>
        <FlexboxContainer flexDirection='row' gap='4px' margin='10px 0px'>
          <FlexboxContainer flexDirection="column" width='100%'>
            <LabelText>{TEXT_LABELS.START_DATE}</LabelText>
            <DatePicker
              value={consignmentData.rentalStartDate}
              inputVariant="outlined"
              format="yyyy MMM dd"
              TextFieldComponent={DatePickerInput}
              onChange={()=>{}}
              disabled
              autoOk
            /> 
          </FlexboxContainer>
          <FlexboxContainer flexDirection="column" width='100%'>
            <LabelText>{TEXT_LABELS.END_DATE}</LabelText>
              <DatePicker
                value={consignmentData.rentalEndDate}
                inputVariant="outlined"
                format="yyyy MMM dd"
                TextFieldComponent={DatePickerInput}
                onChange={()=>{}}
                disabled
                autoOk
              />
          </FlexboxContainer>
        </FlexboxContainer>
        <FlexboxContainer flexDirection='row' gap='4px' margin='10px 0px'>
          <FlexboxContainer flexDirection="column" width='100%'>
            <FlexboxContainer gap='4px'>
              <LabelText>{TEXT_LABELS.NEW_START_DATE}</LabelText>
              <LabelText italic color={Text.placeholder}>{TEXT_LABELS.OPTIONAL}</LabelText>
            </FlexboxContainer>
            <DatePicker
              value={rentalStartDate}
              inputVariant="outlined"
              format="yyyy MMM dd"
              TextFieldComponent={DatePickerInput}
              onChange={(date) => setRentalStartDate(date?.toString()!)}
              autoOk
              shouldDisableDate={(date) => onlyWeekdaysAllowed(date, consignmentData?.weekdaysOnly)}
            /> 
          </FlexboxContainer>
          <FlexboxContainer flexDirection="column" width='100%'>
            <FlexboxContainer gap='4px'>
                <LabelText>{TEXT_LABELS.NEW_END_DATE}</LabelText>
                <LabelText italic color={Text.placeholder}>{TEXT_LABELS.OPTIONAL}</LabelText>
              </FlexboxContainer>
              <DatePicker
                value={rentalEndDate}
                inputVariant="outlined"
                format="yyyy MMM dd"
                TextFieldComponent={DatePickerInput}
                onChange={(date) => setRentalEndDate(date?.toString()!)}
                autoOk
                shouldDisableDate={(date) => onlyWeekdaysAllowed(date, consignmentData?.weekdaysOnly)}
              />
          </FlexboxContainer>
        </FlexboxContainer>
        {
          consignmentData.previousState !== RENT_IN_PROGRESS && 
          <FlexboxContainer flexDirection='row' gap='4px' margin='10px 0px'>
            <FlexboxContainer flexDirection="column" width='50%'>
              <FlexboxContainer gap='4px'>
                  <LabelText>{TEXT_LABELS.ESTIMATED_DELIVERY}</LabelText>
                  <LabelText italic color={Text.placeholder}>{TEXT_LABELS.OPTIONAL}</LabelText>
                </FlexboxContainer>
                <DatePicker
                  value={newEstDate}
                  inputVariant="outlined"
                  format="yyyy MMM dd"
                  TextFieldComponent={DatePickerInput}
                  onChange={(date) => setNewEstDate(date?.toString()!)}
                  autoOk
                />
            </FlexboxContainer>
            <div style={{width: '50%'}}></div>
          </FlexboxContainer>
        }
      <FlexboxContainer flexDirection="column" margin='10px 0px 0px 0px'>
        <LabelText>{TEXT_LABELS.ADD_INFO}</LabelText>
        <TextArea maxLength={500} rows={5} value={reason} onChange={(event: any) => setReason(event.target.value)} />
      </FlexboxContainer>

      {
        documents?.map((file: any, index: number) => 
          <AssetCardV2
            asset={file}
            index={index}
            editableName={true}
            key={file.id}
            onDelete={() => onDeleteMedia(file.id)}
            renderThumbnail={() => <DocumentIcon />}
          />
        )
      }

      <FlexboxContainer margin='20px 0px' justifyContent='center'>
        <FileUploader
          acceptTypes={VALID_TICKET_DOCUMENT_TYPES}
          onChange={uploadMedia}
          productRedesignFlag
          leftIcon={() => <Icon name='FileUploadV2' size={18} />}
          text={TEXT_LABELS.ATTACH_DOCUMENTS}
          allowMultiple
        />
      </FlexboxContainer>
      </FlexboxContainer>
    </Modal>
  );
};

export default RentalTicketDisputeModal;
