export const printComponent = (id: string, filename: string, title?: string) => {
    const printContent = document.getElementById(id);

    if (!printContent) {
        console.error('No content found to print');
        return; // Early return if no content found
    }

    // Save the current page title
    const originalTitle = document.title;

    // Set the page title to the desired filename
    document.title = filename;

    // Create an iframe
    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';

    document.body.appendChild(iframe);

    // Check if iframe.contentWindow exists
    const iframeWindow = iframe.contentWindow;
    if (!iframeWindow) {
        console.error('Failed to access iframe contentWindow');
        return;
    }

    const iframeDoc = iframeWindow.document;

    if (iframeDoc) {
        // Get existing styles
        let styles = Array.from(document.styleSheets).map(styleSheet => {
            try {
                return Array.from(styleSheet.cssRules).map(rule => rule.cssText).join('\n');
            } catch (e) {
                return ''; // Ignore any errors from cross-origin stylesheets
            }
        }).join('\n');

        // Add the base styles to the iframe (handle possible overrides)
        styles = styles.replace("html { display: none; }", "");

        // Apply lato font family to all elements
        styles += `
            body {
                font-family: 'Lato', sans-serif;
            }
        `;
        
        // Write HTML to the iframe
        iframeDoc.open();
        iframeDoc.write(`
            <html>
                <head>
                    <title>${filename}</title>
                    <style>
                        ${styles}
                    </style>
                </head>
                <body>
                    ${title ? `<h4 style="margin-bottom: 20px">${title}</h4>` : ''}
                    <div>${printContent.innerHTML}</div> <!-- Ensure this is capturing the correct HTML -->
                </body>
            </html>
        `);
        iframeDoc.close(); // Close the document to finish loading

        // Wait for fonts to load before printing
        document.fonts.ready.then(() => {
            setTimeout(() => {
                iframeWindow.focus();
                iframeWindow.print();

                // Fallback to ensure iframe removal after a delay
                setTimeout(() => {
                    document.title = originalTitle;
                    if (document.body.contains(iframe)) {
                        document.body.removeChild(iframe);
                    }
                }, 1000);

                // Restore the original page title after printing
                iframeWindow.onafterprint = () => {
                    document.title = originalTitle;
                    if (document.body.contains(iframe)) {
                        document.body.removeChild(iframe);
                    }
                };
            }, 500); // Small delay to ensure iframe content is rendered before printing
        }).catch(() => {
            console.error('Fonts failed to load');
            iframeWindow.focus();
            iframeWindow.print();
        });
    } else {
        console.error('Failed to access iframe document');
        document.body.removeChild(iframe);
    }
};
