import React, { useEffect, useState } from 'react';
import Vendor from 'data/Vendor';
import { useAPI } from 'lib/API';
import { downloadDocument, getCompanyDocuments } from 'requests/documents';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import { useAuth } from 'lib/Auth';
import Page from 'components/UI/Page';
import MatTable from 'components/MaterialTable/MatTable';
import initialDocumentsColumns from 'constants/companyDocumentsColumns'
import { mapCompanyDocumentsGridColumns } from 'utils/gridColumnMappings';
import { isVendor } from 'data/User';
import {
  applySortFilter,
  handleOnSortChange
} from 'utils/grid';
import { companyDocumentOptions } from 'constants/documentOptions';
import FlexboxContainer from 'components/UI/FlexboxContainer';

interface Props {
  companyId: string;
  selectedVendor?: Vendor;
  page?: string;
}

const CompanyDocumentsList = ({
  selectedVendor,
  companyId,
  page,
}: Props) => {
  const { user } = useAuth();
  const isVendorUser = isVendor(user!);
  const [sortBy, setSortBy] = useState(initialDocumentsColumns.find(c => c.defaultSort !== undefined));
  const [currentPage, setCurrentPage] = useState(page ? parseInt(page) : 1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [downloadedDocument, setDownloadedDocument] = useState<any>();
  const [selectedOption, setSelectedOption] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [companyDocuments, setCompanyDocuments] = useState<any>({
    page: 1,
    totalPages: 1,
    size: 1,
    totalItems: 1,
    documents: []
  });

  const calculatePages = (documentsCount: number, pageSize: number) => {
    const pagesCount = Math.ceil(documentsCount / pageSize);

    return (pagesCount === 0) ? 1 : pagesCount;
  };

  const applyPaginationFilter = (companyDocuments: any[], pageIndex: number, pageSize: number) => {
    return companyDocuments.slice((pageIndex - 1) * pageSize, pageIndex * pageSize);
  };

  const onSortChange = (index: number, direction: string) => handleOnSortChange(index, direction, initialDocumentsColumns, setSortBy);

  const onSizeChange = (newSize: number) => {
    setItemsPerPage(newSize);
    setCurrentPage(1)
  }

  const getDocumentsForCompanyReq = useAPI({
    deferFn: getCompanyDocuments,
    onResolve: result => {
      setCompanyDocuments(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const downloadDocumentReq = useAPI({
    deferFn: downloadDocument,
    onResolve: result => {
      setDownloadedDocument(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    if (isVendorUser){
      getDocumentsForCompanyReq.run(companyId, 1, itemsPerPage, 0, selectedVendor?.id, selectedVendor?.code, user?.email);
    } else {
      getDocumentsForCompanyReq.run(companyId, 1, itemsPerPage, 0, null, selectedVendor?.code)
    }
  }, []);

  const mappedColumns = mapCompanyDocumentsGridColumns(initialDocumentsColumns, sortBy);
  const companiesFromSort = applySortFilter(companyDocuments.documents, sortBy);
  const companyDocumentsFromPagination = applyPaginationFilter(companiesFromSort, currentPage, itemsPerPage);
  const pagesCount = calculatePages(companyDocuments.documents.length, itemsPerPage);

  const download = (doc: any) => {
    const a = document.createElement('a');

    a.style.display = 'none';
    document.body.appendChild(a);

    const url = window.URL.createObjectURL(doc);

    a.href = url;
    a.download = documentName;
    a.click();

    window.URL.revokeObjectURL(url);
  };

  const showInOtherTab = (doc: any) => {
    const url = window.URL.createObjectURL(doc);
    window.open(url, '_blank');
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (downloadedDocument && downloadedDocument.document) {
      if (selectedOption === 'Preview') {
        if (canBePreviewed(downloadedDocument.extension)) {
          showInOtherTab(downloadedDocument.document);
        } else {
          download(downloadedDocument.document);
        }
      } else if (selectedOption === 'Download') {
        download(downloadedDocument.document);
      }

      setSelectedOption('');
    }
  }, [selectedOption, downloadedDocument]);

  const canBePreviewed: (ext: string) => boolean = (ext: string) => {
    return ['.jpg', '.png', '.pdf', '.jpeg', '.tiff'].includes(ext);
  };

  const onSelect = (data: { name: string, tableData: { id: number }}, id: number) => {
    const selectedOption = companyDocumentOptions.find(item => item.id === id);

    if (selectedOption) {
      setSelectedOption(selectedOption.title);
      setDocumentName(data.name);

      downloadDocumentReq.run(
        companyDocuments.documents[data.tableData.id].id,
        companyDocuments.documents[data.tableData.id].url
      );
    }
  }

  return (
    <Page padding='0'>
      <FlexboxContainer>
      <MatTable
        columns={mappedColumns}
        data={companyDocumentsFromPagination}
        isLoading={getDocumentsForCompanyReq.isLoading}
        toolbar
        overrideToolbar
        onOrderChange={onSortChange}
        columnsButton
        actionsColumnIndex={-1}
        actionMenu={companyDocumentOptions.map(item => ({...item, callBack: onSelect }))}
        customPagination={{
          itemsPerPage,
          setItemsPerPage: onSizeChange,
          currentPage,
          setCurrentPage,
          totalPages: pagesCount,
          shouldRedirect: false,
          baseRoute: ''
        }}
      />
      </FlexboxContainer>
    </Page>
    );
};

export default CompanyDocumentsList;
