import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { navigate } from 'hookrouter';
import { MailOutline } from '@material-ui/icons';
import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, FontWeight } from 'lib/styles';
import { useAuth } from 'lib/Auth';
import { useAPI } from 'lib/API';
import { validateEmail } from 'lib/validation';
import { saveUser } from 'requests/user';
import { Space } from 'styles/themeSpaces';
import { Main } from 'styles/themeColors';
import FlexboxContainer from 'components/UI/FlexboxContainer';

const ButtonsContainer = styled.div`
  margin-top: 20px;
  display: flex;
`;

const InputHelperErrorMessage = styled.div<any>`
  color: ${Main.error};
  font-size: 15px;
`;

const LabelText = styled.label`
  color: ${Color.black};
  display: block;
  font-weight: ${FontWeight.medium};
  text-align: left;
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

interface Props {
  className?: string;
};

const UserAccount = ({ className }: Props) => {
  const { updateUser, user } = useAuth();
  const [email, setEmail] = useState(user!.email);
  const [administratorEmail, setAdministratorEmail] = useState(user!.administratorEmail || '');
  const [name, setName] = useState(user!.name);
  const [isAdministratorEmailValid, setIsAdministratorEmailValid] = useState(true);
  const [showAccountModal, setShowAccountModal] = useState(false);

  const userSaveReq = useAPI({
    deferFn: saveUser,
    onResolve: () => {
      if (user) {
        updateUser({ ...user, email, name, administratorEmail });

        renderToast(toast.TYPE.SUCCESS, 'User information saved.');
      }
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const saveUserAccountSettings = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    userSaveReq.run({ ...user, email, name, administratorEmail });
  };

  const onConfirmCancelModalClick = () => {
    setShowAccountModal(false);
    navigate('/');
  };

  const confirmCancelModal = (
    <Modal
      showModal={showAccountModal}
      onClose={() => setShowAccountModal(false)}
      onClick={onConfirmCancelModalClick}
      title="Are you sure you want to cancel account update?"
      primaryButtonText="Confirm cancel"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          If you leave this page, all unsaved changes will be lost.
        </Span>
      </FlexboxContainer>
    </Modal>
  );

  const validateAdministratorEmail = (value: string) => {
    setAdministratorEmail(value);
    const valid = validateEmail(value);
    setIsAdministratorEmailValid(valid);
  };

  const endIcon = <MailOutline style={{ color: Color.shuttleGray, marginRight: '10px' }} />

  return (
    <div className={className}>
      {confirmCancelModal}
      <h2>Account</h2>
      <Input
        id="name-id"
        labelName={user!.role === 'Vendor' ? 'Organization name' : 'Name'}
        onChange={(e) => setName(e.target.value)}
        value={name}
      />
      <Input id="email-id" labelName="Email" disabled={true} onChange={(e) => setEmail(e.target.value)} value={email} />
      <LabelText htmlFor="adminEmail">Administrator email</LabelText>
      <FlexboxContainer displayFlex='flex'>
        <Input
          id="adminEmail"
          placeholder="Enter administrator email address"
          onChange={e => validateAdministratorEmail(e.target.value)}
          value={administratorEmail}
          type="email"
          endIcon={endIcon}
        />
      </FlexboxContainer>
      {isAdministratorEmailValid ? null : <InputHelperErrorMessage>Please enter a valid email address</InputHelperErrorMessage>}
      <ButtonsContainer>
        <Button disabled={userSaveReq.isLoading} margin="0 10px 0 0" isGhost color={Main.accent} onClick={() => setShowAccountModal(true)}>
          Cancel
        </Button>
        <Button disabled={userSaveReq.isLoading || !isAdministratorEmailValid} onClick={saveUserAccountSettings} >
          Save changes
        </Button>
      </ButtonsContainer>
    </div>
  );
};

export default UserAccount;
