import React from 'react';
import styled, { css } from 'styled-components';
import { FontSize, FontWeight, Percent } from 'lib/styles';
import { Text } from 'styles/themeColors';

const Input = styled.textarea<any>`
  border: none;
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${FontWeight.bold};
  overflow: hidden;
  resize: none;
  width: ${Percent.full};

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 64px;
    line-height: 1;
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    font-size: 64px;
    line-height: 1;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    font-size: 64px;
    line-height: 1;
  }

  &:focus {
    outline: none;
  }

  ${({ disabledBgColor }) => disabledBgColor && css`
    &:disabled {
      background-color: ${disabledBgColor};
    }
  `}

  ${({ disabledColor }) => disabledColor && css`
    &:disabled {
      color: ${disabledColor};
    }
  `}
`;

const StyledText = styled.span`
  font-size: ${FontSize.small};
  font-weight: ${FontWeight.bold};
  letter-spacing: 1px;
  color: ${({color}) => color};
`;

interface Props {
  className?: string;
  onChange: (value: string) => void;
  value: string;
  disabled?: boolean;
  disabledBgColor?: string;
  disabledColor?: string;
  placeholder: string;
  fontSize?: string;
  entityId?: string;
  idLabel?: string;
  showDelimiter?: boolean;
};

const EditorNameInput = ({
  className,
  onChange,
  value,
  disabled,
  disabledBgColor,
  disabledColor,
  placeholder,
  fontSize,
  entityId,
  idLabel,
  showDelimiter
}: Props) => {
  const inputRef = (el: any) => {
    if (el) {
      el.style.height = 0;
      el.style.height = `${el.scrollHeight}px`;
    }
  };

  const onInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;

    onChange(value.replace(/\n/g, ''));
  };

  return (
    <>
      <Input
        className={className}
        onChange={onInputChange}
        placeholder={placeholder}
        ref={inputRef}
        value={value}
        disabled={disabled}
        fontSize={fontSize}
        disabledBgColor={disabledBgColor}
        disabledColor={disabledColor}
      />
      {
        entityId ? (
          <>
            <StyledText color={Text.secondary}>{idLabel}</StyledText>
            { showDelimiter ? ': ' : ' ' }
            <StyledText color={Text.primary}>{entityId}</StyledText>
          </>
        ) : null
      }
    </>
  );
};

EditorNameInput.defaultProps = {
  fontSize: FontSize.enormous,
  showDelimiter: true
};

export default EditorNameInput;
