export const DELIVERY_OPTIONS = {
    SHIPPING_AND_PICKUP: 'SHIPPING_AND_PICKUP',
    SHIPPING_ONLY: 'SHIPPING_ONLY',
    PICKUP_ONLY: 'PICKUP_ONLY'
};

export const DELIVERY_OPTIONS_LABELS = {
    [DELIVERY_OPTIONS.SHIPPING_AND_PICKUP]: 'Shipping & pickup',
    [DELIVERY_OPTIONS.SHIPPING_ONLY]: 'Shipping only',
    [DELIVERY_OPTIONS.PICKUP_ONLY]: 'Pickup only'
};

export const USER_ROLE = {
    Admin: 'Admin',
    SuperAdmin: 'SuperAdmin',
    Vendor: 'Vendor',
    VendorAdmin: 'VendorAdmin'
}

export const WAREHOUSE_TYPE = {
    PURCHASE: 'PURCHASE',
    RENT: 'RENT'
}
