import React from 'react';
import styled, { css } from 'styled-components';
import ConsignmentEntry from 'data/ConsignmentEntry';
import { CANCELED, MANUFACTURING, PROCESSING } from 'constants/consignmentStates';
import { Background, Border, Main, Text } from 'styles/themeColors';
import { formatDateTime, currencySign } from 'utils/formatters';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { SHIPPING } from 'constants/deliveryModes';
import { IN_STOCK } from 'constants/consignmentEntry';
import { CONSIGNMENT_TYPES } from 'constants/order';
import OrderTag from 'components/UI/OrderTag';
import { getPillTheme } from 'utils/consignment';
import Pill from 'components/UI/Pill';

const TableFooterColumn = styled.span<any>`
  width: 200px;
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const TableFooterData = styled.span<any>`
  width: 104px;
  height: 20px;
  text-align: right;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const Surcharges = styled.span<any>`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${Text.primary};
`;

const TableColumn = styled.span<any>`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-height: 18px;
  width: 100%;
  color: ${Text.secondary};
  margin-bottom: 4px;

  ${({ color }) => color && css`
  color: ${color};
`}

${({ textDecoration }) => textDecoration && css`
  text-decoration: ${textDecoration};
`}

${({ fontSize }) => fontSize && css`
  font-size: ${fontSize};
`}

${({ fontWeight }) => fontWeight && css`
  font-weight: ${fontWeight};
`}

${({ deleted }) => deleted && css`
  opacity: 0.5;
`}

${({ width }) => width && css`
  width: ${width};
`}
`;

const mapLabelForEntry = (type: string) => {
  switch (type) {
    case PROCESSING:
      return 'In stock';
    case MANUFACTURING:
      return 'Manufacturing';
    case CANCELED:
      return 'Canceled';
    default:
      return '';
  }
}


interface Props {
  consignmentEntries: ConsignmentEntry[];
  isPreparationStep?: boolean;
  totalDeliveryCost?: number;
  totalPriceWithTax?: number;
  state: string;
  showSummary?: boolean;
  subTotal?: number;
  deliveryDate?: string;
  isRental?: boolean;
  consignmentId?: string;
  deliveryTax?: number;
  deliveryCost?: number;
  orderTotalCost?: number;
};

// Styled Components for consistent styling
const ProductContainer = styled.div`
  display: flex;
  gap: 14px;
  align-items: flex-start;
`;

const ProductImage = styled.img`
  max-height: 96px;
  max-width: 96px;
  border: 1px solid #e1e6eb;
`;

const ProductDetails = styled.div`
  font-size: 14px;
  color: ${Text.primary};
  text-align: left;
  line-height: 1.5;
  opacity: 0.8;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 16px;
`;

const TableHeader = styled.th`
  padding: 12px;
  background-color: #f8f9fa;
  font-weight: bold;
  font-size: 14px;
  text-align: left;
  border: 1px solid #dee2e6;
`;

const TableData = styled.td`
  padding: 12px;
  font-size: 14px;
  color: ${Text.primary};
  border: 1px solid #dee2e6;
  vertical-align: top;
`;

const Consignment = styled.span<any>`
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const splitColumnView = (key:string,value:any) =>  <FlexboxContainerComponent alignItems='center'>
    <TableColumn color={Text.primary} width={'100px'}>{key}</TableColumn>
    <TableColumn  color={Text.primary} width={'auto'}>{value}</TableColumn>  
</FlexboxContainerComponent>

const OrderPreviewItem = ({
  consignmentEntries,
  state,
  showSummary,
  deliveryDate,
  isRental,
  consignmentId,
  deliveryCost = 0,
  deliveryTax = 0,
  orderTotalCost = 0
}: Props) => {

  // Get Value from param for currency differentiate
  const queryParameters = new URLSearchParams(window.location.search);
  const country = queryParameters.get("country");
  let orderSubTotalPrice = 0;
  let totalTax = 0;
  let totalSurcharges = 0;

  const productColumn = (consignmentEntry: ConsignmentEntry) => <>
    <TableData style={{ width: '70%' }}>
      <ProductContainer>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <OrderTag type={isRental ? CONSIGNMENT_TYPES.RENT : CONSIGNMENT_TYPES.PURCHASE} margin={'0px 0px 10px 0px'} />
          <ProductImage
            src={consignmentEntry.productImages && consignmentEntry.productImages.length > 0 ? process.env.REACT_APP_MEDIA_URL! + consignmentEntry.productImages[0].path : ''}
            alt="Product"
          />
        </div>
        <ProductDetails>
          {/* Product name */}
          <div style={{ fontWeight: 'bold' }}>{consignmentEntry.productName}</div>

          {/* Product sku */}
        <div style={{ opacity: 0.7 }}>
          {
            splitColumnView('SKU', consignmentEntry.productSKU)
          }
        </div>

          {/* Product attributes */}
          {consignmentEntry.selectableAttributes?.map((attr) => {
            const atrValue = consignmentEntry.attributeValues.find(val => val.attributeId === attr.id);

            return (
              <div key={attr.id} style={{ opacity: 0.7 }}>
                {
                 splitColumnView(attr.name, atrValue ? atrValue.name : '')
                }
              </div>
            )
          })}

          {/* Pricebooks */}
          {
            consignmentEntry.pricebookName && consignmentEntry.pricebookId &&
            <div style={{ opacity: 0.7 }}>
              {
                splitColumnView('Pricebook name / ID', `${consignmentEntry.pricebookName} / ${consignmentEntry?.priceBook?.PricebookVendorId}`)
              }
            </div>
          }

          {/* Delivery date */}
          <div>
            {
              splitColumnView(consignmentEntry.deliveryMode === SHIPPING ? "Est. delivery date" : "Est. pickup date", formatDateTime(deliveryDate, false, false))
            }
          </div>

          {/* Rental dates */}
          {consignmentEntry.rentalStartDate && consignmentEntry.rentalEndDate && (
            <div>
              {
                splitColumnView('Date range', formatDateTime(consignmentEntry.rentalStartDate, false, false) + " - " + formatDateTime(consignmentEntry.rentalEndDate, false, false))
              }
            </div>
          )}

        </ProductDetails>
      </ProductContainer>
    </TableData>
  </>

  const quantityColumn = (consignmentEntry: ConsignmentEntry) => <>
    <TableData style={{ width: '10%' }}>{consignmentEntry?.quantity}</TableData>
  </>;

  const unitPriceColumn = (consignmentEntry: ConsignmentEntry) => <>
      {
      <TableData style={{ width: '10%' }}>{currencySign(country, consignmentEntry.basePrice !== consignmentEntry.discountedPrice ? consignmentEntry.discountedPrice : consignmentEntry.basePrice)}</TableData>
      }
  </>;


  const subTotalColumn = (consignmentEntry: ConsignmentEntry) => {

    totalTax += consignmentEntry?.taxAmount || 0;
    totalSurcharges += consignmentEntry?.surcharge || 0;
    orderSubTotalPrice += consignmentEntry.totalPrice || 0;

    return <>
        <TableData style={{ width: '10%' }}>
          {currencySign(country, consignmentEntry.totalPrice)}
        {
          consignmentEntry.surcharge > 0
            ? <><br/><span>+{currencySign(country, consignmentEntry.surcharge)} <br /> surcharge</span></>
            : <span>&nbsp;</span>
        }
          </TableData>
      </>
  };

  const rows = consignmentEntries.map((consignmentEntry) => {
   
    return (
      <tr key={consignmentEntry.id}>
        {productColumn(consignmentEntry)}
        {quantityColumn(consignmentEntry)}
        {unitPriceColumn(consignmentEntry)}
        {subTotalColumn(consignmentEntry)}
      </tr>
    );
  });

  const orderTotal = showSummary && (
    <FlexboxContainerComponent flexDirection="column" alignItems='flex-end' width='100%' padding='16px'>
      <FlexboxContainerComponent padding='16px'>
        <TableFooterColumn>Subtotal</TableFooterColumn>
        <TableFooterData> {orderSubTotalPrice && currencySign(country, orderSubTotalPrice)}</TableFooterData>
      </FlexboxContainerComponent>
      {
        totalSurcharges && totalSurcharges > 0
          ? (
            <FlexboxContainerComponent padding='16px'>
              <Surcharges>includes surcharges</Surcharges>
              <TableFooterData> +{totalSurcharges && currencySign(country, totalSurcharges)}</TableFooterData>
            </FlexboxContainerComponent>
          )
          : null
      }
      {
        deliveryCost ? (
          <FlexboxContainerComponent padding='16px' borderTop={`1px solid ${Border.light}`} borderBottom={`1px solid ${Border.light}`}>
            <TableFooterColumn fontSize="15px">Shipping</TableFooterColumn>
            <TableFooterData fontSize="15px">{currencySign(country, deliveryCost)}</TableFooterData>
          </FlexboxContainerComponent>
        ) : null
      }
      {
        totalTax ? (
          <FlexboxContainerComponent borderTop='1px solid #E1E6EB' padding='16px'>
            <TableFooterColumn>Tax</TableFooterColumn>
            <TableFooterData> {totalTax && currencySign(country, totalTax + deliveryTax)}</TableFooterData>
          </FlexboxContainerComponent>
        ) : null
      }
      <FlexboxContainerComponent borderTop={`2px solid ${Background.navy}`} padding='16px'>
        <TableFooterColumn>Order total</TableFooterColumn>
        <TableFooterData> {currencySign(country, orderTotalCost)}</TableFooterData>
      </FlexboxContainerComponent>
    </FlexboxContainerComponent>
  )

  const pillTheme = getPillTheme(state);

  const pill = <Pill margin="10px 0px" theme={pillTheme} bgColor={pillTheme} size="xsPlus" padding="4px">{mapLabelForEntry(state)}</Pill>;

  return (
    <>
      <FlexboxContainerComponent flexDirection='column' gap='10px' margin='0px'>
        <FlexboxContainerComponent flexDirection='row' gap='10px' margin='0px' alignItems='center'>
          <Consignment>Order # {consignmentId?.slice(4)}</Consignment>
          {pill}
          {state !== CANCELED && <FlexboxContainerComponent alignItems='center'>
            <TableColumn color={Text.primary} width={'134px'}>{consignmentEntries[0]?.deliveryMode === SHIPPING ? "Est. delivery date" : "Est. pickup date"}</TableColumn>
            <TableColumn color={Text.primary} width={'134px'}>{formatDateTime(deliveryDate, false, false)}</TableColumn>
          </FlexboxContainerComponent>
          }
        </FlexboxContainerComponent>

        {splitColumnView('Start rent date', formatDateTime(consignmentEntries[0]?.rentalStartDate, false, false))}
        {splitColumnView('End rent date', formatDateTime(consignmentEntries[0]?.rentalEndDate, false, false))}
       
      </FlexboxContainerComponent>

      <p style={{ padding: 0 }}>{'Order items'}</p>

      <Table style={{padding: 0, margin: 0}}>
        <thead>
          <tr>
            <TableHeader style={{ width: '70%' }}>Product</TableHeader>
            <TableHeader style={{ width: '10%' }}>Quantity</TableHeader>
            <TableHeader style={{ width: '10%' }}>Unit price</TableHeader>
            <TableHeader style={{ width: '10%' }}>Subtotal</TableHeader>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      {orderTotal}
    </>
  );
};

OrderPreviewItem.defaultProps = {
  showSummary: true
}

export default OrderPreviewItem;
