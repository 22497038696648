import React from 'react';
import {
  STEPS,
  TEXT_LABELS,
  XLS_TEXT_CONSTANTS,
} from '../../constants/productVariantImport';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import styled from 'styled-components';
import Icon from 'view/Icon';
import { Color } from 'lib/styles';
import { Main } from 'styles/themeColors';
import { UploadingStatus } from './ImportCampaignModal';
import Link from '@material-ui/core/Link';
import { campaignFileUrl } from 'utils/campaign';

const StyledFileDownloadIcon = styled(Icon).attrs({
  name: 'FileDownloadIconWhite',
  size: 20,
})`
  color: white !important;
  margin: 0px 8px 10px 0px;
`;

const DownloadReport = styled.a`
  display: flex;
  height: 36px;
  background-color: black !important;
  color: white !important;
  border-color: #666c73 !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;

const ButtonIcon = styled(Icon).attrs({ size: 16 })`
  color: #666c73;
`;
const DownloadTemplate = styled.div`
  display: flex;
  height: 36px;
  background-color: transparent !important;
  color: #666c73 !important;
  border-color: #666c73 !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;

interface Props {
  step: number;
  showNextButton: Boolean;
  disableNextButton: boolean;
  gotoNextStep: () => void;
  uploadSuccess: Boolean;
  uploadingStatus: UploadingStatus | undefined;
  handleClear: ()=> void;
}

const FooterComponent = ({
  step,
  showNextButton,
  disableNextButton,
  gotoNextStep,
  uploadSuccess,
  uploadingStatus,
  handleClear
}: Props) => {
  return (
    <FlexboxContainerComponent
      padding="24px 0px"
      justifyContent={step === STEPS.COMPLETION ? 'space-between' : 'center'}
    >
      {
        step === STEPS.COMPLETION && 
         ( <Button
          id="modal-main-button"
          bgColor={Main.white}
          borderColor={Color.black}
          color={Color.black}
          onClick={() => {
            handleClear()
          }}
        >
           {TEXT_LABELS.REUPLOAD}
        </Button>
          )
      }
      {showNextButton && step === STEPS.UPLOADING ? (
        <Button
          id="modal-main-button"
          bgColor={disableNextButton ? '#E9ECEF' : '#FC780B'}
          borderColor={disableNextButton ? '#E9ECEF' : '#FC780B'}
          color={disableNextButton ? '#BFC2C5' : '#FFFFFF'}
          onClick={gotoNextStep}
          disabled={disableNextButton}
        >
          {TEXT_LABELS.NEXT}
        </Button>
      ) : !uploadSuccess && step === STEPS.UPLOADING ? (
        <Link href='/Campaign Template.xlsx' style={{textDecoration: 'none'}}>
            <DownloadTemplate>
                <FlexboxContainerComponent>
                    <ButtonIcon name="FileDownloadIcon" />
                    <FlexboxContainerComponent>
                        {XLS_TEXT_CONSTANTS.DOWNLOAD_XLXS}
                    </FlexboxContainerComponent>
                </FlexboxContainerComponent>
            </DownloadTemplate>
      </Link>
      ) : !uploadingStatus?.passedCount && step === STEPS.COMPLETION ? (
        <DownloadReport
          href={campaignFileUrl(uploadingStatus?.validatedFileLocation as string)}
        >
          <StyledFileDownloadIcon />
          {TEXT_LABELS.DONWLOAD_ERROR_LOGS}
        </DownloadReport>
      ) : step === STEPS.COMPLETION && uploadingStatus?.passedCount  ? (
        <DownloadReport
          href={campaignFileUrl(uploadingStatus?.validatedFileLocation as string)}
        >
          <StyledFileDownloadIcon />
          {TEXT_LABELS.DOWNLOAD_REPORT}
        </DownloadReport>
      ) : (
        <></>
      )}
    </FlexboxContainerComponent>
  );
}

export default React.memo(FooterComponent);