import React, { Fragment } from 'react';

import { Heading2, Heading3, Heading5, Paragraph, CardCallout, Img, List, ListItem } from 'OnboardingComponents/Elements';

import websiteBannerEx from 'images/onboardingAssets/website-banner-example.jpg';
import carouselEx from 'images/onboardingAssets/carousel-example.jpg';
import socialMediaEx from 'images/onboardingAssets/social-media-example.jpg';
import newsletterEx from 'images/onboardingAssets/newsletter-example.jpg';

const ProductImagery = () => {
  return (
    <Fragment>
      <Heading2 id="product-imagery">Product imagery</Heading2>
      <Paragraph>A vital part of your product’s description page is its imagery. On The OFMP, imagery tends to be simple, sleek, and consistent in its design. We ask that our seller partners strive to maintain our user experience by taking the following into consideration:</Paragraph>
      <Heading3>Images</Heading3>
      <List>
        <ListItem>Use a white background</ListItem>
        <ListItem>Show the products from various angles</ListItem>
        <ListItem>Show the products in relation to humans wherever possible – it gives a sense of sizing, while also adding a human touch</ListItem>
        <ListItem>Include images that are zoomed in on your product’s key features</ListItem>
        <ListItem>Provide contextual images and diagrams that help users understand where and how the product fits in relation to others</ListItem>
      </List>
      <Heading3>Videos</Heading3>
      <List>
        <ListItem>Consider adding a 360° product view</ListItem>
        <ListItem>Include contextual videos explaining how, where, and why the product is us</ListItem>
      </List>
      <Paragraph bold>Please note: high-resolution (1920×1080 pixels, 300 DPI) images and videos are required for the best user experience.</Paragraph>
      <Paragraph>High resolution imagery is not just for the customers’ benefit, but also for ours. As we promote your products across our channels, we’ll need high quality visuals that we can pull from to get the best engagement from followers across the board. It’s important to consider what other channels your product images might appear on (apart from just the dedicated product description page). For example:</Paragraph>
      <CardCallout>
        <Heading5>Website homepage banners:</Heading5>
        <Img src={websiteBannerEx}></Img>
      </CardCallout>
      <CardCallout>
        <Heading5>Website homepage carousels:</Heading5>
        <Img src={carouselEx}></Img>
      </CardCallout>
      <CardCallout>
        <Heading5>Social media posts:</Heading5>
        <Img src={socialMediaEx}></Img>
      </CardCallout>
      <CardCallout>
        <Heading5>Newsletter features:</Heading5>
        <Img src={newsletterEx}></Img>
      </CardCallout>
    </Fragment>
  );
};

export default ProductImagery;
