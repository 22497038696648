import { Text } from 'styles/themeColors';
import { capitalize, formatDateTime } from '../../../utils/formatters';
import { Column } from 'material-table';

const ProductColumns:Column<any>[] = [
  {
    title: 'Image',
    field: 'imagePath',
    width: '62px',
    sorting: false,
    align: 'left',
    cellStyle: { padding: '5px 12px' },
  },
  {
    title: 'Status',
    field: 'status',
    headerStyle: { color: Text.primary },
    cellStyle: { textTransform: 'capitalize', padding: '5px' },
    width: '7%',
    render: (rowData: any) => capitalize(rowData.status),
  },
  {
    title: 'Name',
    field: 'name',
    headerStyle: { color: Text.primary },
    removable: false,
    width: '20%',
    cellStyle: { padding: '5px' },
  },
  {
    title: 'Brands',
    field: 'brand',
    headerStyle: { color: Text.primary },
    cellStyle: { padding: '5px' },
  },
  {
    title: 'Inventory',
    field: 'inventory',
    headerStyle: { color: Text.primary },
    cellStyle: { padding: '5px' },
  },
  {
    title: 'Variants',
    field: 'variantsCount',
    headerStyle: { color: Text.primary },
    cellStyle: { padding: '5px' },
  },
  {
    title: 'Warehouses',
    field: 'warehousesCount',
    headerStyle: { color: Text.primary },
    cellStyle: { padding: '5px' },
  },
  {
    title: 'Category',
    field: 'category',
    headerStyle: { color: Text.primary },
    cellStyle: { padding: '5px' },
  },
  {
    title: 'Publish Date',
    field: 'publishedOn',
    type: 'datetime',
    headerStyle: { color: Text.primary },
    render: (rowData: any) =>
      rowData.publishedOn ? formatDateTime(rowData.publishedOn, true) : 'N/A',
    cellStyle: { padding: '5px' },
  },
  {
    title: 'Actions',
    field: 'actions',
    removable: false,
    cellStyle: { padding: '5px' },
    sorting: false,
    width: '120px'
  }
];

export default ProductColumns;
