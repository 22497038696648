import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Size } from 'lib/styles';
import { Background, Border, Main, Text } from 'styles/themeColors';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Button from 'components/UI/Button';
import DocumentCard from 'components/Consignments/DocumentCard';
import { formatDateTime } from 'utils/formatters';
import { useAPI } from 'lib/API';
import { sendEmailToCustomer } from 'requests/documents';
import { renderToast } from 'components/UI/ToastNotification';
import { toast } from 'react-toastify';
import { Email } from '@material-ui/icons';

const Container = styled.div`
  padding: ${Size.tiny} 0;
  background: ${Background.white};
  border-radius: 6px;
  margin: 12px 0px;
  border: 1px solid ${Border.light};
`;

const EmailButton = styled(Button).attrs({ size: "m" })`
  border-color: #c1c9d1 !important;
  color: #666C73 !important;
`;

const CurrentDate = styled.span`
  width: 100%;
`;

const CurrentTime = styled.span`
  width: 100%;
  font-size: 12px;
  color: ${Text.secondary};
  line-height: 16px;
`;

const DocumentsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 4px;
`;

const Comment = styled.div`
  margin-top: 16px;
`;

interface Props {
  className?: string;
  cardForCustomer?: boolean;
  cards: any[];
  date?: string;
  comment?: string;
  consignmentId?: string;
  createdBy?: string;
  documentId?: number;
  customerEmail: string;
  orderNumber: string;
};

export default function DocumentCardGroup({
  className,
  cardForCustomer,
  cards,
  date,
  comment,
  createdBy,
  consignmentId,
  documentId,
  customerEmail,
  orderNumber
}: Props) {

  const [emailToCustomerBody, setEmailToCustomerBody] = useState<any>({
    createdBy: '',
    orderNumber: 0,
    attachments: []
  })
  const [sendEmailData, setSendEmailData] = useState(false);

  const sendEmailToCustomerReq = useAPI({
    deferFn: sendEmailToCustomer,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Email has been sent to customer');
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const documents = cards.map(card => ({
    id: card.id,
    name: card.name,
    mimeType: card.mimeType,
    url: card.url
  }));

  const sendEmail = () => {
    setEmailToCustomerBody({
      createdBy: createdBy,
      orderNumber: orderNumber,
      attachments: [{
        comment: comment,
        creationTime: date,
        documents: documents
      }]
    });

    setSendEmailData(true)
  }

  useEffect(() => {
    if (sendEmailData) {
      sendEmailToCustomerReq.run(customerEmail, emailToCustomerBody)
    }

    setSendEmailData(false);
  }, [emailToCustomerBody]);

  return (
    <Container
      className={className}
    >
      <FlexboxContainer flexDirection='column' width='100%'>
        <FlexboxContainer margin='16px'>
          {
            cardForCustomer ? (
              <FlexboxContainer justify-content="space-between" flexDirection='column' minWidth='105px'>
                <FlexboxContainer>
                  <CurrentDate>{formatDateTime(date, false)}</CurrentDate>
                </FlexboxContainer>
                <FlexboxContainer>
                  <CurrentTime>{formatDateTime(date, true).split(', ')[1]}</CurrentTime>
                </FlexboxContainer>
              </FlexboxContainer>
            ) : null
          }
          <FlexboxContainer flexDirection='column' margin='margin: 0 0 0 8px' width='100%'>
            <DocumentsContainer>
              {
                cards.map(card => (
                  <DocumentCard
                    orderNumber={orderNumber}
                    createdBy={createdBy}
                    date={date}
                    comment={comment}
                    customerEmail={customerEmail}
                    card={card}
                    cardForCustomer={cardForCustomer}
                    key={card.id}
                  />
                ))
              }
            </DocumentsContainer>
            <Comment className="base">{comment}</Comment>
          </FlexboxContainer>
        </FlexboxContainer>
        {
          cardForCustomer ? (
            <FlexboxContainer justifyContent='flex-end' margin='16px 30px 16px 0'>
              <EmailButton isGhost color={Main.accent} onClick={sendEmail}>
                <Email className='material-symbols-rounded filled' style={{ marginRight: 8 }} />
                Email to customer
              </EmailButton>
            </FlexboxContainer>
          ) : null
        }
      </FlexboxContainer>
    </Container>
  );
};
