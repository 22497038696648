import Attribute, { AttributeValue } from 'data/Attribute';

export const updateAttributeList = (vendorAttributes: Attribute[], newAttribute: Attribute) => {
  const indexOfAttribute = vendorAttributes.indexOf(vendorAttributes.find(v => v.code === newAttribute.code)!);
  let newAttributes = [...vendorAttributes];
  newAttributes[indexOfAttribute] = newAttribute;

  return newAttributes;
};

export const mergeAttributes = (attributesToMerge: Attribute[], code: string, name: string): Attribute => {
  const mergeInIndex = attributesToMerge.findIndex(el => el.code === code);
  return {
    id: attributesToMerge[mergeInIndex].id,
    code,
    name,
    order: attributesToMerge[0].order,
    values: attributesToMerge.reduce((allValues: AttributeValue[], attribute) => {
      allValues = allValues.concat(attribute.values.map((val, i) => {
        return {
          id: val.id,
          code: replaceAttributeValueCode(attribute.code, code, val.code),
          name: val.name,
          order: i + 1,
          attributeId: attribute.id
        }
      }));
      return allValues
    }, [])
  }
};

export const replaceAttributeValueCode = (oldParentCode: string, newParentCode: string, valueCode: string) => {
  return valueCode.replace(oldParentCode, newParentCode);
};
