import Input from 'components/UI/Input';
import React from 'react';

interface Props {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  label: string;
  onChange?: (value: string) => void;
  onEnter?: (value: string) => void;
  placeholder?: string;
  value: string;
}

export default function PasswordInput({
  autoFocus,
  className,
  disabled,
  label,
  onChange,
  onEnter,
  placeholder,
  value
}: Props) {
  return (
    <Input
      id='password-input-id'
      className={className}
      disabled={disabled}
      labelName={label}
      onInputChange={onChange}
      onEnter={onEnter}
      placeholder={placeholder}
      type="password"
      value={value}
    />
  );
}

PasswordInput.defaultProps = {
  autoFocus: false,
  disabled: false
};
