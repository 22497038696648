export const READY = 'READY';
export const CHECKING_CREDIT = 'CHECKING_CREDIT';
export const ORDER_SENT_TO_OEM = 'ORDER_SENT_TO_OEM';
export const PROCESSING = 'PROCESSING';
export const BUYER_DELAY_CANCEL = 'BUYER_DELAY_CANCEL';
export const SPLIT_CONSIGNMENT = 'SPLIT_CONSIGNMENT';
export const MANUFACTURING = 'MANUFACTURING';
export const ON_HOLD = 'ON_HOLD';
export const CANCELING = 'CANCELING';
export const PACKING = 'PACKING';
export const PROCESSING_PACKING = 'PROCESSING_PACKING';
export const SHIPPED_READY_FOR_PICKUP = 'SHIPPED_READY_FOR_PICKUP';
export const DELIVERED_PICKED_UP = 'DELIVERED_PICKED_UP';
export const DELAYED = 'DELAYED';
export const CANCELED = 'CANCELED';
export const AWAITS_SHIPPING = 'AWAITS_SHIPPING';
export const AWAITS_SHIPPING_APPROVAL = 'AWAITS_SHIPPING_APPROVAL';
export const DOW_JONES_FAILED = 'DOW_JONES_FAILED';
export const NEW = 'NEW';
export const READY_FOR_PICKUP = 'READY_FOR_PICKUP';
export const READY_TO_SHIP = 'READY_TO_SHIP';
export const SHIPPED = 'SHIPPED';
export const PICKED_UP = 'PICKED_UP';
export const BUYER_FREIGHT_COST_CANCEL = 'BUYER_FREIGHT_COST_CANCEL';
export const BUYER_FREIGHT_COST_TIMEOUT = 'BUYER_FREIGHT_COST_TIMEOUT';
export const BUYER_GENERAL_CANCEL = 'BUYER_GENERAL_CANCEL';
export const OFMP_CANCEL = 'OFMP_CANCEL';
export const DJ_CANCEL = 'DJ_CANCEL';
export const VENDOR_REJECT = 'VENDOR_REJECT';
export const VENDOR_FREIGHT_COST_CANCEL = 'VENDOR_FREIGHT_COST_CANCEL';
export const VENDOR_CREDIT_LIMIT_CANCEL = 'VENDOR_CREDIT_LIMIT_CANCEL';
export const SYSTEM_CREDIT_CARD_FAILURE = 'SYSTEM_CREDIT_CARD_FAILURE';
export const APPROVED = 'APPROVED';
export const REJECTED = 'REJECTED';
export const RESOLVED = 'RESOLVED';
export const TICKET_IN_PROGRESS = 'TICKET_IN_PROGRESS'; // Ticket is in progress

export const CANCELATION_REASON_MESSAGES = {
    BUYER_DELAY_CANCEL: 'Order cancelation due to delayed delivery',
    BUYER_GENERAL_CANCEL: 'Order cancellation due to customer request',
    BUYER_FREIGHT_COST_CANCEL: 'Shipping cost was rejected by the buyer. Order is canceled.',
    BUYER_FREIGHT_COST_TIMEOUT: 'Shipping cost approval timeout. Buyer failed to approve provided shipping cost in 5 days. Order was canceled automatically.',
    OFMP_CANCEL: 'This order was canceled by OFMP',
    DJ_CANCEL: 'This customer has failed OFMP compliance check. Order is canceled.',
    VENDOR_REJECT: 'Order cancelation due to vendor request',
    VENDOR_FREIGHT_COST_CANCEL: 'Order cancelation due to vendor request',
    VENDOR_CREDIT_LIMIT_CANCEL: 'Order cancelation due to insufficient credit.',
    SYSTEM_CREDIT_CARD_FAILURE: 'Order cancelation due to credit card failure.'
}

export const CANCEL_CONSIGNMENT_MODAL_TEXT = {
    TITLE: 'Canceling consignment',
    LABEL: `'Please provide a cancelation reason which will be sent as a part of the “Cancelation notification” email to both Customer and Vendor'`,
    PLACEHOLDER: 'Unfortunately, we are forced to cancel these items due to...'
}

export const RENT_UPDATED = 'RENT_UPDATED';
export const RENT_IN_PROGRESS = 'RENT_IN_PROGRESS';
export const MODIFIED_BY_VENDOR = 'MODIFIED_BY_VENDOR';
export const MODIFIED_BY_CUSTOMER = 'MODIFIED_BY_CUSTOMER';
export const RENT_RETURN_PENDING = 'RENT_RETURN_PENDING';
export const RENT_COMPLETED = 'RENT_COMPLETED';

export const HIDE_CANCEL_CONSIGNMENT_STATES = [SHIPPED, PICKED_UP, RENT_UPDATED, RENT_IN_PROGRESS, RENT_RETURN_PENDING, RENT_COMPLETED, TICKET_IN_PROGRESS, CANCELED, CANCELING, AWAITS_SHIPPING_APPROVAL, READY, AWAITS_SHIPPING];

export const HIDE_STATUS_ON_ORDER_HISTORY = [
    ORDER_SENT_TO_OEM,
    SPLIT_CONSIGNMENT,
    CANCELING,
    PROCESSING_PACKING,
    SHIPPED_READY_FOR_PICKUP,
    DELIVERED_PICKED_UP,
    DOW_JONES_FAILED,
    BUYER_FREIGHT_COST_CANCEL,
    BUYER_FREIGHT_COST_TIMEOUT,
    DJ_CANCEL,
    VENDOR_FREIGHT_COST_CANCEL,
    VENDOR_CREDIT_LIMIT_CANCEL,
    SYSTEM_CREDIT_CARD_FAILURE,
    RENT_UPDATED,
    READY,
  ]