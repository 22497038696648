import { useAPI } from '../lib/API';
import { useEffect, useState } from 'react';
import LaunchCompany from 'data/LaunchCompany';
import { getLaunchCompanies } from 'requests/campaigns';

export const useLaunchCompanies = () => {
    const [launchCompanies, setLaunchCompanies] = useState<LaunchCompany[]>([]);

    const launchCompaniesReq = useAPI({
        deferFn: getLaunchCompanies,
        onResolve: (result: any) => {
            setLaunchCompanies(result.data);
        },
        onReject: (err: any) => { },
    });

    useEffect(() => {
        launchCompaniesReq.run([]);
    }, []);

    return { launchCompanies,launchCompaniesReq };

}