import React from 'react';
import styled, { css } from 'styled-components';
import { Color } from 'lib/styles';

const Arrow = styled.div<any>`
  width: 0; 
  height: 0;
  margin: ${({ margin }) => margin};

    ${({ direction, borderWidth }) =>
      (direction === 'top' || direction === 'bottom') &&
      css`
        border-left: ${borderWidth}px solid transparent;
        border-right: ${borderWidth}px solid transparent;
      `}

    ${({ direction, borderWidth }) =>
      (direction === 'left' || direction === 'right') &&
      css`
        border-top: ${borderWidth}px solid transparent;
        border-bottom: ${borderWidth}px solid transparent;
      `}

    ${({ direction, borderWidth, color }) =>
      direction === 'top' &&
      css`
        border-bottom: ${borderWidth}px solid ${color};
      `}

    ${({ direction, borderWidth, color }) =>
      direction === 'bottom' &&
      css`
        border-top: ${borderWidth}px solid ${color};
      `}

    ${({ direction, borderWidth, color }) =>
      direction === 'left' &&
      css`
        border-right: ${borderWidth}px solid ${color};
      `}

    ${({ direction, borderWidth, color }) =>
      direction === 'right' &&
      css`
        border-left: ${borderWidth}px solid ${color};
      `}
`;

interface Props {
  color?: string;
  className?: string;
  direction: string;
  borderWidth?: number;
  margin?: string;
  onClick?: () => void;
}

export default function SortingArrow({
  color,
  className,
  direction,
  borderWidth,
  margin,
  onClick
}: Props) {
    const sharedProps = {
        color,
        direction,
        borderWidth,
        margin
    };

    return (
        <Arrow onClick={onClick} {...sharedProps} />
    );
}

SortingArrow.defaultProps = {
  bgColor: Color.black,
  borderWidth: 5,
  margin: '0',
};
