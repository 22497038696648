import { unique } from './filters';

type KeyedObject = Record<string, any>;

export function get(obj: any, defaultValue: any, ...keys: string[]): any {
  if (keys.length === 0) return obj;

  if (typeof obj === 'object' && keys[0] in obj) {
    return get(obj[keys[0]], defaultValue, ...keys.slice(1));
  }

  return defaultValue;
}

export function isShallowEqual(a: KeyedObject, b: KeyedObject): boolean {
  const keys = [...Object.keys(a), ...Object.keys(b)].filter(unique);
  return keys.every((key: string) => a[key] === b[key]);
}

export function omit<T extends object, U extends keyof T>(
  obj: T,
  keys: ReadonlyArray<U>
): Omit<T, U> {
  const newObj = { ...obj };
  const modified = keys.reduce((modified, key) => {
    if (newObj.hasOwnProperty(key)) {
      delete newObj[key];
      return true;
    }
    return modified;
  }, false);

  return modified ? newObj : obj;
}

export function pick<T extends object, U extends keyof T>(
  obj: T,
  keys: ReadonlyArray<U>
): Pick<T, U> {
  return keys.reduce(
    (newObj, key) => {
      newObj[key] = obj[key];
      return newObj;
    },
    {} as Pick<T, U>
  );
}

export function set<T extends object, U extends keyof T>(
  obj: T,
  key: U,
  value: T[U]
): T {
  return { ...obj, [key]: value };
}

export const trimObject = (obj: any) => {
  let newObj: any = {};

  Object.keys(obj).forEach(key => {
    newObj[key] = typeof obj[key] == 'string' ? obj[key].trim() : obj[key];
  });

  return newObj;
};