import React from 'react';

import ConfirmModal from 'components/UI/Modal';
import { useAuth } from '../../../lib/Auth';
import { toast } from 'react-toastify';
import { deleteDraftProducts } from '../../../requests/product';
import FlexboxContainer from '../../UI/FlexboxContainer';
import { renderToast } from '../../UI/ToastNotification';
import { useAPI } from '../../../lib/API';
import * as Prod from 'data/Product';
import { navigate } from 'hookrouter';
import ProductLocalization from '../ProductLocalization';
import { PRODUCT_STATUS, VALIDATION_STATUS } from 'constants/product';
import { Main } from 'styles/themeColors';

interface Props {
  showDeleteModal: boolean;
  onDelete: Function;
  selectedRows: any[];
  productId?: number;

  showUpdateModal: 'none' | 'update' | 'revise';
  onUpdate: Function;

  showPublishModal: boolean;
  setShowPublishModal: Function;
  setSelectedRows: Function;
  setReload: Function;
}

const ProductListingActions = ({
  showDeleteModal,
  onDelete,
  selectedRows,
  showUpdateModal,
  onUpdate,
  productId,
  showPublishModal,
  setShowPublishModal,
  setSelectedRows,
  setReload
}: Props) => {
  const { token } = useAuth();

  const onConfirmMessageClick = () => {
    if (showUpdateModal === 'update') {
      prodCloneReq.run(productId);
    } else if (showUpdateModal === 'revise') {
      prodUpdateReq.run([productId], PRODUCT_STATUS.DRAFT);
    }
  };

  const prodCloneReq = useAPI({
    deferFn: Prod.cloneProduct,
    onResolve: result => {
      renderToast(toast.TYPE.SUCCESS, ProductLocalization.success.clone);
      navigate(`/products/product-details/${result.id}`);
    },
    onReject: (err: Error & { code?: number }) => {
      if (err.code && err.code === 2.9) {
        renderToast(toast.TYPE.ERROR, ProductLocalization.errors.clone);
      } else {
        renderToast(toast.TYPE.ERROR, err.message);
      }
      onUpdate('none');
    }
  });

  const prodUpdateReq = useAPI({
    deferFn: Prod.updateProductsStatus,
    onResolve: result => {
      renderToast(toast.TYPE.SUCCESS, ProductLocalization.success.statusUpdate);
      navigate(`/products/product-details/${result.ids[0]}`);
    },
    onReject: err => {
      onUpdate('none');
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });


  const prodPublishedReq = useAPI({
    deferFn: Prod.updateProductsStatus,
    onResolve: result => {
      let type = toast.TYPE.SUCCESS;
      let message = ProductLocalization.success.statusUpdate;

      if(result.status === VALIDATION_STATUS.PARTIAL){
        type = toast.TYPE.WARNING;
        message = ProductLocalization.warning.materialApi;
      }else if(result.status === VALIDATION_STATUS.FAILED){
        type = toast.TYPE.ERROR;
        message = ProductLocalization.errors.materialApi;
      }

      renderToast(type, message);
      setSelectedRows([]);
      setShowPublishModal(false);
      setReload(true);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
      setSelectedRows([]);
      setShowPublishModal(false);
    }
  });


  const handleProductPublish = () => {
    const selectedIds = selectedRows.map((row) => row.id)
    prodPublishedReq.run(selectedIds, PRODUCT_STATUS.PUBLISHED);
  }

  const publishModal = (
    <ConfirmModal
      showModal={showPublishModal}
      onClose={() => setShowPublishModal(false)}
      onClick={handleProductPublish}
      title={`Publish ${selectedRows.length > 1 ? 'products' : 'product'}`}
      ghostButtonText="Cancel"
      primaryButtonColor={Main.brandDark}
      borderColor={Main.lightGray}
      color={Main.gray}
      disabled={prodPublishedReq.isLoading}
    >
      <FlexboxContainer flexDirection="column">
        <span>
          {`Are you sure you want to publish ${selectedRows.length > 1 ? 'selected products?' : 'this product?'}`}
        </span>
      </FlexboxContainer>
    </ConfirmModal>
  )

  return (
    <>
      <ConfirmModal
        showModal={showDeleteModal}
        onClose={() => onDelete(false)}
        onClick={() => {
          deleteDraftProducts(
            selectedRows.map((row) => row.id),
            token as string
          )
            .then(() => {
              onDelete(true);
              renderToast(
                toast.TYPE.SUCCESS,
                ProductLocalization.success.delete
              );
            })
            .catch((err) => {
              renderToast(toast.TYPE.ERROR, err.message);
              onDelete(false);
            });
        }}
        title={`Delete ${selectedRows.length > 1 ? 'products' : 'product'}`}
        ghostButtonText="Back"
      >
        <FlexboxContainer flexDirection="column">
          <span>
            {`Are you sure you want to delete ${selectedRows.length > 1
              ? `${selectedRows.length} products?`
              : 'this product?'
              }`}
          </span>
        </FlexboxContainer>
      </ConfirmModal>

      <ConfirmModal
        showModal={showUpdateModal !== 'none'}
        onClose={() => onUpdate('none')}
        onClick={onConfirmMessageClick}
        title="Update product"
        ghostButtonText="Back"
      >
        <FlexboxContainer flexDirection="column">
          <span>
            {showUpdateModal === "update" && ProductLocalization.warning.update}
            {showUpdateModal === "revise" && ProductLocalization.warning.revise}
          </span>
        </FlexboxContainer>
      </ConfirmModal>

      {publishModal}
    </>
  );
};

export default ProductListingActions;
