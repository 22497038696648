


export const validateVendorData = (vendor: any) => {
    let isValid = true;
    let errors: any = {};

    const nameErrors = validateVendorName(vendor.name);
    const codeErrors = validateVendorCode(vendor.code);
    const mediaErros = validateVendorMedia(vendor.media);
    const descriptionErrors = validateVendorDescription(vendor.description);

    if (Object.keys(nameErrors).length > 0) {
        isValid = false;
        errors = { ...errors, ...nameErrors };

    }

    if (Object.keys(codeErrors).length > 0) {
        isValid = false;
        errors = { ...errors, ...codeErrors };

    }

    if (Object.keys(mediaErros).length > 0) {
        isValid = false;
        errors = { ...errors, ...mediaErros };

    }

    if (Object.keys(descriptionErrors).length > 0) {
        isValid = false;
        errors = { ...errors, ...descriptionErrors };
    }

    return { isValid, errors };
}


export const validateVendorName = (name: string) => {

    const errors: any = {};
    if (name.trim().length === 0) {
        errors.name = 'Name is required';
    } else if (name.trim().length > 50) {
        errors.name = 'Max 50 characters allowed';
    }

    return errors;
}

export const validateVendorCode = (code: string) => {

    const errors: any = {};

    if (code.trim().length === 0) {
        errors.code = 'Code is required';
    } else if (code.trim().length > 8) {
        errors.code = 'Max 8 characters allowed';
    }
    //  else if (code.trim().length < 2) {
    //     errors.code = 'Min 2 characters required';
    // }

    return errors;
}

export const validateVendorMedia = (media: any) => {
    const errors: any = {};

    if (media.length === 0) {
        errors.media = 'Logo is required';
    }

    return errors
}

export const validateVendorDescription = (description: string, setErrors?: any) => {
    
    const errors: any = {};
    
    // Strip HTML tags
    let plainTextContent = stripHtmlTags(description);

    if (plainTextContent?.trim().length > 512) {
        errors.description = 'Max 512 characters allowed';
    }

    return errors;
}


export const mapDataToVendor = (vendor: any) => {
    const { name, code, description, media, isSlbVendor, isMaterialApiEnabled, isStockApiEnabled, isSalesApiEnabled, rentReminderDays } = vendor;
    return {
        name,
        code,
        description,
        media,
        isSlbVendor,
        isMaterialApiEnabled,
        isStockApiEnabled,
        isSalesApiEnabled,
        rentReminderDays
    }
}

function stripHtmlTags(html: any) {
    let doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
}
