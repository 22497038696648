import { get, post } from 'lib/API';
import Invite from 'data/Invite';

export const acceptInvite = async ([invite, password]: any[]): Promise<any> => {
  return await post(`v1/invites/${invite.id}`, undefined, { password });
};

export const createInvite = async (
  [email, name, role, vendorId]: string[],
  { token }: any
): Promise<any> => {
  return await post('v1/invites', token, { email, name, role, vendorId });
};

export const loadInvite = async ({ inviteId }: any): Promise<Invite> => {
  return await get(`v1/invites/${inviteId}`);
};

export const loadInvites = async ({ token }: any): Promise<Invite[]> => {
  return await get('v1/invites', token);
};
