import React from "react";
import styled from "styled-components";
import { Border } from "styles/themeColors";
import { DeleteForever } from '@material-ui/icons';
import FlexboxContainerComponent from "./FlexboxContainer";
import { truncateTitle } from "utils/resources";

const ThumbnailWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const ThumbnailImg = styled.img`
  height: 100px;
  width: 157px;
  object-fit: contain;
  border: 1px solid ${Border.light};
  cursor: pointer;
`;

const Icon = styled.div`
  position: absolute;
  top: 5px;
  background-color: white;  
  right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
`;

interface Props {
  url: string;
  onDelete?: (e: any) => void;
  title?: string;
  onClick?: () => void;
  margin?: string;
}

const Thumbnail = ({ title, url, onDelete, onClick, margin = '0px' }: Props) => {
  return (
    <FlexboxContainerComponent
        flexDirection='column'
        gap='4px'
        width='157px'
        onClick={onClick}
        margin={margin}
  >
    <ThumbnailWrapper>
      <ThumbnailImg src={url} />
      {onDelete && (
        <Icon onClick={onDelete}>
            <DeleteForever style={{ fontSize: 20 }} />
        </Icon>
      )}
    </ThumbnailWrapper>
    {title && <>{truncateTitle(title)}</>}
    </FlexboxContainerComponent>
  );
};

export default Thumbnail;
