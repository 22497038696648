import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import DraggableRow from './DraggableRow'
import { Droppable } from 'react-beautiful-dnd';
import { Color, FontSize, BorderRadius, Percent } from 'lib/styles';
import Slab from 'view/Slab';
import SortingArrow from 'view/SortingArrow';
import Button from 'components/UI/Button';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { renderToast } from 'components/UI/ToastNotification';
import { useAuth } from 'lib/Auth';
import * as User from 'data/User';
import Attribute, * as Attr from 'data/Attribute';
import { updateAttributeValue } from 'requests/atribute';
import { Background } from 'styles/themeColors';
import { TEXT_LABELS } from 'constants/productDetails';

const Container = styled.div`
margin-left: 10px;
  border-radius: ${BorderRadius.tiny};
  width: ${Percent.full};
  height: ${Percent.full};
`;

const ValuesList = styled.div`
  padding: 8px;
`;

const IconContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  margin-left: 15px;
  margin-top: 5px;
  vertical-align: top;
`;

const NameSlab = styled(Slab)`
  flex-grow: 1;
  font-size: ${FontSize.tiny};
  line-height: 1.63;
  color: ${Color.black};
  display: flex;
  align-items: center;
  width: 20%;
  word-break: break-word;
  padding: 10px;
`;

const HeaderSlab = styled(NameSlab)`
  font-size:18px;
  width: 20%;
`;
interface Props {
  selectedAttribute: Attribute;
  droppableId: string;
  changeValue: (index: number, e: string) => void;
  setAdding: (status: boolean) => void;
  setImportModal: (status: boolean) => void;
  updateVendorAttributes: () => void;
  toggleMerging: () => void;
  mergingValues: boolean;
  setChecked: (valueId: number) => void;
  sort: (direction: 'asc' | 'desc') => void;
  resetWithoutSave: () => void;
};

const DroppableColumn = ({
  selectedAttribute,
  droppableId,
  changeValue,
  setAdding,
  setImportModal,
  toggleMerging,
  mergingValues,
  setChecked,
  sort,
  resetWithoutSave
}: Props) => {
  const { token, user } = useAuth();
  const isVendor = User.isVendor(user!);
  const duplicateValues = selectedAttribute.values.filter((v, i, a) => a.findIndex(t => (t.name === v.name)) !== i).map(obj => obj.name);

  const updateValue = (index: number, name: string) => {
    const update: Attr.AttributeValueRequest = {
      id: selectedAttribute.values[index].id,
      attributeId: selectedAttribute.id!,
      code: selectedAttribute.values[index].code,
      name: name,
      displayOrder: selectedAttribute.values[index].order
    }
    updateAttributeValue(token!, update).then(() => {
      renderToast(toast.TYPE.SUCCESS, 'Value updated.');
    }).catch(err => {
      renderToast(toast.TYPE.ERROR, err.message);
    });
  };

  return (
    <Container>
      <FlexboxContainer padding="12px" justifyContent="flex-end" bgColor={Background.white} boxShadow="0px 5px lightgrey" margin="0 0 8px 0">
        <HeaderSlab label="">Order
          <IconContainer>
            <SortingArrow onClick={() => sort('asc')} borderWidth={10} direction="top" margin="0 0 2px 0" color={Color.darkerGray} />
            <SortingArrow onClick={() => sort('desc')} borderWidth={10} direction="bottom" color={Color.darkerGray} />
          </IconContainer>
        </HeaderSlab>
        <Button onClick={() => setImportModal(true)} margin="0 10px 0 0">{TEXT_LABELS.IMPORT_XLSX}</Button>
        <Button onClick={resetWithoutSave} margin="0 10px 0 0">Reset</Button>
        {!isVendor
          ? <Button onClick={toggleMerging} margin="0 10px 0 0">{mergingValues ? 'Merge values' : 'Select to merge'}</Button>
          : null
        }
        <Button onClick={() => setAdding(true)}>Add value</Button>
      </FlexboxContainer>
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <ValuesList
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {selectedAttribute.values.map((val, i) => {
              return (
                <DraggableRow
                  key={i}
                  draggableId={i + ""}
                  value={val.name}
                  index={i}
                  changeValue={changeValue}
                  merging={mergingValues}
                  setChecked={setChecked}
                  inputBorder={duplicateValues.includes(val.name) ? '3px solid red' : '1px solid lightgrey'}
                  updateAttributeValue={updateValue}
                />
              )
            })}
            {provided.placeholder}
          </ValuesList>
        )}
      </Droppable>
    </Container>
  );
};

export default DroppableColumn;
