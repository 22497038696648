import React, { useState, useEffect } from 'react';
import feather from 'feather-icons';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Button from 'components/UI/Button';
import { renderToast } from 'components/UI/ToastNotification';
import { useAuth } from 'lib/Auth';
import { contentWidth } from 'lib/styles';
import { Main } from 'styles/themeColors';
import { productVariantMessages } from 'constants/errorMessages';

const apiUrl = process.env.REACT_APP_API_URL;

const icon = feather.icons['chevron-down'].toSvg({ 'stroke-width': 2, color: 'rgb(73,141,218)' });

const Footer = styled.footer`
  display: flex;
  align-items: center;
  background: white;
  position: fixed;
  bottom: 0px;
  justify-content: space-between;
  width: ${contentWidth};
  box-shadow: 0 -2px 18px 0 rgba(40, 40, 40, 0.5);
`;

const Form = styled.form`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
`;

const Label = styled.label`
  display: block;
  flex: 0 0 auto;
  font-size: 0.875rem;
  margin-left: auto;
  margin-right: 0.5rem;
`;

const Dropdown = styled.select`
  background-color: transparent;
  border: none;
  color: ${Main.accent};
  display: block;
  flex: 0 0 auto;
  font-size: 0.875rem;
  line-height: 1.6;
  margin-right: 0.5rem;
  padding: 0.25rem 1.75rem 0.25rem 0.5rem;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,${encodeURI(icon)}');
  background-repeat: no-repeat;
  background-position: right .25rem top 50%, 0 0;
  background-size: 1.25rem auto, 100%;
`;

const ItemCount = styled.p`
  font-size: 0.875rem;
`;

const ExportButton: React.FC<{ selectedProducts: Array<number>, products:any, setSelectedProducts: React.Dispatch<React.SetStateAction<number[]>> }> = ({ selectedProducts, products, setSelectedProducts }) => {
  const { token } = useAuth();

  const hasProducts = selectedProducts.length > 0;

  const beginExport = async () => {
    try {
      // fetch selected products
      const selectedProductsVariants = products.filter((product:any)=>{
        if(selectedProducts.includes(product.id)){
          return product;
        }
      }).map((obj:any)=> obj.productVariants);

      // fetch product variant with zero price
      const productWithZeroPrice = selectedProductsVariants.some((productVariants:any)=>{
        return productVariants.some((productVariant:any)=>{
          return productVariant.productVariantPrices.some((productVariantPrice:any)=>{
            if(productVariantPrice.pricePerUnit === 0){
              console.log('productVariant with conflict', productVariant);
              return true;
            }
          });
        });
      });

      // validation if true 
      if(productWithZeroPrice){
        renderToast(toast.TYPE.ERROR, productVariantMessages.ZERO_PRICE_VALIDATION);
      }else{
        const headers = {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        };
  
        const options = {
          headers,
          method: 'POST',
          body: JSON.stringify({
            selectedProducts,
          })
        };
  
        const res = await fetch(`${apiUrl}/v1/export`, options);
  
        if (res.ok) {
          renderToast(toast.TYPE.INFO, `Export of ${selectedProducts.length} products started...`);
          setSelectedProducts(() => []);
        }
      }
    } catch (err) {
      // Next line needs to be ignored due to typescript not being able to add types to err in catch
      // @ts-ignore
      renderToast(toast.TYPE.ERROR, err);
    }
  }

  const clickHandler = (ev: React.MouseEvent) => {
    ev.preventDefault();
    beginExport();
    return false;
  };

  return (
    <Footer>
      <Form>
        <ItemCount>{selectedProducts.length || 'No'} items selected</ItemCount>
        {/* <Label htmlFor='exportTarget'>Export to:</Label> */}
        <Button disabled={!hasProducts} onClick={clickHandler} margin='10px'>Export</Button>
      </Form>
    </Footer>
  );
}

export default ExportButton;
