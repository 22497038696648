export const YOUTUBE_LINK_REGEX = /(?:https?:\/\/)?(?:www\.)?youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\/)([a-zA-Z0-9_-]{11})/;

export const RESOURCE_ERROR_MESSAGES = {
    INVALID_URL: 'Invalid video link. Please check URL and try again',
    ERROR_FETCHING_DATA: 'There was an error fetching the youtube meda data. Please try again later.'
}

export const RESOURCES_TEXT_LABELS = {
    NO_RESOURCE_VIDEOS: 'You have no items in resources so far.',
    NO_RESOURCES_FOR_VENDORS: 'No items for selected vendor[s]',
    RESOURCE_CREATED_SUCCESSFULLY: 'Resource has been created successfully.',
    RESOURCE_DELETED_SUCCESSFULLY: 'Resource has been deleted successfully.',
    REMOVE_VIDEO: 'Remove video',
    REMOVE_VIDEO_CONFIMRATION: 'Are you sure you want to remove this video?',
    ALL: "Global",
}

export const MAX_RESOURCE_TITLE_LENGTH = 35;