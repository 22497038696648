import React from 'react';
import { navigate } from 'hookrouter';
import styled, { css } from 'styled-components';
import Consignment from 'data/Consignment';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import LineStepper from 'components/UI/LineStepper';
import { formatDateTime, currencySign } from 'utils/formatters';
import { Main, Text, Background, Border } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import {
  MANUFACTURING,
  PACKING,
  SHIPPED_READY_FOR_PICKUP,
  READY_TO_SHIP,
  READY_FOR_PICKUP,
  DELIVERED_PICKED_UP,
  SHIPPED,
  PICKED_UP
} from 'constants/consignmentStates';
import { PICKUP, SHIPPING } from 'constants/deliveryModes';
import { Height } from 'styles/themeHeights';
import FeatureFlag from 'data/FeatureFlag';

const processingInfoColspan = 5;

const ColoredText = styled.div<any>`
  color: ${({ color }) => color} !important;
`;

const Banner = styled.div<any>`
  color: ${({ color }) => color} !important;
  background-color: ${({ bgColor }) => bgColor} !important;
  height: 24px;
  width: 65%;
  ${({ bannerWidth }) => bannerWidth && css`
    width: ${bannerWidth + 'px'};
  `}
  padding: ${Space.spQuarter} ${Space.sp2x};
`;

const FlexboxItem = styled.div<any>`
  padding: ${Space.sp2x};

  &:first-child {
    padding: 0;
  }

  ${({ itemWidth }) => itemWidth && css`
    width: ${itemWidth};
  `}

  ${({ textAlign }) => textAlign && css`
    text-align: ${textAlign};
  `}

  ${({ marginRight }) => marginRight && css`
    margin-right: ${marginRight};
  `}
`;

const ConsignmentRow = styled(FlexboxContainer)<any>`
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    bottom: 0;
    border-bottom: 1px solid ${Border.brand};

    ${({ borderHorizontalPadding }) => borderHorizontalPadding && css`
      width: calc(100% - ${borderHorizontalPadding * 2 + 'px'});
      margin-left: ${borderHorizontalPadding + 'px'};
  `}
  }
`

interface Props {
  consignments: Consignment[];
  isVendor: boolean;
  getSteps: Function;
  navigateBackUrl?: string;
  page: number;
  dowJonesStatus: string;
  featureFlags: FeatureFlag;
  columnWidths?: Array<number>;
  country?: string
};

const ConsignmentsTable = ({ consignments, isVendor, getSteps, navigateBackUrl, page, dowJonesStatus, featureFlags, columnWidths, country }: Props) => {
  const onGoToDetails = (consignmentId: string) => {
    const navigateBack = navigateBackUrl ? navigateBackUrl : '';
    navigate(`/orders/order-details/${consignmentId.slice(4)}`, false, { country, ordersPage: page, navigateBack });
  };

  const updatedByColumnIndex = (columnWidths?.length || 0) - 2;
  const processingInfoIndex = updatedByColumnIndex - processingInfoColspan
  const bannerLeftPadding = columnWidths?.slice(0, processingInfoIndex).reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  const processingInfoWidth = columnWidths?.slice(processingInfoIndex, updatedByColumnIndex).reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <FlexboxContainer flexDirection="column" width="100%">
      <FlexboxContainer
        width="100%"
        padding={'0 0 0 ' + (bannerLeftPadding ? (bannerLeftPadding + 'px') : '46%')}
        height={Height.mMinus}
        alignItems="flex-end"
        bgColor={Background.lightBrand}
        borderRight={'1px solid'+Border.brand}
        borderLeft={'1px solid'+Border.brand}
      >
        <Banner color={Text.whitePrimary} bgColor={Main.brandLight} bannerWidth={processingInfoWidth} className="base-strong">
          Processing info:
        </Banner>
      </FlexboxContainer>
      {renderConsignments(consignments, isVendor, getSteps, onGoToDetails, dowJonesStatus, featureFlags, columnWidths || [], processingInfoWidth || 0, country)}
    </FlexboxContainer>
  );
};

const renderConsignments = (
  consignments: Consignment[],
  isVendor: boolean,
  getSteps: Function,
  onGoToDetails: Function,
  dowJonesStatus: string,
  featureFlags: FeatureFlag,
  columnWidths: Array<number>,
  processingInfoWidth?: number,
  country?: string
) => {
  consignments.sort((a, b): any => a.consignmentId.localeCompare(b.consignmentId))
  return consignments.map((consignment, index) => {
    const {
      displayState,
      currentStep,
      stepsColor
    } = getSteps(
      consignment.state,
      [consignment.consignmentEntries[0].deliveryMode],
      isVendor,
      dowJonesStatus,
      featureFlags,
      false,
      true
    );

    const promisedDateText = [SHIPPED_READY_FOR_PICKUP, READY_TO_SHIP, READY_FOR_PICKUP, DELIVERED_PICKED_UP, SHIPPED, PICKED_UP].includes(consignment.state)
      ? consignment.consignmentEntries[0].deliveryMode === PICKUP
        ? 'Package is ready for pickup on:'
        : 'Package has shipped on:'
      : consignment.consignmentEntries[0].deliveryMode === PICKUP
        ? 'Minimum promised ready to pickup date:'
        : 'Minimum promised ready to ship date:';

    const displayDate = consignment.state === MANUFACTURING && consignment.consignmentEntries[0].manufactureDate
      ? consignment.consignmentEntries[0].manufactureDate
      : consignment.state === DELIVERED_PICKED_UP || consignment.state === SHIPPED || consignment.state === PICKED_UP
        ? consignment.dispatchDate
        : consignment.availableDate;

    const deliveryType = consignment.consignmentEntries[0].deliveryMode === SHIPPING
      ? 'Delivery'
      : 'Pickup';

    const consignmentInfo = consignment.consignmentEntries[0].deliveryMode === PICKUP && consignment.referenceNumber
      ? (
        <>
          <FlexboxContainer flexDirection="column" padding={Space.sp2x}>
            <ColoredText color={Text.secondary}>Reference #:</ColoredText>
            <ColoredText>{consignment.referenceNumber}</ColoredText>
          </FlexboxContainer>
          <FlexboxContainer flexDirection="column" padding={Space.sp2x}>&nbsp;</FlexboxContainer>
        </>
      )
      : consignment.consignmentEntries[0].deliveryMode === SHIPPING && consignment.trackingId && consignment.deliveryCarrier
        ? (
          <>
            <FlexboxContainer flexDirection="column" padding={Space.sp2x}>
              <ColoredText color={Text.secondary}>Carrier:</ColoredText>
              <ColoredText>{consignment.deliveryCarrier.name}</ColoredText>
            </FlexboxContainer>
            <FlexboxContainer flexDirection="column" padding={Space.sp2x}>
              <ColoredText color={Text.secondary}>Tracking #:</ColoredText>
              <ColoredText>{consignment.trackingId}</ColoredText>
            </FlexboxContainer>
          </>
        )
        : (
          <FlexboxContainer onClick={() => onGoToDetails(consignment.consignmentId)} padding={Space.sp2x}>
            <ColoredText color={Main.accent}>
              {consignment.consignmentEntries[0].deliveryMode === PICKUP ? 'Confirm / Update pickup date' : 'Confirm / Update shipping date'}
            </ColoredText>
          </FlexboxContainer>
        );

    let columnWidthsIndex = 0;

    return (
      <ConsignmentRow
        key={index}
        width="100%"
        bgColor={Background.lightBrand}
        borderRight={"1px solid"+Border.brand}
        borderLeft={"1px solid"+Border.brand}
        borderHorizontalPadding={index < consignments.length - 1 && columnWidths[0] + 10}
      >
        <FlexboxItem
          hideBorder={index < consignments.length - 1}
          itemWidth={columnWidths[columnWidthsIndex] ? (columnWidths[columnWidthsIndex++] + 10) + 'px' : "48px"}>
          &nbsp;
        </FlexboxItem>
        <FlexboxItem
          itemWidth={columnWidths[columnWidthsIndex] ? (columnWidths[columnWidthsIndex++] - 12) + 'px' : "140px"}
          onClick={() => onGoToDetails(consignment.consignmentId)}>
          {consignment.consignmentId.slice(4)}
        </FlexboxItem>
        <FlexboxContainer flexDirection="column" width={columnWidths[columnWidthsIndex] ? columnWidths[columnWidthsIndex++] + 'px' : '120px'} padding={Space.sp2x}>
          <div>{displayState}</div>
          <LineStepper numberOfSteps={5} currentStep={currentStep} stepperWidth={90} stepsColor={stepsColor} />
        </FlexboxContainer>
        {
          !isVendor ? <FlexboxItem itemWidth={columnWidths[columnWidthsIndex] ? columnWidths[columnWidthsIndex++] + 'px' : "9%"}>{consignment.vendor ? consignment.vendor.name : ''}</FlexboxItem> : null
        }
        <FlexboxItem itemWidth={columnWidths[columnWidthsIndex] ? columnWidths[columnWidthsIndex++] + 'px' : "9%"}>{consignment.warehouse.name}</FlexboxItem>
        <FlexboxItem itemWidth={columnWidths[columnWidthsIndex] ? columnWidths[columnWidthsIndex++] + 'px' : "9%"}>{deliveryType}</FlexboxItem>
        <FlexboxContainer width={`${processingInfoWidth}px`|| "36%"}>
          {
           [PACKING, MANUFACTURING, READY_TO_SHIP, READY_FOR_PICKUP, SHIPPED_READY_FOR_PICKUP, DELIVERED_PICKED_UP, SHIPPED, PICKED_UP].includes(consignment.state)
              ? (
                <FlexboxContainer
                  flexDirection="column"
                  padding={`${Space.sp2x} ${Space.sp2x} ${Space.sp2x} ${Space.sp2x}`}
                >
                  <FlexboxContainer>
                    <ColoredText color={Text.secondary}>
                      {promisedDateText}
                    </ColoredText>
                  </FlexboxContainer>
                  <ColoredText color={[SHIPPED, PICKED_UP, DELIVERED_PICKED_UP].includes(consignment.state) ? Main.success : Text.primary}>{formatDateTime(displayDate, true)}</ColoredText>
                </FlexboxContainer>
              ) : <FlexboxItem>&nbsp;</FlexboxItem>
          }
          {
           [PACKING, MANUFACTURING, SHIPPED_READY_FOR_PICKUP, READY_TO_SHIP, READY_FOR_PICKUP, DELIVERED_PICKED_UP, SHIPPED, PICKED_UP].includes(consignment.state)
              ? consignmentInfo
              : <FlexboxItem>&nbsp;</FlexboxItem>
          }
        </FlexboxContainer>
        <FlexboxItem itemWidth={`${columnWidths[columnWidths.length - 2]}px`|| "9%"}>{consignment.updatedBy}</FlexboxItem>
        <FlexboxItem itemWidth={`${columnWidths[columnWidths.length - 1]}px`|| "150px"} textAlign="end">
          {currencySign(country,consignment.totalPriceWithTax)}
        </FlexboxItem>
      </ConsignmentRow>
    );
  });
};

export default React.memo(ConsignmentsTable);
