import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import TagsInput, { TagProps, InputProps, RenderInputProps } from 'react-tagsinput';
import { Color, FontSize, FontWeight, Percent, BorderRadius } from 'lib/styles';
import { Background } from 'styles/themeColors';

const StyledTagsInput = styled(TagsInput) <any>`
  width: ${Percent.full};
  background-color: ${props => props.bgColor};
  overflow: hidden;

  .react-tagsinput-tag {
    display: inline-flex;
    font-size: ${FontSize.small};
    font-weight: ${FontWeight.medium};
    margin-bottom: 5px;
    margin-right: 5px;
    margin-left: 5px;
    padding: 5px;
  }

  .react-tagsinput-tag-custom {
    display: inline-flex;
    font-size:  ${FontSize.small};
    font-weight: ${FontWeight.medium};
    border: 1px solid ${Color.lightGray};
    border-radius: 14px;
    background-color: ${Color.carrotOrange};
    margin: 5px;
    padding: 1px 5px;
    color:white;
  }

  span {
    flex-direction: row;
    div:after {
      content: ' ';
    }

    div:last-of-type:after {
      content: ' ';
    }
  }

  ${({ withDelimiter }) => withDelimiter && css`
    span {
      div:after {
        content: ', ';
      }

      div:last-of-type:after {
        content: ' ';
      }
    }
  `}

  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: ${FontWeight.bold};
  }

  .react-tagsinput-input {
    background: transparent;
    font-size: ${FontSize.small};
    font-weight: ${FontWeight.medium};
    margin-top: 1px;
    outline: none;
    padding: 15px 5px;
    border: none;

    &:focus {
      outline: transparent;
    }
  }

  .react-tagsinput-input-redesign {
    background: transparent;
    font-size: ${FontSize.small};
    font-weight: ${FontWeight.medium};
    margin-top: 1px;
    outline: none;
    padding: 9px 9px;
    border: none;
    width: 180px;

    &:focus {
      outline: transparent;
    }
  }
`;

interface Props {
  value: string[];
  onChange: (value: string[]) => void;
  className?: string;
  addKeys?: number[];
  onlyUnique?: boolean;
  addOnBlur?: boolean;
  disabled?: boolean;
  withDelimiter?: boolean;
  inputProps?: InputProps;
  tagProps?: TagProps;
  renderTag?: (props: any) => ReactNode;
  renderInput?: (props: RenderInputProps<any>) => React.ReactNode;
  renderInputProps?: RenderInputProps;
  bgColor: string;
  color?: string;
};

const TagsInputComponent = ({
  className,
  value,
  onChange,
  addKeys,
  onlyUnique,
  addOnBlur,
  inputProps,
  tagProps,
  renderTag,
  renderInput,
  renderInputProps,
  withDelimiter,
  bgColor,
  color,
  ...other
}: Props) => {
  return (
    <StyledTagsInput
      className={className}
      value={value}
      onChange={onChange}
      addKeys={addKeys}
      onlyUnique={onlyUnique}
      addOnBlur={addOnBlur}
      inputProps={inputProps}
      tagProps={tagProps}
      renderTag={renderTag}
      renderInput={renderInput}
      withDelimiter={withDelimiter}
      {...other}
    />
  );
};

TagsInputComponent.defaultProps = {
  className: 'react-tagsinput',
  addKeys: [13],
  onlyUnique: true,
  addOnBlur: true,
  withDelimiter: false,
  bgColor: Background.white,
};

export default TagsInputComponent;
