import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import ConsignmentEntry from 'data/ConsignmentEntry';
import { SPLIT_CONSIGNMENT } from 'constants/consignmentStates';
import { FontWeight} from 'lib/styles';
import { Background, Border, Main, Text } from 'styles/themeColors';
import { formatDateTime, currencySign } from 'utils/formatters';
import MatTable from 'components/MaterialTable/MatTable';
import productColumns from 'constants/productColumns';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import { SHIPPING } from 'constants/deliveryModes';

const ProductImage = styled.img<any>`
  cursor: pointer;
  max-height: 96px;
  max-width: 96px;
  margin: 0 auto;
  align-self: baseline;
`;

const TableFooterColumn = styled.span<any>`
  width: 200px;
  height: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const TableFooterData = styled.span<any>`
  width: 104px;
  height: 20px;
  text-align: right;
  color: ${Text.primary};
  font-size: 16px;
  line-height: 20px;
`;

const Surcharges = styled.span<any>`
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${Text.primary};
`;

const TableColumn = styled.span<any>`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  min-height: 18px;
  width: 234px;
  color: ${Text.secondary};
  margin-bottom: 4px;

  ${({ color }) => color && css`
  color: ${color};
`}

${({ textDecoration }) => textDecoration && css`
  text-decoration: ${textDecoration};
`}

${({ fontSize }) => fontSize && css`
  font-size: ${fontSize};
`}

${({ fontWeight }) => fontWeight && css`
  font-weight: ${fontWeight};
`}

  ${({ deleted }) => deleted && css`
  opacity: 0.5;
`}
`;

const TableData = styled.td<any>`
  text-align: center;
  vertical-align: top;

  &:first-child:not(:only-child):not([rowspan]) {
    text-align: left;
    font-weight: ${FontWeight.bold};
  }

  &:first-child:not(:only-child)[rowspan] {
    text-align: left;
  }

  &:last-child:not(:only-child)[rowspan] {
    padding-right: 10px;
    text-align: right;
    margin-right: 0;
    font-weight: ${FontWeight.bold};
  }

  ${({ color }) => color && css`
    color: ${color};
  `}

  ${({ textDecoration }) => textDecoration && css`
    text-decoration: ${textDecoration};
  `}

  ${({ fontSize }) => fontSize && css`
    font-size: ${fontSize};
  `}

  ${({ fontWeight }) => fontWeight && css`
    font-weight: ${fontWeight};
  `}

  ${({ deleted }) => deleted && css`
    opacity: 0.5;
  `}
`;

interface TableValue{
  product: JSX.Element,
  quantity: JSX.Element,
  unitPrice: JSX.Element,
  subTotal: JSX.Element
};

interface Props {
  consignmentEntries: ConsignmentEntry[];
  isPreparationStep?: boolean;
  totalTax?: number;
  totalSurcharges?: number;
  totalDeliveryCost?: number;
  totalPriceWithTax?: number;
  state?: string;
  showSummary?: boolean;
  subTotal: number;
  deliveryDate?: string;
};

const ShippingCostRequiredItems = ({
  consignmentEntries,
  isPreparationStep,
  totalTax,
  totalDeliveryCost,
  totalPriceWithTax,
  state,
  totalSurcharges,
  showSummary,
  subTotal,
  deliveryDate
}: Props) => {

  // Get Value from param for currency differentiate
  const queryParameters = new URLSearchParams(window.location.search);
  const country = queryParameters.get("country"); 

  const productColumn = consignmentEntries && consignmentEntries.map((consignmentEntry, index) => {
    return (
    <Fragment key={index}>
      <FlexboxContainerComponent flexDirection='row'>
        <FlexboxContainerComponent width='15%'><ProductImage src={consignmentEntry.productImages && consignmentEntry.productImages.length > 0 ? process.env.REACT_APP_MEDIA_URL! + consignmentEntry.productImages[0].path : ''} /></FlexboxContainerComponent>
        <FlexboxContainerComponent flexDirection='column'>
          <FlexboxContainerComponent justifyContent='flex-start' margin='0 0 12px' padding='0px 0 0'>
            <TableData deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT} textDecoration={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT ? 'line-through' : 'normal'}>{consignmentEntry.productName}</TableData>
          </FlexboxContainerComponent>
          <FlexboxContainerComponent flexDirection='row'>
            <FlexboxContainerComponent flexDirection='column'>
              <FlexboxContainerComponent><TableColumn>SKU</TableColumn><TableColumn>{consignmentEntry.sku}</TableColumn></FlexboxContainerComponent>
              {
                consignmentEntry.selectableAttributes && consignmentEntry.selectableAttributes.map((atr, index) => {
                  const atrValue = consignmentEntry.attributeValues.find(val => val.attributeId === atr.id);
                  return (
                    <FlexboxContainerComponent key={index}><TableColumn>{atr.name}</TableColumn><TableColumn>{atrValue ? atrValue.name : ''}</TableColumn></FlexboxContainerComponent>
                  )
                })
              }    
              {
                consignmentEntry.pricebookName && consignmentEntry.pricebookId &&
                <FlexboxContainerComponent>
                  <TableColumn style={{marginTop:"8px"}}>Pricebook name / ID</TableColumn>
                  <TableColumn style={{marginTop:"8px", width:'auto'}}>
                    {`${consignmentEntry.pricebookName} / ${consignmentEntry?.priceBook?.PricebookVendorId}`}
                  </TableColumn>
                </FlexboxContainerComponent>
              }
              <FlexboxContainerComponent><TableColumn>{consignmentEntries[0]?.deliveryMode === SHIPPING ? "Est. delivery date" : "Est. pickup date"}</TableColumn><TableColumn>{deliveryDate ? formatDateTime(deliveryDate, false,false) : formatDateTime(consignmentEntry.preliminaryDeliveryDate, false,false)}</TableColumn></FlexboxContainerComponent>
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
        </FlexboxContainerComponent>
      </FlexboxContainerComponent>
    </Fragment>
  )
});

  const quantityColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {
  return (
    <Fragment key={consignmentEntry.id}>
      <FlexboxContainerComponent justifyContent='flex-end' >
        <TableData deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>{consignmentEntry.quantity}</TableData>
      </FlexboxContainerComponent>
    </Fragment>
  )
  });

  const unitPriceColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {
  return (
    <Fragment key={consignmentEntry.id}>
      <FlexboxContainerComponent justifyContent='flex-end'>
        {
          consignmentEntry.discountedPrice !== consignmentEntry.basePrice
            ? <TableData color={Main.error} deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>{currencySign(country,consignmentEntry.discountedPrice)}</TableData>
            : <TableData deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>{currencySign(country,consignmentEntry.basePrice)}</TableData>
        }
      </FlexboxContainerComponent>
    </Fragment>
  )
  });

  const subTotalColumn = consignmentEntries && consignmentEntries.map(consignmentEntry => {
  return (
    <Fragment key={consignmentEntry.id}>
      <FlexboxContainerComponent justifyContent='flex-end'>
        <TableData deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>{currencySign(country,consignmentEntry.totalPrice)}</TableData>
      </FlexboxContainerComponent>
      <FlexboxContainerComponent justifyContent='flex-end' padding='16px 0 0 '>
        {
          consignmentEntry.surcharge > 0
            ? <TableData rowSpan={2} deleted={consignmentEntry.cancelQuantity > 0 && state !== SPLIT_CONSIGNMENT}>+{currencySign(country,consignmentEntry.surcharge)} <br /> surcharge</TableData>
            : <TableData>&nbsp;</TableData>
        }
      </FlexboxContainerComponent>
    </Fragment>
  )
  });

  const data: TableValue[] = [];

  consignmentEntries.forEach((c,index)=> {data.push({
    product: productColumn[index],
    quantity: quantityColumn[index],
    unitPrice: unitPriceColumn[index],
    subTotal: subTotalColumn[index]
  })}); 

  const orderTotal = showSummary && (
    <FlexboxContainerComponent flexDirection="column" alignItems='flex-end' width='100%' padding='16px'>
      <FlexboxContainerComponent padding='16px'>
        <TableFooterColumn>Subtotal</TableFooterColumn>
        <TableFooterData> { subTotal && currencySign(country,subTotal)}</TableFooterData>
      </FlexboxContainerComponent>
      {
        totalSurcharges && totalSurcharges > 0
          ? (
            <FlexboxContainerComponent padding='16px'>
              <Surcharges>includes surcharges</Surcharges>
              <TableFooterData> +{totalSurcharges && currencySign(country,totalSurcharges)}</TableFooterData>
            </FlexboxContainerComponent>
          )
          : null
      }
      {
        totalDeliveryCost ? (
          <FlexboxContainerComponent padding='16px' borderTop={`1px solid ${Border.light}`} borderBottom={`1px solid ${Border.light}`}>
            <TableFooterColumn fontSize="15px">Shipping</TableFooterColumn>
            <TableFooterData fontSize="15px">{totalDeliveryCost && currencySign(country,totalDeliveryCost)}</TableFooterData>
          </FlexboxContainerComponent>
        ) : null
      }
      {
        totalTax ? (
          <FlexboxContainerComponent borderTop='1px solid #E1E6EB' padding='16px'>
            <TableFooterColumn>Tax</TableFooterColumn>
            <TableFooterData> {totalTax && currencySign(country,totalTax)}</TableFooterData>
          </FlexboxContainerComponent>
        ) : null
      }
      <FlexboxContainerComponent borderTop={`2px solid ${Background.navy}`} padding='16px'>
        <TableFooterColumn>Order total</TableFooterColumn>
        <TableFooterData> {totalPriceWithTax ? currencySign(country,totalPriceWithTax) : currencySign(country,subTotal)}</TableFooterData>
      </FlexboxContainerComponent>
    </FlexboxContainerComponent>
  )

  return (
    <>
    <MatTable
      columns={productColumns}
      data={data}
      toolbar
      overrideToolbar
      borders={true}
      rowStyle={{backgroundColor:"#fff"}}
      customHeaderBorders={true}
    />
    {orderTotal}
    </>
  );
};

ShippingCostRequiredItems.defaultProps = {
  showSummary: true
}

export default React.memo(ShippingCostRequiredItems);
