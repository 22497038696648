import React, { useEffect } from 'react';
import { navigate } from 'hookrouter';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Vendor from 'data/Vendor';
import Button from 'components/UI/Button';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import Card from 'view/Card';
import Slab from 'view/Slab';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import PageHeading from 'components/UI/PageHeading';
import { renderToast } from 'components/UI/ToastNotification';
import { useAPI } from 'lib/API';
import { Color, contentWidth, Size } from 'lib/styles';
import { loadBrands } from 'requests/brand';
import { Background, Main } from 'styles/themeColors';
import placeholderImage from 'images/placeholder-image.jpg';

const Page = styled.div`
  margin: ${Size.large} 0px;
  width: 100%;
  padding: 0 30px;
`;

const Header = styled.header`
  border-bottom: 1px solid ${Color.darkGray};
  display: flex;
  line-height: 1;
  margin: 0px auto;
  width: 100%;
  padding: ${Size.medium} 0px;
`;

const NameSlab = styled(Slab)`
  flex-grow: 1;
`;

const List = styled.ul`
  margin: 0px auto;
  width: 100%;
  padding: 0px;
`;

const BrandImage = styled.img`
  background-color: ${Background.white};
  height: 64px;
  margin-right: ${Size.medium};
  object-fit: contain;
  object-position: 50%;
  padding: ${Size.tiny};
  width: 64px;
`;

const PlaceHolderImage = styled.img`
  background-color: ${Background.white};
  height: 64px;
  margin-right: ${Size.medium};
  object-fit: contain;
  object-position: 50%;
  padding: ${Size.tiny};
  width: 64px;
`;


// const EmptyLogo = styled.div`
//   background-color: ${Color.whiteSmoke};
//   height: 64px;
//   margin-right: ${Size.medium};
//   padding: ${Size.tiny};
//   width: 64px;
// `;

const EmptyMessage = styled.h1`
  margin: 20px auto;
  max-width: ${contentWidth};
  padding: 0px;
`;

interface Props {
  selectedVendor?: Vendor;
};

const BrandsList = ({ selectedVendor }: Props) => {
  const brandsReq = useAPI({
    deferFn: loadBrands
  });

  useEffect(() => {
    brandsReq.run(selectedVendor ? selectedVendor.id : null);
  }, [selectedVendor]);

  let brandsList = null;

  if (brandsReq.isLoading) {
    brandsList = <LoadingIndicator />;
  } else if (brandsReq.data) {
    const brandItems = brandsReq.data.map(brand => {
      const brandLogo = brand.media && brand.media.length > 0
        ? <BrandImage alt={`${brand.name} logo`} src={process.env.REACT_APP_MEDIA_URL + brand.media[0].path} />
        : <PlaceHolderImage alt={`${brand.name} placeholder-logo`} src={placeholderImage} />;

      return (
        <Card key={brand.id}>
          {brandLogo}
          <NameSlab label="">{brand.name}</NameSlab>
          <FlexboxContainer justifyContent="flex-end">
            <Button
              isGhost
              color={Main.accent}
              onClick={() => navigate(`/brands/brand-details/${brand.id}`)}
            >
              Edit
            </Button>
          </FlexboxContainer>
        </Card>
      );
    });

    brandsList = <List>{brandItems}</List>;
  }

  if (!selectedVendor) {
    brandsList = <EmptyMessage>Please select a vendor to show it's brands.</EmptyMessage>;
  }

  const createButtonClick = () => {
    if (!selectedVendor) {
      renderToast(toast.TYPE.INFO, 'Please select a vendor before you try to create a brand.');
      return;
    }
    else {
      navigate(`/brands/new-brand`);
    }
  };

  return (
    <Page>
      <Header>
        <PageHeading>Brands</PageHeading>
        <FlexboxContainer margin="0 0 0 83%">
          <Button onClick={createButtonClick}>Create Brand</Button>
        </FlexboxContainer>
      </Header>
      {brandsList}
    </Page>
  );
};

export default BrandsList;
