import React from 'react';
import styled from 'styled-components';
import { Text } from 'styles/themeColors';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { currencySign } from 'utils/formatters';
import { Space } from 'styles/themeSpaces';

interface Props {
  consignmentEntries: any;
  currencyIso: string;
};

const CardItem = styled.label<any>`
  text-align: ${({ $textAlign }) => $textAlign ? $textAlign : 'center'};
  width: ${({ $width }) => $width};
  color: ${({ $color }) => $color ? $color : Text.primary};
  text-decoration: ${({ $textDecoration }) => $textDecoration};
  font-size: ${({ $fontSize }) => $fontSize};
  margin-right: ${({ $marginRight }) => $marginRight};
  margin-top: ${({ $marginTop }) => $marginTop};
`;

const CardSpan = styled.span<any>`
  color: ${({ $color }) => $color};
  margin-bottom: ${({ $marginBottom }) => $marginBottom};
`;

const Label = styled.label`
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  color: ${Text.secondary};
  margin-left: ${Space.sp2xPlus};
`;

const OrderConsignmentEntryList = ({ consignmentEntries, currencyIso }: Props) => {
  const calculateDiscountPercent = (totalPrice: number, discountedPrice: number) => {
    return Math.round(100 - ((discountedPrice / totalPrice) * 100));
  };

  const productInfo = (consignmentEntry: any) => (
    <CardItem
      $width='50%'
      $textAlign='left'
      className='base' >
      <FlexboxContainer flexDirection='column'>
        <CardSpan
          $marginBottom='4px'
          $color={Text.primary}>
          {consignmentEntry.productName}
        </CardSpan>
        <CardSpan $color={Text.secondary} $marginBottom={Space.spBasePlus}>
          Vendor SKU: {consignmentEntry?.productVariant?.sku}
        </CardSpan>
        {
          consignmentEntry.pricebookName && consignmentEntry.pricebookId &&
          <CardSpan $color={Text.secondary}>
            Pricebook name / ID: {`${consignmentEntry.pricebookName} / ${consignmentEntry?.priceBook?.PricebookVendorId}`}
          </CardSpan>
        }
      </FlexboxContainer>
    </CardItem>
  );

  const productQuantity = (consignmentEntry: any) => (
    <CardItem
      $width='15%'
      className='base'>
      {consignmentEntry.quantity}
    </CardItem>
  );

  const productBasePrice = (consignmentEntry: any) => {
    if (consignmentEntry.basePrice !== consignmentEntry.discountedPrice) {
      return (
        <FlexboxContainer
          flexDirection='column'
          padding='0'
          width='15%'
        >
          <CardItem $color='red'>
            {currencySign(currencyIso,consignmentEntry.discountedPrice)}
          </CardItem>
          <CardItem
            $textDecoration='line-through'
          >
            {currencySign(currencyIso,consignmentEntry.basePrice)}
          </CardItem>
          <CardItem
            $color='red'
          >
            {currencySign(currencyIso,consignmentEntry.basePrice - consignmentEntry.discountedPrice)}
            &nbsp;
            ({calculateDiscountPercent(consignmentEntry.basePrice, consignmentEntry.discountedPrice)}%)
          </CardItem>
        </FlexboxContainer>
      );
    }

    return (
      <CardItem
        $width='15%'
        className='base'
      >
        {currencySign(currencyIso,consignmentEntry.basePrice)}
      </CardItem>
    );
  };

  const productTotal = (consignmentEntry: any) => (
    <FlexboxContainer flexDirection='column'>
      <CardItem
        $width='15%'
        $textAlign='right'
        $marginRight='15px'
        className='base'
      >
        {currencySign(currencyIso,consignmentEntry.totalPrice)}
      </CardItem>
      {
        consignmentEntry.surcharge
          ? (
            <CardItem
              $width='15%'
              $textAlign='right'
              $marginRight='15px'
              $marginTop='10px'
              className='base'
            >
              +{currencySign(currencyIso,consignmentEntry.surcharge)} <br /> surcharge
            </CardItem>
          )
          : null
      }
    </FlexboxContainer>
  );

  return consignmentEntries.map((consignmentEntry: any, key: number) => (
    <FlexboxContainer
      key={key}
      width='100%'
      justifyContent='space-between'
      padding='16px 5px'
    >
      {productInfo(consignmentEntry)}
      {productQuantity(consignmentEntry)}
      {productBasePrice(consignmentEntry)}
      {productTotal(consignmentEntry)}
    </FlexboxContainer>
  ));
};

export default OrderConsignmentEntryList;
