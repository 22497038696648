import React from 'react';
import styled from 'styled-components';
import { Background } from 'styles/themeColors';

const StyledBackdrop = styled.div`
  background-color: ${Background.navy};
  opacity: 35%;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 150;
`;

interface Props {
  showBackdrop: boolean;
  onClick: (event: any) => void;
};

const Backdrop = ({
  showBackdrop,
  onClick
}: Props) => {
  return showBackdrop ? <StyledBackdrop onClick={onClick} /> : null;
};

Backdrop.defaultProps = {
  showBackdrop: true
};

export default Backdrop;
