import { OrderCustomerType } from 'data/Customer';
import Consignment from 'data/Consignment';
import Company from 'data/Company';
import DeliveryAddress from 'data/DeliveryAddress';
import DowJonesResult from 'data/DowJonesResult';
import Address from 'data/Address';

export default interface Order {
  id: number;
  orderId: string;
  state: string;
  customer: OrderCustomerType;
  paymentType: string;
  deliveryType: string;
  created: string;
  updatedBy: string;
  consignments: Consignment[];
  warehouse: string;
  dowJones: DowJonesResult;
  totalTax: number;
  totalPrice: number;
  totalPriceWithTax: number;
  company?: Pick<Company, 'displayName' | 'id' | 'companyName' | 'companyHistoryNameCount' | 'companyCountry' | 'countryIso'>;
  displayCompanyName?: string;
  companyId?: string;
  vendor?: string;
  deliveryAddress?: Pick<DeliveryAddress, 'addressLine1' | 'addressLine2' | 'town' | 'state' | 'postalCode'>;
  cancelEmailSentDate?: string;
  billingAddress?: Address;
  status?: string;
  companyName: string;
  totalSurcharges: number;
  currencyIso: string;
};

export const empty: Order = {
  id: 0,
  orderId: '',
  state: '',
  dowJones: {
    id: 0,
    status: '',
    comments: '',
    dowJonesTimeStamp: '',
    updatedBy: '',
    updatedDate: '',
    order: {
      id: 0,
      state: '',
      deliveryAddress: {
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        state: '',
        town: ''
      }
    }
  },
  customer: {
    id: 0,
    companyName: '',
    customerId: '',
    email: '',
    firstName: '',
    lastName: '',
    fullName: '',
    phone: '',
    dowJonesStatus: ''
  },
  paymentType: '',
  deliveryType: '',
  created: '',
  updatedBy: '',
  consignments: [],
  totalPrice: 0,
  totalPriceWithTax: 0,
  totalTax: 0,
  warehouse: '',
  company: {
    id: 0,
    displayName: '',
    companyName: '',
    companyHistoryNameCount: 0
  },
  deliveryAddress: {
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    state: '',
    town: ''
  },
  companyName: '',
  totalSurcharges: 0,
  currencyIso: ''
};

export interface OrdersList {
  page: number;
  totalPages: number;
  size: number;
  totalItems: number;
  orders: Order[];
};
