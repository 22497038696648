

import { post, get, put } from 'lib/API';

export const getAllCampaigns = async (
  queryParams: string,
  token: any
) => {
  return await get(`v1/campaigns?${queryParams}`, token);
}

export const getCampaign = async (
  [campaignId]: any[], { token }: any
) => {
  return await get(`v1/campaigns/get/${campaignId}`, token);
}

export const getCampaignTypes = async (
  _: any[],
  { token }: any
) => {
  return await get(`v1/campaigns/types`, token);
}

export const getLaunchCompanies = async (
  _: any[],
  { token }: any
) => {
  return await get(`v1/campaigns/launch-companies`, token);
}

export const getEventIds = async (
  _: any[],
  { token }: any
) => {
  return await get(`v1/campaigns/eventIds`, token);
}

export const createCampaign = async (
  [campaign]: any,
  {token}: any
) => {
  return await post('v1/campaigns/bulk', token, campaign);
}

export const deleteCampaign = async (
  [campaignIds]: any,
  {token}: any
) => {
  return await put(`v1/campaigns/bulk/delete`, token,{campaignIds});
}

export const updateCampaign = async (
  [campaign]: any,
  {token}: any
) => {
  return await put(`v1/campaigns/${campaign.id}`, token, campaign);
}

export const validateCampaign = async (
  [fileContent, fileName, uploadDate, vendorIds]: any[],
  { token }: any
) : Promise<any> => {
  return await post(`v1/campaigns/validate`, token, {
    fileContent,
    fileName,
    uploadDate,
    vendorIds
  });
}

export const getUserCampaign = async (
  [userId]: any[], { token }: any
) : Promise<any> => {
  return await get(`v1/campaigns/user/${userId}`, token);
}