import React, { createContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { ServerToClientEvents, ClientToServerEvents } from 'types/socket';

export const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(
  process.env.REACT_APP_SOCKET_URL!,
  {
    path: process.env.REACT_APP_SOCKET_PATH!
  }
);
export const SocketContext = createContext<any>(socket);

interface ProviderProps {
  children: React.ReactNode;
};

export const SocketProvider = ({ children }: ProviderProps) => {
  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};
