import styled from 'styled-components';
import { theme } from 'OnboardingComponents/OnboardingPageTheme';


export const InlineLink = styled.a`
  &&& {
    display: inline;
    color: ${theme.blue};
    cursor: pointer;
    text-decoration: none;
    font-weight: 500;
  }
`;
