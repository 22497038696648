export const PRODUCT_VARIANT_FIELDS_CONFIG: any = {
    sku: {
        maxLength: 100
    },
    vendorPartNumber: {
        maxLength: 100
    },
    weight: {
        maxLength: 50
    },
    itemType: {
        maxLength: 20
    },
    materialNumber: {
        maxLength: 100
    },
    default: {
        maxLength: 524288
    }
}