import React from 'react';
import ConfirmModal from 'components/UI/Modal';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import styled from 'styled-components';
import { Space } from 'styles/themeSpaces';
import { TEXT_LABELS } from 'constants/productDetails';

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;
interface Props {
  isSubmitModalOpen: boolean;
  keep: () => void,
  discard: () => void
}

export default function KeepOrDiscardModal({
  isSubmitModalOpen,
  discard,
  keep
}: Props) {
   
  return (
    <ConfirmModal
      showModal={isSubmitModalOpen}
      onClose={discard}
      onClick={keep}
      title={TEXT_LABELS.CONFRIMATION}
      ghostButtonText="Discard"
      primaryButtonText={TEXT_LABELS.KEEP_PRODUCT}
    >
      <FlexboxContainer flexDirection="column">
        <Span>{TEXT_LABELS.KEEP_OR_DISCARD}</Span>
      </FlexboxContainer>
    </ConfirmModal>
  );
}
