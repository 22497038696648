import { PRICE_TYPE } from "constants/product";
import styled from "styled-components";
import React from 'react';
import { mapConsignmentTypeLabel, mapConsignmentTypeTag } from "utils/consignment";

const Tag = styled.span<any>`
  background-color: ${({ type }) => mapConsignmentTypeTag(type)};
  color: white;
  width: 96px;
  height: 24px;
  text-align: center;
  border-radius: 7px 0px 7px 0px;
  display: inline-block;
  font-size: 0.9em;
  margin: ${({ margin }) => margin ? margin : '0px'};
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  @media print {
    padding-top: 4px
  }
`;

interface Props {
    type?: string;
    margin?: string;
}

const OrderTag = ({type = PRICE_TYPE.PURCHASE, margin='10px 0px'}: Props) => {
    return (
    <Tag margin={margin} type={type}>{mapConsignmentTypeLabel(type)}</Tag>
    );
}

export default OrderTag;