import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: Math.floor(window.innerWidth / 220)
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: Math.floor(window.innerWidth / 220)
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: Math.floor(window.innerWidth / 220)
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: Math.floor(window.innerWidth / 220)
  }
};

interface Props {
  children: React.ReactNode;
}

const CustomLeftArrow = ({ onClick }: { onClick?: () => void }) => (
  <button
    className="custom-arrow custom-arrow-left"
    onClick={onClick}
    style={{
      position: 'absolute',
      left: '10px',
      top: '40%',
      transform: 'translateY(-50%)',
      zIndex: 100,
      background: 'rgba(0, 0, 0, 0.5)',
      border: 'none',
      borderRadius: '50%',
      padding: '10px',
      cursor: 'pointer',
    }}
  >
    <ArrowBackIos style={{ color: '#fff', fontSize: '20px', margin: '0px 0px 0px 5px' }} />
  </button>
);

const CustomRightArrow = ({ onClick }: { onClick?: () => void }) => (
  <button
    className="custom-arrow custom-arrow-right"
    onClick={onClick}
    style={{
      position: 'absolute',
      right: '10px',
      top: '40%',
      transform: 'translateY(-50%)',
      zIndex: 100,
      background: 'rgba(0, 0, 0, 0.5)',
      border: 'none',
      borderRadius: '50%',
      padding: '10px',
      cursor: 'pointer',
    }}
  >
    <ArrowForwardIos style={{ color: '#fff', fontSize: '20px', margin: '0px 2px' }} />
  </button>
);

const Carousal = ({ children }: Props) => {
  return (
    <Carousel
      responsive={responsive}
      containerClass="carousel-container"
      customLeftArrow={<CustomLeftArrow />}
      customRightArrow={<CustomRightArrow />}
    >
      {children}
    </Carousel>
  );
};

export default Carousal;
