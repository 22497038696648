import React from 'react';
import styled from 'styled-components';
import Vendor from 'data/Vendor';
import adminImage from 'images/vendorSelector/admin.png';
import { Main } from 'styles/themeColors';

const StyledVendorSelectorLogo = styled.div<any>`
  position: fixed;
  width: 100px;
  height: 102px;
  background-color: ${Main.brandDark};
  margin: 17.32px 0;
  left: calc(100vw - 130px);
  bottom: 10px;
  background: url(${({ vendorImage }) => vendorImage}) 0% 0% no-repeat padding-box;
  background-size: contain;
  cursor: pointer;
  z-index: 100;
`;

export interface Props {
  onClick: () => void;
  selectedVendor: Vendor;
}

const VendorSelectorLogo = ({ onClick, selectedVendor }: Props) => {
  const [vendorImage, setVendorImage] = React.useState<string>(''); // State to hold the image URL

  React.useEffect(() => {
    // Dynamically import the image based on the selected vendor code
    if(selectedVendor && selectedVendor.code) {
      import(`images/vendorSelector/${selectedVendor.code}.png`)
        .then((image) => {
          setVendorImage(image.default)
        })
        .catch((error) => {
          console.error('Error loading image:', error);
          setVendorImage(adminImage);
        });
    } else {
      setVendorImage(adminImage);
    }
  }, [selectedVendor]);

  return (
    <StyledVendorSelectorLogo onClick={onClick} vendorImage={vendorImage} />
  );
};

export default VendorSelectorLogo;
