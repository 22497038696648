import React, { useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';
import { Color, Size, Percent, FontWeight } from 'lib/styles';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { renderToast } from 'components/UI/ToastNotification';
import Attribute, * as Attr from 'data/Attribute';
import { useAuth } from 'lib/Auth';
import { mergeRequest } from 'requests/atribute';
import { Background, Main } from 'styles/themeColors';

const AttributesContainer = styled.div`
  margin: 10px;
  padding: 5px;
  display: flex;
  width: ${Percent.full};
`
const ModalTitle = styled.div`
  text-align: center;
  vertical-align: middle;
  font-weight: ${FontWeight.bold};
  font-size: ${Size.large};
  color: ${Main.accent};
  width: ${Percent.full};
`
const StyledLabel = styled.label`
  font-weight: ${FontWeight.bold};
  font-size: 14pt;
  width: ${Percent.full};
`
const ListItem = styled.div<any>`
  margin: 0px;
  padding: 5px;
  width: ${Percent.full};
  display: block;
  border: 1px solid ${Color.veryLightGray};
  cursor: pointer;
  font-size: ${Size.medium};
  font-weight: ${FontWeight.bold};
  background-color: ${Background.white};
  border-bottom: 1px solid ${Color.veryLightGray};;

  &:hover {
    border-color: ${Main.accent};
  }

  &:active {
    -webkit-box-shadow: inset 0px 0px 10px ${Color.darkGray};
  }

  ${({ active }) => active && `
    background: ${Color.pattensBlue};
    -webkit-box-shadow: inset 0px 0px 5px ${Color.darkGray};
    -moz-box-shadow: inset 0px 0px 5px ${Color.darkGray};
    box-shadow: inset 0px 0px 5px ${Color.darkGray};
    outline: none;
  `}
`;

interface Props {
  onClose: () => void;
  valuesToMerge?: Attr.AttributeValue[];
  attributesToMerge?: Attribute[];
  mergeValues?: (newValue: Attr.AttributeValue) => void;
  mergeAttributes?: (code: string, name: string) => void;
  showModal: boolean;
};

export default function MergeAttributesModal({ onClose, valuesToMerge, attributesToMerge, mergeValues, mergeAttributes, showModal }: Props) {
  const [newCode, setNewCode] = useState<string>('');
  const [newName, setNewName] = useState<string>('');
  const [active, setActive] = useState<number[][]>([[-1], [-1]]);
  const { token } = useAuth();
  let buttonContainer = null;
  let attributes = null;

  function selectNewCode(index: number, val: string) {
    setNewCode(val)
    let newActive = [...active];
    newActive[0][0] = index;
    setActive(newActive)
  }

  function handleClick() {
    if (valuesToMerge) {
      mergeRequest(token!, { mergeInId: valuesToMerge[0].id!, code: newCode, name: newName, mergeFromId: valuesToMerge[1].id! }, 'attributeValue').then(res => {
        renderToast(toast.TYPE.SUCCESS, 'Values merged successfully.');
        mergeValues!({ id: valuesToMerge[0].id, code: newCode, name: newName, order: 0, attributeId: 0 })
      }).catch(err => {
        renderToast(toast.TYPE.ERROR, err.message);
      });
      onClose();
    }
    if (attributesToMerge) {
      const mergeInIndex = attributesToMerge.findIndex(el => el.code === newCode);
      mergeRequest(token!, { mergeInId: attributesToMerge[mergeInIndex].id!, code: newCode, name: newName, mergeFromId: attributesToMerge[1 - mergeInIndex].id! }, 'attributes').then(res => {
        renderToast(toast.TYPE.SUCCESS, 'Attributes merged successfully.');
        mergeAttributes!(newCode, newName)
      }).catch(err => {
        renderToast(toast.TYPE.ERROR, err.message);
      });
      onClose();
    }
  }

  function selectNewName(index: number, val: string) {
    setNewName(val)
    let newActive = [...active];
    newActive[1][0] = index;
    setActive(newActive)
  }

  if (attributesToMerge) {
    if (attributesToMerge.length > 0) {
      buttonContainer = (
        <FlexboxContainer margin="10px" justifyContent="center">
          <Button
            onClick={onClose}
            isGhost
            color={Main.accent}
            margin="0 10px 0 0"
          >
            Cancel
          </Button>
          <Button
            disabled={(newCode === '' || newName === '')}
            onClick={() => handleClick()}
          >
            Merge
          </Button>
        </FlexboxContainer>
      );

      attributes = attributesToMerge.map((val, i) =>
      (
        <AttributesContainer key={i}>
          <ListItem active={active[0][0] === i} onClick={() => selectNewCode(i, val.code)}>
            <StyledLabel>code: </StyledLabel>
            <p>
              {val.code}
            </p>
          </ListItem>
          <ListItem active={active[1][0] === i} onClick={() => selectNewName(i, val.name)}>
            <StyledLabel>name: </StyledLabel>
            <p>
              {val.name}
            </p>
          </ListItem>
        </AttributesContainer>
      )
      )
    }
  }

  if (valuesToMerge) {
    if (valuesToMerge.length > 0) {
      buttonContainer = (
        <FlexboxContainer margin="10px" justifyContent="center">
          <Button
            onClick={onClose}
            isGhost
            color={Main.accent}
            margin="0 10px 0 0"
          >
            Cancel
          </Button>
          <Button
            disabled={(newCode === '' || newName === '')}
            onClick={() => handleClick()}
          >
            Merge
          </Button>
        </FlexboxContainer>
      );

      attributes = valuesToMerge.map((val, i) =>
      (
        <AttributesContainer key={i}>
          <ListItem active={active[0][0] === i} onClick={() => selectNewCode(i, val.code)}>
            <StyledLabel>code: </StyledLabel>
            <p>
              {val.code}
            </p>
          </ListItem>
          <ListItem active={active[1][0] === i} onClick={() => selectNewName(i, val.name)}>
            <StyledLabel>name: </StyledLabel>
            <p>
              {val.name}
            </p>
          </ListItem>
        </AttributesContainer>
      )
      )
    }
  }

  let mergedItem = null;
  if (newCode !== '' || newName !== '') {
    mergedItem =

      <AttributesContainer>
        <ListItem>
          <StyledLabel>code: </StyledLabel>
          <p>
            {newCode}
          </p>
        </ListItem>
        <ListItem>
          <StyledLabel>name: </StyledLabel>
          <p>
            {newName}
          </p>
        </ListItem>
      </AttributesContainer>
  }

  return (
    <Modal width={'600px'} title='' onClose={onClose} showButtons={false} showModal={showModal}>
      <ModalTitle>Select combination:</ModalTitle>
      <FlexboxContainer padding="30px" width="100%" flexDirection="column" alignItems="center" justifyContent="center">
        <FlexboxContainer width="100%" flexDirection="column">
          {attributes}
        </FlexboxContainer>
        {mergedItem != null ? <ModalTitle>Merged preview: </ModalTitle> : null}
        <FlexboxContainer width="100%" flexDirection="column">
          {mergedItem}
        </FlexboxContainer>
      </FlexboxContainer>
      {buttonContainer}
    </Modal>
  );
};
