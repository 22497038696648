import { Text } from 'styles/themeColors';

const productColumns = [
  {
    title: 'Product',
    field: 'product',
    headerStyle: { color: Text.primary },
    width: '55%'
  },
  {
    title: 'Quantity',
    field: 'quantity',
    headerStyle: { color: Text.primary },
    width: '15%'
  },
  {
    title: 'Unit price',
    field: 'unitPrice',
    headerStyle: { color: Text.primary },
    width: '15%'
  },
  {
    title: 'Subtotal',
    field: 'subTotal',
    headerStyle: { color: Text.primary },
    width: '15%'
  }
];

export default productColumns;

export const PRODUCT_CONSTANTS = {
  PICKUP_VALIDATE_MESSAGE:'You can not sell pickup-only product from shipping-only warehouses. Please align delivery options of your warehouses and products.',
  WAREHOUSE_REMOVAL_VALIDATE_MESSAGE:`All settings for this warehouse will be deleted from every variant of this product, i.e. warehouse availability, warehouse lead days, etc.`,
  UNDONE: `This can not be undone.`,
  HEADER_MODAL_WAREHOUSE:'Remove product from warehouse?',
  ALLOW_POST_ORDER_SHIPPING:'Allow post-order shipping cost for this product',
  DELIVERY_LEAD_DAYS_TEXT:'Can be overridden on warehouse and variant levels',
  PICKUP_CHECKBOX_LABEL: 'This product is pickup only (can not be shipped)',
  PRODUCT_AVAILABILITY_MESSAGE: 'This product is available at the following warehouses:',
  WAREHOUSE_SELECTION_WARNING: 'At least 1 warehouse has to be selected',
  WAREHOUSE_SELECTION_WARNING_TOAST: 'Please select at least one warehouse before proceeding',
  BRAND_SELECTION_WARNING: 'Please select brand before proceeding',
  SAVE_WARNING: 'Please save a product before navigating to variants/warehouses',
  DELETE_PRODUCT_MEDIA: 'Delete product media?',
  ERROR: 'Error',
  SUPPORTING_DOCS_TOOLTIP: 'Upload any supporting documents you have for the product',
  VIDEOS_TOOLTIP: 'Add product videos',
  SECONDARY_IMAGES_TOOLTIP: 'Upload any additional images you have for the product'
}

export const WAREHOUSE_DELIVERY_OPTIONS = {
  SHIPPING_AND_PICKUP: 'Shipping & pickup',
  SHIPPING_ONLY: 'Shipping only',
  PICKUP_ONLY: 'Pickup only'
};

export const PRODUCT_VARIANT_COLUMNS = ['SKU', 'Vendor Part Number', 'Purchase', 'Item Type', 'Hidden', 'Weight'];

export const PRODUCT_VARIANT_COLUMNS_WITH_MATERIAL_NUMBER = ['SKU', 'Vendor Part Number', 'Purchase', 'Item Type', 'Hidden', 'Weight', 'Material Number'];

export const PRODUCT_VARIANT_COLUMNS_WITH_RENT_AND_MATERIAL = ['SKU', 'Vendor Part Number', 'Purchase', 'Rent', 'Item Type', 'Hidden', 'Weight', 'Material Number'];

export const PRODUCT_VARIANT_COLUMNS_WITH_RENT_WITHOUT_MATERIAL = ['SKU', 'Vendor Part Number', 'Purchase', 'Rent', 'Item Type', 'Hidden', 'Weight'];
