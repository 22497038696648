import { MAX_RESOURCE_TITLE_LENGTH } from "constants/resources";
import { getYoutubeVideoId } from "./formatters";

const YOUTUBE_THUMBNAIL_URL = process.env.REACT_APP_YOUTUBE_THUMBNAIL_URL;

export const parseYoutubeThumbnailUrl = (url: string) => {
    const videoId = getYoutubeVideoId(url);
    if(videoId){
        return YOUTUBE_THUMBNAIL_URL?.replace('{videoId}', videoId);
    }
    return null;
}

export const truncateTitle = (title: string) => {
   if(title) return title.length > MAX_RESOURCE_TITLE_LENGTH ? `${title.slice(0, MAX_RESOURCE_TITLE_LENGTH - 3)}...` : title;
   return '';
}