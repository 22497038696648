import { VariantErrorType } from 'data/ProductValidation';
import { MAX_PRODUCT_NAME_LENGTH } from './product';

export const productDetailsTab = (detailsUrl:string, product: any, isWarehousesEdited: boolean, saveDisabled: boolean) =>{
    return(
        [
            {
              badge: { content: '', color: '', backgroundColor: '' },
              value: 'Details',
              link: `${detailsUrl}`
            },
            {
              badge: { content: '', color: '', backgroundColor: '' },
              value: 'Warehouses',
              link: product && (!product.brand || !product.id || saveDisabled) ? `` : `${detailsUrl}/warehouses`
            },
            {
              badge: { content: '', color: '', backgroundColor: '' },
              value: 'Variants',
              link: product && (!product.brand || !product.id || !product.warehouses.length || isWarehousesEdited || saveDisabled) ? `` : `${detailsUrl}/variants`

      },
    ]
  )
}

export const TEXT_LABELS = {
  PRODUCT_SUBMITTED_MESSAGE: 'Product submitted successfully.',
  PRODUCT_VALIDATION_START_MESSAGE:
    'Validation has been started. This may take a few minutes.',
  VARIANTS: 'variants',
  DETAILS: 'details',
  EDIT_ATTRIBUTES: 'Edit attributes',
  COLUMN_SETTING: 'Column Settings',
  SUBMIT_CONFIRMATION: 'Are you sure you want to submit this product?',
  NEW_PRODUCT: 'newProduct',
  FULFILLED : 'fulfilled',
  PUBLISHED : 'published',
  PRODUCTS:'products',
  IMPORT_XLSX :'Import XLSX',
 DRAFT : 'draft',
  PREVIEW:'Preview',
  C_PREVIEW:'preview',
 READY : 'ready',
 TOAST_ID : 'submitProduct',
 PRODUCT_SUBMITING :'The product is being submitted, please wait...',
 SELECT_VENDOR_TO_SUBMIT:'Please select a vendor before you try to submit a product.',
SUBMIT_PRODUCT : "Submit product",
DISCARD_PRODUCT : "Discard",
CONFRIMATION : "Confirmation Modal",
KEEP_PRODUCT : "Keep",
KEEP_OR_DISCARD: 'Do you want to keep warehouse changes?',
SAVE_PRODUCT: 'saveProduct',
PRODUCT_SAVING: 'The product is being saved, please wait...',
DRAFT_SAVED_SUCCESS:'Draft saved successfully.',
SELECT_VENDOR_BEFORE_SAVE: 'Please select a vendor before you try to save a product.',
SAVE: 'Save',
SUBMIT : 'Submit',
PRODUCT_MAY_NOT_PUBLISHED: 'This product may not be published until all issues have been resolved',
CHECK_BOX : 'checkbox',
ERRORS:'Errors',
SKU:'SKU',
TYPE: 'Type',
CLEAR_ALL_FILTERS:'Clear all filters',
SURE_TO_DELETE_VARIANTS: 'Are you sure you want to delete selected variants?',
PRICE_PER_UNIT: 'pricePerUnit',
 SKU_SMALL: 'sku',
 MATERIAL_NUMBER : 'materialNumber',
 HIDDEN : 'hidden', 
 MISSING_VALUE:'MissingValue',
 EMPTY_ATTRIBUTE_VALUE: 'EmptyAttributeValue',
  AVAILABLE : 'available',
 DELIEVERY_LEAD_DAYS : 'deliveryLeadDays',
 POST_ORDER_SHIPPING_COST: 'postOrderShippingCost',
 INVENTORY: 'Inventory',
 WAREHOUSE: 'WAREHOUSE',
 DELETE_VARIANT : '"Delete variant"',
 SEARCH_BY_SKU: 'Search by SKU number',
 PRODUCT_HIDDEN_MESSAGE :'The product is hidden. If you want to show individual variants, please uncheck the "Hide product" checkbox on the Details page.',
 SELECT_TYPE: 'Select type',
WARNING : 'warning',
 BLACK: 'black',
 No_VARIANTS_AVAILABLE: 'No variants available',
 WAREHOUSES: 'warehouses',
ADD_WAREHOUSE : 'Add warehouse',
CREATE_WAREHOUSE : 'Create warehouse',
PURCHASE_LOG: 'Purchase logistics',
RENT_LOG: 'Rent logistics',
SEARCH_WAREHOUSE: 'Search the warehouse',
NO_WAREHOUSE_SELECTED: 'No warehouse selected ',
NO_WAREHOUSE_FOUND: 'No warehouse found.',
PURCHASE_UNIT_PRICE: 'Purchase Unit Price',
UNIT_PRICES : 'unitPrices',
RENT_RESTRICTED_STATES: 'Rent restricted states (US)',
RENT_RESTRICTED_PROVINCE: 'Rent restricted provinces (CA)',
RANGE: 'range',
RANGE_PRICE: 'rangePrice',
RANGE_UNITS: 'rangeUnits',
RENT: 'rent',
RENT_C: 'RENT',
PURCHASE: 'purchase',
PURCHASE_C: 'PURCHASE',
ADD_VIDEOS: 'Add Videos',
PRODUCT_NAME_LENGTH_ERROR: `The product name cannot exceed ${MAX_PRODUCT_NAME_LENGTH} characters. Please shorten the name and try again.`,
SAVE_PRODUCT_ERROR: 'Please fill all the required fields.',
RENT_WAREHOUSE_ERROR: 'Please add rental warehouse',
PURCHASE_WAREHOUSE_ERROR: 'Please add purchase warehouse',
BOTH_WAREHOUSE_ERROR: 'Please add purchase and rent warehouse',
LAST_US_RANGE: 'range-5-US',
LAST_CA_RANGE: 'range-5-CA',
CONSIGNMENT_TYPE: 'consignmentType'
}

export const RESTRICTED_TEXT_LABELS: { RESTRICTED_STATES_TEXT: { [key: string]: string } } = {
  RESTRICTED_STATES_TEXT: {
    'US': 'Rent restricted states (US)',
    'CA': 'Rent restricted provinces (CA)'
  }
}

export const errorMessages = {
  [VariantErrorType.DuplicateUniqueValue]: 'Duplicate SKU',
  [VariantErrorType.DuplicateVariant]: 'Duplicate variant',
  [VariantErrorType.MissingValue]: 'Missing or blank values',
  [VariantErrorType.InvalidSKUValue]: 'The value for SKU is invalid. Valid characters are A-Z a-z 0-9 . _ - \\ /. No spaces are allowed',
  [VariantErrorType.InvalidSKULength]: `The SKU should be less than ${process.env.REACT_APP_SKU_MAX_LENGTH!} characters long`,
  [VariantErrorType.SKUValidation]: `The SKU validation has been failed`,
  [VariantErrorType.EmptyAttributeValue]: `Empty Attribute value`,
  [VariantErrorType.InvalidMaterialNumber]: 'The value for SKU is invalid. Valid characters are A-Z a-z 0-9 . _ - \\ /. No spaces are allowed',
  [VariantErrorType.EmptyMaterialNumber]: 'Material number Value can not be empty',
  [VariantErrorType.InvalidMaterialNumberLength]: `The SKU should be less than ${process.env.REACT_APP_SKU_MAX_LENGTH!} characters long`,
  [VariantErrorType.DuplicateMaterialNumber]: 'Duplicate material number',
  [VariantErrorType.MaterialNumberValidation]: 'MaterialNumberValidation',
  [VariantErrorType.EmptyInventory]: 'Inventory not updated',
  [VariantErrorType.EmptySku]: 'Sku value is missing in variant',
  [VariantErrorType.RentMissingPriceUS]: 'Price is mandatory for first record',
  [VariantErrorType.RentMissingPriceCA]: 'Price is mandatory for first record',
  [VariantErrorType.RentMissingRangeUS]: 'Range is mandatory for first record',
  [VariantErrorType.RentMissingRangeCA]: 'Range is mandatory for first record',
  [VariantErrorType.RentRangeSkipped]: 'Price ranges must be populated in order',
  [VariantErrorType.RentRangeOrder]: 'Rental ranges should be in ascending order',
  [VariantErrorType.RequiredRentalRange]: 'Rental variants must have at least one price range set',
  [VariantErrorType.EmptyConsignmentType]: 'Either purchase or rent should be selected'
};

export const ROUTES = {
  PRODUCT_DETAIL: '/products/product-details/',
  PRODUCTS: '/products',
  PUBLISHED: '/products/published',
  READY: '/products/ready',
  NEW_PRODUCT: '/products/new-product'
}

export const attributeErrorMessage = (serverMessage: string = ''): string => {
  return (
    'Some attributes were omitted.' +
    (serverMessage ? ` Reason: ${serverMessage}` : '')
  );
};

export const WAREHOUSE_COLUMNS_ENABLED = ['available-', "deliveryLeadDays-", "postOrderShippingCost-"]

export const disabledColumns = [TEXT_LABELS.RANGE_UNITS, TEXT_LABELS.LAST_CA_RANGE, TEXT_LABELS.LAST_US_RANGE];