import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import * as User from 'data/User';
import Vendor from 'data/Vendor';
import SuperCategoryModal from 'admin/SuperCategoryModal';
import CategoryModal from 'admin/CategoryModal';
import Pill from 'components/UI/Pill';
import Button from 'components/UI/Button';
import Divider from 'components/UI/Divider';
import Accordion from 'components/UI/Accordion';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import PageHeading from 'components/UI/PageHeading';
import LoadingIndicator from 'components/UI/LoadingIndicator';
import { Color, Size, Percent } from 'lib/styles';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import { loadSuperCategoriesSummary } from 'requests/superCategory';
import { Background, Main } from 'styles/themeColors';

const Header = styled.header`
  border-bottom: 1px solid ${Color.darkGray};
  display: flex;
  line-height: 1;
  margin: 0px auto;
  width: ${Percent.full};
  padding: ${Size.medium} 0px;
`;

const List = styled.ul`
  margin: 0px auto;
  width: ${Percent.full};
  padding: 0px;
`;

const ListItem = styled.li`
  background-color: ${Color.whiteSmoke};
  padding: 10px;
`;

const Page = styled.div`
  margin: ${Size.large} 0px;
  width: ${Percent.full};
  padding: 0 30px;
`;

const Label = styled.span`
  width: 80px;
  text-align: center;
`;

const Actions = styled.div`
  flex-grow: 1;
  text-align: right;
`;

const AddCategoryButton = styled(Button)`
  display: inline-flex;
`;

const EditCategoryButton = styled(Button)`
  margin-left: 55px;
`;

const CategoryImage = styled.img`
  background-color: ${Background.white};
  height: 64px;
  margin-right: ${Size.medium};
  object-fit: contain;
  object-position: 50%;
  padding: ${Size.tiny};
  width: 64px;
`;

const EmptyLogo = styled.div`
  background-color: ${Color.darkGray};
  height: 64px;
  margin-right: ${Size.medium};
  padding: ${Size.tiny};
  width: 64px;
`;

const SideButton = styled(Button)`
  margin: 5px 0 !important;
`;

export interface Props {
  selectedVendor?: Vendor;
};

export default function CategoriesList() {
  const [showSuperCategoryModal, setShowSuperCategoryModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [selectedSuperCategoryId, setSelectedSuperCategoryId] = useState<number | null>(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
  const [shouldReload, setShouldReload] = useState(false);
  const { token, user } = useAuth();
  const superCategoriesReq = useAPI({ deferFn: loadSuperCategoriesSummary });
  const isVendor = User.isVendor(user!);

  useEffect(() => {
    if (!isVendor) {
      superCategoriesReq.run([token]);
    }
  }, []);

  if (shouldReload) {
    superCategoriesReq.reload();
    setShouldReload(false);
  }

  let superCategoriesList = null;
  let superCategoryModal = null;
  let categoryModal = null;

  const onEditSuperCategoryClick = (superCategoryId: number) => {
    setShowSuperCategoryModal(true);
    setSelectedSuperCategoryId(superCategoryId);
  }

  const onAddCategoryClick = (superCategoryId: number) => {
    setShowCategoryModal(true);
    setSelectedSuperCategoryId(superCategoryId);
  }

  const onEditCategoryClick = (superCategoryId: number, categoryId: number) => {
    setShowCategoryModal(true);
    setSelectedSuperCategoryId(superCategoryId);
    setSelectedCategoryId(categoryId);
  }

  const onCloseSuperCategoryClick = () => {
    setShowSuperCategoryModal(false);
    setSelectedSuperCategoryId(null);
  }

  const onCloseCategoryClick = () => {
    setShowCategoryModal(false);
    setSelectedSuperCategoryId(null);
    setSelectedCategoryId(null);
  }

  if (superCategoriesReq.isLoading) {
    superCategoriesList = <LoadingIndicator />;
  } else if (superCategoriesReq.data) {
    const superCategories = superCategoriesReq.data.map(superCategory => {
      const categories = superCategory.categories.map(category => {
        const draftsInCategory = category.draft;
        const readyInCategory = category.ready;
        const publishedInCategory = category.published;
        const categoryImage = (
          <>
            {
              category.media && category.media.length > 0
                ? <CategoryImage alt="Logo" src={process.env.REACT_APP_MEDIA_URL + category.media[0].path} />
                : <EmptyLogo />
            }
          </>
        );

        return (
          <Fragment key={category.id}>
            <ListItem>
              <FlexboxContainer
                justifyContent="space-between"
                alignItems="center"
                margin="5px 10px"
              >
                <FlexboxContainer
                  justifyContent="flex-start"
                  alignItems="center"
                  margin="5px 10px"
                >
                  {categoryImage}
                  {category.name}
                </FlexboxContainer>
                <FlexboxContainer
                  justifyContent="flex-end"
                  alignItems="center"
                  margin="5px 10px"
                >
                  <Pill theme="danger" margin="0 43px 0 0" size='s'>
                    {draftsInCategory.toString()}
                  </Pill>
                  <Pill theme="warning" margin="0 40px 0 0" size='s'>
                    {readyInCategory.toString()}
                  </Pill>
                  <Pill theme="success" margin="0" size='s'>
                    {publishedInCategory.toString()}
                  </Pill>
                  <EditCategoryButton
                    size="s"
                    onClick={() => onEditCategoryClick(superCategory.id, category.id)}
                  >
                    Edit
                  </EditCategoryButton>
                </FlexboxContainer>
              </FlexboxContainer>
            </ListItem>
            <Divider height="2px" />
          </Fragment>
        );
      });

      const productLabels = (
        <FlexboxContainer
          justifyContent="flex-end"
          alignItems="center"
          margin="5px 10px"
        >
          <Label>Draft</Label>
          <Label>Ready</Label>
          <Label>Publish</Label>
          <Label></Label>
        </FlexboxContainer>
      );

      return (
        <FlexboxContainer key={superCategory.id}>
          <Accordion
            title={superCategory.name}
            childrenLength={categories.length.toString()}
            withPill={true}
            width="85%"
          >
            {productLabels}
            {categories}
          </Accordion>
          <FlexboxContainer
            flexDirection="column"
            alignItems="flex-end"
            margin="0 10px"
          >
            <SideButton
              onClick={() => onEditSuperCategoryClick(superCategory.id)}
              isGhost
              color={Main.accent}
            >
              Manage
            </SideButton>
            <SideButton
              onClick={() => onAddCategoryClick(superCategory.id)}
              isGhost
              color={Main.accent}
            >
              Add Subcategory
            </SideButton>
          </FlexboxContainer>
        </FlexboxContainer>
      );
    });

    superCategoriesList = <List>{superCategories}</List>;

    if (showSuperCategoryModal) {
      superCategoryModal = (
        <SuperCategoryModal
          showModal={showSuperCategoryModal}
          onClose={() => onCloseSuperCategoryClick()}
          superCategories={superCategoriesReq.data}
          selectedSuperCategoryId={selectedSuperCategoryId}
          setShouldReload={setShouldReload}
        />
      );
    }

    if (showCategoryModal) {
      categoryModal = (
        <CategoryModal
          showModal={showCategoryModal}
          onClose={() => onCloseCategoryClick()}
          superCategories={superCategoriesReq.data}
          selectedSuperCategoryId={selectedSuperCategoryId}
          selectedCategoryId={selectedCategoryId}
          setShouldReload={setShouldReload}
        />
      );
    }
  }

  return (
    <Page>
      {superCategoryModal}
      {categoryModal}
      <Header>
        <PageHeading>Categories</PageHeading>
        <Actions>
          <AddCategoryButton onClick={() => setShowSuperCategoryModal(true)}>Add Category</AddCategoryButton>
        </Actions>
      </Header>
      {superCategoriesList}
    </Page>
  );
};
