import ConsignmentEntry from 'data/ConsignmentEntry';
import Order from 'data/Order';
import Warehouse from 'data/Warehouse';
import Vendor from 'data/Vendor';
import ShippingCarrier from 'data/ShippingCarrier';
import DeliveryAddress from 'data/DeliveryAddress';
import ConsignmentShortPayments from 'data/ConsignmentShortPayments';
import VendorCompany from './VendorCompany';
import ConsignmentCredit from './ConsignmentCredit';
import DowJonesResult from './DowJonesResult';
import PickupContact from './PickupContact';
import { PRICE_TYPE } from 'constants/product';
import ConsignmentModificationEntry from './ConsignmentModificationEntry';
import ConsignmentHistoryChange from './ConsignmentHistoryChange';
import Ticket from './Ticket';

export default interface Consignment {
  id: number;
  consignmentId: string;
  orderId: string;
  state: string;
  consignmentEntries: Pick<ConsignmentEntry, 'id' | 'deliveryMode' | 'committedDate' | 'manufactureDate'>[];
  consignmentShortPayments: ConsignmentShortPayments[];
  order: Pick<Order, 'orderId' | 'customer' | 'paymentType'>;
  warehouse: Pick<Warehouse, 'id' | 'name' | 'code'>;
  deliveryType: string;
  trackingId: string;
  referenceNumber: string;
  availableDate: string;
  dispatchDate: string;
  deliveryCarrier: ShippingCarrier;
  totalPrice: number;
  totalPriceWithTax: number;
  created: string;
  updatedBy: string;
  dowJonesStatus?: string;
  vendorId?: number;
  totalTax: number;
  totalDeliveryCost: number;
  vendor: Vendor;
  cancelationReason?: any;
  totalSurcharges: number;
  dowJones: DowJonesResult;
  salesOrderNumber?: string;
  countryId: number;
  modificationEntries?: ConsignmentModificationEntry[];
  consignmentHistoryChanges?: ConsignmentHistoryChange[];
  consignmentType: string;
};

export interface ConsignmentDetails {
  id: number;
  consignmentId: string;
  orderId: string;
  consignmentDate: string;
  state: string;
  previousState: string;
  deliveryType: string;
  trackingId: string;
  referenceNumber: string;
  consignmentCount: number;
  consignmentCredit: ConsignmentCredit;
  consignmentEntries: ConsignmentEntry[];
  consignmentShortPayments: ConsignmentShortPayments[];
  order: Pick<Order, 'id' | 'orderId' | 'customer' | 'paymentType' | 'cancelEmailSentDate' | 'companyId' | 'company' | 'companyName'>;
  warehouse: Pick<Warehouse, 'id' | 'name' | 'code' | 'sapPlantId'>;
  shippingCarrier: ShippingCarrier;
  availableDate: string;
  created: string;
  updated: string;
  dispatchDate: string;
  pickedUpBy: string;
  totalTax: number;
  totalDeliveryCost: number;
  totalPrice: number;
  totalPriceWithTax: number;
  subTotal: number;
  deliveryAddress: Pick<DeliveryAddress, 'id' | 'firstName' | 'lastName' | 'addressLine1' | 'addressLine2' | 'town' | 'postalCode' | 'countryIsoCode' | 'state'>;
  vendorCompanyId?: string;
  readOnly?: boolean;
  cancellationReason?: string;
  vendorId: string;
  isSlbVendor:boolean;
  vendorCompany?: VendorCompany;
  totalSurcharges: number;
  totalAdditionalShippingCost: number;
  paymentStatus: string;
  cancelationReason?: any;
  salesOrderNumber?: string;
  countryId: number;
  pickupContact: PickupContact;
  syncToSap?:string;
  consignmentType: string;
  rentalStartDate: string;
  rentalEndDate: string;
  modificationEntry: ConsignmentModificationEntry;
  consignmentHistoryChanges?: ConsignmentHistoryChange[];
  oldRentalStartDate?: string;
  oldRentalEndDate?: string;
  tickets?: Ticket[];
  weekdaysOnly: boolean;
};

export interface ConsignmentsList {
  page: number;
  totalPages: number;
  size: number;
  totalItems: number;
  consignments: Consignment[];
};

export interface ConsignmentRentDates {
  rentalStartDate: string;
  rentalEndDate: string;
  newRentalStartDate?: string;
  newRentalEndDate?: string;
}

export interface ShippingConsignmentRequest {
  shippingCarrier: ShippingCarrier;
  trackingId: string;
  deliveryDate?: string;
  attachDocuments: boolean;
  rentalNewStartDate?: string;
  rentalNewEndDate?: string;
};

export interface AdditionalShippingCostRequest {
  shippingCarrier?: ShippingCarrier;
  deliveryDate?: string;
  shippingAdditionalInfo?: string;
  totalAdditionalShippingCost: string;
};

export interface SplitConsignmentShippingCostRequest {
  state: string;
  additionalShippingCost: number;
  estimatedAvailableDate?: string;
  rentalStartDate?: number;
  rentalEndDate?: number;
  entryNumbers?: number[];
};

export interface PickupConsignmentRequest {
  warehouseId: number;
  referenceNumber: string;
  pickupDate?: string;
  attachDocuments: boolean;
  rentalNewStartDate?: string;
  rentalNewEndDate?: string;
};

export interface DispatchRequest {
  dispatchDate: string;
  pickedUpBy: string;
};

export const emptyConsignmentDetails: ConsignmentDetails = {
  id: 0,
  vendorId: '',
  consignmentId: '',
  created: '',
  updated: '',
  orderId: '',
  consignmentDate: '',
  consignmentCount: 0,
  deliveryType: '',
  state: '',
  previousState: '',
  trackingId: '',
  referenceNumber: '',
  modificationEntry: {
    id: 0,
    sequenceNumber: 0,
    consignmentId: '',
    originalStartDate: '',
    originalEndDate: '',
    newStartDate: '',
    newEndDate: '',
    requestedByEmail: '',
    requestedBy: '',
    status: '',
    message: '',
    initiatedFrom: '',
    originalDeliveryDate: '',
    newDeliveryDate: ''
  },
  consignmentEntries: [],
  consignmentCredit: {
    id: 0,
    vendorCompanyId: '',
    vendorCompanyCreditId: '',
    orderId: '',
    consignmentId: '',
    totalCreditLimit: 0,
    availableCreditLimit: 0,
    overrideAmount: 0,
    createdBy: '',
    status: '',
    vendorCompanyCredit: {
      id: 0,
      availableCreditLimit: 0,
      currency: '',
      limitLastModified: '',
      readonly: false,
      riskClass: '',
      riskClassDescription: '',
      riskClassLastModified: '',
      segmentId: '',
      totalCreditLimit: 0,
      vendorCompanyId: ''
    }
  },
  consignmentShortPayments: [],
  order: {
    id: 0,
    orderId: '',
    paymentType: '',
    companyName: '',
    customer: {
      id: 0,
      customerId: '',
      firstName: '',
      lastName: '',
      fullName: '',
      email: '',
      phone: '',
      companyName: ''
    }
  },
  warehouse: {
    id: 0,
    name: '',
    code: '',
    sapPlantId: ''
  },
  shippingCarrier: {
    id: 0,
    name: ''
  },
  cancelationReason: '',
  availableDate: '',
  dispatchDate: '',
  pickedUpBy: '',
  totalTax: 0,
  totalDeliveryCost: 0,
  totalPrice: 0,
  totalPriceWithTax: 0,
  subTotal: 0,
  totalSurcharges: 0,
  totalAdditionalShippingCost: 0,
  paymentStatus: '',
  deliveryAddress: {
    id: 0,
    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    town: '',
    postalCode: '',
    countryIsoCode: '',
    state: ''
  },
  salesOrderNumber:'',
  countryId: 0,
  isSlbVendor: false,
  pickupContact: {
    id: 0,
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  },
  consignmentType: PRICE_TYPE.PURCHASE,
  rentalEndDate: '',
  rentalStartDate: '',
  weekdaysOnly: false
};

export type ConsignmentKeys = keyof Consignment;
