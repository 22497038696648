import TicketHistory from "./TicketHistory";


export default interface Ticket {
    id: number;
    code: string;
    description: string;
    consignmentId: number;
    categoryId: string;
    rentalStartDate: string;
    rentalEndDate: string;
    modifiedRentalStartDate: string;
    modifiedRentalEndDate: string;
    status: TicketStatus;
    entries: TicketHistory[];
    deliveryDate: string;
    originalDeliveryDate: string;
    newDeliveryDate: string;
}

export enum TicketStatus {
    OPENED = "OPENED",
    CLOSED = "CLOSED",
    RESOLVED = "RESOLVED",
    REJECTED = "REJECTED"
}