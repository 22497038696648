import Icon from 'view/Icon';
import Product, * as Prod from 'data/Product';
import React, { useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
import Variant from 'data/Variant';
import VariantTableRow, { Row } from 'common/VariantTableRow';
import { Color, Size } from 'lib/styles';
import { Main } from 'styles/themeColors';
import { fieldName, getBasicColumns } from 'utils/variant';
import FeatureFlag from 'data/FeatureFlag';
import Warehouse from 'data/Warehouse';
import Country from 'data/Country';
import ProductStock from 'data/ProductsStock';
import Currency from 'data/Currency';
import { useAuth } from 'lib/Auth';
import Vendor from 'data/Vendor';


const BaseTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  color: ${Main.accent};
  text-align: left;
  width:100%;

`;

const Container = styled.div`
  -webkit-overflow-scrolling: touch;
  margin-left: -2.25rem;
  margin-top: ${Size.medium};
  overflow-x: auto;
  display: flex;
  overflow-y: auto;
  max-height: 70vh;
`;

const EditIcon = styled(Icon).attrs({ name: 'edit', size: 14 })`
  color: ${Main.accent};
`;

const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  margin-left: ${Size.medium};
  margin-top: -0.1em; /* adjustment to align with text properly */
  padding: 0;
`;

const Th = styled.th<any>`
  background-color: ${Color.lightBlue};
  padding: ${Size.tiny} ${Size.small};
  position: relative;
  white-space: nowrap;
  left: 0;
  border-top: 1px solid ${Main.accent};
  border-left: 1px solid ${Main.accent};
`;

const ThParent = styled.th<any>`
  background-color: ${Color.lightBlue};
  padding: ${Size.tiny} ${Size.small};
  position: relative;
  white-space: nowrap;
  left: 0;
  border-top: 1px solid ${Main.accent};
  border-left: 1px solid ${Main.accent};
  
`;

const ThAction = styled.th`
  font-size: 0;
`;

const ThContentsParent = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ThContents = styled.div`
  align-items: center;
  display: flex;
`;

const Headers = styled.tr`
  th:last-child {
    border-left: 1px solid ${Main.accent};
  }
`;

const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #ffffff;
`;

export interface Column {
  key: string;
  label: string;
  keyId?: number;
  isWarehouseColumn?: boolean;
  isUnitPriceColumn?: boolean;
  isRentalColumn?: boolean;
  name?: string;
  id?: number;
  warehouse?: any,
};

export interface Props {
  classifications: Prod.ProductAttribute[];
  focusedMode: boolean;
  isErrorColumn: (key: string, sku: string) => boolean;
  onAttributeEdit: (key: number, headerKey: string) => void;
  onCellEdit: (
    newAttributeValue: string | number | boolean,
    variant: Variant,
    attributeKey: string,
    keyId?: number
  ) => void;
  onRowDelete: (variant: Variant) => void;
  rows: Row[];
  attributes: Prod.ProductAttribute[];
  suggestionValuesList: string[];
  clearSuggestions: () => void;
  originalId?: number;
  product: Product;
  disabled?: boolean;
  publishedSKUList: string[];
  featureFlags: FeatureFlag;
  currencies: Currency[];
  selectedVendor: Vendor;
  stockFlag?: boolean;
};


const getColumnMetaData = (key: any) => {
  if (key instanceof Object) {
    return {
      key: key.name,
      label: fieldName(key.name!),
      keyId: key.id
    };
  } else {
    return ({
      key,
      label: fieldName(key),
    });
  }
}


const getFieldNameForWarehouseColumn = (key: string) => {
  switch (key) {
    case 'available':
      return 'In stock'
    case 'deliveryLeadDays':
      return 'Delivery lead days'
    case 'postOrderShippingCost':
      return 'Post order shipping cost'
    default:
      return key
  }
}


const getColumnMetaDataForWarehouse = (key: string, label: string) => {
  return {
    key,
    label,
    isWarehouseColumn: true,
  }
}


export default function Table({
  classifications,
  focusedMode,
  isErrorColumn,
  onAttributeEdit,
  onCellEdit,
  onRowDelete,
  rows,
  suggestionValuesList,
  attributes,
  clearSuggestions,
  originalId,
  product,
  disabled,
  publishedSKUList,
  currencies,
  selectedVendor,
  stockFlag
}: Props) {

  const { user } = useAuth();
  const [isMounted, setIsMounted] = React.useState(false);
  const productStocks = product.variants?.map((variant: Variant) => variant.productStocks).flat() || []
  const selectedWarehouses = product.warehouses
  const warehouseCountries = selectedWarehouses.map((warehouse: Prod.ProductWarehouse) => warehouse.address.country)

  // Filtering duplicate countries
  const availableCountries = warehouseCountries.filter((country: Country, index: number, self: any) =>
    index === self.findIndex((t: any) => (
      t.countryName === country.countryName
    ))
  ).map((country: Country) => {
    const currency = currencies.find((currency: Currency) => currency.countryId === country.id)
    return { ...country, currency }
  })

  const parentHeaders = useMemo(() => {
    const basicColumns = getBasicColumns(product.brand?.brandVendor!, selectedVendor.isSlbVendor, user?.role);
    let warehouseColumnsKeys = ['available', 'Inventory', 'deliveryLeadDays'];

    const unitPricesColumns: Column[] = availableCountries.map(country =>
    ({
      label: `${country.countryName} price (${country.currency?.currencyIso})`,
      key: `pricePerUnit-${country.id}`,
      isUnitPriceColumn: true,
    }))


    const warehouseColumns = selectedWarehouses.map((warehouse) => {
      // TODO: Remove the postOrderShippingCost key from warehouseColumnKeys if warehouse is pickup only
      if (warehouse.pickup && !warehouse.delivery) {
        warehouseColumnsKeys = warehouseColumnsKeys.filter(key => key !== 'postOrderShippingCost')
      } else {
        if (!warehouseColumnsKeys.includes('postOrderShippingCost') && product.postOrderShippingCost)
          warehouseColumnsKeys.push('postOrderShippingCost')
      }
      // stockFlag is true when view is not for published product
      // Remove key Inventory if StockFlag is true and Stock API is disabled
      if (stockFlag || !product.brandVendor.isStockApiEnabled) {
        warehouseColumnsKeys = warehouseColumnsKeys.filter(key => key !== 'Inventory')
      }
      return ({
        key: warehouse.name,
        name: `"${warehouse.address.countryIsoCode}-${warehouse.name}" WAREHOUSE`,
        colSpan: warehouseColumnsKeys.length,
        columns: warehouseColumnsKeys.map(key => {
          const label = `${getFieldNameForWarehouseColumn(key)} "${warehouse.address?.countryIsoCode}-${warehouse.name}" warehouse`

          // This key is a unique identifier for the warehouse columns
          key = key + `-${warehouse.id}`
          return getColumnMetaDataForWarehouse(key, label)
        })
      })
    })

    const columns = [
      {
        key: 'basic',
        name: 'BASIC',
        colSpan: basicColumns.length,
        columns: basicColumns.map(key => getColumnMetaData(key))
      },
      {
        key: 'selectableAttributes',
        name: 'SELECTABLE ATTRIBUTES',
        colSpan: attributes.length,
        columns: attributes.map(key => getColumnMetaData(key))
      },
      {
        key: 'tehcnicalSpecifications',
        name: 'TECHNICAL SPECIFICATIONS',
        colSpan: classifications.length,
        columns: classifications.map(key => getColumnMetaData(key))
      }
    ]


    if (warehouseColumns.length) columns.push(...warehouseColumns)

    if (unitPricesColumns.length) {
      const unitPriceColumn = {
        key: 'unitPrices',
        name: 'UNIT PRICE',
        colSpan: unitPricesColumns.length,
        columns: unitPricesColumns
      }
      columns.push(unitPriceColumn)
    }

    return columns
  }, [isErrorColumn, selectedWarehouses, product.postOrderShippingCost, product.variants, availableCountries, attributes, classifications, selectedVendor, product.brand]);


  const columns: any[] = parentHeaders.map(header => header.columns).flat()

  const columnHeaders = columns.map(({ key, label, isWarehouseColumn, isUnitPriceColumn }, i) => {
    const foundAttribute = attributes.find(attribute => attribute.name === key);
    const foundClassification = classifications.find(classification => classification.name === key);
    const attributeId = foundAttribute ? foundAttribute.id : foundClassification ? foundClassification.id : 0;

    const variantKey = product.variants.length > 0 && Object.keys(product.variants[0]).includes(key);


    let editButton =
      (
        <EditButton onClick={() => onAttributeEdit(attributeId, variantKey ? key : '')} disabled={disabled}>
          <EditIcon />
        </EditButton>
      )


    const hideEditButton = key === 'hidden' || key === 'postOrderShippingCost' || isUnitPriceColumn || isWarehouseColumn
    return (
      <Th orderIndex={i} key={i}>
        <ThContents>
          {label}
          {!hideEditButton ? editButton : null}
        </ThContents>
      </Th>
    )
  });


  const bodyRows = rows.map((row, i) => (
    <VariantTableRow
      key={i}
      columns={columns}
      errorInventoryTypes={row.errorInventoryTypes}
      errorTypes={row.errorTypes}
      stockFlag={stockFlag}
      focused={row.focused}
      focusedMode={focusedMode}
      onRowDelete={onRowDelete}
      onCellEdit={onCellEdit}
      showDeleteButton={i + 1 < rows.length}
      variant={row.variant}
      suggestionValuesList={suggestionValuesList}
      attributes={attributes}
      classifications={classifications}
      clearSuggestions={clearSuggestions}
      originalId={originalId}
      product={product}
      disabled={disabled}
      publishedSKUList={publishedSKUList}
      selectedWarehouses={selectedWarehouses}
      productStocks={productStocks}
      availableCountries={availableCountries}
      isErrorColumn={isErrorColumn}
    />
  ));

  const parentColumns = <Headers>
    <ThAction></ThAction>
    {

      parentHeaders.map(ph => ph.columns.length ? (
        <ThParent key={ph.key} colSpan={ph.colSpan}>
          <ThContentsParent>
            {ph.name}
          </ThContentsParent>
        </ThParent>
      ) : null
      )
    }
    <ThAction></ThAction>

  </Headers>


  const childColumns = <Headers>
    <ThAction>Errors</ThAction>
    {columnHeaders}
    <ThAction>Errors</ThAction>
    {/* <ThAction>Delete row</ThAction> */}
  </Headers>

  useEffect(() => {
    const tableContainer = document.querySelector('.table-container');
    if (tableContainer && isMounted) {
      tableContainer.scrollTo({
        top: tableContainer.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [rows.length]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <Container className='table-container'>
      <BaseTable>
        <Thead>
          {parentColumns}
          {childColumns}
        </Thead>
        <tbody>{bodyRows}</tbody>
      </BaseTable>
    </Container>
  );
};
