import React, {ButtonHTMLAttributes} from 'react';
import styled from 'styled-components';
import { Space } from 'styles/themeSpaces';
import { Text } from 'styles/themeColors';
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';
import Icon from 'view/Icon';
import Button from 'components/UI/Button';
import { PricebookState } from 'data/Pricebook';
import { formatDateTime } from 'utils/formatters';


const ButtonIcon = styled(Icon).attrs({ size: 16 }) <any>`
  color: #666c73;
`;
const ButtonIconLarge = styled(Icon).attrs({ size: 24 }) <any>`
  color: #666c73;
`;

const ColumnName = styled.div`
  width: 160px;
  font-size: ${Space.sp2x};
  line-height: 24px;
  color: ${Text.primary};
  padding-top: ${Space.spHalf};
  margin-right: ${Space.sp3x};
`;

const DownloadTemplate = styled.a`
  display: flex;
  height: 36px;
  background-color: transparent !important;
  color: #666c73 !important;
  border-color: #c1c9d1 !important;
  cursor: pointer;
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
`;

const GhostButton = styled.button<ButtonHTMLAttributes<HTMLButtonElement>>`
  margin-right: 4px;
  display: flex;
  height: 36px;
  background-color: ${props => (props.disabled ? '#f5f5f5 !important' : 'transparent !important')};
  color: ${props => (props.disabled ? '#999999 !important' : '#666c73 !important')};
  border-color: ${props => (props.disabled ? '#dcdcdc !important' : '#c1c9d1 !important')};
  cursor: ${ prop => (prop.disabled ? 'not-allowed !important' : 'pointer !important')};
  border-radius: 6px;
  padding: 10px 12px;
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid;
  align-items: center;
`;
const ColumnValue = styled.div<any>`
  font-size: ${({ $fontSize }) => ($fontSize ? $fontSize : Space.sp2x)};
  line-height: 20px;
  background-color: ${({ $bgcolor }) => ($bgcolor ? $bgcolor : 'transparent')};
  padding: ${({ $padding }) => ($padding ? $padding : '0px')};
  color: ${({ $color }) => ($color ? $color : Text.primary)};
  font-weight: ${({ $weight }) => ($weight ? $weight : '400')};
  border-radius: 6px;
`;

const DeleteMessage = styled.p`
  text-align: center;
  font-size: 14px;
  color: #c82135;
  font-weight: 700;
`;

interface Props {
  pricebook: any;
  onDeleteConfirmPricebookHandler: any;
  onCancelDeletionPricebookHandler: any;
  onDeleteNowPricebookHandler: any;
  hideReplaceButton?: boolean;
  hideDeleteButton?: boolean;
  isLoading?: boolean;
  isAdmin?: boolean;
  onReplaceClick?: (pricebookId: number) => void;
}

const PricebooksPreview = ({
 pricebook,
 onDeleteConfirmPricebookHandler,
 onCancelDeletionPricebookHandler,
 onDeleteNowPricebookHandler,
 hideReplaceButton = false,
 hideDeleteButton = false,
 isLoading,
 isAdmin,
 onReplaceClick,
}: Props) => {
  const publishedButtons = (
    <FlexboxContainerComponent
      margin="16px 0px 0px 0px"
      flexDirection="row"
      justifyContent="space-between"
    >
      <FlexboxContainerComponent>
        <DownloadTemplate
          href={
            process.env.REACT_APP_MEDIA_URL +
            "/pricebooks/" +
            pricebook.pathToFile
          }
        >
          <FlexboxContainerComponent>
            <ButtonIcon name="FileDownloadIcon" />{' '}
            <FlexboxContainerComponent>
              Download as .xlsx
            </FlexboxContainerComponent>
          </FlexboxContainerComponent>
        </DownloadTemplate>
      </FlexboxContainerComponent>    
        <FlexboxContainerComponent>
        {!hideDeleteButton &&
          <GhostButton onClick={onDeleteConfirmPricebookHandler}>
            {/* <ButtonIcon name='DeleteIconPricebooks' /> */}
            <FlexboxContainerComponent>Delete</FlexboxContainerComponent>
          </GhostButton>
        }
        {!hideReplaceButton &&
          <Button
            margin='0 4px 0 0'
            borderColor={Text.primary}
            bgColor={Text.primary}
            onClick={() => {
              if (onReplaceClick) {
                onReplaceClick(pricebook?.id || 0)
              }
            }}
          >
            Replace
          </Button>
        }
        </FlexboxContainerComponent>   
    </FlexboxContainerComponent>
  )

  const deleteButtons = (
    <FlexboxContainerComponent
      margin="16px 0px 0px 0px"
      flexDirection="row"
      justifyContent="space-between"
    >
      <FlexboxContainerComponent>
        <GhostButton disabled={isLoading} onClick={onCancelDeletionPricebookHandler}>
          <FlexboxContainerComponent>Cancel deletion</FlexboxContainerComponent>
        </GhostButton>
      </FlexboxContainerComponent>
      {isAdmin &&
        <FlexboxContainerComponent>
          <GhostButton disabled={isLoading} onClick={onDeleteNowPricebookHandler}>
            <ButtonIconLarge name="PricebookDeleteWarning" />
            <FlexboxContainerComponent>Delete now</FlexboxContainerComponent>
          </GhostButton>
        </FlexboxContainerComponent>
      }
    </FlexboxContainerComponent>
  )

  return (
    <FlexboxContainerComponent
      border={
        pricebook.priceBookState == PricebookState.PUBLISHED ? '1px solid #2CAE44' :
          pricebook.priceBookState == PricebookState.DELETE ? '1px solid #C82135' :
            '1px solid #2CAE44'
      }
      flexDirection="column"
      padding="24px"
      borderRadius="6px"
      margin="8px 0px"
    >
      <FlexboxContainerComponent alignItems="center" flexDirection="row">
        <ColumnValue
          $bgcolor={
            pricebook.priceBookState == PricebookState.PUBLISHED ? '#2CAE44' :
              pricebook.priceBookState == PricebookState.DELETE ? '#C82135' :
                '#2CAE44'
          }
          $weight="700"
          $padding="0px 4px"
          $color="#FFF"
          $fontSize="12px"
        >
          ACTIVE PRICEBOOK
        </ColumnValue>
      </FlexboxContainerComponent>
      <FlexboxContainerComponent margin='16px 0px 0px 0px' alignItems="center" flexDirection="row">
        <ColumnName>Name</ColumnName>
        <ColumnValue>{pricebook ? pricebook.priceBookName : ''}</ColumnValue>
      </FlexboxContainerComponent>
      <FlexboxContainerComponent alignItems="center" flexDirection="row">
        <ColumnName>ID</ColumnName>
        <ColumnValue>
          {pricebook ? pricebook.priceBookVendorId : ''}
        </ColumnValue>
      </FlexboxContainerComponent>
      <FlexboxContainerComponent alignItems="center" flexDirection="row">
        <ColumnName>Published</ColumnName>
        <ColumnValue>
          {pricebook && pricebook.published
            ? formatDateTime(new Date(pricebook.published).toDateString(),false)
            : ''}
        </ColumnValue>
      </FlexboxContainerComponent>


      {pricebook.priceBookState == PricebookState.DELETE &&
        <DeleteMessage>
          Pricebook will be deleted today at midnight. You can close this window.
        </DeleteMessage>
      }
      {
        pricebook.priceBookState == PricebookState.PUBLISHED ? publishedButtons :
        pricebook.priceBookState == PricebookState.DELETE? deleteButtons : null
      }
    </FlexboxContainerComponent>
  );
};

export default PricebooksPreview;
