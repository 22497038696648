import React, {  } from 'react';
import styled from 'styled-components';
import Modal from 'components/UI/Modal';
import { TEXT_LABELS } from 'constants/productDetails';
import Vendor from 'data/Vendor';
import { Resource } from 'data/Resource';
import ResourceVideoForm from './ResourceVideoForm';
import { VendorsResources } from 'data/vendorResources';

const Content = styled.div``;

interface Props {
  onSave: () => void;
  modalState: boolean;
  toggleModal: (value: boolean) => void;
  vendorsList?: Vendor[];
  setSelectedResource?: (resource: Resource) => void;
  refreshResources: () => void;
  newResources: VendorsResources[]
  setNewResources: (resources: VendorsResources[]) => void;
}

export default function ResourceVideoModal({
  onSave,
  modalState,
  toggleModal,
  vendorsList,
  setSelectedResource,
  refreshResources,
  newResources,
  setNewResources
}: Props) {
  return (
    <Modal
      showModal={modalState}
      showCloseIcon
      width={'750px'}
      onClose={toggleModal}
      primaryButtonText="Save"
      title={TEXT_LABELS.ADD_VIDEOS}
      modalFooterMargin="0"
      top='5%'
      left='30%'
      onClick={onSave}
      className='modal-max-height'
      disabled={newResources.length === 0}
    >
      <Content style={{marginTop: 25}}>
          <ResourceVideoForm
            vendorsList={vendorsList}
            setSelectedResource={setSelectedResource}
            refreshResources={refreshResources}
            newResources={newResources}
            setNewResources={setNewResources}
          />
      </Content>
    </Modal>
  );
}
