import styled from 'styled-components';
import { Color, Size } from 'lib/styles';
import { Background } from 'styles/themeColors';

const ModalButtonSet = styled.div`
  background: ${Background.white};
  border-top: 1px solid ${Color.gray};
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: ${Size.large};
  position: sticky;
`;

export default ModalButtonSet;
