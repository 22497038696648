import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import { Height } from 'styles/themeHeights';
import { Background, Border, Main, Text } from 'styles/themeColors';
import { Space } from 'styles/themeSpaces';
import { BorderRadius } from 'styles/themeBorderRadius';
import { LabelPosition } from 'types/position';

const Input = styled.input`
  border: 4px solid white;
  border-radius: ${BorderRadius.l};
  width: ${Height.s};
  height: ${Height.s};
  background-color: ${Background.white};

  &:checked {
    background-color: ${Main.brandLight};
  }

  &:disabled {
    background-color: ${Main.disabled};
    border: 2px solid ${Border.main};
  }
`;

const Label = styled.label<any>`
  text-align: center;
`;

interface Props {
  id: string;
  className?: string;
  name: string;
  value?: string;
  checked?: boolean;
  disabled?: boolean;
  label?: string;
  labelPosition?: LabelPosition;
  color?: string;
  onChange?: (checked: boolean) => void;
  onChangeValue?: (value: string) => void;
};

const RadioButton = ({
  id,
  className,
  name,
  value,
  checked,
  disabled = false,
  label,
  labelPosition,
  color,
  onChange,
  onChangeValue
}: Props) => {
  const labelStyle = labelPosition === 'left'
    ? { marginRight: `${Space.spHalfPlus}` }
    : { marginLeft: `${Space.spHalfPlus}` };

  const labelElement = <Label htmlFor={id} style={labelStyle} className="base">{label}</Label>;
  const getStyles = styles({ color, disabled });

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.target.checked)
    } else if (onChangeValue) {
      onChangeValue(event.target.value);
      }
    }

  return (
    <FlexboxContainer
      alignItems="center"
      padding={label ? `${Space.spHalfPlus} ${Space.sp2x} ${Space.spHalfPlus} 0` : '0'}
      displayFlex="inline-flex"
    >
      {label && labelPosition === 'left' ? labelElement : null}
        <Input
          id={id}
          style={getStyles.radioButton as any}
          className={className}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={handleChange}
          type="radio"
        />
      {label && labelPosition === 'right' ? labelElement : null}
    </FlexboxContainer>
  );
};

RadioButton.defaultProps = {
  className: '',
  checked: false,
  disabled: false,
  labelPosition: 'right'
};

const styles = (props: any)=> ({
  radioButton: {
    appearance: props.color && !props.disabled ? 'none' : 'radio',
    boxShadow: props.color && !props.disabled ? `0 0 0 1px ${props.color}` : 'none',
  },
});

export default RadioButton;