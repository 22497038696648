export enum FeatureFlagRoleType {
  ALL = 'ALL',
  ADMIN = 'ADMIN',
  VENDOR = 'VENDOR',
  VENDOR_ADMIN = 'VENDOR_ADMIN'
};

export enum FeatureFlagValues {
  DOW_JONES = 'dowJones',
  ORDER = 'order',
  COMPANY = 'company',
  PRICEBOOK = 'pricebooks',
  PRODUCT_V2 = 'productV2',
  MARKETING = 'marketingCampaign'
};

export default interface FeatureFlag {
  [name: string]: { enabled: boolean, role: FeatureFlagRoleType };
};
