export const PricebookCompanyTemplateBasicColumn = [
    'Reference SKU',
    'Product name',
    'Price',
    'Currency',
    'Favorite',
    'Type of pricebook',
    'Name of pricebook',
    'ID of pricebook',
]

export const PricebookReferenceTemplateBasicColumn = [
    'Reference SKU',
    'Pricebook‑specific SKU',
    'Product name',
    'Price',
    'Currency',
    'Type of pricebook',
    'Name of pricebook',
    'ID of pricebook',
]

export const PricebookGeographyTemplateBasicColumn = [
    'Reference SKU',
    'Pricebook‑specific SKU',
    'Product name',
    'Price',
    'Currency',
    'Type of pricebook',
    'Name of pricebook',
    'ID of pricebook',
]

export const PricebookRentalColumns = [
    'Price Range 1 [Range]',
    'Price Range 1 [Range Units]',
    'Price Range 1 [Range price]',
    'Price Range 2 [Range]',
    'Price Range 2 [Range Units]',
    'Price Range 2 [Range price]',
    'Price Range 3 [Range]',
    'Price Range 3 [Range Units]',
    'Price Range 3 [Range price]',
    'Price Range 4 [Range]',
    'Price Range 4 [Range Units]',
    'Price Range 4 [Range price]',
    'Price Range 5 [Range]',
    'Price Range 5 [Range Units]',
    'Price Range 5 [Range price]'
]