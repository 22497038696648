import React from 'react';
import Welcome from './Welcome';
import SellerGuidelinesPage from './SellerGuidelines';
import ProductGuidelines from './ProductGuidelines';
import ProcessGuidelines from './ProcessGuidelines';
import BrandCommunication from './BrandCommunication';
import PromoResources from './PromoResources';
import { Heading1, Paragraph, PageLayout, ArticleContainer, ContentPanel, GlobalStyle } from 'OnboardingComponents/Elements';
import { theme } from './OnboardingPageTheme';
import NavPanel from './NavPanel';

import { ThemeProvider } from 'styled-components';
import { navigate } from 'hookrouter';

const renderContent = (view: string) => {
  if (view === 'welcome') {
    return <Welcome />;
  } else if (view === 'product-guidelines') {
    return <ProductGuidelines />;
  } else if (view === 'process-guidelines') {
    return <ProcessGuidelines />;
  } else if (view === 'seller-guidelines') {
    return <SellerGuidelinesPage />;
  } else if (view === 'brand-communication') {
    return <BrandCommunication />;
  } else if (view === 'promo-resources') {
    return <PromoResources />;
  } else {
    navigate('/onboarding/welcome');
  }
}

const OnboardingPage: React.FunctionComponent<{ view: string }> = ({ view }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <PageLayout>
        <Heading1>Resource library</Heading1>
        <Paragraph>Your go-to spot for our tips on how to become a rockstar seller on OFMP.</Paragraph>
        <ArticleContainer>
          <NavPanel view={view} />
          <ContentPanel>
            {renderContent(view)}
          </ContentPanel>
        </ArticleContainer>
      </PageLayout >
    </ThemeProvider >
  );
};

export default OnboardingPage;
