import Icon from 'view/Icon';
import React from 'react';
import styled, { css } from 'styled-components';
import { Color, Size } from 'lib/styles';
import { Draggable } from 'react-beautiful-dnd';
import { Main, Background } from 'styles/themeColors';

const Container = styled.div`
  padding: ${Size.tiny} 0;
`;

const Card = styled.div`
  align-items: center;
  background: ${Background.white};
  border: 1px solid ${Main.accent};
  border-radius: ${Size.tiny};
  display: flex;
  padding: ${Size.tiny} ${Size.small};
  width: 100%;
`;

const DeleteAction = styled.div`
  align-items: center;
  color: ${Main.error};
  cursor: pointer;
  display: flex;
  font-size: 0.9rem;
  font-weight: 400;
  margin-left: ${Size.medium};
`;

const DeleteIcon = styled(Icon).attrs({ name: 'Trash', size: 14 })`
  margin-right: ${Size.tiny};
`;

const DragHandle = styled(Icon).attrs({ name: 'DragHandle', size: 18 })`
  color: ${Main.accent};
  display: block;
`;

const Thumbnail = styled.div<any>`
  align-items: center;
  display: flex;
  height: 3.5rem;
  justify-content: center;
  margin-left: ${Size.small};

  ${({ thumbnailShadow }) => thumbnailShadow && css`
    border: 1px solid ${Color.gray};
    padding: ${Size.tiny};
  `}
`;

const ThumbnailImg = styled.img`
  height: 100%;
  object-fit: contain;
`;

const SharedNameStyles = css`
  flex-grow: 1;
  font-size: 1rem;
  margin-left: ${Size.medium};
`;

const Name = styled.div`
  ${SharedNameStyles}
`;

const EditableName = styled.input`
  ${SharedNameStyles}
  border: 0 solid ${Main.accent};
  border-bottom-width: 1px;
  padding-bottom: ${Size.small};
`;

export interface VideoAsset {
  id: number;
  name: string;
  path?: string;
  videoId: string;
};

interface Props {
  asset: VideoAsset;
  className?: string;
  editableName?: boolean;
  index: number;
  onDelete: (index: number) => void;
  onNameChange?: (name: string) => void;
  renderThumbnail?: (asset: VideoAsset) => React.ReactNode;
  disabled?: boolean;
};

export default function VideoAssetCard({
  asset,
  className,
  editableName,
  index,
  onDelete,
  onNameChange,
  renderThumbnail,
  disabled
}: Props) {
  const { id, name, path } = asset;

  let nameElement = <Name>{name}</Name>;

  if (editableName && onNameChange) {
    nameElement = (
      <EditableName onChange={e => onNameChange(e.target.value)} value={name} disabled={disabled} />
    );
  }

  function getThumbnailComponent() {
    if (typeof path === 'string') {
      return (
        <Thumbnail thumbnailShadow={true}>
          <ThumbnailImg src={process.env.REACT_APP_MEDIA_URL + path} />
        </Thumbnail>
      );
    }
    if (renderThumbnail) {
      return (
        <Thumbnail thumbnailShadow={false}>{renderThumbnail(asset)}</Thumbnail>
      );
    }

    return null;
  };

  return (
    <Draggable draggableId={id.toString()} index={index} isDragDisabled={disabled}>
      {provided => (
        <Container
          className={className}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <Card>
            <div {...provided.dragHandleProps}>
              <DragHandle />
            </div>
            {getThumbnailComponent()}
            {nameElement}
            <DeleteAction onClick={() => onDelete(index)}>
              <DeleteIcon /> Delete
            </DeleteAction>
          </Card>
        </Container>
      )}
    </Draggable>
  );
};

VideoAssetCard.defaultProps = {
  editableName: false,
  thumbnailShadow: true
};
