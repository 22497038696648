import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { BorderRadius } from 'lib/styles';
import { Space } from 'styles/themeSpaces';
import { Border, Text, Main } from 'styles/themeColors';
import Button from 'components/UI/Button';
import FlexboxContainer from './FlexboxContainer';
import LoadingIndicator from 'components/UI/LoadingIndicator';

const Container = styled.div<any>`
  margin: ${({ $margin }) => $margin ? $margin : `${Space.sp3x} 0`};
  padding: ${Space.sp3x};
  background-color: ${({ bgColor }) => bgColor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  border: 1px solid ${Border.light};
  border-radius: ${BorderRadius.medium};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Action = styled(Button) <any>`
  background-color: ${({ $backgroundColor }) => $backgroundColor ? $backgroundColor : Main.accent};
  border: 1px solid ${({ $borderColor }) => $borderColor ? $borderColor : Main.accent}!important;
  color: ${({ $color }) => $color ? $color : Text.whitePrimary};

  ${({ $disabled }) => $disabled && css`
    pointer-events: none;
  `}
`;

const Title = styled.h5<any>`
  color: ${({ $titleColor }) => $titleColor ? $titleColor : Text.primary};;
  font-size: 20px;
  line-height: 24px;

  ${({ titleMarginBottom }) => titleMarginBottom && css`
    margin-bottom: ${titleMarginBottom};
  `}
`;

const Textarea = styled.div<any>`
  color: ${({ $color }) => $color ? $color : Text.primary};
  margin: ${Space.sp2xPlus} 0 ${Space.spBasePlus};

  ${({ textAreaMarginBottom }) => textAreaMarginBottom && css`
    margin-bottom: ${textAreaMarginBottom};
    margin-top: 0;
  `}
`;

const Message = styled.p<any>`
  font-size: 16px;
  margin-top: ${({$textMargin }) => $textMargin }
  ${({ textAreaMarginBottom }) => textAreaMarginBottom && css`
    margin: 0;
  `}
`;

const MessageDates = styled.p<any>`
  font-size: 16px;
  margin:0px;
`;

interface Props {
  title?: string;
  bgColor: string;
  color?: string;
  titleColor?: string;
  margin?: string;
  text?: string;
  text1?: any;
  text2?: any;
  text3?: any;
  loadSpinner?: boolean;
  button?: {
    value: string;
    onClick: () => void;
    backgroundColor?: string;
    color?: string;
    borderColor?: string;
    leftIcon?: ReactNode;
    width?: string;
    disabled?: boolean;
  }
  buttons?: ReactNode;
  boxShadow?: string;
  children?: ReactNode;
  paymentBox?: ReactNode;
  titleMarginBottom?: string;
  textAreaMarginBottom?: string;
  textMargin?: string;
  bannerHtml?: ReactNode;
};

const Banner = ({
  title,
  loadSpinner,
  bgColor,
  color,
  titleColor,
  margin,
  text,
  text1,
  text2,
  text3,
  buttons,
  boxShadow,
  children,
  button,
  paymentBox,
  titleMarginBottom,
  textAreaMarginBottom,
  textMargin,
  bannerHtml
}: Props) => {
  return (
    <Container
      $margin={margin}
      bgColor={bgColor}
      boxShadow={boxShadow}
    >
      <FlexboxContainer flexDirection='column' width="100%">
        {
          title ? (
            <Title $titleColor={titleColor} titleMarginBottom={titleMarginBottom}>
             <FlexboxContainer width="40%">{title} {loadSpinner ? <LoadingIndicator margin="0 0 0 4px !important" size={20} /> : null } </FlexboxContainer>
            </Title>
          ) : null
        }
        {
          text
            ? (
              <Textarea $color={color} textAreaMarginBottom={'0px'}>
                <Message textAreaMarginBottom={textAreaMarginBottom}>{text}</Message>
                {text1 ? <Message textAreaMarginBottom={textAreaMarginBottom} $textMargin={textMargin}>{text1}</Message> : null}
                {text2 ? <MessageDates>{text2}</MessageDates> : null}
                {text3 ? <MessageDates>{text3}</MessageDates> : null}
              </Textarea>
            )
            : null
        }
        {
          bannerHtml
            ? (
              <Textarea $color={color} textAreaMarginBottom={textAreaMarginBottom}>
                {bannerHtml}
              </Textarea>
            )
            : null
        }
        {
          button?.value && (
            <Action
              $color={button.color}
              $backgroundColor={button.backgroundColor}
              $borderColor={button.borderColor}
              $disabled={button.disabled}
              onClick={button.onClick}
              leftIcon={(_: string) => button.leftIcon}
            >
              {button.value}
            </Action>
          )
        }
        {
          buttons
            ? (
              <FlexboxContainer margin={`${Space.spBasePlus} 0 0 0`} width="100%" justifyContent="flex-end">
                {buttons}
              </FlexboxContainer>
            )
            : null
        }
        {children}
      </FlexboxContainer>
      {paymentBox}
    </Container>
  );
};

Banner.defaultProps = {
  color: Text.primary,
  loadSpinner: false
};

export default Banner;
