import Icon from 'view/Icon';
import React from 'react';
import styled from 'styled-components';
import { Size } from 'lib/styles';

const Container = styled.div`
  display: flex;
  margin: ${Size.medium} 0;
`;

const Content = styled.div``;

interface Props {
  children: React.ReactNode;
  passed?: boolean;
}

export default function Requirement({ children, passed }: Props) {
  const iconName = passed ? 'check-circle' : 'circle';

  return (
    <Container>
      <Icon name={iconName} />
      <Content>{children}</Content>
    </Container>
  );
}

Requirement.defaultProps = {
  passed: false
};
