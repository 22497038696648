import React, { useState, useEffect, Fragment } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'hookrouter';
import { toast } from 'react-toastify';
import Keyword from 'data/Keyword';
import Brand, * as Br from 'data/Brand';
import Vendor from 'data/Vendor';
import FlexboxContainer from 'components/UI/FlexboxContainer';
import Input from 'components/UI/Input';
import AutosuggestRenderInput from 'view/Autosuggest';
import UploadButton from 'view/UploadButton';
import EditorSection from 'view/EditorSection';
import Button from 'components/UI/Button';
import RichTextEditor from 'view/RichTextEditor';
import Divider from 'components/UI/Divider';
import ConfirmModal from 'components/UI/Modal';
import RenderTag from 'components/UI/RenderTag';
import TagsInputComponent from 'view/TagsInputComponent';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, Size, BorderRadius, Percent, FontSize, FontWeight } from 'lib/styles';
import { useAPI } from 'lib/API';
import { useAuth } from 'lib/Auth';
import {
  getBrandValidationTier,
  BrandValidationTierRequirements
} from 'lib/brandValidationTiers';
import { formatDateTime } from 'utils/formatters';
import {
  deleteBrandMedia,
  deleteBrandOldMedia,
  loadBrand
} from 'requests/brand';
import { postMedia } from 'requests/media';
import { getValueCount } from 'utils/brand';
import { Space } from 'styles/themeSpaces';
import { Main, Background } from 'styles/themeColors';
import brandLogo from 'images/default-brand-logo.jpg'
import FlexboxContainerComponent from 'components/UI/FlexboxContainer';

const LinkButton = styled(Button) <any>`
  font-size: ${FontSize.smaller};
  color: ${({ color }) => color};
  background-color: ${Background.white};
  border: none;
  margin: 10px 0;
`;

const Label = styled.label<any>`
  font-size: 15px;
  line-height: 1.47;
  color: ${Color.dimGray};

  ${({ margin }) => margin && css`
    margin: ${margin};
  `}
`;


const BlockUploadButton = styled(UploadButton)`
  padding: 2px 8px 2px 10px;
  border-radius: ${BorderRadius.medium};
  border: solid 2px ${Main.accent};
  background-color: ${Background.white};
  font-size: 14px;
  font-weight: ${FontWeight.bold};
  line-height: 1.71;
  color: ${Main.accent}!important;
  width: auto;
`;

const InputContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid ${Color.veryLightGray};
  border-radius: ${BorderRadius.medium};
  width: 100%;
  padding-right: 5px;
  margin-top: 10px;
`;

const LogoContainer = styled.div`
 align-items: flex-end;
  display: flex;
  margin-top: ${Size.small};
`;

const Logo = styled.img`
  flex: 1;
  max-height: 320px;
  max-width: 320px;
  object-fit: contain;
  object-position: center;
  border: 1px solid ${Color.gray};
`;

const BrandLogoImage = styled.img`
  background-color: ${Background.white};
  height: 90px;
  margin-right: ${Size.medium};
  object-fit: contain;
  object-position: 50%;
  padding: ${Size.tiny};
  width: 90px;
`;

const LogoMetadata = styled.div`
  font-size: ${FontSize.smaller};
  line-height: 1.5;
  color: ${Color.dimGray};
  margin: 3px 0;
`;

const VerticalLine = styled.div<any>`
  width: 1px;
  margin: 5px ${Size.medium} 5px 0;
  height: 20px;
  background-color: ${Color.gray}
`;

const Span = styled.span`
  margin: ${Space.spBase} 0;
`;

const renderLogo = (
  brand: Brand,
  media: Br.Logo,
  index: number,
  deleteBrandMedia: Function,
  setDefaultLogo: Function
) => {
  return (
    <Fragment key={index}>
      <Logo src={process.env.REACT_APP_MEDIA_URL + media.path} />
      {
        <>
          <LogoMetadata>Uploaded: {formatDateTime(media.createdOn, true)}</LogoMetadata>
          <LogoMetadata>Format: {media.fileType.toUpperCase()}</LogoMetadata>
        </>

      }
      <FlexboxContainer>
        <LinkButton color={Main.error} onClick={() => deleteBrandMedia(media.id)}>
          Remove
        </LinkButton>
        {
          index !== -1 ? (
            <>
              <VerticalLine />
              <LinkButton color={Color.hexacomBlue} onClick={() => setDefaultLogo(media)}>
                Set as default
              </LinkButton>
            </>
          ) : null
        }
      </FlexboxContainer>
      {index !== -1 ? <Divider bgColor={Color.gray} marginY="20px" maxWidth="50%" /> : null}
    </Fragment>
  );
};

export interface Props {
  brand: Brand;
  selectedVendor: Vendor;
  vendorsList?: Vendor[];
  keywordsList: Keyword[];
  updateVendor: (name: string) => void;
  setBrand: (brand: Brand) => void;
};

const BrandConfigurationTab = ({ brand, selectedVendor, vendorsList, keywordsList, updateVendor, setBrand }: Props) => {

  const { token } = useAuth();
  const [keywordsTags, setKeywordsTags] = useState<string[]>([]);
  const [showBrandCancelModal, setShowBrandCancelModal] = useState(false);
  const [showBrandMediaDeleteModal, setShowBrandMediaDeleteModal] = useState(false);
  const [mediaId, setMediaId] = useState<number>(-1);
  const [showBrandOldMediaDeleteModal, setShowBrandOldMediaDeleteModal] = useState(false);
  const [oldMediaId, setOldMediaId] = useState<number>(-1);

  const brandReq = useAPI<Brand>({
    deferFn: loadBrand,
    onResolve: (result) => {
      setBrand(result);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    if (selectedVendor) {
      setBrand({ ...brand, vendor: selectedVendor });
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (brand) {
      setKeywordsTags(brand.keywords);
    }
  }, [brand && brand.keywords]);

  useEffect(() => {
    if (vendorsList && vendorsList.length > 0 && brand && brand.vendor) {
      const brandVendor = Object.values(brand!.vendor!)[0];
      const currentVendor = vendorsList.find(vendor => vendor.id === brandVendor.id) || selectedVendor;
      updateVendor(currentVendor.name);
    }
  }, [vendorsList, brand]);

  const onDeleteBrandMediaConfirm = () => {
    if (brand.id !== 0 && mediaId) {
      deleteBrandMedia(brand.id, mediaId, token!)
        .then(() => {
          setBrand({ ...brand, media: [] });
          brandReq.reload();
        })
        .catch(err =>{
          // renderToast(toast.TYPE.ERROR, err.message)
          setBrand({ ...brand, media: [] });
        } );
    } else {
      setBrand({ ...brand, media: [] });
    }

    setShowBrandMediaDeleteModal(false);
  }

  const onDeleteBrandMedia = (mediaId: number) => {
    setShowBrandMediaDeleteModal(true);
    setMediaId(mediaId);
  };

  const onDeleteBrandOldMediaConfirm = () => {
    if (brand.id !== 0 && oldMediaId) {
      deleteBrandOldMedia(brand.id, oldMediaId, token!)
        .then(() => brandReq.reload())
        .catch(err => renderToast(toast.TYPE.ERROR, err.message));
    }

    setShowBrandOldMediaDeleteModal(false);
  };

  const onDeleteBrandOldMedia = (mediaId: number) => {
    setShowBrandOldMediaDeleteModal(true);
    setOldMediaId(mediaId);
  };

  const setDefaultLogo = (media: Br.Logo) => {
    setBrand({ ...brand, media: [media] });
  };


  const uploadImage = async (file: File) => {
    const image = await postMedia({
      file,
      token: token as string,
      type: 'vendor_logo',
      vendorId: selectedVendor ? selectedVendor.id : undefined,
    });

    setBrand({ ...brand, media: [image] });
  };

  const onKeywordsChange = (keywords: string[]) => {
    setKeywordsTags(keywords);

    setBrand({ ...brand, keywords });
  };

  const brandTextEditorOnBlur = (description: string) => {
    setBrand({ ...brand, description })
  };

  const validationTier = getBrandValidationTier(brand);

  const getValidationCount = (key: keyof BrandValidationTierRequirements) => {
    return {
      count: getValueCount(brand, key),
      recommended: validationTier.recommendations[key].min,
      required: validationTier.requirements[key].min
    };
  };

  const onConfirmCancelBrandModalClick = () => {
    setShowBrandCancelModal(false);
    navigate('/brands');
  };

  const confirmDeleteBrandMediaModal = (
    <ConfirmModal
      showModal={showBrandMediaDeleteModal}
      onClose={() => setShowBrandMediaDeleteModal(false)}
      onClick={onDeleteBrandMediaConfirm}
      title="Remove media"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          Are you sure you want to remove this media?
        </Span>
      </FlexboxContainer>
    </ConfirmModal>

  )

  const confirmDeleteBrandOldMediaModal = (
    <ConfirmModal
      showModal={showBrandOldMediaDeleteModal}
      onClose={() => setShowBrandOldMediaDeleteModal(false)}
      onClick={onDeleteBrandOldMediaConfirm}
      title="Remove media"
      ghostButtonText="Back"
    >
      <FlexboxContainer flexDirection="column">
        <Span>
          Are you sure you want to remove this media?
        </Span>
      </FlexboxContainer>
    </ConfirmModal>
  )


  const content = (
    <FlexboxContainerComponent flexDirection="column">
      <EditorSection
        shouldShowValidationStatus={false}
        noBorder={true}
        label="Brand name"
        disablePaddingBottom={true}
        validation={getValidationCount('name')}
      >
        <div style={{ width: '36%' }}>
          <Input
            id='brand-name-id'
            placeholder='Enter your brand(50 characters)'
            value={brand.name}
            width="100%"
            name="brand-name"
            onInputChange={(name) => setBrand({ ...brand, name })}
            labelText='Brand name you would like to listen on the storefront'
          />
        </div>
      </EditorSection>
      <EditorSection
        shouldShowValidationStatus={false}
        noBorder={true}
        label="Brand description"
        isOptional={true}
        disablePaddingBottom={true}
        validation={getValidationCount('description')}
      >
        <RichTextEditor
          onBlur={brandTextEditorOnBlur}
          placeholder="Enter your brand description"
          value={brand.description || ''}
        />
      </EditorSection>
      <EditorSection
        noBorder={true}
        label="Keywords"
        disablePaddingBottom={true}
      >
        <FlexboxContainer flexDirection="column">
          <InputContainer>
            <TagsInputComponent
              value={keywordsTags}
              onChange={onKeywordsChange}
              inputProps={{
                className: 'react-tagsinput-input',
                placeholder: keywordsTags.length === 0 ? 'Enter brand keyword(s)' : '',
                keywords: keywordsList,
              }}
              tagProps={{ className: 'react-tagsinput-tag-custom' }}
              renderTag={RenderTag}
              renderInput={AutosuggestRenderInput}
            />
          </InputContainer>
        </FlexboxContainer>
      </EditorSection>
      <EditorSection
        noBorder={true}
        label="Brand logo"
        infoIconText="Please upload the logo for your brand following the file specifications listed below"
      >
        <FlexboxContainer flexDirection="column">
          {
            brand.media && brand.media.length > 0
              ? brand.id !== 0
                ? <Label margin="0 0 30px 0">Defaut logo</Label>
                : <Label margin="0 0 30px 0">No logo uploaded</Label>
              : <Label margin="0 0 30px 0">No logo uploaded</Label>
          }
          {
            (brand.media && brand.media.length > 0 && brand.media[0]?.path)
              ? renderLogo(brand, brand.media[0], -1, onDeleteBrandMedia, setDefaultLogo)
              : <BrandLogoImage alt={`${brand.name} placeholder-logo`} src={brandLogo} />
          }
          <LogoContainer>
            <BlockUploadButton
              acceptTypes={['image/*']}
              label={
                brand && brand.media && brand.media.length > 0
                  ? 'Upload image'
                  : 'Choose brand image'
              }
              onChange={uploadImage}
            />
          </LogoContainer>
        </FlexboxContainer>
        <FlexboxContainer flexDirection="column">
          <Divider bgColor={Color.gray} marginY="20px" maxWidth={Percent.half} />
          {
            brand.oldMedia && brand.oldMedia.length > 0 ? <Label margin="0 0 30px 0">Other versions</Label> : null
          }
          {
            brand.oldMedia && brand.oldMedia.length > 0
              ? brand.oldMedia.map((oldMedia, index) => {
                return renderLogo(brand, oldMedia, index, onDeleteBrandOldMedia, setDefaultLogo)
              })
              : null
          }
        </FlexboxContainer>
      </EditorSection>
    </FlexboxContainerComponent>
  );


  return (
    <>
      {confirmDeleteBrandMediaModal}
      {confirmDeleteBrandOldMediaModal}
      {content}
    </>
  );
};

export default BrandConfigurationTab;
