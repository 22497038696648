import React from 'react';
import { DateRange, DateRangePicker as MUIDateRangePicker } from 'materialui-daterange-picker';

interface DateRangePickerProps {
  dateRange: DateRange;
  onDateRangeChange: (dateRange: DateRange) => void;
  open: boolean;
  toggle: () => void;
};

const DateRangePicker = ({
  dateRange,
  onDateRangeChange,
  open,
  toggle
}: DateRangePickerProps) => {
  return (
    <MUIDateRangePicker
      definedRanges={[]}
      open={open}
      toggle={toggle}
      onChange={(range) => {
        onDateRangeChange(range);
        toggle();
      }}
    />
  );
};

export default DateRangePicker;
