import React, { Fragment, useEffect } from 'react';
import Banner from '../UI/Banner';
import { Background, Main, Shade, Text } from '../../styles/themeColors';
import { Space } from '../../styles/themeSpaces';
import FlexboxContainer from '../UI/FlexboxContainer';
import OrderInformationTable from '../Orders/OrderInformationTable';
import { formatDateTime } from '../../utils/formatters';
import * as Cons from 'data/Consignment';
import RentalDates from './components/RentalDates';
import Button from '../UI/Button';
import { APPROVED, MODIFIED_BY_CUSTOMER, MODIFIED_BY_VENDOR, PACKING, REJECTED, RENT_COMPLETED, RENT_IN_PROGRESS, RENT_RETURN_PENDING, RENT_UPDATED, TICKET_IN_PROGRESS } from '../../constants/consignmentStates';
import { toast } from 'react-toastify';
import { useAPI } from '../../lib/API';
import { updateRentalState } from '../../requests/consignment';
import { renderToast } from '../UI/ToastNotification';
import styled from 'styled-components';
import { PAYMENT_STATUS } from '../../constants/paymentStatus';
import ModifyRentalDatesModal from './Modals/ModifyRentalDatesModal';
import { TEXT_LABELS } from 'constants/order';
import { CONSIGNMENT_MODIFICATION_ACTORS } from 'constants/consignmentEntry';
import TicketDispute from './TicketDispute';
import { SHIPPING } from 'constants/deliveryModes';
import ModifyEstDateWithRentaModal from './Modals/ModifyEstDateWithRentModal';
import ShippingCarrier from 'data/ShippingCarrier';

const BoldText = styled.strong``;

const IndentedText = styled.div`
  padding-left: 20px;
`;

const PreviousValue = styled.span`
    text-decoration: line-through;
    color: ${Text.secondary}; // Lighter color for previous value
    font-size: ${Space.sp2x};

`;

const CurrentValue = styled.span`
    font-weight: bold;
    color: ${Text.primary}; // Normal color for current value
    font-size: ${Space.sp2x};
`;

interface RentalOrderStatesProps {
  paymentStatus?: string;
  consignmentData?: Cons.ConsignmentDetails;
  refreshConsignment?: () => void;
  shippingCarriers?: ShippingCarrier[];
};

const renderDateValue = (previous: any, current: any, formatter = (val: any, _?: any) => val) => (
  <>
    {previous && <PreviousValue>{formatter(previous)}</PreviousValue>}
    <br />
    {current && <CurrentValue>{formatter(current)}</CurrentValue>}
  </>
);

const RentalCompletion = ({paymentStatus, consignmentData}: RentalOrderStatesProps) => {
  return <Fragment>
    {paymentStatus === PAYMENT_STATUS.NOT_PAID && (<Banner
        loadSpinner={true}
        title={TEXT_LABELS.PROCESSING_PAYMENT}
        boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
        text={TEXT_LABELS.PLEASE_WAIT_FOR_A_MINUTE}
        bgColor={Background.white}
      />)}
      {paymentStatus !== PAYMENT_STATUS.NOT_PAID && (<Banner
        color={Background.white}
        titleColor={Background.white}
        bgColor={Main.success}
        title={TEXT_LABELS.RENTAL_COMPLETED}
        titleMarginBottom={Space.spBasePlus}
        textAreaMarginBottom={Space.spBasePlus}
      >
        <FlexboxContainer width='100%' justifyContent='space-between' alignItems="flex-start">
          <FlexboxContainer width='50%' flexWrap='wrap'>
            <OrderInformationTable
              color={Background.white}
              rowName1={TEXT_LABELS.START_DATE}
              rowValue1={formatDateTime(consignmentData?.rentalStartDate)}
              rowName2={TEXT_LABELS.END_DATE}
              rowValue2={formatDateTime(consignmentData?.rentalEndDate)}
              padding="0"
            />
          </FlexboxContainer>
        </FlexboxContainer>
      </Banner>)}
  </Fragment>
}

const RentalInProgress = ({consignmentData, refreshConsignment}: RentalOrderStatesProps) => {
  const [showModal, setShowModal] = React.useState(false);
  const [rentalStartDate, setRentalStartDate] = React.useState('');
  const [rentalEndDate, setRentalEndDate] = React.useState('');
  const [reason, setReason] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);

  const updateStateReq = useAPI({
    deferFn: updateRentalState,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, TEXT_LABELS.ORDER_UPDATED_SUCCESSFULLY);
      refreshConsignment?.();
      setLoader(false);
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
      setLoader(false);
    }
  });

  const handleRefresh = () => {
    setRentalStartDate(consignmentData?.rentalStartDate!);
    setRentalEndDate(consignmentData?.rentalEndDate!);
    setReason('');
    setShowModal(false);
    setIsDisabled(true);
  }

  useEffect(() => {
    handleRefresh();
  }, [consignmentData])

  useEffect(() => {
    const startDateChanged = formatDateTime(rentalStartDate) !== formatDateTime(consignmentData?.rentalStartDate);
    const endDateChanged = formatDateTime(rentalEndDate) !== formatDateTime(consignmentData?.rentalEndDate);
  
    if (startDateChanged || endDateChanged) {
      const startDate = new Date(rentalStartDate);
      const endDate = new Date(rentalEndDate);
  
      if (startDate <= endDate) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }else{
      setIsDisabled(true);
    }
  }, [rentalStartDate, rentalEndDate, consignmentData]);
  
  const handleSubmit = () => {
    setLoader(true);
    setShowModal(false);
    updateStateReq.run(consignmentData?.consignmentId, RENT_UPDATED, null, rentalStartDate, rentalEndDate, reason);
  }

  return (
    <Fragment>
      <Banner
        boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
        bgColor={Background.white}
        title={loader ? TEXT_LABELS.PLEASE_WAIT : TEXT_LABELS.RENTAL_DETAILS}
        text=''
        loadSpinner={loader}
      >
        <FlexboxContainer width='100%' justifyContent='space-between' >
          <RentalDates consignmentData={consignmentData} isRental={true} showIcon={false} datesFormat="yyyy MMM dd" />
          <FlexboxContainer
            justifyContent="flex-end"
            alignItems="flex-end"
            width="100%"
            margin="25px 0px"
          >
            <Button
              onClick={() => setShowModal(true)}
            >
              {TEXT_LABELS.MODIFY_RENTAL}
            </Button>
          </FlexboxContainer>
        </FlexboxContainer>
      </Banner>
      <ModifyRentalDatesModal
        rentalEndDate={rentalEndDate}
        rentalStartDate={rentalStartDate}
        reason={reason}
        setReason={setReason}
        setRentalEndDate={setRentalEndDate}
        setRentalStartDate={setRentalStartDate}
        show={showModal}
        onClose={handleRefresh}
        onConfirm={handleSubmit}
        title={TEXT_LABELS.RENTAL_MODIFICATION}
        disabled={isDisabled}
        weekdaysOnly={consignmentData?.weekdaysOnly!}
      />
    </Fragment>
  )
}

const RentalReturnPending = ({consignmentData, paymentStatus, refreshConsignment}: RentalOrderStatesProps) => {
  const [loader, setLoader] = React.useState(false);

  const rentalCompletionReq = useAPI({
    deferFn: updateRentalState,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, TEXT_LABELS.ORDER_UPDATED_SUCCESSFULLY);
      refreshConsignment?.();
    },
    onReject: err => {
      setLoader(false);
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  return (
    <Fragment>
      <Banner
        boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
        bgColor={Background.white}
        titleColor={loader? Main.black: Main.success}
        title={loader ? TEXT_LABELS.PLEASE_WAIT : TEXT_LABELS.RENTAL_PERIOD_FINISHED}
        text=''
        loadSpinner={loader}
      >
        {!loader && <FlexboxContainer width='100%' justifyContent='space-between' >
          <RentalDates consignmentData={consignmentData} isRental={true} />
          <FlexboxContainer
            justifyContent="flex-end"
            alignItems="flex-end"
            margin="25px 0px"
            gap="4px"
          >
            {/* {paymentStatus !== PAYMENT_STATUS.FAILED && <FlexboxContainer
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                bgColor={Background.transparent}
                color={Main.error}
                borderColor={Main.error}
                onClick={() => { 
                  // todo: open dispute functionality will be added later
                }}
              >
                {TEXT_LABELS.OPEN_DISPUTE}
              </Button>
            </FlexboxContainer>} */}
            <FlexboxContainer
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                onClick={() => { 
                  setLoader(true);
                   rentalCompletionReq.run(consignmentData?.consignmentId, RENT_COMPLETED, null, null, null, null, paymentStatus === PAYMENT_STATUS.FAILED);
                }}
              >
                {paymentStatus === PAYMENT_STATUS.FAILED ? TEXT_LABELS.RETRY_PAYMENT : TEXT_LABELS.CONFIRM_RENTAL_COMPLETION}
              </Button>
            </FlexboxContainer>
          </FlexboxContainer>
        </FlexboxContainer>}
      </Banner>
    </Fragment>
  )
}

const RentModifiedByCustomer = ({paymentStatus, consignmentData, refreshConsignment, shippingCarriers = []}: RentalOrderStatesProps) => {
  const [showModal, setShowModal] = React.useState(false);
  const [rentalStartDate, setRentalStartDate] = React.useState<string | null>(null);
  const [rentalEndDate, setRentalEndDate] = React.useState<string | null>(null);
  const [estDate, setEstDate] = React.useState('');
  const [trackingId, setTrackingId] = React.useState('');
  const [carrier, setCarrier] = React.useState<ShippingCarrier>();
  const [reason, setReason] = React.useState('');
  const [loader, setLoader] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);

  const updateStateReq = useAPI({
    deferFn: updateRentalState,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, TEXT_LABELS.ORDER_UPDATED_SUCCESSFULLY);
      refreshConsignment?.();
    },
    onReject: err => {
      setLoader(false)
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const handleRefresh = () => {
    const modificationEntry = consignmentData?.modificationEntry;

    setRentalStartDate(modificationEntry?.newStartDate!);
    setRentalEndDate(modificationEntry?.newEndDate!);
    setEstDate(consignmentData?.availableDate!);
    setTrackingId(consignmentData?.deliveryType === SHIPPING ? consignmentData?.trackingId! : (consignmentData?.referenceNumber! || consignmentData?.consignmentId.substring(4)!));
    setCarrier(consignmentData?.shippingCarrier)
    setReason('');
    setShowModal(false);
    setIsDisabled(true);
  }

  useEffect(() => {
    handleRefresh();
  }, [consignmentData])

  useEffect(() => {
    const modificationEntry = consignmentData?.modificationEntry;
    const startDateChanged = formatDateTime(rentalStartDate!) !== formatDateTime(modificationEntry?.newStartDate);
    const endDateChanged = formatDateTime(rentalEndDate!) !== formatDateTime(modificationEntry?.newEndDate);
  
    if (startDateChanged || endDateChanged) {
      const startDate = new Date(rentalStartDate!);
      const endDate = new Date(rentalEndDate!);
      const estDate_ = new Date(estDate!);
  
      if (estDate_ <= startDate && startDate <= endDate && trackingId) {
        setIsDisabled(false);
      } else {
        setIsDisabled(true);
      }
    }else{
      setIsDisabled(true);
    }
  }, [rentalStartDate, rentalEndDate, consignmentData, estDate, trackingId, carrier]);
  
  const handleSubmit = () => {
    setLoader(true);
    setShowModal(false);
    updateStateReq.run(consignmentData?.consignmentId, RENT_UPDATED, null, rentalStartDate, rentalEndDate, reason, null, true, trackingId, estDate, carrier);
  }

  const onShippingCarrierSelect = (event: any) => {
    const foundShippingCarrier = shippingCarriers.find(shCarrier => shCarrier.name === event.target.value);

    if (foundShippingCarrier) {
      setCarrier(foundShippingCarrier!);
    }
  }

  return (
  <Fragment>
    <Banner
      boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
      bgColor={Background.white}
      titleColor={Main.black}
      title={loader ? TEXT_LABELS.PLEASE_WAIT : TEXT_LABELS.RENT_DATES_MODIFIED}
      text={TEXT_LABELS.RENT_MODIFIED_BY_CUSTOMER}
      textAreaMarginBottom='0'
      loadSpinner={loader}
    >
      {!loader && <><FlexboxContainer flexDirection='row' gap='5px'>
        {TEXT_LABELS.PREVIOUS_RENT_DATES}
        <BoldText>{`${formatDateTime(consignmentData?.modificationEntry?.originalStartDate)} - ${formatDateTime(consignmentData?.modificationEntry?.originalEndDate)}`}</BoldText>
      </FlexboxContainer>
      <FlexboxContainer flexDirection='row' gap='5px'>
        {TEXT_LABELS.NEW_RENT_DATES}
        <BoldText>{`${formatDateTime(consignmentData?.modificationEntry?.newStartDate)} - ${formatDateTime(consignmentData?.modificationEntry?.newEndDate)}`}</BoldText>
      </FlexboxContainer>
      {
        consignmentData?.modificationEntry?.newDeliveryDate ?
        <FlexboxContainer flexDirection='row' gap='5px'>
          {consignmentData?.deliveryType === SHIPPING ? TEXT_LABELS.ESTIMATED_DELIVERY : TEXT_LABELS.ESTIMATED_PICKUP}:
          {renderDateValue(consignmentData?.modificationEntry?.originalDeliveryDate, consignmentData?.modificationEntry?.newDeliveryDate, formatDateTime)}
        </FlexboxContainer> :
        <FlexboxContainer flexDirection='row' gap='5px'>
           {consignmentData?.deliveryType === SHIPPING ? TEXT_LABELS.ESTIMATED_DELIVERY : TEXT_LABELS.ESTIMATED_PICKUP}:
          <BoldText>{`${formatDateTime(consignmentData?.availableDate)}`}</BoldText>
        </FlexboxContainer>
      }
       <FlexboxContainer flexDirection='column' gap='5px' margin='20px 0px 0px 0px'>
        <div>{TEXT_LABELS.RENT_MODIFICATION_REASON}</div>
        <IndentedText>
          {consignmentData?.modificationEntry?.message 
            ? consignmentData.modificationEntry.message 
            : TEXT_LABELS.NO_RENT_MODIFICATION_REASON}
        </IndentedText>
      </FlexboxContainer>
      <FlexboxContainer width='100%' justifyContent='space-between' >
        <RentalDates consignmentData={consignmentData} isRental={true} showOriginal={false} />
        <FlexboxContainer
          justifyContent="flex-end"
          alignItems="flex-end"
          width="100%"
          margin="25px 0px"
        >
          <FlexboxContainer
            justifyContent="flex-end"
            alignItems="flex-end"
            margin='0 8px 0 0'
          >
            <Button
              bgColor={Background.transparent}
              color={Main.accent}
              borderColor={Main.accent}
              onClick={() => setShowModal(true)}
            >
              {TEXT_LABELS.EDT_CHANGES}
            </Button>
          </FlexboxContainer>
          <FlexboxContainer
            justifyContent="flex-end"
            alignItems="flex-end"
            margin='0 8px 0 0'
          >
            <Button
              onClick={() => { 
                setLoader(true);
                updateStateReq.run(consignmentData?.consignmentId, RENT_IN_PROGRESS, APPROVED);
              }}
            >
              {TEXT_LABELS.CONFIRM_CHANGES}
            </Button>
          </FlexboxContainer>
          <FlexboxContainer
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              bgColor={Main.error2}
              borderColor={Main.error2}
              onClick={() => { 
                setLoader(true);
                updateStateReq.run(consignmentData?.consignmentId, RENT_IN_PROGRESS, REJECTED);
              }}
            >
              {TEXT_LABELS.REJECT_CHANGES}
            </Button>
          </FlexboxContainer>
        </FlexboxContainer>
      </FlexboxContainer></>}
    </Banner>
    {
      consignmentData?.modificationEntry?.initiatedFrom === RENT_IN_PROGRESS ? 
      <ModifyRentalDatesModal
        rentalEndDate={rentalEndDate!}
        rentalStartDate={rentalStartDate!}
        reason={reason}
        setReason={setReason}
        setRentalEndDate={setRentalEndDate}
        setRentalStartDate={setRentalStartDate}
        show={showModal}
        onClose={handleRefresh}
        onConfirm={handleSubmit}
        title={TEXT_LABELS.RENTAL_MODIFICATION}
        disabled={isDisabled}
        weekdaysOnly={consignmentData?.weekdaysOnly!}
      /> : 
      <ModifyEstDateWithRentaModal
        rentalEndDate={rentalEndDate}
        rentalStartDate={rentalStartDate}
        reason={reason}
        setReason={setReason}
        setRentalEndDate={setRentalEndDate!}
        setRentalStartDate={setRentalStartDate!}
        show={showModal}
        onClose={handleRefresh}
        onConfirm={handleSubmit}
        title={TEXT_LABELS.RENTAL_UPDATE}
        disabled={isDisabled}
        originalRentalStartDate={consignmentData?.rentalStartDate!}
        originalRentalEndDate={consignmentData?.rentalEndDate!}
        shippingCarriers={shippingCarriers}
        isShipping={consignmentData?.deliveryType === 'SHIPPING'}
        carrier={carrier?.name!}
        setCarrier={onShippingCarrierSelect}
        trackingId={trackingId}
        setTrackingId={setTrackingId}
        estDate={estDate}
        setEstDate={setEstDate}
        isRentalModificationRequired={true}
        weekdaysOnly={consignmentData?.weekdaysOnly!}
      />
    }
    
  </Fragment>
  )
}

const RentModifiedByVendor = ({paymentStatus, consignmentData, refreshConsignment}: RentalOrderStatesProps) => {
  const [loader, setLoader] = React.useState(false);

  const updateStateReq = useAPI({
    deferFn: updateRentalState,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, TEXT_LABELS.ORDER_UPDATED_SUCCESSFULLY);
      setLoader(false)
      refreshConsignment?.();
    },
    onReject: err => {
      setLoader(false)
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  return (
  <Fragment>
    <Banner
      boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
      bgColor={Background.white}
      titleColor={Main.black}
      title={loader ? TEXT_LABELS.PLEASE_WAIT : TEXT_LABELS.RENT_WAS_MODIFIED}
      text={TEXT_LABELS.RENT_WAS_MODIFIED_DETAILS}
      textAreaMarginBottom='0'
      loadSpinner={loader}
    >
      {!loader && <><FlexboxContainer flexDirection='row' gap='5px'>
        {TEXT_LABELS.PREVIOUS_RENT_DATES}
        <BoldText>{`${formatDateTime(consignmentData?.modificationEntry?.originalStartDate)} - ${formatDateTime(consignmentData?.modificationEntry?.originalEndDate)}`}</BoldText>
      </FlexboxContainer>
      <FlexboxContainer flexDirection='row' gap='5px'>
        {TEXT_LABELS.NEW_RENT_DATES}
        <BoldText>{`${formatDateTime(consignmentData?.modificationEntry?.newStartDate)} - ${formatDateTime(consignmentData?.modificationEntry?.newEndDate)}`}</BoldText>
      </FlexboxContainer>
      {
        consignmentData?.modificationEntry?.newDeliveryDate ?
        <FlexboxContainer flexDirection='row' gap='5px'>
          {consignmentData?.deliveryType === SHIPPING ? TEXT_LABELS.ESTIMATED_DELIVERY : TEXT_LABELS.ESTIMATED_PICKUP}:
          {renderDateValue(consignmentData?.modificationEntry?.originalDeliveryDate, consignmentData?.modificationEntry?.newDeliveryDate, formatDateTime)}
        </FlexboxContainer> :
        <FlexboxContainer flexDirection='row' gap='5px'>
           {consignmentData?.deliveryType === SHIPPING ? TEXT_LABELS.ESTIMATED_DELIVERY : TEXT_LABELS.ESTIMATED_PICKUP}:
          <BoldText>{`${formatDateTime(consignmentData?.availableDate)}`}</BoldText>
        </FlexboxContainer>
      }
      <FlexboxContainer flexDirection='column' gap='5px' margin='20px 0px 0px 0px'>
        <div>{TEXT_LABELS.RENT_MODIFICATION_REASON}</div>
        <IndentedText>
          {consignmentData?.modificationEntry?.message 
            ? consignmentData.modificationEntry.message 
            : TEXT_LABELS.NO_RENT_MODIFICATION_REASON}
        </IndentedText>
      </FlexboxContainer>
      <FlexboxContainer width='100%' justifyContent='space-between' >
        <RentalDates consignmentData={consignmentData} isRental={true} datesFormat="yyyy MMM dd" showIcon={false} showOriginal={false} />
        <FlexboxContainer
          justifyContent="flex-end"
          alignItems="flex-end"
          width="100%"
          margin="25px 0px"
        >
          <FlexboxContainer
            justifyContent="flex-end"
            alignItems="flex-end"
            margin='0 8px 0 0'
          >
            <Button
              onClick={() => { 
                setLoader(true);
                updateStateReq.run(consignmentData?.consignmentId, null, APPROVED);
              }}
            >
              {TEXT_LABELS.CONFIRM_MODIFICATION}
            </Button>
          </FlexboxContainer>
          <FlexboxContainer
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              bgColor={Main.error2}
              borderColor={Main.error2}
              onClick={() => { 
                setLoader(true);
                updateStateReq.run(consignmentData?.consignmentId, null, REJECTED);
              }}
            >
              {TEXT_LABELS.REJECT_CHANGES}
            </Button>
          </FlexboxContainer>
        </FlexboxContainer>
      </FlexboxContainer></>}
    </Banner>
  </Fragment>
  )
}

export const RentModifiedOnHold = ({paymentStatus, consignmentData, refreshConsignment}: RentalOrderStatesProps) => {
  const [loader, setLoader] = React.useState(false);

  const updateStateReq = useAPI({
    deferFn: updateRentalState,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, TEXT_LABELS.ORDER_UPDATED_SUCCESSFULLY);
      setLoader(false)
      refreshConsignment?.();
    },
    onReject: err => {
      setLoader(false)
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  return (
  <Fragment>
    <Banner
      boxShadow='0px 8px 16px rgba(47, 66, 83, 0.25), 0px 16px 20px rgba(47, 66, 83, 0.15)'
      bgColor={Background.white}
      titleColor={Main.black}
      title={loader ? TEXT_LABELS.PLEASE_WAIT : TEXT_LABELS.RENT_DATES_MODIFIED}
      text={consignmentData?.modificationEntry.requestedBy === CONSIGNMENT_MODIFICATION_ACTORS.BUYER ?
        TEXT_LABELS.RENT_MODIFIED_BY_CUSTOMER_ON_HOLD : TEXT_LABELS.RENT_MODIFIED_BY_VENDOR_ON_HOLD}
      textAreaMarginBottom='0'
      loadSpinner={loader}
    >
      {!loader && <><FlexboxContainer flexDirection='row' gap='5px'>
        {TEXT_LABELS.PREVIOUS_RENT_DATES}
        <BoldText>{`${formatDateTime(consignmentData?.modificationEntry?.originalStartDate)} - ${formatDateTime(consignmentData?.modificationEntry?.originalEndDate)}`}</BoldText>
      </FlexboxContainer>
      <FlexboxContainer width='100%' gap='20px'>
        <RentalDates consignmentData={consignmentData} isRental={true} datesFormat="yyyy MMM dd" showIcon={false} showNewDates />
        <FlexboxContainer
          margin="25px 0px"
        >
          <FlexboxContainer
            justifyContent="flex-end"
            alignItems="flex-end"
            margin='0 4px 0 0'
          >
            <Button
              bgColor={Main.error2}
              borderColor={Main.error2}
              onClick={() => { 
                setLoader(true);
                updateStateReq.run(consignmentData?.consignmentId, null, REJECTED);
              }}
            >
              {TEXT_LABELS.REJECT_CHANGES}
            </Button>
          </FlexboxContainer>
          <FlexboxContainer
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Button
              disabled
              borderColor={Shade.shade550}
              color={Background.navy}
              isGhost
            >
              {TEXT_LABELS.EDT_CHANGES}
            </Button>
          </FlexboxContainer>
        </FlexboxContainer>
      </FlexboxContainer></>}
    </Banner>
  </Fragment>
  )
}

const RentalOrderStates = ({paymentStatus, consignmentData, refreshConsignment, shippingCarriers}: RentalOrderStatesProps) => {
  
  const rentalState = consignmentData?.state || '';

  const renderStateComponent = () => {
    switch (rentalState) {
      case RENT_IN_PROGRESS:
        return <RentalInProgress consignmentData={consignmentData} refreshConsignment={refreshConsignment} />;
      case RENT_RETURN_PENDING:
        return <RentalReturnPending consignmentData={consignmentData} refreshConsignment={refreshConsignment} />;
      case RENT_COMPLETED:
        return paymentStatus === PAYMENT_STATUS.FAILED ? 
          <RentalReturnPending consignmentData={consignmentData} paymentStatus={paymentStatus} refreshConsignment={refreshConsignment} /> : 
          <RentalCompletion paymentStatus={paymentStatus} consignmentData={consignmentData} refreshConsignment={refreshConsignment} />;
      case MODIFIED_BY_CUSTOMER:
        return <RentModifiedByCustomer paymentStatus={paymentStatus} consignmentData={consignmentData} refreshConsignment={refreshConsignment} shippingCarriers={shippingCarriers} />;
      case MODIFIED_BY_VENDOR:
        return <RentModifiedByVendor paymentStatus={paymentStatus} consignmentData={consignmentData} refreshConsignment={refreshConsignment} />;
      case TICKET_IN_PROGRESS:
        return <TicketDispute consignmentData={consignmentData} refreshConsignment={refreshConsignment} />
        default:
        return <div></div>;
    }
  };

  return <Fragment>{renderStateComponent()}</Fragment>;
};

export default React.memo(RentalOrderStates);
