import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import * as Ven from 'data/Vendor';
import Modal from 'components/UI/Modal';
import UploadButton from 'view/UploadButton';
import ErrorMessage from 'view/ErrorMessage';
import Input from 'components/UI/Input';
import InfoIcon from 'components/UI/InfoIcon';
import { renderToast } from 'components/UI/ToastNotification';
import { Color, Size } from 'lib/styles';
import { useAuth } from 'lib/Auth';
import { useAPI } from 'lib/API';
import { postMedia } from 'requests/media';
import { saveVendor, createVendor } from 'requests/vendor';

const StyledInfoIcon = styled(InfoIcon)`
  display: inline-block;
  margin-left: ${Size.small};
  vertical-align: text-bottom;
`;

const BlockUploadButton = styled(UploadButton)`
  display: block;
`;

const Label = styled.label`
  display: block;
`;

const Logo = styled.img`
  flex: 1;
  height: 3.5rem;
  margin-left: ${Size.medium};
  object-fit: contain;
  object-position: center;
`;

const LogoContainer = styled.div`
  align-items: flex-end;
  display: flex;
  margin-top: ${Size.medium};
`;

const EmptyLogo = styled.div`
  background-color: ${Color.darkGray};
  height: 64px;
  margin-left: ${Size.medium};
  padding: ${Size.tiny};
  width: 64px;
`;

export interface Props {
  onClose: () => void;
  vendors: Ven.VendorSummary[];
  selectedVendorId: number | null;
  setShouldReload: any;
  showModal: boolean;
}

export default function VendorModal({ onClose, vendors, selectedVendorId, setShouldReload, showModal }: Props) {
  const { token } = useAuth();
  const [newVendor, setNewVendor] = useState<Ven.PartialVendor>({
    name: '',
    code: ''
  });
  const [vendorToUpdate, setVendorToUpdate] = useState<Ven.VendorSummary | null>(null);

  const createReq = useAPI({
    deferFn: createVendor,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Vendor created.');
      setShouldReload(true);
      onClose();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  const updateReq = useAPI({
    deferFn: saveVendor,
    onResolve: () => {
      renderToast(toast.TYPE.SUCCESS, 'Vendor updated.');
      setShouldReload(true);
      onClose();
    },
    onReject: err => {
      renderToast(toast.TYPE.ERROR, err.message);
    }
  });

  useEffect(() => {
    const selectedVendor = vendors.find(brand => brand.id === selectedVendorId);
    setVendorToUpdate(selectedVendor!);
  }, [selectedVendorId]);

  const handleClick = () => {
    if (vendorToUpdate) {
      updateReq.run(vendorToUpdate);
    } else {
      createReq.run(newVendor);
    }
  }

  const uploadImage = async (file: File) => {
    if (vendorToUpdate) {
      const image = await postMedia({
        file,
        token: token as string,
        type: 'vendor_logo',
        vendorId: vendorToUpdate!.id
      });

      setVendorToUpdate({ ...vendorToUpdate, media: [image] });
    }
  }

  const cantSubmit =
    vendorToUpdate
      ? (updateReq.isPending || (vendorToUpdate !== null && vendorToUpdate.name.trim().length === 0))
      : (createReq.isPending || newVendor.name.trim().length === 0 || newVendor.code.trim().length === 0);

  let errorMessage = null;
  if (createReq.error) {
    errorMessage = <ErrorMessage message={createReq.error.message} />;
  } else if (updateReq.error) {
    errorMessage = <ErrorMessage message={updateReq.error.message} />;
  }

  const vendorLogo =
    vendorToUpdate && vendorToUpdate.media && vendorToUpdate.media.length > 0
      ? <Logo src={process.env.REACT_APP_MEDIA_URL + vendorToUpdate.media[0].path} />
      : <EmptyLogo />;

  const logoContainer =
    vendorToUpdate ? (
      <LogoContainer>
        <Label>
          Logo{' '}
          <StyledInfoIcon
            tooltipText="Logos should be at least 400x100 pixels."
            width="auto"
          />
          <BlockUploadButton
            acceptTypes={['image/*']}
            label="Choose"
            onChange={uploadImage}
          />
        </Label>
        {vendorLogo}
      </LogoContainer>
    ) : null;

  const codeInput =
    vendorToUpdate
      ? null
      : (
        <Input
          id='vendor-code-id'
          disabled={createReq.isPending}
          labelName="Vendor code"
          onInputChange={code => setNewVendor({ ...newVendor, code })}
          value={newVendor && newVendor.code ? newVendor.code : ''}
          placeholder="type vendor code (2-8 characters)"
        />
      );

  return (
    <Modal
      showModal={showModal}
      showCloseIcon
      onClose={onClose}
      onClick={handleClick}
      disabled={cantSubmit}
      title={vendorToUpdate ? 'Edit Vendor' : 'Create Vendor'}
      primaryButtonText='Save'
    >
      <Input
        id='vendor-name-id'
        disabled={createReq.isPending || updateReq.isPending}
        placeholder='Vendor name'
        labelName="Name"
        onInputChange={
          vendorToUpdate
            ? name => setVendorToUpdate({ ...vendorToUpdate!, name })
            : name => setNewVendor({ ...newVendor, name })
        }
        value={vendorToUpdate && vendorToUpdate.name ? vendorToUpdate.name : newVendor.name}
      />
      {codeInput}
      {logoContainer}
      {errorMessage}
    </Modal>
  );
};

VendorModal.defaultProps = {};
