import React, { useCallback, useMemo, useState } from 'react';
import { COUNTRY_SELECTOR_DEFAULT } from './orderlist.enums';
import { useCountries } from '../../../hooks/useCountries';


const useTrackTableColumnsWidth = (tableRef:  React.MutableRefObject<{tableContainerDiv?: any} | undefined>) => {
  const [columnWidths, setColumnWidths] = useState<Array<number>>([])

  const getColumnWidths = useCallback(() => {
    const firstCell = tableRef?.current?.tableContainerDiv?.current?.querySelector("table tbody tr td");
    if (!firstCell || firstCell.colSpan > 1) {
      return [];
    }
    const tableHeaders = tableRef?.current?.tableContainerDiv?.current?.querySelectorAll("table thead th") || [];
    return [...tableHeaders].map((tableHeader: { offsetWidth: number; }) => tableHeader.offsetWidth)
  }, [tableRef])

  const updateColumnWidth = useCallback(() => {
    Promise.resolve().then(() => setColumnWidths(getColumnWidths()));
  }, [getColumnWidths]);

  return {columnWidths, updateColumnWidth}
}

const useCountrySelector = () => {
  const [selectedCountry, setSelectedCountry] = useState(COUNTRY_SELECTOR_DEFAULT);
  const { countries } = useCountries();
  const options = [
    { value: COUNTRY_SELECTOR_DEFAULT, id: 'null' },
    ...countries.map((country)=>({
      value: country.countryName, id: country.countryIso
    })),
  ];

  const onCountrySelect = useCallback((selectProps) => {
    setSelectedCountry(selectProps?.target?.value || COUNTRY_SELECTOR_DEFAULT);
  }, [setSelectedCountry])

  const countrySelector = useMemo(() => ({
    label: '',
    value: selectedCountry,
    options,
    onChange: onCountrySelect,
    width: '160px'
  }), [selectedCountry, options, onCountrySelect]);

  return { selectedCountry, countrySelector }
}

export { useTrackTableColumnsWidth, useCountrySelector }