import { get, post, put, del } from 'lib/API';
import PointOfService from 'data/PointOfService';

export const createPointOfService = async ([newPOS, warehouseId]: any[], { token }: any): Promise<any> => {
  return await post('v1/pos', token, {
    posId: newPOS.posId,
    name: newPOS.name,
    nickname: newPOS.nickname,
    street: newPOS.street,
    city: newPOS.city,
    state: newPOS.state,
    zip: newPOS.zip,
    latitude: newPOS.latitude,
    longitude: newPOS.longitude,
    stateIsoCode: newPOS.stateIsoCode,
    regionIsoCode: newPOS.regionIsoCode,
    countryIsoCode: newPOS.countryIsoCode,
    shortIsoCode: newPOS.shortIsoCode,
    warehouseId
  });
};

export const createPointsOfService = async (
  [pointsOfService, warehouseId]: any[],
  { token }: any
): Promise<any[]> => {
  return await Promise.all(
    pointsOfService.map((pos: PointOfService) => {
      return createPointOfService([pos, warehouseId], { token });
    })
  );
};

export const savePointOfService = async ([POSToUpdate, warehouseId]: any[], { token }: any): Promise<any> => {
  return await put(`v1/pos/${POSToUpdate.id}`, token, {
    posId: POSToUpdate.posId,
    name: POSToUpdate.name,
    nickname: POSToUpdate.nickname,
    street: POSToUpdate.street,
    city: POSToUpdate.city,
    state: POSToUpdate.state,
    latitude: POSToUpdate.latitude,
    longitude: POSToUpdate.longitude,
    stateIsoCode: POSToUpdate.stateIsoCode,
    regionIsoCode: POSToUpdate.regionIsoCode,
    countryIsoCode: POSToUpdate.countryIsoCode,
    shortIsoCode: POSToUpdate.shortIsoCode,
    zip: POSToUpdate.zip,
    warehouseId
  });
};

export const removePointOfService = async ([POSToRemove]: any[], { token }: any): Promise<any> => {
  return await del(`v1/pos/${POSToRemove.id}`, token);
};

//this endpoint is deprecated and should not be used since the opencage usage was canceled
export const formatAddress = async ([address]: any[], { token }: any): Promise<any> => {
  return await get(`v1/pos/formatAddress?address=${address}`, token);
};
